export const DATE_FORMATS = {
  FULL_DATE_TIME: 'dd MMM yyyy, HH:mm',
  DEFAULT: 'MM/dd/yyyy',
  DATE_TIME: "dd'.'MM'.'yy HH:mm",
  DATE_MONTH: 'd MMMM',
  FULL_DATE: 'dd MMM yyyy',
  SHORT_DATE: 'dd.MM',
  SHORT_FULL_DATE: 'dd.MM.yy',
  TIME: 'HH:mm',
  CALENDAR_FULL_DATE: 'd MMM yyyy',
  CALENDAR_DATE: 'd MMM',
  SHORT_YEAR: 'yy',
  SELECT_LIST_FULL_DATE: 'dd MMM ‘yy',
};
