import { Events } from 'packages/rest';
import { settingsSelectors } from 'packages/settings';
import { favoritesSelectors } from 'packages/favorites';

import { LoadEventsOptions } from '../../types';
import { DEFAULT_EVENTS_LIMIT } from '../../constants';
import { mapEvents, getMatchStatusQueryParams } from '../../services';

const loadEvents = ({
  date,
  offset = 0,
  limit = DEFAULT_EVENTS_LIMIT,
  isFinished = false,
  isScheduled = false,
  categoryId,
}: LoadEventsOptions) => async (dispatch, getState) => {
  const state = getState();
  const activeDate = date || settingsSelectors.getActiveDate(state);
  const activeSportId = settingsSelectors.getActiveSportId(state);
  const favoriteTournamentIds = favoritesSelectors
    .getFavoriteLeagues(state)
    .map(({ uniqueTournamentId }) => uniqueTournamentId);

  const matchStatusFilter = getMatchStatusQueryParams(isFinished, isScheduled);

  const { data } = await Events.loadAllEvents({
    offset,
    limit,
    categoryId,
    activeSportId,
    date: activeDate,
    orderByFavoriteTournamentsIds: favoriteTournamentIds,
    ...matchStatusFilter,
  });

  if (data?.results) {
    const { results, next } = data;

    return {
      events: mapEvents(results),
      hasNext: !!next,
      offset: offset + results.length,
    };
  }

  return [];
};

export default loadEvents;
