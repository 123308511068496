import React, { memo, useCallback } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { Button, Icon, Select } from 'packages/shared/components';
import { ButtonType } from 'packages/shared/components/Button/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { SelectItem, SelectSize } from 'packages/shared/components/Select/types';
import { PresetType } from 'packages/season-team-statistics/types';

import styles from './styles.scss';

type Props = {
  presetItems: SelectItem[];
  onPresetItemSelect: (presetItem: PresetType) => void;
  onCustomizeButtonClick: () => void;
  activePresetItem: SelectItem;
  className?: string;
};

const ControlHeader = ({
  onCustomizeButtonClick,
  onPresetItemSelect,
  activePresetItem,
  presetItems,
  className,
}: Props) => {
  const handlePresetItemChange = useCallback(({ id: presetType }: SelectItem) => {
    onPresetItemSelect(presetType as PresetType);
  }, []);

  return (
    <div className={classnames(styles.controlHeader, className)}>
      <div className={styles.filterGroup}>
        <div className={styles.title}>{i18n.value('season.teamStatistics.title')}</div>
        <Select
          items={presetItems}
          onChange={handlePresetItemChange}
          selectedItem={activePresetItem}
          className={styles.presetSelect}
          size={SelectSize.Md}
        />
      </div>
      <Button
        type={ButtonType.Default}
        contentClassName={styles.customizeButtonContent}
        onClick={onCustomizeButtonClick}
      >
        <Icon className={styles.icon} id={IconType.Configuration} />
      </Button>
    </div>
  );
};

export default memo(ControlHeader);
