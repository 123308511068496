import React, { memo, useCallback, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useSearchParams } from 'react-router-dom';

import { Category, MediaScreen, Tournament } from 'types';
import { MobilePage, SearchQuery } from 'router/types';
import { Figure, Icon, Separator } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';
import { useNavigation } from 'packages/hooks';

import styles from './styles.scss';
import connect from './conenct';
import { TournamentList, LoadingSkeleton } from './components';

type Props = {
  category?: Category;
  tournaments: Tournament[];
  isLoading: boolean;
  categoryActions: ActionCreatorsMapObject;
  selectedCategoryId?: string;
  closePopover: () => void;
};

const CategoryModal = ({
  categoryActions,
  isLoading,
  category,
  tournaments,
  selectedCategoryId,
  closePopover,
}: Props) => {
  const [searchParams] = useSearchParams();
  const { appNavigate } = useNavigation(MediaScreen.Mobile);

  const activeCategoryId = searchParams.get(SearchQuery.CategoryId) || selectedCategoryId;

  useEffect(() => {
    categoryActions.loadCategory({ categoryId: activeCategoryId });

    return categoryActions.clearCategory;
  }, [activeCategoryId]);

  const handleCategoryClick = useCallback(() => {
    if (activeCategoryId) {
      appNavigate(MobilePage.FootballCategory, { categoryId: activeCategoryId });
    }
  }, [activeCategoryId]);

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      {category && (
        <div className={styles.container}>
          <div className={styles.header}>
            <Figure src={category.logo} className={styles.categoryIcon} />
            <div className={styles.name}> {category.name}</div>
            <Icon onClick={closePopover} className={styles.closeIcon} id={IconType.Cross} />
          </div>

          <div onClick={handleCategoryClick} className={styles.allGames}>
            <Separator />
            <div className={styles.title}>All {category.name} leagues’ games</div>
            <Separator />
          </div>

          <TournamentList tournaments={tournaments} />
        </div>
      )}
    </>
  );
};

export default connect(memo(CategoryModal));
