import { ReducerMap, handleActions, combineActions } from 'redux-actions';

import { stadiumActions } from '../actions';
import { Stadium } from '../types';

const defaultState: Stadium[] = [];

interface PayloadType {
  stadiums: Stadium[];
}

const reducerMap: ReducerMap<Stadium[], PayloadType> = {
  [`${combineActions(
    stadiumActions.loadInitialStadiumsSuccess,
    stadiumActions.loadStadiumsPageSuccess,
  )}`]: (state, { payload }) => {
    const { stadiums } = payload;

    return stadiums;
  },

  [`${stadiumActions.cleanStadiums}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
