import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { INITIAL_PLAYERS_LIMIT } from '../../constants';
import { commonActions, customFilterStateActions, playersActions } from '../../actions';
import { PlayersStatus } from '../../types';

const defaultState: PlayersStatus = {
  isInitialPageLoading: true,
  isNewPageLoading: false,
  hasNext: false,
  limit: INITIAL_PLAYERS_LIMIT,
  offset: 0,
  count: 0,
};

const reducerMap: ReducerMap<PlayersStatus, any> = {
  [`${playersActions.loadInitialPlayersStart}`]: (state) => ({
    ...state,
    isInitialPageLoading: true,
  }),
  [`${combineActions(
    playersActions.loadInitialPlayersSuccess,
    playersActions.loadInitialPlayersFailure,
  )}`]: (state) => ({
    ...state,
    isInitialPageLoading: false,
  }),
  [`${playersActions.loadPlayersPageStart}`]: (state) => ({
    ...state,
    isNewPageLoading: true,
  }),
  [`${combineActions(
    playersActions.loadPlayersPageFailure,
    playersActions.loadPlayersPageSuccess,
  )}`]: (state) => ({
    ...state,
    isNewPageLoading: false,
  }),
  [`${combineActions(
    playersActions.loadInitialPlayersSuccess,
    playersActions.loadPlayersPageSuccess,
  )}`]: (state, { payload }) => {
    const { hasNext, count } = payload;

    return {
      ...state,
      hasNext,
      count,
    };
  },
  [`${playersActions.updatePaginationSuccess}`]: (state, { payload }) => {
    const { offset, limit } = payload;

    return {
      ...state,
      offset,
      limit,
    };
  },
  [`${customFilterStateActions.customFilterUpdate}`]: (state) => {
    return {
      ...state,
      offset: defaultState.offset,
      limit: defaultState.limit,
      count: defaultState.count,
    };
  },
  [`${commonActions.cleanPlayerStatistics}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
