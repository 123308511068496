import getPath from './getPath';

export const getData = (state) => {
  return getPath(state).data;
};

export const getSeasons = (state) => {
  return getData(state).seasons;
};

export const getChampions = (state) => {
  return getData(state).champions;
};

export const getFirstSeason = (state) => {
  return getData(state).firstSeason;
};
