import React, { memo, useCallback, useEffect, useMemo } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useSearchParams } from 'react-router-dom';
import isEmpty from 'is-empty-typed';

import { AvailableTabs, EventSeries } from 'packages/event-details/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage, SearchQuery } from 'router/types';
import { Event, Tournament } from 'types';

import EventDetailsView from './views';
import connect from './connect';
import { getFilteredEventDetailsTabs } from './services';
import { DetailsSkeleton } from './components';

type Props = {
  isLoading: boolean;
  event: Event;
  className?: string;
  eventSeries: EventSeries;
  availableTabs: AvailableTabs;
  eventDetailsActions: ActionCreatorsMapObject;
  eventId: number;
};

const extendedEventDetails = (
  EventDetails: typeof EventDetailsView[keyof typeof EventDetailsView],
) =>
  memo(
    ({
      event,
      isLoading,
      eventSeries,
      availableTabs,
      eventDetailsActions,
      eventId,
      className,
    }: Props) => {
      const [searchParams, setSearchParams] = useSearchParams();
      const { appNavigate } = useNavigation();

      const eventDetailsTabs = useMemo(() => getFilteredEventDetailsTabs(availableTabs), [
        availableTabs,
      ]);

      useEffect(() => {
        if (eventId) {
          eventDetailsActions.openEventDetails(eventId);

          return () => {
            eventDetailsActions.closeEventDetails();
          };
        }
      }, [eventId]);

      const handleClose = useCallback(() => {
        searchParams.delete(SearchQuery.EventCardId);
        setSearchParams(searchParams);
      }, [searchParams]);

      const handleTournamentClick = useCallback(
        ({ currentSeasonId, uniqueTournamentId }: Tournament) => {
          appNavigate(DesktopPage.FootballSeason, {
            seasonId: currentSeasonId,
            uniqueTournamentId,
          });
        },
        [],
      );

      const handleFirstLegClick = useCallback(
        (event?: Event) => {
          if (event) {
            setSearchParams({ [SearchQuery.EventCardId]: event.id.toString() });
          }
        },
        [searchParams],
      );

      if (isLoading) {
        return <DetailsSkeleton />;
      }

      return (
        <>
          {!isEmpty(event) && (
            <EventDetails
              event={event}
              className={className}
              onClose={handleClose}
              eventDetailsTabs={eventDetailsTabs}
              onTournamentClick={handleTournamentClick}
              onFirstLegClick={handleFirstLegClick}
              eventSeries={eventSeries}
            />
          )}
        </>
      );
    },
  );

export default {
  Desktop: connect(extendedEventDetails(EventDetailsView.Desktop)),
  DesktopNarrow: connect(extendedEventDetails(EventDetailsView.DesktopNarrow)),
  Tablet: connect(extendedEventDetails(EventDetailsView.Tablet)),
  TabletNarrow: connect(extendedEventDetails(EventDetailsView.TabletNarrow)),
  Mobile: connect(extendedEventDetails(EventDetailsView.Mobile)),
};
