import React, { memo } from 'react';

import { TournamentView } from 'packages/events/types';
import { Pagination } from 'packages/shared/components';
import { PaginationParams } from 'packages/shared/components/Pagination/types';

import { EventActiveFilters, PlayerTeam, Season, Tournament } from 'packages/player-events/types';
import { CustomPeriod, YearItem } from 'packages/shared/components/Calendar/types';
import { LocaleCode } from 'types';

import { EventList, EventFilter, HeaderSkeleton, EventListSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  onPaginationChange: (params: PaginationParams) => void;
  onEventClick: (eventId: number) => void;
  onTournamentChange: (tournament?: Tournament) => void;
  onTeamChange: (team?: PlayerTeam) => void;
  onSeasonsChange: (selectedSeasons: Season[]) => void;
  onYearClick: (year: YearItem) => void;
  onPeriodClick: (period: CustomPeriod) => void;
  onPeriodChange: (period: [string, string]) => void;
  events: TournamentView[];
  tournamentFilters: Tournament[];
  teamFilters: PlayerTeam[];
  activeFilters: EventActiveFilters;
  customPeriod?: CustomPeriod;
  localeCode: LocaleCode;
  isLimitSelectable: boolean;
  isMoreEventsLoading: boolean;
  limit: number;
  isMoreFiltersLoading: boolean;
  eventsCount: number;
  yearOffset: number;
  isInitialDataLoading: boolean;
};

const PlayerEvents = ({
  onPaginationChange,
  onEventClick,
  isLimitSelectable,
  events,
  isMoreEventsLoading,
  eventsCount,
  onTournamentChange,
  onTeamChange,
  onSeasonsChange,
  onYearClick,
  onPeriodClick,
  onPeriodChange,
  tournamentFilters,
  teamFilters,
  activeFilters,
  customPeriod,
  localeCode,
  isMoreFiltersLoading,
  yearOffset,
  limit,
  isInitialDataLoading,
}: Props) => {
  return (
    <>
      {isInitialDataLoading ? (
        <>
          <HeaderSkeleton />
          <EventListSkeleton elementsCount={limit} />
        </>
      ) : (
        <>
          <EventFilter
            onTournamentChange={onTournamentChange}
            onTeamChange={onTeamChange}
            onSeasonsChange={onSeasonsChange}
            onYearClick={onYearClick}
            onPeriodClick={onPeriodClick}
            onPeriodChange={onPeriodChange}
            tournamentFilters={tournamentFilters}
            teamFilters={teamFilters}
            activeFilters={activeFilters}
            customPeriod={customPeriod}
            localeCode={localeCode}
            isMoreFiltersLoading={isMoreFiltersLoading}
            eventsCount={eventsCount}
            yearOffset={yearOffset}
          />

          <EventList onEventClick={onEventClick} isLoading={isMoreEventsLoading} events={events} />
        </>
      )}

      <Pagination
        isLoading={isInitialDataLoading}
        resultsLabel="common.pagination.gamesLabel"
        isLimitSelectable={isLimitSelectable}
        amountOfItems={eventsCount}
        pageSize={limit}
        onPaginationChange={onPaginationChange}
        className={styles.paginationContainer}
        isLabelVisible
      />
    </>
  );
};

export default memo(PlayerEvents);
