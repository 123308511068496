import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { NotifierReducer } from '../types';

const defaultState = {
  notifications: [],
};

const reducerMap: ReducerMap<NotifierReducer, any> = {
  [`${actions.updateNotifications}`]: (state, { payload: notifications }) => {
    return { ...state, notifications };
  },
};

export default handleActions(reducerMap, defaultState);
