import { PopoverAlign } from '../types';

const calculateAlign = (
  initialAlignValue: number,
  targetRect: ClientRect,
  popoverHeight: number,
  align: PopoverAlign,
) => {
  switch (align) {
    case PopoverAlign.Top: {
      return calculatePopoverTopAlign(initialAlignValue, targetRect, popoverHeight);
    }
    case PopoverAlign.CenterStart: {
      return calculatePopoverCenterStartAlign(initialAlignValue, targetRect);
    }
    case PopoverAlign.Center: {
      return calculatePopoverCenterAlign(initialAlignValue, targetRect, popoverHeight);
    }
    case PopoverAlign.CenterEnd: {
      return calculatePopoverCenterEndAlign(initialAlignValue, targetRect, popoverHeight);
    }
    case PopoverAlign.Bottom: {
      return calculatePopoverBottomAlign(initialAlignValue, targetRect);
    }
  }
};

const calculatePopoverTopAlign = (
  initialAlignValue: number,
  targetRect: ClientRect,
  popoverHeight: number,
) => {
  return targetRect.top - popoverHeight + initialAlignValue;
};

const calculatePopoverCenterStartAlign = (initialAlignValue: number, targetRect: ClientRect) => {
  return targetRect.top + initialAlignValue;
};

const calculatePopoverCenterAlign = (
  initialAlignValue: number,
  targetRect: ClientRect,
  popoverHeight: number,
) => {
  return targetRect.top + targetRect.height / 2 - popoverHeight / 2 + initialAlignValue;
};

const calculatePopoverCenterEndAlign = (
  initialAlignValue: number,
  targetRect: ClientRect,
  popoverHeight: number,
) => {
  return targetRect.bottom - popoverHeight + initialAlignValue;
};

const calculatePopoverBottomAlign = (initialAlignValue: number, targetRect: ClientRect) => {
  return targetRect.bottom + initialAlignValue;
};

export default calculateAlign;
