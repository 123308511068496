import animateBracketScroll from './animateBracketScroll';
import getElementTranslateXValue from './getElementTranslateXValue';
import { SCROLL_ANIMATION_DURATION } from '../constants';

interface Params {
  gridContainerElement: HTMLDivElement;
  gridElement: HTMLDivElement;
  initialContentHeight: number;
  initialColumnsHeights: number[];
  scrollLeftTo: number;
  scrollTopTo?: number;
  duration?: number;
}

const getScrollConfiguration = ({
  gridContainerElement,
  gridElement,
  initialContentHeight,
  initialColumnsHeights,
  scrollLeftTo,
  scrollTopTo,
  duration = SCROLL_ANIMATION_DURATION,
}: Params): Parameters<typeof animateBracketScroll>[0] | undefined => {
  const scrollLeft = getScrollLeftByDirection(gridElement, scrollLeftTo);
  const scale = calculateScale(gridContainerElement, gridElement, scrollLeft);
  const scrollScale = gridContainerElement.scrollTop / gridContainerElement.scrollHeight;
  const height = calculateScaledHeight(gridContainerElement, initialContentHeight, scale);
  const scrollTop = scrollTopTo || Math.round(scrollScale * height);
  const scaledColumnsHeights = calculateScaledColumnsHeights(initialColumnsHeights, scale);

  if (isScrollInBounds(gridElement, scrollLeft)) {
    return {
      bracketContainerElement: gridContainerElement,
      bracketGridElement: gridElement,
      columnElements: gridElement.querySelectorAll<HTMLDivElement>(`[id^='column-']`),
      columnsHeights: scaledColumnsHeights,
      left: scrollLeft,
      top: scrollTop,
      duration,
    };
  }
};

const getScrollLeftByDirection = (gridElement: HTMLDivElement, scrollTo: number) => {
  const scrollLeft = getElementTranslateXValue(gridElement);

  return scrollLeft + scrollTo;
};

const calculateScale = (
  gridContainerElement: HTMLDivElement,
  gridElement: HTMLDivElement,
  scrollLeft: number,
) => {
  return 1 / 2 ** (gridElement.children.length / (gridElement.scrollWidth / scrollLeft));
};

const calculateScaledHeight = (
  gridContainerElement: HTMLDivElement,
  initialContentHeight: number,
  scale: number,
) => {
  const scaledHeight = initialContentHeight * scale;

  return scaledHeight < gridContainerElement.clientHeight
    ? gridContainerElement.clientHeight
    : scaledHeight;
};

const calculateScaledColumnsHeights = (initialColumnsHeights: number[], scale: number) => {
  return initialColumnsHeights.map((height) => height * scale);
};

const isScrollInBounds = (gridElement: HTMLDivElement, scrollLeft: number) => {
  return (
    scrollLeft >= 0 && Math.floor(scrollLeft + gridElement.clientWidth) <= gridElement.scrollWidth
  );
};

export default getScrollConfiguration;
