import { TabletNarrowPage } from '../types';

export const ROOT = {
  [TabletNarrowPage.Home]: '/',
  [TabletNarrowPage.Settings]: '/settings',
  [TabletNarrowPage.Football]: '/football',
  [TabletNarrowPage.FootballSeason]: '/football/unique-tournament/:tournamentId/season/:seasonId',
  [TabletNarrowPage.FootballTeam]: '/football/team/:teamId',
  [TabletNarrowPage.FootballFavorites]: '/football/favorites',
  [TabletNarrowPage.FootballCategories]: '/football/categories',
  [TabletNarrowPage.FootballCategory]: '/football/category/:categoryId',
  [TabletNarrowPage.FootballPlayer]: '/football/player/:playerId',
  [TabletNarrowPage.FootballStadium]: '/football/stadium/:stadiumId',
  [TabletNarrowPage.FootballMatch]: '/football/match/:eventId',
  [TabletNarrowPage.NotFound]: '*',
};

export const SEASON_CHILDREN = {
  [TabletNarrowPage.FootballSeasonHome]: '',
  [TabletNarrowPage.FootballSeasonSummary]: 'summary',
  [TabletNarrowPage.FootballSeasonGames]: 'games',
  [TabletNarrowPage.FootballSeasonStandings]: 'standings',
  [TabletNarrowPage.FootballSeasonTeamStatistics]: 'team-statistics',
  [TabletNarrowPage.FootballSeasonPlayerStatistics]: 'player-statistics',
  [TabletNarrowPage.FootballSeasonStadiums]: 'stadiums',
  [TabletNarrowPage.FootballSeasonHistory]: 'history',
  [TabletNarrowPage.FootballSeasonComingSoon]: '*',
};

export const STADIUM_CHILDREN = {
  [TabletNarrowPage.FootballStadiumHome]: '',
  [TabletNarrowPage.FootballStadiumSummary]: 'unique-tournament/:tournamentId/season/:seasonId',
};

export const TEAM_CHILDREN = {
  [TabletNarrowPage.FootballTeamHome]: '',
  [TabletNarrowPage.FootballTeamSummary]: 'summary',
  [TabletNarrowPage.FootballTeamSummaryDescription]:
    'summary/unique-tournament/:tournamentId/season/:seasonId',
  [TabletNarrowPage.FootballTeamStandings]:
    'standings/unique-tournament/:tournamentId/season/:seasonId',
  [TabletNarrowPage.FootballTeamPlayerStatistics]:
    'player-statistics/unique-tournament/:tournamentId/season/:seasonId',
  [TabletNarrowPage.FootballTeamGames]: 'games',
  [TabletNarrowPage.FootballTeamSquad]: 'squad',
};

export const PLAYER_CHILDREN = {
  [TabletNarrowPage.FootballPlayerHome]: '',
  [TabletNarrowPage.FootballPlayerSummary]: 'summary',
  [TabletNarrowPage.FootballPlayerGames]: 'games',
};
