import { Event } from 'packages/events/types';

import { StorageEventGroup } from '../../types';
import { commonSelectors } from '../../selectors';
import { EVENT_STORAGE_GROUP_KEYS } from '../../constants';

export const getAllEvents = (state): Event[] => {
  return commonSelectors.getEventsByGroup(state, EVENT_STORAGE_GROUP_KEYS[StorageEventGroup.All])
    .data;
};

export const getEventFromAllById = (state, id) => {
  return getAllEvents(state).find((event) => event.id === id);
};
