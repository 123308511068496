export enum Ordering {
  Number = 'jersey_number',
  StartDate = 'start_date',
  Name = 'name',
  Height = 'height',
  Weight = 'weight',
  PreferredFoot = 'preferred_foot',
  MarketValue = 'market_value',
  Country = 'country_name',
  PositionName = 'position_name',
  Age = 'date_of_birth',
}
