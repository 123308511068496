import { EventTabCategory, StorageEventGroup } from 'packages/events/types';

export const EVENT_GROUPS = [
  EventTabCategory.Default,
  EventTabCategory.Live,
  EventTabCategory.Scheduled,
  EventTabCategory.Finished,
  EventTabCategory.MyGames,
];

export const EVENT_STORAGE_GROUP_KEYS = {
  [StorageEventGroup.All]: 'all',
  [StorageEventGroup.Scheduled]: 'scheduled',
  [StorageEventGroup.Finished]: 'finished',
  [StorageEventGroup.Live]: 'live',
  [StorageEventGroup.Favorites]: 'favorites',
};
