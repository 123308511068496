import { TeamEvents } from 'packages/rest';

import { mapFilters } from '../../services';
import { filterActions } from '../../actions';

const loadFilters = (teamId: number) => async (dispatch) => {
  try {
    dispatch(filterActions.loadFiltersStart());

    const { data } = await TeamEvents.loadTeamFilters({ teamId });

    const tournaments = mapFilters(data.results);

    dispatch(filterActions.loadFiltersSuccess({ tournaments }));
  } catch (error) {
    dispatch(filterActions.loadFiltersFailure(error));
  }
};

export default loadFilters;
