import { createAction } from 'redux-actions';

import { NAMESPACE } from '../../constants';

export const loadMobileFiltersStart = createAction(`${NAMESPACE}LOAD_MOBILE_FILTERS_START`);
export const loadMobileFiltersSuccess = createAction(`${NAMESPACE}LOAD_MOBILE_FILTERS_SUCCESS`);
export const loadMobileFiltersFailure = createAction(`${NAMESPACE}LOAD_MOBILE_FILTERS_FAILURE`);

export const updateMobileFiltersSuccess = createAction(`${NAMESPACE}UPDATE_MOBILE_FILTERS_SUCCESS`);

export const cleanMobileFiltersSuccess = createAction(`${NAMESPACE}CLEAN_MOBILE_FILTERS_SUCCESS`);
