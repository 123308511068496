import * as playerDetailsActions from './actions';
import * as playerDetailsSelectors from './selectors';
import * as playerDetailsConstants from './constants';
import * as playerDetailsThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  playerDetailsActions,
  playerDetailsConstants,
  playerDetailsThunks,
  playerDetailsSelectors,
  createReducer,
};
