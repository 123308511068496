import { createElement } from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { ColumnType, TeamStatistics } from 'packages/season-team-statistics/types';
import { StickyColumnDirection } from 'packages/shared/components/FlexibleTable/types';
import { Tooltip } from 'packages/shared/components';

import { ColumnLogo, ColumnRank, ColumnTitle, TeamCell } from '../../../../../components';
import { SchemaParams } from '../../../../../types';

const getTableSchema = ({ columns, additionalProps }: SchemaParams): Column<TeamStatistics>[] => {
  return columns.map((column) => {
    const { ordering, onOrderingChange } = additionalProps;
    const { type, columnKey } = column;

    switch (type) {
      case ColumnType.Rank:
        return {
          id: ColumnType.Rank,
          accessor: ({ team }) => team.rank,
          Cell: ({ value: rank }) => createElement(ColumnRank, { rank }),
          sticky: StickyColumnDirection.Left,
          width: 40,
        };
      case ColumnType.ClubLogo:
        return {
          id: ColumnType.ClubLogo,
          accessor: ({ team }) => team.logo,
          Cell: ({ value: logo }) => createElement(ColumnLogo, { logo }),
          stickyFor: ColumnType.Rank,
          width: 40,
        };
      case ColumnType.Club:
        return {
          id: columnKey,
          accessor: ({ team }) => team,
          Cell: ({ value: team }) => createElement(TeamCell, { team }),
          minWidth: 300,
        };
      default:
        return {
          Header: () =>
            createElement(Tooltip, {
              value: i18n.value(
                `season.teamStatistics.customFilter.columnConfiguration.columnName.${type}`,
              ),
              children: createElement(ColumnTitle, {
                type,
                sortingType: ordering[type],
                onOrderingChange,
              }),
            }),
          id: columnKey,
          accessor: ({ statistics }) => statistics[columnKey],
          minWidth: 60,
          width: 60,
        };
    }
  });
};

export default getTableSchema;
