import { EventStandings, SeasonStandings } from 'packages/rest';
import { mobileFilterStagesSelectors } from 'packages/team-standings';

import { stagesActions } from '../../actions';
import { mapStandings, getActiveStageIdByTeamId } from '../../services';

interface Params {
  seasonId: number;
  teamId?: number;
}

const loadStages = ({ seasonId, teamId }: Params) => async (dispatch, getState) => {
  try {
    dispatch(stagesActions.loadStagesStart());

    const [
      {
        data: { results: seasonTablesStagesResponse },
      },
      {
        data: { results: seasonBracketsResponse },
      },
    ] = await Promise.all([
      SeasonStandings.loadSeasonTables({ seasonId }),
      EventStandings.loadEventBrackets({ seasonId }),
    ]);

    const stages = mapStandings({
      seasonTablesStages: seasonTablesStagesResponse,
      tournamentBrackets: seasonBracketsResponse,
    });
    const activeStageId = teamId ? getActiveStageIdByTeamId(stages, teamId) : undefined;
    const defaultStageTabId = mobileFilterStagesSelectors.getDefaultStageTabId(getState());

    dispatch(
      stagesActions.loadStagesSuccess({
        stages,
        activeStageId: defaultStageTabId || activeStageId,
      }),
    );
  } catch (e) {
    dispatch(stagesActions.loadStagesFailure(e));
  }
};

export default loadStages;
