import { connect } from 'react-redux';

import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => {
  return {
    selectedEvent: eventDetailsSelectors.getActiveEvent(state),
  };
};

export default connect(mapStateToProps);
