import { Stage } from 'packages/event-standings/types';

import getPath from './getPath';
import { Stages } from '../../types';

export const getData = (state): Stages.Data => {
  return getPath(state).data;
};

export const getStages = (state): Stage[] | null => {
  return getData(state).stages;
};

export const getActiveStageId = (state): number | undefined => {
  return getData(state).activeStageId;
};
