import React from 'react';
import classnames from 'classnames';

import { CommonSideBarContent } from 'packages/season-sidebar-content-ui';

import styles from './styles.scss';

type Props = {
  className?: string;
};

const SideBar = ({ className }: Props) => {
  return (
    <div className={classnames(styles.sideBar, className)}>
      <CommonSideBarContent />
    </div>
  );
};

export default SideBar;
