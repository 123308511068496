import instance from '../../../axios';
import { LoadCategories } from '../types';

export const loadCategories = ({ sportId, limit, offset }: LoadCategories) => {
  return instance({
    method: 'get',
    url: '/categories/',
    params: {
      sport_id: sportId,
      limit,
      offset,
    },
  });
};
