import React from 'react';

import styles from './styles.scss';

const SuspenseFallback = () => {
  return (
    <div className={styles.fallback}>
      <img className={styles.image} src="/images/logo.svg" alt="" />
    </div>
  );
};

export default SuspenseFallback;
