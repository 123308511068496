import React, { useEffect, memo } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { Competitor, Event } from 'types';
import { BaseHappening } from 'packages/event-stats/types';
import { LoaderContainer, Separator } from 'packages/shared/components';
import { Statistics } from 'packages/shared/modules';
import { Type } from 'packages/shared/components/Separator/types';

import connect from './connect';
import styles from './styles.scss';

type Props = {
  isLoading: boolean;
  cleanEventStats: () => any;
  eventStatsActions: ActionCreatorsMapObject;
  activeEvent: Event;
  details: Record<any, any>;
  timeLines: {
    homeTimeLine: BaseHappening[];
    awayTimeLine: BaseHappening[];
  };
  teams: [Competitor, Competitor];
};

const EventStats = ({
  isLoading,
  activeEvent,
  teams,
  details,
  eventStatsActions,
  cleanEventStats,
  timeLines,
}: Props) => {
  useEffect(() => {
    eventStatsActions.loadEventStats({ eventId: activeEvent.id });
  }, [activeEvent]);

  useEffect(
    () => () => {
      cleanEventStats();
    },
    [],
  );

  return (
    <LoaderContainer isLoading={isLoading} className={styles.loader}>
      <Statistics details={details} teams={teams} timeLines={timeLines} isHeaderSticky />

      <Separator type={Type.Block} />
    </LoaderContainer>
  );
};

export default connect(memo(EventStats));
