import React from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const ListSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Skeleton theme="dark" variant="rectangular" height={12} width={72} />
      </div>

      <div className={styles.wrapper}>
        <Skeleton marginBottom={12} width={154} height={8} variant="rectangular" />
        <Skeleton width={96} height={8} variant="rectangular" />
      </div>
    </div>
  );
};

export default ListSkeleton;
