import React from 'react';

import { NavigationTheme, ThemedNavigationMenuProps } from './types';
import schema from './schema';

interface Props extends ThemedNavigationMenuProps {
  theme?: NavigationTheme;
}

const NavigationMenu = ({ theme = NavigationTheme.Link, ...props }: Props) => {
  const NavigationMenuComponent = schema(theme);

  return <NavigationMenuComponent {...props} />;
};

export default NavigationMenu;
