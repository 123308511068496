import { mobileFilterStagesActions } from '../../actions';
import { stagesSelectors } from '../../selectors';

const resetStages = () => (dispatch, getState) => {
  const { stages, activeStageId } = stagesSelectors.getData(getState());

  dispatch(
    mobileFilterStagesActions.resetStagesSuccess({ stages, defaultStageTabId: activeStageId }),
  );
};

export default resetStages;
