import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { seasonStadiumsThunks, seasonStadiumsSelectors } from 'packages/season-stadiums';

const mapStateToProps = (state) => {
  return {
    stadiums: seasonStadiumsSelectors.getData(state),
    limit: seasonStadiumsSelectors.getLimit(state),
    stadiumsAmount: seasonStadiumsSelectors.getCount(state),
    isInitialPageLoading: seasonStadiumsSelectors.getIsInitialPageLoading(state),
    isNewPageLoading: seasonStadiumsSelectors.getIsNewPageLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    seasonStadiumsActions: bindActionCreators(seasonStadiumsThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
