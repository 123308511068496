import React, { ReactNode } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { isString } from 'packages/utils/Object.utils';

import { ButtonSize, ButtonType } from './types';
import { BUTTON_VIEWS_BY_TYPE } from './constants';
import styles from './styles.scss';

type Props = {
  onClick?: () => void;
  isActive?: boolean;
  isDisabled?: boolean;
  type?: ButtonType;
  className?: string;
  contentClassName?: string;
  size?: ButtonSize;
  children?: ReactNode;
};

const Button = ({
  onClick,
  isActive = false,
  isDisabled = false,
  type = ButtonType.Default,
  children,
  size,
  className,
  contentClassName,
}: Props) => {
  const ViewComponent = BUTTON_VIEWS_BY_TYPE[type];

  return (
    <button
      type="button"
      disabled={isDisabled}
      className={classnames(
        styles.button,
        {
          [styles.disabled]: isDisabled,
          [styles.active]: isActive,
        },
        className,
      )}
      onClick={onClick}
    >
      <ViewComponent
        isActive={isActive}
        isDisabled={isDisabled}
        size={size}
        className={contentClassName}
      >
        {isString(children) ? i18n.value(children) : children}
      </ViewComponent>
    </button>
  );
};

export default React.memo(Button);
