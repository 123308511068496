import { toCamelCase } from 'packages/utils/Object.utils';
import { TEAM_DEFAULT_LOGO } from 'appConstants';

import { TeamStatistics, Statistics, StatType } from '../types';

const DEFAULT_PARAMETER_VALUE = 0;
const EXTENDED_PARAMETER_VALUE = {
  total: 0,
  average: 0,
  matches: 0,
};

const mapTeams = (teamStatistics: any[], offset: number, statType: StatType): TeamStatistics[] => {
  return teamStatistics.map((teamStatisticsEntry, index) => {
    const { statistics = {}, team } = toCamelCase(teamStatisticsEntry);
    const { id: teamId, logo: teamLogo, name: teamName } = toCamelCase(team);
    const {
      gamesPlayed = DEFAULT_PARAMETER_VALUE,
      shotsTotal = EXTENDED_PARAMETER_VALUE,
      shotsOnTarget = EXTENDED_PARAMETER_VALUE,
      shotsOffTarget = EXTENDED_PARAMETER_VALUE,
      shotsBlocked = EXTENDED_PARAMETER_VALUE,
      shotsOnPost = EXTENDED_PARAMETER_VALUE,
      shotsOnBar = EXTENDED_PARAMETER_VALUE,
      shootingEfficiency = EXTENDED_PARAMETER_VALUE,
      averageBallPossession = EXTENDED_PARAMETER_VALUE,
      cornerKicks = EXTENDED_PARAMETER_VALUE,
      freeKicks = EXTENDED_PARAMETER_VALUE,
      offsides = EXTENDED_PARAMETER_VALUE,
      yellowCards = EXTENDED_PARAMETER_VALUE,
      yellowRedCards = EXTENDED_PARAMETER_VALUE,
      redCards = EXTENDED_PARAMETER_VALUE,
      goalsConceded = EXTENDED_PARAMETER_VALUE,
      goalsScored = EXTENDED_PARAMETER_VALUE,
      goalsByHead = EXTENDED_PARAMETER_VALUE,
      goalsByFoot = EXTENDED_PARAMETER_VALUE,
    } = toCamelCase(statistics || {});

    let resolvedStatistics: Statistics;

    switch (statType) {
      case StatType.PerGame:
        resolvedStatistics = {
          gamesPlayed,
          goalsScored: parseFloat(goalsScored.average.toFixed(2)),
          headed: parseFloat(goalsByHead.average.toFixed(2)),
          footGoals: parseFloat(goalsByFoot.average.toFixed(2)),
          totalShots: parseFloat(shotsTotal.average.toFixed(2)),
          shotsOnGoal: parseFloat(shotsOnTarget.average.toFixed(2)),
          shotsOffGoal: parseFloat(shotsOffTarget.average.toFixed(2)),
          shotsBlocked: parseFloat(shotsBlocked.average.toFixed(2)),
          shotsOnPost: parseFloat(shotsOnPost.average.toFixed(2)),
          shotsOnBar: parseFloat(shotsOnBar.average.toFixed(2)),
          shootingEfficiency: parseFloat(shootingEfficiency.total.toFixed(2)),
          possession: parseFloat(averageBallPossession.total.toFixed(2)),
          cornerKicks: parseFloat(cornerKicks.average.toFixed(2)),
          freeKicks: parseFloat(freeKicks.average.toFixed(2)),
          offside: parseFloat(offsides.average.toFixed(2)),
          yellowCards: parseFloat(yellowCards.average.toFixed(2)),
          yellowRedCards: parseFloat(yellowRedCards.average.toFixed(2)),
          redCards: parseFloat(redCards.average.toFixed(2)),
          goalsConceded: parseFloat(goalsConceded.average.toFixed(2)),
        };
        break;
      default:
        resolvedStatistics = {
          gamesPlayed,
          goalsScored: parseFloat(goalsScored.total.toFixed(2)),
          headed: parseFloat(goalsByHead.total.toFixed(2)),
          footGoals: parseFloat(goalsByFoot.total.toFixed(2)),
          totalShots: parseFloat(shotsTotal.total.toFixed(2)),
          shotsOnGoal: parseFloat(shotsOnTarget.total.toFixed(2)),
          shotsOffGoal: parseFloat(shotsOffTarget.total.toFixed(2)),
          shotsBlocked: parseFloat(shotsBlocked.total.toFixed(2)),
          shotsOnPost: parseFloat(shotsOnPost.total.toFixed(2)),
          shotsOnBar: parseFloat(shotsOnBar.total.toFixed(2)),
          shootingEfficiency: parseFloat(shootingEfficiency.total.toFixed(2)),
          possession: parseFloat(averageBallPossession.total.toFixed(2)),
          cornerKicks: parseFloat(cornerKicks.total.toFixed(2)),
          freeKicks: parseFloat(freeKicks.total.toFixed(2)),
          offside: parseFloat(offsides.total.toFixed(2)),
          yellowCards: parseFloat(yellowCards.total.toFixed(2)),
          yellowRedCards: parseFloat(yellowRedCards.total.toFixed(2)),
          redCards: parseFloat(redCards.total.toFixed(2)),
          goalsConceded: parseFloat(goalsConceded.total.toFixed(2)),
        };
        break;
    }

    return {
      team: {
        id: teamId,
        logo: teamLogo || TEAM_DEFAULT_LOGO,
        name: teamName,
        rank: index + 1 + offset,
      },
      statistics: resolvedStatistics,
    };
  });
};

export default mapTeams;
