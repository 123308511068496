import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { appThunks, appSelectors } from 'modules/app';

const mapStateToProps = (state) => {
  return {
    mediaScreen: appSelectors.getMediaScreen(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    appThunks: bindActionCreators(appThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
