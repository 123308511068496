import { combineReducers } from 'redux';

import state from './state';
import status from './status';
import settings from './settings';

export default combineReducers({
  settings,
  state,
  status,
});
