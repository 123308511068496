import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  eventStandingsThunks,
  eventStandingsSelectors,
  eventStandingsActions,
} from 'packages/event-standings';
import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => {
  return {
    activeEvent: eventDetailsSelectors.getActiveEvent(state),
    stages: eventStandingsSelectors.getStages(state),
    activeStageId: eventStandingsSelectors.getActiveStageId(state),
    isLoading: eventStandingsSelectors.getIsEventStandingsLoading(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    eventStandingsActions: bindActionCreators(eventStandingsThunks, dispatch),
    cleanEventStandings: () => dispatch(eventStandingsActions.cleanEventStandings()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
