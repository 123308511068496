import { SeasonTopBoard } from 'packages/rest';
import { PLAYER_API_ORDERING_BY_PARAMETERS } from 'packages/season-top-board/constants';
import { mapSeasonTopPlayers, parseOrderingParams } from 'packages/season-top-board/services';
import { PlayersParameter } from 'packages/season-top-board/types';

import { detailsActions } from '../../../actions';

const { topPlayers: topPlayersActions } = detailsActions;

interface Params {
  teamId: number;
  seasonId: number;
  sortingParameter?: PlayersParameter;
  limit?: number;
}

const loadTopPlayers = ({
  teamId,
  seasonId,
  sortingParameter = PlayersParameter.Goals,
  limit,
}: Params) => async (dispatch) => {
  try {
    dispatch(topPlayersActions.loadTopPlayersStart());

    const { data } = await SeasonTopBoard.loadSeasonTopPlayers({
      teamIds: [teamId],
      seasonId,
      ordering: parseOrderingParams(PLAYER_API_ORDERING_BY_PARAMETERS[sortingParameter]),
      limit,
    });
    const topPlayers = mapSeasonTopPlayers(data.results);

    dispatch(topPlayersActions.loadTopPlayersSuccess(topPlayers));
  } catch (e) {
    dispatch(topPlayersActions.loadTopPlayersFailure(e));
  }
};

export default loadTopPlayers;
