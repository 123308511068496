const getTargetRect = (
  targetEl: HTMLElement,
  parentEl: HTMLElement | null,
  insertIntoParent?: boolean,
): ClientRect => {
  return parentEl && insertIntoParent
    ? getTargetRectRelativeToParent(targetEl)
    : getTargetRectRelativeToViewPort(targetEl);
};

const getTargetRectRelativeToParent = (targetEl: HTMLElement): ClientRect => {
  const { offsetTop, offsetLeft, clientWidth, clientHeight } = targetEl;

  return {
    top: offsetTop,
    left: offsetLeft,
    bottom: offsetTop + clientHeight,
    right: offsetLeft + clientWidth,
    height: clientHeight,
    width: clientWidth,
  };
};

const getTargetRectRelativeToViewPort = (targetEl: HTMLElement): ClientRect => {
  return targetEl.getBoundingClientRect();
};

export default getTargetRect;
