import React, { memo, useMemo } from 'react';
import { Column } from 'react-table';

import { FlexibleTable, TournamentTableRulesBlock } from 'packages/shared/components';
import {
  CommonTableStatistics,
  SeasonTablePromotion,
  SeasonTableRow,
  StatisticsGroup,
  TeamComment,
} from 'packages/event-standings/types';
import { sortTournamentRowsByStatisticsGroupPosition } from 'packages/event-standings/services';
import { Team } from 'types';

import { getRowProps } from '../../services';
import { GetColumnSchemaArguments } from '../../types';
import styles from './styles.scss';

type Props = {
  groupName: string;
  rule: string;
  activeTeamIds: number[];
  isResultBlockDisplayed: boolean;
  rows: SeasonTableRow<CommonTableStatistics>[];
  promotions: SeasonTablePromotion[];
  teamComments: TeamComment[];
  statisticsGroup: StatisticsGroup;
  onRowMouseUp: (team: Team) => void;
  getColumnSchema: (
    params: GetColumnSchemaArguments,
  ) => Column<SeasonTableRow<CommonTableStatistics>>[];
  className?: string;
  blockRef?: React.RefObject<HTMLDivElement>;
};

const StatisticsTable = ({
  rows,
  groupName,
  rule,
  promotions,
  teamComments,
  isResultBlockDisplayed,
  statisticsGroup,
  onRowMouseUp,
  activeTeamIds,
  getColumnSchema,
  className,
  blockRef,
}: Props) => {
  const { data, columns, rowProps } = useMemo(
    () => ({
      data: sortTournamentRowsByStatisticsGroupPosition(rows, statisticsGroup),
      columns: getColumnSchema({ groupName, statisticsGroup }),
      rowProps: getRowProps({
        onRowMouseUp,
        activeTeamIds,
        activeRowClassName: styles.activeTableRow,
      }),
    }),
    [rows, statisticsGroup, groupName, onRowMouseUp, activeTeamIds, getColumnSchema],
  );

  return (
    <div ref={blockRef} className={className}>
      <FlexibleTable<SeasonTableRow<CommonTableStatistics>>
        data={data}
        columns={columns}
        setRowProps={rowProps}
        columnClassName={styles.tableColumn}
        className={styles.narrowStatisticsTable}
        isHeaderSticky
        isRowClickable
      />

      {isResultBlockDisplayed && (
        <TournamentTableRulesBlock
          rule={rule}
          promotions={promotions}
          teamComments={teamComments}
        />
      )}
    </div>
  );
};

export default memo(StatisticsTable);
