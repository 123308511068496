import { NavigationTab, TabView } from 'packages/shared/components/Tabs/types';

export const mapTabsIntoNavigationItems = (tabs: TabView[]): NavigationTab[] => {
  return tabs.map(
    ({ id, name, isDisabled, isLoading, tabComponent, tabComponentProps, hasMaxWidth }) => ({
      id,
      name,
      hasMaxWidth,
      itemComponent: tabComponent,
      isDisabled: isDisabled || isLoading,
      itemComponentProps: tabComponentProps,
    }),
  );
};
