exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2k13EBeQ{display:grid;grid-row-gap:16px;grid-column-gap:11px;grid-template-columns:repeat(auto-fill, minmax(274px, 1fr))}._3xYEHdQE{max-height:300px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/season-team-statistics-ui/containers/TeamStatistics/views/DesktopNarrow/components/GeneralFilter/styles.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,iBAAkB,CAClB,oBAAqB,CACrB,2DAA4D,CALhE,WASI,gBAAiB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .general-filter-group {\n    display: grid;\n    grid-row-gap: 16px;\n    grid-column-gap: 11px;\n    grid-template-columns: repeat(auto-fill, minmax(274px, 1fr));\n  }\n\n  .search-list-items {\n    max-height: 300px;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"general-filter-group": "_2k13EBeQ",
	"generalFilterGroup": "_2k13EBeQ",
	"search-list-items": "_3xYEHdQE",
	"searchListItems": "_3xYEHdQE"
};