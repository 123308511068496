import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { TopTeamsStatus } from '../../types';
import * as actions from '../../actions';

const defaultState: TopTeamsStatus = {
  isTopTeamsLoading: true,
};

const reducerMap: ReducerMap<TopTeamsStatus, any> = {
  [`${actions.loadTopTeamsStart}`]: () => {
    return { isTopTeamsLoading: true };
  },
  [`${combineActions(actions.loadTopTeamsFailure, actions.loadTopTeamsSuccess)}`]: () => {
    return { isTopTeamsLoading: false };
  },
  [`${actions.cleanTopBoardSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<TopTeamsStatus>(reducerMap, defaultState);
