import isEmpty from 'is-empty-typed/lib';
import DateService from 'packages/date-az';

import { toCamelCase } from 'packages/utils/Object.utils';

const now = DateService.getDate().toISODate();

const getEventsDatePeriod = (events: object[]): [string, string] => {
  if (isEmpty(events)) {
    return [now, now];
  }

  if (events.length < 2) {
    const [event] = events;

    return [getEventDate(event), now];
  }

  const sortedDates = sortDates(events);
  const [firstEvent] = sortedDates;

  return [getEventDate(firstEvent), now];
};

const getEventDate = (event: object): string => {
  const { scheduled } = toCamelCase(event);

  return DateService.parse(scheduled).toISODate();
};

const sortDates = (events: Record<string, any>[]): object[] => {
  return events.sort(({ scheduled: leftEventScheduled }, { scheduled: rightEventScheduled }) => {
    const leftDate = DateService.getDateTimeFromIsoString(leftEventScheduled);
    const rightDate = DateService.getDateTimeFromIsoString(rightEventScheduled);

    if (leftDate > rightDate) {
      return 1;
    } else if (leftDate < rightDate) {
      return -1;
    } else {
      return 0;
    }
  });
};

export default getEventsDatePeriod;
