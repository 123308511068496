import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../../actions';
import { TopOfTheSeasonStatus } from '../../types';

const defaultState: TopOfTheSeasonStatus = {
  isTopOfTheSeasonLoading: true,
};

const reducerMap: ReducerMap<TopOfTheSeasonStatus, any> = {
  [`${actions.loadTopOfTheSeasonStart}`]: () => {
    return { isTopOfTheSeasonLoading: true };
  },
  [`${combineActions(
    actions.loadTopOfTheSeasonFailure,
    actions.loadTopOfTheSeasonSuccess,
  )}`]: () => {
    return { isTopOfTheSeasonLoading: false };
  },
  [`${actions.cleanTopBoardSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<TopOfTheSeasonStatus>(reducerMap, defaultState);
