import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GridColumnId } from 'types';
import { Description, Summary } from 'packages/player-summary-ui';
import { Grid } from 'modules/shared/layouts';
import { EventDetails } from 'packages/event-details-ui';
import { SearchQuery } from 'router/types';

import styles from './styles.scss';
import { ACTUAL_GAMES_LIMIT } from './constants';

const PlayerSummary = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Grid.SingleColumn isSecondaryOpen={!!eventId}>
      <div key={GridColumnId.Primary} className={styles.primary}>
        <div ref={containerRef} className={styles.content}>
          <Summary.Mobile actualGamesLimit={ACTUAL_GAMES_LIMIT} parentContainerRef={containerRef} />
          <Description.Mobile />
        </div>
      </div>

      <div className={styles.popup} key={GridColumnId.Secondary}>
        <EventDetails.Mobile eventId={eventId} />
      </div>
    </Grid.SingleColumn>
  );
};

export default PlayerSummary;
