import { PlayerHappening } from 'packages/event-time-line/types';
import { PlayerHappeningType, SummaryHappeningType } from 'packages/events/types';

const mapPlayerHappening = (happening: PlayerHappening): PlayerHappening => {
  const { eventType } = happening;

  switch (eventType) {
    // case PlayerHappeningType.YELLOW_CARD: {
    //   return { ...happening, eventType: SummaryHappeningType.YELLOW_CARD };
    // }
    // case PlayerHappeningType.RED_CARD: {
    //   return { ...happening, eventType: SummaryHappeningType.RED_CARD };
    // }
    // case PlayerHappeningType.YELLOW_RED_CARD: {
    //   return { ...happening, eventType: SummaryHappeningType.YELLOW_RED_CARD };
    // }
    case PlayerHappeningType.SCORE_CHANGE: {
      return { ...happening, eventType: SummaryHappeningType.SCORE_CHANGE };
    }
    case PlayerHappeningType.SUBSTITUTION_IN: {
      return { ...happening, eventType: SummaryHappeningType.SUBSTITUTION };
    }
    case PlayerHappeningType.SUBSTITUTION_OUT: {
      return { ...happening, eventType: SummaryHappeningType.SUBSTITUTION };
    }
    case PlayerHappeningType.ASSISTS: {
      return { ...happening, eventType: SummaryHappeningType.ASSISTS };
    }
    default: {
      return happening;
    }
  }
};

export default mapPlayerHappening;
