import { toCamelCase } from 'packages/utils/Object.utils';
import { Team } from 'types';

import { Nationality } from '../types';

const mapFilters = (filters: any) => {
  const { teams, playersCountries: nationalities } = toCamelCase(filters);

  return {
    teams: teams.map(mapTeam),
    nationalities: nationalities.map(mapNationality),
  };
};

const mapTeam = (team: any): Team => {
  const { id, name, logo } = toCamelCase(team);

  return { id, name, logo };
};

const mapNationality = (nationality): Nationality => {
  const { id, name, logo } = toCamelCase(nationality);

  return { id, name, logo };
};

export default mapFilters;
