import React, { useCallback, useEffect, memo } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { Tournament } from 'types';

import PopularTournamentsView from './views';
import connect from './connect';

type Props = {
  tournaments: Tournament[];
  favoriteLeagues: Tournament[];
  isPopularTournamentsLoading: boolean;
  isCategoriesLoading: boolean;
  tournamentsActions: ActionCreatorsMapObject;
  favoriteActions: ActionCreatorsMapObject;
};

const extendedPopularTournaments = (
  PopularTournaments: typeof PopularTournamentsView[keyof typeof PopularTournamentsView],
) =>
  memo(
    ({
      tournaments,
      favoriteLeagues,
      isPopularTournamentsLoading,
      tournamentsActions,
      favoriteActions,
      isCategoriesLoading,
    }: Props) => {
      useEffect(() => {
        tournamentsActions.loadPopularTournaments();
      }, []);

      const getFavoriteTournamentToggleHandler = useCallback(
        (league) => () => {
          favoriteActions.toggleLeague(league);
        },
        [],
      );

      return (
        <PopularTournaments
          isCategoriesLoading={isCategoriesLoading}
          tournaments={tournaments}
          favoriteLeagues={favoriteLeagues}
          getFavoriteTournamentToggleHandler={getFavoriteTournamentToggleHandler}
          isPopularTournamentsLoading={isPopularTournamentsLoading}
        />
      );
    },
  );

export default {
  Desktop: connect(extendedPopularTournaments(PopularTournamentsView.Desktop)),
  DesktopNarrow: connect(extendedPopularTournaments(PopularTournamentsView.DesktopNarrow)),
  Tablet: connect(extendedPopularTournaments(PopularTournamentsView.Tablet)),
  TabletNarrow: connect(extendedPopularTournaments(PopularTournamentsView.TabletNarrow)),
  Mobile: connect(extendedPopularTournaments(PopularTournamentsView.Mobile)),
};
