import React, { memo, useMemo } from 'react';
import classnames from 'classnames';

import { FlexibleTable, Skeleton } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';

import { getSkeletonTableSchema } from './services';
import styles from './styles.scss';

type Props = {
  className?: string;
  elementsCount: number;
};

const LoadingSkeleton = ({ className, elementsCount }: Props) => {
  const elements = useMemo(() => ArrayUtils.getArrayFromNumber(elementsCount), [elementsCount]);
  const tableSchema = useMemo(getSkeletonTableSchema, []);

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.header}>
        <Skeleton variant="rectangular" width={120} height={16} />
      </div>
      <FlexibleTable
        className={classnames(styles.table, className)}
        headerClassName={styles.header}
        rowClassName={styles.tableRow}
        columnClassName={styles.tableColumn}
        columns={tableSchema}
        data={elements}
        isColumnsSticky
        isRowClickable
        isHeaderSticky
      />
    </div>
  );
};

export default memo(LoadingSkeleton);
