import { Duration } from 'luxon';

interface Params {
  isShort?: boolean;
  time: Duration;
}

const getLiveTimeView = ({ isShort, time }: Params) => {
  const { minutes, seconds } = time;

  if (isShort) {
    return `${minutes + 1}'`;
  }

  const minuteView = minutes < 10 ? `0${minutes}` : minutes;
  const secondView = seconds < 10 ? `0${seconds}` : seconds;

  return `${minuteView}:${secondView}`;
};

export default getLiveTimeView;
