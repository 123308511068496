import instance from '../../../axios';

import { LoadUniqueTournaments } from '../types';

export const loadUniqueTournaments = async ({ ids, activeSportId }: LoadUniqueTournaments) => {
  return instance({
    method: 'get',
    url: '/unique_tournaments/',
    params: {
      id: ids.toString(),
      sport_id: activeSportId,
    },
  });
};
