import React, { useCallback, memo } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { Player } from 'packages/season-player-statistics/types';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { useNavigation } from 'packages/hooks';
import { Enums, Interfaces } from 'packages/team-player-statistics/types';
import { DesktopPage } from 'router/types';
import { Team } from 'types';

import { MainScreen, GeneralFilter } from './components';
import { ExtendedFilter } from '../../components';
import styles from './styles.scss';

type Props = {
  customFilterActions: ActionCreatorsMapObject;
  tournaments?: SeasonsExtendedTournament[];
  customFilters: Interfaces.CustomFilter.CustomFilterState;
  playersStatistics: Interfaces.PlayerStatistics.PlayerStatistics[];
  filters: Interfaces.Filters.Filters;
  loadPlayersPage: () => void;
  onCustomFilterApply: () => void;
  onCustomFilterCancel: () => void;
  onOrderingChange: (columnType: Enums.ColumnType) => void;
  onPaginationChange: ({ offset, limit }) => void;
  onCustomizeButtonClick: () => void;
  isFiltersLoading: boolean;
  isInitialPlayersPageLoading: boolean;
  isNewPlayersPageLoading: boolean;
  isExtendedFilterOpen: boolean;
  limit: number;
  count: number;
};

const PlayerStatistics = ({
  filters,
  customFilters,
  customFilterActions,
  playersStatistics,
  tournaments = [],
  isFiltersLoading,
  isInitialPlayersPageLoading,
  isNewPlayersPageLoading,
  isExtendedFilterOpen,
  limit,
  count,
  loadPlayersPage,
  onCustomFilterApply,
  onCustomFilterCancel,
  onOrderingChange,
  onPaginationChange,
  onCustomizeButtonClick,
}: Props) => {
  const { teamId, tournamentId, seasonId } = useParams();
  const { appNavigate } = useNavigation();

  const handleTeamClick = useCallback((team: Team) => {
    appNavigate(DesktopPage.FootballTeam, { teamId: team.id });
  }, []);

  const handlePlayerClick = useCallback((player: Player) => {
    appNavigate(DesktopPage.FootballPlayer, { playerId: player.id });
  }, []);

  const handleTournamentChange = useCallback(
    (tournamentId: number | string) => {
      if (teamId && tournamentId) {
        const [firstSelectedTournamentSeason] =
          tournaments.find(({ id }) => id === tournamentId)?.seasons || [];

        appNavigate(DesktopPage.FootballTeamPlayerStatistics, {
          uniqueTournamentId: tournamentId,
          seasonId: firstSelectedTournamentSeason.id,
          teamId,
        });
      }
    },
    [teamId, tournaments, teamId],
  );

  const handleSeasonChange = useCallback(
    (seasonId: number | string) => {
      if (teamId && tournamentId) {
        appNavigate(DesktopPage.FootballTeamPlayerStatistics, {
          uniqueTournamentId: tournamentId,
          seasonId,
          teamId,
        });
      }
    },
    [tournamentId, teamId],
  );

  const handlePresetChange = useCallback(
    (presetType: Enums.PresetType) => {
      customFilterActions.updateFilterPreset(presetType);
      loadPlayersPage();
    },
    [loadPlayersPage],
  );

  const handleCustomFilterUpdate = useCallback(
    (updates: Interfaces.CustomFilter.CustomFilterState) => {
      customFilterActions.updateCustomFilter(updates);
      loadPlayersPage();
    },
    [loadPlayersPage],
  );

  return (
    <div className={styles.playerStatisticsContainer}>
      <ExtendedFilter
        tournaments={tournaments}
        GeneralFilterComponent={GeneralFilter}
        onCustomFilterUpdate={handleCustomFilterUpdate}
        onCancel={onCustomFilterCancel}
        onApply={onCustomFilterApply}
        customFilters={customFilters}
        isLoading={isFiltersLoading}
        filters={filters}
        isOpen={isExtendedFilterOpen}
        className={styles.extendedFilter}
      />
      <MainScreen
        tournaments={tournaments}
        selectedTournamentId={parseInt(tournamentId || '')}
        selectedSeasonId={parseInt(seasonId || '')}
        onTournamentChange={handleTournamentChange}
        onSeasonChange={handleSeasonChange}
        onTeamClick={handleTeamClick}
        onPlayerClick={handlePlayerClick}
        onCustomizeButtonClick={onCustomizeButtonClick}
        onOrderingChange={onOrderingChange}
        onPresetChange={handlePresetChange}
        onPaginationChange={onPaginationChange}
        amountOfPlayers={count}
        currentPageSize={limit}
        playersStatistics={playersStatistics}
        isInitialPlayersPageLoading={isInitialPlayersPageLoading}
        isNewPlayersPageLoading={isNewPlayersPageLoading}
        activeStatsTab={customFilters.generalFilter.activeStatsTab}
        activePreset={customFilters.columnConfigurationFilter.activePreset}
        columns={customFilters.columnConfigurationFilter.columns}
        ordering={customFilters.columnConfigurationFilter.configurableOrdering}
        className={styles.tableContainer}
      />
    </div>
  );
};

export default memo(PlayerStatistics);
