exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3xRw85c4{position:absolute;height:100%;width:100%;overflow:hidden}._2vyJHiuj{flex:1 1;flex-basis:0;z-index:1;overflow-y:scroll;scrollbar-width:none;-ms-overflow-style:none}._2vyJHiuj::-webkit-scrollbar{display:none;background:transparent;width:0;height:0}._2vyJHiuj ._1EVlX6ea{font-weight:500}._2vyJHiuj ._QSzKZ7Ky{z-index:5}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/team-squad-ui/containers/TeamSquad/components/PlayersTable/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/scroll.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,iBAAkB,CAClB,WAAY,CACZ,UAAW,CACX,eAAgB,CALpB,WASI,QAAO,CACP,YAAa,CACb,SAAU,CCXV,iBAAkB,CAKpB,oBAAqB,CACrB,uBAAwB,CDN1B,8BCUI,YAAa,CACb,sBAAuB,CACvB,OAAQ,CACR,QAAS,CDbb,sBAgBM,eAAgB,CAhBtB,sBAoBM,SAAU","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .loader-container {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n    overflow: hidden;\n  }\n\n  .table {\n    flex: 1;\n    flex-basis: 0;\n    z-index: 1;\n\n    @include hidden-scroll;\n\n    .column {\n      font-weight: 500;\n    }\n\n    .header {\n      z-index: 5;\n    }\n  }\n}\n","@mixin hidden-scroll($axis: y) {\n  @if $axis == y {\n    overflow-y: scroll;\n  } @else {\n    overflow-x: scroll;\n  }\n\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* Internet Explorer 10+ */\n\n  &::-webkit-scrollbar {\n    /* WebKit */\n    display: none;\n    background: transparent;\n    width: 0;\n    height: 0;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"loader-container": "_3xRw85c4",
	"loaderContainer": "_3xRw85c4",
	"table": "_2vyJHiuj",
	"column": "_1EVlX6ea",
	"header": "_QSzKZ7Ky"
};