import { TabletPage } from '../types';

export const ROOT = {
  [TabletPage.Home]: '/',
  [TabletPage.Settings]: '/settings',
  [TabletPage.Football]: '/football',
  [TabletPage.FootballSeason]: '/football/unique-tournament/:tournamentId/season/:seasonId',
  [TabletPage.FootballTeam]: '/football/team/:teamId',
  [TabletPage.FootballFavorites]: '/football/favorites',
  [TabletPage.FootballCategories]: '/football/categories',
  [TabletPage.FootballCategory]: '/football/category/:categoryId',
  [TabletPage.FootballPlayer]: '/football/player/:playerId',
  [TabletPage.FootballStadium]: '/football/stadium/:stadiumId',
  [TabletPage.FootballMatch]: '/football/match/:eventId',
  [TabletPage.NotFound]: '*',
};

export const SEASON_CHILDREN = {
  [TabletPage.FootballSeasonHome]: '',
  [TabletPage.FootballSeasonSummary]: 'summary',
  [TabletPage.FootballSeasonGames]: 'games',
  [TabletPage.FootballSeasonStandings]: 'standings',
  [TabletPage.FootballSeasonTeamStatistics]: 'team-statistics',
  [TabletPage.FootballSeasonPlayerStatistics]: 'player-statistics',
  [TabletPage.FootballSeasonStadiums]: 'stadiums',
  [TabletPage.FootballSeasonHistory]: 'history',
  [TabletPage.FootballSeasonComingSoon]: '*',
};

export const STADIUM_CHILDREN = {
  [TabletPage.FootballStadiumHome]: '',
  [TabletPage.FootballStadiumSummary]: 'unique-tournament/:tournamentId/season/:seasonId',
};

export const TEAM_CHILDREN = {
  [TabletPage.FootballTeamHome]: '',
  [TabletPage.FootballTeamSummary]: 'summary',
  [TabletPage.FootballTeamSummaryDescription]:
    'summary/unique-tournament/:tournamentId/season/:seasonId',
  [TabletPage.FootballTeamStandings]: 'standings/unique-tournament/:tournamentId/season/:seasonId',
  [TabletPage.FootballTeamPlayerStatistics]:
    'player-statistics/unique-tournament/:tournamentId/season/:seasonId',
  [TabletPage.FootballTeamGames]: 'games',
  [TabletPage.FootballTeamSquad]: 'squad',
};

export const PLAYER_CHILDREN = {
  [TabletPage.FootballPlayerHome]: '',
  [TabletPage.FootballPlayerSummary]: 'summary',
  [TabletPage.FootballPlayerGames]: 'games',
};
