import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { mainCategorySelectors, mainCategoryThunks } from 'packages/categories';

const mapStateToProps = (state) => {
  return {
    mainCategory: mainCategorySelectors.getData(state),
    isLoading: mainCategorySelectors.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mainCategoryActions: bindActionCreators(mainCategoryThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
