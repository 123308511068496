export enum EventType {
  Goal = 'score_change',
  YellowCard = 'yellow_card',
  RedCard = 'red_card',
  YellowRedCard = 'yellow_red_card',
  PenaltyMissed = 'penalty_missed',
}

export enum PlayerType {
  Scorer = 'scorer',
  Assist = 'assist',
  Player = 'player',
}
