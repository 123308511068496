import React from 'react';
import i18n from 'i18n-smart';

import { Button, Link } from 'packages/shared/components';
import { ButtonType } from 'packages/shared/components/Button/types';

import styles from './styles.scss';

type Props = {
  customRangeLabel: string;
  onCustomRangeClick: () => void;
  onApplyClick: () => void;
  onCancelClick: () => void;
  closePopover?: () => void;
};

const ConfirmationBlock = ({
  customRangeLabel,
  onApplyClick,
  onCancelClick,
  onCustomRangeClick,
  closePopover,
}: Props) => {
  const handleApplyClick = () => {
    onApplyClick();
    closePopover?.();
  };

  const handleCancelClick = () => {
    onCancelClick();
    closePopover?.();
  };

  const handleCustomRangeClick = () => {
    onCustomRangeClick();
    closePopover?.();
  };

  return (
    <div className={styles.confirmationBlock}>
      <Link onClick={handleCustomRangeClick}>{i18n.value(customRangeLabel)}</Link>
      <div className={styles.rightButtons}>
        <Button type={ButtonType.Secondary} onClick={handleCancelClick}>
          common.cancel
        </Button>
        <Button type={ButtonType.Primary} onClick={handleApplyClick}>
          common.apply
        </Button>
      </div>
    </div>
  );
};

export default React.memo(ConfirmationBlock);
