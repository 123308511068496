import i18n from 'i18n-smart';

import { SelectItem } from 'packages/shared/components/Select/types';
import { PreferredFoot } from 'packages/season-player-statistics/types';

const getFootSelectItems = (): SelectItem[] => [
  {
    id: PreferredFoot.All,
    label: i18n.value('season.playerStatistics.customFilter.general.footSelectItem.all'),
  },
  {
    id: PreferredFoot.Both,
    label: i18n.value('season.playerStatistics.customFilter.general.footSelectItem.both'),
  },
  {
    id: PreferredFoot.Left,
    label: i18n.value('season.playerStatistics.customFilter.general.footSelectItem.left'),
  },
  {
    id: PreferredFoot.Right,
    label: i18n.value('season.playerStatistics.customFilter.general.footSelectItem.right'),
  },
];

export default getFootSelectItems;
