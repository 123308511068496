const IntegerParameterCell = (value: number | null) => {
  const localeWithSpaceGrouping = 'fr-FR';

  if (value) {
    return value.toLocaleString(localeWithSpaceGrouping, { maximumFractionDigits: 0 });
  }

  return 0;
};

export default IntegerParameterCell;
