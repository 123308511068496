import { PlayersOrderingParameter } from 'packages/rest/api/SeasonTopBoard/types';

import { PlayersParameter, TabMode } from '../types';

export const PLAYER_DEFAULT_ORDERING = {
  [TabMode.Goals]: PlayersParameter.Goals,
  [TabMode.Attack]: PlayersParameter.TotalShots,
  [TabMode.Aggression]: PlayersParameter.YellowCards,
};

export const PLAYER_API_ORDERING_BY_PARAMETERS = {
  [PlayersParameter.Goals]: [PlayersOrderingParameter.Goals, PlayersOrderingParameter.Assists],
  [PlayersParameter.Assists]: [PlayersOrderingParameter.Assists, PlayersOrderingParameter.Goals],
  [PlayersParameter.GoalPoints]: [PlayersOrderingParameter.GoalPoints],
  [PlayersParameter.Offside]: [PlayersOrderingParameter.Offside],
  [PlayersParameter.GamesPlayed]: [PlayersOrderingParameter.GamesPlayed],
  [PlayersParameter.TotalShots]: [
    PlayersOrderingParameter.TotalShots,
    PlayersOrderingParameter.ShotsOnGoal,
  ],
  [PlayersParameter.ShotsOnGoal]: [
    PlayersOrderingParameter.ShotsOnGoal,
    PlayersOrderingParameter.TotalShots,
  ],
  [PlayersParameter.YellowRedCards]: [
    PlayersOrderingParameter.YellowRedCards,
    PlayersOrderingParameter.RedCards,
    PlayersOrderingParameter.YellowCards,
  ],
  [PlayersParameter.RedCards]: [
    PlayersOrderingParameter.RedCards,
    PlayersOrderingParameter.YellowRedCards,
    PlayersOrderingParameter.YellowCards,
  ],
  [PlayersParameter.YellowCards]: [
    PlayersOrderingParameter.YellowCards,
    PlayersOrderingParameter.RedCards,
    PlayersOrderingParameter.YellowRedCards,
  ],
};
