import { LoadSquadParams } from 'packages/team-squad/types';

import { squadSelectors } from 'packages/team-squad';
import { TeamSquad } from 'packages/rest';
import { mapSquad } from 'packages/team-squad/services';

import { squadActions } from '../actions';

const loadSquadPage = ({ ordering, teamId }: LoadSquadParams) => async (dispatch, getState) => {
  try {
    dispatch(squadActions.loadPageStart());

    const offset = squadSelectors.getOffset(getState());
    const limit = squadSelectors.getLimit(getState());

    const { data } = await TeamSquad.loadTeamSquad({ ordering, teamId, limit, offset });

    const players = mapSquad(data.results);

    dispatch(squadActions.updateCountSuccess({ count: data.count }));

    dispatch(squadActions.loadPageSuccess(players));
  } catch (e) {
    dispatch(squadActions.loadPageSuccess());
  }
};

export default loadSquadPage;
