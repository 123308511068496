import React, { memo, useEffect, useMemo, useRef } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import { LoaderContainer } from 'packages/shared/components';

import { DetailsStatistics } from '../../../../components';
import { Standings, TopPlayers } from './components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  isLoading: boolean;
  detailsActions: ActionCreatorsMapObject;
  className?: string;
};

const Details = ({ isLoading, detailsActions, className }: Props) => {
  const { teamId = '', seasonId = '' } = useParams();
  const topBlockRef = useRef<HTMLDivElement>(null);
  const activeTeamIds = useMemo(() => [parseInt(teamId)], [teamId]);

  useEffect(() => {
    detailsActions.loadDetails({ seasonId: parseInt(seasonId), teamId: parseInt(teamId) });
  }, [seasonId, teamId]);

  return (
    <LoaderContainer className={styles.loadingContainer} isLoading={isLoading}>
      <div className={classnames(styles.detailsContainer, className)}>
        <Standings activeTeamIds={activeTeamIds} containerRef={topBlockRef} />
        <div className={classnames(styles.block, styles.topPlayersContainer)}>
          <TopPlayers />
        </div>
        <div className={classnames(styles.block, styles.middleBlock)}>
          <DetailsStatistics useSingleColumn />
        </div>
      </div>
    </LoaderContainer>
  );
};

export default connect(memo(Details));
