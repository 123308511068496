import React, { memo } from 'react';

import { Figure } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  logo?: string;
};

const PlayerCell = ({ logo }: Props) => {
  return (
    <div className={styles.cellContainer}>
      <Figure src={logo} className={styles.playerLogo} />
    </div>
  );
};
export default memo(PlayerCell);
