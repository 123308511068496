import { scheduledEventsActions } from '../../actions';
import { LiveEventsUpdateEventType } from '../../types/enums';
import { updateScheduledEvents } from './updateScheduledEvents';

export const subscribeForScheduledEventsUpdates = () => (dispatch) => {
  try {
    dispatch(scheduledEventsActions.subscribeForScheduledEventsUpdatesStart());

    const handleUpdatedData = (event) => {
      const { detail } = event as CustomEvent;

      dispatch(updateScheduledEvents(detail));
    };

    document.addEventListener(LiveEventsUpdateEventType.Updated, handleUpdatedData);
    document.addEventListener(LiveEventsUpdateEventType.Inserted, handleUpdatedData);

    dispatch(scheduledEventsActions.subscribeForScheduledEventsUpdatesSuccess());

    return () => {
      document.removeEventListener(LiveEventsUpdateEventType.Updated, handleUpdatedData);
      document.removeEventListener(LiveEventsUpdateEventType.Inserted, handleUpdatedData);
    };
  } catch (error) {
    dispatch(scheduledEventsActions.subscribeForScheduledEventsUpdatesFailure(error));
  }
};
