import React from 'react';
import i18n from 'i18n-smart';
import classes from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import { OddsGrid, OddItem } from './components';
import styles from './styles.scss';

type Props = {
  className?: string;
};

const Odds = ({ className }: Props) => {
  const containerClasses = classes(styles.odds, className);

  return (
    <div className={containerClasses}>
      <div className={styles.caption}>
        <div className={styles.captionTitle}>{i18n.value('eventSummary.odds.header')}</div>
        <div className={styles.captionText}>
          {i18n.value('eventSummary.odds.sponsoredBy')}
          {/*<Icon id={IconType.OlimpLogo} className={styles.captionLogo} />*/}
        </div>
      </div>

      <OddsGrid />

      <div className={styles.list}>
        <OddItem />
      </div>
    </div>
  );
};

export default Odds;
