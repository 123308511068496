import React from 'react';
import i18n from 'i18n-smart';

import { ToggleArrowButton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  onClick: (event) => void;
  isOpen: boolean;
};

const ExpandButton = ({ onClick, isOpen }: Props) => {
  return (
    <div className={styles.button} onClick={onClick}>
      <div className={styles.label}>
        {i18n.value(isOpen ? 'toggleButton.hide' : 'toggleButton.show')}
      </div>
      <ToggleArrowButton isOpen={isOpen} className={styles.arrow} />
    </div>
  );
};

export default React.memo(ExpandButton);
