import React, { memo, useMemo } from 'react';
import classnames from 'classnames';

import { FlexibleTable } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';

import { getSkeletonTableSchema } from './services';

import styles from './styles.scss';

type Props = {
  className?: string;
  elementsCount: number;
};

const PlayerStatisticsTable = ({ className, elementsCount }: Props) => {
  const elements = ArrayUtils.getArrayFromNumber(elementsCount);
  const tableSchema = useMemo(() => getSkeletonTableSchema(), []);

  return (
    <FlexibleTable
      className={classnames(styles.table, className)}
      headerClassName={styles.header}
      rowClassName={styles.tableRow}
      columnClassName={styles.tableColumn}
      columns={tableSchema}
      data={elements}
      isColumnsSticky
      isRowClickable
      isHeaderSticky
    />
  );
};

export default memo(PlayerStatisticsTable);
