import React, { memo } from 'react';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { PlayerStatistics } from 'packages/season-player-statistics-ui';
import { Advertisement } from 'packages/shared/components';
import { FeaturedOdds } from 'packages/odds-ui';

import styles from './styles.scss';

const SeasonPlayerStatistics = () => {
  return (
    <Grid.DoubleColumn
      isSecondaryOpen={false}
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
    >
      <div key={GridColumnId.LeftBar} className={styles.leftBar}>
        <PlayerStatistics.DesktopNarrow />
      </div>
      <div key={GridColumnId.Primary} className={styles.sideBar}>
        <Advertisement />
        <FeaturedOdds.DesktopNarrow />
      </div>
    </Grid.DoubleColumn>
  );
};

export default memo(SeasonPlayerStatistics);
