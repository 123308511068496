import React, { memo } from 'react';
import classnames from 'classnames';

import { CardHappening } from 'packages/event-stats/types';
import { isEmpty } from 'packages/utils/Object.utils';
import { Icon } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { CARD_ICON_TYPE } from 'appConstants';
import { DesktopPage } from 'router/types';

import styles from './styles.scss';

type Props = {
  happening: CardHappening;
  isAway?: boolean;
};

const HappeningLine = ({ happening, isAway = false }: Props) => {
  const { minute, players, eventType } = happening;
  const [player] = players;
  const playerName = player?.shortName || player?.name;

  const cardIconType = CARD_ICON_TYPE[eventType];

  const { appNavigate } = useNavigation();

  const handlePlayerClick = () => {
    appNavigate(DesktopPage.FootballPlayer, { playerId: player.playerId });
  };

  return (
    <>
      <span className={classnames(styles.minute, { [styles.away]: isAway })}>
        {minute ? `${minute}'` : ''}
      </span>
      <Icon id={cardIconType} className={classnames(styles.icon, { [styles.away]: isAway })} />
      <div className={styles.playerContainer}>
        {!isEmpty(playerName) && (
          <div onClick={handlePlayerClick} className={styles.playerName}>
            {playerName}
          </div>
        )}
      </div>
    </>
  );
};

export default memo(HappeningLine);
