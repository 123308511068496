import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import 'large-small-dynamic-viewport-units-polyfill';

import { appThunks } from 'modules/app';
import configureRest from 'configure/rest';
import configureRestCache from 'configure/restCache';
import configureStorage from 'configure/storage';
import configureDictionary from 'configure/dictionary';
import { SuspenseFallback } from 'packages/shared/components';

import AppRouter from './router';
import configureStore from './store';

const store = configureStore(window['__PRELOADED_STATE__']);

configureStorage();
configureRestCache();
configureRest();
configureDictionary();

const { dispatch } = store;

dispatch(async (dispatch) => {
  const { error } = await dispatch(appThunks.initApp());

  if (error) return;

  ReactDOM.render(
    <Provider store={store}>
      <BrowserRouter>
        <Suspense fallback={<SuspenseFallback />}>
          <AppRouter />
        </Suspense>
      </BrowserRouter>
    </Provider>,
    document.getElementById('root'),
  );
});
