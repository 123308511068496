const buildLabelParameters = (currentPage: number, pageSize: number, amountOfItems) => {
  const startRangeFirstParameter = currentPage * pageSize + 1;
  const startRangeSecondParameter = (currentPage + 1) * pageSize;

  return [
    `${startRangeFirstParameter}-${
      startRangeSecondParameter > amountOfItems ? amountOfItems : startRangeSecondParameter
    }`,
    amountOfItems,
  ];
};

export default buildLabelParameters;
