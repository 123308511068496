import React, { memo } from 'react';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { PlayerStatistics } from 'packages/team-player-statistics-ui';

import styles from './styles.scss';

const SeasonPlayerStatistics = () => {
  return (
    <Grid.SingleColumn isSecondaryOpen={false}>
      <div key={GridColumnId.Primary} className={styles.contentBlock}>
        <PlayerStatistics.Tablet />
      </div>
      <></>
    </Grid.SingleColumn>
  );
};

export default memo(SeasonPlayerStatistics);
