import React from 'react';
import { Column } from 'react-table';

import { ColumnType } from 'packages/season-player-statistics/types';
import { Skeleton } from 'packages/shared/components';

import { PlayerCountrySkeleton } from '../../../../../components';

const centerAlignStyle = {
  display: 'flex',
  justifyContent: 'center',
};

const getSkeletonTableSchema = (): Column[] => [
  {
    id: ColumnType.PlayerRank,
    headerStyle: {},
    cellStyle: { paddingLeft: 8, ...centerAlignStyle },
    Header: () => null,
    Cell: <Skeleton variant="rectangular" height={14} width={8} />,
    width: 40,
  },
  {
    id: ColumnType.PlayerIcon,
    Header: () => null,
    Cell: <Skeleton marginLeft={5} width={32} height={32} variant="circular" />,
    width: 40,
  },
  {
    id: ColumnType.Player,
    Header: () => null,
    Cell: PlayerCountrySkeleton,
    width: 140,
  },
  {
    id: ColumnType.Club,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton width={16} height={16} variant="circular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.GamesPlayed,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.MinutesPlayed,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.Goals,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.Assists,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.GoalsAndAssists,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.Shots,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.ShotsOnGoal,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.Offside,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
];
export default getSkeletonTableSchema;
