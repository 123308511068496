import BaseDifferenceExtractor from './BaseDifferenceExtractor';

class ObjectDifferenceExtractor<ItemType> extends BaseDifferenceExtractor<ItemType, ItemType> {
  public getDataUpdate(item: ItemType, updatedItem: ItemType) {
    const isItemsEqual = this.dataComparisonPredicate(item, updatedItem);
    let updated;
    let added;
    let removed;

    if (!isItemsEqual) {
      if (!item) {
        removed = item;
      } else if (item && !updatedItem) {
        added = updatedItem;
      } else {
        updated = updatedItem;
      }
    }

    return {
      added,
      removed,
      updated,
    };
  }
}

export default ObjectDifferenceExtractor;
