import instance from '../../../axios';
import { PopularTournamentsParams } from '../types';

export const loadPopularTournaments = ({ activeSportId }: PopularTournamentsParams) => {
  return instance({
    method: 'get',
    url: '/unique_tournaments/',
    params: {
      sport_id: activeSportId,
      is_popular: true,
    },
  });
};
