import React, { memo } from 'react';

import { TeamLabel, TournamentTablePositionCell } from 'packages/shared/components';
import { Size } from 'packages/shared/components/TeamLabel/types';
import { Team } from 'types';

import styles from './styles.scss';

type Props = {
  team: Team;
  position: {
    value: string | number;
    cssClass?: string;
  };
};

const TeamCell = ({ team, position }: Props) => {
  return (
    <div className={styles.teamCell}>
      <div className={styles.positionWrapper}>
        <TournamentTablePositionCell {...position} />
      </div>
      <TeamLabel team={team} size={Size.Md} isLogoFirst />
    </div>
  );
};

export default memo(TeamCell);
