import { connect } from 'react-redux';

import { stadiumDetailsSelectors } from 'packages/stadium-details';

const mapStateToProps = (state) => {
  return {
    details: stadiumDetailsSelectors.getData(state),
    isLoading: stadiumDetailsSelectors.getIsLoading(state),
    stadiumDetails: stadiumDetailsSelectors.getData(state),
  };
};

export default connect(mapStateToProps);
