import { CSSProperties, RefObject, useEffect, useState } from 'react';

import { MediaScreen } from 'types';
import { SCREEN_WIDTH } from 'modules/constants';

const DEFAULT_JERSEY_CONTAINER_STYLES: CSSProperties = {
  position: 'absolute',
  right: 0,
  overflow: 'hidden',
};

const JERSEY_STYLE_BY_MEDIA_SCREEN = {
  [MediaScreen.Mobile]: {
    container: {
      ...DEFAULT_JERSEY_CONTAINER_STYLES,
      width: 200,
      top: -103,
    },
    icon: {
      width: 320,
      height: 320,
    },
  },
  [MediaScreen.Tablet]: {
    container: {
      ...DEFAULT_JERSEY_CONTAINER_STYLES,
      width: 480,
      top: -263,
    },
    icon: {
      width: 640,
      height: 640,
    },
  },
  [MediaScreen.Desktop]: {
    container: {
      ...DEFAULT_JERSEY_CONTAINER_STYLES,
      width: 1000,
      top: -530,
    },
    icon: {
      width: 1200,
      height: 1200,
    },
  },
};

type JerseyStyle = {
  container: CSSProperties;
  icon: CSSProperties;
};

const useJerseyHeaderStyle = <T extends HTMLElement>(containerRef: RefObject<T>) => {
  const [jerseyStyle, setJerseyStyle] = useState<JerseyStyle>();

  useEffect(() => {
    if (containerRef.current) {
      const { clientWidth } = containerRef.current;

      if (clientWidth <= SCREEN_WIDTH.MAX_TABLET_NARROW_WIDTH) {
        setJerseyStyle(JERSEY_STYLE_BY_MEDIA_SCREEN[MediaScreen.Mobile]);
      } else if (clientWidth <= SCREEN_WIDTH.MAX_TABLET_WIDTH) {
        setJerseyStyle(JERSEY_STYLE_BY_MEDIA_SCREEN[MediaScreen.Tablet]);
      } else {
        setJerseyStyle(JERSEY_STYLE_BY_MEDIA_SCREEN[MediaScreen.Desktop]);
      }
    }
  }, []);

  return jerseyStyle;
};

export default useJerseyHeaderStyle;
