import { sessionStorage } from 'packages/storage';
import { ACTIVE_TOURNAMENT_SEASON_SESSION_KEY } from 'packages/team-active-tournament-season/constants';
import { getData } from '../selectors';
import { updateTeamActiveTournamentSeasonSuccess } from '../actions';

interface Params {
  seasonId: number;
  tournamentId: number;
  teamId: number;
}

interface Params {
  teamId: number;
  tournamentId: number;
  seasonId: number;
}

const updateTeamActiveTournamentSeason = ({ teamId, tournamentId, seasonId }: Params) => (
  dispatch,
  getState,
) => {
  const activeTournamentSeasons = getData(getState());
  const updatedActiveTournamentSeasons = {
    ...activeTournamentSeasons,
    [teamId]: { tournamentId, seasonId },
  };

  sessionStorage.set(ACTIVE_TOURNAMENT_SEASON_SESSION_KEY, updatedActiveTournamentSeasons);

  dispatch(updateTeamActiveTournamentSeasonSuccess(updatedActiveTournamentSeasons));
};

export default updateTeamActiveTournamentSeason;
