import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { teamsActions, commonActions } from '../../actions';

const defaultState = [];

const reducerMap: ReducerMap<any, any> = {
  [`${combineActions(teamsActions.loadInitialTeamsSuccess, teamsActions.loadTeamsPageSuccess)}`]: (
    state,
    { payload },
  ) => {
    const { teams } = payload;

    return teams;
  },
  [`${combineActions(
    teamsActions.loadInitialTeamsFailure,
    commonActions.cleanTeamStatistics,
    teamsActions.cleanTeams,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
