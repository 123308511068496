exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1nitpS59{height:100%}._3iEXfJjg{padding:0 16px 16px}._3iEXfJjg ._2JQbzg2P,._3iEXfJjg ._1HbG_vxi>*:not(:last-of-type){margin-bottom:24px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/modules/Statistics/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,WAAY,CAFhB,WAMI,mBCRU,CDEd,iEAUM,kBAAmB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .loader {\n    height: 100%;\n  }\n\n  .event-stats {\n    padding: 0 $padding $padding;\n\n    .timelines-container,\n    .stats > *:not(:last-of-type) {\n      margin-bottom: 24px;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"loader": "_1nitpS59",
	"event-stats": "_3iEXfJjg",
	"eventStats": "_3iEXfJjg",
	"timelines-container": "_2JQbzg2P",
	"timelinesContainer": "_2JQbzg2P",
	"stats": "_1HbG_vxi"
};