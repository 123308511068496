import { EventStandings } from 'packages/rest';

import { mapStandings, getActiveStageIdByTeamId } from '../../../services';
import { detailsActions } from '../../../actions';

const { standings } = detailsActions;

interface Params {
  teamId?: number;
  seasonId: number;
}

const loadStandings = ({ seasonId, teamId }: Params) => async (dispatch) => {
  try {
    dispatch(standings.loadStandingsStart());

    const [
      { data: seasonTablesStagesResponse },
      { data: tournamentBracketsResponse },
    ] = await Promise.all([
      EventStandings.loadSeasonTables({ seasonId }),
      EventStandings.loadEventBrackets({ seasonId }),
    ]);

    const stages = mapStandings({
      seasonTablesStages: seasonTablesStagesResponse.results,
      tournamentBrackets: tournamentBracketsResponse.results,
    });
    const activeStageId = teamId ? getActiveStageIdByTeamId(stages, teamId) : undefined;

    dispatch(standings.loadStandingsSuccess({ stages, activeStageId }));
  } catch (e) {
    dispatch(standings.loadStandingsFailure(e));
  }
};

export default loadStandings;
