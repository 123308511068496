import { OrderType } from 'types';

export interface FiltersParams {
  seasonId: number;
}

export interface SeasonTopPlayersParams {
  seasonId: number;
  limit?: number;
  offset?: number;
  teams?: (string | number)[];
  positions?: string[];
  nationalities?: (string | number)[];
  preferredFoot?: PreferredFoot;
  ordering: Ordering;
  parameterRanges?: ParameterRanges;
}

export type Ordering = Partial<Record<OrderingKey, OrderType>>;
export type ParameterRanges = Partial<Record<RangeParameter, string>>;

export enum OrderingKey {
  PlayerName = 'player__name',
  Assists = 'statistics__assists__total',
  Goals = 'statistics__goals_scored__total',
  GoalsAndAssists = 'statistics__goal_points__total',
  HeadedGoals = 'statistics__goals_by_head__total',
  PenaltyGoals = 'statistics__goals_by_penalty__total',
  Corners = 'statistics__corner_kicks__total',
  Shots = 'statistics__total_shots__total',
  GamesPlayed = 'statistics__matches_played__total',
  YellowCards = 'statistics__yellow_cards__total',
  YellowRedCards = 'statistics__yellow_red_cards__total',
  RedCards = 'statistics__red_cards__total',
  CleanSheets = 'statistics__clean_sheets__total',
  ShotsBlocked = 'statistics__shots_blocked__total',
  ShotsOnGoal = 'statistics__shots_on_target__total',
  MinutesPlayed = 'statistics__minutes_played__total',
  ShotsOffGoal = 'statistics__shots_off_target__total',
  SubstitutedOut = 'statistics__substituted_out__total',
  GoalsConversion = 'statistics__goals_conversion_total',
  Offside = 'statistics__offsides__total',

  AssistsPerNinety = 'statistics__assists__per90',
  GoalsPerNinety = 'statistics__goals_scored__per90',
  GoalsAndAssistsPerNinety = 'statistics__goal_points__per90',
  HeadedGoalsPerNinety = 'statistics__goals_by_head__per90',
  PenaltyGoalsPerNinety = 'statistics__goals_by_penalty__per90',
  CornersPerNinety = 'statistics__corner_kicks__per90',
  ShotsPerNinety = 'statistics__total_shots__per90',
  YellowCardsPerNinety = 'statistics__yellow_cards__per90',
  YellowRedCardsPerNinety = 'statistics__yellow_red_cards__per90',
  RedCardsPerNinety = 'statistics__red_cards__per90',
  CleanSheetsPerNinety = 'statistics__clean_sheets__per90',
  ShotsBlockedPerNinety = 'statistics__shots_blocked__per90',
  ShotsOnGoalPerNinety = 'statistics__shots_on_target__per90',
  ShotsOffGoalPerNinety = 'statistics__shots_off_target__per90',
  SubstitutedOutPerNinety = 'statistics__substituted_out__per90',
  GoalsConversionPerNinety = 'statistics__goals_conversion_total',
  OffsidePerNinety = 'statistics__offsides__per90',

  AssistsPerGame = 'statistics__assists__average',
  GoalsPerGame = 'statistics__goals_scored__average',
  GoalsAndAssistsPerGame = 'statistics__goal_points_average',
  HeadedGoalsPerGame = 'statistics__goals_by_head__average',
  PenaltyGoalsPerGame = 'statistics__goals_by_penalty__average',
  CornersPerGame = 'statistics__corner_kicks__average',
  ShotsPerGame = 'statistics__total_shots_average',
  YellowCardsPerGame = 'statistics__yellow_cards__average',
  YellowRedCardsPerGame = 'statistics__yellow_red_cards__average',
  RedCardsPerGame = 'statistics__red_cards__average',
  CleanSheetsPerGame = 'statistics__clean_sheets__average',
  ShotsBlockedPerGame = 'statistics__shots_blocked__average',
  ShotsOnGoalPerGame = 'statistics__shots_on_target__average',
  ShotsOffGoalPerGame = 'statistics__shots_off_target__average',
  SubstitutedOutPerGame = 'statistics__substituted_out__average',
  GoalsConversionPerGame = 'statistics__goals_conversion_total',
  OffsidePerGame = 'statistics__offsides__average',
  MinutesPlayedPerGame = 'statistics__minutes_played__average',
}

export enum RangeType {
  MinGoals = 'minGoals',
  MaxGoals = 'maxGoals',
  MinGoalsAndAssists = 'minGoalsAndAssists',
  MaxGoalsAndAssists = 'maxGoalsAndAssists',
  MinHeadedGoals = 'minHeadedGoals',
  MaxHeadedGoals = 'maxHeadedGoals',
  MinPenaltyGoals = 'minPenaltyGoals',
  MaxPenaltyGoals = 'maxPenaltyGoals',
  MinShots = 'minShots',
  MaxShots = 'maxShots',
  MinShotsOffGoal = 'minShotsOffGoal',
  MaxShotsOffGoal = 'maxShotsOffGoal',
  MinShotsOnGoal = 'minShotsOnGoal',
  MaxShotsOnGoal = 'maxShotsOnGoal',
  MinGoalConversation = 'minGoalConversation',
  MaxGoalConversation = 'maxGoalConversation',
  MinOffside = 'minOffside',
  MaxOffside = 'maxOffside',
  MinYellowCards = 'minYellowCards',
  MaxYellowCards = 'maxYellowCards',
  MinYellowRedCards = 'minYellowRedCards',
  MaxYellowRedCards = 'maxYellowRedCards',
  MinRedCards = 'minRedCards',
  MaxRedCards = 'maxRedCards',
  MinCleanSheets = 'minCleanSheets',
  MaxCleanSheets = 'maxCleanSheets',
  MinAssists = 'minAssists',
  MaxAssists = 'maxAssists',
  MinGamesPlayed = 'minGamesPlayed',
  MaxGamesPlayed = 'maxGamesPlayed',
  MinStarted = 'minStarted',
  MaxStarted = 'maxStarted',
  MinMinutesPlayed = 'minMinutesPlayed',
  MaxMinutesPlayed = 'maxMinutesPlayed',
  MinDateOfBirth = 'minDateOfBirth',
  MaxDateOfBirth = 'maxDateOfBirth',
}

export enum RangeParameter {
  MinGamesPlayed = 'min_matches_played',
  MaxGamesPlayed = 'max_matches_played',
  MinDateOfBirth = 'min_birthday',
  MaxDateOfBirth = 'max_birthday',

  MinGoals = 'min_goals_scored',
  MaxGoals = 'max_goals_scored',
  MinYellowCards = 'min_yellow_cards',
  MaxYellowCards = 'max_yellow_cards',
  MinYellowRedCards = 'min_yellow_red_cards',
  MaxYellowRedCards = 'max_yellow_red_cards',
  MinRedCards = 'min_red_cards',
  MaxRedCards = 'max_red_cards',
  MinOffside = 'min_offsides',
  MaxOffside = 'max_offsides',
  MinShotsOffGoal = 'min_shots_off_target',
  MaxShotsOffGoal = 'max_shots_off_target',
  MinShotsOnGoal = 'min_shots_on_target',
  MaxShotsOnGoal = 'max_shots_on_target',
  MinPenaltyGoals = 'min_goals_by_penalty',
  MaxPenaltyGoals = 'max_goals_by_penalty',
  MinAssists = 'min_assists',
  MaxAssists = 'max_assists',
  MinGoalsAndAssists = 'min_goal_points',
  MaxGoalsAndAssists = 'max_goal_points',
  MinHeadedGoals = 'min_goals_by_head',
  MaxHeadedGoals = 'max_goals_by_head',
  MinShots = 'min_total_shots',
  MaxShots = 'max_total_shots',
  MinCleanSheets = 'min_clean_sheets',
  MaxCleanSheets = 'max_clean_sheets',
  MinGoalConversation = 'min_goal_conversation',
  MaxGoalConversation = 'max_goal_conversation',
  MinMinutesPlayed = 'min_minutes_played',
  MaxMinutesPlayed = 'max_minutes_played',

  MinGoalsPerNinety = 'min_per_90_goals_scored',
  MaxGoalsPerNinety = 'max_per_90_goals_scored',
  MinGoalsAndAssistsPerNinety = 'min_per_90_goal_points',
  MaxGoalsAndAssistsPerNinety = 'max_per_90_goal_points',
  MinHeadedGoalsPerNinety = 'min_per_90_goals_by_head',
  MaxHeadedGoalsPerNinety = 'max_per_90_goals_by_head',
  MinPenaltyGoalsPerNinety = 'min_per_90_goals_by_penalty',
  MaxPenaltyGoalsPerNinety = 'max_per_90_goals_by_penalty',
  MinShotsPerNinety = 'min_per_90_total_shots',
  MaxShotsPerNinety = 'max_per_90_total_shots',
  MinShotsOffGoalPerNinety = 'min_per_90_shots_off_target',
  MaxShotsOffGoalPerNinety = 'max_per_90_shots_off_target',
  MinShotsOnGoalPerNinety = 'min_per_90_shots_on_target',
  MaxShotsOnGoalPerNinety = 'max_per_90_shots_on_target',
  MinGoalConversationPerNinety = 'min_per_90_goal_conversation',
  MaxGoalConversationPerNinety = 'max_per_90_goal_conversation',
  MinOffsidePerNinety = 'min_per_90_offsides',
  MaxOffsidePerNinety = 'max_per_90_offsides',
  MinYellowCardsPerNinety = 'min_per_90_yellow_cards',
  MaxYellowCardsPerNinety = 'max_per_90_yellow_cards',
  MinYellowRedCardsPerNinety = 'min_per_90_yellow_red_cards',
  MaxYellowRedCardsPerNinety = 'max_per_90_yellow_red_cards',
  MinRedCardsPerNinety = 'min_per_90_red_cards',
  MaxRedCardsPerNinety = 'max_per_90_red_cards',
  MinCleanSheetsPerNinety = 'min_per_90_clean_sheets',
  MaxCleanSheetsPerNinety = 'max_per_90_clean_sheets',
  MinAssistsPerNinety = 'min_per_90_assists',
  MaxAssistsPerNinety = 'max_per_90_assists',

  MinGoalsPerGame = 'min_average_goals_scored',
  MaxGoalsPerGame = 'max_average_goals_scored',
  MinGoalsAndAssistsPerGame = 'min_average_goal_points',
  MaxGoalsAndAssistsPerGame = 'max_average_goal_points',
  MinHeadedGoalsPerGame = 'min_average_goals_by_head',
  MaxHeadedGoalsPerGame = 'max_average_goals_by_head',
  MinPenaltyGoalsPerGame = 'min_average_goals_by_penalty',
  MaxPenaltyGoalsPerGame = 'max_average_goals_by_penalty',
  MinShotsPerGame = 'min_per_average_shots',
  MaxShotsPerGame = 'max_per_average_shots',
  MinShotsOffGoalPerGame = 'min_average_shots_off_target',
  MaxShotsOffGoalPerGame = 'max_average_shots_off_target',
  MinShotsOnGoalPerGame = 'min_average_shots_on_target',
  MaxShotsOnGoalPerGame = 'max_average_shots_on_target',
  MinGoalConversationPerGame = 'min_average_goal_conversation',
  MaxGoalConversationPerGame = 'max_average_goal_conversation',
  MinOffsidePerGame = 'min_average_offsides',
  MaxOffsidePerGame = 'max_average_offsides',
  MinYellowCardsPerGame = 'min_average_yellow_cards',
  MaxYellowCardsPerGame = 'max_average_yellow_cards',
  MinYellowRedCardsPerGame = 'min_average_yellow_red_cards',
  MaxYellowRedCardsPerGame = 'max_average_yellow_red_cards',
  MinRedCardsPerGame = 'min_average_red_cards',
  MaxRedCardsPerGame = 'max_average_red_cards',
  MinCleanSheetsPerGame = 'min_average_clean_sheets',
  MaxCleanSheetsPerGame = 'max_average_clean_sheets',
  MinAssistsPerGame = 'min_average_assists',
  MaxAssistsPerGame = 'max_average_assists',
  MinMinutesPlayedPerGame = 'min_average_minutes',
  MaxMinutesPlayedPerGame = 'max_average_minutes',
}

export enum PreferredFoot {
  All = 'all',
  Left = 'left',
  Right = 'right',
  Both = 'both',
}
