import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { tournamentsSelectors, tournamentsThunks } from 'packages/tournaments';
import { favoritesSelectors, favoritesThunks } from 'packages/favorites';
import { categoryListSelectors } from 'packages/categories';

const mapStateToProps = (state) => {
  return {
    tournaments: tournamentsSelectors.getPopularTournaments(state),
    favoriteLeagues: favoritesSelectors.getFavoriteLeagues(state),
    isPopularTournamentsLoading: tournamentsSelectors.getIsPopularTournamentsLoading(state),
    isCategoriesLoading: categoryListSelectors.getIsCategoriesLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    favoriteActions: bindActionCreators(favoritesThunks, dispatch),
    tournamentsActions: bindActionCreators(tournamentsThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
