import instance from 'packages/rest/axios';

import { ActualGamesParams } from '../types';

export const loadActualGames = ({ seasonId, stadiumId }: ActualGamesParams) => {
  return instance({
    method: 'get',
    url: `/seasons/${seasonId}/${stadiumId}/sport_events/`,
  });
};
