import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { PlayerEvents } from 'packages/player-events-ui';
import { EventDetails } from 'packages/event-details-ui';
import { Advertisement } from 'packages/shared/components';
import { FeaturedOdds } from 'packages/odds-ui';
import { SearchQuery } from 'router/types';

import styles from './styles.scss';

const PlayerGames = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  return (
    <Grid.DoubleColumn
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
      isSecondaryOpen={false}
    >
      <div key={GridColumnId.LeftBar} className={styles.leftBar}>
        <PlayerEvents.Desktop />
      </div>
      <div key={GridColumnId.Primary} className={styles.sideBar}>
        {eventId ? (
          <EventDetails.Desktop eventId={eventId} />
        ) : (
          <>
            <Advertisement />
            <FeaturedOdds.Desktop />
          </>
        )}
      </div>
    </Grid.DoubleColumn>
  );
};

export default memo(PlayerGames);
