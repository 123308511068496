import { sessionStorage } from 'packages/storage';
import { OrderType } from 'types';

import { COLUMNS_TO_SKIP_DEFAULT_ORDERING, CUSTOM_PRESET_SESSION_KEY } from '../../constants';
import { Interfaces, Enums } from '../../types';
import { customFilterStateActions } from '../../actions';

const updateCustomFilter = (data: Interfaces.CustomFilter.CustomFilterState) => (dispatch) => {
  const { columnConfigurationFilter } = data;
  const { activePreset, columns } = columnConfigurationFilter;
  const updatedColumnConfigurationFilter = { ...columnConfigurationFilter };

  if (activePreset === Enums.PresetType.Custom) {
    const [topPriorityColumn] = columns.filter(
      ({ type }) => !COLUMNS_TO_SKIP_DEFAULT_ORDERING.includes(type),
    );
    updatedColumnConfigurationFilter.configurableOrdering = topPriorityColumn
      ? { [topPriorityColumn.type]: OrderType.Descending }
      : {};
    sessionStorage.set(CUSTOM_PRESET_SESSION_KEY, updatedColumnConfigurationFilter);
  }

  dispatch(
    customFilterStateActions.customFilterUpdate({
      ...data,
      columnConfigurationFilter: updatedColumnConfigurationFilter,
    }),
  );
};

export default updateCustomFilter;
