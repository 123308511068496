import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { EventDetails } from 'packages/event-details-ui';
import { SearchQuery } from 'router/types';

import styles from './styles.scss';

const SideBar = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  return (
    <div className={styles.popup}>
      <EventDetails.Tablet eventId={eventId} />
    </div>
  );
};

export default SideBar;
