import React, { RefObject, useRef, memo } from 'react';

import { Competitor } from 'types';
import { BaseHappening } from 'packages/event-stats/types';
import { StatsCompareRow } from 'packages/shared/components';

import { HappeningsTimeLines, Header } from './components';
import styles from './styles.scss';

type Props = {
  details: Record<any, any>;
  timeLines?: {
    homeTimeLine: BaseHappening[];
    awayTimeLine: BaseHappening[];
  };
  teams: [Competitor, Competitor];
  isHeaderSticky?: boolean;
};

const EventStats = ({ teams, details, timeLines, isHeaderSticky }: Props) => {
  const containerRef: RefObject<any> = useRef();
  const [homeTeam, awayTeam] = teams;

  return (
    <div ref={containerRef}>
      <Header
        parentRef={containerRef}
        homeTeam={homeTeam}
        awayTeam={awayTeam}
        isSticky={isHeaderSticky}
      />
      <div className={styles.eventStats}>
        <HappeningsTimeLines className={styles.timelinesContainer} happenings={timeLines} />

        <div className={styles.stats}>
          {details.map((parameter) => (
            <StatsCompareRow key={parameter.key} parameter={parameter} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default memo(EventStats);
