import i18n, { configure } from 'i18n-smart';
import pluralizePlugin from 'i18n-smart/lib/plugins/pluralize';

import { LocaleCode } from 'types';

import * as translations from './translations';

export default () => {
  const { en } = translations;

  configure({
    plugins: [pluralizePlugin],
    defaultValues: en,
  });

  i18n.setValues(en, LocaleCode.En);
};
