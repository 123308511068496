import { CommonTableParameter } from 'types';

export enum TeamsParameter {
  GamesPlayed = CommonTableParameter.GamesPlayed,
  GoalsScored = CommonTableParameter.Goals,
  PenaltySuccessCount = CommonTableParameter.PenaltySuccessCount,
  GoalsByHead = CommonTableParameter.GoalsByHead,
  GoalAttempts = CommonTableParameter.GoalAttempts,
  BallPossession = CommonTableParameter.BallPossession,
  ShotsOnGoalPerGame = CommonTableParameter.ShotsOnGoalPerGame,
  ShotsOnGoal = CommonTableParameter.ShotsOnGoal,
  YellowCards = CommonTableParameter.YellowCards,
  YellowRedCards = CommonTableParameter.YellowRedCards,
  RedCards = CommonTableParameter.RedCards,
}
