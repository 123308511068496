import Dexie from 'dexie';

import { keyValueDatabase } from 'packages/storage';
import { Tables, FavoritesTableIndex } from 'configure/storage/types';

import {
  loadActiveFavoritesFromStorageStart,
  loadActiveFavoritesFromStorageFailure,
  loadActiveFavoritesFromStorageSuccess,
} from '../actions';
import { flushFilterClause } from '../services';

type Params = {
  [FavoritesTableIndex.Date]?: string;
  [FavoritesTableIndex.CategoryId]?: number;
};

const loadActiveFavoritesFromStorage = (params: Params = {}) => async (dispatch) => {
  try {
    dispatch(loadActiveFavoritesFromStorageStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);
    const flushedFilterClause = flushFilterClause(params);

    const activeFavorites = await favoritesModel.where(flushedFilterClause).toArray();

    dispatch(loadActiveFavoritesFromStorageSuccess());

    return activeFavorites;
  } catch (e) {
    dispatch(loadActiveFavoritesFromStorageFailure(e));
  }
};

export default loadActiveFavoritesFromStorage;
