import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Team } from 'types';

import styles from './styles.scss';

type Props = {
  teams: [Team, Team];
  aggregationScore: {
    [key: number]: number;
  };
  isLive: boolean;
  theme: 'light' | 'dark';
};

const AggregationScore = ({ aggregationScore, isLive, teams, theme = 'light' }: Props) => {
  const [homeTeam, awayTeam] = teams;
  const homeAggregationScore = aggregationScore[homeTeam.id];
  const awayAggregationScore = aggregationScore[awayTeam.id];

  return (
    <div
      className={classnames(
        styles.aggregationScoreContainer,
        { [styles.live]: isLive },
        styles[theme],
      )}
    >
      <span className={styles.label}>{`${i18n.value(
        'eventDetails.header.aggregationScore',
      )}: `}</span>
      <span className={styles.value}>
        {homeAggregationScore}-{awayAggregationScore}
      </span>
    </div>
  );
};

export default memo(AggregationScore);
