import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { commonActions, filtersActions } from '../../actions';
import { FiltersStatus } from '../../types';

const defaultState: FiltersStatus = {
  isLoading: true,
};

const reducerMap: ReducerMap<FiltersStatus, any> = {
  [`${filtersActions.loadFiltersStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(
    filtersActions.loadFiltersFailure,
    filtersActions.loadFiltersSuccess,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${commonActions.cleanTeamStatistics}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
