exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._177l-Cm-{width:100%}._177l-Cm- ._1Q35ZvPl{width:100%;display:flex;align-items:baseline}._177l-Cm- ._1Q35ZvPl ._1A_vwSNE{flex:1 1}._177l-Cm- ._1Q35ZvPl ._2hTMJ8CU{margin:0 8px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Input/views/TextRange/styles.scss"],"names":[],"mappings":"AAEA,WAEI,UAAW,CAFf,sBAKM,UAAW,CACX,YAAa,CACb,oBAAqB,CAP3B,iCAUQ,QAAO,CAVf,iCAcQ,YAAa","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .text-range-input-container {\n    width: 100%;\n\n    .range-input {\n      width: 100%;\n      display: flex;\n      align-items: baseline;\n\n      .text-input {\n        flex: 1;\n      }\n\n      .dash {\n        margin: 0 8px;\n      }\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"text-range-input-container": "_177l-Cm-",
	"textRangeInputContainer": "_177l-Cm-",
	"range-input": "_1Q35ZvPl",
	"rangeInput": "_1Q35ZvPl",
	"text-input": "_1A_vwSNE",
	"textInput": "_1A_vwSNE",
	"dash": "_2hTMJ8CU"
};