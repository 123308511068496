import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadTournamentListStart = createAction(`${NAMESPACE}LOAD_TOURNAMENT_LIST_START`);
export const loadTournamentListSuccess = createAction(`${NAMESPACE}LOAD_TOURNAMENT_LIST_SUCCESS`);
export const loadTournamentListFailure = createAction(`${NAMESPACE}LOAD_TOURNAMENT_LIST_FAILURE`);

export const selectTournamentSeasonSuccess = createAction(
  `${NAMESPACE}SELECT_TOURNAMENT_SEASON_SUCCESS`,
);
