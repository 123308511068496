import React, { memo, RefObject, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ActionCreatorsMapObject } from 'redux';

import { TimeZone } from 'types';
import { useNonInitialEffect } from 'packages/hooks';
import { ScrollUtils } from 'packages/utils';

import { ActualGames, Details, DetailsSelector, TournamentChipList } from './components';
import { ACTUAL_GAMES_FINISHED_COUNT, SCHEDULED_GAMES_FINISHED_COUNT } from './constants';
import styles from './styles.scss';

type Props = {
  actualGamesActions: ActionCreatorsMapObject;
  commonActions: ActionCreatorsMapObject;
  timeZone: TimeZone;
  containerRef: RefObject<HTMLDivElement>;
};

const TeamSummary = ({ actualGamesActions, commonActions, timeZone, containerRef }: Props) => {
  const { seasonId, teamId } = useParams();

  useNonInitialEffect(() => {
    ScrollUtils.getScrollParent(containerRef.current)?.scrollTo(0, 0);
  }, [teamId]);

  useEffect(() => {
    actualGamesActions.loadActualGames({
      teamId,
      finishedCount: ACTUAL_GAMES_FINISHED_COUNT,
      scheduledCount: SCHEDULED_GAMES_FINISHED_COUNT,
    });

    return commonActions.cleanSummary;
  }, [timeZone.value, teamId]);

  return (
    <>
      <ActualGames />
      <DetailsSelector className={styles.detailsSelector} />
      {seasonId === undefined ? (
        <TournamentChipList className={styles.tournamentChipList} />
      ) : (
        <Details className={styles.details} />
      )}
    </>
  );
};

export default memo(TeamSummary);
