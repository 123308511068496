import Dexie from 'dexie';

import { keyValueDatabase } from 'packages/storage';
import { Tables, FavoritesTableIndex, FavoritesTeamsTableIndex } from 'configure/storage/types';
import { EventTeam } from 'types';

import {
  updateFavoriteTeamsFailure,
  updateFavoriteTeamsStart,
  updateFavoriteTeamsSuccess,
} from '../actions';

import loadActiveFavoritesByCategory from './loadActiveFavoritesByCategory';
import countFavoriteEventInAllCategories from './countFavoriteEventInAllCategories';
import extendItemWithOrder from './extendItemWithOrder';
import loadLatestTeamsFromStorage from './loadLatestTeamsFromStorage';

const toggleTeam = (team: EventTeam) => async (dispatch) => {
  try {
    dispatch(updateFavoriteTeamsStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoriteTeamsModel = dexieInstance.table(Tables.FAVORITE_TEAMS);
    const favoriteLeaguesModel = dexieInstance.table(Tables.FAVORITE_LEAGUES);
    const favoriteEventsModel = dexieInstance.table(Tables.FAVORITE_EVENTS);
    const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);

    const isTeamFavorite = await favoriteTeamsModel
      .where(FavoritesTeamsTableIndex.Id)
      .equals(team.id)
      .first();

    if (isTeamFavorite) {
      const teamFavorites = await favoritesModel
        .where(FavoritesTableIndex.HomeTeamId)
        .equals(team.id)
        .or(FavoritesTableIndex.AwayTeamId)
        .equals(team.id)
        .toArray();

      await dexieInstance.transaction(
        'rw',
        [favoritesModel, favoriteTeamsModel, favoriteLeaguesModel, favoriteEventsModel],
        () =>
          Promise.all([
            ...teamFavorites.map(async (favorite) => {
              const favoriteCategoriesCount = await dispatch(
                countFavoriteEventInAllCategories(favorite),
              );

              if (favoriteCategoriesCount === 1) {
                favoritesModel.delete(favorite.id);
              }
            }),
            favoriteTeamsModel.delete(team.id),
          ]),
      );
    } else {
      const [teamFavoriteEvents, orderNumberExtendedItem] = await Promise.all([
        dispatch(loadActiveFavoritesByCategory({ teamIds: [team.id] })),
        dispatch(extendItemWithOrder(team, Tables.FAVORITE_TEAMS)),
      ]);

      await dexieInstance.transaction('rw', [favoriteTeamsModel, favoritesModel], () =>
        Promise.all([
          favoritesModel.bulkPut(teamFavoriteEvents),
          favoriteTeamsModel.put(orderNumberExtendedItem),
        ]),
      );
    }

    const latestFavoriteTeams = await dispatch(loadLatestTeamsFromStorage());

    dispatch(updateFavoriteTeamsSuccess(latestFavoriteTeams));
  } catch (e) {
    dispatch(updateFavoriteTeamsFailure(e));
  }
};

export default toggleTeam;
