import React from 'react';

import { TeamSquad } from 'packages/team-squad-ui';
import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';

import styles from './styles.scss';

const TeamSquadPage = () => (
  <Grid.SingleColumn isSecondaryOpen={false}>
    <div key={GridColumnId.Primary} className={styles.primary}>
      <TeamSquad.Mobile />
    </div>
    <></>
  </Grid.SingleColumn>
);

export default TeamSquadPage;
