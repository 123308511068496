import { handleActions, ReducerMap } from 'redux-actions';

import { headToHeadActions } from '../../actions';
import { HeadToHead } from '../../types';

const defaultState: HeadToHead.Data = {
  teams: [],
  eventRows: [],
};

const reducerMap: ReducerMap<HeadToHead.Data, HeadToHead.Data> = {
  [`${headToHeadActions.loadHeadToHeadSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${headToHeadActions.cleanHeadToHeadSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
