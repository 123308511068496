import { isNil } from 'packages/utils/Object.utils';

const getMatchResult = (teams, eventStatus) => {
  const [leftTeam, rightTeam] = teams;

  if (!isNil(leftTeam.score) && !isNil(rightTeam.score)) {
    return `${leftTeam.score} - ${rightTeam.score}`;
  }

  return eventStatus.status;
};

export default getMatchResult;
