import { SocketConfig, SocketOptions } from '../../types';
import { EMULATED_SOCKET_INTERVAL } from '../../constants';
import { DifferentiatedUpdatesSelector, UpdatesSelectorFactory } from '../updatesSelectors';
import BaseSocket from './BaseSocket';

class EmulatedSocket<ItemType = any> extends BaseSocket<ItemType> {
  protected readonly options: SocketOptions;
  protected socketIntervalId: number | null;
  protected updatesSelector: DifferentiatedUpdatesSelector<any>;

  constructor(config: SocketConfig<ItemType>, options: SocketOptions) {
    super(config);
    const { dataReducerFactoryConfig } = config;

    this.socketIntervalId = null;
    this.options = options;
    this.updatesSelector = UpdatesSelectorFactory<ItemType>(dataReducerFactoryConfig);
  }

  protected updateData = async () => {
    const { fetchData, onMessage, onError } = this.config;

    try {
      const data = await fetchData();
      onMessage && onMessage(this.updatesSelector.handleDataUpdate(data));
    } catch (e) {
      onError && onError(e);
    }
  };

  public async subscribe() {
    const { interval = EMULATED_SOCKET_INTERVAL, fetchByInitialize } = this.options;

    if (fetchByInitialize) {
      await this.updateData();
    }

    this.socketIntervalId = window.setInterval(this.updateData, interval);
  }

  public unsubscribe() {
    this.socketIntervalId && window.clearTimeout(this.socketIntervalId);
    this.socketIntervalId = null;
  }
}

export default EmulatedSocket;
