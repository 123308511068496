import React, { memo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { EventLoadingSkeleton } from 'packages/events-ui/components';

import { Header } from './components';
import styles from './styles.scss';

type Props = {
  elementsCount: number;
  isCompact?: boolean;
};

const EventListSkeleton = ({ elementsCount, isCompact = false }: Props) => {
  const rows = ArrayUtils.getArrayFromNumber(elementsCount);

  return (
    <div className={styles.container}>
      <Header />
      <div className={styles.content}>
        {rows.map((element) => (
          <EventLoadingSkeleton key={element} isCompact={isCompact} />
        ))}
      </div>
    </div>
  );
};

export default memo(EventListSkeleton);
