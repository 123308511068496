import React, { memo } from 'react';

import styles from './styles.scss';

type Props = {
  rank: number;
};

const ColumnRank = ({ rank }: Props) => {
  return <div className={styles.rank}>{rank}</div>;
};

export default memo(ColumnRank);
