import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'is-empty-typed';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { Stadium } from 'packages/team-details/types';
import { Figure, Separator } from 'packages/shared/components';
import { Weight } from 'packages/shared/components/Separator/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import { LoadingSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  stadiums?: Stadium[];
  isLoading?: boolean;
  className?: string;
};

const Stadiums = ({ stadiums, isLoading, className }: Props) => {
  const { tournamentId, seasonId } = useParams();
  const { appNavigate } = useNavigation();

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (isEmpty(stadiums)) {
    return <></>;
  }

  const getStadiumClickHandler = (stadiumId: number) => () => {
    if (tournamentId && seasonId) {
      appNavigate(DesktopPage.FootballStadiumSummary, {
        stadiumId,
        seasonId,
        uniqueTournamentId: tournamentId,
      });
    } else {
      appNavigate(DesktopPage.FootballStadium, { stadiumId });
    }
  };

  return (
    <div className={classnames(styles.stadiumsContainer, className)}>
      <div className={styles.header}>
        {i18n.value('team.teamSummary.description.stadium.title')}
      </div>
      {stadiums.map(({ id, name, city, logo, capacity, year }, index) => (
        <div className={styles.stadiumContainer} key={id}>
          {!isEmpty(name) && (
            <div className={styles.descriptionLine}>
              <div className={styles.property}>
                {i18n.value('team.teamSummary.description.stadium.stadiumPropertyName')}
              </div>
              {!isEmpty(logo) && (
                <Figure
                  src={logo}
                  onClick={getStadiumClickHandler(id)}
                  className={classnames(styles.countryLogo, styles.clickable)}
                />
              )}
              <div
                className={classnames(styles.description, styles.clickable)}
                onClick={getStadiumClickHandler(id)}
              >
                {name}
                {!isEmpty(city) && <span className={styles.additionalInfo}>({city})</span>}
              </div>
            </div>
          )}
          {!isEmpty(capacity) && (
            <div className={styles.descriptionLine}>
              <div className={styles.property}>
                {i18n.value('team.teamSummary.description.stadium.capacityPropertyName')}
              </div>
              <div className={styles.description}>{capacity}</div>
            </div>
          )}
          {!isEmpty(year) && (
            <div className={styles.descriptionLine}>
              <div className={styles.property}>
                {i18n.value('team.teamSummary.description.stadium.yearPropertyName')}
              </div>
              <div className={styles.description}>{year}</div>
            </div>
          )}
          {index !== stadiums.length - 1 && (
            <Separator weight={Weight.Thin} className={styles.descriptionLine} />
          )}
        </div>
      ))}
    </div>
  );
};

export default memo(Stadiums);
