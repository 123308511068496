import { TeamSummary } from 'packages/rest';

import { loadTeamDetailsStart, loadTeamDetailsSuccess, loadTeamDetailsFailure } from '../actions';
import { mapDetails } from '../services';

interface Params {
  teamId: number;
}

const loadDetails = ({ teamId }: Params) => async (dispatch) => {
  try {
    dispatch(loadTeamDetailsStart());

    const [{ data: descriptionData }, { data: tournamentsData }] = await Promise.all([
      TeamSummary.loadDescription({ teamId }),
      TeamSummary.loadTournamentList({ teamId }),
    ]);

    const description = mapDetails(descriptionData.results, tournamentsData.results);

    dispatch(loadTeamDetailsSuccess(description));
  } catch (e) {
    dispatch(loadTeamDetailsFailure());
  }
};

export default loadDetails;
