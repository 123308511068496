import React from 'react';
import { Column } from 'react-table';

import { Skeleton } from 'packages/shared/components';

const getSkeletonSchema = (): Column[] => {
  return [
    {
      id: 'startDate',
      Header: (
        <Skeleton width={60} marginTop={3} marginBottom={3} height={10} variant="rectangular" />
      ),
      headerStyle: { textAlign: 'left', paddingLeft: '16px' },
      cellStyle: { padding: '4px 16px' },
      Cell: <Skeleton height={14} width={80} variant="rectangular" />,
      width: 120,
    },
    {
      id: 'endDAte',
      Header: (
        <Skeleton width={60} marginTop={3} marginBottom={3} height={10} variant="rectangular" />
      ),
      headerStyle: { textAlign: 'left', paddingLeft: '16px' },
      cellStyle: { padding: '4px 16px' },
      Cell: <Skeleton height={14} width={80} variant="rectangular" />,
      width: 120,
    },
    {
      id: 'team',
      Header: <Skeleton marginTop={3} marginBottom={3} height={10} variant="rectangular" />,
      headerStyle: { textAlign: 'left' },
      Cell: <Skeleton variant="circular" />,
      width: 40,
    },
    {
      id: 'contactType',
      Header: (
        <Skeleton width={70} marginTop={3} marginBottom={3} height={10} variant="rectangular" />
      ),
      headerStyle: { textAlign: 'left', paddingRight: '16px' },
      Cell: <Skeleton height={14} width={60} variant="rectangular" />,
      width: 100,
    },
  ];
};

export default getSkeletonSchema;
