import React, { memo } from 'react';

import { Data as StadiumDetailsData } from 'packages/stadium-details/types';

import StadiumDetailsView from './views';
import connect from './connect';

type Props = {
  stadiumDetails: StadiumDetailsData;
  isLoading: boolean;
  className?: string;
};

const extendStadiumDetails = (
  StadiumDetails: typeof StadiumDetailsView[keyof typeof StadiumDetailsView],
) =>
  memo(({ stadiumDetails, isLoading, className }: Props) => {
    return (
      <StadiumDetails stadiumDetails={stadiumDetails} isLoading={isLoading} className={className} />
    );
  });

export default {
  Desktop: connect(extendStadiumDetails(StadiumDetailsView.Desktop)),
  DesktopNarrow: connect(extendStadiumDetails(StadiumDetailsView.DesktopNarrow)),
  Tablet: connect(extendStadiumDetails(StadiumDetailsView.Tablet)),
  TabletNarrow: connect(extendStadiumDetails(StadiumDetailsView.TabletNarrow)),
  Mobile: connect(extendStadiumDetails(StadiumDetailsView.Mobile)),
};
