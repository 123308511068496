import React from 'react';
import isEmpty from 'is-empty-typed';

import { Country } from 'packages/team-squad/types';
import { Figure } from 'packages/shared/components';

import styles from './styles.scss';
import EmptyCell from '../EmptyCell';

type Props = {
  country?: Country;
};

const CountryLogoCell = ({ country }: Props) => {
  return (
    <div className={styles.logoWrapper}>
      {isEmpty(country) ? (
        <EmptyCell />
      ) : (
        <Figure className={styles.countryLogo} src={country?.logo} />
      )}
    </div>
  );
};

export default CountryLogoCell;
