import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { seasonHistorySelectors, seasonHistoryThunks } from 'packages/season-history';
import { seasonDetailsSelectors } from 'packages/season-details';

const mapStateToProps = (state) => {
  return {
    history: seasonHistorySelectors.getSeasons(state),
    isLoading: seasonHistorySelectors.getIsLoading(state),
    seasonDetails: seasonDetailsSelectors.getData(state),
    activeTournament: seasonDetailsSelectors.getTournament(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    seasonHistoryActions: bindActionCreators(seasonHistoryThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
