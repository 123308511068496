exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3gBDiftL{position:relative}._3gBDiftL._2kSQHHcu{width:100%}._3gBDiftL._vRkeljle{width:90px}._3gBDiftL._2bgrOWBb{width:160px}._3gBDiftL._3UH670Ag{width:220px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Select/containers/ExtendedMultipleSelect/styles.scss"],"names":[],"mappings":"AAEA,WAMI,iBAAkB,CANtB,qBASM,UAAW,CATjB,qBAaM,UAZU,CADhB,qBAiBM,WAfW,CAFjB,qBAqBM,WAlBW","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\r\n\r\n:local {\r\n  $sm-size: 90px;\r\n  $md-size: 160px;\r\n  $lg-size: 220px;\r\n\r\n  .main-wrapper {\r\n    position: relative;\r\n\r\n    &.full-width {\r\n      width: 100%;\r\n    }\r\n\r\n    &.sm {\r\n      width: $sm-size;\r\n    }\r\n\r\n    &.md {\r\n      width: $md-size;\r\n    }\r\n\r\n    &.lg {\r\n      width: $lg-size;\r\n    }\r\n  }\r\n}"]}]);

// Exports
exports.locals = {
	"main-wrapper": "_3gBDiftL",
	"mainWrapper": "_3gBDiftL",
	"full-width": "_2kSQHHcu",
	"fullWidth": "_2kSQHHcu",
	"sm": "_vRkeljle",
	"md": "_2bgrOWBb",
	"lg": "_3UH670Ag"
};