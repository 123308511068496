import React, { memo } from 'react';
import classnames from 'classnames';

import { IconType } from 'packages/shared/components/Icon/types';

import { Icon } from 'packages/shared/components';
import styles from './styles.scss';

type Props = {
  label: string;
  onClick?: () => void;
  isDisabled?: boolean;
  useHorizontalPadding?: boolean;
  className?: string;
};

const BlockLink = ({
  onClick,
  isDisabled = false,
  useHorizontalPadding = false,
  className,
  label,
}: Props) => {
  return (
    <div
      onClick={!isDisabled ? onClick : undefined}
      className={classnames(
        styles.linkContainer,
        { [styles.active]: !isDisabled, [styles.horizontalPadding]: useHorizontalPadding },
        className,
      )}
    >
      <span className={styles.link}>{label}</span>
      <Icon id={IconType.Arrow} className={styles.arrow} />
    </div>
  );
};

export default memo(BlockLink);
