import DateService from 'packages/date-az';
import { Season } from 'packages/player-events/types';

import { SeasonSelectOption } from '../../../types';

const getTeamSelectItem = (season: Season): SeasonSelectOption => {
  const { id, name, period, startDate, endDate } = season;

  return {
    label: name,
    id,
    componentProps: {
      period,
      name,
      isCurrent: DateService.isCurrentDateBetweenRange(startDate, endDate),
    },
  };
};

export default getTeamSelectItem;
