import React, { useMemo, memo } from 'react';

import { calcAge } from './services';

type Props = {
  dateOfBirth: string;
};

const Age = ({ dateOfBirth }: Props) => {
  const age = useMemo(() => {
    return calcAge(dateOfBirth);
  }, [dateOfBirth]);

  return <div>{age}</div>;
};

export default memo(Age);
