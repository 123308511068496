import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { playersActions, commonActions } from '../../actions';

const defaultState = [];

const reducerMap: ReducerMap<any, any> = {
  [`${combineActions(
    playersActions.loadInitialPlayersSuccess,
    playersActions.loadPlayersPageSuccess,
  )}`]: (state, { payload }) => {
    const { players } = payload;

    return players;
  },
  [`${combineActions(
    playersActions.loadInitialPlayersFailure,
    commonActions.cleanPlayerStatistics,
    playersActions.cleanPlayers,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
