import { SearchSelectItem } from '../../../types';

type Params<T extends SearchSelectItem> = {
  options: T[];
  substring: string;
};

const findOptionBySubstring = <T extends SearchSelectItem>({
  options,
  substring,
}: Params<T>): T[] => {
  return options.filter(({ value }) => value.toLowerCase().startsWith(substring.toLowerCase()));
};

export default findOptionBySubstring;
