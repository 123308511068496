import { combineReducers } from 'redux';

import teams from './teams';
import filters from './filters';
import customFilter from './customFilter';

export default combineReducers({
  customFilter,
  filters,
  teams,
});
