exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._V4lf2ips{padding:16px}._V4lf2ips ._LFjxejLT{margin-top:24px}._V4lf2ips ._6mMrrfjg{margin-top:24px}._V4lf2ips ._1SN9X6lV{margin-top:24px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/team-player-statistics-ui/containers/PlayerStatistics/components/ExtendedFilter/components/FilterArea/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,YCJU,CDEd,sBAKM,eAAgB,CALtB,sBASM,eAAgB,CATtB,sBAaM,eAAgB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .filter-area-container {\n    padding: $padding;\n\n    .filter-group-separator {\n      margin-top: 24px;\n    }\n\n    .customization-presets-container {\n      margin-top: 24px;\n    }\n\n    .columns-arrangement-container {\n      margin-top: 24px;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"filter-area-container": "_V4lf2ips",
	"filterAreaContainer": "_V4lf2ips",
	"filter-group-separator": "_LFjxejLT",
	"filterGroupSeparator": "_LFjxejLT",
	"customization-presets-container": "_6mMrrfjg",
	"customizationPresetsContainer": "_6mMrrfjg",
	"columns-arrangement-container": "_1SN9X6lV",
	"columnsArrangementContainer": "_1SN9X6lV"
};