import { handleActions, ReducerMap, combineActions } from 'redux-actions';

import * as actions from '../actions';
import { Data } from '../types';

const defaultState = {
  searchKey: '',
  results: [],
};

const reducerMap: ReducerMap<Data, Partial<Data>> = {
  [`${combineActions(actions.loadMoreResultsSuccess, actions.loadResultsSuccess)}`]: (
    state,
    { payload },
  ) => {
    const { results = [] } = payload;

    return { ...state, results };
  },

  [`${actions.setSearchKeySuccess}`]: (state, { payload }) => {
    const { searchKey = '' } = payload;

    return { ...state, searchKey };
  },

  [`${combineActions(actions.clearResultsSuccess)}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
