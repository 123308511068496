import React from 'react';

import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import Icon from '../Icon';
import { IconType } from '../Icon/types';
import styles from './styles.scss';

type Props = {
  scorerName?: string;
  assistName?: string;
  scorerId?: number;
  assistId?: number;
};

const AssistLabel = ({ scorerId, assistId, assistName, scorerName }: Props) => {
  const { appNavigate } = useNavigation();

  const getPlayerClickHandler = (playerId?: number) => {
    return () => {
      if (playerId) {
        appNavigate(DesktopPage.FootballPlayer, { playerId });
      }
    };
  };

  return (
    <div className={styles.wrapper}>
      <Icon className={styles.icon} id={IconType.Assist} />
      <div className={styles.playersList}>
        <span onClick={getPlayerClickHandler(assistId)}>{assistName}</span>{' '}
        <span className={styles.scorer} onClick={getPlayerClickHandler(scorerId)}>
          ({scorerName})
        </span>
      </div>
    </div>
  );
};

export default AssistLabel;
