import { EventHeadToHead } from 'packages/rest';

import DateService from 'packages/date-az';
import { Competitor } from 'types';

import { mapEventHeadToHead } from '../services';
import * as actions from '../actions';

export const loadEventHeadToHead = (
  homeTeam: Competitor,
  awayTeam: Competitor,
  eventScheduledDate: string,
) => async (dispatch) => {
  dispatch(actions.loadEventHeadToHeadStart());

  const scheduledDate = DateService.parse(eventScheduledDate).toISODate();

  const headToHeadResponse = await EventHeadToHead.loadHeadToHeadEvents({
    homeTeamId: homeTeam.id,
    awayTeamId: awayTeam.id,
    scheduledDate,
  });
  const homeLastFiveResponse = await EventHeadToHead.loadLastFiveEvents({
    teamId: homeTeam.id,
    scheduledDate,
  });
  const awayLastFiveResponse = await EventHeadToHead.loadLastFiveEvents({
    teamId: awayTeam.id,
    scheduledDate,
  });

  const eventHeadToHead = mapEventHeadToHead(
    headToHeadResponse.data.results,
    homeLastFiveResponse.data.results,
    awayLastFiveResponse.data.results,
    homeTeam,
    awayTeam,
  );

  try {
    dispatch(actions.loadEventHeadToHeadSuccess({ eventHeadToHead }));
  } catch (error) {
    dispatch(actions.loadEventHeadToHeadFailure());
  }
};
