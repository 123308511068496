import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { statisticsActions, commonActions } from '../../actions';
import { Interfaces } from '../../types';

const defaultState: Interfaces.Statistics.Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Interfaces.Statistics.Statistics.Status, void> = {
  [`${statisticsActions.common.loadInitialStatisticsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(
    statisticsActions.common.loadInitialStatisticsFailure,
    statisticsActions.common.loadInitialStatisticsSuccess,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${combineActions(
    commonActions.cleanSummarySuccess,
    statisticsActions.common.cleanWholeStatisticsSuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
