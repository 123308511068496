import React from 'react';
import { Column } from 'react-table';

import { Skeleton } from 'packages/shared/components';

import { PlayerCellSkeleton } from '../components';

const centerAlignStyle = {
  display: 'flex',
  justifyContent: 'center',
};

export const getColumnSchema = (): Column[] => [
  {
    id: 'player',
    Header: () => <Skeleton width={24} height={12} variant="rectangular" />,
    Cell: PlayerCellSkeleton,
    width: 58,
    cellStyle: { paddingLeft: 16 },
    headerStyle: { textAlign: 'left', padding: '8px 8px 8px 16px' },
  },
  {
    id: 'team',
    Cell: () => <Skeleton width={20} height={20} variant="circular" />,
    width: 10,
    cellStyle: centerAlignStyle,
    headerStyle: centerAlignStyle,
  },
  {
    id: 'gamesPlayed',
    Header: () => <Skeleton width={14} height={12} variant="rectangular" />,
    Cell: () => <Skeleton width={16} height={16} variant="rectangular" />,
    width: 10,
    cellStyle: centerAlignStyle,
    headerStyle: centerAlignStyle,
  },
  {
    id: 'goals',
    Header: () => <Skeleton width={14} height={12} variant="rectangular" />,
    Cell: () => <Skeleton width={16} height={16} variant="rectangular" />,
    width: 10,
    cellStyle: centerAlignStyle,
    headerStyle: centerAlignStyle,
  },
  {
    id: 'assists',
    Header: () => <Skeleton width={14} height={12} variant="rectangular" />,
    Cell: () => <Skeleton width={16} height={16} variant="rectangular" />,
    width: 10,
    cellStyle: centerAlignStyle,
    headerStyle: centerAlignStyle,
  },
  {
    id: 'goalPoints',
    Header: () => <Skeleton width={14} height={12} variant="rectangular" />,
    Cell: () => <Skeleton width={16} height={16} variant="rectangular" />,
    width: 12,
    cellStyle: centerAlignStyle,
    headerStyle: centerAlignStyle,
  },
];

export default getColumnSchema;
