import React from 'react';

import { Score } from 'types';
import { eventsServices } from 'packages/events';
import { DisplayingScorePeriod } from 'packages/events/types';

import styles from './styles.scss';

interface Props {
  scores?: Score[];
  isFirstTeamAtHome: boolean;
}

const AdditionalScore = ({ scores, isFirstTeamAtHome }: Props) => {
  if (scores) {
    const extractPeriodScore = eventsServices.getPeriodScoreExtractor(scores, isFirstTeamAtHome);
    const firstPeriodScore = extractPeriodScore(DisplayingScorePeriod.FIRST_PERIOD);

    return (
      <div className={styles.periodScoreContainer}>
        {!!firstPeriodScore && <span className={styles.row}>({firstPeriodScore})</span>}
      </div>
    );
  }

  return <div className={styles.periodScoreContainer} />;
};

export default React.memo(AdditionalScore);
