import { StatisticsGroup } from 'packages/event-standings/types';
import { NarrowTeamStatisticsTable } from 'packages/shared/modules';

const getTableProps = () => ({
  contentComponent: NarrowTeamStatisticsTable.Mobile,
  getProps: (props) => ({
    ...props,
    statisticsGroup: StatisticsGroup.Overall,
  }),
});

export default getTableProps;
