import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { Grid } from 'modules/shared/layouts';
import { GridColumnId } from 'types';
import { SearchQuery } from 'router/types';

import { Popup, Main } from './components';

const Category = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  return (
    <Grid.SingleColumn isSecondaryOpen={!!eventId}>
      <Main key={GridColumnId.Primary} />
      <Popup key={GridColumnId.Secondary} />
    </Grid.SingleColumn>
  );
};

export default Category;
