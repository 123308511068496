import DateService from 'packages/date-az';
import { DateTime } from 'luxon';

export const getNewRelativeDate = (isPrevious: boolean, currentRelativeDate: Date): DateTime => {
  const currentRelativeDateTime: DateTime = DateService.getDateTimeFromDate(currentRelativeDate);
  const options = { month: 1 };

  return isPrevious
    ? DateService.minus(currentRelativeDateTime, options)
    : DateService.plus(currentRelativeDateTime, options);
};

export default getNewRelativeDate;
