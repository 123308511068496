import { EventCommentary, EventSummary } from 'packages/rest';
import { BaseHappening } from 'packages/event-time-line/types';
import { matchTimeLineThunks, matchTimeLineSelectors } from 'packages/match-time-line';
import { mapCommentary } from 'packages/event-commentary/services';
import {
  groupHappeningsByPeriod,
  normalizePenaltyPeriodHappenings,
} from 'packages/event-summary/services';

import {
  loadMatchSummaryStart,
  loadMatchSummarySuccess,
  loadMatchSummaryFailure,
} from '../actions';
import { mapEventSummary } from '../services';

const loadMatchSummary = ({ eventId }) => async (dispatch, getState) => {
  try {
    dispatch(loadMatchSummaryStart());

    await dispatch(matchTimeLineThunks.loadTimeLine({ eventId }));
    const happenings: BaseHappening[] = matchTimeLineSelectors.getData(getState());
    const groupedPeriods = groupHappeningsByPeriod(happenings);
    const periods = normalizePenaltyPeriodHappenings(groupedPeriods);

    const { data: summaryData } = await EventSummary.loadEventSummary({ eventId });
    const { data: commentaryData } = await EventCommentary.loadEventCommentary({ eventId });
    const summary = mapEventSummary(summaryData.results);
    const commentaries = mapCommentary(commentaryData.results);

    dispatch(loadMatchSummarySuccess({ ...summary, periods, commentaries }));
  } catch (error) {
    dispatch(loadMatchSummaryFailure());
  }
};

export default loadMatchSummary;
