import { memo, ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';

import { usePortalContainer, useBodyOverflowDisabling, useTouchMoveDisabling } from './hooks';
import { getLastPortal } from './services';

type Props = {
  children: ReactNode;
  useOverlay?: boolean;
  disableBodyOverflow?: boolean;
  disableTouchMoveEvent?: boolean;
};

const SimplePortal = ({
  children,
  useOverlay = true,
  disableBodyOverflow = false,
  disableTouchMoveEvent = false,
}: Props) => {
  const isPortalExist = useMemo(getLastPortal, []);

  if (!isPortalExist) {
    if (disableBodyOverflow) {
      useBodyOverflowDisabling();
    }

    if (disableTouchMoveEvent) {
      useTouchMoveDisabling();
    }
  }

  const portalContainer = usePortalContainer({ overlay: useOverlay });

  return createPortal(children, portalContainer);
};

export default memo(SimplePortal);
