import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadSquadStart = createAction(`${NAMESPACE}LOAD_SQUAD_START`);
export const loadSquadSuccess = createAction(`${NAMESPACE}LOAD_SQUAD_SUCCESS`);
export const loadSquadFailure = createAction(`${NAMESPACE}LOAD_SQUAD_FAILURE`);

export const loadPageStart = createAction(`${NAMESPACE}LOAD_PAGE_START`);
export const loadPageSuccess = createAction(`${NAMESPACE}LOAD_PAGE_SUCCESS`);
export const loadPageFailure = createAction(`${NAMESPACE}LOAD_PAGE_FAILURE`);

export const updatePaginationSuccess = createAction(`${NAMESPACE}UPDATE_PAGINATION_SUCCESS`);
export const updateCountSuccess = createAction(`${NAMESPACE}UPDATE_COUNT_SUCCESS`);
