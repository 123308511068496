import React, { memo } from 'react';
import classnames from 'classnames';

import { LineupRow, PlayerTypeJersey } from './types';

import styles from './styles.scss';
import { PlayerCell } from './components';

type Props = {
  className?: string;
  lineupInfoForm?: LineupRow[];
  playerTypeJersey?: PlayerTypeJersey;
  isReversed?: boolean;
};

const GOALKEEPER_PLAYER_POSITION = 'Goalkeeper';

const Lineup = ({ lineupInfoForm, className, playerTypeJersey, isReversed }: Props) => {
  return (
    <div className={classnames(styles.lineup, className)}>
      {lineupInfoForm?.map(({ id, lineupPlayers }) => (
        <div className={classnames(styles.lineupRow, { [styles.reverse]: isReversed })} key={id}>
          {lineupPlayers.map(({ id, jerseyNumber, shortName, happenings, positionName }) => (
            <PlayerCell
              happenings={happenings}
              name={shortName}
              jerseyColorScheme={
                positionName === GOALKEEPER_PLAYER_POSITION
                  ? playerTypeJersey?.gkJersey
                  : playerTypeJersey?.player
              }
              jerseyNumber={jerseyNumber}
              key={id}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

export default memo(Lineup);
