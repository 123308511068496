import React, { memo } from 'react';
import classnames from 'classnames';

import { BaseHappening } from 'packages/event-stats/types';

import { Line } from './components';
import styles from './styles.scss';

type Props = {
  happenings: BaseHappening[];
  isAway?: boolean;
  className?: string;
};

const TimeLine = ({ happenings, isAway = false, className }: Props) => {
  return (
    <div className={classnames(styles.timelineContainer, className, { [styles.away]: isAway })}>
      {happenings.map((happening) => (
        <Line key={happening.id} happening={happening} isAway={isAway} />
      ))}
    </div>
  );
};

export default memo(TimeLine);
