interface Params {
  homeWins: number;
  awayWins: number;
  draws: number;
}

enum CompareLineType {
  Primary = 'primary',
  Secondary = 'secondary',
  Default = 'default',
}

type LineType = { [K in keyof Params]?: CompareLineType };

const getLineTypes = (params: Params): LineType => {
  const keysByLineType = getKeysByLineTypes(params);

  return mapKeysByLineType(keysByLineType);
};

const getKeysByLineTypes = (params: Params): Record<CompareLineType, string[]> => {
  const results = Object.values(params);
  const maxResult = Math.max(...results);
  const minResult = Math.min(...results);

  const keysByLineType: Record<CompareLineType, string[]> = {
    [CompareLineType.Primary]: [],
    [CompareLineType.Secondary]: [],
    [CompareLineType.Default]: [],
  };

  for (const [paramKey, paramValue] of Object.entries(params)) {
    if (paramValue === maxResult) {
      keysByLineType[CompareLineType.Primary].push(paramKey);
    } else if (paramValue === minResult) {
      keysByLineType[CompareLineType.Default].push(paramKey);
    } else {
      keysByLineType[CompareLineType.Secondary].push(paramKey);
    }
  }

  return keysByLineType;
};

const mapKeysByLineType = (keysByLineType: Record<CompareLineType, string[]>) => {
  let linesByTypes = {};

  for (const [compareLineType, compareLineTypeKeys] of Object.entries(keysByLineType)) {
    if (
      (compareLineType === CompareLineType.Primary ||
        compareLineType === CompareLineType.Default) &&
      compareLineTypeKeys.length !== 1
    ) {
      linesByTypes = {
        ...linesByTypes,
        ...mapKeysToLinesTypes(compareLineTypeKeys, CompareLineType.Secondary),
      };
    } else {
      linesByTypes = {
        ...linesByTypes,
        ...mapKeysToLinesTypes(compareLineTypeKeys, compareLineType as CompareLineType),
      };
    }
  }

  return linesByTypes;
};

const mapKeysToLinesTypes = (lineKeys: string[], extendingType: CompareLineType): LineType => {
  return lineKeys.reduce((acc, curr) => ({ ...acc, [curr]: extendingType }), {});
};

export default getLineTypes;
