import React, { memo } from 'react';

import { Team } from 'types';
import { Data as StadiumDetailsData } from 'packages/stadium-details/types';
import { Interfaces as StadiumSummaryInterfaces } from 'packages/stadium-summary/types';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';

import { ActualLeagues } from 'packages/shared/components';
import DetailsSelector from '../../../DetailsSelector';
import { HomeTeams, StadiumLocation } from '../../../../components';
import {
  Attendance,
  Average,
  GoalStatistics,
  TopScorers,
  WinLose,
  DetailsSelectorSkeleton,
} from '../../components';
import { ActualGames } from './components';
import styles from './styles.scss';

type Props = {
  details: StadiumDetailsData;
  homeTeams: Team[];
  isStatisticsLoading: boolean;
  tournamentSeasons: SeasonsExtendedTournament[];
  isTournamentsLoading: boolean;
  isDetailsLoading: boolean;
  goalStatistics?: StadiumSummaryInterfaces.Statistics.GoalStatistics;
  attendance?: StadiumSummaryInterfaces.Statistics.Attendance;
  average?: StadiumSummaryInterfaces.Statistics.Average;
  topScorers?: StadiumSummaryInterfaces.Statistics.TopScorer[];
  winLose?: StadiumSummaryInterfaces.Statistics.WinLose;
};

const StadiumSummary = ({
  isStatisticsLoading,
  details,
  homeTeams,
  goalStatistics,
  attendance,
  average,
  topScorers,
  winLose,
  isDetailsLoading,
  tournamentSeasons,
  isTournamentsLoading,
}: Props) => {
  return (
    <div className={styles.teamSummary}>
      {isTournamentsLoading ? (
        <DetailsSelectorSkeleton isCompact />
      ) : (
        <DetailsSelector.Mobile useSelectorsInOneRow className={styles.detailsSelector} />
      )}

      <div className={styles.games}>
        <ActualGames />
      </div>

      <div className={styles.details}>
        <HomeTeams isLoading={isStatisticsLoading} homeTeams={homeTeams} />

        <Attendance
          isLoading={isStatisticsLoading}
          attendance={attendance}
          className={styles.attendance}
        />

        <GoalStatistics isLoading={isStatisticsLoading} goalStatistics={goalStatistics} />

        <TopScorers
          isLoading={isStatisticsLoading}
          topScorers={topScorers}
          className={styles.topScorers}
        />

        <Average isLoading={isStatisticsLoading} average={average} className={styles.average} />

        <WinLose isLoading={isStatisticsLoading} winLose={winLose} className={styles.winLose} />

        <StadiumLocation isLoading={isDetailsLoading} mapCoordinates={details?.mapCoordinates} />

        <ActualLeagues tournamentList={tournamentSeasons} isLoading={isTournamentsLoading} />
      </div>
    </div>
  );
};

export default memo(StadiumSummary);
