import React from 'react';
import classnames from 'classnames';
import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import { RefereeCardType } from './types';
import styles from './styles.scss';

type Props = {
  type: RefereeCardType;
  count?: number;
  className?: string;
};

const RefereeCard = ({ type, count = 0, className = '' }: Props) => {
  const CARD_ICON_TYPE_MAP = {
    [RefereeCardType.Yellow]: IconType.YellowCard,
    [RefereeCardType.Red]: IconType.RedCard,
    [RefereeCardType.YellowRed]: IconType.YellowRedCard,
  };

  const countView = count > 1 ? count : '';

  return (
    <div className={classnames(styles.refereeCard, className)}>
      <div className={styles.iconContainer}>
        <Icon id={CARD_ICON_TYPE_MAP[type]} className={styles.icon} />
        {countView && <div className={styles.counter}>{countView}</div>}
      </div>
    </div>
  );
};

export default React.memo(RefereeCard);
