import { Row } from 'react-table';

import { Team } from 'types';
import { TeamStatistics } from 'packages/season-team-statistics/types';

type Params = {
  onClick: (team: Team) => void;
};

const getRowProps = ({ onClick }: Params) => ({ original }: Row<TeamStatistics>) => {
  const { team } = original;

  return {
    onClick: () => {
      onClick(team);
    },
  };
};

export default getRowProps;
