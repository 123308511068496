import { handleActions, ReducerMap } from 'redux-actions';
import DateService from 'packages/date-az';

import { teamsFiltersActions, tournamentsFiltersActions, commonActions } from '../../actions';
import { EventFilters } from '../../types';

const now = DateService.getDate().toISODate();

const defaultState: EventFilters = {
  teams: [],
  tournaments: [],
  activeFilters: {
    period: [now, now],
    team: undefined,
    tournament: undefined,
    tournamentSeasons: [],
    selectedSeasons: [],
  },
};

const reducerMap: ReducerMap<EventFilters, Partial<EventFilters>> = {
  [`${teamsFiltersActions.loadTeamsFiltersSuccess}`]: (state, { payload }) => {
    const { teams = [] } = payload;

    return { ...state, teams };
  },

  [`${tournamentsFiltersActions.loadTournamentsFiltersSuccess}`]: (state, { payload }) => {
    const { tournaments = [] } = payload;

    return { ...state, tournaments };
  },

  [`${commonActions.updateActiveFilterSuccess}`]: (state, { payload }) => {
    const { activeFilters = state.activeFilters } = payload;

    return { ...state, activeFilters };
  },

  [`${commonActions.clearSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
