export enum PropertyType {
  Advantage = 'advantage',
  Disadvantage = 'disadvantage',
}

export enum ValueType {
  Counter = 'counter',
  Penalty = 'penalty',
  Percentage = 'percentage',
}

export enum ParameterNames {
  Goals = 'goals',
  YellowCards = 'yellow_cards',
  RedCards = 'red_cards',
  CardsGiven = 'cards_given',
  Substitutions = 'substitutions',
  BallPossession = 'ball_possession',
  FreeKicks = 'free_kicks',
  GoalKicks = 'goal_kicks',
  ThrowIns = 'throw_ins',
  Offsides = 'offsides',
  CornerKicks = 'corner_kicks',
  ShotsOnTarget = 'shots_on_target',
  ShotsOffTarget = 'shots_off_target',
  ShotsSaved = 'shots_saved',
  Fouls = 'fouls',
  Injuries = 'injuries',
  ShotsBlocked = 'shots_blocked',
  ShotsTotal = 'shots_total',
}
