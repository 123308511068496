import React, { memo, useCallback } from 'react';
import classnames from 'classnames';

import { Competitor, Team } from 'types';
import DateService from 'packages/date-az';
import { MatchResult, TeamLabel } from 'packages/shared/components';
import { Event } from 'packages/event-head-to-head/types';
import { isNumber } from 'packages/utils/Object.utils';

import { DesktopPage } from 'router/types';
import { useNavigation } from 'packages/hooks';
import { getMatchResult } from './services';
import styles from './styles.scss';

type Props = {
  event: Event;
  onEventClick: () => void;
  selectedTeam?: Competitor;
};

const TeamMatch = ({ event, onEventClick, selectedTeam }: Props) => {
  const { appNavigate } = useNavigation();

  const {
    tournamentName,
    categoryName,
    date,
    teams,
    matchResult,
    status,
    uniqueTournamentId,
    seasonId,
  } = event;
  const { periodScores, awayScore, homeScore } = status;
  const [leftTeam, rightTeam] = teams;
  const dateView = DateService.format(date, 'd LLL');
  const leftTeamResultValue = getMatchResult(leftTeam, rightTeam);
  const rightTeamResultValue = getMatchResult(rightTeam, leftTeam);
  const isScoreExists = isNumber(awayScore) && isNumber(homeScore);

  const handleTeamClick = useCallback(
    (team: Team) => {
      appNavigate(DesktopPage.FootballTeamSummaryDescription, {
        teamId: team.id,
        uniqueTournamentId,
        seasonId,
      });
    },
    [uniqueTournamentId, seasonId],
  );

  const leftTeamMatchResultElement = selectedTeam?.id === leftTeam.id && (
    <MatchResult value={leftTeamResultValue} />
  );
  const rightTeamMatchResultElement = selectedTeam?.id === rightTeam.id && (
    <MatchResult value={rightTeamResultValue} />
  );
  const matchResultElement =
    periodScores && (leftTeamMatchResultElement || rightTeamMatchResultElement);

  return (
    <div className={styles.teamMatch} onClick={onEventClick}>
      <div className={classnames(styles.rowContainer, styles.matchTitleContainer)}>
        <div>{dateView}</div>
        <div>
          {categoryName}, {tournamentName}
        </div>
      </div>
      <div className={classnames(styles.rowContainer, styles.matchTitleContainer)}>
        <div className={styles.matchResultsContainer}>{matchResultElement}</div>
        <div className={styles.matchDetailsContainer}>
          <TeamLabel
            onLogoClick={handleTeamClick}
            onNameClick={handleTeamClick}
            team={leftTeam}
            className={styles.teamLabel}
            isLogoFirst
          />
          <div className={styles.score}>
            {isScoreExists ? `${homeScore} - ${awayScore}` : matchResult}
          </div>
          <TeamLabel
            onLogoClick={handleTeamClick}
            onNameClick={handleTeamClick}
            team={rightTeam}
            className={classnames(styles.teamLabel, styles.away)}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(TeamMatch);
