import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { EventActiveFilters, Season, Tournament, PlayerTeam } from 'packages/player-events/types';
import { CustomPeriod, YearItem } from 'packages/shared/components/Calendar/types';
import { DatePicker } from 'packages/shared/components';
import { LocaleCode } from 'types';

import { TournamentSelect, SeasonSelect, TeamSelect } from '../../../../components';
import styles from './styles.scss';

type Props = {
  onTournamentChange: (tournament?: Tournament) => void;
  onTeamChange: (team?: PlayerTeam) => void;
  onSeasonsChange: (selectedSeasons: Season[]) => void;
  onYearClick: (year: YearItem) => void;
  onPeriodClick: (period: CustomPeriod) => void;
  onPeriodChange: (period: [string, string]) => void;
  tournamentFilters: Tournament[];
  teamFilters: PlayerTeam[];
  activeFilters: EventActiveFilters;
  customPeriod?: CustomPeriod;
  localeCode: LocaleCode;
  isMoreFiltersLoading: boolean;
  eventsCount: number;
  yearOffset: number;
};

const TeamEventsFiltersBase = ({
  onTournamentChange,
  onTeamChange,
  onSeasonsChange,
  onYearClick,
  onPeriodClick,
  onPeriodChange,
  tournamentFilters,
  teamFilters,
  activeFilters,
  customPeriod,
  localeCode,
  isMoreFiltersLoading,
  eventsCount,
  yearOffset,
}: Props) => {
  const {
    period,
    tournament: selectedTournament,
    selectedSeasons,
    tournamentSeasons,
    team: selectedTeam,
  } = activeFilters;

  return (
    <div className={styles.teamEventsFilters}>
      <div className={styles.title}>{i18n.value('player.events.filters.title')}</div>
      <DatePicker
        onApplyClick={onPeriodChange}
        eventsCount={eventsCount}
        yearOffset={yearOffset}
        localeCode={localeCode}
        onYearClick={onYearClick}
        onPeriodClick={onPeriodClick}
        customPeriod={customPeriod}
        value={period}
        isRangeMode
      />

      <TeamSelect selectedTeam={selectedTeam} teams={teamFilters} onChange={onTeamChange} />

      <TournamentSelect
        isLoading={isMoreFiltersLoading}
        onChange={onTournamentChange}
        tournaments={tournamentFilters}
        selectedTournament={selectedTournament}
        isDisabled={!selectedTeam?.isTournamentSelectable}
      />

      <SeasonSelect
        isLoading={isMoreFiltersLoading}
        onChange={onSeasonsChange}
        seasons={tournamentSeasons}
        selectedSeasons={selectedSeasons}
        isDisabled={!selectedTournament}
      />
    </div>
  );
};

export default memo(TeamEventsFiltersBase);
