import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState: Status = {
  isSeasonDetailsLoading: true,
};

const reducerMap: ReducerMap<Status, Status> = {
  [`${actions.loadSeasonDetailsStart}`]: () => {
    return { isSeasonDetailsLoading: true };
  },
  [`${combineActions(actions.loadSeasonDetailsFailure, actions.loadSeasonDetailsSuccess)}`]: () => {
    return { isSeasonDetailsLoading: false };
  },
  [`${actions.cleanSeasonDetails}`]: () => {
    return { ...defaultState };
  },
};

export default handleActions<Status>(reducerMap, defaultState);
