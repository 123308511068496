import { favoritesThunks } from 'packages/favorites';

import { Tournament } from 'types';

import { favoriteEventsActions } from '../../actions';

const toggleLeague = (league: Tournament) => async (dispatch) => {
  try {
    dispatch(favoriteEventsActions.toggleFavoriteStart());

    await dispatch(favoritesThunks.toggleLeague(league));

    dispatch(favoriteEventsActions.toggleFavoriteSuccess());
  } catch (error) {
    dispatch(favoriteEventsActions.toggleFavoriteFailure(error));
  }
};

export default toggleLeague;
