import { Stage } from 'packages/event-standings/types';
import { isTableStage } from 'packages/event-standings/services';

const shouldHeadToHeadBeDisplayed = (stages: Stage[] | null) => {
  let shouldHeadToHeadBeDisplayed = false;

  if (stages?.length === 1) {
    const firstStage = stages?.[0];

    if (isTableStage(firstStage)) {
      shouldHeadToHeadBeDisplayed = firstStage.data.tournamentTables.length === 1;
    }
  }

  return shouldHeadToHeadBeDisplayed;
};

export default shouldHeadToHeadBeDisplayed;
