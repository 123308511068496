import { MutableRefObject, useCallback, useEffect, useRef } from 'react';

import { throttle } from 'packages/utils/Function.utils';
import { BracketRound } from 'packages/event-standings/types';

import { AUTOFOCUS_ANIMATION_DURATION, SCROLL_ANIMATION_DURATION } from '../constants';
import {
  animateBracketScroll,
  getScrollConfiguration,
  switchButtonState,
  resetBracketState,
} from '../services';

interface Params {
  gridRef: MutableRefObject<HTMLDivElement | null>;
  gridContainerRef: MutableRefObject<HTMLDivElement | null>;
  leftButtonRef: MutableRefObject<HTMLDivElement | null>;
  rightButtonRef: MutableRefObject<HTMLDivElement | null>;
  initialColumnsHeights: number[];
  rounds: BracketRound[];
  initialContentHeight: number;
  baseMatchUpRoundIndex?: number;
  baseMatchUpIndex?: number;
}

const useBracketNavigation = ({
  gridRef,
  gridContainerRef,
  leftButtonRef,
  rightButtonRef,
  rounds,
  initialColumnsHeights,
  initialContentHeight,
  baseMatchUpRoundIndex,
  baseMatchUpIndex,
}: Params) => {
  const scrollableRef = useRef(true);

  useEffect(() => {
    const gridElement = gridRef.current;
    const gridContainerElement = gridContainerRef.current;
    const leftButtonElement = leftButtonRef.current;
    const rightButtonElement = rightButtonRef.current;

    if (gridElement && gridContainerElement && leftButtonElement && rightButtonElement) {
      switchButtonState(gridElement, leftButtonElement, rightButtonElement, 0);

      resetBracketState({
        bracketContainerElement: gridContainerElement,
        bracketGridElement: gridElement,
        columnElements: gridElement.querySelectorAll<HTMLDivElement>(`[id^='column-']`),
        columnsHeights: initialColumnsHeights,
      });
    }
  }, [rounds]);

  useEffect(() => {
    if (scrollableRef.current) {
      const gridContainerElement = gridContainerRef.current;
      const gridElement = gridRef.current;
      const leftButtonElement = leftButtonRef.current;
      const rightButtonElement = rightButtonRef.current;

      if (
        gridContainerElement &&
        gridElement &&
        leftButtonElement &&
        rightButtonElement &&
        baseMatchUpRoundIndex !== undefined &&
        baseMatchUpIndex !== undefined
      ) {
        const { width: columnWidth } = gridElement.children[0].getBoundingClientRect();
        const columnHeight = baseMatchUpRoundIndex === 0 ? 81 : 162;
        const columnOffset = baseMatchUpRoundIndex === 0 ? 0 : columnWidth;
        const scrollLeftTo = baseMatchUpRoundIndex * columnWidth - columnOffset;
        const scrollTopTo = baseMatchUpIndex * columnHeight;

        const scrollConfiguration = getScrollConfiguration({
          gridContainerElement,
          gridElement,
          initialColumnsHeights,
          initialContentHeight,
          scrollLeftTo,
          scrollTopTo,
          duration: AUTOFOCUS_ANIMATION_DURATION,
        });

        switchButtonState(
          gridElement,
          leftButtonElement,
          rightButtonElement,
          scrollConfiguration?.left || 0,
        );

        if (scrollConfiguration) {
          animateBracketScroll(scrollConfiguration, scrollableRef);
        }
      }
    }
  }, [
    baseMatchUpRoundIndex,
    baseMatchUpIndex,
    initialColumnsHeights,
    initialContentHeight,
    rounds,
  ]);

  const handleNavigation = useCallback(
    throttle((direction: 'left' | 'right') => {
      if (scrollableRef.current) {
        const gridContainerElement = gridContainerRef.current;
        const gridElement = gridRef.current;
        const leftButtonElement = leftButtonRef.current;
        const rightButtonElement = rightButtonRef.current;

        if (gridContainerElement && gridElement && leftButtonElement && rightButtonElement) {
          const { width: columnWidth } = gridElement.children[0].getBoundingClientRect();
          const scrollLeftTo = direction === 'left' ? -columnWidth : columnWidth;

          if (gridContainerElement && gridElement) {
            const scrollConfiguration = getScrollConfiguration({
              gridContainerElement,
              gridElement,
              initialColumnsHeights,
              initialContentHeight,
              scrollLeftTo,
            });

            if (scrollConfiguration) {
              switchButtonState(
                gridElement,
                leftButtonElement,
                rightButtonElement,
                scrollConfiguration.left,
              );

              animateBracketScroll(scrollConfiguration, scrollableRef);
            }
          }
        }
      }
    }, SCROLL_ANIMATION_DURATION),
    [initialColumnsHeights, initialContentHeight],
  );

  const navigateBackward = useCallback(() => {
    handleNavigation('left');
  }, [handleNavigation]);

  const navigateForward = useCallback(() => {
    handleNavigation('right');
  }, [handleNavigation]);

  return [navigateBackward, navigateForward];
};

export default useBracketNavigation;
