import React, { useEffect, useState, memo, useCallback, ChangeEvent } from 'react';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';
import { isEmpty } from 'packages/utils/Object.utils';

import styles from './styles.scss';

type Props = {
  placeholder: string;
  className?: string;
  value: string;
  onFocus?: () => void;
  onBlur?: () => void;
  onClear: () => void;
  onChange: Function;
};

const SearchBar = ({
  placeholder,
  className,
  value,
  onFocus,
  onBlur,
  onChange,
  onClear,
}: Props) => {
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);

    onChange(value);
  }, []);

  const handleBlur = useCallback(() => {
    setIsFocused(false);

    onBlur?.();
  }, []);

  const handleFocus = useCallback(() => {
    setIsFocused(true);

    onFocus?.();
  }, []);

  const handleClear = useCallback(() => {
    setInputValue('');

    onClear();
  }, []);

  return (
    <div
      className={classnames(styles.searchBarContainer, { [styles.focused]: isFocused }, className)}
    >
      <input
        type="text"
        className={styles.input}
        placeholder={placeholder}
        value={inputValue}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
      />
      <Icon id={IconType.SearchLogo} className={classnames(styles.icon, styles.iconSearch)} />
      {!isEmpty(inputValue) && (
        <Icon
          id={IconType.CrossDesktop}
          className={classnames(styles.icon, styles.iconCross)}
          onClick={handleClear}
        />
      )}
    </div>
  );
};

export default memo(SearchBar);
