import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Input, ItemLabel } from 'packages/shared/components';
import { StatType, CountrySelectOption } from 'packages/season-team-statistics/types';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';

import styles from './styles.scss';

type Props = {
  onActiveStatTypeChange: (statType: StatType) => void;
  onSelectedCountriesChange: (countries: CountrySelectOption[]) => void;
  activeStatType: StatType;
  statTabs: NavigationMenuItem[];
  countrySelectItems: CountrySelectOption[];
  selectedCountryItems: CountrySelectOption[];
  className?: string;
};

const GeneralFilter = ({
  activeStatType,
  onActiveStatTypeChange,
  onSelectedCountriesChange,
  selectedCountryItems,
  countrySelectItems,
  statTabs,
  className,
}: Props) => {
  return (
    <div className={classnames(styles.generalFilterGroup, className)}>
      <Input.Tabs
        isWidthLimited
        onChange={(activeStatsTab) => onActiveStatTypeChange(activeStatsTab as StatType)}
        activeId={activeStatType}
        items={statTabs}
        label={i18n.value('season.teamStatistics.customFilter.general.showStatsLabel')}
        name="Show stats"
      />
      <Input.SearchSelect<CountrySelectOption>
        itemsContainerClass={styles.searchListItems}
        items={countrySelectItems}
        selectedItems={selectedCountryItems}
        onSelectionChange={onSelectedCountriesChange}
        itemComponent={ItemLabel}
        labelPrefix={i18n.value('season.teamStatistics.customFilter.general.searchLabel')}
        label={i18n.value('season.teamStatistics.customFilter.general.countryLabel')}
        name="Team"
        useScrollIntoPopoverView
        isCloseableOnResize={false}
      />
    </div>
  );
};

export default memo(GeneralFilter);
