import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Skeleton height={14} width={160} variant="rectangular" />
      </div>

      <div className={styles.content}>
        <Skeleton height={88} width={120} marginRight={15} variant="rectangular" />

        <div>
          <Skeleton height={48} marginBottom={8} width={102} variant="rectangular" />
          <Skeleton height={16} width={165} variant="rectangular" />
        </div>
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
