import { EventSummary } from 'packages/rest';
import { eventTimeLineThunks, eventTimeLineSelectors } from 'packages/event-time-line';
import { BaseHappening } from 'packages/event-time-line/types';

import * as actions from '../actions';
import {
  mapEventSummary,
  groupHappeningsByPeriod,
  normalizePenaltyPeriodHappenings,
} from '../services';

const loadEventSummary = ({ eventId }) => async (dispatch, getState) => {
  try {
    dispatch(actions.loadEventSummaryStart());

    await dispatch(eventTimeLineThunks.loadEventTimeLine({ eventId }));
    const happenings: BaseHappening[] = eventTimeLineSelectors.getData(getState());
    const groupedPeriods = groupHappeningsByPeriod(happenings);
    const penaltyNormalizedPeriods = normalizePenaltyPeriodHappenings(groupedPeriods);

    const { data } = await EventSummary.loadEventSummary({ eventId });
    const eventSummary = mapEventSummary(data.results, penaltyNormalizedPeriods);

    dispatch(actions.loadEventSummarySuccess({ eventSummary }));
  } catch (error) {
    dispatch(actions.loadEventSummaryFailure());
  }
};

export default loadEventSummary;
