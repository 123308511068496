import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import isEmpty from 'is-empty-typed/lib';
import i18n from 'i18n-smart';
import { Row } from 'react-table';

import { TopVenue, VenuesParameter } from 'packages/season-top-board/types';
import { BlockLink } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import { TopTable } from '../../../../../../components';
import { getVenuesSchema as getColumnSchema } from '../../../../../../services';
import { SortingValueStateContext } from '../../../../../../constants';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  seasonTopBoardActions: ActionCreatorsMapObject;
  venues: TopVenue[];
  isTopVenuesLoading: boolean;
  seasonId: string;
  uniqueTournamentId: string;
};

const TopVenues = ({
  seasonId,
  uniqueTournamentId,
  seasonTopBoardActions,
  venues,
  isTopVenuesLoading,
}: Props) => {
  const { appNavigate } = useNavigation();
  const sortingValueState = useState<VenuesParameter>(VenuesParameter.Capacity);
  const columns = useMemo(getColumnSchema, []);
  const [sortingValue] = sortingValueState;

  useEffect(() => {
    seasonTopBoardActions.loadSeasonTopVenues(seasonId, sortingValue);
  }, [sortingValue, seasonId]);

  const handleLinkClick = useCallback(() => {
    appNavigate(DesktopPage.FootballSeasonStadiums, { seasonId, uniqueTournamentId });
  }, [seasonId, uniqueTournamentId]);

  const setRowProps = useCallback(
    ({ original }: Row<TopVenue>) => {
      const { venue } = original;

      return {
        onClick: () => {
          appNavigate(DesktopPage.FootballStadiumSummary, {
            stadiumId: venue.id,
            uniqueTournamentId,
            seasonId,
          });
        },
      };
    },
    [seasonId, uniqueTournamentId],
  );

  return (
    <SortingValueStateContext.Provider value={sortingValueState}>
      <div className={styles.topVenues}>
        <TopTable<TopVenue>
          setRowProps={setRowProps}
          rows={venues}
          columns={columns}
          isLoading={isTopVenuesLoading}
        />
      </div>
      <BlockLink
        label={i18n.value('season.seasonTopBoard.tabs.showAll.venues')}
        isDisabled={isEmpty(venues)}
        onClick={handleLinkClick}
        useHorizontalPadding
      />
    </SortingValueStateContext.Provider>
  );
};

export default connect(TopVenues);
