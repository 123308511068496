import { INITIAL_EVENTS_LIMIT } from 'packages/player-events/constants';

import { eventsActions, commonActions } from '../../actions';
import { initActiveFilter } from '../filters';
import loadEvents from './loadEvents';

const loadInitialEvents = (playerId: number) => async (dispatch) => {
  try {
    dispatch(eventsActions.loadInitialEventsStart());

    const { events, period, count } = await dispatch(
      loadEvents({ playerId, limit: INITIAL_EVENTS_LIMIT, offset: 0 }),
    );

    dispatch(initActiveFilter(period));
    dispatch(eventsActions.loadInitialEventsSuccess(events));
    dispatch(commonActions.updateCountSuccess({ count }));
  } catch (e) {
    dispatch(eventsActions.loadInitialEventsFailure(e));
  }
};

export default loadInitialEvents;
