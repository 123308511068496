import React from 'react';

import { Separator } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  title;
};

const AdjacentMatchUpTitle = ({ title }: Props) => {
  return (
    <>
      <Separator />
      <div className={styles.roundTitle}>{title}</div>
      <Separator />
    </>
  );
};

export default AdjacentMatchUpTitle;
