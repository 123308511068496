import {
  AdditionalBracketData,
  AdjacentMatchUp,
  BracketRound,
  CommonMatchUp,
  MatchUp,
  MatchUpPosition,
} from 'packages/event-standings/types';
import { toCamelCase } from 'packages/utils/Object.utils';
import { TEAM_DEFAULT_LOGO } from 'appConstants';
import { Competitor, Qualifier } from 'types';

import mapSportEvents from './mapSportEvents';

const FINAL_CUP_ROUND_NAME = 'Final';

const mapCupRounds = (cupRounds: any[], additionalData: AdditionalBracketData) => {
  const formattedCupRounds = formatCupRounds(cupRounds);
  const { cupRounds: splittedCupRounds, additionalCupRounds } = splitCupRounds(formattedCupRounds);
  const mappedRounds = mapReversedBracketRounds(splittedCupRounds.reverse(), additionalData);
  const adjacentMatchesPopulatedReversedRounds = populateMatchUpRoundsWithAdjacentMatches(
    mappedRounds,
    additionalCupRounds,
    additionalData,
  );

  return adjacentMatchesPopulatedReversedRounds.reverse();
};

const formatCupRounds = (cupRounds: any[]): BracketRound[] => {
  const camelCasedCupRounds = toCamelCase(cupRounds, true);

  return camelCasedCupRounds.map(({ name, ...rest }) => ({
    name: name.replace('_', ' '),
    ...rest,
  }));
};

const splitCupRounds = (cupRounds: any[]) => {
  const cupRoundsCopy = [...cupRounds];
  const lastCupRound = cupRoundsCopy[cupRoundsCopy.length - 1];
  const preLastCupRound = cupRoundsCopy[cupRoundsCopy.length - 2];
  let additionalCupRounds: any[] = [];

  if (!lastCupRound || !preLastCupRound) {
    return { cupRounds, additionalCupRounds };
  }

  const { matchups: lastCupRoundMatchups } = lastCupRound;
  const { matchups: preLastCupRoundMatchups } = preLastCupRound;
  const [preLastCupRoundMatchup] = preLastCupRoundMatchups;
  const [lastCupRoundMatchup] = lastCupRoundMatchups;

  const isChildrenSame = preLastCupRoundMatchup.childrenMatchupsIds.every((child) =>
    lastCupRoundMatchup.childrenMatchupsIds.includes(child),
  );

  if (isChildrenSame) {
    if (lastCupRound.name === FINAL_CUP_ROUND_NAME) {
      additionalCupRounds = cupRounds.splice(cupRounds.length - 2, 1);
    } else if (preLastCupRound.name === FINAL_CUP_ROUND_NAME) {
      additionalCupRounds = cupRounds.splice(cupRounds.length - 1, 1);
    }

    return { cupRounds, additionalCupRounds };
  }

  return { cupRounds, additionalCupRounds };
};

const mapReversedBracketRounds = (
  bracketRounds: any[],
  additionalData: AdditionalBracketData,
): BracketRound[] => {
  const mappedRounds: BracketRound[] = [];
  let childrenMatchUpsPositions: MatchUpPosition[] | null = null;
  let groupedMatchUps: MatchUp[] | null = null;

  for (const bracketRound of bracketRounds) {
    const { matchups, ...roundData } = bracketRound;
    const mappedMatchUps = mapMatchUps(matchups, additionalData);

    if (childrenMatchUpsPositions !== null) {
      groupedMatchUps = groupMatchUps(mappedMatchUps, childrenMatchUpsPositions);
    } else {
      groupedMatchUps = mappedMatchUps;
    }

    mappedRounds.push({ matchups: groupedMatchUps, ...roundData });

    if (mappedMatchUps.length) {
      childrenMatchUpsPositions = getChildrenMatchUpsPositions(groupedMatchUps);
    }
  }

  return mappedRounds;
};

const populateMatchUpRoundsWithAdjacentMatches = (
  mappedRounds: BracketRound[],
  adjacentRounds: BracketRound[],
  additionalData: AdditionalBracketData,
) => {
  for (const adjacentRound of adjacentRounds) {
    populateMatchUpRoundWithAdjacentMatches(mappedRounds, adjacentRound, additionalData);
  }

  return [...mappedRounds];
};

const populateMatchUpRoundWithAdjacentMatches = (
  mainRounds: BracketRound[],
  adjacentRound: BracketRound,
  additionalData: AdditionalBracketData,
) => {
  const { matchups: adjacentMatchUp, ...roundData } = adjacentRound;
  const mappedAdjacentMatchUps = mapMatchUps(adjacentMatchUp, additionalData);

  for (const mappedAdjacentMatchUp of mappedAdjacentMatchUps) {
    findAndAppendAdjacentMatchUps(mainRounds, { roundData, ...mappedAdjacentMatchUp });
  }
};

const findAndAppendAdjacentMatchUps = (
  mainRounds: BracketRound[],
  adjacentMatchUp: AdjacentMatchUp,
) => {
  const [firstChildrenId, secondChildrenId] = adjacentMatchUp.children;

  if (firstChildrenId || secondChildrenId) {
    for (const mainRound of mainRounds) {
      const adjacentMatchUpIndex = findAdjacentMatchUpIndexByChildren(
        adjacentMatchUp.children,
        mainRound.matchups,
      );

      if (adjacentMatchUpIndex >= 0) {
        appendAdjacentMatchUpByIndex(mainRound, adjacentMatchUp, adjacentMatchUpIndex);
      }
    }
  }
};

const findAdjacentMatchUpIndexByChildren = (
  children: (number | undefined)[],
  matchUps: MatchUp[],
) => {
  const [firstChildrenId, secondChildrenId] = children;

  return matchUps.findIndex(({ children }) => {
    const [firstMappedChildrenId, secondMappedChildrenId] = children;

    return firstMappedChildrenId === firstChildrenId && secondMappedChildrenId === secondChildrenId;
  });
};

const appendAdjacentMatchUpByIndex = (
  mainRound: BracketRound,
  adjacentMatchUp: AdjacentMatchUp,
  index: number,
) => {
  if (mainRound.matchups[index].adjacentMatches) {
    mainRound.matchups[index].adjacentMatches?.push(adjacentMatchUp);
  } else {
    mainRound.matchups[index].adjacentMatches = [adjacentMatchUp];
  }
};

const mapMatchUps = (matchUps: any[], additionalData: AdditionalBracketData): CommonMatchUp[] => {
  return matchUps.map(({ childrenMatchupsIds, ...matchUpData }) => {
    const { matchupEvents, ...camelCasedMatchUpData } = matchUpData;
    const { homeTeam, awayTeam } = mapMatchUpContestants(matchUpData);

    return {
      ...camelCasedMatchUpData,
      sportEvents: mapSportEvents(matchupEvents, {
        ...additionalData,
        firstMatchUpTeam: homeTeam,
        secondMatchUpTeam: awayTeam,
      }),
      children: getValidChildren(childrenMatchupsIds),
      homeTeam,
      awayTeam,
    };
  });
};

const mapMatchUpContestants = (matchUp) => {
  const { matchupEvents, winnerId } = matchUp;
  const totalScore = getTotalScore(matchUp);
  const [matchupEvent] = matchupEvents;
  const [homeTeam, awayTeam] = matchupEvent.event.competitors;

  const mappedHomeTeam = homeTeam ? mapTeam(homeTeam, winnerId, totalScore) : undefined;
  const mappedAwayTeam = homeTeam ? mapTeam(awayTeam, winnerId, totalScore) : undefined;

  return {
    homeTeam: mappedHomeTeam,
    awayTeam: mappedAwayTeam,
  };
};

const getTotalScore = (matchUp): Record<number, number> => {
  const { matchupEvents } = matchUp;

  return matchupEvents.reduce((totalScore, matchupEvent) => {
    const { competitors, eventStatus } = matchupEvent.event;
    const [firstCompetitor, secondCompetitor] = competitors;
    const currentFirstCompetitorScore = totalScore[firstCompetitor.id] || 0;
    const currentSecondCompetitorScore = totalScore[secondCompetitor.id] || 0;

    totalScore[firstCompetitor.id] =
      currentFirstCompetitorScore +
      (firstCompetitor.qualifier === Qualifier.Home
        ? eventStatus.homeScore
        : eventStatus.awayScore);
    totalScore[secondCompetitor.id] =
      currentSecondCompetitorScore +
      (secondCompetitor.qualifier === Qualifier.Home
        ? eventStatus.homeScore
        : eventStatus.awayScore);

    return totalScore;
  }, {});
};

const mapTeam = (
  matchUpTeam: Competitor,
  winnerId: number | null,
  totalScore: Record<number, number>,
) => {
  const { id, name, logo } = toCamelCase(matchUpTeam);

  return {
    id,
    name,
    logo: logo || TEAM_DEFAULT_LOGO,
    isWinner: id === winnerId,
    score: totalScore?.[id],
  };
};

const groupMatchUps = (
  matchUps: CommonMatchUp[],
  matchUpPositions: MatchUpPosition[] = [],
): MatchUp[] => {
  let orderedMatchUps: any = [];
  const matchUpPositionsCopy = [...matchUpPositions];
  let lastMatchUpPosition = matchUpPositionsCopy[matchUpPositionsCopy.length - 1].position;

  for (const matchUp of matchUps) {
    const { id } = matchUp;
    const foundIndex = matchUpPositionsCopy.findIndex(({ childId }) => childId === id);

    if (foundIndex >= 0) {
      orderedMatchUps[matchUpPositionsCopy[foundIndex].position] = matchUp;
      matchUpPositionsCopy.splice(foundIndex, 1);
    } else {
      orderedMatchUps[lastMatchUpPosition++] = matchUp;
    }
  }

  return fillEmptyMatchUpsByPositions(orderedMatchUps, matchUpPositionsCopy);
};

const fillEmptyMatchUpsByPositions = (
  matchUps: CommonMatchUp[],
  matchUpPositions: MatchUpPosition[] = [],
) => {
  const matchUpsCopy: MatchUp[] = [...matchUps];

  for (const emptyMatchUpPosition of matchUpPositions) {
    matchUpsCopy[emptyMatchUpPosition.position] = {
      id: emptyMatchUpPosition.position,
      adjacentMatches: undefined,
      children: [undefined, undefined],
    };
  }

  return matchUpsCopy;
};

const getValidChildren = (children: number[]) => {
  const MAX_CHILDREN_AMOUNT = 2;

  return [...children, ...new Array(MAX_CHILDREN_AMOUNT - children.length)];
};

const getChildrenMatchUpsPositions = (matchUps): MatchUpPosition[] => {
  return matchUps
    .map(({ children }) => children)
    .flat()
    .map((childId, index) => ({ position: index, childId }));
};

export default mapCupRounds;
