import { useLayoutEffect, useRef } from 'react';

import { Jersey } from 'packages/shared/components/Lineup/types';

import { customizeJersey } from '../services';
import { Options } from '../services/customizeJersey';

const useJerseyCustomization = (jersey: Jersey, options?: Options) => {
  const jerseyContainerRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (jerseyContainerRef.current) {
      customizeJersey(jerseyContainerRef.current, jersey, options);
    }
  }, []);

  return { jerseyContainerRef };
};

export default useJerseyCustomization;
