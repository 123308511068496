import React, { memo } from 'react';
import classnames from 'classnames';

import { Event, EventStatus } from 'types';
import {
  LiveEventStatusLabel,
  ScheduleLabel,
  SpecialStatusLabel,
  BreakStatusLabel,
  FinishedEventStatusLabel,
} from 'packages/shared/components';
import { PopoverInvokeTrigger } from 'packages/shared/components/FlexiblePopover/types';

import styles from './styles.scss';

type Props = {
  event: Event;
  popoverInvokeTrigger?: PopoverInvokeTrigger;
  theme?: 'dark' | 'light';
  className?: string;
};

const EventDetailsStatus = ({ event, popoverInvokeTrigger, theme = 'light', className }: Props) => {
  const { status } = event;

  return (
    <div className={classnames(styles.eventDetailsStatus, className)}>
      {(() => {
        switch (status) {
          case EventStatus.NotStarted:
          case EventStatus.Unknown:
            return (
              <ScheduleLabel className={classnames(styles.status, styles.default)} event={event} />
            );
          case EventStatus.FirstHalf:
          case EventStatus.SecondHalf:
          case EventStatus.Overtime:
          case EventStatus.SecondExtra:
          case EventStatus.Penalties:
            return (
              <LiveEventStatusLabel
                className={classnames(styles.status, styles.live)}
                event={event}
              />
            );
          case EventStatus.Halftime:
          case EventStatus.AwaitingExtraTime:
          case EventStatus.ExtraTimeHalfTime:
          case EventStatus.AwaitingPenalties:
            return (
              <BreakStatusLabel
                className={classnames(styles.status, styles.live, styles.md)}
                status={status}
              />
            );
          case EventStatus.Postponed:
          case EventStatus.Suspended:
          case EventStatus.Cancelled:
            return (
              <>
                <SpecialStatusLabel
                  className={classnames(styles.status, styles.md, styles[theme])}
                  popoverInvokeTrigger={popoverInvokeTrigger}
                  status={status}
                  isShort
                />
                <div className={styles.gap} />
                <ScheduleLabel
                  className={classnames(styles.status, styles.default)}
                  event={event}
                />
              </>
            );
          case EventStatus.Ended:
          case EventStatus.AfterExtraTime:
          case EventStatus.AfterPenalties:
            return (
              <>
                <FinishedEventStatusLabel
                  className={classnames(styles.status, styles.md, styles[theme])}
                  event={event}
                />
                <div className={styles.gap} />
                <ScheduleLabel
                  className={classnames(styles.status, styles.default)}
                  event={event}
                />
              </>
            );
        }
      })()}
    </div>
  );
};

export default memo(EventDetailsStatus);
