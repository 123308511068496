import { createAction } from 'redux-actions';
import { NAMESPACE } from '../constants';

export const loadPopularTournamentsStart = createAction(`${NAMESPACE}POPULAR_TOURNAMENTS_START`);
export const loadPopularTournamentsSuccess = createAction(
  `${NAMESPACE}POPULAR_TOURNAMENTS_SUCCESS`,
);
export const loadPopularTournamentsFailure = createAction(
  `${NAMESPACE}POPULAR_TOURNAMENTS_FAILURE`,
);
