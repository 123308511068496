import { toCamelCase } from 'packages/utils/Object.utils';

import { Interfaces } from '../types';

const mapFilters = (playerFilters: object) => {
  const { playersCountries } = toCamelCase(playerFilters);

  return {
    nationalities: playersCountries.map(mapNationality),
  };
};

const mapNationality = (nationality): Interfaces.Filters.Nationality => {
  const { id, name, logo } = toCamelCase(nationality);

  return { id, name, logo };
};

export default mapFilters;
