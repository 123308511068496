import React, { memo } from 'react';

import { Grid } from 'modules/shared/layouts';
import { Settings } from 'packages/settings-ui';
import { GridColumnId } from 'types';

const Categories = () => {
  return (
    <Grid.SingleColumn isSecondaryOpen={false}>
      <div key={GridColumnId.Primary}>
        <Settings.Tablet />
      </div>
      <></>
    </Grid.SingleColumn>
  );
};

export default memo(Categories);
