import { SelectItem } from 'packages/shared/components/Select/types';

const getLimitSelectItem = (limit: number): SelectItem => {
  return {
    label: `${limit}`,
    id: limit,
  };
};

export default getLimitSelectItem;
