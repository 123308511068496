exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1Q7oVrY2{padding:16px}._1Q7oVrY2 ._3ZIBOcfI{margin-top:24px}._1Q7oVrY2 ._17mrt_0q{margin-top:24px}._1Q7oVrY2 ._omaHGuOQ{margin-top:24px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/season-team-statistics-ui/containers/TeamStatistics/components/ExtendedFilter/components/FilterArea/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,YCJU,CDEd,sBAKM,eAAgB,CALtB,sBASM,eAAgB,CATtB,sBAaM,eAAgB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .filter-area-container {\n    padding: $padding;\n\n    .filter-group-separator {\n      margin-top: 24px;\n    }\n\n    .customization-presets-container {\n      margin-top: 24px;\n    }\n\n    .columns-arrangement-container {\n      margin-top: 24px;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"filter-area-container": "_1Q7oVrY2",
	"filterAreaContainer": "_1Q7oVrY2",
	"filter-group-separator": "_3ZIBOcfI",
	"filterGroupSeparator": "_3ZIBOcfI",
	"customization-presets-container": "_17mrt_0q",
	"customizationPresetsContainer": "_17mrt_0q",
	"columns-arrangement-container": "_omaHGuOQ",
	"columnsArrangementContainer": "_omaHGuOQ"
};