import React, { memo, useMemo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Interfaces as StadiumSummaryInterfaces } from 'packages/stadium-summary/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { Icon } from 'packages/shared/components';

import { calculateAverageAttendancePercentage } from './services';
import { LoadingSkeleton } from './components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  attendance?: StadiumSummaryInterfaces.Statistics.Attendance;
  className?: string;
  capacity?: number;
  isLoading: boolean;
};

const Attendance = ({ attendance, capacity, className, isLoading }: Props) => {
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (!attendance) {
    return null;
  }

  const { averageAttendance } = attendance;

  const averageAttendancePercentage = useMemo(
    () => calculateAverageAttendancePercentage(averageAttendance, capacity),
    [capacity, averageAttendance],
  );

  return (
    <div className={classnames(styles.attendanceContainer, className)}>
      <div className={styles.title}>{i18n.value('stadium.statistics.attendance.title')}</div>
      <div className={styles.attendance}>
        <div className={styles.stadium}>
          <Icon
            id={IconType.Stadium}
            preserveAspectRatio="xMinYMid slice"
            style={{ width: `${averageAttendancePercentage}%` }}
            className={classnames(styles.icon, styles.active)}
          />
          <div
            className={styles.separator}
            style={{
              left: `${averageAttendancePercentage}%`,
              visibility: averageAttendancePercentage ? 'visible' : 'hidden',
            }}
          />
          <Icon id={IconType.Stadium} className={classnames(styles.icon, styles.disabled)} />
        </div>
        <div className={styles.visitors}>
          <div className={styles.value}>
            {averageAttendance}{' '}
            <span className={styles.label}>
              {i18n.pluralize('stadium.statistics.attendance.visitorsLabel', {
                value: averageAttendance,
              })}
            </span>
          </div>
          <div className={styles.description}>
            {i18n.value('stadium.statistics.attendance.visitorsDescription', [
              averageAttendancePercentage,
            ])}
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(memo(Attendance));
