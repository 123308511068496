import React, { memo, RefObject } from 'react';
import classnames from 'classnames';

import { ToggleArrowButton, Input, Icon } from 'packages/shared/components';
import { ArrowType } from 'packages/shared/components/ToggleArrowButton/types';
import { PopoverState } from 'packages/shared/components/FlexiblePopover/types';

import { IconType } from 'packages/shared/components/Icon/types';
import { SelectTheme } from '../../../../types';
import { SelectedResults } from './components';
import styles from './styles.scss';

type Props = {
  searchValue: string;
  inputPlaceholder?: string;
  allSelectedLabel: string;
  itemsSelectLabel: string;
  selectedItemsCount: number;
  popoverState?: PopoverState;
  theme: SelectTheme;
  getInputHandler: () => (value: string) => void;
  onCrossClick: () => void;
  wrapperForwardedRef: RefObject<HTMLDivElement>;
  inputRef?: RefObject<HTMLInputElement>;
};

const SelectInput = ({
  itemsSelectLabel,
  popoverState,
  searchValue,
  getInputHandler,
  inputPlaceholder,
  theme,
  selectedItemsCount,
  allSelectedLabel,
  onCrossClick,
  wrapperForwardedRef,
  inputRef,
}: Props) => {
  const isOpen = popoverState === PopoverState.Opened;

  return (
    <div ref={wrapperForwardedRef} className={classnames(styles.inputWrapper, styles[theme])}>
      {isOpen && (
        <Input.Text
          inputRef={inputRef}
          inputClassName={styles.input}
          value={searchValue}
          onChange={getInputHandler()}
          placeholder={inputPlaceholder}
          name="searchSelect"
          isAutoFocused
        />
      )}

      <SelectedResults
        className={classnames({ [styles.hidden]: isOpen })}
        theme={theme}
        selectedTitle={itemsSelectLabel}
        selectedItemsCount={selectedItemsCount}
        title={allSelectedLabel}
      />

      {!isOpen && !!selectedItemsCount && (
        <div className={styles.crossWrapper}>
          <Icon className={styles.cross} onMouseUp={onCrossClick} id={IconType.RoundedCross} />
        </div>
      )}

      <div className={styles.arrowWrapper}>
        <ToggleArrowButton isOpen={isOpen} type={ArrowType.Dropdown} />
      </div>
    </div>
  );
};

export default memo(SelectInput);
