import React, { useCallback, useEffect, useMemo } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';
import i18n from 'i18n-smart';
import { Row } from 'react-table';

import {
  PlayersParameter,
  TopOfTheSeason as TopOfTheSeasonView,
  TopPlayer,
  TopTeam,
  TopVenue,
} from 'packages/season-top-board/types';
import { MobilePage } from 'router/types';
import { useNavigation } from 'packages/hooks';
import { MediaScreen } from 'types';

import { LoadingSkeleton } from '../../../../components';
import { getVenuesSchema, getTeamCompactGoals } from '../../../../services';
import { MiniTopTable, CompactTopPlayers } from './components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  topOfTheSeason: TopOfTheSeasonView;
  isTopOfTheSeasonLoading: boolean;
  compactPlayers: TopPlayer[];
  isCompactPlayersLoading: boolean;
  seasonTopBoardActions: ActionCreatorsMapObject;
};

const TopBoard = ({
  topOfTheSeason,
  isTopOfTheSeasonLoading,
  compactPlayers,
  isCompactPlayersLoading,
  seasonTopBoardActions,
}: Props) => {
  const { seasonId = '', tournamentId = '' } = useParams();
  const { appNavigate } = useNavigation(MediaScreen.Mobile);
  const { teams, venues } = topOfTheSeason;

  const [teamColumns, venueColumns] = useMemo(() => [getTeamCompactGoals(), getVenuesSchema()], []);

  useEffect(() => {
    seasonTopBoardActions.loadTopOfTheSeason(seasonId);
    seasonTopBoardActions.loadCompactTopPlayers({ seasonId });

    return () => {
      seasonTopBoardActions.cleanTopBoard();
    };
  }, [seasonId]);

  const handleTeamStatisticsLinkClick = useCallback(() => {
    appNavigate(MobilePage.FootballSeasonTeamStatistics, {
      seasonId,
      uniqueTournamentId: tournamentId,
    });
  }, [seasonId, tournamentId]);

  const handleStadiumsLinkClick = useCallback(() => {
    appNavigate(MobilePage.FootballSeasonStadiums, { seasonId, uniqueTournamentId: tournamentId });
  }, [seasonId, tournamentId]);

  const getTopTeamRowProps = useCallback(
    ({ original }: Row<TopTeam>) => {
      const { team } = original;

      return {
        onClick: () => {
          appNavigate(MobilePage.FootballTeamSummaryDescription, {
            teamId: team.id,
            uniqueTournamentId: tournamentId,
            seasonId,
          });
        },
      };
    },
    [seasonId, tournamentId],
  );

  const getTopVenueRowProps = useCallback(
    ({ original }: Row<TopVenue>) => {
      const { venue } = original;

      return {
        onClick: () => {
          appNavigate(MobilePage.FootballStadiumSummary, {
            stadiumId: venue.id,
            seasonId,
            uniqueTournamentId: tournamentId,
          });
        },
      };
    },
    [seasonId, tournamentId],
  );

  const handleCompactPlayersOrderingUpdate = useCallback(
    (seasonId: number | string, sortingParameter: PlayersParameter) => {
      seasonTopBoardActions.loadCompactTopPlayers({ seasonId, sortingParameter });
    },
    [seasonId],
  );

  return (
    <div className={styles.topOfTheSeason}>
      {isTopOfTheSeasonLoading ? (
        <>
          <LoadingSkeleton elementsCount={3} isCompact />
          <LoadingSkeleton elementsCount={3} isCompact />
          <LoadingSkeleton elementsCount={3} isCompact />
        </>
      ) : (
        <>
          <CompactTopPlayers
            players={compactPlayers}
            isLoading={isCompactPlayersLoading}
            onOrderingUpdate={handleCompactPlayersOrderingUpdate}
          />
          <MiniTopTable<TopTeam>
            title={i18n.value('season.seasonTopBoard.headers.topTeams')}
            setRowProps={getTopTeamRowProps}
            linkLabel={i18n.value('season.seasonTopBoardCompact.showAll.teams')}
            handleLinkClick={handleTeamStatisticsLinkClick}
            rows={teams}
            columns={teamColumns}
          />
          <MiniTopTable<TopVenue>
            title={i18n.value('season.seasonTopBoard.headers.topVenues')}
            setRowProps={getTopVenueRowProps}
            linkLabel={i18n.value('season.seasonTopBoardCompact.showAll.venues')}
            handleLinkClick={handleStadiumsLinkClick}
            rows={venues}
            columns={venueColumns}
          />
        </>
      )}
    </div>
  );
};

export default connect(TopBoard);
