import React, { memo } from 'react';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { TournamentSeasonDetails } from 'packages/stadium-summary-ui';

import styles from './styles.scss';

const StadiumTournamentSeasonSelect = () => {
  return (
    <Grid.SingleColumn isSecondaryOpen={false}>
      <div key={GridColumnId.Primary} className={styles.primary}>
        <TournamentSeasonDetails.Mobile />
      </div>
      <></>
    </Grid.SingleColumn>
  );
};

export default memo(StadiumTournamentSeasonSelect);
