import { EventTableView } from '../types';

const serializeEventToTableModel = (event: EventTableView): void => {
  const { teams, tournament, category } = event;
  const { uniqueTournamentId } = tournament;
  const [homeTeam, awayTeam] = teams;

  event.uniqueTournamentId = uniqueTournamentId;
  event.categoryId = category.id;
  event.homeTeamId = homeTeam.id;
  event.awayTeamId = awayTeam.id;
};

export default serializeEventToTableModel;
