import { BaseHappening, PlayerType } from 'packages/event-time-line/types';

const SUBSTITUTION_HAPPENING_TYPE = 'SUBSTITUTION';

type SubstitutionPlayer = {
  name: string;
  playerId: number;
};

export const getSubstitutionPlayerNameFromHappenings = (
  playerId,
  happenings?: BaseHappening[],
): SubstitutionPlayer | undefined => {
  const substitutionHappening = happenings?.find(
    ({ eventType }) => eventType === SUBSTITUTION_HAPPENING_TYPE,
  );

  const substitutionPlayer = substitutionHappening?.players?.find(
    ({ playerId: substitutionPlayerId }) => playerId !== substitutionPlayerId,
  );

  if (substitutionPlayer) {
    const { playerType, shortName, playerId } = substitutionPlayer;

    return {
      name: `${playerType === PlayerType.SubstitutedIn ? '←' : '→'} ${shortName}`,
      playerId,
    };
  }
};
