import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import { StatisticsCell } from './components';
import styles from './styles.scss';

const DetailsSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.info}>
          <div className={styles.stadium}>
            <div>
              <Skeleton height={32} width={250} marginBottom={20} variant="rectangular" />
              <div className={styles.details}>
                <Skeleton height={10} width={150} marginRight={12} variant="rectangular" />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.statistics}>
          <StatisticsCell />
          <StatisticsCell />
          <StatisticsCell />
        </div>
      </div>
    </div>
  );
};

export default memo(DetailsSkeleton);
