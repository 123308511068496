import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState = {
  isEventHeadToHeadLoading: true,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${actions.loadEventHeadToHeadStart}`]: () => {
    return { isEventHeadToHeadLoading: true };
  },
  [`${combineActions(
    actions.loadEventHeadToHeadSuccess,
    actions.loadEventHeadToHeadFailure,
  )}`]: () => {
    return { isEventHeadToHeadLoading: false };
  },
  [`${actions.cleanEventHeadToHead}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
