import { LineupPlayer, LineupRow } from 'packages/shared/components/Lineup/types';

const groupPlayersByLine = (formation: string, startingLineup: LineupPlayer[]): LineupRow[] => {
  if (!formation || !startingLineup) {
    return [];
  }

  const linesSchema = getLinesSchema(formation);

  return createLines(linesSchema, startingLineup);
};

const getLinesSchema = (formation: string): number[] => {
  return [1, ...formation.split('-').map((item) => Number(item))];
};

function createLines(linesSchema: number[], players: LineupPlayer[]): LineupRow[] {
  const tempLineupInfos: LineupPlayer[] = [...players];

  return linesSchema.map((playersOnTheLine, index) => ({
    id: index,
    lineupPlayers: tempLineupInfos.splice(0, playersOnTheLine),
  }));
}

export default groupPlayersByLine;
