import { EventTeam } from 'types';
import { mapEventTeams } from 'packages/events/services';
import { toCamelCase } from 'packages/utils/Object.utils';
import DateService from 'packages/date-az';

import { Event, HeadToHead } from '../../types';
import { getMatchResult, groupEventsByYears } from '..';

const mapEventHeadToHead = (
  headToHeadEvents,
  homeLastFiveEvents,
  awayLastFiveEvents,
  homeTeam,
  awayTeam,
): HeadToHead => {
  return [
    {
      id: 0,
      props: {
        team: homeTeam,
        yearsGroupedEvents: mapEvents(homeLastFiveEvents),
      },
    },
    {
      id: 1,
      props: {
        yearsGroupedEvents: mapEvents(headToHeadEvents),
      },
    },
    {
      id: 2,
      props: {
        team: awayTeam,
        yearsGroupedEvents: mapEvents(awayLastFiveEvents),
      },
    },
  ];
};

const mapEvents = (events) => {
  const mappedEvents = events.map((event) => mapEvent(event));

  return groupEventsByYears(mappedEvents);
};

const mapEvent = (event): Event => {
  const {
    id,
    scheduled,
    competitors,
    eventStatus,
    uniqueTournament,
    seasonId,
    category,
  } = toCamelCase(event, true);

  const teams = mapEventTeams(competitors, eventStatus) as [EventTeam, EventTeam];

  return {
    id,
    date: DateService.parse(new Date(scheduled).toISOString()),
    teams: teams,
    tournamentName: uniqueTournament?.name,
    uniqueTournamentId: uniqueTournament?.id,
    seasonId,
    categoryName: category?.name,
    matchResult: getMatchResult(teams, eventStatus),
    status: toCamelCase(eventStatus),
  };
};

export default mapEventHeadToHead;
