import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const customFilterUpdate = createAction(`${NAMESPACE}CUSTOM_FILTER_UPDATE`);
export const updateConfigurableOrdering = createAction(`${NAMESPACE}UPDATE_CONFIGURABLE_ORDERING`);
export const setCommonOrdering = createAction(`${NAMESPACE}SET_COMMON_ORDERING`);
export const columnConfigurationFilterPresetUpdate = createAction(
  `${NAMESPACE}COLUMN_CONFIGURATION_FILTER_PRESET_UPDATE`,
);
