import BaseDifferenceExtractor from 'packages/socket/core/differenceExtractors/BaseDifferenceExtractor';

class MapDifferenceExtractor<ItemKey extends string, ItemValue> extends BaseDifferenceExtractor<
  Map<ItemKey, ItemValue>,
  ItemValue
> {
  public getDataUpdate(itemMap: Map<ItemKey, ItemValue>, updatedItemsMap: Map<ItemKey, ItemValue>) {
    const added: Map<ItemKey, ItemValue> = new Map();
    const updated: Map<ItemKey, ItemValue> = new Map();

    for (const [updatedItemKey, updatedItemValue] of updatedItemsMap.entries()) {
      const previousMapItemValue = itemMap.get(updatedItemKey);

      if (previousMapItemValue) {
        if (!this.dataComparisonPredicate(previousMapItemValue, updatedItemValue)) {
          updated.set(updatedItemKey, updatedItemValue);
        }

        itemMap.delete(updatedItemKey);
      } else {
        added.set(updatedItemKey, updatedItemValue);
      }
    }

    return {
      added,
      updated,
      removed: itemMap,
    };
  }
}

export default MapDifferenceExtractor;
