import React, { memo } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { SocialMedia as SocialMediaType } from 'packages/player-details/types';
import { SOCIAL_MEDIA_ICON_LINK_TYPE } from 'appConstants';
import { Icon } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  links: SocialMediaType;
  className?: string;
};

const SocialMedia = ({ links, className }: Props) => {
  return (
    <div className={classnames(styles.socialMediaContainer, className)}>
      <div className={styles.header}>
        {i18n.value('player.playerSummary.description.socialMedia.title')}
      </div>
      <div className={styles.linkList}>
        {Object.entries(links).map(([linkType, link]) => (
          <a key={linkType} className={styles.linkIcon} href={link}>
            <Icon id={SOCIAL_MEDIA_ICON_LINK_TYPE[linkType]} />
          </a>
        ))}
      </div>
    </div>
  );
};

export default memo(SocialMedia);
