import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadMatchStatisticsStart,
  loadMatchStatisticsSuccess,
  loadMatchStatisticsFailure,
  cleanMatchStatisticsSuccess,
} from '../actions';
import { Status } from '../types';

const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${loadMatchStatisticsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(loadMatchStatisticsSuccess, loadMatchStatisticsFailure)}`]: () => {
    return { isLoading: false };
  },
  [`${cleanMatchStatisticsSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
