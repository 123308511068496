import { allEventsActions } from '../../actions';
import { LiveEventsUpdateEventType } from '../../types/enums';
import { updateAllEvents } from './updateAllEvents';

export const subscribeForAllEventsUpdates = () => (dispatch) => {
  try {
    dispatch(allEventsActions.subscribeForAllEventsUpdatesStart());

    const handleUpdatedData = (event) => {
      const { detail } = event as CustomEvent;

      dispatch(updateAllEvents(detail));
    };

    document.addEventListener(LiveEventsUpdateEventType.Updated, handleUpdatedData);
    document.addEventListener(LiveEventsUpdateEventType.Inserted, handleUpdatedData);
    document.addEventListener(LiveEventsUpdateEventType.Removed, handleUpdatedData);

    dispatch(allEventsActions.subscribeForAllEventsUpdatesSuccess());

    return () => {
      document.removeEventListener(LiveEventsUpdateEventType.Updated, handleUpdatedData);
      document.removeEventListener(LiveEventsUpdateEventType.Inserted, handleUpdatedData);
      document.removeEventListener(LiveEventsUpdateEventType.Removed, handleUpdatedData);
    };
  } catch (error) {
    dispatch(allEventsActions.subscribeForAllEventsUpdatesFailure(error));
  }
};
