import { DesktopPage } from '../enums';

export interface DesktopPathParams {
  [DesktopPage.Football]: {};

  [DesktopPage.FootballSeason]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopPage.FootballSeasonPlayerStatistics]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopPage.FootballSeasonTeamStatistics]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopPage.FootballSeasonStadiums]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopPage.FootballSeasonStandings]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopPage.FootballSeasonGames]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [DesktopPage.FootballTeam]: {
    teamId: string | number;
  };
  [DesktopPage.FootballTeamGames]: {
    teamId: string | number;
  };
  [DesktopPage.FootballTeamSquad]: {
    teamId: string | number;
  };
  [DesktopPage.FootballTeamSummaryDescription]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
    teamId: string | number;
  };
  [DesktopPage.FootballTeamStandings]: {
    teamId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopPage.FootballTeamPlayerStatistics]: {
    teamId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [DesktopPage.FootballCategory]: {
    categoryId: string | number;
  };

  [DesktopPage.FootballPlayer]: {
    playerId: string | number;
  };
  [DesktopPage.FootballPlayerSummary]: {
    playerId: string | number;
  };
  [DesktopPage.FootballPlayerGames]: {
    playerId: string | number;
  };

  [DesktopPage.FootballStadium]: {
    stadiumId: string | number;
  };
  [DesktopPage.FootballStadiumSummary]: {
    stadiumId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [DesktopPage.FootballMatch]: {
    eventId: string | number;
  };
}
