import React, { memo, useMemo } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { Weight } from 'packages/shared/components/Separator/types';
import { Interfaces as StadiumSummaryInterfaces } from 'packages/stadium-summary/types';
import { Separator, StatsCompareRow } from 'packages/shared/components';

import { getLineTypes, getLineVolumes } from './services';
import { LoadingSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  winLose?: StadiumSummaryInterfaces.Statistics.WinLose;
  className?: string;
  isLoading: boolean;
};

const WinLose = ({ winLose, className, isLoading }: Props) => {
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (!winLose) {
    return null;
  }

  const {
    homeWins,
    draws,
    awayWins,
    homeGoals,
    awayGoals,
    homeCornerKicks,
    awayCornerKicks,
    homeGoalsPercentage,
    awayGoalsPercentage,
    homeCornerKicksPercentage,
    awayCornerKicksPercentage,
  } = winLose;

  const lineTypes = useMemo(() => getLineTypes({ homeWins, draws, awayWins }), [
    homeWins,
    draws,
    awayWins,
  ]);
  const lineVolumes = useMemo(() => getLineVolumes({ homeWins, draws, awayWins }), [
    homeWins,
    draws,
    awayWins,
  ]);

  return (
    <div className={classnames(styles.winLoseContainer, className)}>
      <div className={styles.title}>wins/loses stats</div>
      <div className={styles.matchResults}>
        <div className={classnames(styles.result, styles.home)}>
          <div className={styles.value}>{homeWins}</div>
          <div className={styles.label}>Home wins</div>
        </div>
        <div className={classnames(styles.result, styles.draw)}>
          <div className={styles.value}>{draws}</div>
          <div className={styles.label}>Draws</div>
        </div>
        <div className={classnames(styles.result, styles.away)}>
          <div className={styles.value}>{awayWins}</div>
          <div className={styles.label}>Away wins</div>
        </div>
      </div>
      <div className={styles.matchResultsCompareLines}>
        <div
          className={classnames(styles.compareLine, styles[lineTypes.homeWins || ''])}
          style={{ flex: lineVolumes.homeWins }}
        />
        <div
          className={classnames(styles.compareLine, styles[lineTypes.draws || ''])}
          style={{ flex: lineVolumes.draws }}
        />
        <div
          className={classnames(styles.compareLine, styles[lineTypes.awayWins || ''])}
          style={{ flex: lineVolumes.awayWins }}
        />
      </div>
      <Separator weight={Weight.Bold} className={styles.separator} />
      <div className={styles.parameterComparison}>
        <div className={styles.teamSideLabel}>
          <span>{i18n.value('stadium.statistics.statisticsLabel.home')}</span>
          <span>{i18n.value('stadium.statistics.statisticsLabel.away')}</span>
        </div>
        <StatsCompareRow
          className={styles.statisticsRow}
          parameter={{
            key: 'stadium.statistics.winLose.statisticsLabel.goals',
            home: {
              formattedValue: homeGoals,
              percentages: homeGoalsPercentage,
              isLeader: homeGoals > awayGoals,
            },
            away: {
              formattedValue: awayGoals,
              percentages: awayGoalsPercentage,
              isLeader: awayGoals > homeGoals,
            },
          }}
        />
        <StatsCompareRow
          className={styles.statisticsRow}
          parameter={{
            key: 'stadium.statistics.winLose.statisticsLabel.cornerKicks',
            home: {
              formattedValue: homeCornerKicks,
              percentages: homeCornerKicksPercentage,
              isLeader: homeCornerKicks > awayCornerKicks,
            },
            away: {
              formattedValue: awayCornerKicks,
              percentages: awayCornerKicksPercentage,
              isLeader: awayCornerKicks > homeCornerKicks,
            },
          }}
        />
      </div>
    </div>
  );
};

export default memo(WinLose);
