import { DataReducerFactoryConfig, StorageDataType } from 'packages/socket/types';
import DifferentiatedMapUpdatesSelector from './DifferentiatedMapUpdatesSelector';
import DifferentiatedUpdatesSelector from './DifferentiatedUpdatesSelector';
import DifferentiatedObjectUpdatesSelector from './DifferentiatedObjectUpdatesSelector';

export default <ItemType>(
  config: DataReducerFactoryConfig<any>,
): DifferentiatedUpdatesSelector<any> => {
  const { storageDataType, ...dataReducerConfig } = config;

  switch (storageDataType) {
    case StorageDataType.Map:
      return new DifferentiatedMapUpdatesSelector<string, ItemType>(dataReducerConfig);
    case StorageDataType.Object:
      return new DifferentiatedObjectUpdatesSelector<ItemType>(dataReducerConfig);
    default:
      return new DifferentiatedMapUpdatesSelector<string, ItemType>(dataReducerConfig);
  }
};
