import { commonSelectors } from '../../selectors';
import { EVENT_STORAGE_GROUP_KEYS } from '../../constants';
import { Status, StorageEventGroup } from '../../types';

export const getFavoriteEventsStatus = (state): Status => {
  return commonSelectors.getEventsStatusByGroup(
    state,
    EVENT_STORAGE_GROUP_KEYS[StorageEventGroup.Favorites],
  );
};

export const getIsLoading = (state): boolean => {
  return getFavoriteEventsStatus(state).isLoading;
};
