import React, { memo, useMemo } from 'react';

import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { Tabs } from 'packages/shared/components';
import { CommentaryGroups } from 'packages/event-commentary/types';
import { Event } from 'types';

import { getTabsData } from './services';

type Props = {
  activeEvent: Event;
  commentaryGroups: CommentaryGroups;
};

const Commentaries = ({ commentaryGroups, activeEvent }: Props) => {
  const tabs = useMemo(() => getTabsData(commentaryGroups, { activeEvent }), [
    commentaryGroups,
    activeEvent,
  ]);

  return <Tabs tabs={tabs} theme={NavigationTheme.Button} isNavigationWidthLimited />;
};

export default memo(Commentaries);
