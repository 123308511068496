import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';

import { Data } from '../types';
import getPath from './getPath';

export const getData = (state): Data => {
  return getPath(state).data;
};

export const getTournamentList = (state): SeasonsExtendedTournament[] | undefined => {
  return getData(state)?.tournamentList;
};
