import React, { useCallback } from 'react';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from './styles.scss';

type Props = {
  onClick: () => void;
  isDisabled?: boolean;
  className?: string;
  isFavorite?: boolean;
};

const FavoriteIcon = ({ onClick, isFavorite, isDisabled = false, className }: Props) => {
  const handleOnClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      onClick();
    },
    [onClick],
  );

  const iconClasses = classnames(styles.favoriteIcon, className, {
    [styles.active]: isFavorite,
    [styles.disabled]: isDisabled,
  });
  const iconId = isFavorite ? IconType.Star : IconType.StarOutline;

  return <Icon id={iconId} className={iconClasses} onClick={handleOnClick} />;
};

export default FavoriteIcon;
