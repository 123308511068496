import { StatisticsParameterKey } from 'types';
import { Interfaces, Enums } from '../types';

const groupWholePlayerStatistics = (
  statistics: Interfaces.Statistics.Statistics.Statistics,
): Interfaces.Statistics.Statistics.Data => {
  const {
    gamesPlayed,
    minutesPlayed,
    goals,
    assists,
    goalPoints,
    goalsByHeader,
    penaltyGoals,
    totalShots,
    shotsOffGoal,
    shotsOnGoal,
    goalsConversion,
    offside,
    yellowCards,
    redCards,

    minutesPlayedPerGame,
    goalsPerGame,
    assistsPerGame,
    goalPointsPerGame,
    goalsByHeaderPerGame,
    penaltyGoalsPerGame,
    totalShotsPerGame,
    shotsOffGoalPerGame,
    shotsOnGoalPerGame,
    offsidePerGame,
    yellowCardsPerGame,
    redCardsPerGame,

    goalsPerNinety,
    assistsPerNinety,
    goalPointsPerNinety,
    goalsByHeaderPerNinety,
    penaltyGoalsPerNinety,
    totalShotsPerNinety,
    shotsOffGoalPerNinety,
    shotsOnGoalPerNinety,
    offsidePerNinety,
    yellowCardsPerNinety,
    redCardsPerNinety,
  } = statistics;

  return {
    [Enums.Statistics.Statistics.StatType.All]: [
      [
        {
          key: StatisticsParameterKey.GamesPlayed,
          value: gamesPlayed,
        },
        {
          key: StatisticsParameterKey.MinutesPlayed,
          value: minutesPlayed,
        },
        {
          key: StatisticsParameterKey.Goals,
          value: goals,
        },
        {
          key: StatisticsParameterKey.Assists,
          value: assists,
        },
        {
          key: StatisticsParameterKey.GoalPoints,
          value: goalPoints,
        },
        {
          key: StatisticsParameterKey.GoalsByHead,
          value: goalsByHeader,
        },
      ],
      [
        {
          key: StatisticsParameterKey.PenaltyGoals,
          value: penaltyGoals,
        },
        {
          key: StatisticsParameterKey.TotalShots,
          value: totalShots,
        },
        {
          key: StatisticsParameterKey.ShotsOffGoal,
          value: shotsOffGoal,
        },
        {
          key: StatisticsParameterKey.ShotsOnGoal,
          value: shotsOnGoal,
        },
        {
          key: StatisticsParameterKey.GoalsConversion,
          value: goalsConversion,
        },
      ],
      [
        {
          key: StatisticsParameterKey.Offside,
          value: offside,
        },
        {
          key: StatisticsParameterKey.YellowCards,
          value: yellowCards,
        },
        {
          key: StatisticsParameterKey.DirectRedCards,
          value: redCards,
        },
      ],
    ],
    [Enums.Statistics.Statistics.StatType.PerGame]: [
      [
        {
          key: StatisticsParameterKey.GamesPlayed,
          value: gamesPlayed,
        },
        {
          key: StatisticsParameterKey.MinutesPlayed,
          value: minutesPlayedPerGame,
        },
        {
          key: StatisticsParameterKey.Goals,
          value: goalsPerGame,
        },
        {
          key: StatisticsParameterKey.Assists,
          value: assistsPerGame,
        },
        {
          key: StatisticsParameterKey.GoalPoints,
          value: goalPointsPerGame,
        },
        {
          key: StatisticsParameterKey.GoalsByHead,
          value: goalsByHeaderPerGame,
        },
      ],
      [
        {
          key: StatisticsParameterKey.PenaltyGoals,
          value: penaltyGoalsPerGame,
        },
        {
          key: StatisticsParameterKey.TotalShots,
          value: totalShotsPerGame,
        },
        {
          key: StatisticsParameterKey.ShotsOffGoal,
          value: shotsOffGoalPerGame,
        },
        {
          key: StatisticsParameterKey.ShotsOnGoal,
          value: shotsOnGoalPerGame,
        },
        {
          key: StatisticsParameterKey.GoalsConversion,
          value: goalsConversion,
        },
      ],
      [
        {
          key: StatisticsParameterKey.Offside,
          value: offsidePerGame,
        },
        {
          key: StatisticsParameterKey.YellowCards,
          value: yellowCardsPerGame,
        },
        {
          key: StatisticsParameterKey.DirectRedCards,
          value: redCardsPerGame,
        },
      ],
    ],
    [Enums.Statistics.Statistics.StatType.PerNinetyMinutes]: [
      [
        {
          key: StatisticsParameterKey.GamesPlayed,
          value: gamesPlayed,
        },
        {
          key: StatisticsParameterKey.Goals,
          value: goalsPerNinety,
        },
        {
          key: StatisticsParameterKey.Assists,
          value: assistsPerNinety,
        },
        {
          key: StatisticsParameterKey.GoalPoints,
          value: goalPointsPerNinety,
        },
        {
          key: StatisticsParameterKey.GoalsByHead,
          value: goalsByHeaderPerNinety,
        },
      ],
      [
        {
          key: StatisticsParameterKey.PenaltyGoals,
          value: penaltyGoalsPerNinety,
        },
        {
          key: StatisticsParameterKey.TotalShots,
          value: totalShotsPerNinety,
        },
        {
          key: StatisticsParameterKey.ShotsOffGoal,
          value: shotsOffGoalPerNinety,
        },
        {
          key: StatisticsParameterKey.ShotsOnGoal,
          value: shotsOnGoalPerNinety,
        },
        {
          key: StatisticsParameterKey.GoalsConversion,
          value: goalsConversion,
        },
        {
          key: StatisticsParameterKey.Offside,
          value: offsidePerNinety,
        },
      ],
      [
        {
          key: StatisticsParameterKey.YellowCards,
          value: yellowCardsPerNinety,
        },
        {
          key: StatisticsParameterKey.DirectRedCards,
          value: redCardsPerNinety,
        },
      ],
    ],
  };
};

export default groupWholePlayerStatistics;
