import isEmpty from 'is-empty-typed/lib';

import { toCamelCase } from 'packages/utils/Object.utils';
import { format } from 'packages/utils/Number.utils';
import { Team } from 'types';

import { Stadium } from '../types';

const mapStadiums = (stadiums: unknown[]): Stadium[] => {
  return stadiums.map((stadium) => {
    const {
      average,
      city,
      capacity,
      countryLogo,
      constructionYear,
      homeTeams,
      name,
      occupancy,
      venueId,
    } = toCamelCase(stadium as object);

    return {
      average: isEmpty(average) ? undefined : format(Math.round(average)),
      capacity: format(capacity),
      city,
      countryLogo,
      constructionYear: isEmpty(constructionYear) ? undefined : constructionYear,
      homeTeams: mapHomeTeam(homeTeams),
      name,
      occupancy: isEmpty(occupancy) ? undefined : Math.round(occupancy),
      id: venueId,
    };
  });
};

const mapHomeTeam = (homeTeams: unknown[]): Team[] => {
  return homeTeams.map((team) => toCamelCase(team as object));
};

export default mapStadiums;
