import React from 'react';

import { LineupRowsExtendedCompetitor } from 'packages/event-lineups/types';
import { Separator, Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';
import { Weight } from 'packages/shared/components/Separator/types';

import TeamDetails from './components/TeamDetails';
import Lineups from './components/Lineups';
import styles from './styles.scss';

type Props = {
  competitors: [LineupRowsExtendedCompetitor, LineupRowsExtendedCompetitor];
  isFormationHidden?: boolean;
};

const Lineup = ({ competitors, isFormationHidden }: Props) => {
  const [homeCompetitor, awayCompetitor] = competitors;

  return (
    <>
      {!isFormationHidden && (
        <TeamDetails formation={homeCompetitor.formation} teamName={homeCompetitor.teamName} />
      )}

      <div className={styles.fieldWrapper}>
        <div className={styles.fieldContainer}>
          <Icon className={styles.fieldImage} id={IconType.FootballField} />
          <Lineups
            className={styles.lineupsContainer}
            awayCompetitor={awayCompetitor}
            homeCompetitor={homeCompetitor}
          />
        </div>
      </div>

      {!isFormationHidden && (
        <>
          <TeamDetails formation={awayCompetitor.formation} teamName={awayCompetitor.teamName} />

          <Separator weight={Weight.Bold} />
        </>
      )}
    </>
  );
};

export default React.memo(Lineup);
