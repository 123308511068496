import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { statisticsThunks, statisticsSelectors, teamsSelectors } from 'packages/player-summary';

const mapStateToProps = (state) => {
  return {
    isSeasonTournamentsLoading: statisticsSelectors.tournamentSeasons.getIsLoading(state),
    isStatisticsLoading: statisticsSelectors.statistics.getIsLoading(state),
    teams: teamsSelectors.getTeams(state),
    tournamentSeasons: statisticsSelectors.tournamentSeasons.getData(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tournamentSeasonsActions: bindActionCreators(statisticsThunks.tournamentSeasons, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
