import React, { memo } from 'react';

import { IconType } from 'packages/shared/components/Icon/types';
import { Cards } from 'types';
import { RefereeCardType } from 'packages/shared/components/RefereeCard/types';
import { RefereeCard } from 'packages/shared/components';

import { CountableHappening } from '../../../../../../components';
import styles from './styles.scss';

type Props = {
  goals: number;
  assists: number;
  cards: Cards;
};

const PlayerStatistic = ({ assists, goals, cards }: Props) => {
  const { yellow, yellowRed, red } = cards;

  return (
    <div className={styles.wrapper}>
      <CountableHappening className={styles.item} iconId={IconType.Goal} amount={goals} />
      <CountableHappening className={styles.item} iconId={IconType.Assist} amount={assists} />

      {!!yellow && (
        <RefereeCard count={yellow} className={styles.item} type={RefereeCardType.Yellow} />
      )}
      {!!yellowRed && (
        <RefereeCard count={yellowRed} className={styles.item} type={RefereeCardType.YellowRed} />
      )}
      {!!red && <RefereeCard count={red} className={styles.item} type={RefereeCardType.Red} />}
    </div>
  );
};

export default memo(PlayerStatistic);
