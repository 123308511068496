import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadMatchStatisticsStart = createAction(`${NAMESPACE}LOAD_MATCH_STATISTICS_START`);
export const loadMatchStatisticsSuccess = createAction(`${NAMESPACE}LOAD_MATCH_STATISTICS_SUCCESS`);
export const loadMatchStatisticsFailure = createAction(`${NAMESPACE}LOAD_MATCH_STATISTICS_FAILURE`);
export const cleanMatchStatisticsSuccess = createAction(
  `${NAMESPACE}CLEAN_MATCH_STATISTICS_SUCCESS`,
);
