import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Interfaces as StadiumSummaryInterfaces } from 'packages/stadium-summary/types';

import styles from './styles.scss';
import { LoadingSkeleton } from './components';

type Props = {
  goalStatistics?: StadiumSummaryInterfaces.Statistics.GoalStatistics;
  className?: string;
  isLoading: boolean;
};

const GoalStatistics = ({ goalStatistics, className, isLoading }: Props) => {
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (!goalStatistics) {
    return null;
  }

  const { periods, averageGoalsByPeriods, averageGoals, totalGoals, gamesPlayed } = goalStatistics;
  const [averageGoalsInteger, averageGoalsDecimal = '00'] = averageGoals.toString().split('.');

  return (
    <div className={classnames(styles.goalStatistics, className)}>
      <div className={styles.title}>{i18n.value('stadium.statistics.goalStats.title')}</div>
      <div className={styles.statistics}>
        <div className={styles.averageGoalsPerMatch}>
          <div className={styles.value}>
            {averageGoalsInteger}
            <span className={styles.decimal}>.{averageGoalsDecimal}</span>
          </div>
          <div className={styles.label}>
            {i18n.value('stadium.statistics.goalStats.averageGoals.label')}
          </div>
        </div>
        <div className={styles.goalsByPeriod}>
          <div className={styles.periodRange}>
            {periods.map((period, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index}>{period}</div>
            ))}
          </div>
          <div className={styles.goalRange}>
            {averageGoalsByPeriods.map((goals, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <div key={index} className={styles.goal}>
                {goals}
              </div>
            ))}
          </div>
          <div className={styles.label}>
            {i18n.value('stadium.statistics.goalStats.goalsByPeriod.label.total')}{' '}
            <span className={styles.highlight}>
              {i18n.pluralize('stadium.statistics.goalStats.goalsByPeriod.totalGoals', {
                value: totalGoals,
              })}
            </span>{' '}
            {i18n.value('stadium.statistics.goalStats.goalsByPeriod.label.scoredIn')}{' '}
            <span className={styles.highlight}>
              {i18n.pluralize('stadium.statistics.goalStats.goalsByPeriod.matchesPlayed', {
                value: gamesPlayed,
              })}
            </span>{' '}
            {i18n.value('stadium.statistics.goalStats.goalsByPeriod.label.duringTheSeason')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(GoalStatistics);
