import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { filtersActions, commonActions } from '../../actions';
import { Interfaces } from '../../types';

const defaultState = {
  teams: [],
  positions: [],
  foot: [],
  nationalities: [],
};

const reducerMap: ReducerMap<Interfaces.Filters.Filters, Interfaces.Filters.Filters> = {
  [`${filtersActions.loadFiltersSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(
    filtersActions.loadFiltersStart,
    filtersActions.loadFiltersFailure,
    filtersActions.cleanFilters,
    commonActions.cleanPlayerStatisticsSuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
