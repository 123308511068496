import instance from '../../../axios';
import { TournamentsParams } from '../types';

const RESULTS_LIMIT = 15;

export const loadTournaments = ({ activeSportId, search, offset = 0 }: TournamentsParams) => {
  return instance({
    method: 'get',
    url: '/unique_tournaments/',
    params: {
      sport_id: activeSportId,
      search,
      limit: RESULTS_LIMIT,
      offset,
    },
  });
};
