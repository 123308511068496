import React from 'react';
import { Skeleton } from 'packages/shared/components';

import { Statistics, Substitution, StatisticsCompact } from './components';
import Team from '../Team';
import TeamCompact from '../TeamCompact';
import styles from '../styles.scss';

type Props = {
  isTournamentVisible?: boolean;
  isCompact?: boolean;
};

const PlayerEvent = ({ isCompact = false, isTournamentVisible = false }: Props) => {
  if (isCompact) {
    return (
      <div className={styles.compactEventWrapper}>
        {isTournamentVisible && (
          <div className={styles.tournament}>
            <Skeleton width={16} height={16} variant="circular" marginRight={8} />
            <Skeleton height={12} width={70} variant="rectangular" />
          </div>
        )}

        <div className={styles.event}>
          <div>
            <div>
              <Skeleton variant="rectangular" width={62} height={14} />
            </div>
            <Substitution />
          </div>

          <div className={styles.teamsWrapper}>
            <div className={styles.teamsContainer}>
              <TeamCompact />
              <TeamCompact />
            </div>

            <StatisticsCompact />

            <div className={styles.score}>
              <Skeleton height={14} width={8} variant="rectangular" />
              <Skeleton height={14} width={8} variant="rectangular" />
            </div>
          </div>

          <div>
            <Skeleton variant="circular" height={14} minHeight={14} minWidth={14} width={14} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.eventWrapper}>
      {isTournamentVisible && (
        <div className={styles.tournament}>
          <Skeleton width={16} height={16} variant="circular" marginRight={8} />
          <Skeleton height={12} width={70} variant="rectangular" />
        </div>
      )}

      <div className={styles.event}>
        <div className={styles.left}>
          <Skeleton minWidth={62} width={62} height={14} variant="rectangular" marginRight={16} />

          <Statistics />

          <Team />
        </div>

        <div className={styles.score}>
          <Skeleton width="80%" height={12} variant="rectangular" />
        </div>

        <div className={styles.right}>
          <Team isReversed />

          <Skeleton width={38} height={14} variant="rectangular" />

          <Skeleton width={60} height={14} variant="rectangular" marginLeft={16} />
        </div>
      </div>
    </div>
  );
};

export default PlayerEvent;
