import React, { memo, useCallback } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { TeamGrid } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { Team } from 'types';
import { DesktopPage } from 'router/types';

import { LoadingSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  homeTeams?: Team[];
  isLoading?: boolean;
  className?: string;
};

const HomeTeams = ({ homeTeams = [], className, isLoading = false }: Props) => {
  const { appNavigate } = useNavigation();

  const handleTeamClick = useCallback((team: Team) => {
    appNavigate(DesktopPage.FootballTeam, { teamId: team.id });
  }, []);

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className={classnames(styles.homeTeamsContainer, className)}>
      <div className={styles.title}>{i18n.value('stadium.statistics.homeTeams.title')}</div>
      <TeamGrid onTeamClick={handleTeamClick} teams={homeTeams} className={styles.teamGrid} />
    </div>
  );
};

export default memo(HomeTeams);
