import React from 'react';
import classnames from 'classnames';

import { Separator } from 'packages/shared/components';

import { ThemedNavigationMenuProps } from '../../types';
import { useScrollingToActiveElement } from './hooks';
import styles from './styles.scss';

interface Props extends ThemedNavigationMenuProps {
  isStretched?: boolean;
  isWhite?: boolean;
}

const LinkNavigationMenu = ({
  items,
  activeId,
  onClick,
  itemComponent,
  className,
  isDisabled = false,
  isWhite = false,
  isStretched = false,
}: Props) => {
  useScrollingToActiveElement(activeId);

  return (
    <div className={styles.navigationContainer}>
      <div data-scroll-lock-scrollable className={styles.linkNavigationMenu}>
        <ul
          className={classnames(styles.navigationList, className, {
            [styles.stretched]: isStretched,
            [styles.white]: isWhite,
          })}
        >
          {items.map((item) => {
            const { id, name, isDisabled: isItemDisabled } = item;
            const Component = item.itemComponent || itemComponent;
            const isTabDisabled = isItemDisabled || isDisabled;
            const isActive = activeId === id;
            const elementId = id.toString();

            return (
              <li
                id={elementId}
                key={id}
                className={classnames(styles.item, {
                  [styles.active]: isActive,
                })}
                onClick={() => !isTabDisabled && onClick(id)}
              >
                <div className={styles.itemName}>
                  {Component ? <Component {...item.itemComponentProps} /> : name}
                </div>
                {isActive && <div className={styles.activeItemLine} />}
              </li>
            );
          })}
        </ul>
      </div>
      {!isWhite && <Separator className={styles.separator} />}
    </div>
  );
};

export default LinkNavigationMenu;
