import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

type Props = {
  className?: string;
};

const Background = ({ className }: Props) => {
  return <div className={classnames(styles.background, className)} />;
};

export default Background;
