const FLOAT_VALIDATION_REGEXP = /[\-]?[0-9]*\.?([0-9]+)?/gm;

const validateFloatRange = (firstValue: string, secondValue: string) => {
  const firstValidatedFloat = validateFloatNumber(firstValue);
  const secondValidatedFloat = validateFloatNumber(secondValue);

  if (firstValidatedFloat !== '' && secondValidatedFloat !== '') {
    if (parseFloat(firstValidatedFloat) >= parseFloat(secondValidatedFloat)) {
      return [`${secondValidatedFloat}`, `${secondValidatedFloat}`];
    }
  }

  return [`${firstValidatedFloat}`, `${secondValidatedFloat}`];
};

const validateFloatNumber = (floatInput: string): string => {
  if (floatInput === '') {
    return floatInput;
  }

  const matchedInput = floatInput.match(FLOAT_VALIDATION_REGEXP)?.join('');
  const [integerPart, decimalPart] = matchedInput?.split('.') || [];
  const validatedInteger = integerPart ? parseInt(integerPart) : 0;
  const validatedDecimalFloatingPoint = decimalPart ? '.' : '';
  const validatedDecimal = `${validatedDecimalFloatingPoint}${decimalPart ? `${decimalPart}` : ''}`;

  return `${validatedInteger}${validatedDecimal}`;
};

export default validateFloatRange;
