import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { configSelectors } from 'packages/config';
import { settingsSelectors, settingsThunks } from 'packages/settings';

const mapStateToProps = (state) => {
  return {
    selectedTimeZone: settingsSelectors.getTimeZone(state),
    timeZones: configSelectors.getTimeZones(state),
    languages: configSelectors.getDefaultLanguages(state),
    localeCode: settingsSelectors.getLocaleCode(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    settingsActions: bindActionCreators(settingsThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
