import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { FavoritesStatus } from '../types';

const defaultState: FavoritesStatus = {
  isEventsLoading: true,
};

const reducerMap: ReducerMap<FavoritesStatus, unknown> = {
  [`${combineActions(actions.loadFavoritesSuccess, actions.loadFavoritesFailure)}`]: (state) => {
    return { isSeasonDetailsLoading: false, ...state };
  },
  [`${actions.loadFavoritesStart}`]: (state) => {
    return { isSeasonDetailsLoading: true, ...state };
  },
};

export default handleActions(reducerMap, defaultState);
