import React from 'react';
import classnames from 'classnames';

import { Separator } from 'packages/shared/components';
import { Interfaces, Enums } from 'packages/team-player-statistics/types';
import { SelectItem } from 'packages/shared/components/Select/types';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';
import { ComponentLike } from 'types';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';

import styles from './styles.scss';
import { ColumnsArrangement, ColumnsConfiguration, CustomizationPresets } from './components';

type Props = {
  configurableColumns: Interfaces.CustomFilter.Column[];
  activePresetType: Enums.PresetType;
  onPresetChange: (activePreset: Interfaces.CustomFilter.PresetView) => void;
  onColumnsOrderChange: (columns: Interfaces.CustomFilter.Column[]) => void;
  onRangeGroupUpdate: (type: Enums.ColumnType, updates?: Interfaces.CustomFilter.Range) => void;
  onColumnToggle: (type: Enums.ColumnType) => void;
  rangeFilters: Interfaces.CustomFilter.RangeFilterState;
  activeColumns: Interfaces.CustomFilter.ActiveColumnsState;
  onActiveStatTypeChange: (statType: Enums.StatType) => void;
  onSelectedPositionsChange: (positions: SelectItem[]) => void;
  onSelectedTournamentChange: (tournament: SelectItem) => void;
  onSelectedSeasonChange: (season: SelectItem) => void;
  onSelectedFootChange: (foot: SelectItem) => void;
  onSelectedNationalitiesChange: (
    nationalities: Interfaces.CustomFilter.NationalitySelectOption[],
  ) => void;
  onAgeRangeChange: (from: string, to: string) => void;
  activeStatType: Enums.StatType;
  statTabs: NavigationMenuItem[];
  positionSelectItems: SelectItem[];
  selectedPositionItems: SelectItem[];
  footSelectItems: SelectItem[];
  selectedFootItem?: SelectItem;
  selectedTournamentId?: number;
  selectedSeasonId?: number;
  nationalitySelectItems: Interfaces.CustomFilter.NationalitySelectOption[];
  selectedNationalityItems: Interfaces.CustomFilter.NationalitySelectOption[];
  ageRange: Interfaces.CustomFilter.Range;
  columnConfigurationSchema: Interfaces.CustomFilter.RangeFilterColumnsView;
  className?: string;
  GeneralFilterComponent: ComponentLike;
  tournaments: SeasonsExtendedTournament[];
};

const FilterArea = ({
  configurableColumns,
  activePresetType,
  onPresetChange,
  onColumnsOrderChange,
  onRangeGroupUpdate,
  onColumnToggle,
  rangeFilters,
  activeColumns,
  activeStatType,
  onActiveStatTypeChange,
  onSelectedPositionsChange,
  onSelectedFootChange,
  onSelectedNationalitiesChange,
  onAgeRangeChange,
  statTabs,
  positionSelectItems,
  selectedPositionItems,
  footSelectItems,
  selectedFootItem,
  nationalitySelectItems,
  selectedNationalityItems,
  ageRange,
  columnConfigurationSchema,
  className,
  GeneralFilterComponent,
  selectedTournamentId,
  selectedSeasonId,
  tournaments,
  onSelectedTournamentChange,
  onSelectedSeasonChange,
}: Props) => {
  return (
    <div className={classnames(styles.filterAreaContainer, className)}>
      <GeneralFilterComponent
        tournaments={tournaments}
        onSelectedTournamentChange={onSelectedTournamentChange}
        onSelectedSeasonChange={onSelectedSeasonChange}
        onActiveStatTypeChange={onActiveStatTypeChange}
        onSelectedPositionsChange={onSelectedPositionsChange}
        onSelectedFootChange={onSelectedFootChange}
        onSelectedNationalitiesChange={onSelectedNationalitiesChange}
        selectedNationalityItems={selectedNationalityItems}
        selectedPositionItems={selectedPositionItems}
        nationalitySelectItems={nationalitySelectItems}
        positionSelectItems={positionSelectItems}
        selectedFootItem={selectedFootItem}
        footSelectItems={footSelectItems}
        activeStatType={activeStatType}
        onAgeRangeChange={onAgeRangeChange}
        statTabs={statTabs}
        ageRange={ageRange}
        selectedTournamentId={selectedTournamentId}
        selectedSeasonId={selectedSeasonId}
      />
      <Separator className={styles.filterGroupSeparator} />
      <CustomizationPresets
        onPresetChange={onPresetChange}
        activePresetType={activePresetType}
        className={styles.customizationPresetsContainer}
      />
      <ColumnsArrangement
        columns={configurableColumns}
        onColumnPositionChange={onColumnsOrderChange}
        className={styles.columnsArrangementContainer}
      />
      <ColumnsConfiguration
        activeStatType={activeStatType}
        columnConfigurationSchema={columnConfigurationSchema}
        rangeFilterState={rangeFilters}
        activeColumnsState={activeColumns}
        onRangeGroupUpdate={onRangeGroupUpdate}
        onColumnToggle={onColumnToggle}
        columns={configurableColumns}
      />
    </div>
  );
};

export default FilterArea;
