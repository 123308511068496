import React from 'react';
import classnames from 'classnames';

import { PlayerHappening } from 'packages/event-time-line/types';

import { countHappeningsByType } from './services';
import HappeningBadge from './components/HappeningBadge';
import styles from './styles.scss';

type Props = {
  happenings: PlayerHappening[];
  className?: string;
};

const HappeningsPreview = ({ happenings, className }: Props) => {
  const countedHappenings = countHappeningsByType(happenings);

  return (
    <div className={classnames(styles.playerHappeningsContainer, className)}>
      {countedHappenings.map(({ id, ...happeningsProps }) => (
        <HappeningBadge key={id} {...happeningsProps} />
      ))}
    </div>
  );
};

export default React.memo(HappeningsPreview);
