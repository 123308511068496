import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Status } from '../../../types';
import { categoryListActions } from '../../../actions';

const defaultState: Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${categoryListActions.loadCategoriesStart}`]: (state) => {
    return { ...state, isLoading: true };
  },
  [`${combineActions(
    categoryListActions.loadCategoriesFailure,
    categoryListActions.loadCategoriesSuccess,
  )}`]: (state) => {
    return { ...state, isLoading: false };
  },
};

export default handleActions(reducerMap, defaultState);
