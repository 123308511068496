import { TeamSummary } from 'packages/rest';

import { DEFAULT_FINISHED_COUNT, DEFAULT_SCHEDULED_COUNT } from '../../constants';
import { mapTeamEvents, findEventsPeriod } from '../../services';

const loadActualEvents = (teamId: string) => async () => {
  const { data } = await TeamSummary.loadActualGames({
    teamId,
    finishedCount: DEFAULT_FINISHED_COUNT,
    scheduledCount: DEFAULT_SCHEDULED_COUNT,
  });

  return {
    events: mapTeamEvents(data.results),
    period: findEventsPeriod(data.results),
    count: data.results.length,
  };
};

export default loadActualEvents;
