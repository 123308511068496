import React, { ReactNode } from 'react';

import { LayoutKey } from 'types';
import { ArrayUtils } from 'packages/utils';

import styles from './styles.scss';

type Props = {
  children: ReactNode[];
};

const BaseLayout = ({ children }: Props) => {
  const TopComponent: ReactNode = ArrayUtils.findBy(children, 'key', LayoutKey.Top);
  const MainComponent: ReactNode = ArrayUtils.findBy(children, 'key', LayoutKey.Main);
  const BottomComponent: ReactNode = ArrayUtils.findBy(children, 'key', LayoutKey.Bottom);

  return (
    <div className={styles.layout}>
      <div className={styles.header}>{TopComponent}</div>

      <div className={styles.main}>{MainComponent}</div>

      <div className={styles.footer}>{BottomComponent}</div>
    </div>
  );
};

export default React.memo(BaseLayout);
