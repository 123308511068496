import { useEffect, useState } from 'react';

import { ComponentLike } from 'types';
import { StagesOptions, StageTab } from 'packages/season-standings-ui/types';
import { Stage } from 'packages/event-standings/types';

import { mapStagesIntoStageTabs, getDefaultActiveTabIndex } from '../services';
import { useActiveTab } from '.';

export const useStageTabs = <T extends ComponentLike, B extends ComponentLike>(
  stages: Stage[] | null,
  options: StagesOptions<T, B>,
) => {
  const { defaultActiveTabId, ...restOptions } = options;
  const [tabs, setTabs] = useState<StageTab[]>([]);
  const defaultActiveTabIndex = getDefaultActiveTabIndex(
    tabs,
    defaultActiveTabId,
    tabs?.length - 1,
  );
  const [activeTab, setActiveTabById] = useActiveTab(tabs, defaultActiveTabIndex);

  useEffect(() => {
    if (stages) {
      setTabs(mapStagesIntoStageTabs(stages, restOptions));
    }
  }, [stages]);

  return {
    tabs,
    activeTab,
    setActiveTabById,
  };
};

export default useStageTabs;
