exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2V5VDhTl{flex:1 1;margin-right:16px}._1hkOEeVm{flex:1 1;display:flex;align-items:flex-start;justify-content:center}._3uhpZuFL{width:443px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktop/football/StadiumHome/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,QAAO,CACP,YAAa,CACb,sBAAuB,CACvB,sBAAuB,CAV3B,WAcI,WAAY","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_2V5VDhTl",
	"leftColumn": "_2V5VDhTl",
	"left-bar": "_1hkOEeVm",
	"leftBar": "_1hkOEeVm",
	"right-column": "_3uhpZuFL",
	"rightColumn": "_3uhpZuFL"
};