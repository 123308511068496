import React from 'react';
import { Column } from 'react-table';

import { Skeleton } from 'packages/shared/components';

import { Country } from '../components';

const rightAlignStyle = {
  display: 'flex',
  justifyContent: 'flex-end',
};

const getTableSchema = (): Column[] => {
  return [
    {
      id: 'name',
      Header: <Skeleton width={30} height={8} variant="rectangular" />,
      headerStyle: { padding: '12px 12px 12px 16px', textAlign: 'left' },
      cellStyle: {
        padding: '12px 8px 12px 16px',
      },
      Cell: <Skeleton width={100} height={12} variant="rectangular" />,
      width: 229,
    },
    {
      id: 'countryLogo',
      Header: <Skeleton width={60} height={8} variant="rectangular" />,
      headerStyle: { padding: '12px 8px' },
      cellStyle: { padding: '12px 8px' },
      Cell: Country,
      width: 100,
    },
    {
      id: 'constructionYear',
      Header: <Skeleton width={40} height={8} variant="rectangular" />,
      headerStyle: { padding: '12px 8px' },
      cellStyle: { padding: '12px 8px' },
      Cell: <Skeleton width={40} height={12} variant="rectangular" />,
      width: 79,
    },
    {
      id: 'capacity',
      Header: <Skeleton width={40} height={8} variant="rectangular" />,
      headerStyle: { padding: '12px 8px', ...rightAlignStyle },
      cellStyle: { padding: '12px 8px', ...rightAlignStyle },
      Cell: <Skeleton width={55} height={12} variant="rectangular" />,
      width: 68,
    },
    {
      id: 'avg',
      Header: <Skeleton width={60} height={8} variant="rectangular" />,
      headerStyle: { padding: '12px 8px', ...rightAlignStyle },
      cellStyle: { padding: '12px 8px', ...rightAlignStyle },
      Cell: <Skeleton width={55} height={12} variant="rectangular" />,
      width: 84,
    },
    {
      id: 'fullness',
      Header: <Skeleton width={30} height={8} variant="rectangular" />,
      headerStyle: { padding: '12px 8px', ...rightAlignStyle },
      cellStyle: { padding: '12px 8px', ...rightAlignStyle },
      Cell: <Skeleton width={30} height={12} variant="rectangular" />,
      width: 70,
    },
    {
      id: 'clubs',
      Header: <Skeleton width={30} height={8} variant="rectangular" />,
      headerStyle: { padding: '12px 8px', textAlign: 'left' },
      cellStyle: { padding: '12px 8px' },
      Cell: <Skeleton variant="circular" />,
      width: 96,
    },
    {
      id: 'topScorers',
      Header: <Skeleton width={70} height={8} variant="rectangular" />,
      headerStyle: { padding: '12px 16px 12px 8px', textAlign: 'left' },
      cellStyle: { padding: '12px 16px 12px 8px' },
      Cell: <Skeleton width={80} height={12} variant="rectangular" />,
      minWidth: 125,
    },
  ];
};

export default getTableSchema;
