import { connect } from 'react-redux';

import { teamDetailsSelectors } from 'packages/team-details';

const mapStateToProps = (state) => {
  return {
    tournaments: teamDetailsSelectors.getTournamentList(state),
    isLoading: teamDetailsSelectors.getIsLoading(state),
  };
};

export default connect(mapStateToProps);
