import React, { memo, useCallback, useMemo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { Select } from 'packages/shared/components';
import { SelectItem, SelectSize, SelectTheme } from 'packages/shared/components/Select/types';
import { PlayerTeam } from 'packages/player-events/types';

import { getTeamSelectItems, getTeamSelectItem } from './services';
import { TeamLabel, TeamItem } from './components';

type Props = {
  teams: PlayerTeam[];
  selectedTeam?: PlayerTeam;
  onChange: (team?: PlayerTeam) => void;
  className?: string;
};

const TournamentSelect = ({ teams, selectedTeam, onChange, className }: Props) => {
  const tournamentSelectItems = useMemo(() => {
    return getTeamSelectItems(teams);
  }, [teams]);
  const selectedTournamentItem = useMemo(() => {
    return getTeamSelectItem(selectedTeam);
  }, [selectedTeam]);

  const handleTeamChange = useCallback(
    (item: SelectItem) => {
      const selectedTournament = ArrayUtils.findBy(teams, 'id', item.id);

      onChange(selectedTournament);
    },
    [teams, onChange],
  );

  return (
    <Select
      selectedItem={selectedTournamentItem}
      labelComponentProps={selectedTournamentItem.componentProps}
      labelComponent={TeamLabel}
      items={tournamentSelectItems}
      onChange={handleTeamChange}
      size={SelectSize.Lg}
      theme={SelectTheme.Gray}
      itemComponent={TeamItem}
      className={className}
    />
  );
};

export default memo(TournamentSelect);
