import { seasonEventsSelectors, seasonFilterSelectors } from '../../selectors';
import * as actions from '../../actions';
import { loadEvents } from './loadEvents';

export const loadMoreEvents = (seasonId: number) => async (dispatch, getState) => {
  try {
    dispatch(actions.loadMoreEventsStart());

    const activeFilters = seasonFilterSelectors.getActiveFilters(getState());
    const limit = seasonEventsSelectors.getLimit(getState());
    const offset = seasonEventsSelectors.getOffset(getState());

    const eventsData = await dispatch(
      loadEvents({
        seasonId,
        activeFilters,
        limit,
        offset,
      }),
    );

    dispatch(actions.loadMoreEventsSuccess(eventsData));
  } catch (error) {
    dispatch(actions.loadMoreEventsFailure(error));
  }
};
