import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Input, ItemLabel } from 'packages/shared/components';
import { InputValidationType } from 'packages/shared/components/Input/types';
import {
  Nationality,
  Range,
  SearchSelectOption,
  StatType,
} from 'packages/season-player-statistics/types';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';
import { SelectItem } from 'packages/shared/components/Select/types';
import { Team } from 'types';

import styles from './styles.scss';

type Props = {
  onActiveStatTypeChange: (statType: StatType) => void;
  onSelectedTeamsChange: (teams: SearchSelectOption<Team>[]) => void;
  onSelectedPositionsChange: (teams: SelectItem[]) => void;
  onSelectedFootChange: (foot: SelectItem) => void;
  onSelectedNationalitiesChange: (nationalities: SearchSelectOption<Nationality>[]) => void;
  onAgeRangeChange: (from: string, to: string) => void;
  activeStatType: StatType;
  statTabs: NavigationMenuItem[];
  teamSelectItems: SearchSelectOption<Team>[];
  selectedTeamItems: SearchSelectOption<Team>[];
  positionSelectItems: SelectItem[];
  selectedPositionItems: SelectItem[];
  footSelectItems: SelectItem[];
  selectedFootItem?: SelectItem;
  nationalitySelectItems: SearchSelectOption<Nationality>[];
  selectedNationalityItems: SearchSelectOption<Nationality>[];
  ageRange: Range;
  className?: string;
};

const GeneralFilter = ({
  activeStatType,
  onActiveStatTypeChange,
  onSelectedTeamsChange,
  onSelectedPositionsChange,
  onSelectedFootChange,
  onSelectedNationalitiesChange,
  onAgeRangeChange,
  statTabs,
  teamSelectItems,
  selectedTeamItems,
  positionSelectItems,
  selectedPositionItems,
  footSelectItems,
  selectedFootItem,
  nationalitySelectItems,
  selectedNationalityItems,
  ageRange,
  className,
}: Props) => {
  return (
    <div className={classnames(styles.generalFilterGroup, className)}>
      <Input.Tabs
        onChange={(activeStatsTab) => onActiveStatTypeChange(activeStatsTab as StatType)}
        activeId={activeStatType}
        items={statTabs}
        label={i18n.value('season.playerStatistics.customFilter.general.showStatsLabel')}
        name="Show stats"
      />
      <Input.SearchSelect<SearchSelectOption<Team>>
        useScrollIntoPopoverView
        itemsContainerClass={styles.searchListItems}
        onSelectionChange={onSelectedTeamsChange}
        items={teamSelectItems}
        selectedItems={selectedTeamItems}
        itemComponent={ItemLabel}
        labelPrefix={i18n.value('season.playerStatistics.customFilter.general.teamsSelectLabel')}
        label={i18n.value('season.playerStatistics.customFilter.general.teamLabel')}
        name="Team"
      />
      <Input.Multiselect
        useScrollIntoPopoverView
        onChange={onSelectedPositionsChange}
        selectedItems={selectedPositionItems}
        items={positionSelectItems}
        pluralItemLabel="season.playerStatistics.customFilter.general.positionsSelectPlural"
        label={i18n.value('season.playerStatistics.customFilter.general.positionLabel')}
        name="Position"
      />
      <Input.Select
        useScrollIntoPopoverView
        onChange={onSelectedFootChange}
        selectedItem={selectedFootItem}
        items={footSelectItems}
        label={i18n.value('season.playerStatistics.customFilter.general.preferredFootLabel')}
        name="Preferred foot"
      />
      <Input.SearchSelect<SearchSelectOption<Nationality>>
        useScrollIntoPopoverView
        itemsContainerClass={styles.searchListItems}
        items={nationalitySelectItems}
        selectedItems={selectedNationalityItems}
        onSelectionChange={onSelectedNationalitiesChange}
        itemComponent={ItemLabel}
        labelPrefix={i18n.value(
          'season.playerStatistics.customFilter.general.nationalitiesSelectLabel',
        )}
        label={i18n.value('season.playerStatistics.customFilter.general.nationalityLabel')}
        name="Nationality"
      />
      <Input.TextRange
        startValue={ageRange.from}
        endValue={ageRange.to}
        onRangeValuesChange={onAgeRangeChange}
        inputValidationType={InputValidationType.Integer}
        startPlaceholder="0"
        endPlaceholder="∞"
        label={i18n.value('season.playerStatistics.customFilter.general.ageLabel')}
        name="Age"
      />
    </div>
  );
};

export default memo(GeneralFilter);
