import { combineReducers } from 'redux';

import players from './players';
import filters from './filters';
import customFilter from './customFilter';

export default combineReducers({
  customFilter,
  players,
  filters,
});
