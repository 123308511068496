import getState from './getState';
import { ActiveEventStatus } from '../types';

export const getStatus = (state): ActiveEventStatus => {
  return getState(state).status;
};

export const getIsEventDetailsOpen = (state): boolean => {
  return getStatus(state).isOpen;
};

export const getIsEventDetailsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
