import React, { memo } from 'react';
import i18n from 'i18n-smart';

import {
  Filter,
  FilterValue,
  SeasonEventsFilter as SeasonEventsFilterType,
} from 'packages/season-events/types';
import { DatePicker } from 'packages/shared/components';
import { LocaleCode } from 'types';

import { TeamSelect } from './components';
import styles from './styles.scss';

type Props = {
  filter: SeasonEventsFilterType;
  onFilterChange: (filterValue: FilterValue, filterType: Filter) => void;
  useDatePickerBackdrop?: boolean;
  onCustomRangeClick: () => void;
  isTeamSelectCloseableOnResize?: boolean;
  teamSelectItemsContainerClass?: string;
};

const SeasonEventsFilter = ({
  filter,
  onFilterChange,
  onCustomRangeClick,
  useDatePickerBackdrop = false,
  isTeamSelectCloseableOnResize,
  teamSelectItemsContainerClass,
}: Props) => {
  const { activeFilters, filters } = filter;

  return (
    <div className={styles.seasonEventsFilters}>
      <div className={styles.title}>{i18n.value('season.seasonEvents.title')}</div>
      <DatePicker
        customRangeLabel="season.seasonEvents.datePicker.fullSeasonLabel"
        onApplyClick={(dates) => onFilterChange(dates, Filter.Dates)}
        onCustomRangeClick={onCustomRangeClick}
        className={styles.datepicker}
        value={activeFilters.dates}
        localeCode={LocaleCode.En}
        useBackdrop={useDatePickerBackdrop}
        isRangeMode
      />

      <TeamSelect
        isCloseableOnResize={isTeamSelectCloseableOnResize}
        className={styles.teamSelect}
        teams={filters.teams}
        selectedTeams={activeFilters.teams}
        onChange={(teams) => onFilterChange(teams, Filter.Teams)}
        itemsContainerClass={teamSelectItemsContainerClass}
      />
    </div>
  );
};

export default memo(SeasonEventsFilter);
