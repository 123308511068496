import { connect } from 'react-redux';

import { teamDetailsSelectors } from 'packages/team-details';
import { teamActiveTournamentSeasonSelectors } from 'packages/team-active-tournament-season';

const mapStateToProps = (state) => {
  return {
    tournaments: teamDetailsSelectors.getTournamentList(state),
    activeTournamentSeason: teamActiveTournamentSeasonSelectors.getData(state),
  };
};

export default connect(mapStateToProps);
