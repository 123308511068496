import React, { memo } from 'react';

import { TeamLabel } from 'packages/shared/components';
import { TournamentSeasonChampion } from 'packages/season-history/types';
import { Team } from 'types';

import styles from './styles.scss';

type Props = {
  team: TournamentSeasonChampion;
  onTeamClick: (team: Team) => void;
};

const TeamCell = ({ team, onTeamClick }: Props) => {
  return (
    <div className={styles.teamCell}>
      <TeamLabel onNameClick={onTeamClick} onLogoClick={onTeamClick} isLogoFirst team={team} />
    </div>
  );
};

export default memo(TeamCell);
