import i18n from 'i18n-smart';

import { Round } from 'types';
import { toCamelCase } from 'packages/utils/Object.utils';

export const mapRound = (round): Round => {
  const { id, name, manualName } = toCamelCase(round);

  return {
    id,
    name: manualName ? manualName : mapName(name),
  };
};

const mapName = (name): string => {
  return Number(name) ? i18n.value('common.roundName', [name]) : name;
};
