import * as matchTimeLineActions from './actions';
import * as matchTimeLineThunks from './thunks';
import * as matchTimeLineConstants from './constants';
import * as matchTimeLineSelectors from './selectors';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  matchTimeLineActions,
  matchTimeLineThunks,
  matchTimeLineConstants,
  matchTimeLineSelectors,
  createReducer,
};
