export enum SearchQuery {
  EventCardId = 'eventCardId',
  TransferTeamId = 'transferTeamId',
  Date = 'date',
  CategoryId = 'categoryId',
}

export enum NavigationRoutes {
  Home = '/',
  Settings = '/settings',
}
