import React from 'react';
import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed/lib';

import { DateLabel } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  value: string | [string, string];
  eventsCount?: number;
};

const SelectedDateLabel = ({ value, eventsCount }: Props) => {
  return (
    <div className={styles.selectedDateLabel}>
      <DateLabel value={value} />

      {!isEmpty(eventsCount) && (
        <div className={styles.matches}>
          {i18n.pluralize('calendar.dataLabel.matches', { value: eventsCount })}
        </div>
      )}
    </div>
  );
};

export default SelectedDateLabel;
