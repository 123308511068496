import React from 'react';

import styles from './styles.scss';

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

type Props = {
  onClick?: () => void;
};

const ExampleBlock = ({ onClick }: Props) => {
  return (
    <div onClick={onClick} className={styles.container}>
      <div className={styles.stickyHeader}>Sticky header</div>

      {Array(100)
        .fill({})
        .map((item, index) => (
          <div key={getRandomInt(1000000)} className={styles.event}>
            {`event-${index + 1}`}
          </div>
        ))}
    </div>
  );
};

export default React.memo(ExampleBlock);
