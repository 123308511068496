import React, { useMemo } from 'react';

import { Select } from 'packages/shared/components';
import { SelectItem, SelectTheme } from 'packages/shared/components/Select/types';

import { getLimitSelectItems, getLimitSelectItem } from './services';

type Props = {
  limits: number[];
  selectedLimit: number;
  onChange: (limit: number) => void;
};

const LimitSelect = ({ limits, selectedLimit, onChange }: Props) => {
  const limitSelectItems = useMemo(() => {
    return getLimitSelectItems(limits);
  }, [limits]);

  const selectedLimitItem = useMemo(() => {
    return getLimitSelectItem(selectedLimit);
  }, [selectedLimit]);

  const handleLimitChange = (item: SelectItem) => {
    const selectedLimit = limits.find((limit) => limit === item.id);

    if (selectedLimit) {
      onChange(selectedLimit);
    }
  };

  return (
    <Select
      selectedItem={selectedLimitItem}
      items={limitSelectItems}
      onChange={handleLimitChange}
      theme={SelectTheme.Gray}
      isReversed
    />
  );
};

export default LimitSelect;
