import * as seasonStadiumsActions from './actions';
import * as seasonStadiumsThunks from './thunks';
import * as seasonStadiumsConstants from './constants';
import * as seasonStadiumsSelectors from './selectors';
import { configure } from './config';
import reducers from './reducers';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  seasonStadiumsActions,
  seasonStadiumsThunks,
  seasonStadiumsConstants,
  seasonStadiumsSelectors,
  createReducer,
};
