import { Stage, StageType } from 'packages/event-standings/types';
import { ComponentLike } from 'types';

import { StagesOptions, StageTab } from '../types';

const mapStagesIntoStageTabs = <T extends ComponentLike, B extends ComponentLike>(
  stages: Stage[],
  { getTableProps, getBracketProps, additionalProps = {} }: StagesOptions<T, B>,
): StageTab[] => {
  return stages.map(({ startDate, data, type, id, name }) => {
    const tabProps = type === StageType.Brackets ? getBracketProps() : getTableProps();

    return {
      id,
      name,
      type,
      props: {
        startDate,
        ...data,
        ...additionalProps,
      },
      ...tabProps,
    };
  });
};

export default mapStagesIntoStageTabs;
