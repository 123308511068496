import React, { memo, ReactElement, RefObject } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { Label, PlainTextInput } from '../../components';
import styles from './styles.scss';

type Props = {
  name: string;
  onChange: (value: any) => void;
  value?: string | number;
  label?: string | ReactElement;
  placeholder?: string;
  className?: string;
  inputClassName?: string;
  isAutoFocused?: boolean;
  inputRef?: RefObject<HTMLInputElement>;
};

const Text = ({
  value = '',
  onChange,
  label,
  name,
  placeholder,
  className,
  isAutoFocused,
  inputClassName,
  inputRef,
}: Props) => {
  return (
    <div className={classnames(styles.textInputContainer, className)}>
      {!isEmpty(label) && <Label name={name} label={label} />}
      <PlainTextInput
        name={name}
        onChange={({ currentTarget }) => onChange(currentTarget.value)}
        placeholder={placeholder}
        value={value}
        className={inputClassName}
        isAutoFocused={isAutoFocused}
        forwardedRef={inputRef}
      />
    </div>
  );
};

export default memo(Text);
