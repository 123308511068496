import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { stagesThunks, stagesSelectors, commonThunks } from 'packages/season-standings';
import { settingsSelectors } from 'packages/settings';

const mapStateToProps = (state) => {
  return {
    isStagesLoading: stagesSelectors.getIsLoading(state),
    stages: stagesSelectors.getData(state),
    timeZone: settingsSelectors.getTimeZone(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    seasonStagesActions: bindActionCreators(stagesThunks, dispatch),
    seasonStandingsActions: bindActionCreators(commonThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
