import { Categories } from 'packages/rest';

import { mainCategoryActions } from '../../actions';
import { mapTournamentExtendedCategory } from '../../services';

const loadCategory = (categoryId: number) => async (dispatch) => {
  try {
    dispatch(mainCategoryActions.loadCategoryStart());

    const { data } = await Categories.loadCategory({ id: categoryId });
    const category = mapTournamentExtendedCategory(data.results);

    dispatch(mainCategoryActions.loadCategorySuccess(category));
  } catch (error) {
    dispatch(mainCategoryActions.loadCategoryFailure({ error }));
  }
};

export default loadCategory;
