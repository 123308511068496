import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { RefereeCard, SortableTableHeader } from 'packages/shared/components';
import { RefereeCardType } from 'packages/shared/components/RefereeCard/types';
import { ColumnType } from 'packages/season-team-statistics/types';
import { OrderType } from 'types';

import styles from './styles.scss';

type Props = {
  type: ColumnType;
  sortingType?: OrderType;
  onOrderingChange: (columnType: ColumnType) => void;
};

const ColumnTitle = ({ type, sortingType, onOrderingChange }: Props) => {
  const getElementByType = (type) => {
    switch (type) {
      case ColumnType.RedCards:
        return (
          <SortableTableHeader<ColumnType>
            onParameterClick={onOrderingChange}
            activeSortingType={sortingType}
            parameter={type}
          >
            <RefereeCard type={RefereeCardType.Red} className={styles.refereeCard} />
          </SortableTableHeader>
        );
      case ColumnType.YellowRedCards:
        return (
          <SortableTableHeader<ColumnType>
            onParameterClick={onOrderingChange}
            activeSortingType={sortingType}
            parameter={type}
          >
            <RefereeCard type={RefereeCardType.YellowRed} className={styles.refereeCard} />
          </SortableTableHeader>
        );
      case ColumnType.YellowCards:
        return (
          <SortableTableHeader<ColumnType>
            onParameterClick={onOrderingChange}
            activeSortingType={sortingType}
            parameter={type}
          >
            <RefereeCard type={RefereeCardType.Yellow} className={styles.refereeCard} />
          </SortableTableHeader>
        );
      default:
        return (
          <SortableTableHeader<ColumnType>
            onParameterClick={onOrderingChange}
            activeSortingType={sortingType}
            parameter={type}
          >
            <>
              {i18n.value(
                `season.teamStatistics.customFilter.columnConfiguration.columnName.short.${type}`,
              )}
            </>
          </SortableTableHeader>
        );
    }
  };

  return getElementByType(type);
};

export default memo(ColumnTitle);
