import { DateTime, DateObjectUnits, Settings } from 'luxon';

import { LocaleCode } from 'types';

class BasicDate {
  setLocale(localeCode: LocaleCode) {
    Settings.defaultLocale = localeCode;
  }

  getDate(): DateTime {
    return DateTime.local();
  }

  getOffset() {
    return this.getDate().offset;
  }

  getIsoString() {
    return DateTime.local().toISO();
  }

  getDateTimeFromIsoString(dateStr: string) {
    return DateTime.fromISO(dateStr);
  }

  getIsoFromDate(date: Date): string {
    return this.getDateTimeFromDate(date).toISODate();
  }

  getDateTimeFromDate(date: Date): DateTime {
    return DateTime.fromJSDate(date);
  }

  format(date: DateTime, format: string) {
    return date.toFormat(format);
  }

  toIsoDateFromDateTime(date: DateTime): string {
    return date.toISODate();
  }

  plus(date: DateTime, options: DateObjectUnits) {
    return date.plus(options);
  }

  minus(date: DateTime, options: DateObjectUnits) {
    return date.minus(options);
  }

  getMonthName(date: DateTime) {
    return date.monthLong;
  }

  getMonthShortName(date: DateTime) {
    return date.monthShort;
  }

  getWeekDayShortName(date: DateTime) {
    return date.weekdayShort;
  }
}

export default BasicDate;
