import { connect } from 'react-redux';

import { favoriteEventsSelectors } from 'packages/events';

const mapStateToProps = (state) => ({
  favoriteGamesAmount: favoriteEventsSelectors.getEvents(state)?.length || 0,
  isLoading: favoriteEventsSelectors.getIsLoading(state),
});

export default connect(mapStateToProps);
