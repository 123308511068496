import React, { memo } from 'react';

import { StatisticsBlock as Statistics, NoDataLabel } from 'packages/shared/components';
import { Interfaces as PlayerSummaryInterfaces } from 'packages/player-summary/types';

import connect from './connect';
import { Skeleton } from './components';
import styles from './styles.scss';

type Props = {
  isColumnDirectionActive?: boolean;
  isSeasonTournamentsLoading: boolean;
  isStatisticsLoading: boolean;
  isInitialLoading: boolean;
  statistics?: PlayerSummaryInterfaces.Statistics.Statistics.StatisticsGroup;
};

const StatisticsBlock = ({
  statistics,
  isSeasonTournamentsLoading,
  isStatisticsLoading,
  isColumnDirectionActive = false,
  isInitialLoading,
}: Props) => {
  if (isSeasonTournamentsLoading || isStatisticsLoading || isInitialLoading) {
    return <Skeleton isColumnDirectionActive={isColumnDirectionActive} />;
  }

  return statistics ? (
    <Statistics isColumnDirectionActive={isColumnDirectionActive} statistics={statistics} />
  ) : (
    <NoDataLabel className={styles.noDataLabel} />
  );
};

export default connect(memo(StatisticsBlock));
