import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

type Props = {
  isShrink?: boolean;
  className?: string;
};

const Background = ({ isShrink, className }: Props) => {
  return (
    <div className={classnames(styles.background, { [styles.shrink]: isShrink }, className)}>
      <img className={styles.image} src="/images/stadium_page_heaer.png" alt="" />
    </div>
  );
};

export default Background;
