import React from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Button } from 'packages/shared/components';
import { ButtonType } from 'packages/shared/components/Button/types';
import { PresetType, PresetView } from 'packages/season-player-statistics/types';
import { seasonPlayerStatisticsConstants } from 'packages/season-player-statistics';

import styles from './styles.scss';

const { CUSTOMIZATION_PRESETS_BY_STAT_TYPE } = seasonPlayerStatisticsConstants;

type Props = {
  onPresetChange: (preset: PresetView) => void;
  activePresetType: PresetType;
  className?: string;
};

const CustomizationPresets = ({ className, onPresetChange, activePresetType }: Props) => {
  const getPresetChangeHandler = (preset) => () => {
    onPresetChange(preset);
  };

  return (
    <div className={classnames(styles.customizationPresetsContainer, className)}>
      <div className={styles.title}>
        {i18n.value(
          'season.playerStatistics.customFilter.columnConfiguration.configurationPresets',
        )}
      </div>
      <div className={styles.customizationPresetsGroup}>
        {CUSTOMIZATION_PRESETS_BY_STAT_TYPE.map((preset) => (
          <Button
            type={ButtonType.Default}
            key={preset.type}
            onClick={getPresetChangeHandler(preset)}
            isActive={preset.type === activePresetType}
            className={styles.presetButton}
          >
            {`season.playerStatistics.customFilter.columnConfiguration.configurationPreset.${preset.type}`}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default CustomizationPresets;
