import { BaseHappening } from 'packages/event-time-line/types';

import { Data, CompareStatistics } from '../types';
import getPath from './getPath';

export const getData = (state): Data => {
  return getPath(state).data;
};

export const getShotEvents = (state): BaseHappening[] => {
  return getData(state).shotEvents;
};

export const getStatistics = (state): CompareStatistics[] => {
  return getData(state).statistics;
};
