import React, { RefObject, useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { ComponentLike } from 'types';
import { excludeById, findById } from 'packages/utils/Array.utils';
import { FlexiblePopover } from 'packages/shared/components';
import { debounce } from 'packages/utils/Function.utils';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';

import { SearchSelectItem, SelectSize, SelectTheme } from '../../../Select/types';
import { PopoverItemList, SelectInput } from './components';
import styles from './styles.scss';
import { findOptionBySubstring } from './services';

type Props<T extends SearchSelectItem> = {
  onSelectionChange: (items: T[]) => void;
  onSearchStart?: (value: string) => Promise<T[]>;
  selectedItems: T[];
  defaultItems?: T[];
  onSearchFinish?: () => void;
  inputPlaceholder?: string;
  itemsContainerClass?: string;
  wrapperClassName?: string;
  labelPrefix?: string;
  searchStartDelay?: number;
  itemComponent?: ComponentLike;
  size?: SelectSize;
  theme?: SelectTheme;
  className?: string;
  scrollElementRef?: RefObject<HTMLElement>;
  isCloseableOnResize?: boolean;
  useScrollIntoPopoverView?: boolean;
};

const SearchSelect = <T extends SearchSelectItem>({
  labelPrefix = i18n.value('searchSelect.labelPrefix.items'),
  inputPlaceholder = i18n.value('searchSelect.input.placeholder'),
  defaultItems = [],
  theme = SelectTheme.Gray,
  itemsContainerClass,
  wrapperClassName,
  selectedItems,
  onSelectionChange,
  onSearchStart,
  itemComponent,
  onSearchFinish,
  searchStartDelay = 300,
  size = SelectSize.Lg,
  className,
  isCloseableOnResize,
  scrollElementRef,
  useScrollIntoPopoverView = false,
}: Props<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const nonTriggerElementRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [searchedItems, setSearchedItems] = useState<T[]>(defaultItems);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [searchValue, setSearchValue] = useState<string>('');
  const allSelectedLabel = `${i18n.value('common.all')} ${labelPrefix}`;
  const itemsSelectLabel = `${labelPrefix} ${i18n.value('searchSelect.label.selected')}`;
  const selectedItemsCount = selectedItems.length;
  const items = searchValue ? searchedItems : defaultItems;

  useEffect(() => {
    resetSelectData();
  }, [selectedItems]);

  const debouncedSearch = useCallback(
    debounce(async (value) => {
      if (!onSearchStart) {
        return;
      }

      const items = await onSearchStart(value);

      setIsSearching(false);
      setSearchedItems(items);
      onSearchFinish?.();
    }, searchStartDelay),
    [onSearchStart, onSearchFinish],
  );

  const handleInputChangeAsync = useCallback(
    async (value: string) => {
      if (!value) {
        resetSelectData();
        onSearchFinish?.();
        debouncedSearch.cancel();

        return;
      }

      setSearchValue(value);
      setIsSearching(true);

      debouncedSearch(value);
    },
    [debouncedSearch, onSearchFinish],
  );

  const handleInputChangeSync = useCallback(
    (value: string) => {
      if (!value) {
        resetSelectData();
        onSearchFinish?.();

        return;
      }
      const items = findOptionBySubstring<T>({ options: defaultItems, substring: value });

      setSearchValue(value);
      setSearchedItems(items);
    },
    [defaultItems, onSearchFinish],
  );

  const getInputHandler = () => {
    if (onSearchStart) {
      return handleInputChangeAsync;
    }

    return handleInputChangeSync;
  };

  const resetSelectData = () => {
    setSearchValue('');
    setSearchedItems(defaultItems);
    setIsSearching(false);
    inputRef.current?.focus();
  };

  const handleSelectAll = () => {
    onSelectionChange(items);
  };

  const handleClear = () => {
    onSelectionChange([]);
  };

  const handleItemClick = (item: T) => {
    const selectedItemsCopy = [...selectedItems];
    const isItemExist = findById(selectedItemsCopy, item.id);

    if (isItemExist) {
      onSelectionChange(excludeById(selectedItemsCopy, item.id));
    } else {
      onSelectionChange([...selectedItemsCopy, item]);
    }
  };

  return (
    <div
      ref={containerRef}
      className={classnames(styles.mainWrapper, styles[size], wrapperClassName, className)}
    >
      <FlexiblePopover
        popoverComponent={PopoverItemList}
        popoverProps={{
          itemsContainerClass,
          searchValue,
          selectedItems,
          items,
          isSearching,
          itemComponent,
          resetSelectData,
          onItemClick: handleItemClick,
          onSelectAll: handleSelectAll,
          onClear: handleClear,
          containerRef,
        }}
        scrollElementRef={scrollElementRef}
        useScrollIntoPopoverView={useScrollIntoPopoverView}
        useChildrenWrapper
        justify={PopoverJustify.CenterStart}
        align={PopoverAlign.Bottom}
        closeOnClickOutside
        isCloseableOnResize={isCloseableOnResize}
        useOverlay={false}
      >
        <SelectInput
          wrapperForwardedRef={nonTriggerElementRef}
          searchValue={searchValue}
          inputPlaceholder={inputPlaceholder}
          allSelectedLabel={allSelectedLabel}
          itemsSelectLabel={itemsSelectLabel}
          selectedItemsCount={selectedItemsCount}
          getInputHandler={getInputHandler}
          onCrossClick={handleClear}
          inputRef={inputRef}
          theme={theme}
        />
      </FlexiblePopover>
    </div>
  );
};

export default SearchSelect;
