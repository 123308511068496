import React, { memo } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';
import i18n from 'i18n-smart';

import { BlockLink, NavigationMenu, NoDataLabel } from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { Stage } from 'packages/event-standings/types';
import { NarrowTeamStatisticsTable } from 'packages/shared/modules';
import {
  useActiveTabContentElement,
  useFilterTabs,
  useStageTabs,
} from 'packages/season-standings-ui/hooks';

import { getBracketProps, getFilterTabs, getTableProps } from './services';
import { HeaderSkeleton } from './components';

import styles from './styles.scss';

type Props = {
  activeUniqueTournamentId?: number | string;
  activeSeasonId?: number | string;
  activeTeamIds?: number[];
  activeEventId?: number;
  activeStageId?: number;
  onLinkClick?: () => void;
  useStagesControl?: boolean;
  useFilterControl?: boolean;
  stages?: Stage[];
  isLoading?: boolean;
};

const StandingsBlock = ({
  stages = [],
  activeUniqueTournamentId,
  activeTeamIds = [],
  activeEventId,
  activeSeasonId,
  activeStageId,
  useStagesControl = false,
  useFilterControl = false,
  onLinkClick,
  isLoading,
}: Props) => {
  const StageTabs = useStageTabs(stages, {
    getTableProps,
    getBracketProps,
    defaultActiveTabId: activeStageId,
    additionalProps: {
      activeTeamIds,
      activeUniqueTournamentId,
      activeSeasonId,
      activeEventId,
    },
  });
  const FilterTabs = useFilterTabs(stages, { getFilterTabs, activeStageTab: StageTabs.activeTab });
  const isTabsInitialized = !isEmpty(StageTabs.activeTab) && !isEmpty(FilterTabs.activeTab);
  const isStagesControlShown = StageTabs.tabs.length !== 1 && useStagesControl;
  const isFilterControlShown = FilterTabs.tabs.length > 1 && useFilterControl;
  const activeContentElement = useActiveTabContentElement(
    StageTabs.activeTab,
    FilterTabs.activeTab,
  );

  if (isLoading) {
    return (
      <div className={styles.tabsContainer}>
        <HeaderSkeleton />
        <div className={styles.content}>
          <NarrowTeamStatisticsTable.Desktop isLoading />
        </div>
      </div>
    );
  }

  if (!isTabsInitialized) {
    return (
      <div className={classnames(styles.tabsContainer, styles.noData)}>
        <div className={styles.header}>
          <div className={styles.leftHeaderBar}>
            <div className={styles.label}>{i18n.value('season.seasonStandings.title')}</div>
          </div>

          <BlockLink
            label={i18n.value('season.seasonStandings.link.fullStandings')}
            onClick={onLinkClick}
          />
        </div>

        <NoDataLabel className={styles.label} />
      </div>
    );
  }

  return (
    <div className={styles.tabsContainer}>
      <div
        className={classnames(styles.header, {
          [styles.withFilter]: isStagesControlShown || isFilterControlShown,
        })}
      >
        <div className={styles.leftHeaderBar}>
          <div className={styles.label}>{i18n.value('season.seasonStandings.title')}</div>
          {isStagesControlShown && (
            <NavigationMenu
              items={StageTabs.tabs}
              activeId={StageTabs.activeTab?.id || StageTabs.tabs[0].id}
              onClick={StageTabs.setActiveTabById}
              theme={NavigationTheme.Dropdown}
              className={styles.stageTabs}
            />
          )}

          {isFilterControlShown && (
            <NavigationMenu
              items={FilterTabs.tabs}
              activeId={FilterTabs.activeTab?.id || FilterTabs.tabs[0].id}
              onClick={FilterTabs.setActiveTabById}
              isDisabled={FilterTabs.isTabsDisabled}
              className={styles.filterTabs}
              theme={NavigationTheme.Dropdown}
            />
          )}
        </div>

        <BlockLink
          label={i18n.value('season.seasonStandings.link.fullStandings')}
          onClick={onLinkClick}
        />
      </div>

      <div className={styles.content}>{activeContentElement}</div>
    </div>
  );
};

export default memo(StandingsBlock);
