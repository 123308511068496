import getPath from './getPath';
import { EventActiveFilters, Tournament, EventFilters, PlayerTeam } from '../../types';

export const getData = (state): EventFilters => {
  return getPath(state).data;
};

export const getTournamentFilters = (state): Tournament[] => {
  return getData(state).tournaments;
};

export const getTeamFilters = (state): PlayerTeam[] => {
  return getData(state).teams;
};

export const getActiveFilter = (state): EventActiveFilters => {
  return getData(state).activeFilters;
};
