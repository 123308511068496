import { connect } from 'react-redux';

import { searchSelectors } from 'packages/search';

const mapStateToProps = (state) => {
  return {
    searchKey: searchSelectors.getSearchKey(state),
  };
};

export default connect(mapStateToProps);
