import React, { memo } from 'react';
import classnames from 'classnames';
import numeral from 'numeral';
import isEmpty from 'is-empty-typed/lib';
import i18n from 'i18n-smart';

import { PitchSize } from 'packages/stadium-details/types';
import { Figure } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  constructionYear: string;
  logo: string;
  countryName: string;
  city: string;
  capacity?: number;
  pitchSize?: PitchSize;
  className?: string;
};

const Description = ({
  className,
  constructionYear,
  logo,
  countryName,
  city,
  capacity,
  pitchSize,
}: Props) => {
  return (
    <div className={classnames(styles.description, className)}>
      <span className={styles.countryLabel}>
        <Figure src={logo} className={styles.logo} />
        {countryName}, {city}
      </span>

      {!isEmpty(constructionYear) && <span>Built: {constructionYear},</span>}
      {!isEmpty(capacity) && <span> Capacity: {numeral(capacity).format('0,0')}</span>}
      {!isEmpty(pitchSize) && (
        <span>
          , Pitch size: <span className={styles.main}>{pitchSize.x}</span>
          {i18n.value('common.unit.meters.short')} X{' '}
          <span className={styles.main}>{pitchSize.y}</span>
          {i18n.value('common.unit.meters.short')}
        </span>
      )}
    </div>
  );
};

export default memo(Description);
