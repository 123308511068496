import React, { memo } from 'react';

import { Controls, PaginationSkeleton } from './components';
import { ControlProps } from './types';

interface Props extends ControlProps {
  isLoading?: boolean;
}

const Pagination = (props: Props) => {
  const {
    useRightAlignment = false,
    isLimitSelectable = true,
    isLabelVisible = false,
    isLoading = false,
    className,
  } = props;

  if (isLoading) {
    return (
      <PaginationSkeleton
        className={className}
        isLimitSelectable={isLimitSelectable}
        useRightAlignment={useRightAlignment}
        isLabelVisible={isLabelVisible}
      />
    );
  }

  return <Controls {...props} />;
};

export default memo(Pagination);
