import { Cell, HeaderGroup, Hooks, Meta, TableCellProps, TableHeaderProps } from 'react-table';

import { getStickyStyle } from '../services';
import { FixedColumn } from '../types';

const useCustomSticky = <D extends object>(hooks: Hooks<D>) => {
  const fixedColumns = new Map<string, FixedColumn>();

  hooks.getHeaderProps.push(getHeaderProps(fixedColumns));
  hooks.getCellProps.push(getCellProps(fixedColumns));
};

useCustomSticky.pluginName = 'useCustomSticky';

const getHeaderProps = (fixedColumnsCollection: Map<string, FixedColumn>) => <D extends object>(
  props: Partial<TableHeaderProps>,
  meta: Meta<D, { column: HeaderGroup<D> }>,
) => {
  const { instance, column } = meta;
  const stickyStyle = getStickyStyle({
    column,
    columnInstance: instance.headers,
    accumulator: fixedColumnsCollection,
  });

  return [
    props,
    {
      style: stickyStyle,
    },
  ];
};

const getCellProps = (fixedColumnsCollection: Map<string, FixedColumn>) => <D extends object>(
  props: Partial<TableCellProps>,
  meta: Meta<D, { cell: Cell<D> }>,
) => {
  const {
    instance,
    cell: { column },
  } = meta;
  const stickyStyle = getStickyStyle({
    column,
    columnInstance: instance.columns,
    accumulator: fixedColumnsCollection,
  });

  return [
    props,
    {
      style: stickyStyle,
    },
  ];
};

export default useCustomSticky;
