import { PlayerHappeningType } from 'packages/events/types';
import { BaseHappening, Player, PlayerType } from 'packages/event-time-line/types';

const PLAYER_TYPES_BY_HAPPENING_TYPE = {
  [PlayerHappeningType.SCORE_CHANGE]: PlayerType.Scorer,
  [PlayerHappeningType.ASSISTS]: PlayerType.Assist,
  [PlayerHappeningType.SUBSTITUTION_IN]: PlayerType.SubstitutedIn,
  [PlayerHappeningType.SUBSTITUTION_OUT]: PlayerType.SubstitutedOut,
};

const getPlayer = (happening: BaseHappening): Player | undefined => {
  const { players, eventType } = happening;

  if (PLAYER_TYPES_BY_HAPPENING_TYPE[eventType]) {
    return players?.find(
      ({ playerType }) => playerType === PLAYER_TYPES_BY_HAPPENING_TYPE[eventType],
    );
  }

  return players?.[0];
};

export default getPlayer;
