exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1wHF1ESw{flex:1 1;margin-right:16px}._26xSPWMj{width:443px}._37ilghqr{flex:1 1;display:flex;align-items:flex-start;justify-content:center}._1BweQyn1{position:relative;display:flex;flex-direction:column;height:100%;width:100%}._2viw6net{margin-bottom:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktop/football/TeamGames/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,WAAY,CAPhB,WAWI,QAAO,CACP,YAAa,CACb,sBAAuB,CACvB,sBAAuB,CAd3B,WAkBI,iBAAkB,CAClB,YAAa,CACb,qBAAsB,CACtB,WAAY,CACZ,UAAW,CAtBf,WA0BI,kBC3BS","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n  }\n\n  .side-bar {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n  }\n\n  .advertisement {\n    margin-bottom: $margin;\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_1wHF1ESw",
	"leftColumn": "_1wHF1ESw",
	"right-column": "_26xSPWMj",
	"rightColumn": "_26xSPWMj",
	"left-bar": "_37ilghqr",
	"leftBar": "_37ilghqr",
	"side-bar": "_1BweQyn1",
	"sideBar": "_1BweQyn1",
	"advertisement": "_2viw6net"
};