import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Status } from '../types';
import { commonActions, squadActions } from '../actions';
import { DEFAULT_PLAYERS_LIMIT } from '../constants';

const defaultState: Status = {
  isInitialPageLoading: true,
  isNewPageLoading: false,
  offset: 0,
  count: 0,
  limit: DEFAULT_PLAYERS_LIMIT,
};

const reducerMap: ReducerMap<Status, Partial<Status>> = {
  [`${squadActions.loadPageStart}`]: (state) => {
    return { ...state, isInitialPageLoading: true };
  },

  [`${combineActions(squadActions.loadPageSuccess, squadActions.loadPageFailure)}`]: (state) => {
    return { ...state, isInitialPageLoading: false };
  },

  [`${squadActions.loadSquadStart}`]: (state) => {
    return { ...state, isNewPageLoading: true };
  },

  [`${squadActions.updateCountSuccess}`]: (state, { payload }) => {
    const { count = 0 } = payload;

    return { ...state, count };
  },

  [`${combineActions(squadActions.loadSquadSuccess, squadActions.loadSquadFailure)}`]: (state) => {
    return { ...state, isNewPageLoading: false };
  },

  [`${squadActions.updatePaginationSuccess}`]: (state, { payload }) => {
    const { offset = 0, limit = 0 } = payload;

    return { ...state, offset, limit };
  },

  [`${commonActions.clearSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
