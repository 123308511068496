import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

type Props = {
  title: string;
  isActive?: boolean;
  className?: string;
  id?: string;
};

const ColumnHeader = ({ id, title, className, isActive = false }: Props) => {
  return (
    <div id={id} className={classnames(styles.title, className, { [styles.active]: isActive })}>
      {title}
    </div>
  );
};

export default ColumnHeader;
