import React, { memo } from 'react';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { TeamStatistics } from 'packages/season-team-statistics-ui';

import styles from './styles.scss';

const SeasonTeamStatistics = () => {
  return (
    <Grid.SingleColumn isSecondaryOpen={false}>
      <div key={GridColumnId.Primary} className={styles.contentBlock}>
        <TeamStatistics.Mobile />
      </div>
      <></>
    </Grid.SingleColumn>
  );
};

export default memo(SeasonTeamStatistics);
