import React, { memo, useCallback, useEffect, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { Enums, Interfaces } from 'packages/team-player-statistics/types';
import { PaginationParams } from 'packages/shared/components/Pagination/types';

import PlayerStatisticsView from './views';
import connect from './connect';

type Props = {
  playersActions: ActionCreatorsMapObject;
  filtersActions: ActionCreatorsMapObject;
  customFilterActions: ActionCreatorsMapObject;
  commonActions: ActionCreatorsMapObject;
  tournaments?: SeasonsExtendedTournament[];
  customFilters: Interfaces.CustomFilter.CustomFilterState;
  playersStatistics: Interfaces.PlayerStatistics.PlayerStatistics[];
  filters: Interfaces.Filters.Filters;
  isFiltersLoading: boolean;
  isInitialPlayersPageLoading: boolean;
  isNewPlayersPageLoading: boolean;
  limit: number;
  count: number;
};

const extendedPlayerStatistics = (
  PlayerStatistics: typeof PlayerStatisticsView[keyof typeof PlayerStatisticsView],
) =>
  memo(
    ({
      playersActions,
      filtersActions,
      customFilterActions,
      commonActions,
      tournaments,
      customFilters,
      playersStatistics,
      filters,
      isFiltersLoading,
      isInitialPlayersPageLoading,
      isNewPlayersPageLoading,
      limit,
      count,
    }: Props) => {
      const { teamId, tournamentId, seasonId } = useParams();
      const [isExtendedFilterOpen, setIsExtendedFilterOpen] = useState(false);

      useEffect(() => {
        return () => commonActions.cleanPlayerStatistics();
      }, []);

      useEffect(() => {
        customFilterActions.updateGeneralFilter({
          tournamentId: tournamentId ? Number(tournamentId) : undefined,
          seasonId: seasonId ? Number(seasonId) : undefined,
        });
      }, [tournamentId, seasonId]);

      useEffect(() => {
        if (seasonId) {
          playersActions.loadInitialPlayers({ seasonId, teamId });
          filtersActions.loadFilters({ seasonId });
        }
      }, [seasonId, teamId]);

      const toggleExtendedFilter = useCallback(() => {
        setIsExtendedFilterOpen((isExtendedFilterOpen) => !isExtendedFilterOpen);
      }, []);

      const handleApplyClick = useCallback(() => {
        toggleExtendedFilter();
      }, []);

      const handleCancelClick = useCallback(() => {
        toggleExtendedFilter();
      }, []);

      const handleCustomizeButtonClick = useCallback(() => {
        toggleExtendedFilter();
      }, []);

      const loadPlayersPage = useCallback(() => {
        if (seasonId) {
          playersActions.loadPlayersPage({ seasonId, teamId });
        }
      }, [seasonId, teamId]);

      const handleOrderingChange = useCallback(
        (type: Enums.ColumnType) => {
          customFilterActions.updateConfigurableOrdering(type);
          loadPlayersPage();
        },
        [loadPlayersPage],
      );

      const handlePaginationChange = useCallback(
        (params: PaginationParams) => {
          playersActions.updatePagination(params);
          loadPlayersPage();
        },
        [loadPlayersPage],
      );

      return (
        <PlayerStatistics
          customFilterActions={customFilterActions}
          tournaments={tournaments}
          customFilters={customFilters}
          playersStatistics={playersStatistics}
          filters={filters}
          onCustomFilterApply={handleApplyClick}
          onCustomizeButtonClick={handleCustomizeButtonClick}
          onOrderingChange={handleOrderingChange}
          onPaginationChange={handlePaginationChange}
          onCustomFilterCancel={handleCancelClick}
          loadPlayersPage={loadPlayersPage}
          isFiltersLoading={isFiltersLoading}
          isInitialPlayersPageLoading={isInitialPlayersPageLoading}
          isNewPlayersPageLoading={isNewPlayersPageLoading}
          isExtendedFilterOpen={isExtendedFilterOpen}
          count={count}
          limit={limit}
        />
      );
    },
  );

export default {
  Desktop: connect(extendedPlayerStatistics(PlayerStatisticsView.Desktop)),
  DesktopNarrow: connect(extendedPlayerStatistics(PlayerStatisticsView.DesktopNarrow)),
  Tablet: connect(extendedPlayerStatistics(PlayerStatisticsView.Tablet)),
  TabletNarrow: connect(extendedPlayerStatistics(PlayerStatisticsView.TabletNarrow)),
  Mobile: connect(extendedPlayerStatistics(PlayerStatisticsView.Mobile)),
};
