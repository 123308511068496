import * as searchActions from './actions';
import * as searchSelectors from './selectors';
import * as searchThunks from './thunks';
import * as searchConstants from './constants';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export { searchActions, searchSelectors, searchThunks, createReducer, searchConstants };
