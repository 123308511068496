import { scheduledEventsSelectors } from '../../selectors';
import { scheduledEventsActions } from '../../actions';
import { getEventsAfterLiveRemove } from '../../services';

export const updateScheduledEvents = (newEvents) => async (dispatch, getState) => {
  try {
    dispatch(scheduledEventsActions.updateScheduledEventsStart());

    const state = getState();
    const oldEvents = scheduledEventsSelectors.getScheduledEvents(state);
    const updatedEvents = getEventsAfterLiveRemove(oldEvents, newEvents);

    dispatch(scheduledEventsActions.updateScheduledEventsSuccess(updatedEvents));
  } catch (error) {
    dispatch(scheduledEventsActions.updateScheduledEventsFailure(error));
  }
};
