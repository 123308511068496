import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState = {
  isEventTimeLineLoading: true,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${actions.loadEventTimeLineStart}`]: () => {
    return { isEventTimeLineLoading: true };
  },
  [`${combineActions(actions.loadEventTimeLineSuccess, actions.loadEventTimeLineFailure)}`]: () => {
    return { isEventTimeLineLoading: false };
  },
};

export default handleActions(reducerMap, defaultState);
