import { isArray } from 'packages/utils/Object.utils';
import DateService from 'packages/date-az';

export const mapIsoValueToDates = (value: string | string[]): Date[] => {
  if (isArray(value)) {
    return value.map(mapValue);
  }

  return [mapValue(value)];
};

const mapValue = (value: string): Date => {
  return DateService.parse(value).toJSDate();
};

export default mapIsoValueToDates;
