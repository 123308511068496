import getPath from '../getPath';
import { TopPlayersStatus } from '../../types';

const getStatus = (state): TopPlayersStatus => {
  return getPath(state).players.status;
};

export const getIsInitialTopPlayersLoading = (state): boolean => {
  return getStatus(state).isInitialTopPlayersLoading;
};

export const getIsTopPlayersLoading = (state): boolean => {
  return getStatus(state).isTopPlayersLoading;
};
