import { useEffect } from 'react';

import { drawCurrentDateButton, drawMonthLabelBorders, clearObservers } from '../services';

const useCurrentDateButtonAndMonthBorders = (
  scrollContainerRef,
  groupedDatesEntries,
  currentDateButtonRef,
  activeDateTime,
  currentDateTime,
) => {
  const intersectionObservers: IntersectionObserver[] = [];

  useEffect(() => {
    intersectionObservers.push(
      drawMonthLabelBorders(scrollContainerRef, groupedDatesEntries, activeDateTime),
      drawCurrentDateButton(
        scrollContainerRef,
        currentDateButtonRef,
        currentDateTime,
        activeDateTime,
      ),
    );

    return () => clearObservers(intersectionObservers);
  });
};

export default useCurrentDateButtonAndMonthBorders;
