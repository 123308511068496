import getPath from './getPath';
import { EventFilterStatus } from '../../types';

export const getStatus = (state): EventFilterStatus => {
  return getPath(state).status;
};

export const getIsInitialLoading = (state): boolean => {
  return getStatus(state).isInitialLoading;
};

export const getIsMoreLoading = (state): boolean => {
  return getStatus(state).isMoreLoading;
};
