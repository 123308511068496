import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { seasonNewcomersThunks, seasonNewcomersSelectors } from 'packages/season-newcomers';

const mapStateToProps = (state) => ({
  newcomers: seasonNewcomersSelectors.getData(state),
  isLoading: seasonNewcomersSelectors.getIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  seasonTopBoardActions: bindActionCreators(seasonNewcomersThunks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
