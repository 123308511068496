import { SeasonTeamStatistics } from 'packages/rest';

import { DEFAULT_TEAMS_LIMIT } from '../../constants';
import { customFilterSelectors } from '../../selectors';
import { formatRangeFilters, mapTeams, serializeOrdering } from '../../services';

const loadTeams = ({ seasonId, limit = DEFAULT_TEAMS_LIMIT, offset }) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { generalFilter, columnConfigurationFilter } = customFilterSelectors.getData(state);
  const { countries, ordering, activeStatsTab } = generalFilter;
  const { rangeFilters, configurableOrdering } = columnConfigurationFilter;
  const parameterRanges = formatRangeFilters(rangeFilters, activeStatsTab);

  const { data } = await SeasonTeamStatistics.loadTeams({
    seasonId,
    countries: countries.map(({ id }) => id),
    ordering: serializeOrdering({ ...configurableOrdering, ...ordering }, activeStatsTab),
    parameterRanges,
    offset,
    limit,
  });

  const { results: playerStatistics, next, count } = data;

  return {
    teams: mapTeams(playerStatistics, offset, activeStatsTab),
    offset: offset + playerStatistics.length,
    hasNext: !!next,
    count,
  };
};

export default loadTeams;
