import { Ordering, StadiumsOrdering } from 'packages/rest/api/SeasonStadiums/types';
import { OrderType } from 'types';

type Params = {
  orderingType: Ordering;
  currentOrdering: StadiumsOrdering;
};

const getOrderingByType = ({ orderingType, currentOrdering }: Params) => {
  const { name, type } = currentOrdering;

  if (name === orderingType) {
    return {
      name,
      type: type === OrderType.Descending ? OrderType.Ascending : OrderType.Descending,
    };
  }

  return { name: orderingType, type: OrderType.Descending };
};

export default getOrderingByType;
