import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Team, Event } from 'types';

import styles from './styles.scss';

type Props = {
  handleClick: () => void;
  firstLegEvent: Event;
  teams: [Team, Team];
  theme: 'light' | 'dark';
};

const FirstLeg = ({ firstLegEvent, teams, handleClick, theme = 'light' }: Props) => {
  const [homeTeam, awayTeam] = teams;

  const cardHomeTeam = firstLegEvent.teams.find(({ id }) => id === homeTeam.id);
  const cardAwayTeam = firstLegEvent.teams.find(({ id }) => id === awayTeam.id);
  const firstLegScore =
    cardHomeTeam?.score !== null && !!cardAwayTeam?.score !== null
      ? `${cardHomeTeam?.score}-${cardAwayTeam?.score}`
      : '-';

  return (
    <div className={classnames(styles.firstLegContainer, styles[theme])} onClick={handleClick}>
      {`${i18n.value('eventDetails.header.firstLeg')}: ${firstLegScore}`}
    </div>
  );
};

export default memo(FirstLeg);
