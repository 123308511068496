import React from 'react';
import { Column } from 'react-table';

import { Skeleton } from 'packages/shared/components';

import { Team, Relegation } from '../components';

type Params = {
  isTeamLabelVisible: boolean;
};

const getTableSchema = ({ isTeamLabelVisible }: Params): Column[] => {
  return [
    {
      id: 'year',
      Header: <Skeleton width={40} height={8} variant="rectangular" />,
      headerStyle: { textAlign: 'left', padding: '12px 0 12px 16px' },
      Cell: <Skeleton marginLeft={16} height={12} width={40} variant="rectangular" />,
      width: 50,
    },
    {
      id: 'winner',
      Header: <Skeleton width={45} height={8} variant="rectangular" />,
      headerStyle: { textAlign: 'left', padding: '12px 0' },
      Cell: <Team isTeamLabelVisible={isTeamLabelVisible} />,
      width: 50,
    },
    {
      id: 'runnerUp',
      Header: <Skeleton width={50} height={8} variant="rectangular" />,
      headerStyle: { textAlign: 'left', padding: '12px 0' },
      Cell: <Team isTeamLabelVisible={isTeamLabelVisible} />,
      width: 50,
    },
    {
      id: 'thirdPlace',
      Header: <Skeleton width={50} height={8} variant="rectangular" />,
      headerStyle: { textAlign: 'left', padding: '12px 0' },
      Cell: <Team isTeamLabelVisible={isTeamLabelVisible} />,
      width: 50,
    },
    {
      id: 'relegations',
      Header: <Skeleton width={50} height={8} variant="rectangular" />,
      headerStyle: { textAlign: 'left', padding: '12px 0' },
      accessor: 'relegations',
      Cell: Relegation,
      width: 50,
    },
  ];
};

export default getTableSchema;
