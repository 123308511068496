import Dexie, { liveQuery } from 'dexie';

import { keyValueDatabase } from 'packages/storage';
import { Tables, FavoritesTableIndex } from 'configure/storage/types';
import { settingsSelectors } from 'packages/settings';

import {
  observeFavoritesStart,
  observeFavoritesSuccess,
  observeFavoritesFailure,
} from '../actions';

const observeActiveFavorites = (onFavoriteChange) => (dispatch, getState) => {
  try {
    dispatch(observeFavoritesStart());

    const activeDate = settingsSelectors.getActiveDate(getState());
    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoritesTable = dexieInstance.table(Tables.FAVORITES_TABLE);

    const { unsubscribe } = liveQuery(() =>
      favoritesTable
        .where(FavoritesTableIndex.Date)
        .equals(activeDate)
        .toArray(),
    ).subscribe({
      next: onFavoriteChange,
    });

    dispatch(observeFavoritesSuccess());

    return unsubscribe;
  } catch (e) {
    dispatch(observeFavoritesFailure(e));
  }
};

export default observeActiveFavorites;
