import React, { memo, useCallback } from 'react';

import { FlexiblePopover, TeamsLogoList, TeamsListPopover } from 'packages/shared/components';
import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';
import { isEmpty } from 'packages/utils/Object.utils';
import { Team } from 'types';

import styles from './styles.scss';
import EmptyCell from '../EmptyCell';

type Props = {
  teams: Team[];
  onTeamClick: (team: Team) => void;
};

const RelegationCell = ({ teams, onTeamClick }: Props) => {
  const handleBlockClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  }, []);

  return isEmpty(teams) ? (
    <EmptyCell />
  ) : (
    <div className={styles.contentWrapper}>
      <FlexiblePopover
        closeOnClickOutside
        popoverComponent={TeamsListPopover}
        popoverProps={{ teams, onTeamClick }}
        justify={PopoverJustify.CenterStart}
        invokeTrigger={PopoverInvokeTrigger.Click}
        align={PopoverAlign.Bottom}
      >
        <div onClick={handleBlockClick}>
          <TeamsLogoList teams={teams} />
        </div>
      </FlexiblePopover>
    </div>
  );
};

export default memo(RelegationCell);
