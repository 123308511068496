import { toCamelCase } from 'packages/utils/Object.utils';

import { TEAM_DEFAULT_LOGO } from 'appConstants';
import { TopTeam } from '../types';

export const mapSeasonTopTeams = (seasonTopTeams: any[]): TopTeam[] => {
  return seasonTopTeams.map((player) => mapSeasonTopTeam(player));
};

const mapSeasonTopTeam = (seasonTopTeam: { team; statistics }): TopTeam => {
  const { team, statistics } = seasonTopTeam;
  const { id, logo, name } = toCamelCase(team);

  const {
    averageBallPossession,
    shotsTotal,
    goalsScored,
    shotsOnTarget,
    yellowCards,
    yellowredCards,
    redCards,
    goalsByHead,
    gamesPlayed,
  } = toCamelCase(statistics);

  return {
    id,
    team: {
      id,
      logo: logo || TEAM_DEFAULT_LOGO,
      name,
    },
    statistics: {
      goalsScored: goalsScored?.total || 0,
      goalsByHead: goalsByHead?.total || 0,
      goalAttempts: shotsTotal?.average || 0,
      shotsOnGoal: shotsOnTarget?.average || 0,
      ballPossession: averageBallPossession?.total || 0,
      redCards: redCards?.total || 0,
      yellowCards: yellowCards?.total || 0,
      yellowRedCards: yellowredCards?.total || 0,
      gamesPlayed: gamesPlayed || 0,
    },
  };
};
