import React from 'react';
import { GridColumnId, MediaScreen } from 'types';

import { getStickyHeaderTopOffset } from '../../services';
import { EventsTabs } from '../../components';

type Props = {
  categoryId?: string;
  className?: string;
};

const Tablet = ({ className, categoryId }: Props) => (
  <EventsTabs
    categoryId={categoryId}
    className={className}
    stickyHeaderTopOffset={getStickyHeaderTopOffset(MediaScreen.Tablet)}
    scrollContainerIdentifier={GridColumnId.Primary}
    useModalOnCategoryClick
  />
);

export default Tablet;
