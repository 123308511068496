import instance from 'packages/rest/axios';

import { stringifyOrderingParam } from '../../../services';

import { TeamSquadParams } from '../types';

export const loadTeamSquad = ({ teamId, limit, offset, ordering }: TeamSquadParams) => {
  const stringifiedOrdering = ordering
    ? stringifyOrderingParam({ [ordering.name]: ordering.type })
    : null;

  return instance({
    method: 'get',
    url: `/teams/${teamId}/players/`,
    params: {
      limit,
      offset,
      ordering: stringifiedOrdering,
    },
  });
};
