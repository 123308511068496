import { loadEventCard } from 'packages/rest/api/EventDetails';

import * as actions from '../actions';
import { mapEventDetails } from '../services';

export const openEventDetails = (eventId) => async (dispatch) => {
  try {
    dispatch(actions.loadEventDetailsStart());

    const {
      data: { results },
    } = await loadEventCard({ eventId });

    const mappedEventDetails = mapEventDetails(results);

    dispatch(actions.loadEventDetailsSuccess(mappedEventDetails));
  } catch (error) {
    dispatch(actions.loadEventDetailsFailure(error));
  }
};
