import React, { memo, useMemo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { FlexibleTable, Skeleton } from 'packages/shared/components';

import { getTableSchema } from './services';
import styles from './styles.scss';

type Props = {
  elementsCount: number;
};

const TableSkeleton = ({ elementsCount }: Props) => {
  const rows = ArrayUtils.getArrayFromNumber(elementsCount);
  const columns = useMemo(() => getTableSchema(), []);

  return (
    <>
      <div className={styles.header}>
        <Skeleton variant="rectangular" height={16} width={100} />
      </div>

      <FlexibleTable
        className={styles.table}
        data={rows}
        columns={columns}
        isHeaderSticky
        isRowClickable
      />
    </>
  );
};

export default memo(TableSkeleton);
