import React, { memo } from 'react';

import { History as SeasonHistory } from 'packages/season-history/types';
import { ExpandableBlock } from 'packages/shared/components';
import { Team } from 'types';

import { HistoryTable, TableSkeleton, Header } from '../../components';
import { HistoryTableTeamComponent } from './components';
import styles from './styles.scss';

type Props = {
  history: SeasonHistory[];
  onSeasonClick: (year: string) => void;
  teamClickGetter: (seasonId: string) => (team: Team) => void;
  isLoading: boolean;
};

const History = ({ history, onSeasonClick, teamClickGetter, isLoading }: Props) => {
  return (
    <div className={styles.seasonHistoryContainer}>
      <Header isLoading={isLoading} />

      <ExpandableBlock>
        <div className={styles.table}>
          {isLoading ? (
            <TableSkeleton elementsCount={8} />
          ) : (
            <HistoryTable
              teamComponent={HistoryTableTeamComponent}
              onSeasonClick={onSeasonClick}
              teamClickGetter={teamClickGetter}
              data={history}
            />
          )}
        </div>
      </ExpandableBlock>
    </div>
  );
};

export default memo(History);
