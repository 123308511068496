import { handleActions, ReducerMap } from 'redux-actions';

import { TimeZone } from 'types';

import * as actions from '../actions';

const defaultState = {
  name: '',
  value: -1,
};

const reducerMap: ReducerMap<TimeZone, TimeZone> = {
  [`${actions.setTimeZone}`]: (state, { payload }) => {
    const { value, name } = payload;

    return { value, name };
  },
};

export default handleActions(reducerMap, defaultState);
