import React, { CSSProperties, memo } from 'react';
import classnames from 'classnames';

import { SkeletonVariant, Theme } from './types';
import styles from './styles.scss';
import { DEFAULT_SIZE } from './constants';

interface Props extends CSSProperties {
  variant: keyof typeof SkeletonVariant;
  theme?: keyof typeof Theme;
  className?: string;
}

const Skeleton = ({
  variant,
  width = DEFAULT_SIZE,
  height = DEFAULT_SIZE,
  className,
  theme = 'light',
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
  maxWidth,
  minWidth,
  minHeight,
}: Props) => {
  const style: CSSProperties = {
    minWidth,
    maxWidth,
    width,
    height,
    minHeight,
    marginTop,
    marginRight,
    marginBottom,
    marginLeft,
  };
  const themeClass = classnames({
    [styles.light]: Theme[theme] === Theme.light,
    [styles.dark]: Theme[theme] === Theme.dark,
  });

  if (SkeletonVariant[variant] === SkeletonVariant.circular) {
    return (
      <div
        className={classnames(styles.skeleton, styles.circle, themeClass, className)}
        style={style}
      />
    );
  }

  return (
    <div
      className={classnames(styles.skeleton, styles.rectangle, themeClass, className)}
      style={style}
    />
  );
};

export default memo(Skeleton);
