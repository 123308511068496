const getParentRect = (parentEl: HTMLElement, insertIntoParent?: boolean): ClientRect => {
  return insertIntoParent
    ? getParentRectForInsertedPopover(parentEl)
    : getParentRectForRootPopover(parentEl);
};

const getParentRectForInsertedPopover = (parentEl: HTMLElement): ClientRect => {
  const { clientWidth, clientHeight } = parentEl;

  return {
    top: 0,
    left: 0,
    bottom: clientHeight,
    right: clientWidth,
    height: clientHeight,
    width: clientWidth,
  };
};

const getParentRectForRootPopover = (parentEl: HTMLElement): ClientRect => {
  return parentEl.getBoundingClientRect();
};

export default getParentRect;
