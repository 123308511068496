import React from 'react';
import i18n from 'i18n-smart';

import { ColumnType } from 'packages/season-player-statistics/types';
import { RefereeCard, SortableTableHeader, Tooltip } from 'packages/shared/components';
import { RefereeCardType } from 'packages/shared/components/RefereeCard/types';
import { OrderType } from 'types';

import styles from './styles.scss';

type Props = {
  type: ColumnType;
  sortingType?: OrderType;
  onOrderingChange: (columnType: ColumnType) => void;
};

const ColumnTitle = ({ type, sortingType, onOrderingChange }: Props) => {
  const getElementByType = (type) => {
    switch (type) {
      case ColumnType.RedCards:
        return (
          <Tooltip
            value={i18n.value(
              `playerStatistics.customFilter.columnConfiguration.columnName.${type}`,
            )}
          >
            <SortableTableHeader<ColumnType>
              onParameterClick={onOrderingChange}
              activeSortingType={sortingType}
              parameter={type}
            >
              <RefereeCard type={RefereeCardType.Red} className={styles.refereeCard} />
            </SortableTableHeader>
          </Tooltip>
        );
      case ColumnType.YellowRedCards:
        return (
          <Tooltip
            value={i18n.value(
              `playerStatistics.customFilter.columnConfiguration.columnName.${type}`,
            )}
          >
            <SortableTableHeader<ColumnType>
              onParameterClick={onOrderingChange}
              activeSortingType={sortingType}
              parameter={type}
            >
              <RefereeCard type={RefereeCardType.YellowRed} className={styles.refereeCard} />
            </SortableTableHeader>
          </Tooltip>
        );
      case ColumnType.YellowCards:
        return (
          <Tooltip
            value={i18n.value(
              `playerStatistics.customFilter.columnConfiguration.columnName.${type}`,
            )}
          >
            <SortableTableHeader<ColumnType>
              onParameterClick={onOrderingChange}
              activeSortingType={sortingType}
              parameter={type}
            >
              <RefereeCard type={RefereeCardType.Yellow} className={styles.refereeCard} />
            </SortableTableHeader>
          </Tooltip>
        );
      case ColumnType.Club:
        return (
          <>
            {i18n.value(
              `playerStatistics.customFilter.columnConfiguration.columnName.short.${type}`,
            )}
          </>
        );
      default:
        return (
          <Tooltip
            value={i18n.value(
              `playerStatistics.customFilter.columnConfiguration.columnName.${type}`,
            )}
          >
            <SortableTableHeader<ColumnType>
              onParameterClick={onOrderingChange}
              activeSortingType={sortingType}
              parameter={type}
            >
              <>
                {i18n.value(
                  `playerStatistics.customFilter.columnConfiguration.columnName.short.${type}`,
                )}
              </>
            </SortableTableHeader>
          </Tooltip>
        );
    }
  };

  return getElementByType(type);
};

export default ColumnTitle;
