import React, { memo } from 'react';
import classnames from 'classnames';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  className?: string;
  useRightAlignment?: boolean;
  isLimitSelectable?: boolean;
  isLabelVisible?: boolean;
};

const PaginationSkeleton = ({
  useRightAlignment,
  isLabelVisible,
  isLimitSelectable,
  className,
}: Props) => {
  return (
    <div className={classnames(styles.wrapper, className)}>
      {isLimitSelectable && (
        <>
          <Skeleton marginRight={8} width={40} height={12} variant="rectangular" />
          <Skeleton marginRight={8} width={58} height={32} variant="rectangular" />
        </>
      )}

      {isLabelVisible && <Skeleton width={120} height={12} variant="rectangular" />}

      <div
        className={classnames(styles.pagination, { [styles.rightAlignment]: useRightAlignment })}
      >
        <Skeleton width={36} height={32} variant="rectangular" />
        <Skeleton width={38} height={32} variant="rectangular" />
        <Skeleton width={38} height={32} variant="rectangular" />
        <Skeleton width={38} height={32} variant="rectangular" />
        <Skeleton width={36} height={32} variant="rectangular" />
      </div>
    </div>
  );
};
export default memo(PaginationSkeleton);
