import React, { useRef } from 'react';
import classnames from 'classnames';

import { MatchResult } from 'packages/shared/components';
import { FORM_MATCH_AMOUNT } from 'packages/event-standings/constants';

import { getEmptyFormMatches } from './services';
import EmptyMatchResult from './views/EmptyMatchResult';
import styles from './styles.scss';
import { Form } from './types';

type Props = {
  className?: string;
  onMatchMouseUp?: Function;
  emptyMatchesAmount?: number;
  isPopoverDisabled?: boolean;
  form: Form[];
};

const MatchHistory = ({
  className,
  emptyMatchesAmount = FORM_MATCH_AMOUNT,
  form = [],
  onMatchMouseUp,
  isPopoverDisabled = false,
}: Props) => {
  const containerRef = useRef(null);
  const emptyFormMatches = getEmptyFormMatches(form, emptyMatchesAmount);

  const getMatchMouseUpHandler = (id: string | number) => {
    return (event: React.MouseEvent<HTMLInputElement>) => {
      event.stopPropagation();
      onMatchMouseUp?.(id);
    };
  };

  return (
    <div
      ref={containerRef}
      className={classnames(
        styles.matchHistory,
        { [styles.separated]: !onMatchMouseUp },
        className,
      )}
    >
      {form.map(({ id, value, ...rest }) => (
        <MatchResult
          isPopoverDisabled={isPopoverDisabled}
          onMatchMouseUp={getMatchMouseUpHandler(id)}
          value={value}
          key={id}
          isClickable={!!onMatchMouseUp}
          {...rest}
        />
      ))}
      {emptyFormMatches.map(({ id }) => (
        <EmptyMatchResult key={id} />
      ))}
    </div>
  );
};

export default React.memo(MatchHistory);
