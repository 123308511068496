import React, { useMemo, useState, memo, useCallback } from 'react';
import isEmpty from 'is-empty-typed';
import classnames from 'classnames';
import { ActionCreatorsMapObject } from 'redux';

import { FavoriteIcon } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { Event, Team } from 'types';
import { DesktopPage } from 'router/types';

import { EventRowStatus, EventDate, EventForm } from '../../../../components';
import { FootballComponentProps } from '../../types';
import {
  AdditionalScore,
  RoundName,
  EventScore,
  EventTeam,
  Attendance,
  PlayerStatistic,
} from './components';
import styles from './styles.scss';
import connect from './connect';

interface Props extends FootballComponentProps {
  favoriteEvents: Event[];
  favoriteEventsThunks: ActionCreatorsMapObject;
}

const FootballEvent = ({
  event,
  onClick,
  className,
  favoriteEvents,
  favoriteEventsThunks,
  isDateVisible = false,
  isRoundVisible = false,
  isFormVisible = false,
  isFormPopoverDisabled = false,
  isPlayerStatsShown = false,
  isAttendanceShown = false,
}: Props) => {
  const { appNavigate } = useNavigation();
  const [isHovered, setIsHovered] = useState(false);

  const { teams, isLive, periodScores, id, date, round, form, playerStats, attendance } = event;
  const [home, away] = teams;
  const { isHomeTeam } = home;

  const isEventFavorite = useMemo(() => Boolean(favoriteEvents.find((event) => event.id === id)), [
    favoriteEvents,
    id,
  ]);

  const handleFavoriteIconClick = () => {
    favoriteEventsThunks.toggleEvent(event);
  };

  const handleTeamClick = useCallback(
    (team: Team) => {
      appNavigate(DesktopPage.FootballTeamSummaryDescription, {
        teamId: team.id,
        uniqueTournamentId: event.tournament.uniqueTournamentId,
        seasonId: event.seasonId,
      });
    },
    [event],
  );

  return (
    <div
      className={classnames(styles.eventRow, className)}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => onClick(event.id)}
    >
      <div className={styles.left}>
        {isDateVisible && <EventDate date={date} />}

        {!isPlayerStatsShown && (
          <EventRowStatus event={event} className={classnames({ [styles.statusLive]: isLive })} />
        )}

        {!isEmpty(playerStats) && isPlayerStatsShown && (
          <PlayerStatistic playerStats={playerStats} />
        )}

        <EventTeam
          isCardsShown={isHovered}
          team={home}
          isTeamFinesShown={!isPlayerStatsShown}
          onTeamClick={handleTeamClick}
          teamLabelClassName={styles.teamLabel}
        />
      </div>

      <EventScore className={styles.eventScore} isLive={isLive} teams={teams} />

      <div className={styles.right}>
        <EventTeam
          isCardsShown={isHovered}
          team={away}
          isTeamFinesShown={!isPlayerStatsShown}
          isReversed
          onTeamClick={handleTeamClick}
          teamLabelClassName={styles.teamLabel}
        />
        <AdditionalScore scores={periodScores} isFirstTeamAtHome={isHomeTeam} />

        {isFormVisible && <EventForm isPopoverDisabled={isFormPopoverDisabled} form={form} />}

        {isRoundVisible && <RoundName round={round} />}

        {isAttendanceShown && <Attendance attendance={attendance} />}

        <div className={styles.actions}>
          <FavoriteIcon
            isFavorite={isEventFavorite}
            className={styles.favoriteIcon}
            onClick={handleFavoriteIconClick}
          />
        </div>
      </div>
    </div>
  );
};

export default connect(memo(FootballEvent));
