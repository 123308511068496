export const duration = 150;

export const mainTransitionStyles = {
  entering: {
    transform: 'translateX(0%)',
    opacity: 1,
    transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  },
  entered: {
    transform: 'translateX(0%)',
    opacity: 1,
    transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  },
  exiting: {
    transform: 'translateX(-100%)',
    opacity: 0,
    transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  },
  exited: {
    opacity: 0,
    height: 0,
    width: 0,
    overflow: 'hidden',
    transform: 'translateX(-100%)',
  },
};

export const secondaryTransitionStyles = {
  entering: {
    transform: 'translateX(0)',
    opacity: 1,
    transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  },
  entered: {
    transform: 'translateX(0)',
    opacity: 1,
    transition: `transform ${duration}ms ease-in-out, opacity ${duration}ms ease-in-out`,
  },
  exiting: {
    transform: 'translateX(100%)',
    opacity: 0,
  },
  exited: {
    opacity: 0,
    height: 0,
    width: 0,
    transform: 'translateX(100%)',
  },
};
