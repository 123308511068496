import React, { memo, RefObject, useLayoutEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import {
  PopoverAlign,
  PopoverJustify,
  PopoverInvokeTrigger,
} from 'packages/shared/components/FlexiblePopover/types';
import FlexiblePopover from 'packages/shared/components/FlexiblePopover';
import { TeamLabel } from 'packages/shared/components';
import { isTextOverlaps } from 'packages/utils/Document.utils';
import { CardsExtendedCompetitor, Team } from 'types';

import { EventFines } from '../../../../../../components';
import TeamPopover from './components/TeamPopover';
import styles from './styles.scss';

type Props = {
  team: CardsExtendedCompetitor;
  isCardsShown: boolean;
  isReversed?: boolean;
  isTeamFinesShown?: boolean;
  onTeamClick?: (team: Team) => void;
  teamLabelClassName?: string;
};

const EventTeam = ({
  onTeamClick,
  team,
  isCardsShown,
  isReversed = false,
  isTeamFinesShown = true,
  teamLabelClassName,
}: Props) => {
  const labelRef: RefObject<HTMLDivElement> = useRef(null);
  const [isPopoverDisabled, setIsPopoverDisabled] = useState<boolean>(true);

  useLayoutEffect(() => {
    if (labelRef.current && isTextOverlaps(labelRef.current)) {
      setIsPopoverDisabled(false);
    }
  }, []);

  const { cards } = team;

  return (
    <div className={classnames(styles.eventTeam, { [styles.reversed]: isReversed })}>
      {isTeamFinesShown && (
        <EventFines className={classnames({ [styles.transparent]: !isCardsShown })} fines={cards} />
      )}
      <FlexiblePopover
        closeOnClickOutside
        popoverComponent={TeamPopover}
        popoverProps={{ name: team.name }}
        justify={PopoverJustify.Center}
        invokeTrigger={PopoverInvokeTrigger.Hover}
        align={PopoverAlign.Bottom}
        isDisabled={isPopoverDisabled}
      >
        <div className={styles.teamLabelWrapper}>
          <TeamLabel
            onLogoClick={onTeamClick}
            onNameClick={onTeamClick}
            forwardedRef={labelRef}
            className={classnames(styles.teamLabel, teamLabelClassName)}
            team={team}
            isLogoFirst={isReversed}
          />
        </div>
      </FlexiblePopover>
    </div>
  );
};

export default memo(EventTeam);
