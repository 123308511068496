import React from 'react';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';
import { MobilePage, NavigationRoutes } from 'router/types';
import { useNavigation } from 'packages/hooks';
import { MediaScreen } from 'types';

import styles from './styles.scss';

const SettingsBtn = () => {
  const { pathname } = useLocation();
  const { appNavigate, deltaNavigate } = useNavigation(MediaScreen.Mobile);

  const isActive = pathname === NavigationRoutes.Settings;

  const handleClick = () => {
    if (isActive) {
      deltaNavigate(-1);
    } else {
      appNavigate(MobilePage.Settings, {});
    }
  };

  return (
    <div className={classnames(styles.btn, { [styles.active]: isActive })} onClick={handleClick}>
      <Icon id={IconType.Settings} className={styles.icon} />
    </div>
  );
};

export default SettingsBtn;
