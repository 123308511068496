import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  actualGamesThunks,
  statisticsThunks,
  commonThunks,
  statisticsSelectors,
  tournamentsSelectors,
} from 'packages/stadium-summary';
import { settingsSelectors } from 'packages/settings';
import { stadiumDetailsSelectors } from 'packages/stadium-details';

const mapStateToProps = (state) => {
  return {
    timeZone: settingsSelectors.getTimeZone(state),
    isStatisticsLoading: statisticsSelectors.getIsLoading(state),
    details: stadiumDetailsSelectors.getData(state),
    homeTeams: statisticsSelectors.getHomeTeams(state),
    goalStatistics: statisticsSelectors.getGoalStatistics(state),
    attendance: statisticsSelectors.getAttendance(state),
    average: statisticsSelectors.getAverage(state),
    topScorers: statisticsSelectors.getTopScorers(state),
    winLose: statisticsSelectors.getWinLose(state),
    isDetailsLoading: stadiumDetailsSelectors.getIsLoading(state),
    tournamentSeasons: tournamentsSelectors.getTournaments(state),
    isTournamentsLoading: tournamentsSelectors.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actualGamesActions: bindActionCreators(actualGamesThunks, dispatch),
    statisticsActions: bindActionCreators(statisticsThunks, dispatch),
    commonActions: bindActionCreators(commonThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
