import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';

import { ScrollUtils } from 'packages/utils';

import { NavItem } from './types';
import styles from './styles.scss';

type Props<T extends NavItem> = {
  navigation: T[];
  isDisabled?: boolean;
  onItemClick?: (item: T) => void;
  className?: string;
  itemClassName?: string;
  activeItemClassName?: string;
};

const AnchorNavigation = <T extends NavItem>({
  navigation,
  isDisabled,
  onItemClick,
  itemClassName,
  activeItemClassName,
  className,
}: Props<T>) => {
  const getItemClickHandler = (item: T) => () => {
    ScrollUtils.scrollToById(item.id.toString(), 'nearest', 'center');
    onItemClick?.(item);
  };

  return (
    <div className={styles.navigationContainer}>
      <div data-scroll-lock-scrollable className={styles.navigationMenu}>
        <nav className={classnames(styles.navigation, className)}>
          {navigation.map((item) => {
            const elementId = item.id.toString();
            const isLinkDisabled = item.isDisabled || isDisabled;
            const linkClassName = classnames(styles.link, itemClassName);
            const activeLinkClassName = classnames(styles.active, activeItemClassName);

            return (
              <NavLink
                id={elementId}
                key={elementId}
                to={item.to}
                onClick={getItemClickHandler(item)}
                className={({ isActive }) => {
                  return classnames(linkClassName, {
                    [activeLinkClassName]: isActive,
                    [styles.disabled]: isLinkDisabled,
                  });
                }}
              >
                <div className={styles.label}>{item.label}</div>
              </NavLink>
            );
          })}
        </nav>
      </div>
    </div>
  );
};

export default AnchorNavigation;
