import React, { memo } from 'react';

import styles from './styles.scss';

type Props = {
  contract: string;
};

const ContractCell = ({ contract }: Props) => {
  return <div className={styles.cell}>{contract}</div>;
};

export default memo(ContractCell);
