import { Tournament } from 'types';
import { toCamelCase } from 'packages/utils/Object.utils';
import { mapTournament } from 'packages/events/services';

export const mapTournaments = (tournaments: any[]): Tournament[] => {
  return tournaments.map((tournament) => {
    const {
      id: uniqueTournamentId,
      currentSeasonId,
      name: uniqueTournamentName,
      ...restTournamentData
    } = toCamelCase(tournament);

    return mapTournament(restTournamentData, {
      currentSeasonId,
      uniqueTournamentId,
      uniqueTournamentName,
    });
  });
};
