export const DATES_RANGE = 10;
export const DATE_ITEM_WIDTH = 84;
export const MONTH_LABEL_WIDTH = 80;
export const MONTH_LABEL_AREA_WIDTH = 100;
export const FULL_VISIBLE_RATIO = 1;
export const HALF_VISIBLE_RATIO = 0.5;
export const NONE_VISIBLE_RATIO = 0;

export const DATEPICKER_BASE_HEIGHT = 64;
export const DATEPICKER_MOBILE_HEIGHT = 44;
