import React, { useCallback, useEffect, useState, memo } from 'react';
import { SortEnd } from 'react-sortable-hoc';

import { LoaderContainer, Separator } from 'packages/shared/components';
import { reorder } from 'packages/utils/Array.utils';
import { ComponentLike } from 'types';

import { DraggableContainer } from './components';
import { SortableItem } from '../../types';
import styles from './styles.scss';

type Props = {
  items: SortableItem[];
  onClick: (item: SortableItem) => void;
  onDelete?: (item: SortableItem) => void;
  onDragEnd?: (items: SortableItem[]) => void;
  itemComponent: ComponentLike;
  emptyMessage: string;
  isLoading?: boolean;
  useControlsHoverEffect?: boolean;
};

const DraggableWrapper = ({
  items,
  itemComponent,
  isLoading = false,
  emptyMessage,
  onClick,
  onDelete,
  onDragEnd,
  useControlsHoverEffect,
}: Props) => {
  const [localItems, setLocalItems] = useState(items);

  useEffect(() => {
    setLocalItems(items);
  }, [items]);

  const handleSortEnd = useCallback(
    ({ oldIndex, newIndex }: SortEnd) => {
      const orderedItems = reorder(items, oldIndex, newIndex);

      setLocalItems(orderedItems);
      onDragEnd?.(orderedItems);
    },
    [items],
  );

  const handleDeleteItem = useCallback((item: SortableItem) => {
    onDelete?.(item);
  }, []);

  return (
    <LoaderContainer isLoading={isLoading}>
      {localItems.length ? (
        <DraggableContainer
          useControlsHoverEffect={useControlsHoverEffect}
          helperClass={styles.draggingItem}
          draggableItems={localItems}
          itemComponent={itemComponent}
          onDelete={handleDeleteItem}
          onSortEnd={handleSortEnd}
          onClick={onClick}
          useDragHandle
        />
      ) : (
        <>
          <div className={styles.empty}>{emptyMessage}</div>
          <Separator />
        </>
      )}
    </LoaderContainer>
  );
};

export default memo(DraggableWrapper);
