import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadPlayerDetailsStart,
  loadPlayerDetailsSuccess,
  loadPlayerDetailsFailure,
  cleanPlayerDetailsSuccess,
} from '../actions';
import { Status } from '../types';

const defaultState: Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${loadPlayerDetailsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(loadPlayerDetailsFailure, loadPlayerDetailsSuccess)}`]: () => {
    return { isLoading: false };
  },
  [`${cleanPlayerDetailsSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
