import EventsTabsDesktop from './Desktop';
import EventsTabsTablet from './Tablet';
import EventsTabsMobile from './Mobile';

export default {
  Desktop: EventsTabsDesktop,
  DesktopNarrow: EventsTabsDesktop,
  Tablet: EventsTabsTablet,
  TabletNarrow: EventsTabsMobile,
  Mobile: EventsTabsMobile,
};
