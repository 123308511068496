import React, { memo } from 'react';

import { TeamLabel } from 'packages/shared/components';
import { Size } from 'packages/shared/components/TeamLabel/types';
import { Team } from 'types';

import styles from './styles.scss';

type Props = {
  team: Team;
  onTeamClick: (team: Team) => void;
};

const HistoryTableTeamComponent = ({ team, onTeamClick }: Props) => {
  return (
    <div className={styles.team}>
      <TeamLabel
        onLogoClick={onTeamClick}
        onNameClick={onTeamClick}
        className={styles.teamLogo}
        size={Size.Lg}
        team={team}
        isLogoFirst
      />
    </div>
  );
};

export default memo(HistoryTableTeamComponent);
