exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2Irwi6Y9{padding:48px 12px 48px 12px}.__bhJNtoJ{flex:1 1;display:flex;flex-direction:column}.__bhJNtoJ ._dsTpEPjv{position:-webkit-sticky;position:sticky;z-index:3;top:0}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/mobile/football/Category/components/Main/styles.scss"],"names":[],"mappings":"AAEA,WAEI,2BAA2D,CAF/D,WAMI,QAAO,CACP,YAAa,CACb,qBAAsB,CAR1B,sBAWM,uBAAgB,CAAhB,eAAgB,CAChB,SAAU,CACV,KAAM","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .modal-window {\n    padding: $header-height 12px $footer-navigation-height 12px;\n  }\n\n  .container {\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n\n    .sticky-block {\n      position: sticky;\n      z-index: 3;\n      top: 0;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"modal-window": "_2Irwi6Y9",
	"modalWindow": "_2Irwi6Y9",
	"container": "__bhJNtoJ",
	"sticky-block": "_dsTpEPjv",
	"stickyBlock": "_dsTpEPjv"
};