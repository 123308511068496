import React, { memo, useState } from 'react';
import classnames from 'classnames';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  onClick?: () => void;
  className?: string;
};

const Advertisement = ({ onClick, className }: Props) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  return (
    <div onClick={onClick} className={classnames(styles.advertisement, className)}>
      {isLoading && <Skeleton width={443} height={351} variant="rectangular" />}
      <img
        style={{ display: isLoading ? 'none' : 'block' }}
        alt="ads"
        src="/images/bofp_ad_1.svg"
        onLoad={handleImageLoad}
      />
    </div>
  );
};

export default memo(Advertisement);
