import { Team } from 'types';
import { CommonTableStatistics, SeasonTableRow } from 'packages/event-standings/types';

import styles from '../styles.scss';

type Params = {
  onClick: (team: Team) => void;
  activeTeamIds: number[];
};

const getRowSchema = ({ onClick, activeTeamIds }: Params) => {
  return (record: SeasonTableRow<CommonTableStatistics>) => {
    const { team } = record;
    const { id: currentTeamId } = team;

    return {
      onClick: () => onClick(team),
      className: activeTeamIds.includes(currentTeamId) ? styles.active : undefined,
    };
  };
};

export default getRowSchema;
