import { combineReducers } from 'redux';

import actualGames from './actualGames';
import statistics from './statistics';
import teams from './teams';

export default combineReducers({
  actualGames,
  statistics,
  teams,
});
