import { combineReducers } from 'redux';

import activeSport from './activeSport';
import locale from './locale';
import state from './state';
import timeZone from './timeZone';

export default combineReducers({
  activeSport,
  locale,
  state,
  timeZone,
});
