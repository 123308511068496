import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Figure } from 'packages/shared/components';
import { Division } from 'packages/season-newcomers/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import styles from './styles.scss';
import { NewcomersTitle } from '../../types';

type Props = {
  newcomers: Division[];
  className?: string;
  type: NewcomersTitle;
};

const Divisions = ({ newcomers, className, type }: Props) => {
  const { appNavigate } = useNavigation();

  const getDivisionClickHandler = (uniqueTournamentId: number, seasonId: number) => () => {
    appNavigate(DesktopPage.FootballSeason, { uniqueTournamentId, seasonId });
  };

  return (
    <div className={classnames(styles.divisionContainer, className)}>
      <div className={styles.title}>
        {i18n.value(`season.seasonTopBoard.newcomers.title.${type}`)}
      </div>
      <div className={styles.content}>
        {newcomers.map(({ id, name, logo, seasonId }) => (
          <div onClick={getDivisionClickHandler(id, seasonId)} key={id} className={styles.division}>
            <Figure src={logo} className={styles.logo} />
            <span className={styles.name}>{name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(Divisions);
