import { Enums, Interfaces } from 'packages/team-player-statistics/types';

const getColumnConfigurationSchema = (): Interfaces.CustomFilter.RangeFilterColumnsView => {
  return [
    [
      {
        type: Enums.RangeFilterGroupType.Attack,
        rangeFilters: [
          { type: Enums.ColumnType.Goals },
          { type: Enums.ColumnType.GoalsAndAssists },
          { type: Enums.ColumnType.HeadedGoals },
          { type: Enums.ColumnType.PenaltyGoals },
          { type: Enums.ColumnType.Shots },
          { type: Enums.ColumnType.ShotsOffGoal },
          { type: Enums.ColumnType.ShotsOnGoal },
          { type: Enums.ColumnType.Offside },
        ],
      },
    ],
    [
      {
        type: Enums.RangeFilterGroupType.Aggression,
        rangeFilters: [
          { type: Enums.ColumnType.YellowCards },
          { type: Enums.ColumnType.YellowRedCards },
          { type: Enums.ColumnType.RedCards },
        ],
      },
      {
        type: Enums.RangeFilterGroupType.Goalkeeper,
        rangeFilters: [{ type: Enums.ColumnType.CleanSheets }],
      },
      {
        type: Enums.RangeFilterGroupType.Passing,
        rangeFilters: [{ type: Enums.ColumnType.Assists }],
      },
    ],
    [
      {
        type: Enums.RangeFilterGroupType.Other,
        rangeFilters: [
          { type: Enums.ColumnType.GamesPlayed },
          { type: Enums.ColumnType.MinutesPlayed },
        ],
      },
    ],
  ];
};

export default getColumnConfigurationSchema;
