import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { favoriteEventsThunks } from 'packages/events';
import { favoritesSelectors } from 'packages/favorites';

const mapStateToProps = (state) => {
  return {
    favoriteTournaments: favoritesSelectors.getFavoriteLeagues(state),
    favoriteEvents: favoritesSelectors.getFavoriteEvents(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    favoriteEventsActions: bindActionCreators(favoriteEventsThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
