import isEmpty from 'is-empty-typed/lib';
import DateService from 'packages/date-az';

import { toCamelCase } from 'packages/utils/Object.utils';

const now = DateService.getDate().toISODate();

const findEventsPeriod = (events: object[]): [string, string] => {
  if (isEmpty(events)) {
    return [now, now];
  }

  if (events.length < 2) {
    const [event] = events;

    return [parseEventPeriod(event), parseEventPeriod(event)];
  }

  const [firstEvent] = events;
  const lastEvent = events[events.length - 1];

  return [parseEventPeriod(firstEvent), parseEventPeriod(lastEvent)];
};

const parseEventPeriod = (event: object): string => {
  const { scheduled } = toCamelCase(event);

  return DateService.parse(scheduled).toISODate();
};

export default findEventsPeriod;
