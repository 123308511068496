import { TournamentView } from 'packages/events/types';

export const sortTournamentsByFavoriteIds = (
  tournamentEventGroups: TournamentView[],
  favoriteLeagueUniqueTournamentIds: number[],
): TournamentView[] => {
  tournamentEventGroups.sort((firstComparingTournament, secondComparingTournament) => {
    const firstFavoriteTournamentIndex = favoriteLeagueUniqueTournamentIds.indexOf(
      firstComparingTournament.uniqueTournamentId,
    );
    const secondFavoriteTournamentIndex = favoriteLeagueUniqueTournamentIds.indexOf(
      secondComparingTournament.uniqueTournamentId,
    );

    if (firstFavoriteTournamentIndex !== -1 && secondFavoriteTournamentIndex === -1) {
      return -1;
    } else if (firstFavoriteTournamentIndex === -1 && secondFavoriteTournamentIndex !== -1) {
      return 1;
    }

    return firstFavoriteTournamentIndex - secondFavoriteTournamentIndex;
  });

  return tournamentEventGroups;
};
