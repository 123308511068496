import { createElement } from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { TeamLabel } from 'packages/shared/components';
import { Interfaces } from 'packages/player-summary/types';
import { Size } from 'packages/shared/components/TeamLabel/types';
import { Team } from 'types';

import { ContractCell, DateCell } from '../../../components/TransferHistory/components';
import { GetColumnSchemaParams } from '../../../components/TransferHistory/types';

interface Params {
  onTeamClick: (team: Team) => void;
}

export default ({ onTeamClick }: Params): Column<Interfaces.Teams.TransferHistory>[] => {
  return [
    {
      id: 'startDate',
      Header: i18n.value('player.playerSummary.transferHistory.column.startDate'),
      headerStyle: { textAlign: 'left', paddingLeft: '16px' },
      accessor: ({ startDate, endDate }) => ({ startDate, endDate }),
      Cell: ({ value: props }) =>
        createElement(DateCell, {
          ...props,
          isStartDate: true,
        }),
      width: 120,
    },
    {
      id: 'endDAte',
      Header: i18n.value('player.playerSummary.transferHistory.column.endDate'),
      headerStyle: { textAlign: 'left', paddingLeft: '16px' },
      accessor: ({ startDate, endDate }) => ({ startDate, endDate }),
      Cell: ({ value: props }) => createElement(DateCell, props),
      width: 120,
    },
    {
      id: 'team',
      Header: i18n.value('player.playerSummary.transferHistory.column.club'),
      headerStyle: { textAlign: 'left' },
      accessor: 'team',
      Cell: ({ value: team }) =>
        createElement(TeamLabel, {
          team,
          onNameClick: onTeamClick,
          onLogoClick: onTeamClick,
          size: Size.Lg,
          isLogoFirst: true,
          isNameHidden: true,
        }),
      width: 40,
    },
    {
      id: 'contactType',
      Header: i18n.value('player.playerSummary.transferHistory.column.contractType'),
      headerStyle: { textAlign: 'left', paddingRight: '16px' },
      accessor: 'playerRole',
      Cell: ({ value: playerRole }) => createElement(ContractCell, { contract: playerRole }),
      width: 100,
    },
  ];
};
