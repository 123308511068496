import React, { memo, RefObject, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { useNonInitialEffect } from 'packages/hooks';
import { Interfaces, Interfaces as PlayerSummaryInterfaces } from 'packages/player-summary/types';
import { Team } from 'types';

import SummaryView from './views';
import connect from './connect';

type Props = {
  teams: Team[];
  commonActions: ActionCreatorsMapObject;
  tournaments: Interfaces.ActualGames.Data;
  actualGamesActions: ActionCreatorsMapObject;
  parentContainerRef?: RefObject<HTMLDivElement>;
  tournamentSeasonsActions: ActionCreatorsMapObject;
  teamsThunks: ActionCreatorsMapObject;
  history: PlayerSummaryInterfaces.Teams.TransferHistory[];
  statisticsActions: ActionCreatorsMapObject;
  playerSummaryStatistics: PlayerSummaryInterfaces.Statistics.Statistics.Data;
  isActualGamesLoading: boolean;
  isWholeStatisticsLoading: boolean;
  isTeamsLoading: boolean;
  selectedSeasonId?: number;
  actualGamesLimit: number;
};

const extendSummary = (Summary: typeof SummaryView[keyof typeof SummaryView]) => {
  return memo(
    ({
      teams,
      commonActions,
      parentContainerRef,
      history,
      tournaments,
      actualGamesActions,
      teamsThunks,
      statisticsActions,
      tournamentSeasonsActions,
      playerSummaryStatistics,
      isActualGamesLoading,
      isWholeStatisticsLoading,
      selectedSeasonId,
      actualGamesLimit,
      isTeamsLoading,
    }: Props) => {
      const { playerId } = useParams();

      useEffect(() => {
        return commonActions.cleanSummary;
      }, []);

      useEffect(() => {
        if (teams.length) {
          tournamentSeasonsActions.loadTournamentSeasons({ playerId, teamId: teams[0].id });
        }
      }, [playerId, teams]);

      useEffect(() => {
        actualGamesActions.loadActualGames({ playerId, actualGamesLimit });
        teamsThunks.loadTeams({ playerId });
      }, [actualGamesLimit, playerId]);

      useNonInitialEffect(() => {
        if (selectedSeasonId) {
          statisticsActions.loadSeasonStatistics({ playerId, seasonId: selectedSeasonId });
        }
      }, [selectedSeasonId, playerId]);

      return (
        <Summary
          isTeamsLoading={isTeamsLoading}
          teams={teams}
          playerSummaryStatistics={playerSummaryStatistics}
          isWholeStatisticsLoading={isWholeStatisticsLoading}
          history={history}
          tournaments={tournaments}
          parentContainerRef={parentContainerRef}
          isActualGamesLoading={isActualGamesLoading}
        />
      );
    },
  );
};

export default {
  Desktop: connect(extendSummary(SummaryView.Desktop)),
  DesktopNarrow: connect(extendSummary(SummaryView.DesktopNarrow)),
  Tablet: connect(extendSummary(SummaryView.Tablet)),
  TabletNarrow: connect(extendSummary(SummaryView.TabletNarrow)),
  Mobile: connect(extendSummary(SummaryView.Mobile)),
};
