import React from 'react';
import classnames from 'classnames';

import { Figure } from 'packages/shared/components';
import { EventSummaryReferee } from 'packages/event-summary/types';

import styles from './styles.scss';

type Props = {
  referee: EventSummaryReferee;
  className?: string;
};

const RefereePreview = ({ referee, className }: Props) => {
  return (
    <div className={classnames(styles.refereePreview, className)}>
      <Figure src={referee.countryLogo} className={styles.countryLogo} />
      <span>{referee.name}</span>
    </div>
  );
};

export default React.memo(RefereePreview);
