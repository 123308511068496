import React from 'react';
import i18n from 'i18n-smart';

import { ButtonType } from 'packages/shared/components/Button/types';
import { Button, Link } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  onApply: () => void;
  onCancel: () => void;
  onClear: () => void;
};

const Footer = ({ onApply, onCancel, onClear }: Props) => {
  return (
    <div className={styles.footer}>
      <div className={styles.buttonGroup}>
        <Link onClick={onClear}>
          {i18n.value('season.playerStatistics.customFilter.clearFilter')}
        </Link>
      </div>

      <div className={styles.buttonGroup}>
        <Button type={ButtonType.Default} onClick={onCancel}>
          season.playerStatistics.customFilter.cancelFilter
        </Button>
        <Button type={ButtonType.Primary} onClick={onApply}>
          season.playerStatistics.customFilter.applyFilter
        </Button>
      </div>
    </div>
  );
};

export default Footer;
