import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { initialEventsActions, moreEventsActions, commonActions } from '../../actions';

import { Data } from '../../types';

const defaultState: Data = [];

const reducerMap: ReducerMap<Data, Data> = {
  [`${combineActions(
    initialEventsActions.loadInitialEventsSuccess,
    moreEventsActions.loadFilteredEventsSuccess,
  )}`]: (state, { payload }) => {
    return payload;
  },

  [`${combineActions(
    initialEventsActions.loadInitialEventsFailure,
    moreEventsActions.loadFilteredEventsFailure,
    commonActions.clearSuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
