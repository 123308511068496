import React from 'react';
import isEmpty from 'is-empty-typed';
import classnames from 'classnames';

import EmptyCell from '../EmptyCell';
import styles from './styles.scss';

type Props = {
  value?: string | number;
  isColorGray?: boolean;
};

const TextCell = ({ value, isColorGray = false }: Props) => {
  return isEmpty(value) ? (
    <EmptyCell />
  ) : (
    <div className={classnames({ [styles.gray]: isColorGray })}>{value}</div>
  );
};

export default TextCell;
