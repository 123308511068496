import getState from './getState';
import { State, Status } from '../types';

export const getStatus = (state: State): Status => {
  return getState(state).status;
};

export const getIsEventStandingsLoading = (state: State): boolean => {
  return getStatus(state).isEventStandingsLoading;
};
