import React, { FormEvent, memo, RefObject } from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

type Props = {
  name?: string;
  onChange: (e: FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: FormEvent<HTMLInputElement>) => void;
  value?: string | number;
  placeholder?: string;
  className?: string;
  isAutoFocused?: boolean;
  forwardedRef?: RefObject<HTMLInputElement>;
};

const PlainTextInput = ({
  value = '',
  className,
  onChange,
  onBlur,
  name,
  placeholder,
  isAutoFocused = false,
  forwardedRef,
}: Props) => {
  return (
    <input
      id={name}
      name={name}
      type="text"
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      className={classnames(styles.textInput, className)}
      placeholder={placeholder}
      autoFocus={isAutoFocused}
      ref={forwardedRef}
    />
  );
};

export default memo(PlainTextInput);
