import React, { useMemo, memo, useRef, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Column } from 'react-table';
import classnames from 'classnames';
import i18n from 'i18n-smart';
import { SearchQuery } from 'router/types';

import { Team } from 'types';
import { FlexibleTable, Skeleton } from 'packages/shared/components';
import { Interfaces, Interfaces as PlayerSummaryInterfaces } from 'packages/player-summary/types';
import { getScrollParent } from 'packages/utils/Scroll.utils';

import styles from './styles.scss';
import { TableSkeleton } from './components';
import { scrollToHistory } from './services';

type Props = {
  history: PlayerSummaryInterfaces.Teams.TransferHistory[];
  getColumnSchema: (GetColumnSchemaParams) => Column<Interfaces.Teams.TransferHistory>[];
  getSkeletonColumnSchema: () => Column[];
  handleTeamClick: (team: Team) => void;
  className?: string;
  isLoading: boolean;
  isScrollable?: boolean;
  isAllDataLoaded: boolean;
  headerTopOffset?: number;
};

const TransferHistory = ({
  handleTeamClick,
  history,
  className,
  getColumnSchema,
  isScrollable = false,
  isLoading,
  getSkeletonColumnSchema,
  isAllDataLoaded,
  headerTopOffset = 0,
}: Props) => {
  const [searchParams] = useSearchParams();
  const transferBlockRef = useRef<HTMLDivElement>(null);
  const transferTeamId = searchParams.get(SearchQuery.TransferTeamId);

  useEffect(() => {
    if (transferTeamId && isAllDataLoaded) {
      setTimeout(() => scrollToHistory({ transferBlockRef, headerTopOffset }));
    }
  }, [isAllDataLoaded, transferTeamId]);

  const columns = useMemo(() => getColumnSchema({ onTeamClick: handleTeamClick }), [
    handleTeamClick,
  ]);

  return (
    <div
      ref={transferBlockRef}
      className={classnames(styles.transferBlock, { [styles.scrollable]: isScrollable }, className)}
    >
      {isLoading ? (
        <>
          <div className={styles.header}>
            <Skeleton height={16} width={120} variant="rectangular" />
          </div>

          <TableSkeleton getColumnSchema={getSkeletonColumnSchema} className={styles.table} />
        </>
      ) : (
        <>
          <div className={styles.header}>
            {i18n.value('player.playerSummary.transferHistory.title')}
          </div>

          <FlexibleTable<PlayerSummaryInterfaces.Teams.TransferHistory>
            data={history}
            columns={columns}
            className={styles.table}
          />
        </>
      )}
    </div>
  );
};

export default memo(TransferHistory);
