import Desktop from './Desktop';
import Mobile from './Mobile';

export default {
  Desktop,
  DesktopNarrow: Mobile,
  Tablet: Mobile,
  TabletNarrow: Mobile,
  Mobile: Mobile,
};
