import { BaseHappening, EventType, GoalHappening } from 'packages/event-stats/types';
import { HappeningMethod } from 'packages/event-time-line/types';

export const getGoalHappening = (happening: BaseHappening): GoalHappening => {
  const { id, additionalInfo, players, eventType, qualifier, minute } = happening;

  return {
    id,
    players,
    eventType,
    qualifier,
    minute,
    additionalInfo: {
      isOwnGoal: additionalInfo?.method === HappeningMethod.OwnGoal,
      isPenaltyGoal: additionalInfo?.method === HappeningMethod.Penalty,
      isPenaltyMissed: eventType === EventType.PenaltyMissed,
    },
  };
};
