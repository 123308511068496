import React from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.skeleton}>
      <div className={styles.header}>
        <Skeleton variant="circular" width={16} height={16} marginRight={8} />
        <Skeleton variant="rectangular" width={120} height={16} />
      </div>

      <div className={styles.content}>
        <Skeleton variant="rectangular" width={360} height={16} />
        <Skeleton variant="rectangular" width={360} height={16} />
        <Skeleton variant="rectangular" width={360} height={16} />
      </div>
    </div>
  );
};

export default LoadingSkeleton;
