import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';
import { Coefficient } from './types';

type Props = {
  time?: string;
  name?: string;
  coeffs?: Coefficient[];
};

const FeaturedItem = ({ time = '12.00', name = 'Tottenham Liverpool', coeffs = mockUp }: Props) => {
  return (
    <div className={styles.featuredItem}>
      <span className={styles.featuredItemTime}>{time}</span>
      <span className={styles.featuredItemName}>{name}</span>

      <ul className={styles.featuredItemCoeffs}>
        {coeffs.map((item) => {
          const className = classnames(styles.coeffItem, {
            [styles.coeffItemShift]: item.shift === 1,
            [styles.coeffItemUnshift]: item.shift === -1,
          });

          return (
            <li key={item.id} className={className}>
              {item.value}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default FeaturedItem;

const mockUp: Coefficient[] = [
  {
    id: '1',
    value: '2.00',
    shift: 1,
  },
  {
    id: '2',
    value: '2.42',
    shift: -1,
  },
  {
    id: '3',
    value: '1.03',
    shift: 1,
  },
  {
    id: '4',
    value: '2.20',
    shift: 1,
  },
  {
    id: '5',
    value: '3.00',
    shift: -1,
  },
];
