import React, { memo } from 'react';

import { Interfaces as StadiumSummaryInterfaces } from 'packages/stadium-summary/types';

import DetailsSelector from '../../../DetailsSelector';
import { ActualGames } from './components';
import styles from './styles.scss';
import {
  Attendance,
  Average,
  GoalStatistics,
  TopScorers,
  WinLose,
  DetailsSelectorSkeleton,
} from '../../components';

type Props = {
  isStatisticsLoading: boolean;
  isTournamentsLoading: boolean;
  goalStatistics?: StadiumSummaryInterfaces.Statistics.GoalStatistics;
  attendance?: StadiumSummaryInterfaces.Statistics.Attendance;
  average?: StadiumSummaryInterfaces.Statistics.Average;
  topScorers?: StadiumSummaryInterfaces.Statistics.TopScorer[];
  winLose?: StadiumSummaryInterfaces.Statistics.WinLose;
};

const StadiumSummary = ({
  isStatisticsLoading,
  isTournamentsLoading,
  goalStatistics,
  attendance,
  average,
  topScorers,
  winLose,
}: Props) => {
  return (
    <div className={styles.teamSummary}>
      {isTournamentsLoading ? <DetailsSelectorSkeleton /> : <DetailsSelector.Desktop />}

      <div className={styles.details}>
        <ActualGames />

        <div className={styles.statisticsContainer}>
          <div className={styles.leftGroup}>
            <GoalStatistics isLoading={isStatisticsLoading} goalStatistics={goalStatistics} />

            <TopScorers isLoading={isStatisticsLoading} topScorers={topScorers} />

            <Average isLoading={isStatisticsLoading} average={average} />
          </div>

          <div className={styles.rightGroup}>
            <Attendance
              isLoading={isStatisticsLoading}
              attendance={attendance}
              className={styles.attendance}
            />

            <WinLose isLoading={isStatisticsLoading} winLose={winLose} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(StadiumSummary);
