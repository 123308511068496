import React, { memo } from 'react';

import { Venue } from 'packages/season-top-board/types';

import styles from './styles.scss';

type Props = {
  venue: Venue;
};

const VenueCell = ({ venue }: Props) => {
  return <div className={styles.stadiumCell}>{venue.name}</div>;
};

export default memo(VenueCell);
