import Dexie from 'dexie';

import { Tables } from 'configure/storage/types';
import { keyValueDatabase } from 'packages/storage';

import loadLatestLeaguesFromStorage from './loadLatestLeaguesFromStorage';
import loadLatestTeamsFromStorage from './loadLatestTeamsFromStorage';
import loadLatestEventsFromStorage from './loadLatestEventsFromStorage';
import {
  loadLatestFavoritesFromStorageStart,
  loadLatestFavoritesFromStorageFailure,
  loadLatestFavoritesFromStorageSuccess,
} from '../actions';

const loadLatestFavoritesFromStorage = () => async (dispatch) => {
  try {
    dispatch(loadLatestFavoritesFromStorageStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);
    const favoriteEventsModel = dexieInstance.table(Tables.FAVORITE_EVENTS);
    const favoriteTeamsModel = dexieInstance.table(Tables.FAVORITE_TEAMS);
    const favoriteLeaguesModel = dexieInstance.table(Tables.FAVORITE_LEAGUES);

    const [updatedEvents, updatedLeagues, updatedTeams] = await dexieInstance.transaction(
      'r',
      [favoritesModel, favoriteEventsModel, favoriteTeamsModel, favoriteLeaguesModel],
      () =>
        Promise.all([
          dispatch(loadLatestEventsFromStorage()),
          dispatch(loadLatestLeaguesFromStorage()),
          dispatch(loadLatestTeamsFromStorage()),
        ]),
    );

    dispatch(
      loadLatestFavoritesFromStorageSuccess({
        events: updatedEvents,
        teams: updatedTeams,
        leagues: updatedLeagues,
      }),
    );
  } catch (e) {
    dispatch(loadLatestFavoritesFromStorageFailure(e));
  }
};

export default loadLatestFavoritesFromStorage;
