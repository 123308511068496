import React, { memo } from 'react';
import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed';
import classnames from 'classnames';

import {
  useActiveTabContentElement,
  useFilterTabs,
  useStageTabs,
} from 'packages/season-standings-ui/hooks';
import {
  ExpandableBlock,
  BlockLink,
  NavigationMenu,
  NoDataLabel,
} from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { Stage, StageType } from 'packages/event-standings/types';

import { getBracketProps, getTableProps, getFilterTabs } from './services';
import { LoadingSkeleton } from '../../components';
import styles from './styles.scss';

type Props = {
  stages: Stage[] | null;
  activeStageId?: number;
  onLinkClick: () => void;
  activeTeamIds?: number[];
  activeUniqueTournamentId?: number | string;
  activeSeasonId?: number | string;
  activeEventId?: number;
  useStagesControl?: boolean;
  useFilterControl?: boolean;
  isLoading?: boolean;
};

const StandingsBlock = ({
  stages,
  activeStageId,
  onLinkClick,
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
  activeEventId,
  useStagesControl = false,
  useFilterControl = false,
  isLoading = false,
}: Props) => {
  const StageTabs = useStageTabs(stages, {
    getTableProps,
    getBracketProps,
    defaultActiveTabId: activeStageId,
    additionalProps: {
      activeTeamIds,
      activeUniqueTournamentId,
      activeSeasonId,
      activeEventId,
    },
  });
  const FilterTabs = useFilterTabs(stages, { getFilterTabs, activeStageTab: StageTabs.activeTab });
  const isTabsInitialized = !isEmpty(StageTabs.activeTab) && !isEmpty(FilterTabs.activeTab);
  const isStagesControlShown = StageTabs.tabs.length !== 1 && useStagesControl;
  const isFilterControlShown = FilterTabs.tabs.length > 1 && useFilterControl;

  const activeContentElement = useActiveTabContentElement(
    StageTabs.activeTab,
    FilterTabs.activeTab,
  );

  if (isLoading) {
    return (
      <div className={classnames(styles.tabsContainer, styles.skeleton)}>
        <LoadingSkeleton elementsCount={11} />
      </div>
    );
  }

  if (!isTabsInitialized) {
    return (
      <div className={classnames(styles.tabsContainer, styles.noData)}>
        <div className={styles.header}>
          <div className={styles.leftHeaderBar}>
            <div className={styles.label}>{i18n.value('season.seasonStandings.title')}</div>
          </div>

          <BlockLink
            label={i18n.value('season.seasonStandings.link.fullStandings')}
            onClick={onLinkClick}
          />
        </div>

        <NoDataLabel className={styles.label} />
      </div>
    );
  }

  return (
    <div className={styles.tabsContainer}>
      <div
        className={classnames(styles.header, {
          [styles.withFilter]: isStagesControlShown || isFilterControlShown,
        })}
      >
        <div className={styles.leftHeaderBar}>
          <div className={styles.label}>{i18n.value('season.seasonStandings.title')}</div>
          {isStagesControlShown && (
            <NavigationMenu
              items={StageTabs.tabs}
              activeId={StageTabs.activeTab?.id || ''}
              onClick={StageTabs.setActiveTabById}
              theme={NavigationTheme.Dropdown}
              className={styles.stageTabs}
            />
          )}
          {isFilterControlShown && (
            <NavigationMenu
              items={FilterTabs.tabs}
              activeId={FilterTabs.activeTab?.id || ''}
              onClick={FilterTabs.setActiveTabById}
              isDisabled={FilterTabs.isTabsDisabled}
              className={styles.filterTabs}
              theme={NavigationTheme.Dropdown}
            />
          )}
        </div>

        <BlockLink
          label={i18n.value('season.seasonStandings.link.fullStandings')}
          onClick={onLinkClick}
        />
      </div>

      {StageTabs.activeTab?.type === StageType.Tables ? (
        <ExpandableBlock
          showLessLabel={i18n.value('season.seasonStandings.expandableBlock.label.showLess')}
          showMoreLabel={i18n.value('season.seasonStandings.expandableBlock.label.showMore')}
          className={styles.tablesContainer}
        >
          {activeContentElement}
        </ExpandableBlock>
      ) : (
        <div className={styles.bracketsContainer}>{activeContentElement}</div>
      )}
    </div>
  );
};

export default memo(StandingsBlock);
