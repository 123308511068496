import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { settingsThunks, settingsSelectors } from 'packages/settings';
import { commonSelectors } from 'packages/events';
import { appSelectors } from 'modules/app';

const mapStateToProps = (state) => {
  return {
    activeDate: settingsSelectors.getActiveDate(state),
    localeCode: settingsSelectors.getLocaleCode(state),
    isLoading: commonSelectors.getIsAnyEventGroupLoading(state),
    isMobile: appSelectors.getIsMobileScreen(state),
    isAnyDesktop: appSelectors.getIsAnyDesktopScreen(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    settingsThunks: bindActionCreators(settingsThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
