exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1Gzv-Ybd{display:flex;align-items:center;overflow:hidden}._1Gzv-Ybd ._3OzBavhx{border-radius:50%;margin-right:8px;min-width:16px;max-width:16px;min-height:16px;max-height:16px}._1Gzv-Ybd ._10JD0x1r{white-space:nowrap;text-overflow:ellipsis;overflow:hidden}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/player-events-ui/containers/PlayerEvents/components/TeamSelect/components/TeamLabel/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/size.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,kBAAmB,CACnB,eAAgB,CAJpB,sBAOM,iBAAkB,CAClB,gBAAiB,CCTrB,cDW4B,CCV5B,cDU4B,CCP1B,eDO0B,CCN1B,eDM0B,CAV9B,sBAcM,kBAAmB,CACnB,sBAAuB,CACvB,eAAgB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .team-label {\n    display: flex;\n    align-items: center;\n    overflow: hidden;\n\n    .logo {\n      border-radius: 50%;\n      margin-right: 8px;\n\n      @include fixed-size(16px);\n    }\n\n    .label {\n      white-space: nowrap;\n      text-overflow: ellipsis;\n      overflow: hidden;\n    }\n  }\n\n}","@mixin fixed-size($width, $height: null) {\n  min-width: $width;\n  max-width: $width;\n\n  @if ($height == null) {\n    min-height: $width;\n    max-height: $width;\n  } @else {\n    min-height: $height;\n    max-height: $height;\n  }\n}"]}]);

// Exports
exports.locals = {
	"team-label": "_1Gzv-Ybd",
	"teamLabel": "_1Gzv-Ybd",
	"logo": "_3OzBavhx",
	"label": "_10JD0x1r"
};