import React from 'react';
import classnames from 'classnames';

import { Type, Weight } from 'packages/shared/components/Separator/types';

import styles from './styles.scss';

type Props = {
  className?: string;
  type?: Type;
  weight?: Weight;
};

const Separator = ({ className, type = Type.Border, weight = Weight.Thin }: Props) => {
  const containerClasses = classnames(styles.separator, className, styles[type], styles[weight]);

  const Component = () => {
    switch (type) {
      case Type.Score:
        return <div className={containerClasses}>-</div>;
      case Type.Border:
      case Type.Block:
      default:
        return <div className={containerClasses} />;
    }
  };

  return <Component />;
};

export default React.memo(Separator);
