import DateService from 'packages/date-az';
import { Event } from 'types';

import {
  BracketStageData,
  CommonTableStatistics,
  SeasonTableStageData,
  Stage,
  StatisticsGroup,
  TournamentTable,
} from '../../types';
import { isBracketStage, isCommonMatchUp, isTableStage } from '../utils';

const getActiveStageIdByEvent = (stages: Stage[], event: Event) => {
  const dateRelatedStages = stages.filter(
    (stage) =>
      DateService.getDateTimeFromIsoString(stage.startDate) <
      DateService.getDateTimeFromIsoString(event.scheduled),
  );

  if (dateRelatedStages.length) {
    const stage = getStageByEventId(stages, event);

    return stage?.id;
  }
};

const getStageByEventId = (stages: Stage[], event: Event) => {
  for (const stage of stages) {
    let foundEvent;

    if (isTableStage(stage)) {
      foundEvent = findEventInSeasonTableStage(stage, event);
    } else if (isBracketStage(stage)) {
      foundEvent = findEventInBracketStage(stage, event.id);
    }

    if (foundEvent) {
      return stage;
    }
  }
};

const findEventInSeasonTableStage = (stage: Stage<SeasonTableStageData>, event: Event) => {
  for (const tournamentTable of stage.data.tournamentTables) {
    if (isEventInTournamentTableDateRange(event, tournamentTable)) {
      for (const row of tournamentTable.rows) {
        const { home, away } = row.form;
        const totalForm = [...home, ...away];

        const foundEvent = totalForm.find((formEvent) => {
          return formEvent.id === event.id;
        });

        if (foundEvent) {
          return foundEvent;
        }
      }
    }
  }
};

const isEventInTournamentTableDateRange = (
  event: Event,
  tournamentTable: TournamentTable<Record<StatisticsGroup, CommonTableStatistics>>,
) => {
  return (
    DateService.getDateTimeFromIsoString(event.scheduled) >=
      DateService.getDateTimeFromIsoString(tournamentTable.start) &&
    DateService.getDateTimeFromIsoString(event.scheduled) <=
      DateService.getDateTimeFromIsoString(tournamentTable.end)
  );
};

const findEventInBracketStage = (stage: Stage<BracketStageData>, eventId: number) => {
  for (const round of stage.data.rounds) {
    const foundEvent = round.matchups.find((matchUp) => {
      if (isCommonMatchUp(matchUp)) {
        return matchUp.sportEvents.find((matchUpEvent) => matchUpEvent.id === eventId);
      }
    });

    if (foundEvent) {
      return foundEvent;
    }
  }
};

export default getActiveStageIdByEvent;
