import React from 'react';
import classnames from 'classnames';

import { Separator } from 'packages/shared/components';
import {
  ActiveColumnsState,
  RangeFilterState,
  PresetView,
  Range,
  Column,
  ColumnType,
  PresetType,
  StatType,
  RangeFilterColumnsView,
  SearchSelectOption,
  Nationality,
} from 'packages/season-player-statistics/types';
import { SelectItem } from 'packages/shared/components/Select/types';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';
import { ComponentLike, Team } from 'types';

import styles from './styles.scss';
import { ColumnsArrangement, ColumnsConfiguration, CustomizationPresets } from './components';

type Props = {
  configurableColumns: Column[];
  activePresetType: PresetType;
  onPresetChange: (activePreset: PresetView) => void;
  onColumnsOrderChange: (columns: Column[]) => void;
  onRangeGroupUpdate: (type: ColumnType, updates?: Range) => void;
  onColumnToggle: (type: ColumnType) => void;
  rangeFilters: RangeFilterState;
  activeColumns: ActiveColumnsState;
  onActiveStatTypeChange: (statType: StatType) => void;
  onSelectedTeamsChange: (teams: SearchSelectOption<Team>[]) => void;
  onSelectedPositionsChange: (teams: SelectItem[]) => void;
  onSelectedFootChange: (foot: SelectItem) => void;
  onSelectedNationalitiesChange: (nationalities: SearchSelectOption<Nationality>[]) => void;
  onAgeRangeChange: (from: string, to: string) => void;
  activeStatType: StatType;
  statTabs: NavigationMenuItem[];
  teamSelectItems: SearchSelectOption<Team>[];
  selectedTeamItems: SearchSelectOption<Team>[];
  positionSelectItems: SelectItem[];
  selectedPositionItems: SelectItem[];
  footSelectItems: SelectItem[];
  selectedFootItem?: SelectItem;
  nationalitySelectItems: SearchSelectOption<Nationality>[];
  selectedNationalityItems: SearchSelectOption<Nationality>[];
  ageRange: Range;
  columnConfigurationSchema: RangeFilterColumnsView;
  className?: string;
  GeneralFilterComponent: ComponentLike;
};

const FilterArea = ({
  configurableColumns,
  activePresetType,
  onPresetChange,
  onColumnsOrderChange,
  onRangeGroupUpdate,
  onColumnToggle,
  rangeFilters,
  activeColumns,
  activeStatType,
  onActiveStatTypeChange,
  onSelectedTeamsChange,
  onSelectedPositionsChange,
  onSelectedFootChange,
  onSelectedNationalitiesChange,
  onAgeRangeChange,
  statTabs,
  teamSelectItems,
  selectedTeamItems,
  positionSelectItems,
  selectedPositionItems,
  footSelectItems,
  selectedFootItem,
  nationalitySelectItems,
  selectedNationalityItems,
  ageRange,
  columnConfigurationSchema,
  className,
  GeneralFilterComponent,
}: Props) => {
  return (
    <div className={classnames(styles.filterAreaContainer, className)}>
      <GeneralFilterComponent
        onActiveStatTypeChange={onActiveStatTypeChange}
        onSelectedTeamsChange={onSelectedTeamsChange}
        onSelectedPositionsChange={onSelectedPositionsChange}
        onSelectedFootChange={onSelectedFootChange}
        onSelectedNationalitiesChange={onSelectedNationalitiesChange}
        selectedNationalityItems={selectedNationalityItems}
        selectedPositionItems={selectedPositionItems}
        nationalitySelectItems={nationalitySelectItems}
        positionSelectItems={positionSelectItems}
        selectedFootItem={selectedFootItem}
        footSelectItems={footSelectItems}
        activeStatType={activeStatType}
        teamSelectItems={teamSelectItems}
        selectedTeamItems={selectedTeamItems}
        onAgeRangeChange={onAgeRangeChange}
        statTabs={statTabs}
        ageRange={ageRange}
      />
      <Separator className={styles.filterGroupSeparator} />
      <CustomizationPresets
        onPresetChange={onPresetChange}
        activePresetType={activePresetType}
        className={styles.customizationPresetsContainer}
      />
      <ColumnsArrangement
        columns={configurableColumns}
        onColumnPositionChange={onColumnsOrderChange}
        className={styles.columnsArrangementContainer}
      />
      <ColumnsConfiguration
        activeStatType={activeStatType}
        columnConfigurationSchema={columnConfigurationSchema}
        rangeFilterState={rangeFilters}
        activeColumnsState={activeColumns}
        onRangeGroupUpdate={onRangeGroupUpdate}
        onColumnToggle={onColumnToggle}
        columns={configurableColumns}
      />
    </div>
  );
};

export default FilterArea;
