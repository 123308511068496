import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { Tooltip } from 'packages/shared/components';
import { CommonTableParameter } from 'types';

type Props = {
  pathKey: CommonTableParameter;
};

const HeaderCell = ({ pathKey }: Props) => (
  <Tooltip value={i18n.value(`common.tableParameter.extended.${pathKey}`)}>
    <>{i18n.value(`common.tableParameter.short.${pathKey}`)}</>
  </Tooltip>
);

export default memo(HeaderCell);
