import { teamsActions } from '../../actions';
import { teamsSelectors } from '../../selectors';
import loadTeams from './loadTeams';

const loadTeamsPage = (seasonId: number) => async (dispatch, getState) => {
  try {
    dispatch(teamsActions.loadTeamsPageStart());

    const offset = teamsSelectors.getOffset(getState());
    const limit = teamsSelectors.getLimit(getState());

    const teamsData = await dispatch(loadTeams({ seasonId, offset, limit }));

    dispatch(teamsActions.loadTeamsPageSuccess(teamsData));
  } catch (e) {
    dispatch(teamsActions.loadTeamsPageFailure(e));
  }
};

export default loadTeamsPage;
