import { statisticsActions } from '../../../actions';
import { groupWholePlayerStatistics } from '../../../services';
import { Interfaces } from '../../../types';

const loadWholePlayerStatistics = (
  rawStatistics: Interfaces.Statistics.Statistics.Statistics,
) => async (dispatch) => {
  try {
    dispatch(statisticsActions.statistics.loadStatisticsStart());

    if (rawStatistics) {
      const statistics = groupWholePlayerStatistics(rawStatistics);

      dispatch(statisticsActions.statistics.loadStatisticsSuccess(statistics));
    } else {
      throw new Error("player details statistics doesn't exist");
    }
  } catch (e) {
    dispatch(statisticsActions.statistics.loadStatisticsFailure(e));
  }
};

export default loadWholePlayerStatistics;
