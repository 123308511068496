import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadFavoritesStart = createAction(`${NAMESPACE}LOAD_FAVORITES_START`);
export const loadFavoritesSuccess = createAction(`${NAMESPACE}LOAD_FAVORITES_SUCCESS`);
export const loadFavoritesFailure = createAction(`${NAMESPACE}LOAD_FAVORITES_FAILURE`);

export const observeFavoritesStart = createAction(`${NAMESPACE}OBSERVE_FAVORITES_START`);
export const observeFavoritesFailure = createAction(`${NAMESPACE}OBSERVE_FAVORITES_FAILURE`);
export const observeFavoritesSuccess = createAction(`${NAMESPACE}OBSERVE_FAVORITES_SUCCESS`);

export const loadActiveFavoritesFromStorageStart = createAction(
  `${NAMESPACE}LOAD_ACTIVE_FAVORITES_FROM_STORAGE_START`,
);
export const loadActiveFavoritesFromStorageSuccess = createAction(
  `${NAMESPACE}LOAD_ACTIVE_FAVORITES_FROM_STORAGE_START`,
);
export const loadActiveFavoritesFromStorageFailure = createAction(
  `${NAMESPACE}LOAD_ACTIVE_FAVORITES_FROM_STORAGE_FAILURE`,
);

export const loadLatestFavoritesFromStorageStart = createAction(
  `${NAMESPACE}LOAD_LATEST_FAVORITES_FROM_STORAGE_START`,
);
export const loadLatestFavoritesFromStorageSuccess = createAction(
  `${NAMESPACE}LOAD_LATEST_FAVORITES_FROM_STORAGE_SUCCESS`,
);
export const loadLatestFavoritesFromStorageFailure = createAction(
  `${NAMESPACE}LOAD_LATEST_FAVORITES_FROM_STORAGE_FAILURE`,
);

export const putFavoritesStart = createAction(`${NAMESPACE}UPDATE_STORING_FAVORITES_START`);
export const putFavoritesSuccess = createAction(`${NAMESPACE}UPDATE_STORING_FAVORITES_SUCCESS`);
export const putFavoritesFailure = createAction(`${NAMESPACE}UPDATE_STORING_FAVORITES_FAILURE`);

export const updateFavoritesStart = createAction(`${NAMESPACE}UPDATE_STORING_FAVORITES_START`);
export const updateFavoritesSuccess = createAction(`${NAMESPACE}UPDATE_STORING_FAVORITES_SUCCESS`);
export const updateFavoritesFailure = createAction(`${NAMESPACE}UPDATE_STORING_FAVORITES_FAILURE`);

export const loadFavoriteLeaguesStart = createAction(`${NAMESPACE}LOAD_FAVORITE_LEAGUES_START`);
export const loadFavoriteLeaguesSuccess = createAction(`${NAMESPACE}LOAD_FAVORITE_LEAGUES_SUCCESS`);
export const loadFavoriteLeaguesFailure = createAction(`${NAMESPACE}LOAD_FAVORITE_LEAGUES_FAILURE`);

export const updateFavoriteLeaguesStart = createAction(`${NAMESPACE}UPDATE_FAVORITE_LEAGUES_START`);
export const updateFavoriteLeaguesSuccess = createAction(
  `${NAMESPACE}UPDATE_FAVORITE_LEAGUES_SUCCESS`,
);
export const updateFavoriteLeaguesFailure = createAction(
  `${NAMESPACE}UPDATE_FAVORITE_LEAGUES_FAILURE`,
);

export const loadFavoriteTeamsStart = createAction(`${NAMESPACE}LOAD_FAVORITE_TEAMS_START`);
export const loadFavoriteTeamsSuccess = createAction(`${NAMESPACE}LOAD_FAVORITE_TEAMS_SUCCESS`);
export const loadFavoriteTeamsFailure = createAction(`${NAMESPACE}LOAD_FAVORITE_TEAMS_FAILURE`);

export const updateFavoriteTeamsStart = createAction(`${NAMESPACE}UPDATE_FAVORITE_TEAMS_START`);
export const updateFavoriteTeamsSuccess = createAction(`${NAMESPACE}UPDATE_FAVORITE_TEAMS_SUCCESS`);
export const updateFavoriteTeamsFailure = createAction(`${NAMESPACE}UPDATE_FAVORITE_TEAMS_FAILURE`);

export const loadFavoriteEventsStart = createAction(`${NAMESPACE}LOAD_FAVORITE_EVENTS_START`);
export const loadFavoriteEventsSuccess = createAction(`${NAMESPACE}LOAD_FAVORITE_EVENTS_SUCCESS`);
export const loadFavoriteEventsFailure = createAction(`${NAMESPACE}LOAD_FAVORITE_EVENTS_FAILURE`);

export const updateFavoriteEventsStart = createAction(`${NAMESPACE}UPDATE_FAVORITE_EVENTS_START`);
export const updateFavoriteEventsSuccess = createAction(
  `${NAMESPACE}UPDATE_FAVORITE_EVENTS_SUCCESS`,
);
export const updateFavoriteEventsFailure = createAction(
  `${NAMESPACE}UPDATE_FAVORITE_EVENTS_FAILURE`,
);
