import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadTopPlayersSuccess,
  loadInitialTopPlayersSuccess,
  cleanTopPlayersSuccess,
} from '../actions';
import { Data } from '../types';

const defaultState = [];

const reducerMap: ReducerMap<Data, Data> = {
  [`${combineActions(loadTopPlayersSuccess, loadInitialTopPlayersSuccess)}`]: (
    state,
    { payload },
  ) => {
    return payload;
  },
  [`${cleanTopPlayersSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
