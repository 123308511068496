import { FiltersStatus } from '../../types';
import getPath from './getPath';

const getStatus = (state): FiltersStatus => {
  return getPath(state).status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
