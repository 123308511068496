import { Team } from 'types';

import { TeamSelectOption } from '../../../../../types';

const getTeamSelectItem = (team: Team): TeamSelectOption => {
  const { id, name } = team;

  return {
    label: name,
    value: name,
    id,
    componentProps: {
      team,
      isLogoFirst: true,
    },
  };
};

export default getTeamSelectItem;
