import React, { memo } from 'react';
import isEmpty from 'is-empty-typed/lib';

import { Data } from 'packages/player-details/types';
import { ActualLeagues } from 'packages/shared/components';

import { SocialMedia, Passport, DetailsSkeleton } from './components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  playerDetails: Data;
  isLoading: boolean;
};

const Description = ({ playerDetails, isLoading }: Props) => {
  if (isLoading) {
    return (
      <div className={styles.detailsContainer}>
        <DetailsSkeleton />
        <ActualLeagues isLoading={isLoading} />
      </div>
    );
  }

  return (
    <div className={styles.detailsContainer}>
      {!isEmpty(playerDetails) ? (
        <>
          <Passport playerDetails={playerDetails} />
          {!isEmpty(playerDetails.socialMedia) && <SocialMedia links={playerDetails.socialMedia} />}
          <ActualLeagues tournamentList={playerDetails.actualTournamentSeasons} />
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default connect(memo(Description));
