import { useEffect, useMemo } from 'react';

import { createPortalContainer } from '../services';

interface Params {
  overlay: boolean;
}

const usePortalContainer = ({ overlay }: Params) => {
  const portal = useMemo(() => createPortalContainer({ overlay }), []);

  useEffect(() => {
    return () => {
      portal?.remove();
    };
  }, []);

  return portal;
};

export default usePortalContainer;
