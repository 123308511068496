import reducers from './reducers';

import { configure } from './config';

export const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export * from './thunks';
export * from './selectors';
