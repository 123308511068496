export enum StorageEventGroup {
  All = 'ALL',
  Live = 'LIVE',
  Scheduled = 'SCHEDULED',
  Finished = 'FINISHED',
  Favorites = 'FAVORITES',
}

export enum LiveEventsUpdateEventType {
  Updated = 'live-events-updated',
  Inserted = 'live-events-inserted',
  Removed = 'live-events-removed',
}
