import { isEmpty } from 'packages/utils/Object.utils';

import instance from '../../../axios';
import { SeasonEventsParams } from '../types';

export const loadSeasonEvents = ({
  seasonId,
  dates,
  roundIds,
  teamIds,
  limit,
  offset,
}: SeasonEventsParams) => {
  const rangeParams = isEmpty(dates)
    ? ''
    : `&scheduled_after=${dates[0]}&scheduled_before=${dates[1]}`;
  const teamsParam = isEmpty(teamIds) ? '' : `&team=${teamIds.join(',')}`;
  const roundsParam = isEmpty(roundIds) ? '' : `&round=${roundIds.join(',')}`;
  const params = `&limit=${limit}&offset=${offset}${rangeParams}${teamsParam}${roundsParam}`;

  return instance({
    method: 'get',
    url: `/seasons/${seasonId}/sport_events/?${params}`,
  });
};
