import DateService from 'packages/date-az';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const setPrevDate = () => (dispatch, getState) => {
  const state = getState();
  const activeDate = selectors.getActiveDate(state);
  const prevDate = DateService.getPrevDayIso(activeDate);

  dispatch(actions.setPrevDate({ activeDate: prevDate }));
};
