import React from 'react';
import classnames from 'classnames';

import { TeamLabel, MatchHistory } from 'packages/shared/components';
import { SeasonTablePromotion } from 'packages/event-standings/types';
import { PROMOTION_COLOR_GROUPS } from 'appConstants';

import { StatisticTeam } from './types';
import styles from './styles.scss';

type Props = {
  isFormShown?: boolean;
  isHighlighted?: boolean;
  className?: string;
  theme?: string;
  team: StatisticTeam;
  promotion?: SeasonTablePromotion;
  handleMatchClick?: Function;
};

const TeamStatistic = ({
  team,
  className,
  theme = '',
  isHighlighted = false,
  isFormShown = true,
  promotion,
  handleMatchClick,
}: Props) => {
  const {
    position,
    form,
    points,
    matchCount,
    winCount,
    lossCount,
    drawCount,
    goalCount,
    goalsAgainst,
    goalDifference,
  } = team;

  const themeClasses = theme.split(' ');
  const cssClass = promotion?.cssClass;

  const positionClasses = classnames(
    styles.position,
    cssClass && styles[`promotion${PROMOTION_COLOR_GROUPS[cssClass]}`],
  );
  const containerClasses = classnames(
    styles.teamStatistic,
    { [styles.highlighted]: isHighlighted },
    ...themeClasses.map((className) => styles[className]),
    className,
  );

  return (
    <div className={containerClasses}>
      {position && <div className={positionClasses}>{position}</div>}
      <TeamLabel team={team} isLogoFirst className={styles.teamPreview} />
      {isFormShown && (
        <MatchHistory
          onMatchMouseUp={handleMatchClick}
          form={form}
          className={styles.matchHistory}
        />
      )}
      <div className={styles.statistics}>
        <div className={styles.matchCount}>{matchCount}</div>
        <div className={styles.winCount}>{winCount}</div>
        <div className={styles.drawCount}>{drawCount}</div>
        <div className={styles.lossCount}>{lossCount}</div>
        <div className={styles.goalCount}>
          {goalCount}:{goalsAgainst}
        </div>
        <div className={styles.goalDifference}>{goalDifference}</div>
        <div className={styles.points}>{points}</div>
      </div>
    </div>
  );
};

export default React.memo(TeamStatistic);
