import React, { memo, RefObject, useEffect } from 'react';

import styles from './styles.scss';

type Props = {
  targetRef: RefObject<HTMLElement> | null;
};

const Backdrop = ({ targetRef }: Props) => {
  // useEffect(() => {
  //   if (targetRef?.current) {
  //     targetRef.current.style.overflow = 'hidden';
  //   }
  //
  //   return () => {
  //     if (targetRef?.current) {
  //       targetRef.current.style.overflow = 'initial';
  //     }
  //   };
  // }, []);

  return <div className={styles.backdrop} />;
};

export default memo(Backdrop);
