import getPath from '../getPath';
import { CompactTopPlayersStatus } from '../../types';

const getStatus = (state): CompactTopPlayersStatus => {
  return getPath(state).compactPlayers.status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
