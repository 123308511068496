export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Default = 'default',
  Expand = 'expand',
}

export enum ButtonSize {
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
}
