import React, { memo } from 'react';

import styles from './styles.scss';

type Props = {
  value: string;
};

const PopoverContent = ({ value }: Props) => <div className={styles.popoverContent}>{value}</div>;

export default memo(PopoverContent);
