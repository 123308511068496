import { BaseHappening, MissedPenaltyHappening } from '../../types';

const mapMissedPenaltyHappening = (happening: BaseHappening): MissedPenaltyHappening => {
  const { id, team, eventType, minute, players, injuryTime } = happening;

  const [player] = players || [];
  const scorerName = player?.name || player?.shortName;

  return {
    id,
    team,
    players,
    eventType,
    minute,
    scorerName,
    injuryTime,
  };
};

export default mapMissedPenaltyHappening;
