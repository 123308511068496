import { EventLineups } from 'packages/rest';
import { eventTimeLineThunks, eventTimeLineSelectors } from 'packages/event-time-line';
import { BaseHappening } from 'packages/event-time-line/types';

import {
  mapCompetitors,
  groupHappeningsByPlayerId,
  mapAssistHappenings,
  sortHappeningsByTime,
} from '../services';
import * as actions from '../actions';

export const loadEventLineups = ({ eventId }) => async (dispatch, getState) => {
  try {
    dispatch(actions.loadEventLineupsStart());

    await dispatch(eventTimeLineThunks.loadEventTimeLine({ eventId }));

    const timeLineHappenings: BaseHappening[] = eventTimeLineSelectors.getData(getState());
    const assistsHappenings = mapAssistHappenings(timeLineHappenings);
    const happenings = timeLineHappenings.concat(assistsHappenings);
    const sortedHappenings = sortHappeningsByTime(happenings);
    const happeningsByPlayerId = groupHappeningsByPlayerId(sortedHappenings);

    const { data } = await EventLineups.loadEventLineups({ eventId });
    const eventLineups = mapCompetitors(data.results, happeningsByPlayerId);

    dispatch(actions.loadEventLineupsSuccess({ eventLineups }));
  } catch (error) {
    dispatch(actions.loadEventLineupsFailure());
  }
};
