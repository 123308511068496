exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2EHbfi_g{display:flex;flex-direction:column;height:510px;overflow:hidden}._2EHbfi_g ._3H3dj0oa{display:flex;justify-content:space-between;padding:16px}._2EHbfi_g ._pqzT2Vig ._1bErhhOc>*{align-items:center;height:34px}._2EHbfi_g ._pqzT2Vig ._-s6JxHYY{height:41px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/season-standings-ui/containers/SeasonCompactStandings/views/Desktop/components/LoadingSkeleton/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,qBAAsB,CACtB,YAAa,CACb,eAAgB,CALpB,sBAQM,YAAa,CACb,6BAA8B,CAC9B,YCZQ,CDEd,mCAgBU,kBAAmB,CACnB,WAAY,CAjBtB,iCAsBQ,WAAY","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .container {\n    display: flex;\n    flex-direction: column;\n    height: 510px;\n    overflow: hidden;\n\n    .header {\n      display: flex;\n      justify-content: space-between;\n      padding: $padding;\n    }\n\n    .table {\n      .table-header {\n        & > * {\n          align-items: center;\n          height: 34px;\n        }\n      }\n\n      .table-row {\n        height: 41px;\n      }\n    }\n  }\n}","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"container": "_2EHbfi_g",
	"header": "_3H3dj0oa",
	"table": "_pqzT2Vig",
	"table-header": "_1bErhhOc",
	"tableHeader": "_1bErhhOc",
	"table-row": "_-s6JxHYY",
	"tableRow": "_-s6JxHYY"
};