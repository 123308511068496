import { BracketGrid } from 'packages/shared/components';

const getBracketProps = () => ({
  contentComponent: BracketGrid,
  getProps: (props) => ({
    ...props,
    columnAmount: 2,
  }),
});

export default getBracketProps;
