import React, { memo } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import classnames from 'classnames';

import styles from './styles.scss';

const DEFAULT_ZOOM = 16;

type Props = {
  apiKey?: string;
  markers?: google.maps.LatLngLiteral[];
  center?: google.maps.LatLngLiteral;
  zoom?: number;
  className?: string;
};

const LocationMap = ({
  apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  markers = [],
  center = markers[0],
  zoom = DEFAULT_ZOOM,
  className,
}: Props) => {
  return (
    <LoadScript googleMapsApiKey={apiKey}>
      <GoogleMap
        mapContainerClassName={classnames(styles.locationContainer, className)}
        center={center}
        zoom={zoom}
      >
        {markers.map(({ lat, lng }) => (
          <Marker key={`${lat}-${lng}`} position={{ lat, lng }} />
        ))}
      </GoogleMap>
    </LoadScript>
  );
};

export default memo(LocationMap);
