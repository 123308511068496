import instance from 'packages/rest/axios';

import { TournamentSeasonsParams } from '../types';

export const loadTournamentSeasons = ({ playerId, teamId }: TournamentSeasonsParams) => {
  return instance({
    method: 'get',
    url: `/players/${playerId}/teams/${teamId}/uq/`,
  });
};
