import { CommonTableParameter } from 'types';

export enum PlayersParameter {
  GamesPlayed = CommonTableParameter.GamesPlayed,
  Goals = CommonTableParameter.Goals,
  Assists = CommonTableParameter.Assists,
  GoalPoints = CommonTableParameter.GoalPoints,
  TotalShots = CommonTableParameter.TotalShots,
  ShotsOnGoal = CommonTableParameter.ShotsOnGoal,
  Offside = CommonTableParameter.Offside,
  YellowCards = CommonTableParameter.YellowCards,
  YellowRedCards = CommonTableParameter.YellowRedCards,
  RedCards = CommonTableParameter.RedCards,
}
