import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { Tournament } from 'types';
import { Separator } from 'packages/shared/components';

import { Tournaments } from '../../components';
import { ListSkeleton } from '../../../../components';
import styles from './styles.scss';

type Props = {
  tournaments: Tournament[];
  favoriteLeagues: Tournament[];
  isPopularTournamentsLoading: boolean;
  isCategoriesLoading: boolean;
  getFavoriteTournamentToggleHandler: (tournament: Tournament) => () => void;
};

const PopularTournaments = ({
  tournaments,
  favoriteLeagues,
  isPopularTournamentsLoading,
  getFavoriteTournamentToggleHandler,
  isCategoriesLoading,
}: Props) => {
  if (isCategoriesLoading || isPopularTournamentsLoading) {
    return <ListSkeleton useMobileView elementsCount={6} />;
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{i18n.value('leftBar.popularTournaments.title')}</h3>
      <Separator />

      <Tournaments
        loaderContainerClassName={styles.loaderContainer}
        onFavoriteTournamentToggle={getFavoriteTournamentToggleHandler}
        tournaments={tournaments}
        favoriteLeagues={favoriteLeagues}
        isPopularTournamentsLoading={isPopularTournamentsLoading}
      />
    </div>
  );
};

export default memo(PopularTournaments);
