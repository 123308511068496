import * as matchStatisticsActions from './actions';
import * as matchStatisticsThunks from './thunks';
import * as matchStatisticsConstants from './constants';
import * as matchStatisticsSelectors from './selectors';
import * as matchStatisticsServices from './services';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  matchStatisticsActions,
  matchStatisticsThunks,
  matchStatisticsConstants,
  matchStatisticsSelectors,
  matchStatisticsServices,
  createReducer,
};
