import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const PlayerCountry = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton width={70} height={12} marginBottom={6} variant="rectangular" />

      <div className={styles.countryWrapper}>
        <Skeleton height={14} width={16} marginRight={10} variant="rectangular" />
        <Skeleton width={70} height={12} variant="rectangular" />
      </div>
    </div>
  );
};

export default memo(PlayerCountry);
