import React from 'react';

import { BaseHappening, PeriodHappening } from 'packages/event-time-line/types';
import { PenaltyHappening } from 'packages/event-summary/types';

import { Theme } from './types';
import { HappeningRow } from './components';

type Props = {
  happenings: (BaseHappening | PenaltyHappening)[] | BaseHappening[];
  useDefaultHappeningComponent?: boolean;
  periodHappening?: PeriodHappening;
  hasSinglePosition?: boolean;
  isAwayPositioned?: boolean;
  className?: string;
  theme?: Theme;
};

const HappeningTimeLine = ({
  happenings,
  useDefaultHappeningComponent,
  periodHappening,
  hasSinglePosition,
  isAwayPositioned,
  className,
  theme,
}: Props) => {
  return (
    <div className={className}>
      {hasSinglePosition ? (
        <>
          {(happenings as BaseHappening[]).map((happening) => (
            <HappeningRow.SinglePositionRow
              key={happening.id}
              happening={happening}
              isAwayHappening={isAwayPositioned}
              useDefaultHappeningComponent={useDefaultHappeningComponent}
              theme={theme}
            />
          ))}
        </>
      ) : (
        <>
          {(happenings as (BaseHappening | PenaltyHappening)[]).map((happening) => (
            <HappeningRow.BothPositionsRow
              key={happening.id}
              happening={happening}
              periodHappening={periodHappening}
              theme={theme}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default React.memo(HappeningTimeLine);
