import { Ordering as RequestOrderingParam } from 'packages/rest/api/SeasonTeamStatistics/types';

import { FILTER_ORDERING_BY_STAT_TYPE } from '../constants';
import { Ordering, StatType } from '../types';

const serializeOrdering = (ordering: Ordering, statsType: StatType): RequestOrderingParam => {
  return Object.entries(ordering).reduce(
    (acc, [orderingKey, orderingValue]) => ({
      ...acc,
      [FILTER_ORDERING_BY_STAT_TYPE[statsType][orderingKey]]: orderingValue,
    }),
    {},
  );
};

export default serializeOrdering;
