import React, { memo, useMemo } from 'react';
import { Column } from 'react-table';

import { FlexibleTable } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';

const ELEMENTS_COUNT = 3;

type Props = {
  className: string;
  getColumnSchema: () => Column[];
};

const TableSkeleton = ({ className, getColumnSchema }: Props) => {
  const { data, columns } = useMemo(() => {
    const data = ArrayUtils.getArrayFromNumber(ELEMENTS_COUNT);
    const columns = getColumnSchema();

    return { data, columns };
  }, [getColumnSchema]);

  return <FlexibleTable className={className} columns={columns} data={data} />;
};
export default memo(TableSkeleton);
