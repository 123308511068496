import React from 'react';
import i18n from 'i18n-smart';

import { CommonTableParameter, Team } from 'types';
import {
  Tooltip,
  TournamentTableGoalsCell,
  TournamentTablePositionCell,
  TournamentTableTeamCell,
} from 'packages/shared/components';
import { StatisticsGroup } from 'packages/event-standings/types';

import styles from '../styles.scss';

type Params = {
  groupName: string;
};

const getColumnSchema = ({ groupName }: Params) => [
  {
    children: [
      {
        title: groupName,
        dataIndex: ['statistics', StatisticsGroup.Overall, 'position'],
        render: TournamentTablePositionCell,
        colSpan: 2,
        width: '3%',
      },
    ],
  },
  {
    dataIndex: 'team',
    colSpan: 0,
    render: (team: Team) => React.createElement(TournamentTableTeamCell, { team }),
  },
  {
    title: <div className={styles.value}>{i18n.value('season.seasonStandings.overallTitle')}</div>,
    children: getStatisticsColumnsByGroup(StatisticsGroup.Overall, { isSeparatorNeeded: true }),
    className: styles.columnGroupHeaderCell,
  },
  {
    title: <div className={styles.value}>{i18n.value('season.seasonStandings.homeTitle')}</div>,
    children: getStatisticsColumnsByGroup(StatisticsGroup.Home, { isSeparatorNeeded: true }),
    className: styles.columnGroupHeaderCell,
  },
  {
    title: <div className={styles.value}>{i18n.value('season.seasonStandings.awayTitle')}</div>,
    children: getStatisticsColumnsByGroup(StatisticsGroup.Away),
    className: styles.columnGroupHeaderCell,
  },
];

const getStatisticsColumnsByGroup = (
  statisticsGroup: StatisticsGroup,
  { isSeparatorNeeded } = { isSeparatorNeeded: false },
) => {
  return [
    {
      title: (
        <Tooltip
          value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.GamesPlayed}`)}
        >
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.GamesPlayed}`)}</>
        </Tooltip>
      ),
      dataIndex: ['statistics', statisticsGroup, 'gamesPlayed'],
      width: '4%',
    },
    {
      title: (
        <Tooltip value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.Win}`)}>
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.Win}`)}</>
        </Tooltip>
      ),
      dataIndex: ['statistics', statisticsGroup, 'win'],
      width: '4%',
    },
    {
      title: (
        <Tooltip value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.Draw}`)}>
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.Draw}`)}</>
        </Tooltip>
      ),
      dataIndex: ['statistics', statisticsGroup, 'draw'],
      width: '4%',
    },
    {
      title: (
        <Tooltip value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.Loss}`)}>
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.Loss}`)}</>
        </Tooltip>
      ),
      dataIndex: ['statistics', statisticsGroup, 'loss'],
      width: '4%',
    },
    {
      title: (
        <Tooltip value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.Goals}`)}>
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.Goals}`)}</>
        </Tooltip>
      ),
      dataIndex: ['statistics', statisticsGroup],
      render: TournamentTableGoalsCell,
      width: '5%',
    },
    {
      title: (
        <Tooltip
          value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.Points}`)}
        >
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.Points}`)}</>
        </Tooltip>
      ),
      dataIndex: ['statistics', statisticsGroup, 'points'],
      className: isSeparatorNeeded ? styles.columnSeparator : undefined,
      width: '4%',
    },
  ];
};

export default getColumnSchema;
