import React, { RefObject, memo } from 'react';
import isEmpty from 'is-empty-typed';
import i18n from 'i18n-smart';

import { Event } from 'types';
import { Event as EventComponent } from 'packages/events-ui';
import { LoadingSkeleton } from './components';

import styles from './styles.scss';

type Props = {
  events: Event[];
  isLoading: boolean;
  onEventClick: (eventId: number) => void;
  containerRef: RefObject<HTMLDivElement>;
};

const SKELETON_EVENT_COUNT = 10;

const EventList = ({ events, isLoading, onEventClick, containerRef }: Props) => {
  if (isLoading) {
    return <LoadingSkeleton eventCount={SKELETON_EVENT_COUNT} />;
  }

  if (isEmpty(events)) {
    return <div className={styles.notFound}>{i18n.value('season.seasonEvents.notFound')}</div>;
  }

  return (
    <>
      {events.map((event) => (
        <EventComponent.Mobile
          containerRef={containerRef}
          isRoundVisible
          isDateVisible
          onClick={onEventClick}
          key={event.id}
          event={event}
        />
      ))}
    </>
  );
};

export default memo(EventList);
