import React, { useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { debounce } from 'packages/utils/Function.utils';
import { Layout } from 'modules/shared/layouts';
import { MediaScreen } from 'types';

import connect from './connect';
import {
  schemaDesktop,
  schemaMobile,
  schemaTabletNarrow,
  schemaTablet,
  schemaDesktopNarrow,
} from './schema';

type Props = {
  appThunks: ActionCreatorsMapObject;
  mediaScreen: MediaScreen;
};

const AppRoot = ({ appThunks, mediaScreen }: Props) => {
  useEffect(() => {
    const debouncedUpdateMediaScreen = debounce(updateMediaScreen, 100);
    window.addEventListener('resize', debouncedUpdateMediaScreen);

    return () => window.removeEventListener('resize', debouncedUpdateMediaScreen);
  }, []);

  const updateMediaScreen = () => {
    appThunks.setMediaInfo();
  };

  const getLayout = () => {
    switch (mediaScreen) {
      case MediaScreen.Mobile:
        return <Layout.Mobile>{schemaMobile()}</Layout.Mobile>;
      case MediaScreen.TabletNarrow:
        return <Layout.Mobile>{schemaTabletNarrow()}</Layout.Mobile>;
      case MediaScreen.Tablet:
        return <Layout.Mobile>{schemaTablet()}</Layout.Mobile>;
      case MediaScreen.DesktopNarrow:
        return <Layout.Base>{schemaDesktopNarrow()}</Layout.Base>;
      default:
        return <Layout.Base>{schemaDesktop()}</Layout.Base>;
    }
  };

  return getLayout();
};

export default connect(AppRoot);
