import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadActiveTournamentSeasonsFromSessionStorageSuccess = createAction(
  `${NAMESPACE}LOAD_ACTIVE_TOURNAMENT_SEASONS_FROM_SESSION_STORAGE_SUCCESS`,
);
export const updateTeamActiveTournamentSeasonSuccess = createAction(
  `${NAMESPACE}UPDATE_TEAM_ACTIVE_TOURNAMENT_SEASON_SUCCESS`,
);
