import { createElement, ReactElement, useEffect, useState } from 'react';

import { StageType } from 'packages/event-standings/types';

import { StageFilterTab, StageTab } from '../types';

const useActiveTabContentElement = <S extends StageTab, F extends StageFilterTab>(
  activeStageTab?: S,
  activeFilterTab?: F,
) => {
  const [activeContentElement, setActiveContentElement] = useState<ReactElement | undefined>();

  const setActiveContentElementByTabType = (isFilterTab: boolean) => {
    if (activeStageTab) {
      let filteredStageTab = activeStageTab;

      if (isFilterTab && !!activeFilterTab) {
        const overriddenProps = activeFilterTab?.overriddenProps || {};

        filteredStageTab = { ...filteredStageTab, ...overriddenProps };
      }
      setActiveContentElement(
        createElement(
          filteredStageTab.contentComponent,
          filteredStageTab.getProps(filteredStageTab.props),
        ),
      );
    } else {
      setActiveContentElement(undefined);
    }
  };

  const changeActiveContentElement = () => {
    if (activeStageTab?.type === StageType.Tables) {
      setActiveContentElementByTabType(true);
    } else {
      setActiveContentElementByTabType(false);
    }
  };

  useEffect(() => {
    changeActiveContentElement();
  }, [activeStageTab, activeFilterTab]);

  return activeContentElement;
};

export default useActiveTabContentElement;
