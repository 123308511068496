exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._d09wSsSe{display:flex;align-items:center;justify-content:flex-end}._2u7gwZtJ{min-width:16px;max-width:16px;min-height:16px;max-height:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/events-ui/containers/Event/components/MinutesPlayed/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/size.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,kBAAmB,CACnB,wBAAyB,CAJ7B,WCDE,cDS0B,CCR1B,cDQ0B,CCLxB,eDKwB,CCJxB,eDIwB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .icon-wrapper {\n    display: flex;\n    align-items: center;\n    justify-content: flex-end;\n  }\n\n  .bench-icon {\n    @include fixed-size(16px)\n  }\n}","@mixin fixed-size($width, $height: null) {\n  min-width: $width;\n  max-width: $width;\n\n  @if ($height == null) {\n    min-height: $width;\n    max-height: $width;\n  } @else {\n    min-height: $height;\n    max-height: $height;\n  }\n}"]}]);

// Exports
exports.locals = {
	"icon-wrapper": "_d09wSsSe",
	"iconWrapper": "_d09wSsSe",
	"bench-icon": "_2u7gwZtJ",
	"benchIcon": "_2u7gwZtJ"
};