exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1OlLBtZu{height:100%}._XfB1_PYj{padding:0 16px 16px}._XfB1_PYj ._3mpjRaqI,._XfB1_PYj ._i0FOlkO5>*:not(:last-of-type){margin-bottom:24px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/event-stats-ui/containers/EventStats/views/Desktop/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,WAAY,CAFhB,WAMI,mBCRU,CDEd,iEAUM,kBAAmB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .loader {\n    height: 100%;\n  }\n\n  .event-stats {\n    padding: 0 $padding $padding;\n\n    .timelines-container,\n    .stats > *:not(:last-of-type) {\n      margin-bottom: 24px;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"loader": "_1OlLBtZu",
	"event-stats": "_XfB1_PYj",
	"eventStats": "_XfB1_PYj",
	"timelines-container": "_3mpjRaqI",
	"timelinesContainer": "_3mpjRaqI",
	"stats": "_i0FOlkO5"
};