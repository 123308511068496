import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { mainCategoryActions } from '../../actions';
import { Status } from '../../types';

const defaultState: Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${mainCategoryActions.loadCategoryStart}`]: (state) => {
    return { ...state, isLoading: true };
  },
  [`${combineActions(
    mainCategoryActions.loadCategoryFailure,
    mainCategoryActions.loadCategorySuccess,
  )}`]: (state) => {
    return { ...state, isLoading: false };
  },
};

export default handleActions(reducerMap, defaultState);
