import React, { memo } from 'react';
import classnames from 'classnames';

import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';

import { Figure } from 'packages/shared/components';
import styles from './styles.scss';

type Props = {
  tournaments: SeasonsExtendedTournament[];
  onTournamentClick?: (tournament: SeasonsExtendedTournament) => void;
  className?: string;
};

const TournamentChipList = ({ tournaments, onTournamentClick, className }: Props) => {
  const getTournamentClickHandler = (tournament) => () => {
    onTournamentClick?.(tournament);
  };

  return (
    <>
      {tournaments.map((tournament) => (
        <div
          onClick={getTournamentClickHandler(tournament)}
          className={classnames(styles.tournamentChip, className)}
          key={tournament.id}
        >
          <Figure src={tournament.logo} className={styles.logo} />
          <div className={styles.title}>{tournament.name}</div>
        </div>
      ))}
    </>
  );
};

export default memo(TournamentChipList);
