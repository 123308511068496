import { Event } from 'packages/events/types';

import { StorageEventGroup } from '../../types';
import { commonSelectors } from '../../selectors';
import { EVENT_STORAGE_GROUP_KEYS } from '../../constants';

export const getFinishedEvents = (state): Event[] => {
  return commonSelectors.getEventsByGroup(
    state,
    EVENT_STORAGE_GROUP_KEYS[StorageEventGroup.Finished],
  ).data;
};

export const getEventFromFinishedById = (state, id) => {
  return getFinishedEvents(state).find((event) => event.id === id);
};
