import React, { memo, useMemo } from 'react';
import i18n from 'i18n-smart';

import { Interfaces } from 'packages/team-summary/types';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { Tabs, StatisticsBlock } from 'packages/shared/components';

import { mapTeamStatisticsIntoTabView } from './services';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  teamStatistics: Interfaces.Details.Statistics.Data;
  useSingleColumn?: boolean;
};

const Statistics = ({ teamStatistics, useSingleColumn }: Props) => {
  const tabs = useMemo(() => mapTeamStatisticsIntoTabView(teamStatistics, useSingleColumn), [
    teamStatistics,
    useSingleColumn,
  ]);

  return (
    <Tabs
      isPaddingDisabled
      navigationClassName={styles.navigation}
      tabs={tabs}
      className={styles.tabsContainer}
      contentComponent={StatisticsBlock}
      theme={NavigationTheme.Button}
      navigationLabel={i18n.value('team.teamSummary.statistics.title')}
    />
  );
};

export default connect(memo(Statistics));
