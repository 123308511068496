import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { Tournament } from 'types';
import { Separator } from 'packages/shared/components';

import { DraggableWrapper } from '../../../../components';
import { TournamentDraggableItem } from '../../components';
import { SortableItem } from '../../../../types';
import styles from './styles.scss';

type Props = {
  tournaments: Tournament[];
  onClick: (tournament: SortableItem) => void;
  onDelete: (tournament: SortableItem) => void;
  onDragEnd: (tournaments: SortableItem[]) => void;
};

const FavoriteTournaments = ({ tournaments, onClick, onDelete, onDragEnd }: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{i18n.value('leftBar.favoriteTournaments.title')}</div>
      <Separator />

      <DraggableWrapper
        onClick={onClick}
        onDelete={onDelete}
        onDragEnd={onDragEnd}
        emptyMessage={i18n.value('leftBar.favoriteTournaments.noItems')}
        itemComponent={TournamentDraggableItem}
        items={tournaments}
      />
    </div>
  );
};

export default memo(FavoriteTournaments);
