exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2z9OdwIt{display:flex;flex-flow:column nowrap;padding:10px 16px}._2dEEemjJ{width:100%;display:grid;grid-row-gap:8px;grid-template-columns:repeat(auto-fill, minmax(210px, 1fr));padding:10px 0}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/modules/NarrowTeamStatisticsTable/components/TournamentRulesBlockSkeleton/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,uBAAwB,CACxB,iBCNU,CDEd,WAQI,UAAW,CACX,YAAa,CACb,gBAAiB,CACjB,2DAA4D,CAC5D,cAAe","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .rules-container {\n    display: flex;\n    flex-flow: column nowrap;\n    padding: 10px $padding;\n  }\n\n  .tournaments-wrapper {\n    width: 100%;\n    display: grid;\n    grid-row-gap: 8px;\n    grid-template-columns: repeat(auto-fill, minmax(210px, 1fr));\n    padding: 10px 0;\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"rules-container": "_2z9OdwIt",
	"rulesContainer": "_2z9OdwIt",
	"tournaments-wrapper": "_2dEEemjJ",
	"tournamentsWrapper": "_2dEEemjJ"
};