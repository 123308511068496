import { connect } from 'react-redux';

import { configSelectors } from 'packages/config';

const mapStateToProps = (state) => {
  return {
    sports: configSelectors.getAvailableSports(state),
  };
};

export default connect(mapStateToProps);
