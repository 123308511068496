import { CommonTableStatistics, SeasonTablePromotion, TableStatistics } from '../../../types';

export const selectCommonOverallStatistics = (
  statistics: TableStatistics,
  promotion: SeasonTablePromotion,
): CommonTableStatistics => {
  const {
    total,
    changeTotal,
    goalDifferenceTotal,
    goalsAgainstTotal,
    goalsForTotal,
    winTotal,
    drawTotal,
    lossTotal,
    pointsTotal,
    sortPositionTotal,
  } = statistics;

  return {
    points: pointsTotal,
    gamesPlayed: total,
    goalDifference: goalDifferenceTotal,
    goalsAgainst: goalsAgainstTotal,
    goalsFor: goalsForTotal,
    change: changeTotal,
    win: winTotal,
    draw: drawTotal,
    loss: lossTotal,
    sortPosition: sortPositionTotal,
    position: {
      value: sortPositionTotal,
      cssClass: promotion?.cssClass,
    },
  };
};

export const selectCommonHomeStatistics = (
  statistics: TableStatistics,
  promotion: SeasonTablePromotion,
): CommonTableStatistics => {
  const {
    changeHome,
    home,
    goalDifferenceHome,
    goalsAgainstHome,
    goalsForHome,
    winHome,
    drawHome,
    lossHome,
    pointsHome,
    sortPositionHome,
  } = statistics;

  return {
    points: pointsHome,
    gamesPlayed: home,
    goalDifference: goalDifferenceHome,
    goalsAgainst: goalsAgainstHome,
    goalsFor: goalsForHome,
    change: changeHome,
    win: winHome,
    draw: drawHome,
    loss: lossHome,
    sortPosition: sortPositionHome,
    position: {
      value: sortPositionHome,
      cssClass: promotion?.cssClass,
    },
  };
};

export const selectCommonAwayStatistics = (
  statistics: TableStatistics,
  promotion: SeasonTablePromotion,
): CommonTableStatistics => {
  const {
    away,
    changeAway,
    goalDifferenceAway,
    goalsAgainstAway,
    goalsForAway,
    winAway,
    drawAway,
    lossAway,
    pointsAway,
    sortPositionAway,
  } = statistics;

  return {
    points: pointsAway,
    gamesPlayed: away,
    goalDifference: goalDifferenceAway,
    goalsAgainst: goalsAgainstAway,
    goalsFor: goalsForAway,
    change: changeAway,
    win: winAway,
    draw: drawAway,
    loss: lossAway,
    sortPosition: sortPositionAway,
    position: {
      value: sortPositionAway,
      cssClass: promotion?.cssClass,
    },
  };
};
