import { connect } from 'react-redux';

import { seasonHistorySelectors } from 'packages/season-history';

const mapStateToProps = (state) => {
  return {
    champions: seasonHistorySelectors.getChampions(state),
    season: seasonHistorySelectors.getFirstSeason(state),
    isLoading: seasonHistorySelectors.getIsLoading(state),
  };
};

export default connect(mapStateToProps);
