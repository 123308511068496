import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { eventDetailsThunks, eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => {
  return {
    event: eventDetailsSelectors.getActiveEvent(state),
    eventSeries: eventDetailsSelectors.getEventSeries(state),
    availableTabs: eventDetailsSelectors.getAvailableTabs(state),
    isLoading: eventDetailsSelectors.getIsEventDetailsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    eventDetailsActions: bindActionCreators(eventDetailsThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
