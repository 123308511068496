import React from 'react';
import classnames from 'classnames';

import { Figure, Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import { Size, Theme, CommonItemType } from './types';
import styles from './styles.scss';

type Props<ItemType> = {
  item: ItemType;
  isLogoFirst?: boolean;
  isNameShown?: boolean;
  className?: string;
  theme?: Theme;
  size?: Size;
};

const ItemLabel = <ItemType extends CommonItemType>({
  item,
  isLogoFirst = false,
  isNameShown = true,
  theme = Theme.Dark,
  size = Size.Md,
  className,
}: Props<ItemType>) => {
  return (
    <div
      className={classnames(
        styles.itemLabel,
        styles[size],
        styles[theme],
        { [styles.logoFirst]: isLogoFirst },
        className,
      )}
    >
      {isNameShown && <div className={styles.name}>{item.name}</div>}
      {item.logo ? (
        <Figure src={item.logo} className={classnames(styles.logo, styles[size], styles[theme])} />
      ) : (
        <div className={classnames(styles.logo, styles[size], styles[theme])}>
          <Icon id={IconType.NotFound} />
        </div>
      )}
    </div>
  );
};

export default React.memo(ItemLabel);
