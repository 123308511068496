import { TeamEvents } from 'packages/rest';

import { mapTournaments } from '../../services';
import { tournamentsFiltersActions } from '../../actions';

const loadTournamentFilter = (teamId: number) => async (dispatch) => {
  try {
    dispatch(tournamentsFiltersActions.loadTournamentsFiltersStart());

    const { data } = await TeamEvents.loadTeamFilters({ teamId });

    const tournaments = mapTournaments(data.results);

    dispatch(tournamentsFiltersActions.loadTournamentsFiltersSuccess({ tournaments }));
  } catch (error) {
    dispatch(tournamentsFiltersActions.loadTournamentsFiltersFailure(error));
  }
};

export default loadTournamentFilter;
