exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._-8l-QZeZ{overflow-y:scroll;scrollbar-width:none;-ms-overflow-style:none}._-8l-QZeZ::-webkit-scrollbar{display:none;background:transparent;width:0;height:0}._-8l-QZeZ ._14Kyzl79:first-of-type{border-top:unset}._-8l-QZeZ ._3v0QkJHa{z-index:4}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/season-team-statistics-ui/containers/TeamStatistics/components/TableSkeleton/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/scroll.mixin.scss"],"names":[],"mappings":"AAEA,WCAI,iBAAkB,CAKpB,oBAAqB,CACrB,uBAAwB,CDN1B,8BCUI,YAAa,CACb,sBAAuB,CACvB,OAAQ,CACR,QAAS,CDbb,oCAMQ,gBAAiB,CANzB,sBAWM,SAAU","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .table {\n    @include hidden-scroll;\n\n    .table-row {\n      &:first-of-type {\n        border-top: unset;\n      }\n    }\n\n    .header {\n      z-index: 4;\n    }\n  }\n}","@mixin hidden-scroll($axis: y) {\n  @if $axis == y {\n    overflow-y: scroll;\n  } @else {\n    overflow-x: scroll;\n  }\n\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* Internet Explorer 10+ */\n\n  &::-webkit-scrollbar {\n    /* WebKit */\n    display: none;\n    background: transparent;\n    width: 0;\n    height: 0;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"table": "_-8l-QZeZ",
	"table-row": "_14Kyzl79",
	"tableRow": "_14Kyzl79",
	"header": "_3v0QkJHa"
};