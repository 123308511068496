import React, { memo, ReactElement } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { NavigationMenu } from 'packages/shared/components';
import {
  NavigationMenuItem,
  NavigationTheme,
} from 'packages/shared/components/NavigationMenu/types';

import { Label } from '../../components';
import styles from './styles.scss';

type Props = {
  name: string;
  onChange: (id: string | number) => void;
  items: NavigationMenuItem[];
  activeId: number | string;
  label?: string | ReactElement;
  className?: string;
  isWidthLimited?: boolean;
};

const Tabs = ({ activeId, items, onChange, label, name, className, isWidthLimited }: Props) => {
  return (
    <div className={classnames(styles.tabsInputContainer, className)}>
      {!isEmpty(label) && <Label name={name} label={label} />}
      <NavigationMenu
        items={items}
        onClick={onChange}
        activeId={activeId}
        theme={NavigationTheme.Button}
        className={styles.tabs}
        isWidthLimited={isWidthLimited}
      />
    </div>
  );
};

export default memo(Tabs);
