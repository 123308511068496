import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Statistics = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton minWidth={30} width={30} height={14} variant="rectangular" marginRight={16} />

      <Skeleton
        minWidth={16}
        minHeight={16}
        width={16}
        height={16}
        variant="circular"
        marginLeft={5}
      />
      <Skeleton
        minWidth={16}
        minHeight={16}
        width={16}
        height={16}
        variant="circular"
        marginLeft={5}
      />
      <Skeleton
        minWidth={16}
        minHeight={16}
        width={16}
        height={16}
        variant="circular"
        marginLeft={5}
      />
      <Skeleton
        minWidth={16}
        minHeight={16}
        width={16}
        height={16}
        variant="circular"
        marginLeft={5}
      />
    </div>
  );
};

export default memo(Statistics);
