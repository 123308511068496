import { EventTeam } from 'types';

import { toCamelCase } from 'packages/utils/Object.utils';
import { TEAM_DEFAULT_LOGO } from 'appConstants';

import { HOME_QUALIFIER } from '../../constants';
import { getTeamScore, getCardsInfo } from '../getters';

export const mapEventTeams = (competitors: any[], status) => {
  return competitors
    .map((team = {}) => {
      const {
        id,
        name,
        fullName,
        logo,
        qualifier,
        team: competitor,
        homeJersey,
        country,
      } = toCamelCase(team, true);

      return {
        id: id || competitor?.id,
        name: name || competitor?.name,
        logo: logo || competitor?.logo || TEAM_DEFAULT_LOGO,
        isWinner: status.winner === (id || competitor?.id),
        isHomeTeam: qualifier === HOME_QUALIFIER,
        score: getTeamScore(qualifier, status),
        cards: getCardsInfo(qualifier, status),
        homeJersey,
        fullName,
        country,
      };
    })
    .sort((a: any, b: any) => b.isHomeTeam - a.isHomeTeam) as [EventTeam, EventTeam];
};
