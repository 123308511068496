import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  topOfTheSeasonSelectors,
  seasonTopBoardThunks,
  compactPlayersSelectors,
} from 'packages/season-top-board';

const mapStateToProps = (state) => ({
  compactPlayers: compactPlayersSelectors.getData(state),
  isCompactPlayersLoading: compactPlayersSelectors.getIsLoading(state),
  topOfTheSeason: topOfTheSeasonSelectors.getData(state),
  isTopOfTheSeasonLoading: topOfTheSeasonSelectors.getIsTopOfTheSeasonLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  seasonTopBoardActions: bindActionCreators(seasonTopBoardThunks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
