import React, { memo } from 'react';

import { ExpandButton, LoaderContainer, Separator } from 'packages/shared/components';
import { TournamentsExtendedCategory } from 'packages/categories/types';
import { Category as CategoryView, Tournament } from 'types';

import { Category } from './components';

type Props = {
  isCategoriesLoading: boolean;
  isSelectedCategoryLoading: boolean;
  isCollapsed: boolean;
  useControlsHoverEffect?: boolean;
  selectedCategoryId: number | null;
  visibleCategories: CategoryView[];
  selectedCategory?: TournamentsExtendedCategory;
  onCategoryClick: (category: CategoryView) => void;
  onTournamentClick: (tournament: Tournament) => void;
  onCategoryToggle: (category: CategoryView) => void;
  onExpandClick: () => void;
};

const CategoryList = ({
  visibleCategories,
  isSelectedCategoryLoading,
  isCategoriesLoading,
  selectedCategory,
  selectedCategoryId,
  onCategoryClick,
  onTournamentClick,
  onCategoryToggle,
  useControlsHoverEffect,
  onExpandClick,
  isCollapsed,
}: Props) => {
  return (
    <>
      <LoaderContainer isLoading={isCategoriesLoading}>
        {visibleCategories.map((category) => {
          const { id } = category;
          const isCategorySelected = selectedCategoryId === id;
          const categoryTournaments = isCategorySelected ? selectedCategory?.tournaments : [];

          return (
            <div key={id}>
              <Category
                category={category}
                tournaments={categoryTournaments}
                isLoading={isSelectedCategoryLoading}
                isOpen={isCategorySelected}
                onTournamentClick={onTournamentClick}
                onCategoryToggle={onCategoryToggle}
                onCategoryClick={onCategoryClick}
                useControlsHoverEffect={useControlsHoverEffect}
              />

              <Separator />
            </div>
          );
        })}
      </LoaderContainer>

      <ExpandButton onClick={onExpandClick} isOpen={!isCollapsed} />
    </>
  );
};

export default memo(CategoryList);
