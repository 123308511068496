import { PluginHook, useAbsoluteLayout, useBlockLayout, useFlexLayout } from 'react-table';
import { useSticky } from 'react-table-sticky';
import classnames from 'classnames';

import { useCustomStyling, useCustomSticky } from '../plugins';
import { LayoutType } from '../types';
import styles from '../styles.scss';

export interface Options {
  layoutType?: LayoutType;
  isColumnsSticky?: boolean;
}

export interface Configuration<D extends object> {
  rowClassName?: string;
  columnClassName?: string;
  plugins: PluginHook<D>[];
}

const getConfigurationsByOptions = <D extends object>({
  layoutType = LayoutType.Flex,
  isColumnsSticky = false,
}: Options): Configuration<D> => {
  const configuration: Configuration<D> = {
    plugins: [useCustomStyling],
  };

  if (isColumnsSticky) {
    populateConfigurationByStickyColumnProps(configuration);
  }

  populateConfigurationByLayoutType(configuration, layoutType);

  return configuration;
};

const populateConfigurationByStickyColumnProps = <D extends object>(
  configuration: Configuration<D>,
): void => {
  configuration.plugins.push(useCustomSticky);
  configuration.plugins.push(useSticky);
};

const populateConfigurationByLayoutType = <D extends object>(
  configuration: Configuration<D>,
  layoutType: LayoutType,
): void => {
  switch (layoutType) {
    case LayoutType.Flex: {
      configuration.plugins.push(useFlexLayout);
      configuration.rowClassName = classnames(configuration.rowClassName, styles.flexLayout);

      break;
    }
    case LayoutType.Absolute: {
      configuration.plugins.push(useAbsoluteLayout);

      break;
    }
    case LayoutType.Block: {
      configuration.plugins.push(useBlockLayout);

      break;
    }
  }
};

export default getConfigurationsByOptions;
