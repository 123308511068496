import React, { memo } from 'react';
import { Chip } from 'packages/shared/components';

import styles from '../styles.scss';
import EventTime from '../EventTime';

export type Props = {
  minute: string;
  injuryTime?: string;
};

const Minute = ({ minute, injuryTime }: Props) => (
  <Chip className={styles.chip}>
    <EventTime minute={minute} injuryTime={injuryTime} />
  </Chip>
);

export default memo(Minute);
