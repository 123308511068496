import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadStandingsStart,
  loadStandingsSuccess,
  loadStandingsFailure,
  cleanStandingsSuccess,
} from '../actions';
import { Status } from '../types';

const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${loadStandingsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(loadStandingsFailure, loadStandingsSuccess)}`]: () => {
    return { isLoading: false };
  },
  [`${cleanStandingsSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
