import { EventHeadToHead } from 'packages/rest';
import { Competitor } from 'types';

import DateService from 'packages/date-az';
import { loadLastGamesStart, loadLastGamesSuccess, loadLastGamesFailure } from '../actions';
import { mapEventHeadToHead } from '../services';

const loadLastGames = (
  homeTeam: Competitor,
  awayTeam: Competitor,
  eventScheduledDate: string,
) => async (dispatch) => {
  dispatch(loadLastGamesStart());

  const scheduledDate = DateService.parse(eventScheduledDate).toISODate();

  const headToHeadResponse = await EventHeadToHead.loadHeadToHeadEvents({
    homeTeamId: homeTeam.id,
    awayTeamId: awayTeam.id,
    scheduledDate,
  });
  const homeLastFiveResponse = await EventHeadToHead.loadLastFiveEvents({
    teamId: homeTeam.id,
    scheduledDate,
  });
  const awayLastFiveResponse = await EventHeadToHead.loadLastFiveEvents({
    teamId: awayTeam.id,
    scheduledDate,
  });

  const headToHeadEvents = mapEventHeadToHead(
    headToHeadResponse.data.results,
    homeLastFiveResponse.data.results,
    awayLastFiveResponse.data.results,
    homeTeam,
    awayTeam,
  );

  try {
    dispatch(loadLastGamesSuccess(headToHeadEvents));
  } catch (error) {
    dispatch(loadLastGamesFailure());
  }
};

export default loadLastGames;
