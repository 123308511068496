import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Settings } from '../types';

const defaultState: any = {};

const reducerMap: ReducerMap<Settings, any> = {
  [`${actions.configSetSettings}`]: (state, { payload }) => {
    const { settings } = payload;

    return settings;
  },
};

export default handleActions(reducerMap, defaultState);
