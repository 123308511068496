import { toCamelCase } from 'packages/utils/Object.utils';

import { Interfaces } from '../types';

const PARAMETER_VALUE = {
  total: 0,
  average: 0,
  per90: 0,
};

const mapStatistics = (
  statistics: Record<string, number>,
): Interfaces.Statistics.Statistics.Statistics => {
  const {
    matchesPlayed = PARAMETER_VALUE,
    cornerKicks = PARAMETER_VALUE,
    shotsBlocked = PARAMETER_VALUE,
    substitutedIn = PARAMETER_VALUE,
    substitutedOut = PARAMETER_VALUE,

    goalsScored = PARAMETER_VALUE,
    goalPoints = PARAMETER_VALUE,
    goalsByPenalty = PARAMETER_VALUE,
    goalsByHead = PARAMETER_VALUE,
    assists = PARAMETER_VALUE,
    totalShots = PARAMETER_VALUE,
    offsides = PARAMETER_VALUE,
    yellowCards = PARAMETER_VALUE,
    yellowRedCards = PARAMETER_VALUE,
    redCards = PARAMETER_VALUE,
    shotsOnTarget = PARAMETER_VALUE,
    shotsOffTarget = PARAMETER_VALUE,
    goalConversion = PARAMETER_VALUE,
    cleanSheets = PARAMETER_VALUE,
    minutesPlayed = PARAMETER_VALUE,
  } = toCamelCase(statistics);

  return {
    gamesPlayed: matchesPlayed.total,
    corners: cornerKicks.total,
    shotsBlocked: shotsBlocked.total,
    substitutedIn: substitutedIn.total,
    substitutedOut: substitutedOut.total,
    minutesPlayed: minutesPlayed.total,

    goals: parseFloat(goalsScored.total.toFixed(2)),
    penaltyGoals: parseFloat(goalsByPenalty.total.toFixed(2)),
    goalPoints: parseFloat(goalPoints.total.toFixed(2)),
    goalsByHeader: parseFloat(goalsByHead.total.toFixed(2)),
    assists: parseFloat(assists.total.toFixed(2)),
    totalShots: parseFloat(totalShots.total.toFixed(2)),
    offside: parseFloat(offsides.total.toFixed(2)),
    yellowCards: parseFloat(yellowCards.total.toFixed(2)),
    yellowRedCards: parseFloat(yellowRedCards.total.toFixed(2)),
    redCards: parseFloat(redCards.total.toFixed(2)),
    shotsOnGoal: parseFloat(shotsOnTarget.total.toFixed(2)),
    shotsOffGoal: parseFloat(shotsOffTarget.total.toFixed(2)),
    goalsConversion: parseFloat(goalConversion.total.toFixed(2)),
    cleanSheet: parseFloat(cleanSheets.total.toFixed(2)),

    goalsPerGame: parseFloat(goalsScored.average.toFixed(2)),
    penaltyGoalsPerGame: parseFloat(goalsByPenalty.average.toFixed(2)),
    goalPointsPerGame: parseFloat(goalPoints.average.toFixed(2)),
    goalsByHeaderPerGame: parseFloat(goalsByHead.average.toFixed(2)),
    assistsPerGame: parseFloat(assists.average.toFixed(2)),
    totalShotsPerGame: parseFloat(totalShots.average.toFixed(2)),
    offsidePerGame: parseFloat(offsides.average.toFixed(2)),
    yellowCardsPerGame: parseFloat(yellowCards.average.toFixed(2)),
    yellowRedCardsPerGame: parseFloat(yellowRedCards.average.toFixed(2)),
    redCardsPerGame: parseFloat(redCards.average.toFixed(2)),
    shotsOnGoalPerGame: parseFloat(shotsOnTarget.average.toFixed(2)),
    shotsOffGoalPerGame: parseFloat(shotsOffTarget.average.toFixed(2)),
    goalsConversionPerGame: parseFloat(goalConversion.average.toFixed(2)),
    cleanSheetPerGame: parseFloat(cleanSheets.average.toFixed(2)),
    minutesPlayedPerGame: Math.round(minutesPlayed.average || 0),

    goalsPerNinety: parseFloat(goalsScored.per90.toFixed(2)),
    penaltyGoalsPerNinety: parseFloat(goalsByPenalty.per90.toFixed(2)),
    goalPointsPerNinety: parseFloat(goalPoints.per90.toFixed(2)),
    goalsByHeaderPerNinety: parseFloat(goalsByHead.per90.toFixed(2)),
    assistsPerNinety: parseFloat(assists.per90.toFixed(2)),
    totalShotsPerNinety: parseFloat(totalShots.per90.toFixed(2)),
    offsidePerNinety: parseFloat(offsides.per90.toFixed(2)),
    yellowCardsPerNinety: parseFloat(yellowCards.per90.toFixed(2)),
    yellowRedCardsPerNinety: parseFloat(yellowRedCards.per90.toFixed(2)),
    redCardsPerNinety: parseFloat(redCards.per90.toFixed(2)),
    shotsOnGoalPerNinety: parseFloat(shotsOnTarget.per90.toFixed(2)),
    shotsOffGoalPerNinety: parseFloat(shotsOffTarget.per90.toFixed(2)),
    goalsConversionPerNinety: parseFloat(goalConversion.per90.toFixed(2)),
    cleanSheetPerNinety: parseFloat(cleanSheets.per90.toFixed(2)),
  };
};

export default mapStatistics;
