import { SelectItem } from 'packages/shared/components/Select/types';

type Params = {
  presetItems: SelectItem[];
  activePreset: string | number;
};

const getActivePresetItem = ({ presetItems, activePreset }: Params) => {
  return presetItems.find(({ id }) => id === activePreset) || presetItems[0];
};

export default getActivePresetItem;
