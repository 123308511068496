import React, { RefObject, useRef } from 'react';
import classnames from 'classnames';

import { FlexiblePopover } from 'packages/shared/components';
import { ComponentLike } from 'types';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';

import { SelectItems, SelectInput } from '../../components';
import { SelectTheme, SelectItem, SelectSize } from '../../types';
import styles from '../styles.scss';

type Props<T extends SelectItem> = {
  selectedItem?: T;
  items: T[];
  itemComponent?: ComponentLike;
  onChange: (item: T) => void;
  theme?: SelectTheme;
  className?: string;
  isClosable?: boolean;
  isDisabled?: boolean;
  labelComponent?: ComponentLike;
  labelComponentProps?: any;
  itemsContainerClass?: string;
  placeholder?: string;
  isLoading?: boolean;
  size?: SelectSize;
  isReversed?: boolean;
  inputClassName?: string;
  scrollElementRef?: RefObject<HTMLElement>;
  useScrollIntoPopoverView?: boolean;
};

const SingleSelect = <T extends SelectItem>({
  selectedItem,
  onChange,
  items,
  itemComponent,
  theme = SelectTheme.Gray,
  size = SelectSize.FullWidth,
  className,
  isClosable = true,
  isDisabled = false,
  isLoading = false,
  labelComponent,
  labelComponentProps,
  placeholder = '',
  itemsContainerClass,
  isReversed = false,
  useScrollIntoPopoverView = false,
  inputClassName,
  scrollElementRef,
}: Props<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const popoverComponentClass = classnames(
    styles.selectItems,
    {
      [styles.bottomOffset]: isReversed,
      [styles.topOffset]: !isReversed,
    },
    itemsContainerClass,
  );
  const handleItemClick = (item: T) => {
    if (selectedItem?.id !== item.id) {
      onChange(item);
    }
  };

  return (
    <div ref={containerRef} className={classnames(styles[size], className)}>
      <FlexiblePopover
        isDisabled={isLoading || isDisabled}
        popoverComponent={SelectItems}
        popoverProps={{
          className: popoverComponentClass,
          onItemClick: handleItemClick,
          itemComponent,
          selectedItem: selectedItem ? [selectedItem] : [],
          items,
          containerRef,
          isCloseableOnSelect: isClosable,
        }}
        useChildrenWrapper
        useScrollIntoPopoverView={useScrollIntoPopoverView}
        scrollElementRef={scrollElementRef}
        justify={PopoverJustify.CenterStart}
        align={isReversed ? PopoverAlign.Top : PopoverAlign.Bottom}
        useOverlay={false}
        closeOnClickOutside
      >
        <SelectInput
          inputClassName={inputClassName}
          label={selectedItem?.label || placeholder}
          theme={theme}
          isDisabled={isDisabled}
          labelComponent={labelComponent}
          labelComponentProps={labelComponentProps}
          isLoading={isLoading}
        />
      </FlexiblePopover>
    </div>
  );
};

export default React.memo(SingleSelect);
