import { useEffect, useMemo, useState } from 'react';

import { Stage, StageType } from 'packages/event-standings/types';

import { getDefaultActiveTabIndex } from '../services';
import { useActiveTab } from '../hooks';
import { StageTab } from '../types';

interface Options<T extends StageTab> {
  getFilterTabs: (stages: Stage[]) => T[];
  activeStageTab?: T;
  defaultActiveTabId?: T['id'];
}

export const useFilterTabs = <T extends StageTab>(
  stages: Stage[] | null,
  { getFilterTabs, activeStageTab, defaultActiveTabId }: Options<T>,
) => {
  const tabs = useMemo(() => {
    return stages ? getFilterTabs(stages) : [];
  }, [stages]);
  const defaultActiveTabIndex = getDefaultActiveTabIndex(tabs, defaultActiveTabId);
  const [activeTab, setActiveTabById] = useActiveTab(tabs, defaultActiveTabIndex);
  const [isTabsDisabled, setIsTabsDisabled] = useState<boolean>();

  useEffect(() => {
    if (activeStageTab) {
      if (activeStageTab.type === StageType.Tables && isTabsDisabled) {
        setIsTabsDisabled(false);
      } else if (activeStageTab.type !== StageType.Tables && !isTabsDisabled) {
        setIsTabsDisabled(true);
      }
    }
  }, [activeStageTab?.type]);

  return {
    activeTab,
    setActiveTabById,
    isTabsDisabled,
    tabs,
  };
};

export default useFilterTabs;
