import { handleActions, ReducerMap } from 'redux-actions';

import { TournamentsExtendedCategory } from '../../types';
import { mainCategoryActions } from '../../actions';

const defaultState: TournamentsExtendedCategory | null = null;

const reducerMap: ReducerMap<TournamentsExtendedCategory | null, TournamentsExtendedCategory> = {
  [`${mainCategoryActions.loadCategorySuccess}`]: (state, { payload }) => {
    return payload;
  },
};

export default handleActions(reducerMap, defaultState);
