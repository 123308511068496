import { seasonHistorySelectors } from './selectors';
import * as seasonHistoryConstants from './constants';
import * as seasonHistoryThunks from './thunks';
import * as seasonHistoryActions from './actions';

import { configure } from './config';
import reducers from './reducers';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  seasonHistoryConstants,
  seasonHistorySelectors,
  seasonHistoryThunks,
  seasonHistoryActions,
  createReducer,
};
