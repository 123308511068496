import React, { memo, ReactElement, useRef } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { SearchSelectItem, SelectSize } from 'packages/shared/components/Select/types';
import { SearchSelect as SearchableSelect } from 'packages/shared/components';
import { ComponentLike } from 'types';

import { Label } from '../../components';
import styles from './styles.scss';

type Props<T extends SearchSelectItem> = {
  name: string;
  labelPrefix: string;
  onSelectionChange: (item: T[]) => void;
  items: T[];
  selectedItems: T[];
  label?: string | ReactElement;
  itemComponent?: ComponentLike;
  className?: string;
  isCloseableOnResize?: boolean;
  useScrollIntoPopoverView?: boolean;
  itemsContainerClass?: string;
};

const SearchSelect = <T extends SearchSelectItem>({
  selectedItems,
  items,
  onSelectionChange,
  label,
  name,
  labelPrefix,
  itemComponent,
  className,
  isCloseableOnResize,
  useScrollIntoPopoverView = false,
  itemsContainerClass,
}: Props<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className={classnames(styles.searchInputContainer, className)}>
      {!isEmpty(label) && <Label name={name} label={label} />}
      <SearchableSelect<T>
        itemsContainerClass={itemsContainerClass}
        useScrollIntoPopoverView={useScrollIntoPopoverView}
        scrollElementRef={containerRef}
        labelPrefix={labelPrefix}
        size={SelectSize.FullWidth}
        selectedItems={selectedItems}
        wrapperClassName={styles.searchSelect}
        onSelectionChange={onSelectionChange}
        defaultItems={items}
        itemComponent={itemComponent}
        isCloseableOnResize={isCloseableOnResize}
      />
    </div>
  );
};

export default memo(SearchSelect) as typeof SearchSelect;
