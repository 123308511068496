import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  isTeamLabelVisible: boolean;
};
const Team = ({ isTeamLabelVisible }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Skeleton marginRight={6} variant="circular" />
      {isTeamLabelVisible && <Skeleton height={12} width={60} variant="rectangular" />}
    </div>
  );
};

export default memo(Team);
