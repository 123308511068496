import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Statistics = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton minWidth={16} minHeight={16} width={16} height={16} variant="circular" />
      <Skeleton minWidth={16} minHeight={16} width={16} height={16} variant="circular" />
      <Skeleton minWidth={16} minHeight={16} width={16} height={16} variant="circular" />
      <Skeleton minWidth={16} minHeight={16} width={16} height={16} variant="circular" />
    </div>
  );
};

export default memo(Statistics);
