import React, { memo } from 'react';
import classnames from 'classnames';

import { Figure } from 'packages/shared/components';
import { Player } from 'packages/season-player-statistics/types';

import styles from './styles.scss';

type Props = {
  player: Player;
  logoClassName?: string;
};

const PlayerCell = ({ player, logoClassName }: Props) => {
  const { countryLogo, name, firstName, lastName, position, age } = player;
  const nameView = name ? name : `${firstName} ${lastName}`;
  const label = `${position}${age ? `, ${age}` : ''}`;

  return (
    <div className={styles.playerCell}>
      <div className={styles.nameBlock}>{nameView}</div>
      <div className={styles.label}>
        <Figure src={countryLogo} className={classnames(styles.logo, logoClassName)} />
        {label}
      </div>
    </div>
  );
};

export default memo(PlayerCell);
