import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  eventHeadToHeadThunks,
  eventHeadToHeadSelectors,
  eventHeadToHeadActions,
} from 'packages/event-head-to-head';
import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => {
  return {
    activeEvent: eventDetailsSelectors.getActiveEvent(state),
    eventHeadToHead: eventHeadToHeadSelectors.getEventHeadToHead(state),
    isLoading: eventHeadToHeadSelectors.getIsEventHeadToHeadLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    cleanEventHeadToHead: () => dispatch(eventHeadToHeadActions.cleanEventHeadToHead()),
    eventHeadToHeadActions: bindActionCreators(eventHeadToHeadThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
