import React, { memo, ReactNode } from 'react';
import classnames from 'classnames';

import { SelectItem } from 'packages/shared/components/Select/types';
import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from './styles.scss';

type Props = {
  item: SelectItem;
  isSelected: boolean;
  isCheckShown?: boolean;
  handleClick: Function;
  children?: ReactNode;
};

const Item = ({ item, handleClick, isSelected, isCheckShown = false, children }: Props) => {
  const onClick = (event) => {
    event.stopPropagation();

    handleClick(item);
  };

  return (
    <div
      className={classnames(styles.item, {
        [styles.selected]: isSelected,
      })}
      onClick={onClick}
    >
      {children ? children : item.label}
      {isCheckShown && <Icon id={IconType.CheckMark} className={styles.icon} />}
    </div>
  );
};

export default memo(Item);
