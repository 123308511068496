import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { EventActiveFilters, Season, Tournament } from 'packages/team-events/types';
import { YearItem } from 'packages/shared/components/Calendar/types';
import { DatePicker } from 'packages/shared/components';
import { LocaleCode } from 'types';

import { TournamentSelect, SeasonSelect, HeaderSkeleton } from '../../../../components';
import { YEARS_SELECT_RANGE } from './constants';
import styles from './styles.scss';

type Props = {
  tournaments: Tournament[];
  onTournamentChange: (item?: Tournament) => void;
  onSeasonChange: (seasons: Season[]) => void;
  onPeriodChange: (dates: [string, string]) => void;
  activeFilters: EventActiveFilters;
  eventsCount: number;
  onYearClick: (year: YearItem) => void;
  isLoading: boolean;
};

const TeamEventsFiltersBase = ({
  tournaments,
  activeFilters,
  onTournamentChange,
  onSeasonChange,
  onPeriodChange,
  eventsCount,
  onYearClick,
  isLoading,
}: Props) => {
  if (isLoading) {
    return <HeaderSkeleton />;
  }

  const { period, tournament, selectedSeasons, tournamentSeasons } = activeFilters;

  return (
    <div className={styles.teamEventsFilters}>
      <div className={styles.title}>{i18n.value('team.teamEvents.filters.title')}</div>
      <DatePicker
        value={period}
        onApplyClick={onPeriodChange}
        eventsCount={eventsCount}
        isRangeMode
        yearOffset={YEARS_SELECT_RANGE}
        localeCode={LocaleCode.En}
        onYearClick={onYearClick}
      />
      <TournamentSelect
        onChange={onTournamentChange}
        tournaments={tournaments}
        selectedTournament={tournament}
      />
      <SeasonSelect
        onChange={onSeasonChange}
        seasons={tournamentSeasons}
        isDisabled={!tournament}
        selectedSeasons={selectedSeasons}
      />
    </div>
  );
};

export default memo(TeamEventsFiltersBase);
