import { EventCommentary } from 'packages/rest';

import * as actions from '../actions';
import { mapCommentary } from '../services';

export const loadEventCommentary = ({ eventId }) => async (dispatch) => {
  try {
    const { data } = await EventCommentary.loadEventCommentary({ eventId });
    const commentaries = mapCommentary(data.results);

    dispatch(actions.loadEventCommentarySuccess({ commentaries }));
  } catch (error) {
    dispatch(actions.loadEventCommentaryFailure());
  }
};
