import React, { memo } from 'react';
import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed/lib';
import { useParams } from 'react-router-dom';

import { NavigationMenu, NoDataLabel } from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { Stage } from 'packages/event-standings/types';
import { NarrowTeamStatisticsTable } from 'packages/shared/modules';

import { useStageTabs, useFilterTabs, useActiveTabContentElement } from '../../../../hooks';
import { getFilterTabs, getBracketProps, getTableProps } from './services';
import styles from './styles.scss';
import { HeaderSkeleton } from './components';

type Props = {
  stages: Stage[] | null;
  isStagesLoading: boolean;
};

const SeasonStandings = ({ stages, isStagesLoading }: Props) => {
  const { tournamentId = '', seasonId = '' } = useParams();
  const StageTabs = useStageTabs(stages, {
    getTableProps,
    getBracketProps,
    additionalProps: {
      activeUniqueTournamentId: tournamentId,
      activeSeasonId: seasonId,
    },
  });
  const FilterTabs = useFilterTabs(stages, { getFilterTabs, activeStageTab: StageTabs.activeTab });
  const isTabsInitialized = !isEmpty(StageTabs.activeTab) && !isEmpty(FilterTabs.activeTab);
  const activeContentElement = useActiveTabContentElement(
    StageTabs.activeTab,
    FilterTabs.activeTab,
  );

  if (isStagesLoading) {
    return (
      <div className={styles.tabsContainer}>
        <HeaderSkeleton />
        <div className={styles.content}>
          <NarrowTeamStatisticsTable.Mobile isLoading />
        </div>
      </div>
    );
  }

  if (!isTabsInitialized) {
    return (
      <div className={styles.tabsContainer}>
        <div className={styles.header}>
          <div className={styles.label}>{i18n.value('season.seasonStandings.title')}</div>
        </div>
        <NoDataLabel />
      </div>
    );
  }

  return (
    <div className={styles.tabsContainer}>
      <div className={styles.header}>
        <div className={styles.label}>{i18n.value('season.seasonStandings.title')}</div>
        {StageTabs.tabs.length !== 1 && (
          <NavigationMenu
            items={StageTabs.tabs}
            className={styles.stageTabs}
            activeId={StageTabs.activeTab?.id || ''}
            onClick={StageTabs.setActiveTabById}
            theme={NavigationTheme.Dropdown}
          />
        )}
        <NavigationMenu
          items={FilterTabs.tabs}
          activeId={FilterTabs.activeTab?.id || ''}
          onClick={FilterTabs.setActiveTabById}
          isDisabled={FilterTabs.isTabsDisabled}
          className={styles.filterTabs}
          theme={NavigationTheme.Dropdown}
        />
      </div>
      <div className={styles.content}>{activeContentElement}</div>
    </div>
  );
};

export default memo(SeasonStandings);
