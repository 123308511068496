import { SeasonTopBoard } from 'packages/rest';

import { TopVenue, VenuesParameter } from '../types';
import { VENUES_API_ORDERING_BY_PARAMETERS } from '../constants';
import * as services from '../services';
import * as actions from '../actions';

export const loadSeasonTopVenues = (seasonId: number, parameter: VenuesParameter) => async (
  dispatch,
) => {
  try {
    dispatch(actions.loadTopVenuesStart());

    const { data } = await SeasonTopBoard.loadSeasonTopVenues({
      seasonId,
      ordering: VENUES_API_ORDERING_BY_PARAMETERS[parameter],
    });

    const topVenues: TopVenue[] = services.mapSeasonTopVenues(data.results);

    dispatch(actions.loadTopVenuesSuccess(topVenues));
  } catch (e) {
    dispatch(actions.loadTopVenuesFailure(e));
  }
};
