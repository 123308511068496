import React, { memo } from 'react';
import classnames from 'classnames';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const DetailsSkeleton = () => {
  return (
    <>
      <div className={classnames(styles.container, styles.passport)}>
        <Skeleton height={16} width="100%" maxWidth={160} variant="rectangular" />

        <div>
          <Skeleton width={50} height={12} variant="rectangular" />
          <Skeleton width={120} height={12} marginLeft={8} variant="rectangular" />
        </div>
        <div>
          <Skeleton width={55} height={12} variant="rectangular" />
          <Skeleton width={16} height={16} variant="rectangular" marginLeft={8} marginRight={8} />
          <Skeleton width={50} height={12} variant="rectangular" />
        </div>
        <div>
          <Skeleton width={60} height={12} variant="rectangular" />
          <Skeleton width={16} height={16} variant="rectangular" marginLeft={8} marginRight={8} />
          <Skeleton width={50} height={12} variant="rectangular" />
        </div>
        <div>
          <Skeleton width={40} height={12} variant="rectangular" />
          <Skeleton width={45} height={12} marginLeft={8} variant="rectangular" />
        </div>
        <div>
          <Skeleton width={40} height={12} variant="rectangular" />
          <Skeleton width={40} height={12} marginLeft={8} variant="rectangular" />
        </div>
        <div>
          <Skeleton width={30} height={12} variant="rectangular" />
          <Skeleton width={40} height={12} marginLeft={8} variant="rectangular" />
        </div>
        <div>
          <Skeleton width={55} height={12} variant="rectangular" />
          <Skeleton width={100} height={12} marginLeft={8} variant="rectangular" />
        </div>
      </div>

      <div className={classnames(styles.container, styles.socialMedia)}>
        <Skeleton height={16} width="100%" maxWidth={160} variant="rectangular" />

        <div>
          <Skeleton marginRight={18} width={24} height={23} variant="circular" />
          <Skeleton marginRight={18} width={24} height={23} variant="circular" />
          <Skeleton marginRight={18} width={24} height={23} variant="circular" />
          <Skeleton marginRight={18} width={24} height={23} variant="circular" />
          <Skeleton width={24} height={23} variant="circular" />
        </div>
      </div>
    </>
  );
};
export default memo(DetailsSkeleton);
