import { FULL_VISIBLE_RATIO, NONE_VISIBLE_RATIO, MONTH_LABEL_AREA_WIDTH } from '../const';

const drawMonthLabelBorders = (scrollContainerRef, groupedDatesEntries, activeDateTime) => {
  const scrollContainerEl = scrollContainerRef.current;
  const monthLabelIntersectionObserver = getIntersectionObserver(
    scrollContainerEl,
    groupedDatesEntries,
  );

  const monthBlocks = Array.from<HTMLElement>(scrollContainerEl.children);

  monthBlocks.forEach((monthBlock: HTMLElement, index) => {
    const monthLabelEl: HTMLElement = monthBlock.firstChild as HTMLElement;

    if (monthLabelEl.id !== activeDateTime.monthLong) {
      addBorder(monthLabelEl, index === 0);
    }

    monthLabelIntersectionObserver.observe(monthLabelEl);
  });

  return monthLabelIntersectionObserver;
};

const getIntersectionObserver = (scrollContainerEl: HTMLElement, groupedDatesEntries) => {
  return new IntersectionObserver(([entry]) => {
    const { target, intersectionRatio } = entry;
    const monthLabelEl = target as HTMLElement;
    const monthLabelIndex = groupedDatesEntries.findIndex(([month]) => month === monthLabelEl.id);

    const isMonthFixedInCentre = intersectionRatio === FULL_VISIBLE_RATIO;
    const isMonthOutOfCenter = intersectionRatio === NONE_VISIBLE_RATIO;

    if (isMonthFixedInCentre) {
      removeBorder(monthLabelEl);
    } else if (isMonthOutOfCenter) {
      addBorder(monthLabelEl, monthLabelIndex === 0);
    }
  }, getObserverOptions(scrollContainerEl));
};

const getObserverOptions = (scrollContainerEl: HTMLElement) => {
  const horizontalOffset = scrollContainerEl.offsetWidth / 2 - MONTH_LABEL_AREA_WIDTH;
  const rootMargin = `0px -${horizontalOffset}px 0px -${horizontalOffset}px`;

  return {
    root: scrollContainerEl,
    rootMargin: horizontalOffset > 0 ? rootMargin : '0px',
    threshold: [1, 0],
  };
};

const addBorder = (monthLabelEl: HTMLElement, isFirstMonth: boolean): void => {
  monthLabelEl.style[isFirstMonth ? 'borderRight' : 'borderLeft'] = '2px solid #c9c9c9';
};

const removeBorder = (monthLabelEl: HTMLElement): void => {
  monthLabelEl.style.border = 'none';
};

export default drawMonthLabelBorders;
