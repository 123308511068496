import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadCategoriesStart = createAction(`${NAMESPACE}LOAD_CATEGORIES_START`);
export const loadCategoriesSuccess = createAction(`${NAMESPACE}LOAD_CATEGORIES_SUCCESS`);
export const loadCategoriesFailure = createAction(`${NAMESPACE}LOAD_CATEGORIES_FAILURE`);

export const loadSelectedCategoryStart = createAction(`${NAMESPACE}LOAD_SELECTED_CATEGORY_START`);
export const loadSelectedCategorySuccess = createAction(
  `${NAMESPACE}LOAD_SELECTED_CATEGORY_SUCCESS`,
);
export const loadSelectedCategoryFailure = createAction(
  `${NAMESPACE}LOAD_SELECTED_CATEGORY_FAILURE`,
);
