import getPath from './getPath';
import { Interfaces } from '../../../types';

export const getStatus = (state): Interfaces.Statistics.Status => {
  return getPath(state).status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
