import { PlayerHappeningType, SummaryHappeningType } from 'packages/events/types';
import { BaseHappening, PlayerHappening } from 'packages/event-time-line/types';
import { ArrayUtils } from 'packages/utils';

import getPlayer from '../getPlayer';
import mapPlayerHappening from './mapPlayerHappening';
import { PlayersHappenings } from '../../types';

const groupHappeningsByPlayerId = (happenings: BaseHappening[] = []): PlayersHappenings => {
  const playerHappenings = happenings.reduce<BaseHappening[]>((acc, happening) => {
    const { eventType } = happening;

    if (eventType in PlayerHappeningType) {
      acc.push(mapHappening(happening));
    } else if (eventType === SummaryHappeningType.SUBSTITUTION) {
      acc.push(mapHappening({ ...happening, eventType: PlayerHappeningType.SUBSTITUTION_IN }));
      acc.push(mapHappening({ ...happening, eventType: PlayerHappeningType.SUBSTITUTION_OUT }));
    }

    return acc;
  }, []);

  return ArrayUtils.groupBy(playerHappenings, (happening) => happening.playerId);
};

const mapHappening = (happening: PlayerHappening): PlayerHappening => {
  const player = getPlayer(happening);

  return mapPlayerHappening({ ...happening, playerId: player?.playerId });
};

export default groupHappeningsByPlayerId;
