import { handleActions, ReducerMap } from 'redux-actions';

import { Stage } from 'packages/event-standings/types';

import { stagesActions } from '../../actions';

const defaultState: Stage[] | null = [];

const reducerMap: ReducerMap<Stage[] | null, Stage[]> = {
  [`${stagesActions.loadStagesSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${stagesActions.cleanStagesSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
