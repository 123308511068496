import React, { memo, useCallback } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Size } from 'packages/shared/components/TeamLabel/types';
import { TeamLabel } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Team } from 'types';

import styles from './styles.scss';

type Props = {
  team?: any;
  isActive?: boolean;
  uniqueTournamentId?: number | string;
  seasonId?: number | string;
  className?: string;
};

const defaultTeam = {
  name: '',
  score: '',
  isWinner: false,
  logo: undefined,
};

const TeamPreview = ({
  team = defaultTeam,
  isActive = false,
  uniqueTournamentId,
  seasonId,
  className,
}: Props) => {
  const { score, name, isWinner } = team;
  const validatedTeam = { ...team, name: name || i18n.value('common.notAvailable') };

  const { appNavigate } = useNavigation();

  const handleTeamClick = useCallback(
    (team: Team) => {
      if (uniqueTournamentId && seasonId) {
        appNavigate(DesktopPage.FootballTeamSummaryDescription, {
          teamId: team.id,
          seasonId,
          uniqueTournamentId,
        });
      } else {
        appNavigate(DesktopPage.FootballTeam, { teamId: team.id });
      }
    },
    [uniqueTournamentId, seasonId],
  );

  return (
    <div
      className={classnames(styles.teamPreviewContainer, className, {
        [styles.winner]: isWinner,
        [styles.empty]: !name,
        [styles.active]: isActive,
      })}
    >
      <TeamLabel
        onLogoClick={handleTeamClick}
        onNameClick={handleTeamClick}
        className={styles.teamLabel}
        team={validatedTeam}
        size={Size.Md}
        isLogoFirst
      />
      <div className={styles.score}>{score}</div>
    </div>
  );
};

export default memo(TeamPreview);
