import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadInitialTopPlayersStart = createAction(
  `${NAMESPACE}LOAD_INITIAL_TOP_PLAYERS_START`,
);
export const loadInitialTopPlayersSuccess = createAction(
  `${NAMESPACE}LOAD_INITIAL_TOP_PLAYERS_SUCCESS`,
);
export const loadInitialTopPlayersFailure = createAction(
  `${NAMESPACE}LOAD_INITIAL_TOP_PLAYERS_FAILURE`,
);

export const loadTopPlayersStart = createAction(`${NAMESPACE}LOAD_TOP_PLAYERS_START`);
export const loadTopPlayersSuccess = createAction(`${NAMESPACE}LOAD_TOP_PLAYERS_SUCCESS`);
export const loadTopPlayersFailure = createAction(`${NAMESPACE}LOAD_TOP_PLAYERS_FAILURE`);

export const cleanTopPlayersSuccess = createAction(`${NAMESPACE}CLEAN_TOP_PLAYERS_SUCCESS`);
