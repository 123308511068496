import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { categoryModalActions } from '../../actions';
import { CategoryModalData } from '../../types';

const defaultState: CategoryModalData = {
  category: undefined,
  tournaments: [],
};

const reducerMap: ReducerMap<CategoryModalData, CategoryModalData> = {
  [`${categoryModalActions.loadCategorySuccess}`]: (state, { payload }) => {
    return payload;
  },

  [`${combineActions(
    categoryModalActions.loadCategoryFailure,
    categoryModalActions.clearCategorySuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
