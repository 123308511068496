import { handleActions, ReducerMap } from 'redux-actions';

import { Event } from 'packages/events/types';

import { finishedEventsActions } from '../../actions';

const defaultState = [];

const reducerMap: ReducerMap<Event[], any> = {
  [`${finishedEventsActions.cleanFinishedEventsSuccess}`]: () => {
    return defaultState;
  },
  [`${finishedEventsActions.loadFinishedEventsSuccess}`]: (state, { payload }) => {
    const { events } = payload;

    return events;
  },
  [`${finishedEventsActions.loadMoreFinishedEventsSuccess}`]: (state, { payload }) => {
    const { events } = payload;

    return [...state, ...events];
  },
  [`${finishedEventsActions.updateFinishedEventsStart}`]: (state, { payload }) => {
    return payload;
  },
};

export default handleActions(reducerMap, defaultState);
