import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed/lib';

import { SelectItem, SelectTheme } from 'packages/shared/components/Select/types';
import { Select, SelectItemComponent, SelectLabelComponent } from 'packages/shared/components';

import { TournamentSeasonSelectItems } from '../../types';
import { LoadingSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  onTournamentSelect: (tournamentItem: SelectItem) => void;
  onSeasonSelect: (seasonItem: SelectItem) => void;
  tournamentSeasonSelectItems: TournamentSeasonSelectItems;
  useSelectorsInOneRow?: boolean;
  isLoading?: boolean;
};

const TournamentSeasonSelect = ({
  tournamentSeasonSelectItems,
  onTournamentSelect,
  onSeasonSelect,
  useSelectorsInOneRow = false,
  isLoading = false,
}: Props) => {
  const {
    tournamentItems,
    seasonItems,
    activeSeasonItem,
    activeTournamentItem,
  } = tournamentSeasonSelectItems;

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{i18n.value('common.tournamentSeasonSelect.title')}</div>

      <div className={classnames(styles.selectors, { [styles.rowView]: useSelectorsInOneRow })}>
        {!isEmpty(activeTournamentItem) && (
          <div className={styles.tournamentGroup}>
            {!useSelectorsInOneRow && (
              <div className={styles.label}>
                {i18n.value('common.tournamentSeasonSelect.label.league')}
              </div>
            )}

            <Select
              labelComponent={SelectLabelComponent.Tournament}
              itemComponent={SelectItemComponent.Tournament}
              labelComponentProps={activeTournamentItem.componentProps}
              selectedItem={activeTournamentItem}
              theme={SelectTheme.White}
              items={tournamentItems}
              onChange={onTournamentSelect}
            />
          </div>
        )}

        {!isEmpty(activeSeasonItem) && (
          <div className={styles.seasonGroup}>
            {!useSelectorsInOneRow && (
              <div className={styles.label}>
                {i18n.value('common.tournamentSeasonSelect.label.season')}
              </div>
            )}

            <Select
              theme={SelectTheme.White}
              items={seasonItems}
              selectedItem={activeSeasonItem}
              onChange={onSeasonSelect}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(TournamentSeasonSelect);
