import { PopoverStyle } from 'packages/shared/components/FlexiblePopover/types';

export const MODAL_CONTAINER_ID = 'modal-container';
export const POPOVER_CONTAINER_ID = 'popover-container';

export const INITIAL_POPOVER_STYLE: PopoverStyle = {
  position: 'absolute',
  visibility: 'hidden',
  top: 0,
  left: 0,
  zIndex: 100,
};

export const DEFAULT_POPOVER_STYLE: PopoverStyle = {
  ...INITIAL_POPOVER_STYLE,
  visibility: 'visible',
};

export const DEFAULT_MODAL_STYLE: PopoverStyle = {
  ...DEFAULT_POPOVER_STYLE,
  position: 'fixed',
  bottom: 0,
  right: 0,
  overflowY: 'auto',
};
