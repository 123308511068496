import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../../actions';
import { TopPlayer } from '../../types';

const defaultState: TopPlayer[] = [];

const reducerMap: ReducerMap<TopPlayer[], TopPlayer[]> = {
  [`${actions.loadCompactTopPlayersSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${actions.cleanTopBoardSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<TopPlayer[], TopPlayer[]>(reducerMap, defaultState);
