import React, { memo } from 'react';
import classnames from 'classnames';

import { SelectTheme } from 'packages/shared/components/Select/types';

import styles from './styles.scss';

type Props = {
  title: string;
  selectedTitle: string;
  selectedItemsCount: number;
  theme: SelectTheme;
  className?: string;
};

const SelectedResults = ({ theme, title, selectedItemsCount, selectedTitle, className }: Props) => {
  return (
    <div className={classnames(styles.resultsWrapper, styles[theme], className)}>
      {selectedItemsCount ? (
        <span>
          {selectedTitle}: {selectedItemsCount}
        </span>
      ) : (
        <span>{title}</span>
      )}
    </div>
  );
};
export default memo(SelectedResults);
