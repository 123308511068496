import React, { ReactElement } from 'react';
import classnames from 'classnames';

import { Spinner, ToggleArrowButton } from 'packages/shared/components';
import { SpinnerSize } from 'packages/shared/components/Spinner/types';
import { ArrowType } from 'packages/shared/components/ToggleArrowButton/types';
import { ComponentLike } from 'types';
import { PopoverState } from 'packages/shared/components/FlexiblePopover/types';

import { SelectTheme } from '../../types';
import styles from './styles.scss';

type Props = {
  label: string | ReactElement;
  popoverState?: PopoverState;
  labelComponent?: ComponentLike;
  theme: SelectTheme;
  isDisabled?: boolean;
  isLoading?: boolean;
  labelComponentProps?: any;
  inputClassName?: string;
};

const SelectInput = ({
  label,
  labelComponent: LabelComponent,
  labelComponentProps = {},
  isDisabled = false,
  isLoading = false,
  popoverState,
  theme,
  inputClassName,
}: Props) => {
  const isOpen = popoverState === PopoverState.Opened;

  return (
    <div
      className={classnames(styles.selectInput, styles[theme], inputClassName, {
        [styles.focused]: isOpen,
        [styles.disabled]: isDisabled || isLoading,
      })}
    >
      {LabelComponent ? (
        <LabelComponent {...labelComponentProps} />
      ) : (
        <div className={styles.label}>{label}</div>
      )}
      {isLoading ? (
        <Spinner className={styles.spinner} size={SpinnerSize.Small} />
      ) : (
        <ToggleArrowButton className={styles.arrow} isOpen={isOpen} type={ArrowType.Dropdown} />
      )}
    </div>
  );
};

export default React.memo(SelectInput);
