import React, { ReactElement } from 'react';
import i18n from 'i18n-smart';
import RCTable from 'rc-table';
import classnames from 'classnames';

import { prepareColumns } from './services';
import { Column, DataRecord } from './types';
import styles from './styles.scss';

type Props = {
  columns: Column[];
  data?: DataRecord[];
  className?: string;
  rowClassName?: string;
  columnClassName?: string;
  emptyText?: ReactElement | string;
  sticky?: boolean;
  [key: string]: any;
};

const Table = ({
  columns,
  data,
  className,
  rowClassName,
  emptyText,
  columnClassName,
  ...props
}: Props) => {
  const preparedColumns = prepareColumns(columns, columnClassName);

  return (
    <RCTable
      rowKey="id"
      className={classnames(styles.table, className)}
      rowClassName={classnames(styles.tableRow, rowClassName)}
      tableLayout="auto"
      columns={preparedColumns}
      emptyText={
        emptyText || <div className={styles.emptyText}>{i18n.value('common.emptyText')}</div>
      }
      data={data}
      {...props}
    />
  );
};

export default React.memo(Table);
