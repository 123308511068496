import { MutableRefObject } from 'react';

import getElementTranslateXValue from './getElementTranslateXValue';

interface Params {
  bracketContainerElement: HTMLDivElement;
  bracketGridElement: HTMLDivElement;
  columnElements: NodeListOf<HTMLDivElement>;
  columnsHeights: number[];
  top: number;
  left: number;
  duration: number;
}

const animateBracketScroll = (
  {
    bracketContainerElement,
    bracketGridElement,
    columnElements,
    columnsHeights,
    top,
    left,
    duration,
  }: Params,
  scrollableRef: MutableRefObject<boolean>,
) => {
  const bracketLeftScrollStart = getElementTranslateXValue(bracketGridElement);
  const bracketTopStart = bracketContainerElement.scrollTop;
  const bracketLeftScrollDiff = left - bracketLeftScrollStart;
  const windowScrollTopDiff = top - bracketTopStart;
  const currentColumnsHeights = getColumnsHeightsByElements(columnElements);
  let animationStartTime: number | null = null;
  let requestId = 0;

  const animate = (time: number) => {
    if (animationStartTime === null) {
      animationStartTime = time;
    }

    const progress = parseFloat(((time - animationStartTime) / duration).toFixed(2));

    if (progress >= 1) {
      window.cancelAnimationFrame(requestId);
      incrementColumnsHeightsByStep(columnElements, currentColumnsHeights, columnsHeights, 1);
      bracketContainerElement.scrollTop = top;
      bracketGridElement.style.transform = `translateX(${-left}px)`;
      scrollableRef.current = true;

      return;
    }

    const step = easeInOut(progress);
    const bracketScrollLeft = bracketLeftScrollStart + bracketLeftScrollDiff * step;
    const containerScrollTop = bracketTopStart + windowScrollTopDiff * step;

    incrementColumnsHeightsByStep(columnElements, currentColumnsHeights, columnsHeights, step);
    bracketContainerElement.scrollTop = containerScrollTop;
    bracketGridElement.style.transform = `translateX(${-bracketScrollLeft}px)`;

    requestId = window.requestAnimationFrame(animate);
  };

  scrollableRef.current = false;
  requestId = window.requestAnimationFrame(animate);
};

const easeInOut = (time: number) => {
  return 0.5 * (1 - Math.cos(Math.PI * time));
};

const incrementColumnsHeightsByStep = (
  columnElements: NodeListOf<HTMLDivElement>,
  currentHeights: number[],
  updatedHeightsDiff: number[],
  step: number,
) => {
  for (let i = 0; i < columnElements.length; i++) {
    const startHeight = currentHeights[i];
    const diffHeight = updatedHeightsDiff[i] - currentHeights[i];
    const height = startHeight + diffHeight * step;

    (columnElements[i] as HTMLDivElement).style.height = `${height}px`;
  }
};

const getColumnsHeightsByElements = (columnElements: NodeListOf<HTMLDivElement>) => {
  return [...columnElements].map(({ clientHeight }) => clientHeight);
};

export default animateBracketScroll;
