import React from 'react';
import classes from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import { FeaturedGrid, FeaturedItem } from './components';

import styles from './styles.scss';

type Props = {
  className?: string;
};
// TODO localize
const FeaturedOdds = ({ className }: Props) => {
  const containerClasses = classes(styles.featuredOdds, className);

  return (
    <div className={containerClasses}>
      <div className={styles.caption}>
        <div className={styles.captionTitle}>Featured odds</div>
        <div className={styles.captionText}>
          Sponsored by
          {/*<Icon id={IconType.OlimpLogo} className={styles.captionLogo} />*/}
        </div>
      </div>

      <FeaturedGrid />

      <div className={styles.list}>
        <FeaturedItem />
        <FeaturedItem />
        <FeaturedItem />
        <FeaturedItem />
        <FeaturedItem />
        <FeaturedItem />
      </div>
    </div>
  );
};

export default FeaturedOdds;
