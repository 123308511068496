import { lazy } from 'react';

import SeasonGames from './SeasonGames';
import SeasonHistory from './SeasonHistory';
import SeasonStadiums from './SeasonStadiums';
import SeasonSummary from './SeasonSummary';
import SeasonPlayerStatistics from './SeasonPlayerStatistics';
import SeasonTeamStatistics from './SeasonTeamStatistics';
import SeasonStandings from './SeasonStandings';

import PlayerSummary from './PlayerSummary';
import PlayerGames from './PlayerGames';

import TeamSummary from './TeamSummary';
import TeamGames from './TeamGames';
import TeamPlayers from './TeamPlayers';
import TeamSquad from './TeamSquad';
import TeamStandings from './TeamStandings';

import StadiumHome from './StadiumHome';
import StadiumSummary from './StadiumSummary';

const Home = lazy(() => import('./Home'));
const Season = lazy(() => import('./Season'));
const Category = lazy(() => import('./Category'));
const Player = lazy(() => import('./Player'));
const Team = lazy(() => import('./Team'));
const Stadium = lazy(() => import('./Stadium'));
const Match = lazy(() => import('./Match'));

export {
  Home,
  Season,
  SeasonGames,
  SeasonHistory,
  SeasonStadiums,
  SeasonSummary,
  SeasonPlayerStatistics,
  SeasonTeamStatistics,
  SeasonStandings,
  Category,
  Player,
  PlayerSummary,
  PlayerGames,
  Stadium,
  StadiumSummary,
  StadiumHome,
  Team,
  TeamSummary,
  TeamGames,
  TeamPlayers,
  TeamSquad,
  TeamStandings,
  Match,
};
