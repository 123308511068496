import { isNumber, toCamelCase } from 'packages/utils/Object.utils';

import { Qualifier } from 'types';
import { ParameterSchema, BaseHappening, PropertyType, ValueType } from '../../types';
import { PARAMETER_SCHEMA_MAP, PARAMETER_ORDER_NUMBER } from '../../constants';

export const mapEventStats = (eventStats: any) => {
  const { details, timeLine } = toCamelCase(eventStats);

  return {
    details: mapDetails(details),
    timeLines: mapTimeLineHappenings(timeLine),
  };
};

const mapTimeLineHappenings = (happenings: any[]) => {
  const homeTimeLine: any[] = [];
  const awayTimeLine: any[] = [];

  for (const happening of happenings) {
    const mappedGoal = mapHappening(happening);
    const { qualifier } = mappedGoal;

    if (qualifier === Qualifier.Home) {
      homeTimeLine.push(mappedGoal);
    } else {
      awayTimeLine.push(mappedGoal);
    }
  }

  return { homeTimeLine, awayTimeLine };
};

const mapHappening = (happening): BaseHappening => {
  const { id, additionalInfo, eventType, minute, players, team } = toCamelCase(happening);

  return {
    id,
    minute,
    eventType,
    qualifier: team,
    players: players.map(toCamelCase),
    additionalInfo: toCamelCase(additionalInfo),
  };
};

const mapDetails = (details) => {
  return Object.values(
    Object.entries(details).reduce((acc, [detailsKey, detailsValue]) => {
      const parameterSchema = PARAMETER_SCHEMA_MAP[detailsKey];
      const parameterOrderNumber = PARAMETER_ORDER_NUMBER[detailsKey];

      if (parameterSchema && parameterOrderNumber !== undefined) {
        acc[parameterOrderNumber] = {
          key: parameterSchema.key,
          ...mapValues(detailsValue, parameterSchema),
        };
      }

      return acc;
    }, {}),
  );
};

const mapValues = (values: any, paramSchema: ParameterSchema) => {
  const home = formatValue(values.home, values.away, paramSchema);
  const away = formatValue(values.away, values.home, paramSchema);

  return { home, away };
};

const formatValue = (value: any, valueToCompare: any, paramSchema: ParameterSchema): any => {
  return {
    isLeader: isLeader(paramSchema.propertyType, value, valueToCompare),
    percentages: getPercentages(paramSchema.valueType, value, valueToCompare),
    formattedValue: getFormattedValue(paramSchema, value),
  };
};

const getFormattedValue = (paramSchema: ParameterSchema, value: any) => {
  return paramSchema.valueType === ValueType.Percentage ? `${value}%` : `${value}`;
};

const getPercentages = (valueType: ValueType, value: any, valueToCompare: any) => {
  switch (valueType) {
    case ValueType.Percentage:
      return value;
    case ValueType.Counter:
      return calculatePercentages(value, valueToCompare);
    case ValueType.Penalty:
      return calculatePercentages(value, valueToCompare);
  }
};

const calculatePercentages = (value: any, valueToCompare: any): number => {
  if (isNumber(value)) {
    return calculatePercentagesWithNumbers(value, valueToCompare);
  }

  return calculatePercentagesWithNumbers(
    getPenaltiesFromString(value),
    getPenaltiesFromString(valueToCompare),
  );
};

const getPenaltiesFromString = (value: string): number => {
  return Number(value.split('/')[0]);
};

const calculatePercentagesWithNumbers = (value: number, valueToCompare: number): number => {
  return (value / (value + valueToCompare)) * 100;
};

const isLeader = (propertyType: PropertyType, value: any, valueToCompare: any) => {
  return propertyType === PropertyType.Advantage ? value > valueToCompare : value < valueToCompare;
};
