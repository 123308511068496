import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';

const defaultState = [];

const reducerMap: ReducerMap<any, any> = {
  [`${actions.loadEventTimeLineSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${actions.cleanEventTimelineSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
