import React from 'react';

import { Event, EventStatus } from 'types';
import {
  LiveEventStatusLabel,
  ScheduleLabel,
  SpecialStatusLabel,
  BreakStatusLabel,
  FinishedEventStatusLabel,
} from 'packages/shared/components';

type Props = {
  className?: string;
  event: Event;
  isShort?: boolean;
};

const EventRowStatus = ({ event, className = '', isShort = false }: Props) => {
  const { status } = event;

  return (
    <div className={className}>
      {(() => {
        switch (status) {
          case EventStatus.NotStarted:
          case EventStatus.Unknown:
            return <ScheduleLabel event={event} isShort />;
          case EventStatus.FirstHalf:
          case EventStatus.SecondHalf:
          case EventStatus.Overtime:
          case EventStatus.SecondExtra:
            return <LiveEventStatusLabel event={event} isShort />;
          case EventStatus.Penalties:
            return <LiveEventStatusLabel event={event} />;
          case EventStatus.Halftime:
          case EventStatus.AwaitingExtraTime:
          case EventStatus.ExtraTimeHalfTime:
          case EventStatus.AwaitingPenalties:
            return <BreakStatusLabel status={status} isShort />;
          case EventStatus.Postponed:
          case EventStatus.Suspended:
          case EventStatus.Cancelled:
            return <SpecialStatusLabel isPopoverDisabled status={status} isShort={isShort} />;
          case EventStatus.Ended:
          case EventStatus.AfterExtraTime:
          case EventStatus.AfterPenalties:
            return <FinishedEventStatusLabel event={event} />;
        }
      })()}
    </div>
  );
};

export default EventRowStatus;
