import { PORTAL_PATTERN } from '../constants';

const getLastPortal = () => {
  const portals = document.querySelectorAll(`[id^="${PORTAL_PATTERN}_"]`);

  if (portals.length) {
    return portals[portals.length - 1];
  }

  return undefined;
};

export default getLastPortal;
