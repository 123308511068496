import React, { memo, useCallback, useEffect, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { PaginationParams } from 'packages/shared/components/Pagination/types';
import { Stadium } from 'packages/season-stadiums/types';
import { Ordering, StadiumsOrdering } from 'packages/rest/api/SeasonStadiums/types';

import StadiumsView from './views';
import connect from './connect';
import styles from './styles.scss';
import { DEFAULT_STADIUMS_ORDERING } from './constants';
import { getOrderingByType } from './services';

type Props = {
  isInitialPageLoading: boolean;
  isNewPageLoading: boolean;
  limit: number;
  stadiumsAmount: number;
  stadiums: Stadium[];
  seasonStadiumsActions: ActionCreatorsMapObject;
};

const extendStadiums = (Stadiums: typeof StadiumsView[keyof typeof StadiumsView]) =>
  memo(
    ({
      isInitialPageLoading,
      isNewPageLoading,
      limit,
      stadiumsAmount,
      stadiums,
      seasonStadiumsActions,
    }: Props) => {
      const [ordering, setOrdering] = useState<StadiumsOrdering>(DEFAULT_STADIUMS_ORDERING);
      const { seasonId } = useParams();

      useEffect(() => {
        seasonStadiumsActions.loadInitialStadiums(seasonId, ordering);

        return () => {
          seasonStadiumsActions.cleanStadiums();
        };
      }, []);

      const handleHeaderCellClick = useCallback(
        (orderingType: Ordering) => {
          const newOrdering = getOrderingByType({ orderingType, currentOrdering: ordering });

          setOrdering(newOrdering);
          seasonStadiumsActions.loadStadiumPage(seasonId, newOrdering);
        },
        [seasonId, ordering],
      );

      const handlePaginationChange = useCallback(
        (params: PaginationParams) => {
          seasonStadiumsActions.updatePagination(params);
          seasonStadiumsActions.loadStadiumPage(seasonId, ordering);
        },
        [seasonId, ordering],
      );

      return (
        <div className={styles.stadiumsPage}>
          <Stadiums
            isInitialPageLoading={isInitialPageLoading}
            onHeaderCellClick={handleHeaderCellClick}
            onPaginationChange={handlePaginationChange}
            isNewPageLoading={isNewPageLoading}
            stadiumsAmount={stadiumsAmount}
            ordering={ordering}
            stadiums={stadiums}
            limit={limit}
          />
        </div>
      );
    },
  );

export default {
  Desktop: connect(extendStadiums(StadiumsView.Desktop)),
  DesktopNarrow: connect(extendStadiums(StadiumsView.DesktopNarrow)),
  Tablet: connect(extendStadiums(StadiumsView.Tablet)),
  TabletNarrow: connect(extendStadiums(StadiumsView.TabletNarrow)),
  Mobile: connect(extendStadiums(StadiumsView.Mobile)),
};
