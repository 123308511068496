import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton variant="rectangular" height={16} width={100} />

      <div className={styles.container}>
        <div>
          <Skeleton width={40} height={40} variant="rectangular" />
          <Skeleton marginTop={8} width={40} height={16} variant="rectangular" />
        </div>
        <div>
          <Skeleton width={40} height={40} variant="rectangular" />
          <Skeleton marginTop={8} width={40} height={16} variant="rectangular" />
        </div>
        <div>
          <Skeleton width={40} height={40} variant="rectangular" />
          <Skeleton marginTop={8} width={40} height={16} variant="rectangular" />
        </div>
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
