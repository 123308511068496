import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Tabs } from 'packages/event-details/types';
import { Data } from '../types';

import {
  loadMatchDetailsStart,
  loadMatchDetailsSuccess,
  loadMatchDetailsFailure,
  cleanMatchDetailsSuccess,
} from '../actions';

const defaultState = {
  meta: {
    availableTabs: {
      [Tabs.Comments]: true,
      [Tabs.Statistics]: true,
      [Tabs.HeadToHead]: true,
      [Tabs.LineUp]: true,
      [Tabs.Standings]: true,
      [Tabs.Summary]: true,
    },
  },
};

const reducerMap: ReducerMap<Data, Data> = {
  [`${loadMatchDetailsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(
    loadMatchDetailsStart,
    loadMatchDetailsFailure,
    cleanMatchDetailsSuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
