import React, { memo } from 'react';
import classnames from 'classnames';

import { EventTeam } from 'types';
import { Separator } from 'packages/shared/components';
import { Type } from 'packages/shared/components/Separator/types';

import styles from './styles.scss';

type Props = {
  teams: [EventTeam, EventTeam];
  isLive: boolean;
  className?: string;
};
const EventScore = ({ teams, isLive, className }: Props) => {
  const [home, away] = teams;

  return (
    <div className={classnames(styles.eventScore, { [styles.live]: isLive }, className)}>
      <span>{home.score}</span>

      <Separator className={styles.delimiter} type={Type.Score} />

      <span>{away.score}</span>
    </div>
  );
};

export default memo(EventScore);
