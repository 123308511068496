import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { headToHeadActions } from '../../actions';
import { HeadToHead } from '../../types';

const defaultState: HeadToHead.Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<HeadToHead.Status, void> = {
  [`${headToHeadActions.loadHeadToHeadStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(
    headToHeadActions.loadHeadToHeadFailure,
    headToHeadActions.loadHeadToHeadSuccess,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${headToHeadActions.cleanHeadToHeadSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
