import React, { HTMLAttributes } from 'react';
import { Row, Column } from 'react-table';
import isEmpty from 'is-empty-typed/lib';
import classnames from 'classnames';

import { LoaderContainer, FlexibleTable } from 'packages/shared/components';

import styles from './styles.scss';

type Props<T extends object> = {
  rows: T[];
  setRowProps?: (row: Row<T>) => HTMLAttributes<HTMLTableRowElement>;
  columns: Column<T>[];
  isLoading?: boolean;
  useFixedPreloader?: boolean;
};

const TopTable = <T extends object>({
  rows,
  columns,
  isLoading = false,
  setRowProps,
  useFixedPreloader = false,
}: Props<T>) => {
  return (
    <LoaderContainer
      className={classnames({ [styles.fixedPreloader]: useFixedPreloader })}
      isContentVisible={!isEmpty(rows)}
      isLoading={isLoading}
    >
      <FlexibleTable<T>
        className={styles.topTable}
        setRowProps={setRowProps}
        columnClassName={styles.tableColumn}
        columns={columns}
        data={rows}
        isRowClickable
      />
    </LoaderContainer>
  );
};

export default TopTable;
