import React, { memo, useMemo } from 'react';

import { Figure } from 'packages/shared/components';
import { isEmpty } from 'packages/utils/Object.utils';
import { Team } from 'types';

import { divideTeams } from './services';
import styles from './styles.scss';

type Props = {
  teams: Team[];
};

const TeamsLogoList = ({ teams }: Props) => {
  const { visibleTeams, hiddenTeamsCount } = useMemo(() => divideTeams(teams), [teams]);

  return (
    <div className={styles.teamList}>
      {visibleTeams.map(({ id, logo }) => (
        <div className={styles.teamWrapper} key={id}>
          <Figure src={logo || '/images/default_team_logo.png'} className={styles.logo} />
        </div>
      ))}
      {!isEmpty(hiddenTeamsCount) && <div className={styles.teamsCount}>{hiddenTeamsCount}</div>}
    </div>
  );
};

export default memo(TeamsLogoList);
