import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  isLoading: boolean;
};

const Header = ({ isLoading }: Props) => {
  return (
    <div className={styles.header}>
      {isLoading ? (
        <Skeleton height={16} width={80} variant="rectangular" />
      ) : (
        i18n.value('season.history.title')
      )}
    </div>
  );
};

export default memo(Header);
