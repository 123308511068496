import { commonSelectors } from '../../selectors';
import { EventsStatus, StorageEventGroup } from '../../types';
import { EVENT_STORAGE_GROUP_KEYS } from '../../constants';

export const getFinishedEventsStatus = (state): EventsStatus => {
  return commonSelectors.getEventsStatusByGroup(
    state,
    EVENT_STORAGE_GROUP_KEYS[StorageEventGroup.Finished],
  );
};

export const getIsFinishedEventsLoading = (state): boolean => {
  return getFinishedEventsStatus(state).isLoading;
};

export const getHasNextFinishedEvents = (state): boolean => {
  return getFinishedEventsStatus(state).hasNext;
};

export const getFinishedEventsOffset = (state): number => {
  return getFinishedEventsStatus(state).offset;
};
