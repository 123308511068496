import React, { useEffect, useMemo, useRef } from 'react';

import { TournamentTable, SeasonTableStatistics } from 'packages/event-standings/types';

import { SeasonTable } from './components';
import { scrollToTable } from './services';

type Props = {
  tournamentTables: TournamentTable<SeasonTableStatistics>[];
  activeUniqueTournamentId?: number | string;
  activeSeasonId?: number | string;
  activeTeamIds?: number[];
  teamIdToScroll?: number;
};

const SeasonTables = ({
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
  tournamentTables,
  teamIdToScroll,
}: Props) => {
  const tableRef = useRef<HTMLDivElement>(null);

  const activeTableIndex = useMemo(() => {
    return tournamentTables.find(({ rows }) => rows.some(({ team }) => team.id === teamIdToScroll))
      ?.id;
  }, [teamIdToScroll, tournamentTables]);

  useEffect(() => {
    if (tableRef.current) {
      scrollToTable({ tableRef });
    }
  }, []);

  return (
    <>
      {tournamentTables.map(({ id, groupName, rows, rule, promotions, teamComments }, index) => (
        <SeasonTable
          key={id}
          rule={rule}
          promotions={promotions}
          teamComments={teamComments}
          isRulesBlockDisplayed={index === tournamentTables.length - 1}
          activeTeamIds={activeTeamIds}
          activeUniqueTournamentId={activeUniqueTournamentId}
          activeSeasonId={activeSeasonId}
          groupName={groupName}
          rows={rows}
          tableRef={activeTableIndex === id ? tableRef : undefined}
        />
      ))}
    </>
  );
};

export default React.memo(SeasonTables);
