import * as eventStatsActions from './actions';
import * as eventStatsThunks from './thunks';
import * as eventStatsConstants from './constants';
import * as eventStatsSelectors from './selectors';
import * as eventStatsServices from './services';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  eventStatsActions,
  eventStatsThunks,
  eventStatsConstants,
  eventStatsSelectors,
  eventStatsServices,
  createReducer,
};
