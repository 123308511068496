import { OrderType } from 'types';

const stringifyOrderingParam = <OrderingKey extends string>(
  ordering: Partial<Record<OrderingKey, OrderType>>,
): string => {
  return Object.entries(ordering)
    .reduce<string[]>((acc, [currentKey, currentValue]) => {
      return [...acc, `${currentValue === OrderType.Descending ? '-' : ''}${currentKey}`];
    }, [])
    .join(',');
};

export default stringifyOrderingParam;
