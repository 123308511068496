import React, { memo, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Form } from 'packages/shared/components/MatchHistory/types';
import { MatchHistory } from 'packages/shared/components';
import { SearchQuery } from 'router/types';

type Props = {
  form: Form[];
};

const FormCell = ({ form }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleMatchMouseUp = useCallback(
    (eventCardId: string) => {
      setSearchParams({ [SearchQuery.EventCardId]: eventCardId });
    },
    [searchParams],
  );

  return <MatchHistory onMatchMouseUp={handleMatchMouseUp} form={form} />;
};

export default memo(FormCell);
