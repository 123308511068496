import { keyValueDatabase } from 'packages/storage';

import { TimeZone } from 'types';

import * as actions from '../actions';
import { SettingsStorageKey } from '../types';

export const setTimeZone = (timeZone: TimeZone) => async (dispatch) => {
  try {
    await keyValueDatabase.set(
      SettingsStorageKey.TimeZone,
      timeZone,
      keyValueDatabase.tables.userSettings,
    );

    dispatch(actions.setTimeZone(timeZone));
  } catch (e) {
    dispatch(actions.failure(e));
  }
};
