import React, { useEffect, useMemo } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { getGroupedEvents } from 'packages/events/services';
import { Event, Tournament } from 'types';

import EventList from '../../../EventList';
import connect from './connect';

type Props = {
  favoriteEventsActions: ActionCreatorsMapObject;
  favoriteTournaments: Tournament[];
  handleEventClick: () => void;
  handleTournamentClick: Function;
  activeSportId: number;
  activeEventId: number;
  events: Event[];
  isLoading: boolean;
  isCompact?: boolean;
  useModalOnCategoryClick?: boolean;
};

const MyGames = ({
  favoriteTournaments,
  handleEventClick,
  handleTournamentClick,
  favoriteEventsActions,
  activeSportId,
  activeEventId,
  isLoading,
  isCompact = false,
  useModalOnCategoryClick = false,
  events,
}: Props) => {
  useEffect(() => {
    const unsubscribe = favoriteEventsActions.subscribeForFavoriteEventsUpdates();

    return unsubscribe;
  }, []);

  const groupedEvents = useMemo(() => getGroupedEvents(events, favoriteTournaments), [
    events,
    favoriteTournaments,
  ]);

  return (
    <EventList
      tournaments={groupedEvents}
      handleEventClick={handleEventClick}
      handleTournamentClick={handleTournamentClick}
      activeSportId={activeSportId}
      activeEventId={activeEventId}
      scrollContainerIdentifier="my-games"
      isCompact={isCompact}
      isLoading={isLoading}
      useModalOnCategoryClick={useModalOnCategoryClick}
    />
  );
};

export default connect(MyGames);
