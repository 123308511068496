import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { TeamLabel } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Team } from 'types';

import styles from './styles.scss';

type Props = {
  team: Team;
  isNameHidden?: boolean;
};

const TeamCell = ({ team, isNameHidden = false }: Props) => {
  const { appNavigate } = useNavigation();
  const { tournamentId = '', seasonId = '' } = useParams();

  const handleTeamClick = useCallback(
    (team: Team) => {
      appNavigate(DesktopPage.FootballTeamSummaryDescription, {
        teamId: team.id,
        seasonId,
        uniqueTournamentId: tournamentId,
      });
    },
    [tournamentId, seasonId],
  );

  return (
    <div className={styles.teamCell}>
      <TeamLabel
        onNameClick={handleTeamClick}
        onLogoClick={handleTeamClick}
        team={team}
        isLogoFirst
        isNameHidden={isNameHidden}
      />
    </div>
  );
};

export default TeamCell;
