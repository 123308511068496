import { Team } from 'types';

import { Interfaces } from '../../types';
import getPath from './getPath';

export const getData = (state): Interfaces.Statistics.Data => {
  return getPath(state).data;
};

export const getGoalStatistics = (state): Interfaces.Statistics.GoalStatistics | undefined => {
  return getData(state)?.goalStatistics;
};

export const getAttendance = (state): Interfaces.Statistics.Attendance | undefined => {
  return getData(state)?.attendance;
};

export const getWinLose = (state): Interfaces.Statistics.WinLose | undefined => {
  return getData(state)?.winLose;
};

export const getAverage = (state): Interfaces.Statistics.Average | undefined => {
  return getData(state)?.average;
};

export const getTopScorers = (state): Interfaces.Statistics.TopScorer[] | undefined => {
  return getData(state)?.topScorers;
};

export const getHomeTeams = (state): Team[] | undefined => {
  return getData(state)?.homeTeams;
};
