import { sessionStorage } from 'packages/storage';

import { loadActiveTournamentSeasonsFromSessionStorageSuccess } from '../actions';
import { ACTIVE_TOURNAMENT_SEASON_SESSION_KEY } from '../constants';

const loadActiveTournamentSeasonsFromSessionStorage = () => (dispatch) => {
  const activeTeamTournamentSeasonMap =
    sessionStorage.get(ACTIVE_TOURNAMENT_SEASON_SESSION_KEY) || {};

  dispatch(loadActiveTournamentSeasonsFromSessionStorageSuccess(activeTeamTournamentSeasonMap));
};

export default loadActiveTournamentSeasonsFromSessionStorage;
