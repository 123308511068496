import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { stagesActions } from '../../actions';
import { Status } from '../../types';

const defaultState: Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${stagesActions.loadStagesStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(stagesActions.loadStagesFailure, stagesActions.loadStagesSuccess)}`]: () => {
    return { isLoading: false };
  },
  [`${stagesActions.cleanStagesSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
