import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavigateOptions } from 'react-router';

import { MobilePathParams, MobilePage, NavigationRoutes, DesktopPathParams } from 'router/types';
import { teamActiveTournamentSeasonSelectors } from 'packages/team-active-tournament-season';

const useDesktopNavigation = () => {
  const teamActiveTournamentSeasons = useSelector(teamActiveTournamentSeasonSelectors.getData);
  const navigate = useNavigate();

  const appNavigate = <T extends keyof MobilePathParams>(
    page: T,
    params: MobilePathParams[T],
    query?: string,
    options?: NavigateOptions,
  ) => {
    switch (page) {
      case MobilePage.Football: {
        navigate('/football', options);
        break;
      }
      case MobilePage.Settings: {
        navigate(NavigationRoutes.Settings, options);
        break;
      }
      case MobilePage.FootballSeason: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as MobilePathParams[MobilePage.FootballSeason];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}`,
            search: query,
          },
          options,
        );
        break;
      }
      case MobilePage.FootballSeasonGames: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as MobilePathParams[MobilePage.FootballSeasonGames];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}/games`,
            search: query,
          },
          options,
        );
        break;
      }
      case MobilePage.FootballSeasonStandings: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as MobilePathParams[MobilePage.FootballSeasonGames];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}/standings`,
            search: query,
          },
          options,
        );
        break;
      }
      case MobilePage.FootballSeasonPlayerStatistics: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as MobilePathParams[MobilePage.FootballSeasonPlayerStatistics];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}/player-statistics`,
            search: query,
          },
          options,
        );
        break;
      }
      case MobilePage.FootballSeasonTeamStatistics: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as MobilePathParams[MobilePage.FootballSeasonTeamStatistics];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}/team-statistics`,
            search: query,
          },
          options,
        );
        break;
      }
      case MobilePage.FootballSeasonStadiums: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as MobilePathParams[MobilePage.FootballSeasonStadiums];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}/stadiums`,
            search: query,
          },
          options,
        );
        break;
      }
      case MobilePage.FootballTeam: {
        const { teamId } = params as DesktopPathParams[MobilePage.FootballTeam];

        if (teamActiveTournamentSeasons[teamId]) {
          const { tournamentId, seasonId } = teamActiveTournamentSeasons[teamId];

          navigate(
            {
              pathname: `/football/team/${teamId}/summary/unique-tournament/${tournamentId}/season/${seasonId}`,
              search: query,
            },
            options,
          );
        } else {
          navigate({ pathname: `/football/team/${teamId}`, search: query }, options);
        }

        break;
      }
      case MobilePage.FootballTeamSummaryDescription: {
        const {
          uniqueTournamentId,
          seasonId,
          teamId,
        } = params as MobilePathParams[MobilePage.FootballTeamSummaryDescription];

        navigate(
          {
            pathname: `/football/team/${teamId}/summary/unique-tournament/${uniqueTournamentId}/season/${seasonId}`,
            search: query,
          },
          options,
        );
        break;
      }
      case MobilePage.FootballTeamGames: {
        const { teamId } = params as MobilePathParams[MobilePage.FootballTeamGames];

        navigate({ pathname: `/football/team/${teamId}/games`, search: query }, options);
        break;
      }
      case MobilePage.FootballTeamSquad: {
        const { teamId } = params as MobilePathParams[MobilePage.FootballTeamSquad];

        navigate({ pathname: `/football/team/${teamId}/squad`, search: query }, options);
        break;
      }
      case MobilePage.FootballTeamStandings: {
        const {
          uniqueTournamentId,
          seasonId,
          teamId,
        } = params as MobilePathParams[MobilePage.FootballTeamStandings];

        navigate(
          {
            pathname: `/football/team/${teamId}/standings/unique-tournament/${uniqueTournamentId}/season/${seasonId}`,
            search: query,
          },
          options,
        );
        break;
      }
      case MobilePage.FootballTeamPlayerStatistics: {
        const {
          uniqueTournamentId,
          seasonId,
          teamId,
        } = params as MobilePathParams[MobilePage.FootballTeamPlayerStatistics];

        navigate(
          {
            // eslint-disable-next-line max-len
            pathname: `/football/team/${teamId}/player-statistics/unique-tournament/${uniqueTournamentId}/season/${seasonId}`,
            search: query,
          },
          options,
        );
        break;
      }
      case MobilePage.FootballCategory: {
        const { categoryId } = params as MobilePathParams[MobilePage.FootballCategory];

        navigate({ pathname: `/football/category/${categoryId}`, search: query }, options);
        break;
      }
      case MobilePage.FootballPlayer: {
        const { playerId } = params as MobilePathParams[MobilePage.FootballPlayer];

        navigate({ pathname: `/football/player/${playerId}`, search: query }, options);
        break;
      }
      case MobilePage.FootballPlayerGames: {
        const { playerId } = params as MobilePathParams[MobilePage.FootballPlayerGames];

        navigate({ pathname: `/football/player/${playerId}/games`, search: query }, options);
        break;
      }
      case MobilePage.FootballPlayerSummary: {
        const { playerId } = params as MobilePathParams[MobilePage.FootballPlayerSummary];

        navigate({ pathname: `/football/player/${playerId}/summary`, search: query }, options);
        break;
      }
      case MobilePage.FootballStadium: {
        const { stadiumId } = params as MobilePathParams[MobilePage.FootballStadium];

        navigate({ pathname: `/football/stadium/${stadiumId}`, search: query }, options);
        break;
      }
      case MobilePage.FootballStadiumSummary: {
        const {
          stadiumId,
          uniqueTournamentId,
          seasonId,
        } = params as MobilePathParams[MobilePage.FootballStadiumSummary];

        navigate(
          {
            pathname: `/football/stadium/${stadiumId}/unique-tournament/${uniqueTournamentId}/season/${seasonId}`,
            search: query,
          },
          options,
        );
        break;
      }

      case MobilePage.FootballMatch: {
        const { eventId } = params as MobilePathParams[MobilePage.FootballMatch];

        navigate(
          {
            pathname: `/football/match/${eventId}`,
            search: query,
          },
          options,
        );
        break;
      }
    }
  };

  const deltaNavigate = (delta: number) => {
    navigate(delta);
  };

  return { appNavigate, deltaNavigate };
};

export default useDesktopNavigation;
