import React, { memo, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ActionCreatorsMapObject } from 'redux';

import { Team, TimeZone } from 'types';
import { Interfaces as StadiumSummaryInterfaces } from 'packages/stadium-summary/types';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { Data as StadiumDetailsData } from 'packages/stadium-details/types';

import SummaryView from './views';
import connect from './connect';

type Props = {
  actualGamesActions: ActionCreatorsMapObject;
  commonActions: ActionCreatorsMapObject;
  statisticsActions: ActionCreatorsMapObject;
  timeZone: TimeZone;
  isStatisticsLoading: boolean;
  isDetailsLoading: boolean;
  tournamentSeasons: SeasonsExtendedTournament[];
  isTournamentsLoading: boolean;
  goalStatistics?: StadiumSummaryInterfaces.Statistics.GoalStatistics;
  attendance?: StadiumSummaryInterfaces.Statistics.Attendance;
  average?: StadiumSummaryInterfaces.Statistics.Average;
  topScorers?: StadiumSummaryInterfaces.Statistics.TopScorer[];
  winLose?: StadiumSummaryInterfaces.Statistics.WinLose;
  homeTeams: Team[];
  details: StadiumDetailsData;
};

const extendedSummary = (Summary: typeof SummaryView[keyof typeof SummaryView]) =>
  memo(
    ({
      commonActions,
      actualGamesActions,
      statisticsActions,
      timeZone,
      isStatisticsLoading,
      goalStatistics,
      attendance,
      average,
      topScorers,
      winLose,
      homeTeams,
      details,
      isDetailsLoading,
      tournamentSeasons,
      isTournamentsLoading,
    }: Props) => {
      const { stadiumId, seasonId } = useParams();

      useEffect(() => {
        return () => {
          commonActions.cleanSummary();
        };
      }, []);

      useEffect(() => {
        actualGamesActions.loadActualGames({ stadiumId, seasonId });
        statisticsActions.loadStatistics({ stadiumId, seasonId });

        return () => {
          actualGamesActions.cleanActualGames();
          statisticsActions.cleanStatistics();
        };
      }, [seasonId, stadiumId, timeZone]);

      return (
        <Summary
          isDetailsLoading={isDetailsLoading}
          homeTeams={homeTeams}
          goalStatistics={goalStatistics}
          attendance={attendance}
          average={average}
          topScorers={topScorers}
          winLose={winLose}
          isStatisticsLoading={isStatisticsLoading}
          tournamentSeasons={tournamentSeasons}
          isTournamentsLoading={isTournamentsLoading}
          details={details}
        />
      );
    },
  );

export default {
  Desktop: connect(extendedSummary(SummaryView.Desktop)),
  DesktopNarrow: connect(extendedSummary(SummaryView.DesktopNarrow)),
  Tablet: connect(extendedSummary(SummaryView.Tablet)),
  TabletNarrow: connect(extendedSummary(SummaryView.TabletNarrow)),
  Mobile: connect(extendedSummary(SummaryView.Mobile)),
};
