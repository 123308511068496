import { SocketConfig, SocketOptions, StorageDataType } from 'packages/socket/types';
import { Event, LiveEventsUpdateEventType } from 'packages/events/types';
import { settingsSelectors } from 'packages/settings';
import { Events } from 'packages/rest';

import { subscribe } from './eventsSocket';
import { emitLiveEventsUpdateEvent, mapEvents } from '../../services';
import getUpdatedRemovedEvents from './getUpdatedRemovedEvents';

interface Params {
  categoryId?: number;
}

const subscribeForLiveEvents = ({ categoryId }: Params = {}) => (dispatch, getState) => {
  const state = getState();
  const activeSportId = settingsSelectors.getActiveSportId(state);

  const fetchData = async () => {
    const { data } = await Events.loadLiveEvents({ activeSportId, categoryId });

    return mapEvents(data.results);
  };

  const socketConfig: SocketConfig<Event> = {
    fetchData,
    dataReducerFactoryConfig: {
      storageDataType: StorageDataType.Map,
      handleNewData: ({ data }) => {
        emitLiveEventsUpdateEvent(LiveEventsUpdateEventType.Inserted, data);
      },
      handleUpdatedData: ({ data }) => {
        emitLiveEventsUpdateEvent(LiveEventsUpdateEventType.Updated, data);
      },
      handleRemovedData: async ({ data }) => {
        const updatedRemovedEvents = await dispatch(getUpdatedRemovedEvents(data));

        emitLiveEventsUpdateEvent(LiveEventsUpdateEventType.Removed, updatedRemovedEvents);
      },
    },
  };

  const socketOptions: SocketOptions = {
    fetchByInitialize: true,
  };

  return dispatch(subscribe(socketConfig, socketOptions));
};

export default subscribeForLiveEvents;
