import React, { memo, useCallback, useMemo, useRef } from 'react';
import i18n from 'i18n-smart';
import { useSearchParams } from 'react-router-dom';

import { Event as EventComponent } from 'packages/events-ui';
import { TournamentView } from 'packages/events/types';
import { Event } from 'types';
import { SearchQuery } from 'router/types';

import { getEvents } from '../../../../services';
import { EventListSkeleton } from '../../../../components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  tournaments: TournamentView[];
  isLoading: boolean;
};

const ActualGamesBase = ({ tournaments, isLoading = false }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const containerRef = useRef<HTMLDivElement>(null);

  const events = useMemo(() => {
    return getEvents(tournaments);
  }, [tournaments]);

  const handleEventClick = useCallback(
    (eventId: number) => {
      setSearchParams({ [SearchQuery.EventCardId]: eventId.toString() });
    },
    [searchParams],
  );

  if (isLoading) {
    return <EventListSkeleton />;
  }

  return (
    <div ref={containerRef} className={styles.actualGamesBlock}>
      <div className={styles.header}>
        <div className={styles.title}> {i18n.value(`stadium.actualGames.title`)}</div>
      </div>
      <div className={styles.gamesList}>
        {events.map((event) => (
          <EventComponent.Desktop
            key={event.id}
            isDateVisible
            isRoundVisible
            isFormPopoverDisabled
            isAttendanceShown
            onClick={handleEventClick}
            event={event as Event}
          />
        ))}
      </div>
    </div>
  );
};

export default connect(memo(ActualGamesBase));
