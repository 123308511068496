import { Players } from 'packages/rest';

import { filterTransferHistory, mapPlayerTeams, mapTransferHistory } from '../../services';
import { teamsActions } from '../../actions';

interface Params {
  playerId: number;
}

const loadTeams = ({ playerId }: Params) => async (dispatch) => {
  try {
    dispatch(teamsActions.loadTeamsStart());

    const { data } = await Players.loadTeams({ playerId });

    const teams = mapPlayerTeams(data.results);
    const history = mapTransferHistory(data.results);
    const transferHistory = filterTransferHistory(history);

    dispatch(teamsActions.loadTeamsSuccess({ transferHistory, teams }));
  } catch (e) {
    dispatch(teamsActions.loadTeamsFailure(e));
  }
};

export default loadTeams;
