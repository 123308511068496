import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SeasonSummary as SeasonSummaryContent } from 'packages/season-summary-ui';
import { Grid } from 'modules/shared/layouts';
import { EventDetails } from 'packages/event-details-ui';
import { TopBoard } from 'packages/season-top-board-ui';
import { Newcomers } from 'packages/season-newcomers-ui';
import { SearchQuery } from 'router/types';
import { GridColumnId } from 'types';

import styles from './styles.scss';

const SeasonSummary = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  return (
    <Grid.DoubleColumn
      isSecondaryOpen={false}
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
    >
      <div key={GridColumnId.LeftBar} className={styles.leftBar}>
        <SeasonSummaryContent.Desktop />
      </div>
      <div key={GridColumnId.Primary} className={styles.sideBar}>
        {eventId ? (
          <EventDetails.Desktop eventId={eventId} />
        ) : (
          <>
            <TopBoard.Desktop />
            <Newcomers.Desktop />
          </>
        )}
      </div>
    </Grid.DoubleColumn>
  );
};

export default memo(SeasonSummary);
