import { toCamelCase } from 'packages/utils/Object.utils';

import { Data } from '../types';

const mapDetails = (details: Record<string, any>): Data => {
  const {
    id,
    name,
    mapCoordinates,
    logo,
    countryName,
    constructionYear,
    city,
    capacity,
    pitchSize,
  } = toCamelCase(details, true);

  const [lat, lng] = mapCoordinates.split(',');

  return {
    id,
    name,
    city,
    constructionYear,
    mapCoordinates: {
      lat: parseFloat(lat),
      lng: parseFloat(lng),
    },
    countryName,
    logo,
    capacity: capacity || undefined,
    pitchSize: pitchSize || undefined,
  };
};

export default mapDetails;
