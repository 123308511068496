import { createElement } from 'react';

import { BaseHappening, GoalHappening } from 'packages/event-time-line/types';
import { SummaryHappeningType } from 'packages/events/types';

import {
  getChipElement,
  getGoalType,
  getHappeningComponentConfig,
  getMinuteChipValue,
  shouldInjuryTimeBeDisplayed,
} from '../../../services';
import { HappeningElementsGetterOptions, Theme } from '../../../../../types';

export const getSinglePositionHappeningElementsByPeriodName = (
  happening: BaseHappening,
  isAwayHappening: boolean,
  theme?: Theme,
  useDefaultComponent?: boolean,
) => {
  const { eventType, injuryTime, minute } = happening;
  const additionalProps = {
    happeningComponentProps: { theme },
    chipComponentProps: {
      theme,
      injuryTime,
      isInjuryTimeDisplayed: shouldInjuryTimeBeDisplayed(minute),
    },
  };

  switch (eventType) {
    case SummaryHappeningType.SCORE_CHANGE:
      return getGoalHappeningElements(happening as GoalHappening, isAwayHappening, additionalProps);
    default:
      return getDefaultPeriodHappeningElements(
        happening,
        isAwayHappening,
        additionalProps,
        useDefaultComponent,
      );
  }
};

const getGoalHappeningElements = (
  happening: GoalHappening,
  isAwayHappening: boolean,
  { happeningComponentProps, chipComponentProps }: HappeningElementsGetterOptions,
) => {
  happeningComponentProps = { type: getGoalType(happening), ...happeningComponentProps };
  chipComponentProps = { isBold: true, ...chipComponentProps };

  return getDefaultPeriodHappeningElements(happening, isAwayHappening, {
    happeningComponentProps,
    chipComponentProps,
  });
};

const getDefaultPeriodHappeningElements = (
  happening: BaseHappening,
  isAwayHappening: boolean,
  { happeningComponentProps, chipComponentProps }: HappeningElementsGetterOptions = {
    happeningComponentProps: {},
    chipComponentProps: {},
  },
  useDefaultComponent?: boolean,
) => {
  const { minute } = happening;

  const chipValue = getMinuteChipValue(minute);
  const chipElement = getChipElement(chipValue, chipComponentProps);
  const happeningElement = getHappeningElements(
    happening,
    isAwayHappening,
    happeningComponentProps,
    useDefaultComponent,
  );

  return { happeningElement, chipElement };
};

const getHappeningElements = (
  happening: BaseHappening,
  isAwayHappening: boolean,
  customProps: Record<any, any> = {},
  useDefaultComponent?: boolean,
) => {
  const happeningConfig = getHappeningComponentConfig({
    happening,
    isReversed: isAwayHappening,
    customProps,
    isSubstitutionByPlayerDisplayed: true,
    isAssistsDisplayed: true,
    useDefaultComponent,
  });

  if (happeningConfig) {
    const { component, props } = happeningConfig;

    return createElement(component, props);
  }

  return null;
};

export default getSinglePositionHappeningElementsByPeriodName;
