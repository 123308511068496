import React from 'react';
import classnames from 'classnames';

import { SeasonTablePromotion } from 'packages/event-standings/types';
import { PROMOTION_COLOR_GROUPS } from 'appConstants';

import styles from './styles.scss';

type Props = {
  promotions: SeasonTablePromotion[];
};

const PromotionBlock = ({ promotions = [] }: Props) => {
  return (
    <div className={styles.rulesPromotionBlock}>
      {promotions.map(({ name, id, cssClass }) => (
        <div key={id} className={styles.rulesPromotionItem}>
          <div
            className={classnames(
              styles[`promotion${PROMOTION_COLOR_GROUPS[cssClass]}`],
              styles.rulesPromotionItemColorIndex,
            )}
          />
          <span className={styles.rulesPromotionItemTitle}>{name}</span>
        </div>
      ))}
    </div>
  );
};

export default React.memo(PromotionBlock);
