import * as eventHeadToHeadActions from './actions';
import * as eventHeadToHeadThunks from './thunks';
import * as eventHeadToHeadConstants from './constants';
import * as eventHeadToHeadSelectors from './selectors';
import * as eventHeadToHeadServices from './services';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  eventHeadToHeadActions,
  eventHeadToHeadThunks,
  eventHeadToHeadConstants,
  eventHeadToHeadSelectors,
  eventHeadToHeadServices,
  createReducer,
};
