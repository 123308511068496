import React, { memo, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { TimeZone } from 'types';
import { useNonInitialEffect } from 'packages/hooks';

import { ACTUAL_GAMES_FINISHED_COUNT, SCHEDULED_GAMES_FINISHED_COUNT } from './constants';
import { ActualGames, Details, DetailsSelector, TournamentChipList } from './components';
import styles from './styles.scss';

type Props = {
  actualGamesActions: ActionCreatorsMapObject;
  commonActions: ActionCreatorsMapObject;
  timeZone: TimeZone;
};

const TeamSummary = ({ actualGamesActions, commonActions, timeZone }: Props) => {
  const { teamId = '', seasonId } = useParams();

  useNonInitialEffect(() => {
    window.scrollTo(0, 0);
  }, [teamId]);

  useEffect(() => {
    actualGamesActions.loadActualGames({
      teamId,
      finishedCount: ACTUAL_GAMES_FINISHED_COUNT,
      scheduledCount: SCHEDULED_GAMES_FINISHED_COUNT,
    });

    return commonActions.cleanSummary;
  }, [timeZone.value, teamId]);

  return (
    <>
      <ActualGames />
      <DetailsSelector className={styles.detailsSelector} />
      {seasonId === undefined ? (
        <TournamentChipList className={styles.tournamentChipList} />
      ) : (
        <Details className={styles.details} />
      )}
    </>
  );
};

export default memo(TeamSummary);
