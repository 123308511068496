import React from 'react';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';

import { ICON_IDS } from './constants';
import { ArrowType } from './types';
import styles from './styles.scss';

type Props = {
  onClick?: (event) => void;
  isOpen: boolean;
  className?: string;
  type?: ArrowType;
};

const ToggleArrowButton = ({ onClick, isOpen, className, type = ArrowType.Default }: Props) => {
  const containerClasses = classnames(styles.arrow, { [styles.arrowDown]: !isOpen }, className);
  const iconId = ICON_IDS[type];

  return <Icon id={iconId} onClick={onClick} className={containerClasses} />;
};

export default React.memo(ToggleArrowButton);
