import instance from '../../../axios';
import { TeamEventsParams } from '../types';

export const loadTeamEvents = ({
  seasons,
  tournament,
  period,
  teamId,
  limit,
  offset,
}: TeamEventsParams) => {
  const seasonParam = seasons?.map((season) => season.id).join(',');
  const [scheduledAfter, scheduledBefore] = period;

  return instance({
    method: 'get',
    url: `/teams/${teamId}/sport_events/`,
    params: {
      limit,
      offset,
      season: seasonParam,
      uq: tournament?.id,
      scheduled_before: scheduledBefore,
      scheduled_after: scheduledAfter,
    },
  });
};
