import { handleActions, ReducerMap } from 'redux-actions';

import { mobileFilterStagesActions } from '../../actions';
import { MobileFilterStages } from '../../types';

const defaultState: MobileFilterStages.Data = {
  stages: [],
  defaultFilterTabId: undefined,
  defaultStageTabId: undefined,
};

const reducerMap: ReducerMap<MobileFilterStages.Data, MobileFilterStages.Data> = {
  [`${mobileFilterStagesActions.loadStagesSuccess}`]: (state, { payload }) => {
    const { stages } = payload;

    return { ...state, stages };
  },
  [`${mobileFilterStagesActions.resetStagesSuccess}`]: (state, { payload }) => {
    const { stages, defaultStageTabId } = payload;

    return { ...state, stages, defaultStageTabId };
  },
  [`${mobileFilterStagesActions.setDefaultFilter}`]: (state, { payload }) => {
    const { defaultFilterTabId, defaultStageTabId } = payload;

    return { ...state, defaultFilterTabId, defaultStageTabId };
  },
  [`${mobileFilterStagesActions.cleanMobileFilterSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
