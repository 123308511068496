const INTEGER_VALIDATION_REGEXP = /[+,-]?[0-9]+/gm;

const validateIntegerRange = (firstValue: string, secondValue: string) => {
  const validatedFirstValue = validateIntegerNumber(firstValue);
  const validatedSecondValue = validateIntegerNumber(secondValue);

  if (validatedFirstValue !== '' && validatedSecondValue !== '') {
    const firstParsedValue = validatedFirstValue ? parseInt(validatedFirstValue) : 0;
    const secondParsedValue = validatedSecondValue ? parseInt(validatedSecondValue) : 0;

    if (firstParsedValue >= secondParsedValue) {
      return [`${validatedSecondValue}`, `${validatedSecondValue}`];
    }
  }

  return [`${validatedFirstValue}`, `${validatedSecondValue}`];
};

const validateIntegerNumber = (integerInput: string): string => {
  const [validatedNumber] = integerInput.match(INTEGER_VALIDATION_REGEXP) || [''];

  return validatedNumber;
};

export default validateIntegerRange;
