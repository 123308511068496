const INITIAL_VOLUME = 0.01;

interface Params {
  homeWins: number;
  awayWins: number;
  draws: number;
}

interface LineVolumes {
  homeWins: number;
  awayWins: number;
  draws: number;
}

const getLineVolumes = ({ homeWins, awayWins, draws }: Params): LineVolumes => {
  const generalValue = INITIAL_VOLUME * 3 + homeWins + awayWins + draws;

  return {
    homeWins: parseFloat((((homeWins + INITIAL_VOLUME) / generalValue) * 100).toFixed(1)),
    awayWins: parseFloat((((awayWins + INITIAL_VOLUME) / generalValue) * 100).toFixed(1)),
    draws: parseFloat((((draws + INITIAL_VOLUME) / generalValue) * 100).toFixed(1)),
  };
};

export default getLineVolumes;
