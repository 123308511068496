import React, { ReactNode, Fragment } from 'react';

import { Header, Footer } from 'modules/shared/components';
import { LayoutKey } from 'types';

import Router from './router';

export default (): ReactNode[] => {
  return [
    <Header.Tablet key={LayoutKey.Top} />,
    <Fragment key={LayoutKey.Main}>
      <Router />
    </Fragment>,
    <Footer.Tablet key={LayoutKey.Bottom} />,
  ];
};
