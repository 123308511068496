import React, { memo, useCallback, useMemo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { Select, SelectItemComponent, SelectLabelComponent } from 'packages/shared/components';
import { SelectItem, SelectSize, SelectTheme } from 'packages/shared/components/Select/types';
import { Tournament } from 'packages/player-events/types';

import { getTournamentSelectItem, getTournamentSelectItems } from './services';

type Props = {
  tournaments: Tournament[];
  selectedTournament?: Tournament;
  isDisabled?: boolean;
  isLoading?: boolean;
  onChange: (item?: Tournament) => void;
  className?: string;
};

const TournamentSelect = ({
  isDisabled = false,
  isLoading = false,
  tournaments,
  selectedTournament,
  onChange,
  className,
}: Props) => {
  const tournamentSelectItems = useMemo(() => {
    return getTournamentSelectItems(tournaments);
  }, [tournaments]);
  const selectedTournamentItem = useMemo(() => {
    return getTournamentSelectItem(selectedTournament);
  }, [selectedTournament]);

  const handleTournamentChange = useCallback(
    (item: SelectItem) => {
      const selectedTournament = ArrayUtils.findBy(tournaments, 'id', item.id);

      onChange(selectedTournament);
    },
    [tournaments, onChange],
  );

  return (
    <Select
      isLoading={isLoading}
      isDisabled={isDisabled}
      selectedItem={selectedTournamentItem}
      labelComponentProps={selectedTournamentItem.componentProps}
      labelComponent={SelectLabelComponent.Tournament}
      itemComponent={SelectItemComponent.Tournament}
      items={tournamentSelectItems}
      onChange={handleTournamentChange}
      size={SelectSize.Lg}
      theme={SelectTheme.Gray}
      className={className}
    />
  );
};

export default memo(TournamentSelect);
