import React, { memo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { EventLoadingSkeleton } from 'packages/events-ui/components';
import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  eventCount: number;
  isCompact?: boolean;
};

const ActualGamesLoadingSkeleton = ({ eventCount, isCompact = false }: Props) => {
  const events = ArrayUtils.getArrayFromNumber(eventCount);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Skeleton height={16} width={130} variant="rectangular" />
        <Skeleton height={16} width={90} variant="rectangular" />
      </div>
      {events.map((event, index) => (
        <EventLoadingSkeleton
          isMatchResultVisible
          isTournamentVisible={index === 0}
          isCompact={isCompact}
          key={event}
        />
      ))}
    </div>
  );
};

export default memo(ActualGamesLoadingSkeleton);
