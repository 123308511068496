import React, { memo, useCallback, useMemo } from 'react';
import i18n from 'i18n-smart';

import { ArrayUtils } from 'packages/utils';
import { Season } from 'packages/player-events/types';
import { ExtendedMultipleSelect } from 'packages/shared/components/Select';

import { getTeamSelectItems } from './services';
import { SeasonSelectOption } from '../../types';
import styles from './styles.scss';

type Props = {
  seasons: Season[];
  selectedSeasons: Season[];
  onChange: (seasons: Season[]) => void;
  isDisabled?: boolean;
  isLoading?: boolean;
  className?: string;
};

const SeasonSelect = ({
  seasons,
  isDisabled = false,
  selectedSeasons,
  onChange,
  isLoading = false,
  className,
}: Props) => {
  const selectItems = useMemo(() => {
    return getTeamSelectItems(seasons);
  }, [seasons]);
  const selectedItems = useMemo(() => {
    return getTeamSelectItems(selectedSeasons);
  }, [selectedSeasons]);

  const handleSelectedSeasonsChange = useCallback(
    (items: SeasonSelectOption[]) => {
      const selectedSeasons = items.map((item) => ArrayUtils.findById(seasons, item.id));

      onChange(selectedSeasons);
    },
    [seasons, onChange],
  );

  return (
    <ExtendedMultipleSelect<SeasonSelectOption>
      isLoading={isLoading}
      itemsContainerClass={styles.selectItemsContainer}
      onChange={handleSelectedSeasonsChange}
      isDisabled={isDisabled}
      items={selectItems}
      selectedItems={selectedItems}
      inputLabel={i18n.value('player.events.filters.seasonStaticSelect.label')}
      className={className}
    />
  );
};

export default memo(SeasonSelect);
