import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const HeaderSkeleton = () => (
  <div className={styles.wrapper}>
    <Skeleton height={14} width={52} marginRight={12} variant="rectangular" />
    <Skeleton height={32} width={230} marginRight={12} variant="rectangular" />
    <Skeleton height={32} width={205} marginRight={12} variant="rectangular" />
    <Skeleton height={32} width={205} marginRight={12} variant="rectangular" />
    <Skeleton height={32} width={160} marginRight={12} variant="rectangular" />
  </div>
);

export default memo(HeaderSkeleton);
