import React from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { TeamsParameter, TopTeam } from 'packages/season-top-board/types';
import { Tooltip } from 'packages/shared/components';

import { ParameterHeader, TeamCell } from '../../../../../../../components';

export default (): Column<TopTeam>[] => [
  {
    id: 'team',
    Header: i18n.value('common.tableParameter.short.team'),
    Cell: ({ value }) => <TeamCell team={value} />,
    accessor: ({ team }) => team,
    cellStyle: { paddingLeft: 16 },
    headerStyle: { textAlign: 'left', padding: '8px 8px 8px 16px' },
    width: 58,
  },
  {
    id: 'gamesPlayed',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${TeamsParameter.GamesPlayed}`)}>
        <>{i18n.value(`common.tableParameter.short.${TeamsParameter.GamesPlayed}`)}</>
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.gamesPlayed,
    width: 10,
  },
  {
    id: 'yellowCards',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${TeamsParameter.YellowCards}`)}>
        <ParameterHeader parameter={TeamsParameter.YellowCards} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.yellowCards,
    width: 10,
  },
  {
    id: 'yellowRedCards',
    Header: (
      <Tooltip
        value={i18n.value(`common.tableParameter.extended.${TeamsParameter.YellowRedCards}`)}
      >
        <ParameterHeader parameter={TeamsParameter.YellowRedCards} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.yellowRedCards,
    width: 10,
  },
  {
    id: 'redCards',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${TeamsParameter.RedCards}`)}>
        <ParameterHeader parameter={TeamsParameter.RedCards} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.redCards,
    width: 12,
  },
];
