import getPath from '../getPath';
import { TopOfTheSeasonStatus } from '../../types';

const getStatus = (state): TopOfTheSeasonStatus => {
  return getPath(state).topOfTheSeason.status;
};

export const getIsTopOfTheSeasonLoading = (state): boolean => {
  return getStatus(state).isTopOfTheSeasonLoading;
};
