import React, { memo, RefObject, useMemo } from 'react';
import classnames from 'classnames';

import {
  Column,
  ColumnType,
  Ordering,
  PlayerStatistics,
  Player,
  StatType,
} from 'packages/season-player-statistics/types';
import { FlexibleTable, LoaderContainer } from 'packages/shared/components';
import { Team } from 'types';

import { getRowProps } from '../../services';
import { getTableSchema } from './services';
import { LoadingSkeleton } from './components';

import styles from './styles.scss';

type Props = {
  forwardedRef: RefObject<HTMLDivElement>;
  onOrderingChange: (columnType: ColumnType) => void;
  onTeamClick: (team: Team) => void;
  onPlayerClick: (player: Player) => void;
  playersStatistics: PlayerStatistics[];
  ordering: Ordering;
  columns: Column[];
  activeStatType: StatType;
  pageSize?: number;
  isPlayersLoading: boolean;
  isInitialPlayersLoading?: boolean;
  className?: string;
};

const DEFAULT_PAGE_SIZE = 15;

const PlayerStatisticsTable = ({
  forwardedRef,
  playersStatistics,
  onOrderingChange,
  isPlayersLoading,
  isInitialPlayersLoading = false,
  columns,
  ordering,
  activeStatType,
  pageSize = DEFAULT_PAGE_SIZE,
  className,
  onTeamClick,
  onPlayerClick,
}: Props) => {
  if (isInitialPlayersLoading) {
    return <LoadingSkeleton elementsCount={pageSize} />;
  }

  const tableSchema = useMemo(
    () =>
      getTableSchema(columns, {
        headerProps: { onOrderingChange, ordering, statType: activeStatType },
        columnProps: { onTeamClick },
      }),
    [columns, ordering, onOrderingChange, activeStatType],
  );

  const rowProps = useMemo(() => getRowProps({ onClick: onPlayerClick }), []);

  return (
    <>
      <LoaderContainer
        isLoading={isPlayersLoading}
        className={classnames(styles.loaderContainer)}
        isContentVisible
      />
      <FlexibleTable<PlayerStatistics>
        forwardedRef={forwardedRef}
        className={classnames(styles.table, className)}
        headerClassName={styles.header}
        rowClassName={styles.tableRow}
        columnClassName={styles.tableColumn}
        columns={tableSchema}
        setRowProps={rowProps}
        data={playersStatistics}
        isColumnsSticky
        isRowClickable
        isHeaderSticky
      />
    </>
  );
};

export default memo(PlayerStatisticsTable);
