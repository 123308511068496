import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { detailsSelectors, detailsThunks } from 'packages/team-summary';
import { teamDetailsSelectors } from 'packages/team-details';

const mapStateToProps = (state) => {
  return {
    isDetailsLoading: detailsSelectors.common.getIsLoading(state),
    tournaments: teamDetailsSelectors.getTournamentList(state),
    isTournamentsLoading: teamDetailsSelectors.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    detailsActions: bindActionCreators(detailsThunks.common, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
