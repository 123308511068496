import Dexie from 'dexie';

import { Tables } from '../../schema';
import { serializeEventToTableModel, flushFavoritesModifications } from '../../services';

const onCreate = (dexieInstance: Dexie) => {
  const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);

  favoritesModel.hook('creating', (primaryKey, record) => {
    serializeEventToTableModel(record);
  });
};

const onUpdate = (dexieInstance: Dexie) => {
  const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);

  favoritesModel.hook('updating', (modifications, primaryKey, record) => {
    return flushFavoritesModifications(modifications, record);
  });
};

export default [onCreate, onUpdate];
