import React, { RefObject, useMemo, memo } from 'react';
import { useParams } from 'react-router-dom';

import { FlexibleTable, LoaderContainer } from 'packages/shared/components';
import { Ordering, SquadOrdering } from 'packages/rest/api/TeamSquad/types';
import { Player } from 'packages/team-squad/types';

import { getTableSchema, getPlayerRowProps } from './services';
import styles from './styles.scss';

type Props = {
  data: Player[];
  ordering: SquadOrdering;
  containerRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  onHeaderCellClick: (orderingType: Ordering) => void;
  onRowClick: (player: Player) => void;
};

const StadiumsTable = ({
  data,
  ordering,
  onHeaderCellClick,
  containerRef,
  isLoading,
  onRowClick,
}: Props) => {
  const { teamId = '' } = useParams();

  const columns = useMemo(() => getTableSchema({ onHeaderCellClick, ordering, teamId }), [
    ordering,
  ]);
  const rowProps = useMemo(() => getPlayerRowProps({ onClick: onRowClick }), []);

  return (
    <>
      <LoaderContainer isLoading={isLoading} isContentVisible className={styles.loaderContainer} />
      <FlexibleTable<Player>
        forwardedRef={containerRef}
        className={styles.table}
        headerClassName={styles.header}
        columnClassName={styles.column}
        setRowProps={rowProps}
        data={data}
        columns={columns}
        isRowClickable
        isHeaderSticky
        isColumnsSticky
      />
    </>
  );
};

export default memo(StadiumsTable);
