import getPath from './getPath';
import { NewcomersStatus } from '../types';

const getStatus = (state): NewcomersStatus => {
  return getPath(state).status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
