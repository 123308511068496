import { EventType } from 'packages/event-commentary/types';

export const NAMESPACE = 'EVENT-COMMENTARY::';

export const IMPORTANT_EVENT_TYPES = [
  EventType.YellowCard,
  EventType.YellowRedCard,
  EventType.RedCard,
  EventType.Goal,
  EventType.PenaltyMissed,
  EventType.PenaltyRewarded,
  EventType.Substitution,
];
