import Dexie from 'dexie';

import { FavoritesTableIndex, Tables } from 'configure/storage/types';
import { keyValueDatabase } from 'packages/storage';
import { settingsSelectors } from 'packages/settings';
import { Tournament } from 'types';
import DateService from 'packages/date-az';

const extendFavoriteLeaguesWithMatchCounts = (leagues: Tournament[]) => (
  dispatch,
  getState,
): Promise<Tournament[]> => {
  const activeDate = settingsSelectors.getActiveDate(getState());
  const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
  const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);
  const currentDate = DateService.getDate().toISODate();

  return dexieInstance.transaction('r', favoritesModel, () =>
    Promise.all(
      leagues.map(async (league) => {
        const [countMatches, liveMatches] = await Promise.all([
          favoritesModel
            .where({
              [FavoritesTableIndex.Date]: activeDate,
              [FavoritesTableIndex.UniqueTournamentId]: league.uniqueTournamentId,
            })
            .count(),
          favoritesModel
            .where({
              [FavoritesTableIndex.Date]: currentDate,
              [FavoritesTableIndex.UniqueTournamentId]: league.uniqueTournamentId,
            })
            .toArray(),
        ]);

        const countLiveMatches = liveMatches.filter(({ isLive }) => isLive).length;

        return { ...league, countMatches, countLiveMatches };
      }),
    ),
  );
};

export default extendFavoriteLeaguesWithMatchCounts;
