import React, { memo } from 'react';

import { TopBoard } from 'packages/season-top-board-ui';
import { Newcomers } from 'packages/season-newcomers-ui';

const SideBar = () => {
  return (
    <>
      <TopBoard.Desktop />
      <Newcomers.Desktop />
    </>
  );
};

export default memo(SideBar);
