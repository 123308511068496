import React, { memo, useMemo } from 'react';
import classnames from 'classnames';

import { ArrayUtils } from 'packages/utils';
import { FlexibleTable } from 'packages/shared/components';

import { getTableSchema } from './services';
import styles from './styles.scss';

type Props = {
  elementsCount: number;
  isTeamLabelVisible?: boolean;
  isHeaderSticky?: boolean;
};

const TableSkeleton = ({
  elementsCount,
  isTeamLabelVisible = false,
  isHeaderSticky = false,
}: Props) => {
  const tableColumns = useMemo(() => getTableSchema({ isTeamLabelVisible }), []);
  const rows = ArrayUtils.getArrayFromNumber(elementsCount);

  return (
    <FlexibleTable
      headerClassName={classnames({ [styles.header]: isHeaderSticky })}
      isHeaderSticky={isHeaderSticky}
      columns={tableColumns}
      data={rows}
      isRowClickable
    />
  );
};

export default memo(TableSkeleton);
