import { Row } from 'react-table';

import { TopTeam } from 'packages/season-top-board/types';

type Params = {
  onClick: (teamId: number) => void;
};

const getTeamRowProps = ({ onClick }: Params) => {
  return ({ original }: Row<TopTeam>) => {
    const { team } = original;

    return {
      onClick: () => {
        onClick(team.id);
      },
    };
  };
};

export default getTeamRowProps;
