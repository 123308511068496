import React, { memo } from 'react';
import classnames from 'classnames';

import { Data as StadiumDetailsData } from 'packages/stadium-details/types';

import { Background, Description, Parameters, DetailsSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  isLoading: boolean;
  stadiumDetails: StadiumDetailsData;
  className?: string;
};

const StadiumDetails = ({ stadiumDetails, isLoading, className }: Props) => {
  if (isLoading || !stadiumDetails) {
    return <DetailsSkeleton />;
  }

  const { name, countryName, city, logo, constructionYear, capacity, pitchSize } = stadiumDetails;

  return (
    <div className={classnames(styles.stadiumDetails, className)}>
      <Background className={styles.background} />
      <div className={styles.details}>
        <Description name={name} logo={logo} countryName={countryName} city={city} />
        <Parameters constructionYear={constructionYear} capacity={capacity} pitchSize={pitchSize} />
      </div>
    </div>
  );
};

export default memo(StadiumDetails);
