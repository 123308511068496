exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._SWp3CjK5{display:flex;flex-direction:column}._SWp3CjK5 ._1oWRLSAD{display:flex;justify-content:space-between;padding:16px}._SWp3CjK5 ._15UvAJY0 ._2q5zRuAi>*{align-items:center;height:34px}._SWp3CjK5 ._15UvAJY0 ._1I42vGnB{height:36px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/modules/StandingsBlock/components/LoadingSkeleton/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,qBAAsB,CAH1B,sBAMM,YAAa,CACb,6BAA8B,CAC9B,YCVQ,CDEd,mCAcU,kBAAmB,CACnB,WAAY,CAftB,iCAoBQ,WAAY","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .container {\n    display: flex;\n    flex-direction: column;\n\n    .header {\n      display: flex;\n      justify-content: space-between;\n      padding: $padding;\n    }\n\n    .table {\n      .table-header {\n        & > * {\n          align-items: center;\n          height: 34px;\n        }\n      }\n\n      .table-row {\n        height: 36px;\n      }\n    }\n  }\n}","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"container": "_SWp3CjK5",
	"header": "_1oWRLSAD",
	"table": "_15UvAJY0",
	"table-header": "_2q5zRuAi",
	"tableHeader": "_2q5zRuAi",
	"table-row": "_1I42vGnB",
	"tableRow": "_1I42vGnB"
};