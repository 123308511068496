import React, { memo, useCallback } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import {
  Button,
  Icon,
  Select,
  SelectItemComponent,
  SelectLabelComponent,
} from 'packages/shared/components';
import { ButtonType } from 'packages/shared/components/Button/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { SelectItem, SelectSize } from 'packages/shared/components/Select/types';
import { Enums } from 'packages/team-player-statistics/types';

import styles from './styles.scss';

type Props = {
  presetItems: SelectItem[];
  tournamentItems: SelectItem[];
  activeTournamentItem?: SelectItem;
  onPresetItemSelect: (presetItem: Enums.PresetType) => void;
  onTournamentChange: (tournamentId: number | string) => void;
  onCustomizeButtonClick: () => void;
  activePresetItem: SelectItem;
  className?: string;
};

const ControlHeader = ({
  onCustomizeButtonClick,
  onPresetItemSelect,
  presetItems,
  tournamentItems,
  activeTournamentItem = tournamentItems[0],
  onTournamentChange,
  activePresetItem,
  className,
}: Props) => {
  const handlePresetItemChange = useCallback(
    ({ id: presetType }: SelectItem) => {
      onPresetItemSelect(presetType as Enums.PresetType);
    },
    [onPresetItemSelect],
  );

  const handleTournamentItemChange = useCallback(
    ({ id }: SelectItem) => {
      onTournamentChange(id);
    },
    [onTournamentChange],
  );

  return (
    <div className={classnames(styles.controlHeader, className)}>
      <div className={styles.filterGroup}>
        <div className={styles.title}>{i18n.value('team.playerStatistics.title')}</div>
        <Select
          className={styles.selectList}
          items={tournamentItems}
          onChange={handleTournamentItemChange}
          selectedItem={activeTournamentItem}
          size={SelectSize.FullWidth}
          labelComponentProps={activeTournamentItem?.componentProps}
          labelComponent={SelectLabelComponent.Tournament}
          itemComponent={SelectItemComponent.Tournament}
        />

        <Select
          className={styles.selectList}
          items={presetItems}
          onChange={handlePresetItemChange}
          selectedItem={activePresetItem}
          size={SelectSize.FullWidth}
        />
      </div>

      <Button
        className={styles.customizeButton}
        contentClassName={styles.customizeButtonContent}
        type={ButtonType.Default}
        onClick={onCustomizeButtonClick}
      >
        <Icon className={styles.icon} id={IconType.Configuration} />
      </Button>
    </div>
  );
};

export default memo(ControlHeader);
