import React, { memo } from 'react';

import { Advertisement } from 'packages/shared/components';
import { Description } from 'packages/player-summary-ui';

import styles from './styles.scss';

const TeamSummarySideBarContent = () => {
  return (
    <>
      <Description.Desktop />
      <Advertisement className={styles.advertisement} />
    </>
  );
};

export default memo(TeamSummarySideBarContent);
