import { isEmpty, toCamelCase } from 'packages/utils/Object.utils';

import { StageType, Stage, StatisticsGroup } from '../../../types';
import { mapSeasonTables } from './mapSeasonTables';

export const mapSeasonTablesStages = (
  seasonTablesStages,
  positionSortKey: StatisticsGroup,
): Stage[] => {
  if (isEmpty(seasonTablesStages)) {
    return [];
  }

  return seasonTablesStages.map((seasonTablesStage) => {
    const { id, name, stageStartDate, tournamentTables } = toCamelCase(seasonTablesStage);

    return {
      id,
      name,
      startDate: stageStartDate,
      type: StageType.Tables,
      data: {
        tournamentTables: mapSeasonTables(tournamentTables, positionSortKey),
      },
    };
  });
};
