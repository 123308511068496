import { DateTime, Duration, DurationUnit, Interval } from 'luxon';

import { TimeZone } from 'types';
import { isArray } from 'packages/utils/Object.utils';

import BasicDate from '../basicInstance';
import { DATE_FORMATS } from '../consts';

class DateService extends BasicDate {
  parse(date: string) {
    return DateTime.fromISO(date);
  }

  formatString(date: string, format: string): string {
    return super.format(this.parse(date), format);
  }

  getInterval(startDate: string, endDate: string): Interval {
    return Interval.fromDateTimes(this.parse(startDate), this.parse(endDate));
  }

  getFollowingDates(date: DateTime, daysCount: number, direction: 'prev' | 'next'): DateTime[] {
    const interval =
      direction === 'next'
        ? this.getNextDatesInterval(date, daysCount)
        : this.getPreviousDatesInterval(date, daysCount);

    return Array.from(this.daysGenerator(interval));
  }

  private getNextDatesInterval(date: DateTime, daysCount: number): Interval {
    const start = date.plus({ days: 1 });
    const end = start.plus({ days: daysCount });

    return Interval.fromDateTimes(start, end);
  }

  private getPreviousDatesInterval(date: DateTime, daysCount: number): Interval {
    const end = date;
    const start = end.minus({ days: daysCount });

    return Interval.fromDateTimes(start, end);
  }

  private *daysGenerator(interval: Interval): IterableIterator<DateTime> {
    let cursor = interval.start.startOf('day');
    while (cursor < interval.end) {
      yield cursor;
      cursor = cursor.plus({ days: 1 });
    }
  }

  parseToMs(date: string) {
    const dateObject: any = this.parse(date);

    return dateObject.ts;
  }

  getPrevDayIso(currentDay: string) {
    this.getAdjacentDayIso(currentDay, -1);
  }

  getNextDayIso(currentDay: string) {
    this.getAdjacentDayIso(currentDay, 1);
  }

  getAdjacentDayIso(currentDay: string, offsetDays: number) {
    const activeDate = this.getDateTimeFromIsoString(currentDay);
    const adjacentDay = this.plus(activeDate, { day: offsetDays });

    return this.toIsoDateFromDateTime(adjacentDay);
  }

  getNextMonthName(activeDate: DateTime) {
    const nextMonth = this.plus(activeDate, { month: 1 });

    return this.getMonthShortName(nextMonth);
  }

  getPrevMonthName(activeDate: DateTime) {
    const previousMonth = this.minus(activeDate, { month: 1 });

    return this.getMonthShortName(previousMonth);
  }

  getEventTime(time: string) {
    const eventDate = this.getDateTimeFromIsoString(time);

    return this.format(eventDate, DATE_FORMATS.TIME);
  }

  getEventFullDate(time: string) {
    const eventDate = this.getDateTimeFromIsoString(time);

    return this.format(eventDate, DATE_FORMATS.DATE_TIME);
  }

  getDurationToNow(time: string) {
    const now = this.getDate();

    return this.getDuration(time, now.toString());
  }

  getDuration(leftTime: string, rightTime: string) {
    const leftDate = this.getDateTimeFromIsoString(leftTime);
    const rightDate = this.getDateTimeFromIsoString(rightTime);
    const dateToNow = this.minus(leftDate, rightDate.toObject());

    return Duration.fromObject(dateToNow.toObject());
  }

  getFullTimeWithOffset(timeZone: TimeZone): string {
    const { value, name } = timeZone;
    const date = this.getDate().toUTC(value);
    const dateString = this.format(date, DATE_FORMATS.DATE_TIME);

    return ` ${name}, ${dateString}`;
  }

  getFormattedDateViewFromString(currentDay: string, format = DATE_FORMATS.DEFAULT) {
    const dateIsoString = this.getDateTimeFromIsoString(currentDay);

    return this.format(dateIsoString, format);
  }

  getDatesFromString(value: string | string[]): Date[] {
    if (isArray(value)) {
      return value.map((isoDate) => this.getDateFromString(isoDate));
    }

    return [this.getDateFromString(value)];
  }

  getDateFromString(value: string): Date {
    return this.parse(value).toJSDate();
  }

  getDateByYearOffset(yearsOffset: number): DateTime {
    const now = this.getDate();

    return this.minus(now, { year: yearsOffset });
  }

  getDiffFromCurrentDate(date: DateTime, unit?: DurationUnit | DurationUnit[]): Duration {
    return this.getDate().diff(date, unit);
  }

  isCurrentDateBetweenRange(startISODate: string, endISODate: string): boolean {
    const currentDate = this.getDate().startOf('day');

    const seasonStartDate = this.parse(startISODate).startOf('day');
    const seasonEndDate = this.parse(endISODate).endOf('day');

    return currentDate >= seasonStartDate && currentDate <= seasonEndDate;
  }
}

export default DateService;
