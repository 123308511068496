import React, { memo, useMemo } from 'react';

import { ArrayUtils } from 'packages/utils';

import { EventLoadingSkeleton, TournamentLoadingSkeleton } from '../../../../components';

const DEFAULT_TOURNAMENTS_COUNT = 15;

type Props = {
  isCompact?: boolean;
  tournamentsCount?: number;
};

const EventListLoadingSkeleton = ({
  isCompact = false,
  tournamentsCount = DEFAULT_TOURNAMENTS_COUNT,
}: Props) => {
  const tournaments = useMemo(() => ArrayUtils.getArrayFromNumber(tournamentsCount), [
    tournamentsCount,
  ]);

  const getEvents = () => {
    const eventsCount = Math.ceil(Math.random() * 5);

    return ArrayUtils.getArrayFromNumber(eventsCount);
  };

  return (
    <>
      {tournaments.map(() => (
        <>
          <TournamentLoadingSkeleton />
          {getEvents().map((event) => (
            <EventLoadingSkeleton key={event} isCompact={isCompact} />
          ))}
        </>
      ))}
    </>
  );
};

export default memo(EventListLoadingSkeleton);
