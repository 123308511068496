import { Row } from 'react-table';

import { History } from 'packages/season-history/types';

type Params = {
  onClick: (year: string) => void;
};

const getRowProps = ({ onClick }: Params) => ({ original }: Row<History>) => {
  const { year } = original;

  return {
    onClick: () => {
      onClick(year);
    },
  };
};

export default getRowProps;
