import React from 'react';
import classnames from 'classnames';

import { HeadToHead } from 'packages/team-standings/types';
import { Team } from 'types';

import {
  addHighlightClassToTableElements,
  removeHighlightClassToTableElements,
  addHighlightClassToVerticalCells,
  removeHighlightClassFromVerticalCells,
} from './highlightTableElements';
import { HeadToHeadTeamColumnCell, HeadToHeadCell, HeadToHeadHeaderCell } from '../components';
import { TOP_HEADER_ID_BASE, LEFT_HEADER_ID_BASE, CELL_ID_BASE } from '../constants';
import styles from '../styles.scss';

interface CellParams {
  onCellClick: (cell: number) => void;
}

export const getTableConfiguration = (
  tableData: HeadToHead.TableViewData,
  activeTeamIds: number[],
  cellParams: CellParams,
  activeUniqueTournamentId?: string | number,
  activeSeasonId?: string | number,
) => {
  const { teams } = tableData;

  const columns: any[] = [
    getTeamLabelColumn(activeTeamIds, activeUniqueTournamentId, activeSeasonId),
    ...getTeamColumns(teams, activeTeamIds, cellParams, activeUniqueTournamentId, activeSeasonId),
  ];
  const data = getTableData(tableData);

  return { columns, data };
};

const getTeamLabelColumn = (
  activeTeamIds: number[],
  activeUniqueTournamentId?: string | number,
  activeSeasonId?: string | number,
) => ({
  width: 152,
  dataIndex: 0,
  fixed: true,
  className: classnames(styles.column, styles.leftSideCell),
  onCell: (event, columnIndex) => ({ id: `${LEFT_HEADER_ID_BASE}${++columnIndex}` }),
  render: ({ logo, name, id }) => (
    <HeadToHeadTeamColumnCell
      activeUniqueTournamentId={activeUniqueTournamentId}
      activeSeasonId={activeSeasonId}
      logo={logo}
      name={name}
      teamId={id}
      activeTeamIds={activeTeamIds}
    />
  ),
});

const getTeamColumns = (
  teams: Team[],
  activeTeamIds: number[],
  cellParams: CellParams,
  activeUniqueTournamentId?: string | number,
  activeSeasonId?: string | number,
) => {
  return teams.map(({ logo, id }, columnIndex) => {
    const isTeamActive = activeTeamIds.includes(id);

    return {
      title: (
        <HeadToHeadHeaderCell
          activeUniqueTournamentId={activeUniqueTournamentId}
          activeSeasonId={activeSeasonId}
          logo={logo}
          teamId={id}
        />
      ),
      width: 46,
      align: 'center' as 'center',
      dataIndex: ++columnIndex,
      className: styles.column,
      onHeaderCell: ({ key: columnIndex }) => {
        return {
          id: `${TOP_HEADER_ID_BASE}${columnIndex}`,
          className: classnames({
            [styles.activeTeam]: isTeamActive,
          }),
          onMouseEnter: () => {
            for (let row = 0; row <= teams.length; row++) {
              addHighlightClassToVerticalCells(columnIndex, row);
            }
          },
          onMouseLeave: () => {
            for (let row = 0; row <= teams.length; row++) {
              removeHighlightClassFromVerticalCells(columnIndex, row);
            }
          },
        };
      },
      onCell: (event, rowIndex) => {
        const hasActiveTeam = isAnyTeamActive(activeTeamIds, teams, columnIndex, rowIndex);
        const isTheSameTeamIntersected = ++rowIndex === columnIndex;

        return {
          id: `${CELL_ID_BASE}${columnIndex}_${rowIndex}`,
          className: classnames({
            [styles.sameTeamIntersected]: isTheSameTeamIntersected,
            [styles.activeTeam]: hasActiveTeam,
          }),
          onClick: () => {
            if (event[columnIndex]?.id) {
              cellParams.onCellClick(event[columnIndex].id);
            }
          },
          onMouseEnter: () => {
            addHighlightClassToTableElements(columnIndex, rowIndex);
          },
          onMouseLeave: () => {
            removeHighlightClassToTableElements(columnIndex, rowIndex);
          },
        };
      },
      render: (value) => <HeadToHeadCell event={value} />,
    };
  });
};

const isAnyTeamActive = (
  activeTeamIds: number[],
  teams: Team[],
  columnIndex: number,
  rowIndex: number,
) => {
  return [teams[columnIndex - 1], teams[rowIndex]].find((team) => activeTeamIds.includes(team?.id));
};

const getTableData = (tableData: HeadToHead.TableViewData) => {
  const { teams, eventRows } = tableData;

  return eventRows.map((eventRow: any, index) => {
    eventRow.unshift(teams[index]);

    return eventRow;
  });
};
