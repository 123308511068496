import {
  CommonTableStatistics,
  SeasonTableStageData,
  Stage,
  StatisticsGroup,
} from 'packages/event-standings/types';
import { isTableStage } from 'packages/event-standings/services';

type Params = {
  stages: Stage[];
  group: StatisticsGroup;
  checkingParam?: keyof CommonTableStatistics;
};

const shouldTableGroupTabBeDisplayed = ({
  stages,
  group,
  checkingParam = 'gamesPlayed',
}: Params): boolean => {
  const stageGroup = stages.find((stage) => isTableStage(stage)) as Stage<SeasonTableStageData>;

  if (stageGroup) {
    for (const tournamentTable of stageGroup.data.tournamentTables) {
      for (const row of tournamentTable.rows) {
        if (row.statistics[group][checkingParam] === undefined) {
          return false;
        }
      }
    }

    return true;
  }

  return false;
};

export default shouldTableGroupTabBeDisplayed;
