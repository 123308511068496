import { TeamSquad } from 'packages/rest';
import { LoadSquadParams } from 'packages/team-squad/types';
import { squadSelectors } from 'packages/team-squad';

import { squadActions } from '../actions';
import { mapSquad } from '../services';

const loadSquad = ({ ordering, teamId }: LoadSquadParams) => async (dispatch, getState) => {
  try {
    dispatch(squadActions.loadSquadStart());

    const offset = squadSelectors.getOffset(getState());
    const limit = squadSelectors.getLimit(getState());

    const { data } = await TeamSquad.loadTeamSquad({ ordering, teamId, limit, offset });

    const players = mapSquad(data.results);

    dispatch(squadActions.loadSquadSuccess(players));
  } catch (e) {
    dispatch(squadActions.loadSquadFailure(e));
  }
};

export default loadSquad;
