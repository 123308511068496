import { finishedEventsSelectors } from '../../selectors';
import { finishedEventsActions } from '../../actions';
import { getEventsAfterLiveInsert } from '../../services';

export const updateFinishedEvents = (newEvents) => async (dispatch, getState) => {
  try {
    dispatch(finishedEventsActions.updateFinishedEventsStart());

    const state = getState();
    const oldEvents = finishedEventsSelectors.getFinishedEvents(state);
    const updatedEvents = getEventsAfterLiveInsert(oldEvents, newEvents);

    dispatch(finishedEventsActions.updateFinishedEventsSuccess(updatedEvents));
  } catch (error) {
    dispatch(finishedEventsActions.updateFinishedEventsFailure(error));
  }
};
