import isEmpty from 'is-empty-typed/lib';

import {
  BaseHappening,
  CardHappening,
  GoalHappening,
  SubstitutionHappening,
} from 'packages/event-time-line/types';
import { SummaryHappeningType } from 'packages/events/types';
import {
  GoalLabel,
  SubstitutionLabel,
  SubstitutionByPlayer,
  AssistLabel,
} from 'packages/shared/components';
import { GoalHappeningViewTypes } from 'packages/shared/components/GoalLabel/types';

import { RefereeCardHappening, DefaultHappening } from '../components';
import { DefaultHappeningComponentConfig } from '../../../types';

type Params = {
  happening: BaseHappening;
  isReversed: boolean;
  customProps?: Record<string, any>;
  isSubstitutionByPlayerDisplayed?: boolean;
  isAssistsDisplayed?: boolean;
  useDefaultComponent?: boolean;
};

const getHappeningComponentConfig = ({
  happening,
  isReversed,
  customProps = {},
  isSubstitutionByPlayerDisplayed = false,
  isAssistsDisplayed = false,
  useDefaultComponent = false,
}: Params): DefaultHappeningComponentConfig | null => {
  switch (happening.eventType) {
    case SummaryHappeningType.SUBSTITUTION: {
      const { playerInName, playerOutName, players } = happening as SubstitutionHappening;

      return {
        component: isSubstitutionByPlayerDisplayed ? SubstitutionByPlayer : SubstitutionLabel,
        props: {
          playerInName,
          playerOutName,
          isIconFirst: isReversed,
          playerInId: players?.find(({ name }) => name === playerInName)?.playerId,
          playerOutId: players?.find(({ name }) => name === playerOutName)?.playerId,
          ...customProps,
        },
      };
    }
    case SummaryHappeningType.ASSISTS: {
      if (!isAssistsDisplayed) {
        return null;
      }

      const { assistName, scorerName, scorerId, assistId } = happening as GoalHappening;

      return {
        component: AssistLabel,
        props: {
          assistName,
          scorerName,
          scorerId,
          assistId,
        },
      };
    }
    case SummaryHappeningType.SCORE_CHANGE: {
      const {
        scorerName,
        assistName,
        isOwnGoal,
        isPenaltyGoal,
        players,
      } = happening as GoalHappening;

      return {
        component: GoalLabel,
        props: {
          scorerName,
          assistName,
          isOwnGoal,
          isPenaltyGoal,
          isIconFirst: isReversed,
          scorerId: players?.find(({ name }) => name === scorerName)?.playerId,
          assistId: players?.find(({ name }) => name === assistName)?.playerId,
          ...customProps,
        },
      };
    }
    case SummaryHappeningType.YELLOW_CARD:
    case SummaryHappeningType.RED_CARD:
    case SummaryHappeningType.YELLOW_RED_CARD: {
      const { color, playerName, isTookOnTheBench, players } = happening as CardHappening;

      return {
        component: RefereeCardHappening,
        props: {
          type: color,
          playerName,
          isIconFirst: isReversed,
          isTookOnTheBench: isTookOnTheBench,
          playerId: players?.find(({ name }) => name === playerName)?.playerId,
          ...customProps,
        },
      };
    }
    case SummaryHappeningType.PENALTY_MISSED: {
      const { scorerName, players } = happening as GoalHappening;

      return {
        component: GoalLabel,
        props: {
          scorerName: scorerName || '',
          scorerId: players?.find(({ name }) => name === scorerName)?.playerId,
          isIconFirst: isReversed,
          type: GoalHappeningViewTypes.PenaltyMissed,
        },
      };
    }
    default: {
      if (useDefaultComponent) {
        const { players } = happening;

        if (!isEmpty(players)) {
          const [{ playerId, name, shortName }] = players;

          return {
            component: DefaultHappening,
            props: {
              playerName: name || shortName,
              playerId: playerId,
            },
          };
        }
      }

      return null;
    }
  }
};

export default getHappeningComponentConfig;
