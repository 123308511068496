import React, { memo } from 'react';

import { Figure } from 'packages/shared/components';
import { RankedTeam } from 'packages/season-team-statistics/types';

import styles from './styles.scss';

type Props = {
  team: RankedTeam;
};

const TeamCell = ({ team }: Props) => {
  const { name, logo } = team;

  return (
    <div className={styles.nameBlock}>
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export default memo(TeamCell);
