import loadStatisticsAndLineups from './loadStatisticsAndLineups';
import { detailsActions } from '../../../actions';
import { loadStandings } from '../standings';
import { loadTopPlayers } from '../topPlayers';

const { common } = detailsActions;

interface Params {
  seasonId: number;
  teamId: number;
}

const loadDetails = ({ teamId, seasonId }: Params) => async (dispatch) => {
  try {
    dispatch(common.loadDetailsStart());

    await Promise.all([
      dispatch(loadStandings({ teamId, seasonId })),
      dispatch(loadTopPlayers({ teamId, seasonId })),
      dispatch(loadStatisticsAndLineups({ teamId, seasonId })),
    ]);

    dispatch(common.loadDetailsSuccess());
  } catch (e) {
    dispatch(common.loadDetailsFailure(e));
  }
};

export default loadDetails;
