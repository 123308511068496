import reducers from './reducers';
import * as squadThunks from './thunks';
import { squadSelectors } from './selectors';

import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export { createReducer, squadThunks, squadSelectors };
