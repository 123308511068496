import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../../actions';
import { TopPlayersStatus } from '../../types';

const defaultState: TopPlayersStatus = {
  isTopPlayersLoading: true,
  isInitialTopPlayersLoading: true,
};

const reducerMap: ReducerMap<TopPlayersStatus, any> = {
  [`${actions.loadInitialTopPlayersStart}`]: (state) => {
    return { ...state, isInitialTopPlayersLoading: true };
  },
  [`${combineActions(
    actions.loadInitialTopPlayersSuccess,
    actions.loadInitialTopPlayersFailure,
  )}`]: () => {
    return { isTopPlayersLoading: false, isInitialTopPlayersLoading: false };
  },
  [`${actions.loadTopPlayersStart}`]: (state) => {
    return { ...state, isTopPlayersLoading: true };
  },
  [`${combineActions(actions.loadTopPlayersFailure, actions.loadTopPlayersSuccess)}`]: (state) => {
    return { ...state, isTopPlayersLoading: false };
  },
  [`${actions.cleanTopBoardSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<TopPlayersStatus>(reducerMap, defaultState);
