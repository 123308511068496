exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._37U3G2Rb{width:120px;transition:all 600ms ease 150ms}._37U3G2Rb._2sGAVjyh{width:340px}._1jfXS5T-{padding-top:4px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/search-ui/containers/Search/views/Desktop/styles.scss"],"names":[],"mappings":"AAEA,WAEI,WAAY,CACZ,+BAAgC,CAHpC,qBAMM,WAAY,CANlB,WAWI,eAAgB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .search-bar {\n    width: 120px;\n    transition: all 600ms ease 150ms;\n\n    &.extended {\n      width: 340px;\n    }\n  }\n\n  .search-popover-container {\n    padding-top: 4px;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"search-bar": "_37U3G2Rb",
	"searchBar": "_37U3G2Rb",
	"extended": "_2sGAVjyh",
	"search-popover-container": "_1jfXS5T-",
	"searchPopoverContainer": "_1jfXS5T-"
};