import React, { memo } from 'react';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import { DEFAULT_MINUTES_PLAYED } from './const';
import styles from './styles.scss';

type Props = {
  minutesPlayed: number;
  substitutedIn: boolean;
  className?: string;
};

const MinutesPlayed = ({ minutesPlayed, substitutedIn, className }: Props) => {
  if (minutesPlayed === 0) {
    if (substitutedIn) {
      return <div className={classnames(className)}>{DEFAULT_MINUTES_PLAYED}’</div>;
    } else {
      return (
        <div className={classnames(styles.iconWrapper, className)}>
          <Icon className={styles.benchIcon} id={IconType.Bench} />
        </div>
      );
    }
  }

  if (minutesPlayed) {
    return <div className={classnames(className)}>{minutesPlayed}’</div>;
  }

  return <></>;
};

export default memo(MinutesPlayed);
