import React from 'react';
import classnames from 'classnames';

import { isEmpty } from 'packages/utils/Object.utils';
import { FlexiblePopover, Separator } from 'packages/shared/components';

import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';
import { HandlingEventType } from 'packages/shared/components/OutsideClickHandler/types';

import { DetailMenu, TeamPreview } from './components';
import styles from './styles.scss';

type Props = {
  matchUp: any;
  activeTeamIds?: number[];
  activeUniqueTournamentId?: number | string;
  activeSeasonId?: number | string;
  className?: string;
};

const MatchUp = ({
  matchUp,
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
  className,
}: Props) => {
  const { homeTeam, awayTeam, sportEvents } = matchUp;
  const popoverProps = { homeTeam, awayTeam, sportEvents };
  const inSportEventsEmpty = isEmpty(sportEvents);
  const matchUpElement = (
    <div className={classnames(styles.matchUp, { [styles.empty]: inSportEventsEmpty }, className)}>
      <TeamPreview
        team={matchUp.homeTeam}
        isActive={activeTeamIds.includes(matchUp?.homeTeam?.id)}
        uniqueTournamentId={activeUniqueTournamentId}
        seasonId={activeSeasonId}
        className={styles.matchUpTeamPreview}
      />
      <Separator />
      <TeamPreview
        team={matchUp.awayTeam}
        isActive={activeTeamIds.includes(matchUp?.awayTeam?.id)}
        uniqueTournamentId={activeUniqueTournamentId}
        seasonId={activeSeasonId}
        className={styles.matchUpTeamPreview}
      />
    </div>
  );

  return (
    <>
      {isEmpty(sportEvents) ? (
        matchUpElement
      ) : (
        <FlexiblePopover
          clickOutsideEventType={HandlingEventType.mouseDown}
          justify={PopoverJustify.CenterStart}
          align={PopoverAlign.CenterStart}
          invokeTrigger={PopoverInvokeTrigger.Click}
          popoverComponent={DetailMenu}
          popoverProps={popoverProps}
          disableBodyOverflow
          closeOnClickOutside
        >
          {matchUpElement}
        </FlexiblePopover>
      )}
    </>
  );
};

export default MatchUp;
