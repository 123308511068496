import { Season } from 'types';

import { loadFilters, initActiveFilter } from '../filters';
import { subscribeForLiveEvents, loadInitialEvents } from '../events';
import { EVENTS_PAGE_DAYS_OFFSET } from '../../constants';

const loadEventsPage = (selectedSeason: Season) => async (dispatch) => {
  dispatch(loadFilters(selectedSeason.id));
  dispatch(subscribeForLiveEvents());
  dispatch(initActiveFilter(selectedSeason, { daysOffset: EVENTS_PAGE_DAYS_OFFSET }));
  dispatch(loadInitialEvents(selectedSeason));
};

export default loadEventsPage;
