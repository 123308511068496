import { Event } from 'types';

import { favoritesThunks } from 'packages/favorites';

import { favoriteEventsActions } from '../../actions';

const updateFavoriteEvents = (newEvents: Event[]) => async (dispatch) => {
  try {
    dispatch(favoriteEventsActions.updateFavoriteEventsStart());

    await dispatch(favoritesThunks.updateFavorites(newEvents));

    dispatch(favoriteEventsActions.updateFavoriteEventsSuccess());
  } catch (error) {
    dispatch(favoriteEventsActions.updateFavoriteEventsFailure(error));
  }
};

export default updateFavoriteEvents;
