import { GoalHappening } from 'packages/event-time-line/types';
import { GoalHappeningViewTypes } from 'packages/shared/components/GoalLabel/types';

const getGoalType = (happening: GoalHappening) => {
  const { isOwnGoal, isPenaltyGoal } = happening;

  if (isOwnGoal) {
    return GoalHappeningViewTypes.OwnGoal;
  } else if (isPenaltyGoal) {
    return GoalHappeningViewTypes.PenaltyGoal;
  }
};

export default getGoalType;
