import React, { useMemo } from 'react';
import i18n from 'i18n-smart';

import { Team } from 'types';
import { FlexibleTable } from 'packages/shared/components';
import { EventSummaryRow, EventSummaryTeam } from 'packages/event-summary/types';

import styles from './styles.scss';
import { getColumnSchema, mapColumnProps } from './services';

type Props = {
  teams: [EventSummaryTeam, EventSummaryTeam];
  onTeamClick: (team: Team) => void;
};

const SummaryForm = ({ teams, onTeamClick }: Props) => {
  const columns = useMemo(() => getColumnSchema({ onTeamClick }), []);
  const rows = mapColumnProps(teams);

  return (
    <div className={styles.summaryForm}>
      <div className={styles.title}>{i18n.value('eventSummary.matchDetails.ranking')}</div>

      <FlexibleTable<EventSummaryRow>
        data={rows}
        columns={columns}
        className={styles.table}
        columnClassName={styles.tableColumn}
      />
    </div>
  );
};

export default React.memo(SummaryForm);
