import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { stadiumActions } from '../actions';
import { StadiumsStatus } from '../types';
import { DEFAULT_STADIUMS_LIMIT } from '../constants';

const defaultState: StadiumsStatus = {
  isInitialPageLoading: true,
  isNewPageLoading: false,
  offset: 0,
  count: 0,
  limit: DEFAULT_STADIUMS_LIMIT,
};

const reducerMap: ReducerMap<StadiumsStatus, StadiumsStatus> = {
  [`${stadiumActions.loadInitialStadiumsStart}`]: (state) => ({
    ...state,
    isInitialPageLoading: true,
  }),

  [`${stadiumActions.loadStadiumsPageStart}`]: (state) => ({
    ...state,
    isNewPageLoading: true,
  }),

  [`${combineActions(
    stadiumActions.loadInitialStadiumsFailure,
    stadiumActions.loadInitialStadiumsSuccess,
  )}`]: (state) => ({ ...state, isInitialPageLoading: false }),

  [`${combineActions(
    stadiumActions.loadStadiumsPageFailure,
    stadiumActions.loadStadiumsPageSuccess,
  )}`]: (state) => ({
    ...state,
    isNewPageLoading: false,
  }),

  [`${combineActions(
    stadiumActions.loadStadiumsPageSuccess,
    stadiumActions.loadInitialStadiumsSuccess,
  )}`]: (state, { payload }) => {
    const { count } = payload;

    return {
      ...state,
      count,
    };
  },

  [`${stadiumActions.updatePaginationSuccess}`]: (state, { payload }) => {
    const { offset, limit } = payload;

    return {
      ...state,
      offset,
      limit,
    };
  },

  [`${stadiumActions.cleanStadiums}`]: () => {
    return defaultState;
  },
};

export default handleActions<any>(reducerMap, defaultState);
