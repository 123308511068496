import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { settingsSelectors } from 'packages/settings';
import { liveEventsThunks, favoriteEventsThunks } from 'packages/events';

const mapStateToProps = (state) => {
  return {
    timeZone: settingsSelectors.getTimeZone(state),
    activeDate: settingsSelectors.getActiveDate(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    liveEventsActions: bindActionCreators(liveEventsThunks, dispatch),
    favoriteEventsActions: bindActionCreators(favoriteEventsThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
