import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Separator, DetailedMatchUp } from 'packages/shared/components';
import { SportEvent } from 'packages/event-standings/types';
import { SearchQuery } from 'router/types';

import { ActionCreatorsMapObject } from 'redux';
import styles from './styles.scss';

type Props = {
  sportEvents: SportEvent[];
  eventDetailsActions: ActionCreatorsMapObject;
};

const DetailMenu = ({ sportEvents = [] }: Props) => {
  const [, setSearchParams] = useSearchParams();

  const lastSportEventIndex = sportEvents.length - 1;

  const getMatchMouseUpHandler = (eventId: number) => {
    return () => {
      setSearchParams({ [SearchQuery.EventCardId]: eventId.toString() });
    };
  };

  return (
    <div className={styles.menuContainer}>
      {sportEvents.map(({ homeTeam, awayTeam, id, ...rest }, index) => (
        <div key={id.toString()}>
          <DetailedMatchUp
            onMouseUp={getMatchMouseUpHandler(id)}
            homeTeam={homeTeam}
            awayTeam={awayTeam}
            {...rest}
          />
          {lastSportEventIndex !== index && <Separator className={styles.menuElementSeparator} />}
        </div>
      ))}
    </div>
  );
};

export default memo(DetailMenu);
