import React from 'react';
import { NavLink } from 'react-router-dom';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Sport } from 'types';

import styles from './styles.scss';

type Props = {
  sports: Sport[];
};

const SportNavigation = ({ sports }: Props) => {
  return (
    <nav className={styles.nav}>
      {sports.map(({ id, type }) => {
        return (
          <NavLink
            key={id}
            to={`/${type.toLowerCase()}`}
            className={({ isActive }) => {
              return isActive ? classnames(styles.navLinkActive, styles.navLink) : styles.navLink;
            }}
          >
            {i18n.value(`common.sport.${type}`)}
          </NavLink>
        );
      })}
    </nav>
  );
};

export default SportNavigation;
