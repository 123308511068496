import React, { memo } from 'react';

import { EventPlayerStatistics } from 'types';
import { IconType } from 'packages/shared/components/Icon/types';
import { Icon, RefereeCard } from 'packages/shared/components';
import { RefereeCardType } from 'packages/shared/components/RefereeCard/types';

import { CountableHappening, MinutesPlayed } from '../../../../../../components';

import styles from './styles.scss';

type Props = {
  playerStats: EventPlayerStatistics;
};

const PlayerStatistic = ({ playerStats }: Props) => {
  const { substitutedIn, substitutedOut, goalsScored, assists, cards, minutesPlayed } = playerStats;
  const { yellow, yellowRed, red } = cards;

  return (
    <div className={styles.wrapper}>
      <MinutesPlayed
        className={styles.minutesPlayed}
        minutesPlayed={minutesPlayed}
        substitutedIn={substitutedIn}
      />

      {substitutedIn && <Icon id={IconType.SubstitutionIn} />}

      <CountableHappening iconId={IconType.Goal} amount={goalsScored} />
      <CountableHappening iconId={IconType.Assist} amount={assists} />

      {!!yellow && (
        <RefereeCard count={yellow} className={styles.card} type={RefereeCardType.Yellow} />
      )}
      {!!yellowRed && (
        <RefereeCard count={yellowRed} className={styles.card} type={RefereeCardType.YellowRed} />
      )}
      {!!red && <RefereeCard count={red} className={styles.card} type={RefereeCardType.Red} />}

      {substitutedOut && <Icon id={IconType.SubstitutionOut} />}
    </div>
  );
};

export default memo(PlayerStatistic);
