import { commonActions } from '../../actions';
import { cleanHeadToHead } from '../headTohead';
import { cleanStages } from '../stages';

const cleanSeasonStandings = () => async (dispatch) => {
  try {
    dispatch(cleanStages());
    dispatch(cleanHeadToHead());
    dispatch(commonActions.cleanSeasonStandingsSuccess());
  } catch (e) {
    dispatch(commonActions.cleanSeasonStandingsFailure(e));
  }
};

export default cleanSeasonStandings;
