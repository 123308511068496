import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';

const defaultState = [];

const reducerMap: ReducerMap<any, any> = {
  [`${actions.loadEventLineupsSuccess}`]: (state, { payload }) => {
    const { eventLineups } = payload;

    return eventLineups;
  },
  [`${actions.cleanEventLineups}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
