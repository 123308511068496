import { connect } from 'react-redux';

import { statisticsSelectors } from 'packages/player-summary';

const mapStateToProps = (state) => {
  return {
    isSeasonTournamentsLoading: statisticsSelectors.tournamentSeasons.getIsLoading(state),
    isStatisticsLoading: statisticsSelectors.statistics.getIsLoading(state),
  };
};

export default connect(mapStateToProps, undefined);
