import { combineReducers } from 'redux';

import all from './allEvents';
import live from './liveEvents';
import finished from './finishedEvents';
import favorites from './favoriteEvents';
import scheduled from './scheduledEvents';

export default combineReducers({
  all,
  live,
  finished,
  favorites,
  scheduled,
});
