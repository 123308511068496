import React from 'react';
import PropTypes from 'prop-types';
import RealtimeCountdown from 'realtime-countdown';

import { DayView } from './views';

import styles from './styles.scss';

const ZERO_STATE = {
  days: '00',
  hours: '00',
  minutes: '00',
  seconds: '00',
};

class TimerCountdown extends React.Component {
  state = {
    ...ZERO_STATE,
  };

  componentDidMount() {
    this.handleInit();
  }

  componentDidUpdate(prevProps) {
    const { timeStamp } = this.props;
    if (prevProps.timeStamp !== timeStamp) {
      this.handleDestroy();
      this.handleInit();
    }
  }

  componentWillUnmount() {
    this.handleDestroy();
  }

  handleOnCount = (timeLeft) => {
    const { hours, minutes, seconds, days } = timeLeft;
    this.setState({ hours, minutes, seconds, days });
  };

  handleInit() {
    const { timeStamp, countStep, onCountEnd } = this.props;

    this.timer = new RealtimeCountdown({
      countStep,
      timeStamp,
      onCountEnd: () => {
        this.setState({ ...ZERO_STATE });
        onCountEnd();
      },
      leadingZero: true,
      onCount: this.handleOnCount,
    });
  }

  handleDestroy() {
    this.timer.destroy();
  }

  render() {
    const { days, hours, minutes, seconds } = this.state;
    const isLessThanDay = days === '0' || days === '00';

    return (
      <div className={styles.durationToStart}>
        {isLessThanDay ? (
          <div>
            {hours}:{minutes}
            <span className={styles.seconds}>:{seconds}</span>
          </div>
        ) : (
          <DayView dayCount={days} />
        )}
      </div>
    );
  }
}

TimerCountdown.defaultProps = {
  countStep: 1000,
  onCountEnd: () => {},
};

TimerCountdown.propTypes = {
  countStep: PropTypes.number,
  onCountEnd: PropTypes.func,
  timeStamp: PropTypes.number.isRequired,
};

export default React.memo(TimerCountdown);
