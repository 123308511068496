export enum SocketStatus {
  Open = 'OPEN',
  Closed = 'CLOSED',
}

export enum StorageDataType {
  Primitive = 'PRIMITIVE',
  Object = 'OBJECT',
  Map = 'MAP',
}
