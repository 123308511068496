import { connect } from 'react-redux';

import { stadiumDetailsSelectors } from 'packages/stadium-details';
import { statisticsSelectors, tournamentsSelectors } from 'packages/stadium-summary';

const mapStateToProps = (state) => {
  return {
    details: stadiumDetailsSelectors.getData(state),
    isDetailsLoading: stadiumDetailsSelectors.getIsLoading(state),
    homeTeams: statisticsSelectors.getHomeTeams(state),
    isHomeTeamsLoading: statisticsSelectors.getIsLoading(state),
    tournamentSeasons: tournamentsSelectors.getTournaments(state),
    isTournamentSeasonsLoading: tournamentsSelectors.getIsLoading(state),
  };
};

export default connect(mapStateToProps);
