import { getPath } from '../common';

import { Status } from '../../types';

export const getStatus = (state): Status => {
  return getPath(state).status;
};

export const getIsNewPageLoading = (state): boolean => {
  return getStatus(state).isNewPageLoading;
};

export const getIsInitialPageLoading = (state): boolean => {
  return getStatus(state).isInitialPageLoading;
};

export const getCount = (state): number => {
  return getStatus(state).count;
};

export const getOffset = (state): number => {
  return getStatus(state).offset;
};

export const getLimit = (state): number => {
  return getStatus(state).limit;
};
