import React from 'react';
import classnames from 'classnames';

import { Icon, Spinner, ToggleArrowButton } from 'packages/shared/components';
import { ArrowType } from 'packages/shared/components/ToggleArrowButton/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { SpinnerSize } from 'packages/shared/components/Spinner/types';
import { PopoverState } from 'packages/shared/components/FlexiblePopover/types';

import styles from './styles.scss';

type Props = {
  isDisabled?: boolean;
  selectedItemsCount: number;
  label: string;
  popoverState?: PopoverState;
  isLoading: boolean;
  onClear: () => void;
};

export const SelectInput = ({
  isDisabled,
  selectedItemsCount,
  onClear,
  label,
  popoverState,
  isLoading,
}: Props) => {
  const isOpen = popoverState === PopoverState.Opened;

  const handleClear = (ev: MouseEvent) => {
    ev.stopPropagation();
    onClear();
  };

  return (
    <div className={classnames(styles.wrapper, { [styles.disabled]: isDisabled })}>
      <span className={styles.labelWrapper}>
        <span className={styles.label}>{label}</span>{' '}
        {!!selectedItemsCount && <span>: {selectedItemsCount}</span>}
      </span>

      {!isOpen && !!selectedItemsCount && (
        <div className={styles.crossWrapper}>
          <Icon className={styles.cross} onMouseUp={handleClear} id={IconType.RoundedCross} />
        </div>
      )}

      <div className={styles.arrowWrapper}>
        {isLoading ? (
          <Spinner className={styles.spinner} size={SpinnerSize.Small} />
        ) : (
          <ToggleArrowButton isOpen={isOpen} type={ArrowType.Dropdown} />
        )}
      </div>
    </div>
  );
};

export default SelectInput;
