import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { RefereeCard } from 'packages/shared/components';
import { RefereeCardType } from 'packages/shared/components/RefereeCard/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import styles from './styles.scss';

type Props = {
  type: RefereeCardType;
  count?: number;
  playerId?: number;
  playerName?: string;
  isIconFirst?: boolean;
  isTookOnTheBench?: boolean;
  className?: string;
};

const RefereeCardHappening = ({
  type,
  count = 0,
  className = '',
  playerName = '',
  isIconFirst = false,
  isTookOnTheBench,
  playerId,
}: Props) => {
  const { appNavigate } = useNavigation();

  const containerClasses = classnames(styles.refereeCard, className, {
    [styles.iconFirst]: isIconFirst,
  });

  const countView = count > 1 ? count : '';

  const handlePlayerClick = () => {
    if (playerId) {
      appNavigate(DesktopPage.FootballPlayer, { playerId });
    }
  };

  return (
    <div className={containerClasses}>
      <div className={styles.description}>
        {!isEmpty(playerName) && (
          <span className={styles.player} onClick={handlePlayerClick}>
            {playerName}
          </span>
        )}
        {isTookOnTheBench === true && (
          <span className={styles.additionalInfoLabel}>
            {` (${i18n.value('eventSummary.timeLine.cardHappeningDescription.onBench')})`}
          </span>
        )}
      </div>
      <div className={styles.cardContainer}>
        <RefereeCard type={type} count={count} />
        <div className={styles.counter}>{countView}</div>
      </div>
    </div>
  );
};

export default memo(RefereeCardHappening);
