import { createElement } from 'react';

import { BaseHappening, GoalHappening, HappeningEventPeriod } from 'packages/event-time-line/types';
import { Qualifier } from 'types';
import { PenaltyHappening } from 'packages/event-summary/types';

import { SummaryHappeningType } from 'packages/events/types';
import {
  getChipElement,
  getGoalType,
  getMinuteChipValue,
  getScoreChipValue,
  getHappeningComponentConfig,
  getPenaltyHappeningComponentConfig,
  shouldInjuryTimeBeDisplayed,
} from '../../../services';
import { HappeningElementsGetterOptions, Theme } from '../../../../../types';

const getBothPositionsHappeningElementsByPeriodName = (
  happening: BaseHappening | PenaltyHappening,
  periodType?: string,
  theme?: Theme,
) => {
  switch (periodType) {
    case HappeningEventPeriod.PENALTIES:
      return getPenaltyPeriodHappeningElements(happening as PenaltyHappening);
    default: {
      const { injuryTime, minute } = happening as BaseHappening;
      const additionalProps = {
        happeningComponentProps: { theme },
        chipComponentProps: {
          theme,
          injuryTime,
          isInjuryTimeDisplayed: shouldInjuryTimeBeDisplayed(minute),
        },
      };

      return getDefaultPeriodHappeningElementsByHappeningName(
        happening as BaseHappening,
        additionalProps,
      );
    }
  }
};

const getPenaltyPeriodHappeningElements = (happening: PenaltyHappening) => {
  const { score } = happening;
  const homeGoalHappening = happening[Qualifier.Home];
  const awayGoalHappening = happening[Qualifier.Away];

  const homeComponentConfig = getPenaltyHappeningComponentConfig(homeGoalHappening, false);
  const awayComponentConfig = getPenaltyHappeningComponentConfig(awayGoalHappening, true);
  const homeHappeningElement = createElement(
    homeComponentConfig.component,
    homeComponentConfig.props,
  );
  const awayHappeningElement = createElement(
    awayComponentConfig.component,
    awayComponentConfig.props,
  );
  const chipValue = getScoreChipValue(score);
  const chipElement = getChipElement(chipValue);

  return { homeHappeningElement, chipElement, awayHappeningElement };
};

const getDefaultPeriodHappeningElementsByHappeningName = (
  happening: BaseHappening,
  additionalProps: HappeningElementsGetterOptions,
) => {
  const { eventType } = happening;

  switch (eventType) {
    case SummaryHappeningType.SCORE_CHANGE:
      return getGoalHappeningElements(happening as GoalHappening, additionalProps);
    default:
      return getDefaultPeriodHappeningElements(happening, additionalProps);
  }
};

const getGoalHappeningElements = (
  happening: GoalHappening,
  { happeningComponentProps, chipComponentProps }: HappeningElementsGetterOptions,
) => {
  happeningComponentProps = { ...happeningComponentProps, type: getGoalType(happening) };
  chipComponentProps = { ...chipComponentProps, isBold: true };

  return getDefaultPeriodHappeningElements(happening, {
    happeningComponentProps,
    chipComponentProps,
  });
};

const getDefaultPeriodHappeningElements = (
  happening: BaseHappening,
  { happeningComponentProps, chipComponentProps }: HappeningElementsGetterOptions = {
    happeningComponentProps: {},
    chipComponentProps: {},
  },
) => {
  const { minute } = happening;

  const chipValue = getMinuteChipValue(minute);
  const chipElement = getChipElement(chipValue, chipComponentProps);
  const [homeHappeningElement, awayHappeningElement] = getHappeningElements(
    happening,
    happeningComponentProps,
  );

  return { homeHappeningElement, chipElement, awayHappeningElement };
};

const getHappeningElements = (happening: BaseHappening, customProps: Record<any, any> = {}) => {
  const { team } = happening;
  const isAwayHappening = team === Qualifier.Away;
  const happeningConfig = getHappeningComponentConfig({
    happening,
    isReversed: isAwayHappening,
    customProps,
  });

  if (happeningConfig) {
    const { component, props } = happeningConfig;

    const happeningElement = createElement(component, props);
    const homeHappeningElement = !isAwayHappening ? happeningElement : null;
    const awayHappeningElement = isAwayHappening ? happeningElement : null;

    return [homeHappeningElement, awayHappeningElement];
  }

  return [undefined, undefined];
};

export default getBothPositionsHappeningElementsByPeriodName;
