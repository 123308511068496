import React, { MouseEvent, RefObject } from 'react';
import classnames from 'classnames';

import { Team } from 'types';
import { Figure, Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import { Size, Theme } from './types';
import styles from './styles.scss';

type Props = {
  team: Team;
  isLogoFirst?: boolean;
  isNameHidden?: boolean;
  className?: string;
  onLogoClick?: (team: Team) => void;
  onNameClick?: (team: Team) => void;
  theme?: Theme;
  size?: Size;
  forwardedRef?: RefObject<HTMLDivElement>;
};

const TeamLabel = ({
  team,
  isLogoFirst = false,
  isNameHidden = false,
  theme = Theme.Dark,
  size = Size.Md,
  className,
  onLogoClick,
  onNameClick,
  forwardedRef,
}: Props) => {
  const containerClasses = classnames(
    styles.teamLabel,
    styles[size],
    styles[theme],
    { [styles.logoFirst]: isLogoFirst, [styles.nameHidden]: isNameHidden },
    className,
  );

  const handleLogoClick = (event: MouseEvent<HTMLElement>) => {
    if (onLogoClick) {
      event.stopPropagation();
      onLogoClick(team);
    }
  };

  const handleNameClick = (event: MouseEvent<HTMLElement>) => {
    if (onNameClick) {
      event.stopPropagation();
      onNameClick(team);
    }
  };

  return (
    <div className={containerClasses}>
      {!isNameHidden && (
        <div
          ref={forwardedRef}
          onClick={handleNameClick}
          className={classnames(styles.name, {
            [styles.isClickable]: !!onNameClick,
          })}
        >
          {team?.name || ''}
        </div>
      )}
      {team?.logo ? (
        <Figure
          onClick={handleLogoClick}
          src={team.logo || '/images/default_team_logo.png'}
          className={classnames(styles.logo, styles[size], styles[theme], {
            [styles.isClickable]: !!onLogoClick,
          })}
        />
      ) : (
        <div
          onClick={handleLogoClick}
          className={classnames(styles.logo, styles[size], styles[theme], {
            [styles.isClickable]: !!onLogoClick,
          })}
        >
          <Icon id={IconType.NotFound} />
        </div>
      )}
    </div>
  );
};

export default React.memo(TeamLabel);
