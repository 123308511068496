import { PopoverJustify } from '../types/enums';

const calculateJustify = (
  initialJustifyValue: number,
  targetRect: ClientRect,
  popoverWidth: number,
  justify: PopoverJustify,
): number => {
  switch (justify) {
    case PopoverJustify.Left: {
      return calculatePopoverLeftJustify(initialJustifyValue, targetRect, popoverWidth);
    }
    case PopoverJustify.CenterStart: {
      return calculatePopoverCenterStartJustify(initialJustifyValue, targetRect);
    }
    case PopoverJustify.Center: {
      return calculatePopoverCenterJustify(initialJustifyValue, targetRect, popoverWidth);
    }
    case PopoverJustify.CenterEnd: {
      return calculatePopoverCenterEndJustify(initialJustifyValue, targetRect, popoverWidth);
    }
    case PopoverJustify.Right: {
      return calculatePopoverRightJustify(initialJustifyValue, targetRect);
    }
    default: {
      throw Error('Invalid justify specified');
    }
  }
};

const calculatePopoverLeftJustify = (
  initialJustifyValue: number,
  targetRect: ClientRect,
  popoverWidth: number,
) => {
  return targetRect.left - popoverWidth + initialJustifyValue;
};

const calculatePopoverCenterStartJustify = (
  initialJustifyValue: number,
  targetRect: ClientRect,
) => {
  return targetRect.left + initialJustifyValue;
};

const calculatePopoverCenterJustify = (
  initialJustifyValue: number,
  targetRect: ClientRect,
  popoverWidth: number,
) => {
  return targetRect.left + targetRect.width / 2 - popoverWidth / 2 + initialJustifyValue;
};

const calculatePopoverCenterEndJustify = (
  initialJustifyValue: number,
  targetRect: ClientRect,
  popoverWidth: number,
) => {
  return targetRect.right - popoverWidth + initialJustifyValue;
};

const calculatePopoverRightJustify = (initialJustifyValue: number, targetRect: ClientRect) => {
  return targetRect.right + initialJustifyValue;
};

export default calculateJustify;
