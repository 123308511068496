import React, { memo, useEffect, useRef, useState } from 'react';
import i18n from 'i18n-smart';

import { EventActiveFilters, Season, Tournament } from 'packages/team-events/types';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';
import { Button, DatePicker, FlexiblePopover, Icon } from 'packages/shared/components';
import { YearItem } from 'packages/shared/components/Calendar/types';
import { ButtonType } from 'packages/shared/components/Button/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { LocaleCode } from 'types';

import { YEARS_SELECT_RANGE } from '../../../../constants';
import { FiltrationPopover, HeaderSkeleton } from '../../../../components';
import styles from './styles.scss';

type Props = {
  tournaments: Tournament[];
  onPeriodChange: (dates: [string, string]) => void;
  onFilterUpdate: (
    period: [string, string],
    selectedSeasons: Season[],
    tournament?: Tournament,
  ) => void;
  activeFilters: EventActiveFilters;
  eventsCount: number;
  onYearClick: (year: YearItem) => void;
  isLoading: boolean;
};

const EventsFilter = ({
  tournaments,
  activeFilters,
  onPeriodChange,
  eventsCount,
  onYearClick,
  onFilterUpdate,
  isLoading,
}: Props) => {
  if (isLoading) {
    return <HeaderSkeleton isDateShrink isCompact />;
  }

  const containerRef = useRef<HTMLDivElement>(null);
  const [popoverTopOffset, setPopoverTopOffset] = useState(0);

  useEffect(() => {
    const { top = 0 } = containerRef?.current?.getBoundingClientRect() || {};

    setPopoverTopOffset(top);
  }, []);

  const { period } = activeFilters;

  return (
    <div ref={containerRef} className={styles.eventsFilter}>
      <div className={styles.title}>{i18n.value('team.teamEvents.filters.title')}</div>
      <div className={styles.filterContainer}>
        <DatePicker
          isRangeMode
          useBackdrop
          value={period}
          onApplyClick={onPeriodChange}
          eventsCount={eventsCount}
          yearOffset={YEARS_SELECT_RANGE}
          localeCode={LocaleCode.En}
          onYearClick={onYearClick}
          className={styles.datepicker}
        />
        <FlexiblePopover
          popoverProps={{
            tournaments,
            activeFilters,
            onFilterUpdate,
            eventsCount,
          }}
          popoverComponent={FiltrationPopover}
          justify={PopoverJustify.Center}
          align={PopoverAlign.CenterStart}
          customPosition={{ top: popoverTopOffset }}
          disableBodyOverflow
          useChildrenWrapper
          useBackdrop
          isModal
        >
          <Button type={ButtonType.Default} contentClassName={styles.settingsButtonContent}>
            <Icon id={IconType.Configuration} className={styles.icon} />
          </Button>
        </FlexiblePopover>
      </div>
    </div>
  );
};

export default memo(EventsFilter);
