import { Row } from 'react-table';

import { Player } from 'packages/team-squad/types';

type Params = {
  onClick: (player: Player) => void;
};

const getPlayerRowProps = ({ onClick }: Params) => ({ original }: Row<Player>) => {
  return {
    onClick: () => onClick(original),
  };
};

export default getPlayerRowProps;
