import React, { MutableRefObject } from 'react';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';

import { IconType } from 'packages/shared/components/Icon/types';
import styles from './styles.scss';

type Props = {
  forwardedRef: MutableRefObject<HTMLDivElement | null>;
  onClick: () => void;
  reversed?: boolean;
  className?: string;
};

const NavigationButton = ({ forwardedRef, onClick, reversed, className }: Props) => {
  return (
    <div
      ref={forwardedRef}
      onClick={onClick}
      className={classnames(styles.navigationButton, { [styles.reversed]: reversed }, className)}
    >
      <Icon id={IconType.Arrow} className={styles.icon} />
    </div>
  );
};

export default NavigationButton;
