import React, { memo, ReactElement, useRef } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { MultipleSelect } from 'packages/shared/components';
import { SelectItem, SelectTheme } from 'packages/shared/components/Select/types';

import { ComponentLike } from 'types';
import { Label } from '../../components';
import styles from './styles.scss';

type Props = {
  name: string;
  onChange: (item: SelectItem[]) => void;
  items: SelectItem[];
  selectedItems: SelectItem[];
  pluralItemLabel: string;
  label?: string | ReactElement;
  itemComponent?: ComponentLike;
  theme?: SelectTheme;
  className?: string;
  useScrollIntoPopoverView?: boolean;
};

const Multiselect = ({
  selectedItems,
  pluralItemLabel,
  items,
  onChange,
  label,
  name,
  theme,
  itemComponent,
  className,
  useScrollIntoPopoverView = false,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className={classnames(styles.multipleInputContainer, className)}>
      {!isEmpty(label) && <Label name={name} label={label} />}
      <MultipleSelect
        useScrollIntoPopoverView={useScrollIntoPopoverView}
        scrollElementRef={containerRef}
        selectedItems={selectedItems}
        className={styles.multiSelect}
        pluralItemLabel={pluralItemLabel}
        itemComponent={itemComponent}
        onChange={onChange}
        items={items}
        theme={theme}
      />
    </div>
  );
};

export default memo(Multiselect);
