import { PopoverAlign, PopoverCustomPosition, PopoverJustify } from '../types';
import calculateJustifyWithoutTarget from './calculateJustifyWithoutTarget';
import calculateAlignWithoutTarget from './calculateAlignWithoutTarget';

interface Params {
  justify: PopoverJustify;
  align: PopoverAlign;
  popoverEl: HTMLDivElement;
  getParent: () => HTMLElement | null;
  insertIntoParent?: boolean;
  customPosition?: PopoverCustomPosition;
}

const calculatePopoverPositionWithoutTarget = ({
  justify,
  align,
  popoverEl,
  getParent,
  insertIntoParent,
  customPosition,
}: Params): { top: number; left: number } => {
  const left = calculateJustifyWithoutTarget(
    justify,
    popoverEl,
    getParent,
    insertIntoParent,
    customPosition?.left,
  );
  const top = calculateAlignWithoutTarget(
    align,
    popoverEl,
    getParent,
    insertIntoParent,
    customPosition?.top,
  );

  return { top, left };
};

export default calculatePopoverPositionWithoutTarget;
