import Dexie from 'dexie';

import { keyValueDatabase } from 'packages/storage';
import { Tables, FavoritesEventsTableIndex } from 'configure/storage/types';
import { Event } from 'types';

import {
  updateFavoriteEventsStart,
  updateFavoriteEventsFailure,
  updateFavoriteEventsSuccess,
} from '../actions';
import countFavoriteEventInAllCategories from './countFavoriteEventInAllCategories';
import loadLatestEventsFromStorage from './loadLatestEventsFromStorage';

const toggleEvent = (event: Event) => async (dispatch) => {
  try {
    dispatch(updateFavoriteEventsStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);
    const favoriteTeamsModel = dexieInstance.table(Tables.FAVORITE_TEAMS);
    const favoriteEventsModel = dexieInstance.table(Tables.FAVORITE_EVENTS);
    const favoriteLeaguesModel = dexieInstance.table(Tables.FAVORITE_LEAGUES);

    const [favoriteEventCategoriesCount, isEventFavorite] = await dexieInstance.transaction(
      'rw',
      [favoritesModel, favoriteTeamsModel, favoriteEventsModel, favoriteLeaguesModel],
      () =>
        Promise.all([
          dispatch(countFavoriteEventInAllCategories(event)),
          favoriteEventsModel
            .where(FavoritesEventsTableIndex.Id)
            .equals(event.id)
            .first(),
        ]),
    );

    if (isEventFavorite) {
      if (favoriteEventCategoriesCount === 1) {
        await favoritesModel.delete(event.id);
      }

      await favoriteEventsModel.delete(event.id);
    } else {
      await dexieInstance.transaction('rw', [favoritesModel, favoriteEventsModel], () =>
        Promise.all([favoritesModel.put(event), favoriteEventsModel.put(event)]),
      );
    }

    const latestFavoriteEvents = await dispatch(loadLatestEventsFromStorage());

    dispatch(updateFavoriteEventsSuccess(latestFavoriteEvents));
  } catch (e) {
    dispatch(updateFavoriteEventsFailure(e));
  }
};

export default toggleEvent;
