import { Players } from 'packages/rest';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';

import {
  loadPlayerDetailsStart,
  loadPlayerDetailsSuccess,
  loadPlayerDetailsFailure,
} from '../actions';
import { mapDetails, mapTournamentSeasons } from '../services';

interface Params {
  playerId: number;
}

const loadDetails = ({ playerId }: Params) => async (dispatch) => {
  try {
    dispatch(loadPlayerDetailsStart());

    let actualTournamentSeasons: SeasonsExtendedTournament[] = [];
    const { data: detailsData } = await Players.loadDetails({ playerId });
    let mappedDetails = mapDetails(detailsData.results);
    const { mainPlayerTeam } = mappedDetails || {};

    if (mainPlayerTeam?.team.id) {
      const { data: tournamentSeasonsData } = await Players.loadTournamentSeasons({
        teamId: mainPlayerTeam.team.id,
        playerId,
      });

      actualTournamentSeasons = mapTournamentSeasons(tournamentSeasonsData.results);
    }

    dispatch(loadPlayerDetailsSuccess({ ...mappedDetails, actualTournamentSeasons }));
  } catch (e) {
    dispatch(loadPlayerDetailsFailure());
  }
};

export default loadDetails;
