import React, { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigation } from 'packages/hooks';

import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { DesktopPage } from 'router/types';

import TournamentSeasonDetailsView from './views';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  tournaments?: SeasonsExtendedTournament[];
  isLoading: boolean;
};

const extendTournamentSeasonDetails = (
  TournamentSeasonDetails: typeof TournamentSeasonDetailsView[keyof typeof TournamentSeasonDetailsView],
) =>
  memo(({ tournaments, isLoading }: Props) => {
    const { stadiumId } = useParams();
    const { appNavigate } = useNavigation();

    const handleTournamentClick = useCallback(
      (tournament: SeasonsExtendedTournament) => {
        if (stadiumId) {
          const [season] = tournament.seasons;

          appNavigate(DesktopPage.FootballStadiumSummary, {
            stadiumId,
            uniqueTournamentId: tournament.id,
            seasonId: season.id,
          });
        }
      },
      [stadiumId],
    );

    return (
      <div className={styles.tournamentSeasonDetails}>
        <TournamentSeasonDetails
          onTournamentClick={handleTournamentClick}
          tournaments={tournaments}
          isLoading={isLoading}
        />
      </div>
    );
  });

export default {
  Desktop: connect(extendTournamentSeasonDetails(TournamentSeasonDetailsView.Desktop)),
  DesktopNarrow: connect(extendTournamentSeasonDetails(TournamentSeasonDetailsView.DesktopNarrow)),
  Tablet: connect(extendTournamentSeasonDetails(TournamentSeasonDetailsView.Tablet)),
  TabletNarrow: connect(extendTournamentSeasonDetails(TournamentSeasonDetailsView.TabletNarrow)),
  Mobile: connect(extendTournamentSeasonDetails(TournamentSeasonDetailsView.Mobile)),
};
