import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadStagesStart = createAction(`${NAMESPACE}LOAD_MOBILE_FILTER_STAGES_START`);
export const loadStagesSuccess = createAction(`${NAMESPACE}LOAD_MOBILE_FILTER_STAGES_SUCCESS`);
export const loadStagesFailure = createAction(`${NAMESPACE}LOAD_MOBILE_FILTER_STAGES_FAILURE`);
export const resetStagesSuccess = createAction(`${NAMESPACE}RESET_MOBILE_FILTER_STAGES_SUCCESS`);

export const setDefaultFilter = createAction(`${NAMESPACE}SET_DEFAULT_MOBILE_FILTER`);

export const cleanMobileFilterSuccess = createAction(`${NAMESPACE}CLEAN_MOBILE_FILTER_SUCCESS`);
