import { Team } from 'types';

import { TeamSelectOption } from '../../../../../types';
import getTeamSelectItem from './getTeamSelectItem';

const getTeamSelectItems = (rounds: Team[]): TeamSelectOption[] => {
  return rounds.map((round) => getTeamSelectItem(round));
};

export default getTeamSelectItems;
