import { Season } from 'packages/team-events/types';
import DateService from 'packages/date-az';

const sortSeasonsByDates = (seasons: Season[]) => seasons.sort(compareSeasons);

const compareSeasons = (leftItem: Season, rightItem: Season): number => {
  if (DateService.parse(leftItem.startDate) > DateService.parse(rightItem.startDate)) {
    return 1;
  }

  return -1;
};

export default sortSeasonsByDates;
