import { useEffect, useRef } from 'react';
import { PaletteColors } from 'react-palette';

const usePalettesGradient = <ElementType extends HTMLElement>(
  firstPalette: PaletteColors,
  secondPalette: PaletteColors,
) => {
  const targetRef = useRef<ElementType>(null);

  useEffect(() => {
    if (firstPalette.vibrant && secondPalette.vibrant && targetRef.current) {
      // eslint-disable-next-line max-len
      targetRef.current.style.background = `linear-gradient(90deg, ${firstPalette.vibrant} 0%, #171725 40%, #171725 60%, ${secondPalette.vibrant} 100%)`;
    }
  }, [firstPalette, secondPalette]);

  return targetRef;
};

export default usePalettesGradient;
