import Dexie from 'dexie';

import { keyValueDatabase } from 'packages/storage';
import { Tables, FavoritesTeamsTableIndex } from 'configure/storage/types';
import { Event, EventTeam } from 'types';

import {
  updateFavoriteTeamsFailure,
  updateFavoriteTeamsStart,
  updateFavoriteTeamsSuccess,
} from '../actions';
import countFavoriteEventInAllCategories from './countFavoriteEventInAllCategories';
import extendItemWithOrder from './extendItemWithOrder';
import loadLatestTeamsFromStorage from './loadLatestTeamsFromStorage';

const toggleTeamEvent = (event: Event, team: EventTeam) => async (dispatch) => {
  try {
    dispatch(updateFavoriteTeamsStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);
    const favoriteTeamsModel = dexieInstance.table(Tables.FAVORITE_TEAMS);
    const favoriteEventsModel = dexieInstance.table(Tables.FAVORITE_EVENTS);
    const favoriteLeaguesModel = dexieInstance.table(Tables.FAVORITE_LEAGUES);

    const [favoriteEventCategoriesCount, isTeamFavorite] = await dexieInstance.transaction(
      'rw',
      [favoritesModel, favoriteTeamsModel, favoriteEventsModel, favoriteLeaguesModel],
      () =>
        Promise.all([
          dispatch(countFavoriteEventInAllCategories(event)),
          favoriteTeamsModel
            .where(FavoritesTeamsTableIndex.Id)
            .equals(team.id)
            .first(),
        ]),
    );

    if (isTeamFavorite) {
      if (favoriteEventCategoriesCount === 1) {
        await favoritesModel.delete(event.id);
      }

      await favoriteTeamsModel.delete(team.id);
    } else {
      if (favoriteEventCategoriesCount === 0) {
        await favoritesModel.put(event);
      }

      const orderNumberExtendedTeam = await dispatch(
        extendItemWithOrder(team, Tables.FAVORITE_TEAMS),
      );
      await favoriteTeamsModel.put(orderNumberExtendedTeam);
    }

    const latestFavoriteTeams = await dispatch(loadLatestTeamsFromStorage());

    dispatch(updateFavoriteTeamsSuccess(latestFavoriteTeams));
  } catch (e) {
    dispatch(updateFavoriteTeamsFailure(e));
  }
};

export default toggleTeamEvent;
