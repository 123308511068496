import React, { CSSProperties, ReactElement, RefObject } from 'react';

import { useMutableState } from 'packages/hooks';
import { DraggableScrollArea } from 'packages/shared/components';

import { ScrollDirection } from './types';

type Props = {
  children: ReactElement;
  forwardedRef?: RefObject<HTMLDivElement>;
  onScroll?: (e: Event) => void;
  onScrollLeft?: () => void;
  onScrollStart?: (e: Event) => void;
  onScrollTop?: () => void;
  onScrollTopEnd?: (e: Event) => void;
  onScrollLeftEnd?: (e: Event) => void;
  className?: string;
  style?: CSSProperties;
};

const SingleDirectedDraggableScrollArea = ({
  children,
  onScrollLeft,
  onScrollTopEnd,
  onScrollLeftEnd,
  onScrollTop,
  ...props
}: Props) => {
  const [getScrollDirection, setScrollDirection] = useMutableState<null | ScrollDirection>(null);

  const handleScrollEnd = (e: Event) => {
    if (getScrollDirection() === ScrollDirection.Horizontal) {
      onScrollLeftEnd?.(e);
    } else {
      onScrollTopEnd?.(e);
    }
    setScrollDirection(null);
  };

  const scrollContainerTo = (scrollContainer: HTMLDivElement, scrollLeft, scrollTop) => {
    initializeScrollDirection(scrollContainer, scrollLeft, scrollTop);
    scrollByDirection(scrollContainer, scrollLeft, scrollTop);
  };

  const initializeScrollDirection = (scrollContainer: HTMLDivElement, scrollLeft, scrollTop) => {
    if (getScrollDirection() === null) {
      const scrollTopDiff = Math.abs(scrollTop - scrollContainer.scrollTop);
      const scrollLeftDiff = Math.abs(scrollLeft - scrollContainer.scrollLeft);

      if (scrollTopDiff > scrollLeftDiff) {
        setScrollDirection(ScrollDirection.Vertical);
      } else {
        setScrollDirection(ScrollDirection.Horizontal);
      }
    }
  };

  const scrollByDirection = (scrollContainer: HTMLDivElement, scrollLeft, scrollTop) => {
    if (getScrollDirection() === ScrollDirection.Horizontal) {
      scrollContainer.scroll({ left: scrollLeft });
      onScrollLeft?.();
    } else {
      scrollContainer.scroll({ top: scrollTop });
      onScrollTop?.();
    }
  };

  return (
    <DraggableScrollArea
      scrollContainerTo={scrollContainerTo}
      onScrollEnd={handleScrollEnd}
      {...props}
    >
      {children}
    </DraggableScrollArea>
  );
};

export default SingleDirectedDraggableScrollArea;
