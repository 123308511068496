import React, { memo } from 'react';

import { Sport } from 'types';
import { Search } from 'packages/search-ui';

import { Logo, SportNavigation } from '../../components';
import { Settings } from './components';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  sports: Sport[];
};

const Header = ({ sports }: Props) => {
  return (
    <header className={styles.headerContainer}>
      <Logo />

      <div className={styles.upperMenu}>
        <SportNavigation sports={sports} />

        <div className={styles.rightBlock}>
          <Search.Desktop className={styles.search} />
          <Settings />
        </div>
      </div>
    </header>
  );
};

export default connect(memo(Header));
