exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1QupghMX{display:flex;flex-direction:column}._1QupghMX ._3Z0sZYkk{padding:16px}._1QupghMX ._34eaoMZZ ._11jn-CgG:first-of-type{border-top:unset}._1QupghMX ._34eaoMZZ ._3Z0sZYkk{z-index:4}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/team-squad-ui/containers/TeamSquad/components/LoadingSkeleton/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,qBAAsB,CAH1B,sBAMM,YCRQ,CDEd,+CAYU,gBAAiB,CAZ3B,iCAiBQ,SAAU","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .container {\n    display: flex;\n    flex-direction: column;\n\n    .header {\n      padding: $padding;\n    }\n\n    .table {\n      .table-row {\n        &:first-of-type {\n          border-top: unset;\n        }\n      }\n\n      .header {\n        z-index: 4;\n      }\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"container": "_1QupghMX",
	"header": "_3Z0sZYkk",
	"table": "_34eaoMZZ",
	"table-row": "_11jn-CgG",
	"tableRow": "_11jn-CgG"
};