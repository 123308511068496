export enum RangeFilterGroupType {
  Attack = 'Attack',
  Aggression = 'Aggression',
  Defence = 'Defence',
  Goals = 'Goals',
  Other = 'Other',
}

export enum PresetType {
  Summary = 'Summary',
  Goals = 'Goals',
  Attack = 'Attack',
  Aggression = 'Aggression',
  Defence = 'Defence',
  Custom = 'Custom',
}

export enum ColumnType {
  Rank = 'Rank',
  ClubLogo = 'ClubLogo',
  Club = 'Club',
  TotalShots = 'TotalShots',
  ShotsOnGoal = 'ShotsOnGoal',
  ShotsOffGoal = 'ShotsOffGoal',
  ShotsBlocked = 'ShotsBlocked',
  ShotsOnBar = 'ShotsOnBar',
  ShotsOnPost = 'ShotsOnPost',
  ShootingEfficiency = 'ShootingEfficiency',
  Possession = 'Possession',
  CornerKicks = 'CornerKicks',
  FreeKicks = 'FreeKicks',
  Offside = 'Offside',
  YellowCards = 'YellowCards',
  YellowRedCards = 'YellowRedCards',
  RedCards = 'RedCards',
  GoalsConceded = 'GoalsConceded',
  GoalsScored = 'GoalsScored',
  Headed = 'Headed',
  FootGoals = 'FootGoals',
  GamesPlayed = 'GamesPlayed',
}

export enum RangeType {
  MinTotalShots = 'minTotalShots',
  MaxTotalShots = 'maxTotalShots',
  MinShotsOnGoal = 'minShotsOnGoal',
  MaxShotsOnGoal = 'maxShotsOnGoal',
  MinShotsOffGoal = 'minShotsOffGoal',
  MaxShotsOffGoal = 'maxShotsOffGoal',
  MinShotsBlocked = 'minShotsBlocked',
  MaxShotsBlocked = 'maxShotsBlocked',
  MinShotsOnPost = 'minShotsOnPost',
  MaxShotsOnPost = 'maxShotsOnPost',
  MinShotsOnBar = 'minShotsOnBar',
  MaxShotsOnBar = 'maxShotsOnBar',
  MinShootingEfficiency = 'minShootingEfficiency',
  MaxShootingEfficiency = 'maxShootingEfficiency',
  MinPossession = 'minPossession',
  MaxPossession = 'maxPossession',
  MinCornerKicks = 'minCornerKicks',
  MaxCornerKicks = 'maxCornerKicks',
  MinFreeKicks = 'minFreeKicks',
  MaxFreeKicks = 'maxFreeKicks',
  MinOffside = 'minOffside',
  MaxOffside = 'maxOffside',
  MinYellowCards = 'minYellowCards',
  MaxYellowCards = 'maxYellowCards',
  MinYellowRedCards = 'minYellowRedCards',
  MaxYellowRedCards = 'maxYellowRedCards',
  MinRedCards = 'minRedCards',
  MaxRedCards = 'maxRedCards',
  MinGoalConceded = 'minGoalConceded',
  MaxGoalConceded = 'maxGoalConceded',
  MinGoalsScored = 'minGoalScored',
  MaxGoalsScored = 'maxGoalScored',
  MinHeaded = 'minHeaded',
  MaxHeaded = 'maxHeaded',
  MinFootGoals = 'minFootGoals',
  MaxFootGoals = 'maxFootGoals',
  MinGamesPlayed = 'minGamesPlayed',
  MaxGamesPlayed = 'maxGamesPlayed',
}

export enum StatType {
  All = 'All',
  PerGame = 'PerGame',
}
