import Dexie from 'dexie';

import { Event } from 'types';
import { keyValueDatabase } from 'packages/storage';
import {
  Tables,
  FavoritesLeaguesTableIndex,
  FavoritesEventsTableIndex,
  FavoritesTeamsTableIndex,
} from 'configure/storage/types';

const countFavoriteEventInAllCategories = (event: Event) => async (): Promise<number> => {
  const { id, tournament, teams } = event;
  const teamIds = teams.map(({ id }) => id);

  const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
  const favoriteEventsModel = dexieInstance.table(Tables.FAVORITE_EVENTS);
  const favoriteTeamsModel = dexieInstance.table(Tables.FAVORITE_TEAMS);
  const favoriteLeaguesModel = dexieInstance.table(Tables.FAVORITE_LEAGUES);

  const favoritesCounts = await dexieInstance.transaction(
    'r',
    [favoriteEventsModel, favoriteTeamsModel, favoriteLeaguesModel],
    () =>
      Promise.all([
        favoriteEventsModel
          .where(FavoritesEventsTableIndex.Id)
          .equals(id)
          .count(),
        favoriteTeamsModel
          .where(FavoritesTeamsTableIndex.Id)
          .anyOf(teamIds)
          .count(),
        favoriteLeaguesModel
          .where(FavoritesLeaguesTableIndex.UniqueTournamentId)
          .equals(tournament.uniqueTournamentId)
          .count(),
      ]),
  );

  return favoritesCounts.reduce((acc, curr) => acc + curr, 0);
};

export default countFavoriteEventInAllCategories;
