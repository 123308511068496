import { SeasonTopBoard } from 'packages/rest';

import * as actions from '../actions';
import * as services from '../services';
import { TopOfTheSeason } from '../types';

export const loadTopOfTheSeason = (seasonId: number) => async (dispatch) => {
  try {
    dispatch(actions.loadTopOfTheSeasonStart());

    const { data } = await SeasonTopBoard.loadTopOfTheSeason({
      seasonId,
    });
    const topOfTheSeason: TopOfTheSeason = services.mapTopOfTheSeason(data.results);

    dispatch(actions.loadTopOfTheSeasonSuccess(topOfTheSeason));
  } catch (e) {
    dispatch(actions.loadTopOfTheSeasonFailure(e));
  }
};
