import _groupBy from 'lodash.groupby';
import _orderBy from 'lodash.orderby';

import { isArray, isEmpty } from 'packages/utils/Object.utils';

export const groupBy = _groupBy;
export const orderBy = _orderBy;

export const mergeWithoutIdentifierDuplicates = <T = any>(...mergingArrays: T[][]) => {
  const identifierComparisonPredicate = (firstElement, secondElement) =>
    firstElement.id === secondElement.id;

  return mergeWithoutDuplicates<T>(identifierComparisonPredicate, ...mergingArrays);
};

export const mergeWithoutDuplicates = <T = any>(
  predicate: (firstComparingValue, secondComparingValue) => boolean,
  ...mergingArrays: T[][]
): T[] => {
  if (isEmpty(mergingArrays)) {
    return [];
  }

  let [uniqueArray, ...restMergingArrays] = mergingArrays;

  if (isEmpty(restMergingArrays)) {
    return uniqueArray;
  }

  for (const mergingArray of mergingArrays) {
    for (const mergingElement of mergingArray) {
      const hasDuplicate = uniqueArray.find((uniqueElement) =>
        predicate(uniqueElement, mergingElement),
      );

      if (!hasDuplicate) {
        uniqueArray = [...uniqueArray, mergingElement];
      }
    }
  }

  return uniqueArray;
};

export const groupByWithExistingOrder = (arr: any[], predicate: Function | string) => {
  return [
    ...arr
      .reduce((acc, curr) => {
        const key = typeof predicate === 'string' ? curr[predicate] : predicate(curr);
        const existingData = acc.get(key);

        return existingData ? acc.set(key, [...existingData, curr]) : acc.set(key, [curr]);
      }, new Map())
      .values(),
  ];
};

export const extractKeys = (arr = [], key: string) => {
  return arr.map((item) => item[key]);
};

export const findById = (arr: any[] = [], id: string | number) => {
  return findBy(arr, 'id', id);
};

export const findBy = (arr: any[] = [], key: string, value: string | number) => {
  return arr.find((item) => item[key] === value);
};

export const filterById = (arr: any[], id: string | number) => {
  return filterBy(arr, 'id', id);
};

export const filterBy = (arr: any[] = [], key = '', value: boolean | string | number = true) => {
  return arr.filter((item) => item[key] === value);
};

export const reorder = (arr: any[], oldIndex, newIndex): any[] => {
  const result = Array.from(arr);
  const [removed] = result.splice(oldIndex, 1);
  result.splice(newIndex, 0, removed);

  return result;
};

export const excludeById = (arr: any = [], item: any) => {
  return excludeBy(arr, 'id', item);
};

export const excludeBy = (arr: any = [], key = '', value: any) => {
  return arr.filter((item) => item[key] !== value);
};

export const createMap = (arr: any[], key: string) => {
  return arr.reduce((acc, item) => {
    acc.set(item[key], item);

    return acc;
  }, new Map());
};

export const toArray = <T>(value: T | T[]): T[] => {
  return isArray(value) ? (value as T[]) : [value as T];
};

export const removeOne = <T>(arr: T[], item: T): void => {
  const index: number = arr.indexOf(item);
  if (index !== -1) {
    arr.splice(index, 1);
  }
};

export const remove = <T>(arr: T[], value: T | T[]): void => {
  toArray(value).forEach((item) => removeOne(arr, item));
};

export const getArrayFromNumber = (length: number): number[] => {
  return Array.from(new Array(length), (element, index) => index);
};
