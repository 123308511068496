import React from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed/lib';

import { CardsExtendedCompetitor, EventPlayerStatistics } from 'types';
import { TeamLabel } from 'packages/shared/components';

import { EventFines } from '../../../../components';
import styles from './styles.scss';
import { PlayerStatistic } from './components';

type Props = {
  teams: CardsExtendedCompetitor[];
  isLive: boolean;
  className?: string;
  isTeamFinesShown?: boolean;
  isPlayerStatsShown?: boolean;
  playerStats?: EventPlayerStatistics;
};

const TeamContent = ({
  isTeamFinesShown = true,
  isPlayerStatsShown = false,
  teams,
  className,
  isLive,
  playerStats,
}: Props) => {
  const [home, away] = teams;

  return (
    <div className={classnames(styles.teamContent, className, { [styles.live]: isLive })}>
      <div className={styles.teamWrapper}>
        <div className={styles.team}>
          <TeamLabel className={styles.name} team={home} isLogoFirst />
          {isTeamFinesShown && <EventFines fines={home.cards} />}
        </div>

        <div className={styles.team}>
          <TeamLabel className={styles.name} team={away} isLogoFirst />
          {isTeamFinesShown && <EventFines fines={away.cards} />}
        </div>
      </div>

      {isPlayerStatsShown && !isEmpty(playerStats) && (
        <PlayerStatistic
          cards={playerStats.cards}
          goals={playerStats.goalsScored}
          assists={playerStats.assists}
        />
      )}

      <div className={styles.scoreWrapper}>
        <div>{home.score}</div>

        <div>{away.score}</div>
      </div>
    </div>
  );
};

export default React.memo(TeamContent);
