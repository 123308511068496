import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { NewcomersStatus } from '../types';

const defaultState: NewcomersStatus = {
  isLoading: true,
};

const reducerMap: ReducerMap<NewcomersStatus, any> = {
  [`${actions.loadNewcomersStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(actions.loadNewcomersFailure, actions.loadNewcomersSuccess)}`]: () => {
    return { isLoading: false };
  },
};

export default handleActions<NewcomersStatus>(reducerMap, defaultState);
