import React, { useCallback, useEffect, useMemo } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { getGroupedEvents } from 'packages/events/services';
import { Event, Tournament, TimeZone } from 'types';

import EventList from '../../../EventList';
import connect from './connect';

type Props = {
  finishedEventsActions: ActionCreatorsMapObject;
  timeZone: TimeZone;
  scrollContainerIdentifier: string;
  favoriteTournaments: Tournament[];
  handleEventClick: () => void;
  handleTournamentClick: Function;
  activeSportId: number;
  activeEventId: number;
  categoryId?: number;
  activeDate: string;
  events: Event[];
  isCompact?: boolean;
  useModalOnCategoryClick?: boolean;
  isLoading: boolean;
  hasNext: boolean;
};

const Finished = ({
  scrollContainerIdentifier,
  favoriteTournaments,
  timeZone,
  handleEventClick,
  handleTournamentClick,
  finishedEventsActions,
  activeSportId,
  activeEventId,
  activeDate,
  categoryId,
  isCompact = false,
  useModalOnCategoryClick = false,
  isLoading,
  hasNext,
  events,
}: Props) => {
  useEffect(() => {
    finishedEventsActions.loadFinishedEvents({ categoryId });

    return () => {
      finishedEventsActions.cleanFinishedEvents();
    };
  }, [activeDate, categoryId, timeZone.value]);

  useEffect(() => {
    const unsubscribe = finishedEventsActions.subscribeForFinishedEventsUpdates();

    return unsubscribe;
  }, []);

  const groupedEvents = useMemo(() => getGroupedEvents(events, favoriteTournaments), [
    events,
    favoriteTournaments,
  ]);

  const handleLoadMoreEvents = useCallback(() => {
    finishedEventsActions.loadMoreFinishedEvents({ categoryId });
  }, [categoryId]);

  return (
    <EventList
      scrollContainerIdentifier={scrollContainerIdentifier}
      onLoadMore={handleLoadMoreEvents}
      tournaments={groupedEvents}
      handleEventClick={handleEventClick}
      handleTournamentClick={handleTournamentClick}
      activeSportId={activeSportId}
      activeEventId={activeEventId}
      eventsLength={events.length}
      isLoading={isLoading}
      isCompact={isCompact}
      useModalOnCategoryClick={useModalOnCategoryClick}
      hasNext={hasNext}
    />
  );
};

export default connect(Finished);
