import * as eventDetailsSelectors from './selectors';
import * as eventDetailsActions from './actions';
import * as eventDetailsThunks from './thunks';
import * as eventDetailsConstants from './constants';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  eventDetailsActions,
  createReducer,
  eventDetailsSelectors,
  eventDetailsConstants,
  eventDetailsThunks,
};
