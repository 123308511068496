import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { detailsActions, commonActions } from '../../actions';
import { Interfaces } from '../../types';

const defaultState: Interfaces.Details.Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Interfaces.Details.Standings.Status, void> = {
  [`${detailsActions.common.loadDetailsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(
    detailsActions.common.loadDetailsSuccess,
    detailsActions.common.loadDetailsFailure,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${commonActions.cleanSummarySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
