import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const TeamCell = () => {
  return (
    <div className={styles.teamCellContainer}>
      <Skeleton marginRight={8} width={20} height={20} variant="circular" />
      <Skeleton marginRight={8} width={20} height={20} variant="rectangular" />
      <Skeleton height={14} width={60} variant="rectangular" />
    </div>
  );
};

export default memo(TeamCell);
