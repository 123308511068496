import { createElement } from 'react';
import { Column } from 'react-table';

import { TournamentTableGoalsCell } from 'packages/shared/components';
import { SeasonTableRow, SeasonTableStatistics } from 'packages/event-standings/types';
import { CommonTableParameter } from 'types';

import { HeaderCell, TeamCell } from '../components';

type Options = {
  groupName: string;
};

const getColumnSchema = ({
  groupName,
}: Options): Column<SeasonTableRow<SeasonTableStatistics>>[] => [
  {
    id: 'groupName',
    Header: groupName,
    headerStyle: { textAlign: 'left', padding: '8px 8px 8px 16px' },
    cellStyle: { paddingLeft: 16 },
    accessor: ({ statistics, team }) => {
      const { position } = statistics.overall;

      return { team, position };
    },
    Cell: ({ value: props }) => createElement(TeamCell, { ...props }),
    minWidth: 150,
  },
  {
    id: 'gamesPlayed',
    Header: () => createElement(HeaderCell, { pathKey: CommonTableParameter.GamesPlayed }),
    accessor: ({ statistics }) => statistics.overall.gamesPlayed,
    width: 15,
  },
  {
    id: 'win',
    Header: () => createElement(HeaderCell, { pathKey: CommonTableParameter.Win }),
    accessor: ({ statistics }) => statistics.overall.win,
    width: 15,
  },
  {
    id: 'draw',
    Header: () => createElement(HeaderCell, { pathKey: CommonTableParameter.Draw }),
    accessor: ({ statistics }) => statistics.overall.draw,
    width: 15,
  },
  {
    id: 'loss',
    Header: () => createElement(HeaderCell, { pathKey: CommonTableParameter.Loss }),
    accessor: ({ statistics }) => statistics.overall.loss,
    width: 15,
  },
  {
    id: 'goals',
    Header: () => createElement(HeaderCell, { pathKey: CommonTableParameter.Goals }),
    accessor: ({ statistics }) => {
      const { goalsFor, goalsAgainst } = statistics.overall;

      return { goalsFor, goalsAgainst };
    },
    Cell: ({ value: props }) => createElement(TournamentTableGoalsCell, props),
    width: 25,
  },
  {
    id: 'goalDifference',
    Header: () => createElement(HeaderCell, { pathKey: CommonTableParameter.GoalDifference }),
    accessor: ({ statistics }) => statistics.overall.goalDifference,
    width: 15,
  },
  {
    id: 'points',
    Header: () => createElement(HeaderCell, { pathKey: CommonTableParameter.Points }),
    headerStyle: { paddingRight: 16 },
    cellStyle: { paddingRight: 16 },
    accessor: ({ statistics }) => statistics.overall.points,
    width: 15,
    minWidth: 21,
  },
];

export default getColumnSchema;
