import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { Icon } from 'packages/shared/components';

import styles from './styles.scss';

const InjuryLabel = ({ theme }) => {
  return (
    <div className={classnames(styles.injuryLabel, styles[theme])}>
      <Icon id="injury" />
    </div>
  );
};

InjuryLabel.defaultProps = {
  theme: '',
};

InjuryLabel.propTypes = {
  theme: PropTypes.string,
};

export default React.memo(InjuryLabel);
