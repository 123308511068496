import { SeasonTopBoard } from 'packages/rest';
import { CommonTableParameter } from 'types';

import {
  loadInitialTopPlayersStart,
  loadInitialTopPlayersSuccess,
  loadInitialTopPlayersFailure,
} from '../actions';
import { mapSeasonTopPlayers, parseOrderingParams } from '../services';
import { PLAYER_API_ORDERING_BY_PARAMETERS } from '../constants';

interface Params {
  teamIds: number[];
  seasonId: number;
  limit?: number;
}

const loadInitialTopPlayers = ({ teamIds, seasonId, limit }: Params) => async (dispatch) => {
  try {
    dispatch(loadInitialTopPlayersStart());

    const { data } = await SeasonTopBoard.loadSeasonTopPlayers({
      seasonId,
      teamIds,
      ordering: parseOrderingParams(PLAYER_API_ORDERING_BY_PARAMETERS[CommonTableParameter.Goals]),
      limit,
    });
    const topPlayers = mapSeasonTopPlayers(data.results);

    dispatch(loadInitialTopPlayersSuccess(topPlayers));
  } catch (e) {
    dispatch(loadInitialTopPlayersFailure(e));
  }
};

export default loadInitialTopPlayers;
