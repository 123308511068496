import React, { memo, useMemo } from 'react';
import { getSearchQuery } from 'router/services';

import DateService from 'packages/date-az';
import { FlexiblePopover } from 'packages/shared/components';
import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';
import { DesktopPage, SearchQuery } from 'router/types';
import { useNavigation } from 'packages/hooks';

import InTeamPopoverContent from '../../../InTeamPopoverContent';
import { calcPlayingPeriod } from './services';

type Props = {
  startDate: string;
  playerId: number;
  teamId: string;
};

const InTeamDate = ({ startDate, playerId, teamId }: Props) => {
  const { appNavigate } = useNavigation();
  const searchQuery = useMemo(() => getSearchQuery({ [SearchQuery.TransferTeamId]: teamId }), [
    teamId,
  ]);
  const { years, months, days } = useMemo(() => {
    return calcPlayingPeriod(startDate);
  }, [startDate]);

  const handleDateClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    ev.stopPropagation();
    appNavigate(DesktopPage.FootballPlayerSummary, { playerId }, searchQuery);
  };

  const inTeamDate = DateService.formatString(startDate, 'd MMM, yyyy');

  return (
    <FlexiblePopover
      closeOnClickOutside
      popoverComponent={InTeamPopoverContent}
      popoverProps={{ years, months, days }}
      justify={PopoverJustify.CenterStart}
      invokeTrigger={PopoverInvokeTrigger.Hover}
      align={PopoverAlign.Top}
    >
      <div onClick={handleDateClick}>{inTeamDate}</div>
    </FlexiblePopover>
  );
};

export default memo(InTeamDate);
