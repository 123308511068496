import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { filterThunks } from 'packages/player-events';

const mapDispatchToProps = (dispatch) => ({
  filterActions: bindActionCreators(filterThunks, dispatch),
});

export default connect(undefined, mapDispatchToProps);
