import { Season } from 'types';

import { initActiveFilter } from '../filters';
import { subscribeForLiveEvents, loadActualEvents } from '../events';

interface Params {
  selectedSeason: Season;
  limit?: number;
}

const loadActualEventsBlock = ({ selectedSeason, limit }: Params) => async (dispatch) => {
  dispatch(subscribeForLiveEvents());
  await dispatch(initActiveFilter(selectedSeason));
  dispatch(loadActualEvents({ seasonId: selectedSeason.id, limit }));
};

export default loadActualEventsBlock;
