import React from 'react';
import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed/lib';

import { ContentBlock, PlayerPreview } from 'packages/shared/components';
import { Lineup } from 'packages/event-lineups/types';

type Props = {
  lineup: Lineup;
  onPlayerClick: (playerId: number) => void;
};

const TeamStructure = ({ lineup, onPlayerClick }: Props) => {
  const { startingLineup, substitutes, teamManager } = lineup;

  return (
    <>
      {!isEmpty(startingLineup) && (
        <ContentBlock title={i18n.value('eventLineups.StartingLineup')}>
          {startingLineup.map((lineupInfo) => (
            <PlayerPreview onPlayerClick={onPlayerClick} key={lineupInfo.id} player={lineupInfo} />
          ))}
        </ContentBlock>
      )}

      {!isEmpty(substitutes) && (
        <ContentBlock title={i18n.value('eventLineups.Bench')}>
          {substitutes.map((player) => (
            <PlayerPreview onPlayerClick={onPlayerClick} key={player.id} player={player} />
          ))}
        </ContentBlock>
      )}

      {!isEmpty(teamManager) && (
        <ContentBlock title={i18n.value('eventLineups.coaches')}>
          <PlayerPreview player={teamManager} isPositionShown={false} isStatusShown={false} />
        </ContentBlock>
      )}
    </>
  );
};

export default React.memo(TeamStructure);
