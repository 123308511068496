import React, { ReactElement, ReactNode, RefObject, useRef } from 'react';
import { Transition } from 'react-transition-group';
import classnames from 'classnames';

import { ArrayUtils } from 'packages/utils';
import { GridColumnId } from 'types';

import { useSavingScroll } from '../../hooks';
import { mainTransitionStyles, secondaryTransitionStyles } from '../../constants';
import styles from './styles.scss';

type Props = {
  children: ReactElement[];
  isSecondaryOpen: boolean;
  leftColumnClassName?: string;
  rightColumnClassName?: string;
};

const DoubleColumn = ({
  children,
  isSecondaryOpen,
  leftColumnClassName,
  rightColumnClassName,
}: Props) => {
  const activeColumnId = isSecondaryOpen ? GridColumnId.Secondary : GridColumnId.Primary;
  const activeColumnRef: RefObject<HTMLDivElement> = useRef(null);

  const LeftBarComponent: ReactNode = ArrayUtils.findBy(children, 'key', GridColumnId.LeftBar);
  const PrimaryComponent: ReactNode = ArrayUtils.findBy(children, 'key', GridColumnId.Primary);
  const SecondaryComponent: ReactNode = ArrayUtils.findBy(children, 'key', GridColumnId.Secondary);

  const { onPrimaryEntered, onPrimaryExit } = useSavingScroll(activeColumnRef.current);

  return (
    <div className={styles.container}>
      <div className={classnames(styles.column, leftColumnClassName)} id={GridColumnId.LeftBar}>
        {LeftBarComponent}
      </div>

      <div
        className={classnames(styles.column, rightColumnClassName)}
        id={activeColumnId}
        ref={activeColumnRef}
      >
        <Transition
          timeout={0}
          in={!isSecondaryOpen}
          onEntered={onPrimaryEntered}
          onExit={onPrimaryExit}
        >
          {(state) => (
            <div className={styles.main} style={mainTransitionStyles[state]}>
              {PrimaryComponent}
            </div>
          )}
        </Transition>

        <Transition timeout={0} in={isSecondaryOpen}>
          {(state) => (
            <div className={styles.secondary} style={secondaryTransitionStyles[state]}>
              {SecondaryComponent}
            </div>
          )}
        </Transition>
      </div>
    </div>
  );
};

export default DoubleColumn;
