import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadLastGamesStart,
  loadLastGamesSuccess,
  loadLastGamesFailure,
  cleanLastGamesSuccess,
} from '../actions';
import { Status } from '../types';

const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${loadLastGamesStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(loadLastGamesFailure, loadLastGamesSuccess)}`]: () => {
    return { isLoading: false };
  },
  [`${cleanLastGamesSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
