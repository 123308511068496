exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3x5OrENh{width:100%;height:100%}._3wKuODvE,._1i_o_x4o{margin-top:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/player-summary-ui/containers/Summary/views/DesktopNarrow/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,UAAW,CACX,WAAY,CAHhB,sBAOI,eCRS","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .player-summary {\n    width: 100%;\n    height: 100%;\n  }\n\n  .statistics-container, .transfer-history-container {\n    margin-top: $margin;\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"player-summary": "_3x5OrENh",
	"playerSummary": "_3x5OrENh",
	"statistics-container": "_3wKuODvE",
	"statisticsContainer": "_3wKuODvE",
	"transfer-history-container": "_1i_o_x4o",
	"transferHistoryContainer": "_1i_o_x4o"
};