import { loadEventCard } from 'packages/rest/api/EventDetails';
import { mapEventDetails } from 'packages/event-details/services';

import {
  loadMatchDetailsStart,
  loadMatchDetailsSuccess,
  loadMatchDetailsFailure,
} from '../actions';

interface Params {
  eventId: number;
}

const loadDetails = ({ eventId }: Params) => async (dispatch) => {
  try {
    dispatch(loadMatchDetailsStart());

    const { data } = await loadEventCard({ eventId });

    const { event, eventSeries, meta } = mapEventDetails(data.results);

    dispatch(loadMatchDetailsSuccess({ event, eventSeries, meta }));
  } catch (e) {
    dispatch(loadMatchDetailsFailure());
  }
};

export default loadDetails;
