export enum EventStatus {
  NotStarted = 'NOT_STARTED',
  Cancelled = 'CANCELLED',
  Postponed = 'POSTPONED',
  FirstHalf = 'FIRST_HALF',
  Halftime = 'HALFTIME',
  SecondHalf = 'SECOND_HALF',
  AwaitingExtraTime = 'AWAITING_EXTRA_TIME',
  Overtime = 'OVERTIME',
  SecondExtra = 'SECOND_EXTRA',
  ExtraTimeHalfTime = 'EXTRA_TIME_HALFTIME',
  AwaitingPenalties = 'AWAITING_PENALTIES',
  Penalties = 'PENALTIES',
  Suspended = 'SUSPENDED',
  Ended = 'ENDED',
  AfterPenalties = 'AFTER_PENALTIES',
  AfterExtraTime = 'AFTER_EXTRA_TIME',
  Unknown = 'UNKNOWN',
}

export enum PeriodStatuses {
  FIRST_PERIOD = 'p1',
  SECOND_PERIOD = 'p2',
  AWAITING_PENALTIES = 'ap',
  FULL_TIME = 'ft',
  OVERTIME = 'ot',
  PENALTY = 'penalty',
}

export enum PeriodStatusViews {
  FIRST_PERIOD = 'p1',
  SECOND_PERIOD = 'p2',
  AWAITING_PENALTIES = 'ap',
  FULL_TIME = 'ft',
  OVERTIME = 'et',
  AFTER_PENALTIES = 'ap',
}

export enum DisplayingScorePeriod {
  FIRST_PERIOD = 'regular_period',
  PENALTY = 'penalties',
}
