import { toCamelCase } from 'packages/utils/Object.utils';
import DateService from 'packages/date-az';

import { PlayerTeam } from '../types';

type DatesRange = {
  start: string;
  end: string;
};
const MAX_DAYS_RANGE = 60;

const mapPlayerTeams = (teams: object[]): PlayerTeam[] => {
  return teams.map((team) => {
    const { startDate, endDate, isActive, team: playerTeam } = toCamelCase(team, true);
    const { start, end } = getDateRange({ start: startDate, end: endDate });
    const { id, name, suffix, logo } = playerTeam;

    return {
      startDate: start,
      endDate: end,
      isActive: !!isActive,
      id,
      name: `${name} ${suffix || ''}`,
      logo: logo || '',
      isTournamentSelectable: !!startDate || !!endDate,
    };
  });
};

const getDateRange = ({ start, end }: Partial<DatesRange>): DatesRange => {
  if (start && end) {
    return getStartEndRange({ start, end });
  }

  if (!end && start) {
    return getRangeWithoutEndDate(start);
  }

  if (end && !start) {
    return getRangeWithoutStartDate(end);
  }

  return getEmptyDatesRange();
};

const getStartEndRange = ({ start, end }: DatesRange): DatesRange => {
  const startDate = start || '';
  const endDate = DateService.getDateTimeFromIsoString(end || '');

  return {
    end: endDate.toISODate(),
    start: startDate,
  };
};

const getRangeWithoutEndDate = (startDate: string): DatesRange => {
  const today = DateService.getDate();
  const daysInterval = DateService.getInterval(today.toISODate(), startDate).count('day');

  return {
    end: today.toISODate(),
    start:
      daysInterval > MAX_DAYS_RANGE
        ? DateService.minus(today, { day: MAX_DAYS_RANGE }).toISODate()
        : startDate,
  };
};

const getRangeWithoutStartDate = (endDate: string): DatesRange => {
  return {
    end: endDate,
    start: DateService.minus(DateService.getDateTimeFromIsoString(endDate), {
      day: MAX_DAYS_RANGE,
    }).toISODate(),
  };
};

const getEmptyDatesRange = (): DatesRange => {
  const today = DateService.getDate();

  return {
    end: today.toISODate(),
    start: DateService.minus(today, { day: MAX_DAYS_RANGE }).toISODate(),
  };
};

export default mapPlayerTeams;
