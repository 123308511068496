import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Interfaces } from '../../types';

import { tournamentsActions } from '../../actions';

const defaultState: Interfaces.Tournaments.Data = {
  tournaments: [],
  selectedTournamentId: undefined,
  selectedSeasonId: undefined,
};

const reducerMap: ReducerMap<Interfaces.Tournaments.Data, Interfaces.Tournaments.Data> = {
  [`${combineActions(
    tournamentsActions.loadTournamentListSuccess,
    tournamentsActions.selectTournamentSeasonSuccess,
  )}`]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  },
  [`${combineActions(
    tournamentsActions.loadTournamentListStart,
    tournamentsActions.loadTournamentListFailure,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
