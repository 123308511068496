import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { statisticsThunks, teamsSelectors, statisticsSelectors } from 'packages/player-summary';
import { playerDetailsSelectors } from 'packages/player-details';

const mapStateToProps = (state) => {
  return {
    teams: teamsSelectors.getTeams(state),
    tournamentSeasons: statisticsSelectors.tournamentSeasons.getData(state),
    playerDetailsStatistics: playerDetailsSelectors.getStatistics(state),
    isStatisticsLoading: statisticsSelectors.statistics.getIsLoading(state),
    isSeasonTournamentsLoading: statisticsSelectors.tournamentSeasons.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tournamentSeasonsActions: bindActionCreators(statisticsThunks.tournamentSeasons, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
