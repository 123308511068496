const calculatePositionFromVerticalEdges = (popoverHeight: number, offsetTop: number) => {
  if (offsetTop > 0) {
    const isPopoverIntersectsHorizontalScreenEdges = offsetTop + popoverHeight > window.innerHeight;

    if (isPopoverIntersectsHorizontalScreenEdges) {
      return window.innerHeight - popoverHeight;
    } else {
      return offsetTop;
    }
  } else {
    return 0;
  }
};

export default calculatePositionFromVerticalEdges;
