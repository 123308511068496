exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._KkDAV-Qw{display:flex;align-items:center;height:16px;min-width:0}._KkDAV-Qw ._3vrItahl{display:flex;word-break:break-all;min-width:0}._KkDAV-Qw ._3vrItahl._QYr5mvhn{cursor:pointer}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/HappeningTimeLine/components/HappeningRow/components/DefaultHappening/styles.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,kBAAmB,CACnB,WAAY,CAEZ,WAAY,CANhB,sBASM,YAAa,CACb,oBAAqB,CAErB,WAAY,CAZlB,gCAeQ,cAAe","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .label {\n    display: flex;\n    align-items: center;\n    height: 16px;\n\n    min-width: 0;\n\n    .player-name {\n      display: flex;\n      word-break: break-all;\n\n      min-width: 0;\n\n      &.clickable {\n        cursor: pointer;\n      }\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"label": "_KkDAV-Qw",
	"player-name": "_3vrItahl",
	"playerName": "_3vrItahl",
	"clickable": "_QYr5mvhn"
};