import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';
import { Tournament } from './components';

const TournamentTableRulesBlock = () => {
  return (
    <div className={styles.rulesContainer}>
      <Skeleton height={14} marginBottom={4} variant="rectangular" width="100%" />
      <Skeleton height={14} variant="rectangular" width="50%" />

      <div className={styles.tournamentsWrapper}>
        <Tournament />
        <Tournament />
        <Tournament />
        <Tournament />
      </div>
    </div>
  );
};

export default memo(TournamentTableRulesBlock);
