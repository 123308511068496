import { Team } from 'types';
import { toCamelCase } from 'packages/utils/Object.utils';

import { Division, Newcomers } from '../types';

export const mapSeasonNewcomers = (seasonNewcomers: any): Newcomers => {
  const { lowerDivisions, higherDivisions, newComers } = toCamelCase(seasonNewcomers);

  return {
    newcomers: mapNewComers(newComers),
    lowerDivisions: mapDivisions(lowerDivisions),
    higherDivisions: mapDivisions(higherDivisions),
  };
};

export const mapNewComers = (newcomers: any[]): Team[] => {
  return newcomers.map((newComer) => {
    const { id, name, logo } = toCamelCase(newComer);

    return { id, name, logo };
  });
};

export const mapDivisions = (divisions: any[]): Division[] => {
  return divisions.map((newComer) => {
    const { id, name, logo, currentSeasonId: seasonId } = toCamelCase(newComer);

    return { id, name, logo, seasonId };
  });
};
