exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1R9kLzFh{display:flex;align-items:center;justify-content:center}._1R9kLzFh ._37jznZF6{border-radius:50%;overflow:hidden;cursor:pointer}._1R9kLzFh ._37jznZF6 ._1NAWm1i-{min-width:32px;max-width:32px;min-height:32px;max-height:32px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/modules/PlayerStatisticsTable/components/PlayerIcon/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/size.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,kBAAmB,CACnB,sBAAuB,CAJ3B,sBAOM,iBAAkB,CAClB,eAAgB,CAChB,cAAe,CATrB,iCCDE,cDa8B,CCZ9B,cDY8B,CCT5B,eDS4B,CCR5B,eDQ4B","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .player-cell {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n\n    .player-logo-container {\n      border-radius: 50%;\n      overflow: hidden;\n      cursor: pointer;\n\n      .player-logo {\n        @include fixed-size(32px);\n      }\n    }\n  }\n}\n","@mixin fixed-size($width, $height: null) {\n  min-width: $width;\n  max-width: $width;\n\n  @if ($height == null) {\n    min-height: $width;\n    max-height: $width;\n  } @else {\n    min-height: $height;\n    max-height: $height;\n  }\n}"]}]);

// Exports
exports.locals = {
	"player-cell": "_1R9kLzFh",
	"playerCell": "_1R9kLzFh",
	"player-logo-container": "_37jznZF6",
	"playerLogoContainer": "_37jznZF6",
	"player-logo": "_1NAWm1i-",
	"playerLogo": "_1NAWm1i-"
};