import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Spinner } from 'packages/shared/components';

import styles from './styles.scss';

const LoaderContainer = ({
  isLoading,
  isContentVisible,
  isSpinnerVisible,
  children,
  className,
  spinnerClassName,
}) => {
  const onContentClick = (e) => e.preventDefault();

  if (isLoading) {
    return (
      <div className={classnames(styles.loaderContainer, className)} onClick={onContentClick}>
        <div className={styles.loaderContent}>
          {isSpinnerVisible && <Spinner className={classnames(spinnerClassName)} />}
        </div>
        {isContentVisible && children}
        {isContentVisible && <div className={styles.overFlow} />}
      </div>
    );
  }

  return children || <></>;
};

LoaderContainer.propTypes = {
  className: PropTypes.string,
  spinnerClassName: PropTypes.string,
  isLoading: PropTypes.bool.isRequired,
  isSpinnerVisible: PropTypes.bool,
  isContentVisible: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

LoaderContainer.defaultProps = {
  className: '',
  isContentVisible: false,
  isSpinnerVisible: true,
};

export default LoaderContainer;
