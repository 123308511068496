import * as tournamentsActions from './actions';
import * as tournamentsThunks from './thunks';
import * as tournamentsConstants from './constants';
import * as tournamentsSelectors from './selectors';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  tournamentsActions,
  tournamentsThunks,
  tournamentsConstants,
  tournamentsSelectors,
  createReducer,
};
