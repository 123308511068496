import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadLineupsStart,
  loadLineupsSuccess,
  loadLineupsFailure,
  cleanLineupsSuccess,
} from '../actions';
import { Status } from '../types';

const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${loadLineupsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(loadLineupsSuccess, loadLineupsFailure)}`]: () => {
    return { isLoading: false };
  },
  [`${cleanLineupsSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
