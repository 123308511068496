import { TeamSummary } from 'packages/rest';

import { mapActualGames } from '../../services';
import { actualGamesActions } from '../../actions';

type Params = {
  teamId: string;
  finishedCount: number;
  scheduledCount: number;
};

const loadActualGames = ({ teamId, finishedCount, scheduledCount }: Params) => async (dispatch) => {
  try {
    dispatch(actualGamesActions.loadActualGamesStart());

    const { data } = await TeamSummary.loadActualGames({
      teamId,
      finishedCount,
      scheduledCount,
    });

    const events = mapActualGames(data.results);

    dispatch(actualGamesActions.loadActualGamesSuccess(events));
  } catch (e) {
    dispatch(actualGamesActions.loadActualGamesFailure(e));
  }
};

export default loadActualGames;
