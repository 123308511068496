import { useMemo } from 'react';

import { isEmpty } from 'packages/utils/Object.utils';
import { BracketRound } from 'packages/event-standings/types';

import { INITIAL_GRID_ELEMENT_HEIGHT } from '../constants';

interface Params {
  rounds: BracketRound[];
}

const useInitialContentHeight = ({ rounds }: Params): [number, number[]] => {
  return useMemo(() => {
    const columnsHeights: number[] = [];

    for (let i = 0; i < rounds.length; i++) {
      const { matchups } = rounds[i];

      if (!isEmpty(matchups.length)) {
        columnsHeights.push(matchups.length * INITIAL_GRID_ELEMENT_HEIGHT * Math.pow(2, i));
      }
    }

    return [columnsHeights[0], columnsHeights];
  }, [rounds]);
};

export default useInitialContentHeight;
