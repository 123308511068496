import React, { useMemo, memo, RefObject } from 'react';

import { ComponentLike } from 'types';

import { Item, SelectItems } from '../../../../components';
import { SelectItem } from '../../../../types';

type Props<T> = {
  className: string;
  items: T[];
  itemComponent?: ComponentLike;
  selectedItems: T[];
  onItemClick: (item: T) => void;
  isAllItemSelected: boolean;
  allSelectedLabel: string;
  isCheckShown: boolean;
  onChooseAll: () => void;
  containerRef?: RefObject<HTMLDivElement>;
};

const PopoverItemList = <T extends SelectItem>({
  className,
  isAllItemSelected,
  allSelectedLabel,
  isCheckShown,
  onChooseAll,
  onItemClick,
  selectedItems,
  itemComponent,
  items,
  containerRef,
}: Props<T>) => {
  const allItemsOption = useMemo(() => ({ id: allSelectedLabel, label: allSelectedLabel }), [
    allSelectedLabel,
  ]);

  return (
    <SelectItems
      className={className}
      onItemClick={onItemClick}
      selectedItems={selectedItems}
      itemComponent={itemComponent}
      items={items}
      containerRef={containerRef}
      isCheckShown={isCheckShown}
    >
      <Item isSelected={isAllItemSelected} item={allItemsOption} handleClick={onChooseAll} />
    </SelectItems>
  );
};
export default memo(PopoverItemList);
