import { EventsStatus, EventStorage } from '../../types';
import getState from './getState';

import { getIsAllEventsLoading, getAllEvents, getEventFromAllById } from '../allEvents';
import {
  getIsFinishedEventsLoading,
  getFinishedEvents,
  getEventFromFinishedById,
} from '../finishedEvents';
import { getIsScheduledEventsLoading, getScheduledEvents } from '../scheduledEvents';
import { getLiveEventById, getLiveEventsList } from '../liveEvents';

export const getEventsByGroup = (state, eventGroup: string): EventStorage => {
  return getState(state)[eventGroup];
};

export const getEventsStatusByGroup = (state, eventGroup: string): EventsStatus => {
  return getEventsByGroup(state, eventGroup).status;
};

export const getIsAnyEventGroupLoading = (state) => {
  return (
    getIsAllEventsLoading(state) ||
    getIsFinishedEventsLoading(state) ||
    getIsScheduledEventsLoading(state)
  );
};

export const getEventFromAllGroupsById = (state, id) => {
  return (
    getLiveEventById(state, id) ||
    getEventFromAllById(state, id) ||
    getEventFromFinishedById(state, id) ||
    getEventFromFinishedById(state, id)
  );
};

export const getEntireEventListsArray = (state) => {
  const allEvents = getAllEvents(state);
  const scheduledEvents = getScheduledEvents(state);
  const finishedEvents = getFinishedEvents(state);
  const liveEvents = getLiveEventsList(state);

  return [allEvents, scheduledEvents, finishedEvents, liveEvents];
};
