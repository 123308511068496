import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';

import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Team } from 'types';

import { getColumnSchema } from './services';
import { Skeleton } from './componets';
import { Props } from '../../types';
import { StatisticsTable } from '../../components';
import styles from './styles.scss';

const NarrowStatistics = <T extends boolean>({
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
  tournamentTables,
  statisticsGroup,
  isLoading = false,
  scrollToTable,
  teamIdToScroll,
}: Props<T>) => {
  if (isLoading) {
    return <Skeleton />;
  }

  const blockRef = useRef<HTMLDivElement>(null);
  const { appNavigate } = useNavigation();

  const activeTableId = useMemo(() => {
    return tournamentTables.find(({ rows }) => rows.some(({ team }) => team.id === teamIdToScroll))
      ?.id;
  }, [teamIdToScroll, tournamentTables]);

  const handleRowMouseUp = useCallback(
    (team: Team) => {
      if (activeUniqueTournamentId && activeSeasonId) {
        appNavigate(DesktopPage.FootballTeamSummaryDescription, {
          teamId: team.id,
          uniqueTournamentId: activeUniqueTournamentId,
          seasonId: activeSeasonId,
        });
      } else {
        appNavigate(DesktopPage.FootballTeam, { teamId: team.id });
      }
    },
    [activeUniqueTournamentId, activeSeasonId, appNavigate],
  );

  useEffect(() => {
    if (teamIdToScroll && scrollToTable && blockRef.current) {
      scrollToTable({
        tableBlockRef: blockRef,
      });
    }
  }, [teamIdToScroll, tournamentTables, scrollToTable, statisticsGroup]);

  return (
    <>
      {tournamentTables.map(({ rows, id, groupName, rule, promotions, teamComments }, index) => (
        <StatisticsTable
          blockRef={activeTableId === id ? blockRef : undefined}
          activeTeamIds={activeTeamIds}
          statisticsGroup={statisticsGroup}
          onRowMouseUp={handleRowMouseUp}
          getColumnSchema={getColumnSchema}
          rows={rows}
          key={id}
          groupName={groupName}
          rule={rule}
          promotions={promotions}
          teamComments={teamComments}
          isResultBlockDisplayed={index === tournamentTables.length - 1}
          className={styles.narrowStatisticsTable}
        />
      ))}
    </>
  );
};

export default memo(NarrowStatistics);
