exports = module.exports = require("../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2_QE5Fm_{flex:1 1;margin-right:16px}._3Z2RpukH{width:443px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/common/ComingSoon/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,WAAY","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_2_QE5Fm_",
	"leftColumn": "_2_QE5Fm_",
	"right-column": "_3Z2RpukH",
	"rightColumn": "_3Z2RpukH"
};