import React, { ReactElement } from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

type Props = {
  children: string | ReactElement;
  onClick?: () => void;
};

const LinkButton = ({ children, onClick }: Props) => (
  <div onClick={onClick} className={classnames(styles.link)}>
    {children}
  </div>
);

export default React.memo(LinkButton);
