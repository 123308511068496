import { BaseHappening, PlayerType, SubstitutionHappening } from '../../types';

const mapSubstitutionHappening = (happening: BaseHappening): SubstitutionHappening => {
  const { players, eventType, minute, team, id, injuryTime } = happening;
  let playerIn;
  let playerOut;

  if (players) {
    playerIn = players.find((player) => player.playerType === PlayerType.SubstitutedIn);
    playerOut = players.find((player) => player.playerType === PlayerType.SubstitutedOut);
  }

  const playerInName = playerIn?.name || playerIn?.shortName || '';
  const playerOutName = playerOut?.name || playerOut?.shortName || '';

  return {
    players,
    eventType,
    minute,
    team,
    playerInName,
    playerOutName,
    id,
    injuryTime,
  };
};

export default mapSubstitutionHappening;
