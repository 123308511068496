import React, { memo } from 'react';

import { BaseHappening } from 'packages/event-time-line/types';
import { Icon } from 'packages/shared/components';

import { getPlayerHappeningIcon } from './services';
import styles from './styles.scss';

type Props = {
  happening: BaseHappening;
};

const HappeningChip = ({ happening }: Props) => {
  const iconType = getPlayerHappeningIcon(happening);
  const { minute, injuryTime } = happening;

  return iconType ? (
    <div className={styles.chip}>
      <Icon id={iconType} className={styles.icon} />
      <div className={styles.minute}>
        {`${minute}'`}
        {!!injuryTime && <span>{`+${injuryTime}'`}</span>}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default memo(HappeningChip);
