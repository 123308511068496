import { EventStatus } from 'packages/events/types';

export const STATUS_MAPPER = {
  ['not_started']: EventStatus.NotStarted,
  ['cancelled']: EventStatus.Cancelled,
  ['postponed']: EventStatus.Postponed,
  ['interrupted']: EventStatus.Suspended,
  ['abandoned']: EventStatus.Suspended,
  ['1st_half']: EventStatus.FirstHalf,
  ['halftime']: EventStatus.Halftime,
  ['2nd_half']: EventStatus.SecondHalf,
  ['awaiting_extra_time']: EventStatus.AwaitingExtraTime,
  ['overtime']: EventStatus.Overtime,
  ['extra_time_halftime']: EventStatus.ExtraTimeHalfTime,
  ['2nd_extra']: EventStatus.SecondExtra,
  ['awaiting_penalties']: EventStatus.AwaitingPenalties,
  ['penalties']: EventStatus.Penalties,
  ['ended']: EventStatus.Ended,
  ['ap']: EventStatus.AfterPenalties,
  ['aet']: EventStatus.AfterExtraTime,
};
