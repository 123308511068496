exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3cOqLdKY{display:flex;flex-direction:column;width:100%;height:100%}._3cOqLdKY ._2Q1wFWle,._3cOqLdKY ._1BN-TqTu{margin-top:12px}._3cOqLdKY ._3OCTqEdC{display:flex;margin-top:16px}._3cOqLdKY ._3OCTqEdC ._2EkUmz0x,._3cOqLdKY ._3OCTqEdC ._t6brLiwr{flex:1 1;display:flex;flex-direction:column}._3cOqLdKY ._3OCTqEdC ._2EkUmz0x>div:not(:first-of-type),._3cOqLdKY ._3OCTqEdC ._t6brLiwr>div:not(:first-of-type){margin-top:16px}._3cOqLdKY ._3OCTqEdC ._2EkUmz0x{margin-right:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/stadium-summary-ui/containers/Summary/views/Desktop/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,qBAAsB,CAEtB,UAAW,CACX,WAAY,CANhB,4CASM,eAAgB,CATtB,sBAaM,YAAa,CACb,eAAgB,CAdtB,kEAiBQ,QAAO,CACP,YAAa,CACb,qBAAsB,CAnB9B,kHAuBY,eCxBC,CDCb,iCA6BQ,iBC9BK","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .team-summary {\n    display: flex;\n    flex-direction: column;\n\n    width: 100%;\n    height: 100%;\n\n    .details, .loader-container {\n      margin-top: 12px;\n    }\n\n    .statistics-container {\n      display: flex;\n      margin-top: 16px;\n\n      .left-group, .right-group {\n        flex: 1;\n        display: flex;\n        flex-direction: column;\n\n        > div {\n          &:not(:first-of-type) {\n            margin-top: $margin;\n          }\n        }\n      }\n\n      .left-group {\n        margin-right: $margin;\n      }\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"team-summary": "_3cOqLdKY",
	"teamSummary": "_3cOqLdKY",
	"details": "_2Q1wFWle",
	"loader-container": "_1BN-TqTu",
	"loaderContainer": "_1BN-TqTu",
	"statistics-container": "_3OCTqEdC",
	"statisticsContainer": "_3OCTqEdC",
	"left-group": "_2EkUmz0x",
	"leftGroup": "_2EkUmz0x",
	"right-group": "_t6brLiwr",
	"rightGroup": "_t6brLiwr"
};