import React, { memo, useCallback, useMemo, useState } from 'react';
import i18n from 'i18n-smart';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import {
  Button,
  Icon,
  Input,
  Separator,
  SelectItemComponent,
  SelectLabelComponent,
} from 'packages/shared/components';
import { ButtonType } from 'packages/shared/components/Button/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';
import { SelectItem } from 'packages/shared/components/Select/types';
import { Interfaces } from 'packages/player-summary/types';

import connect from './connect';
import styles from './styles.scss';
import { FilterState } from '../../../../types';
import { getMobileFilterSelectItems } from './services';

type Props = {
  mobileFilter: Interfaces.Statistics.TournamentSeasons.Data;
  mobileFilterActions: ActionCreatorsMapObject;
  tournamentSeasonsActions: ActionCreatorsMapObject;
  updateMainFilter: React.Dispatch<React.SetStateAction<FilterState>>;
  tournamentSeasons?: Interfaces.Statistics.TournamentSeasons.Data;
  closePopover: () => void;
  tabs: NavigationMenuItem[];
  teamItems: SelectItem[];
  mainFilter: FilterState;
  isMobileFilterLoading: boolean;
  isWidthFixed?: boolean;
};

const FiltrationPopover = ({
  isWidthFixed = false,
  mobileFilter,
  mobileFilterActions,
  closePopover,
  tabs,
  mainFilter,
  teamItems,
  tournamentSeasons: activeFilter,
  tournamentSeasonsActions,
  isMobileFilterLoading,
  updateMainFilter,
}: Props) => {
  const { playerId } = useParams();
  const [localFilter, updateLocalFilter] = useState<FilterState>(mainFilter);

  const { tournaments = [], selectedTournamentId, selectedSeasonId } = mobileFilter || {};
  const { tournamentItems, seasonItems, activeSeasonItem, activeTournamentItem } = useMemo(
    () => getMobileFilterSelectItems(tournaments, selectedTournamentId, selectedSeasonId),
    [tournaments, selectedTournamentId, selectedSeasonId],
  );

  const handleChangeTab = useCallback((tabId: NavigationMenuItem['id']) => {
    updateLocalFilter((prevState) => ({ ...prevState, activeViewId: tabId }));
  }, []);

  const handleTeamChange = useCallback((selectedTeam: SelectItem) => {
    updateLocalFilter((prevState) => ({ ...prevState, selectedTeam }));

    mobileFilterActions.loadFilters({ playerId, teamId: selectedTeam.id });
  }, []);

  const handleTournamentChange = useCallback(
    (tournamentItem: SelectItem) => {
      const { seasons } = tournaments.find(({ id }) => id === tournamentItem.id) || {};
      const [firstSeason] = seasons || [];

      mobileFilterActions.updateFilters({
        selectedTournamentId: tournamentItem.id,
        selectedSeasonId: firstSeason.id,
      });
    },
    [tournaments],
  );

  const handleSeasonChange = useCallback(
    (seasonItem: SelectItem) => {
      mobileFilterActions.updateFilters({
        selectedTournamentId: activeTournamentItem?.id,
        selectedSeasonId: seasonItem.id,
      });
    },
    [activeTournamentItem?.id],
  );

  const handleClear = useCallback(() => {
    if (!isMobileFilterLoading) {
      updateLocalFilter({
        activeViewId: tabs[0].id,
        selectedTeam: teamItems[0],
      });
      mobileFilterActions.cleanFilter();
    }
  }, [teamItems, tabs, isMobileFilterLoading]);

  const handleClose = useCallback(() => {
    const { selectedSeasonId, selectedTournamentId, tournaments } = activeFilter || {};

    updateLocalFilter(mainFilter);
    mobileFilterActions.updateFilters({
      tournaments,
      selectedTournamentId,
      selectedSeasonId,
    });

    closePopover();
  }, [activeFilter]);

  const handleApply = () => {
    if (!isMobileFilterLoading) {
      updateMainFilter(localFilter);

      tournamentSeasonsActions.selectTournamentSeason({
        tournamentId: activeTournamentItem?.id,
        seasonId: activeSeasonItem?.id,
      });

      closePopover();
    }
  };

  const { activeViewId: activeTabId, selectedTeam } = localFilter;

  return (
    <div className={classnames(styles.content, { [styles.fixedWidth]: isWidthFixed })}>
      <div className={styles.header}>
        <div className={styles.title}>{i18n.value('player.playerSummary.statistics.title')}</div>
        <Button
          onClick={handleClose}
          type={ButtonType.Default}
          contentClassName={styles.closeButton}
        >
          <Icon id={IconType.Cross} className={styles.icon} />
        </Button>
      </div>

      <Separator />

      <div className={styles.content}>
        <Input.Select
          items={teamItems}
          selectedItem={selectedTeam}
          onChange={handleTeamChange}
          name="team"
          label={i18n.value('player.playerSummary.mobileFilterPopover.teamSelect.title')}
          itemsContainerClass={styles.selectItemsContainer}
        />

        <Input.Select
          isLoading={isMobileFilterLoading}
          items={tournamentItems}
          selectedItem={activeTournamentItem}
          onChange={handleTournamentChange}
          name="league"
          label={i18n.value('player.playerSummary.mobileFilterPopover.leagueSelect.title')}
          isDisabled={!activeTournamentItem}
          itemsContainerClass={styles.selectItemsContainer}
          labelComponentProps={activeTournamentItem?.componentProps}
          labelComponent={SelectLabelComponent.Tournament}
          itemComponent={SelectItemComponent.Tournament}
        />

        <Input.Select
          isLoading={isMobileFilterLoading}
          items={seasonItems}
          selectedItem={activeSeasonItem}
          onChange={handleSeasonChange}
          name="season"
          label={i18n.value('player.playerSummary.mobileFilterPopover.seasonSelect.title')}
          isDisabled={!activeSeasonItem}
          itemsContainerClass={styles.selectItemsContainer}
        />

        <Input.Tabs
          onChange={handleChangeTab}
          activeId={activeTabId}
          items={tabs}
          isWidthLimited
          label={i18n.value('player.playerSummary.mobileFilterPopover.viewTabs.title')}
          name="view"
        />
      </div>

      <Separator />

      <div className={styles.footer}>
        <div className={styles.clearBtn} onClick={handleClear}>
          {i18n.value('player.playerSummary.mobileFilterPopover.clearFilter')}
        </div>

        <div className={styles.controlButtons}>
          <Button onClick={handleClose} type={ButtonType.Secondary}>
            {i18n.value('player.playerSummary.mobileFilterPopover.cancel')}
          </Button>

          <Button onClick={handleApply} type={ButtonType.Primary}>
            {i18n.value('player.playerSummary.mobileFilterPopover.apply')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(memo(FiltrationPopover));
