import React, { useRef } from 'react';
import classnames from 'classnames';
import { DateTime } from 'luxon';

import DateService from 'packages/date-az';

import { getGroupedDates } from './services';
import { useCurrentDateButtonAndMonthBorders, useScroll } from './hooks';
import { DateItem, CurrentDateButton } from './views';
import { DATE_ITEM_WIDTH, MONTH_LABEL_WIDTH } from './const';
import styles from './styles.scss';

type Props = {
  className?: string;
  isCompact?: boolean;
  activeDate: string;
  handleDateClick: (date) => void;
};

const DateCarousel = ({ className, activeDate, handleDateClick, isCompact = false }: Props) => {
  const activeDateTime = DateService.parse(activeDate);
  const currentDateTime = DateTime.local().startOf('day');
  const groupedDatesEntries = getGroupedDates(activeDateTime);

  const currentDateButtonRef = useRef();
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  useScroll(scrollContainerRef, activeDateTime);

  const onDateClick = (date: DateTime) => {
    handleDateClick(DateService.toIsoDateFromDateTime(date));
  };

  if (!isCompact) {
    useCurrentDateButtonAndMonthBorders(
      scrollContainerRef,
      groupedDatesEntries,
      currentDateButtonRef,
      activeDateTime,
      currentDateTime,
    );
  }

  return (
    <div className={classnames(styles.dateCarousel, { [styles.compact]: isCompact }, className)}>
      {!isCompact && (
        <CurrentDateButton
          forwardedRef={currentDateButtonRef}
          date={currentDateTime}
          onClick={onDateClick}
          className={styles.currentDateButton}
        />
      )}

      <div draggable ref={scrollContainerRef} className={styles.carouselScrollContainer}>
        {groupedDatesEntries.map(([month, dates]) => {
          const monthBlockWidth = dates.length * DATE_ITEM_WIDTH;

          return (
            <div key={month} style={{ width: monthBlockWidth }} className={styles.monthBlock}>
              {!isCompact && (
                <div id={month} className={styles.monthLabel}>
                  {month}
                </div>
              )}
              <div style={{ right: MONTH_LABEL_WIDTH }} className={styles.dates}>
                {dates.map((date) => (
                  <DateItem
                    id={date.toMillis()}
                    key={date.toISO()}
                    date={date}
                    isCurrent={date.toMillis() === currentDateTime.toMillis()}
                    isActive={date.toMillis() === activeDateTime.toMillis()}
                    handleClick={onDateClick}
                    className={styles.dateItem}
                  />
                ))}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default React.memo(DateCarousel);
