import * as matchStandingsActions from './actions';
import * as matchStandingsSelectors from './selectors';
import * as matchStandingsConstants from './constants';
import * as matchStandingsThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  matchStandingsActions,
  matchStandingsSelectors,
  matchStandingsConstants,
  matchStandingsThunks,
  createReducer,
};
