import { getLocalSelectors } from 'packages/socket';

import getState from '../common/getState';

const eventSocketSelectors = getLocalSelectors('live', getState);

export const getIsLiveEventSocketOpen = eventSocketSelectors.getIsConnectionOpen;
export const getLiveEventSocketVersion = eventSocketSelectors.getVersion;
export const getLiveEvents = eventSocketSelectors.getSocketData;
export const getIsLiveEventsLoaded = eventSocketSelectors.getIsSocketDataLoaded;
export const getLiveEventsError = eventSocketSelectors.getSocketError;

export const getLiveEventsList = (state) => {
  return Object.values(getLiveEvents(state) || {});
};

export const getLiveEventById = (state, id) => {
  return getLiveEvents(state)?.[id];
};
