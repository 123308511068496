import React, { ReactNode, RefObject, useMemo } from 'react';
import classnames from 'classnames';

import { SelectItem } from 'packages/shared/components/Select/types';
import { ArrayUtils } from 'packages/utils';
import { ComponentLike } from 'types';

import Item from '../Item';
import styles from './styles.scss';
import { getContainerWidth } from './services';

type Props = {
  selectedItems?: SelectItem[];
  items: SelectItem[];
  onItemClick: Function;
  className?: string;
  children?: ReactNode | ReactNode[];
  itemComponent?: ComponentLike;
  isCheckShown?: boolean;
  containerRef?: RefObject<HTMLDivElement>;
  closePopover?: () => void;
  isCloseableOnSelect?: boolean;
};

const SelectItems = ({
  selectedItems,
  items,
  itemComponent: ItemComponent,
  onItemClick,
  className,
  children,
  isCheckShown = false,
  containerRef,
  closePopover,
  isCloseableOnSelect = false,
}: Props) => {
  const listStyle = useMemo(() => getContainerWidth(containerRef), [containerRef]);

  const handleItemClick = (event) => {
    onItemClick(event);

    if (closePopover && isCloseableOnSelect) {
      closePopover();
    }
  };

  return (
    <div
      data-scroll-lock-scrollable
      style={listStyle}
      className={classnames(styles.container, className)}
    >
      {children}
      {items.map((item) => {
        const { id, componentProps } = item;
        const isSelected = !!ArrayUtils.findById(selectedItems, id);

        return (
          <Item
            key={id}
            isSelected={isSelected}
            item={item}
            isCheckShown={isCheckShown}
            handleClick={handleItemClick}
          >
            {ItemComponent && <ItemComponent {...componentProps} />}
          </Item>
        );
      })}
    </div>
  );
};

export default SelectItems;
