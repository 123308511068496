import { createAction } from 'redux-actions';
import { NAMESPACE } from '../constants';

export const loadEventHeadToHeadStart = createAction(`${NAMESPACE}LOAD_EVENT_HEAD_TO_HEAD_START`);
export const cleanEventHeadToHead = createAction(`${NAMESPACE}CLEAN_EVENT_HEAD_TO_HEAD`);
export const loadEventHeadToHeadSuccess = createAction(
  `${NAMESPACE}LOAD_EVENT_HEAD_TO_HEAD_SUCCESS`,
);
export const loadEventHeadToHeadFailure = createAction(
  `${NAMESPACE}LOAD_EVENT_HEAD_TO_HEAD_FAILURE`,
);
