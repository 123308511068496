import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { Summary } from 'packages/stadium-summary-ui';
import { EventDetails } from 'packages/event-details-ui';
import { SearchQuery } from 'router/types';

import styles from './styles.scss';

const StadiumSummary = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  return (
    <Grid.SingleColumn isSecondaryOpen={!!eventId}>
      <div key={GridColumnId.Primary} className={styles.primary}>
        <Summary.Mobile />
      </div>

      <div key={GridColumnId.Secondary} className={styles.popup}>
        <EventDetails.Mobile eventId={eventId} />
      </div>
    </Grid.SingleColumn>
  );
};

export default memo(StadiumSummary);
