import React, { memo, useMemo } from 'react';

import { Skeleton } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';
import { EventLoadingSkeleton } from 'packages/events-ui/components';

import styles from '../../styles.scss';

const ELEMENTS_COUNT = 6;

type Props = {
  isCompact: boolean;
};

const GamesSkeleton = ({ isCompact }: Props) => {
  const elements = useMemo(() => ArrayUtils.getArrayFromNumber(ELEMENTS_COUNT), []);

  return (
    <div className={styles.actualGamesBlock}>
      <div className={styles.header}>
        <Skeleton width={113} height={14} variant="rectangular" />
        <Skeleton
          marginBottom={9}
          marginTop={9}
          width={100}
          height={14}
          marginLeft="auto"
          variant="rectangular"
        />
      </div>

      <div className={styles.gamesList}>
        {elements.map((element) => (
          <EventLoadingSkeleton
            key={element}
            isCompact={isCompact}
            isTournamentVisible
            variant="player"
          />
        ))}
      </div>
    </div>
  );
};

export default memo(GamesSkeleton);
