export enum Type {
  Score = 'score',
  Border = 'border',
  Block = 'block',
}

export enum Weight {
  Thin = 'thin',
  Bold = 'bold',
}
