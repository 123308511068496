import { Row } from 'react-table';

import { TopPlayer } from 'packages/season-top-board/types';

type Params = {
  onClick: (playerId: number) => void;
};

const getPlayerRowProps = ({ onClick }: Params) => {
  return ({ original }: Row<TopPlayer>) => {
    const { player } = original;

    return {
      onClick: () => {
        onClick(player.id);
      },
    };
  };
};

export default getPlayerRowProps;
