import React, { useEffect, useMemo } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useSearchParams } from 'react-router-dom';

import { isEmpty } from 'packages/utils/Object.utils';
import Tabs from 'packages/shared/components/Tabs';
import { GridColumnId, TimeZone } from 'types';
import { SearchQuery } from 'router/types';

import { getEventTabs } from './services';
import styles from './styles.scss';
import connect from './connect';

export const StickyHeaderTopOffsetContext = React.createContext(0);

type Props = {
  timeZone: TimeZone;
  favoriteEventsActions: ActionCreatorsMapObject;
  liveEventsActions: ActionCreatorsMapObject;
  activeDate: string;
  categoryId?: string;
  className?: string;
  stickyHeaderTopOffset: number;
  scrollContainerIdentifier?: GridColumnId | null;
  isCompact?: boolean;
  useModalOnCategoryClick?: boolean;
};

const EventsTabs = ({
  favoriteEventsActions,
  liveEventsActions,
  timeZone,
  activeDate,
  categoryId,
  className,
  stickyHeaderTopOffset,
  isCompact = false,
  useModalOnCategoryClick = false,
  scrollContainerIdentifier = null,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const eventTabs = useMemo(getEventTabs, []);

  useEffect(() => {
    liveEventsActions.subscribe({ categoryId });

    return () => {
      liveEventsActions.unsubscribe();
    };
  }, [categoryId]);

  useEffect(() => {
    const unobserve = favoriteEventsActions.observeFavorites({ categoryId });
    favoriteEventsActions.loadFavoriteEvents();

    return () => {
      favoriteEventsActions.cleanFavoriteEvents();
      unobserve();
    };
  }, [activeDate, categoryId, timeZone.value]);

  const contentComponentProps = {
    handleEventClick: (eventCardId) => {
      searchParams.set(SearchQuery.EventCardId, eventCardId);
      setSearchParams(searchParams);
    },
    isCompact,
    useModalOnCategoryClick,
    scrollContainerIdentifier,
    categoryId,
    activeDate,
    className,
  };

  return (
    !isEmpty(eventTabs) && (
      <StickyHeaderTopOffsetContext.Provider value={stickyHeaderTopOffset}>
        <Tabs
          contentComponentProps={contentComponentProps}
          stickyHeaderTopOffset={stickyHeaderTopOffset}
          className={styles.eventList}
          tabs={eventTabs}
          isHeaderSticky
        />
      </StickyHeaderTopOffsetContext.Provider>
    )
  );
};

export default connect(EventsTabs);
