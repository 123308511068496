exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2yYSbste{width:100%}._2yYSbste ._g0Jv-84J{margin-top:8px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Input/views/SearchSelect/styles.scss"],"names":[],"mappings":"AAEA,WAEI,UAAW,CAFf,sBAKM,cAAe","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .search-input-container {\n    width: 100%;\n\n    .search-select {\n      margin-top: 8px;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"search-input-container": "_2yYSbste",
	"searchInputContainer": "_2yYSbste",
	"search-select": "_g0Jv-84J",
	"searchSelect": "_g0Jv-84J"
};