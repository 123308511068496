import i18n from 'i18n-smart';

import { SelectItem } from 'packages/shared/components/Select/types';
import { seasonTeamStatisticsConstants } from 'packages/season-team-statistics';

const { CUSTOMIZATION_PRESET_BY_TYPE } = seasonTeamStatisticsConstants;

const getPresetItems = (): SelectItem[] =>
  Object.values(CUSTOMIZATION_PRESET_BY_TYPE).map(({ type }) => ({
    id: type,
    label: i18n.value(
      `season.teamStatistics.customFilter.columnConfiguration.configurationPreset.${type}`,
    ),
  }));

export default getPresetItems;
