import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  useShortTitle?: boolean;
  isCompact?: boolean;
};

const SkeletonHeader = ({ useShortTitle = false, isCompact = false }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Skeleton
        width="100%"
        maxWidth={useShortTitle ? 65 : 149}
        marginRight={12}
        variant="rectangular"
        height={14}
      />
      <Skeleton width="100%" maxWidth={200} marginRight={12} variant="rectangular" height={32} />
      {!isCompact && (
        <Skeleton width="100%" maxWidth={90} marginRight={12} variant="rectangular" height={32} />
      )}
      <Skeleton width="100%" maxWidth={160} marginRight={12} variant="rectangular" height={32} />
      <Skeleton
        minWidth={32}
        maxWidth={126}
        width={isCompact ? 32 : '100%'}
        height={32}
        variant="rectangular"
      />
    </div>
  );
};

export default memo(SkeletonHeader);
