exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._gR4yredD{margin-top:32px;flex-wrap:wrap}._2TA-uvDt{margin-top:12px}._362sgqtk{margin-top:12px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/team-summary-ui/containers/TeamSummary/views/Desktop/styles.scss"],"names":[],"mappings":"AAAA,WAEI,eAAgB,CAChB,cAAe,CAHnB,WAOI,eAAgB,CAPpB,WAWI,eAAgB","file":"styles.scss","sourcesContent":[":local {\r\n  .details-selector {\r\n    margin-top: 32px;\r\n    flex-wrap: wrap;\r\n  }\r\n\r\n  .tournament-chip-list {\r\n    margin-top: 12px;\r\n  }\r\n\r\n  .details {\r\n    margin-top: 12px;\r\n  }\r\n}"]}]);

// Exports
exports.locals = {
	"details-selector": "_gR4yredD",
	"detailsSelector": "_gR4yredD",
	"tournament-chip-list": "_2TA-uvDt",
	"tournamentChipList": "_2TA-uvDt",
	"details": "_362sgqtk"
};