import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { PlayersParameter, TopPlayer } from 'packages/season-top-board/types';
import { TopPlayers as TopPlayersModule } from 'packages/shared/modules';
import { MobilePage } from 'router/types';
import { useNavigation } from 'packages/hooks';
import { MediaScreen } from 'types';

import styles from './styles.scss';

type Props = {
  players: TopPlayer[];
  isLoading: boolean;
  onOrderingUpdate: (seasonId: number | string, sortingParameter: PlayersParameter) => void;
};

const CompactTopPlayers = ({ players, isLoading, onOrderingUpdate }: Props) => {
  const { seasonId = '', tournamentId = '' } = useParams();
  const { appNavigate } = useNavigation(MediaScreen.Mobile);

  const handleCompactPlayersOrderingUpdate = useCallback(
    (sortingParameter) => {
      onOrderingUpdate(seasonId, sortingParameter);
    },
    [seasonId],
  );

  const handleNavigationLinkClick = useCallback(() => {
    if (tournamentId && seasonId) {
      appNavigate(MobilePage.FootballSeasonPlayerStatistics, {
        uniqueTournamentId: tournamentId,
        seasonId,
      });
    }
  }, [tournamentId, seasonId]);

  const handleRowClick = useCallback((playerId: number) => {
    appNavigate(MobilePage.FootballPlayer, { playerId });
  }, []);

  return (
    <TopPlayersModule
      players={players}
      onUpdate={handleCompactPlayersOrderingUpdate}
      onRowClick={handleRowClick}
      onNavigationLinkClick={handleNavigationLinkClick}
      dropSortingValueOn={seasonId}
      isLoading={isLoading}
      className={styles.topPlayersContainer}
      useFixedPreloader
    />
  );
};

export default CompactTopPlayers;
