import instance from '../../../axios';
import { LoadAllEventsParams } from '../types';

export const loadAllEvents = ({
  activeSportId,
  date,
  orderByFavoriteTournamentsIds,
  favoriteTournamentsIds,
  favoriteTeamsIds,
  categoryId,
  isLive,
  isFinished,
  offset,
  limit,
}: LoadAllEventsParams) => {
  return instance({
    method: 'get',
    url: '/sport_events/',
    params: {
      sport: activeSportId,
      is_live: isLive,
      is_finished: isFinished,
      order_by_ut_ids: orderByFavoriteTournamentsIds,
      utid: favoriteTournamentsIds,
      team: favoriteTeamsIds,
      scheduled: date,
      category: categoryId,
      offset,
      limit,
    },
  });
};
