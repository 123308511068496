import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { squadSelectors, squadThunks } from 'packages/team-squad';

const mapStateToProps = (state) => {
  return {
    isInitialPageLoading: squadSelectors.getIsInitialPageLoading(state),
    isNewPageLoading: squadSelectors.getIsNewPageLoading(state),
    playersCount: squadSelectors.getCount(state),
    players: squadSelectors.getData(state),
    limit: squadSelectors.getLimit(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    squadActions: bindActionCreators(squadThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
