import { toCamelCase } from 'packages/utils/Object.utils';
import { LineupPlayer } from 'packages/shared/components/Lineup/types';

import groupPlayersByLine from '../groupPlayersByLine';
import { Competitor, LineupRowsExtendedCompetitor, PlayersHappenings } from '../../types';

const mapCompetitors = (
  competitors,
  happeningsByPlayerId: PlayersHappenings,
): [LineupRowsExtendedCompetitor, LineupRowsExtendedCompetitor] => {
  return competitors.map((competitor) => {
    const mappedCompetitor = toCamelCase(competitor, true);
    const formattedCompetitor = formatCompetitor(mappedCompetitor, happeningsByPlayerId);

    return {
      ...formattedCompetitor,
      lineupRows: groupPlayersByLine(
        formattedCompetitor.formation,
        formattedCompetitor.lineup.startingLineup,
      ),
    };
  });
};

const formatCompetitor = (lineupData, happeningsByPlayerId: PlayersHappenings): Competitor => {
  const { team, lineUp, jersey } = lineupData;
  const { name: teamName, logo: teamLogo } = team;
  const { startingLineup, substitutes, formation, manager: teamManager } = lineUp;

  return {
    teamName,
    teamLogo,
    playerTypeJersey: jersey,
    formation,
    lineup: {
      startingLineup: startingLineup.map((startingLineupInfo) =>
        formatLineupInfo(startingLineupInfo, happeningsByPlayerId),
      ),
      substitutes: substitutes.map((substituteLineupInfo) =>
        formatLineupInfo(substituteLineupInfo, happeningsByPlayerId),
      ),
      teamManager: teamManager ? toCamelCase(teamManager) : undefined,
    },
  };
};

const formatLineupInfo = (lineupInfo, happeningsByPlayerId): LineupPlayer => {
  const { sportEventJerseyNumber, player, position: matchPositionName } = toCamelCase(lineupInfo);
  const { id, country, position: playerPositionName, ...formattedPlayerData } = toCamelCase(player);

  return {
    id,
    country: country ? toCamelCase(country) : undefined,
    jerseyNumber: sportEventJerseyNumber,
    positionName: matchPositionName || playerPositionName,
    happenings: happeningsByPlayerId[id] || [],
    ...formattedPlayerData,
  };
};

export default mapCompetitors;
