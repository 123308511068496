import { BaseHappening, PlayerType } from 'packages/event-time-line/types';
import { PlayerHappeningType, SummaryHappeningType } from 'packages/events/types';

const mapAssistHappenings = (happenings: BaseHappening[] = []): BaseHappening[] => {
  return happenings.reduce((accumulator: BaseHappening[], happening) => {
    if (happening.eventType === SummaryHappeningType.SCORE_CHANGE) {
      const { players } = happening;
      const assists = players?.find((player) => player.playerType === PlayerType.Assist);

      if (assists) {
        accumulator.push({ ...happening, eventType: PlayerHappeningType.ASSISTS });
      }
    }

    return accumulator;
  }, []);
};

export default mapAssistHappenings;
