import { IconType } from 'packages/shared/components/Icon/types';

import { GoalHappeningViewTypes } from '../types';

export const THEMING_GOAL_TYPES = [GoalHappeningViewTypes.Goal, GoalHappeningViewTypes.PenaltyGoal];

export const GOAL_ICON_MAP = {
  [GoalHappeningViewTypes.Goal]: IconType.Goal,
  [GoalHappeningViewTypes.OwnGoal]: IconType.OwnGoal,
  [GoalHappeningViewTypes.NotKicked]: IconType.NotKicked,
  [GoalHappeningViewTypes.PenaltyMissed]: IconType.PenaltyMissed,
  [GoalHappeningViewTypes.PenaltyGoal]: IconType.PenaltyGoal,
};
