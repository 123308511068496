import React, { useCallback, useEffect, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { Ordering, SquadOrdering } from 'packages/rest/api/TeamSquad/types';
import { Player } from 'packages/team-squad/types';
import { useNonInitialEffect } from 'packages/hooks';
import { PaginationParams } from 'packages/shared/components/Pagination/types';

import { DEFAULT_SQUAD_ORDERING } from './constants';
import { getOrderingByType } from './services';
import TeamSquadView from './views';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  isNewPageLoading: boolean;
  isInitialPageLoading: boolean;
  squadActions: ActionCreatorsMapObject;
  players: Player[];
  playersCount: number;
  limit: number;
};

const extendTeamSquad = (TeamSquad: typeof TeamSquadView[keyof typeof TeamSquadView]) => ({
  isNewPageLoading,
  isInitialPageLoading,
  squadActions,
  players,
  playersCount,
  limit,
}: Props) => {
  const [ordering, setOrdering] = useState<SquadOrdering>(DEFAULT_SQUAD_ORDERING);
  const { teamId } = useParams();

  useEffect(() => {
    squadActions.loadSquadPage({
      teamId,
      ordering,
    });

    return () => {
      squadActions.clean();
    };
  }, []);

  useNonInitialEffect(() => {
    squadActions.loadSquad({
      teamId,
      ordering,
    });
  }, [ordering]);

  const handleOrderingClick = useCallback(
    (selectedOrdering: Ordering) => {
      const newOrdering = getOrderingByType({
        currentOrdering: ordering,
        orderingType: selectedOrdering,
      });

      setOrdering(newOrdering);
    },
    [teamId, ordering],
  );

  const handlePaginationChange = useCallback(
    (params: PaginationParams) => {
      squadActions.updatePagination(params);
      squadActions.loadSquad({
        teamId,
        ordering,
      });
    },
    [teamId, ordering],
  );

  return (
    <div className={styles.teamSquad}>
      <TeamSquad
        isInitialPageLoading={isInitialPageLoading}
        onPaginationChange={handlePaginationChange}
        onOrderingClick={handleOrderingClick}
        ordering={ordering}
        players={players}
        playersCount={playersCount}
        isNewPageLoading={isNewPageLoading}
        limit={limit}
      />
    </div>
  );
};

export default {
  Desktop: connect(extendTeamSquad(TeamSquadView.Desktop)),
  DesktopNarrow: connect(extendTeamSquad(TeamSquadView.DesktopNarrow)),
  Tablet: connect(extendTeamSquad(TeamSquadView.Tablet)),
  TabletNarrow: connect(extendTeamSquad(TeamSquadView.TabletNarrow)),
  Mobile: connect(extendTeamSquad(TeamSquadView.Mobile)),
};
