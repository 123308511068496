import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { HappeningPeriod } from 'packages/event-summary/types';
import { isEmpty } from 'packages/utils/Object.utils';
import { HappeningTimeLine } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  periods: HappeningPeriod[];
};

const TimeLine = ({ periods }: Props) => {
  return (
    <>
      {periods.map(({ happeningsOfThePeriod, periodHappening }) => {
        const periodName = i18n.value(
          `eventSummary.timeLine.periods.${periodHappening.periodType}`,
        );

        return (
          <div className={styles.periodSummary} key={periodHappening.id}>
            <div className={styles.header}>
              <div>{periodName}</div>
              <div className={styles.periodScoreContainer}>{periodHappening.score}</div>
            </div>
            {!isEmpty(happeningsOfThePeriod) && (
              <HappeningTimeLine
                happenings={happeningsOfThePeriod}
                periodHappening={periodHappening}
                className={styles.body}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default memo(TimeLine);
