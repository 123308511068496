import { PopoverJustify } from '../types';

const calculateJustifyWithoutTarget = (
  justify: PopoverJustify,
  popoverEl: HTMLElement,
  getParent: () => HTMLElement | null,
  insertIntoParent: boolean = false,
  customLeft: number = 0,
): number => {
  const popoverWidth = popoverEl.clientWidth;
  const parentEl = getParent();

  switch (justify) {
    case PopoverJustify.Left:
      return getLeftJustify(popoverWidth, customLeft, parentEl, insertIntoParent);
    case PopoverJustify.CenterStart:
    case PopoverJustify.Center:
    case PopoverJustify.CenterEnd:
      return getCenteredJustify(popoverWidth, customLeft, parentEl, insertIntoParent);
    case PopoverJustify.Right:
      return getRightJustify(popoverWidth, customLeft, parentEl, insertIntoParent);
    default:
      throw Error('Invalid justify specified');
  }
};

const getLeftJustify = (
  popoverWidth: number,
  customLeft: number,
  parentEl: HTMLElement | null,
  insertIntoParent: boolean,
) => {
  if (parentEl && insertIntoParent) {
    return parentEl.getBoundingClientRect().left + customLeft;
  }

  return customLeft;
};

const getCenteredJustify = (
  popoverWidth: number,
  customLeft: number,
  parentEl: HTMLElement | null,
  insertIntoParent: boolean,
) => {
  const centerEdgeLeft = getCenterEdgeLeft(parentEl, insertIntoParent);

  return centerEdgeLeft - popoverWidth / 2 + customLeft;
};

const getRightJustify = (
  popoverWidth: number,
  customLeft: number,
  parentEl: HTMLElement | null,
  insertIntoParent: boolean,
) => {
  const rightEdgeLeft = getRightEdgeLeft(parentEl, insertIntoParent);

  return rightEdgeLeft - popoverWidth + customLeft;
};

const getRightEdgeLeft = (parentEl: HTMLElement | null, insertIntoParent: boolean) => {
  if (!parentEl) {
    return window.innerWidth;
  }

  if (insertIntoParent) {
    return parentEl.clientWidth;
  } else {
    const { left, width } = parentEl.getBoundingClientRect();

    return left + width;
  }
};

const getCenterEdgeLeft = (parentEl: HTMLElement | null, insertIntoParent: boolean) => {
  if (!parentEl) {
    return window.innerWidth / 2;
  }

  if (insertIntoParent) {
    return parentEl.clientWidth / 2;
  } else {
    const { left, width } = parentEl.getBoundingClientRect();

    return left + width / 2;
  }
};

export default calculateJustifyWithoutTarget;
