import React, { memo } from 'react';
import classnames from 'classnames';
import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import { DesktopPage } from 'router/types';
import { useNavigation } from 'packages/hooks';
import { MediaScreen } from 'types';

import { Theme } from './types';
import styles from './styles.scss';

type Props = {
  playerInName?: string;
  playerOutName?: string;
  playerInId?: number;
  playerOutId?: number;
  isIconFirst?: boolean;
  minute?: number | null;
  theme?: Theme;
};

const SubstitutionLabel = ({
  playerInName = '',
  playerOutName = '',
  isIconFirst = false,
  minute = null,
  theme = Theme.Light,
  playerInId,
  playerOutId,
}: Props) => {
  const { appNavigate } = useNavigation(MediaScreen.Desktop);

  const containerClasses = classnames(
    styles.substitutionLabel,
    { [styles.iconFirst]: isIconFirst },
    styles[theme],
  );

  const handlePlayerClick = (playerId?: number) => () => {
    if (playerId) {
      appNavigate(DesktopPage.FootballPlayer, { playerId });
    }
  };

  return (
    <div className={containerClasses}>
      {playerInName && playerOutName && (
        <div className={styles.playersInfo}>
          <div
            onClick={handlePlayerClick(playerInId)}
            className={classnames(styles.newPlayerName, {
              [styles.clickable]: playerInId !== undefined,
            })}
          >{`${playerInName} →`}</div>
          <div
            onClick={handlePlayerClick(playerOutId)}
            className={classnames(styles.oldPlayerName, {
              [styles.clickable]: playerOutId !== undefined,
            })}
          >{` ${playerOutName}`}</div>
        </div>
      )}
      {minute && <div className={styles.minute}>{`${minute}'`}</div>}
      <div className={styles.iconContainer}>
        <Icon id={IconType.Change} />
      </div>
    </div>
  );
};

export default memo(SubstitutionLabel);
