import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Data } from '../types';
import {
  loadActiveTournamentSeasonsFromSessionStorageSuccess,
  updateTeamActiveTournamentSeasonSuccess,
} from '../actions';

const defaultState: Data = {};

const reducerMap: ReducerMap<Data, Data> = {
  [`${combineActions(
    loadActiveTournamentSeasonsFromSessionStorageSuccess,
    updateTeamActiveTournamentSeasonSuccess,
  )}`]: (state, { payload }) => {
    return payload;
  },
};

export default handleActions(reducerMap, defaultState);
