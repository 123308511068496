import React, { memo } from 'react';

import { BaseHappening } from 'packages/event-time-line/types';

import { getSinglePositionHappeningElementsByPeriodName } from './services';
import { Theme } from '../../../../types';
import { HappeningLine } from '../../components';

type Props = {
  isAwayHappening?: boolean;
  happening: BaseHappening;
  theme?: Theme;
  useDefaultHappeningComponent?: boolean;
};

const HappeningRow = ({
  happening,
  isAwayHappening = true,
  theme,
  useDefaultHappeningComponent,
}: Props) => {
  const { happeningElement, chipElement } = getSinglePositionHappeningElementsByPeriodName(
    happening,
    isAwayHappening,
    theme,
    useDefaultHappeningComponent,
  );

  return (
    <HappeningLine
      homeHappeningElement={!isAwayHappening ? happeningElement : undefined}
      awayHappeningElement={isAwayHappening ? happeningElement : undefined}
      chipElement={chipElement}
      theme={theme}
    />
  );
};

export default memo(HappeningRow);
