import React from 'react';

import { Size, Theme } from 'packages/shared/components/TeamLabel/types';
import { TeamLabel } from 'packages/shared/components';
import { Team } from 'types';

import styles from './styles.scss';

type Props = {
  teams: Team[];
  onTeamClick?: (team: Team) => void;
};

const TeamsListPopover = ({ teams, onTeamClick }: Props) => {
  const handleBlockClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div onMouseUp={handleBlockClick} className={styles.teamsList}>
      {teams.map((team) => (
        <div key={team.id} className={styles.listItem}>
          <TeamLabel
            onLogoClick={onTeamClick}
            onNameClick={onTeamClick}
            theme={Theme.Light}
            isLogoFirst
            size={Size.Sm}
            team={team}
          />
        </div>
      ))}
    </div>
  );
};

export default TeamsListPopover;
