import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { Manager } from 'packages/team-details/types';
import { Figure } from 'packages/shared/components';

import { LoadingSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  manager?: Manager;
  isLoading?: boolean;
};

const Description = ({ manager, isLoading }: Props) => {
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (!manager) {
    return <></>;
  }

  const { name, logo, countryLogo } = manager;

  return (
    <div className={styles.managerContainer}>
      <div className={styles.header}>
        {i18n.value('team.teamSummary.description.currentManager.title')}
      </div>
      <div className={styles.managerDescriptionContainer}>
        <Figure src={logo} className={styles.logo} />
        <div className={styles.description}>
          <div className={styles.name}>
            {name}
            <Figure src={countryLogo} className={styles.countryLogo} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Description);
