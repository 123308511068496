import { PeriodStatuses, PeriodStatusViews } from 'packages/events/types';

export const PERIOD_STATUS_PRIORITY = {
  p1: 1, // first period
  ft: 2, // full time
  ot: 3, // over time
  ap: 4, // after penalty
  unknown: 5,
};

export const PERIOD_DICTIONARY_KEYS = {
  1: '1ST',
  2: '2ND',
  11: '1ST_EXTRA',
  12: '2ND_EXTRA',
  20: 'PENALTIES',
  31: 'HALFTIME',
  32: 'AWAITING_EXTRA_TIME',
  33: 'EXTRA_HALFTIME',
  34: 'AWAITING_PENALTIES',
};

export const STATUS_VIEW_SET = {
  [PeriodStatuses.FIRST_PERIOD]: PeriodStatusViews.FIRST_PERIOD,
  [PeriodStatuses.SECOND_PERIOD]: PeriodStatusViews.SECOND_PERIOD,
  [PeriodStatuses.AWAITING_PENALTIES]: PeriodStatusViews.AWAITING_PENALTIES,
  [PeriodStatuses.PENALTY]: PeriodStatusViews.AFTER_PENALTIES,
  [PeriodStatuses.OVERTIME]: PeriodStatusViews.OVERTIME,
  [PeriodStatuses.FULL_TIME]: PeriodStatusViews.FULL_TIME,
};
