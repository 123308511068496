import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Interfaces } from '../../types';
import { actualGamesActions, commonActions } from '../../actions';

const defaultState = [];

const reducerMap: ReducerMap<Interfaces.ActualGames.Data, Interfaces.ActualGames.Data> = {
  [`${actualGamesActions.loadActualGamesSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(
    actualGamesActions.loadActualGamesFailure,
    actualGamesActions.cleanActualGamesSuccess,
    commonActions.cleanSummarySuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
