import i18n from 'i18n-smart';

import { SelectItem } from 'packages/shared/components/Select/types';
import { PlayerTeam } from 'packages/player-events/types';

import getTeamSelectItem from './getTeamSelectItem';
import { ALL_ITEMS_OPTION_ID } from '../../../constants';

const getTeamSelectItems = (teams: PlayerTeam[]): SelectItem[] => {
  return [
    {
      id: ALL_ITEMS_OPTION_ID,
      label: i18n.value(`player.events.filters.teamSelect.label`),
      componentProps: {
        name: i18n.value(`player.events.filters.teamSelect.label`),
      },
    },
    ...teams.map(getTeamSelectItem),
  ];
};

export default getTeamSelectItems;
