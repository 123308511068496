export const scrollHorizontallyByIdToCenter = (id): void => {
  scrollToById(id, 'center', 'center', false);
};

export const scrollToById = (
  id: string,
  block: ScrollLogicalPosition = 'nearest',
  inline: ScrollLogicalPosition = 'nearest',
  isSmooth: boolean = false,
): void => {
  scrollTo(document.getElementById(id), block, inline, isSmooth);
};

export const scrollTo = (
  element: HTMLElement | null,
  block: ScrollLogicalPosition,
  inline: ScrollLogicalPosition,
  isSmooth: boolean = false,
): void => {
  if (element) {
    element.scrollIntoView({ block, inline, behavior: isSmooth ? 'smooth' : 'auto' });
  }
};

export const getScrollParent = (node) => {
  if (node === document || !node) {
    return document;
  }

  const overflowRegex = /(auto|scroll)/;
  let style = getComputedStyle(node);

  if (overflowRegex.test(style.overflow + style.overflowY + style.overflowX)) {
    return node;
  }

  return getScrollParent(node.parentNode);
};
