import { Tournaments } from 'packages/rest';
import { settingsSelectors } from 'packages/settings';

import * as actions from '../actions';
import { mapTournaments } from '../services';

export const loadPopularTournaments = () => async (dispatch, getState) => {
  try {
    dispatch(actions.loadPopularTournamentsStart());

    const activeSportId = settingsSelectors.getActiveSportId(getState());
    const { data } = await Tournaments.loadPopularTournaments({ activeSportId });
    const popularTournaments = mapTournaments(data.results);

    dispatch(actions.loadPopularTournamentsSuccess({ popularTournaments }));
  } catch (error) {
    dispatch(actions.loadPopularTournamentsFailure());
  }
};
