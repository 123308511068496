import React, { memo, useCallback, useState } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { EventActiveFilters, Season, Tournament } from 'packages/team-events/types';
import { YearItem } from 'packages/shared/components/Calendar/types';
import { Button, DatePicker, Icon, Separator } from 'packages/shared/components';
import { ButtonType } from 'packages/shared/components/Button/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { Weight } from 'packages/shared/components/Separator/types';
import DateService from 'packages/date-az';
import { LocaleCode } from 'types';

import { SeasonSelect, TournamentSelect } from '..';
import { YEARS_SELECT_RANGE } from './constants';
import styles from './styles.scss';

type Props = {
  tournaments: Tournament[];
  onFilterUpdate: (
    period: [string, string],
    selectedSeasons: Season[],
    tournament?: Tournament,
  ) => void;
  activeFilters: EventActiveFilters;
  eventsCount: number;
  closePopover: () => void;
};

interface FilterState {
  tournament?: Tournament;
  seasons: Season[];
  period: [string, string];
}

const FiltrationPopover = ({
  tournaments,
  activeFilters,
  onFilterUpdate,
  eventsCount,
  closePopover,
}: Props) => {
  const { period, tournament, selectedSeasons } = activeFilters;
  const [filter, setFilter] = useState<FilterState>({
    tournament,
    seasons: selectedSeasons,
    period,
  });

  const getFilterSetter = <T extends keyof FilterState>(filterKey: T) => (
    value: FilterState[T],
  ) => {
    setFilter({ ...filter, [filterKey]: value });
  };

  const handleYearChange = (year: YearItem) => {
    const { startDate, endDate } = year;
    const setPeriod = getFilterSetter('period');

    setPeriod([startDate.toISODate(), endDate.toISODate()]);
  };

  const handleClearFilterClick = useCallback(() => {
    const now = DateService.getDate().toISODate();

    setFilter({ tournament: undefined, seasons: [], period: [now, now] });
  }, []);

  const handleApplyClick = useCallback(() => {
    const { period, tournament, seasons } = filter;

    onFilterUpdate(period, seasons, tournament);
    closePopover();
  }, [filter]);

  return (
    <div className={styles.filtrationPopover}>
      <div className={styles.header}>
        <div className={styles.title}>
          {i18n.value('team.teamEvents.mobileGamesFilterPopover.title')}
        </div>
        <Button
          onClick={closePopover}
          type={ButtonType.Default}
          contentClassName={styles.closeButton}
        >
          <Icon id={IconType.Cross} className={styles.icon} />
        </Button>
      </div>
      <Separator weight={Weight.Bold} />
      <div className={styles.filters}>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('team.teamEvents.mobileGamesFilterPopover.periodFilter.label')}
          </span>
          <DatePicker
            onApplyClick={getFilterSetter('period')}
            onYearClick={handleYearChange}
            eventsCount={eventsCount}
            yearOffset={YEARS_SELECT_RANGE}
            localeCode={LocaleCode.En}
            value={filter.period}
            isRangeMode
          />
        </div>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('team.teamEvents.mobileGamesFilterPopover.tournamentFilter.label')}
          </span>
          <TournamentSelect
            onChange={getFilterSetter('tournament')}
            selectedTournament={filter.tournament}
            tournaments={tournaments}
            className={styles.select}
          />
        </div>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('team.teamEvents.mobileGamesFilterPopover.seasonFilter.label')}
          </span>
          <SeasonSelect
            onChange={getFilterSetter('seasons')}
            seasons={filter.tournament?.seasons || []}
            isDisabled={!filter.tournament}
            selectedSeasons={filter.seasons}
            className={styles.select}
          />
        </div>
      </div>
      <Separator />
      <div className={styles.footer}>
        <div onClick={handleClearFilterClick} className={classnames(styles.button, styles.clear)}>
          {i18n.value('team.teamEvents.mobileGamesFilterPopover.clearFilter')}
        </div>

        <div className={styles.controlButtons}>
          <Button onClick={closePopover} type={ButtonType.Secondary} className={styles.button}>
            {i18n.value('team.teamEvents.mobileGamesFilterPopover.cancel')}
          </Button>
          <Button type={ButtonType.Primary} onClick={handleApplyClick} className={styles.button}>
            {i18n.value('team.teamEvents.mobileGamesFilterPopover.apply')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default memo(FiltrationPopover);
