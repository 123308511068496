import React, { memo, RefObject, useLayoutEffect, useRef, useState } from 'react';

import { FlexiblePopover } from 'packages/shared/components';
import { isTextOverlaps } from 'packages/utils/Document.utils';
import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';

import styles from './styles.scss';
import PlayerPopoverContent from '../PlayerPopoverContent';

type Props = {
  name: string;
};

const PlayerCell = ({ name }: Props) => {
  const labelRef: RefObject<HTMLDivElement> = useRef(null);
  const [isPopoverDisabled, setIsPopoverDisabled] = useState<boolean>(true);

  useLayoutEffect(() => {
    if (labelRef.current && isTextOverlaps(labelRef.current)) {
      setIsPopoverDisabled(false);
    }
  }, [name]);

  return (
    <div className={styles.cellContainer}>
      <FlexiblePopover
        closeOnClickOutside
        popoverComponent={PlayerPopoverContent}
        popoverProps={{ name }}
        justify={PopoverJustify.CenterStart}
        invokeTrigger={PopoverInvokeTrigger.Hover}
        align={PopoverAlign.Top}
        isDisabled={isPopoverDisabled}
      >
        <div className={styles.playerName}>
          <div ref={labelRef}>{name}</div>
        </div>
      </FlexiblePopover>
    </div>
  );
};

export default memo(PlayerCell);
