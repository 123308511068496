exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3DiGFHO5{background:inherit;position:relative;display:flex;flex-direction:column;flex-shrink:0}._3DiGFHO5 ._z0ooTifk{display:flex;justify-content:space-between}._3DiGFHO5 ._z0ooTifk ._2ldpedCv{padding:8px 16px}._3DiGFHO5 ._3LWwEGe9{position:-webkit-sticky;position:sticky;z-index:3;background:inherit}._3DiGFHO5 ._2bcnvUv1{flex:1 1}._ID6UWFCl{min-height:unset;flex:1 1}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Tabs/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,kBAAmB,CACnB,iBAAkB,CAClB,YAAa,CACb,qBAAsB,CAEtB,aAAc,CAPlB,sBAUM,YAAa,CACb,6BAA8B,CAXpC,iCAcQ,gBChBM,CDEd,sBAmBM,uBAAgB,CAAhB,eAAgB,CAChB,SAAU,CACV,kBAAmB,CArBzB,sBAyBM,QAAO,CAzBb,WA8BI,gBAAiB,CACjB,QAAO","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .tabs {\n    background: inherit;\n    position: relative;\n    display: flex;\n    flex-direction: column;\n\n    flex-shrink: 0;\n\n    .navigation {\n      display: flex;\n      justify-content: space-between;\n\n      .with-padding {\n        padding: 8px $padding;\n      }\n    }\n\n    .sticky-navigation {\n      position: sticky;\n      z-index: 3;\n      background: inherit;\n    }\n\n    .no-data-label {\n      flex: 1;\n    }\n  }\n\n  .loader-container {\n    min-height: unset;\n    flex: 1;\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"tabs": "_3DiGFHO5",
	"navigation": "_z0ooTifk",
	"with-padding": "_2ldpedCv",
	"withPadding": "_2ldpedCv",
	"sticky-navigation": "_3LWwEGe9",
	"stickyNavigation": "_3LWwEGe9",
	"no-data-label": "_2bcnvUv1",
	"noDataLabel": "_2bcnvUv1",
	"loader-container": "_ID6UWFCl",
	"loaderContainer": "_ID6UWFCl"
};