import React from 'react';

import { getScrollParent } from 'packages/utils/Scroll.utils';

interface ScrollParameters {
  tableRef: React.RefObject<HTMLDivElement>;
}

const scrollToTable = ({ tableRef }: ScrollParameters) => {
  const tabPanel = tableRef.current?.parentElement?.firstChild;
  const scrollContainerElement = getScrollParent(tableRef.current);

  if (!tabPanel) {
    return;
  }

  const { top: containerTopOffset = 0 } = scrollContainerElement.getBoundingClientRect() || {};
  const { top: tableTopOffset = 0 } = tableRef.current?.getBoundingClientRect() || {};
  const tabPanelHeight = (tabPanel as HTMLElement).offsetHeight;
  const scrollOffset = tableTopOffset - containerTopOffset - tabPanelHeight;

  scrollContainerElement.scrollTo({
    left: 0,
    top: scrollOffset,
    behavior: 'smooth',
  });
};

export default scrollToTable;
