import { handleActions, ReducerMap } from 'redux-actions';

import { loadMatchStatisticsSuccess, cleanMatchStatisticsSuccess } from '../actions';
import { Data } from '../types';

const defaultState: Data = {
  details: [],
  timeLines: {
    homeTimeLine: [],
    awayTimeLine: [],
  },
};

const reducerMap: ReducerMap<Data, Data> = {
  [`${loadMatchStatisticsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${cleanMatchStatisticsSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
