import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { EventDetails } from 'packages/event-details-ui';
import { Advertisement } from 'packages/shared/components';
import { FeaturedOdds } from 'packages/odds-ui';
import { SeasonStandings } from 'packages/season-standings-ui';
import { SearchQuery } from 'router/types';

import styles from './styles.scss';

const SeasonStandingsPage = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  return (
    <Grid.DoubleColumn
      isSecondaryOpen={false}
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
    >
      <div key={GridColumnId.LeftBar} className={styles.leftBar}>
        <SeasonStandings.Desktop />
      </div>

      <div key={GridColumnId.Primary} className={styles.sideBar}>
        {eventId ? (
          <EventDetails.Desktop eventId={eventId} />
        ) : (
          <>
            <Advertisement />
            <FeaturedOdds.Desktop />
          </>
        )}
      </div>
    </Grid.DoubleColumn>
  );
};

export default memo(SeasonStandingsPage);
