import { Season } from 'packages/team-events/types';

import getTeamSelectItem from './getTeamSelectItem';
import { SeasonSelectOption } from '../types';

const getTeamSelectItems = (seasons: Season[]): SeasonSelectOption[] => {
  return seasons.map((season) => getTeamSelectItem(season));
};

export default getTeamSelectItems;
