import { finishedEventsSelectors } from '../../selectors';
import { finishedEventsActions } from '../../actions';
import { loadEvents } from '../common';

interface Params {
  categoryId?: number;
}

export const loadMoreFinishedEvents = ({ categoryId }: Params) => async (dispatch, getState) => {
  try {
    const offset = finishedEventsSelectors.getFinishedEventsOffset(getState());
    dispatch(finishedEventsActions.loadMoreFinishedEventsStart());

    const eventsData = await dispatch(loadEvents({ categoryId, offset, isFinished: true }));

    dispatch(finishedEventsActions.loadMoreFinishedEventsSuccess(eventsData));
  } catch (error) {
    dispatch(finishedEventsActions.loadMoreFinishedEventsFailure(error));
  }
};
