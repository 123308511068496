import { EventStandings, SeasonStandings } from 'packages/rest';

import { mobileFilterStagesActions } from '../../actions';
import { mapStandings } from '../../services';

const loadStages = (seasonId: number) => async (dispatch) => {
  try {
    dispatch(mobileFilterStagesActions.loadStagesStart());

    const [
      {
        data: { results: seasonTablesStagesResponse },
      },
      {
        data: { results: seasonBracketsResponse },
      },
    ] = await Promise.all([
      SeasonStandings.loadSeasonTables({ seasonId }),
      EventStandings.loadEventBrackets({ seasonId }),
    ]);

    const stages = mapStandings({
      seasonTablesStages: seasonTablesStagesResponse,
      tournamentBrackets: seasonBracketsResponse,
    });

    dispatch(mobileFilterStagesActions.loadStagesSuccess({ stages }));
  } catch (e) {
    dispatch(mobileFilterStagesActions.loadStagesFailure(e));
  }
};

export default loadStages;
