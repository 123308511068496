import React, { memo } from 'react';
import isEmpty from 'is-empty-typed/lib';

import { FlexiblePopover, TeamsLogoList, TeamsListPopover } from 'packages/shared/components';
import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';
import { Team } from 'types';

import styles from './styles.scss';
import EmptyCell from '../EmptyCell';

type Props = {
  teams: Team[];
  onTeamClick: (team: Team) => void;
};

const ClubsCell = ({ teams, onTeamClick }: Props) => {
  return isEmpty(teams) ? (
    <EmptyCell />
  ) : (
    <div className={styles.contentWrapper}>
      <FlexiblePopover
        closeOnClickOutside
        popoverComponent={TeamsListPopover}
        popoverProps={{ teams, onTeamClick }}
        justify={PopoverJustify.Center}
        invokeTrigger={PopoverInvokeTrigger.Click}
        align={PopoverAlign.Bottom}
      >
        <div>
          <TeamsLogoList teams={teams} />
        </div>
      </FlexiblePopover>
    </div>
  );
};

export default memo(ClubsCell);
