import React from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { PlayersParameter, TopPlayer } from 'packages/season-top-board/types';
import { Tooltip } from 'packages/shared/components';

import { ParameterHeader, TopPlayersPlayerCell } from '../../components';

export default (): Column<TopPlayer>[] => [
  {
    id: 'player',
    Header: i18n.value('common.tableParameter.short.player'),
    Cell: ({ value }) => <TopPlayersPlayerCell player={value} />,
    accessor: ({ player }) => player,
    width: 58,
    cellStyle: { paddingLeft: 16 },
    headerStyle: { textAlign: 'left', padding: '8px 8px 8px 16px' },
  },
  {
    id: 'gamesPlayed',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.GamesPlayed}`)}>
        <>{i18n.value(`common.tableParameter.short.${PlayersParameter.GamesPlayed}`)}</>
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.gamesPlayed,
    width: 10,
  },
  {
    id: 'yellowCards',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.YellowCards}`)}>
        <ParameterHeader parameter={PlayersParameter.YellowCards} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.yellowCards,
    width: 10,
  },
  {
    id: 'yellowRedCards',
    Header: (
      <Tooltip
        value={i18n.value(`common.tableParameter.extended.${PlayersParameter.YellowRedCards}`)}
      >
        <ParameterHeader parameter={PlayersParameter.YellowRedCards} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.yellowRedCards,
    width: 10,
  },
  {
    id: 'redCards',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.RedCards}`)}>
        <ParameterHeader parameter={PlayersParameter.RedCards} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.redCards,
    width: 12,
  },
];
