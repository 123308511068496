import React, { memo } from 'react';

import { SearchResults } from '../../../../components';
import { MIN_SYMBOL_COUNT } from '../../../../constants';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  searchKey: string;
};

const SearchResultsView = ({ searchKey }: Props) => {
  return (
    <div className={styles.container}>
      {searchKey.length >= MIN_SYMBOL_COUNT && <SearchResults className={styles.results} />}
    </div>
  );
};

export default connect(memo(SearchResultsView));
