import React, { useCallback, useEffect, useState, memo } from 'react';
import { useParams } from 'react-router-dom';
import { ActionCreatorsMapObject } from 'redux';

import {
  ColumnType,
  CustomFilterState,
  Filters,
  PresetType,
  TeamStatistics as TeamStatisticsType,
} from 'packages/season-team-statistics/types';
import { PaginationParams } from 'packages/shared/components/Pagination/types';

import TeamStatisticsViews from './views';
import connect from './connect';

type Props = {
  filters: Filters;
  customFilters: CustomFilterState;
  teamsActions: ActionCreatorsMapObject;
  filtersActions: ActionCreatorsMapObject;
  customFilterActions: ActionCreatorsMapObject;
  commonActions: ActionCreatorsMapObject;
  teamStatistics: TeamStatisticsType[];
  isFiltersLoading: boolean;
  isInitialTeamsPageLoading: boolean;
  isNewTeamsPageLoading: boolean;
  limit: number;
  count: number;
};

const extendedTeamStatistics = (
  TeamStatistics: typeof TeamStatisticsViews[keyof typeof TeamStatisticsViews],
) =>
  memo(
    ({
      filters,
      customFilters,
      teamsActions,
      filtersActions,
      customFilterActions,
      commonActions,
      teamStatistics,
      isFiltersLoading,
      isInitialTeamsPageLoading,
      isNewTeamsPageLoading,
      limit,
      count,
    }: Props) => {
      const [isExtendedFilterOpen, setIsExtendedFilterOpen] = useState(false);
      const { seasonId } = useParams();

      useEffect(() => {
        teamsActions.loadInitialTeams(seasonId);

        return commonActions.cleanTeamStatistics;
      }, []);

      const toggleExtendedFilter = useCallback(() => {
        setIsExtendedFilterOpen((isExtendedFilterOpen) => !isExtendedFilterOpen);
      }, []);

      const handleApplyClick = useCallback(() => {
        toggleExtendedFilter();
      }, []);

      const handleCancelClick = useCallback(() => {
        toggleExtendedFilter();
      }, []);

      const handleCustomizeButtonClick = useCallback(() => {
        toggleExtendedFilter();
      }, []);

      const loadTeamsPage = useCallback(() => {
        teamsActions.loadTeamsPage(seasonId);
      }, [seasonId]);

      const loadFilters = useCallback(() => {
        filtersActions.loadFilters(seasonId);
      }, [seasonId]);

      const handleCustomFilterUpdate = useCallback((updates: CustomFilterState) => {
        customFilterActions.updateCustomFilter(updates);
        loadTeamsPage();
      }, []);

      const handlePresetChange = useCallback((presetType: PresetType) => {
        customFilterActions.updateFilterPreset(presetType);
        loadTeamsPage();
      }, []);

      const handleOrderingChange = useCallback((type: ColumnType) => {
        customFilterActions.updateConfigurableOrdering(type);
        loadTeamsPage();
      }, []);

      const handlePaginationChange = useCallback((params: PaginationParams) => {
        teamsActions.updatePagination(params);
        loadTeamsPage();
      }, []);

      return (
        <TeamStatistics
          filters={filters}
          customFilters={customFilters}
          teamStatistics={teamStatistics}
          isFiltersLoading={isFiltersLoading}
          isInitialTeamsPageLoading={isInitialTeamsPageLoading}
          isNewTeamsPageLoading={isNewTeamsPageLoading}
          isExtendedFilterOpen={isExtendedFilterOpen}
          limit={limit}
          count={count}
          onCustomFilterUpdate={handleCustomFilterUpdate}
          onPaginationChange={handlePaginationChange}
          onOrderingChange={handleOrderingChange}
          onCustomizeButtonClick={handleCustomizeButtonClick}
          onPresetChange={handlePresetChange}
          loadFilters={loadFilters}
          onApply={handleApplyClick}
          onCancel={handleCancelClick}
        />
      );
    },
  );

export default {
  Desktop: connect(extendedTeamStatistics(TeamStatisticsViews.Desktop)),
  DesktopNarrow: connect(extendedTeamStatistics(TeamStatisticsViews.DesktopNarrow)),
  Tablet: connect(extendedTeamStatistics(TeamStatisticsViews.Tablet)),
  TabletNarrow: connect(extendedTeamStatistics(TeamStatisticsViews.TabletNarrow)),
  Mobile: connect(extendedTeamStatistics(TeamStatisticsViews.Mobile)),
};
