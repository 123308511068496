import React from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { EventStatus } from 'types';
import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from './styles.scss';

type Props = {
  status: EventStatus;
  isShort?: boolean;
  className?: string;
};

const BreakStatusLabel = ({ status, isShort, className }: Props) => {
  const labelText = i18n.value(
    `eventStatusLabel.${isShort ? 'breakStatusShort' : 'breakStatus'}.${status}`,
  );

  return (
    <div className={classnames(styles.breakLabel, className)}>
      <Icon id={IconType.Clock} className={styles.icon} />
      <div>{labelText}</div>
    </div>
  );
};

export default BreakStatusLabel;
