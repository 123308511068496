exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3Q_eZonM{flex:1 1;margin-right:16px}._2OV1A2XS{flex:1 1;display:flex;align-items:flex-start}._4jZLssAL{width:443px}._4jZLssAL ._h5sVTOna{margin-top:16px}._2tRMGxzS{position:relative;display:flex;flex-direction:column;width:100%;height:100%}._2tRMGxzS>*:not(:last-of-type){margin-bottom:8px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktop/football/PlayerSummary/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,QAAO,CACP,YAAa,CACb,sBAAuB,CAT3B,WAaI,WAAY,CAbhB,sBAgBM,eCjBO,CDCb,WAqBI,iBAAkB,CAClB,YAAa,CACb,qBAAsB,CAEtB,UAAW,CACX,WAAY,CA1BhB,gCA6BM,iBAAkB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    align-items: flex-start;\n  }\n\n  .right-column {\n    width: 443px;\n\n    .advertisement {\n      margin-top: $margin;\n    }\n  }\n\n  .side-bar {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n\n    width: 100%;\n    height: 100%;\n\n    > *:not(:last-of-type) {\n      margin-bottom: 8px;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_3Q_eZonM",
	"leftColumn": "_3Q_eZonM",
	"left-bar": "_2OV1A2XS",
	"leftBar": "_2OV1A2XS",
	"right-column": "_4jZLssAL",
	"rightColumn": "_4jZLssAL",
	"advertisement": "_h5sVTOna",
	"side-bar": "_2tRMGxzS",
	"sideBar": "_2tRMGxzS"
};