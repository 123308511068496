import DateService from 'packages/date-az';
import { DATE_FORMATS } from 'packages/date-az/consts';

const getBirthdayViews = (date?: string) => {
  if (!date) {
    return;
  }

  const birthDay = DateService.getDateTimeFromIsoString(date);

  const yearsOldView = Math.floor(birthDay.diffNow('year').negate().years);

  const birthdayView = DateService.format(birthDay, DATE_FORMATS.FULL_DATE);

  return {
    yearsOldView,
    birthdayView,
  };
};

export default getBirthdayViews;
