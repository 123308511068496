import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { statisticsActions, commonActions } from '../../../../actions';
import { Interfaces } from '../../../../types';

const defaultState: Interfaces.Statistics.MobileFilter.Data = {};

const reducerMap: ReducerMap<
  Interfaces.Statistics.MobileFilter.Data,
  Interfaces.Statistics.MobileFilter.Data
> = {
  [`${combineActions(
    statisticsActions.mobileFilter.loadMobileFiltersSuccess,
    statisticsActions.tournamentSeasons.loadTournamentSeasonsSuccess,
  )}`]: (state, { payload }) => {
    return payload;
  },

  [`${statisticsActions.mobileFilter.updateMobileFiltersSuccess}`]: (state, { payload }) => {
    return { ...state, ...payload };
  },

  [`${combineActions(
    statisticsActions.mobileFilter.loadMobileFiltersFailure,
    statisticsActions.mobileFilter.cleanMobileFiltersSuccess,
    commonActions.cleanSummarySuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
