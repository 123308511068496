import { Season } from 'types';

import * as actions from '../../actions';
import { getDefaultFilterDates } from '../../services';
import { Filters } from '../../types';

interface Options {
  daysOffset?: number;
}

export const initActiveFilter = (selectedSeason: Season, { daysOffset }: Options = {}) => (
  dispatch,
) => {
  const activeFilter: Filters = {
    dates: getDefaultFilterDates(selectedSeason, daysOffset),
    teams: [],
    rounds: [],
  };

  dispatch(actions.updateActiveFilter(activeFilter));
};
