import { MediaScreen } from 'types';

import getState from './getState';

export const getAppState = (state) => {
  return getState(state).state;
};

export const getMediaScreen = (state) => {
  return getAppState(state).mediaScreen;
};

export const getViewWidth = (state) => {
  return getAppState(state).viewWidth;
};

export const getIsMobileScreen = (state) => {
  return getAppState(state).mediaScreen === MediaScreen.Mobile;
};

export const getIsDesktopScreen = (state) => {
  return getAppState(state).mediaScreen === MediaScreen.Desktop;
};

export const getIsAnyDesktopScreen = (state) => {
  const { mediaScreen } = getAppState(state);

  return mediaScreen === MediaScreen.Desktop || mediaScreen === MediaScreen.DesktopNarrow;
};
