import React from 'react';

import { Spinner } from 'packages/shared/components';
import { SpinnerSize } from 'packages/shared/components/Spinner/types';

import styles from './styles.scss';
import connect from './connect';

type Props = {
  tabName: string;
  favoriteGamesAmount: number;
  isLoading: boolean;
};

const FavoriteGamesTabHeader = ({ tabName, isLoading, favoriteGamesAmount }: Props) => {
  return (
    <div className={styles.favoriteGamesCounterContainer}>
      <span>{tabName}</span>
      {isLoading ? (
        <Spinner className={styles.spinner} size={SpinnerSize.Small} />
      ) : (
        <span className={styles.favoriteGamesAmountChip}>{favoriteGamesAmount}</span>
      )}
    </div>
  );
};

export default React.memo(connect(FavoriteGamesTabHeader));
