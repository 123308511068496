import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { categoryModalActions } from '../../actions';
import { Status } from '../../types';

const defaultState: Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${categoryModalActions.loadCategoryStart}`]: () => {
    return { isLoading: true };
  },

  [`${combineActions(
    categoryModalActions.loadCategorySuccess,
    categoryModalActions.loadCategoryFailure,
  )}`]: () => {
    return { isLoading: false };
  },

  [`${categoryModalActions.clearCategorySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
