import React, { memo, useCallback, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getSearchQuery } from 'router/services';

import { SelectItem } from 'packages/shared/components/Select/types';
import { TournamentSeasonSelect } from 'packages/shared/components';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { useNavigation } from 'packages/hooks';
import { MobilePage, SearchQuery } from 'router/types';
import { MediaScreen } from 'types';

import connect from './connect';

type Props = {
  tournaments?: SeasonsExtendedTournament[];
  isLoading?: boolean;
  className?: string;
};

const DetailsSelector = ({ tournaments = [], isLoading, className }: Props) => {
  const { appNavigate } = useNavigation(MediaScreen.Mobile);
  const { teamId, seasonId, tournamentId } = useParams();
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);
  const searchQuery = useMemo(() => getSearchQuery({ [SearchQuery.EventCardId]: eventId }), [
    eventId,
  ]);

  const handleTournamentChange = useCallback(
    (tournamentItem: SelectItem) => {
      if (teamId) {
        const [firstSelectedTournamentSeason] =
          tournaments.find(({ id }) => id === tournamentItem.id)?.seasons || [];

        appNavigate(
          MobilePage.FootballTeamSummaryDescription,
          {
            uniqueTournamentId: tournamentItem.id,
            seasonId: firstSelectedTournamentSeason.id,
            teamId,
          },
          searchQuery,
        );
      }
    },
    [teamId, tournaments, searchQuery],
  );

  const handleSeasonChange = useCallback(
    (seasonItem: SelectItem) => {
      if (teamId && tournamentId) {
        appNavigate(
          MobilePage.FootballTeamSummaryDescription,
          {
            uniqueTournamentId: tournamentId,
            seasonId: seasonItem.id,
            teamId,
          },
          searchQuery,
        );
      }
    },
    [teamId, tournamentId, searchQuery],
  );

  return (
    <TournamentSeasonSelect.Mobile
      tournaments={tournaments}
      onTournamentSelect={handleTournamentChange}
      onSeasonSelect={handleSeasonChange}
      activeTournamentId={tournamentId}
      activeSeasonId={seasonId}
      className={className}
      isLoading={isLoading}
      useSelectorsInOneRow
    />
  );
};

export default connect(memo(DetailsSelector));
