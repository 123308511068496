import instance from 'packages/rest/axios';

import { SeasonHistoryParams } from '../types';

export const loadSeasonHistory = ({ tournamentId }: SeasonHistoryParams) => {
  return instance({
    method: 'get',
    url: `/unique_tournaments/${tournamentId}/history/`,
  });
};
