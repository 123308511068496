import { Event } from 'types';

import { favoritesThunks } from 'packages/favorites';

import { favoriteEventsActions } from '../../actions';

const toggleEvent = (event: Event) => async (dispatch) => {
  try {
    dispatch(favoriteEventsActions.toggleFavoriteStart());

    await dispatch(favoritesThunks.toggleEvent(event));

    dispatch(favoriteEventsActions.toggleFavoriteSuccess());
  } catch (error) {
    dispatch(favoriteEventsActions.toggleFavoriteFailure(error));
  }
};

export default toggleEvent;
