import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { statisticsActions, commonActions } from '../../actions';
import { Interfaces } from '../../types';

const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Interfaces.Statistics.Status, Interfaces.Statistics.Status> = {
  [`${statisticsActions.loadStatisticsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(
    statisticsActions.loadStatisticsFailure,
    statisticsActions.loadStatisticsSuccess,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${combineActions(
    commonActions.cleanSummarySuccess,
    statisticsActions.cleanStatisticsSuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
