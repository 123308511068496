import React, { useCallback, memo } from 'react';
import { useSearchParams } from 'react-router-dom';
import i18n from 'i18n-smart';

import { BlockLink } from 'packages/shared/components';
import { Interfaces } from 'packages/player-summary/types';
import { Tournament } from 'packages/events-ui/containers/EventsTabs/components';
import { Event } from 'packages/events-ui';
import { SearchQuery } from 'router/types';

import styles from './styles.scss';
import { GamesSkeleton } from './components';

const eventComponentProps = {
  isDateVisible: true,
  isRoundVisible: true,
  isPlayerStatsShown: true,
};

type Props = {
  isLoading: boolean;
  useModalOnCategoryClick?: boolean;
  tournaments: Interfaces.ActualGames.Data;
  onLinkClick: () => void;
  eventComponent: typeof Event[keyof typeof Event];
  isCompact?: boolean;
};

const ActualGames = ({
  tournaments,
  eventComponent,
  onLinkClick,
  isLoading,
  useModalOnCategoryClick = false,
  isCompact = false,
}: Props) => {
  const [, setSearchParams] = useSearchParams();
  const lastTournamentIndex = tournaments.length - 1;

  const handleEventClick = useCallback((eventId: number) => {
    setSearchParams({ [SearchQuery.EventCardId]: eventId.toString() });
  }, []);

  if (isLoading) {
    return <GamesSkeleton isCompact={isCompact} />;
  }

  return (
    <div className={styles.actualGamesBlock}>
      <div className={styles.header}>
        <div className={styles.title}> {i18n.value(`player.summary.actualGames.title`)}</div>
        <BlockLink label={i18n.value('player.summary.actualGames.link')} onClick={onLinkClick} />
      </div>

      <div className={styles.gamesList}>
        {tournaments.map((tournament, index) => {
          const key = `${tournament.id}${index}`;

          return (
            <Tournament
              useModalOnCategoryClick={useModalOnCategoryClick}
              key={key}
              tournament={tournament}
              eventComponent={eventComponent}
              handleEventClick={handleEventClick}
              eventComponentProps={eventComponentProps}
              isUniqueTournamentNameDisplayed
              isTournamentLast={lastTournamentIndex === index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(ActualGames);
