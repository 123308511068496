import React from 'react';
import classnames from 'classnames';
import numeral from 'numeral';
import isEmpty from 'is-empty-typed/lib';
import i18n from 'i18n-smart';

import { PitchSize } from 'packages/stadium-details/types';

import styles from './styles.scss';

type Props = {
  className?: string;
  constructionYear?: string;
  capacity?: number;
  pitchSize?: PitchSize;
};

const Parameters = ({ className, constructionYear, capacity, pitchSize }: Props) => {
  return (
    <div className={classnames(styles.parameters, className)}>
      {!isEmpty(constructionYear) && (
        <div className={styles.parameter}>
          <span className={styles.value}>
            <span className={styles.main}>{constructionYear}</span>
          </span>
          <span className={styles.key}>Built</span>
        </div>
      )}
      {!isEmpty(capacity) && (
        <div className={styles.parameter}>
          <span className={styles.value}>
            <span className={styles.main}>{numeral(capacity).format('0,0')}</span>
          </span>
          <span className={styles.key}>Capacity</span>
        </div>
      )}
      {!isEmpty(pitchSize) && (
        <div className={styles.parameter}>
          <span className={styles.value}>
            <span className={styles.main}>{pitchSize.x}</span>
            {i18n.value('common.unit.meters.short')} X{' '}
            <span className={styles.main}>{pitchSize.y}</span>
            {i18n.value('common.unit.meters.short')}
          </span>
          <span className={styles.key}>Pitch size</span>
        </div>
      )}
    </div>
  );
};

export default Parameters;
