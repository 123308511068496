import { useRef } from 'react';

type ReturnType<ValueType> = [() => ValueType, (value: ValueType) => void];

const useMutableState = <ValueType = any>(initialValue: ValueType): ReturnType<ValueType> => {
  const ref = useRef(initialValue);

  const getState = () => {
    return ref.current;
  };

  const setState = (value) => {
    ref.current = value;
  };

  return [getState, setState];
};

export default useMutableState;
