exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3-OGt671{display:flex;align-items:center}._3-OGt671 ._T00yhTNH{border-radius:50%;cursor:pointer;min-width:32px;max-width:32px;min-height:32px;max-height:32px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/team-squad-ui/containers/TeamSquad/components/PlayersTable/components/PlayerLogo/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/size.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,kBAAmB,CAHvB,sBAMM,iBAAkB,CAClB,cAAe,CCRnB,cDU4B,CCT5B,cDS4B,CCN1B,eDM0B,CCL1B,eDK0B","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .cell-container {\n    display: flex;\n    align-items: center;\n\n    .player-logo {\n      border-radius: 50%;\n      cursor: pointer;\n\n      @include fixed-size(32px);\n    }\n  }\n}","@mixin fixed-size($width, $height: null) {\n  min-width: $width;\n  max-width: $width;\n\n  @if ($height == null) {\n    min-height: $width;\n    max-height: $width;\n  } @else {\n    min-height: $height;\n    max-height: $height;\n  }\n}"]}]);

// Exports
exports.locals = {
	"cell-container": "_3-OGt671",
	"cellContainer": "_3-OGt671",
	"player-logo": "_T00yhTNH",
	"playerLogo": "_T00yhTNH"
};