import { FavoritesStatus } from '../types';
import getPath from './getPath';

export const getStatus = (state): FavoritesStatus => {
  return getPath(state).status;
};

export const getIsEventsLoading = (state): boolean => {
  return getStatus(state).isEventsLoading;
};
