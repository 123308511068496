import { combineReducers } from 'redux';

import headToHead from './headToHead';
import stages from './stages';
import mobileFilterStages from './mobileFilterStages';

export default combineReducers({
  headToHead,
  stages,
  mobileFilterStages,
});
