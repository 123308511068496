import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { allEventsSelectors, allEventsThunks } from 'packages/events';
import { favoritesSelectors } from 'packages/favorites';
import { settingsSelectors } from 'packages/settings';
import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => ({
  timeZone: settingsSelectors.getTimeZone(state),
  favoriteTournaments: favoritesSelectors.getFavoriteLeagues(state),
  activeSportId: settingsSelectors.getActiveSportId(state),
  activeEventId: eventDetailsSelectors.getActiveEventId(state),
  isLoading: allEventsSelectors.getIsAllEventsLoading(state),
  hasNext: allEventsSelectors.getHasNextAllEvents(state),
  events: allEventsSelectors.getAllEvents(state),
});

const mapDispatchToProps = (dispatch) => ({
  allEventsActions: bindActionCreators(allEventsThunks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
