import React, { useMemo, memo } from 'react';
import { Team } from 'types';

import { FlexibleTable } from 'packages/shared/components';
import { TournamentSeasonChampion } from 'packages/season-history/types';

import { getTableSchema } from './services';

type Props = {
  data: TournamentSeasonChampion[];
  onTeamClick: (team: Team) => void;
};

const TrophiesTable = ({ data, onTeamClick }: Props) => {
  const tableSchema = useMemo(() => getTableSchema({ onTeamClick }), [onTeamClick]);

  return <FlexibleTable isHeaderSticky data={data} columns={tableSchema} />;
};

export default memo(TrophiesTable);
