import { Interfaces } from '../../types';
import { customFilterSelectors } from '../../selectors';
import { customFilterStateActions } from '../../actions';

const updateGeneralFilter = (data: Partial<Interfaces.CustomFilter.GeneralFilter>) => (
  dispatch,
  getState,
) => {
  const { generalFilter, columnConfigurationFilter } = customFilterSelectors.getData(getState());

  dispatch(
    customFilterStateActions.customFilterUpdate({
      generalFilter: {
        ...generalFilter,
        ...data,
      },
      columnConfigurationFilter,
    }),
  );
};

export default updateGeneralFilter;
