import React from 'react';

import { Team } from 'types';
import { Size } from 'packages/shared/components/TeamLabel/types';
import { TeamLabel } from 'packages/shared/components';

type Props = {
  onTeamClick?: (team: Team) => void;
  team: Team;
};

const TournamentTableTeamCell = ({ team, onTeamClick }: Props) => (
  <TeamLabel
    onLogoClick={onTeamClick}
    onNameClick={onTeamClick}
    team={team}
    size={Size.Md}
    isLogoFirst
  />
);

export default TournamentTableTeamCell;
