import React, { memo, useMemo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { EventLoadingSkeleton } from 'packages/events-ui/components';

type Props = {
  className: string;
  eventsCount: number;
  isCompact?: boolean;
};

const EventListSkeleton = ({ className, eventsCount, isCompact }: Props) => {
  const skeletonElements = useMemo(() => ArrayUtils.getArrayFromNumber(eventsCount), [eventsCount]);

  return (
    <div className={className}>
      {skeletonElements.map((element) => (
        <EventLoadingSkeleton
          key={element}
          isCompact={isCompact}
          isMatchResultVisible
          isTournamentVisible
        />
      ))}
    </div>
  );
};

export default memo(EventListSkeleton);
