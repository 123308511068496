import { TeamsOrderingParameter } from 'packages/rest/api/SeasonTopBoard/types';

import { TabMode, TeamsParameter } from '../types';

export const TEAM_DEFAULT_ORDERING = {
  [TabMode.Goals]: TeamsParameter.GoalsScored,
  [TabMode.Attack]: TeamsParameter.GoalAttempts,
  [TabMode.Aggression]: TeamsParameter.YellowCards,
};

export const TEAM_API_ORDERING_BY_PARAMETERS = {
  [TeamsParameter.GoalAttempts]: [
    TeamsOrderingParameter.GoalAttempts,
    TeamsOrderingParameter.ShotsOnGoal,
    TeamsOrderingParameter.Name,
  ],
  [TeamsParameter.BallPossession]: [
    TeamsOrderingParameter.BallPossession,
    TeamsOrderingParameter.Name,
  ],
  [TeamsParameter.GoalsByHead]: [
    TeamsOrderingParameter.GoalsByHead,
    TeamsOrderingParameter.Goals,
    TeamsOrderingParameter.Name,
  ],
  [TeamsParameter.GoalsScored]: [
    TeamsOrderingParameter.Goals,
    TeamsOrderingParameter.ShotsOnGoal,
    TeamsOrderingParameter.Name,
  ],
  [TeamsParameter.ShotsOnGoalPerGame]: [
    TeamsOrderingParameter.ShotsOnGoal,
    TeamsOrderingParameter.GoalAttempts,
    TeamsOrderingParameter.Name,
  ],
  [TeamsParameter.YellowRedCards]: [
    TeamsOrderingParameter.YellowRedCards,
    TeamsOrderingParameter.RedCards,
    TeamsOrderingParameter.YellowCards,
    TeamsOrderingParameter.Name,
  ],
  [TeamsParameter.RedCards]: [
    TeamsOrderingParameter.RedCards,
    TeamsOrderingParameter.YellowRedCards,
    TeamsOrderingParameter.YellowCards,
    TeamsOrderingParameter.Name,
  ],
  [TeamsParameter.YellowCards]: [
    TeamsOrderingParameter.YellowCards,
    TeamsOrderingParameter.YellowRedCards,
    TeamsOrderingParameter.RedCards,
    TeamsOrderingParameter.Name,
  ],
};
