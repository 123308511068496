import React from 'react';
import i18n from 'i18n-smart';

import { Tooltip } from 'packages/shared/components';

import EmptyCell from '../EmptyCell';
import styles from './styles.scss';

type Props = {
  attendance?: string;
};

const Attendance = ({ attendance }: Props) => {
  const tooltipTxt = i18n.value('common.matchAttendance');

  return (
    <Tooltip value={tooltipTxt}>
      <div className={styles.wrapper}>{attendance || <EmptyCell />}</div>
    </Tooltip>
  );
};

export default Attendance;
