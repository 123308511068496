import { Category } from 'types';
import { toCamelCase } from 'packages/utils/Object.utils';

export const mapEventCategory = (category): Category => {
  const { name, id, logo } = toCamelCase(category);

  return {
    name,
    logo,
    id,
  };
};
