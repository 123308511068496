import { formatScore } from './mapPeriodStartHappening';
import { getPenaltyScorerName } from './getPenaltyScorerName';
import { BaseHappening, PenaltyShootoutHappening } from '../../types';

const mapPenaltyHappening = (happening: BaseHappening): PenaltyShootoutHappening => {
  const { id, team, eventType, players, additionalInfo, injuryTime } = happening;
  const { score: penaltyScore, status } = additionalInfo;
  const score = penaltyScore ? formatScore(penaltyScore) : '-';
  const scorerName = getPenaltyScorerName(players);

  return {
    id,
    team,
    players,
    eventType,
    score,
    status,
    scorerName,
    injuryTime,
  };
};

export default mapPenaltyHappening;
