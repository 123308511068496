import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const appInitStart = createAction(`${NAMESPACE}INIT_START`);
export const appInitSuccess = createAction(`${NAMESPACE}INIT_SUCCESS`);
export const appInitFailure = createAction(`${NAMESPACE}INIT_FAILURE`);

export const appUpdateSettings = createAction(`${NAMESPACE}UPDATE_SETTINGS`);

export const setMediaInfo = createAction(`${NAMESPACE}SET_MEDIA_INFO`);

export const appInitSetDevTranslations = createAction(`${NAMESPACE}INIT_SET_DEV_TRANSLATIONS`);

export const failure = createAction(`${NAMESPACE}FAILURE`);
