import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadFavoriteEventsStart = createAction(`${NAMESPACE}LOAD_FAVORITE_EVENTS_START`);
export const loadFavoriteEventsSuccess = createAction(`${NAMESPACE}LOAD_FAVORITE_EVENTS_SUCCESS`);
export const loadFavoriteEventsFailure = createAction(`${NAMESPACE}LOAD_FAVORITE_EVENTS_FAILURE`);

export const loadLatestFavoriteEventsStart = createAction(
  `${NAMESPACE}LOAD_LATEST_FAVORITE_EVENTS_START`,
);
export const loadLatestFavoriteEventsSuccess = createAction(
  `${NAMESPACE}LOAD_LATEST_FAVORITE_EVENTS_SUCCESS`,
);
export const loadLatestFavoriteEventsFailure = createAction(
  `${NAMESPACE}LOAD_LATEST_FAVORITE_EVENTS_FAILURE`,
);

export const toggleFavoriteStart = createAction(`${NAMESPACE}TOGGLE_FAVORITE_EVENT_START`);
export const toggleFavoriteSuccess = createAction(`${NAMESPACE}TOGGLE_FAVORITE_EVENT_SUCCESS`);
export const toggleFavoriteFailure = createAction(`${NAMESPACE}TOGGLE_FAVORITE_EVENT_FAILURE`);

export const cleanFavoriteEventsSuccess = createAction(`${NAMESPACE}CLEAN_FAVORITE_EVENTS_SUCCESS`);
export const cleanFavoriteEventsFailure = createAction(`${NAMESPACE}CLEAN_FAVORITE_EVENTS_FAILURE`);

export const updateFavoriteEventsStart = createAction(`${NAMESPACE}UPDATE_FAVORITE_EVENTS_START`);
export const updateFavoriteEventsFailure = createAction(
  `${NAMESPACE}UPDATE_FAVORITE_EVENTS_FAILURE`,
);
export const updateFavoriteEventsSuccess = createAction(
  `${NAMESPACE}UPDATE_FAVORITE_EVENTS_SUCCESS`,
);

export const toggleFavoriteEventsStart = createAction(`${NAMESPACE}TOGGLE_FAVORITE_EVENTS_START`);
export const toggleFavoriteEventsSuccess = createAction(
  `${NAMESPACE}TOGGLE_FAVORITE_EVENTS_SUCCESS`,
);
export const toggleFavoriteEventsFailure = createAction(
  `${NAMESPACE}TOGGLE_FAVORITE_EVENTS_FAILURE`,
);

export const subscribeForFavoriteEventsUpdatesStart = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_FAVORITE_EVENTS_UPDATES_START`,
);
export const subscribeForFavoriteEventsUpdatesFailure = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_FAVORITE_EVENTS_UPDATES_FAILURE`,
);
export const subscribeForFavoriteEventsUpdatesSuccess = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_FAVORITE_EVENTS_UPDATES_SUCCESS`,
);
