import React, { memo } from 'react';
import isEmpty from 'is-empty-typed';

import EmptyCell from '../EmptyCell';
import styles from './styles.scss';
import { InTeamDate } from './components';

type Props = {
  startDate?: string;
  playerId: number;
  teamId: string;
};

const InTeamCell = ({ startDate, playerId, teamId }: Props) => {
  return (
    <div className={styles.wrapper}>
      {isEmpty(startDate) ? (
        <EmptyCell />
      ) : (
        <InTeamDate teamId={teamId} playerId={playerId} startDate={startDate} />
      )}
    </div>
  );
};

export default memo(InTeamCell);
