import React, { CSSProperties, useMemo, useRef } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { BracketRound } from 'packages/event-standings/types';
import { NoDataLabel } from 'packages/shared/components';

import { useBracketNavigation, useInitialContentHeight } from './hooks';
import { BracketColumn, NavigationButton } from './components';
import { getColumnElementsIdsByIndex, markActiveMatchUps } from './services';
import styles from './styles.scss';

type Props = {
  rounds: BracketRound[];
  style?: CSSProperties;
  className?: string;
  columnAmount?: number;
  activeTeamIds?: number[];
  activeUniqueTournamentId?: number | string;
  activeSeasonId?: number | string;
  activeEventId?: number;
};

const BracketGrid = ({
  rounds,
  className,
  style,
  columnAmount = 2,
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
  activeEventId,
}: Props) => {
  const gridContainerRef = useRef<HTMLDivElement>(null);
  const gridRef = useRef<HTMLDivElement>(null);
  const leftButtonRef = useRef<HTMLDivElement>(null);
  const rightButtonRef = useRef<HTMLDivElement>(null);

  const { activeRoundIndex, markedRounds, baseMatchUpRoundIndex, baseMatchUpIndex } = useMemo(
    () => markActiveMatchUps(rounds, activeTeamIds, activeEventId),
    [rounds, activeTeamIds, activeEventId],
  );

  const [initialContentHeight, initialColumnsHeights] = useInitialContentHeight({
    rounds,
  });

  const [handleBackwardClick, handleForwardClick] = useBracketNavigation({
    gridRef,
    gridContainerRef,
    rightButtonRef,
    leftButtonRef,
    initialContentHeight,
    initialColumnsHeights,
    baseMatchUpRoundIndex,
    baseMatchUpIndex,
    rounds,
  });

  return (
    <div className={classnames(styles.navigationContainer, className)}>
      {!isEmpty(markedRounds) ? (
        <>
          <NavigationButton
            forwardedRef={leftButtonRef}
            onClick={handleBackwardClick}
            className={classnames(styles.navigationButton, styles.left)}
          />
          <NavigationButton
            forwardedRef={rightButtonRef}
            onClick={handleForwardClick}
            className={classnames(styles.navigationButton, styles.right)}
            reversed
          />

          <div
            className={classnames(styles.gridContainer, className)}
            ref={gridContainerRef}
            style={style}
          >
            <div
              ref={gridRef}
              className={styles.flexContainer}
              style={{
                gridTemplateColumns: `repeat(${markedRounds.length}, ${100 / columnAmount}%)`,
              }}
            >
              {markedRounds.map((round, index) => {
                const { headerId, gridId } = getColumnElementsIdsByIndex(index);
                const isRoundActive = index === activeRoundIndex;
                activeTeamIds = activeEventId ? [] : activeTeamIds;

                return (
                  !isEmpty(round.matchups) && (
                    <BracketColumn
                      isActive={isRoundActive}
                      activeTeamIds={activeTeamIds}
                      activeUniqueTournamentId={activeUniqueTournamentId}
                      activeSeasonId={activeSeasonId}
                      scrollContainerRef={gridContainerRef}
                      gridElementId={gridId}
                      headerElementId={headerId}
                      initialHeight={initialColumnsHeights[index]}
                      isFirstRound={index === 0}
                      round={round}
                      key={round.id}
                    />
                  )
                );
              })}
            </div>
          </div>
        </>
      ) : (
        <NoDataLabel />
      )}
    </div>
  );
};

export default BracketGrid;
