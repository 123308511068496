import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState: Status = {
  isInitialized: false,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${actions.appInitSuccess}`]: (state) => {
    return { ...state, isInitialized: true };
  },
};

export default handleActions(reducerMap, defaultState);
