exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._16FWRcs2{padding:48px 12px 48px 12px}._18vhuWBZ{display:flex;flex:1 1;position:relative;width:100%;flex-direction:column}._18vhuWBZ ._2L7ssVcE{display:flex;overflow:hidden;flex:1 1}._18vhuWBZ ._2L7ssVcE ._2XL4nGA9{flex:1 1;margin-right:16px}._18vhuWBZ ._2L7ssVcE ._ivRtKJJ2{width:443px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/mobile/football/Stadium/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,2BAA2D,CAF/D,WAMI,YAAa,CACb,QAAO,CAEP,iBAAkB,CAClB,UAAW,CACX,qBAAsB,CAX1B,sBAcM,YAAa,CAEb,eAAgB,CAChB,QAAO,CAjBb,iCAoBQ,QAAO,CACP,iBCtBK,CDCb,iCAyBQ,WAAY","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .modal-window {\n    padding: $header-height 12px $footer-navigation-height 12px;\n  }\n\n  .stadium {\n    display: flex;\n    flex: 1;\n\n    position: relative;\n    width: 100%;\n    flex-direction: column;\n\n    .content-wrapper {\n      display: flex;\n\n      overflow: hidden;\n      flex: 1;\n\n      .left-column {\n        flex: 1;\n        margin-right: $margin;\n      }\n\n      .right-column {\n        width: 443px;\n      }\n    }\n  }\n}","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"modal-window": "_16FWRcs2",
	"modalWindow": "_16FWRcs2",
	"stadium": "_18vhuWBZ",
	"content-wrapper": "_2L7ssVcE",
	"contentWrapper": "_2L7ssVcE",
	"left-column": "_2XL4nGA9",
	"leftColumn": "_2XL4nGA9",
	"right-column": "_ivRtKJJ2",
	"rightColumn": "_ivRtKJJ2"
};