import React, { memo, RefObject, useRef } from 'react';
import classnames from 'classnames';

import { Team } from 'types';
import { Size } from 'packages/shared/components/TeamLabel/types';
import { TeamLabel } from 'packages/shared/components';
import { useIntersection } from 'packages/hooks';

import styles from './styles.scss';

type Props = {
  homeTeam: Team;
  awayTeam: Team;
  parentRef: RefObject<any>;
  isSticky?: boolean;
  className?: string;
};

const Header = ({ homeTeam, awayTeam, parentRef, isSticky = false, className }: Props) => {
  const containerRef: RefObject<any> = useRef();
  const isHeaderSticky = isSticky ? useIntersection(containerRef, { parentRef }) : false;

  return (
    <div
      ref={containerRef}
      className={classnames(styles.teamsHeader, className, {
        [styles.sticky]: isSticky,
        [styles.shadow]: isHeaderSticky,
      })}
    >
      <TeamLabel className={styles.teamLabel} team={homeTeam} size={Size.Lg} isLogoFirst />
      <TeamLabel className={styles.teamLabel} team={awayTeam} size={Size.Lg} />
    </div>
  );
};

export default memo(Header);
