import React, { memo } from 'react';

import { Separator, Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Skeleton width={80} height={16} variant="rectangular" />
      </div>
      <div className={styles.newcomers}>
        <div className={styles.newcomer}>
          <Skeleton width={40} height={40} variant="circular" />
          <Skeleton marginTop={16} width={60} height={16} variant="rectangular" />
        </div>
        <div className={styles.newcomer}>
          <Skeleton width={40} height={40} variant="circular" />
          <Skeleton marginTop={16} width={60} height={16} variant="rectangular" />
        </div>
        <div className={styles.newcomer}>
          <Skeleton width={40} height={40} variant="circular" />
          <Skeleton marginTop={16} width={60} height={16} variant="rectangular" />
        </div>
      </div>

      <Separator />

      <div className={styles.header}>
        <Skeleton width={100} height={14} variant="rectangular" />
      </div>
      <div className={styles.lowerDivision}>
        <div className={styles.league}>
          <Skeleton width={18} height={18} marginRight={8} variant="circular" />
          <Skeleton width={50} height={16} variant="rectangular" />
        </div>
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
