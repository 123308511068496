import { Interfaces } from 'packages/team-player-statistics/types';

import getFootSelectItems from './getFootSelectItems';
import getStatsTabs from './getStatsTabs';

const formatFilters = (
  filters: Interfaces.Filters.Filters,
): Interfaces.CustomFilter.GeneralFilterProps => {
  const { nationalities } = filters;

  return {
    nationalitySelectItems: formatNationalities(nationalities),
    footSelectItems: getFootSelectItems(),
    statTabs: getStatsTabs(),
  };
};

const formatNationalities = (
  nationalities: Interfaces.Filters.Nationality[],
): Interfaces.CustomFilter.NationalitySelectOption[] => {
  return nationalities.map((nationality) => {
    const { id, name } = nationality;

    return {
      id,
      label: name,
      value: name,
      componentProps: {
        item: nationality,
        isLogoFirst: true,
      },
    };
  });
};

export default formatFilters;
