import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose, Store } from 'redux';

import errors from '../middleware/errors';
import device from '../middleware/device';
import reducers from './reducers';

const composeEnhancers =
  window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__'] && process.env.NODE_ENV === 'development'
    ? window['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']
    : compose;

const middleware: any[] = [];

middleware.push(thunk);
middleware.push(errors);
middleware.push(device);

const configureStore = (initialState = {}): Store<any, any> => {
  const store = createStore(
    reducers,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  if (module['hot'] && process.env.NODE_ENV === 'development') {
    module['hot'].accept();
  }

  return store;
};

export default configureStore;
