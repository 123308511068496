import {
  PromotionColorTypes,
  PromotionGroups,
} from 'packages/shared/components/TeamStatistic/types';

export const PROMOTION_COLOR_GROUPS = {
  [PromotionColorTypes.PROMOTIONCOLOR_CL]: PromotionGroups.FIRST_GROUP,
  [PromotionColorTypes.PROMOTIONCOLOR_FINALFOUR]: PromotionGroups.FIRST_GROUP,
  [PromotionColorTypes.PROMOTIONCOLOR_CLQ]: PromotionGroups.SECOND_GROUP,
  [PromotionColorTypes.PROMOTIONCOLOR_PROMOTION]: PromotionGroups.THIRD_GROUP,
  [PromotionColorTypes.PROMOTIONCOLOR_PROMOTIONPLAYOFF]: PromotionGroups.FOURTH_GROUP,
  [PromotionColorTypes.PROMOTIONCOLOR_PLAYOFF]: PromotionGroups.FIFTH_GROUP,
  [PromotionColorTypes.PROMOTIONCOLOR_UEFA]: PromotionGroups.SIXTH_GROUP,
  [PromotionColorTypes.PROMOTIONCOLOR_UEFAQ]: PromotionGroups.SEVENTH_GROUP,
  [PromotionColorTypes.PROMOTIONCOLOR_RELEGATIONPLAYOFF]: PromotionGroups.EIGHTH_GROUP,
  [PromotionColorTypes.PROMOTIONCOLOR_RELEGATION]: PromotionGroups.NINTH_GROUP,
};
