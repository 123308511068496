import { FootballEvent } from '../components';

const getEventSchema = (activeSportId) => {
  switch (activeSportId) {
    case 1:
    default:
      return FootballEvent;
  }
};

export default getEventSchema;
