import { combineReducers } from 'redux';

import { sortObjectByKeys } from 'packages/services';
import { createReducer as createAppReducer } from 'modules/app';
import { createReducer as createConfigReducer } from 'packages/config';
import { createReducer as createSettingsReducer } from 'packages/settings';
import { createReducer as createNotifierReducer } from 'packages/notifier';

import { createReducer as createEventsReducer } from 'packages/events';
import { createReducer as createEventDetailsReducer } from 'packages/event-details';
import { createReducer as createEventSummaryReducer } from 'packages/event-summary';
import { createReducer as createEventStatsReducer } from 'packages/event-stats';
import { createReducer as createEventLineupsReducer } from 'packages/event-lineups';
import { createReducer as createEventHeadToHeadReducer } from 'packages/event-head-to-head';
import { createReducer as createEventStandingsReducer } from 'packages/event-standings';
import { createReducer as createEventTimeLineReducer } from 'packages/event-time-line';
import { createReducer as createEventCommentaryReducer } from 'packages/event-commentary';

import { createReducer as categoriesReducer } from 'packages/categories';
import { createReducer as createTournamentReducer } from 'packages/tournaments';
import { createReducer as createRenewedFavoritesReducer } from 'packages/favorites';
import { createReducer as createSearchReducer } from 'packages/search';

import { createReducer as createSeasonEventsReducer } from 'packages/season-events';
import { createReducer as createSeasonDetailsReducer } from 'packages/season-details';
import { createReducer as createSeasonStandingsReducer } from 'packages/season-standings';
import { createReducer as createSeasonTopBoardReducer } from 'packages/season-top-board';
import { createReducer as createSeasonNewcomersReducer } from 'packages/season-newcomers';
import { createReducer as createSeasonPlayerStatisticsReducer } from 'packages/season-player-statistics';
import { createReducer as createSeasonTeamStatisticsReducer } from 'packages/season-team-statistics';
import { createReducer as createSeasonHistoryReducer } from 'packages/season-history';
import { createReducer as createSeasonStadiumsReducer } from 'packages/season-stadiums';

import { createReducer as createTeamSummaryReducer } from 'packages/team-summary';
import { createReducer as createTeamPlayerStatisticsReducer } from 'packages/team-player-statistics';
import { createReducer as createTeamEventsReducer } from 'packages/team-events';
import { createReducer as createTeamSquadReducer } from 'packages/team-squad';
import { createReducer as createTeamActiveTournamentSeasonReducer } from 'packages/team-active-tournament-season';
import { createReducer as createTeamStandingsReducer } from 'packages/team-standings';
import { createReducer as createTeamDetailsReducer } from 'packages/team-details';

import { createReducer as createPlayerSummaryReducer } from 'packages/player-summary';
import { createReducer as createPlayerGamesReducer } from 'packages/player-events';
import { createReducer as createPlayerDetailsReducer } from 'packages/player-details';

import { createReducer as createStadiumDetailsReducer } from 'packages/stadium-details';
import { createReducer as createStadiumSummaryReducer } from 'packages/stadium-summary';

import { createReducer as createMatchDetailsReducer } from 'packages/match-details';
import { createReducer as createMatchLastGamesReducer } from 'packages/match-last-games';
import { createReducer as createMatchStandingsReducer } from 'packages/match-standings';
import { createReducer as createMatchTopPlayersReducer } from 'packages/match-top-players';
import { createReducer as createMatchSummaryReducer } from 'packages/match-summary';
import { createReducer as createMatchShotsReducer } from 'packages/match-shots';
import { createReducer as createMatchStatisticsReducer } from 'packages/match-statistics';
import { createReducer as createMatchTimeLineReducer } from 'packages/match-time-line';
import { createReducer as createMatchLineupsReducer } from 'packages/match-lineups';

import { ReducerLevel } from '../types';

const stadiumReducer = combineReducers({
  details: createStadiumDetailsReducer(),
  summary: createStadiumSummaryReducer(),
});

const teamReducer = combineReducers({
  details: createTeamDetailsReducer(),
  playerStatistics: createTeamPlayerStatisticsReducer(),
  activeTournamentSeason: createTeamActiveTournamentSeasonReducer(),
  standings: createTeamStandingsReducer(),
  summary: createTeamSummaryReducer(),
  games: createTeamEventsReducer(),
  squad: createTeamSquadReducer(),
});

const seasonPageReducers = combineReducers({
  details: createSeasonDetailsReducer(),
  topBoard: createSeasonTopBoardReducer(),
  standings: createSeasonStandingsReducer(),
  events: createSeasonEventsReducer(),
  newcomers: createSeasonNewcomersReducer(),
  playerStatistics: createSeasonPlayerStatisticsReducer(),
  teamStatistics: createSeasonTeamStatisticsReducer(),
  history: createSeasonHistoryReducer(),
  stadiums: createSeasonStadiumsReducer(),
});

const eventCardReducers = combineReducers({
  details: createEventDetailsReducer(),
  summary: createEventSummaryReducer(),
  stats: createEventStatsReducer(),
  lineups: createEventLineupsReducer(),
  headToHead: createEventHeadToHeadReducer(),
  standings: createEventStandingsReducer(),
  timeLine: createEventTimeLineReducer(),
  commentary: createEventCommentaryReducer(),
});

const playerReducer = combineReducers({
  summary: createPlayerSummaryReducer(),
  games: createPlayerGamesReducer(),
  details: createPlayerDetailsReducer(),
});

const matchReducer = combineReducers({
  shots: createMatchShotsReducer(),
  details: createMatchDetailsReducer(),
  lastGames: createMatchLastGamesReducer(),
  standings: createMatchStandingsReducer(),
  topPlayers: createMatchTopPlayersReducer(),
  summary: createMatchSummaryReducer(),
  statistics: createMatchStatisticsReducer(),
  timeLine: createMatchTimeLineReducer(),
  lineups: createMatchLineupsReducer(),
});

const rootReducers = {
  app: createAppReducer(),
  config: createConfigReducer(),
  notifier: createNotifierReducer(),
  categories: categoriesReducer(),
  events: createEventsReducer(),
  tournaments: createTournamentReducer(),
  favorites: createRenewedFavoritesReducer(),
  settings: createSettingsReducer(),
  search: createSearchReducer(),
  [ReducerLevel.Team]: teamReducer,
  [ReducerLevel.Season]: seasonPageReducers,
  [ReducerLevel.EventCard]: eventCardReducers,
  [ReducerLevel.Player]: playerReducer,
  [ReducerLevel.Stadium]: stadiumReducer,
  [ReducerLevel.Match]: matchReducer,
};

export default combineReducers(sortObjectByKeys(rootReducers));
