import React from 'react';
import { Column } from 'react-table';

import { Skeleton } from 'packages/shared/components';

import { TeamCell, FormCell } from '../components';

const centerAlignStyle = {
  display: 'flex',
  justifyContent: 'center',
};

const getColumnSchema = (): Column[] => {
  return [
    {
      id: 'position',
      Header: () => <Skeleton height={12} width={70} variant="rectangular" />,
      headerStyle: { textAlign: 'left', paddingLeft: 16 },
      cellStyle: { paddingLeft: 16 },
      Cell: TeamCell,
      width: 170,
    },
    {
      id: 'gamesPlayed',
      Header: () => <Skeleton height={12} width={16} variant="rectangular" />,
      Cell: () => <Skeleton height={16} width={24} variant="rectangular" />,
      headerStyle: centerAlignStyle,
      cellStyle: centerAlignStyle,
      width: 16,
    },
    {
      id: 'win',
      Header: () => <Skeleton height={12} width={16} variant="rectangular" />,
      Cell: () => <Skeleton height={16} width={24} variant="rectangular" />,
      headerStyle: centerAlignStyle,
      cellStyle: centerAlignStyle,
      width: 16,
    },
    {
      id: 'draw',
      Header: () => <Skeleton height={12} width={16} variant="rectangular" />,
      Cell: () => <Skeleton height={16} width={24} variant="rectangular" />,
      headerStyle: centerAlignStyle,
      cellStyle: centerAlignStyle,
      width: 16,
    },
    {
      id: 'loss',
      Header: () => <Skeleton height={12} width={16} variant="rectangular" />,
      Cell: () => <Skeleton height={16} width={24} variant="rectangular" />,
      headerStyle: centerAlignStyle,
      cellStyle: centerAlignStyle,
      width: 16,
    },
    {
      id: 'statistics',
      Header: () => <Skeleton height={12} width={16} variant="rectangular" />,
      Cell: () => <Skeleton height={16} width={37} variant="rectangular" />,
      headerStyle: centerAlignStyle,
      cellStyle: centerAlignStyle,
      width: 22,
    },
    {
      id: 'goalDifference',
      Header: () => <Skeleton height={12} width={20} variant="rectangular" />,
      Cell: () => <Skeleton height={16} width={24} variant="rectangular" />,
      headerStyle: centerAlignStyle,
      cellStyle: centerAlignStyle,
      width: 19,
    },
    {
      id: 'form',
      Header: () => <Skeleton height={12} width={30} variant="rectangular" />,
      Cell: FormCell,
      headerStyle: { maxWidth: 116, ...centerAlignStyle },
      cellStyle: { maxWidth: 116, ...centerAlignStyle },
      minWidth: 130,
    },
    {
      id: 'points',
      Header: () => <Skeleton height={12} width={16} variant="rectangular" />,
      Cell: () => <Skeleton height={16} width={24} variant="rectangular" />,
      headerStyle: centerAlignStyle,
      cellStyle: centerAlignStyle,
      width: 16,
    },
  ];
};

export default getColumnSchema;
