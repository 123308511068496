import React from 'react';
import { Event } from 'types';
import classnames from 'classnames';

import DateService from 'packages/date-az';

import styles from './styles.scss';

type Props = {
  event: Event;
  isShort?: boolean;
  className?: string;
};

const ScheduleLabel = ({ event, isShort = false, className }: Props) => {
  const { scheduled } = event;

  const label = isShort
    ? DateService.getEventTime(scheduled)
    : DateService.getEventFullDate(scheduled);

  return <div className={classnames(styles.scheduleLabel, className)}>{label}</div>;
};

export default ScheduleLabel;
