import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { eventsActions, commonActions } from '../../actions';
import { DEFAULT_EVENTS_LIMIT } from '../../constants';
import { EventStatus } from '../../types';

const defaultState: EventStatus = {
  isInitialLoading: true,
  isMoreLoading: false,
  count: 0,
  offset: 0,
  limit: DEFAULT_EVENTS_LIMIT,
};

const reducerMap: ReducerMap<EventStatus, Partial<EventStatus>> = {
  [`${eventsActions.loadInitialEventsStart}`]: (state) => {
    return { ...state, isInitialLoading: true };
  },

  [`${combineActions(
    eventsActions.loadInitialEventsFailure,
    eventsActions.loadInitialEventsSuccess,
  )}`]: (state) => {
    return { ...state, isInitialLoading: false };
  },

  [`${eventsActions.loadMoreEventsStart}`]: (state) => {
    return { ...state, isMoreLoading: true };
  },

  [`${combineActions(eventsActions.loadMoreEventsFailure, eventsActions.loadMoreEventsSuccess)}`]: (
    state,
  ) => {
    return { ...state, isMoreLoading: false };
  },

  [`${commonActions.updateCountSuccess}`]: (state, { payload }) => {
    const { count = 0 } = payload;

    return {
      ...state,
      count,
    };
  },

  [`${commonActions.updatePaginationSuccess}`]: (state, { payload }) => {
    const { offset = 0, limit = 0 } = payload;

    return {
      ...state,
      offset,
      limit,
    };
  },

  [`${commonActions.clearSuccess}`]: () => {
    return defaultState;
  },

  [`${commonActions.updateActiveFilterSuccess}`]: (state) => {
    return {
      ...state,
      count: defaultState.count,
      offset: defaultState.offset,
      limit: defaultState.limit,
    };
  },
};

export default handleActions(reducerMap, defaultState);
