import { toCamelCase } from 'packages/utils/Object.utils';
import DateService from 'packages/date-az';
import { Team } from 'types';

import { TopPlayer } from '../types';

export const mapSeasonTopPlayers = (seasonTopPlayers: any[]): TopPlayer[] => {
  return seasonTopPlayers.map((player) => mapSeasonTopPlayer(player));
};

const mapSeasonTopPlayer = (seasonTopPlayer: { player; statistics; team }): TopPlayer => {
  const { player, statistics, team } = seasonTopPlayer;
  const { id, firstName, lastName, name, countryLogo, logo, position, dateOfBirth } = toCamelCase(
    player,
  );

  const {
    shotsOnTarget,
    matchesPlayed,
    assists,
    goalPoints,
    goalsScored,
    offsides,
    totalShots,
    yellowCards,
    yellowRedCards,
    redCards,
  } = toCamelCase(statistics);

  return {
    id,
    player: {
      id,
      name,
      firstName,
      lastName,
      countryLogo,
      position,
      age: getPlayerAge(dateOfBirth),
      team: getTeam(team),
      logo,
    },
    statistics: {
      shotsOnGoal: shotsOnTarget?.total || 0,
      gamesPlayed: matchesPlayed?.total || 0,
      assists: assists?.total || 0,
      goalPoints: goalPoints?.total || 0,
      goals: goalsScored?.total || 0,
      offside: offsides?.total || 0,
      totalShots: totalShots?.total || 0,
      yellowCards: yellowCards?.total || 0,
      yellowRedCards: yellowRedCards?.total || 0,
      redCards: redCards?.total || 0,
    },
  };
};

const getPlayerAge = (dateOfBirth: string): string => {
  return `${Math.abs(DateService.getDurationToNow(dateOfBirth).years)}`;
};

const getTeam = (team: object): Team => {
  const { id, logo, name } = toCamelCase(team);

  return {
    id,
    logo,
    name,
  };
};
