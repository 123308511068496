import { EventType } from 'packages/event-commentary/types';

import Chip from '../components';

const getHappeningComponentByEventType = (eventType) => {
  switch (eventType) {
    case EventType.PeriodStart:
      return Chip.PeriodStart;
    case EventType.MatchStarted:
      return Chip.PeriodStart;
    case EventType.PeriodScore:
      return Chip.PeriodStart;
    case EventType.Corner:
      return Chip.Corner;
    case EventType.Substitution:
      return Chip.Substitution;
    case EventType.Goal:
      return Chip.Goal;
    case EventType.YellowRedCard:
    case EventType.YellowCard:
    case EventType.RedCard:
      return Chip.Card;
    case EventType.Offside:
      return Chip.Offside;
    case EventType.ShotOnTarget:
      return Chip.Shot;
    case EventType.ShotOffTarget:
      return Chip.Shot;
    case EventType.InjuryTime:
      return Chip.InjuryTime;
    case EventType.Injury:
      return Chip.Injury;
    case EventType.PenaltyRewarded:
      return Chip.PenaltyRewarded;
    case EventType.PenaltyMissed:
      return Chip.PenaltyMissed;
    default:
      return Chip.Minute;
  }
};

export default getHappeningComponentByEventType;
