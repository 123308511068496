import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Settings } from '../types';

const defaultState: Settings = {
  devTranslations: {},
};

const reducerMap: ReducerMap<Settings, any> = {
  [`${actions.appInitSetDevTranslations}`]: (state, { payload }) => {
    const { devTranslations } = payload;

    return { ...state, devTranslations };
  },
};

export default handleActions(reducerMap, defaultState);
