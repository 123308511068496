import React, { memo } from 'react';
import isEmpty from 'is-empty-typed';

import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { Figure } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import { LoadingSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  tournamentList?: SeasonsExtendedTournament[];
  isLoading?: boolean;
};

const ActualLeagues = ({ tournamentList, isLoading = false }: Props) => {
  const { appNavigate } = useNavigation();

  const getTournamentClickHandler = (tournament: SeasonsExtendedTournament) => () => {
    const { id, seasons } = tournament;

    appNavigate(DesktopPage.FootballSeason, { uniqueTournamentId: id, seasonId: seasons[0].id });
  };

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (isEmpty(tournamentList)) {
    return <></>;
  }

  return (
    <div className={styles.actualLeagues}>
      <>
        <div className={styles.header}>
          <div className={styles.title}>Actual leagues</div>
          <div className={styles.description}>・ last season played</div>
        </div>
        <div className={styles.tournamentList}>
          {tournamentList.map((tournament) => {
            const { id, name, logo, seasons } = tournament;
            const handleTournamentClick = getTournamentClickHandler(tournament);

            return (
              <div key={id} className={styles.tournament}>
                <Figure onClick={handleTournamentClick} src={logo} className={styles.logo} />
                <div onClick={handleTournamentClick} className={styles.label}>
                  {name} <span className={styles.season}>{seasons[0].year}</span>
                </div>
              </div>
            );
          })}
        </div>
      </>
    </div>
  );
};

export default memo(ActualLeagues);
