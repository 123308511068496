import React, { memo, useCallback, useMemo, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import isEmpty from 'is-empty-typed';
import i18n from 'i18n-smart';

import {
  Button,
  Icon,
  Select,
  Separator,
  SelectItemComponent,
  SelectLabelComponent,
} from 'packages/shared/components';
import { ButtonType } from 'packages/shared/components/Button/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { Weight } from 'packages/shared/components/Separator/types';
import { SelectItem, SelectSize } from 'packages/shared/components/Select/types';
import { useStageTabs, useFilterTabs } from 'packages/season-standings-ui/hooks';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { getTournamentSeasonSelectItems } from 'packages/team-standings-ui/containers/Standings/services';
import { StageTab } from 'packages/season-standings-ui/types';
import { Stage } from 'packages/event-standings/types';
import { useNonInitialEffect } from 'packages/hooks';
import {
  getBracketProps,
  getFilterTabs,
  getTableProps,
} from 'packages/team-standings-ui/containers/Standings/views/Mobile/services';
import { getActiveStageIdByTeamId } from 'packages/team-standings/services';

import { useTabsAsSelect } from './hooks';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  onFilterUpdate: (tournamentId?: string, seasonId?: string) => void;
  tournaments: SeasonsExtendedTournament[];
  teamId?: string;
  activeStageTabId?: StageTab['id'];
  activeFilterTabId?: StageTab['id'];
  closePopover: () => void;
  stages: Stage[];
  mobileFilterStagesActions: ActionCreatorsMapObject;
  isLoading: boolean;
  tournamentId?: string;
  seasonId?: string;
};

const FiltrationPopover = ({
  teamId,
  closePopover,
  activeStageTabId,
  activeFilterTabId,
  onFilterUpdate,
  tournaments,
  stages,
  mobileFilterStagesActions,
  tournamentId,
  seasonId,
  isLoading,
}: Props) => {
  const [localTournamentId, setLocalTournamentId] = useState(tournamentId);
  const [localSeasonId, setLocalSeasonId] = useState(seasonId);
  const [defaultStageTabId, setDefaultStageTabId] = useState(activeStageTabId);

  useNonInitialEffect(() => {
    setDefaultStageTabId(getActiveStageIdByTeamId(stages, parseInt(teamId || '')));
  }, [stages, teamId]);

  const { activeSeasonItem, activeTournamentItem, seasonItems, tournamentItems } = useMemo(
    () =>
      getTournamentSeasonSelectItems(
        tournaments,
        parseInt(localTournamentId || ''),
        parseInt(localSeasonId || ''),
      ),
    [tournaments, localTournamentId, localSeasonId],
  );

  useNonInitialEffect(() => {
    mobileFilterStagesActions.loadStages(localSeasonId);
  }, [localSeasonId]);

  const StageTabs = useStageTabs(stages, {
    getTableProps,
    getBracketProps,
    defaultActiveTabId: defaultStageTabId,
    additionalProps: { activeTeamIds: teamId ? [parseInt(teamId)] : undefined },
  });

  const FilterTabs = useFilterTabs(stages, {
    getFilterTabs,
    activeStageTab: StageTabs.activeTab,
    defaultActiveTabId: activeFilterTabId,
  });

  const StageTabSelect = useTabsAsSelect(StageTabs);
  const FilterTabSelect = useTabsAsSelect(FilterTabs);

  const handleTournamentChange = useCallback(
    (tournamentItem: SelectItem) => {
      const [firstSelectedTournamentSeason] =
        tournaments.find(({ id }) => id === tournamentItem.id)?.seasons || [];

      setLocalTournamentId(`${tournamentItem.id}`);
      setLocalSeasonId(`${firstSelectedTournamentSeason.id}`);
    },
    [tournaments],
  );

  const handleSeasonChange = useCallback((seasonItem: SelectItem) => {
    setLocalSeasonId(`${seasonItem.id}`);
  }, []);

  const handleClearFilterClick = useCallback(() => {
    setLocalTournamentId(tournamentId);
    setLocalSeasonId(seasonId);
  }, [tournamentId, seasonId]);

  const handleCancelClick = useCallback(() => {
    mobileFilterStagesActions.resetStages();
    closePopover();
  }, []);

  const handleApplyClick = useCallback(() => {
    onFilterUpdate(localTournamentId, localSeasonId);
    mobileFilterStagesActions.setDefaultFilter(StageTabs.activeTab?.id, FilterTabs.activeTab?.id);
    closePopover();
  }, [localTournamentId, localSeasonId, StageTabs, FilterTabs, onFilterUpdate]);

  const isFilterTabsDisabled =
    isEmpty(FilterTabs.activeTab) || isEmpty(StageTabs.tabs) || FilterTabs.isTabsDisabled;
  const isStageTabsDisabled = isEmpty(StageTabs.activeTab) || StageTabs.tabs.length <= 1;

  return (
    <div className={styles.filtrationPopover}>
      <div className={styles.header}>
        <div className={styles.title}>
          {i18n.value('team.teamEvents.mobileGamesFilterPopover.title')}
        </div>
        <Button
          onClick={handleCancelClick}
          type={ButtonType.Default}
          contentClassName={styles.closeButton}
        >
          <Icon id={IconType.Cross} className={styles.icon} />
        </Button>
      </div>
      <Separator weight={Weight.Bold} />
      <div className={styles.filters}>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('team.standings.filterPopover.tournamentFilter.label')}
          </span>
          <Select
            className={styles.select}
            items={tournamentItems}
            size={SelectSize.Lg}
            selectedItem={activeTournamentItem}
            onChange={handleTournamentChange}
            labelComponentProps={activeTournamentItem?.componentProps}
            labelComponent={SelectLabelComponent.Tournament}
            itemComponent={SelectItemComponent.Tournament}
          />
        </div>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('team.standings.filterPopover.seasonFilter.label')}
          </span>
          <Select
            className={styles.select}
            items={seasonItems}
            size={SelectSize.Lg}
            selectedItem={activeSeasonItem}
            onChange={handleSeasonChange}
          />
        </div>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('team.standings.filterPopover.stageFilter.label')}
          </span>
          <Select
            className={styles.select}
            items={StageTabSelect.selectItems}
            size={SelectSize.Lg}
            selectedItem={StageTabSelect.activeSelectItem}
            onChange={StageTabSelect.handleSelectItem}
            isDisabled={isStageTabsDisabled}
            isLoading={isLoading}
          />
        </div>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('team.standings.filterPopover.tableViewFilter.label')}
          </span>
          <Select
            className={styles.select}
            items={FilterTabSelect.selectItems}
            size={SelectSize.Lg}
            selectedItem={FilterTabSelect.activeSelectItem}
            onChange={FilterTabSelect.handleSelectItem}
            isDisabled={isFilterTabsDisabled}
            isLoading={isLoading}
          />
        </div>
      </div>
      <Separator />
      <div className={styles.footer}>
        <Button
          type={ButtonType.Default}
          onClick={handleClearFilterClick}
          className={styles.button}
        >
          {i18n.value('team.teamEvents.mobileGamesFilterPopover.clearFilter')}
        </Button>

        <div className={styles.controlButtons}>
          <Button onClick={handleCancelClick} type={ButtonType.Secondary} className={styles.button}>
            {i18n.value('team.teamEvents.mobileGamesFilterPopover.cancel')}
          </Button>
          <Button type={ButtonType.Primary} onClick={handleApplyClick} className={styles.button}>
            {i18n.value('team.teamEvents.mobileGamesFilterPopover.apply')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(memo(FiltrationPopover));
