import { MediaScreen } from 'types';

import useDesktopNavigation from './useDesktopNavigation';
import useMobileNavigation from './useMobileNavigation';

function useNavigation(mediaScreen: MediaScreen.Mobile): ReturnType<typeof useMobileNavigation>;
function useNavigation(mediaScreen: MediaScreen.Desktop): ReturnType<typeof useDesktopNavigation>;
function useNavigation(): ReturnType<typeof useDesktopNavigation>;

function useNavigation(mediaScreen: MediaScreen = MediaScreen.Desktop) {
  switch (mediaScreen) {
    case MediaScreen.Mobile:
      return useMobileNavigation();
    default:
      return useDesktopNavigation();
  }
}

export default useNavigation;
