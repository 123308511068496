exports = module.exports = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1DEMVQ9H{display:flex;align-items:center;overflow:hidden;flex-grow:1;justify-content:flex-end}._1DEMVQ9H:not(._PpB2yugB){margin-left:12px}._1DEMVQ9H._PpB2yugB{flex-direction:row-reverse;margin-right:12px}._1DEMVQ9H ._2Lm6VKkH{overflow:hidden}._1DEMVQ9H ._2Lm6VKkH ._2mOjQzHX{margin:0 6px}._1DEMVQ9H ._V4wl1C1g{opacity:0}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/events-ui/containers/Event/views/Desktop/components/FootballEvent/components/EventTeam/styles.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,kBAAmB,CACnB,eAAgB,CAChB,WAAY,CACZ,wBAAyB,CAN7B,2BASM,gBAAiB,CATvB,qBAcM,0BAA2B,CAC3B,iBAAkB,CAfxB,sBAmBM,eAAgB,CAnBtB,iCAsBQ,YAAa,CAtBrB,sBA2BM,SAAU","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .event-team {\n    display: flex;\n    align-items: center;\n    overflow: hidden;\n    flex-grow: 1;\n    justify-content: flex-end;\n\n    &:not(.reversed) {\n      margin-left: 12px;\n\n    }\n\n    &.reversed {\n      flex-direction: row-reverse;\n      margin-right: 12px;\n    }\n\n    .team-label-wrapper {\n      overflow: hidden;\n\n      .team-label {\n        margin: 0 6px;\n      }\n    }\n\n    .transparent {\n      opacity: 0;\n    }\n  }\n}"]}]);

// Exports
exports.locals = {
	"event-team": "_1DEMVQ9H",
	"eventTeam": "_1DEMVQ9H",
	"reversed": "_PpB2yugB",
	"team-label-wrapper": "_2Lm6VKkH",
	"teamLabelWrapper": "_2Lm6VKkH",
	"team-label": "_2mOjQzHX",
	"teamLabel": "_2mOjQzHX",
	"transparent": "_V4wl1C1g"
};