exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2xHDOGU6{height:100%}._2po4igdv{padding:16px}._2po4igdv ._1zIy7sot:last-of-type>*{border-color:transparent}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/event-commentary-ui/containers/EventCommentary/views/Desktop/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,WAAY,CAFhB,WAMI,YCRU,CDEd,qCAUQ,wBAAyB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .loader {\n    height: 100%;\n  }\n\n  .event-commentary {\n    padding: $padding;\n\n    .comment:last-of-type {\n      > * {\n        border-color: transparent;\n      }\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"loader": "_2xHDOGU6",
	"event-commentary": "_2po4igdv",
	"eventCommentary": "_2po4igdv",
	"comment": "_1zIy7sot"
};