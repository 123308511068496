import React from 'react';

import { ICONS } from './constants';
import { IconType } from './types';

const Icon = ({ id = IconType.NotFound, ...props }) => {
  const SvgIcon = ICONS[id];

  return <SvgIcon {...props} />;
};

export default Icon;
