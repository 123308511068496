import { createAction } from 'redux-actions';

import { getNamespacedActionCreators } from 'packages/socket';

import { NAMESPACE } from '../constants';

export const eventSocketActions = getNamespacedActionCreators(NAMESPACE);

export const updateLiveEvents = createAction(`${NAMESPACE}UPDATE_LIVE_EVENTS_SUCCESS`);

export const updatePaginationSuccess = createAction(`${NAMESPACE}UPDATE_PAGINATION_SUCCESS`);

export const clearSuccess = createAction(`${NAMESPACE}CLEAR_SUCCESS`);
