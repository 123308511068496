import { handleActions, ReduxCompatibleReducer } from 'redux-actions';

import { SocketActionCreators, State } from '../types';

export const getSpecifiedReducer = (
  actionCreators: SocketActionCreators,
): ReduxCompatibleReducer<State, State> => {
  const defaultState = {
    version: null,
    status: '',
    data: null,
  };

  const reducerMap = {
    [`${actionCreators.update}`]: (state, { payload }) => {
      return { ...defaultState, ...state, ...payload };
    },
    [`${actionCreators.open}`]: (state, { payload }) => {
      const { status } = payload;

      return { ...defaultState, status };
    },
    [`${actionCreators.close}`]: (state, { payload }) => {
      const { status } = payload;

      return { ...defaultState, status };
    },
    [`${actionCreators.failure}`]: (state, { payload }) => {
      const { error } = payload;

      return { ...state, error };
    },
  };

  return handleActions(reducerMap, defaultState);
};
