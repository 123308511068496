import { SortableContainer, SortableContainerProps } from 'react-sortable-hoc';
import React, { Fragment, memo } from 'react';

import { Separator } from 'packages/shared/components';
import { SortableItem } from 'packages/favorites-ui/types';
import { ComponentLike } from 'types';

export interface DraggableContainerProps extends SortableContainerProps {
  draggableItems: SortableItem[];
  itemComponent: ComponentLike;
  onDelete: (item: SortableItem) => void;
  onClick: (item: SortableItem) => void;
  useControlsHoverEffect?: boolean;
}

const DraggableContainer = SortableContainer(
  ({
    draggableItems,
    itemComponent: ItemComponent,
    onClick,
    onDelete,
    useControlsHoverEffect,
  }: DraggableContainerProps) => {
    return (
      <ul>
        {draggableItems.map((item, index) => (
          <Fragment key={item.id}>
            <ItemComponent
              useHoverEffect={useControlsHoverEffect}
              item={item}
              index={index}
              onClick={onClick}
              onDelete={onDelete}
            />
            <Separator />
          </Fragment>
        ))}
      </ul>
    );
  },
);

export default memo(DraggableContainer);
