import { ArrayUtils } from 'packages/utils';

import DifferentiatedUpdatesSelector from './DifferentiatedUpdatesSelector';
import { MapDifferenceExtractor } from '../differenceExtractors';
import { DataReducerConfig } from '../../types';

class DifferentiatedMapUpdatesSelector<
  ItemKey extends string,
  ItemValue
> extends DifferentiatedUpdatesSelector<ItemValue> {
  protected data: Map<ItemKey, ItemValue>;
  protected differenceExtractor: MapDifferenceExtractor<ItemKey, ItemValue>;

  constructor(config?: DataReducerConfig<ItemValue>) {
    super(config);
    this.differenceExtractor = new MapDifferenceExtractor(config?.differenceExtractorConfig);
    this.data = new Map();
  }

  prepareDataForUpdateSelection(data) {
    return this.createItemMap(data, 'id');
  }

  protected createItemMap(data, mapKey) {
    return ArrayUtils.createMap(data, mapKey);
  }

  protected handleNewData(data: Map<ItemKey, ItemValue>) {
    !!data.size && this.config?.handleNewData?.(this.buildMessageBody(data));
  }

  protected handleUpdatedData(data: Map<ItemKey, ItemValue>) {
    !!data.size && this.config?.handleUpdatedData?.(this.buildMessageBody(data));
  }

  protected handleRemovedData(data: Map<ItemKey, ItemValue>) {
    !!data.size && this.config?.handleRemovedData?.(this.buildMessageBody(data));
  }

  protected buildMessageBody(data: Map<ItemKey, ItemValue>) {
    return {
      data: [...data.values()],
      version: this.version,
    };
  }
}

export default DifferentiatedMapUpdatesSelector;
