import { handleActions, ReducerMap } from 'redux-actions';

import { Event } from 'packages/events/types';

import { allEventsActions } from '../../actions';

const defaultState = [];

const reducerMap: ReducerMap<Event[], any> = {
  [`${allEventsActions.cleanAllEventsSuccess}`]: () => {
    return defaultState;
  },
  [`${allEventsActions.loadAllEventsSuccess}`]: (state, { payload }) => {
    const { events } = payload;

    return events;
  },
  [`${allEventsActions.loadMoreAllEventsSuccess}`]: (state, { payload }) => {
    const { events } = payload;

    return [...state, ...events];
  },
  [`${allEventsActions.updateAllEventsSuccess}`]: (state, { payload }) => {
    return payload;
  },
};

export default handleActions(reducerMap, defaultState);
