import { headToHeadActions } from '../../actions';

const cleanHeadToHead = () => async (dispatch) => {
  try {
    dispatch(headToHeadActions.cleanHeadToHeadSuccess());
  } catch (e) {
    dispatch(headToHeadActions.cleanHeadToHeadFailure(e));
  }
};

export default cleanHeadToHead;
