import React, { memo, useMemo } from 'react';
import i18n from 'i18n-smart';

import { GoogleMap, Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  mapCoordinates?: google.maps.LatLngLiteral;
  isLoading?: boolean;
};

const StadiumLocation = ({ mapCoordinates, isLoading = false }: Props) => {
  const markers = useMemo(() => {
    return mapCoordinates ? [mapCoordinates] : [];
  }, []);

  return (
    <div className={styles.stadiumLocation}>
      {isLoading ? (
        <>
          <div className={styles.title}>
            <Skeleton height={16} width={100} variant="rectangular" />
          </div>
          <Skeleton width="100%" className={styles.map} variant="rectangular" />
        </>
      ) : (
        <>
          <div className={styles.title}>{i18n.value('stadium.location.title')}</div>
          <GoogleMap markers={markers} className={styles.map} />
        </>
      )}
    </div>
  );
};

export default memo(StadiumLocation);
