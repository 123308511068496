import instance from '../../../axios';
import { SeasonTopPlayersParams } from '../types';
import { stringifyOrderingParam } from '../../../services';

export const loadPlayers = ({
  seasonId,
  preferredFoot,
  nationalities,
  positions,
  teams,
  limit,
  offset,
  parameterRanges = {},
  ordering = {},
}: SeasonTopPlayersParams) => {
  const stringifiedOrdering = stringifyOrderingParam(ordering);

  return instance({
    method: 'get',
    url: `/seasons/${seasonId}/top_players/`,
    params: {
      limit,
      offset,
      preferred_foot: preferredFoot,
      ordering: stringifiedOrdering,
      team: teams?.join(','),
      position: positions?.join(','),
      country: nationalities?.join(','),
      ...parameterRanges,
    },
  });
};
