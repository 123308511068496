import i18n from 'i18n-smart';

import { StatisticsGroup } from 'packages/event-standings/types';
import { TeamFormTable } from 'packages/shared/modules';
import { StageTab } from 'packages/season-standings-ui/types';

const getFilterTabs = (): StageTab[] => {
  return [
    {
      id: 'standings-overall-filter',
      name: i18n.value('season.seasonStandings.overallTitle'),
      overriddenProps: {
        getProps: (props) => {
          return {
            ...props,
            statisticsGroup: StatisticsGroup.Overall,
          };
        },
      },
    },
    {
      id: 'standings-home-filter',
      name: i18n.value('season.seasonStandings.homeTitle'),
      overriddenProps: {
        getProps: (props) => {
          return {
            ...props,
            statisticsGroup: StatisticsGroup.Home,
          };
        },
      },
    },
    {
      id: 'standings-away-filter',
      name: i18n.value('season.seasonStandings.awayTitle'),
      overriddenProps: {
        getProps: (props) => {
          return {
            ...props,
            statisticsGroup: StatisticsGroup.Away,
          };
        },
      },
    },
    {
      id: 'season.seasonStandings.form',
      name: i18n.value('season.seasonStandings.form'),
      overriddenProps: {
        contentComponent: TeamFormTable,
        getProps: (props) => {
          return {
            ...props,
            statisticsGroup: StatisticsGroup.Overall,
          };
        },
      },
    },
  ] as any[];
};

export default getFilterTabs;
