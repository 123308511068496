import React from 'react';
import { SortableHandle } from 'react-sortable-hoc';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from './styles.scss';

type Props = {
  useHoverEffect?: boolean;
};

const DraggableHandler = SortableHandle(({ useHoverEffect = true }: Props) => (
  <span onClick={(e) => e.stopPropagation()}>
    <Icon
      id={IconType.Drag}
      className={classnames(styles.dnd, { [styles.hoverable]: useHoverEffect })}
    />
  </span>
));

export default DraggableHandler;
