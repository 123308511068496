import React, { memo } from 'react';
import classnames from 'classnames';

import { GoalHappening, PlayerType } from 'packages/event-stats/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { Icon } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import styles from './styles.scss';

type Props = {
  happening: GoalHappening;
  isAway?: boolean;
};

const HappeningLine = ({ happening, isAway = false }: Props) => {
  const { players, minute, additionalInfo } = happening;
  const { isOwnGoal, isPenaltyGoal, isPenaltyMissed } = additionalInfo;

  const goalIcon =
    (isOwnGoal && IconType.OwnGoal) ||
    (isPenaltyGoal && IconType.PenaltyGoal) ||
    (isPenaltyMissed && IconType.PenaltyMissed) ||
    IconType.Goal;

  const scorerPlayerType = isPenaltyMissed ? null : PlayerType.Scorer;

  const scorer = players.find(({ playerType }) => playerType === scorerPlayerType);
  const assists = players.find(({ playerType }) => playerType === PlayerType.Assist);
  const scorerNameView = scorer?.shortName || scorer?.name;
  const assistsNameView = assists?.shortName || assists?.name;

  const { appNavigate } = useNavigation();

  const handlePlayerClick = (playerId?: number) => () => {
    if (playerId) {
      appNavigate(DesktopPage.FootballPlayer, { playerId });
    }
  };

  return (
    <>
      <span className={classnames(styles.minute, { [styles.away]: isAway })}>
        {minute ? `${minute}'` : ''}
      </span>
      <Icon id={goalIcon} className={classnames(styles.icon, { [styles.away]: isAway })} />
      <div className={styles.scorersContainer}>
        {scorerNameView && (
          <div
            className={classnames(styles.playerName, {
              [styles.extended]: !assistsNameView,
            })}
            onClick={handlePlayerClick(scorer?.playerId)}
          >
            <span>{scorerNameView}</span>
          </div>
        )}

        {assists?.name && (
          <div className={styles.assists}>
            <span>(</span>
            <div
              onClick={handlePlayerClick(assists.playerId)}
              className={classnames(styles.nameContainer, {
                [styles.extended]: !scorerNameView,
              })}
            >
              {assistsNameView}
            </div>
            <span>)</span>
          </div>
        )}
      </div>
    </>
  );
};

export default memo(HappeningLine);
