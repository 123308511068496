import settings from 'settings';
import DateService from 'packages/date-az';
import { settingsActions } from 'packages/settings';
import { configThunks, configSelectors, configActions } from 'packages/config';
import { favoritesThunks } from 'packages/favorites';
import { services as restServices } from 'packages/rest';
import { teamActiveTournamentSeasonThunks } from 'packages/team-active-tournament-season';

import * as actions from '../actions';
import { setMediaInfo } from './setMediaInfo';
import { handleAppInitFailure } from './failure';

export const initApp = () => async (dispatch, getState) => {
  dispatch(actions.appInitStart());

  try {
    dispatch(configActions.configSetSettings({ settings }));

    const state = getState();
    const activeDate = DateService.getDate().toISODate();
    const defaultSport = configSelectors.getDefaultSport(state);

    dispatch(setMediaInfo());
    await dispatch(configThunks.configureTimeZone());
    dispatch(configThunks.configureLanguage());
    dispatch(teamActiveTournamentSeasonThunks.loadActiveTournamentSeasonsFromSessionStorage());
    dispatch(settingsActions.setActiveSport(defaultSport));
    dispatch(settingsActions.setActiveDate({ activeDate }));
    await dispatch(favoritesThunks.loadLatestFavoritesFromStorage());
    restServices.appendTimeZone(getState);

    dispatch(actions.appInitSuccess({ isInitialized: true }));

    return {};
  } catch (error) {
    dispatch(actions.appInitFailure(error));
    handleAppInitFailure(error);

    return { error };
  }
};
