import React, { useCallback, useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ActionCreatorsMapObject } from 'redux';
import isEmpty from 'is-empty-typed';

import { DEFAULT_EVENTS_LIMIT } from 'packages/team-events/constants';
import { getSeasonsRange } from 'packages/team-events-ui/containers/TeamEvents/services';
import { PaginationParams } from 'packages/shared/components/Pagination/types';
import { YearItem } from 'packages/shared/components/Calendar/types';
import { TournamentView } from 'packages/events/types';
import { SearchQuery } from 'router/types';
import {
  ActiveFiltersParams,
  EventActiveFilters,
  Season,
  Tournament,
} from 'packages/team-events/types';

import connect from './connect';
import TeamEventsView from './views';
import styles from './styles.scss';

type Props = {
  filterActions: ActionCreatorsMapObject;
  eventActions: ActionCreatorsMapObject;
  commonActions: ActionCreatorsMapObject;
  tournamentFilters: Tournament[];
  activeFilters: EventActiveFilters;
  eventsCount: number;
  events: TournamentView[];
  isFiltersLoading: boolean;
  isEventsLoading: boolean;
  isMoreEventsLoading: boolean;
  useModalOnCategoryClick?: boolean;
  limit: number;
  filterDefaultPeriod: [string, string];
};

const extendActualEvents = (TeamEvents: typeof TeamEventsView[keyof typeof TeamEventsView]) => {
  return ({
    isEventsLoading,
    filterActions,
    eventActions,
    commonActions,
    tournamentFilters,
    events,
    isFiltersLoading,
    isMoreEventsLoading,
    activeFilters,
    eventsCount,
    limit,
    filterDefaultPeriod,
    useModalOnCategoryClick = false,
  }: Props) => {
    const { teamId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      filterActions.loadFilters(teamId);
      eventActions.loadTeamEventsPage(teamId);

      return () => {
        commonActions.clearTeamEvents();
      };
    }, [teamId]);

    const handleFilterUpdate = useCallback(
      (period: [string, string], selectedSeasons: Season[], tournament?: Tournament) => {
        commonActions.updateActiveFilter(
          {
            period,
            tournament,
            selectedSeasons,
            tournamentSeasons: tournament?.seasons || [],
          },
          teamId,
        );
      },
      [teamId],
    );

    const handleTournamentChange = useCallback(
      (tournament?: Tournament) => {
        commonActions.updateActiveFilter(
          {
            tournament,
            selectedSeasons: [],
            tournamentSeasons: tournament?.seasons || [],
          },
          teamId,
        );
      },
      [teamId],
    );

    const handleSeasonChange = useCallback(
      (selectedSeasons: Season[]) => {
        const filters: ActiveFiltersParams = { selectedSeasons };

        if (!isEmpty(selectedSeasons)) {
          filters.period = getSeasonsRange(selectedSeasons);
        } else {
          filters.period = filterDefaultPeriod;
        }

        commonActions.updateActiveFilter(filters, teamId);
      },
      [filterDefaultPeriod, teamId],
    );

    const handlePeriodChange = useCallback(
      (period: [string, string]) => {
        const { selectedSeasons } = activeFilters;
        const filters: ActiveFiltersParams = { period };

        if (!isEmpty(selectedSeasons)) {
          filters.selectedSeasons = [];
        }

        commonActions.updateActiveFilter(filters, teamId);
      },
      [activeFilters, teamId],
    );

    const handlePaginationChange = useCallback(
      (params: PaginationParams) => {
        commonActions.updatePagination(params);
        eventActions.loadFilteredEvents(teamId);
      },
      [teamId],
    );

    const handleYearClick = useCallback(
      (year: YearItem) => {
        handlePeriodChange([year.startDate.toISODate(), year.endDate.toISODate()]);
      },
      [handlePeriodChange],
    );

    const handleEventClick = useCallback(
      (eventId: number) => {
        setSearchParams({ [SearchQuery.EventCardId]: eventId.toString() });
      },
      [searchParams],
    );

    const isLimitSelectable = eventsCount > DEFAULT_EVENTS_LIMIT;
    const isInitialLoading = isFiltersLoading || isEventsLoading;

    return (
      <div className={styles.teamEvents}>
        <TeamEvents
          useModalOnCategoryClick={useModalOnCategoryClick}
          events={events}
          eventsCount={eventsCount}
          onEventClick={handleEventClick}
          onYearClick={handleYearClick}
          onTournamentChange={handleTournamentChange}
          onSeasonChange={handleSeasonChange}
          onPeriodChange={handlePeriodChange}
          onPaginationChange={handlePaginationChange}
          tournamentFilters={tournamentFilters}
          onFilterUpdate={handleFilterUpdate}
          isLimitSelectable={isLimitSelectable}
          limit={limit}
          isMoreEventsLoading={isMoreEventsLoading}
          activeFilters={activeFilters}
          isInitialLoading={isInitialLoading}
        />
      </div>
    );
  };
};

export default {
  Desktop: connect(extendActualEvents(TeamEventsView.Desktop)),
  DesktopNarrow: connect(extendActualEvents(TeamEventsView.DesktopNarrow)),
  Tablet: connect(extendActualEvents(TeamEventsView.Tablet)),
  TabletNarrow: connect(extendActualEvents(TeamEventsView.TabletNarrow)),
  Mobile: connect(extendActualEvents(TeamEventsView.Mobile)),
};
