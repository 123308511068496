import React, { memo, useMemo } from 'react';

import { Skeleton } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';

import styles from './styles.scss';

const ELEMENTS_COUNT = 6;

const BlockSkeleton = () => {
  const elements = useMemo(() => ArrayUtils.getArrayFromNumber(ELEMENTS_COUNT), []);

  return (
    <div className={styles.wrapper}>
      {elements.map((element) => (
        <div className={styles.line} key={element}>
          <Skeleton height={14} width={80} variant="rectangular" />
          <Skeleton height={14} width={30} variant="rectangular" />
        </div>
      ))}
    </div>
  );
};

export default memo(BlockSkeleton);
