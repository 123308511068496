import { ReactComponent as Uk } from '../assets/uk.svg';
import { ReactComponent as Ru } from '../assets/ru.svg';
import { ReactComponent as Leagues } from '../assets/leagues.svg';
import { ReactComponent as StarOutline } from '../assets/star-outline.svg';
import { ReactComponent as Star } from '../assets/star.svg';
import { ReactComponent as Events } from '../assets/events.svg';
import { ReactComponent as SearchLogo } from '../assets/search-logo.svg';
import { ReactComponent as NotFound } from '../assets/not-found.svg';
import { ReactComponent as Cross } from '../assets/cross.svg';
import { ReactComponent as CrossDesktop } from '../assets/cross-desktop.svg';
import { ReactComponent as OlimpLogo } from '../assets/olimp-logo.svg';
import { ReactComponent as Arrow } from '../assets/arrow.svg';
import { ReactComponent as ArrowExtended } from '../assets/arrow-extended.svg';
import { ReactComponent as ArrowDropdown } from '../assets/arrow-dropdown.svg';
import { ReactComponent as Burger } from '../assets/burger.svg';
import { ReactComponent as Drag } from '../assets/drag.svg';
import { ReactComponent as OwnGoal } from '../assets/own-goal.svg';
import { ReactComponent as Injury } from '../assets/injury.svg';
import { ReactComponent as InjuryTime } from '../assets/injury-time.svg';
import { ReactComponent as SubstitutionLight } from '../assets/substitution-light.svg';
import { ReactComponent as RedCard } from '../assets/red-card.svg';
import { ReactComponent as YellowRedCard } from '../assets/yellow-red-card.svg';
import { ReactComponent as YellowCard } from '../assets/yellow-card.svg';
import { ReactComponent as Goal } from '../assets/goal.svg';
import { ReactComponent as MatchShotsGoal } from '../assets/match-shots-goal.svg';
import { ReactComponent as EventLink } from '../assets/event-link.svg';
import { ReactComponent as Settings } from '../assets/settings.svg';
import { ReactComponent as Video } from '../assets/video.svg';
import { ReactComponent as ComingSoon } from '../assets/coming-soon.svg';
import { ReactComponent as PeriodStart } from '../assets/periodstart.svg';
import { ReactComponent as Miss } from '../assets/miss.svg';
import { ReactComponent as Calendar } from '../assets/calendar.svg';
import { ReactComponent as NotKicked } from '../assets/not-kicked.svg';
import { ReactComponent as Corner } from '../assets/corner.svg';
import { ReactComponent as Offside } from '../assets/offside.svg';
import { ReactComponent as Shot } from '../assets/shot.svg';
import { ReactComponent as PenaltyMissed } from '../assets/penalty-missed.svg';
import { ReactComponent as PenaltyGoal } from '../assets/penalty-goal.svg';
import { ReactComponent as PenaltyRewarded } from '../assets/penalty-rewarded.svg';
import { ReactComponent as Suspended } from '../assets/suspended.svg';
import { ReactComponent as Cancelled } from '../assets/cancelled.svg';
import { ReactComponent as Postponed } from '../assets/postponed.svg';
import { ReactComponent as Clock } from '../assets/clock.svg';
import { ReactComponent as FootballField } from '../assets/football-field.svg';
import { ReactComponent as FootballFieldHalf } from '../assets/football-field-half.svg';
import { ReactComponent as DefaultLeague } from '../assets/default-league.svg';
import { ReactComponent as CheckMark } from '../assets/check-mark.svg';
import { ReactComponent as Configuration } from '../assets/configuration.svg';
import { ReactComponent as FacebookIconLink } from '../assets/facebook-icon-link.svg';
import { ReactComponent as TwitterIconLink } from '../assets/twitter-icon-link.svg';
import { ReactComponent as YoutubeIconLink } from '../assets/youtube-icon-link.svg';
import { ReactComponent as Jersey } from '../assets/jersey.svg';
import { ReactComponent as SubstitutionIn } from '../assets/substitution_in.svg';
import { ReactComponent as SubstitutionOut } from '../assets/substitution_out.svg';
import { ReactComponent as Assist } from '../assets/assist.svg';
import { ReactComponent as Change } from '../assets/change.svg';
import { ReactComponent as Stadium } from '../assets/stadium.svg';
import { ReactComponent as RoundedCross } from '../assets/rounded-cross.svg';
import { ReactComponent as NavEvents } from '../assets/navigation-events.svg';
import { ReactComponent as NavLeagues } from '../assets/navigation-leagues.svg';
import { ReactComponent as NavFavorites } from '../assets/navigation-favorites.svg';
import { ReactComponent as Bench } from '../assets/bench.svg';
import { ReactComponent as Facts } from '../assets/facts.svg';
import { IconType } from '../types';

export const ICONS = {
  [IconType.En]: Uk,
  [IconType.Ru]: Ru,
  [IconType.Events]: Events,
  [IconType.Leagues]: Leagues,
  [IconType.DefaultLeague]: DefaultLeague,
  [IconType.Star]: Star,
  [IconType.StarOutline]: StarOutline,
  [IconType.SearchLogo]: SearchLogo,
  [IconType.Cross]: Cross,
  [IconType.Clock]: Clock,
  [IconType.CrossDesktop]: CrossDesktop,
  [IconType.OlimpLogo]: OlimpLogo,
  [IconType.Arrow]: Arrow,
  [IconType.ArrowExtended]: ArrowExtended,
  [IconType.ArrowDropdown]: ArrowDropdown,
  [IconType.Burger]: Burger,
  [IconType.Drag]: Drag,
  [IconType.Calendar]: Calendar,

  [IconType.Suspended]: Suspended,
  [IconType.Postponed]: Postponed,
  [IconType.Cancelled]: Cancelled,

  [IconType.FootballField]: FootballField,
  [IconType.FootballFieldHalf]: FootballFieldHalf,

  [IconType.Miss]: Miss,
  [IconType.SubstitutionLight]: SubstitutionLight,
  [IconType.RedCard]: RedCard,
  [IconType.YellowCard]: YellowCard,
  [IconType.YellowRedCard]: YellowRedCard,
  [IconType.Goal]: Goal,
  [IconType.MatchShotsGoal]: MatchShotsGoal,
  [IconType.OwnGoal]: OwnGoal,
  [IconType.PeriodStart]: PeriodStart,
  [IconType.NotKicked]: NotKicked,
  [IconType.Injury]: Injury,
  [IconType.InjuryTime]: InjuryTime,
  [IconType.NotFound]: NotFound,
  [IconType.Settings]: Settings,
  [IconType.EventLink]: EventLink,
  [IconType.Video]: Video,
  [IconType.ComingSoon]: ComingSoon,
  [IconType.Corner]: Corner,
  [IconType.Offside]: Offside,
  [IconType.Shot]: Shot,
  [IconType.PenaltyMissed]: PenaltyMissed,
  [IconType.PenaltyRewarded]: PenaltyRewarded,
  [IconType.PenaltyGoal]: PenaltyGoal,
  [IconType.CheckMark]: CheckMark,
  [IconType.Configuration]: Configuration,
  [IconType.Jersey]: Jersey,
  [IconType.Stadium]: Stadium,

  [IconType.FacebookIconLink]: FacebookIconLink,
  [IconType.TwitterIconLink]: TwitterIconLink,
  [IconType.YoutubeIconLink]: YoutubeIconLink,

  [IconType.SubstitutionIn]: SubstitutionIn,
  [IconType.SubstitutionOut]: SubstitutionOut,
  [IconType.Assist]: Assist,
  [IconType.Change]: Change,

  [IconType.RoundedCross]: RoundedCross,

  [IconType.NavigationFavorites]: NavFavorites,
  [IconType.NavigationEvents]: NavEvents,
  [IconType.NavigationLeagues]: NavLeagues,

  [IconType.Bench]: Bench,

  [IconType.Facts]: Facts,
};
