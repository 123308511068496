import { sessionStorage } from 'packages/storage';

import {
  COLUMN_CONFIGURATION_FILTER_EMPTY_STATE,
  CUSTOM_PRESET_SESSION_KEY,
  CUSTOMIZATION_PRESET_BY_TYPE,
} from '../../constants';
import { PresetType } from '../../types';
import { customFilterStateActions } from '../../actions';

const updateFilterPreset = (preset: PresetType) => (dispatch) => {
  const columnConfigurationFilter =
    preset === PresetType.Custom
      ? sessionStorage.get(CUSTOM_PRESET_SESSION_KEY) || COLUMN_CONFIGURATION_FILTER_EMPTY_STATE
      : CUSTOMIZATION_PRESET_BY_TYPE[preset].overrides;

  dispatch(customFilterStateActions.customFilterUpdate({ columnConfigurationFilter }));
};

export default updateFilterPreset;
