import React from 'react';

import { HappeningTimeLine } from 'packages/shared/components';
import { Theme } from 'packages/shared/components/HappeningTimeLine/types';
import { PlayerHappening } from 'packages/event-time-line/types';

import styles from './styles.scss';

type Props = {
  happenings: PlayerHappening[];
};

const HappeningsTimelinePopover = ({ happenings }: Props) => {
  return (
    <HappeningTimeLine
      happenings={happenings}
      theme={Theme.Dark}
      className={styles.popover}
      useDefaultHappeningComponent
      hasSinglePosition
    />
  );
};

export default React.memo(HappeningsTimelinePopover);
