import React, { memo, RefObject } from 'react';

import { Event } from 'types';
import { Pagination } from 'packages/shared/components';
import { PaginationParams } from 'packages/shared/components/Pagination/types';
import {
  Filter,
  FilterValue,
  SeasonEventsFilter as SeasonEventsFiltersType,
} from 'packages/season-events/types';

import { EventList } from '../../../../components';
import { SeasonEventsFilters } from '../../components';
import { EventListSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  onFilterChange: (filterValue: FilterValue, filterType: Filter) => void;
  onPaginationChange: (params: PaginationParams) => void;
  onEventClick: (eventId: number) => void;
  onCustomRangeClick: () => void;
  seasonEventsFilter: SeasonEventsFiltersType;
  containerRef: RefObject<HTMLDivElement>;
  events: Event[];
  isInitialEventsLoading: boolean;
  isFiltersLoading: boolean;
  isMoreEventsLoading: boolean;
  limit: number;
  eventsCount: number;
};

const SeasonEvents = ({
  events,
  limit,
  seasonEventsFilter,
  eventsCount,
  isMoreEventsLoading,
  isInitialEventsLoading,
  onCustomRangeClick,
  onPaginationChange,
  onEventClick,
  onFilterChange,
  containerRef,
  isFiltersLoading,
}: Props) => {
  const isInitialLoading = isFiltersLoading || isInitialEventsLoading;

  return (
    <>
      {isInitialLoading ? (
        <EventListSkeleton elementsCount={limit} />
      ) : (
        <>
          <SeasonEventsFilters
            onCustomRangeClick={onCustomRangeClick}
            filter={seasonEventsFilter}
            onFilterChange={onFilterChange}
            useDatePickerBackdrop
            isTeamSelectCloseableOnResize={false}
            teamSelectItemsContainerClass={styles.teamSelectItemsContainer}
          />

          <div className={styles.listContainer}>
            <EventList.Mobile
              containerRef={containerRef}
              events={events}
              isLoading={isInitialEventsLoading || isMoreEventsLoading}
              onEventClick={onEventClick}
            />
          </div>
        </>
      )}

      <Pagination
        isLoading={isInitialLoading}
        useRightAlignment
        resultsLabel="common.pagination.gamesLabel"
        onPaginationChange={onPaginationChange}
        className={styles.paginationBlock}
        amountOfItems={eventsCount}
        pageSize={limit}
        isLimitSelectable
      />
    </>
  );
};

export default memo(SeasonEvents);
