import i18n from 'i18n-smart';

import { SelectItem } from 'packages/shared/components/Select/types';
import { Enums } from 'packages/team-player-statistics/types';

const getFootSelectItems = (): SelectItem[] => [
  {
    id: Enums.PreferredFoot.All,
    label: i18n.value('team.playerStatistics.customFilter.general.footSelectItem.all'),
  },
  {
    id: Enums.PreferredFoot.Both,
    label: i18n.value('team.playerStatistics.customFilter.general.footSelectItem.both'),
  },
  {
    id: Enums.PreferredFoot.Left,
    label: i18n.value('team.playerStatistics.customFilter.general.footSelectItem.left'),
  },
  {
    id: Enums.PreferredFoot.Right,
    label: i18n.value('team.playerStatistics.customFilter.general.footSelectItem.right'),
  },
];

export default getFootSelectItems;
