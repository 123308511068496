import getLastPortal from './getLastPortal';
import { PORTAL_PATTERN } from '../constants';

import styles from '../styles.scss';

interface Params {
  overlay: boolean;
}

const createPortalContainer = ({ overlay }: Params) => {
  const lastPortal = getLastPortal();
  let lastPortalOrderIndex = 100;

  if (lastPortal) {
    const [, lastPortalOrder] = lastPortal.id.split('_') || [];
    lastPortalOrderIndex = parseInt(lastPortalOrder) + 1;
  }

  const portal = document.createElement('div');
  portal.id = `${PORTAL_PATTERN}_${lastPortalOrderIndex}`;
  portal.style.zIndex = `${lastPortalOrderIndex}`;
  portal.classList.add(styles.portal);

  if (overlay) {
    portal.classList.add(styles.overlay);
  }

  document.body.appendChild(portal);

  return portal;
};

export default createPortalContainer;
