import { MediaScreen } from 'types';

import { SCREEN_WIDTH } from '../../constants';

export const getMediaScreen = (viewWidth) => {
  const {
    MAX_MOBILE_WIDTH,
    MAX_TABLET_NARROW_WIDTH,
    MAX_TABLET_WIDTH,
    MAX_DESKTOP_NARROW_WIDTH,
  } = SCREEN_WIDTH;

  if (viewWidth < MAX_MOBILE_WIDTH) return MediaScreen.Mobile;
  if (viewWidth < MAX_TABLET_NARROW_WIDTH) return MediaScreen.TabletNarrow;
  if (viewWidth < MAX_TABLET_WIDTH) return MediaScreen.Tablet;
  if (viewWidth < MAX_DESKTOP_NARROW_WIDTH) return MediaScreen.DesktopNarrow;

  return MediaScreen.Desktop;
};
