import { SearchParams } from '../types';

const validateParams = (params: SearchParams) => {
  return Object.entries(params).reduce<Record<string, string>>((accumulator, [key, value]) => {
    if (value || typeof value === 'number') {
      accumulator[key] = String(value);
    }

    return accumulator;
  }, {});
};

export default validateParams;
