import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Skeleton height={16} width={130} variant="rectangular" />
        <Skeleton height={16} width={90} variant="rectangular" />
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
