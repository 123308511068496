import { Stage } from 'packages/event-standings/types';

import { MobileFilterStages } from '../../types';
import getPath from './getPath';

export const getData = (state): MobileFilterStages.Data => {
  return getPath(state).data;
};

export const getStages = (state): Stage[] | null => {
  return getData(state).stages;
};

export const getDefaultStageTabId = (state): number | string | undefined => {
  return getData(state).defaultStageTabId;
};

export const getDefaultFilterTabId = (state): number | string | undefined => {
  return getData(state).defaultFilterTabId;
};
