import getState from './getState';
import { Data, Stage, State } from '../types';

export const getData = (state: State): Data => {
  return getState(state).data;
};

export const getStages = (state: State): Stage[] => {
  return getData(state).stages;
};

export const getActiveStageId = (state: State): number | undefined => {
  return getData(state).activeStageId;
};
