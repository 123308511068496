import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const PlayerCellSkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton width={32} height={32} marginRight={8} variant="circular" />
      <div className={styles.details}>
        <Skeleton width={120} height={14} marginRight={8} variant="rectangular" />
        <div className={styles.additionalInfo}>
          <Skeleton width={16} height={14} marginRight={8} variant="rectangular" />
          <Skeleton width={60} height={14} variant="rectangular" />
        </div>
      </div>
    </div>
  );
};

export default memo(PlayerCellSkeleton);
