import React from 'react';

import { FlexiblePopover, Icon } from 'packages/shared/components';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { SettingsPopup } from 'packages/popups-ui';

import styles from './styles.scss';

const Settings = () => {
  return (
    <div className={styles.settings}>
      <FlexiblePopover
        popoverComponent={SettingsPopup}
        justify={PopoverJustify.CenterEnd}
        align={PopoverAlign.CenterStart}
        closeOnClickOutside
      >
        <div>
          <Icon id={IconType.Settings} className={styles.icon} />
        </div>
      </FlexiblePopover>
    </div>
  );
};

export default Settings;
