import { EventActiveFilters } from 'packages/player-events/types';

import { commonActions } from '../../actions';
import { filterSelectors } from '../../selectors';

export const updateActiveFilter = (newFilters: Partial<EventActiveFilters>) => async (
  dispatch,
  getState,
) => {
  const activeFilter = filterSelectors.getActiveFilter(getState());
  const updatedFilter = { ...activeFilter, ...newFilters };

  dispatch(commonActions.updateActiveFilterSuccess({ activeFilters: updatedFilter }));
};

export default updateActiveFilter;
