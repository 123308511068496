import instance from '../../../axios';

import { TeamFiltersParams } from '../types';

export const loadTeamFilters = ({ teamId }: TeamFiltersParams) => {
  return instance({
    method: 'get',
    url: `/teams/${teamId}/unique_tournaments/`,
  });
};
