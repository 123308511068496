exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1DBxghDW{display:flex;align-items:center;overflow:hidden}._1DBxghDW ._w7YCqVhv{border-radius:50%;margin-right:8px;min-width:16px;max-width:16px;min-height:16px;max-height:16px}._1DBxghDW ._Q6QKcmFq{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Select/components/TournamentLabel/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/size.mixin.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/ellipsis.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,kBAAmB,CACnB,eAAgB,CAJpB,sBAOM,iBAAkB,CAClB,gBAAiB,CCTrB,cDW4B,CCV5B,cDU4B,CCP1B,eDO0B,CCN1B,eDM0B,CAV9B,sBEDE,eAAgB,CAChB,kBAFuC,CAGvC,sBAAuB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .tournament-label {\n    display: flex;\n    align-items: center;\n    overflow: hidden;\n\n    .logo {\n      border-radius: 50%;\n      margin-right: 8px;\n\n      @include fixed-size(16px);\n    }\n\n    .label {\n      @include text-ellipsis();\n    }\n  }\n}","@mixin fixed-size($width, $height: null) {\n  min-width: $width;\n  max-width: $width;\n\n  @if ($height == null) {\n    min-height: $width;\n    max-height: $width;\n  } @else {\n    min-height: $height;\n    max-height: $height;\n  }\n}","@mixin text-ellipsis($white-space: nowrap) {\n  overflow: hidden;\n  white-space: $white-space;\n  text-overflow: ellipsis;\n}\n"]}]);

// Exports
exports.locals = {
	"tournament-label": "_1DBxghDW",
	"tournamentLabel": "_1DBxghDW",
	"logo": "_w7YCqVhv",
	"label": "_Q6QKcmFq"
};