import React, { memo } from 'react';

import { SkeletonVariant } from './types';
import { BaseEvent, PlayerEvent } from './components';

type Props = {
  isCompact?: boolean;
  isTournamentVisible?: boolean;
  isMatchResultVisible?: boolean;
  variant?: keyof typeof SkeletonVariant;
};

const EventLoadingSkeleton = ({
  isCompact = false,
  isTournamentVisible = false,
  isMatchResultVisible = false,
  variant = 'base',
}: Props) => {
  switch (SkeletonVariant[variant]) {
    case SkeletonVariant.base:
      return (
        <BaseEvent
          isMatchResultVisible={isMatchResultVisible}
          isTournamentVisible={isTournamentVisible}
          isCompact={isCompact}
        />
      );
    case SkeletonVariant.player:
      return <PlayerEvent isCompact={isCompact} isTournamentVisible={isTournamentVisible} />;
    default:
      return (
        <BaseEvent
          isMatchResultVisible={isMatchResultVisible}
          isTournamentVisible={isTournamentVisible}
          isCompact={isCompact}
        />
      );
  }
};

export default memo(EventLoadingSkeleton);
