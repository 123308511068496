exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._12OT2nZs{display:flex;justify-content:space-between;padding:4px 16px 16px}._12OT2nZs ._1ZACQSsh>:first-child{margin-right:8px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Calendar/views/ConfirmationBlock/styles.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,6BAA8B,CAC9B,qBAA0B,CAJ9B,mCAOM,gBAAiB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .confirmation-block {\n    display: flex;\n    justify-content: space-between;\n    padding: 4px $padding 16px;\n\n    .right-buttons > :first-child {\n      margin-right: 8px;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"confirmation-block": "_12OT2nZs",
	"confirmationBlock": "_12OT2nZs",
	"right-buttons": "_1ZACQSsh",
	"rightButtons": "_1ZACQSsh"
};