import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const SkeletonHeader = () => (
  <div className={styles.wrapper}>
    <Skeleton height={14} minWidth={52} width={52} marginRight={12} variant="rectangular" />
    <Skeleton height={32} width={200} marginRight={12} variant="rectangular" />

    <Skeleton
      height={32}
      width={32}
      minWidth={32}
      maxWidth={32}
      marginLeft="auto"
      variant="rectangular"
    />
  </div>
);

export default memo(SkeletonHeader);
