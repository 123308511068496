import React, { useCallback, memo, useRef } from 'react';
import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed/lib';

import { DesktopPage } from 'router/types';
import {
  NavigationMenu,
  NoDataLabel,
  Select,
  SelectItemComponent,
  SelectLabelComponent,
} from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { Stage } from 'packages/event-standings/types';
import { SelectItem, SelectSize } from 'packages/shared/components/Select/types';
import { NarrowTeamStatisticsTable } from 'packages/shared/modules';
import { useNavigation } from 'packages/hooks';
import {
  SeasonsExtendedTournament,
  TournamentSeasonSelectItems,
} from 'packages/shared/components/TournamentSeasonSelect/types';
import {
  useActiveTabContentElement,
  useFilterTabs,
  useStageTabs,
} from 'packages/season-standings-ui/hooks';

import { getBracketProps, getFilterTabs, getTableProps } from './services';
import { getScrollToStageTable } from '../../services';
import { HeaderSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  tournaments?: SeasonsExtendedTournament[];
  activeTournamentId?: number;
  activeSeasonId?: number;
  stages: Stage[] | null;
  activeStageId?: number;
  tournamentSeasonSelectItems: TournamentSeasonSelectItems;
  teamId?: string;
  tournamentId?: string;
  isLoading?: boolean;
};

const Standings = ({
  stages,
  activeStageId,
  activeTournamentId,
  activeSeasonId,
  tournaments = [],
  tournamentSeasonSelectItems,
  teamId,
  tournamentId,
  isLoading = false,
}: Props) => {
  if (isLoading) {
    return (
      <div className={styles.tabsContainer}>
        <HeaderSkeleton />
        <div className={styles.content}>
          <NarrowTeamStatisticsTable.Desktop isLoading />
        </div>
      </div>
    );
  }

  const { appNavigate } = useNavigation();
  const headerBlockRef = useRef<HTMLDivElement>(null);

  const {
    tournamentItems,
    seasonItems,
    activeSeasonItem,
    activeTournamentItem,
  } = tournamentSeasonSelectItems;

  const StageTabs = useStageTabs(stages, {
    getTableProps,
    getBracketProps,
    defaultActiveTabId: activeStageId,
    additionalProps: {
      activeTeamIds: teamId ? [parseInt(teamId)] : undefined,
      activeUniqueTournamentId: activeTournamentId,
      teamIdToScroll: teamId ? parseInt(teamId) : undefined,
      activeSeasonId,
      scrollToTable: getScrollToStageTable(headerBlockRef),
    },
  });
  const FilterTabs = useFilterTabs(stages, { getFilterTabs, activeStageTab: StageTabs.activeTab });
  const activeContentElement = useActiveTabContentElement(
    StageTabs.activeTab,
    FilterTabs.activeTab,
  );

  const handleTournamentChange = useCallback(
    (tournamentItem: SelectItem) => {
      if (teamId) {
        const [firstSelectedTournamentSeason] =
          tournaments.find(({ id }) => id === tournamentItem.id)?.seasons || [];

        appNavigate(DesktopPage.FootballTeamStandings, {
          uniqueTournamentId: tournamentItem.id,
          seasonId: firstSelectedTournamentSeason.id,
          teamId,
        });
      }
    },
    [teamId],
  );

  const handleSeasonChange = useCallback(
    (seasonItem: SelectItem) => {
      if (teamId && tournamentId) {
        appNavigate(DesktopPage.FootballTeamStandings, {
          uniqueTournamentId: tournamentId,
          seasonId: seasonItem.id,
          teamId,
        });
      }
    },
    [teamId, tournamentId],
  );

  const selectedTournamentItem = activeTournamentItem || tournamentItems[0];

  return (
    <div className={styles.tabsContainer}>
      <div ref={headerBlockRef} className={styles.header}>
        <div className={styles.leftFilterGroup}>
          <div className={styles.title}>{i18n.value('team.standings.title')}</div>
          <Select
            items={tournamentItems}
            size={SelectSize.Lg}
            selectedItem={selectedTournamentItem}
            onChange={handleTournamentChange}
            labelComponentProps={selectedTournamentItem?.componentProps}
            labelComponent={SelectLabelComponent.Tournament}
            itemComponent={SelectItemComponent.Tournament}
          />
          <Select
            items={seasonItems}
            size={SelectSize.Sm}
            selectedItem={activeSeasonItem || seasonItems[0]}
            onChange={handleSeasonChange}
          />
          {!isEmpty(StageTabs.activeTab) && StageTabs.tabs.length > 1 && (
            <NavigationMenu
              items={StageTabs.tabs}
              activeId={StageTabs.activeTab?.id}
              onClick={StageTabs.setActiveTabById}
              theme={NavigationTheme.Dropdown}
              className={styles.stageTabs}
            />
          )}
        </div>
        <div className={styles.rightFilterGroup}>
          {!isEmpty(FilterTabs.activeTab) && !isEmpty(StageTabs.tabs) && (
            <NavigationMenu
              items={FilterTabs.tabs}
              activeId={FilterTabs.activeTab?.id}
              onClick={FilterTabs.setActiveTabById}
              isDisabled={FilterTabs.isTabsDisabled}
              className={styles.filterTabs}
              theme={NavigationTheme.Dropdown}
            />
          )}
        </div>
      </div>
      <div className={styles.content}>
        {!isEmpty(activeContentElement) ? activeContentElement : <NoDataLabel />}
      </div>
    </div>
  );
};

export default memo(Standings);
