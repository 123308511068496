import React from 'react';

import styles from './styles.scss';

const FeaturedGrid = () => {
  return (
    <div className={styles.grid}>
      <div className={styles.gridRow}>
        <div className={styles.gridItem}>1</div>
        <div className={styles.gridItem}>X</div>
        <div className={styles.gridItem}>2</div>
        <div className={styles.gridItem}>O(2.5)</div>
        <div className={styles.gridItem}>U(2.5)</div>
      </div>
    </div>
  );
};

export default FeaturedGrid;
