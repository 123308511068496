import { Season } from 'packages/team-events/types';
import sortSeasonsByDates from './sortSeasonsByDates';

const getSeasonsRange = (seasons: Season[]) => {
  const sortedSeasons = sortSeasonsByDates(seasons);

  return getRanges(sortedSeasons);
};

const getRanges = (seasons: Season[]): [string, string] => {
  const firstItem = seasons[0];
  const lastItem = seasons[seasons.length - 1];

  return [firstItem.startDate, lastItem.endDate];
};

export default getSeasonsRange;
