import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  seasonEventsSelectors,
  seasonEventsThunks,
  seasonFilterSelectors,
} from 'packages/season-events';
import { seasonDetailsSelectors } from 'packages/season-details';
import { settingsSelectors } from 'packages/settings';

const mapStateToProps = (state) => {
  return {
    selectedSeason: seasonDetailsSelectors.getSelectedSeason(state),
    isEventsLoading: seasonEventsSelectors.getIsInitialLoading(state),
    isFiltersLoading: seasonFilterSelectors.getIsLoading(state),
    events: seasonEventsSelectors.getData(state),
    timeZone: settingsSelectors.getTimeZone(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    seasonEventsActions: bindActionCreators(seasonEventsThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
