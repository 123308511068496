import { OrderingKey, RangeParameter } from 'packages/rest/api/SeasonPlayerStatistics/types';
import { InputValidationType } from 'packages/shared/components/Input/types';
import { OrderType } from 'types';

import { SelectItem } from 'packages/shared/components/Select/types';
import { Enums } from 'packages/team-player-statistics/types';
import {
  Column,
  ColumnConfigurationFilter,
  ColumnType,
  CustomFilterState,
  Ordering,
  PlayerPosition,
  PositionType,
  PresetType,
  PresetView,
  RangeType,
  StatType,
} from '../types';

export const NAMESPACE = 'SEASON-PLAYERS-STATISTICS::';
export const CUSTOM_PRESET_SESSION_KEY = 'season-player-statistics-custom-preset';
export const INITIAL_PLAYERS_LIMIT = 30;
export const DEFAULT_PLAYERS_LIMIT = 30;

export const COLUMN_TEMPLATE_BY_TYPE: Record<ColumnType, Column> = {
  [ColumnType.PlayerRank]: {
    type: ColumnType.PlayerRank,
    columnKey: ColumnType.PlayerRank,
    isDisabled: true,
  },
  [ColumnType.PlayerIcon]: {
    type: ColumnType.PlayerIcon,
    columnKey: ColumnType.PlayerIcon,
    isDisabled: true,
  },
  [ColumnType.Player]: {
    type: ColumnType.Player,
    columnKey: ColumnType.Player,
    isDisabled: true,
  },
  [ColumnType.Club]: {
    type: ColumnType.Club,
    columnKey: 'team',
    isDisabled: true,
  },
  [ColumnType.Goals]: {
    type: ColumnType.Goals,
    columnKey: 'goals',
  },
  [ColumnType.GoalsAndAssists]: {
    type: ColumnType.GoalsAndAssists,
    columnKey: 'goalPoints',
  },
  [ColumnType.HeadedGoals]: {
    type: ColumnType.HeadedGoals,
    columnKey: 'goalsByHeader',
  },
  [ColumnType.PenaltyGoals]: {
    type: ColumnType.PenaltyGoals,
    columnKey: 'penalties',
  },
  [ColumnType.Shots]: {
    type: ColumnType.Shots,
    columnKey: 'totalShots',
  },
  [ColumnType.ShotsOffGoal]: {
    type: ColumnType.ShotsOffGoal,
    columnKey: 'shotsOffGoal',
  },
  [ColumnType.ShotsOnGoal]: {
    type: ColumnType.ShotsOnGoal,
    columnKey: 'shotsOnGoal',
  },
  [ColumnType.GoalConversion]: {
    type: ColumnType.GoalConversion,
    columnKey: 'goalConversion',
  },
  [ColumnType.Offside]: {
    type: ColumnType.Offside,
    columnKey: 'offside',
  },
  [ColumnType.YellowCards]: {
    type: ColumnType.YellowCards,
    columnKey: 'yellowCards',
  },
  [ColumnType.YellowRedCards]: {
    type: ColumnType.YellowRedCards,
    columnKey: 'yellowRedCards',
  },
  [ColumnType.RedCards]: {
    type: ColumnType.RedCards,
    columnKey: 'redCards',
  },
  [ColumnType.CleanSheets]: {
    type: ColumnType.CleanSheets,
    columnKey: 'cleanSheet',
  },
  [ColumnType.Assists]: {
    type: ColumnType.Assists,
    columnKey: 'assists',
  },
  [ColumnType.GamesPlayed]: {
    type: ColumnType.GamesPlayed,
    columnKey: 'gamesPlayed',
    validationType: InputValidationType.Integer,
  },
  [ColumnType.MinutesPlayed]: {
    type: ColumnType.MinutesPlayed,
    columnKey: 'minutesPlayed',
    validationType: InputValidationType.Integer,
  },
};

export const POSITION_BY_TYPE = {
  [PositionType.Midfielder]: {
    id: 1,
    label: 'Midfielder',
  },
  [PositionType.Defender]: {
    id: 2,
    label: 'Defender',
  },
  [PositionType.Goalkeeper]: {
    id: 3,
    label: 'Goalkeeper',
  },
  [PositionType.Forward]: {
    id: 4,
    label: 'Forward',
  },
};

export const RANGE_FILTER_PARAMETER_BY_STAT_TYPE = {
  [StatType.All]: {
    [RangeType.MinGoals]: RangeParameter.MinGoals,
    [RangeType.MaxGoals]: RangeParameter.MaxGoals,
    [RangeType.MinGoalsAndAssists]: RangeParameter.MinGoalsAndAssists,
    [RangeType.MaxGoalsAndAssists]: RangeParameter.MaxGoalsAndAssists,
    [RangeType.MinHeadedGoals]: RangeParameter.MinHeadedGoals,
    [RangeType.MaxHeadedGoals]: RangeParameter.MaxHeadedGoals,
    [RangeType.MinPenaltyGoals]: RangeParameter.MinPenaltyGoals,
    [RangeType.MaxPenaltyGoals]: RangeParameter.MaxPenaltyGoals,
    [RangeType.MinShots]: RangeParameter.MinShots,
    [RangeType.MaxShots]: RangeParameter.MaxShots,
    [RangeType.MinShotsOffGoal]: RangeParameter.MinShotsOffGoal,
    [RangeType.MaxShotsOffGoal]: RangeParameter.MaxShotsOffGoal,
    [RangeType.MinShotsOnGoal]: RangeParameter.MinShotsOnGoal,
    [RangeType.MaxShotsOnGoal]: RangeParameter.MaxShotsOnGoal,
    [RangeType.MinGoalConversation]: RangeParameter.MinGoalConversation,
    [RangeType.MaxGoalConversation]: RangeParameter.MaxGoalConversation,
    [RangeType.MinOffside]: RangeParameter.MinOffside,
    [RangeType.MaxOffside]: RangeParameter.MaxOffside,
    [RangeType.MinYellowCards]: RangeParameter.MinYellowCards,
    [RangeType.MaxYellowCards]: RangeParameter.MaxYellowCards,
    [RangeType.MinYellowRedCards]: RangeParameter.MinYellowRedCards,
    [RangeType.MaxYellowRedCards]: RangeParameter.MaxYellowRedCards,
    [RangeType.MinRedCards]: RangeParameter.MinRedCards,
    [RangeType.MaxRedCards]: RangeParameter.MaxRedCards,
    [RangeType.MinCleanSheets]: RangeParameter.MinCleanSheets,
    [RangeType.MaxCleanSheets]: RangeParameter.MaxCleanSheets,
    [RangeType.MinAssists]: RangeParameter.MinAssists,
    [RangeType.MaxAssists]: RangeParameter.MaxAssists,
    [RangeType.MinGamesPlayed]: RangeParameter.MinGamesPlayed,
    [RangeType.MaxGamesPlayed]: RangeParameter.MaxGamesPlayed,
    [RangeType.MinMinutesPlayed]: RangeParameter.MinMinutesPlayed,
    [RangeType.MaxMinutesPlayed]: RangeParameter.MaxMinutesPlayed,
    [RangeType.MinDateOfBirth]: RangeParameter.MinDateOfBirth,
    [RangeType.MaxDateOfBirth]: RangeParameter.MaxDateOfBirth,
  },
  [StatType.PerNinetyMinutes]: {
    [RangeType.MinGoals]: RangeParameter.MinGoalsPerNinety,
    [RangeType.MaxGoals]: RangeParameter.MaxGoalsPerNinety,
    [RangeType.MinGoalsAndAssists]: RangeParameter.MinGoalsAndAssistsPerNinety,
    [RangeType.MaxGoalsAndAssists]: RangeParameter.MaxGoalsAndAssistsPerNinety,
    [RangeType.MinHeadedGoals]: RangeParameter.MinHeadedGoalsPerNinety,
    [RangeType.MaxHeadedGoals]: RangeParameter.MaxHeadedGoalsPerNinety,
    [RangeType.MinPenaltyGoals]: RangeParameter.MinPenaltyGoalsPerNinety,
    [RangeType.MaxPenaltyGoals]: RangeParameter.MaxPenaltyGoalsPerNinety,
    [RangeType.MinShots]: RangeParameter.MinShotsPerNinety,
    [RangeType.MaxShots]: RangeParameter.MaxShotsPerNinety,
    [RangeType.MinShotsOffGoal]: RangeParameter.MinShotsOffGoalPerNinety,
    [RangeType.MaxShotsOffGoal]: RangeParameter.MaxShotsOffGoalPerNinety,
    [RangeType.MinShotsOnGoal]: RangeParameter.MinShotsOnGoalPerNinety,
    [RangeType.MaxShotsOnGoal]: RangeParameter.MaxShotsOnGoalPerNinety,
    [RangeType.MinGoalConversation]: RangeParameter.MinGoalConversationPerNinety,
    [RangeType.MaxGoalConversation]: RangeParameter.MaxGoalConversationPerNinety,
    [RangeType.MinOffside]: RangeParameter.MinOffsidePerNinety,
    [RangeType.MaxOffside]: RangeParameter.MaxOffsidePerNinety,
    [RangeType.MinYellowCards]: RangeParameter.MinYellowCardsPerNinety,
    [RangeType.MaxYellowCards]: RangeParameter.MaxYellowCardsPerNinety,
    [RangeType.MinYellowRedCards]: RangeParameter.MinYellowRedCardsPerNinety,
    [RangeType.MaxYellowRedCards]: RangeParameter.MaxYellowRedCardsPerNinety,
    [RangeType.MinRedCards]: RangeParameter.MinRedCardsPerNinety,
    [RangeType.MaxRedCards]: RangeParameter.MaxRedCardsPerNinety,
    [RangeType.MinCleanSheets]: RangeParameter.MinCleanSheetsPerNinety,
    [RangeType.MaxCleanSheets]: RangeParameter.MaxCleanSheetsPerNinety,
    [RangeType.MinAssists]: RangeParameter.MinAssistsPerNinety,
    [RangeType.MaxAssists]: RangeParameter.MaxAssistsPerNinety,
    [RangeType.MinGamesPlayed]: RangeParameter.MinGamesPlayed,
    [RangeType.MaxGamesPlayed]: RangeParameter.MaxGamesPlayed,
    [RangeType.MinMinutesPlayed]: RangeParameter.MinMinutesPlayed,
    [RangeType.MaxMinutesPlayed]: RangeParameter.MaxMinutesPlayed,
    [RangeType.MinDateOfBirth]: RangeParameter.MinDateOfBirth,
    [RangeType.MaxDateOfBirth]: RangeParameter.MaxDateOfBirth,
  },
  [StatType.PerGame]: {
    [RangeType.MinGoals]: RangeParameter.MinGoalsPerGame,
    [RangeType.MaxGoals]: RangeParameter.MaxGoalsPerGame,
    [RangeType.MinGoalsAndAssists]: RangeParameter.MinGoalsAndAssistsPerGame,
    [RangeType.MaxGoalsAndAssists]: RangeParameter.MaxGoalsAndAssistsPerGame,
    [RangeType.MinHeadedGoals]: RangeParameter.MinHeadedGoalsPerGame,
    [RangeType.MaxHeadedGoals]: RangeParameter.MaxHeadedGoalsPerGame,
    [RangeType.MinPenaltyGoals]: RangeParameter.MinPenaltyGoalsPerGame,
    [RangeType.MaxPenaltyGoals]: RangeParameter.MaxPenaltyGoalsPerGame,
    [RangeType.MinShots]: RangeParameter.MinShotsPerGame,
    [RangeType.MaxShots]: RangeParameter.MaxShotsPerGame,
    [RangeType.MinShotsOffGoal]: RangeParameter.MinShotsOffGoalPerGame,
    [RangeType.MaxShotsOffGoal]: RangeParameter.MaxShotsOffGoalPerGame,
    [RangeType.MinShotsOnGoal]: RangeParameter.MinShotsOnGoalPerGame,
    [RangeType.MaxShotsOnGoal]: RangeParameter.MaxShotsOnGoalPerGame,
    [RangeType.MinGoalConversation]: RangeParameter.MinGoalConversationPerGame,
    [RangeType.MaxGoalConversation]: RangeParameter.MaxGoalConversationPerGame,
    [RangeType.MinOffside]: RangeParameter.MinOffsidePerGame,
    [RangeType.MaxOffside]: RangeParameter.MaxOffsidePerGame,
    [RangeType.MinYellowCards]: RangeParameter.MinYellowCardsPerGame,
    [RangeType.MaxYellowCards]: RangeParameter.MaxYellowCardsPerGame,
    [RangeType.MinYellowRedCards]: RangeParameter.MinYellowRedCardsPerGame,
    [RangeType.MaxYellowRedCards]: RangeParameter.MaxYellowRedCardsPerGame,
    [RangeType.MinRedCards]: RangeParameter.MinRedCardsPerGame,
    [RangeType.MaxRedCards]: RangeParameter.MaxRedCardsPerGame,
    [RangeType.MinCleanSheets]: RangeParameter.MinCleanSheetsPerGame,
    [RangeType.MaxCleanSheets]: RangeParameter.MaxCleanSheetsPerGame,
    [RangeType.MinAssists]: RangeParameter.MinAssistsPerGame,
    [RangeType.MaxAssists]: RangeParameter.MaxAssistsPerGame,
    [RangeType.MinGamesPlayed]: RangeParameter.MinGamesPlayed,
    [RangeType.MaxGamesPlayed]: RangeParameter.MaxGamesPlayed,
    [RangeType.MinMinutesPlayed]: RangeParameter.MinMinutesPlayedPerGame,
    [RangeType.MaxMinutesPlayed]: RangeParameter.MaxMinutesPlayedPerGame,
    [RangeType.MinDateOfBirth]: RangeParameter.MinDateOfBirth,
    [RangeType.MaxDateOfBirth]: RangeParameter.MaxDateOfBirth,
  },
};

export const FILTER_ORDERING_BY_STAT_TYPE = {
  [StatType.All]: {
    [ColumnType.Player]: OrderingKey.PlayerName,
    [ColumnType.Goals]: OrderingKey.Goals,
    [ColumnType.GamesPlayed]: OrderingKey.GamesPlayed,
    [ColumnType.MinutesPlayed]: OrderingKey.MinutesPlayed,
    [ColumnType.HeadedGoals]: OrderingKey.HeadedGoals,
    [ColumnType.PenaltyGoals]: OrderingKey.PenaltyGoals,
    [ColumnType.GoalConversion]: OrderingKey.GoalsConversion,
    [ColumnType.YellowCards]: OrderingKey.YellowCards,
    [ColumnType.YellowRedCards]: OrderingKey.YellowRedCards,
    [ColumnType.RedCards]: OrderingKey.RedCards,
    [ColumnType.CleanSheets]: OrderingKey.CleanSheets,
    [ColumnType.Assists]: OrderingKey.Assists,
    [ColumnType.GoalsAndAssists]: OrderingKey.GoalsAndAssists,
    [ColumnType.Shots]: OrderingKey.Shots,
    [ColumnType.ShotsOnGoal]: OrderingKey.ShotsOnGoal,
    [ColumnType.ShotsOffGoal]: OrderingKey.ShotsOffGoal,
    [ColumnType.Offside]: OrderingKey.Offside,
  },
  [StatType.PerNinetyMinutes]: {
    [ColumnType.Player]: OrderingKey.PlayerName,
    [ColumnType.Goals]: OrderingKey.GoalsPerNinety,
    [ColumnType.GamesPlayed]: OrderingKey.GamesPlayed,
    [ColumnType.MinutesPlayed]: OrderingKey.MinutesPlayed,
    [ColumnType.HeadedGoals]: OrderingKey.HeadedGoalsPerNinety,
    [ColumnType.PenaltyGoals]: OrderingKey.PenaltyGoalsPerNinety,
    [ColumnType.GoalConversion]: OrderingKey.GoalsConversionPerNinety,
    [ColumnType.YellowCards]: OrderingKey.YellowCardsPerNinety,
    [ColumnType.YellowRedCards]: OrderingKey.YellowRedCardsPerNinety,
    [ColumnType.RedCards]: OrderingKey.RedCardsPerNinety,
    [ColumnType.CleanSheets]: OrderingKey.CleanSheetsPerNinety,
    [ColumnType.Assists]: OrderingKey.AssistsPerNinety,
    [ColumnType.GoalsAndAssists]: OrderingKey.GoalsAndAssistsPerNinety,
    [ColumnType.Shots]: OrderingKey.ShotsPerNinety,
    [ColumnType.ShotsOnGoal]: OrderingKey.ShotsOnGoalPerNinety,
    [ColumnType.ShotsOffGoal]: OrderingKey.ShotsOffGoalPerNinety,
    [ColumnType.Offside]: OrderingKey.OffsidePerNinety,
  },
  [StatType.PerGame]: {
    [ColumnType.Player]: OrderingKey.PlayerName,
    [ColumnType.Goals]: OrderingKey.GoalsPerGame,
    [ColumnType.GamesPlayed]: OrderingKey.GamesPlayed,
    [ColumnType.MinutesPlayed]: OrderingKey.MinutesPlayedPerGame,
    [ColumnType.HeadedGoals]: OrderingKey.HeadedGoalsPerGame,
    [ColumnType.PenaltyGoals]: OrderingKey.PenaltyGoalsPerGame,
    [ColumnType.GoalConversion]: OrderingKey.GoalsConversionPerGame,
    [ColumnType.YellowCards]: OrderingKey.YellowCardsPerGame,
    [ColumnType.YellowRedCards]: OrderingKey.YellowRedCardsPerGame,
    [ColumnType.RedCards]: OrderingKey.RedCardsPerGame,
    [ColumnType.CleanSheets]: OrderingKey.CleanSheetsPerGame,
    [ColumnType.Assists]: OrderingKey.AssistsPerGame,
    [ColumnType.GoalsAndAssists]: OrderingKey.GoalsAndAssistsPerGame,
    [ColumnType.Shots]: OrderingKey.ShotsPerGame,
    [ColumnType.ShotsOnGoal]: OrderingKey.ShotsOnGoalPerGame,
    [ColumnType.ShotsOffGoal]: OrderingKey.ShotsOffGoalPerGame,
    [ColumnType.Offside]: OrderingKey.OffsidePerGame,
  },
};

export const DEFAULT_COLUMNS = [
  COLUMN_TEMPLATE_BY_TYPE[ColumnType.PlayerRank],
  COLUMN_TEMPLATE_BY_TYPE[ColumnType.PlayerIcon],
  COLUMN_TEMPLATE_BY_TYPE[ColumnType.Player],
  COLUMN_TEMPLATE_BY_TYPE[ColumnType.Club],
];

export const COLUMNS_BY_PRESET_TYPE = {
  [PresetType.Summary]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.MinutesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Goals],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Assists],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GoalsAndAssists],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Shots],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.ShotsOnGoal],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Offside],
  ],
  [PresetType.Attack]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Goals],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.HeadedGoals],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.PenaltyGoals],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Shots],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.ShotsOnGoal],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Offside],
  ],
  [PresetType.Aggression]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.YellowCards],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.YellowRedCards],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.RedCards],
  ],
  [PresetType.Goalkeeper]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.CleanSheets],
  ],
  [PresetType.Passing]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Assists],
  ],
};

export const ACTIVE_COLUMNS_BY_PRESET_TYPE = {
  [PresetType.Summary]: {
    [ColumnType.GamesPlayed]: true,
    [ColumnType.MinutesPlayed]: true,
    [ColumnType.Goals]: true,
    [ColumnType.Assists]: true,
    [ColumnType.GoalsAndAssists]: true,
    [ColumnType.Shots]: true,
    [ColumnType.ShotsOnGoal]: true,
    [ColumnType.Offside]: true,
  },
  [PresetType.Attack]: {
    [ColumnType.GamesPlayed]: true,
    [ColumnType.Goals]: true,
    [ColumnType.HeadedGoals]: true,
    [ColumnType.PenaltyGoals]: true,
    [ColumnType.Shots]: true,
    [ColumnType.ShotsOnGoal]: true,
    [ColumnType.Offside]: true,
  },
  [PresetType.Aggression]: {
    [ColumnType.GamesPlayed]: true,
    [ColumnType.YellowCards]: true,
    [ColumnType.YellowRedCards]: true,
    [ColumnType.RedCards]: true,
  },
  [PresetType.Goalkeeper]: {
    [ColumnType.GamesPlayed]: true,
    [ColumnType.CleanSheets]: true,
  },
  [PresetType.Passing]: {
    [ColumnType.GamesPlayed]: true,
    [ColumnType.Assists]: true,
  },
};

export const RANGE_FILTERS_DEFAULT_STATE = {
  [ColumnType.Goals]: {},
  [ColumnType.GamesPlayed]: {},
  [ColumnType.HeadedGoals]: {},
  [ColumnType.PenaltyGoals]: {},
  [ColumnType.GoalConversion]: {},
  [ColumnType.YellowCards]: {},
  [ColumnType.YellowRedCards]: {},
  [ColumnType.CleanSheets]: {},
  [ColumnType.RedCards]: {},
  [ColumnType.Assists]: {},
  [ColumnType.MinutesPlayed]: {},
  [ColumnType.GoalsAndAssists]: {},
  [ColumnType.Shots]: {},
  [ColumnType.ShotsOnGoal]: {},
  [ColumnType.ShotsOffGoal]: {},
  [ColumnType.Offside]: {},
};

export const DEFAULT_COMMON_ORDERING: Ordering = {
  [ColumnType.Player]: OrderType.Ascending,
};

export const COMMON_ORDERING_BY_STATS_TYPE: Record<StatType, Ordering> = {
  [StatType.All]: DEFAULT_COMMON_ORDERING,
  [StatType.PerGame]: DEFAULT_COMMON_ORDERING,
  [StatType.PerNinetyMinutes]: DEFAULT_COMMON_ORDERING,
};

export const COLUMN_CONFIGURATION_FILTER_EMPTY_STATE: ColumnConfigurationFilter = {
  activePreset: PresetType.Custom,
  columns: DEFAULT_COLUMNS,
  configurableOrdering: {},
  activeColumns: {},
  rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
  positions: [],
};

export const CUSTOM_GENERAL_FILTER_EMPTY_STATE = {
  ages: {},
  teams: [],
  nationalities: [],
  activeStatsTab: StatType.All,
  commonOrdering: COMMON_ORDERING_BY_STATS_TYPE[StatType.All],
};

export const COLUMN_CONFIGURATION_FILTER_DEFAULT_STATE: ColumnConfigurationFilter = {
  activePreset: PresetType.Summary,
  columns: COLUMNS_BY_PRESET_TYPE[PresetType.Summary],
  activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Summary],
  rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
  configurableOrdering: { [ColumnType.Goals]: OrderType.Descending },
  positions: [],
};

export const CUSTOM_FILTER_DEFAULT_STATE: CustomFilterState = {
  generalFilter: CUSTOM_GENERAL_FILTER_EMPTY_STATE,
  columnConfigurationFilter: COLUMN_CONFIGURATION_FILTER_DEFAULT_STATE,
};

export const CUSTOM_FILTER_EMPTY_STATE: CustomFilterState = {
  generalFilter: CUSTOM_GENERAL_FILTER_EMPTY_STATE,
  columnConfigurationFilter: COLUMN_CONFIGURATION_FILTER_EMPTY_STATE,
};

export const CUSTOMIZATION_PRESET_BY_TYPE = {
  [PresetType.Summary]: {
    type: PresetType.Summary,
    overrides: {
      activePreset: PresetType.Summary,
      columns: COLUMNS_BY_PRESET_TYPE[PresetType.Summary],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Summary],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      positions: [],
      configurableOrdering: {
        [ColumnType.Goals]: OrderType.Descending,
      },
    },
  },
  [PresetType.Attack]: {
    type: PresetType.Attack,
    overrides: {
      activePreset: PresetType.Attack,
      columns: COLUMNS_BY_PRESET_TYPE[PresetType.Attack],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Attack],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      positions: [],
      configurableOrdering: {
        [ColumnType.Goals]: OrderType.Descending,
      },
    },
  },
  [PresetType.Aggression]: {
    type: PresetType.Aggression,
    overrides: {
      activePreset: PresetType.Aggression,
      columns: COLUMNS_BY_PRESET_TYPE[PresetType.Aggression],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Aggression],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      positions: [],
      configurableOrdering: {
        [ColumnType.YellowCards]: OrderType.Descending,
      },
    },
  },
  [PresetType.Goalkeeper]: {
    type: PresetType.Goalkeeper,
    overrides: {
      activePreset: PresetType.Goalkeeper,
      columns: COLUMNS_BY_PRESET_TYPE[PresetType.Goalkeeper],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Goalkeeper],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      positions: [POSITION_BY_TYPE[PositionType.Goalkeeper]],
      configurableOrdering: {
        [ColumnType.CleanSheets]: OrderType.Descending,
      },
    },
  },
  [PresetType.Passing]: {
    type: PresetType.Passing,
    overrides: {
      activePreset: PresetType.Passing,
      columns: COLUMNS_BY_PRESET_TYPE[PresetType.Passing],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Passing],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      positions: [],
      configurableOrdering: {
        [ColumnType.Assists]: OrderType.Descending,
      },
    },
  },
  [PresetType.Custom]: { type: PresetType.Custom, label: 'Custom', overrides: {} },
};

export const CUSTOMIZATION_PRESETS_BY_STAT_TYPE: PresetView[] = [
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Summary],
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Attack],
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Aggression],
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Goalkeeper],
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Passing],
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Custom],
];

export const COLUMNS_TO_SKIP_DEFAULT_ORDERING = [
  Enums.ColumnType.Club,
  Enums.ColumnType.Player,
  Enums.ColumnType.PlayerRank,
  Enums.ColumnType.PlayerIcon,
];

export const PLAYER_POSITION_LABEL = {
  [PlayerPosition.Goalkeeper]: 'Goalkeeper',
  [PlayerPosition.Defender]: 'Defender',
  [PlayerPosition.Midfielder]: 'Midfielder',
  [PlayerPosition.Forward]: 'Forward',
};

export const PLAYER_ID_BY_POSITION = {
  [PlayerPosition.Midfielder]: 1,
  [PlayerPosition.Defender]: 2,
  [PlayerPosition.Goalkeeper]: 3,
  [PlayerPosition.Forward]: 4,
};

export const PLAYER_POSITION_BY_ID = {
  [PLAYER_ID_BY_POSITION[PlayerPosition.Goalkeeper]]: PlayerPosition.Goalkeeper,
  [PLAYER_ID_BY_POSITION[PlayerPosition.Defender]]: PlayerPosition.Defender,
  [PLAYER_ID_BY_POSITION[PlayerPosition.Midfielder]]: PlayerPosition.Midfielder,
  [PLAYER_ID_BY_POSITION[PlayerPosition.Forward]]: PlayerPosition.Forward,
};

export const PLAYER_POSITION_SELECT_ITEMS: SelectItem[] = [
  {
    id: PLAYER_ID_BY_POSITION[PlayerPosition.Goalkeeper],
    label: PLAYER_POSITION_LABEL[PlayerPosition.Goalkeeper],
  },
  {
    id: PLAYER_ID_BY_POSITION[PlayerPosition.Defender],
    label: PLAYER_POSITION_LABEL[PlayerPosition.Defender],
  },
  {
    id: PLAYER_ID_BY_POSITION[PlayerPosition.Midfielder],
    label: PLAYER_POSITION_LABEL[PlayerPosition.Midfielder],
  },
  {
    id: PLAYER_ID_BY_POSITION[PlayerPosition.Forward],
    label: PLAYER_POSITION_LABEL[PlayerPosition.Forward],
  },
];
