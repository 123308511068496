export enum Tables {
  DEFAULT_TABLE = 'default',
  FAVORITES_TABLE = 'favorites',
  FAVORITE_EVENTS = 'favoriteEvents',
  FAVORITE_TEAMS = 'favoriteTeams',
  FAVORITE_LEAGUES = 'favoriteLeagues',
  REST_CACHE_TABLE = 'restCache',
  USER_SETTINGS = 'userSettings',
}

export enum FavoritesTableIndex {
  Id = 'id',
  Date = 'date',
  UniqueTournamentId = 'uniqueTournamentId',
  HomeTeamId = 'homeTeamId',
  AwayTeamId = 'awayTeamId',
  CategoryId = 'categoryId',
  UniqueTournamentIdByDate = '[uniqueTournamentId+date]',
  CategoryIdByDate = `[date+categoryId]`,
}

export enum FavoritesEventsTableIndex {
  Id = 'id',
  Date = 'date',
}

export enum FavoritesTeamsTableIndex {
  Id = 'id',
  OrderNumber = 'orderNumber',
}

export enum FavoritesLeaguesTableIndex {
  UniqueTournamentId = 'uniqueTournamentId',
  OrderNumber = 'orderNumber',
}
