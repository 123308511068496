export enum Theme {
  Dark = 'dark',
  Light = 'light',
}

export enum GoalHappeningViewTypes {
  Goal = 'Goal',
  OwnGoal = 'OwnGoal',
  PenaltyGoal = 'PenaltyGoal',
  PenaltyMissed = 'PenaltyMissed',
  NotKicked = 'NotKicked',
}
