import React, { memo, useMemo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { FlexibleTable, Skeleton } from 'packages/shared/components';

import { getColumnSchema } from './services';
import styles from './styles.scss';

type Props = {
  elementsCount?: number;
  isCompact?: boolean;
};

const DEFAULT_ELEMENTS_COUNT = 10;

const LoadingSkeleton = ({ elementsCount = DEFAULT_ELEMENTS_COUNT, isCompact = false }: Props) => {
  const elements = useMemo(() => ArrayUtils.getArrayFromNumber(elementsCount), []);
  const columns = useMemo(getColumnSchema, []);

  if (isCompact) {
    return (
      <div className={styles.container}>
        <div className={styles.compactHeader}>
          <Skeleton width={101} height={16} variant="rectangular" />
          <Skeleton width={80} height={16} variant="rectangular" />
        </div>
        <FlexibleTable
          data={elements}
          columns={columns}
          className={styles.table}
          headerClassName={styles.tableHeader}
          rowClassName={styles.tableRow}
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.filter}>
        <Skeleton width={101} height={16} variant="rectangular" />
        <Skeleton width={100} height={16} variant="rectangular" />
        <Skeleton width={120} height={16} variant="rectangular" />
      </div>
      <div className={styles.tabs}>
        <Skeleton height={30} width="100%" variant="rectangular" />
      </div>
      <FlexibleTable
        data={elements}
        columns={columns}
        className={styles.table}
        headerClassName={styles.tableHeader}
        rowClassName={styles.tableRow}
      />
      <div className={styles.link}>
        <Skeleton width={120} height={16} variant="rectangular" />
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
