import { BracketRound, MatchUp, MatchUpActiveType } from 'packages/event-standings/types';
import { isCommonMatchUp } from 'packages/event-standings/services';

const markActiveMatchUps = (
  rounds: BracketRound[],
  activeTeamIds: number[],
  activeEventId?: number,
) => {
  const markedRounds = [...rounds];
  let activeRoundIndex: number | undefined;
  let baseMatchUpIndex: number | undefined;
  let baseMatchUpRoundIndex: number | undefined;

  if (activeEventId) {
    const result = markBaseActiveMatchUpByEventId(markedRounds, activeEventId);

    if (result) {
      const { roundIndex, matchUpIndex, matchUp } = result;
      const matchUpActiveTeamIds: [number, number] = [matchUp.homeTeam.id, matchUp.awayTeam.id];
      activeRoundIndex = roundIndex;
      baseMatchUpIndex = matchUpIndex;
      baseMatchUpRoundIndex = roundIndex;

      markActiveMatchUpsByRound(markedRounds, matchUp, roundIndex - 1, matchUpActiveTeamIds);
    }
  } else {
    for (const activeTeamId of activeTeamIds) {
      const result = markBaseActiveMatchUpByTeamId(markedRounds, activeTeamId);

      if (result) {
        const { roundIndex, matchUpIndex, matchUp } = result;
        const matchUpActiveTeamIds: [number, number] = [matchUp.homeTeam.id, matchUp.awayTeam.id];
        baseMatchUpIndex = matchUpIndex;
        baseMatchUpRoundIndex = roundIndex;

        markActiveMatchUpsByRound(markedRounds, matchUp, roundIndex - 1, matchUpActiveTeamIds);
      }
    }
  }

  return { markedRounds, activeRoundIndex, baseMatchUpIndex, baseMatchUpRoundIndex };
};

const markBaseActiveMatchUpByEventId = (rounds: BracketRound[], activeEventId?: number) => {
  for (let roundIndex = rounds.length - 1; roundIndex >= 0; roundIndex--) {
    for (let matchUpIndex = 0; matchUpIndex < rounds[roundIndex].matchups.length; matchUpIndex++) {
      const matchUp = rounds[roundIndex].matchups[matchUpIndex];

      if (isCommonMatchUp(matchUp)) {
        const exactMatchUp = matchUp.sportEvents.find(({ id }) => id === activeEventId);

        if (exactMatchUp) {
          return {
            roundIndex,
            matchUpIndex,
            matchUp,
          };
        }
      }
    }
  }
};

const markBaseActiveMatchUpByTeamId = (rounds: BracketRound[], activeTeamId: number) => {
  for (let roundIndex = rounds.length - 1; roundIndex >= 0; roundIndex--) {
    for (let matchUpIndex = 0; matchUpIndex < rounds[roundIndex].matchups.length; matchUpIndex++) {
      const matchUp = rounds[roundIndex].matchups[matchUpIndex];

      if (isCommonMatchUp(matchUp)) {
        if (matchUp.homeTeam.id === activeTeamId || matchUp.awayTeam.id === activeTeamId) {
          return {
            roundIndex,
            matchUpIndex,
            matchUp,
          };
        }
      }
    }
  }
};

const markActiveMatchUpsByRound = (
  rounds: BracketRound[],
  parentMatchUp: MatchUp,
  roundIndex: number,
  activeTeamIds: number[] = [],
) => {
  if (isCommonMatchUp(parentMatchUp)) {
    parentMatchUp.isActive = true;

    if (roundIndex < 0) {
      return;
    }

    const [homeChildrenMatchUpId, awayChildrenMatchUpId] = parentMatchUp.children;
    const childrenMatchUps = rounds[roundIndex].matchups.filter(({ id }) =>
      parentMatchUp.children.includes(id),
    );

    const childrenHomeMatchUp = findMatchUpWithActiveTeamById(
      childrenMatchUps,
      homeChildrenMatchUpId,
      activeTeamIds,
    );
    const childrenAwayMatchUp = findMatchUpWithActiveTeamById(
      childrenMatchUps,
      awayChildrenMatchUpId,
      activeTeamIds,
    );

    roundIndex--;

    if (childrenHomeMatchUp) {
      parentMatchUp.activeType = MatchUpActiveType.HalfTop;
      markActiveMatchUpsByRound(rounds, childrenHomeMatchUp, roundIndex, activeTeamIds);
    }

    if (childrenAwayMatchUp) {
      parentMatchUp.activeType = MatchUpActiveType.HalfBottom;
      markActiveMatchUpsByRound(rounds, childrenAwayMatchUp, roundIndex, activeTeamIds);
    }

    if (childrenHomeMatchUp && childrenAwayMatchUp) {
      parentMatchUp.activeType = MatchUpActiveType.Full;
    }
  }
};

const findMatchUpWithActiveTeamById = (matchups: MatchUp[], matchUpId, activeTeamIds: number[]) => {
  return matchups.find((childrenMatchUp) => {
    if (isCommonMatchUp(childrenMatchUp)) {
      const hasActiveTeam = activeTeamIds.some(
        (activeTeamId) =>
          childrenMatchUp.homeTeam.id === activeTeamId ||
          childrenMatchUp.awayTeam.id == activeTeamId,
      );

      return childrenMatchUp.id === matchUpId && hasActiveTeam;
    }
  });
};

export default markActiveMatchUps;
