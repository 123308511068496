import { handleActions, ReducerMap, combineActions } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState = {
  isResultsLoading: false,
  hasNextResults: false,
  nextOffset: 0,
  scrollTop: 0,
};

const reducerMap: ReducerMap<Status, Status> = {
  [`${actions.loadResultsStart}`]: (state) => {
    return { ...state, isResultsLoading: true };
  },
  [`${combineActions(
    actions.loadResultsSuccess,
    actions.loadMoreResultsSuccess,
    actions.loadResultsFailure,
  )}`]: (state, { payload }) => {
    const { hasNextResults, nextOffset, scrollTop } = payload;

    return {
      ...state,
      isResultsLoading: false,
      hasNextResults,
      nextOffset,
      scrollTop,
    };
  },
};

export default handleActions(reducerMap, defaultState);
