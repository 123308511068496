import { COMMON_ORDERING_BY_STATS_TYPE, RANGE_FILTERS_DEFAULT_STATE } from '../../constants';
import { customFilterStateActions } from '../../actions';
import { customFilterSelectors } from '../../selectors';
import { StatType } from '../../types';

const updateStatType = (statType: StatType) => (dispatch, getState) => {
  const { generalFilter, columnConfigurationFilter } = customFilterSelectors.getData(getState());

  dispatch(
    customFilterStateActions.customFilterUpdate({
      generalFilter: {
        ...generalFilter,
        commonOrdering: COMMON_ORDERING_BY_STATS_TYPE[statType],
        activeStatsTab: statType,
      },
      columnConfigurationFilter: {
        ...columnConfigurationFilter,
        rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      },
    }),
  );
};

export default updateStatType;
