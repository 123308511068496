import { Cell, HeaderGroup, Hooks, Meta, TableCellProps, TableHeaderProps } from 'react-table';

const useCustomStyling = <D extends object>(hooks: Hooks<D>) => {
  hooks.getHeaderProps.push(getHeaderProps());
  hooks.getCellProps.push(getCellProps());
};

useCustomStyling.pluginName = 'useCustomStyling';

const getHeaderProps = () => <D extends object>(
  props: Partial<TableHeaderProps>,
  meta: Meta<D, { column: HeaderGroup<D> }>,
) => {
  const { column } = meta;
  const { headerStyle, headerClassName } = column;

  return [
    props,
    {
      style: headerStyle,
      className: headerClassName,
    },
  ];
};

const getCellProps = () => <D extends object>(
  props: Partial<TableCellProps>,
  meta: Meta<D, { cell: Cell<D> }>,
) => {
  const {
    cell: { column },
  } = meta;
  const { cellStyle, cellClassName } = column;

  return [
    props,
    {
      style: cellStyle,
      className: cellClassName,
    },
  ];
};

export default useCustomStyling;
