import Dexie from 'dexie';

import { keyValueDatabase } from 'packages/storage';
import { Favorites } from 'packages/rest';
import { mapEvents } from 'packages/events/services';
import { FavoritesTableIndex, Tables } from 'configure/storage/types';

import putFavorites from './putFavorites';
import { loadFavoritesStart, loadFavoritesSuccess, loadFavoritesFailure } from '../actions';

const loadFavoritesByDate = (date: string) => async (dispatch) => {
  try {
    dispatch(loadFavoritesStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);
    const favoriteEventsModel = dexieInstance.table(Tables.FAVORITE_EVENTS);
    const favoriteTeamsModel = dexieInstance.table(Tables.FAVORITE_TEAMS);
    const favoriteLeaguesModel = dexieInstance.table(Tables.FAVORITE_LEAGUES);

    const [
      favoriteEventsIds,
      favoriteTeamsIds,
      favoriteLeaguesIds,
    ] = await dexieInstance.transaction(
      'r',
      [favoriteEventsModel, favoriteTeamsModel, favoriteLeaguesModel],
      () =>
        Promise.all([
          favoriteEventsModel
            .where('date')
            .equals(date)
            .primaryKeys(),
          favoriteTeamsModel.toCollection().primaryKeys(),
          favoriteLeaguesModel.toCollection().primaryKeys(),
        ]),
    );

    const [{ data }] = await Promise.all([
      Favorites.loadFavoriteEvents({
        leagueIds: favoriteLeaguesIds as number[],
        eventIds: favoriteEventsIds as number[],
        teamIds: favoriteTeamsIds as number[],
        activeDate: date,
      }),
      favoritesModel
        .where(FavoritesTableIndex.Date)
        .equals(date)
        .delete(),
    ]);

    const mappedFavoriteEvents = mapEvents(data.results);

    await dispatch(putFavorites(mappedFavoriteEvents));

    dispatch(loadFavoritesSuccess());
  } catch (e) {
    dispatch(loadFavoritesFailure(e));
  }
};

export default loadFavoritesByDate;
