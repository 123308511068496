import { PopoverAlign, PopoverCustomPosition, PopoverJustify } from '../types';

import calculateJustify from './calculateJustify';
import calculateAlign from './calculateAlign';
import calculateAlignByParent from './calculateAlignByParent';
import calculateJustifyByParent from './calculateJustifyByParent';
import calculatePositionFromVerticalEdges from './calculatePositionFromVerticalEdges';
import calculatePositionFromHorizontalEdges from './calculatePositionFromHorizontalEdges';

import { getTargetRect, getParentRect } from './getters';

interface Params {
  justify: PopoverJustify;
  align: PopoverAlign;
  popoverEl: HTMLDivElement;
  targetEl: HTMLDivElement;
  getParent: () => HTMLElement | null;
  boundedByParent: boolean;
  insertIntoParent?: boolean;
  customPosition?: PopoverCustomPosition;
}

const calculatePositionWithTarget = ({
  justify,
  align,
  popoverEl,
  targetEl,
  getParent,
  insertIntoParent,
  customPosition,
  boundedByParent,
}: Params): { top: number; left: number } => {
  let left = customPosition?.left || 0;
  let top = customPosition?.top || 0;
  const {
    height: popoverHeight,
    width: popoverWidth,
  }: ClientRect = popoverEl.getBoundingClientRect();

  const parentEl = getParent();
  const targetRect = getTargetRect(targetEl, parentEl, insertIntoParent);

  left = calculateJustify(left, targetRect, popoverWidth, justify);
  top = calculateAlign(top, targetRect, popoverHeight, align);

  if (parentEl && boundedByParent) {
    const parentRect = getParentRect(parentEl, insertIntoParent);

    left = calculateJustifyByParent(left, popoverWidth, parentRect);
    top = calculateAlignByParent(top, popoverHeight, parentRect);
  }

  left = calculatePositionFromHorizontalEdges(popoverWidth, left);
  top = calculatePositionFromVerticalEdges(popoverHeight, top);

  return {
    left,
    top,
  };
};

export default calculatePositionWithTarget;
