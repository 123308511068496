import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Data } from '../types';
import {
  loadMatchSummaryStart,
  loadMatchSummarySuccess,
  loadMatchSummaryFailure,
} from '../actions';

const defaultState: Data = {
  periods: [],
  facts: [],
  commentaries: {
    important: [],
    all: [],
  },
};

const reducerMap: ReducerMap<Data, Data> = {
  [`${loadMatchSummarySuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(loadMatchSummaryFailure, loadMatchSummaryStart)}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
