import { Event } from 'types';
import DateService from 'packages/date-az';

export const getDatePeriodByEvents = (events: Event[]): [string, string] | undefined => {
  if (!events.length) {
    return undefined;
  }

  if (events.length === 1) {
    return getSingleEventPeriod(events);
  }

  return getMultipleEventsPeriod(events);
};

const getSingleEventPeriod = (events: Event[]): [string, string] => {
  const [event] = events;
  const { scheduled } = event;

  return [DateService.parse(scheduled).toISODate(), DateService.parse(scheduled).toISODate()];
};

const getMultipleEventsPeriod = (events: Event[]): [string, string] => {
  const [firstEvent] = events;
  const lastEvent = events[events.length - 1];
  const { scheduled: firstEventDate } = firstEvent;
  const { scheduled: lastEventDate } = lastEvent;

  return [
    DateService.parse(firstEventDate).toISODate(),
    DateService.parse(lastEventDate).toISODate(),
  ];
};
