import React, { memo } from 'react';
import classnames from 'classnames';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  isReversed?: boolean;
};

const Team = ({ isReversed }: Props) => {
  return (
    <div className={classnames(styles.container, { [styles.reversed]: isReversed })}>
      <div className={styles.wrapper}>
        <Skeleton
          className={styles.teamLabel}
          marginRight={!isReversed ? 6 : undefined}
          marginLeft={isReversed ? 6 : undefined}
          maxWidth={80}
          height={14}
          variant="rectangular"
        />
        <Skeleton width={20} height={20} minHeight={20} minWidth={20} variant="circular" />
      </div>
    </div>
  );
};
export default memo(Team);
