import React, { memo } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed';

import { Interfaces as StadiumSummaryInterfaces } from 'packages/stadium-summary/types';
import { Weight } from 'packages/shared/components/Separator/types';
import { Figure, Separator } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import styles from './styles.scss';
import { LoadingSkeleton } from './components';

type Props = {
  topScorers?: StadiumSummaryInterfaces.Statistics.TopScorer[];
  className?: string;
  isLoading: boolean;
};

const TopScorers = ({ topScorers = [], className, isLoading }: Props) => {
  const { appNavigate } = useNavigation();

  const getTopScorerClickHandler = (playerId: number) => () => {
    appNavigate(DesktopPage.FootballPlayer, { playerId });
  };

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (isEmpty(topScorers)) {
    return null;
  }

  return (
    <div className={classnames(styles.topScorersContainer, className)}>
      <div className={styles.header}>
        <div className={styles.title}>{i18n.value('stadium.statistics.topScorers.title')}</div>
        <div className={styles.goalsLabel}>
          {i18n.value('stadium.statistics.topScorers.goalsLabel')}
        </div>
      </div>
      <div className={styles.topScorers}>
        {topScorers.map(({ id, name, logo, goals }, index) => (
          <>
            <div className={styles.topScorer} key={id}>
              <div className={styles.player}>
                <Figure onClick={getTopScorerClickHandler(id)} className={styles.logo} src={logo} />
                <div onClick={getTopScorerClickHandler(id)} className={styles.name}>
                  {name}
                </div>
              </div>
              <div className={styles.goals}>
                <div className={styles.value}>{goals}</div>
              </div>
            </div>
            {index !== topScorers.length - 1 && <Separator weight={Weight.Bold} />}
          </>
        ))}
      </div>
    </div>
  );
};

export default memo(TopScorers);
