import React, { RefObject, memo } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';
import i18n from 'i18n-smart';

import { ComponentLike, Event } from 'types';
import { LoaderContainer } from 'packages/shared/components';
import styles from './styles.scss';

type Props = {
  events: Event[];
  eventComponent: ComponentLike;
  isLoading: boolean;
  onEventClick: (eventId: number) => void;
  containerRef: RefObject<HTMLDivElement>;
  className?: string;
};

const EventList = ({
  events,
  eventComponent: Event,
  isLoading,
  onEventClick,
  containerRef,
  className,
}: Props) => {
  return (
    <LoaderContainer className={classnames(className)} isLoading={isLoading}>
      {isEmpty(events) ? (
        <div className={styles.notFound}>{i18n.value('season.seasonEvents.notFound')}</div>
      ) : (
        events.map((event) => (
          <Event
            containerRef={containerRef}
            isRoundVisible
            isDateVisible
            onClick={onEventClick}
            key={event.id}
            event={event}
          />
        ))
      )}
    </LoaderContainer>
  );
};
export default memo(EventList);
