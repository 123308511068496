import getPath from './getPath';

export const getStatus = (state) => {
  return getPath(state).status;
};

export const getIsResultsLoading = (state) => {
  return getStatus(state).isResultsLoading;
};

export const getHasNextResults = (state) => {
  return getStatus(state).hasNextResults;
};

export const getNextOffset = (state) => {
  return getStatus(state).nextOffset;
};

export const getScrollTop = (state) => {
  return getStatus(state).scrollTop;
};
