import React, { memo, useCallback } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { TopPlayers as TopPlayersModule } from 'packages/shared/modules';
import { TopPlayer } from 'packages/season-top-board/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import connect from './connect';
import styles from './styles.scss';

type Props = {
  topPlayersActions: ActionCreatorsMapObject;
  players?: TopPlayer[];
  isLoading: boolean;
};

const TopPlayers = ({ players, topPlayersActions, isLoading }: Props) => {
  const { appNavigate } = useNavigation();
  const { teamId, seasonId, tournamentId } = useParams();

  const handleUpdate = useCallback(
    (sortingParameter) => {
      topPlayersActions.loadTopPlayers({ sortingParameter, teamId, seasonId });
    },
    [teamId, seasonId],
  );

  const handleNavigationLinkClick = useCallback(() => {
    if (tournamentId && seasonId && teamId) {
      appNavigate(DesktopPage.FootballTeamPlayerStatistics, {
        uniqueTournamentId: tournamentId,
        seasonId,
        teamId,
      });
    }
  }, [tournamentId, seasonId, seasonId]);

  const handleRowClick = useCallback((playerId: number) => {
    appNavigate(DesktopPage.FootballPlayer, { playerId });
  }, []);

  return (
    <TopPlayersModule
      players={players}
      onUpdate={handleUpdate}
      className={styles.tabsContainer}
      onNavigationLinkClick={handleNavigationLinkClick}
      dropSortingValueOn={seasonId}
      onRowClick={handleRowClick}
      isLoading={isLoading}
    />
  );
};

export default connect(memo(TopPlayers));
