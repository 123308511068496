import getPath from './getPath';
import { Data } from '../types';

export const getData = (state): Data => {
  return getPath(state).data;
};

export const getDetails = (state) => {
  return getData(state).details;
};

export const getTimeLines = (state) => {
  return getData(state).timeLines;
};
