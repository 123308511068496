import React from 'react';
import classnames from 'classnames';

import { Figure } from 'packages/shared/components/';
import { BaseHappening } from 'packages/event-time-line/types';

import { HappeningChip } from './components';
import { getSubstitutionPlayerNameFromHappenings } from './services';
import styles from './styles.scss';

type Props = {
  isPositionShown?: boolean;
  isStatusShown?: boolean;
  onPlayerClick?: (playerId: number) => void;
  player: {
    id: number;
    name: string;
    positionName?: string;
    logo: string;
    happenings?: BaseHappening[];
    country?: {
      logo: string;
    };
  };
};

const PlayerPreview = ({
  player,
  isPositionShown = true,
  isStatusShown = true,
  onPlayerClick,
}: Props) => {
  const { name, positionName, country, happenings, id: currentPlayerId, logo } = player;
  const substitutionPlayerName = getSubstitutionPlayerNameFromHappenings(
    currentPlayerId,
    happenings,
  );

  const getPlayerClickHandler = (playerId: number) => {
    return (event: React.MouseEvent<HTMLInputElement>) => {
      event.stopPropagation();
      onPlayerClick?.(playerId);
    };
  };

  return (
    <div onClick={getPlayerClickHandler(currentPlayerId)} className={styles.playerPreview}>
      <div className={styles.leftBlock}>
        <div
          className={classnames(styles.playerLogoContainer, {
            [styles.clickable]: !!onPlayerClick,
          })}
        >
          <Figure src={logo} className={styles.playerLogo} />
        </div>

        <div className={styles.playerNameBlock}>
          <div className={styles.playerName}>
            <div className={classnames({ [styles.clickable]: !!onPlayerClick })}>{name}</div>
            <Figure src={country?.logo} className={styles.countryLogo} />
            {!!substitutionPlayerName && (
              <div
                onClick={getPlayerClickHandler(substitutionPlayerName.playerId)}
                className={classnames(styles.substitutionPlayerName, {
                  [styles.clickable]: !!onPlayerClick,
                })}
              >
                {substitutionPlayerName.name}
              </div>
            )}
          </div>
          {isPositionShown && <div className={styles.playerPosition}>{positionName}</div>}
        </div>
      </div>

      {isStatusShown && (
        <div className={styles.statusBlock}>
          {!!happenings &&
            happenings.map((happening) => (
              <HappeningChip happening={happening} key={happening.id} />
            ))}
        </div>
      )}
    </div>
  );
};

export default React.memo(PlayerPreview);
