import * as matchSummaryActions from './actions';
import * as matchSummaryThunks from './thunks';
import * as matchSummaryConstants from './constants';
import * as matchSummarySelectors from './selectors';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  matchSummaryActions,
  matchSummaryThunks,
  matchSummaryConstants,
  matchSummarySelectors,
  createReducer,
};
