import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { statisticsActions, commonActions } from '../../../../actions';
import { Interfaces } from '../../../../types';

const defaultState: Interfaces.Statistics.Statistics.Data = null;

const reducerMap: ReducerMap<
  Interfaces.Statistics.Statistics.Data,
  Interfaces.Statistics.Statistics.Data
> = {
  [`${statisticsActions.statistics.loadStatisticsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(
    statisticsActions.statistics.loadStatisticsFailure,
    statisticsActions.statistics.loadStatisticsStart,
    statisticsActions.statistics.cleanStatisticsSuccess,
    statisticsActions.tournamentSeasons.loadTournamentSeasonsStart,
    statisticsActions.tournamentSeasons.loadTournamentSeasonsFailure,
    commonActions.cleanSummarySuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
