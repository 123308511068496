export enum RangeFilterGroupType {
  Attack = 'Attack',
  Aggression = 'Aggression',
  Goalkeeper = 'Goalkeeper',
  Passing = 'Passing',
  Other = 'Other',
}

export enum PositionType {
  Midfielder = 'Midfielder',
  Defender = 'Defender',
  Goalkeeper = 'Goalkeeper',
  Forward = 'Forward',
}

export enum PresetType {
  Summary = 'Summary',
  Attack = 'Attack',
  Aggression = 'Aggression',
  Goalkeeper = 'Goalkeeper',
  Passing = 'Passing',
  Custom = 'Custom',
}

export enum ColumnType {
  PlayerRank = 'PlayerRank',
  PlayerIcon = 'PlayerIcon',
  Player = 'Player',
  Club = 'Club',
  Goals = 'Goals',
  GoalsAndAssists = 'GoalsAndAssists',
  HeadedGoals = 'HeadedGoals',
  PenaltyGoals = 'PenaltyGoals',
  Shots = 'Shots',
  ShotsOffGoal = 'ShotsOffGoal',
  ShotsOnGoal = 'ShotsOnGoal',
  GoalConversion = 'GoalConversion',
  Offside = 'Offside',
  YellowCards = 'YellowCards',
  YellowRedCards = 'YellowRedCards',
  RedCards = 'RedCards',
  CleanSheets = 'CleanSheets',
  Assists = 'Assists',
  GamesPlayed = 'GamesPlayed',
  MinutesPlayed = 'MinutesPlayed',
}

export enum RangeType {
  MinGoals = 'minGoals',
  MaxGoals = 'maxGoals',
  MinGoalsAndAssists = 'minGoalsAndAssists',
  MaxGoalsAndAssists = 'maxGoalsAndAssists',
  MinHeadedGoals = 'minHeadedGoals',
  MaxHeadedGoals = 'maxHeadedGoals',
  MinPenaltyGoals = 'minPenaltyGoals',
  MaxPenaltyGoals = 'maxPenaltyGoals',
  MinShots = 'minShots',
  MaxShots = 'maxShots',
  MinShotsOffGoal = 'minShotsOffGoal',
  MaxShotsOffGoal = 'maxShotsOffGoal',
  MinShotsOnGoal = 'minShotsOnGoal',
  MaxShotsOnGoal = 'maxShotsOnGoal',
  MinGoalConversation = 'minGoalConversation',
  MaxGoalConversation = 'maxGoalConversation',
  MinOffside = 'minOffside',
  MaxOffside = 'maxOffside',
  MinYellowCards = 'minYellowCards',
  MaxYellowCards = 'maxYellowCards',
  MinYellowRedCards = 'minYellowRedCards',
  MaxYellowRedCards = 'maxYellowRedCards',
  MinRedCards = 'minRedCards',
  MaxRedCards = 'maxRedCards',
  MinCleanSheets = 'minCleanSheets',
  MaxCleanSheets = 'maxCleanSheets',
  MinAssists = 'minAssists',
  MaxAssists = 'maxAssists',
  MinGamesPlayed = 'minGamesPlayed',
  MaxGamesPlayed = 'maxGamesPlayed',
  MinMinutesPlayed = 'minMinutesPlayed',
  MaxMinutesPlayed = 'maxMinutesPlayed',
  MinDateOfBirth = 'minDateOfBirth',
  MaxDateOfBirth = 'maxDateOfBirth',
}

export enum PreferredFoot {
  All = 'all',
  Left = 'left',
  Right = 'right',
  Both = 'both',
}

export enum StatType {
  All = 'All',
  PerGame = 'PerGame',
  PerNinetyMinutes = 'PerNinetyMinutes',
}

export enum PlayerPosition {
  Goalkeeper = 'Goalkeeper',
  Defender = 'Defender',
  Midfielder = 'Midfielder',
  Forward = 'Forward',
}
