import { LiveEventsUpdateEventType } from '../types';

export const emitLiveEventsUpdateEvent = (
  eventType: LiveEventsUpdateEventType,
  data: Record<number, Event>,
) => {
  const customEvent = new CustomEvent(eventType, { detail: data });

  document.dispatchEvent(customEvent);
};
