import { Tournament } from 'packages/player-events/types';
import DateService from 'packages/date-az';

const today = DateService.getDate();

const getPeriodBySelectedTournament = (tournament?: Tournament): [string, string] | undefined => {
  if (!tournament) {
    return undefined;
  }

  const { endDate: lastSeasonEndDate } = tournament.seasons[0];
  const { startDate: firstSeasonStartDate } = tournament.seasons[tournament.seasons.length - 1];

  let lastSeasonParsedEndDate = lastSeasonEndDate ? DateService.parse(lastSeasonEndDate) : today;
  const firstSeasonParsedStartDate = DateService.parse(firstSeasonStartDate);

  lastSeasonParsedEndDate = lastSeasonParsedEndDate > today ? today : lastSeasonParsedEndDate;

  return [firstSeasonParsedStartDate.toISODate(), lastSeasonParsedEndDate.toISODate()];
};

export default getPeriodBySelectedTournament;
