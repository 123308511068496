import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  isButtonLabelDisabled?: boolean;
};

const Header = ({ isButtonLabelDisabled = false }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Skeleton width="100%" maxWidth={126} marginRight={12} variant="rectangular" height={14} />
      <Skeleton width="100%" maxWidth={160} marginRight={12} variant="rectangular" height={32} />
      <Skeleton
        minWidth={32}
        height={32}
        width={isButtonLabelDisabled ? 32 : 126}
        variant="rectangular"
      />
    </div>
  );
};

export default memo(Header);
