import {
  FavoritesLeaguesTableIndex,
  FavoritesEventsTableIndex,
  FavoritesTeamsTableIndex,
  FavoritesTableIndex,
  Tables,
} from './types';

const dbName = 'az-score';
const dbVersion = 5;

const schema = {
  [Tables.DEFAULT_TABLE]: ``,
  [Tables.REST_CACHE_TABLE]: ``,
  [Tables.USER_SETTINGS]: ``,
  // eslint-disable-next-line max-len
  [Tables.FAVORITES_TABLE]: `&${FavoritesTableIndex.Id}, ${FavoritesTableIndex.Date}, ${FavoritesTableIndex.UniqueTournamentId}, ${FavoritesTableIndex.HomeTeamId}, ${FavoritesTableIndex.AwayTeamId}, ${FavoritesTableIndex.CategoryId}, ${FavoritesTableIndex.CategoryIdByDate}, ${FavoritesTableIndex.UniqueTournamentIdByDate}`,
  // eslint-disable-next-line max-len
  [Tables.FAVORITE_LEAGUES]: `&${FavoritesLeaguesTableIndex.UniqueTournamentId}, ${FavoritesLeaguesTableIndex.OrderNumber}`,
  [Tables.FAVORITE_EVENTS]: `&${FavoritesEventsTableIndex.Id}, ${FavoritesEventsTableIndex.Date}`,
  [Tables.FAVORITE_TEAMS]: `&${FavoritesTeamsTableIndex.Id}, ${FavoritesTeamsTableIndex.OrderNumber}`,
};

const namespace = (namespace?: string) => {
  return namespace || Tables.DEFAULT_TABLE;
};

export { Tables, dbName, dbVersion, namespace, schema as default };
