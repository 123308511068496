import { RefObject, useEffect, useState } from 'react';

const useHeaderIntersection = (headerContainerRef: RefObject<HTMLDivElement>) => {
  const [isMiniHeaderShown, setIsMiniHeaderShown] = useState(false);

  useEffect(() => {
    const eventHeaderObserver = new IntersectionObserver(
      ([{ intersectionRatio }]) => {
        setIsMiniHeaderShown(intersectionRatio < 0.1);
      },
      {
        threshold: [0, 0.1],
      },
    );

    if (headerContainerRef.current) {
      eventHeaderObserver.observe(headerContainerRef.current);
    }

    return () => eventHeaderObserver.disconnect();
  }, []);

  return isMiniHeaderShown;
};

export default useHeaderIntersection;
