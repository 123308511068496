import Dexie from 'dexie';

import { keyValueDatabase } from 'packages/storage';
import { Tables } from 'configure/storage/types';
import { Tournament } from 'types';

import {
  updateFavoriteLeaguesStart,
  updateFavoriteLeaguesFailure,
  updateFavoriteLeaguesSuccess,
} from '../actions';
import { injectOrderNumberByIndex } from '../services';

const resetLeagues = (leagues: Tournament[]) => async (dispatch) => {
  try {
    dispatch(updateFavoriteLeaguesStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoriteLeaguesModel = dexieInstance.table(Tables.FAVORITE_LEAGUES);

    const orderedLeagues = injectOrderNumberByIndex(leagues);

    await favoriteLeaguesModel.clear();
    await favoriteLeaguesModel.bulkAdd(orderedLeagues);

    dispatch(updateFavoriteLeaguesSuccess(leagues));
  } catch (e) {
    dispatch(updateFavoriteLeaguesFailure(e));
  }
};

export default resetLeagues;
