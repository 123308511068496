import React, { memo, MouseEvent, useCallback, useMemo } from 'react';
import { SortableElement } from 'react-sortable-hoc';
import classnames from 'classnames';

import { Figure, Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import { DraggableItemProps } from '../../../../types';
import { DraggableHandler } from '../../../../components';
import { getLiveMatchesCount } from './services';
import connect from './connect';
import styles from './styles.scss';

const TournamentDraggableItem = SortableElement(
  ({ activeDate, item, onClick, onDelete, useHoverEffect = false }: DraggableItemProps) => {
    const { uniqueTournamentName, logo, countMatches, countLiveMatches } = item;

    const liveMatchesCount = useMemo(
      () => getLiveMatchesCount(countMatches, countLiveMatches, activeDate),
      [countMatches, countLiveMatches, activeDate],
    );

    const handleTournamentClick = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onClick(item);
      },
      [item],
    );

    const handleTournamentDelete = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        onDelete(item);
      },
      [item],
    );

    return (
      <div
        onClick={handleTournamentClick}
        className={classnames(styles.draggableItem, { [styles.hoverable]: useHoverEffect })}
      >
        <Figure src={logo} className={styles.logo} />
        <span className={styles.name}>{uniqueTournamentName}</span>

        <span className={styles.count}>
          {!!liveMatchesCount && <span className={styles.live}>{liveMatchesCount}</span>}
          {!!liveMatchesCount && !!countMatches && <>/</>}
          {!!countMatches && <>{countMatches}</>}
        </span>

        {useHoverEffect && <div className={styles.gradient} />}

        <div className={styles.controls}>
          <DraggableHandler useHoverEffect={useHoverEffect} />
          <Icon id={IconType.Cross} onClick={handleTournamentDelete} className={styles.delete} />
        </div>
      </div>
    );
  },
);

export default connect(memo(TournamentDraggableItem));
