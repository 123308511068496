import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Country = () => {
  return (
    <div className={styles.country}>
      <Skeleton width={16} height={12} marginRight={8} variant="rectangular" />
      <Skeleton width={60} height={12} variant="rectangular" />
    </div>
  );
};

export default memo(Country);
