import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const clearSuccess = createAction(`${NAMESPACE}CLEAR_SUCCESS`);

export const updateCountSuccess = createAction(`${NAMESPACE}UPDATE_COUNT_SUCCESS`);

export const updatePaginationSuccess = createAction(`${NAMESPACE}UPDATE_PAGINATION_SUCCESS`);

export const updateActiveFilterSuccess = createAction(`${NAMESPACE}UPDATE_ACTIVE_FILTER_SUCCESS`);
