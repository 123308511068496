import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { statisticsThunks, statisticsSelectors } from 'packages/player-summary';

const mapStateToProps = (state) => {
  return {
    mobileFilter: statisticsSelectors.mobileFilter.getData(state),
    tournamentSeasons: statisticsSelectors.tournamentSeasons.getData(state),
    isMobileFilterLoading: statisticsSelectors.mobileFilter.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mobileFilterActions: bindActionCreators(statisticsThunks.mobileFilter, dispatch),
    tournamentSeasonsActions: bindActionCreators(statisticsThunks.tournamentSeasons, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
