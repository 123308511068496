import React, { memo } from 'react';

import { Pagination } from 'packages/shared/components';
import { TournamentView } from 'packages/events/types';
import { PaginationParams } from 'packages/shared/components/Pagination/types';
import { YearItem } from 'packages/shared/components/Calendar/types';
import { EventActiveFilters, Season, Tournament } from 'packages/team-events/types';

import { EventList, EventsFilter } from './components';
import styles from './styles.scss';

type Props = {
  onPeriodChange: (period: [string, string]) => void;
  onYearClick: (year: YearItem) => void;
  onPaginationChange: (params: PaginationParams) => void;
  onFilterUpdate: (
    period: [string, string],
    selectedSeasons: Season[],
    tournament?: Tournament,
  ) => void;
  onEventClick: (eventId: number) => void;
  tournamentFilters: Tournament[];
  activeFilters: EventActiveFilters;
  eventsCount: number;
  events: TournamentView[];
  isMoreEventsLoading: boolean;
  limit: number;
  isInitialLoading: boolean;
};

const TeamEvents = ({
  isMoreEventsLoading,
  tournamentFilters,
  activeFilters,
  events,
  eventsCount,
  limit,
  onPeriodChange,
  onYearClick,
  onPaginationChange,
  onFilterUpdate,
  onEventClick,
  isInitialLoading,
}: Props) => (
  <>
    <EventsFilter
      onYearClick={onYearClick}
      onPeriodChange={onPeriodChange}
      onFilterUpdate={onFilterUpdate}
      activeFilters={activeFilters}
      eventsCount={eventsCount}
      tournaments={tournamentFilters}
      isLoading={isInitialLoading}
    />
    <EventList
      eventsCount={limit}
      isInitialLoading={isInitialLoading}
      onEventClick={onEventClick}
      isLoading={isMoreEventsLoading}
      events={events}
    />
    <Pagination
      isLoading={isInitialLoading}
      resultsLabel="common.pagination.gamesLabel"
      onPaginationChange={onPaginationChange}
      className={styles.paginationContainer}
      useRightAlignment
      amountOfItems={eventsCount}
      pageSize={limit}
      isLimitSelectable
    />
  </>
);

export default memo(TeamEvents);
