import DateService from 'packages/date-az';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const setNextDate = () => (dispatch, getState) => {
  const state = getState();
  const activeDate = selectors.getActiveDate(state);
  const nextDate = DateService.getNextDayIso(activeDate);

  dispatch(actions.setNextDate({ activeDate: nextDate }));
};
