import React, { RefObject, useRef } from 'react';
import { Transition } from 'react-transition-group';
import classnames from 'classnames';

import { Event, Tournament } from 'types';
import { TabView } from 'packages/shared/components/Tabs/types';
import { EventSeries as EventSeriesType } from 'packages/event-details/types';
import { FlexiblePopover, Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';
import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';
import { CategoryModal } from 'packages/categories-ui';

import { useHeaderIntersection } from './hooks';
import { MINI_HEADER_TRANSITION_STYLES, MINI_HEADER_HEIGHT } from './constants';
import { EventDetailsHeader, EventDetailsMiniHeader, EventDetailsContent } from './components';
import styles from './styles.scss';

type Props = {
  event: Event;
  onClose: () => void;
  onBack: () => void;
  eventSeries: EventSeriesType;
  onTournamentClick: (tournament: Tournament) => void;
  onFirstLegClick: (event?: Event) => void;
  eventDetailsTabs: TabView[];
  className?: string;
  popoverInvokeTrigger?: PopoverInvokeTrigger;
  selectedCategoryId?: string;
  onCloseModal?: () => void;
  onCategoryClick?: (id: string) => void;
};

const EventDetailsCard = ({
  event,
  onClose,
  onBack,
  eventSeries,
  onTournamentClick,
  onFirstLegClick,
  eventDetailsTabs,
  className,
  popoverInvokeTrigger,
  selectedCategoryId,
  onCloseModal,
  onCategoryClick,
}: Props) => {
  const headerContainerRef: RefObject<HTMLDivElement> = useRef<HTMLDivElement>(null);
  const isMiniHeaderShown = useHeaderIntersection(headerContainerRef);

  return (
    <div className={classnames(styles.eventDetailsCard, className)}>
      <Icon id={IconType.ArrowExtended} onClick={onBack} className={styles.backButton} />
      <Icon id={IconType.Cross} className={styles.closeButton} onClick={onClose} />

      {selectedCategoryId && (
        <FlexiblePopover
          popoverComponent={CategoryModal.Mobile}
          justify={PopoverJustify.CenterStart}
          align={PopoverAlign.Top}
          popoverProps={{
            selectedCategoryId,
          }}
          onClose={onCloseModal}
          isModal
          isOpenInitially
          closeOnClickOutside
          className={styles.modalWindow}
        />
      )}

      <div ref={headerContainerRef} className={styles.header}>
        <EventDetailsHeader
          onCategoryClick={onCategoryClick}
          popoverInvokeTrigger={popoverInvokeTrigger}
          event={event}
          eventSeries={eventSeries}
          onFirstLegClick={onFirstLegClick}
          onTournamentClick={onTournamentClick}
        />
      </div>

      <div className={styles.contentBlock}>
        <div className={styles.miniHeaderStickyContainer}>
          <Transition in={isMiniHeaderShown} timeout={0}>
            {(state) => (
              <EventDetailsMiniHeader event={event} style={MINI_HEADER_TRANSITION_STYLES[state]} />
            )}
          </Transition>
        </div>
        <EventDetailsContent stickyHeaderTopOffset={MINI_HEADER_HEIGHT} tabs={eventDetailsTabs} />
      </div>
    </div>
  );
};

export default React.memo(EventDetailsCard);
