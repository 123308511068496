import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const DetailsSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.info}>
          <div className={styles.stadium}>
            <div>
              <Skeleton height={35} width={200} variant="rectangular" />
              <Skeleton height={15} width={300} marginTop={18} variant="rectangular" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(DetailsSkeleton);
