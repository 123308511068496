import React from 'react';
import PropTypes from 'prop-types';

import BaseWrapper from './views/BaseWrapper';
import AnimatedWrapper from './views/AnimatedWrapper';

const ModalWrapper = ({ isUsingAnimation, children, ...props }) => {
  return isUsingAnimation ? (
    <AnimatedWrapper {...props}>{children}</AnimatedWrapper>
  ) : (
    <BaseWrapper {...props}>{children}</BaseWrapper>
  );
};

ModalWrapper.propTypes = {
  isUsingAnimation: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

ModalWrapper.defaultProps = {
  isUsingAnimation: true,
};

export default React.memo(ModalWrapper);
