import React from 'react';

import styles from './styles.scss';

type Props = {
  value: string | number;
};

const TooltipPopover = ({ value }: Props) => {
  return <div className={styles.tooltip}>{value}</div>;
};

export default React.memo(TooltipPopover);
