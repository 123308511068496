import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isEqual from 'lodash.isequal';

class Portal extends React.Component {
  constructor(props) {
    super(props);

    this.lastPosition = null;
    this.positionNode = React.createRef();

    this.portalRoot = document.getElementById('portal');

    this.wrapperNode = document.createElement('div');
    this.wrapperNode.setAttribute('class', 'portal-wrapper');
  }

  componentDidMount() {
    const { isSavePosition } = this.props;

    this.updateWrapperPosition();

    this.portalRoot.appendChild(this.wrapperNode);

    if (isSavePosition) {
      window.addEventListener('resize', this.onResizeWindow);
      window.addEventListener('scroll', this.onScrollWindow, true);
    }
  }

  componentWillUnmount() {
    const { isSavePosition } = this.props;

    if (isSavePosition) {
      window.removeEventListener('resize', this.onResizeWindow);
      window.removeEventListener('scroll', this.onScrollWindow, true);
    }

    this.portalRoot.removeChild(this.wrapperNode);
  }

  onScrollWindow = () => {
    this.updateWrapperPosition();
  };

  onResizeWindow = () => {
    this.updateWrapperPosition();
  };

  updateWrapperPosition() {
    const { zIndex, onUpdatePosition, isRelative } = this.props;

    const positionNodeRect = this.positionNode.current.getBoundingClientRect();
    const position = { x: positionNodeRect.x, y: positionNodeRect.y };
    const offsetTop = positionNodeRect.top + window.pageYOffset;
    const offsetLeft = positionNodeRect.left + window.pageXOffset;

    if (isRelative) {
      this.wrapperNode.style.position = 'absolute';
      this.wrapperNode.style.top = `${offsetTop}px`;
      this.wrapperNode.style.left = `${offsetLeft}px`;
      this.wrapperNode.style.width = `${positionNodeRect.width}px`;
      this.wrapperNode.style.zIndex = zIndex;
    }

    if (!isEqual(this.lastPosition, position)) {
      this.lastPosition = position;
      onUpdatePosition(position);
    }
  }

  render() {
    const { children, className } = this.props;

    return (
      <div className={classNames('portal', className)} ref={this.positionNode}>
        {ReactDOM.createPortal(children, this.wrapperNode)}
      </div>
    );
  }
}

Portal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  isSavePosition: PropTypes.bool,
  zIndex: PropTypes.number,
  className: PropTypes.string,
  onUpdatePosition: PropTypes.func,
  isRelative: PropTypes.bool,
};

Portal.defaultProps = {
  zIndex: 1,
  children: [],
  className: '',
  isSavePosition: false,
  onUpdatePosition: () => {},
  isRelative: true,
};

export default Portal;
