import React from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { TeamsParameter, TopTeam } from 'packages/season-top-board/types';
import { Tooltip } from 'packages/shared/components';

import { ParameterHeader, TeamCell } from '../../../../../../../components';

export default (): Column<TopTeam>[] => [
  {
    id: 'team',
    Header: i18n.value('common.tableParameter.short.team'),
    Cell: ({ value }) => <TeamCell team={value} />,
    accessor: ({ team }) => team,
    cellStyle: { paddingLeft: 16 },
    headerStyle: { textAlign: 'left', padding: '8px 8px 8px 16px' },
    width: 58,
  },
  {
    id: 'gamesPlayed',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${TeamsParameter.GamesPlayed}`)}>
        <>{i18n.value(`common.tableParameter.short.${TeamsParameter.GamesPlayed}`)}</>
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.gamesPlayed,
    width: 10,
  },
  {
    id: 'goalAttempts',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${TeamsParameter.GoalAttempts}`)}>
        <ParameterHeader parameter={TeamsParameter.GoalAttempts} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => Number(statistics.goalAttempts).toFixed(1),
    width: 15,
  },
  {
    id: 'shotsOnGoal',
    Header: (
      <Tooltip
        value={i18n.value(`common.tableParameter.extended.${TeamsParameter.ShotsOnGoalPerGame}`)}
      >
        <ParameterHeader parameter={TeamsParameter.ShotsOnGoalPerGame} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => Number(statistics.shotsOnGoal).toFixed(1),
    width: 15,
  },
  {
    id: 'ballPossession',
    Header: (
      <Tooltip
        value={i18n.value(`common.tableParameter.extended.${TeamsParameter.BallPossession}`)}
      >
        <ParameterHeader parameter={TeamsParameter.BallPossession} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => Number(statistics.ballPossession).toFixed(1),
    width: 15,
  },
];
