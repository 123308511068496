import * as eventSummaryActions from './actions';
import * as eventSummaryThunks from './thunks';
import * as eventSummaryConstants from './constants';
import * as eventSummarySelectors from './selectors';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  eventSummaryActions,
  eventSummaryThunks,
  eventSummaryConstants,
  eventSummarySelectors,
  createReducer,
};
