import { GoalLabel } from 'packages/shared/components';
import { PenaltyResult } from 'packages/event-summary/types';
import { GoalHappeningViewTypes } from 'packages/shared/components/GoalLabel/types';
import { DefaultHappeningComponentConfig } from '../../../types';

const getPenaltyHappeningComponentConfig = (
  penaltyResult: PenaltyResult,
  isReversed: boolean,
): DefaultHappeningComponentConfig => {
  const { isGoalScored, isPenaltyKicked, scorerName, goalHappening } = penaltyResult;

  if (isGoalScored) {
    return {
      component: GoalLabel,
      props: {
        type: GoalHappeningViewTypes.PenaltyGoal,
        scorerName,
        scorerId: goalHappening?.players?.find(({ shortName }) => shortName === scorerName)
          ?.playerId,
        isIconFirst: isReversed,
      },
    };
  }

  return {
    component: GoalLabel,
    props: {
      scorerName,
      scorerId: goalHappening?.players?.find(({ shortName }) => shortName === scorerName)?.playerId,
      isIconFirst: isReversed,
      type:
        isPenaltyKicked === true
          ? GoalHappeningViewTypes.PenaltyMissed
          : GoalHappeningViewTypes.NotKicked,
    },
  };
};

export default getPenaltyHappeningComponentConfig;
