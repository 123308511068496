import React, { memo, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed/lib';
import { useSearchParams } from 'react-router-dom';

import { Event } from 'types';
import { SearchQuery } from 'router/types';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { HeadToHead } from 'packages/event-head-to-head/types';
import { LoaderContainer, Separator, Tabs, TeamLabel } from 'packages/shared/components';
import { Type } from 'packages/shared/components/Separator/types';
import DateService from 'packages/date-az';

import { TeamMatches } from './components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  cleanEventHeadToHead: () => void;
  eventHeadToHeadActions: ActionCreatorsMapObject;
  activeEvent: Event;
  eventHeadToHead: HeadToHead;
  isLoading: boolean;
};

const EventHeadToHead = ({
  eventHeadToHead,
  eventHeadToHeadActions,
  activeEvent,
  isLoading,
  cleanEventHeadToHead,
}: Props) => {
  const [homeTeam, awayTeam] = activeEvent.teams;
  const currentDate = DateService.getDate();

  const [, setSearchParams] = useSearchParams();

  useEffect(() => {
    eventHeadToHeadActions.loadEventHeadToHead(homeTeam, awayTeam, activeEvent.scheduled);

    return () => {
      cleanEventHeadToHead();
    };
  }, [activeEvent]);

  const data = eventHeadToHead.map((item, index) => {
    const { id, props } = item;
    const isHeadToHeadTab = index === 1;

    return {
      id,
      name: isHeadToHeadTab ? i18n.value('common.short.h2h') : undefined,
      contentComponentProps: {
        onEventClick: (eventCardId) => {
          setSearchParams({ [SearchQuery.EventCardId]: eventCardId });
        },
        currentYear: currentDate.year.toString(),
        ...props,
      },
      activeByDefault: isHeadToHeadTab,
      contentComponent: TeamMatches,
      tabComponent: isHeadToHeadTab ? undefined : TeamLabel,
      tabComponentProps: { ...props, isLogoFirst: true },
      isDefault: isHeadToHeadTab,
      hasMaxWidth: !isHeadToHeadTab,
    };
  });

  return (
    <LoaderContainer isLoading={isLoading} className={styles.loader}>
      {!isEmpty(data) ? (
        <Tabs isNavigationWidthLimited theme={NavigationTheme.Button} tabs={data} />
      ) : (
        <div>{i18n.value('common.notFound')}</div>
      )}

      <Separator type={Type.Block} />
    </LoaderContainer>
  );
};

export default connect(memo(EventHeadToHead));
