import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { Grid } from 'modules/shared/layouts';
import { PopularTournaments } from 'packages/favorites-ui';
import { CategoryList } from 'packages/categories-ui';
import { GridColumnId } from 'types';

const Categories = () => {
  return (
    <Grid.SingleColumn isSecondaryOpen={false}>
      <div key={GridColumnId.Primary}>
        <PopularTournaments.Tablet />
        <CategoryList.Tablet title={i18n.value('categories.title')} />
      </div>
      <></>
    </Grid.SingleColumn>
  );
};

export default memo(Categories);
