import { Interfaces } from '../../../types';
import getPath from './getPath';

export const getData = (state): Interfaces.Statistics.TournamentSeasons.Data => {
  return getPath(state).data;
};

export const getSelectedSeasonId = (state): number | undefined => {
  return getData(state)?.selectedSeasonId;
};
