export enum EventStatus {
  NotStarted = 'NOT_STARTED',
  Cancelled = 'CANCELLED',
  Postponed = 'POSTPONED',
  FirstHalf = 'FIRST_HALF',
  Halftime = 'HALFTIME',
  SecondHalf = 'SECOND_HALF',
  AwaitingExtraTime = 'AWAITING_EXTRA_TIME',
  Overtime = 'OVERTIME',
  SecondExtra = 'SECOND_EXTRA',
  ExtraTimeHalfTime = 'EXTRA_TIME_HALFTIME',
  AwaitingPenalties = 'AWAITING_PENALTIES',
  Penalties = 'PENALTIES',
  Suspended = 'SUSPENDED',
  Ended = 'ENDED',
  AfterPenalties = 'AFTER_PENALTIES',
  AfterExtraTime = 'AFTER_EXTRA_TIME',
  Unknown = 'UNKNOWN',
}
