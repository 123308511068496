import i18n from 'i18n-smart';

import DateService from 'packages/date-az';
import { DATE_FORMATS } from 'packages/date-az/consts';

interface Params {
  startDate?: string;
  endDate?: string;
}

const formatEndDate = ({ startDate, endDate }: Params): string | undefined => {
  if (!startDate && !endDate) {
    return undefined;
  }

  if (endDate) {
    return DateService.getFormattedDateViewFromString(endDate, DATE_FORMATS.FULL_DATE);
  }

  return i18n.value('player.playerSummary.transferHistory.column.present');
};

export default formatEndDate;
