import getPath from './getState';
import { Status } from '../types';

const getStatus = (state): Status => {
  return getPath(state).status;
};

export const getIsSeasonDetailsLoading = (state): boolean => {
  return getStatus(state).isSeasonDetailsLoading;
};
