exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._KiX6undc{display:flex;flex-direction:column;justify-content:space-between;padding:16px}._KiX6undc .__XbgD3PB{height:46px;display:flex;justify-content:space-evenly;align-items:center}._KiX6undc .__XbgD3PB._1SOcjyht{flex-direction:row-reverse}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Lineup/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,qBAAsB,CACtB,6BAA8B,CAE9B,YCRU,CDEd,sBASM,WAAY,CAEZ,YAAa,CACb,4BAA6B,CAC7B,kBAAmB,CAbzB,gCAgBQ,0BAA2B","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .lineup {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n\n    padding: $padding;\n\n    .lineup-row {\n      height: 46px;\n\n      display: flex;\n      justify-content: space-evenly;\n      align-items: center;\n\n      &.reverse {\n        flex-direction: row-reverse;\n      }\n    }\n\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"lineup": "_KiX6undc",
	"lineup-row": "__XbgD3PB",
	"lineupRow": "__XbgD3PB",
	"reverse": "_1SOcjyht"
};