import * as stadiumDetailsActions from './actions';
import * as stadiumDetailsSelectors from './selectors';
import * as stadiumDetailsConstants from './constants';
import * as stadiumDetailsThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  stadiumDetailsActions,
  stadiumDetailsConstants,
  stadiumDetailsThunks,
  stadiumDetailsSelectors,
  createReducer,
};
