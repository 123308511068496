import React from 'react';
import i18n from 'i18n-smart';

import { CloseButton } from './components';
import styles from './styles.scss';

type Props = {
  onClose: () => void;
};

const Header = ({ onClose }: Props) => {
  return (
    <div className={styles.header}>
      <span className={styles.title}>{i18n.value('season.teamStatistics.customFilter.title')}</span>
      <CloseButton onClick={onClose} />
    </div>
  );
};

export default Header;
