import React from 'react';

import { getScrollParent } from 'packages/utils/Scroll.utils';

interface ScrollParameters {
  tableRef: React.RefObject<HTMLDivElement>;
}

const scrollToTable = ({ tableRef }: ScrollParameters) => {
  const scrollContainerElement = getScrollParent(tableRef.current);
  const { scrollTop: scrolledValue } = scrollContainerElement || {};
  const [tableHeader] = tableRef.current?.getElementsByTagName('thead') || [];
  const { top: containerTopOffset = 0 } = scrollContainerElement.getBoundingClientRect() || {};
  const { top: tableTopOffset = 0 } = tableRef.current?.getBoundingClientRect() || {};
  const tableHeaderHeight = tableHeader?.clientHeight || 0;
  const scrollOffset = tableTopOffset - containerTopOffset + scrolledValue - tableHeaderHeight;

  scrollContainerElement.scrollTo({
    left: 0,
    top: scrollOffset,
    behavior: 'smooth',
  });
};

export default scrollToTable;
