import React, { memo } from 'react';

import { Figure } from 'packages/shared/components';
import { Player } from 'packages/season-player-statistics/types';

import styles from './styles.scss';

type Props = {
  player: Player;
};

const PlayerIcon = ({ player }: Props) => {
  const { logo } = player;

  return (
    <div className={styles.playerCell}>
      <div className={styles.playerLogoContainer}>
        <Figure src={logo} className={styles.playerLogo} />
      </div>
    </div>
  );
};

export default memo(PlayerIcon);
