exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3D2DLryS{position:absolute;height:100%;width:100%;overflow:hidden}._2jXHrTRI{margin-left:auto}._3GXJC8b4{overflow-y:scroll;scrollbar-width:none;-ms-overflow-style:none}._3GXJC8b4::-webkit-scrollbar{display:none;background:transparent;width:0;height:0}._3GXJC8b4 ._3c3d_pjx:first-of-type{border-top:unset}._3GXJC8b4 ._13yxV8qj{z-index:4}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/modules/PlayerStatisticsTable/views/Mobile/components/LoadingSkeleton/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/scroll.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,iBAAkB,CAClB,WAAY,CACZ,UAAW,CACX,eAAgB,CALpB,WASI,gBAAiB,CATrB,WCAI,iBAAkB,CAKpB,oBAAqB,CACrB,uBAAwB,CDN1B,8BCUI,YAAa,CACb,sBAAuB,CACvB,OAAQ,CACR,QAAS,CDbb,oCAiBQ,gBAAiB,CAjBzB,sBAsBM,SAAU","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .loader-container {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n    overflow: hidden;\n  }\n\n  .country-logo {\n    margin-left: auto;\n  }\n\n  .table {\n    @include hidden-scroll;\n\n    .table-row {\n      &:first-of-type {\n        border-top: unset;\n      }\n    }\n\n    .header {\n      z-index: 4;\n    }\n  }\n}\n","@mixin hidden-scroll($axis: y) {\n  @if $axis == y {\n    overflow-y: scroll;\n  } @else {\n    overflow-x: scroll;\n  }\n\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* Internet Explorer 10+ */\n\n  &::-webkit-scrollbar {\n    /* WebKit */\n    display: none;\n    background: transparent;\n    width: 0;\n    height: 0;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"loader-container": "_3D2DLryS",
	"loaderContainer": "_3D2DLryS",
	"country-logo": "_2jXHrTRI",
	"countryLogo": "_2jXHrTRI",
	"table": "_3GXJC8b4",
	"table-row": "_3c3d_pjx",
	"tableRow": "_3c3d_pjx",
	"header": "_13yxV8qj"
};