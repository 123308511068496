import { OrderingKey, RangeParameter } from 'packages/rest/api/SeasonPlayerStatistics/types';
import { InputValidationType } from 'packages/shared/components/Input/types';
import { POSITION_BY_TYPE } from 'packages/season-player-statistics/constants';
import { OrderType } from 'types';

import { Interfaces, Enums } from '../types';

export const NAMESPACE = 'TEAM-PLAYERS-STATISTICS::';
export const CUSTOM_PRESET_SESSION_KEY = 'team-player-statistics-custom-preset';
export const INITIAL_PLAYERS_LIMIT = 30;
export const DEFAULT_PLAYERS_LIMIT = 30;

export const COLUMN_TEMPLATE_BY_TYPE: Record<Enums.ColumnType, Interfaces.CustomFilter.Column> = {
  [Enums.ColumnType.PlayerRank]: {
    type: Enums.ColumnType.PlayerRank,
    columnKey: 'player',
    isDisabled: true,
  },
  [Enums.ColumnType.PlayerIcon]: {
    type: Enums.ColumnType.PlayerIcon,
    columnKey: 'player',
    isDisabled: true,
  },
  [Enums.ColumnType.Player]: {
    type: Enums.ColumnType.Player,
    columnKey: 'player',
    isDisabled: true,
  },
  [Enums.ColumnType.Club]: {
    type: Enums.ColumnType.Club,
    columnKey: 'team',
    isDisabled: true,
  },
  [Enums.ColumnType.Goals]: {
    type: Enums.ColumnType.Goals,
    columnKey: 'goals',
  },
  [Enums.ColumnType.GoalsAndAssists]: {
    type: Enums.ColumnType.GoalsAndAssists,
    columnKey: 'goalPoints',
  },
  [Enums.ColumnType.HeadedGoals]: {
    type: Enums.ColumnType.HeadedGoals,
    columnKey: 'goalsByHeader',
  },
  [Enums.ColumnType.PenaltyGoals]: {
    type: Enums.ColumnType.PenaltyGoals,
    columnKey: 'penalties',
  },
  [Enums.ColumnType.Shots]: {
    type: Enums.ColumnType.Shots,
    columnKey: 'totalShots',
  },
  [Enums.ColumnType.ShotsOffGoal]: {
    type: Enums.ColumnType.ShotsOffGoal,
    columnKey: 'shotsOffGoal',
  },
  [Enums.ColumnType.ShotsOnGoal]: {
    type: Enums.ColumnType.ShotsOnGoal,
    columnKey: 'shotsOnGoal',
  },
  [Enums.ColumnType.GoalConversion]: {
    type: Enums.ColumnType.GoalConversion,
    columnKey: 'goalConversion',
  },
  [Enums.ColumnType.Offside]: {
    type: Enums.ColumnType.Offside,
    columnKey: 'offside',
  },
  [Enums.ColumnType.YellowCards]: {
    type: Enums.ColumnType.YellowCards,
    columnKey: 'yellowCards',
  },
  [Enums.ColumnType.YellowRedCards]: {
    type: Enums.ColumnType.YellowRedCards,
    columnKey: 'yellowRedCards',
  },
  [Enums.ColumnType.RedCards]: {
    type: Enums.ColumnType.RedCards,
    columnKey: 'redCards',
  },
  [Enums.ColumnType.CleanSheets]: {
    type: Enums.ColumnType.CleanSheets,
    columnKey: 'cleanSheet',
  },
  [Enums.ColumnType.Assists]: {
    type: Enums.ColumnType.Assists,
    columnKey: 'assists',
  },
  [Enums.ColumnType.GamesPlayed]: {
    type: Enums.ColumnType.GamesPlayed,
    columnKey: 'gamesPlayed',
    validationType: InputValidationType.Integer,
  },
  [Enums.ColumnType.MinutesPlayed]: {
    type: Enums.ColumnType.MinutesPlayed,
    columnKey: 'minutesPlayed',
    validationType: InputValidationType.Integer,
  },
};

export const RANGE_FILTER_PARAMETER_BY_STAT_TYPE = {
  [Enums.StatType.All]: {
    [Enums.RangeType.MinGoals]: RangeParameter.MinGoals,
    [Enums.RangeType.MaxGoals]: RangeParameter.MaxGoals,
    [Enums.RangeType.MinGoalsAndAssists]: RangeParameter.MinGoalsAndAssists,
    [Enums.RangeType.MaxGoalsAndAssists]: RangeParameter.MaxGoalsAndAssists,
    [Enums.RangeType.MinHeadedGoals]: RangeParameter.MinHeadedGoals,
    [Enums.RangeType.MaxHeadedGoals]: RangeParameter.MaxHeadedGoals,
    [Enums.RangeType.MinPenaltyGoals]: RangeParameter.MinPenaltyGoals,
    [Enums.RangeType.MaxPenaltyGoals]: RangeParameter.MaxPenaltyGoals,
    [Enums.RangeType.MinShots]: RangeParameter.MinShots,
    [Enums.RangeType.MaxShots]: RangeParameter.MaxShots,
    [Enums.RangeType.MinShotsOffGoal]: RangeParameter.MinShotsOffGoal,
    [Enums.RangeType.MaxShotsOffGoal]: RangeParameter.MaxShotsOffGoal,
    [Enums.RangeType.MinShotsOnGoal]: RangeParameter.MinShotsOnGoal,
    [Enums.RangeType.MaxShotsOnGoal]: RangeParameter.MaxShotsOnGoal,
    [Enums.RangeType.MinGoalConversation]: RangeParameter.MinGoalConversation,
    [Enums.RangeType.MaxGoalConversation]: RangeParameter.MaxGoalConversation,
    [Enums.RangeType.MinOffside]: RangeParameter.MinOffside,
    [Enums.RangeType.MaxOffside]: RangeParameter.MaxOffside,
    [Enums.RangeType.MinYellowCards]: RangeParameter.MinYellowCards,
    [Enums.RangeType.MaxYellowCards]: RangeParameter.MaxYellowCards,
    [Enums.RangeType.MinYellowRedCards]: RangeParameter.MinYellowRedCards,
    [Enums.RangeType.MaxYellowRedCards]: RangeParameter.MaxYellowRedCards,
    [Enums.RangeType.MinRedCards]: RangeParameter.MinRedCards,
    [Enums.RangeType.MaxRedCards]: RangeParameter.MaxRedCards,
    [Enums.RangeType.MinCleanSheets]: RangeParameter.MinCleanSheets,
    [Enums.RangeType.MaxCleanSheets]: RangeParameter.MaxCleanSheets,
    [Enums.RangeType.MinAssists]: RangeParameter.MinAssists,
    [Enums.RangeType.MaxAssists]: RangeParameter.MaxAssists,
    [Enums.RangeType.MinGamesPlayed]: RangeParameter.MinGamesPlayed,
    [Enums.RangeType.MaxGamesPlayed]: RangeParameter.MaxGamesPlayed,
    [Enums.RangeType.MinMinutesPlayed]: RangeParameter.MinMinutesPlayed,
    [Enums.RangeType.MaxMinutesPlayed]: RangeParameter.MaxMinutesPlayed,
    [Enums.RangeType.MinDateOfBirth]: RangeParameter.MinDateOfBirth,
    [Enums.RangeType.MaxDateOfBirth]: RangeParameter.MaxDateOfBirth,
  },
  [Enums.StatType.PerNinetyMinutes]: {
    [Enums.RangeType.MinGoals]: RangeParameter.MinGoalsPerNinety,
    [Enums.RangeType.MaxGoals]: RangeParameter.MaxGoalsPerNinety,
    [Enums.RangeType.MinGoalsAndAssists]: RangeParameter.MinGoalsAndAssistsPerNinety,
    [Enums.RangeType.MaxGoalsAndAssists]: RangeParameter.MaxGoalsAndAssistsPerNinety,
    [Enums.RangeType.MinHeadedGoals]: RangeParameter.MinHeadedGoalsPerNinety,
    [Enums.RangeType.MaxHeadedGoals]: RangeParameter.MaxHeadedGoalsPerNinety,
    [Enums.RangeType.MinPenaltyGoals]: RangeParameter.MinPenaltyGoalsPerNinety,
    [Enums.RangeType.MaxPenaltyGoals]: RangeParameter.MaxPenaltyGoalsPerNinety,
    [Enums.RangeType.MinShots]: RangeParameter.MinShotsPerNinety,
    [Enums.RangeType.MaxShots]: RangeParameter.MaxShotsPerNinety,
    [Enums.RangeType.MinShotsOffGoal]: RangeParameter.MinShotsOffGoalPerNinety,
    [Enums.RangeType.MaxShotsOffGoal]: RangeParameter.MaxShotsOffGoalPerNinety,
    [Enums.RangeType.MinShotsOnGoal]: RangeParameter.MinShotsOnGoalPerNinety,
    [Enums.RangeType.MaxShotsOnGoal]: RangeParameter.MaxShotsOnGoalPerNinety,
    [Enums.RangeType.MinGoalConversation]: RangeParameter.MinGoalConversationPerNinety,
    [Enums.RangeType.MaxGoalConversation]: RangeParameter.MaxGoalConversationPerNinety,
    [Enums.RangeType.MinOffside]: RangeParameter.MinOffsidePerNinety,
    [Enums.RangeType.MaxOffside]: RangeParameter.MaxOffsidePerNinety,
    [Enums.RangeType.MinYellowCards]: RangeParameter.MinYellowCardsPerNinety,
    [Enums.RangeType.MaxYellowCards]: RangeParameter.MaxYellowCardsPerNinety,
    [Enums.RangeType.MinYellowRedCards]: RangeParameter.MinYellowRedCardsPerNinety,
    [Enums.RangeType.MaxYellowRedCards]: RangeParameter.MaxYellowRedCardsPerNinety,
    [Enums.RangeType.MinRedCards]: RangeParameter.MinRedCardsPerNinety,
    [Enums.RangeType.MaxRedCards]: RangeParameter.MaxRedCardsPerNinety,
    [Enums.RangeType.MinCleanSheets]: RangeParameter.MinCleanSheetsPerNinety,
    [Enums.RangeType.MaxCleanSheets]: RangeParameter.MaxCleanSheetsPerNinety,
    [Enums.RangeType.MinAssists]: RangeParameter.MinAssistsPerNinety,
    [Enums.RangeType.MaxAssists]: RangeParameter.MaxAssistsPerNinety,
    [Enums.RangeType.MinGamesPlayed]: RangeParameter.MinGamesPlayed,
    [Enums.RangeType.MaxGamesPlayed]: RangeParameter.MaxGamesPlayed,
    [Enums.RangeType.MinMinutesPlayed]: RangeParameter.MinMinutesPlayed,
    [Enums.RangeType.MaxMinutesPlayed]: RangeParameter.MaxMinutesPlayed,
    [Enums.RangeType.MinDateOfBirth]: RangeParameter.MinDateOfBirth,
    [Enums.RangeType.MaxDateOfBirth]: RangeParameter.MaxDateOfBirth,
  },
  [Enums.StatType.PerGame]: {
    [Enums.RangeType.MinGoals]: RangeParameter.MinGoalsPerGame,
    [Enums.RangeType.MaxGoals]: RangeParameter.MaxGoalsPerGame,
    [Enums.RangeType.MinGoalsAndAssists]: RangeParameter.MinGoalsAndAssistsPerGame,
    [Enums.RangeType.MaxGoalsAndAssists]: RangeParameter.MaxGoalsAndAssistsPerGame,
    [Enums.RangeType.MinHeadedGoals]: RangeParameter.MinHeadedGoalsPerGame,
    [Enums.RangeType.MaxHeadedGoals]: RangeParameter.MaxHeadedGoalsPerGame,
    [Enums.RangeType.MinPenaltyGoals]: RangeParameter.MinPenaltyGoalsPerGame,
    [Enums.RangeType.MaxPenaltyGoals]: RangeParameter.MaxPenaltyGoalsPerGame,
    [Enums.RangeType.MinShots]: RangeParameter.MinShotsPerGame,
    [Enums.RangeType.MaxShots]: RangeParameter.MaxShotsPerGame,
    [Enums.RangeType.MinShotsOffGoal]: RangeParameter.MinShotsOffGoalPerGame,
    [Enums.RangeType.MaxShotsOffGoal]: RangeParameter.MaxShotsOffGoalPerGame,
    [Enums.RangeType.MinShotsOnGoal]: RangeParameter.MinShotsOnGoalPerGame,
    [Enums.RangeType.MaxShotsOnGoal]: RangeParameter.MaxShotsOnGoalPerGame,
    [Enums.RangeType.MinGoalConversation]: RangeParameter.MinGoalConversationPerGame,
    [Enums.RangeType.MaxGoalConversation]: RangeParameter.MaxGoalConversationPerGame,
    [Enums.RangeType.MinOffside]: RangeParameter.MinOffsidePerGame,
    [Enums.RangeType.MaxOffside]: RangeParameter.MaxOffsidePerGame,
    [Enums.RangeType.MinYellowCards]: RangeParameter.MinYellowCardsPerGame,
    [Enums.RangeType.MaxYellowCards]: RangeParameter.MaxYellowCardsPerGame,
    [Enums.RangeType.MinYellowRedCards]: RangeParameter.MinYellowRedCardsPerGame,
    [Enums.RangeType.MaxYellowRedCards]: RangeParameter.MaxYellowRedCardsPerGame,
    [Enums.RangeType.MinRedCards]: RangeParameter.MinRedCardsPerGame,
    [Enums.RangeType.MaxRedCards]: RangeParameter.MaxRedCardsPerGame,
    [Enums.RangeType.MinCleanSheets]: RangeParameter.MinCleanSheetsPerGame,
    [Enums.RangeType.MaxCleanSheets]: RangeParameter.MaxCleanSheetsPerGame,
    [Enums.RangeType.MinAssists]: RangeParameter.MinAssistsPerGame,
    [Enums.RangeType.MaxAssists]: RangeParameter.MaxAssistsPerGame,
    [Enums.RangeType.MinGamesPlayed]: RangeParameter.MinGamesPlayed,
    [Enums.RangeType.MaxGamesPlayed]: RangeParameter.MaxGamesPlayed,
    [Enums.RangeType.MinMinutesPlayed]: RangeParameter.MinMinutesPlayedPerGame,
    [Enums.RangeType.MaxMinutesPlayed]: RangeParameter.MaxMinutesPlayedPerGame,
    [Enums.RangeType.MinDateOfBirth]: RangeParameter.MinDateOfBirth,
    [Enums.RangeType.MaxDateOfBirth]: RangeParameter.MaxDateOfBirth,
  },
};

export const FILTER_ORDERING_BY_STAT_TYPE = {
  [Enums.StatType.All]: {
    [Enums.ColumnType.Player]: OrderingKey.PlayerName,
    [Enums.ColumnType.Goals]: OrderingKey.Goals,
    [Enums.ColumnType.GamesPlayed]: OrderingKey.GamesPlayed,
    [Enums.ColumnType.MinutesPlayed]: OrderingKey.MinutesPlayed,
    [Enums.ColumnType.HeadedGoals]: OrderingKey.HeadedGoals,
    [Enums.ColumnType.PenaltyGoals]: OrderingKey.PenaltyGoals,
    [Enums.ColumnType.GoalConversion]: OrderingKey.GoalsConversion,
    [Enums.ColumnType.YellowCards]: OrderingKey.YellowCards,
    [Enums.ColumnType.YellowRedCards]: OrderingKey.YellowRedCards,
    [Enums.ColumnType.RedCards]: OrderingKey.RedCards,
    [Enums.ColumnType.CleanSheets]: OrderingKey.CleanSheets,
    [Enums.ColumnType.Assists]: OrderingKey.Assists,
    [Enums.ColumnType.GoalsAndAssists]: OrderingKey.GoalsAndAssists,
    [Enums.ColumnType.Shots]: OrderingKey.Shots,
    [Enums.ColumnType.ShotsOnGoal]: OrderingKey.ShotsOnGoal,
    [Enums.ColumnType.ShotsOffGoal]: OrderingKey.ShotsOffGoal,
    [Enums.ColumnType.Offside]: OrderingKey.Offside,
  },
  [Enums.StatType.PerNinetyMinutes]: {
    [Enums.ColumnType.Player]: OrderingKey.PlayerName,
    [Enums.ColumnType.Goals]: OrderingKey.GoalsPerNinety,
    [Enums.ColumnType.GamesPlayed]: OrderingKey.GamesPlayed,
    [Enums.ColumnType.MinutesPlayed]: OrderingKey.MinutesPlayed,
    [Enums.ColumnType.HeadedGoals]: OrderingKey.HeadedGoalsPerNinety,
    [Enums.ColumnType.PenaltyGoals]: OrderingKey.PenaltyGoalsPerNinety,
    [Enums.ColumnType.GoalConversion]: OrderingKey.GoalsConversionPerNinety,
    [Enums.ColumnType.YellowCards]: OrderingKey.YellowCardsPerNinety,
    [Enums.ColumnType.YellowRedCards]: OrderingKey.YellowRedCardsPerNinety,
    [Enums.ColumnType.RedCards]: OrderingKey.RedCardsPerNinety,
    [Enums.ColumnType.CleanSheets]: OrderingKey.CleanSheetsPerNinety,
    [Enums.ColumnType.Assists]: OrderingKey.AssistsPerNinety,
    [Enums.ColumnType.GoalsAndAssists]: OrderingKey.GoalsAndAssistsPerNinety,
    [Enums.ColumnType.Shots]: OrderingKey.ShotsPerNinety,
    [Enums.ColumnType.ShotsOnGoal]: OrderingKey.ShotsOnGoalPerNinety,
    [Enums.ColumnType.ShotsOffGoal]: OrderingKey.ShotsOffGoalPerNinety,
    [Enums.ColumnType.Offside]: OrderingKey.OffsidePerNinety,
  },
  [Enums.StatType.PerGame]: {
    [Enums.ColumnType.Player]: OrderingKey.PlayerName,
    [Enums.ColumnType.Goals]: OrderingKey.GoalsPerGame,
    [Enums.ColumnType.GamesPlayed]: OrderingKey.GamesPlayed,
    [Enums.ColumnType.MinutesPlayed]: OrderingKey.MinutesPlayedPerGame,
    [Enums.ColumnType.HeadedGoals]: OrderingKey.HeadedGoalsPerGame,
    [Enums.ColumnType.PenaltyGoals]: OrderingKey.PenaltyGoalsPerGame,
    [Enums.ColumnType.GoalConversion]: OrderingKey.GoalsConversionPerGame,
    [Enums.ColumnType.YellowCards]: OrderingKey.YellowCardsPerGame,
    [Enums.ColumnType.YellowRedCards]: OrderingKey.YellowRedCardsPerGame,
    [Enums.ColumnType.RedCards]: OrderingKey.RedCardsPerGame,
    [Enums.ColumnType.CleanSheets]: OrderingKey.CleanSheetsPerGame,
    [Enums.ColumnType.Assists]: OrderingKey.AssistsPerGame,
    [Enums.ColumnType.GoalsAndAssists]: OrderingKey.GoalsAndAssistsPerGame,
    [Enums.ColumnType.Shots]: OrderingKey.ShotsPerGame,
    [Enums.ColumnType.ShotsOnGoal]: OrderingKey.ShotsOnGoalPerGame,
    [Enums.ColumnType.ShotsOffGoal]: OrderingKey.ShotsOffGoalPerGame,
    [Enums.ColumnType.Offside]: OrderingKey.OffsidePerGame,
  },
};

export const DEFAULT_COLUMNS = [
  COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.PlayerRank],
  COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.PlayerIcon],
  COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.Player],
];

export const COLUMNS_BY_PRESET_TYPE = {
  [Enums.PresetType.Summary]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.MinutesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.Goals],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.Assists],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.GoalsAndAssists],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.Shots],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.ShotsOnGoal],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.Offside],
  ],
  [Enums.PresetType.Attack]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.Goals],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.HeadedGoals],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.PenaltyGoals],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.Shots],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.ShotsOnGoal],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.Offside],
  ],
  [Enums.PresetType.Aggression]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.YellowCards],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.YellowRedCards],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.RedCards],
  ],
  [Enums.PresetType.Goalkeeper]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.CleanSheets],
  ],
  [Enums.PresetType.Passing]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[Enums.ColumnType.Assists],
  ],
};

export const ACTIVE_COLUMNS_BY_PRESET_TYPE = {
  [Enums.PresetType.Summary]: {
    [Enums.ColumnType.GamesPlayed]: true,
    [Enums.ColumnType.MinutesPlayed]: true,
    [Enums.ColumnType.Goals]: true,
    [Enums.ColumnType.Assists]: true,
    [Enums.ColumnType.GoalsAndAssists]: true,
    [Enums.ColumnType.Shots]: true,
    [Enums.ColumnType.ShotsOnGoal]: true,
    [Enums.ColumnType.Offside]: true,
  },
  [Enums.PresetType.Attack]: {
    [Enums.ColumnType.GamesPlayed]: true,
    [Enums.ColumnType.Goals]: true,
    [Enums.ColumnType.HeadedGoals]: true,
    [Enums.ColumnType.PenaltyGoals]: true,
    [Enums.ColumnType.Shots]: true,
    [Enums.ColumnType.ShotsOnGoal]: true,
    [Enums.ColumnType.Offside]: true,
  },
  [Enums.PresetType.Aggression]: {
    [Enums.ColumnType.GamesPlayed]: true,
    [Enums.ColumnType.YellowCards]: true,
    [Enums.ColumnType.YellowRedCards]: true,
    [Enums.ColumnType.RedCards]: true,
  },
  [Enums.PresetType.Goalkeeper]: {
    [Enums.ColumnType.GamesPlayed]: true,
    [Enums.ColumnType.CleanSheets]: true,
  },
  [Enums.PresetType.Passing]: {
    [Enums.ColumnType.GamesPlayed]: true,
    [Enums.ColumnType.Assists]: true,
  },
};

export const RANGE_FILTERS_DEFAULT_STATE = {
  [Enums.ColumnType.Goals]: {},
  [Enums.ColumnType.GamesPlayed]: {},
  [Enums.ColumnType.HeadedGoals]: {},
  [Enums.ColumnType.PenaltyGoals]: {},
  [Enums.ColumnType.GoalConversion]: {},
  [Enums.ColumnType.YellowCards]: {},
  [Enums.ColumnType.YellowRedCards]: {},
  [Enums.ColumnType.CleanSheets]: {},
  [Enums.ColumnType.RedCards]: {},
  [Enums.ColumnType.Assists]: {},
  [Enums.ColumnType.MinutesPlayed]: {},
  [Enums.ColumnType.GoalsAndAssists]: {},
  [Enums.ColumnType.Shots]: {},
  [Enums.ColumnType.ShotsOnGoal]: {},
  [Enums.ColumnType.ShotsOffGoal]: {},
  [Enums.ColumnType.Offside]: {},
};

export const DEFAULT_COMMON_ORDERING: Interfaces.CustomFilter.Ordering = {
  [Enums.ColumnType.Player]: OrderType.Ascending,
};

export const COMMON_ORDERING_BY_STATS_TYPE: Record<
  Enums.StatType,
  Interfaces.CustomFilter.Ordering
> = {
  [Enums.StatType.All]: DEFAULT_COMMON_ORDERING,
  [Enums.StatType.PerGame]: DEFAULT_COMMON_ORDERING,
  [Enums.StatType.PerNinetyMinutes]: DEFAULT_COMMON_ORDERING,
};

export const COLUMN_CONFIGURATION_FILTER_EMPTY_STATE: Interfaces.CustomFilter.ColumnConfigurationFilter = {
  activePreset: Enums.PresetType.Custom,
  columns: DEFAULT_COLUMNS,
  configurableOrdering: {},
  activeColumns: {},
  rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
  positions: [],
};

export const CUSTOM_GENERAL_FILTER_EMPTY_STATE = {
  ages: {},
  positions: [],
  nationalities: [],
  activeStatsTab: Enums.StatType.All,
  commonOrdering: COMMON_ORDERING_BY_STATS_TYPE[Enums.StatType.All],
};

export const COLUMN_CONFIGURATION_FILTER_DEFAULT_STATE: Interfaces.CustomFilter.ColumnConfigurationFilter = {
  activePreset: Enums.PresetType.Summary,
  columns: COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Summary],
  activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Summary],
  rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
  configurableOrdering: { [Enums.ColumnType.Goals]: OrderType.Descending },
  positions: [],
};

export const CUSTOM_FILTER_DEFAULT_STATE: Interfaces.CustomFilter.CustomFilterState = {
  generalFilter: CUSTOM_GENERAL_FILTER_EMPTY_STATE,
  columnConfigurationFilter: COLUMN_CONFIGURATION_FILTER_DEFAULT_STATE,
};

export const CUSTOM_FILTER_EMPTY_STATE: Interfaces.CustomFilter.CustomFilterState = {
  generalFilter: CUSTOM_GENERAL_FILTER_EMPTY_STATE,
  columnConfigurationFilter: COLUMN_CONFIGURATION_FILTER_EMPTY_STATE,
};

export const CUSTOMIZATION_PRESET_BY_TYPE = {
  [Enums.PresetType.Summary]: {
    type: Enums.PresetType.Summary,
    overrides: {
      activePreset: Enums.PresetType.Summary,
      columns: COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Summary],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Summary],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      positions: [],
      configurableOrdering: {
        [Enums.ColumnType.Goals]: OrderType.Descending,
      },
    },
  },
  [Enums.PresetType.Attack]: {
    type: Enums.PresetType.Attack,
    overrides: {
      activePreset: Enums.PresetType.Attack,
      columns: COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Attack],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Attack],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      positions: [],
      configurableOrdering: {
        [Enums.ColumnType.Goals]: OrderType.Descending,
      },
    },
  },
  [Enums.PresetType.Aggression]: {
    type: Enums.PresetType.Aggression,
    overrides: {
      activePreset: Enums.PresetType.Aggression,
      columns: COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Aggression],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Aggression],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      positions: [],
      configurableOrdering: {
        [Enums.ColumnType.YellowCards]: OrderType.Descending,
      },
    },
  },
  [Enums.PresetType.Goalkeeper]: {
    type: Enums.PresetType.Goalkeeper,
    overrides: {
      activePreset: Enums.PresetType.Goalkeeper,
      columns: COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Goalkeeper],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Goalkeeper],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      positions: [POSITION_BY_TYPE[Enums.PositionType.Goalkeeper]],
      configurableOrdering: {
        [Enums.ColumnType.CleanSheets]: OrderType.Descending,
      },
    },
  },
  [Enums.PresetType.Passing]: {
    type: Enums.PresetType.Passing,
    overrides: {
      activePreset: Enums.PresetType.Passing,
      columns: COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Passing],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[Enums.PresetType.Passing],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      positions: [],
      configurableOrdering: {
        [Enums.ColumnType.Assists]: OrderType.Descending,
      },
    },
  },
  [Enums.PresetType.Custom]: { type: Enums.PresetType.Custom, label: 'Custom', overrides: {} },
};

export const CUSTOMIZATION_PRESETS_BY_STAT_TYPE: Interfaces.CustomFilter.PresetView[] = [
  CUSTOMIZATION_PRESET_BY_TYPE[Enums.PresetType.Summary],
  CUSTOMIZATION_PRESET_BY_TYPE[Enums.PresetType.Attack],
  CUSTOMIZATION_PRESET_BY_TYPE[Enums.PresetType.Aggression],
  CUSTOMIZATION_PRESET_BY_TYPE[Enums.PresetType.Goalkeeper],
  CUSTOMIZATION_PRESET_BY_TYPE[Enums.PresetType.Passing],
  CUSTOMIZATION_PRESET_BY_TYPE[Enums.PresetType.Custom],
];

export const COLUMNS_TO_SKIP_DEFAULT_ORDERING = [
  Enums.ColumnType.Club,
  Enums.ColumnType.Player,
  Enums.ColumnType.PlayerRank,
  Enums.ColumnType.PlayerIcon,
];
