exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2bHkXS2F{flex:1 1;margin-right:16px}._1dUw8VSF{width:443px}._1P0zAn5W{flex:1 1;display:flex;align-items:flex-start;justify-content:center}._XSD-QWdr{margin-bottom:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktopNarrow/football/TeamPlayers/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,WAAY,CAPhB,WAWI,QAAO,CACP,YAAa,CACb,sBAAuB,CACvB,sBAAuB,CAd3B,WAkBI,kBCnBS","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n  }\n\n  .advertisement {\n    margin-bottom: $margin;\n  }\n}","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_2bHkXS2F",
	"leftColumn": "_2bHkXS2F",
	"right-column": "_1dUw8VSF",
	"rightColumn": "_1dUw8VSF",
	"left-bar": "_1P0zAn5W",
	"leftBar": "_1P0zAn5W",
	"advertisement": "_XSD-QWdr"
};