import getState from './getState';

export const getTimeZone = (state) => {
  return getState(state).timeZone;
};

export const getTimeZoneValue = (state) => {
  return getTimeZone(state).value;
};

export const getTimeZoneName = (state) => {
  return getTimeZone(state).name;
};
