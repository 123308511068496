import { SeasonPlayerStatistics } from 'packages/rest';

import { mapFilters } from '../../services';
import { filtersActions } from '../../actions';

const loadFilters = (seasonId) => async (dispatch) => {
  try {
    dispatch(filtersActions.loadFiltersStart());

    const { data } = await SeasonPlayerStatistics.loadFilters({ seasonId });
    const mappedFilters = mapFilters(data.results);

    dispatch(filtersActions.loadFiltersSuccess(mappedFilters));
  } catch (e) {
    dispatch(filtersActions.loadFiltersFailure(e));
  }
};

export default loadFilters;
