import { toCamelCase } from 'packages/utils/Object.utils';
import { TEAM_DEFAULT_LOGO } from 'appConstants';
import DateService from 'packages/date-az';

import { PlayerStatistics, Statistics, StatType } from 'packages/season-player-statistics/types';

const PARAMETER_VALUE = {
  total: 0,
  average: 0,
  per90: 0,
};
const mapPlayers = (
  playerStatistics: any[],
  playersOffset: number,
  statType: StatType,
): PlayerStatistics[] => {
  return playerStatistics.map((playerStatisticsEntry, index) => {
    const { player, statistics, team } = toCamelCase(playerStatisticsEntry);
    const { id: teamId, logo: teamLogo, name: teamName } = toCamelCase(team);
    const { countryLogo, dateOfBirth, firstName, lastName, logo, name, position, id } = toCamelCase(
      player,
    );
    const {
      matchesPlayed = PARAMETER_VALUE,
      cornerKicks = PARAMETER_VALUE,
      shotsBlocked = PARAMETER_VALUE,
      substitutedIn = PARAMETER_VALUE,
      substitutedOut = PARAMETER_VALUE,

      goalsScored = PARAMETER_VALUE,
      goalPoints = PARAMETER_VALUE,
      goalsByPenalty = PARAMETER_VALUE,
      goalsByHead = PARAMETER_VALUE,
      assists = PARAMETER_VALUE,
      totalShots = PARAMETER_VALUE,
      offsides = PARAMETER_VALUE,
      yellowCards = PARAMETER_VALUE,
      yellowRedCards = PARAMETER_VALUE,
      redCards = PARAMETER_VALUE,
      shotsOnTarget = PARAMETER_VALUE,
      shotsOffTarget = PARAMETER_VALUE,
      goalsConversion = PARAMETER_VALUE,
      cleanSheets = PARAMETER_VALUE,
      minutesPlayed = PARAMETER_VALUE,
    } = toCamelCase(statistics);

    const commonParameters = {
      gamesPlayed: matchesPlayed.total,
      corners: cornerKicks.total,
      shotsBlocked: shotsBlocked.total,
      substitutedIn: substitutedIn.total,
      substitutedOut: substitutedOut.total,
    };

    const playerYearOfBirth = dateOfBirth
      ? DateService.getDateFromString(dateOfBirth).getFullYear()
      : undefined;
    const playerAge = playerYearOfBirth
      ? DateService.getDateByYearOffset(playerYearOfBirth).year
      : undefined;

    let resolvedStatistics: Statistics;

    switch (statType) {
      case StatType.PerNinetyMinutes:
        resolvedStatistics = {
          goals: parseFloat(goalsScored.per90.toFixed(2)),
          penalties: parseFloat(goalsByPenalty.per90.toFixed(2)),
          goalPoints: parseFloat(goalPoints.per90.toFixed(2)),
          goalsByHeader: parseFloat(goalsByHead.per90.toFixed(2)),
          assists: parseFloat(assists.per90.toFixed(2)),
          totalShots: parseFloat(totalShots.per90.toFixed(2)),
          offside: parseFloat(offsides.per90.toFixed(2)),
          yellowCards: parseFloat(yellowCards.per90.toFixed(2)),
          yellowRedCards: parseFloat(yellowRedCards.per90.toFixed(2)),
          redCards: parseFloat(redCards.per90.toFixed(2)),
          shotsOnGoal: parseFloat(shotsOnTarget.per90.toFixed(2)),
          shotsOffGoal: parseFloat(shotsOffTarget.per90.toFixed(2)),
          goalsConversion: parseFloat(goalsConversion.per90.toFixed(2)),
          cleanSheet: parseFloat(cleanSheets.per90.toFixed(2)),
          minutesPlayed: minutesPlayed.total,
          ...commonParameters,
        };
        break;
      case StatType.PerGame:
        resolvedStatistics = {
          goals: parseFloat(goalsScored.average.toFixed(2)),
          penalties: parseFloat(goalsByPenalty.average.toFixed(2)),
          goalPoints: parseFloat(goalPoints.average.toFixed(2)),
          goalsByHeader: parseFloat(goalsByHead.average.toFixed(2)),
          assists: parseFloat(assists.average.toFixed(2)),
          totalShots: parseFloat(totalShots.average.toFixed(2)),
          offside: parseFloat(offsides.average.toFixed(2)),
          yellowCards: parseFloat(yellowCards.average.toFixed(2)),
          yellowRedCards: parseFloat(yellowRedCards.average.toFixed(2)),
          redCards: parseFloat(redCards.average.toFixed(2)),
          shotsOnGoal: parseFloat(shotsOnTarget.average.toFixed(2)),
          shotsOffGoal: parseFloat(shotsOffTarget.average.toFixed(2)),
          goalsConversion: parseFloat(goalsConversion.average.toFixed(2)),
          cleanSheet: parseFloat(cleanSheets.average.toFixed(2)),
          minutesPlayed: minutesPlayed.total,
          ...commonParameters,
        };
        break;
      default:
        resolvedStatistics = {
          goals: parseFloat(goalsScored.total.toFixed(2)),
          penalties: parseFloat(goalsByPenalty.total.toFixed(2)),
          goalPoints: parseFloat(goalPoints.total.toFixed(2)),
          goalsByHeader: parseFloat(goalsByHead.total.toFixed(2)),
          assists: parseFloat(assists.total.toFixed(2)),
          totalShots: parseFloat(totalShots.total.toFixed(2)),
          offside: parseFloat(offsides.total.toFixed(2)),
          yellowCards: parseFloat(yellowCards.total.toFixed(2)),
          yellowRedCards: parseFloat(yellowRedCards.total.toFixed(2)),
          redCards: parseFloat(redCards.total.toFixed(2)),
          shotsOnGoal: parseFloat(shotsOnTarget.total.toFixed(2)),
          shotsOffGoal: parseFloat(shotsOffTarget.total.toFixed(2)),
          goalsConversion: parseFloat(goalsConversion.total.toFixed(2)),
          cleanSheet: parseFloat(cleanSheets.total.toFixed(2)),
          minutesPlayed: minutesPlayed.total,
          ...commonParameters,
        };
    }

    return {
      player: {
        id,
        countryLogo,
        age: playerAge,
        firstName,
        lastName,
        logo,
        name,
        position,
        rank: index + 1 + playersOffset,
      },
      team: {
        id: teamId,
        logo: teamLogo || TEAM_DEFAULT_LOGO,
        name: teamName,
      },
      statistics: resolvedStatistics,
    };
  });
};

export default mapPlayers;
