import { commonActions } from '../../actions';
import { filterSelectors } from '../../selectors';

const initActiveFilter = (period: [string, string]) => async (dispatch, getState) => {
  const activeFilter = filterSelectors.getActiveFilter(getState());
  const updatedFilter = { ...activeFilter, period };

  dispatch(commonActions.updateActiveFilterSuccess({ activeFilters: updatedFilter }));
};

export default initActiveFilter;
