import {
  ColumnType,
  RangeFilterColumnsView,
  RangeFilterGroupType,
} from 'packages/season-team-statistics/types';

const getColumnConfigurationSchema = (): RangeFilterColumnsView => {
  return [
    [
      {
        type: RangeFilterGroupType.Attack,
        rangeFilters: [
          { type: ColumnType.TotalShots },
          { type: ColumnType.ShotsOnGoal },
          { type: ColumnType.ShotsOffGoal },
          { type: ColumnType.ShotsBlocked },
          { type: ColumnType.ShotsOnPost },
          { type: ColumnType.ShotsOnBar },
          { type: ColumnType.Possession },
          { type: ColumnType.CornerKicks },
          { type: ColumnType.FreeKicks },
          { type: ColumnType.Offside },
        ],
      },
    ],
    [
      {
        type: RangeFilterGroupType.Aggression,
        rangeFilters: [
          { type: ColumnType.YellowCards },
          { type: ColumnType.YellowRedCards },
          { type: ColumnType.RedCards },
        ],
      },
      {
        type: RangeFilterGroupType.Defence,
        rangeFilters: [{ type: ColumnType.GoalsConceded }],
      },
      {
        type: RangeFilterGroupType.Goals,
        rangeFilters: [
          { type: ColumnType.GoalsScored },
          { type: ColumnType.ShootingEfficiency },
          { type: ColumnType.Headed },
          { type: ColumnType.FootGoals },
        ],
      },
    ],
    [
      {
        type: RangeFilterGroupType.Other,
        rangeFilters: [{ type: ColumnType.GamesPlayed }],
      },
    ],
  ];
};

export default getColumnConfigurationSchema;
