import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadMatchSummaryStart,
  loadMatchSummarySuccess,
  loadMatchSummaryFailure,
  cleanMatchSummarySuccess,
} from '../actions';
import { Status } from '../types';

const defaultState: Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, Status> = {
  [`${loadMatchSummaryStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(loadMatchSummarySuccess, loadMatchSummaryFailure)}`]: () => {
    return { isLoading: false };
  },
  [`${cleanMatchSummarySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
