import { mapTournament } from 'packages/events/services';

import { toCamelCase } from 'packages/utils/Object.utils';
import { Tournament } from 'types';

const mapSearchResults = (tournaments: object[]): Tournament[] => {
  return tournaments.map((tournament) => {
    const {
      id: uniqueTournamentId,
      currentSeasonId,
      name: uniqueTournamentName,
      ...restTournamentData
    } = toCamelCase(tournament);

    return mapTournament(restTournamentData, {
      currentSeasonId,
      uniqueTournamentName,
      uniqueTournamentId,
    });
  });
};

export default mapSearchResults;
