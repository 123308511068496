import { statisticsActions } from '../../../actions';

interface Params {
  tournamentId: string;
  seasonId: string;
}

const selectTournamentSeason = ({ tournamentId, seasonId }: Params) => (dispatch) => {
  dispatch(
    statisticsActions.tournamentSeasons.setTournamentSeasonsSuccess({
      selectedTournamentId: tournamentId,
      selectedSeasonId: seasonId,
    }),
  );
};

export default selectTournamentSeason;
