import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { TournamentsExtendedCategory } from 'packages/categories/types';
import { useNavigation } from 'packages/hooks';
import { Category as CategoryView, Tournament } from 'types';
import { DesktopPage } from 'router/types';

import CategoryListView from './views';
import connect from './connect';

type Props = {
  categories: CategoryView[];
  selectedCategory?: TournamentsExtendedCategory;
  categoryListActions: ActionCreatorsMapObject;
  isCategoriesLoading: boolean;
  isSelectedCategoryLoading: boolean;
  isPopularTournamentsLoading: boolean;
  title?: string;
};

const extendedCategoryList = (
  CategoryList: typeof CategoryListView[keyof typeof CategoryListView],
) =>
  memo(
    ({
      categories,
      isSelectedCategoryLoading,
      isCategoriesLoading,
      selectedCategory,
      categoryListActions,
      title,
      isPopularTournamentsLoading,
    }: Props) => {
      const { appNavigate } = useNavigation();

      const [selectedCategoryId, setSelectedCategoryId] = useState<number | null>(null);
      const [isCollapsed, setIsCollapsed] = useState(true);

      const visibleCategories = useMemo(() => {
        if (isCollapsed) {
          return categories.slice(0, 7);
        }

        return categories;
      }, [categories, isCollapsed]);

      useEffect(() => {
        categoryListActions.loadCategories();
      }, []);

      const handleCategoryToggle = useCallback(
        ({ id }: CategoryView) => {
          categoryListActions.loadSelectedCategory(id);
          setSelectedCategoryId(id === selectedCategoryId ? null : id);
        },
        [selectedCategoryId],
      );

      const handleTournamentClick = useCallback(
        ({ currentSeasonId, uniqueTournamentId }: Tournament) => {
          appNavigate(DesktopPage.FootballSeason, {
            uniqueTournamentId,
            seasonId: currentSeasonId,
          });
        },
        [],
      );

      const handleCategoryClick = useCallback(({ id }: CategoryView) => {
        appNavigate(DesktopPage.FootballCategory, { categoryId: id });
      }, []);

      const handleExpandClick = useCallback(
        () => setIsCollapsed((isCollapsed) => !isCollapsed),
        [],
      );

      return (
        <CategoryList
          visibleCategories={visibleCategories}
          isCategoriesLoading={isCategoriesLoading}
          isSelectedCategoryLoading={isSelectedCategoryLoading}
          selectedCategory={selectedCategory}
          title={title}
          selectedCategoryId={selectedCategoryId}
          onCategoryClick={handleCategoryClick}
          onTournamentClick={handleTournamentClick}
          onCategoryToggle={handleCategoryToggle}
          onExpandClick={handleExpandClick}
          isCollapsed={isCollapsed}
          isPopularTournamentsLoading={isPopularTournamentsLoading}
        />
      );
    },
  );

export default {
  Desktop: connect(extendedCategoryList(CategoryListView.Desktop)),
  DesktopNarrow: connect(extendedCategoryList(CategoryListView.DesktopNarrow)),
  Tablet: connect(extendedCategoryList(CategoryListView.Tablet)),
  TabletNarrow: connect(extendedCategoryList(CategoryListView.TabletNarrow)),
  Mobile: connect(extendedCategoryList(CategoryListView.Mobile)),
};
