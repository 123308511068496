import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { statisticsActions, commonActions } from '../../../../actions';
import { Interfaces } from '../../../../types';

const defaultState: Interfaces.ActualGames.Status = {
  isLoading: false,
};

const reducerMap: ReducerMap<Interfaces.ActualGames.Status, void> = {
  [`${statisticsActions.tournamentSeasons.loadTournamentSeasonsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(
    statisticsActions.tournamentSeasons.loadTournamentSeasonsSuccess,
    statisticsActions.tournamentSeasons.loadTournamentSeasonsFailure,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${commonActions.cleanSummarySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
