import {
  PlayersParameter,
  TeamsParameter,
  VenuesParameter,
} from 'packages/season-top-board/types/enums';

export * from './interfaces';
export * from './enums';

export type Parameter = TeamsParameter | PlayersParameter | VenuesParameter;
