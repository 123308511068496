import instance from '../../../axios';
import { HeadToHeadParams, LastFiveParams } from '../types';

export const loadHeadToHeadEvents = ({
  homeTeamId,
  awayTeamId,
  scheduledDate,
}: HeadToHeadParams) => {
  return instance({
    method: 'get',
    url: `/sport_events/h2h/${homeTeamId}/${awayTeamId}/`,
    params: {
      scheduled_before: scheduledDate,
    },
  });
};

export const loadLastFiveEvents = ({ teamId, scheduledDate }: LastFiveParams) => {
  return instance({
    method: 'get',
    url: `/sport_events/last/${teamId}/`,
    params: {
      scheduled_before: scheduledDate,
    },
  });
};
