import React, { memo, RefObject, useMemo } from 'react';
import classnames from 'classnames';
import { Column as ReactTableColumn } from 'react-table';

import {
  Column,
  ColumnType,
  Ordering,
  TeamStatistics,
  StatType,
} from 'packages/season-team-statistics/types';
import { LoaderContainer, FlexibleTable } from 'packages/shared/components';
import { Team } from 'types';

import { getRowProps } from './services';
import { SchemaParams } from '../../types';
import styles from './styles.scss';

type Props = {
  onTeamClick: (team: Team) => void;
  forwardedRef: RefObject<HTMLDivElement>;
  teamStatistics: TeamStatistics[];
  columns: Column[];
  ordering: Ordering;
  onOrderingChange: (columnType: ColumnType) => void;
  activeStatType: StatType;
  getTableSchema: (params: SchemaParams) => ReactTableColumn<TeamStatistics>[];
  isLoading: boolean;
  className?: string;
};

const StatisticsTable = ({
  forwardedRef,
  teamStatistics,
  onOrderingChange,
  columns,
  ordering,
  activeStatType,
  isLoading,
  className,
  onTeamClick,
  getTableSchema,
}: Props) => {
  const tableColumns = useMemo(
    () =>
      getTableSchema({
        columns,
        additionalProps: { onOrderingChange, ordering, statType: activeStatType },
      }),
    [columns, ordering, onOrderingChange, activeStatType],
  );

  const rowProps = useMemo(() => getRowProps({ onClick: onTeamClick }), []);

  return (
    <>
      <LoaderContainer
        isLoading={isLoading}
        className={classnames(styles.loaderContainer)}
        isContentVisible
      />
      <FlexibleTable<TeamStatistics>
        forwardedRef={forwardedRef}
        className={classnames(styles.table, className)}
        headerClassName={styles.header}
        columns={tableColumns}
        setRowProps={rowProps}
        data={teamStatistics}
        isColumnsSticky
        isHeaderSticky
        isRowClickable
      />
    </>
  );
};

export default memo(StatisticsTable);
