type GetOffsetParams = {
  element: HTMLElement | null | undefined;
};

const getPopoverOffsets = ({ element }: GetOffsetParams) => {
  const { top = 0, left = 0, width = 0 } = element?.getBoundingClientRect() || {};

  return { right: window.innerWidth - left - width, left, top };
};

export default getPopoverOffsets;
