import * as favoritesActions from './actions';
import * as favoritesSelectors from './selectors';
import * as favoritesThunks from './thunks';
import * as favoritesConstants from './constants';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export { favoritesActions, createReducer, favoritesSelectors, favoritesThunks, favoritesConstants };
