import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { mobileFilterStagesThunks, mobileFilterStagesSelectors } from 'packages/team-standings';

const mapStateToProps = (state) => {
  return {
    stages: mobileFilterStagesSelectors.getStages(state),
    isLoading: mobileFilterStagesSelectors.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mobileFilterStagesActions: bindActionCreators(mobileFilterStagesThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
