import { Players } from 'packages/rest';

import { statisticsActions } from '../../../actions';
import { groupSeasonPlayerStatistics, mapStatistics } from '../../../services';

interface Params {
  playerId: number;
  seasonId: number;
}

const loadSeasonStatistics = ({ playerId, seasonId }: Params) => async (dispatch) => {
  try {
    dispatch(statisticsActions.statistics.loadStatisticsStart());

    const { data } = await Players.loadStatistics({ playerId, seasonId });

    const rawStatistics = mapStatistics(data.results.statistics);
    const statistics = groupSeasonPlayerStatistics(rawStatistics);

    dispatch(statisticsActions.statistics.loadStatisticsSuccess(statistics));
  } catch (e) {
    dispatch(statisticsActions.statistics.loadStatisticsFailure(e));
  }
};

export default loadSeasonStatistics;
