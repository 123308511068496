import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { headToHeadThunks, headToHeadSelectors } from 'packages/team-standings';

const mapStateToProps = (state) => {
  return {
    isLoading: headToHeadSelectors.getIsLoading(state),
    headToHead: headToHeadSelectors.getData(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    seasonHeadToHeadActions: bindActionCreators(headToHeadThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
