import * as matchDetailsActions from './actions';
import * as matchDetailsSelectors from './selectors';
import * as matchDetailsConstants from './constants';
import * as matchDetailsThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  matchDetailsActions,
  matchDetailsSelectors,
  matchDetailsConstants,
  matchDetailsThunks,
  createReducer,
};
