import React, { RefObject, useMemo, useRef } from 'react';
import classnames from 'classnames';

import { FlexiblePopover } from 'packages/shared/components';
import { excludeById, findById } from 'packages/utils/Array.utils';
import { isEmpty } from 'packages/utils/Object.utils';
import { ComponentLike } from 'types';
import { PopoverJustify, PopoverAlign } from 'packages/shared/components/FlexiblePopover/types';

import { SelectInput } from '../../components';
import { SelectTheme, SelectItem, SelectSize } from '../../types';
import { getLabels } from './services';
import styles from '../styles.scss';
import { PopoverItemList } from './components';

type Props<T extends SelectItem> = {
  selectedItems: T[];
  items: T[];
  pluralItemLabel: string;
  defaultLabel?: string;
  onChange: Function;
  itemComponent?: ComponentLike;
  theme?: SelectTheme;
  isReversed?: boolean;
  className?: string;
  isDisabled?: boolean;
  itemsContainerClass?: string;
  isCheckShown?: boolean;
  isLoading?: boolean;
  useScrollIntoPopoverView?: boolean;
  isAllItemsSelected?: boolean;
  onSelectAllItems?: () => void;
  size?: SelectSize;
  scrollElementRef?: RefObject<HTMLElement>;
};

const MultipleSelect = <T extends SelectItem>({
  selectedItems,
  pluralItemLabel,
  onChange,
  items,
  itemComponent,
  isReversed = false,
  theme = SelectTheme.Gray,
  className,
  isDisabled = false,
  defaultLabel,
  itemsContainerClass,
  isCheckShown = true,
  isLoading = false,
  useScrollIntoPopoverView = false,
  isAllItemsSelected,
  onSelectAllItems,
  size = SelectSize.FullWidth,
  scrollElementRef,
}: Props<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { inputLabel, allSelectedLabel, defaultLabel: placeHolder } = useMemo(
    () => getLabels(pluralItemLabel, selectedItems, defaultLabel),
    [pluralItemLabel, selectedItems, defaultLabel],
  );
  const popoverComponentClass = classnames(styles.selectItems, itemsContainerClass, {
    [styles.bottomOffset]: isReversed,
    [styles.topOffset]: !isReversed,
  });

  const handleItemClick = (item: T) => {
    const selectedItemsCopy = [...selectedItems];

    const updatedItems = findById(selectedItemsCopy, item.id)
      ? excludeById(selectedItemsCopy, item.id)
      : [...selectedItemsCopy, item];

    onChange(updatedItems);
  };

  const handleChooseAll = () => {
    if (onSelectAllItems) {
      onSelectAllItems();
    } else {
      onChange([]);
    }
  };

  const isAllItemSelected =
    isAllItemsSelected !== undefined ? isAllItemsSelected : isEmpty(selectedItems);

  return (
    <div ref={containerRef} className={classnames(styles[size], className)}>
      <FlexiblePopover
        isDisabled={isLoading || isDisabled}
        popoverComponent={PopoverItemList}
        popoverProps={{
          className: popoverComponentClass,
          items,
          itemComponent,
          selectedItems,
          onItemClick: handleItemClick,
          isAllItemSelected,
          allSelectedLabel,
          isCheckShown,
          containerRef,
          onChooseAll: handleChooseAll,
        }}
        useScrollIntoPopoverView={useScrollIntoPopoverView}
        scrollElementRef={scrollElementRef}
        useChildrenWrapper
        justify={PopoverJustify.CenterStart}
        align={isReversed ? PopoverAlign.Top : PopoverAlign.Bottom}
        closeOnClickOutside
        useOverlay={false}
      >
        <SelectInput
          label={isDisabled && placeHolder ? placeHolder : inputLabel}
          isDisabled={isDisabled}
          isLoading={isLoading}
          theme={theme}
        />
      </FlexiblePopover>
    </div>
  );
};

export default React.memo(MultipleSelect);
