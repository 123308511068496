import {
  ColumnType,
  RangeFilterColumnsView,
  RangeFilterGroupType,
} from 'packages/season-player-statistics/types';

const getColumnConfigurationSchema = (): RangeFilterColumnsView => {
  return [
    [
      {
        type: RangeFilterGroupType.Attack,
        rangeFilters: [
          { type: ColumnType.Goals },
          { type: ColumnType.GoalsAndAssists },
          { type: ColumnType.HeadedGoals },
          { type: ColumnType.PenaltyGoals },
          { type: ColumnType.Shots },
          { type: ColumnType.ShotsOffGoal },
          { type: ColumnType.ShotsOnGoal },
          { type: ColumnType.Offside },
        ],
      },
    ],
    [
      {
        type: RangeFilterGroupType.Aggression,
        rangeFilters: [
          { type: ColumnType.YellowCards },
          { type: ColumnType.YellowRedCards },
          { type: ColumnType.RedCards },
        ],
      },
      {
        type: RangeFilterGroupType.Goalkeeper,
        rangeFilters: [{ type: ColumnType.CleanSheets }],
      },
      {
        type: RangeFilterGroupType.Passing,
        rangeFilters: [{ type: ColumnType.Assists }],
      },
    ],
    [
      {
        type: RangeFilterGroupType.Other,
        rangeFilters: [{ type: ColumnType.GamesPlayed }, { type: ColumnType.MinutesPlayed }],
      },
    ],
  ];
};

export default getColumnConfigurationSchema;
