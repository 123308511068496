import React, { memo, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { LoaderContainer, Separator } from 'packages/shared/components';
import { Commentaries } from 'packages/shared/modules';
import { Type } from 'packages/shared/components/Separator/types';
import { CommentaryGroups } from 'packages/event-commentary/types';
import { Event } from 'types';

import connect from './connect';
import styles from './styles.scss';

type Props = {
  cleanEventCommentary: Function;
  eventCommentaryActions: ActionCreatorsMapObject;
  isLoading: boolean;
  activeEvent: Event;
  eventCommentary: CommentaryGroups;
};

const EventCommentary = ({
  eventCommentary,
  isLoading,
  eventCommentaryActions,
  cleanEventCommentary,
  activeEvent,
}: Props) => {
  useEffect(() => {
    eventCommentaryActions.loadEventCommentary({ eventId: activeEvent.id });
  }, [activeEvent]);

  useEffect(
    () => () => {
      cleanEventCommentary();
    },
    [],
  );

  return (
    <LoaderContainer isLoading={isLoading} className={styles.loader}>
      <div className={styles.eventCommentary}>
        <Commentaries commentaryGroups={eventCommentary} activeEvent={activeEvent} />
      </div>

      <Separator type={Type.Block} />
    </LoaderContainer>
  );
};

export default connect(memo(EventCommentary));
