import { Team } from 'types';
import { toCamelCase } from 'packages/utils/Object.utils';

import { Interfaces } from '../types';
import { GOAL_STATISTICS_PERIOD_LABELS } from '../constants';

const mapStatistics = (stadiumStatistics: object): Interfaces.Statistics.Data => {
  const { seasonStats, homeTeams: rawHomeTeams } = toCamelCase(stadiumStatistics, true);
  const { statistics, topScorers: rawTopScorers } = seasonStats;

  const goalStatistics = mapGoalStatistics(statistics);
  const attendance = mapAttendance(statistics);
  const winLose = mapWinLose(statistics);
  const average = mapAverage(statistics);
  const topScorers = mapTopScorers(rawTopScorers);
  const homeTeams = mapHomeTeams(rawHomeTeams);

  return {
    goalStatistics,
    attendance,
    winLose,
    average,
    topScorers,
    homeTeams,
  };
};

const mapGoalStatistics = (
  statistics: Record<string, number>,
): Interfaces.Statistics.GoalStatistics => {
  const {
    venueGoals015,
    venueGoals1630,
    venueGoals3145,
    venueGoals4660,
    venueGoals6175,
    venueGoals7690,
    venueGoalsTotal,
    venueNrOfMatches,
    venueGoalsAverage,
  } = statistics;

  const averageGoalsByPeriods = [
    venueGoals015 || 0,
    venueGoals1630 || 0,
    venueGoals3145 || 0,
    venueGoals4660 || 0,
    venueGoals6175 || 0,
    venueGoals7690 || 0,
  ];

  return {
    averageGoalsByPeriods,
    periods: GOAL_STATISTICS_PERIOD_LABELS,
    totalGoals: venueGoalsTotal || 0,
    gamesPlayed: venueNrOfMatches || 0,
    averageGoals: venueGoalsAverage ? parseFloat(venueGoalsAverage.toFixed(2)) : 0,
  };
};

const mapAttendance = (statistics: Record<string, number>): Interfaces.Statistics.Attendance => {
  const { venueAttendanceAverage } = statistics;

  return {
    averageAttendance: venueAttendanceAverage,
  };
};

const mapWinLose = (statistics: Record<string, number>): Interfaces.Statistics.WinLose => {
  const {
    venueHomeWins,
    venueAwayWins,
    venueDraws,
    venueHomeGoals,
    venueAwayGoals,
    venueHomeCornerKicks,
    venueAwayCornerKicks,
    venueHomeGoalsPercentage,
    venueAwayGoalsPercentage,
    venueHomeCornerKicksPercentage,
    venueAwayCornerKicksPercentage,
  } = statistics;

  return {
    homeGoals: venueHomeGoals || 0,
    awayGoals: venueAwayGoals || 0,
    homeGoalsPercentage: venueHomeGoalsPercentage
      ? parseFloat(venueHomeGoalsPercentage.toFixed(2))
      : 0,
    awayGoalsPercentage: venueAwayGoalsPercentage
      ? parseFloat(venueAwayGoalsPercentage.toFixed(2))
      : 0,
    homeCornerKicksPercentage: venueHomeCornerKicksPercentage
      ? parseFloat(venueHomeCornerKicksPercentage.toFixed(2))
      : 0,
    awayCornerKicksPercentage: venueAwayCornerKicksPercentage
      ? parseFloat(venueAwayCornerKicksPercentage.toFixed(2))
      : 0,
    homeCornerKicks: venueHomeCornerKicks || 0,
    awayCornerKicks: venueAwayCornerKicks || 0,
    homeWins: venueHomeWins || 0,
    awayWins: venueAwayWins || 0,
    draws: venueDraws || 0,
  };
};

const mapAverage = (statistics: Record<string, number>): Interfaces.Statistics.Average => {
  const { venueCornerKicksAverage, venueCardsAverage } = statistics;

  return {
    cards: venueCardsAverage ? parseFloat(venueCardsAverage.toFixed(2)) : 0,
    cornerKicks: venueCornerKicksAverage ? parseFloat(venueCornerKicksAverage.toFixed(2)) : 0,
  };
};

const mapTopScorers = (topScorers: Record<string, any>[]): Interfaces.Statistics.TopScorer[] => {
  return topScorers.map(({ goals, topScorer }) => {
    const { id, name, logo } = topScorer;

    return {
      id,
      name,
      logo,
      goals: goals || 0,
    };
  });
};

const mapHomeTeams = (homeTeams: Record<string, any>[]): Team[] => {
  return homeTeams.map(({ id, name, logo }) => ({
    id,
    name,
    logo,
  }));
};

export default mapStatistics;
