import React, { memo } from 'react';
import classnames from 'classnames';

import { Data } from 'packages/team-details/types';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { ActualLeagues } from 'packages/shared/components';

import { Manager, Stadiums, JerseyKits } from './components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  details: Data;
  tournamentList: SeasonsExtendedTournament[];
  isLoading?: boolean;
  className?: string;
};

const Description = ({ details, tournamentList, isLoading, className }: Props) => {
  return (
    <div className={classnames(styles.descriptionContainer, className)}>
      <Manager isLoading={isLoading} manager={details?.manager} />
      <Stadiums isLoading={isLoading} stadiums={details?.stadiums} />
      <JerseyKits isLoading={isLoading} jerseySet={details?.jerseySet} />
      <ActualLeagues isLoading={isLoading} tournamentList={tournamentList} />
    </div>
  );
};

export default connect(memo(Description));
