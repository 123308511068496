import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const TeamSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton variant="circular" />
      <Skeleton marginLeft={6} height={14} width={50} variant="rectangular" />
    </div>
  );
};

export default memo(TeamSkeleton);
