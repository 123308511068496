import { SeasonDetails } from 'packages/rest';

import * as services from '../services';
import * as actions from '../actions';

export const loadSeasonDetails = (seasonId: number) => async (dispatch) => {
  dispatch(actions.loadSeasonDetailsStart());

  try {
    const { data } = await SeasonDetails.loadSeasonDetails({ seasonId });
    const seasonDetails = services.mapSeasonDetails(data.results);

    dispatch(actions.loadSeasonDetailsSuccess(seasonDetails));
  } catch (e) {
    dispatch(actions.loadSeasonDetailsFailure(e));
  }
};
