import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { favoritesThunks, favoritesSelectors } from 'packages/favorites';
import { searchSelectors, searchThunks } from 'packages/search';
import { settingsSelectors } from 'packages/settings';

const mapStateToProps = (state) => {
  return {
    results: searchSelectors.getResults(state),
    hasNextResults: searchSelectors.getHasNextResults(state),
    favoriteTournaments: favoritesSelectors.getFavoriteLeagues(state),
    isLoading: searchSelectors.getIsResultsLoading(state),
    activeDate: settingsSelectors.getActiveDate(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    favoritesActions: bindActionCreators(favoritesThunks, dispatch),
    searchActions: bindActionCreators(searchThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
