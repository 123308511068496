import classnames from 'classnames';

import styles from '../styles.scss';
import { Column } from '../types';

export const prepareColumns = (
  columns: Column[],
  columnClassName: string | undefined,
): Column[] => {
  return columns.map<Column>(({ className, dataIndex, children, key, ...rest }) => ({
    className: classnames(styles.column, columnClassName, className),
    children: children ? prepareColumns(children, columnClassName) : undefined,
    key: key || (dataIndex as string | number),
    dataIndex,
    ...rest,
  }));
};
