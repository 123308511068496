import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadMatchDetailsStart,
  loadMatchDetailsSuccess,
  loadMatchDetailsFailure,
  cleanMatchDetailsSuccess,
} from '../actions';
import { Status } from '../types';

const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${loadMatchDetailsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(loadMatchDetailsFailure, loadMatchDetailsSuccess)}`]: () => {
    return { isLoading: false };
  },
  [`${cleanMatchDetailsSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
