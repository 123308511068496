import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { Tournament } from 'types';
import { FavoriteIcon, Figure } from 'packages/shared/components';

import { getLiveMatchesCount } from './services';
import styles from './styles.scss';

type Props = {
  tournament: Tournament;
  onFavoriteToggle: () => void;
  activeDate: string;
  isTournamentUnique?: boolean;
  onClick?: () => void;
  useHoverEffect?: boolean;
  isFavorite?: boolean;
  className?: string;
};

const TournamentPreview = ({
  className,
  isFavorite = false,
  onFavoriteToggle,
  isTournamentUnique = false,
  tournament,
  useHoverEffect = false,
  onClick,
  activeDate,
}: Props) => {
  const {
    logo,
    countLiveMatches = 0,
    countMatches = 0,
    name,
    uniqueTournamentName,
    uniqueTournamentId,
    currentSeasonId,
  } = tournament;
  const liveMatchesCount = getLiveMatchesCount(countMatches, countLiveMatches, activeDate);

  return (
    <Link
      to={`/football/unique-tournament/${uniqueTournamentId}/season/${currentSeasonId}`}
      onClick={onClick}
      className={classnames(
        styles.tournamentPreview,
        { [styles.hoverable]: useHoverEffect },
        className,
      )}
    >
      <Figure src={logo} className={styles.logo} />
      <span className={styles.name}>{isTournamentUnique ? uniqueTournamentName : name}</span>

      <span className={styles.count}>
        {!!liveMatchesCount && <span className={styles.live}>{liveMatchesCount}</span>}
        {!!liveMatchesCount && !!countMatches && <>/</>}
        {!!countMatches && <>{countMatches}</>}
      </span>

      <FavoriteIcon
        isFavorite={isFavorite}
        onClick={onFavoriteToggle}
        className={styles.favoriteIcon}
      />
    </Link>
  );
};

export default TournamentPreview;
