import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadShotEventsStart,
  loadShotEventsSuccess,
  loadShotEventsFailure,
  cleanShotEventsSuccess,
} from '../actions';
import { Status } from '../types';

const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${combineActions(loadShotEventsStart, cleanShotEventsSuccess)}`]: () => {
    return defaultState;
  },
  [`${combineActions(loadShotEventsSuccess, loadShotEventsFailure)}`]: () => {
    return { isLoading: false };
  },
};

export default handleActions(reducerMap, defaultState);
