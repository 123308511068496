import styles from '../styles.scss';

import { TOP_HEADER_ID_BASE, LEFT_HEADER_ID_BASE, CELL_ID_BASE } from '../constants';

const getHighlightableElements = (columnIndex: number, rowIndex: number) => {
  const topHeaderElement = document.getElementById(`${TOP_HEADER_ID_BASE}${columnIndex}`);
  const leftHeaderElement = document.getElementById(`${LEFT_HEADER_ID_BASE}${rowIndex}`);
  const cellElementElement = document.getElementById(`${CELL_ID_BASE}${columnIndex}_${rowIndex}`);

  return [topHeaderElement, leftHeaderElement, cellElementElement];
};

export const addHighlightClassToTableElements = (columnIndex: number, rowIndex: number) => {
  const highlightableElements = getHighlightableElements(columnIndex, rowIndex);

  for (const highlightableElement of highlightableElements) {
    highlightableElement?.classList?.add(styles.hover);
  }
};

export const removeHighlightClassToTableElements = (columnIndex: number, rowIndex: number) => {
  const highlightableElements = getHighlightableElements(columnIndex, rowIndex);

  for (const highlightableElement of highlightableElements) {
    highlightableElement?.classList?.remove(styles.hover);
  }
};

export const addHighlightClassToVerticalCells = (columnIndex: number, rowIndex: number) => {
  const [topHeaderElement, leftHeaderElement, cellElementElement] = getHighlightableElements(
    columnIndex,
    rowIndex,
  );

  cellElementElement?.classList?.add(styles.hover);
};

export const removeHighlightClassFromVerticalCells = (columnIndex: number, rowIndex: number) => {
  const [topHeaderElement, leftHeaderElement, cellElementElement] = getHighlightableElements(
    columnIndex,
    rowIndex,
  );

  cellElementElement?.classList?.remove(styles.hover);
};
