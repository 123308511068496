import { createElement } from 'react';

import { Team } from 'types';
import { SelectItem } from 'packages/shared/components/Select/types';
import { ItemLabel } from 'packages/shared/components';

const mapTeamsToSelectItems = (teams: Team[]): SelectItem[] => {
  return [
    ...teams.map((team) => ({
      id: team.id,
      label: createElement(ItemLabel, { item: team, isLogoFirst: true, key: team.id }),
    })),
  ];
};

export default mapTeamsToSelectItems;
