import { SeasonStadiums } from 'packages/rest';

import { SeasonStadiumParams } from 'packages/rest/api/SeasonStadiums/types';

import { mapStadiums } from '../services';

export const loadStadiums = ({
  seasonId,
  limit,
  offset,
  ordering,
}: SeasonStadiumParams) => async () => {
  const {
    data: { results, count },
  } = await SeasonStadiums.loadSeasonStadiums({ seasonId, limit, offset, ordering });

  return {
    stadiums: mapStadiums(results),
    count,
  };
};
