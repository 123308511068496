import React, { memo, useCallback } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import isEmpty from 'is-empty-typed/lib';

import { MediaScreen, Tournament } from 'types';
import { FavoriteIcon } from 'packages/shared/components';
import { MobilePage } from 'router/types';
import { useNavigation } from 'packages/hooks';

import styles from './styles.scss';
import connect from './connect';

type Props = {
  tournaments: Tournament[];
  favoriteLeagues: Tournament[];
  favoritesActions: ActionCreatorsMapObject;
};

const TournamentList = ({ tournaments, favoriteLeagues, favoritesActions }: Props) => {
  const { appNavigate } = useNavigation(MediaScreen.Mobile);

  const getFavoriteIconHandler = useCallback(
    (league) => () => {
      favoritesActions.toggleLeague(league);
    },
    [],
  );

  const getTournamentClickHandler = ({ currentSeasonId, uniqueTournamentId }: Tournament) => () => {
    appNavigate(MobilePage.FootballSeason, { seasonId: currentSeasonId, uniqueTournamentId });
  };

  return (
    <div className={styles.tournaments}>
      {tournaments.map((tournament) => {
        const {
          uniqueTournamentName,
          uniqueTournamentId,
          countLiveMatches = 0,
          countMatches = 0,
        } = tournament;

        const isSeparatorVisible = !isEmpty(countLiveMatches) && !isEmpty(countMatches);
        const isTournamentFavorite = Boolean(
          favoriteLeagues.find((league) => league.uniqueTournamentId === uniqueTournamentId),
        );

        return (
          <div key={uniqueTournamentId} className={styles.tournament}>
            <div className={styles.name}>
              <span onClick={getTournamentClickHandler(tournament)}>{uniqueTournamentName}</span>
            </div>

            <div className={styles.gamesCount}>
              {!isEmpty(countLiveMatches) && (
                <span className={styles.live}>{countLiveMatches}</span>
              )}

              {isSeparatorVisible && <span>/</span>}

              {!isEmpty(countMatches) && <span>{countMatches}</span>}
            </div>

            <FavoriteIcon
              onClick={getFavoriteIconHandler(tournament)}
              isFavorite={isTournamentFavorite}
              className={styles.favoriteIcon}
            />
          </div>
        );
      })}
    </div>
  );
};

export default connect(memo(TournamentList));
