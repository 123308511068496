import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { teamDetailsSelectors } from 'packages/team-details';
import { teamActiveTournamentSeasonThunks } from 'packages/team-active-tournament-season';

const mapStateToProps = (state) => {
  return {
    tournaments: teamDetailsSelectors.getTournamentList(state),
    isLoading: teamDetailsSelectors.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teamActiveTournamentSeasonActions: bindActionCreators(
      teamActiveTournamentSeasonThunks,
      dispatch,
    ),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
