import { handleActions, ReducerMap } from 'redux-actions';

import { TournamentsExtendedCategory } from '../../../types';
import { categoryListActions } from '../../../actions';

const defaultState: TournamentsExtendedCategory | null = null;

const reducerMap: ReducerMap<TournamentsExtendedCategory | null, TournamentsExtendedCategory> = {
  [`${categoryListActions.loadSelectedCategorySuccess}`]: (state, { payload }) => {
    return payload;
  },
};

export default handleActions(reducerMap, defaultState);
