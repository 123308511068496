import { EventTeam, Event } from 'types';
import { Meta, EventDetailsData, EventSeries, AvailableTabs } from '../types';

import getState from './getState';

export const getData = (state): EventDetailsData => {
  return getState(state).data;
};

export const getEventSeries = (state): EventSeries | null => {
  return getData(state)?.eventSeries;
};

export const getActiveEvent = (state): Event | null => {
  return getData(state)?.event;
};

export const getActiveEventId = (state): number | undefined => {
  return getActiveEvent(state)?.id;
};

export const getActiveEventTeams = (state): [EventTeam, EventTeam] | undefined => {
  return getActiveEvent(state)?.teams;
};

export const getMeta = (state): Meta => {
  return getData(state).meta;
};

export const getAvailableTabs = (state): AvailableTabs => {
  return getMeta(state).availableTabs;
};
