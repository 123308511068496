import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadScheduledEventsStart = createAction(`${NAMESPACE}LOAD_SCHEDULED_EVENTS_START`);
export const loadScheduledEventsSuccess = createAction(`${NAMESPACE}LOAD_SCHEDULED_EVENTS_SUCCESS`);
export const loadScheduledEventsFailure = createAction(`${NAMESPACE}LOAD_SCHEDULED_EVENTS_FAILURE`);
export const loadMoreScheduledEventsStart = createAction(
  `${NAMESPACE}LOAD_MORE_SCHEDULED_EVENTS_START`,
);
export const loadMoreScheduledEventsSuccess = createAction(
  `${NAMESPACE}LOAD_MORE_SCHEDULED_EVENTS_SUCCESS`,
);
export const loadMoreScheduledEventsFailure = createAction(
  `${NAMESPACE}LOAD_MORE_SCHEDULED_EVENTS_FAILURE`,
);
export const cleanScheduledEventsSuccess = createAction(
  `${NAMESPACE}CLEAN_SCHEDULED_EVENTS_SUCCESS`,
);
export const cleanScheduledEventsFailure = createAction(
  `${NAMESPACE}CLEAN_SCHEDULED_EVENTS_FAILURE`,
);
export const updateScheduledEventsStart = createAction(`${NAMESPACE}UPDATE_SCHEDULED_EVENTS_START`);
export const updateScheduledEventsFailure = createAction(
  `${NAMESPACE}UPDATE_SCHEDULED_EVENTS_FAILURE`,
);
export const updateScheduledEventsSuccess = createAction(
  `${NAMESPACE}UPDATE_SCHEDULED_EVENTS_SUCCESS`,
);

export const subscribeForScheduledEventsUpdatesStart = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_SCHEDULED_EVENTS_UPDATES_START`,
);
export const subscribeForScheduledEventsUpdatesFailure = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_SCHEDULED_EVENTS_UPDATES_FAILURE`,
);
export const subscribeForScheduledEventsUpdatesSuccess = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_SCHEDULED_EVENTS_UPDATES_SUCCESS`,
);
