import React, { memo, RefObject, useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Row } from 'react-table';

import { Stadium } from 'packages/season-stadiums/types';
import { Ordering, StadiumsOrdering } from 'packages/rest/api/SeasonStadiums/types';
import { FlexibleTable, LoaderContainer } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Team } from 'types';

import { getTableSchema } from './services';
import styles from './styles.scss';

type Props = {
  data: Stadium[];
  ordering: StadiumsOrdering;
  forwardedRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  onTeamClick: (team: Team) => void;
  onHeaderCellClick: (orderingType: Ordering) => void;
  isCountryNameDisplayed?: boolean;
};

const StadiumsTable = ({
  onTeamClick,
  data,
  ordering,
  onHeaderCellClick,
  forwardedRef,
  isLoading,
  isCountryNameDisplayed,
}: Props) => {
  const { tournamentId = '', seasonId = '' } = useParams();
  const { appNavigate } = useNavigation();

  const columns = useMemo(
    () =>
      getTableSchema({
        onTeamClick,
        forwardedRef,
        onHeaderCellClick,
        ordering,
        isCountryNameDisplayed,
      }),
    [ordering, forwardedRef],
  );

  const setRowProps = useCallback(
    ({ original }: Row<Stadium>) => {
      const { id } = original;

      return {
        onMouseUp: () => {
          appNavigate(DesktopPage.FootballStadiumSummary, {
            stadiumId: id,
            uniqueTournamentId: tournamentId,
            seasonId,
          });
        },
      };
    },
    [tournamentId, seasonId],
  );

  return (
    <>
      <LoaderContainer isLoading={isLoading} isContentVisible className={styles.loaderContainer} />
      <FlexibleTable<Stadium>
        forwardedRef={forwardedRef}
        className={styles.table}
        data={data}
        setRowProps={setRowProps}
        columns={columns}
        isHeaderSticky
        isRowClickable
      />
    </>
  );
};

export default memo(StadiumsTable);
