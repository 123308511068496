import React, { memo, RefObject, useCallback, useRef } from 'react';
import i18n from 'i18n-smart';

import { Ordering, SquadOrdering } from 'packages/rest/api/TeamSquad/types';
import { Player } from 'packages/team-squad/types';
import { Pagination } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { PaginationParams } from 'packages/shared/components/Pagination/types';
import { DEFAULT_PLAYERS_LIMIT } from 'packages/team-squad/constants';
import { MobilePage } from 'router/types';
import { MediaScreen } from 'types';

import styles from './styles.scss';
import { PlayersTable, LoadingSkeleton } from '../../components';

type Props = {
  onOrderingClick: (ordering: Ordering) => void;
  onPaginationChange: (params: PaginationParams) => void;
  ordering: SquadOrdering;
  isInitialPageLoading: boolean;
  isNewPageLoading: boolean;
  players: Player[];
  playersCount: number;
  limit: number;
};

const TeamSquad = ({
  isInitialPageLoading,
  isNewPageLoading,
  onOrderingClick,
  onPaginationChange,
  ordering,
  players,
  playersCount,
  limit,
}: Props) => {
  const tableRef: RefObject<HTMLDivElement> = useRef(null);
  const { appNavigate } = useNavigation(MediaScreen.Mobile);

  const isLimitSelectable = playersCount > DEFAULT_PLAYERS_LIMIT;

  const handleRowClick = useCallback((player: Player) => {
    appNavigate(MobilePage.FootballPlayer, { playerId: player.id });
  }, []);

  return (
    <div className={styles.teamSquadContainer}>
      {isInitialPageLoading ? (
        <LoadingSkeleton className={styles.skeleton} elementsCount={limit} />
      ) : (
        <>
          <div className={styles.header}>{i18n.value('team.teamSquad.title')}</div>
          <PlayersTable
            containerRef={tableRef}
            onHeaderCellClick={onOrderingClick}
            onRowClick={handleRowClick}
            isLoading={isNewPageLoading}
            ordering={ordering}
            data={players}
          />
        </>
      )}

      <Pagination
        isLoading={isInitialPageLoading}
        isLimitSelectable={isLimitSelectable}
        amountOfItems={playersCount}
        pageSize={limit}
        onPaginationChange={onPaginationChange}
        useRightAlignment
      />
    </div>
  );
};

export default memo(TeamSquad);
