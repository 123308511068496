import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export * from './selectors';
export * from './actions';
export * from './thunks';

export { createReducer };
