import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  actualGamesSelectors,
  actualGamesThunks,
  commonThunks,
  statisticsSelectors,
  teamsSelectors,
  statisticsThunks,
  teamsThunks,
} from 'packages/player-summary';
import { playerDetailsSelectors } from 'packages/player-details';

const mapStateToProps = (state) => {
  return {
    tournaments: actualGamesSelectors.getData(state),
    isActualGamesLoading: actualGamesSelectors.getIsLoading(state),
    history: teamsSelectors.getTransferHistory(state),
    teams: teamsSelectors.getTeams(state),
    isTeamsLoading: teamsSelectors.getIsLoading(state),
    selectedSeasonId: statisticsSelectors.tournamentSeasons.getSelectedSeasonId(state),
    playerSummaryStatistics: statisticsSelectors.statistics.getData(state),
    isWholeStatisticsLoading: playerDetailsSelectors.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    commonActions: bindActionCreators(commonThunks, dispatch),
    actualGamesActions: bindActionCreators(actualGamesThunks, dispatch),
    teamsThunks: bindActionCreators(teamsThunks, dispatch),
    statisticsActions: bindActionCreators(statisticsThunks.statistics, dispatch),
    tournamentSeasonsActions: bindActionCreators(statisticsThunks.tournamentSeasons, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
