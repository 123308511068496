import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { historyActions } from 'packages/season-history/actions';
import { HistoryStatus } from 'packages/season-history/types';

const defaultState: HistoryStatus = {
  isLoading: true,
};

const reducerMap: ReducerMap<HistoryStatus, HistoryStatus> = {
  [`${historyActions.loadHistoryStart}`]: () => {
    return { isLoading: true };
  },

  [`${combineActions(
    historyActions.loadHistoryFailure,
    historyActions.loadHistorySuccess,
  )}`]: () => {
    return { isLoading: false };
  },
};

export default handleActions<any>(reducerMap, defaultState);
