import { Interfaces } from '../../types';
import getPath from './getPath';

const getStatus = (state): Interfaces.Filters.FiltersStatus => {
  return getPath(state).status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
