import React, { memo } from 'react';
import classnames from 'classnames';

import { BlockSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  isColumnDirectionActive?: boolean;
};

const Skeleton = ({ isColumnDirectionActive }: Props) => {
  return (
    <div
      className={classnames(styles.wrapper, { [styles.columnDirection]: isColumnDirectionActive })}
    >
      <BlockSkeleton />
      <BlockSkeleton />
      <BlockSkeleton />
    </div>
  );
};

export default memo(Skeleton);
