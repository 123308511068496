import getPath from './getPath';
import { Status } from '../types';

export const getStatus = (state): Status => {
  return getPath(state).status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
