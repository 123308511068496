import React, { FormEvent, memo, ReactElement } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { Label, PlainTextInput } from '../../components';
import { getValidatorByType } from './services';
import { InputValidationType } from '../../types';
import styles from './styles.scss';

type Props = {
  name: string;
  onRangeValuesChange: (startValue: any, endValue) => void;
  startValue?: string | number;
  endValue?: string | number;
  startPlaceholder?: string;
  endPlaceholder?: string;
  inputValidationType?: InputValidationType;
  label?: string | ReactElement;
  className?: string;
};

const TextRange = ({
  onRangeValuesChange,
  label,
  name,
  className,
  endValue = '',
  startValue = '',
  startPlaceholder = '',
  endPlaceholder = '',
  inputValidationType = InputValidationType.None,
}: Props) => {
  const validate = getValidatorByType(inputValidationType);
  const handleBlur = () => {
    const [firstValidatedValue, secondValidatedValue] = validate(
      String(startValue),
      String(endValue),
    );

    onRangeValuesChange(firstValidatedValue, secondValidatedValue);
  };

  const handleStartValueChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => {
    onRangeValuesChange(currentTarget.value, endValue);
  };

  const handleEndValueChange = ({ currentTarget }: FormEvent<HTMLInputElement>) => {
    onRangeValuesChange(startValue, currentTarget.value);
  };

  return (
    <div className={classnames(styles.textRangeInputContainer, className)}>
      {!isEmpty(label) && <Label name={name} label={label} />}
      <div className={styles.rangeInput}>
        <PlainTextInput
          name={name}
          onChange={handleStartValueChange}
          onBlur={handleBlur}
          placeholder={startPlaceholder}
          value={startValue}
          className={styles.textInput}
        />
        <div className={styles.dash}>-</div>
        <PlainTextInput
          onChange={handleEndValueChange}
          onBlur={handleBlur}
          placeholder={endPlaceholder}
          value={endValue}
          className={styles.textInput}
        />
      </div>
    </div>
  );
};

export default memo(TextRange);
