import { lazy } from 'react';

import PlayerSummary from './PlayerSummary';
import PlayerGames from './PlayerGames';
import SeasonGames from './SeasonGames';
import SeasonHistory from './SeasonHistory';
import SeasonSummary from './SeasonSummary';
import SeasonPlayerStatistics from './SeasonPlayerStatistics';
import SeasonTeamStatistics from './SeasonTeamStatistics';
import SeasonStandings from './SeasonStandings';
import StadiumSummary from './StadiumSummary';
import TeamGames from './TeamGames';
import TeamPlayers from './TeamPlayers';
import TeamSummary from './TeamSummary';
import TeamStandings from './TeamStandings';
import TeamSquad from './TeamSquad';
import SeasonStadiums from './SeasonStadiums';
import StadiumHome from './StadiumHome';
import Categories from './Categories';
import Favorites from './Favorites';
import Settings from './Settings';
import Search from './Search';

const Home = lazy(() => import('./Home'));
const Season = lazy(() => import('./Season'));
const Category = lazy(() => import('./Category'));
const Player = lazy(() => import('./Player'));
const Team = lazy(() => import('./Team'));
const Stadium = lazy(() => import('./Stadium'));
const Match = lazy(() => import('./Match'));

export {
  Category,
  Player,
  PlayerSummary,
  PlayerGames,
  Home,
  SeasonGames,
  Season,
  SeasonHistory,
  SeasonSummary,
  SeasonPlayerStatistics,
  SeasonTeamStatistics,
  SeasonStandings,
  StadiumSummary,
  TeamGames,
  TeamPlayers,
  TeamSummary,
  TeamStandings,
  TeamSquad,
  Team,
  SeasonStadiums,
  StadiumHome,
  Stadium,
  Categories,
  Favorites,
  Settings,
  Search,
  Match,
};
