import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  closeEventDetailsSuccess,
  loadEventDetailsStart,
  loadEventDetailsSuccess,
  loadEventDetailsFailure,
} from '../actions';
import { EventDetailsData, Tabs } from '../types';

const defaultState = {
  event: null,
  eventSeries: null,
  meta: {
    availableTabs: {
      [Tabs.Comments]: true,
      [Tabs.Statistics]: true,
      [Tabs.HeadToHead]: true,
      [Tabs.LineUp]: true,
      [Tabs.Standings]: true,
      [Tabs.Summary]: true,
    },
  },
};

const reducerMap: ReducerMap<EventDetailsData, any> = {
  [`${loadEventDetailsSuccess}`]: (state, { payload }) => {
    const { event, eventSeries, meta } = payload;
    const { availableTabs } = meta;
    const mergedAvailableTabs = {
      ...state.meta.availableTabs,
      ...availableTabs,
    };

    return {
      event,
      eventSeries,
      meta: {
        availableTabs: mergedAvailableTabs,
      },
    };
  },
  [`${combineActions(
    closeEventDetailsSuccess,
    loadEventDetailsFailure,
    loadEventDetailsStart,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
