import { initialEventsActions, commonActions } from '../../actions';
import { initActiveFilter } from '../filters';
import loadActualEvents from './loadActualEvents';

export const loadTeamEventsPage = (teamId: string) => async (dispatch) => {
  try {
    dispatch(initialEventsActions.loadInitialEventsStart());

    const { events, period, count } = await dispatch(loadActualEvents(teamId));

    dispatch(initActiveFilter(period));

    dispatch(initialEventsActions.loadInitialEventsSuccess(events));

    dispatch(commonActions.updateCountSuccess({ count }));
  } catch (e) {
    dispatch(initialEventsActions.loadInitialEventsFailure(e));
  }
};

export default loadTeamEventsPage;
