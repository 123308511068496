import { Event, EventTeam, Tournament } from 'types';

import { Favorites } from '../types';
import getPath from './getPath';

export const getData = (state): Favorites => {
  return getPath(state).data;
};

export const getFavoriteEvents = (state): Event[] => {
  return getData(state).events;
};

export const getFavoriteTeams = (state): EventTeam[] => {
  return getData(state).teams;
};

export const getFavoriteLeagues = (state): Tournament[] => {
  return getData(state).leagues;
};
