import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const configInitStart = createAction(`${NAMESPACE}INIT_START`);
export const configInitSuccess = createAction(`${NAMESPACE}INIT_SUCCESS`);
export const configInitFailure = createAction(`${NAMESPACE}INIT_FAILURE`);

export const configLoadLanguagesStart = createAction(`${NAMESPACE}LOAD_LANGUAGES_START`);
export const configLoadLanguagesSuccess = createAction(`${NAMESPACE}LOAD_LANGUAGES_SUCCESS`);
export const configLoadLanguagesFailure = createAction(`${NAMESPACE}LOAD_LANGUAGES_FAILURE`);

export const configLoadMessagesStart = createAction(`${NAMESPACE}LOAD_MESSAGES_START`);
export const configLoadMessagesSuccess = createAction(`${NAMESPACE}LOAD_MESSAGES_SUCCESS`);
export const configLoadMessagesFailure = createAction(`${NAMESPACE}LOAD_MESSAGES_FAILURE`);

export const configSetSettings = createAction(`${NAMESPACE}SET_SETTINGS`);

export const configSetLanguageStart = createAction(`${NAMESPACE}SET_LANGUAGE_START`);
export const configSetLanguageSuccess = createAction(`${NAMESPACE}SET_LANGUAGE_SUCCESS`);
export const configSetLanguageFailure = createAction(`${NAMESPACE}SET_LANGUAGE_FAILURE`);

export const failure = createAction(`${NAMESPACE}FAILURE`);
