import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { eventSummaryThunks, eventSummarySelectors } from 'packages/event-summary';
import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => {
  return {
    activeEvent: eventDetailsSelectors.getActiveEvent(state),
    eventSummary: eventSummarySelectors.getEventSummary(state),
    isLoading: eventSummarySelectors.getIsEventSummaryLoading(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    eventSummaryActions: bindActionCreators(eventSummaryThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
