import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  eventCommentarySelectors,
  eventCommentaryThunks,
  eventCommentaryActions,
} from 'packages/event-commentary';
import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => {
  return {
    activeEvent: eventDetailsSelectors.getActiveEvent(state),
    eventCommentary: eventCommentarySelectors.getEventCommentary(state),
    isLoading: eventCommentarySelectors.getIsEventCommentaryLoading(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    eventCommentaryActions: bindActionCreators(eventCommentaryThunks, dispatch),
    cleanEventCommentary: () => dispatch(eventCommentaryActions.cleanEventCommentary()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
