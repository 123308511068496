import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Team = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton width={20} height={20} marginLeft={16} marginRight={6} variant="circular" />
      <Skeleton height={12} width={60} variant="rectangular" />
    </div>
  );
};

export default memo(Team);
