import React, { memo } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';
import i18n from 'i18n-smart';

import { JerseySet } from 'packages/team-details/types';

import { JerseyCell, LoadingSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  jerseySet?: JerseySet;
  isLoading?: boolean;
  className?: string;
};

const JerseyKits = ({ jerseySet, isLoading, className }: Props) => {
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (isEmpty(jerseySet)) {
    return <></>;
  }

  return (
    <div className={classnames(styles.jerseyKitsContainer, className)}>
      <div className={styles.header}>{i18n.value('team.teamSummary.description.kits.title')}</div>
      <div className={styles.jerseyKitsGrid}>
        {!isEmpty(jerseySet.home) && (
          <JerseyCell
            label={i18n.value('team.teamSummary.description.kits.home')}
            jersey={jerseySet.home}
          />
        )}
        {!isEmpty(jerseySet.away) && (
          <JerseyCell
            label={i18n.value('team.teamSummary.description.kits.away')}
            jersey={jerseySet.away}
          />
        )}
        {!isEmpty(jerseySet.awaySecondary) && (
          <JerseyCell
            label={i18n.value('team.teamSummary.description.kits.awaySecondary')}
            jersey={jerseySet.awaySecondary}
          />
        )}
        {!isEmpty(jerseySet.goalkeeper) && (
          <JerseyCell
            label={i18n.value('team.teamSummary.description.kits.goalkeeper')}
            jersey={jerseySet.goalkeeper}
          />
        )}
      </div>
    </div>
  );
};

export default memo(JerseyKits);
