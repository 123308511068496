import React from 'react';
import { TeamComment } from 'packages/event-standings/types';

type Props = {
  teamComments: TeamComment[],
}

const TeamCommentsBlock = ({ teamComments }: Props) => {
  return <div>
    {teamComments.map(({ text, teamName, id }) => (<span key={id}>{teamName}: {text}</span>))}
  </div>
};

export default TeamCommentsBlock;
