import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { detailsSelectors, detailsThunks } from 'packages/team-summary';

const mapStateToProps = (state) => ({
  players: detailsSelectors.topPlayers.getData(state),
  isLoading: detailsSelectors.topPlayers.getIsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  topPlayersActions: bindActionCreators(detailsThunks.topPlayers, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
