import { GoalHappening, PlayerHappening, PlayerType } from 'packages/event-time-line/types';
import { SummaryHappeningType } from 'packages/events/types';

import { PlayerHappeningType } from '../../../../../types';

export const getPlayerHappeningType = (
  happening: PlayerHappening,
): PlayerHappeningType | undefined => {
  const { eventType } = happening;

  switch (eventType) {
    case SummaryHappeningType.SCORE_CHANGE: {
      const { isOwnGoal, isPenaltyGoal } = happening as GoalHappening;

      if (isOwnGoal) {
        return PlayerHappeningType.OwnGoal;
      } else if (isPenaltyGoal) {
        return PlayerHappeningType.PenaltyGoal;
      } else {
        return PlayerHappeningType.Goal;
      }
    }
    case SummaryHappeningType.ASSISTS: {
      return PlayerHappeningType.Assist;
    }
    case SummaryHappeningType.YELLOW_CARD: {
      return PlayerHappeningType.YellowCard;
    }
    case SummaryHappeningType.RED_CARD: {
      return PlayerHappeningType.RedCard;
    }
    case SummaryHappeningType.YELLOW_RED_CARD: {
      return PlayerHappeningType.YellowRedCard;
    }
    case SummaryHappeningType.SUBSTITUTION: {
      const { playerId: currentPlayerId, players } = happening;
      const currentPlayerHappening = players?.find(({ playerId }) => playerId === currentPlayerId);

      if (currentPlayerHappening) {
        if (currentPlayerHappening.playerType === PlayerType.SubstitutedIn) {
          return PlayerHappeningType.SubstitutionIn;
        } else {
          return PlayerHappeningType.SubstitutionOut;
        }
      }

      return PlayerHappeningType.Substitution;
    }
  }
};
