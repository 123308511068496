import React, { memo, ReactElement } from 'react';
import classnames from 'classnames';

import { Theme } from '../../../../types';
import styles from './styles.scss';

type Props = {
  homeHappeningElement?: ReactElement | null;
  chipElement: ReactElement;
  awayHappeningElement?: ReactElement | null;
  theme?: Theme;
};

const HappeningLine = ({
  homeHappeningElement,
  chipElement,
  awayHappeningElement,
  theme = Theme.Light,
}: Props) => {
  const shouldLeftComponentBeRendered = homeHappeningElement === null || !!homeHappeningElement;
  const shouldRightComponentBeRendered = awayHappeningElement === null || !!awayHappeningElement;
  const shouldLineBeRendered = shouldLeftComponentBeRendered || shouldRightComponentBeRendered;
  const shouldPaddingsNotBeAttached = !homeHappeningElement && !awayHappeningElement;

  return shouldLineBeRendered ? (
    <div
      className={classnames(styles.happeningRow, styles[theme], {
        [styles.paddingsNotAttached]: shouldPaddingsNotBeAttached,
        [styles.leftOffset]: shouldLeftComponentBeRendered,
        [styles.rightOffset]: shouldRightComponentBeRendered,
      })}
    >
      {shouldLeftComponentBeRendered && (
        <div className={styles.homeBlock}>{homeHappeningElement}</div>
      )}
      <div className={styles.chip}>{chipElement}</div>
      {shouldRightComponentBeRendered && (
        <div className={styles.awayBlock}>{awayHappeningElement}</div>
      )}
    </div>
  ) : (
    <></>
  );
};

export default memo(HappeningLine);
