import { SummaryHappeningType } from 'packages/events/types';

import {
  BaseHappening,
  TimeLinePeriodScores,
  PeriodHappening,
  HappeningEventPeriod,
  PeriodType,
  Period,
} from '../../types';

const mapPeriodStartHappening = (
  happening: BaseHappening,
  periodScores: TimeLinePeriodScores,
): PeriodHappening => {
  const { eventType, id } = happening;
  const periodType = getPeriodByHappening(happening);
  const periodScore = periodScores[periodType];

  return {
    id,
    eventType,
    periodType: periodType!,
    score: periodScore ? formatScore(periodScore) : '',
  };
};

export const formatScore = ({ home, away }) => {
  return `${home} - ${away}`;
};

export default mapPeriodStartHappening;

export const getPeriodByHappening = (happening: BaseHappening): HappeningEventPeriod => {
  const { period, periodType, eventType } = happening;

  if (periodType === PeriodType.RegularPeriod && period === Period.First) {
    return HappeningEventPeriod.FIRST_HALF;
  }

  if (periodType === PeriodType.RegularPeriod && period === Period.Second) {
    return HappeningEventPeriod.SECOND_HALF;
  }

  if (periodType === PeriodType.Overtime && period === Period.First) {
    return HappeningEventPeriod.FIRST_EXTRA;
  }

  if (periodType === PeriodType.Overtime && period === Period.Second) {
    return HappeningEventPeriod.SECOND_EXTRA;
  }

  if (periodType === PeriodType.Penalties) {
    return HappeningEventPeriod.PENALTIES;
  }

  if (eventType === SummaryHappeningType.BREAK_START) {
    return HappeningEventPeriod.HALFTIME;
  }

  throw new Error('Unexpected period');
};
