import React, { memo } from 'react';

import { Separator } from 'packages/shared/components';
import { TournamentsExtendedCategory } from 'packages/categories/types';
import { Category as CategoryView, Tournament } from 'types';

import { CategoryList, ListSkeleton } from '../../components';
import styles from './styles.scss';

type Props = {
  visibleCategories: CategoryView[];
  selectedCategory?: TournamentsExtendedCategory;
  isCategoriesLoading: boolean;
  isSelectedCategoryLoading: boolean;
  title?: string;
  selectedCategoryId: number | null;
  onCategoryClick: (category: CategoryView) => void;
  onTournamentClick: (tournament: Tournament) => void;
  onCategoryToggle: (category: CategoryView) => void;
  onExpandClick: () => void;
  isCollapsed: boolean;
  isPopularTournamentsLoading: boolean;
};

const CategoryListView = ({
  visibleCategories,
  isSelectedCategoryLoading,
  isCategoriesLoading,
  selectedCategory,
  title,
  selectedCategoryId,
  onCategoryClick,
  onTournamentClick,
  onCategoryToggle,
  onExpandClick,
  isCollapsed,
  isPopularTournamentsLoading,
}: Props) => {
  if (isCategoriesLoading || isPopularTournamentsLoading) {
    return <ListSkeleton elementsCount={10} />;
  }

  return (
    <div className={styles.container}>
      {title && (
        <>
          <div className={styles.title}>{title}</div>
          <Separator />
        </>
      )}

      <CategoryList
        visibleCategories={visibleCategories}
        isSelectedCategoryLoading={isSelectedCategoryLoading}
        isCategoriesLoading={isCategoriesLoading}
        selectedCategory={selectedCategory}
        selectedCategoryId={selectedCategoryId}
        onCategoryClick={onCategoryClick}
        onTournamentClick={onTournamentClick}
        onCategoryToggle={onCategoryToggle}
        onExpandClick={onExpandClick}
        isCollapsed={isCollapsed}
        useControlsHoverEffect
      />
    </div>
  );
};

export default memo(CategoryListView);
