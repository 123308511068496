import { handleActions, ReducerMap, combineActions } from 'redux-actions';

import * as actions from '../actions';
import { State } from '../types';

const defaultState: State = {
  activeDate: '',
};

const reducerMap: ReducerMap<State, any> = {
  [`${combineActions(actions.setActiveDate, actions.setPrevDate, actions.setNextDate)}`]: (
    state,
    { payload },
  ) => {
    const { activeDate } = payload;

    return { ...state, activeDate };
  },
};

export default handleActions(reducerMap, defaultState);
