import { handleActions, ReducerMap } from 'redux-actions';

import { OrderType } from 'types';

import { customFilterStateActions, commonActions } from '../../actions';
import { CUSTOM_FILTER_DEFAULT_STATE } from '../../constants';
import { Interfaces } from '../../types';

const defaultState = CUSTOM_FILTER_DEFAULT_STATE;

const reducerMap: ReducerMap<Interfaces.CustomFilter.CustomFilterState, any> = {
  [`${customFilterStateActions.customFilterUpdate}`]: (state, { payload }) => {
    return { ...state, ...payload };
  },
  [`${customFilterStateActions.updateConfigurableOrdering}`]: (state, { payload: columnType }) => {
    const { columnConfigurationFilter } = state;
    const { configurableOrdering } = columnConfigurationFilter;
    let updatedOrdering;

    if (configurableOrdering[columnType] === OrderType.Descending) {
      updatedOrdering = { [columnType]: OrderType.Ascending };
    } else {
      updatedOrdering = { [columnType]: OrderType.Descending };
    }

    return {
      ...state,
      columnConfigurationFilter: {
        ...columnConfigurationFilter,
        configurableOrdering: updatedOrdering,
      },
    };
  },
  [`${customFilterStateActions.setCommonOrdering}`]: (state, payload) => {
    const { columnConfigurationFilter } = state;

    return {
      ...state,
      columnConfigurationFilter: {
        ...columnConfigurationFilter,
        commonOrdering: payload,
      },
    };
  },
  [`${commonActions.cleanPlayerStatisticsSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
