import { PlayerHappening } from 'packages/event-time-line/types';

import { getPlayerHappeningType } from './getPlayerHappeningType';
import { PlayerHappeningType } from '../../../../../types';

export const countHappeningsByType = (happenings: PlayerHappening[]) => {
  const happeningTypesCounts: { id: number; type: PlayerHappeningType; count: number }[] = [];

  for (const happening of happenings) {
    const playerHappeningType = getPlayerHappeningType(happening);

    if (playerHappeningType) {
      const foundHappeningTypeIndex = happeningTypesCounts.findIndex(
        ({ type }) => type === playerHappeningType,
      );

      if (foundHappeningTypeIndex >= 0) {
        happeningTypesCounts[foundHappeningTypeIndex].count++;
      } else {
        happeningTypesCounts.push({
          id: happening.id,
          type: playerHappeningType,
          count: 1,
        });
      }
    }
  }

  return happeningTypesCounts;
};
