import React, { memo } from 'react';

import { ActualEvents } from 'packages/season-events-ui';
import { SeasonCompactStandings } from 'packages/season-standings-ui';
import { Newcomers } from 'packages/season-newcomers-ui';
import { TopBoard } from 'packages/season-top-board-ui';

import styles from './styles.scss';

const SeasonSummary = () => {
  return (
    <div className={styles.seasonSummary}>
      <SeasonCompactStandings.Mobile />
      <ActualEvents.Mobile />
      <TopBoard.Mobile />
      <Newcomers.Mobile />
    </div>
  );
};

export default memo(SeasonSummary);
