import React from 'react';
import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed/lib';

import styles from './styles.scss';

type Props = {
  years: number;
  months: number;
  days: number;
};

const InTeamPopoverContent = ({ years, months, days }: Props) => {
  return (
    <div className={styles.wrapper}>
      {isEmpty(years) && isEmpty(months) ? (
        i18n.pluralize('team.teamSquad.popover.inTeamSince.days', { value: days })
      ) : (
        <>
          {i18n.pluralize('common.yearLabel', { value: years })}{' '}
          {i18n.pluralize('team.teamSquad.popover.inTeamSince.month', { value: months })}
        </>
      )}
    </div>
  );
};
export default InTeamPopoverContent;
