export enum PlayerHappeningType {
  SCORE_CHANGE = 'SCORE_CHANGE',
  YELLOW_CARD = 'YELLOW_CARD',
  YELLOW_RED_CARD = 'YELLOW_RED_CARD',
  RED_CARD = 'RED_CARD',
  ASSISTS = 'ASSISTS',
  SUBSTITUTION_IN = 'SUBSTITUTION_IN',
  SUBSTITUTION_OUT = 'SUBSTITUTION_OUT',
}

export enum SummaryHappeningType {
  SCORE_CHANGE = 'SCORE_CHANGE',
  YELLOW_CARD = 'YELLOW_CARD',
  YELLOW_RED_CARD = 'YELLOW_RED_CARD',
  RED_CARD = 'RED_CARD',
  ASSISTS = 'ASSISTS',
  SUBSTITUTION = 'SUBSTITUTION',
  PERIOD_START = 'PERIOD_START',
  PENALTY_SHOOTOUT = 'PENALTY_SHOOTOUT',
  PENALTY_MISSED = 'PENALTY_MISSED',
  BREAK_START = 'BREAK_START',
  INJURY = 'INJURY',
}
