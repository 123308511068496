import React, { memo } from 'react';

import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { TournamentChipList } from 'packages/shared/components';

import DetailsSelector from '../../../DetailsSelector';
import { LoadingSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  tournaments?: SeasonsExtendedTournament[];
  onTournamentClick: (tournament: SeasonsExtendedTournament) => void;
  isLoading?: boolean;
};

const TournamentSeasonDetails = ({ tournaments, onTournamentClick, isLoading = false }: Props) => {
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <div className={styles.detailsSelectorContainer}>
      <DetailsSelector.Mobile className={styles.detailsSelector} />
      <TournamentChipList.Mobile tournaments={tournaments} onTournamentClick={onTournamentClick} />
    </div>
  );
};

export default memo(TournamentSeasonDetails);
