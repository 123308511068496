import React from 'react';
import i18n from 'i18n-smart';

import { Input, Separator } from 'packages/shared/components';
import { InputValidationType } from 'packages/shared/components/Input/types';
import { seasonPlayerStatisticsConstants } from 'packages/season-player-statistics';
import {
  RangeFilterColumnsView,
  ActiveColumnsState,
  RangeFilterState,
  Column,
  Range,
  ColumnType,
  StatType,
} from 'packages/season-player-statistics/types';

import styles from './styles.scss';

const { COLUMN_TEMPLATE_BY_TYPE } = seasonPlayerStatisticsConstants;

type Props = {
  onRangeGroupUpdate: (type: ColumnType, updates?: Range) => void;
  onColumnToggle: (type: ColumnType) => void;
  columnConfigurationSchema: RangeFilterColumnsView;
  rangeFilterState: RangeFilterState;
  activeColumnsState: ActiveColumnsState;
  activeStatType: StatType;
  columns: Column[];
};

const ColumnsConfiguration = ({
  columnConfigurationSchema,
  onRangeGroupUpdate,
  rangeFilterState,
  onColumnToggle,
  activeStatType,
  activeColumnsState,
  columns,
}: Props) => {
  const commonColumnRangeValidationType =
    activeStatType === StatType.All ? InputValidationType.Integer : InputValidationType.Float;

  const getCheckboxToggleHandler = (type) => () => {
    onColumnToggle(type);
  };

  const getRangeValuesChangeHandler = (type) => (from, to) => {
    onRangeGroupUpdate(type, { from, to });
  };

  return (
    <div className={styles.columnsConfigurationContainer}>
      {columnConfigurationSchema.map((rangeFilterGroups, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.column} key={index}>
          {rangeFilterGroups.map(({ rangeFilters, type }) => (
            <div key={type} className={styles.columnsConfigurationGroup}>
              <div className={styles.columnsGroupHeader}>
                <div className={styles.title}>
                  {i18n.value(
                    `season.playerStatistics.customFilter.columnConfiguration.columnGroup.${type}`,
                  )}
                </div>
                <Separator className={styles.separator} />
              </div>
              <div className={styles.columnGroupsContent}>
                {rangeFilters.map(({ type }) => (
                  <div key={type} className={styles.columnGroup}>
                    <Input.Checkbox
                      label={i18n.value(
                        `playerStatistics.customFilter.columnConfiguration.columnName.${type}`,
                      )}
                      name={type}
                      isChecked={!!activeColumnsState[type]}
                      isDisabled={!activeColumnsState[type] && columns.length === 10}
                      onToggle={getCheckboxToggleHandler(type)}
                    />
                    {!!activeColumnsState[type] && (
                      <Input.TextRange
                        onRangeValuesChange={getRangeValuesChangeHandler(type)}
                        startValue={rangeFilterState[type]?.from}
                        endValue={rangeFilterState[type]?.to}
                        inputValidationType={
                          COLUMN_TEMPLATE_BY_TYPE[type].validationType ||
                          commonColumnRangeValidationType
                        }
                        name={type}
                        startPlaceholder="0"
                        endPlaceholder="∞"
                      />
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default ColumnsConfiguration;
