import getPath from './getPath';
import { EventActiveFilters, Tournament, EventsFilters } from '../../types';

export const getData = (state): EventsFilters => {
  return getPath(state).data;
};

export const getFilters = (state): Tournament[] => {
  return getData(state).tournaments;
};

export const getDefaultPeriod = (state): [string, string] => {
  return getData(state).defaultPeriod;
};

export const getActiveFilter = (state): EventActiveFilters => {
  return getData(state).activeFilters;
};
