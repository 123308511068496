import instance from '../../../axios';
import { SeasonTopPlayersParams } from '../types';

const DEFAULT_TOP_PLAYERS_LIMIT = 10;

export const loadSeasonTopPlayers = ({
  seasonId,
  teamIds,
  ordering,
  limit = DEFAULT_TOP_PLAYERS_LIMIT,
}: SeasonTopPlayersParams) => {
  return instance({
    method: 'get',
    url: `/seasons/${seasonId}/top_players/`,
    params: {
      limit,
      team: teamIds?.join(','),
      ordering,
    },
  });
};
