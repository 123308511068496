import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Data } from '../types';

import {
  loadLastGamesStart,
  loadLastGamesSuccess,
  loadLastGamesFailure,
  cleanLastGamesSuccess,
} from '../actions';

const defaultState = [];

const reducerMap: ReducerMap<Data, Data> = {
  [`${loadLastGamesSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(loadLastGamesStart, loadLastGamesFailure, cleanLastGamesSuccess)}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
