export enum Size {
  Md = 'md',
  Sm = 'sm',
  Lg = 'lg',
}

export enum Theme {
  Light = 'light',
  Dark = 'dark',
}
