import { ActionCreatorsMapObject } from 'redux';
import React, { memo, useCallback, useEffect } from 'react';

import { History as SeasonHistory } from 'packages/season-history/types';
import { SeasonDetails } from 'packages/season-details/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Team, Tournament } from 'types';

import SeasonHistoryView from './views';
import connect from './connect';

type Props = {
  history: SeasonHistory[];
  isLoading: boolean;
  seasonHistoryActions: ActionCreatorsMapObject;
  seasonDetails: SeasonDetails;
  activeTournament?: Tournament;
};

const extendSeasonHistory = (
  SeasonHistory: typeof SeasonHistoryView[keyof typeof SeasonHistoryView],
) =>
  memo(({ history, seasonHistoryActions, isLoading, seasonDetails, activeTournament }: Props) => {
    const { appNavigate } = useNavigation();

    useEffect(() => {
      if (seasonDetails?.uniqueTournament?.id) {
        seasonHistoryActions.loadHistoryPage(seasonDetails.uniqueTournament.id);

        return () => {
          seasonHistoryActions.cleanHistory();
        };
      }
    }, [seasonDetails?.uniqueTournament?.id]);

    const handleSeasonClick = useCallback(
      (year: string) => {
        const seasonId = seasonDetails.seasons.find((season) => season.year === year)?.id;

        if (seasonId && seasonDetails?.uniqueTournament?.id) {
          appNavigate(DesktopPage.FootballSeason, {
            seasonId,
            uniqueTournamentId: seasonDetails.uniqueTournament.id,
          });
        }
      },
      [seasonDetails?.seasons, seasonDetails?.uniqueTournament?.id],
    );

    const getTeamClickHandler = useCallback(
      (seasonId: string) => {
        return (team: Team) => {
          if (activeTournament) {
            appNavigate(DesktopPage.FootballTeamSummaryDescription, {
              teamId: team.id,
              uniqueTournamentId: activeTournament.uniqueTournamentId,
              seasonId,
            });
          }
        };
      },
      [activeTournament],
    );

    return (
      <SeasonHistory
        isLoading={isLoading}
        history={history}
        onSeasonClick={handleSeasonClick}
        teamClickGetter={getTeamClickHandler}
      />
    );
  });

export default {
  Desktop: connect(extendSeasonHistory(SeasonHistoryView.Desktop)),
  DesktopNarrow: connect(extendSeasonHistory(SeasonHistoryView.DesktopNarrow)),
  Tablet: connect(extendSeasonHistory(SeasonHistoryView.Tablet)),
  TabletNarrow: connect(extendSeasonHistory(SeasonHistoryView.TabletNarrow)),
  Mobile: connect(extendSeasonHistory(SeasonHistoryView.Mobile)),
};
