import React from 'react';
import classnames from 'classnames';

import { Grid } from 'modules/shared/layouts';
import { Advertisement } from 'packages/shared/components';
import { FeaturedOdds } from 'packages/odds-ui';
import { SeasonStadiums as Stadiums } from 'packages/season-stadiums-ui';
import { GridColumnId } from 'types';

import styles from './styles.scss';

const SeasonStadiums = () => {
  return (
    <Grid.DoubleColumn
      isSecondaryOpen={false}
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
    >
      <div key={GridColumnId.LeftBar} className={classnames(styles.leftBar)}>
        <Stadiums.Desktop />
      </div>

      <div key={GridColumnId.Primary} className={classnames(styles.sideBar)}>
        <Advertisement />
        <FeaturedOdds.Desktop />
      </div>
    </Grid.DoubleColumn>
  );
};

export default SeasonStadiums;
