import { Event } from 'packages/events/types';

export const getEventsAfterLiveUpdate = (oldEvents: Event[], newEvents: Event[]) => {
  const updatingEvents: Event[] = [];

  for (const event of oldEvents) {
    const updatedEvent = newEvents.find(({ id }) => id === event.id);

    if (updatedEvent) {
      updatingEvents.push(updatedEvent);
    } else {
      updatingEvents.push(event);
    }
  }

  return updatingEvents;
};
