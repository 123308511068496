import instance from 'packages/rest/axios';

import { TeamsParams } from '../types';

export const loadTeams = ({ playerId }: TeamsParams) => {
  return instance({
    method: 'get',
    url: `/players/${playerId}/teams`,
  });
};
