import { OrderingKey, RangeParameter } from 'packages/rest/api/SeasonTeamStatistics/types';
import { InputValidationType } from 'packages/shared/components/Input/types';
import { OrderType } from 'types';

import {
  Column,
  ColumnConfigurationFilter,
  ColumnType,
  CustomFilterState,
  GeneralFilter,
  Ordering,
  PresetType,
  PresetView,
  RangeType,
  StatType,
} from '../types';

export const NAMESPACE = 'SEASON-TEAM-STATISTICS::';
export const CUSTOM_PRESET_SESSION_KEY = 'team-player-statistics-custom-preset';
export const INITIAL_TEAMS_LIMIT = 30;
export const DEFAULT_TEAMS_LIMIT = 30;

export const COLUMN_TEMPLATE_BY_TYPE: Record<ColumnType, Column> = {
  [ColumnType.Rank]: {
    type: ColumnType.Rank,
    columnKey: 'rank',
    isDisabled: true,
  },
  [ColumnType.ClubLogo]: {
    type: ColumnType.ClubLogo,
    columnKey: 'clubLogo',
    isDisabled: true,
  },
  [ColumnType.Club]: {
    type: ColumnType.Club,
    columnKey: 'team',
    isDisabled: true,
  },
  [ColumnType.TotalShots]: {
    type: ColumnType.TotalShots,
    columnKey: 'totalShots',
  },
  [ColumnType.ShotsBlocked]: {
    type: ColumnType.ShotsBlocked,
    columnKey: 'shotsBlocked',
  },
  [ColumnType.ShotsOffGoal]: {
    type: ColumnType.ShotsOffGoal,
    columnKey: 'shotsOffGoal',
  },
  [ColumnType.ShotsOnGoal]: {
    type: ColumnType.ShotsOnGoal,
    columnKey: 'shotsOnGoal',
  },
  [ColumnType.ShotsOnPost]: {
    type: ColumnType.ShotsOnPost,
    columnKey: 'shotsOnPost',
  },
  [ColumnType.ShotsOnBar]: {
    type: ColumnType.ShotsOnBar,
    columnKey: 'shotsOnBar',
  },
  [ColumnType.GoalsConceded]: {
    type: ColumnType.GoalsConceded,
    columnKey: 'goalsConceded',
  },
  [ColumnType.ShootingEfficiency]: {
    type: ColumnType.ShootingEfficiency,
    columnKey: 'shootingEfficiency',
    validationType: InputValidationType.Float,
  },
  [ColumnType.ShotsOffGoal]: {
    type: ColumnType.ShotsOffGoal,
    columnKey: 'shotsOffGoal',
  },
  [ColumnType.ShotsOnGoal]: {
    type: ColumnType.ShotsOnGoal,
    columnKey: 'shotsOnGoal',
  },
  [ColumnType.Possession]: {
    type: ColumnType.Possession,
    columnKey: 'possession',
    validationType: InputValidationType.Float,
  },
  [ColumnType.Offside]: {
    type: ColumnType.Offside,
    columnKey: 'offside',
  },
  [ColumnType.YellowCards]: {
    type: ColumnType.YellowCards,
    columnKey: 'yellowCards',
  },
  [ColumnType.YellowRedCards]: {
    type: ColumnType.YellowRedCards,
    columnKey: 'yellowRedCards',
  },
  [ColumnType.RedCards]: {
    type: ColumnType.RedCards,
    columnKey: 'redCards',
  },
  [ColumnType.CornerKicks]: {
    type: ColumnType.CornerKicks,
    columnKey: 'cornerKicks',
  },
  [ColumnType.FreeKicks]: {
    type: ColumnType.FreeKicks,
    columnKey: 'freeKicks',
  },
  [ColumnType.GamesPlayed]: {
    type: ColumnType.GamesPlayed,
    columnKey: 'gamesPlayed',
    validationType: InputValidationType.Integer,
  },
  [ColumnType.Headed]: {
    type: ColumnType.Headed,
    columnKey: 'headed',
  },
  [ColumnType.FootGoals]: {
    type: ColumnType.FootGoals,
    columnKey: 'footGoals',
  },
  [ColumnType.GoalsScored]: {
    type: ColumnType.GoalsScored,
    columnKey: 'goalsScored',
  },
};

export const RANGE_FILTER_PARAMETER_BY_STAT_TYPE = {
  [StatType.All]: {
    [RangeType.MinTotalShots]: RangeParameter.MinGoalAttempts,
    [RangeType.MaxTotalShots]: RangeParameter.MaxGoalAttempts,
    [RangeType.MinShotsOnGoal]: RangeParameter.MinShotsOnGoal,
    [RangeType.MaxShotsOnGoal]: RangeParameter.MaxShotsOnGoal,
    [RangeType.MinShotsOffGoal]: RangeParameter.MinShotsOffGoal,
    [RangeType.MaxShotsOffGoal]: RangeParameter.MaxShotsOffGoal,
    [RangeType.MinShotsBlocked]: RangeParameter.MinShotsBlocked,
    [RangeType.MaxShotsBlocked]: RangeParameter.MaxShotsBlocked,
    [RangeType.MinShotsOnPost]: RangeParameter.MinShotsOnPost,
    [RangeType.MaxShotsOnPost]: RangeParameter.MaxShotsOnPost,
    [RangeType.MinShotsOnBar]: RangeParameter.MinShotsOnBar,
    [RangeType.MaxShotsOnBar]: RangeParameter.MaxShotsOnBar,
    [RangeType.MinShootingEfficiency]: RangeParameter.MinAverageShootingEfficiency,
    [RangeType.MaxShootingEfficiency]: RangeParameter.MaxAverageShootingEfficiency,
    [RangeType.MinPossession]: RangeParameter.MinAverageBallPossession,
    [RangeType.MaxPossession]: RangeParameter.MaxAverageBallPossession,
    [RangeType.MinCornerKicks]: RangeParameter.MinCornerKicks,
    [RangeType.MaxCornerKicks]: RangeParameter.MaxCornerKicks,
    [RangeType.MinFreeKicks]: RangeParameter.MinFreeKicks,
    [RangeType.MaxFreeKicks]: RangeParameter.MaxFreeKicks,
    [RangeType.MinOffside]: RangeParameter.MinOffside,
    [RangeType.MaxOffside]: RangeParameter.MaxOffside,
    [RangeType.MinYellowCards]: RangeParameter.MinYellowCards,
    [RangeType.MaxYellowCards]: RangeParameter.MaxYellowCards,
    [RangeType.MinYellowRedCards]: RangeParameter.MinYellowRedCards,
    [RangeType.MaxYellowRedCards]: RangeParameter.MaxYellowRedCards,
    [RangeType.MinRedCards]: RangeParameter.MinRedCards,
    [RangeType.MaxRedCards]: RangeParameter.MaxRedCards,
    [RangeType.MinGoalConceded]: RangeParameter.MinGoalsConceded,
    [RangeType.MaxGoalConceded]: RangeParameter.MaxGoalsConceded,
    [RangeType.MinGoalsScored]: RangeParameter.MinGoalsScored,
    [RangeType.MaxGoalsScored]: RangeParameter.MaxGoalsScored,
    [RangeType.MinHeaded]: RangeParameter.MinGoalsByHead,
    [RangeType.MaxHeaded]: RangeParameter.MaxGoalsByHead,
    [RangeType.MinFootGoals]: RangeParameter.MinGoalsByFoot,
    [RangeType.MaxFootGoals]: RangeParameter.MaxGoalsByFoot,
    [RangeType.MinGamesPlayed]: RangeParameter.MinGamesPlayed,
    [RangeType.MaxGamesPlayed]: RangeParameter.MaxGamesPlayed,
  },
  [StatType.PerGame]: {
    [RangeType.MinTotalShots]: RangeParameter.MinAverageGoalAttempts,
    [RangeType.MaxTotalShots]: RangeParameter.MaxAverageGoalAttempts,
    [RangeType.MinShotsOnGoal]: RangeParameter.MinAverageShotsOnGoal,
    [RangeType.MaxShotsOnGoal]: RangeParameter.MaxAverageShotsOnGoal,
    [RangeType.MinShotsOffGoal]: RangeParameter.MinAverageShotsOffGoal,
    [RangeType.MaxShotsOffGoal]: RangeParameter.MaxAverageShotsOffGoal,
    [RangeType.MinShotsBlocked]: RangeParameter.MinAverageShotsBlocked,
    [RangeType.MaxShotsBlocked]: RangeParameter.MaxAverageShotsBlocked,
    [RangeType.MinShotsOnPost]: RangeParameter.MinAverageShotsOnPost,
    [RangeType.MaxShotsOnPost]: RangeParameter.MaxAverageShotsOnPost,
    [RangeType.MinShotsOnBar]: RangeParameter.MinAverageShotsOnBar,
    [RangeType.MaxShotsOnBar]: RangeParameter.MaxAverageShotsOnBar,
    [RangeType.MinShootingEfficiency]: RangeParameter.MinAverageShootingEfficiency,
    [RangeType.MaxShootingEfficiency]: RangeParameter.MaxAverageShootingEfficiency,
    [RangeType.MinPossession]: RangeParameter.MinAverageBallPossession,
    [RangeType.MaxPossession]: RangeParameter.MaxAverageBallPossession,
    [RangeType.MinCornerKicks]: RangeParameter.MinAverageCornerKicks,
    [RangeType.MaxCornerKicks]: RangeParameter.MaxAverageCornerKicks,
    [RangeType.MinFreeKicks]: RangeParameter.MinAverageFreeKicks,
    [RangeType.MaxFreeKicks]: RangeParameter.MaxAverageFreeKicks,
    [RangeType.MinOffside]: RangeParameter.MinAverageOffside,
    [RangeType.MaxOffside]: RangeParameter.MaxAverageOffside,
    [RangeType.MinYellowCards]: RangeParameter.MinAverageYellowCards,
    [RangeType.MaxYellowCards]: RangeParameter.MaxAverageYellowCards,
    [RangeType.MinRedCards]: RangeParameter.MinAverageRedCards,
    [RangeType.MaxRedCards]: RangeParameter.MaxAverageRedCards,
    [RangeType.MinYellowRedCards]: RangeParameter.MinAverageYellowRedCards,
    [RangeType.MaxYellowRedCards]: RangeParameter.MaxAverageYellowRedCards,
    [RangeType.MinGoalConceded]: RangeParameter.MinAverageGoalsConceded,
    [RangeType.MaxGoalConceded]: RangeParameter.MaxAverageGoalsConceded,
    [RangeType.MinGoalsScored]: RangeParameter.MinAverageGoalsScored,
    [RangeType.MaxGoalsScored]: RangeParameter.MaxAverageGoalsScored,
    [RangeType.MinHeaded]: RangeParameter.MinAverageGoalsByHead,
    [RangeType.MaxHeaded]: RangeParameter.MaxAverageGoalsByHead,
    [RangeType.MinFootGoals]: RangeParameter.MinAverageGoalsByFoot,
    [RangeType.MaxFootGoals]: RangeParameter.MaxAverageGoalsByFoot,
    [RangeType.MinGamesPlayed]: RangeParameter.MinGamesPlayed,
    [RangeType.MaxGamesPlayed]: RangeParameter.MaxGamesPlayed,
  },
};

export const FILTER_ORDERING_BY_STAT_TYPE = {
  [StatType.All]: {
    [ColumnType.Club]: OrderingKey.TeamName,
    [ColumnType.TotalShots]: OrderingKey.GoalAttempts,
    [ColumnType.ShotsOnGoal]: OrderingKey.ShotsOnGoal,
    [ColumnType.ShotsOffGoal]: OrderingKey.ShotsOffGoal,
    [ColumnType.ShotsBlocked]: OrderingKey.ShotsBlocked,
    [ColumnType.ShotsOnPost]: OrderingKey.ShotsOnPost,
    [ColumnType.ShotsOnBar]: OrderingKey.ShotsOnBar,
    [ColumnType.ShootingEfficiency]: OrderingKey.ShootingEfficiency,
    [ColumnType.Possession]: OrderingKey.BallPossession,
    [ColumnType.CornerKicks]: OrderingKey.CornerKicks,
    [ColumnType.FreeKicks]: OrderingKey.FreeKicks,
    [ColumnType.Offside]: OrderingKey.Offside,
    [ColumnType.YellowCards]: OrderingKey.YellowCards,
    [ColumnType.YellowRedCards]: OrderingKey.YellowRedCards,
    [ColumnType.RedCards]: OrderingKey.RedCards,
    [ColumnType.GoalsConceded]: OrderingKey.GoalConceded,
    [ColumnType.GoalsScored]: OrderingKey.GoalsScored,
    [ColumnType.Headed]: OrderingKey.GoalsByHead,
    [ColumnType.FootGoals]: OrderingKey.GoalsByFoot,
    [ColumnType.GamesPlayed]: OrderingKey.GamesPlayed,
  },
  [StatType.PerGame]: {
    [ColumnType.Club]: OrderingKey.TeamName,
    [ColumnType.TotalShots]: OrderingKey.AverageGoalAttempts,
    [ColumnType.ShotsOnGoal]: OrderingKey.AverageShotsOnGoal,
    [ColumnType.ShotsOffGoal]: OrderingKey.AverageShotsOffGoal,
    [ColumnType.ShotsBlocked]: OrderingKey.AverageShotsBlocked,
    [ColumnType.ShotsOnPost]: OrderingKey.AverageShotsOnPost,
    [ColumnType.ShotsOnBar]: OrderingKey.AverageShotsOnBar,
    [ColumnType.ShootingEfficiency]: OrderingKey.AverageShootingEfficiency,
    [ColumnType.Possession]: OrderingKey.AverageBallPossession,
    [ColumnType.CornerKicks]: OrderingKey.AverageCornerKicks,
    [ColumnType.FreeKicks]: OrderingKey.AverageFreeKicks,
    [ColumnType.Offside]: OrderingKey.AverageOffside,
    [ColumnType.YellowCards]: OrderingKey.AverageYellowCards,
    [ColumnType.YellowRedCards]: OrderingKey.AverageYellowRedCards,
    [ColumnType.RedCards]: OrderingKey.AverageRedCards,
    [ColumnType.GoalsConceded]: OrderingKey.AverageGoalConceded,
    [ColumnType.GoalsScored]: OrderingKey.AverageGoalsScored,
    [ColumnType.Headed]: OrderingKey.AverageGoalsByHead,
    [ColumnType.FootGoals]: OrderingKey.AverageGoalsByFoot,
    [ColumnType.GamesPlayed]: OrderingKey.GamesPlayed,
  },
};

export const DEFAULT_COLUMNS = [
  COLUMN_TEMPLATE_BY_TYPE[ColumnType.Rank],
  COLUMN_TEMPLATE_BY_TYPE[ColumnType.ClubLogo],
  COLUMN_TEMPLATE_BY_TYPE[ColumnType.Club],
];

export const COLUMNS_BY_PRESET_TYPE = {
  [PresetType.Summary]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GoalsScored],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GoalsConceded],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Possession],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.ShotsOnGoal],
  ],
  [PresetType.Goals]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GoalsScored],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.ShootingEfficiency],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Headed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.FootGoals],
  ],
  [PresetType.Attack]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.TotalShots],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.ShotsOnGoal],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Possession],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.CornerKicks],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.Offside],
  ],
  [PresetType.Aggression]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.YellowCards],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.YellowRedCards],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.RedCards],
  ],
  [PresetType.Defence]: [
    ...DEFAULT_COLUMNS,
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GamesPlayed],
    COLUMN_TEMPLATE_BY_TYPE[ColumnType.GoalsConceded],
  ],
};

export const ACTIVE_COLUMNS_BY_PRESET_TYPE = {
  [PresetType.Summary]: {
    [ColumnType.GamesPlayed]: true,
    [ColumnType.GoalsScored]: true,
    [ColumnType.GoalsConceded]: true,
    [ColumnType.Possession]: true,
    [ColumnType.ShotsOnGoal]: true,
  },
  [PresetType.Goals]: {
    [ColumnType.GamesPlayed]: true,
    [ColumnType.GoalsScored]: true,
    [ColumnType.ShootingEfficiency]: true,
    [ColumnType.Headed]: true,
    [ColumnType.FootGoals]: true,
  },
  [PresetType.Attack]: {
    [ColumnType.GamesPlayed]: true,
    [ColumnType.TotalShots]: true,
    [ColumnType.ShotsOnGoal]: true,
    [ColumnType.Possession]: true,
    [ColumnType.CornerKicks]: true,
    [ColumnType.Offside]: true,
  },
  [PresetType.Aggression]: {
    [ColumnType.GamesPlayed]: true,
    [ColumnType.YellowCards]: true,
    [ColumnType.YellowRedCards]: true,
    [ColumnType.RedCards]: true,
  },
  [PresetType.Defence]: {
    [ColumnType.GamesPlayed]: true,
    [ColumnType.GoalsConceded]: true,
  },
};

export const RANGE_FILTERS_DEFAULT_STATE = {
  [ColumnType.TotalShots]: {},
  [ColumnType.ShotsBlocked]: {},
  [ColumnType.ShotsOffGoal]: {},
  [ColumnType.ShotsOnGoal]: {},
  [ColumnType.ShotsOnPost]: {},
  [ColumnType.ShotsOnBar]: {},
  [ColumnType.GoalsConceded]: {},
  [ColumnType.ShootingEfficiency]: {},
  [ColumnType.ShotsOffGoal]: {},
  [ColumnType.ShotsOnGoal]: {},
  [ColumnType.Possession]: {},
  [ColumnType.Offside]: {},
  [ColumnType.YellowCards]: {},
  [ColumnType.YellowRedCards]: {},
  [ColumnType.RedCards]: {},
  [ColumnType.CornerKicks]: {},
  [ColumnType.FreeKicks]: {},
  [ColumnType.GamesPlayed]: {},
  [ColumnType.Offside]: {},
  [ColumnType.YellowCards]: {},
  [ColumnType.YellowRedCards]: {},
  [ColumnType.RedCards]: {},
  [ColumnType.Headed]: {},
  [ColumnType.FootGoals]: {},
  [ColumnType.GoalsScored]: {},
};

export const DEFAULT_COMMON_ORDERING: Ordering = {
  [ColumnType.Club]: OrderType.Ascending,
};

export const COMMON_ORDERING_BY_STATS_TYPE: Record<StatType, Ordering> = {
  [StatType.All]: DEFAULT_COMMON_ORDERING,
  [StatType.PerGame]: DEFAULT_COMMON_ORDERING,
};

export const COLUMN_CONFIGURATION_FILTER_EMPTY_STATE: ColumnConfigurationFilter = {
  activePreset: PresetType.Custom,
  columns: DEFAULT_COLUMNS,
  configurableOrdering: {},
  activeColumns: {},
  rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
};

export const CUSTOM_GENERAL_FILTER_EMPTY_STATE: GeneralFilter = {
  countries: [],
  activeStatsTab: StatType.All,
  ordering: COMMON_ORDERING_BY_STATS_TYPE[StatType.All],
};

export const COLUMN_CONFIGURATION_FILTER_DEFAULT_STATE: ColumnConfigurationFilter = {
  activePreset: PresetType.Summary,
  columns: COLUMNS_BY_PRESET_TYPE[PresetType.Summary],
  activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Summary],
  rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
  configurableOrdering: { [ColumnType.GoalsScored]: OrderType.Descending },
};

export const CUSTOM_FILTER_DEFAULT_STATE: CustomFilterState = {
  generalFilter: CUSTOM_GENERAL_FILTER_EMPTY_STATE,
  columnConfigurationFilter: COLUMN_CONFIGURATION_FILTER_DEFAULT_STATE,
};

export const CUSTOM_FILTER_EMPTY_STATE: CustomFilterState = {
  generalFilter: CUSTOM_GENERAL_FILTER_EMPTY_STATE,
  columnConfigurationFilter: COLUMN_CONFIGURATION_FILTER_EMPTY_STATE,
};

export const CUSTOMIZATION_PRESET_BY_TYPE = {
  [PresetType.Summary]: {
    type: PresetType.Summary,
    overrides: {
      activePreset: PresetType.Summary,
      columns: COLUMNS_BY_PRESET_TYPE[PresetType.Summary],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Summary],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      configurableOrdering: {
        [ColumnType.GoalsScored]: OrderType.Descending,
      },
    },
  },
  [PresetType.Goals]: {
    type: PresetType.Goals,
    overrides: {
      activePreset: PresetType.Goals,
      columns: COLUMNS_BY_PRESET_TYPE[PresetType.Goals],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Goals],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      configurableOrdering: {
        [ColumnType.GoalsScored]: OrderType.Descending,
      },
    },
  },
  [PresetType.Attack]: {
    type: PresetType.Attack,
    overrides: {
      activePreset: PresetType.Attack,
      columns: COLUMNS_BY_PRESET_TYPE[PresetType.Attack],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Attack],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      configurableOrdering: {
        [ColumnType.TotalShots]: OrderType.Descending,
      },
    },
  },
  [PresetType.Aggression]: {
    type: PresetType.Aggression,
    overrides: {
      activePreset: PresetType.Aggression,
      columns: COLUMNS_BY_PRESET_TYPE[PresetType.Aggression],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Aggression],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      configurableOrdering: {
        [ColumnType.YellowCards]: OrderType.Descending,
      },
    },
  },
  [PresetType.Defence]: {
    type: PresetType.Defence,
    overrides: {
      activePreset: PresetType.Defence,
      columns: COLUMNS_BY_PRESET_TYPE[PresetType.Defence],
      activeColumns: ACTIVE_COLUMNS_BY_PRESET_TYPE[PresetType.Defence],
      rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      configurableOrdering: {
        [ColumnType.GoalsConceded]: OrderType.Descending,
      },
    },
  },
  [PresetType.Custom]: { type: PresetType.Custom, label: 'Custom', overrides: {} },
};

export const CUSTOMIZATION_PRESETS_BY_STAT_TYPE: PresetView[] = [
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Summary],
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Goals],
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Attack],
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Aggression],
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Defence],
  CUSTOMIZATION_PRESET_BY_TYPE[PresetType.Custom],
];

export const COLUMNS_TO_SKIP_DEFAULT_ORDERING = [
  ColumnType.Club,
  ColumnType.ClubLogo,
  ColumnType.Rank,
];
