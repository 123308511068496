import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Header = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton variant="rectangular" height={16} width={52} />
      <Skeleton width={230} height={32} variant="rectangular" />
      <Skeleton width={230} height={32} variant="rectangular" />
    </div>
  );
};

export default memo(Header);
