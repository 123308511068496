import React from 'react';

import { Spinner } from 'packages/shared/components';
import { SpinnerSize } from 'packages/shared/components/Spinner/types';

import styles from './styles.scss';

const InfiniteScrollSpinnerContainer = () => (
  <div className={styles.spinnerContainer}>
    <Spinner size={SpinnerSize.Medium} />
  </div>
);

export default InfiniteScrollSpinnerContainer;
