import { DesktopNarrowPage } from '../enums';

export interface DesktopNarrowPathParams {
  [DesktopNarrowPage.Football]: {};

  [DesktopNarrowPage.FootballSeason]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopNarrowPage.FootballSeasonPlayerStatistics]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopNarrowPage.FootballSeasonTeamStatistics]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopNarrowPage.FootballSeasonStadiums]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopNarrowPage.FootballSeasonGames]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [DesktopNarrowPage.FootballTeam]: {
    teamId: string | number;
  };
  [DesktopNarrowPage.FootballTeamGames]: {
    teamId: string | number;
  };
  [DesktopNarrowPage.FootballTeamSquad]: {
    teamId: string | number;
  };
  [DesktopNarrowPage.FootballTeamSummaryDescription]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
    teamId: string | number;
  };
  [DesktopNarrowPage.FootballTeamStandings]: {
    teamId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [DesktopNarrowPage.FootballTeamPlayerStatistics]: {
    teamId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [DesktopNarrowPage.FootballCategory]: {
    categoryId: string | number;
  };

  [DesktopNarrowPage.FootballPlayer]: {
    playerId: string | number;
  };
  [DesktopNarrowPage.FootballPlayerGames]: {
    playerId: string | number;
  };

  [DesktopNarrowPage.FootballStadium]: {
    stadiumId: string | number;
  };
  [DesktopNarrowPage.FootballStadiumSummary]: {
    stadiumId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
}
