import React from 'react';
import i18n from 'i18n-smart';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from './styles.scss';

const ComingSoon = () => {
  return (
    <div className={styles.comingSoon}>
      <Icon id={IconType.ComingSoon} />
      <div className={styles.comingSoonInfo}>{i18n.value('comingSoon.info')}</div>
    </div>
  );
};

export default React.memo(ComingSoon);
