import { Filters, SeasonEventsFilter } from '../../types';
import getPath from '../getPath';

export const getData = (state): SeasonEventsFilter => {
  return getPath(state).filter.data;
};

export const getActiveFilters = (state): Filters => {
  return getData(state).activeFilters;
};
