import React from 'react';

import { SeasonTablePromotion, TeamComment } from 'packages/event-standings/types';
import PromotionBlock from './components/PromotionBlock';
import TeamCommentsBlock from './components/TeamCommentsBlock';
import styles from './styles.scss';

type Props = {
  teamComments: TeamComment[];
  promotions: SeasonTablePromotion[];
  rule: string;
};

const TournamentTableRulesBlock = ({ rule, promotions, teamComments }: Props) => {
  return (
    <div className={styles.rulesContainer}>
      <div className={styles.rulesBlock}>{rule}</div>
      <div className={styles.rulesPromotionContainer}>
        <PromotionBlock promotions={promotions} />
      </div>
      <TeamCommentsBlock teamComments={teamComments} />
    </div>
  );
};

export default React.memo(TournamentTableRulesBlock);
