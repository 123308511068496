import React, { memo } from 'react';

import styles from './styles.scss';

type Props = {
  count: number;
};

const TrophiesCell = ({ count }: Props) => {
  return <div className={styles.countCell}>{count}</div>;
};

export default memo(TrophiesCell);
