import { allEventsSelectors } from '../../selectors';
import { allEventsActions } from '../../actions';
import { getEventsAfterLiveUpdate } from '../../services';

export const updateAllEvents = (newEvents) => async (dispatch, getState) => {
  try {
    dispatch(allEventsActions.updateAllEventsStart());

    const state = getState();
    const oldEvents = allEventsSelectors.getAllEvents(state);
    const updatedEvents = getEventsAfterLiveUpdate(oldEvents, newEvents);

    dispatch(allEventsActions.updateAllEventsSuccess(updatedEvents));
  } catch (error) {
    dispatch(allEventsActions.updateAllEventsFailure(error));
  }
};
