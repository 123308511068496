import i18n from 'i18n-smart';

import { SelectItem } from 'packages/shared/components/Select/types';
import { Tournament } from 'packages/team-events/types';

import { ALL_TOURNAMENTS_OPTION_ID } from '../constants';

const getTournamentSelectItem = (tournament?: Tournament): SelectItem => {
  if (!tournament) {
    return {
      id: ALL_TOURNAMENTS_OPTION_ID,
      label: i18n.value(`team.teamEvents.filters.TournamentsSelect.label`),
      componentProps: {
        name: i18n.value(`team.teamEvents.filters.TournamentsSelect.label`),
      },
    };
  }

  const { id, name, logo } = tournament;

  return {
    label: name,
    id,
    componentProps: {
      logo,
      name,
    },
  };
};

export default getTournamentSelectItem;
