import { useState } from 'react';

import { duration } from '../constants';

export const useSavingScroll = (
  scrollContainer: Element | null,
): { onPrimaryEntered; onPrimaryExit } => {
  const [primaryScrollTop, setPrimaryScrollTop] = useState<number>(0);

  const applySavedScroll = () => {
    if (scrollContainer) {
      setTimeout(() => {
        scrollContainer.scrollTop = primaryScrollTop;
      }, duration / 2);
    }
  };

  const savePrimaryScroll = () => {
    setPrimaryScrollTop(scrollContainer?.scrollTop || 0);
  };

  return { onPrimaryEntered: applySavedScroll, onPrimaryExit: savePrimaryScroll };
};
