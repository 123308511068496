import React, { useEffect } from 'react';
import classnames from 'classnames';
import { ActionCreatorsMapObject } from 'redux';
import { useSearchParams } from 'react-router-dom';
import { SearchQuery } from 'router/types';

import {
  Calendar,
  DateCarousel,
  FlexiblePopover,
  Icon,
  LoaderContainer,
} from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';
import { LocaleCode } from 'types';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';

import connect from './connect';
import styles from './styles.scss';

type Props = {
  className?: string;
  activeDate: string;
  localeCode: LocaleCode;
  settingsThunks: ActionCreatorsMapObject;
  isMobile: boolean;
  isAnyDesktop: boolean;
  isLoading: boolean;
  isWatchingSearchQuery?: boolean;
};

const DatePicker = ({
  className,
  activeDate,
  localeCode,
  settingsThunks,
  isMobile,
  isAnyDesktop,
  isLoading,
  isWatchingSearchQuery = true,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const queryDate = searchParams.get(SearchQuery.Date);

  useEffect(() => {
    if (isWatchingSearchQuery && !!queryDate && queryDate !== activeDate) {
      settingsThunks.setActiveDate(queryDate);
    }
  }, [queryDate, activeDate]);

  const setActiveDate = (date: string) => {
    if (isWatchingSearchQuery) {
      searchParams.set(SearchQuery.Date, date);
      setSearchParams(searchParams);
    }

    settingsThunks.setActiveDate(date);
  };

  return (
    <div className={classnames(styles.datePicker, { [styles.compact]: isMobile }, className)}>
      <LoaderContainer
        isLoading={isLoading}
        isContentVisible
        isSpinnerVisible={false}
        className={styles.loaderContainer}
      >
        <DateCarousel
          activeDate={activeDate}
          handleDateClick={setActiveDate}
          isCompact={isMobile}
        />

        <div className={classnames(styles.dispellingBlock, styles.bottomRight)} />

        <FlexiblePopover
          popoverComponent={Calendar}
          popoverProps={{
            value: activeDate,
            localeCode,
            onChange: setActiveDate,
          }}
          justify={PopoverJustify.CenterEnd}
          align={PopoverAlign.CenterStart}
          useBackdrop={!isAnyDesktop}
          closeOnClickOutside
          disableBodyOverflow
        >
          <div className={styles.calendarButton}>
            <Icon id={IconType.Calendar} className={styles.calendarIcon} />
          </div>
        </FlexiblePopover>
      </LoaderContainer>
    </div>
  );
};

export default connect(DatePicker);
