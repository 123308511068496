exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._ocR3r143{display:grid;grid-row-gap:16px;grid-column-gap:11px;grid-template-columns:repeat(auto-fill, minmax(300px, 1fr))}._1tYpxkQK{max-height:300px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/season-player-statistics-ui/containers/PlayerStatistics/views/DesktopNarrow/components/GeneralFilter/styles.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,iBAAkB,CAClB,oBAAqB,CACrB,2DAA4D,CALhE,WASI,gBAAiB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .general-filter-group {\n    display: grid;\n    grid-row-gap: 16px;\n    grid-column-gap: 11px;\n    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));\n  }\n\n  .search-list-items {\n    max-height: 300px;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"general-filter-group": "_ocR3r143",
	"generalFilterGroup": "_ocR3r143",
	"search-list-items": "_1tYpxkQK",
	"searchListItems": "_1tYpxkQK"
};