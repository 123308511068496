import * as matchShotsActions from './actions';
import * as matchShotsSelectors from './selectors';
import * as matchShotsConstants from './constants';
import * as matchShotsThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  matchShotsActions,
  matchShotsSelectors,
  matchShotsConstants,
  matchShotsThunks,
  createReducer,
};
