import { StadiumsOrdering } from 'packages/rest/api/SeasonStadiums/types';

import { stadiumActions } from '../actions';
import { loadStadiums } from './loadStadiums';
import { DEFAULT_STADIUMS_LIMIT } from '../constants';

export const loadInitialStadiums = (seasonId: number, ordering?: StadiumsOrdering) => async (
  dispatch,
) => {
  try {
    dispatch(stadiumActions.loadInitialStadiumsStart());

    const stadiums = await dispatch(
      loadStadiums({ seasonId, offset: 0, limit: DEFAULT_STADIUMS_LIMIT, ordering }),
    );

    dispatch(stadiumActions.loadInitialStadiumsSuccess(stadiums));
  } catch (e) {
    dispatch(stadiumActions.loadInitialStadiumsFailure(e));
  }
};
