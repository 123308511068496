import React, { memo, useCallback } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { useSearchParams } from 'react-router-dom';
import { BlockLink } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage, SearchQuery } from 'router/types';

import styles from './styles.scss';

type Props = {
  isCompact?: boolean;
  className?: string;
};

const EventDetailsHeader = ({ isCompact, className }: Props) => {
  const [searchParams] = useSearchParams();
  const { appNavigate } = useNavigation();

  const eventId = searchParams.get(SearchQuery.EventCardId) || '';

  const handleClick = useCallback(() => {
    appNavigate(DesktopPage.FootballMatch, { eventId });
  }, [eventId]);

  return (
    <BlockLink
      label={i18n.value('eventDetails.header.fullDetailsLink')}
      onClick={handleClick}
      className={classnames(styles.link, { [styles.compact]: isCompact }, className)}
    />
  );
};

export default memo(EventDetailsHeader);
