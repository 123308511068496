exports = module.exports = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3L0mYdEd ._iwn6Xo0z{display:inline-block;width:20px;height:13px;margin-right:8px;border-radius:2px;background-size:cover}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/event-summary-ui/containers/EventSummary/views/Desktop/components/SummaryDetails/views/RefereePreview/styles.scss"],"names":[],"mappings":"AAEA,sBAGM,oBAAqB,CAErB,UAAW,CACX,WAAY,CACZ,gBAAiB,CACjB,iBAAkB,CAElB,qBAAsB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .referee-preview {\n    .country-logo {\n      display: inline-block;\n\n      width: 20px;\n      height: 13px;\n      margin-right: 8px;\n      border-radius: 2px;\n\n      background-size: cover;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"referee-preview": "_3L0mYdEd",
	"refereePreview": "_3L0mYdEd",
	"country-logo": "_iwn6Xo0z",
	"countryLogo": "_iwn6Xo0z"
};