import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Data } from '../types';
import { commonActions, squadActions } from '../actions';

const defaultState: Data = [];

const reducerMap: ReducerMap<Data, Data> = {
  [`${combineActions(squadActions.loadSquadSuccess, squadActions.loadPageSuccess)}`]: (
    state,
    { payload },
  ) => {
    return payload;
  },

  [`${combineActions(
    squadActions.loadSquadFailure,
    commonActions.clearSuccess,
    squadActions.loadPageFailure,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
