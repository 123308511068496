import React, { memo } from 'react';

import styles from './styles.scss';

type Props = {
  name: string;
};

const TeamPopover = ({ name }: Props) => <div className={styles.teamPopover}>{name}</div>;

export default memo(TeamPopover);
