import { TeamSummary } from 'packages/rest';

import { detailsActions } from '../../../actions';
import { mapTeamStatistics } from '../../../services';

interface Params {
  seasonId: number;
  teamId: number;
}

const { statistics: statisticsActions } = detailsActions;

const loadStatisticsAndLineups = ({ seasonId, teamId }: Params) => async (dispatch) => {
  try {
    dispatch(statisticsActions.loadStatisticsStart());

    const { data } = await TeamSummary.loadTeamStatistics({ seasonId, teamId });
    const statistics = mapTeamStatistics(data.results);

    dispatch(statisticsActions.loadStatisticsSuccess(statistics));
  } catch (e) {
    dispatch(statisticsActions.loadStatisticsFailure(e));
  }
};

export default loadStatisticsAndLineups;
