import React, { memo } from 'react';
import classnames from 'classnames';

import { Team } from 'types';
import { Size } from 'packages/shared/components/TeamLabel/types';
import { TeamLabel } from 'packages/shared/components';
import { PROMOTION_COLOR_GROUPS } from 'appConstants';

import styles from './styles.scss';

type Props = {
  team: Team;
  position: {
    value: number;
    cssClass: string;
  };
};

const TeamCell = ({ team, position }: Props) => {
  const { value, cssClass } = position;

  return (
    <div className={styles.teamCellContainer}>
      <div
        className={classnames(
          styles.position,
          cssClass && styles[`promotion${PROMOTION_COLOR_GROUPS[cssClass]}`],
        )}
      >
        {value}
      </div>
      <TeamLabel className={styles.teamLabel} team={team} size={Size.Md} isLogoFirst />
    </div>
  );
};

export default memo(TeamCell);
