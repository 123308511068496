import React, { memo } from 'react';
import classnames from 'classnames';

import { ArrayUtils } from 'packages/utils';
import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  elementsCount: number;
  useMobileView?: boolean;
};

const ListSkeleton = ({ elementsCount, useMobileView = false }: Props) => {
  const elements = ArrayUtils.getArrayFromNumber(elementsCount);

  return (
    <div className={classnames(styles.container, { [styles.mobileView]: useMobileView })}>
      <div className={styles.header}>
        <Skeleton theme="dark" variant="rectangular" height={12} width={72} />
      </div>

      <div>
        {elements.map((value) => (
          <div className={styles.item} key={value}>
            <Skeleton width={20} height={20} variant="circular" />
            <Skeleton
              marginLeft={7}
              height={8}
              width={value % 2 ? 78 : 130}
              variant="rectangular"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(ListSkeleton);
