import React, { memo } from 'react';

import { Grid } from 'modules/shared/layouts';
import { GridColumnId } from 'types';
import { PlayerStatistics } from 'packages/team-player-statistics-ui';
import { Advertisement } from 'packages/shared/components';
import { FeaturedOdds } from 'packages/odds-ui';

import styles from './styles.scss';

const TeamPlayers = () => {
  return (
    <Grid.DoubleColumn
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
      isSecondaryOpen={false}
    >
      <div key={GridColumnId.LeftBar} className={styles.leftBar}>
        <PlayerStatistics.DesktopNarrow />
      </div>

      <div key={GridColumnId.Primary}>
        <Advertisement className={styles.advertisement} />
        <FeaturedOdds.DesktopNarrow />
      </div>
    </Grid.DoubleColumn>
  );
};

export default memo(TeamPlayers);
