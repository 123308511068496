import React from 'react';
import classnames from 'classnames';

import { Event, EventStatus } from 'types';
import { TimerCountdown } from 'packages/shared/components';
import DateService from 'packages/date-az';

import styles from './styles.scss';

type Props = {
  event: Event;
  className?: string;
};

const EventDetailsScore = ({ event, className }: Props) => {
  const { teams, isLive, isFinished, scheduled, status } = event;

  if (status === EventStatus.Postponed || status === EventStatus.Cancelled) {
    return (
      <div className={classnames(styles.emptyBlock, className)}>
        <div className={styles.delimiter} />
      </div>
    );
  }

  const [home, away] = teams;
  const eventTimeStamp = DateService.parseToMs(scheduled);
  const isScoreShown = isLive || isFinished || !scheduled;

  return (
    <div className={classnames(styles.eventDetailsScore, className)}>
      {isScoreShown ? (
        <div className={styles.score}>
          {home.score} - {away.score}
        </div>
      ) : (
        <TimerCountdown timeStamp={eventTimeStamp} />
      )}
    </div>
  );
};

export default React.memo(EventDetailsScore);
