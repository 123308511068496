import React, { ReactElement } from 'react';

import { Separator } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  title: string;
  children: ReactElement | ReactElement[];
};

const ContentBlock = ({ title = '', children }: Props) => {
  return (
    <div className={styles.contentBlock}>
      {title && <div className={styles.title}>{title}</div>}

      <Separator />

      <>{children}</>
    </div>
  );
};

export default React.memo(ContentBlock);
