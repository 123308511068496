export const getMatchStatusQueryParams = (isFinished, isScheduled) => {
  if (isFinished) {
    return {
      isFinished: true,
    };
  }

  if (isScheduled) {
    return {
      isFinished: false,
      isLive: false,
    };
  }

  return {};
};
