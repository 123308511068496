import React, { memo } from 'react';
import isEmpty from 'is-empty-typed/lib';
import i18n from 'i18n-smart';

import { SelectItem, SelectTheme } from 'packages/shared/components/Select/types';
import { Select, SelectItemComponent, SelectLabelComponent } from 'packages/shared/components';

import { LoadingSkeleton } from './components';
import { TournamentSeasonSelectItems } from '../../types';
import styles from './styles.scss';

type Props = {
  onTournamentSelect: (tournamentItem: SelectItem) => void;
  onSeasonSelect: (seasonItem: SelectItem) => void;
  tournamentSeasonSelectItems: TournamentSeasonSelectItems;
  isLoading?: boolean;
};

const TournamentSeasonSelect = ({
  tournamentSeasonSelectItems,
  onTournamentSelect,
  onSeasonSelect,
  isLoading = false,
}: Props) => {
  const {
    tournamentItems,
    seasonItems,
    activeSeasonItem,
    activeTournamentItem,
  } = tournamentSeasonSelectItems;

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <div className={styles.selectGroup}>
        <div className={styles.label}>{i18n.value('common.tournamentSeasonSelect.title')}</div>

        {!isEmpty(activeTournamentItem) && (
          <Select
            labelComponent={SelectLabelComponent.Tournament}
            itemComponent={SelectItemComponent.Tournament}
            theme={SelectTheme.White}
            items={tournamentItems}
            selectedItem={activeTournamentItem}
            labelComponentProps={activeTournamentItem.componentProps}
            onChange={onTournamentSelect}
            className={styles.tournamentSelect}
          />
        )}
      </div>

      {!isEmpty(activeSeasonItem) && (
        <div className={styles.selectGroup}>
          <div className={styles.label}>
            {i18n.value('common.tournamentSeasonSelect.label.season')}
          </div>
          <Select
            theme={SelectTheme.White}
            items={seasonItems}
            selectedItem={activeSeasonItem}
            onChange={onSeasonSelect}
          />
        </div>
      )}
    </>
  );
};

export default memo(TournamentSeasonSelect);
