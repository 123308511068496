import { DesktopPage } from '../types';

export const ROOT = {
  [DesktopPage.Home]: '/',
  [DesktopPage.Football]: '/football',
  [DesktopPage.FootballSeason]: '/football/unique-tournament/:tournamentId/season/:seasonId',
  [DesktopPage.FootballTeam]: '/football/team/:teamId',
  [DesktopPage.FootballCategory]: '/football/category/:categoryId',
  [DesktopPage.FootballPlayer]: '/football/player/:playerId',
  [DesktopPage.FootballStadium]: '/football/stadium/:stadiumId',
  [DesktopPage.FootballStadium]: '/football/stadium/:stadiumId',
  [DesktopPage.FootballMatch]: '/football/match/:eventId',
  [DesktopPage.NotFound]: '*',
};

export const SEASON_CHILDREN = {
  [DesktopPage.FootballSeasonHome]: '',
  [DesktopPage.FootballSeasonSummary]: 'summary',
  [DesktopPage.FootballSeasonGames]: 'games',
  [DesktopPage.FootballSeasonStandings]: 'standings',
  [DesktopPage.FootballSeasonTeamStatistics]: 'team-statistics',
  [DesktopPage.FootballSeasonPlayerStatistics]: 'player-statistics',
  [DesktopPage.FootballSeasonStadiums]: 'stadiums',
  [DesktopPage.FootballSeasonHistory]: 'history',
  [DesktopPage.FootballSeasonComingSoon]: '*',
};

export const STADIUM_CHILDREN = {
  [DesktopPage.FootballStadiumHome]: '',
  [DesktopPage.FootballStadiumSummary]: 'unique-tournament/:tournamentId/season/:seasonId',
};

export const TEAM_CHILDREN = {
  [DesktopPage.FootballTeamHome]: '',
  [DesktopPage.FootballTeamSummary]: 'summary',
  [DesktopPage.FootballTeamSummaryDescription]:
    'summary/unique-tournament/:tournamentId/season/:seasonId',
  [DesktopPage.FootballTeamStandings]: 'standings/unique-tournament/:tournamentId/season/:seasonId',
  [DesktopPage.FootballTeamPlayerStatistics]:
    'player-statistics/unique-tournament/:tournamentId/season/:seasonId',
  [DesktopPage.FootballTeamGames]: 'games',
  [DesktopPage.FootballTeamSquad]: 'squad',
};

export const PLAYER_CHILDREN = {
  [DesktopPage.FootballPlayerHome]: '',
  [DesktopPage.FootballPlayerSummary]: 'summary',
  [DesktopPage.FootballPlayerGames]: 'games',
};
