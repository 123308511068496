import React, { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { TournamentSeasonSelect as TournamentSeasonSelectView } from 'packages/shared/components';
import { SelectItem } from 'packages/shared/components/Select/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import connect from './connect';

type Props = {
  tournaments?: SeasonsExtendedTournament[];
  className?: string;
  useSelectorsInOneRow?: boolean;
};

const extendDetailsSelector = (
  TournamentSeasonSelect: typeof TournamentSeasonSelectView[keyof typeof TournamentSeasonSelectView],
) =>
  memo(({ tournaments = [], className, useSelectorsInOneRow }: Props) => {
    const { stadiumId, tournamentId, seasonId } = useParams();
    const { appNavigate } = useNavigation();

    const handleTournamentChange = useCallback(
      (tournamentItem: SelectItem) => {
        if (stadiumId) {
          const [season] = tournaments.find(({ id }) => id === tournamentItem.id)?.seasons || [];

          if (season) {
            appNavigate(DesktopPage.FootballStadiumSummary, {
              uniqueTournamentId: tournamentItem.id,
              seasonId: season.id,
              stadiumId,
            });
          }
        }
      },
      [stadiumId],
    );

    const handleSeasonChange = useCallback(
      (seasonItem: SelectItem) => {
        if (tournamentId && stadiumId) {
          appNavigate(DesktopPage.FootballStadiumSummary, {
            uniqueTournamentId: tournamentId,
            seasonId: seasonItem.id,
            stadiumId,
          });
        }
      },
      [tournamentId, stadiumId],
    );

    return (
      <TournamentSeasonSelect
        tournaments={tournaments}
        activeTournamentId={tournamentId}
        activeSeasonId={seasonId}
        onSeasonSelect={handleSeasonChange}
        onTournamentSelect={handleTournamentChange}
        className={className}
        useSelectorsInOneRow={useSelectorsInOneRow}
      />
    );
  });

export default {
  Desktop: connect(extendDetailsSelector(TournamentSeasonSelectView.Desktop)),
  DesktopNarrow: connect(extendDetailsSelector(TournamentSeasonSelectView.DesktopNarrow)),
  Tablet: connect(extendDetailsSelector(TournamentSeasonSelectView.Tablet)),
  TabletNarrow: connect(extendDetailsSelector(TournamentSeasonSelectView.TabletNarrow)),
  Mobile: connect(extendDetailsSelector(TournamentSeasonSelectView.Mobile)),
};
