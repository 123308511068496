import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  filterThunks,
  eventsSelectors,
  filterSelectors,
  eventThunks,
  commonThunks,
} from 'packages/team-events';

const mapStateToProps = (state) => {
  return {
    events: eventsSelectors.getData(state),
    eventsCount: eventsSelectors.getCount(state),
    limit: eventsSelectors.getLimit(state),
    filterDefaultPeriod: filterSelectors.getDefaultPeriod(state),
    tournamentFilters: filterSelectors.getFilters(state),
    activeFilters: filterSelectors.getActiveFilter(state),
    isFiltersLoading: filterSelectors.getIsLoading(state),
    isEventsLoading: eventsSelectors.getIsEventsLoading(state),
    isMoreEventsLoading: eventsSelectors.getIsMoreEventsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  filterActions: bindActionCreators(filterThunks, dispatch),
  eventActions: bindActionCreators(eventThunks, dispatch),
  commonActions: bindActionCreators(commonThunks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
