import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import isEmpty from 'is-empty-typed';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import {
  NavigationMenu,
  BlockLink,
  ExpandableBlock,
  NoDataLabel,
} from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { Stage, StageType } from 'packages/event-standings/types';

import { useActiveTabContentElement, useStageTabs } from '../../../../hooks';
import { getBracketProps, getTableProps } from './services';
import { LoadingSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  stages: Stage[] | null;
  onLinkClick: () => void;
  isLoading?: boolean;
};

const CompactStandings = ({ stages, onLinkClick, isLoading = false }: Props) => {
  const { seasonId, tournamentId } = useParams();

  const tabsState = useStageTabs(stages, {
    getTableProps,
    getBracketProps,
    additionalProps: {
      activeUniqueTournamentId: tournamentId,
      activeSeasonId: seasonId,
    },
  });
  const activeContentElement = useActiveTabContentElement(tabsState.activeTab);
  const isTabsInitialized = !isEmpty(tabsState.activeTab);
  const isBracketTab = tabsState.activeTab?.type === StageType.Brackets;
  const isStageControlShown = tabsState.tabs.length;

  if (isLoading) {
    return (
      <div className={styles.tabsContainer}>
        <LoadingSkeleton />
      </div>
    );
  }

  if (!isTabsInitialized) {
    return (
      <div className={classnames(styles.tabsContainer, styles.noData)}>
        <div className={styles.header}>
          <div className={styles.leftHeaderBar}>
            <div className={styles.label}>{i18n.value('season.seasonStandings.title')}</div>
          </div>

          <BlockLink
            label={i18n.value('season.seasonStandings.link.fullStandings')}
            onClick={onLinkClick}
          />
        </div>

        <NoDataLabel className={styles.label} />
      </div>
    );
  }

  return (
    <div className={classnames(styles.tabsContainer, { [styles.withBracket]: isBracketTab })}>
      <div className={classnames(styles.header, { [styles.withControl]: isStageControlShown })}>
        <div className={styles.leftHeaderBar}>
          <div className={styles.label}>{i18n.value('season.seasonStandings.title')}</div>
          {isStageControlShown && (
            <NavigationMenu
              items={tabsState.tabs}
              activeId={tabsState.activeTab?.id || ''}
              onClick={tabsState.setActiveTabById}
              theme={NavigationTheme.Dropdown}
              className={styles.filterTabs}
            />
          )}
        </div>

        <BlockLink
          label={i18n.value('season.seasonStandings.link.fullStandings')}
          onClick={onLinkClick}
        />
      </div>

      <div className={styles.content}>
        {!isBracketTab ? (
          <ExpandableBlock
            showLessLabel={i18n.value('season.seasonStandings.expandableBlock.label.showLess')}
            showMoreLabel={i18n.value('season.seasonStandings.expandableBlock.label.showMore')}
            className={styles.expandableBlock}
          >
            {activeContentElement}
          </ExpandableBlock>
        ) : (
          activeContentElement
        )}
      </div>
    </div>
  );
};

export default memo(CompactStandings);
