import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { teamsFiltersActions, tournamentsFiltersActions, commonActions } from '../../actions';
import { EventFilterStatus } from '../../types';

const defaultState: EventFilterStatus = {
  isInitialLoading: true,
  isMoreLoading: false,
};

const reducerMap: ReducerMap<EventFilterStatus, void> = {
  [`${teamsFiltersActions.loadTeamsFiltersStart}`]: (state) => {
    return { ...state, isInitialLoading: true };
  },

  [`${combineActions(
    teamsFiltersActions.loadTeamsFiltersFailure,
    teamsFiltersActions.loadTeamsFiltersSuccess,
  )}`]: (state) => {
    return { ...state, isInitialLoading: false };
  },

  [`${tournamentsFiltersActions.loadTournamentsFiltersStart}`]: (state) => {
    return { ...state, isMoreLoading: true };
  },

  [`${combineActions(
    tournamentsFiltersActions.loadTournamentsFiltersFailure,
    tournamentsFiltersActions.loadTournamentsFiltersSuccess,
  )}`]: (state) => {
    return { ...state, isMoreLoading: false };
  },

  [`${commonActions.clearSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
