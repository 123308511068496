import React, { memo } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import classnames from 'classnames';

import { Tournament } from 'types';
import { isEmpty } from 'packages/utils/Object.utils';
import { FavoriteIcon } from 'packages/shared/components';

import { getLiveMatchesCount } from './services';
import { LoadingSkeleton } from './components';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  favoriteLeagues: Tournament[];
  favoritesActions: ActionCreatorsMapObject;
  onTournamentClick: (tournament: Tournament) => void;
  useControlsHoverEffect?: boolean;
  activeDate: string;
  tournaments?: Tournament[];
  isLoading?: boolean;
};

const TournamentList = ({
  favoriteLeagues,
  onTournamentClick,
  favoritesActions,
  useControlsHoverEffect,
  activeDate,
  isLoading = false,
  tournaments = [],
}: Props) => {
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  const getFavoriteIconHandler = (league) => () => {
    favoritesActions.toggleLeague(league);
  };

  const getTournamentClickHandler = (tournament) => () => {
    onTournamentClick(tournament);
  };

  return (
    <>
      {tournaments.map((tournament) => {
        const {
          uniqueTournamentName,
          countLiveMatches = 0,
          countMatches = 0,
          uniqueTournamentId,
        } = tournament;
        const isTournamentFavorite = Boolean(
          favoriteLeagues.find((league) => league.uniqueTournamentId === uniqueTournamentId),
        );
        const liveMatchesCount = getLiveMatchesCount(countMatches, countLiveMatches, activeDate);

        return (
          <div
            key={uniqueTournamentId}
            className={classnames(styles.tournament, {
              [styles.hoverable]: useControlsHoverEffect,
            })}
            onClick={getTournamentClickHandler(tournament)}
          >
            <div className={styles.tournamentName}>{uniqueTournamentName}</div>
            <div className={styles.countBlock}>
              {!isEmpty(liveMatchesCount) && (
                <span className={styles.live}>{liveMatchesCount}</span>
              )}
              {!isEmpty(liveMatchesCount) && !isEmpty(countMatches) && <>/</>}
              {!isEmpty(countMatches) && countMatches}
            </div>
            <FavoriteIcon
              isFavorite={isTournamentFavorite}
              onClick={getFavoriteIconHandler(tournament)}
              className={styles.favoriteIcon}
            />
          </div>
        );
      })}
    </>
  );
};

export default connect(memo(TournamentList));
