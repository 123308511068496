exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1fRGi2eG{position:absolute;left:0;display:flex;align-items:center;height:100%}._1fRGi2eG ._2Ge43fkZ{position:absolute;left:35px;top:11px;z-index:1}._1fRGi2eG ._2Ge43fkZ ._32XKxqSN{min-width:50px;max-width:50px;min-height:26px;max-height:26px}._1fRGi2eG ._2um_5ATl{position:absolute}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/modules/shared/components/Header/components/Logo/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/size.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,iBAAkB,CAClB,MAAO,CAEP,YAAa,CACb,kBAAmB,CAEnB,WAAY,CARhB,sBAWM,iBAAkB,CAClB,SAAU,CACV,QAAS,CACT,SAAU,CAdhB,iCCDE,cDkB8B,CCjB9B,cDiB8B,CCX5B,eDWkC,CCVlC,eDUkC,CAjBtC,sBAsBM,iBAAkB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .app-logo {\n    position: absolute;\n    left: 0;\n\n    display: flex;\n    align-items: center;\n\n    height: 100%;\n\n    .logo {\n      position: absolute;\n      left: 35px;\n      top: 11px;\n      z-index: 1;\n\n      .image {\n        @include fixed-size(50px, 26px);\n      }\n    }\n\n    .lines {\n      position: absolute;\n    }\n  }\n}\n","@mixin fixed-size($width, $height: null) {\n  min-width: $width;\n  max-width: $width;\n\n  @if ($height == null) {\n    min-height: $width;\n    max-height: $width;\n  } @else {\n    min-height: $height;\n    max-height: $height;\n  }\n}"]}]);

// Exports
exports.locals = {
	"app-logo": "_1fRGi2eG",
	"appLogo": "_1fRGi2eG",
	"logo": "_2Ge43fkZ",
	"image": "_32XKxqSN",
	"lines": "_2um_5ATl"
};