import React, { memo } from 'react';

import { Sport } from 'types';
import { Search } from 'packages/search-ui';

import { Logo, SportNavigation, SettingsBtn } from '../../components';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  sports: Sport[];
};

const Header = ({ sports }: Props) => {
  return (
    <header className={styles.headerContainer}>
      <Logo />

      <div className={styles.wrapper}>
        <SportNavigation sports={sports} />

        <div className={styles.container}>
          <Search.Desktop
            resultsClassName={styles.resultsClassName}
            inputClassName={styles.searchInput}
            className={styles.search}
          />

          <SettingsBtn />
        </div>
      </div>
    </header>
  );
};

export default connect(memo(Header));
