import * as eventCommentaryActions from './actions';
import * as eventCommentaryThunks from './thunks';
import * as eventCommentaryConstants from './constants';
import * as eventCommentarySelectors from './selectors';
import * as eventCommentaryServices from './services';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  eventCommentaryActions,
  eventCommentaryThunks,
  eventCommentaryConstants,
  eventCommentarySelectors,
  eventCommentaryServices,
  createReducer,
};
