import { DateTime } from 'luxon';

import DateService from 'packages/date-az';
import { ArrayUtils } from 'packages/utils';

import { DATES_RANGE } from '../const';

const getGroupedDates = (dateTime: DateTime): any[] => {
  const dates = [
    ...DateService.getFollowingDates(dateTime, DATES_RANGE, 'prev'),
    dateTime,
    ...DateService.getFollowingDates(dateTime, DATES_RANGE, 'next'),
  ];

  return Object.entries(ArrayUtils.groupBy(dates, ({ monthLong }) => monthLong));
};

export default getGroupedDates;
