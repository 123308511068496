import React, { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ActionCreatorsMapObject } from 'redux';
import classnames from 'classnames';

import { LoaderContainer } from 'packages/shared/components';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import { Standings, TopPlayers } from './components';
import { DetailsStatistics } from '../../../../components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  isDetailsLoading: boolean;
  isTournamentsLoading: boolean;
  detailsActions: ActionCreatorsMapObject;
  tournaments: SeasonsExtendedTournament[] | undefined;
  className?: string;
};

const Details = ({
  isDetailsLoading,
  isTournamentsLoading,
  detailsActions,
  className,
  tournaments,
}: Props) => {
  const { teamId = '', seasonId = '', tournamentId = '' } = useParams();
  const { appNavigate } = useNavigation();
  const activeTeamIds = useMemo(() => [parseInt(teamId)], [teamId]);

  useEffect(() => {
    if (!isTournamentsLoading) {
      const seasonExists = tournaments?.some(
        ({ id, seasons }) => id === +tournamentId && seasons.some(({ id }) => id === +seasonId),
      );

      if (!seasonExists) {
        const lastSeasonId = tournaments?.find(({ id }) => id === +tournamentId);

        if (lastSeasonId) {
          appNavigate(
            DesktopPage.FootballTeamSummaryDescription,
            {
              teamId: teamId,
              uniqueTournamentId: tournamentId,
              seasonId: lastSeasonId.seasons[0].id,
            },
            undefined,
            { replace: true },
          );
        }
      } else {
        detailsActions.loadDetails({ seasonId: parseInt(seasonId), teamId: parseInt(teamId) });
      }
    }
  }, [seasonId, teamId, tournaments, isTournamentsLoading, tournamentId]);

  return (
    <LoaderContainer
      className={styles.loadingContainer}
      isLoading={isDetailsLoading || isTournamentsLoading}
    >
      <div className={classnames(styles.detailsContainer, className)}>
        <div className={styles.topBlock}>
          <div className={classnames(styles.block, styles.standingsContainer)}>
            <Standings activeTeamIds={activeTeamIds} />
          </div>
          <div className={classnames(styles.block, styles.topPlayersContainer)}>
            <TopPlayers />
          </div>
        </div>
        <div className={classnames(styles.block, styles.middleBlock)}>
          <DetailsStatistics />
        </div>
      </div>
    </LoaderContainer>
  );
};

export default connect(memo(Details));
