import React, { useMemo, memo } from 'react';
import { Column as ReactTableColumn } from 'react-table';

import { ArrayUtils } from 'packages/utils';
import { FlexibleTable } from 'packages/shared/components';

import { Header } from './components';
import styles from './styles.scss';

type Props = {
  elementsCount: number;
  getTableSchema: () => ReactTableColumn[];
  isHeaderButtonLabelHidden?: boolean;
};

const TableSkeleton = ({ elementsCount, getTableSchema, isHeaderButtonLabelHidden }: Props) => {
  const elements = ArrayUtils.getArrayFromNumber(elementsCount);

  const tableColumns = useMemo(() => getTableSchema(), []);

  return (
    <>
      <Header isButtonLabelDisabled={isHeaderButtonLabelHidden} />
      <FlexibleTable
        className={styles.table}
        headerClassName={styles.header}
        columns={tableColumns}
        data={elements}
        isColumnsSticky
        isHeaderSticky
        isRowClickable
      />
    </>
  );
};

export default memo(TableSkeleton);
