import React, { memo } from 'react';
import classnames from 'classnames';

import { Figure } from 'packages/shared/components';
import { EventSummaryStadium } from 'packages/event-summary/types';

import styles from './styles.scss';

type Props = {
  stadium: EventSummaryStadium;
  onClick?: () => void;
  className?: string;
};

const StadiumPreview = ({ stadium, onClick, className }: Props) => {
  return (
    <div className={classnames(styles.stadiumPreview, className)}>
      <Figure onClick={onClick} src={stadium.countryLogo} className={styles.countryLogo} />
      <span onClick={onClick} className={styles.label}>{`${stadium.name} (${stadium.city})`}</span>
    </div>
  );
};

export default memo(StadiumPreview);
