import { settingsSelectors } from 'packages/settings';
import { Categories } from 'packages/rest';

import { categoryListActions } from '../../actions';
import { mapCategories } from '../../services';

const loadCategories = () => async (dispatch, getState) => {
  try {
    dispatch(categoryListActions.loadCategoriesStart());

    const state = getState();
    const activeSportId = settingsSelectors.getActiveSportId(state);

    const { data } = await Categories.loadCategories({ sportId: activeSportId });

    const categories = mapCategories(data.results);

    dispatch(categoryListActions.loadCategoriesSuccess(categories));
  } catch (error) {
    dispatch(categoryListActions.loadCategoriesFailure({ error }));
  }
};

export default loadCategories;
