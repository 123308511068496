exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3mTmWXQw{width:100%;height:100px;position:relative;overflow:hidden;background:#171724}._3mTmWXQw ._ZrJBd7j0{width:100%;max-width:1440px;padding:0 16px;position:absolute;z-index:5;top:0;bottom:0;right:0;left:0;margin:auto;display:flex;justify-content:space-between}._3mTmWXQw ._m1HB65yQ{width:100%;z-index:4}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/stadium-details-ui/containers/StadiumDetails/views/Desktop/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/screens.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAIA,WAEI,UAAW,CACX,YAL0B,CAO1B,iBAAkB,CAClB,eAAgB,CAChB,kBAA2B,CAP/B,sBAUM,UAAW,CACX,gBCXkB,CDYlB,cEhBQ,CFkBR,iBAAkB,CAClB,SAAU,CACV,KAAM,CACN,QAAS,CACT,OAAQ,CACR,MAAO,CACP,WAAY,CAEZ,YAAa,CACb,6BAA8B,CAvBpC,sBA2BM,UAAW,CACX,SAAU","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n$stadium-details-height: 100px;\n\n:local {\n  .stadium-details {\n    width: 100%;\n    height: $stadium-details-height;\n\n    position: relative;\n    overflow: hidden;\n    background: rgb(23, 23, 36);\n\n    .details {\n      width: 100%;\n      max-width: $screen-xl-width;\n      padding: 0 $padding;\n\n      position: absolute;\n      z-index: 5;\n      top: 0;\n      bottom: 0;\n      right: 0;\n      left: 0;\n      margin: auto;\n\n      display: flex;\n      justify-content: space-between;\n    }\n\n    .background {\n      width: 100%;\n      z-index: 4;\n    }\n  }\n}\n","$screen-xs-width: 600px;\n$screen-sm-width: 768px;\n$screen-md-width: 1024px;\n$screen-lg-width: 1360px;\n$screen-xl-width: 1440px;\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"stadium-details": "_3mTmWXQw",
	"stadiumDetails": "_3mTmWXQw",
	"details": "_ZrJBd7j0",
	"background": "_m1HB65yQ"
};