import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import ReactCalendar from 'react-calendar/dist/entry.nostyle';

import { LocaleCode } from 'types';
import DateService from 'packages/date-az';
import { Separator } from 'packages/shared/components';
import { isArray, isEmpty } from 'packages/utils/Object.utils';
import { YearItem, CustomPeriod } from 'packages/shared/components/Calendar/types';

import * as services from './services';
import { NavigationLabel, SelectedDateLabel, ConfirmationBlock, CustomRange } from './views';
import { MIN_DATE } from './constants';
import styles from './styles.scss';
import YearsRange from './views/YearsRange';

type Props = {
  value: string | [string, string];
  localeCode: LocaleCode;
  isRangeMode?: boolean;
  className?: string;
  onChange: (isoDate: string) => void;
  customRangeLabel?: string;
  onCustomRangeClick?: () => void;
  onApplyClick?: Function;
  onCancelClick?: () => void;
  closePopover?: () => void;
  eventsCount?: number;
  yearOffset?: number;
  onYearClick?: (year: YearItem) => void;
  onPeriodClick?: (customPeriod: CustomPeriod) => void;
  customPeriod?: CustomPeriod;
};

const Calendar = ({
  localeCode,
  value,
  onChange,
  isRangeMode = false,
  className,
  customRangeLabel = '',
  onApplyClick = () => {},
  onCancelClick = () => {},
  onCustomRangeClick = () => {},
  closePopover,
  eventsCount,
  yearOffset,
  onYearClick,
  customPeriod,
  onPeriodClick,
}: Props) => {
  const minDate: Date = DateService.parse(MIN_DATE).toJSDate();
  const [dates, setDates] = useState<Date[]>(services.mapIsoValueToDates(value));
  const [date]: Date[] = dates;

  const [relativeDate, setRelativeDate] = useState<Date>(date);
  const [clickedDay, setClickedDay] = useState<Date>(date);

  useEffect(() => {
    setRelativeDate(date);
  }, [value]);

  const handleOnChange = (updatedValue: Date | Date[]) => {
    isArray(updatedValue) ? handleRangeChange(updatedValue) : handleSingleDateChange(updatedValue);
  };

  const handleSingleDateChange = (date: Date) => {
    onChange(DateService.getIsoFromDate(date));
  };

  const handleRangeChange = (dates: Date[]) => {
    setDates(dates);
  };

  const handleApplyClick = () => {
    const updatedRange = isEmpty(dates) ? [clickedDay, clickedDay] : dates;
    const updatedStrRange = updatedRange.map((date) => DateService.getIsoFromDate(date));

    onApplyClick(updatedStrRange);
  };

  const handleDayClick = (day: Date) => {
    setDates([]);
    setClickedDay(day);
  };

  const handleNavigationLabelClick = (isPrevious: boolean) => {
    const updatedRelativeDate: Date = services
      .getNewRelativeDate(isPrevious, relativeDate)
      .toJSDate();

    setRelativeDate(updatedRelativeDate);
  };

  const isNavigationLabelDisabled = () => {
    const relativeDateTime = DateService.getDateTimeFromDate(relativeDate);
    const navigationLabelDate = DateService.minus(relativeDateTime, {
      month: 1,
    }).toJSDate();

    return minDate >= navigationLabelDate;
  };

  return (
    <div className={classnames(styles.calendar, className)}>
      <ReactCalendar
        className={styles.reactCalendar}
        locale={localeCode}
        value={services.mapIsoValueToDates(value)}
        minDate={minDate}
        minDetail="month"
        onChange={handleOnChange}
        onClickDay={handleDayClick}
        selectRange={isRangeMode}
        nextLabel={
          <NavigationLabel
            label={services.getNavigationLabel(relativeDate, false)}
            handleClick={() => handleNavigationLabelClick(false)}
          />
        }
        prevLabel={
          <NavigationLabel
            label={services.getNavigationLabel(relativeDate, true)}
            handleClick={() => handleNavigationLabelClick(true)}
            isPrevious
            isDisabled={isNavigationLabelDisabled()}
          />
        }
        next2Label={null}
        prev2Label={null}
      />
      <Separator />

      {!isEmpty(yearOffset) && isEmpty(customPeriod) && (
        <>
          <YearsRange
            closePopover={closePopover}
            onYearClick={onYearClick}
            yearOffset={yearOffset}
          />
          <Separator />
        </>
      )}

      {!isEmpty(customPeriod) && (
        <>
          <CustomRange
            closePopover={closePopover}
            onPeriodClick={onPeriodClick}
            customPeriod={customPeriod}
          />
          <Separator />
        </>
      )}

      <SelectedDateLabel value={value} eventsCount={eventsCount} />
      {isRangeMode && (
        <ConfirmationBlock
          customRangeLabel={customRangeLabel}
          onCustomRangeClick={onCustomRangeClick}
          onCancelClick={onCancelClick}
          closePopover={closePopover}
          onApplyClick={handleApplyClick}
        />
      )}
    </div>
  );
};

export default Calendar;
