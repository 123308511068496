import { TimeZone } from 'types';
import { SelectItem } from 'packages/shared/components/Select/types';
import DateService from 'packages/date-az';

const getTimeZoneSelectItem = (timeZone: TimeZone): SelectItem => {
  return {
    label: DateService.getFullTimeWithOffset(timeZone),
    id: timeZone.value,
  };
};

export default getTimeZoneSelectItem;
