import { isEmpty } from 'packages/utils/Object.utils';
import { Qualifier } from 'types';
import {
  BaseHappening,
  HappeningEventPeriod,
  PenaltyShootoutStatuses,
  PenaltyShootoutHappening,
} from 'packages/event-time-line/types';

import { SummaryHappeningType } from 'packages/events/types';
import { INITIAL_PENALTY_HAPPENING_STATE } from '../constants';
import { HappeningPeriod, PenaltyHappening } from '../types';

const normalizePenaltyPeriodHappenings = (
  happeningPeriods: HappeningPeriod[],
): HappeningPeriod[] => {
  const happeningPeriodsCopy = [...happeningPeriods];
  const penaltyPeriod = happeningPeriodsCopy.find(
    ({ periodHappening, happeningsOfThePeriod }) =>
      periodHappening.periodType === HappeningEventPeriod.PENALTIES &&
      !isEmpty(happeningsOfThePeriod),
  );

  if (penaltyPeriod) {
    const penaltyHappeningResults: PenaltyHappening[] = [];
    const score = { home: 0, away: 0 };
    const { happeningsOfThePeriod } = penaltyPeriod;

    for (const happeningOfThePeriod of happeningsOfThePeriod as BaseHappening[]) {
      const { eventType } = happeningOfThePeriod;

      if (eventType === SummaryHappeningType.PENALTY_SHOOTOUT) {
        const penaltyShootoutHappening = happeningOfThePeriod as PenaltyShootoutHappening;
        if (isEmpty(penaltyShootoutHappening.status)) {
          penaltyPeriod.happeningsOfThePeriod = [];

          return happeningPeriodsCopy;
        }

        handlePenaltyShootoutHappening(penaltyHappeningResults, penaltyShootoutHappening, score);
      }
    }

    penaltyPeriod.happeningsOfThePeriod = penaltyHappeningResults;
  }

  return happeningPeriodsCopy;
};

const handlePenaltyShootoutHappening = (
  penaltyHappeningResults: PenaltyHappening[],
  penaltyShootoutHappening: PenaltyShootoutHappening,
  score: { home: number; away: number },
) => {
  const lastPenaltyHappeningIndex = penaltyHappeningResults.length - 1;

  if (!isEmpty(penaltyHappeningResults)) {
    const isLastHomePenaltyKicked =
      penaltyHappeningResults[lastPenaltyHappeningIndex][Qualifier.Home].isPenaltyKicked;
    const isLastAwayPenaltyKicked =
      penaltyHappeningResults[lastPenaltyHappeningIndex][Qualifier.Away].isPenaltyKicked;

    if (isLastHomePenaltyKicked && isLastAwayPenaltyKicked) {
      penaltyHappeningResults.push(
        getPenaltyShootoutHappening(
          INITIAL_PENALTY_HAPPENING_STATE,
          penaltyShootoutHappening,
          score,
        ),
      );
    } else {
      penaltyHappeningResults[lastPenaltyHappeningIndex] = getPenaltyShootoutHappening(
        penaltyHappeningResults[lastPenaltyHappeningIndex],
        penaltyShootoutHappening,
        score,
      );
    }
  } else {
    penaltyHappeningResults.push(
      getPenaltyShootoutHappening(INITIAL_PENALTY_HAPPENING_STATE, penaltyShootoutHappening, score),
    );
  }
};

const getPenaltyShootoutHappening = (
  penaltyShootoutState: PenaltyHappening,
  penaltyShootoutHappening: PenaltyShootoutHappening,
  score: { home: number; away: number },
) => {
  const { status, team, ...rest } = penaltyShootoutHappening;

  if (!team) {
    throw new Error('Team is not defined!');
  }

  const isPenaltyScored = status === PenaltyShootoutStatuses.SCORED;
  const goalScoredPenaltyData = { isGoalScored: true, isPenaltyKicked: true, ...rest };
  const goalNotScoredPenaltyData = { isGoalScored: false, isPenaltyKicked: true, ...rest };
  score[team] = isPenaltyScored ? score[team] + 1 : score[team];

  return {
    ...penaltyShootoutState,
    score: `${score.home}-${score.away}`,
    [team]: isPenaltyScored ? goalScoredPenaltyData : goalNotScoredPenaltyData,
  };
};

export default normalizePenaltyPeriodHappenings;
