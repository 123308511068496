import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';

const defaultState = {
  details: [],
  goals: {
    homeGoals: [],
    awayGoals: [],
  },
};

const reducerMap: ReducerMap<any, any> = {
  [`${actions.loadEventStatsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${actions.cleanEventStats}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
