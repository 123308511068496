import React, { RefObject, useContext, useMemo, useRef, useState, memo } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import classnames from 'classnames';
import { useSearchParams } from 'react-router-dom';

import { useNavigation } from 'packages/hooks';
import { TournamentView } from 'packages/events/types';
import { FavoriteIcon, Figure, ToggleArrowButton } from 'packages/shared/components';
import { ComponentLike, Tournament as TournamentType } from 'types';
import { DesktopPage, SearchQuery } from 'router/types';
import { validateParams } from 'router/services';

import { StickyHeaderTopOffsetContext } from '../EventsTabs';
import { EventAdditionalProps } from './types';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  tournament: TournamentView;
  eventComponent: ComponentLike;
  favoriteEventsActions: ActionCreatorsMapObject;
  favoriteTournaments: TournamentType[];
  handleEventClick: (eventId: number) => void;
  activeSportId: number;
  activeEventId: number;
  isSingleRow?: boolean;
  headerOffset?: number;
  eventComponentProps?: EventAdditionalProps;
  isUniqueTournamentNameDisplayed: boolean;
  isTournamentLast?: boolean;
  useModalOnCategoryClick?: boolean;
};

const Tournament = ({
  tournament,
  handleEventClick,
  favoriteEventsActions,
  favoriteTournaments,
  activeSportId,
  activeEventId,
  headerOffset = 0,
  isSingleRow = true,
  eventComponent: Event,
  eventComponentProps = {},
  isUniqueTournamentNameDisplayed = false,
  isTournamentLast = false,
  useModalOnCategoryClick = false,
}: Props) => {
  const {
    logo,
    uniqueTournamentId,
    category,
    events,
    name,
    uniqueTournamentName,
    currentSeasonId,
  } = tournament;

  const { appNavigate } = useNavigation();
  const containerRef: RefObject<any> = useRef();
  const parentRef: RefObject<any> = useRef();
  const [isOpen, changeIsOpen] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();

  const isTournamentFavorite = useMemo(
    () =>
      Boolean(
        favoriteTournaments.find(
          (tournament) => uniqueTournamentId === tournament.uniqueTournamentId,
        ),
      ),
    [favoriteTournaments, tournament],
  );

  const lastEventIndex = events.length - 1;
  const containerClasses = classnames(styles.tournament, { [styles.singleRow]: isSingleRow });
  const eventsCountClasses = classnames(styles.eventsCount, { [styles.hidden]: isOpen });
  const captionRowStickyOffset = useContext(StickyHeaderTopOffsetContext) + headerOffset;

  const handleHeaderClick = () => changeIsOpen(!isOpen);

  const handleCategoryClick = (event) => {
    event.stopPropagation();

    if (useModalOnCategoryClick) {
      const params = validateParams({
        [SearchQuery.Date]: searchParams.get(SearchQuery.Date),
        [SearchQuery.CategoryId]: category.id.toString(),
      });

      setSearchParams(params);
    } else {
      appNavigate(DesktopPage.FootballCategory, { categoryId: category.id });
    }
  };

  const handleTournamentClick = (event) => {
    event.stopPropagation();
    appNavigate(DesktopPage.FootballSeason, { uniqueTournamentId, seasonId: currentSeasonId });
  };

  const handleFavoriteTournamentClick = () => {
    favoriteEventsActions.toggleLeague(tournament);
  };

  return (
    <div ref={parentRef} className={containerClasses}>
      <div
        style={{ top: captionRowStickyOffset }}
        ref={containerRef}
        onClick={handleHeaderClick}
        className={styles.header}
      >
        <Figure onClick={handleTournamentClick} src={logo} className={styles.logo} />

        <div className={styles.centerBlock}>
          <div className={styles.nameBlock}>
            <div onClick={handleTournamentClick} className={styles.name}>
              {isUniqueTournamentNameDisplayed
                ? uniqueTournamentName
                : name || uniqueTournamentName}
            </div>
            <div onClick={handleCategoryClick} className={styles.countryInfo}>
              {category.name}
            </div>
          </div>

          <div className={styles.favorite}>
            <FavoriteIcon
              onClick={handleFavoriteTournamentClick}
              isFavorite={isTournamentFavorite}
            />
          </div>
        </div>

        <div className={styles.controls}>
          <div className={eventsCountClasses}>{events.length}</div>
          <ToggleArrowButton isOpen={isOpen} />
        </div>
      </div>
      {isOpen && (
        <div>
          {events.map((event, index) => (
            <Event
              containerRef={containerRef}
              key={event.id}
              id={event.id}
              event={event}
              activeSportId={activeSportId}
              onClick={() => handleEventClick(event.id)}
              className={{ [styles.activeEvent]: activeEventId === event.id }}
              isSeparatorHidden={isTournamentLast && index === lastEventIndex}
              {...eventComponentProps}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default connect(memo(Tournament));
