import React, { useCallback } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { useNavigation } from 'packages/hooks';
import { Team } from 'types';
import { DesktopPage } from 'router/types';

import FavoriteTeamsView from './views';
import connect from './connect';
import { SortableItem } from '../../types';

type Props = {
  teams: Team[];
  favoritesActions: ActionCreatorsMapObject;
  isPopularTournamentsLoading: boolean;
  isCategoriesLoading: boolean;
};

const extendFavoriteTeams = (
  FavoriteTeams: typeof FavoriteTeamsView[keyof typeof FavoriteTeamsView],
) => ({ teams, favoritesActions, isPopularTournamentsLoading, isCategoriesLoading }: Props) => {
  const { appNavigate } = useNavigation();

  const handleDelete = useCallback((deletingTeam: SortableItem) => {
    favoritesActions.toggleTeam(deletingTeam);
  }, []);

  const handleDragEnd = useCallback((teams: SortableItem[]) => {
    favoritesActions.resetTeams(teams);
  }, []);

  const handleClick = useCallback((team: SortableItem) => {
    appNavigate(DesktopPage.FootballTeam, { teamId: team.id });
  }, []);

  return (
    <FavoriteTeams
      isPopularTournamentsLoading={isPopularTournamentsLoading}
      isCategoriesLoading={isCategoriesLoading}
      teams={teams}
      onDelete={handleDelete}
      onDragEnd={handleDragEnd}
      onClick={handleClick}
    />
  );
};

export default {
  Desktop: connect(extendFavoriteTeams(FavoriteTeamsView.Desktop)),
  DesktopNarrow: connect(extendFavoriteTeams(FavoriteTeamsView.DesktopNarrow)),
  Tablet: connect(extendFavoriteTeams(FavoriteTeamsView.Tablet)),
  TabletNarrow: connect(extendFavoriteTeams(FavoriteTeamsView.TabletNarrow)),
  Mobile: connect(extendFavoriteTeams(FavoriteTeamsView.Mobile)),
};
