import React from 'react';
import { MediaScreen, GridColumnId } from 'types';

import { getStickyHeaderTopOffset } from '../../services';
import { EventsTabs } from '../../components';

type Props = {
  categoryId?: string;
  className?: string;
};

const Desktop = ({ categoryId, className }: Props) => (
  <EventsTabs
    categoryId={categoryId}
    className={className}
    stickyHeaderTopOffset={getStickyHeaderTopOffset(MediaScreen.Desktop)}
    scrollContainerIdentifier={GridColumnId.Primary}
  />
);

export default Desktop;
