import { Event, Tournament } from 'types';
import { toCamelCase } from 'packages/utils/Object.utils';
import DateService from 'packages/date-az';
import { format } from 'packages/utils/Number.utils';

import { mapEventCategory } from './mapEventCategory';
import * as getters from '../getters';
import { mapEventTeams } from './mapEventTeams';
import { mapRound } from './mapRound';

export const mapEvents = (events) => {
  return events.filter(isEventValid).map(mapEvent);
};

const isEventValid = (event): boolean => {
  return event?.event_status;
};

export const mapEvent = (event): Event => {
  const {
    tournament,
    unique_tournament: {
      id: uniqueTournamentId,
      name: uniqueTournamentName,
      logo: uniqueTournamentLogo,
    },
    event_status,
    competitors,
    scheduled,
    id,
    season_id: seasonId,
    category,
    round,
    attendance,
  } = event;
  const { periodScores, injuryTime, timeInfo, isLive } = toCamelCase(event_status, true);
  const status = getters.getStatus(event_status.status);

  return {
    id,
    seasonId,
    tournament: mapTournament(tournament, {
      currentSeasonId: seasonId,
      uniqueTournamentId,
      uniqueTournamentName,
      logo: uniqueTournamentLogo,
    }),
    date: DateService.parse(scheduled).toISODate(),
    status,
    isLive: event_status.is_live,
    isFinished: event_status.is_finished,
    periodTime: isLive ? getters.getPeriodTime(timeInfo, status) : undefined,
    periodScores: getters.getPeriodScore(periodScores),
    periodStatus: getters.getPeriodStatus(periodScores),
    injuryTime: injuryTime || undefined,
    scheduled,
    teams: mapEventTeams(competitors, event_status),
    category: mapEventCategory(category),
    round: round && mapRound(round),
    attendance: attendance ? format(attendance) : undefined,
  };
};

type AdditionalProps = {
  currentSeasonId: number;
  uniqueTournamentId: number;
  uniqueTournamentName: string;
  logo?: string;
};

export const mapTournament = (tournament, additionalProps: AdditionalProps): Tournament => {
  return {
    ...toCamelCase(tournament),
    ...additionalProps,
  };
};
