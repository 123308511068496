import instance from 'packages/rest/axios';

import { FiltersParams } from '../types';

export const loadFilters = ({ seasonId }: FiltersParams) => {
  return instance({
    method: 'get',
    url: `/seasons/${seasonId}/top_players_filters/`,
  });
};
