import Dexie from 'dexie';

import {
  Tables,
  FavoritesLeaguesTableIndex,
  FavoritesTeamsTableIndex,
} from 'configure/storage/types';
import { keyValueDatabase } from 'packages/storage';

const extendItemWithOrderNumber = (item: object, table: Tables) => async () => {
  const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
  const model = dexieInstance.table(table);
  let lastModelRecord;

  switch (table) {
    case Tables.FAVORITE_LEAGUES:
      lastModelRecord = await model.orderBy(FavoritesLeaguesTableIndex.OrderNumber).last();
      break;
    case Tables.FAVORITE_TEAMS:
      lastModelRecord = await model.orderBy(FavoritesTeamsTableIndex.OrderNumber).last();
      break;
    default:
      throw new Error(`Unexpected table provided`);
  }

  const orderNumber = (lastModelRecord?.orderNumber || 0) + 1;

  return { ...item, orderNumber };
};

export default extendItemWithOrderNumber;
