import React from 'react';

import { ScrollParameters } from 'packages/shared/modules/NarrowTeamStatisticsTable/types';
import { getScrollParent } from 'packages/utils/Scroll.utils';

const getScrollToStageTable = (headerBlockRef: React.RefObject<HTMLDivElement>) => {
  return ({ tableBlockRef }: ScrollParameters) => {
    const scrollContainerElement = getScrollParent(tableBlockRef.current);
    const { scrollTop: scrolledValue } = scrollContainerElement || {};
    const [tableHeader = undefined] = tableBlockRef.current?.getElementsByTagName('thead') || [];

    if (!headerBlockRef.current) {
      return;
    }

    const { top: containerTopOffset = 0 } = scrollContainerElement.getBoundingClientRect() || {};
    const { top: tableTopOffset = 0 } = tableBlockRef.current?.getBoundingClientRect() || {};
    const tableHeaderHeight = tableHeader?.offsetHeight || 0;
    const headerBlockHeight = (headerBlockRef.current as HTMLElement).offsetHeight;
    const scrollOffset =
      tableTopOffset - containerTopOffset + scrolledValue - headerBlockHeight + tableHeaderHeight;

    scrollContainerElement.scrollTo({
      left: 0,
      top: scrollOffset,
      behavior: 'smooth',
    });
  };
};

export default getScrollToStageTable;
