import { favoriteEventsActions } from '../../actions';
import { LiveEventsUpdateEventType } from '../../types/enums';
import updateFavoriteEvents from './updateFavoriteEvents';

const subscribeForFavoriteEventsUpdates = () => (dispatch) => {
  try {
    dispatch(favoriteEventsActions.subscribeForFavoriteEventsUpdatesStart());

    const handleUpdatedData = (event) => {
      const { detail } = event as CustomEvent;

      dispatch(updateFavoriteEvents(detail));
    };

    document.addEventListener(LiveEventsUpdateEventType.Updated, handleUpdatedData);
    document.addEventListener(LiveEventsUpdateEventType.Inserted, handleUpdatedData);
    document.addEventListener(LiveEventsUpdateEventType.Removed, handleUpdatedData);

    dispatch(favoriteEventsActions.subscribeForFavoriteEventsUpdatesSuccess());

    return () => {
      document.removeEventListener(LiveEventsUpdateEventType.Updated, handleUpdatedData);
      document.removeEventListener(LiveEventsUpdateEventType.Inserted, handleUpdatedData);
      document.removeEventListener(LiveEventsUpdateEventType.Removed, handleUpdatedData);
    };
  } catch (error) {
    dispatch(favoriteEventsActions.subscribeForFavoriteEventsUpdatesFailure(error));
  }
};

export default subscribeForFavoriteEventsUpdates;
