import * as seasonDetailsActions from './actions';
import * as seasonDetailsSelectors from './selectors';
import * as seasonDetailsConstants from './constants';
import * as seasonDetailsThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  seasonDetailsActions,
  seasonDetailsConstants,
  seasonDetailsThunks,
  seasonDetailsSelectors,
  createReducer,
};
