import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { TopVenuesStatus } from '../../types';
import * as actions from '../../actions';

const defaultState: TopVenuesStatus = {
  isTopVenuesLoading: true,
};

const reducerMap: ReducerMap<TopVenuesStatus, any> = {
  [`${actions.loadTopVenuesStart}`]: () => {
    return { isTopVenuesLoading: true };
  },
  [`${combineActions(actions.loadTopVenuesFailure, actions.loadTopVenuesSuccess)}`]: () => {
    return { isTopVenuesLoading: false };
  },
  [`${actions.cleanTopBoardSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<TopVenuesStatus>(reducerMap, defaultState);
