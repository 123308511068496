import React, { CSSProperties } from 'react';
import classnames from 'classnames';

import { IconType } from 'packages/shared/components/Icon/types';
import { Icon } from 'packages/shared/components';

import { Options } from './services/customizeJersey';
import { Jersey as JerseyType } from './types';
import { useJerseyCustomization } from './hooks';
import styles from './styles.scss';

type Props = {
  jersey: JerseyType;
  containerStyle?: CSSProperties;
  iconStyle?: CSSProperties;
  options?: Options;
  className?: string;
};

const Jersey = ({ jersey, options, containerStyle, iconStyle, className }: Props) => {
  const { jerseyContainerRef } = useJerseyCustomization(jersey, options);

  return (
    <div
      ref={jerseyContainerRef}
      style={containerStyle}
      className={classnames(styles.jerseyContainer, className)}
    >
      <Icon id={IconType.Jersey} style={iconStyle} className={styles.jersey} />
    </div>
  );
};

export default Jersey;
