import React from 'react';

import Skeleton from 'packages/shared/components/Skeleton';

import styles from './styles.scss';

const EventInfo = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Skeleton width={63} height={24} marginRight={10} variant="rectangular" />
        <Skeleton width={16} height={4} variant="rectangular" />
        <Skeleton width={63} height={24} marginLeft={10} variant="rectangular" />
      </div>

      <Skeleton width={114} height={8} marginTop={16} variant="rectangular" />
    </div>
  );
};

export default EventInfo;
