import { toCamelCase } from 'packages/utils/Object.utils';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { Season } from 'types';

const mapTournamentList = (tournaments: Record<string, unknown>[]): SeasonsExtendedTournament[] => {
  return tournaments.map(mapTournament);
};

const mapTournament = (tournament: Record<string, unknown>): SeasonsExtendedTournament => {
  const { id, name, logo, seasons } = toCamelCase(tournament);

  return { id, name, logo, seasons: seasons.map(mapSeason) };
};

const mapSeason = (season: Record<string, unknown>): Season => {
  const { id, name, year, startDate, endDate } = toCamelCase(season);

  return {
    id,
    name,
    year,
    startDate,
    endDate,
  };
};

export default mapTournamentList;
