import React, { memo, RefObject, useCallback, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';
import i18n from 'i18n-smart';

import { Event, Season, TimeZone } from 'types';
import { DesktopPage } from 'router/types';
import { BlockLink } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';

import { EventList } from '../../../../components';
import { LoadingSkeleton } from './components';
import { ACTUAL_GAMES_LIMIT } from './constants';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  events: Event[];
  isEventsLoading: boolean;
  isFiltersLoading: boolean;
  containerRef: RefObject<HTMLDivElement>;
  onEventClick: (eventId: number) => void;
  selectedSeason: Season;
  seasonEventsActions: ActionCreatorsMapObject;
  timeZone: TimeZone;
};

const SeasonEvents = ({
  events,
  containerRef,
  onEventClick,
  isEventsLoading,
  isFiltersLoading,
  selectedSeason,
  seasonEventsActions,
  timeZone,
}: Props) => {
  const { seasonId = '', tournamentId = '' } = useParams();
  const { appNavigate } = useNavigation();

  useEffect(() => {
    if (selectedSeason) {
      seasonEventsActions.loadActualEventsBlock({
        selectedSeason,
        limit: ACTUAL_GAMES_LIMIT,
      });
    }

    return () => {
      seasonEventsActions.unsubscribe();
      seasonEventsActions.clear();
    };
  }, [selectedSeason, timeZone.value]);

  const handleLinkClick = useCallback(() => {
    appNavigate(DesktopPage.FootballSeasonGames, { uniqueTournamentId: tournamentId, seasonId });
  }, [seasonId, tournamentId]);

  return isFiltersLoading ? (
    <LoadingSkeleton />
  ) : (
    <>
      <div className={styles.header}>
        <div className={styles.title}>{i18n.value('season.seasonSummary.games.title')}</div>

        <BlockLink
          label={i18n.value('season.seasonSummary.games.link')}
          onClick={handleLinkClick}
        />
      </div>

      <EventList.Mobile
        events={events}
        containerRef={containerRef}
        isLoading={isEventsLoading}
        onEventClick={onEventClick}
      />
    </>
  );
};

export default connect(memo(SeasonEvents));
