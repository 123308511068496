import Dexie from 'dexie';

import { keyValueDatabase, KeyValueDatabase } from 'packages/storage';

import schema, { Tables, dbName, dbVersion, namespace } from './schema';
import { initializeHooks } from './services';

class StorageInstance implements KeyValueDatabase {
  storage: Dexie;

  constructor(db) {
    this.storage = db;
  }

  set(key: string, value: any, tableName?: string) {
    const ns = namespace(tableName);
    console.debug(`Table '${ns}' -> set: `, key, value);

    return this.storage.table(ns).put(value, key);
  }

  get(key: string, tableName?: string) {
    const ns = namespace(tableName);

    return this.storage.table(ns).get(key);
  }

  remove(key: string, tableName?: string) {
    const ns = namespace(tableName);
    console.debug(`Table '${ns}' -> remove: `, key);

    return this.storage.table(ns).delete(key);
  }

  clearTable(tableName?: string) {
    const ns = namespace(tableName);

    return this.storage.table(ns).clear();
  }

  getAll(tableName?: string) {
    const ns = namespace(tableName);

    return this.storage.table(ns).toArray();
  }

  getStorageInstance() {
    return this.storage;
  }
}

export default function DexieInit() {
  const db = new Dexie(dbName);
  db.version(dbVersion).stores(schema);
  const instance = new StorageInstance(db);
  keyValueDatabase.init(instance, Tables);

  initializeHooks(db);
}
