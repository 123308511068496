import React, { memo, useCallback, useMemo } from 'react';
import i18n from 'i18n-smart';
import { ActionCreatorsMapObject } from 'redux';
import { ArrayUtils } from 'packages/utils';

import { Select, Separator } from 'packages/shared/components';
import { Language, LocaleCode, TimeZone } from 'types';
import { SelectTheme } from 'packages/shared/components/Select/types';

import { LanguageSelect } from './components';
import styles from './styles.scss';
import connect from './connect';
import { getTimeZoneSelectItems, getTimeZoneSelectItem } from './services';

type Props = {
  timeZones: TimeZone[];
  languages: Language[];
  localeCode: LocaleCode;
  selectedTimeZone: TimeZone;
  settingsActions: ActionCreatorsMapObject;
};

const Settings = ({
  languages,
  timeZones,
  localeCode,
  settingsActions,
  selectedTimeZone,
}: Props) => {
  const timeZoneSelectItems = useMemo(() => getTimeZoneSelectItems(timeZones), [timeZones]);
  const selectedTimeZoneSelectItem = useMemo(() => getTimeZoneSelectItem(selectedTimeZone), [
    selectedTimeZone,
  ]);

  const handleTimeZoneChange = useCallback(
    (item) => {
      const selectedTimeZone = ArrayUtils.findBy(timeZones, 'value', item.id);

      settingsActions.setTimeZone(selectedTimeZone);
    },
    [timeZones],
  );

  return (
    <div className={styles.container}>
      <h2 className={styles.header}>{i18n.value('settings.header')}</h2>
      <Separator />

      <div className={styles.controls}>
        <h3 className={styles.subHeader}>{i18n.value('settings.header')}</h3>

        <div className={styles.block}>
          <div className={styles.subTitle}>{i18n.value('settings.timeZone')}</div>

          <Select
            itemsContainerClass={styles.timeZoneSelect}
            theme={SelectTheme.Gray}
            items={timeZoneSelectItems}
            selectedItem={selectedTimeZoneSelectItem}
            onChange={handleTimeZoneChange}
          />
        </div>

        <div className={styles.block}>
          <div className={styles.subTitle}>{i18n.value('settings.language')}</div>

          <LanguageSelect
            localeCode={localeCode}
            languages={languages}
            onLanguageChange={settingsActions.setLocale}
          />
        </div>
      </div>
      <Separator />
    </div>
  );
};

export default connect(memo(Settings));
