import DateService from 'packages/date-az';

import { YearItem } from '../types';

const getYearsByOffset = (yearOffset: number): YearItem[] => {
  const years: YearItem[] = [];

  const currentDate = DateService.getDate();
  const { year: offsetYear } = currentDate.minus({ years: yearOffset });

  for (let currentYear = offsetYear; currentYear <= currentDate.year; currentYear++) {
    years.push(parseDateFromYear(currentYear));
  }

  return years.reverse();
};

const parseDateFromYear = (year: number): YearItem => {
  const date = DateService.getDate().set({ year: year });

  return {
    year: `${year}`,
    startDate: date.startOf('year'),
    endDate: date.endOf('year'),
  };
};

export default getYearsByOffset;
