import { RefObject, useEffect, useState } from 'react';

interface StuckOptions<P extends HTMLElement> {
  parentRef?: RefObject<P>;
  offset?: StuckOffset;
  initialValue?: boolean;
}

interface StuckOffset {
  top?: number;
  bottom?: number;
  left?: number;
  right?: number;
}

const useIntersection = <T extends HTMLElement, P extends HTMLElement>(
  targetRef: RefObject<T>,
  options: StuckOptions<P> = {},
) => {
  const { parentRef, initialValue = false, offset = {} } = options;
  const { top = -1, bottom = 0, left = 0, right = 0 } = offset;

  const [isIntersected, setIsIntersected] = useState(initialValue);

  useEffect(() => {
    if (targetRef.current) {
      const observer = new IntersectionObserver(
        ([{ intersectionRatio }]) => {
          setIsIntersected(intersectionRatio === 1);
        },
        {
          rootMargin: `${top}px ${right}px ${bottom}px ${left}px`,
          root: parentRef?.current,
          threshold: [0.99, 1],
        },
      );

      observer.observe(targetRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, []);

  return isIntersected;
};

export default useIntersection;
