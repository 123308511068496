import React, { RefObject, useCallback, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SearchQuery } from 'router/types';

import styles from './styles.scss';
import ActualEventsView from './views';

const extendActualEvents = (
  ActualEvents: typeof ActualEventsView[keyof typeof ActualEventsView],
) => {
  return () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const containerRef: RefObject<HTMLDivElement> = useRef(null);

    const handleEventClick = useCallback(
      (eventId) => {
        setSearchParams({ [SearchQuery.EventCardId]: eventId });
      },
      [searchParams],
    );

    return (
      <div ref={containerRef} className={styles.seasonEvents}>
        <ActualEvents onEventClick={handleEventClick} containerRef={containerRef} />
      </div>
    );
  };
};

export default {
  Desktop: extendActualEvents(ActualEventsView.Desktop),
  DesktopNarrow: extendActualEvents(ActualEventsView.DesktopNarrow),
  Tablet: extendActualEvents(ActualEventsView.Tablet),
  TabletNarrow: extendActualEvents(ActualEventsView.TabletNarrow),
  Mobile: extendActualEvents(ActualEventsView.Mobile),
};
