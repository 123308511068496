import { HOME_QUALIFIER } from 'packages/events/constants';
import { toCamelCase } from 'packages/utils/Object.utils';
import {
  EventSummary,
  EventSummaryReferee,
  EventSummaryStadium,
  EventSummaryTeam,
  HappeningPeriod,
} from '../../types';

const mapEventSummary = (summary, periods): EventSummary => {
  const { teams, stadium, attendance, referee, facts = [] } = toCamelCase(summary, true);

  return {
    teams: mapTeams(teams),
    facts,
    periods: periods as HappeningPeriod[],
    stadium: stadium ? mapStadium(stadium) : stadium,
    referee: referee ? mapReferee(referee) : referee,
    attendance,
  };
};

const mapTeams = (teams): [EventSummaryTeam, EventSummaryTeam] =>
  teams.map((team) => mapTeam(team));

const mapTeam = (team) => {
  const { id, name, logo, form, points, position } = team;

  return {
    id,
    name,
    logo,
    position,
    points,
    form: mapForm(form || []),
  };
};

export const mapForm = (totalForm) =>
  totalForm
    .map((form) => {
      const { id, value, competitors, scheduled, eventStatus } = form;
      const [firstTeam, secondTeam] = mapFormTeams(competitors);
      const homeTeam = firstTeam.qualifier === HOME_QUALIFIER ? firstTeam : secondTeam;
      const awayTeam = secondTeam.qualifier === HOME_QUALIFIER ? firstTeam : secondTeam;

      return {
        id,
        homeTeam,
        awayTeam,
        scheduled,
        status: mapFormEventStatus(eventStatus),
        value,
      };
    })
    .slice(0, 5);

const mapFormTeams = (teams: any[]) =>
  teams.map((team) => {
    const { id, name, logo, qualifier, team: competitor } = team;

    return {
      id: id || competitor?.id,
      name: name || competitor?.name,
      logo: logo || competitor?.logo,
      qualifier: qualifier,
    };
  });

const mapFormEventStatus = ({ homeScore, awayScore, winner: winnerId }) => ({
  homeScore,
  awayScore,
  winnerId,
});

const mapReferee = (referee): EventSummaryReferee => {
  const { name, logo, countryName, countryLogo, statistics } = referee;
  const redCardsAverage = statistics?.red_cards_average;
  const yellowCardsAverage = statistics?.yellow_cards_average;
  const penaltiesAverage = statistics?.penalties_average;

  return {
    name,
    logo,
    countryLogo,
    countryName,
    redCardsAverage: redCardsAverage?.toFixed(2),
    yellowCardsAverage: yellowCardsAverage?.toFixed(2),
    penaltiesAverage: penaltiesAverage?.toFixed(2),
  };
};

const mapStadium = (stadium): EventSummaryStadium => {
  const { id, city, countryLogo, name, capacity } = stadium;

  return { id, city, countryLogo, name, capacity };
};

export default mapEventSummary;
