import { TournamentView, Event } from 'packages/events/types';
import { Tournament } from 'types';

import { groupEventsByTournament, sortTournamentsByFavoriteIds } from '../../services';

export const getGroupedEvents = (
  events: Event[],
  favoriteLeagues: Tournament[],
): TournamentView[] => {
  const groupedEvents = groupEventsByTournament(events);
  const favoriteLeagueUniqueTournamentIds = favoriteLeagues.map(
    ({ uniqueTournamentId }) => uniqueTournamentId,
  );

  return sortTournamentsByFavoriteIds(groupedEvents, favoriteLeagueUniqueTournamentIds);
};
