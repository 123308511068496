import React, { memo, RefObject, useLayoutEffect, useRef, useState } from 'react';

import Figure from 'packages/shared/components/Figure';
import { FlexiblePopover } from 'packages/shared/components';
import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';
import { isTextOverlaps } from 'packages/utils/Document.utils';

import PopoverContent from '../PopoverContent';
import styles from './styles.scss';

type Props = {
  countryLogo: string;
  city: string;
  isNameDisplayed?: boolean;
};

const CountryLogoCell = ({ countryLogo, isNameDisplayed = false, city }: Props) => {
  const labelRef: RefObject<HTMLDivElement> = useRef(null);
  const [isPopoverDisabled, setIsPopoverDisabled] = useState<boolean>(true);

  useLayoutEffect(() => {
    if (labelRef.current && isTextOverlaps(labelRef.current)) {
      setIsPopoverDisabled(false);
    }
  }, []);

  return (
    <div className={styles.countryCell}>
      <div className={styles.countryBlock}>
        <Figure className={styles.logo} src={countryLogo} />

        {isNameDisplayed && (
          <FlexiblePopover
            closeOnClickOutside
            popoverComponent={PopoverContent}
            popoverProps={{ value: city }}
            justify={PopoverJustify.Center}
            invokeTrigger={PopoverInvokeTrigger.Hover}
            align={PopoverAlign.Bottom}
            isDisabled={isPopoverDisabled}
          >
            <div className={styles.name}>
              <div ref={labelRef}>{city}</div>
            </div>
          </FlexiblePopover>
        )}
      </div>
    </div>
  );
};

export default memo(CountryLogoCell);
