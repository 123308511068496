import { MobilePage } from '../enums';

export interface MobilePathParams {
  [MobilePage.Football]: {};

  [MobilePage.Settings]: {};

  [MobilePage.FootballSeason]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [MobilePage.FootballSeasonStandings]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [MobilePage.FootballSeasonPlayerStatistics]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [MobilePage.FootballSeasonTeamStatistics]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [MobilePage.FootballSeasonStadiums]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [MobilePage.FootballSeasonGames]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [MobilePage.FootballTeam]: {
    teamId: string | number;
  };
  [MobilePage.FootballTeamGames]: {
    teamId: string | number;
  };
  [MobilePage.FootballTeamSquad]: {
    teamId: string | number;
  };
  [MobilePage.FootballTeamSummaryDescription]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
    teamId: string | number;
  };
  [MobilePage.FootballTeamStandings]: {
    teamId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [MobilePage.FootballTeamPlayerStatistics]: {
    teamId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [MobilePage.FootballCategory]: {
    categoryId: string | number;
  };

  [MobilePage.FootballPlayer]: {
    playerId: string | number;
  };
  [MobilePage.FootballPlayerSummary]: {
    playerId: string | number;
  };
  [MobilePage.FootballPlayerGames]: {
    playerId: string | number;
  };

  [MobilePage.FootballStadium]: {
    stadiumId: string | number;
  };
  [MobilePage.FootballStadiumSummary]: {
    stadiumId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [MobilePage.FootballMatch]: {
    eventId: string | number;
  };
}
