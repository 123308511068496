import { useEffect } from 'react';

const useBodyOverflowDisabling = () => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'initial';
    };
  }, []);
};

export default useBodyOverflowDisabling;
