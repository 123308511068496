import { BaseDifferenceExtractor } from '../differenceExtractors';
import BaseUpdatesSelector from './BaseUpdatesSelector';

abstract class DifferentiatedUpdatesSelector<ItemType> extends BaseUpdatesSelector<ItemType> {
  protected abstract differenceExtractor: BaseDifferenceExtractor<any, any>;

  public handleDataUpdate(data: any[]) {
    const temporaryVersion = this.version;

    this.incrementVersion();

    if (temporaryVersion === 0) {
      return this.handleInitialDataUpdate(data);
    } else {
      return this.handleRepetitiveDataUpdate(data);
    }
  }

  public handleInitialDataUpdate(data: any) {
    this.data = this.prepareDataForUpdateSelection(data);
    this.handleNewData(this.data);

    return this.buildMessageBody(this.data);
  }

  public handleRepetitiveDataUpdate(data: any) {
    const preparedData = this.prepareDataForUpdateSelection(data);
    const { added, updated, removed } = this.differenceExtractor.getDataUpdate(
      this.data,
      preparedData,
    );

    this.data = preparedData;
    this.handleNewData(added);
    this.handleUpdatedData(updated);
    this.handleRemovedData(removed);

    return this.buildMessageBody(this.data);
  }

  protected prepareDataForUpdateSelection(data: any) {
    return data;
  }
}

export default DifferentiatedUpdatesSelector;
