import { ColumnType, RangeFilterState, RangeType, StatType } from '../types';
import { RANGE_FILTER_PARAMETER_BY_STAT_TYPE } from '../constants';

const formatRangeFilters = (rangeFilters: RangeFilterState, statType: StatType) => {
  return {
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinTotalShots]]:
      rangeFilters[ColumnType.TotalShots]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxTotalShots]]:
      rangeFilters[ColumnType.TotalShots]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShotsOnGoal]]:
      rangeFilters[ColumnType.ShotsOnGoal]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShotsOnGoal]]:
      rangeFilters[ColumnType.ShotsOnGoal]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShotsOffGoal]]:
      rangeFilters[ColumnType.ShotsOffGoal]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShotsOffGoal]]:
      rangeFilters[ColumnType.ShotsOffGoal]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShotsBlocked]]:
      rangeFilters[ColumnType.ShotsBlocked]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShotsBlocked]]:
      rangeFilters[ColumnType.ShotsBlocked]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShotsOnPost]]:
      rangeFilters[ColumnType.ShotsOnPost]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShotsOnPost]]:
      rangeFilters[ColumnType.ShotsOnPost]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShotsOffGoal]]:
      rangeFilters[ColumnType.ShotsOffGoal]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShotsOffGoal]]:
      rangeFilters[ColumnType.ShotsOffGoal]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShotsOnGoal]]:
      rangeFilters[ColumnType.ShotsOnGoal]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShotsOnGoal]]:
      rangeFilters[ColumnType.ShotsOnGoal]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShotsOnBar]]:
      rangeFilters[ColumnType.ShotsOnBar]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShotsOnBar]]:
      rangeFilters[ColumnType.ShotsOnBar]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShootingEfficiency]]:
      rangeFilters[ColumnType.ShootingEfficiency]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShootingEfficiency]]:
      rangeFilters[ColumnType.ShootingEfficiency]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinPossession]]:
      rangeFilters[ColumnType.Possession]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxPossession]]:
      rangeFilters[ColumnType.Possession]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinCornerKicks]]:
      rangeFilters[ColumnType.CornerKicks]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxCornerKicks]]:
      rangeFilters[ColumnType.CornerKicks]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinFreeKicks]]:
      rangeFilters[ColumnType.FreeKicks]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxFreeKicks]]:
      rangeFilters[ColumnType.FreeKicks]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinOffside]]:
      rangeFilters[ColumnType.Offside]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxOffside]]:
      rangeFilters[ColumnType.Offside]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinYellowCards]]:
      rangeFilters[ColumnType.YellowCards]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxYellowCards]]:
      rangeFilters[ColumnType.YellowCards]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinYellowRedCards]]:
      rangeFilters[ColumnType.YellowRedCards]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxYellowRedCards]]:
      rangeFilters[ColumnType.YellowRedCards]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinRedCards]]:
      rangeFilters[ColumnType.RedCards]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxRedCards]]:
      rangeFilters[ColumnType.RedCards]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinGoalConceded]]:
      rangeFilters[ColumnType.GoalsConceded]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxGoalConceded]]:
      rangeFilters[ColumnType.GoalsConceded]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinGoalsScored]]:
      rangeFilters[ColumnType.GoalsScored]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxGoalsScored]]:
      rangeFilters[ColumnType.GoalsScored]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinHeaded]]:
      rangeFilters[ColumnType.Headed]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxHeaded]]:
      rangeFilters[ColumnType.Headed]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinFootGoals]]:
      rangeFilters[ColumnType.FootGoals]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxFootGoals]]:
      rangeFilters[ColumnType.FootGoals]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinGamesPlayed]]:
      rangeFilters[ColumnType.GamesPlayed]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxGamesPlayed]]:
      rangeFilters[ColumnType.GamesPlayed]?.to,
  };
};

export default formatRangeFilters;
