import { Tournament } from 'types';

type Params = {
  tournaments: Tournament[];
  favoriteLeagues: Tournament[];
};

const filterFavoriteTournaments = ({ tournaments, favoriteLeagues }: Params): Tournament[] => {
  return tournaments.reduce<Tournament[]>((acc, currentTournament) => {
    const { uniqueTournamentId: leagueId } = currentTournament;

    const isInFavoriteLeagues = favoriteLeagues.find(
      ({ uniqueTournamentId }) => uniqueTournamentId === leagueId,
    );

    if (!isInFavoriteLeagues) {
      acc.push(currentTournament);
    }

    return acc;
  }, []);
};

export default filterFavoriteTournaments;
