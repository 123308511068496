import { useEffect, useState } from 'react';

import { getDefaultTab, getTabByPredicate } from '../services';
import { TabView } from '../types';

const useWiredTabs = (
  tabs: TabView[],
  onTabChange?: (tabId: string | number) => void,
): {
  activeTab: TabView;
  setActiveTab: (id: string | number) => void;
} => {
  const [activeTab, setActiveTab] = useState<TabView>(getDefaultTab(tabs));

  const setActiveTabById = (tabId) => {
    if (activeTab.id === tabId) {
      return;
    }

    const tab = getTabByPredicate(tabs, ({ id }) => id === tabId);

    setActiveTab(tab);
    onTabChange?.(tabId);
  };

  useEffect(() => {
    setActiveTab(getDefaultTab(tabs));
  }, [tabs]);

  return { activeTab, setActiveTab: setActiveTabById };
};

export default useWiredTabs;
