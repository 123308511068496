import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

type Props = {
  name?: string;
  onChange: () => void;
  isChecked?: boolean;
  isDisabled?: boolean;
  className?: string;
};

const PlainTextInput = ({
  isChecked = false,
  isDisabled = false,
  className,
  onChange,
  name,
}: Props) => {
  return (
    <input
      id={name}
      name={name}
      type="checkbox"
      checked={isChecked}
      disabled={isDisabled}
      onChange={onChange}
      className={classnames(styles.checkboxInput, className)}
    />
  );
};

export default React.memo(PlainTextInput);
