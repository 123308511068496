import React, { useCallback, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';
import isEmpty from 'is-empty-typed/lib';

import { Newcomers as NewcomersType } from 'packages/season-newcomers/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Team } from 'types';

import { NewcomersDetails, LoadingSkeleton } from './components';
import connect from './connect';

type Props = {
  newcomers: NewcomersType;
  isLoading: boolean;
  seasonTopBoardActions: ActionCreatorsMapObject;
};

const Newcomers = ({ newcomers, isLoading, seasonTopBoardActions }: Props) => {
  const { tournamentId = '', seasonId = '' } = useParams();
  const { appNavigate } = useNavigation();

  useEffect(() => {
    seasonTopBoardActions.loadNewcomers(seasonId);

    return () => {
      seasonTopBoardActions.cleanNewcomers();
    };
  }, [seasonId]);

  const handleTeamClick = useCallback(
    (team: Team) => {
      appNavigate(DesktopPage.FootballTeamSummaryDescription, {
        teamId: team.id,
        uniqueTournamentId: tournamentId,
        seasonId,
      });
    },
    [tournamentId, seasonId],
  );

  const isNewcomersEmpty =
    isEmpty(newcomers.newcomers) &&
    isEmpty(newcomers.lowerDivisions) &&
    isEmpty(newcomers.higherDivisions);

  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <>{!isNewcomersEmpty && <NewcomersDetails onTeamClick={handleTeamClick} {...newcomers} />}</>
  );
};

export default connect(Newcomers);
