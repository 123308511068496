import Dexie from 'dexie';

import { keyValueDatabase } from 'packages/storage';
import { Tables } from 'configure/storage/types';
import { EventTeam } from 'types';

import {
  updateFavoriteTeamsStart,
  updateFavoriteTeamsFailure,
  updateFavoriteTeamsSuccess,
} from '../actions';
import { injectOrderNumberByIndex } from '../services';

const resetTeams = (teams: EventTeam[]) => async (dispatch) => {
  try {
    dispatch(updateFavoriteTeamsStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoriteTeamsModel = dexieInstance.table(Tables.FAVORITE_TEAMS);

    const orderedTeams = injectOrderNumberByIndex(teams);

    await favoriteTeamsModel.clear();
    await favoriteTeamsModel.bulkAdd(orderedTeams);

    dispatch(updateFavoriteTeamsSuccess(teams));
  } catch (e) {
    dispatch(updateFavoriteTeamsFailure(e));
  }
};

export default resetTeams;
