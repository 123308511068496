import React from 'react';

import { getDateLabel } from './services';

type Props = {
  value?: string | [string, string];
};

const DateLabel = ({ value }: Props) => {
  return <>{value ? getDateLabel(value) : ''}</>;
};

export default DateLabel;
