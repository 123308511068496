exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1sdKljRT{padding:8px 16px 0 16px}._1sdKljRT ._11QINFqv{margin-bottom:12px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/stadium-summary-ui/containers/TournamentSeasonDetails/views/Mobile/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,uBCJU,CDEd,sBAKM,kBAAmB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .details-selector-container {\n    padding: 8px $padding 0 $padding;\n\n    .details-selector {\n      margin-bottom: 12px;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"details-selector-container": "_1sdKljRT",
	"detailsSelectorContainer": "_1sdKljRT",
	"details-selector": "_11QINFqv",
	"detailsSelector": "_11QINFqv"
};