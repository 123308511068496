import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { NavigateOptions } from 'react-router';

import { teamActiveTournamentSeasonSelectors } from 'packages/team-active-tournament-season';
import { DesktopPathParams, DesktopPage } from 'router/types';

const useDesktopNavigation = () => {
  const teamActiveTournamentSeasons = useSelector(teamActiveTournamentSeasonSelectors.getData);
  const navigate = useNavigate();

  const appNavigate = <T extends keyof DesktopPathParams>(
    page: T,
    params: DesktopPathParams[T],
    query?: string,
    options?: NavigateOptions,
  ) => {
    switch (page) {
      case DesktopPage.Football: {
        navigate('/football', options);
        break;
      }
      case DesktopPage.FootballSeason: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as DesktopPathParams[DesktopPage.FootballSeason];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}`,
            search: query,
          },
          options,
        );
        break;
      }
      case DesktopPage.FootballSeasonGames: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as DesktopPathParams[DesktopPage.FootballSeasonGames];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}/games`,
            search: query,
          },
          options,
        );
        break;
      }
      case DesktopPage.FootballSeasonStandings: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as DesktopPathParams[DesktopPage.FootballSeasonGames];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}/standings`,
            search: query,
          },
          options,
        );
        break;
      }
      case DesktopPage.FootballSeasonPlayerStatistics: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as DesktopPathParams[DesktopPage.FootballSeasonPlayerStatistics];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}/player-statistics`,
            search: query,
          },
          options,
        );
        break;
      }
      case DesktopPage.FootballSeasonTeamStatistics: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as DesktopPathParams[DesktopPage.FootballSeasonTeamStatistics];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}/team-statistics`,
            search: query,
          },
          options,
        );
        break;
      }
      case DesktopPage.FootballSeasonStadiums: {
        const {
          seasonId,
          uniqueTournamentId,
        } = params as DesktopPathParams[DesktopPage.FootballSeasonStadiums];

        navigate(
          {
            pathname: `/football/unique-tournament/${uniqueTournamentId}/season/${seasonId}/stadiums`,
            search: query,
          },
          options,
        );
        break;
      }
      case DesktopPage.FootballTeam: {
        const { teamId } = params as DesktopPathParams[DesktopPage.FootballTeam];

        if (teamActiveTournamentSeasons[teamId]) {
          const { tournamentId, seasonId } = teamActiveTournamentSeasons[teamId];

          navigate(
            {
              pathname: `/football/team/${teamId}/summary/unique-tournament/${tournamentId}/season/${seasonId}`,
              search: query,
            },
            options,
          );
        } else {
          navigate({ pathname: `/football/team/${teamId}`, search: query }, options);
        }

        break;
      }
      case DesktopPage.FootballTeamSummaryDescription: {
        const {
          uniqueTournamentId,
          seasonId,
          teamId,
        } = params as DesktopPathParams[DesktopPage.FootballTeamSummaryDescription];

        navigate(
          {
            pathname: `/football/team/${teamId}/summary/unique-tournament/${uniqueTournamentId}/season/${seasonId}`,
            search: query,
          },
          options,
        );
        break;
      }
      case DesktopPage.FootballTeamGames: {
        const { teamId } = params as DesktopPathParams[DesktopPage.FootballTeamGames];

        navigate({ pathname: `/football/team/${teamId}/games`, search: query }, options);
        break;
      }
      case DesktopPage.FootballTeamSquad: {
        const { teamId } = params as DesktopPathParams[DesktopPage.FootballTeamSquad];

        navigate({ pathname: `/football/team/${teamId}/squad`, search: query }, options);
        break;
      }
      case DesktopPage.FootballTeamStandings: {
        const {
          uniqueTournamentId,
          seasonId,
          teamId,
        } = params as DesktopPathParams[DesktopPage.FootballTeamStandings];

        navigate(
          {
            pathname: `/football/team/${teamId}/standings/unique-tournament/${uniqueTournamentId}/season/${seasonId}`,
            search: query,
          },
          options,
        );
        break;
      }
      case DesktopPage.FootballTeamPlayerStatistics: {
        const {
          uniqueTournamentId,
          seasonId,
          teamId,
        } = params as DesktopPathParams[DesktopPage.FootballTeamPlayerStatistics];

        navigate(
          {
            // eslint-disable-next-line max-len
            pathname: `/football/team/${teamId}/player-statistics/unique-tournament/${uniqueTournamentId}/season/${seasonId}`,
            search: query,
          },
          options,
        );
        break;
      }
      case DesktopPage.FootballCategory: {
        const { categoryId } = params as DesktopPathParams[DesktopPage.FootballCategory];

        navigate({ pathname: `/football/category/${categoryId}`, search: query }, options);
        break;
      }
      case DesktopPage.FootballPlayer: {
        const { playerId } = params as DesktopPathParams[DesktopPage.FootballPlayer];

        navigate({ pathname: `/football/player/${playerId}`, search: query }, options);
        break;
      }
      case DesktopPage.FootballPlayerGames: {
        const { playerId } = params as DesktopPathParams[DesktopPage.FootballPlayerGames];

        navigate({ pathname: `/football/player/${playerId}/games`, search: query }, options);
        break;
      }
      case DesktopPage.FootballPlayerSummary: {
        const { playerId } = params as DesktopPathParams[DesktopPage.FootballPlayerSummary];

        navigate({ pathname: `/football/player/${playerId}/summary`, search: query }, options);
        break;
      }
      case DesktopPage.FootballStadium: {
        const { stadiumId } = params as DesktopPathParams[DesktopPage.FootballStadium];

        navigate({ pathname: `/football/stadium/${stadiumId}`, search: query }, options);
        break;
      }
      case DesktopPage.FootballStadiumSummary: {
        const {
          stadiumId,
          uniqueTournamentId,
          seasonId,
        } = params as DesktopPathParams[DesktopPage.FootballStadiumSummary];

        navigate(
          {
            pathname: `/football/stadium/${stadiumId}/unique-tournament/${uniqueTournamentId}/season/${seasonId}`,
            search: query,
          },
          options,
        );
        break;
      }

      case DesktopPage.FootballMatch: {
        const { eventId } = params as DesktopPathParams[DesktopPage.FootballMatch];

        navigate(
          {
            pathname: `/football/match/${eventId}`,
            search: query,
          },
          options,
        );
        break;
      }
    }
  };

  const deltaNavigate = (delta: number) => {
    navigate(delta);
  };

  return { appNavigate, deltaNavigate };
};

export default useDesktopNavigation;
