import { groupByWithExistingOrder } from 'packages/utils/Array.utils';
import { TournamentView, Event } from 'packages/events/types';

export const groupEventsByTournament = (events: Event[] = []): TournamentView[] => {
  const eventsByTournamentId: Event[][] = groupByWithExistingOrder(
    events,
    ({ tournament }: Event) => (tournament.id ? tournament.id : tournament.uniqueTournamentId),
  );

  return eventsByTournamentId.map(
    (events): TournamentView => {
      const [{ tournament, category }] = events;

      return { ...tournament, category, events };
    },
  );
};
