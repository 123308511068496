import getPath from './getPath';
import { EventsFilterStatus } from '../../types';

export const getStatus = (state): EventsFilterStatus => {
  return getPath(state).status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
