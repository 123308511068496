exports = module.exports = require("../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2o4bz8uq ._1nxaymYZ{display:inline-block;width:20px;height:13px;margin-right:8px;border-radius:2px;background-size:cover}._2o4bz8uq ._1nxaymYZ,._2o4bz8uq ._2EgQUJLk{cursor:pointer}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/event-summary-ui/containers/EventSummary/views/Desktop/components/SummaryDetails/views/StadiumPreview/styles.scss"],"names":[],"mappings":"AAEA,sBAGM,oBAAqB,CAErB,UAAW,CACX,WAAY,CACZ,gBAAiB,CACjB,iBAAkB,CAElB,qBAAsB,CAV5B,4CAcM,cAAe","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .stadium-preview {\n    .country-logo {\n      display: inline-block;\n\n      width: 20px;\n      height: 13px;\n      margin-right: 8px;\n      border-radius: 2px;\n\n      background-size: cover;\n    }\n\n    .country-logo, .label {\n      cursor: pointer;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"stadium-preview": "_2o4bz8uq",
	"stadiumPreview": "_2o4bz8uq",
	"country-logo": "_1nxaymYZ",
	"countryLogo": "_1nxaymYZ",
	"label": "_2EgQUJLk"
};