exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2km-FlUP{width:100%}._2km-FlUP ._12U48Qfq{margin-top:8px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Input/views/Multiselect/styles.scss"],"names":[],"mappings":"AAEA,WAEI,UAAW,CAFf,sBAKM,cAAe","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .multiple-input-container {\n    width: 100%;\n\n    .multi-select {\n      margin-top: 8px;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"multiple-input-container": "_2km-FlUP",
	"multipleInputContainer": "_2km-FlUP",
	"multi-select": "_12U48Qfq",
	"multiSelect": "_12U48Qfq"
};