import { StadiumsOrdering } from 'packages/rest/api/SeasonStadiums/types';

import { stadiumActions } from '../actions';
import * as seasonStadiumsSelectors from '../selectors';
import { loadStadiums } from './loadStadiums';

export const loadStadiumPage = (seasonId: number, ordering?: StadiumsOrdering) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(stadiumActions.loadStadiumsPageStart());

    const offset = seasonStadiumsSelectors.getOffset(getState());
    const limit = seasonStadiumsSelectors.getLimit(getState());

    const stadiums = await dispatch(loadStadiums({ seasonId, offset, limit, ordering }));

    dispatch(stadiumActions.loadStadiumsPageSuccess(stadiums));
  } catch (e) {
    dispatch(stadiumActions.loadStadiumsPageFailure(e));
  }
};
