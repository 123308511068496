import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadInitialTeamsStart = createAction(`${NAMESPACE}LOAD_INITIAL_TEAMS_START`);
export const loadInitialTeamsSuccess = createAction(`${NAMESPACE}LOAD_INITIAL_TEAMS_SUCCESS`);
export const loadInitialTeamsFailure = createAction(`${NAMESPACE}LOAD_INITIAL_TEAMS_FAILURE`);

export const loadTeamsPageStart = createAction(`${NAMESPACE}LOAD_TEAMS_PAGE_START`);
export const loadTeamsPageSuccess = createAction(`${NAMESPACE}LOAD_TEAMS_PAGE_SUCCESS`);
export const loadTeamsPageFailure = createAction(`${NAMESPACE}LOAD_TEAMS_PAGE_FAILURE`);

export const updatePaginationSuccess = createAction(`${NAMESPACE}UPDATE_PAGINATION_SUCCESS`);

export const cleanTeams = createAction(`${NAMESPACE}CLEAN_TEAMS`);
