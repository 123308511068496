import Dexie from 'dexie';

import { Tables } from '../../schema';

const onCreate = (dexieInstance: Dexie) => {
  const favoriteTeamsModel = dexieInstance.table(Tables.FAVORITE_TEAMS);

  favoriteTeamsModel.hook('creating', async (primaryKey, record) => {
    const lastTeamRecord = await favoriteTeamsModel.orderBy('orderNumber').last();

    record.orderNumber = lastTeamRecord?.orderNumber || 0;
  });
};

export default [onCreate];
