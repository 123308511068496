import { DateTime } from 'luxon';

import DateService from 'packages/date-az';

const getNavigationLabel = (relativeDate: Date, isPrevious: boolean): string => {
  const relativeDateTime: DateTime = DateService.getDateTimeFromDate(relativeDate);

  return isPrevious
    ? DateService.getPrevMonthName(relativeDateTime)
    : DateService.getNextMonthName(relativeDateTime);
};

export default getNavigationLabel;
