import { Round, Team, Event } from 'types';

export interface SeasonEventGroup {
  id: string;
  events: Event[];
  round: Round;
  date: string;
}

export interface SeasonEventsStatus {
  isInitialLoading: boolean;
  isMoreLoading: boolean;
  limit: number;
  offset: number;
  count: number;
}

export interface LoadEventsOptions {
  seasonId: number;
  activeFilters: Filters;
  offset?: number;
  limit?: number;
}

export enum Filter {
  Dates = 'dates',
  Rounds = 'rounds',
  Teams = 'teams',
}

export type FilterValue = [string, string] | Round[] | Team[];

export interface Filters {
  [Filter.Dates]?: [string, string];
  [Filter.Rounds]: Round[];
  [Filter.Teams]: Team[];
}

export interface SeasonEventsFilter {
  filters: Filters;
  activeFilters: Filters;
}

export interface SeasonFilterStatus {
  isLoading: boolean;
}
