import React from 'react';
import PropTypes from 'prop-types';

import PopoverContent from './views/PopoverContent';
import styles from './styles.scss';

class Popover extends React.PureComponent {
  constructor(props) {
    super(props);

    const { isVisible } = this.props;

    this.state = {
      isVisible: !!isVisible,
    };

    this.onShow = this.onShow.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onToggle = this.onToggle.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onClickOutside = this.onClickOutside.bind(this);
  }

  onShow(e) {
    e.stopPropagation();
    this.setState({ isVisible: true });
  }

  onClose() {
    this.setState({ isVisible: false });
  }

  onToggle(e) {
    const { isVisible } = this.props;

    e.stopPropagation();
    this.setState({ isVisible: !isVisible });
  }

  onMouseLeave() {
    const { triggerType } = this.props;

    if (triggerType === 'hover') {
      this.onClose();
    }
  }

  onClickOutside() {
    const { onClickOutside } = this.props;

    this.onClose();
    onClickOutside && onClickOutside();
  }

  getTriggerNodeProps(trigger) {
    if (trigger === 'click') {
      return { onClick: this.onToggle };
    }
    if (trigger === 'hover') {
      return { onMouseOver: this.onShow };
    }
  }

  render() {
    const { className, children, closeOnClick, triggerNode, triggerType } = this.props;
    const { isVisible } = this.state;

    return (
      <div className={styles.popover} onMouseLeave={this.onMouseLeave}>
        {triggerNode && React.cloneElement(triggerNode, this.getTriggerNodeProps(triggerType))}

        {isVisible && (
          <PopoverContent
            closeOnClick={closeOnClick}
            onClickOutside={this.onClickOutside}
            className={className}
            onClose={this.onClose}
          >
            {children}
          </PopoverContent>
        )}
      </div>
    );
  }
}

Popover.defaultProps = {
  isVisible: '',
  triggerType: 'hover',
  className: '',
  closeOnClick: null,
  onClickOutside: null,
};

Popover.propTypes = {
  isVisible: PropTypes.bool,
  triggerType: PropTypes.string,
  triggerNode: PropTypes.node.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClickOutside: PropTypes.func,
  closeOnClick: PropTypes.func,
};

export default Popover;
