import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Locale } from '../types';

const defaultState = {
  code: '',
};

const reducerMap: ReducerMap<Locale, Locale> = {
  [`${actions.setLocale}`]: (state, { payload }) => {
    const { code } = payload;

    return { ...state, code };
  },
};

export default handleActions(reducerMap, defaultState);
