import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { categoryListThunks, categoryListSelectors } from 'packages/categories';
import { tournamentsSelectors } from 'packages/tournaments';

const mapStateToProps = (state) => {
  return {
    categories: categoryListSelectors.getCategories(state),
    selectedCategory: categoryListSelectors.getSelectedCategory(state) || [],
    isCategoriesLoading: categoryListSelectors.getIsCategoriesLoading(state),
    isSelectedCategoryLoading: categoryListSelectors.getIsSelectedCategoryLoading(state),
    isPopularTournamentsLoading: tournamentsSelectors.getIsPopularTournamentsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categoryListActions: bindActionCreators(categoryListThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
