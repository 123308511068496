import React, { useContext } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Parameter, PlayersParameter, TeamsParameter } from 'packages/season-top-board/types';
import { RefereeCard } from 'packages/shared/components';
import { RefereeCardType } from 'packages/shared/components/RefereeCard/types';

import { SortingValueStateContext } from '../../constants';
import styles from './styles.scss';

type Props = {
  parameter: Parameter;
};

const ParameterHeader = ({ parameter }: Props) => {
  const [sortingValue, setSortingValue] = useContext(SortingValueStateContext);

  const onClick = () => {
    if (sortingValue) {
      setSortingValue(parameter);
    }
  };

  return (
    <div
      className={classnames(styles.parameterHeader, {
        [styles.clickable]: !!sortingValue,
        [styles.active]: parameter === sortingValue,
      })}
      onClick={onClick}
    >
      {(() => {
        switch (parameter) {
          case PlayersParameter.YellowCards:
          case TeamsParameter.YellowCards:
            return <RefereeCard type={RefereeCardType.Yellow} className={styles.refereeCard} />;
          case PlayersParameter.YellowRedCards:
          case TeamsParameter.YellowRedCards:
            return <RefereeCard type={RefereeCardType.YellowRed} className={styles.refereeCard} />;
          case PlayersParameter.RedCards:
          case TeamsParameter.RedCards:
            return <RefereeCard type={RefereeCardType.Red} className={styles.refereeCard} />;
          default:
            return i18n.value(`common.tableParameter.short.${parameter}`);
        }
      })()}
    </div>
  );
};

export default ParameterHeader;
