import React, { useCallback } from 'react';
import classnames from 'classnames';

import { Event } from 'types';
import { EventSeries as EventSeriesType } from 'packages/event-details/types';

import { AggregationScore, FirstLeg } from './components';
import styles from './styles.scss';

type Props = {
  activeEvent: Event;
  eventSeries: EventSeriesType;
  onFirstLegClick: (event?: Event) => void;
  theme?: 'light' | 'dark';
  className?: string;
};

const EventSeries = ({
  activeEvent,
  onFirstLegClick,
  eventSeries,
  theme = 'light',
  className,
}: Props) => {
  const { teams, isLive } = activeEvent;

  const { aggregationScore, firstLeg } = eventSeries;
  const shouldEventSeriesBeDisplayed = !!aggregationScore || !!firstLeg;

  const handleFirstLegClick = useCallback(() => {
    onFirstLegClick(firstLeg);
  }, [onFirstLegClick, firstLeg]);

  if (!shouldEventSeriesBeDisplayed) {
    return <></>;
  }

  return (
    <div className={classnames(styles.eventSeriesContainer, className)}>
      {!!aggregationScore && (
        <AggregationScore
          aggregationScore={aggregationScore}
          isLive={isLive}
          teams={teams}
          theme={theme}
        />
      )}
      {!!firstLeg && (
        <FirstLeg
          handleClick={handleFirstLegClick}
          firstLegEvent={firstLeg}
          teams={teams}
          theme={theme}
        />
      )}
    </div>
  );
};

export default EventSeries;
