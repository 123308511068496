import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { filtersActions, commonActions } from '../../actions';
import { Filters } from '../../types';

const defaultState: Filters = {
  countries: [],
};

const reducerMap: ReducerMap<Filters, Filters> = {
  [`${filtersActions.loadFiltersSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(
    filtersActions.loadFiltersStart,
    filtersActions.loadFiltersFailure,
    filtersActions.cleanFilters,
    commonActions.cleanTeamStatistics,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
