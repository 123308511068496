import React from 'react';
import classnames from 'classnames';

import { Jersey as JerseyType } from 'packages/shared/components/Lineup/types';
import { Jersey } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  jersey: JerseyType;
  label: string;
  className?: string;
};

const JerseyCell = ({ jersey, label, className }: Props) => {
  return (
    <div className={classnames(styles.jerseyContainer, className)}>
      <Jersey jersey={jersey} options={{ useJerseyOutline: true }} className={styles.jersey} />
      <span className={styles.label}>{label}</span>
    </div>
  );
};

export default JerseyCell;
