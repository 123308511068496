import React, { memo, ReactElement } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { Label, PlainCheckboxInput } from '../../components';
import styles from './styles.scss';

type Props = {
  name: string;
  onToggle: () => void;
  isChecked?: boolean;
  isDisabled?: boolean;
  label: string | ReactElement;
  className?: string;
};

const Checkbox = ({ isChecked, onToggle, label, name, isDisabled, className }: Props) => {
  return (
    <div className={classnames(styles.checkboxInputContainer, className)}>
      <PlainCheckboxInput
        name={name}
        onChange={onToggle}
        isChecked={isChecked}
        isDisabled={isDisabled}
        className={styles.checkbox}
      />
      {!isEmpty(label) && <Label name={name} label={label} className={styles.label} />}
    </div>
  );
};

export default memo(Checkbox);
