import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.scss';

class PopoverContent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.handleClickOutSide = this.handleClickOutSide.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
  }

  componentDidMount() {
    this.node && this.node.focus();
    window.document.addEventListener('click', this.handleClickOutSide);
  }

  componentWillUnmount() {
    this.node && this.node.blur();
    window.document.removeEventListener('click', this.handleClickOutSide);
  }

  onKeyDown(e) {
    const { onClose } = this.props;

    if (e.key === 'Escape') {
      e.stopPropagation();
      onClose();
    }
  }

  handleClickOutSide(e) {
    const { onClose, closeOnClick, onClickOutside } = this.props;

    if (!this.node) {
      return;
    }

    if (closeOnClick) {
      onClose();
    } else {
      if (!this.node.contains(e.target)) {
        onClickOutside();
      }
    }
  }

  render() {
    const { className, children } = this.props;
    const containerClasses = classnames(className, styles.popoverContent);

    return (
      <div
        ref={(node) => (this.node = node)}
        tabIndex="-1"
        onKeyDown={this.onKeyDown}
        className={containerClasses}
      >
        <div>{children}</div>
      </div>
    );
  }
}

PopoverContent.defaultProps = {
  className: '',
  closeOnClick: null,
  onClose: null,
  onClickOutside: null,
};

PopoverContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  closeOnClick: PropTypes.func,
  onClose: PropTypes.func,
  onClickOutside: PropTypes.func,
};

export default PopoverContent;
