import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { teamsActions, commonActions } from '../../actions';
import { Interfaces } from '../../types';

const defaultState: Interfaces.Teams.Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Interfaces.Teams.Status, void> = {
  [`${teamsActions.loadTeamsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(teamsActions.loadTeamsSuccess, teamsActions.loadTeamsFailure)}`]: () => {
    return { isLoading: false };
  },
  [`${commonActions.cleanSummarySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
