import React, { useCallback, memo } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { Tournament } from 'types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import FavoriteTournamentsView from './views';
import { SortableItem } from '../../types';
import connect from './connect';

type Props = {
  tournaments: Tournament[];
  favoritesActions: ActionCreatorsMapObject;
  isPopularTournamentsLoading: boolean;
  isCategoriesLoading: boolean;
};

const extendedFavoriteTournaments = (
  FavoriteTournaments: typeof FavoriteTournamentsView[keyof typeof FavoriteTournamentsView],
) =>
  memo(
    ({
      tournaments,
      favoritesActions,
      isPopularTournamentsLoading,
      isCategoriesLoading,
    }: Props) => {
      const { appNavigate } = useNavigation();

      const handleDelete = useCallback((tournament: SortableItem) => {
        favoritesActions.toggleLeague(tournament);
      }, []);

      const handleDragEnd = useCallback((tournaments: SortableItem[]) => {
        favoritesActions.resetLeagues(tournaments);
      }, []);

      const handleClick = useCallback((tournament: SortableItem) => {
        appNavigate(DesktopPage.FootballSeason, {
          seasonId: tournament.currentSeasonId,
          uniqueTournamentId: tournament.uniqueTournamentId,
        });
      }, []);

      return (
        <FavoriteTournaments
          isPopularTournamentsLoading={isPopularTournamentsLoading}
          isCategoriesLoading={isCategoriesLoading}
          tournaments={tournaments}
          onDelete={handleDelete}
          onClick={handleClick}
          onDragEnd={handleDragEnd}
        />
      );
    },
  );

export default {
  Desktop: connect(extendedFavoriteTournaments(FavoriteTournamentsView.Desktop)),
  DesktopNarrow: connect(extendedFavoriteTournaments(FavoriteTournamentsView.DesktopNarrow)),
  Tablet: connect(extendedFavoriteTournaments(FavoriteTournamentsView.Tablet)),
  TabletNarrow: connect(extendedFavoriteTournaments(FavoriteTournamentsView.TabletNarrow)),
  Mobile: connect(extendedFavoriteTournaments(FavoriteTournamentsView.Mobile)),
};
