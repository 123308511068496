import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { SeasonDetails } from '../types';

const defaultState = null;

const reducerMap: ReducerMap<SeasonDetails | null, SeasonDetails | null> = {
  [`${actions.loadSeasonDetailsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${actions.cleanSeasonDetails}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
