import {
  ENDED_SEASON_INITIAL_EVENTS_AMOUNT,
  ACTIVE_SEASON_INITIAL_EVENTS_AMOUNT,
} from 'packages/season-events/constants';
import { Event } from 'types';

export const getLastEventsFromList = (events: Event[], isSeasonEnded: boolean): Event[] => {
  if (isSeasonEnded) {
    return events.slice(-ENDED_SEASON_INITIAL_EVENTS_AMOUNT);
  }

  return events.slice(-ACTIVE_SEASON_INITIAL_EVENTS_AMOUNT);
};
