import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Team = () => {
  return (
    <div className={styles.container}>
      <Skeleton width={20} height={20} variant="circular" />
      <Skeleton marginLeft={8} width={100} height={16} variant="rectangular" />
    </div>
  );
};

export default memo(Team);
