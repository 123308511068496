import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import * as Common from 'pages/common';
import { Football } from 'pages/desktop';

import { DESKTOP_PATH } from '../../constants';
import { DesktopPage } from '../../types';

const { ROOT, SEASON_CHILDREN, PLAYER_CHILDREN, STADIUM_CHILDREN, TEAM_CHILDREN } = DESKTOP_PATH;

const FOOTBALL_SEASON_ROUTES: RouteObject[] = [
  {
    path: SEASON_CHILDREN[DesktopPage.FootballSeasonHome],
    element: <Navigate to={SEASON_CHILDREN[DesktopPage.FootballSeasonSummary]} replace />,
  },
  { path: SEASON_CHILDREN[DesktopPage.FootballSeasonSummary], element: <Football.SeasonSummary /> },
  { path: SEASON_CHILDREN[DesktopPage.FootballSeasonGames], element: <Football.SeasonGames /> },
  { path: SEASON_CHILDREN[DesktopPage.FootballSeasonHistory], element: <Football.SeasonHistory /> },
  {
    path: SEASON_CHILDREN[DesktopPage.FootballSeasonStadiums],
    element: <Football.SeasonStadiums />,
  },
  {
    path: SEASON_CHILDREN[DesktopPage.FootballSeasonTeamStatistics],
    element: <Football.SeasonTeamStatistics />,
  },
  {
    path: SEASON_CHILDREN[DesktopPage.FootballSeasonPlayerStatistics],
    element: <Football.SeasonPlayerStatistics />,
  },
  {
    path: SEASON_CHILDREN[DesktopPage.FootballSeasonStandings],
    element: <Football.SeasonStandings />,
  },
  { path: SEASON_CHILDREN[DesktopPage.FootballSeasonComingSoon], element: <Common.ComingSoon /> },
];

const FOOTBALL_TEAM_ROUTES: RouteObject[] = [
  {
    path: TEAM_CHILDREN[DesktopPage.FootballTeamHome],
    element: <Navigate to={TEAM_CHILDREN[DesktopPage.FootballTeamSummary]} replace />,
  },
  { path: TEAM_CHILDREN[DesktopPage.FootballTeamSummary], element: <Football.TeamSummary /> },
  {
    path: TEAM_CHILDREN[DesktopPage.FootballTeamSummaryDescription],
    element: <Football.TeamSummary />,
  },
  { path: TEAM_CHILDREN[DesktopPage.FootballTeamStandings], element: <Football.TeamStandings /> },
  { path: TEAM_CHILDREN[DesktopPage.FootballTeamGames], element: <Football.TeamGames /> },
  { path: TEAM_CHILDREN[DesktopPage.FootballTeamSquad], element: <Football.TeamSquad /> },
  {
    path: TEAM_CHILDREN[DesktopPage.FootballTeamPlayerStatistics],
    element: <Football.TeamPlayers />,
  },
];

const FOOTBALL_STADIUM_ROUTES: RouteObject[] = [
  {
    path: STADIUM_CHILDREN[DesktopPage.FootballStadiumHome],
    element: <Football.StadiumHome />,
  },
  {
    path: STADIUM_CHILDREN[DesktopPage.FootballStadiumSummary],
    element: <Football.StadiumSummary />,
  },
];

const FOOTBALL_PLAYER_ROUTES: RouteObject[] = [
  {
    path: PLAYER_CHILDREN[DesktopPage.FootballPlayerHome],
    element: <Navigate to={PLAYER_CHILDREN[DesktopPage.FootballPlayerSummary]} replace />,
  },
  { path: PLAYER_CHILDREN[DesktopPage.FootballPlayerSummary], element: <Football.PlayerSummary /> },
  { path: PLAYER_CHILDREN[DesktopPage.FootballPlayerGames], element: <Football.PlayerGames /> },
];

const ROOT_ROUTES: RouteObject[] = [
  {
    path: ROOT[DesktopPage.Home],
    children: [
      {
        path: ROOT[DesktopPage.Home],
        element: <Navigate to={ROOT[DesktopPage.Football]} replace />,
      },
      { path: ROOT[DesktopPage.Football], element: <Football.Home /> },
      { path: ROOT[DesktopPage.FootballCategory], element: <Football.Category /> },
      {
        path: ROOT[DesktopPage.FootballTeam],
        element: <Football.Team />,
        children: FOOTBALL_TEAM_ROUTES,
      },
      {
        path: ROOT[DesktopPage.FootballPlayer],
        element: <Football.Player />,
        children: FOOTBALL_PLAYER_ROUTES,
      },
      {
        path: ROOT[DesktopPage.FootballSeason],
        element: <Football.Season />,
        children: FOOTBALL_SEASON_ROUTES,
      },
      {
        path: ROOT[DesktopPage.FootballStadium],
        element: <Football.Stadium />,
        children: FOOTBALL_STADIUM_ROUTES,
      },
      {
        path: ROOT[DesktopPage.FootballMatch],
        element: <Football.Match />,
      },
    ],
  },
  { path: ROOT[DesktopPage.NotFound], element: <Common.NotFound /> },
];

const Router = () => {
  return useRoutes(ROOT_ROUTES);
};

export default Router;
