import { DividedTeams } from 'packages/season-history/types';
import { Team } from 'types';

const divideTeams = (teams: Team[]): DividedTeams => {
  const teamsCopy = [...teams];
  const visibleTeams = teamsCopy.splice(0, 4);

  return { visibleTeams, hiddenTeamsCount: teamsCopy.length };
};

export default divideTeams;
