import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { actualGamesActions, commonActions } from '../../actions';
import { Interfaces } from '../../types';

const defaultState: Interfaces.ActualGames.Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Interfaces.ActualGames.Status, void> = {
  [`${actualGamesActions.loadActualGamesStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(
    actualGamesActions.loadActualGamesFailure,
    actualGamesActions.loadActualGamesSuccess,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${commonActions.cleanSummarySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
