import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { detailsActions, commonActions } from '../../../../actions';
import { Interfaces } from '../../../../types';

const { topPlayers } = detailsActions;
const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Interfaces.Details.TopPlayers.Status, void> = {
  [`${topPlayers.loadTopPlayersStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(topPlayers.loadTopPlayersSuccess, topPlayers.loadTopPlayersFailure)}`]: () => {
    return { isLoading: false };
  },
  [`${commonActions.cleanSummarySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
