exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1CUbkBXZ{display:flex}._1CUbkBXZ ._3YS0Akg2{display:flex;flex-direction:column;justify-content:space-between}._1CUbkBXZ ._3YS0Akg2 ._5dPihUbZ{display:flex}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/season-top-board-ui/components/LoadingSkeleton/components/PlayerCellSkeleton/styles.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CAFjB,sBAKM,YAAa,CACb,qBAAsB,CACtB,6BAA8B,CAPpC,iCAUQ,YAAa","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .container {\n    display: flex;\n\n    .details {\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n\n      .additional-info {\n        display: flex;\n      }\n    }\n  }\n}"]}]);

// Exports
exports.locals = {
	"container": "_1CUbkBXZ",
	"details": "_3YS0Akg2",
	"additional-info": "_5dPihUbZ",
	"additionalInfo": "_5dPihUbZ"
};