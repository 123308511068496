import { Ordering as RequestOrderingParam } from 'packages/rest/api/SeasonPlayerStatistics/types';

import { FILTER_ORDERING_BY_STAT_TYPE } from '../constants';
import { Enums, Interfaces } from '../types';

const serializeOrdering = (
  ordering: Interfaces.CustomFilter.Ordering,
  statsType: Enums.StatType,
): RequestOrderingParam => {
  return Object.entries(ordering).reduce(
    (acc, [orderingKey, orderingValue]) => ({
      ...acc,
      [FILTER_ORDERING_BY_STAT_TYPE[statsType][orderingKey]]: orderingValue,
    }),
    {},
  );
};

export default serializeOrdering;
