import React from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Icon, Tooltip } from 'packages/shared/components';
import { EventStatus } from 'types';
import { PopoverInvokeTrigger } from 'packages/shared/components/FlexiblePopover/types';

import styles from './styles.scss';
import { ICON_ID_MAP } from './constants';

type Props = {
  status: EventStatus;
  isShort?: boolean;
  isPopoverDisabled?: boolean;
  popoverInvokeTrigger?: PopoverInvokeTrigger;
  className?: string;
};

const SpecialStatusLabel = ({
  status,
  isShort = false,
  isPopoverDisabled = false,
  popoverInvokeTrigger,
  className,
}: Props) => {
  const iconId = ICON_ID_MAP[status];
  const statusText = i18n.value(`eventStatusLabel.${isShort ? 'statusShort' : 'status'}.${status}`);

  return (
    <Tooltip
      invokeTrigger={popoverInvokeTrigger}
      isPopoverDisabled={isPopoverDisabled}
      value={i18n.value(`eventStatusLabel.status.tooltip.${status}`)}
    >
      <div className={classnames(styles.specialStatusLabel, className)}>
        <Icon id={iconId} className={styles.icon} />
        <div className={styles.status}>{statusText}</div>
      </div>
    </Tooltip>
  );
};

export default SpecialStatusLabel;
