import React, { cloneElement, memo, ReactElement, RefObject } from 'react';

type Props = {
  popoverElement: JSX.Element;
  popoverRef: RefObject<HTMLElement> | null;
  closePopover: () => void;
  openPopover: () => void;
  generatePopoverComponent: Function;
  children?: ReactElement;
};

const HoverablePopover = ({
  generatePopoverComponent,
  openPopover,
  closePopover,
  popoverElement,
  children,
  popoverRef,
}: Props) => {
  const onMouseLeave = ({ clientX, clientY }) => {
    if (popoverRef?.current) {
      const popoverEl = popoverRef.current;

      const topmostElementUnderCursor = document.elementFromPoint(clientX, clientY);
      const isCursorOverPopup = popoverEl.contains(topmostElementUnderCursor);

      if (isCursorOverPopup) {
        popoverEl.addEventListener('mouseleave', closePopover, { once: true });
      } else {
        closePopover();
      }
    }
  };

  return (
    <>
      {children &&
        cloneElement(children, {
          onMouseEnter: openPopover,
          onTouchStart: openPopover,
          onMouseLeave,
        })}
      {generatePopoverComponent(popoverElement)}
    </>
  );
};

export default memo(HoverablePopover);
