import React, { useMemo, memo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { FlexibleTable } from 'packages/shared/components';

import { getSkeletonTableSchema } from './services';
import { TournamentRulesBlockSkeleton } from '../../../../components';
import styles from './styles.scss';

const ELEMENT_COUNT = 20;

const Skeleton = () => {
  const elements = ArrayUtils.getArrayFromNumber(ELEMENT_COUNT);
  const tableSchema = useMemo(getSkeletonTableSchema, []);

  return (
    <>
      <FlexibleTable
        isHeaderSticky
        data={elements}
        columns={tableSchema}
        columnClassName={styles.tableColumn}
        className={styles.narrowStatisticsTable}
      />
      <TournamentRulesBlockSkeleton />
    </>
  );
};

export default memo(Skeleton);
