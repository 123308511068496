import DateService from 'packages/date-az';

const calcPlayingPeriod = (date: string): { years: number; months: number; days: number } => {
  const periodInTeam = DateService.getDiffFromCurrentDate(DateService.parse(date), [
    'years',
    'months',
    'days',
  ]);

  return {
    years: Math.floor(periodInTeam.years),
    months: Math.floor(periodInTeam.months),
    days: Math.floor(periodInTeam.days),
  };
};

export default calcPlayingPeriod;
