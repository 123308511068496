import React, { memo, useMemo } from 'react';
import isEmpty from 'is-empty-typed/lib';

import { Tabs, TeamLabel, NoDataLabel } from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { LineupRowsExtendedCompetitor } from 'packages/event-lineups/types';

import { Lineups, TeamStructure } from './components';

type Props = {
  competitors: [LineupRowsExtendedCompetitor, LineupRowsExtendedCompetitor];
  onPlayerClick?: (playerId: number) => void;
  isFormationHidden?: boolean;
};

const TeamLineups = ({ onPlayerClick, isFormationHidden, competitors }: Props) => {
  const [bottomLineup, topLineup] = competitors;

  const competitorTabs = useMemo(
    () =>
      competitors.map(({ teamName, teamLogo, lineup }) => ({
        id: teamName,
        contentComponent: TeamStructure,
        contentComponentProps: { lineup, onPlayerClick },
        tabComponent: TeamLabel,
        tabComponentProps: {
          team: { name: teamName, logo: teamLogo },
          isLogoFirst: true,
        },
        hasMaxWidth: true,
      })),
    [competitors, onPlayerClick],
  );

  if (isEmpty(competitors)) {
    return <NoDataLabel />;
  }

  return (
    <>
      {!isEmpty(bottomLineup.lineupRows) && !isEmpty(topLineup.lineupRows) && (
        <Lineups competitors={competitors} isFormationHidden={isFormationHidden} />
      )}
      <Tabs theme={NavigationTheme.Button} tabs={competitorTabs} isNavigationWidthLimited />
    </>
  );
};

export default memo(TeamLineups);
