import {
  Filters,
  GeneralFilterProps,
  Nationality,
  SearchSelectOption,
} from 'packages/season-player-statistics/types';

import { Team } from 'types';

import getFootSelectItems from './getFootSelectItems';
import { getStatsTabs } from '../../../services';

const formatFilters = (filters: Filters): GeneralFilterProps => {
  const { teams, nationalities } = filters;

  return {
    teamSelectItems: formatTeams(teams),
    nationalitySelectItems: formatNationalities(nationalities),
    footSelectItems: getFootSelectItems(),
    statTabs: getStatsTabs(),
  };
};

const formatTeams = (teams: Team[]): SearchSelectOption<Team>[] => {
  return teams.map((team) => {
    const { id, name } = team;

    return {
      id,
      label: name,
      value: name,
      componentProps: {
        item: team,
        isLogoFirst: true,
      },
    };
  });
};

const formatNationalities = (nationalities: Nationality[]): SearchSelectOption<Nationality>[] => {
  return nationalities.map((nationality) => {
    const { id, name } = nationality;

    return {
      id,
      label: name,
      value: name,
      componentProps: {
        item: nationality,
        isLogoFirst: true,
      },
    };
  });
};

export default formatFilters;
