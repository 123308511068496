import i18n from 'i18n-smart';

import { TopBoardTab } from 'packages/season-top-board/types';
import { TabView } from 'packages/shared/components/Tabs/types';

import { TopVenues, TopTeams, TopPlayers } from '../components';

export const getMainTabs = (): TabView[] => {
  return [
    {
      id: TopBoardTab.Players,
      name: i18n.value(`season.seasonTopBoard.tabs.${TopBoardTab.Players}`),
      contentComponent: TopPlayers,
    },
    {
      id: TopBoardTab.Teams,
      name: i18n.value(`season.seasonTopBoard.tabs.${TopBoardTab.Teams}`),
      contentComponent: TopTeams,
    },
    {
      id: TopBoardTab.Venues,
      name: i18n.value(`season.seasonTopBoard.tabs.${TopBoardTab.Venues}`),
      contentComponent: TopVenues,
    },
  ];
};
