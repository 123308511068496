import { stadiumActions } from '../actions';

export interface Pagination {
  offset: number;
  limit: number;
}

export const updatePagination = (pagination: Pagination) => (dispatch) => {
  dispatch(stadiumActions.updatePaginationSuccess(pagination));
};
