import React, { ReactElement } from 'react';
import classnames from 'classnames';

import { OrderType } from 'types';

import styles from './styles.scss';

type Props<ParameterType> = {
  activeSortingType?: OrderType;
  parameter: ParameterType;
  onParameterClick: (sortingValueType: ParameterType) => void;
  children: ReactElement;
};

const SortableTableHeader = <SortingValueType extends unknown>({
  activeSortingType,
  onParameterClick,
  parameter,
  children,
}: Props<SortingValueType>) => {
  const handleClick = () => {
    onParameterClick(parameter);
  };

  return (
    <div
      className={classnames(styles.parameterHeader, {
        [styles.ascending]: activeSortingType === OrderType.Ascending,
        [styles.descending]: activeSortingType === OrderType.Descending,
      })}
      onClick={handleClick}
    >
      {children}
    </div>
  );
};

export default SortableTableHeader;
