import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { INITIAL_TEAMS_LIMIT } from '../../constants';
import { commonActions, teamsActions, customFilterStateActions } from '../../actions';
import { TeamsStatus } from '../../types';

const defaultState: TeamsStatus = {
  isInitialPageLoading: true,
  isNewPageLoading: false,
  hasNext: false,
  limit: INITIAL_TEAMS_LIMIT,
  offset: 0,
  count: 0,
};

const reducerMap: ReducerMap<TeamsStatus, any> = {
  [`${teamsActions.loadInitialTeamsStart}`]: (state) => ({
    ...state,
    isInitialPageLoading: true,
  }),
  [`${combineActions(
    teamsActions.loadInitialTeamsFailure,
    teamsActions.loadInitialTeamsSuccess,
  )}`]: (state) => ({
    ...state,
    isInitialPageLoading: false,
  }),
  [`${teamsActions.loadTeamsPageStart}`]: (state) => ({
    ...state,
    isNewPageLoading: true,
  }),
  [`${combineActions(teamsActions.loadTeamsPageFailure, teamsActions.loadTeamsPageSuccess)}`]: (
    state,
  ) => ({
    ...state,
    isNewPageLoading: false,
  }),
  [`${combineActions(teamsActions.loadInitialTeamsSuccess, teamsActions.loadTeamsPageSuccess)}`]: (
    state,
    { payload },
  ) => {
    const { hasNext, count } = payload;

    return {
      ...state,
      hasNext,
      count,
    };
  },
  [`${teamsActions.updatePaginationSuccess}`]: (state, { payload }) => {
    return {
      ...state,
      ...payload,
    };
  },
  [`${customFilterStateActions.customFilterUpdate}`]: (state) => {
    return {
      ...state,
      offset: defaultState.offset,
      limit: defaultState.limit,
      count: defaultState.count,
    };
  },
  [`${commonActions.cleanTeamStatistics}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
