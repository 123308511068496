import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { detailsActions, commonActions } from '../../../../actions';
import { Interfaces } from '../../../../types';

const { standings } = detailsActions;
const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Interfaces.Details.Standings.Status, void> = {
  [`${standings.loadStandingsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(standings.loadStandingsSuccess, standings.loadStandingsFailure)}`]: () => {
    return { isLoading: false };
  },
  [`${commonActions.cleanSummarySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
