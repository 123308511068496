import React, { memo, RefObject, useLayoutEffect, useRef, useState } from 'react';

import { FlexiblePopover } from 'packages/shared/components';
import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';
import { isTextOverlaps } from 'packages/utils/Document.utils';

import PopoverContent from '../PopoverContent';
import styles from './styles.scss';

type Props = {
  name: string;
};

const NameCell = ({ name }: Props) => {
  const labelRef: RefObject<HTMLDivElement> = useRef(null);
  const [isPopoverDisabled, setIsPopoverDisabled] = useState<boolean>(true);

  useLayoutEffect(() => {
    if (labelRef.current && isTextOverlaps(labelRef.current)) {
      setIsPopoverDisabled(false);
    }
  }, []);

  return (
    <div className={styles.nameCell}>
      <FlexiblePopover
        closeOnClickOutside
        popoverComponent={PopoverContent}
        popoverProps={{ value: name }}
        justify={PopoverJustify.Center}
        invokeTrigger={PopoverInvokeTrigger.Hover}
        align={PopoverAlign.Bottom}
        isDisabled={isPopoverDisabled}
      >
        <div className={styles.name}>
          <div ref={labelRef}>{name}</div>
        </div>
      </FlexiblePopover>
    </div>
  );
};

export default memo(NameCell);
