import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { stagesThunks, stagesSelectors, commonThunks } from 'packages/team-standings';
import { teamDetailsSelectors } from 'packages/team-details';
import { teamActiveTournamentSeasonSelectors } from 'packages/team-active-tournament-season';
import { settingsSelectors } from 'packages/settings';

const mapStateToProps = (state) => {
  return {
    activeTournamentSeasons: teamActiveTournamentSeasonSelectors.getData(state),
    tournaments: teamDetailsSelectors.getTournamentList(state),
    isStagesLoading: stagesSelectors.getIsLoading(state),
    stages: stagesSelectors.getStages(state),
    activeStageId: stagesSelectors.getActiveStageId(state),
    timeZone: settingsSelectors.getTimeZone(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    seasonStagesActions: bindActionCreators(stagesThunks, dispatch),
    seasonStandingsActions: bindActionCreators(commonThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
