import { playersActions } from '../../actions';
import { INITIAL_PLAYERS_LIMIT } from '../../constants';
import loadPlayers from './loadPlayers';

const loadInitialPlayers = (seasonId: number) => async (dispatch) => {
  try {
    dispatch(playersActions.loadInitialPlayersStart());

    const playersData = await dispatch(
      loadPlayers({ seasonId, limit: INITIAL_PLAYERS_LIMIT, offset: 0 }),
    );

    dispatch(playersActions.loadInitialPlayersSuccess(playersData));
  } catch (e) {
    dispatch(playersActions.loadInitialPlayersFailure(e));
  }
};

export default loadInitialPlayers;
