import DateService from 'packages/date-az';
import { Season } from 'types';

export const getIsSeasonEnded = (season: Season) => {
  const { endDate } = season;

  return (
    DateService.parse(endDate)
      .diffNow()
      .valueOf() < 0
  );
};
