import React, { memo } from 'react';

import { Separator, Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  isCompact?: boolean;
};

const HeaderSkeleton = ({ isCompact = false }: Props) => {
  return (
    <>
      <div className={styles.wrapper}>
        <Skeleton marginRight={12} height={14} width={isCompact ? 90 : 82} variant="rectangular" />
        <Skeleton marginRight={12} width={160} height={32} variant="rectangular" />

        {!isCompact ? (
          <>
            <Skeleton marginRight={12} width={160} height={32} variant="rectangular" />
            <Skeleton marginRight={12} width={90} height={32} variant="rectangular" />
            <Skeleton marginLeft="auto" width={365} height={32} variant="rectangular" />
          </>
        ) : (
          <Skeleton
            marginLeft="auto"
            minHeight={32}
            height={32}
            width={32}
            minWidth={32}
            variant="rectangular"
          />
        )}
      </div>

      {isCompact && <Separator />}
    </>
  );
};

export default memo(HeaderSkeleton);
