import React, { RefObject, useRef } from 'react';
import classnames from 'classnames';

import { ComponentLike } from 'types';
import { excludeById, findById } from 'packages/utils/Array.utils';
import { FlexiblePopover } from 'packages/shared/components';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';

import { SelectItem, SelectSize } from '../../types';
import { SelectInput, SeasonListItem, PopoverItemList } from './components';
import styles from './styles.scss';

type Props<T extends SelectItem> = {
  selectedItems: T[];
  items: T[];
  onChange: (items: T[]) => void;
  onClear?: () => void;
  itemComponent?: ComponentLike;
  inputLabel: string;
  className?: string;
  itemsContainerClass?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  useScrollIntoPopoverView?: boolean;
  size?: SelectSize;
  scrollElementRef?: RefObject<HTMLElement>;
};

const ExtendedMultipleSelect = <T extends SelectItem>({
  selectedItems,
  items,
  onChange,
  isDisabled,
  size = SelectSize.Md,
  itemComponent = SeasonListItem,
  inputLabel,
  className,
  itemsContainerClass,
  isLoading = false,
  useScrollIntoPopoverView = false,
  onClear,
  scrollElementRef,
}: Props<T>) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handleItemClick = (item: T) => {
    const selectedItemsCopy = [...selectedItems];

    const updatedItems = findById(selectedItemsCopy, item.id)
      ? excludeById(selectedItemsCopy, item.id)
      : [...selectedItemsCopy, item];

    onChange(updatedItems);
  };

  const handleSelectAll = () => {
    if (items.length !== selectedItems.length) {
      onChange(items);
    }
  };

  const handleClear = () => {
    if (onClear) {
      onClear();
    } else {
      onChange([]);
    }
  };

  return (
    <div ref={containerRef} className={classnames(styles.mainWrapper, styles[size], className)}>
      <FlexiblePopover
        isDisabled={isLoading || isDisabled}
        popoverComponent={PopoverItemList}
        popoverProps={{
          containerRef,
          items,
          itemsContainerClass,
          onItemClick: handleItemClick,
          selectedItems,
          itemComponent,
          onClear: handleClear,
          onSelectAll: handleSelectAll,
        }}
        scrollElementRef={scrollElementRef}
        useScrollIntoPopoverView={useScrollIntoPopoverView}
        useChildrenWrapper
        justify={PopoverJustify.CenterStart}
        align={PopoverAlign.Bottom}
        closeOnClickOutside
      >
        <SelectInput
          isLoading={isLoading}
          selectedItemsCount={selectedItems.length}
          isDisabled={isDisabled}
          label={inputLabel}
          onClear={handleClear}
        />
      </FlexiblePopover>
    </div>
  );
};

export default ExtendedMultipleSelect;
