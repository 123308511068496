import { configure } from 'memo-storage';

import { keyValueDatabase } from 'packages/storage';

import { Tables } from '../storage/schema';

export default () => {
  const table = Tables.REST_CACHE_TABLE;

  configure({
    onSave: (key, value) => keyValueDatabase.set(key, value, table),
    onReceive: (key) => keyValueDatabase.get(key, table),
    onInvalidate: (key) => keyValueDatabase.remove(key, table),
    responseParser: (response) => response.data,
    logger: console.debug,
  });
};
