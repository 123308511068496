import React, { useCallback, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { Event } from 'types';
import { DesktopPage } from 'router/types';
import { LoaderContainer, Separator } from 'packages/shared/components';
import { Type } from 'packages/shared/components/Separator/types';
import { LineupRowsExtendedCompetitor } from 'packages/event-lineups/types';
import { Lineups } from 'packages/shared/modules';
import { useNavigation } from 'packages/hooks';

import connect from './connect';
import styles from './styles.scss';

type Props = {
  eventLineupsActions: ActionCreatorsMapObject;
  activeEvent: Event;
  isLoading: boolean;
  cleanEventLineups: () => void;
  competitors: [LineupRowsExtendedCompetitor, LineupRowsExtendedCompetitor];
};

const EventLineups = ({
  eventLineupsActions,
  activeEvent,
  competitors,
  isLoading,
  cleanEventLineups,
}: Props) => {
  const { appNavigate } = useNavigation();

  useEffect(() => {
    eventLineupsActions.loadEventLineups({ eventId: activeEvent.id });
  }, [activeEvent]);

  useEffect(
    () => () => {
      cleanEventLineups();
    },
    [],
  );

  const handlePlayerClick = useCallback((playerId: number) => {
    appNavigate(DesktopPage.FootballPlayer, { playerId });
  }, []);

  return (
    <LoaderContainer isLoading={isLoading} className={styles.loader}>
      <Lineups competitors={competitors} onPlayerClick={handlePlayerClick} />

      <Separator type={Type.Block} />
    </LoaderContainer>
  );
};

export default connect(EventLineups);
