import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';
import { Coefficient } from './types';

type Props = {
  coeffs?: Coefficient[];
};

const OddItem = ({ coeffs = mockUp }: Props) => {
  return (
    <div className={styles.oddItem}>
      <ul className={styles.oddItemCoeffs}>
        {coeffs.map((item) => {
          const className = classnames(styles.coeffItem, {
            [styles.coeffItemShift]: item.shift === 1,
            [styles.coeffItemUnshift]: item.shift === -1,
          });

          return (
            <li key={item.id} className={className}>
              {item.value}
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default OddItem;

const mockUp: Coefficient[] = [
  {
    id: '1',
    value: '2.00',
    shift: 1,
  },
  {
    id: '2',
    value: '2.42',
    shift: -1,
  },
  {
    id: '3',
    value: '1.03',
    shift: 1,
  },
];
