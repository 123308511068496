export enum StageType {
  Tables = 'tables',
  Brackets = 'brackets',
}

export enum MatchUpActiveType {
  Full = 'full',
  HalfTop = 'halfTop',
  HalfBottom = 'halfBottom',
}

export enum StatisticsGroup {
  Overall = 'overall',
  Home = 'home',
  Away = 'away',
}
