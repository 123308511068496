import { ConfigureReducer } from './types';

export const configureReducer = (config: ConfigureReducer) => (options: any = {}) => {
  for (let key in options) {
    if (options.hasOwnProperty(key)) {
      if (key in config) {
        config[key] = options[key];
      }
    }
  }
};
