import i18n from 'i18n-smart';

import { LocaleCode } from 'types';
import { keyValueDatabase } from 'packages/storage';
import DateService from 'packages/date-az';
import { settingsActions } from 'packages/settings';
import { SettingsStorageKey } from 'packages/settings/types';

export const setLocale = (localeCode: LocaleCode) => async (dispatch) => {
  i18n.setLocale(localeCode);
  DateService.setLocale(localeCode);
  keyValueDatabase.set(
    SettingsStorageKey.LocaleCode,
    localeCode,
    keyValueDatabase.tables.userSettings,
  );

  dispatch(settingsActions.setLocale({ code: localeCode }));
};
