import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { favoriteEventsThunks } from 'packages/events';
import { favoritesSelectors } from 'packages/favorites';

const mapDispatchToProps = (dispatch) => {
  return {
    favoriteEventsThunks: bindActionCreators(favoriteEventsThunks, dispatch),
  };
};

const mapStateToProps = (state) => {
  return {
    favoriteEvents: favoritesSelectors.getFavoriteEvents(state),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
