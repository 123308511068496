import { moreEventsActions, commonActions } from '../../actions';
import loadTeamEvents from './loadTeamEvents';

const loadFilteredEvents = (teamId: string) => async (dispatch) => {
  try {
    dispatch(moreEventsActions.loadFilteredEventsStart());

    const { events, count } = await dispatch(loadTeamEvents(teamId));

    dispatch(moreEventsActions.loadFilteredEventsSuccess(events));

    dispatch(commonActions.updateCountSuccess({ count }));
  } catch (e) {
    dispatch(moreEventsActions.loadFilteredEventsFailure(e));
  }
};

export default loadFilteredEvents;
