import React from 'react';
import { Column } from 'react-table';

import { Skeleton } from 'packages/shared/components';

const centerAlignStyle = {
  display: 'flex',
  justifyContent: 'center',
};

const getSkeletonTableSchema = (): Column[] => {
  return [
    {
      id: 'number',
      Header: <Skeleton height={14} width={45} variant="rectangular" />,
      headerStyle: { padding: '8px 8px 8px 16px', textAlign: 'center' },
      cellStyle: {
        padding: '8px 8px 8px 16px',
        ...centerAlignStyle,
      },
      Cell: <Skeleton height={16} width={14} variant="rectangular" />,
      width: 64,
    },
    {
      id: 'playerLogo',
      Header: <Skeleton height={14} width={36} variant="rectangular" />,
      headerStyle: { padding: '8px', textAlign: 'left' },
      cellStyle: {
        padding: '8px',
      },
      Cell: <Skeleton height={32} width={32} variant="circular" />,
      width: 48,
    },
    {
      id: 'playerName',
      Cell: <Skeleton height={16} width={112} variant="rectangular" />,
      minWidth: 200,
    },
    {
      id: 'position',
      Header: <Skeleton height={14} width={40} variant="rectangular" />,
      headerStyle: { padding: '8px', textAlign: 'left' },
      cellStyle: {
        padding: '8px',
      },
      Cell: <Skeleton height={16} width={70} variant="rectangular" />,
      width: 80,
    },
    {
      id: 'nation',
      Header: <Skeleton height={14} width={38} variant="rectangular" />,
      headerStyle: { padding: '8px', textAlign: 'center' },
      cellStyle: {
        padding: '8px',
        ...centerAlignStyle,
      },
      Cell: <Skeleton height={14} width={20} variant="rectangular" />,
      width: 50,
    },
    {
      id: 'age',
      Header: <Skeleton height={14} width={23} variant="rectangular" />,
      headerStyle: { padding: '8px', textAlign: 'right' },
      cellStyle: {
        padding: '8px',
      },
      Cell: <Skeleton height={16} width={22} variant="rectangular" />,
      width: 38,
    },
    {
      id: 'height',
      Header: <Skeleton height={14} width={62} variant="rectangular" />,
      headerStyle: { padding: '8px', textAlign: 'right' },
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '8px',
      },
      Cell: <Skeleton height={16} width={33} variant="rectangular" />,
      width: 75,
    },
    {
      id: 'weight',
      Header: <Skeleton height={14} width={56} variant="rectangular" />,
      headerStyle: { padding: '8px', textAlign: 'right' },
      cellStyle: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '8px',
      },
      Cell: <Skeleton height={16} width={22} variant="rectangular" />,
      width: 70,
    },
    {
      id: 'foot',
      Header: <Skeleton height={14} width={25} variant="rectangular" />,
      headerStyle: { padding: '8px', textAlign: 'left' },
      cellStyle: {
        padding: '8px',
      },
      Cell: <Skeleton height={16} width={28} variant="rectangular" />,
      width: 60,
    },
    {
      id: 'startDate',
      Header: <Skeleton height={14} width={70} variant="rectangular" />,
      headerStyle: { padding: '8px 16px 8px 8px', textAlign: 'left' },
      Cell: <Skeleton height={16} width={80} variant="rectangular" />,
      cellStyle: {
        padding: '8px 16px 8px 8px',
      },
      width: 115,
    },
  ];
};
export default getSkeletonTableSchema;
