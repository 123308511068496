import React, { CSSProperties, ReactElement, RefObject, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { useMutableState } from 'packages/hooks';
import { INITIAL_SCROLL_POSITION } from './constants';
import styles from './styles.scss';

type Props = {
  children: ReactElement;
  forwardedRef?: RefObject<HTMLDivElement>;
  onScrollStart?: (e: Event) => void;
  onScroll?: (e: Event) => void;
  onScrollEnd?: (e: Event) => void;
  scrollContainerTo?: (scrollContainer: HTMLDivElement, scrollLeft, scrollTop) => void;
  className?: string;
  style?: CSSProperties;
};

const DraggableScrollArea = ({
  children,
  scrollContainerTo = (scrollContainer: HTMLDivElement, scrollLeft, scrollTop) => {
    scrollContainer.scroll({ left: scrollLeft, top: scrollTop });
  },
  onScrollStart,
  onScroll,
  onScrollEnd,
  className,
  style,
  forwardedRef,
}: Props) => {
  const scrollAreaRef = forwardedRef || useRef<HTMLDivElement>(null);
  const [getScrollPosition, setScrollPosition] = useMutableState(INITIAL_SCROLL_POSITION);
  const [getMouseWheelTimer, setMouseWheelTimer] = useMutableState<NodeJS.Timeout | null>(null);
  const [getIsEventPreventable, setIsEventPreventable] = useMutableState<boolean>(false);

  useEffect(() => {
    if (scrollAreaRef.current) {
      const { current: scrollAreaElement } = scrollAreaRef;

      scrollAreaElement.addEventListener('scroll', handleScroll);
      scrollAreaElement.addEventListener('wheel', handleMouseWheel);
      scrollAreaElement.addEventListener('mousedown', handleMouseDown);
      scrollAreaElement.addEventListener('touchstart', handleTouchStart);

      return () => {
        scrollAreaElement.removeEventListener('scroll', handleScroll);
        scrollAreaElement.removeEventListener('wheel', handleMouseWheel);
        scrollAreaElement.removeEventListener('mousedown', handleMouseDown);
        scrollAreaElement.removeEventListener('touchstart', handleTouchStart);
      };
    }
  }, []);

  const handleScroll = (e: Event) => {
    e.preventDefault();
    onScroll?.(e);
  };

  const handleMouseWheel = (e: WheelEvent) => {
    e.preventDefault();

    const scrollContainer = scrollAreaRef.current;

    if (scrollContainer) {
      const { deltaX, deltaY } = e;
      const mouseWheelTimer = getMouseWheelTimer();

      if (mouseWheelTimer) {
        clearMouseWheelTimer();
        scrollContainerByMouseWheel(scrollContainer, deltaX, deltaY);
      } else {
        handleScrollStart(e);
      }

      setMouseWheelTimer(
        setTimeout(() => {
          clearMouseWheelTimer();
          handleScrollEnd(e);
        }, 150),
      );
    }
  };

  const clearMouseWheelTimer = () => {
    const mouseWheelTimer = getMouseWheelTimer();

    if (mouseWheelTimer) {
      clearTimeout(mouseWheelTimer);
      setMouseWheelTimer(null);
    }
  };

  const scrollContainerByMouseWheel = (scrollContainer: HTMLDivElement, x: number, y: number) => {
    const scrollLeft = scrollContainer.scrollLeft + x;
    const scrollTop = scrollContainer.scrollTop + y;

    scrollContainerTo(scrollContainer, scrollLeft, scrollTop);
  };

  const handleMouseDown = (e: MouseEvent) => {
    e.preventDefault();
    const { clientX, clientY } = e;

    if (scrollAreaRef.current) {
      scrollAreaRef.current.classList.add(styles.grabbing);
      setActualScrollPosition(scrollAreaRef.current, clientX, clientY);
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp, true);
      handleScrollStart(e);
    }
  };

  const handleTouchStart = (e: TouchEvent) => {
    e.stopPropagation();

    const [{ clientX, clientY }] = e.touches;

    if (scrollAreaRef.current) {
      setActualScrollPosition(scrollAreaRef.current, clientX, clientY);
      document.addEventListener('touchmove', handleTouchMove);
      document.addEventListener('touchend', handleTouchEnd, true);
      handleScrollStart(e);
    }
  };

  const setActualScrollPosition = (scrollContainer: HTMLDivElement, x, y) => {
    const { scrollLeft, scrollTop } = scrollContainer;

    setScrollPosition({
      scrollLeft,
      scrollTop,
      x,
      y,
    });
  };

  const handleMouseMove = (e: MouseEvent) => {
    const { clientX, clientY } = e;

    if (scrollAreaRef.current) {
      scrollContainer(scrollAreaRef.current, clientX, clientY);
    }
  };

  const handleMouseUp = (e: MouseEvent) => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);

    if (scrollAreaRef.current) {
      scrollAreaRef.current.classList.remove(styles.grabbing);
      handleScrollEnd(e);
    }
  };

  const handleTouchMove = (e: TouchEvent) => {
    const [{ clientX, clientY }] = e.touches;

    if (scrollAreaRef.current) {
      scrollContainer(scrollAreaRef.current, clientX, clientY);
    }
  };

  const handleTouchEnd = (e: TouchEvent) => {
    document.removeEventListener('touchmove', handleTouchMove);
    document.removeEventListener('touchend', handleTouchEnd, true);

    if (scrollAreaRef.current) {
      handleScrollEnd(e);
    }
  };

  const handleScrollStart = (e: Event) => {
    onScrollStart?.(e);
  };

  const handleScrollEnd = (e: Event) => {
    if (getIsEventPreventable()) {
      setIsEventPreventable(false);
      e.stopPropagation();
    }

    onScrollEnd?.(e);
  };

  const scrollContainer = (scrollContainer: HTMLDivElement, x: number, y: number) => {
    const actualPosition = getScrollPosition();
    let scrollLeft = actualPosition.scrollLeft + actualPosition.x - x;
    let scrollTop = actualPosition.scrollTop + actualPosition.y - y;

    scrollContainerTo(scrollContainer, scrollLeft, scrollTop);
    setIsEventPreventable(true);
  };

  return (
    <div
      style={style}
      className={classnames(styles.draggableScrollArea, className)}
      ref={scrollAreaRef}
    >
      {children}
    </div>
  );
};

export default DraggableScrollArea;
