import getPath from './getPath';

export const getSummary = (state) => {
  return getPath(state).data;
};

export const getFacts = (state) => {
  return getSummary(state).facts;
};

export const getPeriods = (state) => {
  return getSummary(state).periods;
};

export const getCommentaries = (state) => {
  return getSummary(state).commentaries;
};

export const getStadium = (state) => {
  return getSummary(state).stadium;
};

export const getReferee = (state) => {
  return getSummary(state).referee;
};

export const getAttendance = (state) => {
  return getSummary(state).attendance;
};

export const getWeather = (state) => {
  return getSummary(state).weather;
};
