import { Event, Team } from 'types';
import { mapEvents } from 'packages/events/services';

import {
  EventRows,
  TableViewData,
  TeamEventIntersectionsMap,
  TeamEventMap,
  TeamMap,
} from '../types';

const mapSeasonHeadToHead = (events): TableViewData => {
  const mappedEvents: Event[] = mapEvents(events);
  const teamMap = getTeamMap(mappedEvents);
  const teamEventIntersectionsMap = getTeamEventIntersectionsMap(teamMap, mappedEvents);

  return getTableViewData(teamMap, teamEventIntersectionsMap);
};

const getTeamMap = (events: Event[]): TeamMap => {
  const teamMap = {};

  for (const event of events) {
    const { teams } = event;

    for (const team of teams) {
      if (!teamMap[team.id]) {
        teamMap[team.id] = team;
      }
    }
  }

  return teamMap;
};

const getTeamEventIntersectionsMap = (
  teamMap: TeamMap,
  events: Event[],
): TeamEventIntersectionsMap => {
  const teamEventIntersectionsMapTemplate = getTeamEventIntersectionsMapTemplate(teamMap);

  return fulfillTeamMapWithEventIntersections(teamEventIntersectionsMapTemplate, events);
};

const getTeamEventIntersectionsMapTemplate = (teamMap: TeamMap) => {
  const teamIds = Object.keys(teamMap);

  return teamIds.reduce(
    (acc, curr, highLevelIndex) => ({
      ...acc,
      [curr]: teamIds.reduce(
        (acc, curr, lowLevelIndex) => ({
          ...acc,
          [curr]: { key: `${highLevelIndex}-${lowLevelIndex}` },
        }),
        {},
      ),
    }),
    {},
  );
};

const fulfillTeamMapWithEventIntersections = (
  teamEventIntersectionsMapTemplate: TeamEventIntersectionsMap,
  events: Event[],
) => {
  const fulfilledTeamEventIntersectionsMap = { ...teamEventIntersectionsMapTemplate };

  for (const event of events) {
    const { teams } = event;
    const [firstTeam, secondTeam] = teams;

    if (firstTeam.isHomeTeam) {
      fulfilledTeamEventIntersectionsMap[firstTeam.id][secondTeam.id] = {
        ...fulfilledTeamEventIntersectionsMap[firstTeam.id][secondTeam.id],
        ...event,
      };
    } else {
      fulfilledTeamEventIntersectionsMap[secondTeam.id][firstTeam.id] = {
        ...fulfilledTeamEventIntersectionsMap[firstTeam.id][secondTeam.id],
        ...event,
      };
    }
  }

  return fulfilledTeamEventIntersectionsMap;
};

const getTableViewData = (
  teamMap: TeamMap,
  teamEventIntersectionsMap: TeamEventIntersectionsMap,
): TableViewData => {
  const orderedTeamIds = getOrderedTeamIds(teamMap);

  return groupTableViewData(teamMap, teamEventIntersectionsMap, orderedTeamIds);
};

const getOrderedTeamIds = (teamMap: TeamMap): string[] => {
  return Object.entries(teamMap)
    .sort(([, teamA], [, teamB]) => teamA.name.localeCompare(teamB.name))
    .map(([teamId]) => teamId);
};

const groupTableViewData = (
  teamMap: TeamMap,
  teamEventIntersectionsMap: TeamEventIntersectionsMap,
  orderedTeamIds: string[],
): TableViewData => {
  const teams: Team[] = [];
  const eventRows: EventRows = [];

  for (const teamId of orderedTeamIds) {
    const orderedTeamEvents = getOrderedTeamEvents(
      teamEventIntersectionsMap[teamId],
      orderedTeamIds,
    );

    teams.push(teamMap[teamId]);
    eventRows.push(orderedTeamEvents);
  }

  return {
    teams,
    eventRows,
  };
};

const getOrderedTeamEvents = (
  teamEventsMap: TeamEventMap,
  orderedTeamIds: string[],
): (Event | null)[] => {
  return orderedTeamIds.map((teamId) => teamEventsMap[teamId]);
};

export default mapSeasonHeadToHead;
