import { LocaleCode } from 'types';

import DateService from './instance';

const DateAZ = new DateService();

export const configure = (localeCode: LocaleCode) => {
  DateAZ.setLocale(localeCode);
};

export default DateAZ;
