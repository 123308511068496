import { toCamelCase } from 'packages/utils/Object.utils';
import { mapEvent, groupEventsByDateAndTournament } from 'packages/events/services';
import { mapForm } from 'packages/event-summary/services/mappers/mapEventSummary';
import { TournamentView } from 'packages/events/types';
import { Event } from 'types';

const mapActualGames = (events: Record<string, unknown>[]): TournamentView[] => {
  const validEvents = events.filter((event) => Boolean(event?.event_status));

  return groupEventsByDateAndTournament(validEvents.map(mapGames));
};

const mapGames = (event: Record<string, unknown>): Event => {
  const { id, value, competitors, scheduled, eventStatus } = toCamelCase(event);

  const mappedEvent = mapEvent(event);

  return {
    ...mappedEvent,
    form: value ? mapForm([{ id, value, competitors, scheduled, eventStatus }]) : undefined,
  };
};

export default mapActualGames;
