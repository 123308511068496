export const isTextOverlaps = (el: HTMLElement): boolean => {
  return el.offsetWidth < el.scrollWidth;
};

export const isBlockContainingClickCoordinates = <T extends HTMLElement>(
  block: T | null | undefined,
  clickEvent: MouseEvent,
) => {
  if (block) {
    const blockRect = block.getBoundingClientRect();

    if (
      clickEvent.clientX >= blockRect.left &&
      clickEvent.clientX < blockRect.right &&
      clickEvent.clientY >= blockRect.top &&
      clickEvent.clientY <= blockRect.bottom
    ) {
      return true;
    }
  }

  return false;
};
