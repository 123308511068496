import { Tournaments } from 'packages/rest';
import { settingsSelectors } from 'packages/settings';

import { mapSearchResults } from '../services';
import * as actions from '../actions';

const loadResults = ({ searchKey }) => async (dispatch, getState) => {
  dispatch(actions.loadResultsStart());

  try {
    const activeSportId = settingsSelectors.getActiveSportId(getState());
    const { data } = await Tournaments.loadTournaments({ activeSportId, search: searchKey });

    const { results, next } = data;
    const hasNextResults = !!next;
    const nextOffset = results.length;
    const mappedResults = mapSearchResults(results);

    dispatch(actions.loadResultsSuccess({ results: mappedResults, hasNextResults, nextOffset }));
  } catch (e) {
    dispatch(actions.loadResultsFailure(e));
  }
};

export default loadResults;
