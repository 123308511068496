import { useEffect, useRef } from 'react';
import { PaletteColors } from 'react-palette';

const usePaletteGradient = <ElementType extends HTMLElement>(palette: PaletteColors) => {
  const targetRef = useRef<ElementType>(null);

  useEffect(() => {
    const { vibrant, darkVibrant } = palette;

    if (vibrant && darkVibrant && targetRef.current) {
      targetRef.current.style.background = `linear-gradient(90deg, ${vibrant} 0%, ${darkVibrant} 100%)`;
    }
  }, [palette]);

  return targetRef;
};

export default usePaletteGradient;
