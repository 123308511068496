import * as appActions from './actions';
import * as appSelectors from './selectors';
import * as appConstants from './constants';
import * as appThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export { appActions, appConstants, appThunks, appSelectors, createReducer };
