import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CSSTransition } from 'react-transition-group';

import styles from './styles.scss';
import fadeIn from '../../transitions/fadeIn.scss';

class AnimatedWrapper extends React.PureComponent {
  content = React.createRef();

  onEnter = () => {
    const { zIndex } = this.props;

    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';
    document.addEventListener('keydown', this.handleEscapePress);

    this.content.current.style.zIndex = zIndex;
  };

  onExit = () => {
    const { isNeedToEnableScrollOnUnmount } = this.props;

    if (isNeedToEnableScrollOnUnmount) {
      document.body.style = {};
      document.removeEventListener('keydown', this.handleEscapePress);
    }
  };

  onChildContainerClick(e) {
    e.stopPropagation();
  }

  handleEscapePress = (e) => {
    const { closeModal } = this.props;

    if (e.key === 'Escape') {
      closeModal();
    }
  };

  handleOnClose = () => {
    const { isNeedToCloseOnClick, closeModal } = this.props;

    if (isNeedToCloseOnClick) {
      closeModal();
    }
  };

  render() {
    const {
      className,
      children,
      isDisableShadow,
      isExtraShadowShown,
      isVisible,
      animationClasses,
      timeout,
    } = this.props;

    const modalClassNames = classNames(
      styles.modalContainer,
      {
        [styles.modalContainerShadowDisabled]: isDisableShadow,
        [styles.modalContainerShadowExtra]: !isDisableShadow && isExtraShadowShown,
      },
      className,
    );

    return (
      <div ref={this.content}>
        <CSSTransition
          onEnter={this.onEnter}
          onExit={this.onExit}
          in={isVisible}
          timeout={timeout}
          classNames={animationClasses}
          unmountOnExit
          appear
        >
          <div className={modalClassNames} onClick={this.handleOnClose}>
            <div onClick={this.onChildContainerClick}>{children}</div>
          </div>
        </CSSTransition>
      </div>
    );
  }
}

AnimatedWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isVisible: PropTypes.bool,

  className: PropTypes.string,
  isDisableShadow: PropTypes.bool,
  isExtraShadowShown: PropTypes.bool,
  closeModal: PropTypes.func,
  isNeedToCloseOnClick: PropTypes.bool,

  animationClasses: PropTypes.shape({
    appear: PropTypes.string,
    enter: PropTypes.string,
    appearActive: PropTypes.string,
    enterActive: PropTypes.string,
    exit: PropTypes.string,
    exitActive: PropTypes.string,
  }),
  timeout: PropTypes.number,

  zIndex: PropTypes.number,
  isNeedToEnableScrollOnUnmount: PropTypes.bool,
};

AnimatedWrapper.defaultProps = {
  className: '',
  isDisableShadow: false,
  isExtraShadowShown: false,
  closeModal: () => {},
  isNeedToCloseOnClick: false,
  animationClasses: fadeIn,
  timeout: 300,
  zIndex: 1,
  isNeedToEnableScrollOnUnmount: true,
  isVisible: true,
};

export default AnimatedWrapper;
