import DateService from 'packages/date-az';

import { mapSeasonTablesStages } from './eventSeasonTables';
import { mapTournamentBrackets } from './tournamentBracket';
import { Stage, StatisticsGroup } from '../../types';

export const mapStandings = (
  { seasonTablesStages, tournamentBrackets },
  positionSortKey: StatisticsGroup = StatisticsGroup.Overall,
): Stage[] => {
  const seasonTables = mapSeasonTablesStages(seasonTablesStages, positionSortKey);
  const tournamentBracket = mapTournamentBrackets(tournamentBrackets);

  return sortStages([...seasonTables, ...tournamentBracket]);
};

export const sortStages = (stages: Stage[]) => {
  return stages.sort((firstStage, secondStage) => {
    const firstStageDate = DateService.getDateTimeFromIsoString(firstStage.startDate);
    const secondStageDate = DateService.getDateTimeFromIsoString(secondStage.startDate);

    if (firstStageDate > secondStageDate) {
      return 1;
    } else if (firstStageDate < secondStageDate) {
      return -1;
    } else {
      return 0;
    }
  });
};
