import React, { memo, useMemo } from 'react';

import { Skeleton } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';
import { EventLoadingSkeleton } from 'packages/events-ui/components';

import styles from './styles.scss';

const ELEMENTS_COUNT = 8;

type Props = {
  isCompact?: boolean;
};

const EventListSkeleton = ({ isCompact = false }: Props) => {
  const elements = useMemo(() => ArrayUtils.getArrayFromNumber(ELEMENTS_COUNT), []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Skeleton variant="rectangular" height={14} width={113} />
      </div>

      <div className={styles.gamesList}>
        {elements.map((element) => (
          <EventLoadingSkeleton isCompact={isCompact} key={element} />
        ))}
      </div>
    </div>
  );
};

export default memo(EventListSkeleton);
