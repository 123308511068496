import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { favoritesThunks, favoritesSelectors } from 'packages/favorites';
import { categoryListSelectors } from 'packages/categories';
import { tournamentsSelectors } from 'packages/tournaments';

const mapStateToProps = (state) => {
  return {
    tournaments: favoritesSelectors.getFavoriteLeagues(state),
    isPopularTournamentsLoading: tournamentsSelectors.getIsPopularTournamentsLoading(state),
    isCategoriesLoading: categoryListSelectors.getIsCategoriesLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    favoritesActions: bindActionCreators(favoritesThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
