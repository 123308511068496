exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3s23dkm5{display:flex;justify-content:center}._3s23dkm5 ._2DXdgeLU{background-size:cover;min-width:16px;max-width:16px;min-height:10px;max-height:10px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/team-squad-ui/containers/TeamSquad/components/PlayersTable/components/CountryLogoCell/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/size.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,sBAAuB,CAH3B,sBAMM,qBAAsB,CCP1B,cDS4B,CCR5B,cDQ4B,CCF1B,eDEgC,CCDhC,eDCgC","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .logo-wrapper{\n    display: flex;\n    justify-content: center;\n\n    .country-logo {\n      background-size: cover;\n\n      @include fixed-size(16px, 10px);\n    }\n  }\n}","@mixin fixed-size($width, $height: null) {\n  min-width: $width;\n  max-width: $width;\n\n  @if ($height == null) {\n    min-height: $width;\n    max-height: $width;\n  } @else {\n    min-height: $height;\n    max-height: $height;\n  }\n}"]}]);

// Exports
exports.locals = {
	"logo-wrapper": "_3s23dkm5",
	"logoWrapper": "_3s23dkm5",
	"country-logo": "_2DXdgeLU",
	"countryLogo": "_2DXdgeLU"
};