import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Substitution = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton width={16} height={16} variant="rectangular" />
      <Skeleton marginRight={2} marginLeft={2} width={16} height={16} variant="rectangular" />
      <Skeleton width={16} height={16} variant="rectangular" />
    </div>
  );
};

export default memo(Substitution);
