import React, { useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import classnames from 'classnames';

import { Figure, Icon, Separator } from 'packages/shared/components';
import { TournamentsExtendedCategory } from 'packages/categories/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Tournament } from 'types';

import { TournamentList } from '../../../../components';
import { HeaderLoadingSkeleton } from './components';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  mainCategory: TournamentsExtendedCategory;
  mainCategoryActions: ActionCreatorsMapObject;
  categoryId: number;
  isLoading: boolean;
  className: string;
};

const MainCategory = ({
  mainCategory,
  mainCategoryActions,
  categoryId,
  isLoading,
  className,
}: Props) => {
  const { appNavigate, deltaNavigate } = useNavigation();

  useEffect(() => {
    mainCategoryActions.loadCategory(categoryId);
  }, [categoryId]);

  const handleTournamentClick = ({ currentSeasonId, uniqueTournamentId }: Tournament) => {
    appNavigate(DesktopPage.FootballSeason, { seasonId: currentSeasonId, uniqueTournamentId });
  };

  const handleCloseButtonClick = () => {
    deltaNavigate(-1);
  };

  return (
    <div className={classnames(styles.container, className)}>
      {isLoading ? (
        <HeaderLoadingSkeleton />
      ) : (
        <div className={styles.header}>
          <Figure className={styles.countryIcon} src={mainCategory?.logo} />
          <span className={styles.title}>{mainCategory?.name}</span>
          <Icon
            onClick={handleCloseButtonClick}
            id={IconType.Cross}
            className={styles.closeCategoryButton}
          />
        </div>
      )}

      <Separator />
      <TournamentList
        tournaments={mainCategory?.tournaments}
        onTournamentClick={handleTournamentClick}
        isLoading={isLoading}
      />
      <Separator />
    </div>
  );
};

export default connect(MainCategory);
