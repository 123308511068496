import { combineReducers } from 'redux';

import tournamentSeasons from './tournamentSeasons';
import statistics from './statistics';
import mobileFilter from './mobileFilter';

export default combineReducers({
  tournamentSeasons,
  statistics,
  mobileFilter,
});
