import DateAZ from 'packages/date-az';
import { keyValueDatabase } from 'packages/storage';
import { settingsThunks } from 'packages/settings';
import { SettingsStorageKey } from 'packages/settings/types';

import * as actions from '../actions';
import * as selectors from '../selectors';

export const configureTimeZone = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const { userSettings } = keyValueDatabase.tables;
    const timeZone = await keyValueDatabase.get(SettingsStorageKey.TimeZone, userSettings);

    if (timeZone) {
      await dispatch(settingsThunks.setTimeZone(timeZone));
    } else {
      const localOffset = DateAZ.getOffset();
      const timeZoneDefault = selectors.getDefaultTimeZone(state);
      const timeZones = selectors.getTimeZones(state);
      const timeZone = timeZones.find(({ value }) => value === localOffset) || timeZoneDefault;

      await keyValueDatabase.set(SettingsStorageKey.TimeZone, timeZone, userSettings);

      dispatch(settingsThunks.setTimeZone(timeZone));
    }
  } catch (e) {
    dispatch(actions.failure(e));
  }
};
