import { CSSProperties, RefObject } from 'react';

const getContainerWidth = (containerRef?: RefObject<HTMLDivElement>): CSSProperties => {
  if (containerRef?.current) {
    return {
      minWidth: `${containerRef.current.getBoundingClientRect().width}px`,
    };
  }

  return {};
};

export default getContainerWidth;
