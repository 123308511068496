import { toCamelCase } from 'packages/utils/Object.utils';

import { IMPORTANT_EVENT_TYPES } from '../../constants';
import { Commentary, CommentaryGroups } from '../../types';

const mapCommentaries = (commentaries): CommentaryGroups => {
  const mappedCommentaries = commentaries.map(mapCommentary);
  const importantCommentaries = selectImportantCommentaries(mappedCommentaries);

  return {
    all: mappedCommentaries,
    important: importantCommentaries,
  };
};

const mapCommentary = (commentary): Commentary => {
  const { text, minute, eventType, id, injuryTime, additionalInfo } = toCamelCase(commentary, true);

  return {
    id,
    text,
    eventType,
    minute: `${minute}'`,
    injuryTime: injuryTime ? `${injuryTime}` : undefined,
    additionalInfo,
  };
};

const selectImportantCommentaries = (commentaries): Commentary[] => {
  return commentaries.filter(({ eventType }) => IMPORTANT_EVENT_TYPES.includes(eventType));
};

export default mapCommentaries;
