import Dexie from 'dexie';

import { Event } from 'types';
import { keyValueDatabase } from 'packages/storage';
import { Tables, FavoritesEventsTableIndex } from 'configure/storage/types';

import loadLatestFavoritesFromStorage from './loadLatestFavoritesFromStorage';

import { putFavoritesStart, putFavoritesFailure, putFavoritesSuccess } from '../actions';

const putFavorites = (events: Event[]) => async (dispatch) => {
  try {
    dispatch(putFavoritesStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoriteEventsModel = dexieInstance.table(Tables.FAVORITE_EVENTS);
    const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);

    await dexieInstance.transaction('rw', [favoriteEventsModel, favoritesModel], () =>
      Promise.all([
        favoritesModel.bulkPut(events),
        ...events.map(async (event) =>
          favoriteEventsModel.update(FavoritesEventsTableIndex.Id, event),
        ),
      ]),
    );

    await dispatch(loadLatestFavoritesFromStorage());

    dispatch(putFavoritesSuccess());
  } catch (e) {
    dispatch(putFavoritesFailure(e));
  }
};

export default putFavorites;
