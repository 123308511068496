import React from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { VenuesParameter, TopVenue } from 'packages/season-top-board/types';
import { Tooltip } from 'packages/shared/components';

import {
  ParameterHeader,
  IntegerParameterCell,
  PercentageParameterCell,
  VenueCell,
} from '../../components';

const getVenuesSchema = (): Column<TopVenue>[] => [
  {
    id: 'venue',
    Header: i18n.value('common.tableParameter.short.venue'),
    Cell: ({ value }) => <VenueCell venue={value} />,
    accessor: 'venue',
    cellStyle: { paddingLeft: 16 },
    headerStyle: { textAlign: 'left', padding: '8px 8px 8px 16px' },
    width: 58,
  },
  {
    id: 'capacity',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${VenuesParameter.Capacity}`)}>
        <ParameterHeader parameter={VenuesParameter.Capacity} />
      </Tooltip>
    ),
    Cell: ({ value }) => IntegerParameterCell(value),
    accessor: 'capacity',
    width: 15,
  },
  {
    id: 'average',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${VenuesParameter.Average}`)}>
        <ParameterHeader parameter={VenuesParameter.Average} />
      </Tooltip>
    ),
    Cell: ({ value }) => IntegerParameterCell(value),
    accessor: 'average',
    width: 15,
  },
  {
    id: 'occupancy',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${VenuesParameter.Occupancy}`)}>
        <ParameterHeader parameter={VenuesParameter.Occupancy} />
      </Tooltip>
    ),
    Cell: ({ value }) => PercentageParameterCell(value),
    accessor: 'occupancy',
    width: 12,
  },
];

export default getVenuesSchema;
