import React from 'react';

type Props = {
  goalsFor: number;
  goalsAgainst: number;
};

const TournamentTableGoalsCell = ({ goalsFor, goalsAgainst }: Props) => (
  <>
    {goalsFor}:{goalsAgainst}
  </>
);

export default TournamentTableGoalsCell;
