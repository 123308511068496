import { Status } from '../types';
import getPath from './getPath';

export const getStatus = (state): Status => {
  return getPath(state).status;
};

export const getIsLoading = (state) => {
  return getStatus(state).isLoading;
};
