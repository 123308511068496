import * as matchLineupsActions from './actions';
import * as matchLineupsThunks from './thunks';
import * as matchLineupsConstants from './constants';
import * as matchLineupsSelectors from './selectors';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  matchLineupsActions,
  matchLineupsThunks,
  matchLineupsConstants,
  matchLineupsSelectors,
  createReducer,
};
