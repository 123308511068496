import React, { memo } from 'react';
import { Chip, Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from '../styles.scss';

export type Props = {
  minute: string;
};

const PeriodStart = () => (
  <Chip className={styles.chip}>
    <Icon id={IconType.PeriodStart} />
  </Chip>
);

export default memo(PeriodStart);
