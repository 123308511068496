import EventDesktop from './Desktop';
import EventMobile from './Mobile';

export default {
  Desktop: EventDesktop,
  DesktopNarrow: EventDesktop,
  Tablet: EventDesktop,
  TabletNarrow: EventDesktop,
  Mobile: EventMobile,
};
