import * as configActions from './actions';
import * as configConstants from './constants';
import * as configSelectors from './selectors';
import * as configThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export { configConstants, configThunks, configSelectors, configActions, createReducer };
