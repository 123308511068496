import { PlayerHappeningType, SummaryHappeningType } from 'packages/events/types';

import { CardColor, PenaltyShootoutStatuses, PlayerType } from '../enums';

export type TimeLinePeriodScores = {
  [period in HappeningEventPeriod]?: TimeLinePeriodScore;
};

export interface TimeLinePeriodScore {
  home: number;
  away: number;
}

export enum HappeningEventPeriod {
  FIRST_EXTRA = '1ST_EXTRA',
  FIRST_HALF = '1ST_HALF',
  SECOND_EXTRA = '2ND_EXTRA',
  SECOND_HALF = '2ND_HALF',
  HALFTIME = 'HALFTIME',
  PENALTIES = 'PENALTIES',
}

export interface BaseHappening {
  id: number;
  periodType?: string;
  period?: number;
  eventType: SummaryHappeningType | PlayerHappeningType;
  players?: Player[];
  team?: string;
  minute?: number;
  seconds?: number;
  injuryTime?: number;
  additionalInfo?: any;
}

export interface PlayerHappening extends BaseHappening {
  playerId?: number;
}

export interface GoalHappening extends BaseHappening {
  isPenaltyGoal: boolean;
  isOwnGoal: boolean;
  scorerName?: string;
  assistName?: string;
  scorerId?: number;
  assistId?: number;
}

export interface MissedPenaltyHappening extends BaseHappening {
  scorerName?: string;
}

export interface PeriodHappening extends BaseHappening {
  periodType: HappeningEventPeriod;
  score: string;
}

export interface CardHappening extends BaseHappening {
  color: CardColor;
  playerName: string;
  isTookOnTheBench?: boolean;
}

export interface SubstitutionHappening extends BaseHappening {
  playerInName: string;
  playerOutName: string;
}

export interface PenaltyShootoutHappening extends BaseHappening {
  score?: string;
  status: PenaltyShootoutStatuses;
  scorerName?: string;
}

export interface Player {
  playerId: number;
  name: string;
  playerType: PlayerType;
  shortName: string;
}

export enum HappeningMethod {
  OwnGoal = 'own_goal',
  Penalty = 'penalty',
}
