import { createElement } from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { TournamentSeasonChampion } from 'packages/season-history/types';
import { Team } from 'types';

import { TeamCell, TrophiesCell } from '../components';

type Options = {
  onTeamClick: (team: Team) => void;
};

const getTableSchema = ({ onTeamClick }: Options): Column<TournamentSeasonChampion>[] => {
  return [
    {
      id: 'team',
      Header: i18n.value('season.totalTrophies.column.team'),
      headerStyle: { textAlign: 'left', paddingLeft: 16 },
      accessor: (row) => row,
      Cell: ({ value: team }) => createElement(TeamCell, { team, onTeamClick }),
      width: 300,
    },
    {
      id: 'trophies',
      Header: i18n.value('season.totalTrophies.column.trophies'),
      headerStyle: { textAlign: 'center' },
      accessor: 'championRecord',
      Cell: ({ value: count }) => createElement(TrophiesCell, { count }),
      width: 80,
    },
  ];
};

export default getTableSchema;
