import { Interfaces as PlayerSummaryInterfaces } from 'packages/player-summary/types';

import { Data } from '../types';
import getPath from './getPath';

export const getData = (state): Data => {
  return getPath(state).data;
};

export const getStatistics = (
  state,
): PlayerSummaryInterfaces.Statistics.Statistics.Statistics | undefined => {
  return getData(state)?.statistics;
};
