import { PropertyType, ValueType, ParameterNames, ParameterSchema } from '../types';

export const NAMESPACE = 'EVENT-STATS::';

export const PARAMETER_ORDER_NUMBER = {
  [ParameterNames.BallPossession]: 0,
  [ParameterNames.ShotsTotal]: 1,
  [ParameterNames.ShotsOnTarget]: 2,
  [ParameterNames.ShotsOffTarget]: 3,
  [ParameterNames.ShotsBlocked]: 4,
  [ParameterNames.CornerKicks]: 5,
  [ParameterNames.Offsides]: 6,
  [ParameterNames.FreeKicks]: 7,
  [ParameterNames.GoalKicks]: 8,
  [ParameterNames.ThrowIns]: 9,
  [ParameterNames.ShotsSaved]: 10,
  [ParameterNames.Fouls]: 11,
  [ParameterNames.YellowCards]: 12,
  [ParameterNames.RedCards]: 13,
  [ParameterNames.CardsGiven]: 14,
  [ParameterNames.Substitutions]: 15,
  [ParameterNames.Injuries]: 16,
};

export const PARAMETER_SCHEMA_MAP: Record<string, ParameterSchema> = {
  yellow_cards: {
    key: 'eventStats.parameter.yellowCards',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Disadvantage,
  },
  red_cards: {
    key: 'eventStats.parameter.redCards',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Disadvantage,
  },
  cards_given: {
    key: 'eventStats.parameter.allCards',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Disadvantage,
  },
  substitutions: {
    key: 'eventStats.parameter.substitutions',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Disadvantage,
  },
  ball_possession: {
    key: 'eventStats.parameter.ballPossession',
    valueType: ValueType.Percentage,
    propertyType: PropertyType.Advantage,
  },
  free_kicks: {
    key: 'eventStats.parameter.freeKicks',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Advantage,
  },
  goal_kicks: {
    key: 'eventStats.parameter.goalKicks',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Advantage,
  },
  throw_ins: {
    key: 'eventStats.parameter.throwIns',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Advantage,
  },
  offsides: {
    key: 'eventStats.parameter.offsides',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Disadvantage,
  },
  corner_kicks: {
    key: 'eventStats.parameter.cornerKicks',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Advantage,
  },
  shots_on_target: {
    key: 'eventStats.parameter.shotsOnTarget',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Advantage,
  },
  shots_off_target: {
    key: 'eventStats.parameter.shotsOffTarget',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Advantage,
  },
  shots_saved: {
    key: 'eventStats.parameter.saves',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Advantage,
  },
  fouls: {
    key: 'eventStats.parameter.fouls',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Disadvantage,
  },
  injuries: {
    key: 'eventStats.parameter.injuries',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Disadvantage,
  },
  shots_blocked: {
    key: 'eventStats.parameter.shotsBlocked',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Advantage,
  },
  shots_total: {
    key: 'eventStats.parameter.goalAttempts',
    valueType: ValueType.Counter,
    propertyType: PropertyType.Advantage,
  },
};
