import { toCamelCase } from 'packages/utils/Object.utils';
import { Team } from 'types';
import { TEAM_DEFAULT_LOGO } from 'appConstants';

import { History } from '../types';

const mapSeasons = (seasons: unknown[]): History[] => {
  return seasons.map((season) => {
    const {
      name,
      year,
      champion: [winnerTeam],
      runnerUp: [runnerUpTeam],
      thirdPlace: [thirdPlaceTeam],
      relegations,
      id,
    } = toCamelCase(season as object);

    return {
      id: String(id),
      name,
      year,
      winner: mapTeam(winnerTeam),
      runnerUp: mapTeam(runnerUpTeam),
      thirdPlace: mapTeam(thirdPlaceTeam),
      relegations: mapTeams(relegations),
    };
  });
};

const mapTeam = (team?: object): Team | undefined => {
  if (team) {
    const { teamId, name, logo } = toCamelCase(team);

    return {
      id: teamId,
      name,
      logo: logo || TEAM_DEFAULT_LOGO,
    };
  }

  return undefined;
};

const mapTeams = (teams: object[]): Team[] => {
  return teams.map(mapTeam).filter((team) => !!team) as Team[];
};

export default mapSeasons;
