import React, { memo } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { Interfaces as StadiumSummaryInterfaces } from 'packages/stadium-summary/types';
import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from './styles.scss';
import { LoadingSkeleton } from './components';

type Props = {
  average?: StadiumSummaryInterfaces.Statistics.Average;
  className?: string;
  isLoading: boolean;
};

const Average = ({ average, className, isLoading }: Props) => {
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (!average) {
    return null;
  }

  const { cards, cornerKicks } = average;

  const [cardsInteger, cardsDecimal = '00'] = cards.toString().split('.');
  const [cornerKicksInteger, cornerKicksDecimal = '00'] = cornerKicks.toString().split('.');

  return (
    <div className={classnames(styles.average, className)}>
      <div className={styles.title}>Average</div>
      <div className={styles.parameters}>
        <div className={styles.parameter}>
          <div className={styles.value}>
            {cardsInteger}
            <span className={styles.decimal}>.{cardsDecimal}</span>
          </div>
          <div className={styles.label}>
            <Icon id={IconType.YellowCard} className={styles.icon} />
            <Icon id={IconType.RedCard} className={styles.icon} />
            {i18n.value('stadium.statistics.average.cards')}
          </div>
        </div>
        <div className={styles.parameter}>
          <div className={styles.value}>
            {cornerKicksInteger}
            <span className={styles.decimal}>.{cornerKicksDecimal}</span>
          </div>
          <div className={styles.label}>
            {i18n.value('stadium.statistics.average.cornerKicksLabel')}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(Average);
