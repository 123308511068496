import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Interfaces } from '../../types';
import { statisticsActions, commonActions } from '../../actions';

const defaultState = null;

const reducerMap: ReducerMap<Interfaces.Statistics.Data, Interfaces.Statistics.Data> = {
  [`${statisticsActions.loadStatisticsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(
    statisticsActions.loadStatisticsFailure,
    statisticsActions.cleanStatisticsSuccess,
    commonActions.cleanSummarySuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
