import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Data } from '../types';

import {
  loadPlayerDetailsStart,
  loadPlayerDetailsSuccess,
  loadPlayerDetailsFailure,
  cleanPlayerDetailsSuccess,
} from '../actions';

const defaultState = null;

const reducerMap: ReducerMap<Data, Data> = {
  [`${loadPlayerDetailsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(
    loadPlayerDetailsStart,
    loadPlayerDetailsFailure,
    cleanPlayerDetailsSuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
