import instance from '../../../axios';
import { SeasonTeamStatisticsParams } from '../types';
import { stringifyOrderingParam } from '../../../services';

export const loadTeams = ({
  seasonId,
  limit,
  offset,
  countries,
  parameterRanges = {},
  ordering = {},
}: SeasonTeamStatisticsParams) => {
  const stringifiedOrdering = stringifyOrderingParam(ordering);

  return instance({
    method: 'get',
    url: `/seasons/${seasonId}/top_teams/`,
    params: {
      limit,
      offset,
      country: countries?.join(','),
      ordering: stringifiedOrdering,
      ...parameterRanges,
    },
  });
};
