import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Skeleton height={14} width={90} variant="rectangular" />
      </div>

      <div className={styles.content}>
        <div className={styles.rightContainer}>
          <Skeleton height={48} width="60%" variant="rectangular" />
          <Skeleton height={12} width="100%" marginTop={12} variant="rectangular" />
        </div>

        <div className={styles.leftContainer}>
          <Skeleton height={48} width="100%" variant="rectangular" />
          <Skeleton height={12} width="100%" marginTop={12} variant="rectangular" />
        </div>
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
