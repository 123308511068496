import React from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { PlayersParameter, TopPlayer } from 'packages/season-top-board/types';
import { Tooltip } from 'packages/shared/components';

import { ParameterHeader, PlayerCell, TeamCell } from '../../../../../../../components';

export default (): Column<TopPlayer>[] => [
  {
    id: 'player',
    Header: i18n.value('common.tableParameter.short.player'),
    Cell: ({ value }) => <PlayerCell player={value} />,
    accessor: ({ player }) => player,
    width: 58,
    cellStyle: { paddingLeft: 16 },
    headerStyle: { textAlign: 'left', padding: '8px 8px 8px 16px' },
  },
  {
    id: 'team',
    Cell: ({ value: team }) => <TeamCell isNameHidden team={team} />,
    accessor: ({ player }) => player.team,
    width: 10,
  },
  {
    id: 'gamesPlayed',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.GamesPlayed}`)}>
        <>{i18n.value(`common.tableParameter.short.${PlayersParameter.GamesPlayed}`)}</>
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.gamesPlayed,
    width: 10,
  },
  {
    id: 'totalShots',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.TotalShots}`)}>
        <ParameterHeader parameter={PlayersParameter.TotalShots} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.totalShots,
    width: 10,
  },
  {
    id: 'shotsOnGoal',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.ShotsOnGoal}`)}>
        <ParameterHeader parameter={PlayersParameter.ShotsOnGoal} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.shotsOnGoal,
    width: 10,
  },
  {
    id: 'offside',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.Offside}`)}>
        <ParameterHeader parameter={PlayersParameter.Offside} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.offside,
    width: 12,
  },
];
