import { EventTimeLine } from 'packages/rest';

import * as actions from '../actions';
import {
  formatEventTimeLine,
  formatTimeLinePeriodScores,
  mapTimeLineHappenings,
} from '../services';

const loadEventTimeLine = ({ eventId }) => async (dispatch) => {
  try {
    dispatch(actions.loadEventTimeLineStart());

    const { data } = await EventTimeLine.loadEventTimeLine({ eventId });
    const { period_scores: periodScores, time_line: timeLine } = data.results;

    const formattedTimeLineHappenings = formatEventTimeLine(timeLine);
    const formattedPeriodScores = formatTimeLinePeriodScores(periodScores);
    const mappedTimeLineHappenings = mapTimeLineHappenings(
      formattedTimeLineHappenings,
      formattedPeriodScores,
    );

    dispatch(actions.loadEventTimeLineSuccess(mappedTimeLineHappenings));
  } catch (error) {
    dispatch(actions.loadEventTimeLineFailure());
  }
};

export default loadEventTimeLine;
