import { playersActions } from '../../actions';
import { playersSelectors } from '../../selectors';
import loadPlayers from './loadPlayers';

const loadPlayersPage = (seasonId: number) => async (dispatch, getState) => {
  try {
    dispatch(playersActions.loadPlayersPageStart());

    const offset = playersSelectors.getOffset(getState());
    const limit = playersSelectors.getLimit(getState());

    const teamsData = await dispatch(loadPlayers({ seasonId, offset, limit }));

    dispatch(playersActions.loadPlayersPageSuccess(teamsData));
  } catch (e) {
    dispatch(playersActions.loadPlayersPageFailure(e));
  }
};

export default loadPlayersPage;
