import { Stadiums } from 'packages/rest';

import { mapTournamentList } from '../../services';
import { tournamentsActions } from '../../actions';

const loadTournamentList = (stadiumId: number) => async (dispatch) => {
  try {
    dispatch(tournamentsActions.loadTournamentListStart());

    const { data } = await Stadiums.loadTournamentList({ stadiumId });

    const tournaments = mapTournamentList(data.results);

    dispatch(
      tournamentsActions.loadTournamentListSuccess({
        tournaments,
      }),
    );
  } catch (e) {
    dispatch(tournamentsActions.loadTournamentListFailure(e));
  }
};

export default loadTournamentList;
