import { Dispatch } from 'redux';

import { SocketActionCreators, SocketConfig } from '../types/interfaces';

export const generateThunkSocketConfig = <ItemType>(
  initialSocketConfig: SocketConfig<ItemType>,
  socketActionCreators: SocketActionCreators,
  dispatch: Dispatch
): SocketConfig<ItemType> => ({
    onConnect: (status) => {
      initialSocketConfig?.onConnect?.(status);
      dispatch(socketActionCreators.open({ status }));
    },
    onClose: (status) => {
      initialSocketConfig?.onClose?.(status);
      dispatch(socketActionCreators.close({ status }));
    },
    onMessage: (message) => {
      initialSocketConfig?.onMessage?.(message);
      dispatch(socketActionCreators.update(message));
    },
    onError: (error) => {
      initialSocketConfig?.onError?.(error);
      dispatch(socketActionCreators.failure(error))
    },
    fetchData: initialSocketConfig.fetchData,
    dataReducerFactoryConfig: initialSocketConfig.dataReducerFactoryConfig
  })
