import { teamsActions } from '../../actions';
import { INITIAL_TEAMS_LIMIT } from '../../constants';
import loadTeams from './loadTeams';

const loadInitialTeams = (seasonId: number) => async (dispatch) => {
  try {
    dispatch(teamsActions.loadInitialTeamsStart());

    const teamsData = await dispatch(
      loadTeams({ seasonId, limit: INITIAL_TEAMS_LIMIT, offset: 0 }),
    );

    dispatch(teamsActions.loadInitialTeamsSuccess(teamsData));
  } catch (e) {
    dispatch(teamsActions.loadInitialTeamsFailure(e));
  }
};

export default loadInitialTeams;
