import { favoriteEventsActions } from '../../actions';

const cleanFavoriteEvents = () => async (dispatch) => {
  try {
    dispatch(favoriteEventsActions.cleanFavoriteEventsSuccess());
  } catch (error) {
    dispatch(favoriteEventsActions.cleanFavoriteEventsFailure(error));
  }
};

export default cleanFavoriteEvents;
