import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { HappeningPeriod } from 'packages/event-summary/types';

import { TimeLine as TimeLineModule } from 'packages/shared/modules';
import styles from './styles.scss';

type Props = {
  periods: HappeningPeriod[];
};

const TimeLine = ({ periods }: Props) => {
  return (
    <div className={styles.summaryOfPeriods}>
      <div className={styles.header}>{i18n.value('eventSummary.timeLine.header')}</div>

      <TimeLineModule periods={periods} />
    </div>
  );
};

export default memo(TimeLine);
