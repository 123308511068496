import { toCamelCase } from 'packages/utils/Object.utils';
import { Country } from 'types';

import { TEAM_DEFAULT_LOGO } from 'appConstants';

import mapTournamentList from './mapTournamentList';
import { Data, Stadium, Manager } from '../types';

const mapDetails = (
  details: Record<string, any>,
  tournamentList: Record<string, unknown>[],
): Data => {
  const {
    id,
    name,
    fullName,
    logo,
    manager,
    homeVenues,
    homeJersey,
    awayJersey,
    awayJersey2,
    gkJersey,
    country,
  } = toCamelCase(details, true);

  return {
    manager: manager ? mapManager(manager) : undefined,
    stadiums: homeVenues ? mapStadiums(homeVenues) : undefined,
    country: country ? mapCountry(country) : undefined,
    team: {
      id,
      name,
      fullName,
      logo: logo || TEAM_DEFAULT_LOGO,
    },
    jerseySet: {
      home: homeJersey,
      away: awayJersey,
      awaySecondary: awayJersey2,
      goalkeeper: gkJersey,
    },
    tournamentList: mapTournamentList(tournamentList),
  };
};

const mapManager = (manager: Record<string, any>): Manager => {
  const { name, logo, countryLogo } = manager;

  return {
    name,
    logo,
    countryLogo,
  };
};

const mapStadiums = (stadiums: Record<string, any>[]): Stadium[] => {
  return stadiums.map(({ id, name, city, capacity, constructionYear, logo }) => ({
    id,
    name,
    city,
    logo,
    capacity,
    year: constructionYear,
  }));
};

const mapCountry = (country: Record<string, any>): Country => {
  const { id, name, logo } = country;

  return { id, name, logo };
};

export default mapDetails;
