import { StageTab } from 'packages/season-standings-ui/types';

const getDefaultActiveTabIndex = (
  tabs: StageTab[],
  activeTabId?: number | string,
  activeTabIndex = 0,
): number => {
  if (activeTabId !== undefined) {
    const defaultActiveTabIndexById = tabs.findIndex(({ id }) => id === activeTabId);

    if (defaultActiveTabIndexById >= 0) {
      activeTabIndex = defaultActiveTabIndexById;
    }
  }

  return activeTabIndex;
};

export default getDefaultActiveTabIndex;
