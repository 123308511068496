import React, { useCallback, memo } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { Player } from 'packages/season-player-statistics/types';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { useNavigation } from 'packages/hooks';
import { Enums, Interfaces } from 'packages/team-player-statistics/types';
import { MobilePage } from 'router/types';
import { MediaScreen, Team } from 'types';

import { MainScreen, GeneralFilter } from './components';
import { ExtendedFilter } from '../../components';

import styles from './styles.scss';

type Props = {
  customFilterActions: ActionCreatorsMapObject;
  tournaments?: SeasonsExtendedTournament[];
  customFilters: Interfaces.CustomFilter.CustomFilterState;
  playersStatistics: Interfaces.PlayerStatistics.PlayerStatistics[];
  filters: Interfaces.Filters.Filters;
  loadPlayersPage: () => void;
  onCustomFilterApply: () => void;
  onCustomFilterCancel: () => void;
  onOrderingChange: (columnType: Enums.ColumnType) => void;
  onPaginationChange: ({ offset, limit }) => void;
  onCustomizeButtonClick: () => void;
  isFiltersLoading: boolean;
  isInitialPlayersPageLoading: boolean;
  isNewPlayersPageLoading: boolean;
  isExtendedFilterOpen: boolean;
  limit: number;
  count: number;
};

const PlayerStatistics = ({
  filters,
  customFilters,
  customFilterActions,
  playersStatistics,
  tournaments = [],
  isFiltersLoading,
  isInitialPlayersPageLoading,
  isNewPlayersPageLoading,
  isExtendedFilterOpen,
  limit,
  count,
  loadPlayersPage,
  onCustomFilterApply,
  onCustomFilterCancel,
  onOrderingChange,
  onPaginationChange,
  onCustomizeButtonClick,
}: Props) => {
  const { teamId, tournamentId, seasonId } = useParams();
  const { appNavigate } = useNavigation(MediaScreen.Mobile);

  const handleTeamClick = useCallback((team: Team) => {
    appNavigate(MobilePage.FootballTeam, { teamId: team.id });
  }, []);

  const handlePlayerClick = useCallback((player: Player) => {
    appNavigate(MobilePage.FootballPlayer, { playerId: player.id });
  }, []);

  const handleTournamentChange = useCallback(
    (tournamentId: number | string) => {
      if (teamId && tournamentId) {
        const [firstSelectedTournamentSeason] =
          tournaments.find(({ id }) => id === tournamentId)?.seasons || [];

        appNavigate(MobilePage.FootballTeamPlayerStatistics, {
          uniqueTournamentId: tournamentId,
          seasonId: firstSelectedTournamentSeason.id,
          teamId,
        });
      }
    },
    [teamId, tournaments, teamId],
  );

  const handlePresetChange = useCallback(
    (presetType: Enums.PresetType) => {
      customFilterActions.updateFilterPreset(presetType);
      loadPlayersPage();
    },
    [loadPlayersPage],
  );

  const handleCustomFilterUpdate = useCallback(
    (updates: Interfaces.CustomFilter.CustomFilterState) => {
      const { generalFilter: updatedGeneralFilter } = updates;
      const { generalFilter } = customFilters;
      const { seasonId, tournamentId } = generalFilter;
      const { seasonId: updatedSeasonId, tournamentId: updatedTournamentId } = updatedGeneralFilter;

      customFilterActions.updateCustomFilter(updates);

      if (updatedSeasonId && updatedTournamentId && teamId) {
        if (seasonId !== updatedSeasonId || tournamentId !== updatedTournamentId) {
          appNavigate(MobilePage.FootballTeamPlayerStatistics, {
            uniqueTournamentId: updatedTournamentId,
            seasonId: updatedSeasonId,
            teamId,
          });
        } else {
          loadPlayersPage();
        }
      }
    },
    [customFilters, teamId, loadPlayersPage],
  );

  return (
    <div className={styles.playerStatisticsContainer}>
      <ExtendedFilter
        tournaments={tournaments}
        GeneralFilterComponent={GeneralFilter}
        onCustomFilterUpdate={handleCustomFilterUpdate}
        onCancel={onCustomFilterCancel}
        onApply={onCustomFilterApply}
        customFilters={customFilters}
        isLoading={isFiltersLoading}
        filters={filters}
        isOpen={isExtendedFilterOpen}
        className={styles.extendedFilter}
      />

      <div className={styles.tableContainer}>
        <MainScreen
          tournaments={tournaments}
          selectedTournamentId={parseInt(tournamentId || '')}
          selectedSeasonId={parseInt(seasonId || '')}
          onTournamentChange={handleTournamentChange}
          onTeamClick={handleTeamClick}
          onPlayerClick={handlePlayerClick}
          onCustomizeButtonClick={onCustomizeButtonClick}
          onOrderingChange={onOrderingChange}
          onPresetChange={handlePresetChange}
          onPaginationChange={onPaginationChange}
          amountOfPlayers={count}
          currentPageSize={limit}
          playersStatistics={playersStatistics}
          isInitialPlayersPageLoading={isInitialPlayersPageLoading}
          isNewPlayersPageLoading={isNewPlayersPageLoading}
          activeStatsTab={customFilters.generalFilter.activeStatsTab}
          activePreset={customFilters.columnConfigurationFilter.activePreset}
          columns={customFilters.columnConfigurationFilter.columns}
          ordering={customFilters.columnConfigurationFilter.configurableOrdering}
        />
      </div>
    </div>
  );
};

export default memo(PlayerStatistics);
