import { Jersey } from '../../../../../types';

export const DEFAULT_JERSEY_COLOR: Jersey = {
  base: '#F1F1F5',
  number: '#F1F1F5',
  stripes: '#F1F1F5',
  sleeve: '#F1F1F5',
  real: false,
  type: '',
};
