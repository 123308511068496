import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  filtersThunks,
  filtersSelectors,
  customFilterThunks,
  customFilterSelectors,
  teamsThunks,
  teamsSelectors,
  commonThunks,
} from 'packages/season-team-statistics';

const mapStateToProps = (state) => {
  return {
    customFilters: customFilterSelectors.getData(state),
    filters: filtersSelectors.getData(state),
    isFiltersLoading: filtersSelectors.getIsLoading(state),
    teamStatistics: teamsSelectors.getData(state),
    isInitialTeamsPageLoading: teamsSelectors.getIsInitialPageLoading(state),
    isNewTeamsPageLoading: teamsSelectors.getIsNewPageLoading(state),
    limit: teamsSelectors.getLimit(state),
    count: teamsSelectors.getCount(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    teamsActions: bindActionCreators(teamsThunks, dispatch),
    commonActions: bindActionCreators(commonThunks, dispatch),
    filtersActions: bindActionCreators(filtersThunks, dispatch),
    customFilterActions: bindActionCreators(customFilterThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
