import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { appSelectors } from 'modules/app';
import { actualGamesThunks, commonThunks } from 'packages/team-summary';
import { settingsSelectors } from 'packages/settings';

const mapStateToProps = (state) => {
  return {
    timeZone: settingsSelectors.getTimeZone(state),
    mediaScreen: appSelectors.getMediaScreen(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actualGamesActions: bindActionCreators(actualGamesThunks, dispatch),
    commonActions: bindActionCreators(commonThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
