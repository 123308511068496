import React, { useRef } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { TimeZone } from 'types';

import connect from './connect';
import TeamSummaryView from './views';
import styles from './styles.scss';

type Props = {
  actualGamesActions: ActionCreatorsMapObject;
  commonActions: ActionCreatorsMapObject;
  timeZone: TimeZone;
};

const extendActualEvents = (TeamSummary: typeof TeamSummaryView[keyof typeof TeamSummaryView]) => {
  return ({ actualGamesActions, commonActions, timeZone }: Props) => {
    const containerRef = useRef<HTMLDivElement>(null);

    return (
      <div ref={containerRef} className={styles.teamSummary}>
        <TeamSummary
          containerRef={containerRef}
          actualGamesActions={actualGamesActions}
          commonActions={commonActions}
          timeZone={timeZone}
        />
      </div>
    );
  };
};

export default {
  Desktop: connect(extendActualEvents(TeamSummaryView.Desktop)),
  DesktopNarrow: connect(extendActualEvents(TeamSummaryView.DesktopNarrow)),
  Tablet: connect(extendActualEvents(TeamSummaryView.Tablet)),
  TabletNarrow: connect(extendActualEvents(TeamSummaryView.TabletNarrow)),
  Mobile: connect(extendActualEvents(TeamSummaryView.Mobile)),
};
