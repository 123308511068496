exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3eYPi1r5{display:flex}._3eYPi1r5 ._3mgyFp-U{display:flex;flex-direction:column;justify-content:space-between}._3eYPi1r5 ._3mgyFp-U ._iDZFkH9A{display:flex}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/modules/TopPlayers/components/LoadingSkeleton/components/PlayerCellSkeleton/styles.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CAFjB,sBAKM,YAAa,CACb,qBAAsB,CACtB,6BAA8B,CAPpC,iCAUQ,YAAa","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .container {\n    display: flex;\n\n    .details {\n      display: flex;\n      flex-direction: column;\n      justify-content: space-between;\n\n      .additional-info {\n        display: flex;\n      }\n    }\n  }\n}"]}]);

// Exports
exports.locals = {
	"container": "_3eYPi1r5",
	"details": "_3mgyFp-U",
	"additional-info": "_iDZFkH9A",
	"additionalInfo": "_iDZFkH9A"
};