import getPath from './getPath';
import { EventStatus } from '../../types';

export const getStatus = (state): EventStatus => {
  return getPath(state).status;
};

export const getIsInitialLoading = (state): boolean => {
  return getStatus(state).isInitialLoading;
};

export const getIsMoreLoading = (state): boolean => {
  return getStatus(state).isMoreLoading;
};

export const getCount = (state): number => {
  return getStatus(state).count;
};

export const getLimit = (state): number => {
  return getStatus(state).limit;
};

export const getOffset = (state): number => {
  return getStatus(state).offset;
};
