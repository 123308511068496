import React from 'react';

import { MediaScreen } from 'types';
import { Grid } from 'modules/shared/layouts';

import { schemaDesktop } from './schema';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  mediaScreen: MediaScreen;
};

const ComingSoon = ({ mediaScreen }: Props) => {
  switch (mediaScreen) {
    case MediaScreen.Mobile:
    case MediaScreen.TabletNarrow:
    case MediaScreen.Tablet:
    default:
      return (
        <Grid.DoubleColumn
          isSecondaryOpen={false}
          leftColumnClassName={styles.leftColumn}
          rightColumnClassName={styles.rightColumn}
        >
          {schemaDesktop()}
        </Grid.DoubleColumn>
      );
  }
};

export default connect(ComingSoon);
