export enum CommonTableParameter {
  GamesPlayed = 'GAMES_PLAYED',
  Goals = 'GOALS',
  Assists = 'ASSISTS',
  GoalPoints = 'GOAL_POINTS',
  TotalShots = 'TOTAL_SHOTS',
  ShotsOnGoal = 'SHOTS_ON_GOAL',
  ShotsOnGoalPerGame = 'SHOTS_ON_GOAL_PER_GAME',
  Offside = 'OFFSIDE',
  YellowCards = 'YELLOW_CARDS',
  YellowRedCards = 'YELLOW_RED_CARDS',
  RedCards = 'RED_CARDS',
  PenaltySuccessCount = 'PENALTY_SUCCESS_COUNT',
  GoalsByHead = 'GOALS_BY_HEAD',
  GoalAttempts = 'GOAL_ATTEMPTS',
  BallPossession = 'BALL_POSSESSION',
  Average = 'AVERAGE',
  Occupancy = 'OCCUPANCY',
  Capacity = 'CAPACITY',
  Win = 'WIN',
  Draw = 'DRAW',
  Loss = 'LOSS',
  GoalDifference = 'GOAL_DIFFERENCE',
  Points = 'POINTS',
}
