import { favoritesThunks } from 'packages/favorites';
import { settingsSelectors } from 'packages/settings';
import { FavoritesTableIndex } from 'configure/storage/types';

import { favoriteEventsActions } from '../../actions';

interface Params {
  categoryId?: number;
}

const loadLatestFavoriteEvents = ({ categoryId }: Params = {}) => async (dispatch, getState) => {
  try {
    dispatch(favoriteEventsActions.loadLatestFavoriteEventsStart());

    const state = getState();
    const activeDate = settingsSelectors.getActiveDate(state);

    const activeFavoriteEvents = await dispatch(
      favoritesThunks.loadActiveFavoritesFromStorage({
        [FavoritesTableIndex.Date]: activeDate,
        [FavoritesTableIndex.CategoryId]: categoryId,
      }),
    );

    dispatch(favoriteEventsActions.loadLatestFavoriteEventsSuccess(activeFavoriteEvents));
  } catch (error) {
    dispatch(favoriteEventsActions.loadLatestFavoriteEventsFailure(error));
  }
};

export default loadLatestFavoriteEvents;
