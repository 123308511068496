import React from 'react';
import { NavLink } from 'react-router-dom';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { Icon } from 'packages/shared/components';

import styles from './styles.scss';
import { LINK_LIST } from './constants';

const FooterNavigation = () => {
  return (
    <nav className={styles.navigation}>
      {LINK_LIST.map(({ id, to, end, label, iconId }) => (
        <NavLink
          key={id}
          className={({ isActive }) => classnames({ [styles.active]: isActive })}
          to={to}
          end={end}
        >
          <Icon id={iconId} />
          <span>{i18n.value(label)}</span>
        </NavLink>
      ))}
    </nav>
  );
};

export default FooterNavigation;
