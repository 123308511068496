import React, { memo, useMemo } from 'react';
import classnames from 'classnames';

import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { Interfaces as PlayerSummaryInterfaces } from 'packages/player-summary/types';
import { Tabs } from 'packages/shared/components';

import { TabsNavigationLabel } from './components';
import { StatisticsBlock, StatisticsHeaderSkeleton } from '../../../../components';
import { mapStatisticsGroupsToTab } from './services';
import styles from './styles.scss';

type Props = {
  playerSummaryStatistics: PlayerSummaryInterfaces.Statistics.Statistics.Data;
  isWholeStatisticsLoading: boolean;
  isTeamsLoading: boolean;
  className?: string;
};

const Statistics = ({
  playerSummaryStatistics,
  isWholeStatisticsLoading,
  className,
  isTeamsLoading,
}: Props) => {
  const tabs = useMemo(() => mapStatisticsGroupsToTab(playerSummaryStatistics), [
    playerSummaryStatistics,
  ]);

  return (
    <div className={classnames(styles.statisticsBlock, className)}>
      {isWholeStatisticsLoading || isTeamsLoading ? (
        <>
          <StatisticsHeaderSkeleton />
          <StatisticsBlock isInitialLoading />
        </>
      ) : (
        <Tabs
          navigationLabel={<TabsNavigationLabel />}
          className={styles.tabs}
          tabs={tabs}
          theme={NavigationTheme.Button}
          contentComponent={StatisticsBlock}
          shouldComponentBeRenderedInAnyCase
          isNavigationWidthLimited
        />
      )}
    </div>
  );
};

export default memo(Statistics);
