import { favoritesThunks } from 'packages/favorites';

import loadLatestFavoriteEvents from './loadLatestFavoriteEvents';

interface Params {
  categoryId?: number;
}

const observeFavorites = ({ categoryId }: Params = {}) => (dispatch) => {
  return dispatch(
    favoritesThunks.observeActiveFavorites(async () =>
      dispatch(loadLatestFavoriteEvents({ categoryId })),
    ),
  );
};

export default observeFavorites;
