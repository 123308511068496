import React from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import styles from './styles.scss';

type Props = {
  className?: string;
};

const Footer = ({ className }: Props) => {
  return (
    <footer className={classnames(styles.footer, className)}>
      <span className={styles.text}>
        <span role="img">©</span> 2019 GameScore
      </span>
      <a href="#" className={styles.infoLink}>
        {i18n.value('common.information.termsOfUse')}
      </a>
      <a href="#" className={styles.infoLink}>
        {i18n.value('common.information.privacyPolicy')}
      </a>
    </footer>
  );
};

export default React.memo(Footer);
