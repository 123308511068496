import React from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { Icon } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import styles from './styles.scss';
import { AdditionalInfoParams, GoalHappeningViewTypes, Theme } from './types';
import { GOAL_ICON_MAP, THEMING_GOAL_TYPES } from './constants';

const getPenaltyDescriptionValue = (goalType) =>
  i18n.value(`eventSummary.timeLine.goalHappeningDescription.${GoalHappeningViewTypes[goalType]}`);

const getAdditionalInfoElement = ({
  isPenaltyGoal,
  goalType,
  assistId,
  assistName,
  handlePlayerClick,
}: AdditionalInfoParams) => {
  if (isPenaltyGoal && goalType !== GoalHappeningViewTypes.NotKicked) {
    return (
      <span className={styles.description}>{`(${getPenaltyDescriptionValue(goalType)})`}</span>
    );
  } else if (assistName) {
    return (
      <span
        onClick={handlePlayerClick(assistId)}
        className={classnames(styles.description, { [styles.clickable]: assistId !== undefined })}
      >{`(${assistName})`}</span>
    );
  } else {
    return <></>;
  }
};

type Props = {
  scorerName?: string;
  assistName?: string;
  scorerId?: number;
  assistId?: number;
  type?: GoalHappeningViewTypes;
  isIconFirst?: boolean;
  isPenaltyGoal?: boolean;
  theme?: Theme;
};

const GoalLabel = ({
  scorerName = '',
  assistName = '',
  scorerId,
  assistId,
  type = GoalHappeningViewTypes.Goal,
  isIconFirst = false,
  theme = Theme.Light,
  isPenaltyGoal = false,
}: Props) => {
  const { appNavigate } = useNavigation();

  const containerClasses = classnames(styles.goalLabel, styles[theme], {
    [styles.iconFirst]: isIconFirst,
  });

  const handlePlayerClick = (playerId?: number) => () => {
    if (playerId) {
      appNavigate(DesktopPage.FootballPlayer, { playerId });
    }
  };

  return (
    <div className={containerClasses}>
      <div className={styles.details}>
        {scorerName && (
          <div>
            <span
              onClick={handlePlayerClick(scorerId)}
              className={classnames(styles.playerName, {
                [styles.clickable]: scorerId !== undefined,
              })}
            >
              {scorerName}
            </span>
            {getAdditionalInfoElement({
              isPenaltyGoal,
              goalType: type,
              handlePlayerClick,
              assistName,
              assistId,
            })}
          </div>
        )}
      </div>
      <Icon
        id={GOAL_ICON_MAP[type]}
        className={classnames(styles.icon, {
          [styles[theme]]: THEMING_GOAL_TYPES.includes(type),
        })}
      />
    </div>
  );
};

export default React.memo(GoalLabel);
