exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._ybtM7Ov0{position:absolute;top:0;left:0;display:flex;flex-direction:column;min-width:100%;max-width:100%;min-height:100%;max-height:100%}._ybtM7Ov0 ._3a1e9ppK,._ybtM7Ov0 ._2mv9UDi0{z-index:2}._ybtM7Ov0 ._3NTbWZnX{flex:1 1;flex-basis:0;z-index:1}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/team-player-statistics-ui/containers/PlayerStatistics/views/Mobile/components/MainScreen/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/size.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,iBAAkB,CAClB,KAAM,CACN,MAAO,CAEP,YAAa,CACb,qBAAsB,CCRxB,cDU0B,CCT1B,cDS0B,CCNxB,eDMwB,CCLxB,eDKwB,CAT5B,4CAYM,SAAU,CAZhB,sBAgBM,QAAO,CACP,YAAa,CACb,SAAU","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .main-screen-container {\n    position: absolute;\n    top: 0;\n    left: 0;\n\n    display: flex;\n    flex-direction: column;\n\n    @include fixed-size(100%);\n\n    .control-header, .pagination-container {\n      z-index: 2;\n    }\n\n    .statistics-table {\n      flex: 1;\n      flex-basis: 0;\n      z-index: 1;\n    }\n  }\n}\n","@mixin fixed-size($width, $height: null) {\n  min-width: $width;\n  max-width: $width;\n\n  @if ($height == null) {\n    min-height: $width;\n    max-height: $width;\n  } @else {\n    min-height: $height;\n    max-height: $height;\n  }\n}"]}]);

// Exports
exports.locals = {
	"main-screen-container": "_ybtM7Ov0",
	"mainScreenContainer": "_ybtM7Ov0",
	"control-header": "_3a1e9ppK",
	"controlHeader": "_3a1e9ppK",
	"pagination-container": "_2mv9UDi0",
	"paginationContainer": "_2mv9UDi0",
	"statistics-table": "_3NTbWZnX",
	"statisticsTable": "_3NTbWZnX"
};