import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';
import { SpinnerSize } from './types';

type Props = {
  className?: string,
  size?: SpinnerSize
}
const Spinner = ({ className, size = SpinnerSize.Big }: Props) => (
  <div className={classnames(styles.spinner, className, styles[size])} />
);

export default Spinner;
