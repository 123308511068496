import { Row } from 'react-table';

import { Player, PlayerStatistics } from 'packages/season-player-statistics/types';

type Params = {
  onClick: (player: Player) => void;
};

const getRowProps = ({ onClick }: Params) => ({ original }: Row<PlayerStatistics>) => {
  const { player } = original;

  return {
    onClick: () => {
      onClick(player);
    },
  };
};

export default getRowProps;
