import { getGoalPlayersDetails } from './getGoalPlayersDetails';
import { BaseHappening, GoalHappening, HappeningMethod } from '../../types';

const mapGoalHappening = (happening: BaseHappening): GoalHappening => {
  const { id, team, eventType, minute, players, additionalInfo, injuryTime, ...rest } = happening;
  const { scorerName, assistName, scorerId, assistId } = getGoalPlayersDetails(players);
  const isOwnGoal = additionalInfo?.method === HappeningMethod.OwnGoal;
  const isPenaltyGoal = additionalInfo?.method === HappeningMethod.Penalty;

  return {
    ...rest,
    id,
    scorerId,
    assistId,
    team,
    players,
    eventType,
    minute,
    scorerName,
    assistName,
    isOwnGoal,
    isPenaltyGoal,
    injuryTime,
  };
};

export default mapGoalHappening;
