import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import Team from '../Team';
import TeamCompact from '../TeamCompact';
import styles from '../styles.scss';

type Props = {
  isCompact?: boolean;
  isTournamentVisible?: boolean;
  isMatchResultVisible?: boolean;
};

const BaseEvent = ({
  isCompact = false,
  isTournamentVisible = false,
  isMatchResultVisible = false,
}: Props) => {
  if (isCompact) {
    return (
      <div className={styles.compactEventWrapper}>
        {isTournamentVisible && (
          <div className={styles.tournament}>
            <Skeleton width={16} height={16} variant="circular" marginRight={8} />
            <Skeleton height={12} width={70} variant="rectangular" />
          </div>
        )}

        <div className={styles.event}>
          <div>
            <div>
              <Skeleton variant="rectangular" width={62} height={14} />
              {isMatchResultVisible && (
                <Skeleton
                  marginLeft={4}
                  minWidth={20}
                  width={20}
                  minHeight={20}
                  height={20}
                  variant="circular"
                />
              )}
            </div>
            <Skeleton width={20} height={20} variant="rectangular" />
          </div>

          <div className={styles.teamsWrapper}>
            <div className={styles.teamsContainer}>
              <TeamCompact />
              <TeamCompact />
            </div>

            <div className={styles.score}>
              <Skeleton height={14} width={8} variant="rectangular" />
              <Skeleton height={14} width={8} variant="rectangular" />
            </div>
          </div>

          <div>
            <Skeleton variant="circular" height={14} minHeight={14} minWidth={14} width={14} />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={styles.eventWrapper}>
      {isTournamentVisible && (
        <div className={styles.tournament}>
          <Skeleton width={16} height={16} variant="circular" marginRight={8} />
          <Skeleton height={12} width={70} variant="rectangular" />
        </div>
      )}

      <div className={styles.event}>
        <div className={styles.left}>
          <Skeleton minWidth={62} width={62} height={14} variant="rectangular" marginRight={16} />
          <Skeleton minWidth={20} width={20} height={20} minHeight={20} variant="rectangular" />

          <Team />
        </div>

        <div className={styles.score}>
          <Skeleton width="80%" height={12} variant="rectangular" />
        </div>

        <div className={styles.right}>
          <Team isReversed />

          <Skeleton width={38} height={14} variant="rectangular" />

          {isMatchResultVisible && (
            <Skeleton
              marginLeft={16}
              minWidth={20}
              width={20}
              minHeight={20}
              height={20}
              variant="circular"
            />
          )}

          <Skeleton width={60} height={14} variant="rectangular" marginLeft={16} />
        </div>
      </div>
    </div>
  );
};

export default memo(BaseEvent);
