import { Player, PlayerType } from '../../types';

export const getGoalPlayersDetails = (players?: Player[]) => {
  const scorer = players?.find((player) => player.playerType === PlayerType.Scorer);
  const assist = players?.find((player) => player.playerType === PlayerType.Assist);

  return {
    scorerName: scorer?.name || scorer?.shortName,
    assistName: assist?.name || assist?.shortName,
    scorerId: scorer?.playerId,
    assistId: assist?.playerId,
  };
};
