import React, { memo } from 'react';
import classnames from 'classnames';

import { Chip, Icon } from 'packages/shared/components';
import { CARD_ICON_TYPE } from 'appConstants';

import EventTime from '../EventTime';
import styles from '../styles.scss';

export type Props = {
  eventType?: string;
  minute: string;
  injuryTime?: string;
};

const Card = ({ minute, eventType, injuryTime }: Props) => {
  const cardIconId = eventType ? CARD_ICON_TYPE[eventType] : undefined;

  return cardIconId ? (
    <Chip className={classnames(styles.chip, styles.extended)}>
      <EventTime minute={minute} injuryTime={injuryTime} />
      <Icon id={cardIconId} />
    </Chip>
  ) : (
    <></>
  );
};

export default memo(Card);
