import { isEmpty } from 'packages/utils/Object.utils';
import { SummaryHappeningType } from 'packages/events/types';

import { BaseHappening, CardHappening, CardColor } from '../../types';
import { ON_BENCH_TIME_DESCRIPTION } from '../../constants';

const mapCardHappening = (happening: BaseHappening): CardHappening => {
  const { players, eventType, team, minute, additionalInfo, id, injuryTime } = happening;
  let playerName;

  if (players && !isEmpty(players)) {
    const [{ name, shortName }] = players;

    playerName = name || shortName;
  }

  let color: CardColor;

  if (eventType === SummaryHappeningType.YELLOW_CARD) {
    color = CardColor.Yellow;
  } else if (eventType === SummaryHappeningType.RED_CARD) {
    color = CardColor.Red;
  } else {
    color = CardColor.YellowRed;
  }

  return {
    eventType,
    team,
    color,
    playerName,
    minute,
    id,
    players,
    isTookOnTheBench: additionalInfo?.timeDescription === ON_BENCH_TIME_DESCRIPTION,
    injuryTime,
  };
};

export default mapCardHappening;
