import { handleActions, ReducerMap, combineActions } from 'redux-actions';

import { SeasonEventsStatus } from '../../types';
import * as actions from '../../actions';
import { DEFAULT_EVENTS_LIMIT } from '../../constants';

const defaultState: SeasonEventsStatus = {
  isInitialLoading: true,
  isMoreLoading: false,
  count: 0,
  offset: 0,
  limit: DEFAULT_EVENTS_LIMIT,
};

const reducerMap: ReducerMap<SeasonEventsStatus, Partial<SeasonEventsStatus>> = {
  [`${actions.loadInitialEventsStart}`]: (state) => {
    return {
      ...state,
      isInitialLoading: true,
    };
  },

  [`${actions.loadInitialEventsSuccess}`]: (state, { payload }) => {
    const { count = 0 } = payload;

    return {
      ...state,
      count,
      isInitialLoading: false,
    };
  },

  [`${actions.loadMoreEventsFailure}`]: (state) => {
    return {
      ...state,
      isInitialLoading: false,
    };
  },

  [`${actions.loadMoreEventsStart}`]: (state) => {
    return {
      ...state,
      isMoreLoading: true,
    };
  },

  [`${actions.loadMoreEventsSuccess}`]: (state, { payload }) => {
    const { count = 0 } = payload;

    return {
      ...state,
      count,
      isMoreLoading: false,
    };
  },

  [`${actions.loadMoreEventsFailure}`]: (state) => {
    return {
      ...state,
      isMoreLoading: false,
    };
  },

  [`${actions.updatePaginationSuccess}`]: (state, { payload }) => {
    const { limit = 0, offset = 0 } = payload;

    return {
      ...state,
      limit,
      offset,
    };
  },

  [`${actions.clearSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
