import { Category, Tournament } from 'types';

import { getPath } from '../common';
import { CategoryModalData } from '../../types';

export const getData = (state): CategoryModalData => {
  return getPath(state).categoryModal.data;
};

export const getCategory = (state): Category | undefined => {
  return getData(state).category;
};

export const getTournaments = (state): Tournament[] => {
  return getData(state).tournaments;
};
