import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { favoritesThunks, favoritesSelectors } from 'packages/favorites';

const mapStateToProps = (state) => {
  return {
    favoriteLeagues: favoritesSelectors.getFavoriteLeagues(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    favoritesActions: bindActionCreators(favoritesThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
