import React, { memo } from 'react';
import classnames from 'classnames';

import { MatchHistory } from 'packages/shared/components';
import { Form } from 'packages/shared/components/MatchHistory/types';
import { FORM_EMPTY_MATCHES_AMOUNT } from './constants';

import styles from './styles.scss';

type Props = {
  form?: Form[];
  isPopoverDisabled?: boolean;
  className?: string;
};

const EventForm = ({ form = [], isPopoverDisabled = false, className }: Props) => {
  return (
    <div className={classnames(styles.eventForm, className)}>
      <MatchHistory
        isPopoverDisabled={isPopoverDisabled}
        form={form}
        emptyMatchesAmount={FORM_EMPTY_MATCHES_AMOUNT}
      />
    </div>
  );
};

export default memo(EventForm);
