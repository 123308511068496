import React, { memo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { EventLoadingSkeleton } from 'packages/events-ui/components';

import styles from './styles.scss';

type Props = {
  elementsCount: number;
};

const EventListSkeleton = ({ elementsCount }: Props) => {
  const elements = ArrayUtils.getArrayFromNumber(elementsCount);

  return (
    <div className={styles.container}>
      {elements.map((element) => (
        <EventLoadingSkeleton key={element} isTournamentVisible variant="player" />
      ))}
    </div>
  );
};

export default memo(EventListSkeleton);
