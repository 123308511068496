import { SocketStatus, SocketSelectors } from '../types';

export const getLocalSelectors = (localStateKey: string, getOuterState): SocketSelectors => {
  const getState = (state) => {
    return getOuterState(state)[localStateKey];
  };

  const getVersion = (state) => {
    return getState(state).version;
  };

  const getStatus = (state) => {
    return getState(state).status;
  };

  const getIsConnectionOpen = (state) => {
    return getStatus(state) === SocketStatus.Open;
  };

  const getSocketData = (state) => {
    return getState(state).data;
  };

  const getSocketError = (state) => {
    return getState(state).error;
  };

  const getIsSocketDataLoaded = (state) => {
    return !!getVersion(state) && !getSocketError(state);
  };

  return {
    getVersion,
    getStatus,
    getIsConnectionOpen,
    getSocketData,
    getIsSocketDataLoaded,
    getSocketError,
  };
};
