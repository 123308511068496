exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._29HPLdNc{flex:1 1;margin-right:16px}._1ndq8oY8{width:443px}._2CaHZxpp{flex:1 1;display:flex;align-items:flex-start;justify-content:center}._3EyKoGID{position:relative;display:flex;flex-direction:column;height:100%;width:100%}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktopNarrow/football/PlayerGames/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,WAAY,CAPhB,WAWI,QAAO,CACP,YAAa,CACb,sBAAuB,CACvB,sBAAuB,CAd3B,WAkBI,iBAAkB,CAClB,YAAa,CACb,qBAAsB,CACtB,WAAY,CACZ,UAAW","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n  }\n\n  .side-bar {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_29HPLdNc",
	"leftColumn": "_29HPLdNc",
	"right-column": "_1ndq8oY8",
	"rightColumn": "_1ndq8oY8",
	"left-bar": "_2CaHZxpp",
	"leftBar": "_2CaHZxpp",
	"side-bar": "_3EyKoGID",
	"sideBar": "_3EyKoGID"
};