import Desktop from './Desktop';
import DesktopNarrow from './DesktopNarrow';
import Mobile from './Mobile';

export default {
  Desktop,
  DesktopNarrow: DesktopNarrow,
  Tablet: Desktop,
  TabletNarrow: Mobile,
  Mobile: Mobile,
};
