import getState from './getState';

export const getSettingsState = (state) => {
  return getState(state).state;
};

export const getActiveDate = (state) => {
  return getSettingsState(state).activeDate;
};

export const getEventsOrderType = (state) => {
  return getSettingsState(state).activeDate;
};
