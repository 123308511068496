import React, { ReactNode, useRef, useState, memo, useMemo } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { IconType } from 'packages/shared/components/Icon/types';
import { Icon } from 'packages/shared/components';

import { Theme } from './types';
import styles from './styles.scss';
import { useContentHeight } from './hooks';

type Props = {
  isInitiallyOpen?: boolean;
  children: ReactNode | ReactNode[];
  theme?: keyof typeof Theme;
  className?: string;
  showMoreLabel?: string;
  showLessLabel?: string;
};

const ExpandableBlock = ({
  isInitiallyOpen = false,
  showMoreLabel = i18n.value('common.expandableBlock.label.default.showMore'),
  showLessLabel = i18n.value('common.expandableBlock.label.default.showLess'),
  className = styles.defaultSize,
  theme = Theme.primary,
  children,
}: Props) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(isInitiallyOpen);
  const contentHeight = useContentHeight({ contentRef, children });

  const style = isOpen ? { maxHeight: `${contentHeight}px` } : {};

  const isExpandButtonVisible = useMemo(() => {
    if (wrapperRef?.current) {
      return wrapperRef.current.getBoundingClientRect().height < contentHeight;
    }

    return false;
  }, [contentHeight]);

  const toggleOpen = () => {
    setIsOpen((prevState) => !prevState);
  };

  return (
    <div className={classnames(styles.expandableBlock, styles[theme])}>
      <div
        ref={wrapperRef}
        style={style}
        className={classnames(styles.contentWrapper, { [className]: !isOpen })}
      >
        <div ref={contentRef}>{children}</div>
      </div>

      {isExpandButtonVisible && (
        <div className={classnames(styles.footer, { [styles.open]: isOpen })}>
          <div className={styles.controlsWrapper} onClick={toggleOpen}>
            <span>{isOpen ? showLessLabel : showMoreLabel}</span>
            <Icon
              className={classnames(styles.arrowIcon, { [styles.closed]: !isOpen })}
              id={IconType.Arrow}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(ExpandableBlock);
