import { Ordering, SquadOrdering } from 'packages/rest/api/TeamSquad/types';
import { OrderType } from 'types';

interface Params {
  orderingType: Ordering;
  currentOrdering: SquadOrdering;
}

const getOrderingByType = ({ orderingType, currentOrdering }: Params): SquadOrdering => {
  const { name, type } = currentOrdering;

  if (name === orderingType) {
    return {
      name,
      type: type === OrderType.Descending ? OrderType.Ascending : OrderType.Descending,
    };
  }

  return { name: orderingType, type: OrderType.Descending };
};

export default getOrderingByType;
