import { configure } from 'packages/rest';
import { API_CALL_TIMEOUT } from 'appConstants';

const headers = {
  ['Content-Type']: 'application/json',
};

export default () => {
  configure({
    headers,
    baseURL: getBaseURL(),
    timeout: API_CALL_TIMEOUT,
  });
};

const getBaseURL = () => {
  const protocol = process.env.API_PROTOCOL || 'http';
  const host = process.env.API_URL ? process.env.API_URL : window.location.host;
  const version = process.env.API_VERSION ? `/${process.env.API_VERSION}` : '';

  return `${protocol}://${host}${version}`;
};
