import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { statisticsActions, commonActions } from '../../../../actions';
import { Interfaces } from '../../../../types';

const defaultState: Interfaces.Statistics.TournamentSeasons.Data = {};

const reducerMap: ReducerMap<
  Interfaces.Statistics.TournamentSeasons.Data,
  Interfaces.Statistics.TournamentSeasons.Data
> = {
  [`${statisticsActions.tournamentSeasons.loadTournamentSeasonsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${statisticsActions.tournamentSeasons.setTournamentSeasonsSuccess}`]: (state, { payload }) => {
    const { selectedSeasonId, selectedTournamentId } = payload;

    return { ...state, selectedSeasonId, selectedTournamentId };
  },
  [`${combineActions(
    statisticsActions.tournamentSeasons.loadTournamentSeasonsFailure,
    statisticsActions.tournamentSeasons.loadTournamentSeasonsStart,
    statisticsActions.tournamentSeasons.cleanTournamentSeasonsSuccess,
    commonActions.cleanSummarySuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
