import React, { memo, useMemo } from 'react';

import { Separator, Skeleton } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';

import styles from './styles.scss';

const ELEMENTS_COUNT = 30;

const LoadingSkeleton = () => {
  const elements = useMemo(() => ArrayUtils.getArrayFromNumber(ELEMENTS_COUNT), []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Skeleton variant="rectangular" width={16} height={16} marginRight={8} />
        <Skeleton variant="rectangular" height={16} width={120} />
        <Skeleton variant="circular" width={16} height={16} marginLeft="auto" />
      </div>

      <Separator />
      <Skeleton
        marginRight={16}
        marginLeft={16}
        marginTop={16}
        marginBottom={16}
        variant="rectangular"
        height={12}
        width={150}
      />
      <Separator />

      <div className={styles.tournamentsBlock}>
        {elements.map((element) => (
          <div key={element}>
            <Skeleton variant="rectangular" height={12} width={90} />
            <Skeleton marginLeft="auto" variant="circular" width={16} height={16} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
