import getPath from './getPath';
import { Stages } from '../../types';

const getStatus = (state): Stages.Status => {
  return getPath(state).status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
