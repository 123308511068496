import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { SearchQuery } from 'router/types';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { SeasonStadiums } from 'packages/season-stadiums-ui';

import styles from './styles.scss';

const SeasonGames = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  return (
    <Grid.SingleColumn isSecondaryOpen={!!eventId}>
      <div key={GridColumnId.Primary} className={styles.primary}>
        <SeasonStadiums.Mobile />
      </div>
      <></>
    </Grid.SingleColumn>
  );
};

export default SeasonGames;
