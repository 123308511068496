import { toCamelCase } from 'packages/utils/Object.utils';
import { mapStatistics } from 'packages/player-summary/services';
import { TEAM_DEFAULT_LOGO } from 'appConstants';
import { Country } from 'types';

import { Data, PlayerTeam, SocialMedia } from '../types';

const mapDetails = (
  details: Record<string, any>,
): Omit<NonNullable<Data>, 'actualTournamentSeasons'> => {
  const {
    id,
    name,
    logo,
    dateOfBirth,
    height,
    weight,
    preferredFoot,
    marketValue,
    facebook,
    twitter,
    youtube,
    country,
    secondCountry,
    position,
    activeTeams,
    statistics,
  } = toCamelCase(details, true);

  const playerTeams = mapPlayerTeams(activeTeams);

  return {
    id,
    name,
    logo,
    dateOfBirth,
    height,
    weight,
    preferredFoot,
    marketValue,
    position: position?.name,
    statistics: statistics ? mapStatistics(statistics) : undefined,
    socialMedia: flushSocialMedia({ facebook, twitter, youtube }),
    country: country ? mapCountry(country) : undefined,
    secondCountry: secondCountry ? mapCountry(secondCountry) : undefined,
    mainPlayerTeam: getMainPlayerTeam(playerTeams),
    playerTeams: playerTeams,
  };
};

const mapPlayerTeams = (playerTeams: Record<string, any>[]): PlayerTeam[] => {
  return playerTeams.map(({ id, jerseyNumber, team }) => {
    const { id: teamId, name, logo, homeJersey, isCountry, suffix } = team;

    return {
      id,
      jerseyNumber,
      jersey: homeJersey,
      isCountry,
      team: {
        id: teamId,
        name: suffix ? `${name} ${suffix}` : name,
        logo: logo || TEAM_DEFAULT_LOGO,
      },
    };
  });
};

const flushSocialMedia = (socialMedia: SocialMedia): SocialMedia => {
  return Object.entries(socialMedia).reduce(
    (acc, [currentSocialMediaKey, currentSocialMediaValue]) => {
      if (currentSocialMediaValue) {
        acc[currentSocialMediaKey] = currentSocialMediaValue;
      }

      return acc;
    },
    {},
  );
};

const mapCountry = (country: Record<string, any>): Country => {
  const { id, name, logo } = country;

  return {
    id,
    name,
    logo,
  };
};

const getMainPlayerTeam = (playerTeams: PlayerTeam[]): PlayerTeam => {
  return playerTeams.find(({ isCountry }) => !isCountry) || playerTeams[0];
};

export default mapDetails;
