import { loadEventById } from 'packages/rest/api/Events';

import { mapEvents } from '../../services';

const getUpdatedRemovedEvents = (data) => async () => {
  const updatedEvents = await Promise.all(
    Object.keys(data).map(async (eventId) => {
      const { data } = await loadEventById({ eventId });

      return data.results;
    }),
  );

  return mapEvents(updatedEvents).reduce((acc, curr) => ({ ...acc, [curr.id]: curr }), {});
};

export default getUpdatedRemovedEvents;
