import { toCamelCase } from 'packages/utils/Object.utils';
import { mapEvent } from 'packages/events/services';

import getMetaData from './getMetaData';

const mapEventDetails = (eventDetails: any) => {
  const { sportEvent, firstLeg, aggregatedScore: aggregationScore } = toCamelCase(eventDetails);

  const meta = getMetaData(sportEvent);
  const mappedEvent = mapEvent(sportEvent);
  const mappedEventSeries = mapEventSeries({ firstLeg, aggregationScore });

  return { event: mappedEvent, eventSeries: mappedEventSeries, meta };
};

export const mapEventSeries = (eventSeries) => {
  const { firstLeg, aggregationScore } = toCamelCase(eventSeries);

  return {
    firstLeg: firstLeg ? mapEvent(firstLeg) : undefined,
    aggregationScore,
  };
};

export default mapEventDetails;
