import React, {
  ElementType,
  memo,
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import classnames from 'classnames';

import { HandlingEventType } from 'packages/shared/components/OutsideClickHandler/types';
import { OutsideClickHandler, SimplePortal } from 'packages/shared/components';
import { isBlockContainingClickCoordinates } from 'packages/utils/Document.utils';

import { getScrollParent } from 'packages/utils/Scroll.utils';
import {
  PopoverAlign,
  PopoverCustomPosition,
  PopoverInvokeTrigger,
  PopoverJustify,
  PopoverState,
} from './types';
import { usePopoverPosition } from './hooks';
import Popover from './views/Popover';
import { MODAL_CONTAINER_ID, POPOVER_CONTAINER_ID } from './constants';
import styles from './styles.scss';

type Props = {
  popoverProps?: any;
  targetRef?: RefObject<HTMLDivElement>;
  popoverComponent: ElementType;
  children?: ReactElement;
  justify?: PopoverJustify;
  align?: PopoverAlign;
  closeOnClickOutside?: boolean;
  clickOutsideEventType?: HandlingEventType;
  closeOnScroll?: boolean;
  isModal?: boolean;
  useBackdrop?: boolean;
  isOpenInitially?: boolean;
  invokeTrigger?: PopoverInvokeTrigger;
  customPosition?: PopoverCustomPosition;
  onClose?: Function;
  className?: string;
  isDisabled?: boolean;
  useChildrenWrapper?: boolean;
  clickOutsideException?: RefObject<HTMLElement>;
  isCloseableOnResize?: boolean;
  scrollElementRef?: RefObject<HTMLElement>;
  useScrollIntoPopoverView?: boolean;
  disableBodyOverflow?: boolean;
  disableTouchMoveEvent?: boolean;
  boundedByParent?: boolean;
  useOverlay?: boolean;
  isOpen?: boolean;
  preserveOnTargetInteraction?: boolean;
};

const FlexiblePopover = ({
  customPosition,
  children,
  popoverProps = {},
  targetRef,
  isOpenInitially = false,
  closeOnClickOutside = false,
  closeOnScroll = true,
  isModal = false,
  useBackdrop = false,
  useChildrenWrapper = false,
  align = PopoverAlign.Top,
  justify = PopoverJustify.Right,
  invokeTrigger = PopoverInvokeTrigger.Click,
  popoverComponent: PopoverComponent,
  isDisabled = false,
  onClose = () => {},
  clickOutsideEventType,
  className,
  clickOutsideException,
  isCloseableOnResize = true,
  scrollElementRef,
  useScrollIntoPopoverView = false,
  disableBodyOverflow = false,
  disableTouchMoveEvent = false,
  boundedByParent = false,
  useOverlay = invokeTrigger === PopoverInvokeTrigger.Click,
  isOpen,
  preserveOnTargetInteraction,
}: Props) => {
  const childrenRef: RefObject<HTMLDivElement> = useRef(null);
  const popoverRef: RefObject<HTMLDivElement> = useRef(null);

  const [isLocalOpen, setIsLocalOpen] = useState(false);
  const controlledFromOutside = isOpen !== undefined;
  const isActualOpen = controlledFromOutside ? isOpen! : isLocalOpen;

  const getParent = useCallback(() => getScrollParent(childrenRef.current), []);

  const [popoverPositionStyle, calculatePopoverPosition, dropPopoverPosition] = usePopoverPosition({
    popoverRef,
    getParent,
    targetRef: targetRef || childrenRef,
    customPosition,
    isModal,
    boundedByParent,
  });

  useEffect(() => {
    if (isActualOpen) {
      calculatePopoverPosition(justify, align);

      if (!isModal && closeOnScroll) {
        return addClosePopoverHandlers();
      }
    }
  }, [isActualOpen]);

  const addClosePopoverHandlers = () => {
    if (isCloseableOnResize) {
      window.addEventListener('resize', closePopover);
    }

    setTimeout(() => getParent?.()?.addEventListener('scroll', closePopover));

    return () => {
      if (isCloseableOnResize) {
        window.removeEventListener('resize', closePopover);
      }

      getParent?.()?.removeEventListener('scroll', closePopover);
    };
  };

  const openPopover = () => {
    if (!controlledFromOutside && !isActualOpen && !isDisabled) {
      if (useScrollIntoPopoverView) {
        scrollIntoPopoverView();
      }

      setTimeout(() => setIsLocalOpen(true));
    }
  };

  const scrollIntoPopoverView = () => {
    const scrollElement = scrollElementRef?.current || childrenRef.current;
    scrollElement?.scrollIntoView();
  };

  const closePopover = () => {
    if (!controlledFromOutside && isActualOpen) {
      setIsLocalOpen(false);
      dropPopoverPosition();
    }

    onClose?.();
  };

  useEffect(() => {
    if (isOpenInitially) {
      openPopover();
    }
  }, []);

  const generatePopoverComponent = (enhancedPopoverComponent) => {
    return isActualOpen ? (
      <SimplePortal
        disableTouchMoveEvent={disableTouchMoveEvent}
        disableBodyOverflow={disableBodyOverflow}
        useOverlay={useOverlay}
      >
        {enhancedPopoverComponent}
      </SimplePortal>
    ) : (
      <></>
    );
  };

  const handleOutsideClick = (e) => {
    const isTriggerClick = isBlockContainingClickCoordinates(childrenRef.current, e);
    const isExceptionClick = isBlockContainingClickCoordinates(clickOutsideException?.current, e);

    if (closeOnClickOutside && !isTriggerClick && !isExceptionClick) {
      closePopover();
    }
  };

  const popoverElement = (
    <div
      id={isModal ? MODAL_CONTAINER_ID : POPOVER_CONTAINER_ID}
      style={popoverPositionStyle}
      className={classnames(styles.animation, className)}
      ref={popoverRef}
    >
      {closeOnClickOutside ? (
        <OutsideClickHandler
          useOverlay={useOverlay}
          onOutsideClick={handleOutsideClick}
          handlingEventType={clickOutsideEventType}
        >
          <PopoverComponent closePopover={closePopover} {...popoverProps} />
        </OutsideClickHandler>
      ) : (
        <PopoverComponent closePopover={closePopover} {...popoverProps} />
      )}
    </div>
  );

  return (
    <Popover
      preserveOnTargetInteraction={preserveOnTargetInteraction}
      generatePopoverComponent={generatePopoverComponent}
      openPopover={openPopover}
      closePopover={closePopover}
      popoverElement={popoverElement}
      invokeTrigger={invokeTrigger}
      isOpen={isActualOpen}
      isModal={isModal}
      useBackdrop={useBackdrop}
      popoverRef={popoverRef}
    >
      {children &&
        (useChildrenWrapper ? (
          <div ref={childrenRef}>
            {React.cloneElement(children, {
              popoverState: isActualOpen ? PopoverState.Opened : PopoverState.Closed,
            })}
          </div>
        ) : (
          React.cloneElement(children, {
            popoverState: isActualOpen ? PopoverState.Opened : PopoverState.Closed,
            ref: childrenRef,
          })
        ))}
    </Popover>
  );
};

export default memo(FlexiblePopover);
