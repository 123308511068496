import { createElement, RefObject } from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { Stadium } from 'packages/season-stadiums/types';
import { Ordering, StadiumsOrdering } from 'packages/rest/api/SeasonStadiums/types';
import { Team } from 'types';

import {
  AttendanceCell,
  ClubsCell,
  CountryLogoCell,
  SortableHeader,
  EmptyCell,
  OccupancyCell,
  NameCell,
} from '../components';

type Options = {
  forwardedRef: RefObject<HTMLDivElement>;
  ordering: StadiumsOrdering;
  onHeaderCellClick: (orderingType: Ordering) => void;
  onTeamClick: (team: Team) => void;
  isCountryNameDisplayed?: boolean;
};

const getTableSchema = ({
  forwardedRef,
  onHeaderCellClick,
  ordering,
  onTeamClick,
  isCountryNameDisplayed,
}: Options): Column<Stadium>[] => {
  return [
    {
      id: 'name',
      Header: () =>
        createElement(SortableHeader, {
          label: 'season.stadiums.column.name',
          orderingValue: Ordering.Name,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px 8px 8px 16px', textAlign: 'left' },
      cellStyle: {
        padding: '12px 8px 12px 16px',
        textAlign: 'left',
        fontWeight: 500,
        textOverflow: '',
      },
      accessor: 'name',
      Cell: ({ value: name }) => createElement(NameCell, { name, forwardedRef }),
      width: 229,
    },
    {
      id: 'countryLogo',
      Header: () =>
        createElement(SortableHeader, {
          label: 'season.stadiums.column.country',
          orderingValue: Ordering.City,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'left' },
      cellStyle: { padding: '12px 8px' },
      accessor: ({ countryLogo, city }) => ({ countryLogo, city, forwardedRef }),
      Cell: ({ value: props }) =>
        createElement(CountryLogoCell, { ...props, isNameDisplayed: isCountryNameDisplayed }),
      width: 100,
    },
    {
      id: 'constructionYear',
      Header: () =>
        createElement(SortableHeader, {
          label: 'season.stadiums.column.opening',
          orderingValue: Ordering.Opening,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'left' },
      cellStyle: { padding: '12px 8px', textAlign: 'left' },
      accessor: 'constructionYear',
      Cell: ({ value: constructionYear }) =>
        constructionYear ? constructionYear : createElement(EmptyCell),
      width: 79,
    },
    {
      id: 'capacity',
      Header: () =>
        createElement(SortableHeader, {
          label: 'season.stadiums.column.capacity',
          orderingValue: Ordering.Capacity,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'right' },
      cellStyle: { padding: '12px 8px', textAlign: 'right' },
      accessor: 'capacity',
      width: 68,
    },
    {
      id: 'avg',
      Header: () =>
        createElement(SortableHeader, {
          label: 'season.stadiums.column.avg',
          orderingValue: Ordering.Attendance,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'right' },
      cellStyle: { padding: '12px 8px' },
      accessor: ({ average }) => ({ average }),
      Cell: ({ value: props }) => createElement(AttendanceCell, props),
      width: 84,
    },
    {
      id: 'fullness',
      Header: () =>
        createElement(SortableHeader, {
          label: 'season.stadiums.column.fullness',
          orderingValue: Ordering.Fullness,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'right' },
      cellStyle: { padding: '12px 8px' },
      accessor: ({ occupancy }) => ({ occupancy }),
      Cell: ({ value: props }) => createElement(OccupancyCell, props),
      width: 70,
    },
    {
      id: 'clubs',
      Header: i18n.value('season.stadiums.column.clubs'),
      headerStyle: { padding: '8px', textAlign: 'left' },
      cellStyle: { padding: '12px 8px' },
      accessor: 'homeTeams',
      Cell: ({ value: teams }) => createElement(ClubsCell, { onTeamClick, teams, forwardedRef }),
      width: 96,
    },
  ];
};

export default getTableSchema;
