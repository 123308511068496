import React, { memo } from 'react';

import { Figure } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  logo: string;
};

const ColumnRank = ({ logo }: Props) => {
  return (
    <div className={styles.teamLogoContainer}>
      <Figure src={logo} className={styles.teamLogo} />
    </div>
  );
};

export default memo(ColumnRank);
