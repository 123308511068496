import React from 'react';
import { MediaScreen } from 'types';

import { getStickyHeaderTopOffset } from '../../services';
import { EventsTabs } from '../../components';

type Props = {
  categoryId?: string;
  className?: string;
};

const Mobile = ({ className, categoryId }: Props) => (
  <EventsTabs
    categoryId={categoryId}
    className={className}
    stickyHeaderTopOffset={getStickyHeaderTopOffset(MediaScreen.Mobile)}
    isCompact
    useModalOnCategoryClick
  />
);

export default Mobile;
