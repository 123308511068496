export enum TopBoardTab {
  Players = 'PLAYERS',
  Teams = 'TEAMS',
  Venues = 'VENUES',
}

export enum TabMode {
  Goals = 'GOALS',
  Attack = 'ATTACK',
  Aggression = 'AGGRESSION',
}
