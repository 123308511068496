export enum PlayersOrderingParameter {
  Goals = 'statistics__goals_scored__total',
  Assists = 'statistics__assists__total',
  GoalPoints = 'statistics__goal_points__total',
  GamesPlayed = 'statistics__matches_played__total',
  TotalShots = 'statistics__total_shots__total',
  Offside = 'statistics__offsides__total',
  ShotsOnGoal = 'statistics__shots_on_target__total',
  YellowCards = 'statistics__yellow_cards__total',
  YellowRedCards = 'statistics__yellow_red_cards__total',
  RedCards = 'statistics__red_cards__total',
}

export enum TeamsOrderingParameter {
  Goals = 'statistics__goals_scored__total',
  GoalsByHead = 'statistics__goals_by_head__total',
  GoalAttempts = 'statistics__shots_total__total',
  BallPossession = 'statistics__average_ball_possession__total',
  ShotsOnGoal = 'statistics__shots_on_target__total',
  YellowCards = 'statistics__yellow_cards__total',
  YellowRedCards = 'statistics__yellow_red_cards__total',
  RedCards = 'statistics__red_cards__total',
  Name = 'team__name',
}

export enum VenuesOrderingParameter {
  Average = 'average',
  Occupancy = 'occupancy',
  Capacity = 'capacity',
}

export interface SeasonTopPlayersParams {
  seasonId: number;
  ordering: string;
  teamIds?: number[];
  limit?: number;
}

export interface SeasonTopPlayersFiltersParams {
  seasonId: number;
  ordering: string;
  teamIds: number[];
  limit?: number;
}

export interface SeasonTopTeamsParams {
  seasonId: number;
  ordering: string;
}

export interface SeasonTopVenuesParams {
  seasonId: number;
  ordering: VenuesOrderingParameter;
}

export interface SeasonTopOfTheLeagueParams {
  seasonId: number;
}
