import React from 'react';

import styles from './styles.scss';

type Props = {
  tabName: string;
};

const LiveEventNavigationTab = ({ tabName }: Props) => (
  <div className={styles.tabName}>{tabName}</div>
);

export default React.memo(LiveEventNavigationTab);
