import React, { memo } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed/lib';

import { BaseHappening } from 'packages/event-stats/types';

import { TimeLine } from './components';
import styles from './styles.scss';

type Props = {
  happenings?: {
    homeTimeLine: BaseHappening[];
    awayTimeLine: BaseHappening[];
  };
  className?: string;
};

const HappeningsTimelines = ({ happenings, className }: Props) => {
  return (
    <div className={classnames(styles.timelinesContainer, className)}>
      {!isEmpty(happenings) && (
        <>
          <TimeLine className={styles.timeline} happenings={happenings.homeTimeLine} />
          <TimeLine className={styles.timeline} happenings={happenings.awayTimeLine} isAway />
        </>
      )}
    </div>
  );
};

export default memo(HappeningsTimelines);
