import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { loadShotEventsStart, loadShotEventsSuccess, cleanShotEventsSuccess } from '../actions';
import { Data } from '../types';

const defaultState: Data = {
  shotEvents: [],
  statistics: [],
};

const reducerMap: ReducerMap<Data, Data> = {
  [`${loadShotEventsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(loadShotEventsStart, cleanShotEventsSuccess)}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
