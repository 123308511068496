import React from 'react';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from './styles.scss';

type Props = {
  isPrevious?: boolean;
  label: string | number;
  handleClick: () => void;
  isDisabled?: boolean;
};

const NavigationLabel = ({ isPrevious = false, label, handleClick, isDisabled = false }: Props) => {
  const containerClasses = classnames(
    styles.navigationLabel,
    { [styles.previous]: isPrevious },
    { [styles.disabled]: isDisabled },
  );

  const onClick = () => {
    if (!isDisabled) {
      handleClick();
    }
  };

  return (
    <div className={containerClasses} onClick={onClick}>
      <div className="">{label}</div>
      <Icon id={IconType.Arrow} className={styles.arrow} />
    </div>
  );
};

export default NavigationLabel;
