import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './styles.scss';

class BaseWrapper extends React.PureComponent {
  content = React.createRef();

  componentDidMount() {
    const { zIndex } = this.props;

    document.body.style.overflow = 'hidden';
    document.body.style.height = '100vh';
    document.addEventListener('keydown', this.handleEscapePress);

    this.content.current.style.zIndex = zIndex;
  }

  componentWillUnmount() {
    const { isNeedToEnableScrollOnUnmount } = this.props;

    if (isNeedToEnableScrollOnUnmount) {
      document.body.style = {};
      document.removeEventListener('keydown', this.handleEscapePress);
    }
  }

  onChildContainerClick(e) {
    e.stopPropagation();
  }

  handleEscapePress = (e) => {
    const { closeModal } = this.props;

    if (e.key === 'Escape') {
      closeModal();
    }
  };

  handleOnClose = () => {
    const { isNeedToCloseOnClick, closeModal } = this.props;

    if (isNeedToCloseOnClick) {
      closeModal();
    }
  };

  render() {
    const { className, children, isDisableShadow, isExtraShadowShown } = this.props;

    const modalClassNames = classNames(
      styles.modalContainer,
      {
        [styles.modalContainerShadowDisabled]: isDisableShadow,
        [styles.modalContainerShadowExtra]: !isDisableShadow && isExtraShadowShown,
      },
      className,
    );

    return (
      <div className={modalClassNames} ref={this.content} onClick={this.handleOnClose}>
        <div onClick={this.onChildContainerClick}>{children}</div>
      </div>
    );
  }
}

BaseWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,

  className: PropTypes.string,
  isDisableShadow: PropTypes.bool,
  isExtraShadowShown: PropTypes.bool,
  closeModal: PropTypes.func,
  isNeedToCloseOnClick: PropTypes.bool,

  zIndex: PropTypes.number,
  isNeedToEnableScrollOnUnmount: PropTypes.bool,
};

BaseWrapper.defaultProps = {
  className: '',
  isDisableShadow: false,
  isExtraShadowShown: false,
  closeModal: () => {},
  isNeedToCloseOnClick: false,
  zIndex: 1,
  isNeedToEnableScrollOnUnmount: true,
};

export default BaseWrapper;
