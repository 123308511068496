import getState from './getState';
import { Status } from '../../types';

const getStatus = (state): Status => {
  return getState(state).status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
