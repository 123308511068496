import { Event, EventTeam } from 'types';

import { favoritesThunks } from 'packages/favorites';

import { favoriteEventsActions } from '../../actions';

export const toggleTeamEvent = (event: Event, team: EventTeam) => async (dispatch) => {
  try {
    dispatch(favoriteEventsActions.toggleFavoriteStart());

    await dispatch(favoritesThunks.toggleTeamEvent(event, team));

    dispatch(favoriteEventsActions.toggleFavoriteSuccess());
  } catch (error) {
    dispatch(favoriteEventsActions.toggleFavoriteFailure(error));
  }
};

export default toggleTeamEvent;
