import { Season } from 'types';

import { seasonEventsSelectors, seasonFilterSelectors } from '../../selectors';
import {
  loadInitialEventsStart,
  loadInitialEventsSuccess,
  loadInitialEventsFailure,
} from '../../actions';
import { updateActiveFilter } from '../../thunks/filters';
import { loadEvents } from './loadEvents';
import { getIsSeasonEnded, getDatePeriodByEvents, getLastEventsFromList } from '../../services';
import { Filter } from '../../types';

export const loadInitialEvents = (season: Season) => async (dispatch, getState) => {
  const { id: seasonId } = season;
  const isSeasonEnded = getIsSeasonEnded(season);

  try {
    dispatch(loadInitialEventsStart());

    const activeFilters = seasonFilterSelectors.getActiveFilters(getState());
    const limit = seasonEventsSelectors.getLimit(getState());
    const offset = seasonEventsSelectors.getOffset(getState());

    const eventsData = await dispatch(
      loadEvents({
        seasonId,
        activeFilters,
        limit,
        offset,
      }),
    );

    const events = getLastEventsFromList(eventsData.events, isSeasonEnded);
    const eventsDateRange = getDatePeriodByEvents(events);

    if (eventsDateRange) {
      dispatch(updateActiveFilter(eventsDateRange, Filter.Dates));
    }

    dispatch(
      loadInitialEventsSuccess({
        events,
        count: events.length,
      }),
    );
  } catch (error) {
    dispatch(loadInitialEventsFailure(error));
  }
};
