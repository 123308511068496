import React, { memo, useEffect, useRef } from 'react';

import DateService from 'packages/date-az';
import { DATE_FORMATS } from 'packages/date-az/consts';
import { Figure } from 'packages/shared/components';

import styles from './styles.scss';
import { CustomPeriod } from '../../types';

type Props = {
  customPeriod: CustomPeriod;
  onPeriodClick?: (customPeriod: CustomPeriod) => void;
  closePopover?: () => void;
};

const YearsRange = ({ customPeriod, onPeriodClick, closePopover }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerRef?.current?.addEventListener('wheel', handleHorizontalScroll);

    return () => {
      containerRef?.current?.removeEventListener('wheel', handleHorizontalScroll);
    };
  }, []);

  const handleHorizontalScroll = (event: WheelEvent) => {
    event.preventDefault();

    if (containerRef?.current) {
      containerRef.current.scrollLeft += event.deltaY;
    }
  };

  const handlePeriodClick = () => {
    onPeriodClick?.(customPeriod);
    closePopover?.();
  };

  const { startDate, endDate, logo, label } = customPeriod;

  return (
    <div className={styles.rangeWrapper}>
      <div className={styles.label}>
        {label}
        {logo && <Figure src={logo} className={styles.logo} />} :
      </div>
      <div ref={containerRef} className={styles.yearsBlock}>
        <span onClick={handlePeriodClick} className={styles.year}>
          {`${DateService.formatString(
            startDate,
            DATE_FORMATS.SELECT_LIST_FULL_DATE,
          )}-${DateService.formatString(endDate, DATE_FORMATS.SELECT_LIST_FULL_DATE)}`}
        </span>
      </div>
    </div>
  );
};

export default memo(YearsRange);
