import {
  Filters,
  Country,
  GeneralFilterProps,
  CountrySelectOption,
} from 'packages/season-team-statistics/types';

import { getStatTabs } from '../../../services';

const formatFilters = (filters: Filters): GeneralFilterProps => {
  const { countries } = filters;

  return {
    countrySelectItems: formatCountries(countries),
    statTabs: getStatTabs(),
  };
};

const formatCountries = (country: Country[]): CountrySelectOption[] => {
  return country.map((country) => {
    const { id, name } = country;

    return {
      id,
      label: name,
      value: name,
      componentProps: {
        item: country,
        isLogoFirst: true,
      },
    };
  });
};

export default formatFilters;
