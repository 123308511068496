import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { detailsSelectors, detailsThunks } from 'packages/team-summary';

const mapStateToProps = (state) => {
  return {
    isLoading: detailsSelectors.common.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    detailsActions: bindActionCreators(detailsThunks.common, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
