import React from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

type Props = {
  name: string;
  period: string;
  isCurrent: boolean;
};

const SeasonListItem = ({ name, period, isCurrent }: Props) => {
  return (
    <div className={classnames(styles.seasonItem, { [styles.currentSeason]: isCurrent })}>
      {name} <span className={styles.period}>{period}</span>
    </div>
  );
};

export default SeasonListItem;
