import React, { useMemo, memo, useCallback } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { FavoriteIcon, Separator } from 'packages/shared/components';
import { Event } from 'types';

import { EventRowStatus, EventDate, EventForm } from '../../components';
import { TeamContent, PlayerSubstitution } from './components';
import styles from './styles.scss';
import connect from './connect';

interface Props {
  event: Event;
  onClick: Function;
  favoriteEvents: Event[];
  favoriteEventsThunks: ActionCreatorsMapObject;
  isDateVisible?: boolean;
  isFormVisible?: boolean;
  isPlayerStatsShown?: boolean;
  isSeparatorHidden?: boolean;
}

const EventMobile = ({
  event,
  onClick,
  favoriteEvents,
  favoriteEventsThunks,
  isDateVisible = false,
  isFormVisible = false,
  isPlayerStatsShown = false,
  isSeparatorHidden = false,
}: Props) => {
  const { teams, isLive, id, date, form, playerStats } = event;

  const isEventFavorite = useMemo(() => Boolean(favoriteEvents.find((event) => event.id === id)), [
    favoriteEvents,
    id,
  ]);

  const handleFavoriteIconClick = useCallback(() => {
    favoriteEventsThunks.toggleErvent(event);
  }, [event]);

  const handleRowClick = useCallback(() => {
    onClick(event.id);
  }, [event.id]);

  return (
    <>
      <div className={classnames(styles.eventRow)} onClick={handleRowClick}>
        <div className={styles.leftColumn}>
          {isDateVisible && <EventDate className={styles.date} date={date} />}

          {!isPlayerStatsShown && (
            <EventRowStatus event={event} className={styles.matchStatus} isShort />
          )}

          {isPlayerStatsShown && !isEmpty(playerStats) && (
            <PlayerSubstitution
              substitutedIn={playerStats.substitutedIn}
              substitutedOut={playerStats.substitutedOut}
              minutesPlayed={playerStats.minutesPlayed}
            />
          )}
        </div>

        {isFormVisible && (
          <div className={styles.formWrapper}>
            <EventForm isPopoverDisabled className={styles.form} form={form} />
          </div>
        )}

        <TeamContent
          playerStats={playerStats}
          isPlayerStatsShown={isPlayerStatsShown}
          isTeamFinesShown={!isPlayerStatsShown}
          teams={teams}
          isLive={isLive}
        />

        <div className={styles.actions}>
          <FavoriteIcon
            className={styles.favoriteIcon}
            onClick={handleFavoriteIconClick}
            isFavorite={isEventFavorite}
          />
        </div>
      </div>

      {!isSeparatorHidden && <Separator />}
    </>
  );
};

export default connect(memo(EventMobile));
