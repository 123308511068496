import { eventsActions, commonActions } from '../../actions';
import { EventActiveFilters } from '../../types';
import loadEvents from './loadEvents';

type Params = {
  playerId: number;
  filters: EventActiveFilters;
  limit: number;
  offset: number;
};

export default ({ playerId, filters, limit, offset }: Params) => async (dispatch) => {
  try {
    dispatch(eventsActions.loadMoreEventsStart());

    const { period, team, tournament, selectedSeasons } = filters;
    const params = {
      period,
      teamId: team?.id,
      tournamentId: tournament?.id,
      seasons: selectedSeasons,
      playerId,
      limit,
      offset,
    };

    const { events, count } = await dispatch(loadEvents(params));

    dispatch(eventsActions.loadMoreEventsSuccess(events));
    dispatch(commonActions.updateCountSuccess({ count }));
  } catch (e) {
    dispatch(eventsActions.loadMoreEventsFailure(e));
  }
};
