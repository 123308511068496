import i18n from 'i18n-smart';
import React, { memo } from 'react';

import { Event } from 'types';
import { Commentary as CommentaryView } from 'packages/event-commentary/types';

import { Commentary, Loading } from './components';
import styles from './styles.scss';

type Props = {
  commentaries: CommentaryView[];
  activeEvent: Event;
};

const Commentaries = ({ commentaries, activeEvent }: Props) => {
  const { isLive, isFinished } = activeEvent;
  const hasMatchStarted = isLive || isFinished;

  return (
    <div className={styles.commentsContainer}>
      {hasMatchStarted ? (
        commentaries.map((comment) => <Commentary key={comment.id} comment={comment} />)
      ) : (
        <div className={styles.emptyCommentsLabel}>
          {i18n.value('eventCommentary.emptyCommentaries')}
        </div>
      )}
      {isLive && <Loading />}
    </div>
  );
};

export default memo(Commentaries);
