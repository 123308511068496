exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1zoxXAJN{flex:1 1;margin-right:16px}._3HhTCUoO{flex:1 1;display:flex;justify-content:center}._2_4dcx7Q{width:443px}._2tVbYTpP{position:relative;display:flex;flex-direction:column;width:100%;height:100%}._2tVbYTpP>*:not(:last-of-type){margin-bottom:8px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktopNarrow/football/SeasonPlayerStatistics/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,QAAO,CACP,YAAa,CACb,sBAAuB,CAT3B,WAaI,WAAY,CAbhB,WAiBI,iBAAkB,CAClB,YAAa,CACb,qBAAsB,CAEtB,UAAW,CACX,WAAY,CAtBhB,gCAyBM,iBAAkB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n\n  .side-bar {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n\n    width: 100%;\n    height: 100%;\n\n    > *:not(:last-of-type) {\n      margin-bottom: 8px;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_1zoxXAJN",
	"leftColumn": "_1zoxXAJN",
	"left-bar": "_3HhTCUoO",
	"leftBar": "_3HhTCUoO",
	"right-column": "_2_4dcx7Q",
	"rightColumn": "_2_4dcx7Q",
	"side-bar": "_2tVbYTpP",
	"sideBar": "_2tVbYTpP"
};