import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState = {
  isEventCommentaryLoading: true,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${actions.loadEventCommentaryStart}`]: () => {
    return { isEventCommentaryLoading: true };
  },
  [`${combineActions(
    actions.loadEventCommentarySuccess,
    actions.loadEventCommentaryFailure,
  )}`]: () => {
    return { isEventCommentaryLoading: false };
  },
  [`${actions.cleanEventCommentary}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
