import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DateService from 'packages/date-az';

import styles from './styless.scss';

const CurrentDateButton = ({ date, onClick, className, forwardedRef }) => {
  const containerClasses = classnames(styles.currentDateButton, className);

  const { day } = date;
  const month = DateService.getMonthName(date);

  return (
    <div ref={forwardedRef} className={containerClasses} onClick={() => onClick(date)}>
      {`${day} ${month}`}
    </div>
  );
};

CurrentDateButton.defaultProps = {
  className: '',
};

CurrentDateButton.propTypes = {
  forwardedRef: PropTypes.shape({ current: PropTypes.instanceOf(Element) }).isRequired,
  date: PropTypes.shape({
    day: PropTypes.number,
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
};

export default React.memo(CurrentDateButton);
