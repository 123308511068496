import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { tournamentsSelectors, tournamentsThunks } from 'packages/stadium-summary';

const mapStateToProps = (state) => {
  return {
    tournaments: tournamentsSelectors.getTournaments(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tournamentsActions: bindActionCreators(tournamentsThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
