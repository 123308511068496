import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { statisticsActions, commonActions } from '../../../../actions';
import { Interfaces } from '../../../../types';

const defaultState: Interfaces.Statistics.Statistics.Status = {
  isLoading: false,
};

const reducerMap: ReducerMap<Interfaces.Statistics.Statistics.Status, void> = {
  [`${statisticsActions.statistics.loadStatisticsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(
    statisticsActions.statistics.loadStatisticsFailure,
    statisticsActions.statistics.loadStatisticsSuccess,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${commonActions.cleanSummarySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
