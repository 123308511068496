import { toCamelCase } from 'packages/utils/Object.utils';
import { DATE_FORMATS } from 'packages/date-az/consts';
import DateService from 'packages/date-az';

import { Season, Tournament } from '../types';

const mapFilters = (items: object[]): Tournament[] => {
  return items.map(mapTournament);
};

const mapTournament = (item: object): Tournament => {
  const { id, name, logo, seasons } = toCamelCase(item);

  const mappedSeasons = mapSeasons(seasons);

  return { id, name, logo, seasons: mappedSeasons };
};

const mapSeasons = (items: object[]): Season[] => {
  return items.map(mapSeason);
};

const mapSeason = (item: object): Season => {
  const { id, startDate, endDate } = toCamelCase(item);

  const startYear = DateService.formatString(startDate, DATE_FORMATS.SHORT_YEAR);
  const endYear = DateService.formatString(endDate, DATE_FORMATS.SHORT_YEAR);

  return {
    id,
    name: `${startYear}/${endYear}`,
    period: parseSeasonPeriod(startDate, endDate),
    startDate,
    endDate,
  };
};

const parseSeasonPeriod = (startDate: string, endDate: string): string => {
  return `${DateService.formatString(
    startDate,
    DATE_FORMATS.SELECT_LIST_FULL_DATE,
  )}-${DateService.formatString(endDate, DATE_FORMATS.SELECT_LIST_FULL_DATE)}`;
};

export default mapFilters;
