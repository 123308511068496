import React from 'react';
import classnames from 'classnames';

import { Calendar, DateLabel, FlexiblePopover, Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';
import { LocaleCode } from 'types';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';
import { CustomPeriod, YearItem } from 'packages/shared/components/Calendar/types';

import styles from './styles.scss';

type Props = {
  value?: string | [string, string];
  localeCode: LocaleCode;
  onChange?: (updatedValue: string | string[]) => void;
  className?: string;
  isRangeMode?: boolean;
  useBackdrop?: boolean;
  customRangeLabel?: string;
  onCustomRangeClick?: () => void;
  onApplyClick?: Function;
  onCancelClick?: () => void;
  eventsCount?: number;
  yearOffset?: number;
  onYearClick?: (year: YearItem) => void;
  onPeriodClick?: (customPeriod: CustomPeriod) => void;
  customPeriod?: CustomPeriod;
};

const DatePicker = ({
  className,
  value,
  localeCode,
  onChange,
  isRangeMode = false,
  useBackdrop,
  customRangeLabel,
  onCustomRangeClick,
  onApplyClick,
  onCancelClick,
  eventsCount,
  yearOffset,
  onYearClick,
  onPeriodClick,
  customPeriod,
}: Props) => (
  <FlexiblePopover
    popoverComponent={Calendar}
    popoverProps={{
      value,
      localeCode,
      yearOffset,
      onChange,
      isRangeMode,
      customRangeLabel,
      onCustomRangeClick,
      onApplyClick,
      onCancelClick,
      eventsCount,
      onYearClick,
      onPeriodClick,
      customPeriod,
    }}
    justify={PopoverJustify.CenterStart}
    align={PopoverAlign.CenterStart}
    disableBodyOverflow
    closeOnClickOutside
    useBackdrop={useBackdrop}
  >
    <div className={classnames(styles.container, className)}>
      <span className={styles.label}>
        <DateLabel value={value} />
      </span>
      <Icon id={IconType.Calendar} className={styles.icon} />
    </div>
  </FlexiblePopover>
);

export default DatePicker;
