import i18n from 'i18n-smart';

import { SelectItem } from 'packages/shared/components/Select/types';
import { PlayerTeam } from 'packages/player-events/types';

import { ALL_ITEMS_OPTION_ID } from '../../../constants';

const getTeamSelectItem = (team?: PlayerTeam): SelectItem => {
  if (!team) {
    return {
      id: ALL_ITEMS_OPTION_ID,
      label: i18n.value(`player.events.filters.teamSelect.label`),
      componentProps: {
        name: i18n.value(`player.events.filters.teamSelect.label`),
      },
    };
  }

  const { id, name, logo } = team;

  return {
    label: name,
    id,
    componentProps: {
      name,
      logo,
    },
  };
};

export default getTeamSelectItem;
