import { MINI_HEADER_HEIGHT } from 'packages/event-details-ui/containers/EventDetails/components/EventDetailsCard/constants';
import { ScrollParameters } from 'packages/shared/modules/NarrowTeamStatisticsTable/types';
import { getScrollParent } from 'packages/utils/Scroll.utils';

const MIN_SCROLL_OFFSET = 170;

const scrollToStageTable = ({ tableBlockRef }: ScrollParameters) => {
  const tabPanel = tableBlockRef.current?.parentElement?.firstChild;
  const scrollContainerElement = getScrollParent(tableBlockRef.current);

  if (!tabPanel) {
    return;
  }

  const { top: containerTopOffset = 0 } = scrollContainerElement.getBoundingClientRect() || {};
  const { top: tableTopOffset = 0 } = tableBlockRef.current?.getBoundingClientRect() || {};
  const tabPanelHeight = (tabPanel as HTMLElement).offsetHeight;
  const scrollOffset = tableTopOffset - containerTopOffset - MINI_HEADER_HEIGHT - tabPanelHeight;

  if (scrollOffset >= MIN_SCROLL_OFFSET) {
    scrollContainerElement.scrollTo({
      left: 0,
      top: scrollOffset,
      behavior: 'smooth',
    });
  }
};

export default scrollToStageTable;
