import { createAction } from 'redux-actions';

import { NAMESPACE } from '../../constants';

export const loadTournamentSeasonsStart = createAction(`${NAMESPACE}LOAD_TOURNAMENT_SEASONS_START`);
export const loadTournamentSeasonsSuccess = createAction(
  `${NAMESPACE}LOAD_TOURNAMENT_SEASONS_SUCCESS`,
);
export const loadTournamentSeasonsFailure = createAction(
  `${NAMESPACE}LOAD_TOURNAMENT_SEASONS_FAILURE`,
);

export const setTournamentSeasonsSuccess = createAction(
  `${NAMESPACE}SET_TOURNAMENT_SEASONS_SUCCESS`,
);
export const cleanTournamentSeasonsSuccess = createAction(
  `${NAMESPACE}CLEAN_TOURNAMENT_SEASONS_SUCCESS`,
);
