import { Stage } from 'packages/event-standings/types';

import { Interfaces } from '../../../types';
import getPath from './getPath';

export const getData = (state): Interfaces.Details.Standings.Data => {
  return getPath(state).data;
};

export const getStages = (state): Stage[] => {
  return getData(state).stages;
};

export const getActiveStageId = (state): number | undefined => {
  return getData(state).activeStageId;
};
