import React from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { DisplayingScorePeriod } from 'packages/events/types';
import { Event, EventStatus } from 'types';
import { eventsServices } from 'packages/events';

import styles from './styles.scss';

type Props = {
  event: Event;
  className?: string;
};

const FinishedEventStatusLabel = ({ event, className }: Props) => {
  const { status, teams, periodScores } = event;

  const [home] = teams;
  const { isHomeTeam } = home;
  let penaltyPeriodScore = '';

  if (periodScores) {
    const extractPeriodScore = eventsServices.getPeriodScoreExtractor(periodScores, isHomeTeam);
    penaltyPeriodScore = extractPeriodScore(DisplayingScorePeriod.PENALTY) || '';
  }

  const statusView = i18n.value(`eventStatusLabel.finishedEventStatus.${status}`);
  const labelText =
    status === EventStatus.AfterPenalties ? `${statusView} ${penaltyPeriodScore}` : statusView;

  return <div className={classnames(styles.finishedEventLabel, className)}>{labelText}</div>;
};

export default FinishedEventStatusLabel;
