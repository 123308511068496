import { createAction } from 'redux-actions';

import { NAMESPACE } from '../../constants';

export const loadInitialStatisticsStart = createAction(`${NAMESPACE}LOAD_INITIAL_STATISTICS_START`);
export const loadInitialStatisticsSuccess = createAction(
  `${NAMESPACE}LOAD_INITIAL_STATISTICS_SUCCESS`,
);
export const loadInitialStatisticsFailure = createAction(
  `${NAMESPACE}LOAD_INITIAL_STATISTICS_FAILURE`,
);

export const cleanWholeStatisticsSuccess = createAction(`${NAMESPACE}CLEAN_STATISTICS_SUCCESS`);
