import { SeasonEvents } from 'packages/rest';
import * as actions from '../../actions';
import * as services from '../../services';

export const loadFilters = (seasonId: number) => async (dispatch) => {
  try {
    dispatch(actions.loadFiltersStart());

    const { data } = await SeasonEvents.loadSeasonFilters({ seasonId });

    const seasonFilters = services.mapSeasonFilters(data.results);

    dispatch(actions.loadFiltersSuccess(seasonFilters));
  } catch (error) {
    dispatch(actions.loadFiltersFailure(error));
  }
};
