export const MINI_HEADER_HEIGHT = 90;

export const MINI_HEADER_TRANSITION_STYLES = {
  entering: {
    transform: 'translateY(0%)',
    opacity: 1,
    transition: `transform 200ms ease-in-out, opacity 600ms ease-in-out`,
  },
  entered: {
    transform: 'translateY(0%)',
    opacity: 1,
    transition: `transform 200ms ease-in-out, opacity 600ms ease-in-out`,
  },
  exited: {
    opacity: 0,
    height: 0,
    width: 0,
    transform: 'translateY(-100%)',
  },
};
