import { SeasonTopBoard } from 'packages/rest';

import {
  loadCompactTopPlayersStart,
  loadCompactTopPlayersSuccess,
  loadTopPlayersFailure,
} from '../actions';
import { PLAYER_API_ORDERING_BY_PARAMETERS } from '../constants';
import { mapSeasonTopPlayers, parseOrderingParams } from '../services';
import { PlayersParameter } from '../types';

const TOP_PLAYERS_LIMIT = 3;

interface Params {
  seasonId: number;
  sortingParameter?: PlayersParameter;
}

export const loadCompactTopPlayers = ({
  seasonId,
  sortingParameter = PlayersParameter.Goals,
}: Params) => async (dispatch) => {
  try {
    dispatch(loadCompactTopPlayersStart());

    const { data } = await SeasonTopBoard.loadSeasonTopPlayers({
      seasonId,
      ordering: parseOrderingParams(PLAYER_API_ORDERING_BY_PARAMETERS[sortingParameter]),
      limit: TOP_PLAYERS_LIMIT,
    });
    const topPlayers = mapSeasonTopPlayers(data.results);

    dispatch(loadCompactTopPlayersSuccess(topPlayers));
  } catch (e) {
    dispatch(loadTopPlayersFailure(e));
  }
};
