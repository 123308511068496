import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const HeaderSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.leftBlock}>
          <Skeleton variant="rectangular" height={16} width="100%" maxWidth={83} />
          <Skeleton variant="rectangular" marginLeft={12} width="100%" height={32} maxWidth={205} />
          <Skeleton variant="rectangular" marginLeft={12} width="100%" height={32} maxWidth={90} />
          <Skeleton variant="rectangular" marginLeft={12} width="100%" height={32} maxWidth={140} />
        </div>
        <Skeleton variant="rectangular" marginLeft={12} height={32} width="100%" maxWidth={100} />
      </div>
    </div>
  );
};

export default memo(HeaderSkeleton);
