import React, { useMemo } from 'react';
import i18n from 'i18n-smart';

import { TeamLabel, SearchSelect } from 'packages/shared/components';
import { Team } from 'types';
import { SelectSize } from 'packages/shared/components/Select/types';
import { ArrayUtils } from 'packages/utils';

import { TeamSelectOption } from '../../../../types';

import { getTeamSelectItems } from './services';

type Props = {
  teams: Team[];
  selectedTeams: Team[];
  onChange: Function;
  className?: string;
  isCloseableOnResize?: boolean;
  itemsContainerClass?: string;
};

const TeamSelect = ({
  selectedTeams,
  itemsContainerClass,
  isCloseableOnResize,
  teams,
  onChange,
  className,
}: Props) => {
  const selectItems = useMemo(() => {
    return getTeamSelectItems(teams);
  }, [teams]);
  const selectedItems = useMemo(() => {
    return getTeamSelectItems(selectedTeams);
  }, [selectedTeams]);

  const handleChange = (items: TeamSelectOption[]) => {
    selectedTeams = items.map((item) => ArrayUtils.findById(teams, item.id));

    onChange(selectedTeams);
  };

  return (
    <SearchSelect<TeamSelectOption>
      defaultItems={selectItems}
      selectedItems={selectedItems}
      onSelectionChange={handleChange}
      size={SelectSize.ExtraLg}
      labelPrefix={i18n.value('season.seasonEvents.select.label')}
      itemComponent={TeamLabel}
      className={className}
      isCloseableOnResize={isCloseableOnResize}
      itemsContainerClass={itemsContainerClass}
    />
  );
};

export default TeamSelect;
