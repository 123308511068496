import { SeasonStandings } from 'packages/rest';

import { mapSeasonHeadToHead } from '../../services';
import { headToHeadActions } from '../../actions';

const loadHeadToHead = (seasonId: number) => async (dispatch) => {
  try {
    dispatch(headToHeadActions.loadHeadToHeadStart());

    const { data } = await SeasonStandings.loadSeasonHeadToHead({ seasonId });
    const mappedHeadToHeadData = mapSeasonHeadToHead(data.results);

    dispatch(headToHeadActions.loadHeadToHeadSuccess(mappedHeadToHeadData));
  } catch (e) {
    dispatch(headToHeadActions.loadHeadToHeadFailure(e));
  }
};

export default loadHeadToHead;
