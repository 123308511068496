export enum EventType {
  MatchStarted = 'match_started',
  PeriodStart = 'period_start',
  PeriodScore = 'period_score',
  Goal = 'score_change',
  Substitution = 'substitution',
  YellowCard = 'yellow_card',
  YellowRedCard = 'yellow_red_card',
  RedCard = 'red_card',
  Injury = 'injury',
  Corner = 'corner_kick',
  Offside = 'offside',
  ShotOnTarget = 'shot_on_target',
  ShotOffTarget = 'shot_off_target',
  InjuryTime = 'injury_time_shown',
  PenaltyMissed = 'penalty_missed',
  PenaltyRewarded = 'penalty_awarded',
}
