import { combineReducers } from 'redux';

import categoryList from './categoryList';
import mainCategory from './mainCategory';
import categoryModal from './categoryModal';

export default combineReducers({
  categoryList,
  mainCategory,
  categoryModal,
});
