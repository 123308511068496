import * as eventLineupsActions from './actions';
import * as eventLineupsThunks from './thunks';
import * as eventLineupsConstants from './constants';
import * as eventLineupsSelectors from './selectors';
import * as eventLineupsServices from './services';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  eventLineupsActions,
  eventLineupsThunks,
  eventLineupsConstants,
  eventLineupsSelectors,
  eventLineupsServices,
  createReducer,
};
