import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import classnames from 'classnames';

import { teamPlayerStatisticsConstants } from 'packages/team-player-statistics';
import { LoaderContainer, Separator } from 'packages/shared/components';
import { SelectItem } from 'packages/shared/components/Select/types';
import { Interfaces, Enums } from 'packages/team-player-statistics/types';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { PLAYER_POSITION_SELECT_ITEMS } from 'packages/season-player-statistics/constants';
import { ComponentLike } from 'types';

import { Header, Footer, FilterArea } from './components';
import { formatFilters, getColumnConfigurationSchema } from './services';
import styles from './styles.scss';

const {
  COLUMN_TEMPLATE_BY_TYPE,
  CUSTOM_FILTER_EMPTY_STATE,
  COMMON_ORDERING_BY_STATS_TYPE,
  RANGE_FILTERS_DEFAULT_STATE,
} = teamPlayerStatisticsConstants;

type Props = {
  onCustomFilterUpdate: (updates: Interfaces.CustomFilter.CustomFilterState) => void;
  onApply: () => void;
  onCancel: () => void;
  customFilters: Interfaces.CustomFilter.CustomFilterState;
  tournaments: SeasonsExtendedTournament[];
  filters: Interfaces.Filters.Filters;
  isOpen: boolean;
  isLoading: boolean;
  className?: string;
  GeneralFilterComponent: ComponentLike;
};

const ExtendedFilter = ({
  onCustomFilterUpdate,
  onApply,
  onCancel,
  customFilters,
  filters,
  isOpen,
  isLoading,
  className,
  GeneralFilterComponent,
  tournaments,
}: Props) => {
  const { tournamentId, seasonId } = useParams();
  const columnConfigurationSchema = useMemo(getColumnConfigurationSchema, []);
  const formattedFilter = useMemo(() => formatFilters(filters), [filters]);
  const [customFilterState, setCustomFilterState] = useState<
    Interfaces.CustomFilter.CustomFilterState
  >(customFilters);

  const { generalFilter, columnConfigurationFilter } = customFilterState;
  const { statTabs, footSelectItems, nationalitySelectItems } = formattedFilter;

  useEffect(() => {
    setCustomFilterState(customFilters);
  }, [customFilters]);

  const setGeneralFilterState = (state: Interfaces.CustomFilter.GeneralFilter) => {
    setCustomFilterState({ ...customFilterState, generalFilter: state });
  };

  const setColumnConfigurationFilterState = (
    state: Interfaces.CustomFilter.ColumnConfigurationFilter,
  ) => {
    setCustomFilterState({ ...customFilterState, columnConfigurationFilter: state });
  };

  const handleColumnToggle = (type: Enums.ColumnType) => {
    const { activeColumns, columns } = customFilterState.columnConfigurationFilter;
    const columnsCopy = [...columns];

    if (activeColumns[type]) {
      const foundIndex = columnsCopy.findIndex((column) => column.type === type);
      columnsCopy.splice(foundIndex, 1);
    } else {
      columnsCopy.push(COLUMN_TEMPLATE_BY_TYPE[type]);
    }

    setColumnConfigurationFilterState({
      ...customFilterState.columnConfigurationFilter,
      activePreset: Enums.PresetType.Custom,
      activeColumns: {
        ...activeColumns,
        [type]: !activeColumns[type],
      },
      rangeFilters: {
        ...customFilterState.columnConfigurationFilter.rangeFilters,
        [type]: {},
      },
      columns: columnsCopy,
    });
  };

  const handleRangeGroupUpdate = (
    type: Enums.ColumnType,
    updates?: Interfaces.CustomFilter.Range,
  ) => {
    const { rangeFilters } = customFilterState.columnConfigurationFilter;

    setColumnConfigurationFilterState({
      ...customFilterState.columnConfigurationFilter,
      rangeFilters: {
        ...rangeFilters,
        [type]: updates,
      },
    });
  };

  const handlePresetChange = (activePreset: Interfaces.CustomFilter.PresetView) => {
    setColumnConfigurationFilterState({
      ...customFilterState.columnConfigurationFilter,
      ...activePreset.overrides,
      activePreset: activePreset.type,
    });
  };

  const handleOrderChange = (columns: Interfaces.CustomFilter.Column[]) => {
    setColumnConfigurationFilterState({
      ...customFilterState.columnConfigurationFilter,
      activePreset: Enums.PresetType.Custom,
      columns,
    });
  };

  const setGeneralState = (updatedData: Partial<Interfaces.CustomFilter.GeneralFilter>) => {
    setGeneralFilterState({ ...customFilterState.generalFilter, ...updatedData });
  };

  const handleStatTypeChange = (activeStatsTab: Enums.StatType) => {
    setCustomFilterState({
      generalFilter: {
        ...generalFilter,
        activeStatsTab: activeStatsTab,
        commonOrdering: COMMON_ORDERING_BY_STATS_TYPE[activeStatsTab],
      },
      columnConfigurationFilter: {
        ...columnConfigurationFilter,
        rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      },
    });
  };

  const handleNationalitiesChange = (
    nationalities: Interfaces.CustomFilter.NationalitySelectOption[],
  ) => {
    setGeneralState({ nationalities });
  };

  const handlePositionsChange = (positions: SelectItem[]) => {
    setColumnConfigurationFilterState({
      ...customFilterState.columnConfigurationFilter,
      positions,
    });
  };

  const handleFootChange = (foot: SelectItem) => {
    setGeneralState({ foot });
  };

  const handleRangeValuesChange = (from: string, to: string) => {
    setGeneralState({ ages: { from, to } });
  };

  const handleSeasonChange = (season: SelectItem) => {
    setGeneralState({ seasonId: Number(season.id) });
  };

  const handleTournamentChange = (tournament: SelectItem) => {
    const tournamentId = Number(tournament.id);
    const [firstSelectedTournamentSeason] =
      tournaments.find(({ id }) => id === tournamentId)?.seasons || [];

    setGeneralState({ tournamentId, seasonId: firstSelectedTournamentSeason?.id });
  };

  const handleApply = () => {
    onCustomFilterUpdate({
      generalFilter: customFilterState.generalFilter,
      columnConfigurationFilter: customFilterState.columnConfigurationFilter,
    });

    onApply();
  };

  const handleClear = useCallback(() => {
    const { generalFilter, columnConfigurationFilter } = CUSTOM_FILTER_EMPTY_STATE;

    setCustomFilterState({
      generalFilter: {
        ...generalFilter,
        tournamentId: tournamentId ? Number(tournamentId) : undefined,
        seasonId: seasonId ? Number(seasonId) : undefined,
      },
      columnConfigurationFilter,
    });
  }, [tournamentId, seasonId]);

  const handleCancel = useCallback(() => {
    setCustomFilterState(customFilters);
    onCancel();
  }, []);

  return (
    <LoaderContainer
      isLoading={isLoading}
      className={classnames(styles.filterContainer, className)}
    >
      <div className={classnames(styles.filterContainer, { [styles.visible]: isOpen }, className)}>
        <Header onClose={handleCancel} />
        <Separator />
        <FilterArea
          tournaments={tournaments}
          GeneralFilterComponent={GeneralFilterComponent}
          onSelectedTournamentChange={handleTournamentChange}
          onSelectedSeasonChange={handleSeasonChange}
          onActiveStatTypeChange={handleStatTypeChange}
          onSelectedNationalitiesChange={handleNationalitiesChange}
          onSelectedPositionsChange={handlePositionsChange}
          onSelectedFootChange={handleFootChange}
          onAgeRangeChange={handleRangeValuesChange}
          onPresetChange={handlePresetChange}
          onColumnsOrderChange={handleOrderChange}
          onRangeGroupUpdate={handleRangeGroupUpdate}
          onColumnToggle={handleColumnToggle}
          activeStatType={generalFilter.activeStatsTab}
          activeColumns={columnConfigurationFilter.activeColumns}
          configurableColumns={columnConfigurationFilter.columns}
          activePresetType={columnConfigurationFilter.activePreset}
          rangeFilters={columnConfigurationFilter.rangeFilters}
          selectedPositionItems={columnConfigurationFilter.positions}
          selectedFootItem={generalFilter.foot}
          selectedNationalityItems={generalFilter.nationalities}
          selectedTournamentId={generalFilter.tournamentId}
          selectedSeasonId={generalFilter.seasonId}
          ageRange={generalFilter.ages}
          nationalitySelectItems={nationalitySelectItems}
          positionSelectItems={PLAYER_POSITION_SELECT_ITEMS}
          footSelectItems={footSelectItems}
          statTabs={statTabs}
          columnConfigurationSchema={columnConfigurationSchema}
          className={styles.filterArea}
        />
        <Separator />
        <Footer onApply={handleApply} onClear={handleClear} onCancel={handleCancel} />
      </div>
    </LoaderContainer>
  );
};

export default memo(ExtendedFilter);
