import { Season } from 'types';
import DateService from 'packages/date-az';

import { getIsSeasonEnded } from './getIsSeasonEnded';
import { DEFAULT_DAYS_OFFSET } from '../constants';

const END_DATE_DAYS_OFFSET = 3;

export const getDefaultFilterDates = (
  selectedSeason: Season,
  days: number = DEFAULT_DAYS_OFFSET,
): [string, string] => {
  const isSeasonEnded = getIsSeasonEnded(selectedSeason);
  const { endDate } = selectedSeason;

  const now = DateService.getDate().toISODate();
  let parsedStartDate;
  let parsedEndDate;

  if (isSeasonEnded) {
    parsedEndDate = DateService.parse(endDate).plus({ days: END_DATE_DAYS_OFFSET });
    parsedStartDate = DateService.parse(endDate).minus({ days });
  } else {
    parsedEndDate = DateService.parse(now).plus({ days: END_DATE_DAYS_OFFSET });
    parsedStartDate = DateService.parse(now).minus({ days });
  }

  const formattedEndDate = parsedEndDate.toISODate();
  const formattedStartDate = parsedStartDate.toISODate();

  return [formattedStartDate, formattedEndDate];
};
