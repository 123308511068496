import { handleActions, ReducerMap, combineActions } from 'redux-actions';

import { AllEventsStatus } from '../../types';
import { scheduledEventsActions } from '../../actions';

const defaultState = {
  isLoading: false,
  offset: 0,
  hasNext: true,
};

const reducerMap: ReducerMap<AllEventsStatus, any> = {
  [`${scheduledEventsActions.loadScheduledEventsStart}`]: () => ({
    ...defaultState,
    isLoading: true,
  }),
  [`${scheduledEventsActions.loadScheduledEventsFailure}`]: (state) => ({
    ...state,
    isLoading: false,
  }),
  [`${combineActions(
    scheduledEventsActions.loadScheduledEventsSuccess,
    scheduledEventsActions.loadMoreScheduledEventsSuccess,
  )}`]: (state, { payload }) => {
    const { hasNext, offset } = payload;

    return {
      ...state,
      isLoading: false,
      offset,
      hasNext,
    };
  },
};

export default handleActions(reducerMap, defaultState);
