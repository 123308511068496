exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1dv3MrPj{margin:12px 16px 0 16px}._3iKxilcg{padding:12px 16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/team-summary-ui/containers/TeamSummary/views/Mobile/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,uBCHS,CDCb,WAMI,iBCRU","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\r\n\r\n:local {\r\n  .details-selector {\r\n    margin: 12px $margin 0 $margin;\r\n  }\r\n\r\n  .tournament-chip-list {\r\n    padding: 12px $padding;\r\n  }\r\n\r\n  .details {\r\n  }\r\n}\r\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"details-selector": "_1dv3MrPj",
	"detailsSelector": "_1dv3MrPj",
	"tournament-chip-list": "_3iKxilcg",
	"tournamentChipList": "_3iKxilcg"
};