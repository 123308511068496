import React, { memo } from 'react';

import Figure from 'packages/shared/components/Figure';

import styles from './styles.scss';

type Props = {
  logo?: string;
  name: string;
};

const TeamItem = ({ logo, name }: Props) => {
  return (
    <div className={styles.teamItem}>
      <Figure src={logo} className={styles.logo} />
      {name}
    </div>
  );
};

export default memo(TeamItem);
