import Desktop from './Desktop';
import Tablet from './Tablet';

export default {
  Desktop,
  DesktopNarrow: Desktop,
  Tablet: Tablet,
  TabletNarrow: Tablet,
  Mobile: Tablet,
};
