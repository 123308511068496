import { useCallback, useEffect } from 'react';

import { MatchUpDetailsDisplayType } from '../types';

const useHiddenContent = (targetRef, contentRef, scrollContainerRef) => {
  const handleIntersection = useCallback(([{ isIntersecting }]: IntersectionObserverEntry[]) => {
    if (isIntersecting) {
      contentRef.current.style.display = MatchUpDetailsDisplayType.Flex;
    } else {
      contentRef.current.style.display = MatchUpDetailsDisplayType.None;
    }
  }, []);

  useEffect(() => {
    if (targetRef.current) {
      const intersectionObserver = new IntersectionObserver(handleIntersection, {
        root: scrollContainerRef.current,
        threshold: 0.01,
      });

      intersectionObserver.observe(targetRef.current);

      return () => {
        intersectionObserver.disconnect();
      };
    }
  }, [handleIntersection]);
};

export default useHiddenContent;
