import { SelectItem } from 'packages/shared/components/Select/types';
import { seasonPlayerStatisticsConstants } from 'packages/season-player-statistics';
import i18n from 'i18n-smart';

const { CUSTOMIZATION_PRESET_BY_TYPE } = seasonPlayerStatisticsConstants;

const getPresetItems = (): SelectItem[] =>
  Object.values(CUSTOMIZATION_PRESET_BY_TYPE).map(({ type }) => ({
    id: type,
    label: i18n.value(
      `season.playerStatistics.customFilter.columnConfiguration.configurationPreset.${type}`,
    ),
  }));

export default getPresetItems;
