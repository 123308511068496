import { EventStats } from 'packages/rest';

import * as services from '../services';
import * as actions from '../actions';

export const loadEventStats = ({ eventId }) => async (dispatch) => {
  try {
    dispatch(actions.loadEventStatsStart());

    const { data } = await EventStats.loadEventStats({ eventId });
    const { details, timeLines } = services.mapEventStats(data.results);

    return dispatch(actions.loadEventStatsSuccess({ details, timeLines }));
  } catch (error) {
    dispatch(actions.loadEventStatsFailure());
  }
};
