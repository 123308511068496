import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Event, EventTeam, Tournament } from 'types';

import * as actions from '../actions';
import { Favorites } from '../types';

const defaultState: Favorites = {
  events: [],
  teams: [],
  leagues: [],
};

const reducerMap: ReducerMap<Favorites, unknown> = {
  [`${combineActions(actions.loadFavoriteEventsSuccess, actions.updateFavoriteEventsSuccess)}`]: (
    state,
    { payload },
  ) => {
    return { ...state, events: payload as Event[] };
  },
  [`${combineActions(actions.loadFavoriteLeaguesSuccess, actions.updateFavoriteLeaguesSuccess)}`]: (
    state,
    { payload },
  ) => {
    return { ...state, leagues: payload as Tournament[] };
  },
  [`${combineActions(actions.loadFavoriteTeamsSuccess, actions.updateFavoriteTeamsSuccess)}`]: (
    state,
    { payload },
  ) => {
    return { ...state, teams: payload as EventTeam[] };
  },
  [`${actions.loadLatestFavoritesFromStorageSuccess}`]: (state, { payload }) => {
    return payload as Favorites;
  },
};

export default handleActions(reducerMap, defaultState);
