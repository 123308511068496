import getPath from '../getPath';
import { SeasonEventsStatus } from '../../types';

const getStatus = (state): SeasonEventsStatus => {
  return getPath(state).events.status;
};

export const getIsInitialLoading = (state): boolean => {
  return getStatus(state).isInitialLoading;
};

export const getIsMoreLoading = (state): boolean => {
  return getStatus(state).isMoreLoading;
};

export const getLimit = (state): number => {
  return getStatus(state).limit;
};

export const getOffset = (state): number => {
  return getStatus(state).offset;
};

export const getCount = (state): number => {
  return getStatus(state).count;
};
