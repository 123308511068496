import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { seasonTopBoardThunks, topVenuesSelectors } from 'packages/season-top-board';

const mapStateToProps = (state) => ({
  venues: topVenuesSelectors.getData(state),
  isTopVenuesLoading: topVenuesSelectors.getIsTopVenuesLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  seasonTopBoardActions: bindActionCreators(seasonTopBoardThunks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
