import { SeasonEvents } from 'packages/rest';
import { eventsConstants } from 'packages/events';

import { LoadEventsOptions } from '../../types';
import { mapSeasonEvents } from '../../services';

export const loadEvents = ({
  seasonId,
  activeFilters,
  limit = eventsConstants.DEFAULT_EVENTS_LIMIT,
  offset = 0,
}: LoadEventsOptions) => async () => {
  const { dates, rounds, teams } = activeFilters;
  const teamIds = teams.map((team) => team.id);
  const roundIds = rounds.map((round) => round.id);

  const { data } = await SeasonEvents.loadSeasonEvents({
    seasonId,
    dates,
    teamIds,
    roundIds,
    limit,
    offset,
  });

  return {
    events: mapSeasonEvents(data.results),
    count: data.count,
  };
};
