import React, { memo } from 'react';

import styles from './styles.scss';
import EmptyCell from '../EmptyCell';
import { formatEndDate, formatStartDate } from './services';

type Props = {
  startDate?: string;
  endDate?: string;
  isStartDate?: boolean;
};

const DateCell = ({ startDate, endDate, isStartDate = false }: Props) => {
  const date = isStartDate
    ? formatStartDate({ startDate, endDate })
    : formatEndDate({ startDate, endDate });

  return <div className={styles.cell}>{date || <EmptyCell />}</div>;
};

export default memo(DateCell);
