import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { ActionCreatorsMapObject } from 'redux';
import isEmpty from 'is-empty-typed';

import { DEFAULT_EVENTS_LIMIT } from 'packages/player-events/constants';
import { PaginationParams } from 'packages/shared/components/Pagination/types';
import { CustomPeriod, YearItem } from 'packages/shared/components/Calendar/types';
import { EventActiveFilters, PlayerTeam, Season, Tournament } from 'packages/player-events/types';
import { TournamentView } from 'packages/events/types';
import { useNonInitialEffect } from 'packages/hooks';
import { SearchQuery } from 'router/types';
import { LocaleCode } from 'types';

import { getCustomPeriod, getPeriodBySelectedTournament, getSeasonsRange } from './services';
import { YEARS_SELECT_RANGE } from './constants';
import PlayerEventsView from './views';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  eventsCount: number;
  events: TournamentView[];
  eventActions: ActionCreatorsMapObject;
  commonActions: ActionCreatorsMapObject;
  filterActions: ActionCreatorsMapObject;
  tournamentFilters: Tournament[];
  teamFilters: PlayerTeam[];
  activeFilters: EventActiveFilters;
  localeCode: LocaleCode;
  isMoreFiltersLoading: boolean;
  isInitialFiltersLoading: boolean;
  isInitialEventsLoading: boolean;
  isMoreEventsLoading: boolean;
  useModalOnCategoryClick?: boolean;
  limit: number;
  offset: number;
};

const extendPlayerEvents = (
  PlayerEvents: typeof PlayerEventsView[keyof typeof PlayerEventsView],
) => {
  return ({
    isInitialEventsLoading,
    eventActions,
    commonActions,
    filterActions,
    tournamentFilters,
    teamFilters,
    activeFilters,
    localeCode,
    isMoreFiltersLoading,
    events,
    isInitialFiltersLoading,
    isMoreEventsLoading,
    eventsCount,
    limit,
    offset,
    useModalOnCategoryClick = false,
  }: Props) => {
    const { playerId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
      if (playerId) {
        commonActions.loadPage(playerId);

        return () => {
          commonActions.clearEvents();
        };
      }
    }, [playerId]);

    useNonInitialEffect(() => {
      eventActions.loadMoreEvents({ filters: activeFilters, limit, offset, playerId });
    }, [activeFilters, limit, offset, playerId]);

    const isLimitSelectable = eventsCount > DEFAULT_EVENTS_LIMIT;
    const { tournament: selectedTournament, team: selectedTeam } = activeFilters;

    const customPeriod = useMemo(() => {
      return getCustomPeriod({
        team: selectedTeam,
        tournament: selectedTournament,
      });
    }, [selectedTeam, selectedTournament]);

    const handlePeriodChange = useCallback(
      (period: [string, string]) => {
        const { team } = activeFilters;
        const filters: Partial<EventActiveFilters> = { period };

        if (!isEmpty(team)) {
          filters.tournamentSeasons = [];
          filters.selectedSeasons = [];
          filters.tournament = undefined;
        }

        filterActions.updateActiveFilter(filters);
      },
      [activeFilters.team, playerId],
    );

    const handleTournamentChange = useCallback(
      (tournament?: Tournament) => {
        const period = getPeriodBySelectedTournament(tournament);
        const filters: Partial<EventActiveFilters> = {
          tournament,
          selectedSeasons: [],
          tournamentSeasons: tournament?.seasons || [],
        };

        if (period) {
          filters.period = period;
        } else if (!tournament && selectedTeam?.id) {
          filters.period = [selectedTeam.startDate, selectedTeam.endDate];
        }

        filterActions.updateActiveFilter(filters);
      },
      [selectedTeam, playerId],
    );

    const handleTeamChange = useCallback(
      (team?: PlayerTeam) => {
        const filters: Partial<EventActiveFilters> = {
          team,
          tournament: undefined,
          selectedSeasons: [],
          tournamentSeasons: [],
        };

        if (team) {
          filters.period = [team.startDate, team.endDate];
          filterActions.loadTournamentFilter(team.id);
        }

        filterActions.updateActiveFilter(filters);
      },
      [playerId],
    );

    const handleSeasonsChange = useCallback(
      (selectedSeasons: Season[]) => {
        const filters: Partial<EventActiveFilters> = { selectedSeasons };

        if (!isEmpty(selectedSeasons)) {
          filters.period = getSeasonsRange(selectedSeasons);
        } else {
          const tournamentPeriod = getPeriodBySelectedTournament(selectedTournament);

          if (tournamentPeriod) {
            filters.period = tournamentPeriod;
          } else if (selectedTeam?.id) {
            filters.period = [selectedTeam.startDate, selectedTeam.endDate];
          }
        }

        filterActions.updateActiveFilter(filters);
      },
      [selectedTournament, selectedTeam, playerId],
    );

    const handleYearClick = useCallback(
      (year: YearItem) => {
        const { startDate, endDate } = year;
        const filters = {
          period: [startDate.toISODate(), endDate.toISODate()],
        };

        filterActions.updateActiveFilter(filters);
      },
      [playerId],
    );

    const handlePeriodClick = useCallback(
      (period: CustomPeriod) => {
        const { startDate, endDate } = period;
        const { tournament } = activeFilters;
        const filters: Partial<EventActiveFilters> = { period: [startDate, endDate] };

        if (!isEmpty(tournament)) {
          filters.selectedSeasons = [];
        }

        filterActions.updateActiveFilter(filters);
      },
      [activeFilters.tournament, playerId],
    );

    const handlePaginationChange = useCallback((params: PaginationParams) => {
      commonActions.updatePagination(params);
    }, []);

    const handleEventClick = useCallback(
      (eventId: number) => {
        setSearchParams({ [SearchQuery.EventCardId]: eventId.toString() });
      },
      [searchParams],
    );

    return (
      <div className={styles.playerEvents}>
        <PlayerEvents
          isInitialDataLoading={isInitialFiltersLoading || isInitialEventsLoading}
          useModalOnCategoryClick={useModalOnCategoryClick}
          onTournamentChange={handleTournamentChange}
          onSeasonsChange={handleSeasonsChange}
          onPeriodChange={handlePeriodChange}
          onPeriodClick={handlePeriodClick}
          onYearClick={handleYearClick}
          onTeamChange={handleTeamChange}
          onEventClick={handleEventClick}
          onPaginationChange={handlePaginationChange}
          yearOffset={YEARS_SELECT_RANGE}
          tournamentFilters={tournamentFilters}
          activeFilters={activeFilters}
          teamFilters={teamFilters}
          localeCode={localeCode}
          customPeriod={customPeriod}
          isMoreFiltersLoading={isMoreFiltersLoading}
          isLimitSelectable={isLimitSelectable}
          isMoreEventsLoading={isMoreEventsLoading}
          eventsCount={eventsCount}
          events={events}
          limit={limit}
        />
      </div>
    );
  };
};

export default {
  Desktop: connect(extendPlayerEvents(PlayerEventsView.Desktop)),
  DesktopNarrow: connect(extendPlayerEvents(PlayerEventsView.DesktopNarrow)),
  Tablet: connect(extendPlayerEvents(PlayerEventsView.Tablet)),
  TabletNarrow: connect(extendPlayerEvents(PlayerEventsView.TabletNarrow)),
  Mobile: connect(extendPlayerEvents(PlayerEventsView.Mobile)),
};
