import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { EventActiveFilters, Tournament } from 'packages/team-events/types';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';
import { Button, DatePicker, FlexiblePopover, Icon } from 'packages/shared/components';
import { YearItem } from 'packages/shared/components/Calendar/types';
import { ButtonType } from 'packages/shared/components/Button/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { PlayerTeam } from 'packages/player-events/types';
import { LocaleCode } from 'types';

import { FiltrationPopover } from '../../../../components';
import { YEARS_SELECT_RANGE } from '../../../../constants';
import styles from './styles.scss';

type Props = {
  onPeriodChange: (dates: [string, string]) => void;
  onYearClick: (year: YearItem) => void;
  tournaments: Tournament[];
  teamFilters: PlayerTeam[];
  activeFilters: EventActiveFilters;
  eventsCount: number;
};

const EventFilter = ({
  tournaments,
  activeFilters,
  teamFilters,
  onPeriodChange,
  eventsCount,
  onYearClick,
}: Props) => {
  const { period } = activeFilters;

  return (
    <div className={styles.teamEventsFilters}>
      <div className={styles.primaryGroup}>
        <div className={styles.title}>{i18n.value('team.teamEvents.filters.title')}</div>
        <DatePicker
          isRangeMode
          value={period}
          onApplyClick={onPeriodChange}
          eventsCount={eventsCount}
          yearOffset={YEARS_SELECT_RANGE}
          localeCode={LocaleCode.En}
          onYearClick={onYearClick}
        />
      </div>
      <FlexiblePopover
        popoverProps={{
          tournaments,
          teamFilters,
          activeFilters,
          eventsCount,
        }}
        popoverComponent={FiltrationPopover}
        justify={PopoverJustify.CenterEnd}
        align={PopoverAlign.CenterStart}
        useChildrenWrapper
        useBackdrop
      >
        <Button type={ButtonType.Default} contentClassName={styles.settingsButton}>
          <Icon id={IconType.Configuration} className={styles.icon} />
        </Button>
      </FlexiblePopover>
    </div>
  );
};

export default memo(EventFilter);
