import React, { memo, RefObject, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import i18n from 'i18n-smart';

import { Ordering, StadiumsOrdering } from 'packages/rest/api/SeasonStadiums/types';
import { PaginationParams } from 'packages/shared/components/Pagination/types';
import { DEFAULT_STADIUMS_LIMIT } from 'packages/season-stadiums/constants';
import { Stadium } from 'packages/season-stadiums/types';
import { Pagination } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Team } from 'types';

import { StadiumsTable, TableSkeleton } from '../../components';
import styles from './styles.scss';

type Props = {
  onPaginationChange: (params: PaginationParams) => void;
  onHeaderCellClick: (orderingType: Ordering) => void;
  ordering: StadiumsOrdering;
  stadiums: Stadium[];
  isNewPageLoading: boolean;
  isInitialPageLoading: boolean;
  stadiumsAmount: number;
  limit: number;
};

const Stadiums = ({
  isNewPageLoading,
  limit,
  stadiumsAmount,
  stadiums,
  onPaginationChange,
  onHeaderCellClick,
  ordering,
  isInitialPageLoading,
}: Props) => {
  const isLimitSelectable = stadiumsAmount > DEFAULT_STADIUMS_LIMIT;
  const tableRef: RefObject<HTMLDivElement> = useRef(null);
  const { tournamentId = '', seasonId = '' } = useParams();
  const { appNavigate } = useNavigation();

  const handleTeamClick = useCallback(
    (team: Team) => {
      appNavigate(DesktopPage.FootballTeamSummaryDescription, {
        teamId: team.id,
        uniqueTournamentId: tournamentId,
        seasonId,
      });
    },
    [tournamentId, seasonId],
  );

  return (
    <div className={styles.stadiumsContainer}>
      {isInitialPageLoading ? (
        <TableSkeleton elementsCount={limit} />
      ) : (
        <>
          <div className={styles.header}>{i18n.value('season.stadiums.title')}</div>
          <StadiumsTable
            onTeamClick={handleTeamClick}
            onHeaderCellClick={onHeaderCellClick}
            ordering={ordering}
            isLoading={isNewPageLoading}
            forwardedRef={tableRef}
            data={stadiums}
            isCountryNameDisplayed
          />
        </>
      )}
      <Pagination
        isLoading={isInitialPageLoading}
        isLimitSelectable={isLimitSelectable}
        useRightAlignment
        onPaginationChange={onPaginationChange}
        amountOfItems={stadiumsAmount}
        pageSize={limit}
      />
    </div>
  );
};

export default memo(Stadiums);
