import React, { MutableRefObject } from 'react';

import { BracketRound } from 'packages/event-standings/types';

import { LinkedMatchUp, ColumnHeader } from './components';
import styles from './styles.scss';

type Props = {
  headerElementId: string;
  gridElementId: string;
  round: BracketRound;
  scrollContainerRef: MutableRefObject<HTMLDivElement | null>;
  isFirstRound: boolean;
  activeTeamIds?: number[];
  activeUniqueTournamentId?: number | string;
  activeSeasonId?: number | string;
  initialHeight?: number;
  isActive?: boolean;
};

const BracketColumn = ({
  scrollContainerRef,
  headerElementId,
  gridElementId,
  isFirstRound,
  round,
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
  initialHeight,
  isActive,
}: Props) => {
  const { matchups, name, id } = round;

  return (
    <div className={styles.gridColumn}>
      <ColumnHeader
        id={headerElementId}
        title={name}
        isActive={isActive}
        className={styles.header}
      />
      <div
        id={gridElementId}
        style={{
          gridTemplateRows: `repeat(${matchups.length}, minmax(0, 1fr))`,
          height: initialHeight,
        }}
        className={styles.column}
      >
        {matchups.map((matchUp) => (
          <LinkedMatchUp
            activeTeamIds={activeTeamIds}
            activeUniqueTournamentId={activeUniqueTournamentId}
            activeSeasonId={activeSeasonId}
            scrollContainerRef={scrollContainerRef}
            isFirstRound={isFirstRound}
            key={`${matchUp.id}-${id}`}
            matchUp={matchUp}
          />
        ))}
      </div>
    </div>
  );
};

export default BracketColumn;
