import instance from '../../../axios';
import { LoadFavoriteEventsParams } from '../types';

export const loadFavoriteEvents = async ({
  leagueIds,
  eventIds,
  teamIds,
  activeDate,
}: LoadFavoriteEventsParams) => {
  return instance({
    method: 'post',
    url: `/sport_events/favourites/`,
    data: {
      unique_tournaments: leagueIds ? leagueIds : [],
      sport_events: eventIds ? eventIds : [],
      teams: eventIds ? teamIds : [],
      scheduled: activeDate,
    },
  });
};
