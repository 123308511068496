import getPath from '../getPath';
import { SeasonFilterStatus } from '../../types';

const getStatus = (state): SeasonFilterStatus => {
  return getPath(state).filter.status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
