import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { favoritesSelectors } from 'packages/favorites';
import { settingsSelectors } from 'packages/settings';
import { favoriteEventsSelectors, favoriteEventsThunks } from 'packages/events';
import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => ({
  favoriteTournaments: favoritesSelectors.getFavoriteLeagues(state),
  activeEventId: eventDetailsSelectors.getActiveEventId(state),
  activeSportId: settingsSelectors.getActiveSportId(state),
  isLoading: favoriteEventsSelectors.getIsLoading(state),
  events: favoriteEventsSelectors.getEvents(state),
});

const mapDispatchToProps = (dispatch) => ({
  favoriteEventsActions: bindActionCreators(favoriteEventsThunks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
