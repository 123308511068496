import { Duration } from 'luxon';

import { EventStatus } from 'types';

interface Params {
  onPeriodMinuteUpdate: (periodMinute: string) => void;
  eventStatus: EventStatus;
}

const STATUS_PERIOD_TIME_LIMIT = {
  [EventStatus.FirstHalf]: 45,
  [EventStatus.SecondHalf]: 90,
  [EventStatus.Overtime]: 105,
  [EventStatus.SecondExtra]: 120,
};

const getTimeModifier = ({ onPeriodMinuteUpdate, eventStatus }: Params) => (
  duration: Duration,
): Duration => {
  const periodTimeLimit = STATUS_PERIOD_TIME_LIMIT[eventStatus];
  const { minutes } = duration;

  if (periodTimeLimit && minutes >= periodTimeLimit) {
    onPeriodMinuteUpdate?.(`${periodTimeLimit}+`);
    duration = duration.minus({ minutes: periodTimeLimit });
  }

  return duration;
};

export default getTimeModifier;
