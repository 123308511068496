import { filterActions } from '../../actions';
import { filterSelectors } from '../../selectors';
import { ActiveFiltersParams } from '../../types';
import { loadFilteredEvents } from '../events';

export const updateActiveFilter = (newFilters: ActiveFiltersParams, teamId: string) => async (
  dispatch,
  getState,
) => {
  const activeFilter = filterSelectors.getActiveFilter(getState());
  const updatedFilter = { ...activeFilter, ...newFilters };

  dispatch(filterActions.updateActiveFilterSuccess({ activeFilters: updatedFilter }));

  dispatch(loadFilteredEvents(teamId));
};

export default updateActiveFilter;
