import { SummaryHappeningType } from 'packages/events/types';

import mapPeriodStartHappening from './mapPeriodStartHappening';
import mapCardHappening from './mapCardHappening';
import mapGoalHappening from './mapGoalHappening';
import mapSubstitutionHappening from './mapSubstitutionHappening';
import mapPenaltyHappening from './mapPenaltyHappening';
import mapMissedPenaltyHappening from './mapMissedPenaltyHappening';
import { BaseHappening, TimeLinePeriodScores } from '../../types';
import sortTimeLineHappenings from './sortTimeLineEvents';

export const mapTimeLineHappenings = (
  happenings: BaseHappening[] = [],
  periodScores: TimeLinePeriodScores,
): BaseHappening[] => {
  const mappedHappenings = happenings.reduce<BaseHappening[]>((acc, happening) => {
    if (happening.eventType in SummaryHappeningType) {
      acc.push(mapHappening(happening, periodScores));
    }

    return acc;
  }, []);

  return sortTimeLineHappenings(mappedHappenings);
};

const mapHappening = (
  happening: BaseHappening,
  periodScores: TimeLinePeriodScores,
): BaseHappening => {
  switch (happening.eventType) {
    case SummaryHappeningType.PERIOD_START:
      return mapPeriodStartHappening(happening, periodScores);
    case SummaryHappeningType.YELLOW_CARD:
    case SummaryHappeningType.RED_CARD:
    case SummaryHappeningType.YELLOW_RED_CARD:
      return mapCardHappening(happening);
    case SummaryHappeningType.SCORE_CHANGE:
      return mapGoalHappening(happening);
    case SummaryHappeningType.PENALTY_MISSED:
      return mapMissedPenaltyHappening(happening);
    case SummaryHappeningType.SUBSTITUTION:
      return mapSubstitutionHappening(happening);
    case SummaryHappeningType.PENALTY_SHOOTOUT:
      return mapPenaltyHappening(happening);
    default:
      return happening;
  }
};
