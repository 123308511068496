exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._DwkR98tz{flex:1 1;margin-right:16px}._Ton37i-c{flex:1 1;display:flex;align-items:flex-start;justify-content:center}._Tvye9OSq{width:443px}._3QsAvecd{position:relative;display:flex;flex-direction:column;width:100%;height:100%}._3QsAvecd>*:not(:last-of-type){margin-bottom:8px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktop/football/StadiumSummary/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,QAAO,CACP,YAAa,CACb,sBAAuB,CACvB,sBAAuB,CAV3B,WAcI,WAAY,CAdhB,WAkBI,iBAAkB,CAClB,YAAa,CACb,qBAAsB,CAEtB,UAAW,CACX,WAAY,CAvBhB,gCA0BM,iBAAkB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n\n  .side-bar {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n\n    width: 100%;\n    height: 100%;\n\n    > *:not(:last-of-type) {\n      margin-bottom: 8px;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_DwkR98tz",
	"leftColumn": "_DwkR98tz",
	"left-bar": "_Ton37i-c",
	"leftBar": "_Ton37i-c",
	"right-column": "_Tvye9OSq",
	"rightColumn": "_Tvye9OSq",
	"side-bar": "_3QsAvecd",
	"sideBar": "_3QsAvecd"
};