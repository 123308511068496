import { MediaScreen } from 'types';
import {
  DATEPICKER_BASE_HEIGHT,
  DATEPICKER_MOBILE_HEIGHT,
} from 'packages/shared/components/DateCarousel/const';

import { GRAY_GAP_HEIGHT, THIN_SEPARATOR_HEIGHT } from '../constants';

const getStickyHeaderTopOffset = (mediaScreen: MediaScreen): number => {
  switch (mediaScreen) {
    case MediaScreen.Mobile:
      return DATEPICKER_MOBILE_HEIGHT + THIN_SEPARATOR_HEIGHT;
    case MediaScreen.Tablet:
    case MediaScreen.TabletNarrow:
      return DATEPICKER_BASE_HEIGHT + THIN_SEPARATOR_HEIGHT;
    default:
      return DATEPICKER_BASE_HEIGHT + GRAY_GAP_HEIGHT;
  }
};

export default getStickyHeaderTopOffset;
