import { settingsSelectors } from 'packages/settings';
import { favoritesThunks } from 'packages/favorites';

import { favoriteEventsActions } from '../../actions';

const loadFavoriteEvents = () => async (dispatch, getState) => {
  try {
    dispatch(favoriteEventsActions.loadFavoriteEventsStart());

    const state = getState();
    const activeDate = settingsSelectors.getActiveDate(state);
    await dispatch(favoritesThunks.loadFavoritesByDate(activeDate));

    dispatch(favoriteEventsActions.loadFavoriteEventsSuccess());
  } catch (error) {
    dispatch(favoriteEventsActions.loadFavoriteEventsFailure(error));
  }
};

export default loadFavoriteEvents;
