import React, { memo, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import { seasonPlayerStatisticsConstants } from 'packages/season-player-statistics';
import { LoaderContainer, Separator } from 'packages/shared/components';
import { SelectItem } from 'packages/shared/components/Select/types';
import {
  ColumnConfigurationFilter,
  CustomFilterState,
  GeneralFilter,
  PresetView,
  Filters,
  Column,
  Range,
  ColumnType,
  PresetType,
  StatType,
  SearchSelectOption,
  Nationality,
} from 'packages/season-player-statistics/types';
import { PLAYER_POSITION_SELECT_ITEMS } from 'packages/season-player-statistics/constants';
import { Team, ComponentLike } from 'types';

import { Header, Footer, FilterArea } from './components';
import { formatFilters, getColumnConfigurationSchema } from './services';
import styles from './styles.scss';

const {
  COLUMN_TEMPLATE_BY_TYPE,
  CUSTOM_FILTER_EMPTY_STATE,
  COMMON_ORDERING_BY_STATS_TYPE,
  RANGE_FILTERS_DEFAULT_STATE,
} = seasonPlayerStatisticsConstants;

type Props = {
  onCustomFilterUpdate: (updates: CustomFilterState) => void;
  loadFilters: () => void;
  onApply: () => void;
  onCancel: () => void;
  customFilters: CustomFilterState;
  filters: Filters;
  isOpen: boolean;
  isLoading: boolean;
  GeneralFilterComponent: ComponentLike;
  className?: string;
};

const ExtendedFilter = ({
  onCustomFilterUpdate,
  loadFilters,
  onApply,
  onCancel,
  customFilters,
  filters,
  isOpen,
  isLoading,
  GeneralFilterComponent,
  className,
}: Props) => {
  const columnConfigurationSchema = useMemo(getColumnConfigurationSchema, []);
  const formattedFilter = useMemo(() => formatFilters(filters), [filters]);
  const [customFilterState, setCustomFilterState] = useState<CustomFilterState>(customFilters);
  const { generalFilter, columnConfigurationFilter } = customFilterState;
  const { statTabs, footSelectItems, nationalitySelectItems, teamSelectItems } = formattedFilter;

  useEffect(() => {
    loadFilters();
  }, []);

  useEffect(() => {
    setCustomFilterState(customFilters);
  }, [customFilters]);

  const setGeneralFilterState = (state: GeneralFilter) => {
    setCustomFilterState({ ...customFilterState, generalFilter: state });
  };

  const setColumnConfigurationFilterState = (state: ColumnConfigurationFilter) => {
    setCustomFilterState({ ...customFilterState, columnConfigurationFilter: state });
  };

  const handleColumnToggle = (type: ColumnType) => {
    const { activeColumns, columns } = customFilterState.columnConfigurationFilter;
    const columnsCopy = [...columns];

    if (activeColumns[type]) {
      const foundIndex = columnsCopy.findIndex((column) => column.type === type);
      columnsCopy.splice(foundIndex, 1);
    } else {
      columnsCopy.push(COLUMN_TEMPLATE_BY_TYPE[type]);
    }

    setColumnConfigurationFilterState({
      ...customFilterState.columnConfigurationFilter,
      activePreset: PresetType.Custom,
      activeColumns: {
        ...activeColumns,
        [type]: !activeColumns[type],
      },
      rangeFilters: {
        ...customFilterState.columnConfigurationFilter.rangeFilters,
        [type]: {},
      },
      columns: columnsCopy,
    });
  };

  const handleRangeGroupUpdate = (type: ColumnType, updates?: Range) => {
    const { rangeFilters } = customFilterState.columnConfigurationFilter;

    setColumnConfigurationFilterState({
      ...customFilterState.columnConfigurationFilter,
      rangeFilters: {
        ...rangeFilters,
        [type]: updates,
      },
    });
  };

  const handlePresetChange = (activePreset: PresetView) => {
    setColumnConfigurationFilterState({
      ...customFilterState.columnConfigurationFilter,
      ...activePreset.overrides,
      activePreset: activePreset.type,
    });
  };

  const handleOrderChange = (columns: Column[]) => {
    setColumnConfigurationFilterState({
      ...customFilterState.columnConfigurationFilter,
      activePreset: PresetType.Custom,
      columns,
    });
  };

  const setGeneralState = (updatedData: Partial<GeneralFilter>) => {
    setGeneralFilterState({ ...customFilterState.generalFilter, ...updatedData });
  };

  const handleStatTypeChange = (activeStatsTab: StatType) => {
    setCustomFilterState({
      generalFilter: {
        ...generalFilter,
        activeStatsTab: activeStatsTab,
        commonOrdering: COMMON_ORDERING_BY_STATS_TYPE[activeStatsTab],
      },
      columnConfigurationFilter: {
        ...columnConfigurationFilter,
        rangeFilters: RANGE_FILTERS_DEFAULT_STATE,
      },
    });
  };

  const handleTeamsChange = (teams: SearchSelectOption<Team>[]) => {
    setGeneralState({ teams });
  };

  const handleNationalitiesChange = (nationalities: SearchSelectOption<Nationality>[]) => {
    setGeneralState({ nationalities });
  };

  const handlePositionsChange = (positions: SelectItem[]) => {
    setColumnConfigurationFilterState({
      ...customFilterState.columnConfigurationFilter,
      positions,
    });
  };

  const handleFootChange = (foot: SelectItem) => {
    setGeneralState({ foot });
  };

  const handleRangeValuesChange = (from: string, to: string) => {
    setGeneralState({ ages: { from, to } });
  };

  const handleApply = () => {
    onCustomFilterUpdate({
      generalFilter: customFilterState.generalFilter,
      columnConfigurationFilter: customFilterState.columnConfigurationFilter,
    });
    onApply();
  };

  const handleClear = () => {
    setCustomFilterState(CUSTOM_FILTER_EMPTY_STATE);
  };

  const handleCancel = () => {
    setCustomFilterState(customFilters);
    onCancel();
  };

  return (
    <LoaderContainer isLoading={isLoading} className={styles.filterContainer}>
      <div className={classnames(styles.filterContainer, { [styles.visible]: isOpen }, className)}>
        <Header onClose={handleCancel} />
        <Separator />
        <FilterArea
          GeneralFilterComponent={GeneralFilterComponent}
          onActiveStatTypeChange={handleStatTypeChange}
          onSelectedTeamsChange={handleTeamsChange}
          onSelectedNationalitiesChange={handleNationalitiesChange}
          onSelectedPositionsChange={handlePositionsChange}
          onSelectedFootChange={handleFootChange}
          onAgeRangeChange={handleRangeValuesChange}
          onPresetChange={handlePresetChange}
          onColumnsOrderChange={handleOrderChange}
          onRangeGroupUpdate={handleRangeGroupUpdate}
          onColumnToggle={handleColumnToggle}
          activeStatType={generalFilter.activeStatsTab}
          activeColumns={columnConfigurationFilter.activeColumns}
          configurableColumns={columnConfigurationFilter.columns}
          activePresetType={columnConfigurationFilter.activePreset}
          rangeFilters={columnConfigurationFilter.rangeFilters}
          selectedFootItem={generalFilter.foot}
          selectedNationalityItems={generalFilter.nationalities}
          selectedPositionItems={columnConfigurationFilter.positions}
          selectedTeamItems={generalFilter.teams}
          ageRange={generalFilter.ages}
          nationalitySelectItems={nationalitySelectItems}
          positionSelectItems={PLAYER_POSITION_SELECT_ITEMS}
          teamSelectItems={teamSelectItems}
          footSelectItems={footSelectItems}
          statTabs={statTabs}
          columnConfigurationSchema={columnConfigurationSchema}
          className={styles.filterArea}
        />
        <Separator />
        <Footer onApply={handleApply} onClear={handleClear} onCancel={handleCancel} />
      </div>
    </LoaderContainer>
  );
};

export default memo(ExtendedFilter);
