import { PreferredFoot } from 'packages/rest/api/SeasonPlayerStatistics/types';
import { SeasonPlayerStatistics } from 'packages/rest';

import { formatRangeFilters, mapPlayers, serializeOrdering } from '../../services';
import { DEFAULT_PLAYERS_LIMIT, PLAYER_POSITION_BY_ID } from '../../constants';
import { customFilterSelectors } from '../../selectors';

const loadPlayers = ({ seasonId, limit = DEFAULT_PLAYERS_LIMIT, offset }) => async (
  dispatch,
  getState,
) => {
  const state = getState();
  const { generalFilter, columnConfigurationFilter } = customFilterSelectors.getData(state);
  const { teams, nationalities, foot, ages, commonOrdering, activeStatsTab } = generalFilter;
  const { rangeFilters, configurableOrdering, positions } = columnConfigurationFilter;
  const parameterRanges = formatRangeFilters(rangeFilters, ages, activeStatsTab);
  const preferredFoot =
    (foot?.id as PreferredFoot) === PreferredFoot.All ? undefined : (foot?.id as PreferredFoot);

  const { data } = await SeasonPlayerStatistics.loadPlayers({
    teams: teams.map(({ id }) => id),
    nationalities: nationalities.map(({ id }) => id),
    positions: positions.map(({ id }) => PLAYER_POSITION_BY_ID[id]),
    ordering: serializeOrdering({ ...configurableOrdering, ...commonOrdering }, activeStatsTab),
    parameterRanges,
    preferredFoot,
    seasonId,
    offset,
    limit,
  });

  const { results: playerStatistics, next, count } = data;

  return {
    players: mapPlayers(playerStatistics, offset, activeStatsTab),
    offset: offset + playerStatistics.length,
    hasNext: !!next,
    count,
  };
};

export default loadPlayers;
