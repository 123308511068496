import { EventSummaryReferee, EventSummaryStadium } from 'packages/event-summary/types';
import { toCamelCase } from 'packages/utils/Object.utils';

import { Data, Weather } from '../types';

const mapEventSummary = (summary): Omit<Data, 'periods' | 'commentaries'> => {
  const { stadium, referee, facts = [], weather, attendance } = summary;

  return {
    facts,
    stadium: stadium ? mapStadium(stadium) : stadium,
    referee: referee ? mapReferee(referee) : referee,
    weather: weather ? mapWeather(weather) : undefined,
    attendance,
  };
};

const mapReferee = (referee): EventSummaryReferee => {
  const { name, logo, countryName, countryLogo, statistics } = toCamelCase(referee);
  const redCardsAverage = statistics?.red_cards_average;
  const yellowCardsAverage = statistics?.yellow_cards_average;
  const penaltiesAverage = statistics?.penalties_average;

  return {
    name,
    logo,
    countryLogo,
    countryName,
    redCardsAverage: redCardsAverage?.toFixed(2),
    yellowCardsAverage: yellowCardsAverage?.toFixed(2),
    penaltiesAverage: penaltiesAverage?.toFixed(2),
  };
};

const mapStadium = (stadium): EventSummaryStadium => {
  const { id, city, countryLogo, name, capacity } = toCamelCase(stadium);

  return { id, city, countryLogo, name, capacity };
};

const mapWeather = (weather): Weather => {
  const { ctemp, desc, mileswind, humidity, pressure } = toCamelCase(weather);

  return {
    temperature: ctemp,
    description: desc,
    airHumidity: humidity,
    windSpeed: mileswind,
    pressure,
  };
};

export default mapEventSummary;
