import React, { memo, useCallback } from 'react';

import { Figure } from 'packages/shared/components';
import { Team } from 'types';

import styles from './styles.scss';

type Props = {
  team: Team;
  onTeamClick: (team: Team) => void;
};

const HistoryTableTeamComponent = ({ team, onTeamClick }: Props) => {
  const handleTeamClick = useCallback(
    (event) => {
      event.stopPropagation();
      onTeamClick(team);
    },
    [team],
  );

  return (
    <div className={styles.team}>
      <Figure
        onClick={handleTeamClick}
        src={team.logo || '/images/default_team_logo.png'}
        className={styles.teamLogo}
      />
    </div>
  );
};

export default memo(HistoryTableTeamComponent);
