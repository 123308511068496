import DateService from 'packages/date-az';
import { EventStatus } from 'types';

const PERIOD_TIME_INCREMENT_VALUE_BY_STATUS = {
  [EventStatus.FirstHalf]: 0,
  [EventStatus.SecondHalf]: 45,
  [EventStatus.Overtime]: 90,
};

export const getPeriodTime = (timeInfo, eventStatus: EventStatus): string => {
  const { periodStartTime } = timeInfo;
  const parsedDate = new Date(periodStartTime);

  return DateService.getDateTimeFromDate(parsedDate)
    .minus({
      minutes: PERIOD_TIME_INCREMENT_VALUE_BY_STATUS[eventStatus] || 0,
    })
    .toISO();
};
