import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const setLocale = createAction(`${NAMESPACE}SET_LOCALE`);
export const setTimeZone = createAction(`${NAMESPACE}SET_TIME_ZONE`);

export const setPrevDate = createAction(`${NAMESPACE}SET_PREV_DATE`);
export const setNextDate = createAction(`${NAMESPACE}SET_NEXT_DATE`);
export const setActiveDate = createAction(`${NAMESPACE}SET_ACTIVE_DATE`);
export const setActiveSport = createAction(`${NAMESPACE}SET_ACTIVE_SPORT`);

export const failure = createAction(`${NAMESPACE}FAILURE`);
