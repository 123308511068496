import DateService from 'packages/date-az';
import { DATE_FORMATS } from 'packages/date-az/consts';
import { DateTime } from 'luxon';

export const getDateLabel = (value: string | [string, string]): string => {
  const dates: Date[] = DateService.getDatesFromString(value);

  return dates.length === 1
    ? getDateView(DateService.getDateTimeFromDate(dates[0]))
    : getRangeView(dates);
};

const getRangeView = (value: Date[]): string => {
  const [startDate, endDate]: DateTime[] = value.map((date) =>
    DateService.getDateTimeFromDate(date),
  );

  if (startDate.equals(endDate)) {
    return getDateView(startDate);
  }

  if (startDate.year !== endDate.year) {
    return `${getDateView(startDate)} - ${getDateView(endDate)}`;
  }

  if (startDate.month !== endDate.month) {
    return `${DateService.format(startDate, DATE_FORMATS.CALENDAR_DATE)} - ${getDateView(endDate)}`;
  }

  return `${startDate.day} - ${getDateView(endDate)}`;
};

const getDateView = (dateTime: DateTime): string => {
  return DateService.format(dateTime, DATE_FORMATS.CALENDAR_FULL_DATE);
};
