import React, { memo, useMemo } from 'react';
import i18n from 'i18n-smart';

import { Tournament } from 'types';
import { Separator } from 'packages/shared/components';

import { Tournaments } from '../../components';
import { ListSkeleton } from '../../../../components';
import styles from './styles.scss';
import { filterFavoriteTournaments } from './services';

type Props = {
  tournaments: Tournament[];
  favoriteLeagues: Tournament[];
  isPopularTournamentsLoading: boolean;
  isCategoriesLoading: boolean;
  getFavoriteTournamentToggleHandler: (tournament: Tournament) => () => void;
};

const PopularTournaments = ({
  tournaments,
  favoriteLeagues,
  isPopularTournamentsLoading,
  getFavoriteTournamentToggleHandler,
  isCategoriesLoading,
}: Props) => {
  const filteredTournaments = useMemo(
    () => filterFavoriteTournaments({ tournaments, favoriteLeagues }),
    [tournaments, favoriteLeagues],
  );

  if (isCategoriesLoading || isPopularTournamentsLoading) {
    return <ListSkeleton elementsCount={5} />;
  }

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>{i18n.value('leftBar.popularTournaments.title')}</h3>
      <Separator />

      <Tournaments
        useControlsHoverEffect
        onFavoriteTournamentToggle={getFavoriteTournamentToggleHandler}
        tournaments={filteredTournaments}
        favoriteLeagues={favoriteLeagues}
      />
    </div>
  );
};

export default memo(PopularTournaments);
