import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Data } from '../types';

import {
  loadTeamDetailsSuccess,
  loadTeamDetailsFailure,
  loadTeamDetailsStart,
  cleanTeamDetailsSuccess,
} from '../actions';

const defaultState = null;

const reducerMap: ReducerMap<Data, Data> = {
  [`${loadTeamDetailsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(
    loadTeamDetailsStart,
    loadTeamDetailsFailure,
    cleanTeamDetailsSuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
