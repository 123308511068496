import React from 'react';
import i18n from 'i18n-smart';
import { ActionCreatorsMapObject } from 'redux';

import { Language, LocaleCode, TimeZone } from 'types';

import { TimeZoneSelect, LanguageSelect } from './views';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  timeZones: TimeZone[];
  selectedTimeZone: TimeZone;
  settingsActions: ActionCreatorsMapObject;
  languages: Language[];
  localeCode: LocaleCode;
};

const SettingsPopup = ({
  selectedTimeZone,
  timeZones,
  settingsActions,
  localeCode,
  languages,
}: Props) => {
  return (
    <div className={styles.settingsPopup}>
      <div className={styles.block}>
        <div className={styles.header}>{i18n.value('settings.header')}</div>
      </div>

      <div className={styles.block}>
        <div className={styles.subTitle}>{i18n.value('settings.timeZone')}</div>

        <TimeZoneSelect
          timeZones={timeZones}
          selectedTimeZone={selectedTimeZone}
          handleTimeZoneChange={settingsActions.setTimeZone}
        />
      </div>

      <div className={styles.block}>
        <div className={styles.subTitle}>{i18n.value('settings.language')}</div>

        <LanguageSelect
          localeCode={localeCode}
          languages={languages}
          handleLanguageChange={settingsActions.setLocale}
        />
      </div>
    </div>
  );
};

export default connect(SettingsPopup);
