import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { mobileFilterStagesSelectors, mobileFilterStagesThunks } from 'packages/team-standings';

const mapStateToProps = (state) => {
  return {
    defaultStageTabId: mobileFilterStagesSelectors.getDefaultStageTabId(state),
    defaultFilterTabId: mobileFilterStagesSelectors.getDefaultFilterTabId(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    mobileFilterStagesActions: bindActionCreators(mobileFilterStagesThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
