const switchButtonState = (
  gridElement: HTMLDivElement,
  leftButtonElement: HTMLDivElement,
  rightButtonElement: HTMLDivElement,
  scrollLeft: number,
) => {
  if (scrollLeft <= 0) {
    leftButtonElement.style.opacity = '0';
  } else {
    leftButtonElement.style.opacity = '1';
  }

  if (Math.round(scrollLeft + gridElement.clientWidth) >= gridElement.scrollWidth) {
    rightButtonElement.style.opacity = '0';
  } else {
    rightButtonElement.style.opacity = '1';
  }
};

export default switchButtonState;
