import { useEffect } from 'react';
import { ScrollUtils } from 'packages/utils';

const useScroll = (scrollContainerRef, activeDateTime) => {
  const activeDateItemId = String(activeDateTime.toMillis());

  useEffect(() => {
    ScrollUtils.scrollHorizontallyByIdToCenter(activeDateItemId);
  }, [activeDateItemId]);

  useEffect(() => {
    const scrollContainerEl = scrollContainerRef.current;

    addDragListeners(scrollContainerEl);

    return () => removeDragListeners(scrollContainerEl);
  }, []);
};

const addDragListeners = (scrollContainerEl: HTMLDivElement) => {
  let isDown = false;
  let isDragging = false;
  let startX;
  let scrollLeft;

  const disableDragMode = () => {
    isDown = false;
    isDragging = false;
    scrollContainerEl.classList.remove('active');
  };

  const enableDragMode = (e) => {
    e.preventDefault();
    isDragging = true;
    isDown = true;
    scrollContainerEl.classList.add('active');
    startX = e.pageX - scrollContainerEl.offsetLeft;
    scrollLeft = scrollContainerEl.scrollLeft;
  };

  const moveScroll = (e) => {
    const x = e.pageX - scrollContainerEl.offsetLeft;
    const walk = x - startX;
    scrollContainerEl.scrollLeft = scrollLeft - walk;
  };

  scrollContainerEl.addEventListener('dragstart', (e) => enableDragMode(e));

  scrollContainerEl.addEventListener('dragend', disableDragMode);

  scrollContainerEl.addEventListener('mouseleave', disableDragMode);

  scrollContainerEl.addEventListener('mouseup', (e) => {
    if (isDragging) {
      e.stopImmediatePropagation();
      disableDragMode();
    }
  });

  scrollContainerEl.addEventListener('mousemove', (e) => {
    if (isDown && isDragging) {
      moveScroll(e);
    }
  });
};

const removeDragListeners = (scrollContainerEl: HTMLDivElement) => {
  const scrollContainerElClone = scrollContainerEl.cloneNode(true);
  if (scrollContainerEl.parentNode) {
    scrollContainerEl.parentNode.replaceChild(scrollContainerElClone, scrollContainerEl);
  }
};

export default useScroll;
