import { Team } from 'types';

import { Interfaces } from '../../types';
import getPath from './getPath';

export const getData = (state): Interfaces.Teams.Data => {
  return getPath(state).data;
};

export const getTeams = (state): Team[] => {
  return getData(state).teams;
};

export const getTransferHistory = (state): Interfaces.Teams.TransferHistory[] => {
  return getData(state).transferHistory;
};
