import { loadEventById } from 'packages/rest/api/Events';

import { mapSeasonEvents } from '../../services';
import { updateLiveEvents } from '../../actions';

export const handleRemovedLiveEvents = (data) => async (dispatch) => {
  const updatedEvents = await Promise.all(
    Object.keys(data).map(async (eventId) => {
      const { data } = await loadEventById({ eventId });

      return data.results;
    }),
  );

  const mappedEvents = mapSeasonEvents(updatedEvents).reduce(
    (acc, curr) => ({ ...acc, [curr.id]: curr }),
    {},
  );

  dispatch(updateLiveEvents({ events: mappedEvents }));
};
