import * as seasonNewcomersActions from './actions';
import * as seasonNewcomersConstants from './constants';
import * as seasonNewcomersThunks from './thunks';
import * as seasonNewcomersSelectors from './selectors';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  seasonNewcomersActions,
  seasonNewcomersConstants,
  seasonNewcomersThunks,
  seasonNewcomersSelectors,
  createReducer,
};
