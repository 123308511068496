import PeriodStart from './PeriodStart';
import Substitution from './Substitution';
import Minute from './Minute';
import Corner from './Corner';
import Goal from './Goal';
import Offside from './Offside';
import Card from './Card';
import Shot from './Shot';
import InjuryTime from './InjuryTime';
import Injury from './Injury';
import PenaltyRewarded from './PenaltyRewarded';
import PenaltyMissed from './PenaltyMissed';

export default {
  PeriodStart,
  Substitution,
  InjuryTime,
  Offside,
  Minute,
  Injury,
  Corner,
  Goal,
  Card,
  Shot,
  PenaltyRewarded,
  PenaltyMissed,
};
