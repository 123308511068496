import instance from 'packages/rest/axios';

import { DetailsParams } from '../types';

export const loadDetails = ({ playerId }: DetailsParams) => {
  return instance({
    method: 'get',
    url: `/players/${playerId}/`,
  });
};
