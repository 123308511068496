import { Season, Tournament } from 'types';

import getState from './getState';
import { SeasonDetails } from '../types';

export const getData = (state): SeasonDetails => {
  return getState(state).data;
};

export const getSelectedSeason = (state): Season | undefined => {
  return getData(state)?.selectedSeason;
};

export const getTournament = (state): Tournament | undefined => {
  return getData(state)?.uniqueTournament;
};
