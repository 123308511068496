import getState from './getState';

export const getActiveSport = (state) => {
  return getState(state).activeSport;
};

export const getActiveSportId = (state) => {
  return getActiveSport(state).id;
};

export const getActiveSportName = (state) => {
  return getActiveSport(state).name;
};
