import React, { useEffect, memo } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams, Outlet } from 'react-router-dom';

import { StadiumDetails } from 'packages/stadium-details-ui';
import { TimeZone } from 'types';

import connect from './connect';
import styles from './styles.scss';

type Props = {
  tournamentsActions: ActionCreatorsMapObject;
  stadiumDetailsActions: ActionCreatorsMapObject;
  commonActions: ActionCreatorsMapObject;
  timeZone: TimeZone;
};

const Stadium = ({ tournamentsActions, commonActions, stadiumDetailsActions, timeZone }: Props) => {
  const { stadiumId } = useParams();

  useEffect(() => {
    tournamentsActions.loadTournamentList(stadiumId);
    stadiumDetailsActions.loadDetails({ stadiumId });

    return () => {
      commonActions.cleanSummary();
      stadiumDetailsActions.cleanDetails();
    };
  }, [timeZone.value, stadiumId]);

  return (
    <div className={styles.stadium}>
      <StadiumDetails.Mobile />
      <div className={styles.contentWrapper}>
        <Outlet />
      </div>
    </div>
  );
};

export default connect(memo(Stadium));
