import { playersActions } from '../../actions';

interface Pagination {
  offset?: number;
  limit?: number;
}

const updatePagination = (pagination: Pagination) => (dispatch) => {
  dispatch(playersActions.updatePaginationSuccess(pagination));
};

export default updatePagination;
