import Dexie from 'dexie';

import { keyValueDatabase } from 'packages/storage';
import { Tables } from 'configure/storage/types';
import { Event } from 'types';

import loadLatestFavoritesFromStorage from './loadLatestFavoritesFromStorage';

import { updateFavoritesStart, updateFavoritesSuccess, updateFavoritesFailure } from '../actions';

const putFavorites = (events: Event[]) => async (dispatch) => {
  try {
    dispatch(updateFavoritesStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoriteEventsModel = dexieInstance.table(Tables.FAVORITE_EVENTS);
    const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);

    await dexieInstance.transaction('rw', [favoriteEventsModel, favoritesModel], () =>
      Promise.all(
        events.map(async (event) =>
          Promise.all([
            favoriteEventsModel.update(event.id, event),
            favoritesModel.update(event.id, event),
          ]),
        ),
      ),
    );

    await dispatch(loadLatestFavoritesFromStorage());

    dispatch(updateFavoritesSuccess());
  } catch (e) {
    dispatch(updateFavoritesFailure(e));
  }
};

export default putFavorites;
