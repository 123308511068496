import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadInitialPlayersStart = createAction(`${NAMESPACE}LOAD_INITIAL_PLAYERS_START`);
export const loadInitialPlayersSuccess = createAction(`${NAMESPACE}LOAD_INITIAL_PLAYERS_SUCCESS`);
export const loadInitialPlayersFailure = createAction(`${NAMESPACE}LOAD_INITIAL_PLAYERS_FAILURE`);

export const loadPlayersPageStart = createAction(`${NAMESPACE}LOAD_MORE_PLAYERS_START`);
export const loadPlayersPageSuccess = createAction(`${NAMESPACE}LOAD_MORE_PLAYERS_SUCCESS`);
export const loadPlayersPageFailure = createAction(`${NAMESPACE}LOAD_MORE_PLAYERS_FAILURE`);

export const updatePaginationSuccess = createAction(`${NAMESPACE}UPDATE_PAGINATION_SUCCESS`);

export const cleanPlayers = createAction(`${NAMESPACE}CLEAN_PLAYERS`);
