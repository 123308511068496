exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._WIHH-tML{width:100%;height:100%;max-width:207px;position:relative}._WIHH-tML ._1VFTW7F1{position:absolute;top:0;left:0;width:100%;height:100%;z-index:2}._WIHH-tML ._1P5pse3K{position:absolute;top:25%;left:0;right:0;margin:auto}._WIHH-tML ._3LxaW0NK{position:absolute;bottom:-5px}._WIHH-tML ._BPVrbazB{position:absolute;top:-2px;left:0;right:0;margin:auto}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Lineup/components/PlayerCell/styles.scss"],"names":[],"mappings":"AAEA,WAEI,UAAW,CACX,WAAY,CACZ,eAAgB,CAChB,iBAAkB,CALtB,sBAQM,iBAAkB,CAElB,KAAM,CACN,MAAO,CAEP,UAAW,CACX,WAAY,CAEZ,SAAU,CAhBhB,sBAoBM,iBAAkB,CAClB,OAAQ,CACR,MAAO,CACP,OAAQ,CACR,WAAY,CAxBlB,sBA4BM,iBAAkB,CAClB,WAAY,CA7BlB,sBAiCM,iBAAkB,CAClB,QAAS,CACT,MAAO,CACP,OAAQ,CACR,WAAY","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .player-cell {\n    width: 100%;\n    height: 100%;\n    max-width: 207px;\n    position: relative;\n\n    .hover-area {\n      position: absolute;\n\n      top: 0;\n      left: 0;\n\n      width: 100%;\n      height: 100%;\n\n      z-index: 2;\n    }\n\n    .player-badge {\n      position: absolute;\n      top: 25%;\n      left: 0;\n      right: 0;\n      margin: auto;\n    }\n\n    .player-name-container {\n      position: absolute;\n      bottom: -5px;\n    }\n\n    .player-happenings-container {\n      position: absolute;\n      top: -2px;\n      left: 0;\n      right: 0;\n      margin: auto;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"player-cell": "_WIHH-tML",
	"playerCell": "_WIHH-tML",
	"hover-area": "_1VFTW7F1",
	"hoverArea": "_1VFTW7F1",
	"player-badge": "_1P5pse3K",
	"playerBadge": "_1P5pse3K",
	"player-name-container": "_3LxaW0NK",
	"playerNameContainer": "_3LxaW0NK",
	"player-happenings-container": "_BPVrbazB",
	"playerHappeningsContainer": "_BPVrbazB"
};