import instance from '../../../axios';
import { LoadAllEventsParams } from '../types';

export const loadLiveEvents = ({ activeSportId, categoryId }: LoadAllEventsParams) => {
  return instance({
    method: 'get',
    url: '/sport_events/live/',
    params: {
      category: categoryId,
      sport: activeSportId,
    },
  });
};
