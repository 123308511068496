import { handleActions, ReducerMap } from 'redux-actions';

import { loadTimeLineSuccess, cleanTimeLineSuccess } from '../actions';

const defaultState = [];

const reducerMap: ReducerMap<any, any> = {
  [`${loadTimeLineSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${cleanTimeLineSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
