import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';

const defaultState = {
  periods: [],
};

const reducerMap: ReducerMap<any, any> = {
  [`${actions.loadEventSummarySuccess}`]: (state, { payload }) => {
    const { eventSummary } = payload;

    return eventSummary;
  },
  [`${combineActions(actions.loadEventSummaryFailure, actions.cleanEventSummarySuccess)}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
