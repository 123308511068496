import * as eventStandingsActions from './actions';
import * as eventStandingsThunks from './thunks';
import * as eventStandingsConstants from './constants';
import * as eventStandingsSelectors from './selectors';
import * as eventStandingsServices from './services';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  eventStandingsActions,
  eventStandingsThunks,
  eventStandingsConstants,
  eventStandingsSelectors,
  eventStandingsServices,
  createReducer,
};
