import { SeasonTopBoard } from 'packages/rest';

import { TeamsParameter, TopTeam } from '../types';
import { TEAM_API_ORDERING_BY_PARAMETERS } from '../constants';
import * as services from '../services';
import * as actions from '../actions';

export const loadSeasonTopTeams = (seasonId: number, parameter: TeamsParameter) => async (
  dispatch,
) => {
  dispatch(actions.loadTopTeamsStart());

  try {
    const { data } = await SeasonTopBoard.loadSeasonTopTeams({
      seasonId,
      ordering: services.parseOrderingParams(TEAM_API_ORDERING_BY_PARAMETERS[parameter]),
    });

    const topTeams: TopTeam[] = services.mapSeasonTopTeams(data.results);

    dispatch(actions.loadTopTeamsSuccess(topTeams));
  } catch (e) {
    dispatch(actions.loadTopTeamsFailure(e));
  }
};
