import { handleActions, ReducerMap } from 'redux-actions';

import { TopTeam } from '../../types';
import * as actions from '../../actions';

const defaultState: TopTeam[] = [];

const reducerMap: ReducerMap<TopTeam[], TopTeam[]> = {
  [`${actions.loadTopTeamsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${actions.cleanTopBoardSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<TopTeam[], TopTeam[]>(reducerMap, defaultState);
