import React, { useEffect, useRef } from 'react';
import classnames from 'classnames';

import { Figure } from 'packages/shared/components';
import { useElementOverflow } from 'packages/hooks';

import styles from './styles.scss';

type Props = {
  className?: string;
  name: string;
  countryName: string;
  city: string;
  logo: string;
};

const Description = ({ className, name, countryName, city, logo }: Props) => {
  const stadiumNameRef = useRef<HTMLSpanElement>(null);
  const isStadiumNameOverflows = useElementOverflow(stadiumNameRef);

  useEffect(() => {
    if (isStadiumNameOverflows) {
      stadiumNameRef.current?.classList.add(styles.shrink);
    }

    stadiumNameRef.current?.classList.add(styles.visible);
  }, [isStadiumNameOverflows]);

  return (
    <div className={classnames(styles.description, className)}>
      <span ref={stadiumNameRef} className={styles.stadiumName}>
        {name}
      </span>
      <span className={styles.countryLabel}>
        <Figure src={logo} className={styles.logo} />
        {countryName}, {city}
      </span>
    </div>
  );
};

export default Description;
