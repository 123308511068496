import { SeasonDetails } from 'packages/season-details/types';
import { toCamelCase } from 'packages/utils/Object.utils';
import { mapCategory } from 'packages/categories/services';
import { mapTournament } from 'packages/events/services';

export const mapSeasonDetails = (seasonDetails): SeasonDetails => {
  const {
    category,
    season,
    seasons,
    champion,
    uniqueTournament,
    statistics,
    divisionLevel,
  } = toCamelCase(seasonDetails, true);

  const { style, id: uniqueTournamentId, name: uniqueTournamentName } = uniqueTournament;
  const { id: currentSeasonId } = season;
  const { goalsByMinutes, goalsInfo, matches, cards = {} } = statistics || {};
  const { averageRed, averageYellow } = cards;

  return {
    category: mapCategory(category),
    selectedSeason: season,
    seasons,
    champion,
    uniqueTournament: mapTournament(uniqueTournament, {
      currentSeasonId,
      uniqueTournamentId,
      uniqueTournamentName,
    }),
    style: style,
    statistics: {
      goalsInfo,
      matches,
      goalsByMinutes,
      cards: {
        avgYellowCards: averageYellow,
        avgRedCards: averageRed,
      },
      divisionLevel,
    },
  };
};
