import React, { RefObject, useRef, useState, memo, useCallback, useEffect } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';
import { ActionCreatorsMapObject } from 'redux';

import { FlexiblePopover, SearchBar } from 'packages/shared/components';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';
import { debounce } from 'packages/utils/Function.utils';

import { SearchResults } from '../../../../components';
import { SEARCH_DELAY, MIN_SYMBOL_COUNT } from '../../../../constants';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  searchActions: ActionCreatorsMapObject;
  searchKey: string;
  scrollTop: number;
  className?: string;
  inputClassName?: string;
  resultsClassName?: string;
};

const Search = ({
  searchActions,
  resultsClassName,
  scrollTop = 0,
  className,
  searchKey,
  inputClassName,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const popoverContainerRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    return searchActions.clearResults;
  }, []);

  const clearSearch = useCallback(() => {
    searchActions.setSearchKey('');
  }, []);

  const handleChange = (searchKey: string) => {
    if (searchKey.length >= MIN_SYMBOL_COUNT) {
      searchActions.loadResults({ searchKey });
    }

    searchActions.setSearchKey(searchKey);
  };

  const debouncedHandleChange = useCallback(debounce(handleChange, SEARCH_DELAY), []);

  const handleFocus = useCallback(() => setIsFocused(true), []);

  const handleBlur = useCallback(() => setIsFocused(false), []);

  return (
    <FlexiblePopover
      useOverlay={false}
      closeOnClickOutside
      preserveOnTargetInteraction
      justify={PopoverJustify.CenterStart}
      align={PopoverAlign.Bottom}
      onClose={clearSearch}
      isOpen={searchKey.length >= MIN_SYMBOL_COUNT}
      popoverComponent={() => (
        <div ref={popoverContainerRef} className={styles.searchPopoverContainer}>
          <SearchResults
            onTournamentClick={clearSearch}
            className={resultsClassName}
            scrollTop={scrollTop}
          />
        </div>
      )}
    >
      <div className={classnames(styles.searchContainer, className)}>
        <SearchBar
          placeholder={i18n.value('header.search.placeholder')}
          value={searchKey}
          onChange={debouncedHandleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onClear={clearSearch}
          className={classnames(
            styles.searchBar,
            { [styles.extended]: isFocused || searchKey },
            inputClassName,
          )}
        />
      </div>
    </FlexiblePopover>
  );
};

export default connect(memo(Search));
