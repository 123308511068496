import { TimeZone } from 'types';
import { SelectItem } from 'packages/shared/components/Select/types';

import getTimeZoneSelectItem from './getTimeZoneSelectItem';

const getTimeZoneSelectItems = (timeZones: TimeZone[]): SelectItem[] => {
  return timeZones.map((timeZone) => getTimeZoneSelectItem(timeZone));
};

export default getTimeZoneSelectItems;
