import PropTypes from 'prop-types';

import connect from './connect';

const Media = ({ screen, items, mediaScreen, renderComponent, children }) => {
  if (Array.isArray(screen) && screen.includes(mediaScreen)) {
    return children || renderComponent();
  } else if (screen === mediaScreen) {
    return children || renderComponent();
  } else if (items) {
    const renderItemComponent = items[mediaScreen];

    return renderItemComponent && renderItemComponent();
  }

  return null;
};

Media.defaultProps = {
  screen: '',
  mediaScreen: '',
  items: null,
};

Media.propTypes = {
  screen: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  mediaScreen: PropTypes.string,
  renderComponent: PropTypes.func,
  items: PropTypes.shape({
    mobile: PropTypes.func,
    tablet: PropTypes.func,
    desktop: PropTypes.func,
  }),
};

export default connect(Media);
