import { DesktopNarrowPage } from '../types';

export const ROOT = {
  [DesktopNarrowPage.Home]: '/',
  [DesktopNarrowPage.Football]: '/football',
  [DesktopNarrowPage.FootballSeason]: '/football/unique-tournament/:tournamentId/season/:seasonId',
  [DesktopNarrowPage.FootballTeam]: '/football/team/:teamId',
  [DesktopNarrowPage.FootballCategory]: '/football/category/:categoryId',
  [DesktopNarrowPage.FootballPlayer]: '/football/player/:playerId',
  [DesktopNarrowPage.FootballStadium]: '/football/stadium/:stadiumId',
  [DesktopNarrowPage.FootballMatch]: '/football/match/:eventId',
  [DesktopNarrowPage.NotFound]: '*',
};

export const SEASON_CHILDREN = {
  [DesktopNarrowPage.FootballSeasonHome]: '',
  [DesktopNarrowPage.FootballSeasonSummary]: 'summary',
  [DesktopNarrowPage.FootballSeasonGames]: 'games',
  [DesktopNarrowPage.FootballSeasonStandings]: 'standings',
  [DesktopNarrowPage.FootballSeasonTeamStatistics]: 'team-statistics',
  [DesktopNarrowPage.FootballSeasonPlayerStatistics]: 'player-statistics',
  [DesktopNarrowPage.FootballSeasonStadiums]: 'stadiums',
  [DesktopNarrowPage.FootballSeasonHistory]: 'history',
  [DesktopNarrowPage.FootballSeasonComingSoon]: '*',
};

export const STADIUM_CHILDREN = {
  [DesktopNarrowPage.FootballStadiumHome]: '',
  [DesktopNarrowPage.FootballStadiumSummary]: 'unique-tournament/:tournamentId/season/:seasonId',
};

export const TEAM_CHILDREN = {
  [DesktopNarrowPage.FootballTeamHome]: '',
  [DesktopNarrowPage.FootballTeamSummary]: 'summary',
  [DesktopNarrowPage.FootballTeamSummaryDescription]:
    'summary/unique-tournament/:tournamentId/season/:seasonId',
  [DesktopNarrowPage.FootballTeamStandings]:
    'standings/unique-tournament/:tournamentId/season/:seasonId',
  [DesktopNarrowPage.FootballTeamPlayerStatistics]:
    'player-statistics/unique-tournament/:tournamentId/season/:seasonId',
  [DesktopNarrowPage.FootballTeamGames]: 'games',
  [DesktopNarrowPage.FootballTeamSquad]: 'squad',
};

export const PLAYER_CHILDREN = {
  [DesktopNarrowPage.FootballPlayerHome]: '',
  [DesktopNarrowPage.FootballPlayerSummary]: 'summary',
  [DesktopNarrowPage.FootballPlayerGames]: 'games',
};
