import { IconType } from 'packages/shared/components/Icon/types';

import { PlayerHappeningType } from '../types';

export const PLAYER_HAPPENING_ICON_MAP = {
  [PlayerHappeningType.Goal]: IconType.Goal,
  [PlayerHappeningType.OwnGoal]: IconType.OwnGoal,
  [PlayerHappeningType.PenaltyGoal]: IconType.PenaltyGoal,
  [PlayerHappeningType.YellowCard]: IconType.YellowCard,
  [PlayerHappeningType.RedCard]: IconType.RedCard,
  [PlayerHappeningType.YellowRedCard]: IconType.YellowRedCard,
  [PlayerHappeningType.Substitution]: IconType.SubstitutionLight,
  [PlayerHappeningType.SubstitutionIn]: IconType.SubstitutionIn,
  [PlayerHappeningType.SubstitutionOut]: IconType.SubstitutionOut,
  [PlayerHappeningType.Assist]: IconType.Assist,
};
