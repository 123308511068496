import { toCamelCase } from 'packages/utils/Object.utils';

import mapCupRounds from './mapCupRounds';
import { Stage, StageType } from '../../../types';

const mapTournamentBrackets = (tournamentBrackets): Stage[] => {
  return tournamentBrackets.map((tournamentBracket) => {
    const { id, name, start, cupRounds, tournament, seasonId, uniqueTournamentId } = toCamelCase(
      tournamentBracket,
    );

    return {
      id,
      name,
      startDate: start,
      type: StageType.Brackets,
      data: {
        rounds: mapCupRounds(cupRounds, { tournament, seasonId, uniqueTournamentId }),
      },
    };
  });
};

export default mapTournamentBrackets;
