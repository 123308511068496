import React, { memo } from 'react';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';
import { MinutesPlayed } from 'packages/events-ui/containers/Event/components';

import styles from './styles.scss';

type Props = {
  substitutedIn: boolean;
  substitutedOut: boolean;
  minutesPlayed: number;
};

const PlayerSubstitution = ({ substitutedIn, substitutedOut, minutesPlayed }: Props) => {
  return (
    <div className={styles.wrapper}>
      {substitutedIn && <Icon className={styles.iconIn} id={IconType.SubstitutionIn} />}

      <MinutesPlayed
        className={styles.minutes}
        minutesPlayed={minutesPlayed}
        substitutedIn={substitutedIn}
      />

      {substitutedOut && <Icon className={styles.iconOut} id={IconType.SubstitutionOut} />}
    </div>
  );
};

export default memo(PlayerSubstitution);
