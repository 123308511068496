import { loadInitialEvents } from '../events';
import { loadTeamsFilter } from '../filters';

const loadPage = (playerId: number) => async (dispatch) => {
  dispatch(loadInitialEvents(playerId));

  dispatch(loadTeamsFilter(playerId));
};

export default loadPage;
