import React, { memo } from 'react';
import classnames from 'classnames';

import { Event, Tournament } from 'types';
import { DesktopPage } from 'router/types';
import { EventSeries as EventSeriesType } from 'packages/event-details/types';
import { useNavigation } from 'packages/hooks';
import { DynamicEventScore, EventDetailsStatus } from 'packages/shared/modules';
import { EventSeries } from 'packages/shared/components';
import { PopoverInvokeTrigger } from 'packages/shared/components/FlexiblePopover/types';

import LinkToMatchPage from '../LinkToMatchPage';
import { EventDetailsTeam } from './components';
import styles from './styles.scss';

type Props = {
  event: Event;
  onTournamentClick: (tournament: Tournament) => void;
  eventSeries: EventSeriesType;
  onFirstLegClick: (event?: Event) => void;
  popoverInvokeTrigger?: PopoverInvokeTrigger;
  onCategoryClick?: (id: string) => void;
};

const EventDetailsHeader = ({
  event,
  eventSeries,
  onFirstLegClick,
  onTournamentClick,
  popoverInvokeTrigger,
  onCategoryClick,
}: Props) => {
  const { appNavigate } = useNavigation();

  const { teams, category, tournament, isLive } = event;
  const { name } = tournament;
  const [home, away] = teams;

  const handleCategoryClick = () => {
    if (onCategoryClick) {
      onCategoryClick(category.id.toString());
    } else {
      appNavigate(DesktopPage.FootballCategory, { categoryId: category.id });
    }
  };

  const handleTournamentClick = () => {
    onTournamentClick(tournament);
  };

  return (
    <div className={classnames(styles.eventDetailsHeader, { [styles.live]: isLive })}>
      <div className={styles.upperBlock}>
        <div className={styles.tournament}>
          <span onClick={handleCategoryClick} className={styles.category}>
            {category.name} /{' '}
          </span>
          <span onClick={handleTournamentClick} className={styles.tournamentName}>
            {name}
          </span>
        </div>
      </div>

      <div className={styles.centerBlock}>
        <EventDetailsTeam event={event} team={home} />

        <div className={styles.statusBlock}>
          <DynamicEventScore className={styles.score} event={event} />
          <EventDetailsStatus popoverInvokeTrigger={popoverInvokeTrigger} event={event} />
          <EventSeries
            activeEvent={event}
            eventSeries={eventSeries}
            onFirstLegClick={onFirstLegClick}
            className={styles.eventSeries}
          />
        </div>

        <EventDetailsTeam event={event} team={away} isReversed />
      </div>

      <LinkToMatchPage className={styles.link} />
    </div>
  );
};

export default memo(EventDetailsHeader);
