import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { appSelectors } from 'modules/app';
import { tournamentsThunks, commonThunks } from 'packages/stadium-summary';
import { stadiumDetailsThunks } from 'packages/stadium-details';
import { settingsSelectors } from 'packages/settings';

const mapStateToProps = (state) => {
  return {
    mediaScreen: appSelectors.getMediaScreen(state),
    timeZone: settingsSelectors.getTimeZone(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    tournamentsActions: bindActionCreators(tournamentsThunks, dispatch),
    stadiumDetailsActions: bindActionCreators(stadiumDetailsThunks, dispatch),
    commonActions: bindActionCreators(commonThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
