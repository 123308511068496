import { handleActions, ReducerMap, combineActions } from 'redux-actions';

import { allEventsActions } from 'packages/events';

import { ActiveEventStatus } from '../types';
import {
  closeEventDetailsSuccess,
  loadEventDetailsStart,
  loadEventDetailsFailure,
  loadEventDetailsSuccess,
} from '../actions';

const { loadAllEventsStart } = allEventsActions;
const defaultState: ActiveEventStatus = {
  isOpen: false,
  isLoading: false,
};

const reducerMap: ReducerMap<ActiveEventStatus, void> = {
  [`${loadEventDetailsStart}`]: () => {
    return { isOpen: true, isLoading: true };
  },
  [`${combineActions(loadEventDetailsFailure, loadEventDetailsSuccess)}`]: (state) => {
    return { ...state, isLoading: false };
  },
  [`${combineActions(closeEventDetailsSuccess, loadAllEventsStart)}`]: () => {
    return { isOpen: false, isLoading: false };
  },
};

export default handleActions(reducerMap, defaultState);
