export enum IconType {
  En = 'en',
  Ru = 'ru',
  Events = 'events',
  Leagues = 'league',
  DefaultLeague = 'default-league',
  Star = 'star',
  StarOutline = 'star-outline',
  SearchLogo = 'search-logo',
  Cross = 'cross',
  Clock = 'clock',
  CrossDesktop = 'cross-desktop',
  OlimpLogo = 'olimp-logo',
  Arrow = 'arrow',
  ArrowExtended = 'arrow-extended',
  ArrowDropdown = 'arrow-dropdown',
  CheckMark = 'check-mark',
  Burger = 'burger',
  Drag = 'drag',
  Calendar = 'calendar',
  Offside = 'offside',

  FootballField = 'football-field',
  FootballFieldHalf = 'football-field-half',

  Suspended = 'suspended',
  Cancelled = 'cancelled',
  Postponed = 'postponed',

  Miss = 'miss',
  SubstitutionLight = 'substitution-light',
  SubstitutionDark = 'substitution-dark',
  RedCard = 'red-card',
  YellowCard = 'yellow-card',
  YellowRedCard = 'yellow-red-card',
  Goal = 'goal',
  OwnGoal = 'own-goal',
  MatchShotsGoal = 'match-shots-goal',
  NotKicked = 'not-kicked',
  Injury = 'injury',
  InjuryTime = 'injury-time',
  NotFound = 'not-found',
  Settings = 'settings',
  EventLink = 'event-link',
  Video = 'video',
  ComingSoon = 'coming-soon',
  PeriodStart = 'periodstart',
  Corner = 'corner',
  Shot = 'shot',
  PenaltyRewarded = 'penalty-rewarded',
  PenaltyMissed = 'penalty-missed',
  PenaltyGoal = 'penalty-goal',
  Configuration = 'configuration',
  Jersey = 'jersey',
  Stadium = 'stadium',

  FacebookIconLink = 'facebook-icon-link',
  TwitterIconLink = 'twitter-icon-link',
  YoutubeIconLink = 'youtube-icon-link',

  SubstitutionIn = 'substitution-in',
  SubstitutionOut = 'substitution-out',
  Assist = 'assist',
  Change = 'change',

  RoundedCross = 'rounded-cross',

  NavigationFavorites = 'navigation-favorites',
  NavigationEvents = 'navigation-events',
  NavigationLeagues = 'navigation-leagues',

  Bench = 'bench',

  Facts = 'facts',
}
