import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const EventLoadingSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <Skeleton height={16} width={16} marginRight={8} variant="circular" />
        <Skeleton height={14} width={50} marginRight={8} variant="rectangular" />
        <Skeleton height={14} width={30} marginRight={8} variant="rectangular" />
        <Skeleton height={14} width={14} variant="rectangular" />
      </div>
      <Skeleton height={14} width={14} variant="rectangular" />
    </div>
  );
};

export default memo(EventLoadingSkeleton);
