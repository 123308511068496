import React, { memo } from 'react';
import classnames from 'classnames';

import { Figure } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Event } from 'types';

import styles from '../../styles.scss';
import connect from './connect';

type Props = {
  teamId: number;
  activeUniqueTournamentId?: string | number;
  activeSeasonId?: string | number;
  logo: string;
  selectedEvent: Event | null;
};

const HeadToHeadHeaderCell = ({
  teamId,
  activeUniqueTournamentId,
  activeSeasonId,
  logo,
  selectedEvent,
}: Props) => {
  const awayTeam = selectedEvent?.teams[1];
  const isTeamHighlighted = awayTeam?.id === teamId;

  const { appNavigate } = useNavigation();

  const handleTeamClick = () => {
    if (activeUniqueTournamentId && activeSeasonId) {
      appNavigate(DesktopPage.FootballTeamSummaryDescription, {
        teamId,
        uniqueTournamentId: activeUniqueTournamentId,
        seasonId: activeSeasonId,
      });
    } else {
      appNavigate(DesktopPage.FootballTeam, { teamId });
    }
  };

  return (
    <div
      onClick={handleTeamClick}
      className={classnames(styles.centered, styles.headerCell, {
        [styles.active]: isTeamHighlighted,
      })}
    >
      <Figure src={logo} className={styles.teamLogo} />
    </div>
  );
};
export default connect(memo(HeadToHeadHeaderCell));
