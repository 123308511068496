export default {
  'common.information.termsOfUse': 'Terms of use',
  'common.information.privacyPolicy': 'Privacy policy',
  'common.information.aboutUs': 'About Us',
  'common.notFound': 'Not found',
  'common.apply': 'Apply',
  'common.cancel': 'Cancel',
  'common.notAvailable': 'N/A',
  'common.ranking': 'Ranking',
  'common.last': 'Last',
  'common.form': 'Form',
  'common.goals': 'G',
  'common.emptyText': 'No data available',
  'common.h2h': 'Head-2-Head',
  'common.h2h.compact': 'H2H',
  'common.short.h2h': 'H2H',
  'common.short.points': 'Pts',
  'common.short.matchesPlayed': 'MP',
  'common.short.win': 'W',
  'common.short.loss': 'L',
  'common.short.draw': 'D',
  'common.short.goalDifference': 'GD',
  'common.roundName': 'round {0}',
  'common.pagination.resultsAmountLabel': '{0} of {1}',
  'common.pagination.limit_label': 'Show',
  'common.yearLabel': {
    one: '1 year',
    other: '{0} years',
  },
  'common.all': 'All',

  'common.pagination.resultsLabel': { one: 'result', other: 'results' },
  'common.pagination.gamesLabel': { one: 'game', other: 'games' },
  'common.pagination.playersLabel': { one: 'player', other: 'players' },
  'common.pagination.teamsLabel': { one: 'team', other: 'teams' },
  'common.pagination.stadiumsLabel': { one: 'stadium', other: 'stadiums' },
  'common.pagination.seasonsLabel': { one: 'season', other: 'seasons' },

  'common.unit.kilograms.short': 'kg',
  'common.unit.centimeters.short': 'cm',
  'common.unit.meters.short': 'm',

  'common.matchAttendance': 'Match attendance',

  'common.tableParameter.short.MATCHES_PLAYED': 'MP',
  'common.tableParameter.short.WIN': 'W',
  'common.tableParameter.short.LOSS': 'L',
  'common.tableParameter.short.DRAW': 'D',
  'common.tableParameter.short.GOAL_DIFFERENCE': 'GD',
  'common.tableParameter.short.POINTS': 'Pts',
  'common.tableParameter.short.GAMES_PLAYED': 'GP',
  'common.tableParameter.short.GOALS': 'G',
  'common.tableParameter.short.ASSISTS': 'A',
  'common.tableParameter.short.GOAL_POINTS': 'G+A',
  'common.tableParameter.short.TOTAL_SHOTS': 'Sh',
  'common.tableParameter.short.SHOTS_ON_GOAL_PER_GAME': 'ShG/GP',
  'common.tableParameter.short.SHOTS_ON_GOAL': 'ShOnG',
  'common.tableParameter.short.OFFSIDE': 'Off',
  'common.tableParameter.short.PENALTY_SUCCESS_COUNT': 'Pen',
  'common.tableParameter.short.GOALS_BY_HEAD': 'Head',
  'common.tableParameter.short.GOAL_ATTEMPTS': 'Sh/GP',
  'common.tableParameter.short.BALL_POSSESSION': 'Pos%/GP',
  'common.tableParameter.short.AVERAGE': 'AVG',
  'common.tableParameter.short.CAPACITY': 'Cap',
  'common.tableParameter.short.OCCUPANCY': 'Full%',
  'common.tableParameter.short.player': 'player',
  'common.tableParameter.short.team': 'team',
  'common.tableParameter.short.venue': 'venue',

  'common.tableParameter.extended.MATCHES_PLAYED': 'Matches played',
  'common.tableParameter.extended.WIN': 'Wins',
  'common.tableParameter.extended.LOSS': 'Losses',
  'common.tableParameter.extended.DRAW': 'Draws',
  'common.tableParameter.extended.GOAL_DIFFERENCE': 'Goal difference',
  'common.tableParameter.extended.POINTS': 'Points',
  'common.tableParameter.extended.GAMES_PLAYED': 'Games played',
  'common.tableParameter.extended.GOALS': 'Goals',
  'common.tableParameter.extended.ASSISTS': 'Assists',
  'common.tableParameter.extended.GOAL_POINTS': 'Goals + Assists',
  'common.tableParameter.extended.TOTAL_SHOTS': 'Total shots',
  'common.tableParameter.extended.SHOTS_ON_GOAL_PER_GAME': 'Shots on goal per game',
  'common.tableParameter.extended.SHOTS_ON_GOAL': 'Shots on goal',
  'common.tableParameter.extended.OFFSIDE': 'Offsides',
  'common.tableParameter.extended.PENALTY_SUCCESS_COUNT': 'Penalty goals',
  'common.tableParameter.extended.GOALS_BY_HEAD': 'Goals by head',
  'common.tableParameter.extended.GOAL_ATTEMPTS': 'Shots per game',
  'common.tableParameter.extended.BALL_POSSESSION': 'Ball possession',
  'common.tableParameter.extended.AVERAGE': 'Average attendance',
  'common.tableParameter.extended.CAPACITY': 'Capacity',
  'common.tableParameter.extended.OCCUPANCY': '% of fullness',
  'common.tableParameter.extended.YELLOW_CARDS': 'Yellow cards',
  'common.tableParameter.extended.YELLOW_RED_CARDS': 'Yellow/red cards',
  'common.tableParameter.extended.RED_CARDS': 'Direct Red cards',

  'common.statisticsBlock.parameter.GamesPlayed': 'Games played',
  'common.statisticsBlock.parameter.GoalsScored': 'Goals scored',
  'common.statisticsBlock.parameter.GoalsConceded': 'Goals conceded',
  'common.statisticsBlock.parameter.GoalsConversion': 'Goals conversion',
  'common.statisticsBlock.parameter.Started': 'Started',
  'common.statisticsBlock.parameter.MinutesPlayed': 'Minutes played',
  'common.statisticsBlock.parameter.Goals': 'Goals',
  'common.statisticsBlock.parameter.GoalPoints': 'Goal points',
  'common.statisticsBlock.parameter.Assists': 'Assists',
  'common.statisticsBlock.parameter.Offside': 'Offside',
  'common.statisticsBlock.parameter.CleanSheets': 'Clean sheets',
  'common.statisticsBlock.parameter.Penalties': 'Penalties',
  'common.statisticsBlock.parameter.PenaltyGoals': 'Penalty goals',
  'common.statisticsBlock.parameter.GoalsByHead': 'Goals by head',
  'common.statisticsBlock.parameter.FootGoals': 'Foot goals',
  'common.statisticsBlock.parameter.TotalShots': 'Total shots',
  'common.statisticsBlock.parameter.ShotsOnGoal': 'Shots on goal',
  'common.statisticsBlock.parameter.ShotsOffGoal': 'Shots off goal',
  'common.statisticsBlock.parameter.ShotsOnPost': 'Shots on post',
  'common.statisticsBlock.parameter.ShootingEfficiency': 'Shooting efficiency',
  'common.statisticsBlock.parameter.Possession': 'Possession',
  'common.statisticsBlock.parameter.CornerKicks': 'Corner kicks',
  'common.statisticsBlock.parameter.FreeKicks': 'Free kicks',
  'common.statisticsBlock.parameter.Offsides': 'Offsides',
  'common.statisticsBlock.parameter.YellowCards': 'Yellow cards',
  'common.statisticsBlock.parameter.YellowRedCards': 'Yellow → red cards',
  'common.statisticsBlock.parameter.DirectRedCards': 'Direct red cards',
  'common.statisticsBlock.parameter.SubstitutedIn': 'Substituted in',
  'common.statisticsBlock.parameter.SubstitutedOut': 'Substituted out',

  'common.tournamentSeasonSelect.title': 'Show details for',
  'common.tournamentSeasonSelect.label.league': 'league',
  'common.tournamentSeasonSelect.label.season': 'season',

  'common.expandableBlock.label.default.showMore': 'Show more',
  'common.expandableBlock.label.default.showLess': 'Short view',

  'common.sport.FOOTBALL': 'Football',

  'header.search.placeholder': 'Search',
  'header.search.noResults': 'No results found',

  'multipleSelect.label': '{0} selected: {1}',
  'multipleSelect.allLabel': 'All {0}',
  'roundSelect.label': 'rounds',
  'teamSelect.label': 'teams',

  'settings.header': 'App settings',
  'settings.timeZone': 'Time zone',
  'settings.language': 'Interface language',
  'languageSelectPicker.language.en': 'English',
  'languageSelectPicker.language.ru': 'Русский',

  'categories.title': 'Categories',
  'otherCategories.title': 'Other categories',

  'timerCountdown.days': {
    one: '1 day',
    other: '{0} days',
  },

  'leftBar.favoriteTournaments.title': 'My leagues',
  'leftBar.favoriteTournaments.noItems': 'Add some league to favorites to see it here.',
  'leftBar.favoriteTeams.title': 'My teams',
  'leftBar.favoriteTeams.noItems': 'Use ☆ near team names to add them here.',
  'leftBar.popularTournaments.title': 'Popular leagues',
  'leftBar.popularTournaments.noItems': 'No leagues',
  'topCountriesContent.all.title': 'All',
  'topCountriesContent.popular.title': 'Popular',
  'toggleButton.show': 'Show all',
  'toggleButton.hide': 'Hide all',

  'eventStatusLabel.status.tooltip.CANCELLED': 'Game was cancelled',
  'eventStatusLabel.status.tooltip.SUSPENDED': 'Game was suspended',
  'eventStatusLabel.status.tooltip.POSTPONED': 'Game was postponed',

  'eventStatusLabel.status.CANCELLED': 'Cancelled',
  'eventStatusLabel.statusShort.CANCELLED': 'Canc',
  'eventStatusLabel.status.SUSPENDED': 'Suspended',
  'eventStatusLabel.statusShort.SUSPENDED': 'Susp',
  'eventStatusLabel.status.POSTPONED': 'Postponed',
  'eventStatusLabel.statusShort.POSTPONED': 'Post',

  'eventStatusLabel.liveStatus.FIRST_HALF': '1st half',
  'eventStatusLabel.liveStatus.SECOND_HALF': '2nd half',
  'eventStatusLabel.liveStatus.FIRST_EXTRA': '1st extra time',
  'eventStatusLabel.liveStatus.SECOND_EXTRA': '2nd extra time',
  'eventStatusLabel.liveStatus.PENALTIES': 'Penalties',

  'eventStatusLabel.breakStatus.HALFTIME': 'Halftime',
  'eventStatusLabel.breakStatusShort.HALFTIME': 'HT',
  'eventStatusLabel.breakStatus.AWAITING_EXTRA_TIME': 'Awaiting 1st extra time',
  'eventStatusLabel.breakStatusShort.AWAITING_EXTRA_TIME': 'ET1',
  'eventStatusLabel.breakStatus.EXTRA_TIME_HALFTIME': 'Awaiting 2nd extra time',
  'eventStatusLabel.breakStatusShort.EXTRA_TIME_HALFTIME': 'ET2',
  'eventStatusLabel.breakStatus.AWAITING_PENALTIES': 'Awaiting penalties',
  'eventStatusLabel.breakStatusShort.AWAITING_PENALTIES': 'Pen',

  'eventStatusLabel.finishedEventStatus.ENDED': 'FT',
  'eventStatusLabel.finishedEventStatus.AFTER_PENALTIES': 'AP',
  'eventStatusLabel.finishedEventStatus.AFTER_EXTRA_TIME': 'AET',

  'eventsMain.navigation.DEFAULT': 'All games',
  'eventsMain.navigation.SCHEDULED': 'Scheduled',
  'eventsMain.navigation.FINISHED': 'Finished',
  'eventsMain.navigation.MY_GAMES': 'My games',
  'eventsMain.navigation.LIVE': 'Live',

  'eventDetails.header.aggregationScore': 'Agg. score',
  'eventDetails.header.firstLeg': '1st leg',
  'eventDetails.header.fullDetailsLink': 'Full game details',
  'eventDetails.navigation.SUMMARY': 'Summary',
  'eventDetails.navigation.STATISTICS': 'Statistics',
  'eventDetails.navigation.LINE_UP': 'Lineups',
  'eventDetails.navigation.HEAD_TO_HEAD': 'H2H',
  'eventDetails.navigation.STANDINGS': 'Standings',
  'eventDetails.navigation.COMMENTS': 'Commentary',

  'eventSummary.form.teamTitle': 'Team',
  'eventSummary.form.formTitle': 'Form',
  'eventSummary.timeLine.header': 'Events timeline',
  'eventSummary.timeLine.periodStarted': 'Period started',
  'eventSummary.timeLine.periods.1ST_HALF': '1st half',
  'eventSummary.timeLine.periods.1ST_EXTRA': '1st extra time',
  'eventSummary.timeLine.periods.2ND_HALF': '2nd half',
  'eventSummary.timeLine.periods.2ND_EXTRA': '2nd extra time',
  'eventSummary.timeLine.periods.FULL_TIME': 'Full time',
  'eventSummary.timeLine.periods.PENALTIES': 'Penalties',
  'eventSummary.timeLine.periods.EXTRA_TIME': 'Extra time',
  'eventSummary.timeLine.periods.HALFTIME': 'Halftime',
  'eventSummary.timeLine.goalHappeningDescription.Goal': 'Penalty',
  'eventSummary.timeLine.goalHappeningDescription.Miss': 'Missed',
  'eventSummary.timeLine.goalHappeningDescription.PenaltyGoal': 'Penalty',
  'eventSummary.timeLine.cardHappeningDescription.onBench': 'on bench',
  'eventSummary.odds.header': 'Odds',
  'eventSummary.odds.sponsoredBy': 'Sponsored by',
  'eventSummary.matchDetails.title': 'Game details',
  'eventSummary.matchDetails.ranking': 'Ranking',
  'eventSummary.matchDetails.stadium': 'Stadium:',
  'eventSummary.matchDetails.referee': 'Referee:',
  'eventSummary.matchDetails.attendance': 'Attendance:',
  'eventSummary.matchDetails.averageCards': 'Average cards per match:',
  'eventSummary.matchDetails.averagePenalties': 'Average penalties/game:',
  'eventSummary.funFacts.header': 'Preview facts',

  'eventStats.parameter.goals': 'Goals',
  'eventStats.parameter.yellowCards': 'Yellow cards',
  'eventStats.parameter.redCards': 'Red cards',
  'eventStats.parameter.allCards': 'All cards',
  'eventStats.parameter.substitutions': 'Substitutions',
  'eventStats.parameter.ballPossession': 'Ball possession',
  'eventStats.parameter.freeKicks': 'Free kicks',
  'eventStats.parameter.goalKicks': 'Goal kicks',
  'eventStats.parameter.throwIns': 'Throw ins',
  'eventStats.parameter.offsides': 'Offsides',
  'eventStats.parameter.cornerKicks': 'Corner kicks',
  'eventStats.parameter.shotsOnTarget': 'Shots on target',
  'eventStats.parameter.shotsOffTarget': 'Shots off target',
  'eventStats.parameter.saves': 'Saves',
  'eventStats.parameter.fouls': 'Fouls',
  'eventStats.parameter.injuries': 'Injuries',
  'eventStats.parameter.penalties': 'Penalties',
  'eventStats.parameter.shotsBlocked': 'Shots blocked',
  'eventStats.parameter.dangerousAttack': 'Dangerous attack',
  'eventStats.parameter.ballSafe': 'Ball safe',
  'eventStats.parameter.attack': 'Attack',
  'eventStats.parameter.goalAttempts': 'Goal attempts',
  'eventStats.parameter.ballSafePercentage': 'Ball safe percentage',
  'eventStats.parameter.dangerousAttackPercentage': 'Dangerous attack percentage',
  'eventStats.parameter.attackPercentage': 'Attack percentage',
  'eventStats.goalType.Penalty': 'Penalty',
  'eventStats.goalType.Own': 'Own goal',

  'eventLineups.StartingLineup': 'Starting Lineup',
  'eventLineups.Bench': 'Bench',
  'eventLineups.coaches': 'Coaches',

  'eventStandings.navigationLabel': 'Stage',
  'eventStandings.seasonTable.totalTabName': 'Total',
  'eventStandings.seasonTable.homeTabName': 'Home',
  'eventStandings.seasonTable.awayTabName': 'Away',
  'eventStandings.tournamentBracket.qualificationRoundGroupName': 'Qualification',
  'eventStandings.tournamentBracket.preliminaryRoundGroupName': 'Preliminary',
  'eventStandings.tournamentBracket.knockoutRoundGroupName': 'Knockout',

  'eventCommentary.allCommentsTab': 'All',
  'eventCommentary.importantCommentsTab': 'Important',
  'eventCommentary.matchGoesOn': "The match goes on... so we're waiting for new hot events!",
  'eventCommentary.emptyCommentaries': 'The match has not started yet',

  'comingSoon.info': "Working hard to ship this feature! We'll notify you when it's ready.",

  // Season page
  'season.seasonDetails.button.label': 'Season {0} details',
  'season.seasonDetails.button.label.active': 'Close season details',
  'season.seasonDetails.titles.titleHolder': 'Title holder',
  'season.seasonDetails.titles.mostTitles': 'Most titles',
  'season.seasonDetails.titles.teamCount': {
    one: '1 team',
    other: '{0} teams',
  },

  'season.navigation.summary': 'Summary',
  'season.navigation.games': 'Games',
  'season.navigation.standings': 'Standings',
  'season.navigation.teamStatistics': 'Team statistics',
  'season.navigation.playerStatistics': 'Player statistics',
  'season.navigation.stadiums': 'Stadiums',
  'season.navigation.history': 'History',

  'season.statistics.division.title': 'Division',
  'season.statistics.division.description': 'Division level',

  'season.statistics.overallStats.title': 'Overall stats',
  'season.statistics.overallStats.homeWins.description': 'Home wins',
  'season.statistics.overallStats.draws.description': 'Draws',
  'season.statistics.overallStats.awayWins.description': 'Away wins',

  'season.statistics.averageCards.title': 'Average cards',
  'season.statistics.averageCards.yellow.description': 'Yellow cards',
  'season.statistics.averageCards.red.description': 'Red cards',

  'season.statistics.goalStats.title': 'Goal stats',
  'season.statistics.goalStats.description': 'Average goals',
  'season.statistics.goalStats.totalGoalsLabel.part1': 'Total ',
  'season.statistics.goalStats.totalGoalsLabel.part2': {
    one: '1 goal',
    other: '{0} goals',
  },
  'season.statistics.goalStats.totalGoalsLabel.part3': ' scored in ',
  'season.statistics.goalStats.totalGoalsLabel.part4': {
    one: '1 match',
    other: '{0} matches',
  },
  'season.statistics.goalStats.totalGoalsLabel.part5': ' during the season',

  'season.seasonSummary.games.title': 'Actual games',
  'season.seasonSummary.games.link': 'All games',

  'season.seasonStandings.title': 'Standings',
  'season.seasonStandings.headToHeadTitle': 'Head-to-Head',
  'season.seasonStandings.overallTitle': 'Overall',
  'season.seasonStandings.homeTitle': 'Home',
  'season.seasonStandings.awayTitle': 'Away',
  'season.seasonStandings.wideTitle': 'Wide',
  'season.seasonStandings.form': 'Form',
  'season.seasonStandings.link.fullStandings': 'Full standings',
  'season.seasonStandings.expandableBlock.label.showLess': 'Short standing view',
  'season.seasonStandings.expandableBlock.label.showMore': 'Show full standing',

  'season.seasonEvents.title': 'Games',
  'season.seasonEvents.datePicker.fullSeasonLabel': 'Full season',
  'season.seasonEvents.select.label': 'Teams',
  'season.seasonEvents.notFound': 'Events not found',

  'season.seasonTopBoard.tabs.PLAYERS': 'Top players',
  'season.seasonTopBoard.tabs.TEAMS': 'Top teams',
  'season.seasonTopBoard.tabs.VENUES': 'Top stadiums',

  'season.seasonTopBoard.tabs.showAll.players': 'Show all players',
  'season.seasonTopBoard.tabs.showAll.teams': 'Show all teams',
  'season.seasonTopBoard.tabs.showAll.venues': 'Show all stadiums',

  'season.seasonTopBoardCompact.showAll.players': 'All players',
  'season.seasonTopBoardCompact.showAll.teams': 'All teams',
  'season.seasonTopBoardCompact.showAll.venues': 'All stadiums',

  'season.seasonTopBoard.tabs.mode.GOALS': 'Goals',
  'season.seasonTopBoard.tabs.mode.ATTACK': 'Attack',
  'season.seasonTopBoard.tabs.mode.AGGRESSION': 'Aggression',

  'season.seasonTopBoard.headers.topPlayers': 'Top players',
  'season.seasonTopBoard.headers.topTeams': 'Top teams',
  'season.seasonTopBoard.headers.topVenues': 'Top stadiums',

  'season.seasonTopBoard.headers.player': 'player',
  'season.seasonTopBoard.headers.team': 'team',
  'season.seasonTopBoard.headers.venue': 'stadium',

  'season.seasonTopBoard.newcomers.title.MAIN': 'Newcomers',
  'season.seasonTopBoard.newcomers.title.HIGHER_DIVISION': 'Higher division',
  'season.seasonTopBoard.newcomers.title.LOWER_DIVISION': 'Lower division',

  'season.playerStatistics.title': 'Players statistics',
  'season.playerStatistics.customizeFilter': 'Customize',
  'season.playerStatistics.customFilter.title': 'Players statistics customization',
  'season.playerStatistics.customFilter.applyFilter': 'Apply',
  'season.playerStatistics.customFilter.cancelFilter': 'Cancel',
  'season.playerStatistics.customFilter.clearFilter': 'Clear filter',

  'season.playerStatistics.customFilter.StatType.all': 'All',
  'season.playerStatistics.customFilter.StatType.perGame': 'Per game',
  'season.playerStatistics.customFilter.StatType.perNinetyMinutes': 'Per 90 minutes',

  'season.playerStatistics.customFilter.general.showStatsLabel': 'Show stats',
  'season.playerStatistics.customFilter.general.teamLabel': 'Team',
  'season.playerStatistics.customFilter.general.positionLabel': 'Position',
  'season.playerStatistics.customFilter.general.preferredFootLabel': 'Preferred foot',
  'season.playerStatistics.customFilter.general.nationalityLabel': 'Nationality',
  'season.playerStatistics.customFilter.general.ageLabel': 'Age',
  'season.playerStatistics.customFilter.general.teamsSelectLabel': 'Teams',
  'season.playerStatistics.customFilter.general.nationalitiesSelectLabel': 'Nationalities',
  'season.playerStatistics.customFilter.general.positionsSelectPlural': 'Positions',

  'season.playerStatistics.customFilter.general.footSelectItem.all': 'All',
  'season.playerStatistics.customFilter.general.footSelectItem.both': 'Both',
  'season.playerStatistics.customFilter.general.footSelectItem.left': 'Left',
  'season.playerStatistics.customFilter.general.footSelectItem.right': 'Right',

  'season.playerStatistics.customFilter.columnConfiguration.configurationPresets':
    'Customization presets',

  'season.playerStatistics.customFilter.columnConfiguration.configurationPreset.Summary': 'Summary',
  'season.playerStatistics.customFilter.columnConfiguration.configurationPreset.Aggression':
    'Aggression',
  'season.playerStatistics.customFilter.columnConfiguration.configurationPreset.Attack': 'Attack',
  'season.playerStatistics.customFilter.columnConfiguration.configurationPreset.Goalkeeper':
    'Goalkeeper',
  'season.playerStatistics.customFilter.columnConfiguration.configurationPreset.Passing': 'Passing',
  'season.playerStatistics.customFilter.columnConfiguration.configurationPreset.Custom': 'Custom',

  'season.playerStatistics.customFilter.columnConfiguration.columnsArrangement':
    'Table columns arrangement',

  'season.playerStatistics.customFilter.columnConfiguration.columnGroup.Attack': 'Attack',
  'season.playerStatistics.customFilter.columnConfiguration.columnGroup.Aggression': 'Aggression',
  'season.playerStatistics.customFilter.columnConfiguration.columnGroup.Goalkeeper': 'Goalkeeper',
  'season.playerStatistics.customFilter.columnConfiguration.columnGroup.Passing': 'Passing',
  'season.playerStatistics.customFilter.columnConfiguration.columnGroup.Other': 'Other',

  'season.teamStatistics.title': 'Team statistics',
  'season.teamStatistics.customizeFilter': 'Customize',
  'season.teamStatistics.customFilter.title': 'Team statistics customization',
  'season.teamStatistics.customFilter.applyFilter': 'Apply',
  'season.teamStatistics.customFilter.cancelFilter': 'Cancel',
  'season.teamStatistics.customFilter.clearFilter': 'Clear filter',

  'season.teamStatistics.customFilter.StatType.all': 'All',
  'season.teamStatistics.customFilter.StatType.perGame': 'Per game',

  'season.teamStatistics.customFilter.general.showStatsLabel': 'Show stats',
  'season.teamStatistics.customFilter.general.countryLabel': 'Country',
  'season.teamStatistics.customFilter.general.searchLabel': 'Countries',

  'season.teamStatistics.customFilter.columnConfiguration.configurationPresets':
    'Customization presets',

  'season.teamStatistics.customFilter.columnConfiguration.configurationPreset.Summary': 'Summary',
  'season.teamStatistics.customFilter.columnConfiguration.configurationPreset.Goals': 'Goals',
  'season.teamStatistics.customFilter.columnConfiguration.configurationPreset.Attack': 'Attack',
  'season.teamStatistics.customFilter.columnConfiguration.configurationPreset.Aggression':
    'Aggression',
  'season.teamStatistics.customFilter.columnConfiguration.configurationPreset.Defence': 'Defence',
  'season.teamStatistics.customFilter.columnConfiguration.configurationPreset.Custom': 'Custom',

  'season.teamStatistics.customFilter.columnConfiguration.columnsArrangement':
    'Table columns arrangement',

  'season.teamStatistics.customFilter.columnConfiguration.columnGroup.Attack': 'Attack',
  'season.teamStatistics.customFilter.columnConfiguration.columnGroup.Aggression': 'Aggression',
  'season.teamStatistics.customFilter.columnConfiguration.columnGroup.Defence': 'Defence',
  'season.teamStatistics.customFilter.columnConfiguration.columnGroup.Goals': 'Goals',
  'season.teamStatistics.customFilter.columnConfiguration.columnGroup.Other': 'Other',

  'season.teamStatistics.customFilter.columnConfiguration.columnName.Club': 'Club',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.ClubLogo': 'Club logo',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.Rank': 'Rank',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.TotalShots': 'Total shots',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.ShotsBlocked': 'Shots blocked',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.ShotsOffGoal':
    'Shots off goal',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.PenaltyGoals': 'Penalty goals',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.Shots': 'Shots',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.ShotsOnGoal': 'Shots on goal',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.ShotsOnPost': 'Shots on post',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.ShotsOnBar': 'Shots on bar',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.shotsOffGoal':
    'Shots off goal',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.GoalsConceded':
    'Goals conceded',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.ShootingEfficiency':
    'Shooting efficiency',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.Possession': 'Possession',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.Offside': 'Offsides',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.YellowCards': 'Yellow cards',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.YellowRedCards':
    'Yellow -> Red cards',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.RedCards': 'Direct red cards',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.CleanSheet': 'Clean sheets',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.CornerKicks': 'Corner kicks',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.FreeKicks': 'Free kicks',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.GamesPlayed': 'Games played',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.Headed': 'Goals by head',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.FootGoals': 'Foot goals',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.GoalsScored': 'Goals scored',

  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.Club': 'Club',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.TotalShots': 'Sh',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.ShotsBlocked': 'ShB',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.ShotsOffGoal': 'ShOffG',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.PenaltyGoals': 'Pen',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.Shots': 'Sh',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.ShotsOnGoal': 'ShOnG',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.ShotsOnPost': 'ShOnP',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.ShotsOnBar': 'ShOnB',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.GoalsConceded': 'GC',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.ShootingEfficiency':
    'ShEff',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.Possession': 'Pos%',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.Offside': 'Off',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.CleanSheet': 'CS',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.CornerKicks': 'Cor',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.FreeKicks': 'FrK',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.GamesPlayed': 'GP',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.Headed': 'Head',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.FootGoals': 'Foot',
  'season.teamStatistics.customFilter.columnConfiguration.columnName.short.GoalsScored': 'G',

  'season.history.title': 'History',
  'season.history.column.season': 'season',
  'season.history.column.winner': 'winner',
  'season.history.column.runnerUp': 'runner-up',
  'season.history.column.thirdPlace': 'third-place',
  'season.history.column.relegation': 'Relegation',

  'season.totalTrophies.title': 'Total trophies from',
  'season.totalTrophies.column.team': 'team',
  'season.totalTrophies.column.trophies': 'Trophies',

  'season.stadiums.title': 'Stadiums',
  'season.stadiums.column.name': 'Name',
  'season.stadiums.column.country': ' Country, City',
  'season.stadiums.column.opening': 'Opening',
  'season.stadiums.column.capacity': 'Capacity',
  'season.stadiums.column.avg': 'Avg.Attend',
  'season.stadiums.column.fullness': '% Full',
  'season.stadiums.column.clubs': 'Clubs',
  'season.stadiums.column.topScorer': 'Top Scorer',
  'season.stadiums.row.topScorer.players': 'players',
  'season.stadiums.popover.topScorer.goals': 'Goals',

  'team.navigation.summary': 'Summary',
  'team.navigation.games': 'Games',
  'team.navigation.standings': 'Standings',
  'team.navigation.playersStatistics': 'Players Statistics',
  'team.navigation.teamSquad': 'Team squad',

  'team.teamSummary.actualGames.title': 'Actual games',
  'team.teamSummary.actualGames.link': 'All games',
  'team.teamSummary.standings.title': 'Standings',
  'team.teamSummary.standings.linkToFullStandings': 'Full standings',

  'team.teamSummary.topPlayers.showAllPlayersLink': 'All players',
  'team.teamSummary.topPlayers.title': 'Top players',

  'team.teamSummary.statistics.title': 'Team statistics',
  'team.teamSummary.statistics.tab.wholeSeason': 'Whole season',
  'team.teamSummary.statistics.tab.perGame': 'Per game',

  'team.teamSummary.lineups.title': 'Usual lineup',
  'team.teamSummary.lineups.teamSquadLink': 'Full team squad',

  'team.teamSummary.description.currentManager.title': 'Current manager',
  'team.teamSummary.description.currentManager.memberSince': 'since {0}',

  'team.teamSummary.description.stadium.title': 'Stadium',
  'team.teamSummary.description.stadium.stadiumPropertyName': 'Stadium:',
  'team.teamSummary.description.stadium.capacityPropertyName': 'Capacity:',
  'team.teamSummary.description.stadium.yearPropertyName': 'Year of construction:',

  'team.teamSummary.description.kits.title': 'Kits',
  'team.teamSummary.description.kits.home': 'Home',
  'team.teamSummary.description.kits.away': 'Away',
  'team.teamSummary.description.kits.awaySecondary': 'Away secondary',
  'team.teamSummary.description.kits.goalkeeper': 'goalkeeper',

  'team.teamEvents.filters.title': 'Games',
  'team.teamEvents.filters.TournamentsSelect.label': 'All tournaments',
  'team.teamEvents.filters.seasonPluralSelect.label': 'Seasons',
  'team.teamEvents.filters.seasonStaticSelect.label': 'Season',
  'team.teamEvents.notFound': 'Events not found',
  'team.teamEvents.mobileGamesFilterPopover.title': 'Select standings',
  'team.teamEvents.mobileGamesFilterPopover.tournamentFilter.label': 'Tournament',
  'team.teamEvents.mobileGamesFilterPopover.seasonFilter.label': 'Season',
  'team.teamEvents.mobileGamesFilterPopover.periodFilter.label': 'Dates',
  'team.teamEvents.mobileGamesFilterPopover.clearFilter': 'Clear filter',
  'team.teamEvents.mobileGamesFilterPopover.cancel': 'Cancel',
  'team.teamEvents.mobileGamesFilterPopover.apply': 'Apply',

  'calendar.yearSelector.title': 'Full year:',
  'calendar.dataLabel.matches': {
    one: '1 match',
    other: '{0} matches',
  },

  'team.teamSquad.title': 'Actual squad',
  'team.teamSquad.column.number': 'Number',
  'team.teamSquad.column.player': 'Player',
  'team.teamSquad.column.position': 'Position',
  'team.teamSquad.column.nation': 'Nation',
  'team.teamSquad.column.age': 'Age',
  'team.teamSquad.column.height': 'Height, cm',
  'team.teamSquad.column.weight': 'Weight, kg',
  'team.teamSquad.column.foot': 'Foot',
  'team.teamSquad.column.marketValue': 'Market value',
  'team.teamSquad.column.inTeamSince': 'In team since',
  'team.teamSquad.popover.inTeamSince.month': {
    one: '1 month',
    other: '{0} months',
  },
  'team.teamSquad.popover.inTeamSince.days': {
    one: '1 day',
    other: '{0} days',
  },

  'team.playerStatistics.title': 'Players statistics',
  'team.playerStatistics.short.title': 'Players',
  'team.playerStatistics.customizeFilter': 'Customize',
  'team.playerStatistics.customFilter.title': 'Players statistics customization',
  'team.playerStatistics.customFilter.applyFilter': 'Apply',
  'team.playerStatistics.customFilter.cancelFilter': 'Cancel',
  'team.playerStatistics.customFilter.clearFilter': 'Clear filter',

  'team.playerStatistics.customFilter.StatType.all': 'All',
  'team.playerStatistics.customFilter.StatType.perGame': 'Per game',
  'team.playerStatistics.customFilter.StatType.perNinetyMinutes': 'Per 90 minutes',

  'team.playerStatistics.customFilter.general.tournamentLabel': 'Tournament',
  'team.playerStatistics.customFilter.general.seasonLabel': 'Season',
  'team.playerStatistics.customFilter.general.showStatsLabel': 'Show stats',
  'team.playerStatistics.customFilter.general.teamLabel': 'Team',
  'team.playerStatistics.customFilter.general.positionLabel': 'Position',
  'team.playerStatistics.customFilter.general.preferredFootLabel': 'Preferred foot',
  'team.playerStatistics.customFilter.general.nationalityLabel': 'Nationality',
  'team.playerStatistics.customFilter.general.searchLabel': 'Nationalities',
  'team.playerStatistics.customFilter.general.ageLabel': 'Age',
  'team.playerStatistics.customFilter.general.teamsSelectPlural': 'Teams',
  'team.playerStatistics.customFilter.general.nationalitiesSelectPlural': 'Nationalities',
  'team.playerStatistics.customFilter.general.positionsSelectPlural': 'Positions',

  'team.playerStatistics.customFilter.general.footSelectItem.all': 'All',
  'team.playerStatistics.customFilter.general.footSelectItem.both': 'Both',
  'team.playerStatistics.customFilter.general.footSelectItem.left': 'Left',
  'team.playerStatistics.customFilter.general.footSelectItem.right': 'Right',

  'team.playerStatistics.customFilter.columnConfiguration.configurationPresets':
    'Customization presets',

  'team.playerStatistics.customFilter.columnConfiguration.configurationPreset.Summary': 'Summary',
  'team.playerStatistics.customFilter.columnConfiguration.configurationPreset.Aggression':
    'Aggression',
  'team.playerStatistics.customFilter.columnConfiguration.configurationPreset.Attack': 'Attack',
  'team.playerStatistics.customFilter.columnConfiguration.configurationPreset.Goalkeeper':
    'Goalkeeper',
  'team.playerStatistics.customFilter.columnConfiguration.configurationPreset.Passing': 'Passing',
  'team.playerStatistics.customFilter.columnConfiguration.configurationPreset.Custom': 'Custom',

  'team.playerStatistics.customFilter.columnConfiguration.columnsArrangement':
    'Table columns arrangement',

  'team.playerStatistics.customFilter.columnConfiguration.columnGroup.Attack': 'Attack',
  'team.playerStatistics.customFilter.columnConfiguration.columnGroup.Aggression': 'Aggression',
  'team.playerStatistics.customFilter.columnConfiguration.columnGroup.Goalkeeper': 'Goalkeeper',
  'team.playerStatistics.customFilter.columnConfiguration.columnGroup.Passing': 'Passing',
  'team.playerStatistics.customFilter.columnConfiguration.columnGroup.Other': 'Other',

  'playerStatistics.customFilter.columnConfiguration.columnName.Player': 'Player',
  'playerStatistics.customFilter.columnConfiguration.columnName.PlayerRank': 'Rank',
  'playerStatistics.customFilter.columnConfiguration.columnName.PlayerIcon': 'Icon',
  'playerStatistics.customFilter.columnConfiguration.columnName.Club': 'Club',
  'playerStatistics.customFilter.columnConfiguration.columnName.Goals': 'Goals',
  'playerStatistics.customFilter.columnConfiguration.columnName.GoalsAndAssists': 'Goals + assists',
  'playerStatistics.customFilter.columnConfiguration.columnName.HeadedGoals': 'Goals by head',
  'playerStatistics.customFilter.columnConfiguration.columnName.PenaltyGoals': 'Penalty goals',
  'playerStatistics.customFilter.columnConfiguration.columnName.Shots': 'Shots',
  'playerStatistics.customFilter.columnConfiguration.columnName.ShotsOffGoal': 'Shots off goal',
  'playerStatistics.customFilter.columnConfiguration.columnName.ShotsOnGoal': 'Shots on goal',
  'playerStatistics.customFilter.columnConfiguration.columnName.GoalConversion':
    'Goal conversion, %',
  'playerStatistics.customFilter.columnConfiguration.columnName.Offside': 'Offside',
  'playerStatistics.customFilter.columnConfiguration.columnName.YellowCards': 'Yellow cards',
  'playerStatistics.customFilter.columnConfiguration.columnName.YellowRedCards':
    'Yellow -> Red cards',
  'playerStatistics.customFilter.columnConfiguration.columnName.RedCards': 'Direct red cards',
  'playerStatistics.customFilter.columnConfiguration.columnName.CleanSheets': 'Clean sheets',
  'playerStatistics.customFilter.columnConfiguration.columnName.Assists': 'Assists',
  'playerStatistics.customFilter.columnConfiguration.columnName.GamesPlayed': 'Games played',
  'playerStatistics.customFilter.columnConfiguration.columnName.Started': 'Started',
  'playerStatistics.customFilter.columnConfiguration.columnName.MinutesPlayed': 'Minutes played',

  'playerStatistics.customFilter.columnConfiguration.columnName.short.Player': '',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.Club': 'Team',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.Goals': 'G',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.GoalsAndAssists': 'G+A',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.HeadedGoals': 'Head',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.PenaltyGoals': 'Pen',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.Shots': 'Sh',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.ShotsOffGoal': 'ShOffG',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.ShotsOnGoal': 'ShOnG',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.GoalConversion': 'Con%',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.Offside': 'Off',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.CleanSheets': 'CS',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.Assists': 'A',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.GamesPlayed': 'GP',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.Started': 'St',
  'playerStatistics.customFilter.columnConfiguration.columnName.short.MinutesPlayed': 'Min',

  'team.standings.title': 'Standings',
  'team.standings.headToHeadTitle': 'Head-to-Head',
  'team.standings.overallTitle': 'Overall',
  'team.standings.homeTitle': 'Home',
  'team.standings.awayTitle': 'Away',
  'team.standings.wideTitle': 'Wide',
  'team.standings.link.fullStandings': 'Full standings',
  'team.standings.filterPopover.tournamentFilter.label': 'Tournament',
  'team.standings.filterPopover.seasonFilter.label': 'Season',
  'team.standings.filterPopover.stageFilter.label': 'Stage',
  'team.standings.filterPopover.tableViewFilter.label': 'Table view',

  'player.navigation.summary': 'Summary',
  'player.navigation.games': 'Games',
  'player.navigation.playerStatistics': 'Player statistics',
  'player.navigation.teamInfo': 'Team info',

  'player.summary.actualGames.title': 'Actual Games',
  'player.summary.actualGames.link': 'All Games',

  'player.summary.statistics.statType.All': 'Overall',
  'player.summary.statistics.statType.PerGame': 'Per game',
  'player.summary.statistics.statType.PerNinetyMinutes': 'Per 90’',

  'player.playerSummary.description.passport.title': 'Players passport',
  'player.playerSummary.description.passport.birthdayPropName': 'Birthday:',
  'player.playerSummary.description.passport.nationalityPropName': 'Nationality:',
  'player.playerSummary.description.passport.secondNationalityPropName': '2nd nationality:',
  'player.playerSummary.description.passport.heightPropName': 'Height:',
  'player.playerSummary.description.passport.weightPropName': 'Weight:',
  'player.playerSummary.description.passport.legPropName': 'Leg:',
  'player.playerSummary.description.passport.marketPricePropName': 'Market price:',

  'player.playerSummary.description.socialMedia.title': 'Follow on',

  'player.playerSummary.statistics.title': 'Statistics',
  'player.playerSummary.statistics.wholeCareerLabel': 'Whole career',

  'player.playerSummary.transferHistory.title': 'Transfer history',
  'player.playerSummary.transferHistory.column.startDate': 'Start date',
  'player.playerSummary.transferHistory.column.endDate': 'End date',
  'player.playerSummary.transferHistory.column.club': 'Club',
  'player.playerSummary.transferHistory.column.contractType': 'Contract type',
  'player.playerSummary.transferHistory.column.present': 'Present',

  'player.playerSummary.mobileFilterPopover.clearFilter': 'Clear filters',
  'player.playerSummary.mobileFilterPopover.cancel': 'Cancel',
  'player.playerSummary.mobileFilterPopover.apply': 'Apply',
  'player.playerSummary.mobileFilterPopover.teamSelect.title': 'Team',
  'player.playerSummary.mobileFilterPopover.leagueSelect.title': 'League',
  'player.playerSummary.mobileFilterPopover.seasonSelect.title': 'Season',
  'player.playerSummary.mobileFilterPopover.viewTabs.title': 'View',

  'player.events.filters.title': 'Games',
  'player.events.filters.seasonStaticSelect.label': 'Season',
  'player.events.filters.seasonPluralSelect.label': 'Seasons',
  'player.events.filters.allSeasonsItem.label': 'All seasons',
  'player.events.filters.teamSelect.label': 'All teams',
  'player.events.filters.tournamentsSelect.label': 'All tournaments',
  'player.events.filters.calendar.customPeriod.label': 'All in',
  'player.events.mobileFilterPopover.title': 'Games filtration',
  'player.events.mobileFilterPopover.tournamentFilter.label': 'Tournament',
  'player.events.mobileFilterPopover.seasonFilter.label': 'Season',
  'player.events.mobileFilterPopover.periodFilter.label': 'Dates',
  'player.events.mobileFilterPopover.teamFilter.label': 'Team',
  'player.events.mobileFilterPopover.clearFilter': 'Clear filter',
  'player.events.mobileFilterPopover.cancel': 'Cancel',
  'player.events.mobileFilterPopover.apply': 'Apply',

  'stadium.location.title': 'How to reach',
  'stadium.actualGames.title': 'Actual games',

  'stadium.statistics.goalStats.title': 'Goal stats',
  'stadium.statistics.goalStats.averageGoals.label': 'Average goals per match',
  'stadium.statistics.goalStats.goalsByPeriod.label.total': 'Total',
  'stadium.statistics.goalStats.goalsByPeriod.label.scoredIn': 'scored in',
  'stadium.statistics.goalStats.goalsByPeriod.label.duringTheSeason': 'during the season',
  'stadium.statistics.goalStats.goalsByPeriod.totalGoals': {
    one: '1 goal',
    other: '{0} goals',
  },
  'stadium.statistics.goalStats.goalsByPeriod.matchesPlayed': {
    one: '1 match',
    other: '{0} matches',
  },
  'stadium.statistics.topScorers.title': 'Top scorer',
  'stadium.statistics.topScorers.goalsLabel': 'Goals',
  'stadium.statistics.average.cards': 'Cards',
  'stadium.statistics.average.redCardsLabel': 'Red cards',
  'stadium.statistics.average.cornerKicksLabel': 'Corner kicks',
  'stadium.statistics.attendance.title': 'Average attendance',
  'stadium.statistics.attendance.visitorsLabel': {
    one: 'visitor',
    other: 'visitors',
  },
  'stadium.statistics.attendance.visitorsDescription': '{0}% of maximum capacity',
  'stadium.statistics.statisticsLabel.home': 'Home',
  'stadium.statistics.statisticsLabel.away': 'Away',
  'stadium.statistics.winLose.statisticsLabel.goals': 'Goals',
  'stadium.statistics.winLose.statisticsLabel.cornerKicks': 'Corner kicks',
  'stadium.statistics.homeTeams.title': 'Home teams',

  'match.lastGames.title': 'Last games',
  'match.link.games': 'All games',
  'match.stadium.title': 'Stadium',
  'match.referee.title': 'Match referee',
  'match.weather.title': 'Weather',
  'match.weather.windSpeed': 'Wind speed',
  'match.weather.windSpeedValue': '{0} km/h',
  'match.weather.airHumidity': 'Air humidity',
  'match.weather.airHumidityValue': '{0}%',
  'match.weather.pressure': 'pressure',
  'match.weather.pressureValue': '{0} hpa',

  'match.shots.title': 'Game shots',
  'match.shots.parameter.score_change': 'Goals',
  'match.shots.parameter.shot_on_target': 'Shot on target',
  'match.shots.parameter.shot_blocked': 'Shot blocked',
  'match.shots.parameter.shot_off_target': 'Shot off target',

  'match.statistics.title': 'Other game stats',

  'match.timeLine.title': 'Game timeline',
  'match.timeLine.events': 'Events',
  'match.timeLine.comments': 'Comments',

  'searchSelect.label.selected': 'selected',
  'searchSelect.labelPrefix.items': 'Items',
  'searchSelect.input.placeholder': 'Start type to search',
  'searchSelect.label.emptyResults': 'There are no options with that name.\n Perhaps you misspelt.',

  'extendedMultipleSelect.controls.selectAll': 'Select all',
  'extendedMultipleSelect.controls.clear': 'Clear',

  'footer.navigation.link.events': 'Games',
  'footer.navigation.link.leagues': 'Leagues',
  'footer.navigation.link.favorites': 'Favorites',
};
