import * as matchTopPlayersActions from './actions';
import * as matchTopPlayersSelectors from './selectors';
import * as matchTopPlayersConstants from './constants';
import * as matchTopPlayersThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  matchTopPlayersActions,
  matchTopPlayersSelectors,
  matchTopPlayersConstants,
  matchTopPlayersThunks,
  createReducer,
};
