import React, { useEffect, useRef, useState } from 'react';
import classnames from 'classnames';

import { useElementOverflow } from 'packages/hooks';

import styles from './styles.scss';

type Props = {
  name: string;
  isShrink?: boolean;
  className?: string;
};

const StadiumTitle = ({ isShrink = false, name, className }: Props) => {
  const stadiumNameRef = useRef<HTMLSpanElement>(null);
  const isStadiumNameOverflows = useElementOverflow(stadiumNameRef);
  const [isTitleVisible, setIsTitleVisible] = useState(false);

  useEffect(() => {
    setIsTitleVisible(true);
  }, []);

  return (
    <span
      ref={stadiumNameRef}
      className={classnames(
        styles.stadiumName,
        { [styles.shrink]: isShrink || isStadiumNameOverflows, [styles.visible]: isTitleVisible },
        className,
      )}
    >
      {name}
    </span>
  );
};

export default StadiumTitle;
