exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2f6zHSmY{flex:1 1;margin-right:16px}._2zGtq0Y6{flex:1 1;display:flex;justify-content:center}._3poqIavu{width:443px}._2FQJ-nI3{position:relative;display:flex;flex-direction:column;height:100%;width:100%}._2FQJ-nI3>*:not(:last-of-type){margin-bottom:8px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktop/football/SeasonSummary/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,QAAO,CACP,YAAa,CACb,sBAAuB,CAT3B,WAaI,WAAY,CAbhB,WAiBI,iBAAkB,CAClB,YAAa,CACb,qBAAsB,CACtB,WAAY,CACZ,UAAW,CArBf,gCAwBM,iBAAkB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    justify-content: center;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n\n  .side-bar {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n\n    > *:not(:last-of-type) {\n      margin-bottom: 8px;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_2f6zHSmY",
	"leftColumn": "_2f6zHSmY",
	"left-bar": "_2zGtq0Y6",
	"leftBar": "_2zGtq0Y6",
	"right-column": "_3poqIavu",
	"rightColumn": "_3poqIavu",
	"side-bar": "_2FQJ-nI3",
	"sideBar": "_2FQJ-nI3"
};