import React from 'react';
import classnames from 'classnames';

import { MatchUp as MatchUpType } from 'packages/event-standings/types';

import { MatchUp, AdjacentMatchUpTitle } from './components';
import styles from './styles.scss';

type Props = {
  matchUp: MatchUpType;
  activeTeamIds?: number[];
  activeUniqueTournamentId?: number | string;
  activeSeasonId?: number | string;
  isActive?: boolean;
  className?: string;
};

const MatchUpsGroup = ({
  matchUp,
  isActive = false,
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
  className,
}: Props) => {
  const { adjacentMatches } = matchUp;

  return (
    <div
      className={classnames(
        styles.matchUpGroupContainer,
        { [styles.extended]: !!adjacentMatches, [styles.active]: isActive },
        className,
      )}
    >
      <div className={styles.matchUpGroup}>
        <MatchUp
          activeTeamIds={activeTeamIds}
          activeUniqueTournamentId={activeUniqueTournamentId}
          activeSeasonId={activeSeasonId}
          matchUp={matchUp}
          className={styles.matchUp}
        />
        {!!adjacentMatches &&
          adjacentMatches.map(({ roundData, ...adjacentMatchUpData }) => (
            <>
              <AdjacentMatchUpTitle title={roundData.name} />
              <MatchUp
                activeTeamIds={activeTeamIds}
                activeUniqueTournamentId={activeUniqueTournamentId}
                activeSeasonId={activeSeasonId}
                matchUp={adjacentMatchUpData}
                className={styles.matchUp}
              />
            </>
          ))}
      </div>
    </div>
  );
};

export default MatchUpsGroup;
