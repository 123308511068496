import { connect } from 'react-redux';

import { playerDetailsSelectors } from 'packages/player-details';

const mapStateToProps = (state) => {
  return {
    playerDetails: playerDetailsSelectors.getData(state),
    isLoading: playerDetailsSelectors.getIsLoading(state),
  };
};

export default connect(mapStateToProps);
