import React, { useCallback } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import styles from './styles.scss';

type Props = {
  onSelectAll: () => void;
  onClear: () => void;
  isClearButtonDisabled?: boolean;
  isSelectAllButtonDisabled?: boolean;
};

const ItemListControls = ({
  onSelectAll,
  onClear,
  isClearButtonDisabled = false,
  isSelectAllButtonDisabled = false,
}: Props) => {
  return (
    <div className={styles.wrapper}>
      <span
        className={classnames({ [styles.highlighted]: !isSelectAllButtonDisabled })}
        onClick={onSelectAll}
      >
        {i18n.value('extendedMultipleSelect.controls.selectAll')}
      </span>

      <span
        className={classnames({ [styles.highlighted]: !isClearButtonDisabled })}
        onClick={onClear}
      >
        {i18n.value('extendedMultipleSelect.controls.clear')}
      </span>
    </div>
  );
};

export default ItemListControls;
