import { scheduledEventsActions } from '../../actions';
import { loadEvents } from '../common';

interface Params {
  date?: string;
  categoryId?: number;
}

export const loadScheduledEvents = ({ date, categoryId }: Params = {}) => async (dispatch) => {
  try {
    dispatch(scheduledEventsActions.loadScheduledEventsStart());

    const eventsData = await dispatch(loadEvents({ categoryId, date, isScheduled: true }));

    dispatch(scheduledEventsActions.loadScheduledEventsSuccess(eventsData));
  } catch (error) {
    dispatch(scheduledEventsActions.loadScheduledEventsFailure(error));
  }
};
