import { Event, TournamentView } from 'packages/events/types';

const getEvents = (tournaments: TournamentView[]) => {
  return tournaments.reduce<Event[]>((accumulator, { events }) => {
    accumulator.push(...events);

    return accumulator;
  }, []);
};

export default getEvents;
