import { handleActions, ReducerMap, combineActions } from 'redux-actions';

import { Status } from '../../types';
import { favoriteEventsActions } from '../../actions';

const defaultState = {
  isLoading: false,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${favoriteEventsActions.loadFavoriteEventsStart}`]: () => ({
    isLoading: true,
  }),
  [`${combineActions(
    favoriteEventsActions.loadFavoriteEventsFailure,
    favoriteEventsActions.loadFavoriteEventsSuccess,
  )}`]: () => ({
    isLoading: false,
  }),
};

export default handleActions(reducerMap, defaultState);
