import { SelectItem } from 'packages/shared/components/Select/types';
import { SeasonsExtendedTournament, TournamentSeasonSelectItems } from '../types';

const DEFAULT_TOURNAMENT_SEASON_SELECT_ITEMS = {
  tournamentItems: [],
  seasonItems: [],
};

const getTournamentSeasonSelectItems = (
  tournaments: SeasonsExtendedTournament[],
  selectedTournamentId?: number,
  selectedSeasonId?: number,
): TournamentSeasonSelectItems => {
  if (!selectedTournamentId || !tournaments.length) {
    return DEFAULT_TOURNAMENT_SEASON_SELECT_ITEMS;
  }
  const selectedTournament = tournaments.find(({ id }) => id === selectedTournamentId);

  const tournamentItems = tournaments.map<SelectItem>(({ id, name, logo }) => ({
    id,
    label: name,
    componentProps: { logo, name },
  }));

  const seasonItems =
    selectedTournament?.seasons?.map<SelectItem>(({ id, year }) => ({
      id,
      label: year,
    })) || [];

  const selectedTournamentItem = tournamentItems.find(({ id }) => id === selectedTournamentId);
  const selectedSeasonItem = seasonItems.find(({ id }) => id === selectedSeasonId);

  return {
    tournamentItems,
    seasonItems,
    activeTournamentItem: selectedTournamentItem,
    activeSeasonItem: selectedSeasonItem,
  };
};

export default getTournamentSeasonSelectItems;
