import React, { memo, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import { ActionCreatorsMapObject } from 'redux';

import { Competitor, Event, Team } from 'types';
import { FavoriteIcon, Figure } from 'packages/shared/components';
import { DesktopPage } from 'router/types';
import { useNavigation } from 'packages/hooks';

import styles from './styles.scss';
import connect from './connect';

type Props = {
  team: Competitor;
  favoritesThunks: ActionCreatorsMapObject;
  favoriteTeams: Team[];
  event: Event;
  isReversed?: boolean;
};

const EventDetailsTeam = ({ favoritesThunks, team, favoriteTeams, event, isReversed }: Props) => {
  const { appNavigate } = useNavigation();
  const isTeamFavorite = useMemo(() => Boolean(favoriteTeams.find(({ id }) => id === team.id)), [
    favoriteTeams,
    team,
  ]);

  const handleFavoriteIconClick = useCallback(() => {
    favoritesThunks.toggleTeamEvent(event, team);
  }, [event, team]);

  const handleTeamClick = useCallback(() => {
    const { seasonId, tournament } = event;

    appNavigate(DesktopPage.FootballTeamSummaryDescription, {
      teamId: team.id,
      seasonId,
      uniqueTournamentId: tournament.uniqueTournamentId,
    });
  }, [team, event]);

  return (
    <div className={classnames(styles.eventDetailsTeam, { [styles.reversed]: isReversed })}>
      <div className={styles.teamPreview}>
        <div className={styles.logoBlock}>
          <FavoriteIcon
            className={styles.favoriteIcon}
            onClick={handleFavoriteIconClick}
            isFavorite={isTeamFavorite}
          />
          <Figure onClick={handleTeamClick} className={styles.teamLogo} src={team.logo} />
        </div>
        <div className={styles.teamName} onClick={handleTeamClick}>
          {team.fullName || team.name}
        </div>
      </div>
    </div>
  );
};

export default memo(connect(EventDetailsTeam));
