import DateService from 'packages/date-az';
import { DATE_FORMATS } from 'packages/date-az/consts';

interface Params {
  startDate?: string;
  endDate?: string;
}

const formatStartDate = ({ startDate, endDate }: Params): string | undefined => {
  if ((!startDate && !endDate) || !startDate) {
    return undefined;
  }

  return DateService.getFormattedDateViewFromString(startDate, DATE_FORMATS.FULL_DATE);
};

export default formatStartDate;
