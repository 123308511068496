import { toCamelCase } from 'packages/utils/Object.utils';
import { groupEventsByDateAndTournament, mapEvent } from 'packages/events/services';
import { mapForm } from 'packages/event-summary/services/mappers/mapEventSummary';

import { HeadToHeadPage } from '../types';

const mapEventHeadToHead = (
  headToHeadEvents,
  homeLastFiveEvents,
  awayLastFiveEvents,
  homeTeam,
  awayTeam,
): HeadToHeadPage[] => {
  return [
    {
      id: 0,
      props: {
        team: homeTeam,
        tournaments: mapEvents(homeLastFiveEvents),
      },
    },
    {
      id: 1,
      props: {
        tournaments: mapEvents(headToHeadEvents),
      },
    },
    {
      id: 2,
      props: {
        team: awayTeam,
        tournaments: mapEvents(awayLastFiveEvents),
      },
    },
  ];
};

const mapEvents = (events: any[]) => {
  return groupEventsByDateAndTournament(
    events
      .map((event) => {
        let { id, value, competitors, scheduled, eventStatus } = toCamelCase(event);
        const mappedEvent = mapEvent(event);

        return {
          ...mappedEvent,
          form: value ? mapForm([{ id, value, competitors, scheduled, eventStatus }]) : undefined,
        };
      })
      .splice(0, 6),
  );
};

export default mapEventHeadToHead;
