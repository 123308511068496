import React, { ReactElement } from 'react';
import classnames from 'classnames';

import styles from './styles.scss';

type Props = {
  name?: string;
  label?: string | ReactElement;
  className?: string;
};

const Label = ({ name, label, className }: Props) => {
  return (
    <label className={classnames(styles.label, className)} htmlFor={name}>
      {label}
    </label>
  );
};

export default React.memo(Label);
