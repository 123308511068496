import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadFinishedEventsStart = createAction(`${NAMESPACE}LOAD_FINISHED_EVENTS_START`);
export const loadFinishedEventsSuccess = createAction(`${NAMESPACE}LOAD_FINISHED_EVENTS_SUCCESS`);
export const loadFinishedEventsFailure = createAction(`${NAMESPACE}LOAD_FINISHED_EVENTS_FAILURE`);
export const cleanFinishedEventsSuccess = createAction(`${NAMESPACE}CLEAN_FINISHED_EVENTS_SUCCESS`);
export const cleanFinishedEventsFailure = createAction(`${NAMESPACE}CLEAN_FINISHED_EVENTS_FAILURE`);
export const loadMoreFinishedEventsStart = createAction(
  `${NAMESPACE}LOAD_MORE_FINISHED_EVENTS_START`,
);
export const loadMoreFinishedEventsSuccess = createAction(
  `${NAMESPACE}LOAD_MORE_FINISHED_EVENTS_SUCCESS`,
);
export const loadMoreFinishedEventsFailure = createAction(
  `${NAMESPACE}LOAD_MORE_FINISHED_EVENTS_FAILURE`,
);
export const updateFinishedEventsStart = createAction(`${NAMESPACE}UPDATE_FINISHED_EVENTS_START`);
export const updateFinishedEventsFailure = createAction(
  `${NAMESPACE}UPDATE_FINISHED_EVENTS_FAILURE`,
);
export const updateFinishedEventsSuccess = createAction(
  `${NAMESPACE}UPDATE_FINISHED_EVENTS_SUCCESS`,
);

export const subscribeForFinishedEventsUpdatesStart = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_FINISHED_EVENTS_UPDATES_START`,
);
export const subscribeForFinishedEventsUpdatesFailure = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_FINISHED_EVENTS_UPDATES_FAILURE`,
);
export const subscribeForFinishedEventsUpdatesSuccess = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_FINISHED_EVENTS_UPDATES_SUCCESS`,
);
