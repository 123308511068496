import { TournamentView, Event } from 'packages/events/types';
import DateService from 'packages/date-az';

export const groupEventsByDateAndTournament = (events: Event[] = []): TournamentView[] => {
  const sortedEventsByDate = events.sort(sortEventsByDate);
  const groupedEvents = groupFilteredEventsByDate(sortedEventsByDate);

  return groupedEvents.map(
    (events): TournamentView => {
      const [{ tournament, category }] = events;

      return { ...tournament, category, events };
    },
  );
};

const sortEventsByDate = (leftEvent, rightEvent): number => {
  const currentEvent = DateService.getDateTimeFromIsoString(leftEvent.scheduled);
  const nextEvent = DateService.getDateTimeFromIsoString(rightEvent.scheduled);
  if (currentEvent > nextEvent) {
    return -1;
  } else if (currentEvent < nextEvent) {
    return 1;
  } else {
    return 0;
  }
};

const groupFilteredEventsByDate = (events: Event[]): Event[][] => {
  return events.reduce((acc: Event[][], currentEvent, index) => {
    const previousEvent = events[index - 1];
    const previousUniqueTournamentId = previousEvent?.tournament?.uniqueTournamentId;
    const currentUniqueTournamentId = currentEvent.tournament.uniqueTournamentId;

    if (previousUniqueTournamentId === currentUniqueTournamentId) {
      const existingTournaments = acc.pop() || [];

      acc.push([...existingTournaments, currentEvent]);

      return acc;
    }

    acc.push([currentEvent]);

    return acc;
  }, []);
};
