import React, { useCallback, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { TimeZone } from 'types';
import { Stage } from 'packages/event-standings/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import SeasonDetailsView from './views';
import connect from './connect';

type Props = {
  isStagesLoading: boolean;
  seasonStagesActions: ActionCreatorsMapObject;
  seasonStandingsActions: ActionCreatorsMapObject;
  stages: Stage[] | null;
  timeZone: TimeZone;
};

const extendSeasonDetails = (
  SeasonDetails: typeof SeasonDetailsView[keyof typeof SeasonDetailsView],
) => ({
  stages,
  isStagesLoading,
  seasonStagesActions,
  seasonStandingsActions,
  timeZone,
}: Props) => {
  const { appNavigate } = useNavigation();
  const { seasonId = '', tournamentId = '' } = useParams();

  useEffect(() => {
    seasonStagesActions.loadStages(seasonId);

    return () => {
      seasonStandingsActions.cleanSeasonStandings();
    };
  }, [seasonId, timeZone.value]);

  const handleLinkClick = useCallback(() => {
    appNavigate(DesktopPage.FootballSeasonStandings, {
      seasonId,
      uniqueTournamentId: tournamentId,
    });
  }, [seasonId, tournamentId]);

  return (
    <SeasonDetails isLoading={isStagesLoading} onLinkClick={handleLinkClick} stages={stages} />
  );
};

export default {
  Desktop: connect(extendSeasonDetails(SeasonDetailsView.Desktop)),
  DesktopNarrow: connect(extendSeasonDetails(SeasonDetailsView.DesktopNarrow)),
  Tablet: connect(extendSeasonDetails(SeasonDetailsView.Tablet)),
  TabletNarrow: connect(extendSeasonDetails(SeasonDetailsView.TabletNarrow)),
  Mobile: connect(extendSeasonDetails(SeasonDetailsView.Mobile)),
};
