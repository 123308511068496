import { DATE_FORMATS } from 'packages/date-az/consts';
import DateService from 'packages/date-az';

import { Enums, Interfaces } from '../types';
import { RANGE_FILTER_PARAMETER_BY_STAT_TYPE } from '../constants';

const formatRangeFilters = (
  rangeFilters: Interfaces.CustomFilter.RangeFilterState,
  ages: Interfaces.CustomFilter.Range,
  statType: Enums.StatType,
) => {
  const minBirthDate = ages.from
    ? DateService.getDateByYearOffset(parseInt(ages.from)).toFormat(DATE_FORMATS.DEFAULT)
    : undefined;

  const maxBirthDate = ages.to
    ? DateService.getDateByYearOffset(parseInt(ages.to)).toFormat(DATE_FORMATS.DEFAULT)
    : undefined;

  return {
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinGoals]]:
      rangeFilters[Enums.ColumnType.Goals]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxGoals]]:
      rangeFilters[Enums.ColumnType.Goals]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinAssists]]:
      rangeFilters[Enums.ColumnType.Assists]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxAssists]]:
      rangeFilters[Enums.ColumnType.Assists]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinGoalsAndAssists]]:
      rangeFilters[Enums.ColumnType.GoalsAndAssists]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxGoalsAndAssists]]:
      rangeFilters[Enums.ColumnType.GoalsAndAssists]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinHeadedGoals]]:
      rangeFilters[Enums.ColumnType.HeadedGoals]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxHeadedGoals]]:
      rangeFilters[Enums.ColumnType.HeadedGoals]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinPenaltyGoals]]:
      rangeFilters[Enums.ColumnType.PenaltyGoals]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxPenaltyGoals]]:
      rangeFilters[Enums.ColumnType.PenaltyGoals]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinShots]]:
      rangeFilters[Enums.ColumnType.Shots]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxShots]]:
      rangeFilters[Enums.ColumnType.Shots]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinShotsOffGoal]]:
      rangeFilters[Enums.ColumnType.ShotsOffGoal]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxShotsOffGoal]]:
      rangeFilters[Enums.ColumnType.ShotsOffGoal]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinShotsOnGoal]]:
      rangeFilters[Enums.ColumnType.ShotsOnGoal]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxShotsOnGoal]]:
      rangeFilters[Enums.ColumnType.ShotsOnGoal]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinGoalConversation]]:
      rangeFilters[Enums.ColumnType.GoalConversion]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxGoalConversation]]:
      rangeFilters[Enums.ColumnType.GoalConversion]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinOffside]]:
      rangeFilters[Enums.ColumnType.Offside]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxOffside]]:
      rangeFilters[Enums.ColumnType.Offside]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinYellowCards]]:
      rangeFilters[Enums.ColumnType.YellowCards]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxYellowCards]]:
      rangeFilters[Enums.ColumnType.YellowCards]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinYellowRedCards]]:
      rangeFilters[Enums.ColumnType.YellowRedCards]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxYellowRedCards]]:
      rangeFilters[Enums.ColumnType.YellowRedCards]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinRedCards]]:
      rangeFilters[Enums.ColumnType.RedCards]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxRedCards]]:
      rangeFilters[Enums.ColumnType.RedCards]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinCleanSheets]]:
      rangeFilters[Enums.ColumnType.CleanSheets]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxCleanSheets]]:
      rangeFilters[Enums.ColumnType.CleanSheets]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinGamesPlayed]]:
      rangeFilters[Enums.ColumnType.GamesPlayed]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxGamesPlayed]]:
      rangeFilters[Enums.ColumnType.GamesPlayed]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinMinutesPlayed]]:
      rangeFilters[Enums.ColumnType.MinutesPlayed]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxMinutesPlayed]]:
      rangeFilters[Enums.ColumnType.MinutesPlayed]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MinDateOfBirth]]: maxBirthDate,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][Enums.RangeType.MaxDateOfBirth]]: minBirthDate,
  };
};

export default formatRangeFilters;
