import React, { memo, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { Stage } from 'packages/event-standings/types';
import { TimeZone } from 'types';

import SeasonStandingsView from './views';
import connect from './connect';

type Props = {
  isStagesLoading: boolean;
  seasonStandingsActions: ActionCreatorsMapObject;
  seasonStagesActions: ActionCreatorsMapObject;
  stages: Stage[] | null;
  timeZone: TimeZone;
};

const extendSeasonStandings = (
  SeasonStandings: typeof SeasonStandingsView[keyof typeof SeasonStandingsView],
) =>
  memo(
    ({ stages, isStagesLoading, seasonStagesActions, seasonStandingsActions, timeZone }: Props) => {
      const { seasonId } = useParams();

      useEffect(() => {
        seasonStagesActions.loadStages(seasonId);

        return () => {
          seasonStandingsActions.cleanSeasonStandings();
        };
      }, [seasonId, timeZone.value]);

      return <SeasonStandings isStagesLoading={isStagesLoading} stages={stages} />;
    },
  );

export default {
  Desktop: connect(extendSeasonStandings(SeasonStandingsView.Desktop)),
  DesktopNarrow: connect(extendSeasonStandings(SeasonStandingsView.DesktopNarrow)),
  Tablet: connect(extendSeasonStandings(SeasonStandingsView.Tablet)),
  TabletNarrow: connect(extendSeasonStandings(SeasonStandingsView.TabletNarrow)),
  Mobile: connect(extendSeasonStandings(SeasonStandingsView.Mobile)),
};
