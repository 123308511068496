import { RefObject } from 'react';
import { getScrollParent } from 'packages/utils/Scroll.utils';

type Params = {
  transferBlockRef: RefObject<HTMLDivElement>;
  headerTopOffset: number;
};

const scrollToHistory = ({ transferBlockRef, headerTopOffset }: Params) => {
  const scrollContainer = getScrollParent(transferBlockRef.current);
  const scrollElement = scrollContainer === document.body ? window : scrollContainer;
  const { scrollTop: scrolledValue } = scrollContainer || {};
  const { top: containerTopOffset = 0 } = scrollContainer.getBoundingClientRect() || {};
  const { top: tableTopOffset = 0 } = transferBlockRef.current?.getBoundingClientRect() || {};
  const scrollOffset = tableTopOffset - headerTopOffset - containerTopOffset + scrolledValue;

  scrollElement.scrollTo({
    left: 0,
    top: scrollOffset,
    behavior: 'smooth',
  });
};

export default scrollToHistory;
