import Dexie from 'dexie';

import { Tables } from 'configure/storage/types';
import { keyValueDatabase } from 'packages/storage';
import { Tournament } from 'types';

import {
  updateFavoriteLeaguesStart,
  updateFavoriteLeaguesSuccess,
  updateFavoriteLeaguesFailure,
} from '../actions';
import countFavoriteEventInAllCategories from './countFavoriteEventInAllCategories';
import extendItemWithOrder from './extendItemWithOrder';
import loadActiveFavoritesByCategory from './loadActiveFavoritesByCategory';
import loadLatestLeaguesFromStorage from './loadLatestLeaguesFromStorage';

const toggleLeague = (league: Tournament) => async (dispatch) => {
  try {
    dispatch(updateFavoriteLeaguesStart());

    const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
    const favoritesModel = dexieInstance.table(Tables.FAVORITES_TABLE);
    const favoriteEventsModel = dexieInstance.table(Tables.FAVORITE_EVENTS);
    const favoriteTeamsModel = dexieInstance.table(Tables.FAVORITE_TEAMS);
    const favoriteLeaguesModel = dexieInstance.table(Tables.FAVORITE_LEAGUES);

    const isLeagueFavorite = await favoriteLeaguesModel
      .where('uniqueTournamentId')
      .equals(league.uniqueTournamentId)
      .first();

    if (isLeagueFavorite) {
      const leagueFavorites = await favoritesModel
        .where('uniqueTournamentId')
        .equals(league.uniqueTournamentId)
        .toArray();

      await dexieInstance.transaction(
        'rw',
        [favoritesModel, favoriteEventsModel, favoriteTeamsModel, favoriteLeaguesModel],
        () =>
          Promise.all([
            ...leagueFavorites.map(async (event) => {
              const favoriteEventCategoriesCount = await dispatch(
                countFavoriteEventInAllCategories(event),
              );

              if (favoriteEventCategoriesCount === 1) {
                return favoritesModel.delete(event.id);
              }
            }),
            favoriteLeaguesModel.delete(league.uniqueTournamentId),
          ]),
      );
    } else {
      const [leagueFavoriteEvents, orderNumberExtendedLeague] = await Promise.all([
        dispatch(loadActiveFavoritesByCategory({ leagueIds: [league.uniqueTournamentId] })),
        dispatch(extendItemWithOrder(league, Tables.FAVORITE_LEAGUES)),
      ]);

      await dexieInstance.transaction('rw', [favoritesModel, favoriteLeaguesModel], () =>
        Promise.all([
          favoritesModel.bulkPut(leagueFavoriteEvents),
          favoriteLeaguesModel.put(orderNumberExtendedLeague),
        ]),
      );
    }

    const latestFavoriteLeagues = await dispatch(loadLatestLeaguesFromStorage());

    dispatch(updateFavoriteLeaguesSuccess(latestFavoriteLeagues));
  } catch (e) {
    dispatch(updateFavoriteLeaguesFailure(e));
  }
};

export default toggleLeague;
