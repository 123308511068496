import React, { memo } from 'react';

import { Grid } from 'modules/shared/layouts';
import { FavoriteTeams, FavoriteTournaments } from 'packages/favorites-ui';
import { GridColumnId } from 'types';

import styles from './styles.scss';

const Categories = () => {
  return (
    <Grid.SingleColumn isSecondaryOpen={false}>
      <div className={styles.container} key={GridColumnId.Primary}>
        <FavoriteTeams.Tablet />
        <FavoriteTournaments.Tablet />
      </div>
      <></>
    </Grid.SingleColumn>
  );
};

export default memo(Categories);
