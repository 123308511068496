import { toCamelCase } from 'packages/utils/Object.utils';
import { Category } from 'types';

const mapCategory = (category: object): Category => {
  const { id, name, logo } = toCamelCase(category, true);

  return {
    id,
    name,
    logo,
  };
};

export default mapCategory;
