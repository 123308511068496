import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { scheduledEventsSelectors, scheduledEventsThunks } from 'packages/events';
import { favoritesSelectors } from 'packages/favorites';
import { settingsSelectors } from 'packages/settings';
import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => ({
  timeZone: settingsSelectors.getTimeZone(state),
  favoriteTournaments: favoritesSelectors.getFavoriteLeagues(state),
  isLoading: scheduledEventsSelectors.getIsScheduledEventsLoading(state),
  hasNext: scheduledEventsSelectors.getHasNextScheduledEvents(state),
  activeSportId: settingsSelectors.getActiveSportId(state),
  activeEventId: eventDetailsSelectors.getActiveEventId(state),
  events: scheduledEventsSelectors.getScheduledEvents(state),
});

const mapDispatchToProps = (dispatch) => ({
  scheduledEventsActions: bindActionCreators(scheduledEventsThunks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
