import { toCamelCase } from 'packages/utils/Object.utils';

import { Country } from '../types';

const mapFilters = ({ countries }) => {
  return {
    countries: countries.map(mapCountry),
  };
};

const mapCountry = (nationality): Country => {
  const { id, name, logo } = toCamelCase(nationality);

  return { id, name, logo };
};

export default mapFilters;
