import { ReducerMap, handleActions } from 'redux-actions';

import { historyActions } from 'packages/season-history/actions';
import { SeasonHistory } from 'packages/season-history/types';

const defaultState: SeasonHistory = {
  seasons: [],
  champions: [],
  firstSeason: '',
};

const reducerMap: ReducerMap<SeasonHistory, SeasonHistory> = {
  [`${historyActions.loadHistorySuccess}`]: (state, { payload }) => {
    return payload;
  },

  [`${historyActions.cleanHistory}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
