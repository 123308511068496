import React from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { BlockLink } from 'packages/shared/components';

import { ThemedNavigationMenuProps } from '../../types';
import styles from './styles.scss';

const ButtonNavigationMenu = ({
  items,
  label,
  activeId,
  onClick,
  itemComponent,
  className,
  isDisabled = false,
  isLinkLabelDisabled = false,
  isWidthLimited,
  linkLabel,
  onLinkClick,
}: ThemedNavigationMenuProps) => {
  return (
    <div
      className={classnames(
        styles.buttonNavigationMenuContainer,
        { [styles.limitedMaxWidth]: isWidthLimited },
        className,
      )}
    >
      <div className={styles.control}>
        {!!label && <div className={styles.label}>{label}</div>}
        <ul
          className={classnames(styles.buttonNavigationMenu, {
            [styles.limitedMaxWidth]: isWidthLimited,
          })}
        >
          {items.map((item) => {
            const { id, name, isDisabled: isItemDisabled, hasMaxWidth } = item;
            const isTabDisabled = isItemDisabled || isDisabled;
            const Component = item.itemComponent || itemComponent;
            const isActive = activeId === id;

            return (
              <li
                key={id}
                className={classnames(styles.item, {
                  [styles.active]: isActive,
                  [styles.disabled]: isTabDisabled,
                  [styles.maxWidth]: hasMaxWidth,
                })}
                onClick={() => !isTabDisabled && onClick(id)}
              >
                <div className={styles.itemName}>
                  {Component ? <Component {...item.itemComponentProps} /> : name}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
      {!isEmpty(linkLabel) && (
        <BlockLink label={linkLabel} onClick={onLinkClick} isDisabled={isLinkLabelDisabled} />
      )}
    </div>
  );
};

export default React.memo(ButtonNavigationMenu);
