import React, { memo } from 'react';

import { Pagination } from 'packages/shared/components';
import { TournamentView } from 'packages/events/types';
import { PaginationParams } from 'packages/shared/components/Pagination/types';
import { YearItem } from 'packages/shared/components/Calendar/types';
import { PlayerTeam } from 'packages/player-events/types';
import { EventActiveFilters, Tournament } from 'packages/team-events/types';

import { EventList, EventFilter, HeaderSkeleton } from './components';
import { EventListSkeleton } from '../../components';
import styles from './styles.scss';

type Props = {
  onPeriodChange: (period: [string, string]) => void;
  onYearClick: (year: YearItem) => void;
  onPaginationChange: (params: PaginationParams) => void;
  onEventClick: (eventId: number) => void;
  teamFilters: PlayerTeam[];
  tournamentFilters: Tournament[];
  activeFilters: EventActiveFilters;
  eventsCount: number;
  events: TournamentView[];
  isMoreEventsLoading: boolean;
  useModalOnCategoryClick?: boolean;
  limit: number;
  isInitialDataLoading: boolean;
};

const TeamEvents = ({
  onPeriodChange,
  onYearClick,
  onPaginationChange,
  onEventClick,
  teamFilters,
  isMoreEventsLoading,
  tournamentFilters,
  activeFilters,
  events,
  eventsCount,
  limit,
  useModalOnCategoryClick = false,
  isInitialDataLoading,
}: Props) => (
  <>
    {isInitialDataLoading ? (
      <>
        <HeaderSkeleton />
        <EventListSkeleton elementsCount={limit} />
      </>
    ) : (
      <>
        <EventFilter
          onYearClick={onYearClick}
          teamFilters={teamFilters}
          onPeriodChange={onPeriodChange}
          activeFilters={activeFilters}
          eventsCount={eventsCount}
          tournaments={tournamentFilters}
        />
        <EventList
          useModalOnCategoryClick={useModalOnCategoryClick}
          onEventClick={onEventClick}
          isLoading={isMoreEventsLoading}
          events={events}
        />
      </>
    )}

    <Pagination
      isLoading={isInitialDataLoading}
      resultsLabel="common.pagination.gamesLabel"
      onPaginationChange={onPaginationChange}
      className={styles.paginationContainer}
      useRightAlignment
      amountOfItems={eventsCount}
      pageSize={limit}
      isLimitSelectable
    />
  </>
);

export default memo(TeamEvents);
