import { appActions } from 'modules/app';

export default () => {
  const root = document.getElementById('root');

  return (next) => (action) => {
    if (action.type === `${appActions.setDisplayMode}`) {
      const { mediaScreen } = action.payload;
      root.className = mediaScreen;
    }
    next(action);
  };
};
