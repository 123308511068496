import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { Team } from 'types';
import { Separator } from 'packages/shared/components';

import { DraggableWrapper, ListSkeleton } from '../../../../components';
import { TeamDraggableItem } from '../../components';
import { SortableItem } from '../../../../types';
import styles from './styles.scss';

type Props = {
  teams: Team[];
  onDelete: (teams: SortableItem) => void;
  onDragEnd: (teams: SortableItem[]) => void;
  onClick: (team: SortableItem) => void;
  isPopularTournamentsLoading: boolean;
  isCategoriesLoading: boolean;
};

const FavoriteTeams = ({
  teams,
  onDelete,
  onDragEnd,
  onClick,
  isPopularTournamentsLoading,
  isCategoriesLoading,
}: Props) => {
  if (isPopularTournamentsLoading || isCategoriesLoading) {
    return <ListSkeleton elementsCount={4} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.title}>{i18n.value('leftBar.favoriteTeams.title')}</div>
      <Separator />

      <DraggableWrapper
        useControlsHoverEffect
        emptyMessage={i18n.value('leftBar.favoriteTeams.noItems')}
        items={teams}
        itemComponent={TeamDraggableItem}
        onClick={onClick}
        onDelete={onDelete}
        onDragEnd={onDragEnd}
      />
    </div>
  );
};

export default memo(FavoriteTeams);
