import { toCamelCase } from 'packages/utils/Object.utils';
import DateService from 'packages/date-az';

import { Tabs } from '../types';

const now = DateService.getDate();

const getMetaData = (cardEvent) => {
  const { comments, details, standings, lineUp, scheduled } = toCamelCase(cardEvent);
  const eventScheduledDate = DateService.parse(scheduled);

  return {
    availableTabs: {
      [Tabs.Comments]: comments,
      [Tabs.Statistics]: details,
      [Tabs.Standings]: standings,
      [Tabs.LineUp]: lineUp,
      [Tabs.Statistics]: eventScheduledDate < now,
    },
  };
};

export default getMetaData;
