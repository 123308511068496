import { useCallback, useMemo } from 'react';

import { useStageTabs } from 'packages/season-standings-ui/hooks';
import { SelectItem } from 'packages/shared/components/Select/types';

import { mapStageTabsIntoSelectItems } from '../services';

const useTabsAsSelect = (Tabs: ReturnType<typeof useStageTabs>) => {
  const selectItems = useMemo(() => mapStageTabsIntoSelectItems(Tabs.tabs), [Tabs.tabs]);
  const activeSelectItem = useMemo(() => selectItems.find(({ id }) => id === Tabs.activeTab?.id), [
    selectItems,
    Tabs.activeTab,
  ]);

  const handleSelectItem = useCallback(
    (selectedItem: SelectItem) => {
      Tabs.setActiveTabById(selectedItem.id);
    },
    [Tabs],
  );

  return {
    selectItems,
    activeSelectItem,
    handleSelectItem,
  };
};

export default useTabsAsSelect;
