import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Skeleton height={14} width={90} variant="rectangular" />
        <Skeleton height={14} width={48} marginLeft="auto" variant="rectangular" />
      </div>

      <div className={styles.content}>
        <div className={styles.playerSkeleton}>
          <Skeleton height={68} width={68} marginRight={15} variant="rectangular" />
          <div>
            <Skeleton height={28} marginBottom={10} width={70} variant="rectangular" />
            <Skeleton height={28} width={50} variant="rectangular" />
          </div>
        </div>

        <Skeleton height={58} width={30} variant="rectangular" />
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
