import i18n from 'i18n-smart';

import { EventTabCategory } from 'packages/events/types';

import { TabView } from 'packages/shared/components/Tabs/types';

import {
  AllGames,
  Live,
  Scheduled,
  Finished,
  MyGames,
  FavoriteGamesTabHeader,
  LiveEventNavigationTab,
} from '../components';

const getEventTabs = (): TabView[] => {
  return [
    {
      id: EventTabCategory.Default,
      name: i18n.value(`eventsMain.navigation.${EventTabCategory.Default}`),
      contentComponent: AllGames,
    },
    {
      id: EventTabCategory.Live,
      contentComponent: Live,
      tabComponent: LiveEventNavigationTab,
      tabComponentProps: {
        tabName: i18n.value(`eventsMain.navigation.${EventTabCategory.Live}`),
      },
    },
    {
      id: EventTabCategory.Scheduled,
      name: i18n.value(`eventsMain.navigation.${EventTabCategory.Scheduled}`),
      contentComponent: Scheduled,
    },
    {
      id: EventTabCategory.Finished,
      name: i18n.value(`eventsMain.navigation.${EventTabCategory.Finished}`),
      contentComponent: Finished,
    },
    {
      id: EventTabCategory.MyGames,
      name: i18n.value(`eventsMain.navigation.${EventTabCategory.MyGames}`),
      contentComponent: MyGames,
      tabComponent: FavoriteGamesTabHeader,
      tabComponentProps: {
        tabName: i18n.value(`eventsMain.navigation.${EventTabCategory.MyGames}`),
      },
    },
  ];
};

export default getEventTabs;
