import { SeasonNewcomers } from 'packages/rest';

import * as services from '../services';
import * as actions from '../actions';

export const loadNewcomers = (seasonId: number) => async (dispatch) => {
  try {
    dispatch(actions.loadNewcomersStart());

    const { data } = await SeasonNewcomers.loadSeasonNewcomers({ seasonId });
    const newcomers = services.mapSeasonNewcomers(data.results);

    dispatch(actions.loadNewcomersSuccess(newcomers));
  } catch (e) {
    dispatch(actions.loadNewcomersFailure(e));
  }
};
