import getPath from '../getPath';
import { TopTeamsStatus } from '../../types';

const getStatus = (state): TopTeamsStatus => {
  return getPath(state).teams.status;
};

export const getIsTopTeamsLoading = (state): boolean => {
  return getStatus(state).isTopTeamsLoading;
};
