import React from 'react';
import PropTypes from 'prop-types';
import classes from 'classnames';

import styles from './styles.scss';

class ImageLazy extends React.Component {
  constructor(props) {
    super(props);

    this.img = new Image();
    this.state = {
      loaded: false,
    };
  }

  componentDidMount() {
    this.img.addEventListener('load', this.loadSuccess);
    this.startLoad();
  }

  componentWillUnmount() {
    this.img.removeEventListener('load', this.loadSuccess);
  }

  startLoad = () => {
    const { src } = this.props;
    this.img.src = src;
  };

  loadSuccess = () => {
    this.setState({ loaded: true });
  };

  render() {
    const { loaded } = this.state;
    const { className, previewClassName, defaultImage, src, onClick, alt } = this.props;
    const classNames = classes(styles.defaultImg, className, previewClassName);

    const imageStyles = defaultImage
      ? {
        backgroundImage: `url(${defaultImage})`,
      }
      : {};

    if (!loaded) {
      return <div style={imageStyles} className={classNames} />;
    } else {
      return <img src={src} className={className} onClick={onClick} alt={alt} />;
    }
  }
}

ImageLazy.propTypes = {
  src: PropTypes.string.isRequired,
  className: PropTypes.string,
  previewClassName: PropTypes.string,
  defaultImage: PropTypes.string,
  onClick: PropTypes.func,
  alt: PropTypes.string.isRequired,
};

ImageLazy.defaultProps = {
  className: '',
  previewClassName: '',
  defaultImage: '',
  onClick: () => {},
};

export default ImageLazy;
