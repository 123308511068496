import React, { MouseEvent } from 'react';
import classNames from 'classnames';

import styles from './styles.scss';

type Props = {
  src?: string;
  className?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  onMouseLeave?: (event: MouseEvent<HTMLElement>) => void;
  onMouseEnter?: (event: MouseEvent<HTMLElement>) => void;
  isBase64?: boolean;
};

const Figure = ({ src, className, onClick, isBase64, onMouseLeave, onMouseEnter }: Props) => {
  const classes = classNames(styles.image, className);
  const imageStyles = isBase64
    ? { backgroundImage: `url(data:image/png;base64,${src})` }
    : { backgroundImage: `url(${src})` };

  return src ? (
    <figure
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
      style={imageStyles}
      className={classes}
      onClick={onClick}
    />
  ) : (
    <div className={className} />
  );
};

export default React.memo(Figure);
