exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._Em9r4pcL{width:100%;height:100%}._2jrYc-LH,._2IaGivlR{margin-top:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/player-summary-ui/containers/Summary/views/Desktop/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,UAAW,CACX,WAAY,CAHhB,sBAOI,eCRS","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .player-summary {\n    width: 100%;\n    height: 100%;\n  }\n\n  .statistics-container, .transfer-history-container {\n    margin-top: $margin;\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"player-summary": "_Em9r4pcL",
	"playerSummary": "_Em9r4pcL",
	"statistics-container": "_2jrYc-LH",
	"statisticsContainer": "_2jrYc-LH",
	"transfer-history-container": "_2IaGivlR",
	"transferHistoryContainer": "_2IaGivlR"
};