import React from 'react';
import classnames from 'classnames';

import { Event } from 'types';
import { DATE_FORMATS } from 'packages/date-az/consts';
import { isNumber } from 'packages/utils/Object.utils';
import DateService from 'packages/date-az';

import styles from '../../styles.scss';
import connect from './connect';

type Props = {
  event: Event | null;
  selectedEvent: Event | null;
};

const HeadToHeadCell = ({ event, selectedEvent }: Props) => {
  const homeTeam = event?.teams?.find(({ isHomeTeam }) => isHomeTeam);
  const awayTeam = event?.teams?.find(({ isHomeTeam }) => !isHomeTeam);
  const shouldScoreBeDisplayed = isNumber(homeTeam?.score) && isNumber(awayTeam?.score);
  const scoreText = `${homeTeam?.score}-${awayTeam?.score}`;
  const isCellHighlighted = event?.id === selectedEvent?.id;

  if (event?.isFinished) {
    if (shouldScoreBeDisplayed) {
      return (
        <div className={classnames(styles.centered, { [styles.active]: isCellHighlighted })}>
          <span className={classnames(styles.cellContent, { [styles.active]: isCellHighlighted })}>
            {scoreText}
          </span>
        </div>
      );
    }
  } else if (event?.isLive) {
    return (
      <div
        className={classnames(styles.cell, styles.live, styles.centered, {
          [styles.active]: isCellHighlighted,
        })}
      >
        <span className={classnames(styles.cellContent, { [styles.active]: isCellHighlighted })}>
          {scoreText}
        </span>
      </div>
    );
  } else if (event?.scheduled) {
    return (
      <div
        className={classnames(styles.cell, styles.scheduledDate, styles.centered, {
          [styles.active]: isCellHighlighted,
        })}
      >
        <span className={classnames(styles.cellContent, { [styles.active]: isCellHighlighted })}>
          {DateService.formatString(event.date, DATE_FORMATS.SHORT_DATE)}
        </span>
      </div>
    );
  }

  return <></>;
};

export default connect(HeadToHeadCell);
