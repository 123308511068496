import { connect } from 'react-redux';

import { actualGamesSelectors } from 'packages/team-summary';
import { teamActiveTournamentSeasonSelectors } from 'packages/team-active-tournament-season';

const mapStateToProps = (state) => {
  return {
    activeTournamentSeason: teamActiveTournamentSeasonSelectors.getData(state),
    isLoading: actualGamesSelectors.getIsLoading(state),
    events: actualGamesSelectors.getData(state),
  };
};

export default connect(mapStateToProps);
