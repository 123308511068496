import React, { memo } from 'react';
import classnames from 'classnames';

import { HEADER_HEIGHT } from 'modules/shared/layouts/Layout/MobileLayout/constants';
import { Data as StadiumDetailsData } from 'packages/stadium-details/types';
import { useDocumentHeaderShrink } from 'packages/hooks';

import { Background, Description, StadiumTitle, DetailsSkeleton } from './components';
import styles from './styles.scss';

type Props = {
  isLoading: boolean;
  stadiumDetails?: StadiumDetailsData;
  className?: string;
};

const StadiumDetails = ({ stadiumDetails, isLoading, className }: Props) => {
  if (isLoading || !stadiumDetails) {
    return <DetailsSkeleton />;
  }

  const isHeaderShrunk = useDocumentHeaderShrink();
  const { name, countryName, city, logo, constructionYear, capacity, pitchSize } = stadiumDetails;

  return (
    <div
      style={{ top: HEADER_HEIGHT }}
      className={classnames(styles.stadiumDetails, { [styles.shrink]: isHeaderShrunk }, className)}
    >
      <Background isShrink={isHeaderShrunk} className={styles.background} />
      <div className={styles.details}>
        <StadiumTitle isShrink={isHeaderShrunk} name={name} className={styles.stadiumTitle} />
        <Description
          logo={logo}
          countryName={countryName}
          city={city}
          constructionYear={constructionYear}
          capacity={capacity}
          pitchSize={pitchSize}
          className={styles.description}
        />
      </div>
    </div>
  );
};

export default memo(StadiumDetails);
