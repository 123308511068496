import Desktop from './Desktop';
import Mobile from './Mobile';

export default {
  Desktop,
  DesktopNarrow: Desktop,
  Tablet: Desktop,
  TabletNarrow: Mobile,
  Mobile,
};
