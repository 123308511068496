import React from 'react';

import { Skeleton } from 'packages/shared/components';

import { Team, EventInfo } from './components';
import Navigation from './components/Navigation';
import styles from './styles.scss';

const DetailsSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Skeleton height={8} width="80%" variant="rectangular" className={styles.title} />

        <div className={styles.competitors}>
          <Team />
          <EventInfo />
          <Team />
        </div>

        <Navigation />
      </div>
    </div>
  );
};
export default DetailsSkeleton;
