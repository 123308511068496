export enum PenaltyShootoutStatuses {
  SCORED = 'scored',
  MISS = 'missed',
}

export enum PlayerType {
  SubstitutedIn = 'substituted_in',
  SubstitutedOut = 'substituted_out',
  Scorer = 'scorer',
  Assist = 'assist',
  Player = 'player',
}

export enum CardColor {
  Yellow = 'yellow',
  Red = 'red',
  YellowRed = 'yellow-red',
}

export enum PeriodType {
  RegularPeriod = 'regular_period',
  Overtime = 'overtime',
  Penalties = 'penalties',
}

export enum Period {
  First = 1,
  Second = 2,
}
