import i18n from 'i18n-smart';

import { Tournament } from 'packages/player-events/types';
import { SelectItem } from 'packages/shared/components/Select/types';

import getTournamentSelectItem from './getTournamentSelectItem';
import { ALL_ITEMS_OPTION_ID } from '../../../constants';

const getTournamentSelectItems = (tournaments: Tournament[]): SelectItem[] => {
  return [
    {
      id: ALL_ITEMS_OPTION_ID,
      label: i18n.value(`team.teamEvents.filters.TournamentsSelect.label`),
      componentProps: {
        name: i18n.value(`team.teamEvents.filters.TournamentsSelect.label`),
      },
    },
    ...tournaments.map(getTournamentSelectItem),
  ];
};

export default getTournamentSelectItems;
