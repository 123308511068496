import React, { memo } from 'react';
import isEmpty from 'is-empty-typed/lib';

import EmptyCell from '../EmptyCell';
import styles from './styles.scss';

type Props = {
  average?: number;
};

const AttendanceCell = ({ average }: Props) => {
  return <div className={styles.attendanceCell}>{isEmpty(average) ? <EmptyCell /> : average}</div>;
};

export default memo(AttendanceCell);
