export const timeZoneDefault = { value: 0, name: 'GMT+0' };

export const timeZones = [
  { value: 840, name: 'GMT+14' },
  { value: 780, name: 'GMT+13' },
  { value: 720, name: 'GMT+12' },
  { value: 660, name: 'GMT+11' },
  { value: 600, name: 'GMT+10' },
  { value: 540, name: 'GMT+9' },
  { value: 480, name: 'GMT+8' },
  { value: 420, name: 'GMT+7' },
  { value: 360, name: 'GMT+6' },
  { value: 300, name: 'GMT+5' },
  { value: 240, name: 'GMT+4' },
  { value: 180, name: 'GMT+3' },
  { value: 120, name: 'GMT+2' },
  { value: 60, name: 'GMT+1' },
  timeZoneDefault,
  { value: -60, name: 'GMT-1' },
  { value: -120, name: 'GMT-2' },
  { value: -180, name: 'GMT-3' },
  { value: -240, name: 'GMT-4' },
  { value: -300, name: 'GMT-5' },
  { value: -360, name: 'GMT-6' },
  { value: -420, name: 'GMT-7' },
  { value: -480, name: 'GMT-8' },
  { value: -540, name: 'GMT-9' },
  { value: -600, name: 'GMT-10' },
  { value: -660, name: 'GMT-11' },
  { value: -720, name: 'GMT-12' },
  { value: -780, name: 'GMT-13' },
];
