import { Categories } from 'packages/rest';

import { categoryListActions } from '../../actions';
import { mapTournamentExtendedCategory } from '../../services';

const loadSelectedCategory = (categoryId: number) => async (dispatch) => {
  try {
    dispatch(categoryListActions.loadSelectedCategoryStart());

    const { data } = await Categories.loadCategory({ id: categoryId });
    const category = mapTournamentExtendedCategory(data.results);

    dispatch(categoryListActions.loadSelectedCategorySuccess(category));
  } catch (error) {
    dispatch(categoryListActions.loadSelectedCategoryFailure({ error }));
  }
};

export default loadSelectedCategory;
