import * as teamEventsConstants from './constants';
import { filterThunks, eventThunks, commonThunks } from './thunks';
import { eventsSelectors, filterSelectors } from './selectors';
import reducers from './reducers';

import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  teamEventsConstants,
  filterThunks,
  eventThunks,
  commonThunks,
  createReducer,
  eventsSelectors,
  filterSelectors,
};
