import instance from 'packages/rest/axios';

import { TournamentListParams } from '../types';

export const loadTournamentList = ({ stadiumId }: TournamentListParams) => {
  return instance({
    method: 'get',
    url: `/venues/${stadiumId}/unique_tournaments/`,
  });
};
