import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadTimeLineStart,
  cleanTimeLineSuccess,
  loadTimeLineFailure,
  loadTimeLineSuccess,
} from '../actions';

const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<any, any> = {
  [`${loadTimeLineStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(cleanTimeLineSuccess, loadTimeLineSuccess, loadTimeLineFailure)}`]: () => {
    return { isLoading: false };
  },
};

export default handleActions(reducerMap, defaultState);
