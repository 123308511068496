import React, { memo } from 'react';
import { useParams } from 'react-router-dom';

import { Stage } from 'packages/event-standings/types';
import { StandingsBlock } from 'packages/shared/modules';

type Props = {
  stages: Stage[] | null;
  onLinkClick: () => void;
  isLoading?: boolean;
};

const CompactStandings = ({ stages, onLinkClick, isLoading = false }: Props) => {
  const { seasonId, tournamentId } = useParams();

  return (
    <StandingsBlock.Mobile
      activeUniqueTournamentId={tournamentId}
      activeSeasonId={seasonId}
      stages={stages}
      onLinkClick={onLinkClick}
      isLoading={isLoading}
      useStagesControl
    />
  );
};

export default memo(CompactStandings);
