import React from 'react';
import { SortableContainer } from 'react-sortable-hoc';

import { Button } from 'packages/shared/components';
import { ButtonType } from 'packages/shared/components/Button/types';
import { Column } from 'packages/season-player-statistics/types';

import { DraggableButton } from './components';
import styles from './styles.scss';

type Props = {
  columns: Column[];
};

const DraggableButtonListContainer = SortableContainer(({ columns }: Props) => {
  return (
    <div className={styles.columnsGroup}>
      {columns.map(({ isDisabled, type }, index) =>
        isDisabled ? (
          <Button
            className={styles.column}
            isDisabled={isDisabled}
            type={ButtonType.Default}
            key={type}
          >
            {`playerStatistics.customFilter.columnConfiguration.columnName.${type}`}
          </Button>
        ) : (
          <DraggableButton index={index} key={type} type={type} className={styles.column} />
        ),
      )}
    </div>
  );
});

export default DraggableButtonListContainer;
