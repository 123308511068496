import { finishedEventsActions } from '../../actions';
import { loadEvents } from '../common';

interface Params {
  date?: string;
  categoryId?: number;
}

export const loadFinishedEvents = ({ date, categoryId }: Params = {}) => async (dispatch) => {
  try {
    dispatch(finishedEventsActions.loadFinishedEventsStart());

    const eventsData = await dispatch(loadEvents({ categoryId, date, isFinished: true }));

    dispatch(finishedEventsActions.loadFinishedEventsSuccess(eventsData));
  } catch (error) {
    dispatch(finishedEventsActions.loadFinishedEventsFailure(error));
  }
};
