import { Dispatch } from 'redux';

import { EmulatedSocket } from '../core';
import { generateThunkSocketConfig } from '../service';
import { SocketActionCreators, SocketConfig, SocketOptions } from '../types';

export const getSpecifiedThunks = <ItemType>(socketActionCreators: SocketActionCreators) => {
  let eventsSocket: EmulatedSocket;

  return {
    subscribe: <ItemType>(socketConfig: SocketConfig<ItemType>, socketOptions: SocketOptions) => (
      dispatch: Dispatch,
    ) => {
      const localSocketConfig: SocketConfig<ItemType> = generateThunkSocketConfig(
        socketConfig,
        socketActionCreators,
        dispatch,
      );

      eventsSocket = new EmulatedSocket(localSocketConfig, socketOptions);
      eventsSocket.connect();
    },
    unsubscribe: () => () => {
      eventsSocket?.close();
    },
  };
};
