import { Team } from 'types';

import { toCamelCase } from 'packages/utils/Object.utils';
import { eventsServices } from 'packages/events';

import { Filters } from '../types';

export const mapSeasonFilters = (seasonEvents: any): Filters => {
  const { rounds, teams } = seasonEvents;

  return {
    rounds: rounds.map(eventsServices.mapRound),
    teams: teams.map(mapTeam),
  };
};

const mapTeam = (team): Team => {
  const { id, name, logo } = toCamelCase(team);

  {
    return { id, name, logo };
  }
};
