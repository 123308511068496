exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._28Y2OPQp{position:absolute;height:100%;width:100%;overflow:hidden}._3o6QI82b{overflow-y:scroll;scrollbar-width:none;-ms-overflow-style:none}._3o6QI82b::-webkit-scrollbar{display:none;background:transparent;width:0;height:0}._3o6QI82b ._3v0r1qmJ:first-of-type{border-top:unset}._3o6QI82b ._3MTK9Qhh{z-index:4}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/modules/PlayerStatisticsTable/views/Desktop/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/scroll.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,iBAAkB,CAClB,WAAY,CACZ,UAAW,CACX,eAAgB,CALpB,WCAI,iBAAkB,CAKpB,oBAAqB,CACrB,uBAAwB,CDN1B,8BCUI,YAAa,CACb,sBAAuB,CACvB,OAAQ,CACR,QAAS,CDbb,oCAaQ,gBAAiB,CAbzB,sBAkBM,SAAU","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .loader-container {\n    position: absolute;\n    height: 100%;\n    width: 100%;\n    overflow: hidden;\n  }\n\n  .table {\n    @include hidden-scroll;\n\n    .table-row {\n      &:first-of-type {\n        border-top: unset;\n      }\n    }\n\n    .header {\n      z-index: 4;\n    }\n  }\n}\n","@mixin hidden-scroll($axis: y) {\n  @if $axis == y {\n    overflow-y: scroll;\n  } @else {\n    overflow-x: scroll;\n  }\n\n  scrollbar-width: none; /* Firefox */\n  -ms-overflow-style: none; /* Internet Explorer 10+ */\n\n  &::-webkit-scrollbar {\n    /* WebKit */\n    display: none;\n    background: transparent;\n    width: 0;\n    height: 0;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"loader-container": "_28Y2OPQp",
	"loaderContainer": "_28Y2OPQp",
	"table": "_3o6QI82b",
	"table-row": "_3v0r1qmJ",
	"tableRow": "_3v0r1qmJ",
	"header": "_3MTK9Qhh"
};