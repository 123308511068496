import React from 'react';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from './styles.scss';

type Props = {
  onClick: () => void;
};

const CloseButton = ({ onClick }: Props) => {
  return (
    <div className={styles.closeButton} onClick={onClick}>
      <Icon className={styles.icon} id={IconType.Cross} />
    </div>
  );
};

export default CloseButton;
