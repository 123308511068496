import { TimeZone } from 'types';
import { settingsSelectors } from 'packages/settings';

import instance from '../axios';

const appendTimeZone = (getState) => {
  instance.interceptors.request.use((req) => {
    const timeZone: TimeZone = settingsSelectors.getTimeZone(getState());

    req.headers['Time-Zone'] = timeZone.value;

    return req;
  });
};

export default appendTimeZone;
