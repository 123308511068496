import React from 'react';
import i18n from 'i18n-smart';

type Props = {
  dayCount: string;
};

const DayView = ({ dayCount }: Props) => (
  <span>{i18n.pluralize('timerCountdown.days', { value: Number(dayCount) })}</span>
);

export default DayView;
