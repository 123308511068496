import Desktop from './Desktop';
import Tablet from './Tablet';
import Mobile from './Mobile';

export default {
  Desktop,
  DesktopNarrow: Desktop,
  Tablet: Tablet,
  TabletNarrow: Mobile,
  Mobile: Mobile,
};
