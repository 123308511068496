import { handleRemovedLiveEvents } from 'packages/season-events/thunks';
import { Events } from 'packages/rest';
import { mapEvents } from 'packages/events/services';
import { settingsSelectors } from 'packages/settings';
import { SocketConfig, SocketOptions, StorageDataType } from 'packages/socket/types';
import { Event } from 'types';

import { updateLiveEvents } from '../../actions';
import { subscribe } from './eventsSocket';

export const subscribeForLiveEvents = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const activeSportId = settingsSelectors.getActiveSportId(state);

    const fetchData = async () => {
      const { data } = await Events.loadLiveEvents({ activeSportId });

      return mapEvents(data.results);
    };

    const handleLiveEventsUpdate = ({ data }) => {
      dispatch(updateLiveEvents({ events: data }));
    };

    const handleRemovedEvents = ({ data }) => {
      dispatch(handleRemovedLiveEvents(data));
    };

    const socketConfig: SocketConfig<Event> = {
      fetchData,
      dataReducerFactoryConfig: {
        storageDataType: StorageDataType.Map,
        handleNewData: handleLiveEventsUpdate,
        handleUpdatedData: handleLiveEventsUpdate,
        handleRemovedData: handleRemovedEvents,
      },
    };

    const socketOptions: SocketOptions = {
      fetchByInitialize: true,
    };

    dispatch(subscribe(socketConfig, socketOptions));
  };
};
