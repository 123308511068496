import { ReactNode, RefObject, useEffect, useState } from 'react';

type Params = {
  contentRef: RefObject<HTMLDivElement>;
  children: ReactNode | ReactNode[];
};

const useContentHeight = ({ children, contentRef }: Params) => {
  const [contentHeight, setContentHeight] = useState<number>(0);

  useEffect(() => {
    if (contentRef?.current) {
      setContentHeight(contentRef.current.getBoundingClientRect().height);
    }
  }, [children]);

  return contentHeight;
};

export default useContentHeight;
