import React, { memo } from 'react';
import i18n from 'i18n-smart';

import { Tournament } from 'types';
import { isEmpty } from 'packages/utils/Object.utils';
import { Separator, TournamentPreview } from 'packages/shared/components';

import connect from './connect';
import styles from './styles.scss';

type Props = {
  tournaments: Tournament[];
  favoriteLeagues: Tournament[];
  onFavoriteTournamentToggle: (tournament: Tournament) => () => void;
  useControlsHoverEffect?: boolean;
  activeDate: string;
};

const Tournaments = ({
  tournaments,
  favoriteLeagues,
  onFavoriteTournamentToggle,
  useControlsHoverEffect,
  activeDate,
}: Props) => {
  return (
    <>
      {!isEmpty(tournaments) ? (
        tournaments.map((tournament) => {
          const isTournamentFavorite = Boolean(
            favoriteLeagues.find(
              ({ uniqueTournamentId }) => uniqueTournamentId === tournament.uniqueTournamentId,
            ),
          );

          return (
            <div key={tournament.uniqueTournamentId}>
              <TournamentPreview
                useHoverEffect={useControlsHoverEffect}
                onFavoriteToggle={onFavoriteTournamentToggle(tournament)}
                tournament={tournament}
                isFavorite={isTournamentFavorite}
                activeDate={activeDate}
                isTournamentUnique
              />
              <Separator />
            </div>
          );
        })
      ) : (
        <>
          <div className={styles.empty}>{i18n.value('leftBar.popularTournaments.noItems')}</div>
          <Separator />
        </>
      )}
    </>
  );
};

export default connect(memo(Tournaments));
