import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Ordering, StadiumsOrdering } from 'packages/rest/api/SeasonStadiums/types';
import styles from 'packages/shared/components/SortableTableHeader/styles.scss';
import { OrderType } from 'types';

type Props = {
  orderingValue: Ordering;
  ordering: StadiumsOrdering;
  label: string;
  onHeaderCellClick: (orderingType: Ordering) => void;
};

const SortableHeader = ({ orderingValue, ordering, onHeaderCellClick, label }: Props) => {
  const { name, type } = ordering;

  const handleHeaderCellClick = () => {
    onHeaderCellClick(orderingValue);
  };

  const isOrderingActive = name === orderingValue;

  return (
    <div
      className={classnames(styles.parameterHeader, {
        [styles.ascending]: isOrderingActive && type === OrderType.Ascending,
        [styles.descending]: isOrderingActive && type === OrderType.Descending,
      })}
      onClick={handleHeaderCellClick}
    >
      {i18n.value(label)}
    </div>
  );
};
export default memo(SortableHeader);
