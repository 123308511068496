import i18n from 'i18n-smart';

import { SelectItem } from 'packages/shared/components/Select/types';
import { teamPlayerStatisticsConstants } from 'packages/team-player-statistics';

const { CUSTOMIZATION_PRESET_BY_TYPE } = teamPlayerStatisticsConstants;

const getPresetItems = (): SelectItem[] =>
  Object.values(CUSTOMIZATION_PRESET_BY_TYPE).map(({ type }) => ({
    id: type,
    label: i18n.value(
      `team.playerStatistics.customFilter.columnConfiguration.configurationPreset.${type}`,
    ),
  }));

export default getPresetItems;
