import Dexie from 'dexie';

import { Tables, FavoritesLeaguesTableIndex } from 'configure/storage/types';
import { keyValueDatabase } from 'packages/storage';

import extendFavoriteLeaguesWithMatchCounts from './extendFavoriteLeaguesWithMatchCounts';

const loadLatestLeaguesFromStorage = () => async (dispatch) => {
  const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
  const favoriteLeaguesModel = dexieInstance.table(Tables.FAVORITE_LEAGUES);

  const latestLeagues = await favoriteLeaguesModel
    .orderBy(FavoritesLeaguesTableIndex.OrderNumber)
    .toArray();

  return dispatch(extendFavoriteLeaguesWithMatchCounts(latestLeagues));
};

export default loadLatestLeaguesFromStorage;
