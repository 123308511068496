import React from 'react';
import { Column } from 'react-table';

import { ColumnType } from 'packages/season-team-statistics/types';
import { Skeleton } from 'packages/shared/components';

const centerAlignStyle = {
  display: 'flex',
  justifyContent: 'center',
};

const getSkeletonTableSchema = (): Column[] => [
  {
    id: ColumnType.Rank,
    headerStyle: {},
    cellStyle: { paddingLeft: 16, ...centerAlignStyle },
    Header: () => null,
    Cell: <Skeleton variant="rectangular" height={14} width={8} />,
    width: 40,
  },
  {
    id: ColumnType.ClubLogo,
    Header: () => null,
    cellStyle: { padding: '8px' },
    Cell: <Skeleton variant="circular" />,
    width: 40,
  },
  {
    id: ColumnType.Club,
    Header: () => null,
    Cell: <Skeleton marginLeft={8} height={14} width={80} variant="rectangular" />,
    width: 150,
  },
  {
    id: ColumnType.GamesPlayed,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.GoalsScored,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.GoalsConceded,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.Possession,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
  {
    id: ColumnType.ShotsOnGoal,
    Header: <Skeleton height={10} variant="rectangular" />,
    headerStyle: { padding: '11px 0', ...centerAlignStyle },
    cellStyle: centerAlignStyle,
    Cell: <Skeleton height={14} width={30} variant="rectangular" />,
    minWidth: 60,
    width: 60,
  },
];

export default getSkeletonTableSchema;
