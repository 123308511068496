import React, { ReactElement } from 'react';

import styles from './styles.scss';

type Props = {
  children: ReactElement[];
};

const SingleColumn = ({ children }: Props) => {
  return (
    <div className={styles.container}>
      {children.map((child) => (
        <div id={`${child.key}`} key={child.key!} className={styles.column}>
          {child}
        </div>
      ))}
    </div>
  );
};

export default SingleColumn;
