import React, { memo, useMemo } from 'react';

import { Skeleton } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';

import styles from './styles.scss';

type Props = {
  tournamentsCount?: number;
};

const DEFAULT_TOURNAMENTS_COUNT = 5;

const LoadingSkeleton = ({ tournamentsCount = DEFAULT_TOURNAMENTS_COUNT }: Props) => {
  const tournaments = useMemo(() => ArrayUtils.getArrayFromNumber(tournamentsCount), []);

  return (
    <>
      <Skeleton height={35} width={280} marginBottom={7} variant="rectangular" />
      <div className={styles.tournamentList}>
        {tournaments.map((tournament) => (
          <div key={tournament} className={styles.tournament}>
            <Skeleton variant="circular" height={32} width={32} marginRight={8} />
            <Skeleton variant="rectangular" height={24} width={180} />
          </div>
        ))}
      </div>
    </>
  );
};

export default memo(LoadingSkeleton);
