import * as teamPlayerStatisticsConstants from './constants';
import reducers from './reducers';
import { configure } from './config';

export * from './actions';
export * from './thunks';
export * from './selectors';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export { teamPlayerStatisticsConstants, createReducer };
