import React, { memo } from 'react';
import classnames from 'classnames';

import { Figure } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Event } from 'types';

import styles from '../../styles.scss';
import connect from './connect';

type Props = {
  logo: string;
  name: string;
  teamId: number;
  selectedEvent: Event | null;
  activeTeamIds: number[];
  activeUniqueTournamentId?: string | number;
  activeSeasonId?: string | number;
};

const HeadToHeadTeamColumnCell = ({
  logo,
  name,
  selectedEvent,
  teamId,
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
}: Props) => {
  const { appNavigate } = useNavigation();

  const homeTeam = selectedEvent?.teams[0];
  const isTeamHighlighted = homeTeam?.id === teamId;
  const isTeamActive = activeTeamIds.includes(teamId);

  const handleTeamClick = () => {
    if (activeUniqueTournamentId && activeSeasonId) {
      appNavigate(DesktopPage.FootballTeamSummaryDescription, {
        teamId,
        uniqueTournamentId: activeUniqueTournamentId,
        seasonId: activeSeasonId,
      });
    } else {
      appNavigate(DesktopPage.FootballTeam, { teamId });
    }
  };

  return (
    <div
      className={classnames(styles.cell, styles.header, {
        [styles.active]: isTeamHighlighted || isTeamActive,
      })}
    >
      <Figure onClick={handleTeamClick} src={logo} className={styles.teamLogo} />
      <span className={styles.teamName} onClick={handleTeamClick}>
        {name}
      </span>
    </div>
  );
};

export default connect(memo(HeadToHeadTeamColumnCell));
