import { toCamelCase } from 'packages/utils/Object.utils';
import { mapTournament } from 'packages/events/services';
import { Tournament } from 'types';

import { TournamentsExtendedCategory } from '../types';

const mapTournamentExtendedCategory = (category: object[]): TournamentsExtendedCategory => {
  const { id, name, logo, uniqueTournaments } = toCamelCase(category, true);

  return {
    id,
    name,
    logo,
    tournaments: uniqueTournaments?.map((tournament) => mapCategoryTournament(tournament)),
  };
};

const mapCategoryTournament = (tournament: object): Tournament => {
  const {
    id: uniqueTournamentId,
    currentSeasonId,
    name: uniqueTournamentName,
    ...restTournamentData
  } = toCamelCase(tournament);

  return mapTournament(restTournamentData, {
    currentSeasonId,
    uniqueTournamentName,
    uniqueTournamentId,
  });
};

export default mapTournamentExtendedCategory;
