import { connect } from 'react-redux';

import { stadiumDetailsSelectors } from 'packages/stadium-details';

const mapStateToProps = (state) => {
  return {
    capacity: stadiumDetailsSelectors.getData(state)?.capacity,
  };
};

export default connect(mapStateToProps);
