exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1ruDCAqp{position:relative}._1ruDCAqp._5vTb3YuW{min-width:100%}._1ruDCAqp._yyn6uvQ3{min-width:90px}._1ruDCAqp._3q3dBRjb{min-width:160px}._1ruDCAqp.__COXqapw{min-width:205px}._1ruDCAqp._1wp2_cJq{min-width:230px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Select/containers/SearchSelect/styles.scss"],"names":[],"mappings":"AAEA,WAOI,iBAAkB,CAPtB,qBAUM,cAAe,CAVrB,qBAcM,cAbU,CADhB,qBAkBM,eAhBW,CAFjB,qBAsBM,eAnBW,CAHjB,qBA0BM,eAtBiB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\r\n\r\n:local {\r\n  $sm-size: 90px;\r\n  $md-size: 160px;\r\n  $lg-size: 205px;\r\n  $extra-lg-size: 230px;\r\n\r\n  .main-wrapper {\r\n    position: relative;\r\n\r\n    &.full-width {\r\n      min-width: 100%;\r\n    }\r\n\r\n    &.sm {\r\n      min-width: $sm-size;\r\n    }\r\n\r\n    &.md {\r\n      min-width: $md-size;\r\n    }\r\n\r\n    &.lg {\r\n      min-width: $lg-size;\r\n    }\r\n\r\n    &.extra-lg {\r\n      min-width: $extra-lg-size;\r\n    }\r\n  }\r\n}"]}]);

// Exports
exports.locals = {
	"main-wrapper": "_1ruDCAqp",
	"mainWrapper": "_1ruDCAqp",
	"full-width": "_5vTb3YuW",
	"fullWidth": "_5vTb3YuW",
	"sm": "_yyn6uvQ3",
	"md": "_3q3dBRjb",
	"lg": "__COXqapw",
	"extra-lg": "_1wp2_cJq",
	"extraLg": "_1wp2_cJq"
};