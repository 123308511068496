import instance from 'packages/rest/axios';

import { stringifyOrderingParam } from '../../../services';

import { SeasonStadiumParams } from '../types';

export const loadSeasonStadiums = ({ seasonId, limit, offset, ordering }: SeasonStadiumParams) => {
  const stringifiedOrdering = ordering
    ? stringifyOrderingParam({ [ordering.name]: ordering.type })
    : null;

  return instance({
    method: 'get',
    url: `/seasons/${seasonId}/top_venues/`,
    params: {
      limit,
      offset,
      ordering: stringifiedOrdering,
    },
  });
};
