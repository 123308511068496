import { IconType } from 'packages/shared/components/Icon/types';

export const CARD_TYPE = {
  RED: 'red_card',
  YELLOW: 'yellow_card',
  YELLOW_RED: 'yellow_red_card',
};

export const CARD_ICON_TYPE = {
  [CARD_TYPE.RED]: IconType.RedCard,
  [CARD_TYPE.YELLOW]: IconType.YellowCard,
  [CARD_TYPE.YELLOW_RED]: IconType.YellowRedCard,
};
