import { scheduledEventsSelectors } from '../../selectors';

import { scheduledEventsActions } from '../../actions';
import { loadEvents } from '../common';

interface Params {
  categoryId?: number;
}

export const loadMoreScheduledEvents = ({ categoryId }: Params = {}) => async (
  dispatch,
  getState,
) => {
  try {
    const offset = scheduledEventsSelectors.getScheduledEventsOffset(getState());
    dispatch(scheduledEventsActions.loadMoreScheduledEventsStart());

    const eventsData = await dispatch(loadEvents({ categoryId, offset, isScheduled: true }));

    dispatch(scheduledEventsActions.loadMoreScheduledEventsSuccess(eventsData));
  } catch (error) {
    dispatch(scheduledEventsActions.loadMoreScheduledEventsFailure(error));
  }
};
