import i18n from 'i18n-smart';
import { isEmpty } from 'packages/utils/Object.utils';
import { SelectItem } from '../../../types';

export const getLabels = (
  pluralItemLabel: string,
  storedSelectedItems: SelectItem[],
  defaultLabel?: string,
) => {
  const pluralItemKey = i18n.value(pluralItemLabel);

  const allSelectedLabel = i18n.value('multipleSelect.allLabel', [pluralItemKey]);

  const inputLabel = isEmpty(storedSelectedItems)
    ? allSelectedLabel
    : i18n.value('multipleSelect.label', [i18n.value(pluralItemLabel), storedSelectedItems.length]);

  return {
    inputLabel,
    allSelectedLabel,
    defaultLabel: defaultLabel ? i18n.value(defaultLabel) : undefined,
  };
};
