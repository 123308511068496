import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../../actions';
import { TopOfTheSeason } from '../../types';

const defaultState: TopOfTheSeason = {
  teams: [],
  venues: [],
};

const reducerMap: ReducerMap<TopOfTheSeason, TopOfTheSeason> = {
  [`${actions.loadTopOfTheSeasonSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${actions.cleanTopBoardSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<TopOfTheSeason, TopOfTheSeason>(reducerMap, defaultState);
