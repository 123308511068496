import React, { memo, useCallback } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Input, ItemLabel } from 'packages/shared/components';
import { InputValidationType } from 'packages/shared/components/Input/types';
import { Interfaces, Enums } from 'packages/team-player-statistics/types';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';
import { SelectItem } from 'packages/shared/components/Select/types';

import styles from './styles.scss';

type Props = {
  onActiveStatTypeChange: (statType: Enums.StatType) => void;
  onSelectedPositionsChange: (positions: SelectItem[]) => void;
  onSelectedFootChange: (foot: SelectItem) => void;
  onSelectedNationalitiesChange: (
    nationalities: Interfaces.CustomFilter.NationalitySelectOption[],
  ) => void;
  onAgeRangeChange: (from: string, to: string) => void;
  activeStatType: Enums.StatType;
  statTabs: NavigationMenuItem[];
  positionSelectItems: SelectItem[];
  selectedPositionItems: SelectItem[];
  footSelectItems: SelectItem[];
  selectedFootItem?: SelectItem;
  nationalitySelectItems: Interfaces.CustomFilter.NationalitySelectOption[];
  selectedNationalityItems: Interfaces.CustomFilter.NationalitySelectOption[];
  ageRange: Interfaces.CustomFilter.Range;
  className?: string;
};

const GeneralFilter = ({
  activeStatType,
  onActiveStatTypeChange,
  onSelectedPositionsChange,
  onSelectedFootChange,
  onSelectedNationalitiesChange,
  onAgeRangeChange,
  statTabs,
  positionSelectItems,
  selectedPositionItems,
  footSelectItems,
  selectedFootItem,
  nationalitySelectItems,
  selectedNationalityItems,
  ageRange,
  className,
}: Props) => {
  const handleActiveStatTypeChange = useCallback(
    (activeStatsTab: string | number) => onActiveStatTypeChange(activeStatsTab as Enums.StatType),
    [],
  );

  return (
    <div className={classnames(styles.generalFilterGroup, className)}>
      <Input.Tabs
        onChange={handleActiveStatTypeChange}
        activeId={activeStatType}
        items={statTabs}
        label={i18n.value('team.playerStatistics.customFilter.general.showStatsLabel')}
        name="Show stats"
      />

      <Input.Multiselect
        onChange={onSelectedPositionsChange}
        selectedItems={selectedPositionItems}
        items={positionSelectItems}
        pluralItemLabel="team.playerStatistics.customFilter.general.positionsSelectPlural"
        label={i18n.value('team.playerStatistics.customFilter.general.positionLabel')}
        name="Position"
      />

      <Input.Select
        onChange={onSelectedFootChange}
        selectedItem={selectedFootItem}
        items={footSelectItems}
        label={i18n.value('team.playerStatistics.customFilter.general.preferredFootLabel')}
        name="Preferred foot"
      />

      <Input.SearchSelect<Interfaces.CustomFilter.NationalitySelectOption>
        items={nationalitySelectItems}
        selectedItems={selectedNationalityItems}
        onSelectionChange={onSelectedNationalitiesChange}
        itemComponent={ItemLabel}
        labelPrefix={i18n.value('team.playerStatistics.customFilter.general.searchLabel')}
        label={i18n.value('team.playerStatistics.customFilter.general.nationalityLabel')}
        name="Nationality"
      />

      <Input.TextRange
        startValue={ageRange.from}
        endValue={ageRange.to}
        onRangeValuesChange={onAgeRangeChange}
        inputValidationType={InputValidationType.Integer}
        startPlaceholder="0"
        endPlaceholder="∞"
        label={i18n.value('team.playerStatistics.customFilter.general.ageLabel')}
        name="Age"
      />
    </div>
  );
};

export default memo(GeneralFilter);
