import React, { useMemo, useState } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { useLiveTime } from 'packages/hooks';
import { EventStatus, Event } from 'types';

import { getTimeModifier, getTimeView } from './services';
import styles from './styles.scss';

type Props = {
  event: Event;
  isShort?: boolean;
  className?: string;
};

const LiveEventStatusLabel = ({ event, isShort, className }: Props) => {
  const { periodTime = '', status } = event;

  const [periodMinutePart, setPeriodMinutePart] = useState('');
  const modifyTimeOnCount = useMemo(
    () => getTimeModifier({ onPeriodMinuteUpdate: setPeriodMinutePart, eventStatus: status }),
    [],
  );
  const time = useLiveTime({
    startDate: periodTime,
    modifyTimeOnCount: modifyTimeOnCount,
  });
  const liveTimeView = getTimeView({ isShort, time });

  const periodView = i18n.value(`eventStatusLabel.liveStatus.${status}`);

  return (
    <div className={classnames(styles.liveLabel, className)}>
      {!isShort && (
        <div
          className={classnames({
            [styles.periodWithGap]: !isShort && status !== EventStatus.Penalties,
          })}
        >
          {periodView}
        </div>
      )}
      {status !== EventStatus.Penalties && (
        <div>
          {periodMinutePart}
          {liveTimeView}
        </div>
      )}
    </div>
  );
};

export default LiveEventStatusLabel;
