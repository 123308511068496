import { seasonEventsSelectors, seasonFilterSelectors } from './selectors';
import * as seasonEventsThunks from './thunks';
import * as seasonEventsActions from './actions';
import * as seasonEventsConstants from './constants';
import * as seasonEventsServices from './services';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  createReducer,
  seasonEventsThunks,
  seasonEventsActions,
  seasonEventsSelectors,
  seasonFilterSelectors,
  seasonEventsConstants,
  seasonEventsServices,
};
