import { allEventsActions } from '../../actions';
import { loadEvents } from '../common';

interface Params {
  date?: string;
  categoryId?: number;
}

export const loadAllEvents = ({ date, categoryId }: Params = {}) => async (dispatch) => {
  try {
    dispatch(allEventsActions.loadAllEventsStart());

    const eventsData = await dispatch(loadEvents({ date, categoryId }));

    dispatch(allEventsActions.loadAllEventsSuccess(eventsData));
  } catch (error) {
    dispatch(allEventsActions.loadAllEventsFailure(error));
  }
};
