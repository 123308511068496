import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import * as Common from 'pages/common';
import { Football } from 'pages/tablet';

import { TABLET_PATH } from '../../constants';
import { TabletPage } from '../../types';

const { ROOT, SEASON_CHILDREN, PLAYER_CHILDREN, STADIUM_CHILDREN, TEAM_CHILDREN } = TABLET_PATH;

const FOOTBALL_SEASON_ROUTES: RouteObject[] = [
  {
    path: SEASON_CHILDREN[TabletPage.FootballSeasonHome],
    element: <Navigate to={SEASON_CHILDREN[TabletPage.FootballSeasonSummary]} replace />,
  },
  { path: SEASON_CHILDREN[TabletPage.FootballSeasonSummary], element: <Football.SeasonSummary /> },
  { path: SEASON_CHILDREN[TabletPage.FootballSeasonGames], element: <Football.SeasonGames /> },
  { path: SEASON_CHILDREN[TabletPage.FootballSeasonHistory], element: <Football.SeasonHistory /> },
  {
    path: SEASON_CHILDREN[TabletPage.FootballSeasonStadiums],
    element: <Football.SeasonStadiums />,
  },
  {
    path: SEASON_CHILDREN[TabletPage.FootballSeasonTeamStatistics],
    element: <Football.SeasonTeamStatistics />,
  },
  {
    path: SEASON_CHILDREN[TabletPage.FootballSeasonPlayerStatistics],
    element: <Football.SeasonPlayerStatistics />,
  },
  {
    path: SEASON_CHILDREN[TabletPage.FootballSeasonStandings],
    element: <Football.SeasonStandings />,
  },
  { path: SEASON_CHILDREN[TabletPage.FootballSeasonComingSoon], element: <Common.ComingSoon /> },
];

const FOOTBALL_TEAM_ROUTES: RouteObject[] = [
  {
    path: TEAM_CHILDREN[TabletPage.FootballTeamHome],
    element: <Navigate to={TEAM_CHILDREN[TabletPage.FootballTeamSummary]} replace />,
  },
  { path: TEAM_CHILDREN[TabletPage.FootballTeamSummary], element: <Football.TeamSummary /> },
  {
    path: TEAM_CHILDREN[TabletPage.FootballTeamSummaryDescription],
    element: <Football.TeamSummary />,
  },
  { path: TEAM_CHILDREN[TabletPage.FootballTeamStandings], element: <Football.TeamStandings /> },
  { path: TEAM_CHILDREN[TabletPage.FootballTeamGames], element: <Football.TeamGames /> },
  { path: TEAM_CHILDREN[TabletPage.FootballTeamSquad], element: <Football.TeamSquad /> },
  {
    path: TEAM_CHILDREN[TabletPage.FootballTeamPlayerStatistics],
    element: <Football.TeamPlayers />,
  },
];

const FOOTBALL_STADIUM_ROUTES: RouteObject[] = [
  {
    path: STADIUM_CHILDREN[TabletPage.FootballStadiumHome],
    element: <Football.StadiumHome />,
  },
  {
    path: STADIUM_CHILDREN[TabletPage.FootballStadiumSummary],
    element: <Football.StadiumSummary />,
  },
];

const FOOTBALL_PLAYER_ROUTES: RouteObject[] = [
  {
    path: PLAYER_CHILDREN[TabletPage.FootballPlayerHome],
    element: <Navigate to={PLAYER_CHILDREN[TabletPage.FootballPlayerSummary]} replace />,
  },
  { path: PLAYER_CHILDREN[TabletPage.FootballPlayerSummary], element: <Football.PlayerSummary /> },
  { path: PLAYER_CHILDREN[TabletPage.FootballPlayerGames], element: <Football.PlayerGames /> },
];

const ROOT_ROUTES: RouteObject[] = [
  {
    path: ROOT[TabletPage.Home],
    children: [
      {
        path: ROOT[TabletPage.Home],
        element: <Navigate to={ROOT[TabletPage.Football]} replace />,
      },
      { path: ROOT[TabletPage.Football], element: <Football.Home /> },
      { path: ROOT[TabletPage.FootballFavorites], element: <Football.Favorites /> },
      { path: ROOT[TabletPage.FootballCategories], element: <Football.Categories /> },
      { path: ROOT[TabletPage.FootballCategory], element: <Football.Category /> },
      {
        path: ROOT[TabletPage.FootballTeam],
        element: <Football.Team />,
        children: FOOTBALL_TEAM_ROUTES,
      },
      {
        path: ROOT[TabletPage.FootballPlayer],
        element: <Football.Player />,
        children: FOOTBALL_PLAYER_ROUTES,
      },
      {
        path: ROOT[TabletPage.FootballSeason],
        element: <Football.Season />,
        children: FOOTBALL_SEASON_ROUTES,
      },
      {
        path: ROOT[TabletPage.FootballStadium],
        element: <Football.Stadium />,
        children: FOOTBALL_STADIUM_ROUTES,
      },
      {
        path: ROOT[TabletPage.FootballMatch],
        element: <Football.Match />,
      },
    ],
  },
  { path: ROOT[TabletPage.Settings], element: <Football.Settings /> },
  { path: ROOT[TabletPage.NotFound], element: <Common.NotFound /> },
];

const Router = () => {
  return useRoutes(ROOT_ROUTES);
};

export default Router;
