import React from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Navigation = () => {
  return (
    <div className={styles.container}>
      <Skeleton height={12} width={71} variant="rectangular" />
      <Skeleton height={12} width={58} variant="rectangular" />
      <Skeleton height={12} width={33} variant="rectangular" />
      <Skeleton height={12} width={67} variant="rectangular" />
    </div>
  );
};

export default Navigation;
