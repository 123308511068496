import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';

import { Interfaces } from '../../types';
import getPath from './getPath';

export const getData = (state): Interfaces.Tournaments.Data => {
  return getPath(state).data;
};

export const getTournaments = (state): SeasonsExtendedTournament[] => {
  return getData(state).tournaments;
};

export const getSelectedTournamentSeasonId = (
  state,
): Interfaces.Tournaments.SelectedTournamentSeason => {
  const { selectedTournamentId, selectedSeasonId } = getData(state);

  return { selectedTournamentId, selectedSeasonId };
};
