import React, { memo } from 'react';
import classnames from 'classnames';

import { BaseHappening, EventType } from 'packages/event-stats/types';

import { getGoalHappening } from './services';
import { Card, Goal } from './views';
import styles from './styles.scss';

type Props = {
  happening: BaseHappening;
  isAway: boolean;
};

const HappeningLine = ({ happening, isAway }: Props) => {
  const { eventType } = happening;

  return (
    <div className={classnames(styles.happeningContainer, { [styles.away]: isAway })}>
      {(() => {
        switch (eventType) {
          case EventType.PenaltyMissed:
          case EventType.Goal:
            return <Goal isAway={isAway} happening={getGoalHappening(happening)} />;
          case EventType.YellowCard:
            return <Card isAway={isAway} happening={happening} />;
          case EventType.RedCard:
            return <Card isAway={isAway} happening={happening} />;
          case EventType.YellowRedCard:
            return <Card isAway={isAway} happening={happening} />;
        }
      })()}
    </div>
  );
};

export default memo(HappeningLine);
