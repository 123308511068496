import { OrderType } from 'types';

export interface FiltersParams {
  seasonId: number;
}

export interface SeasonTeamStatisticsParams {
  seasonId: number;
  limit?: number;
  offset?: number;
  countries?: (string | number)[];
  ordering?: Ordering;
  parameterRanges?: ParameterRanges;
}

export type Ordering = Partial<Record<OrderingKey, OrderType>>;
export type ParameterRanges = Partial<Record<RangeParameter, string>>;

export enum OrderingKey {
  TeamName = 'team__name',
  GamesPlayed = 'statistics__matches_played__total',

  GoalAttempts = 'statistics__shots_total__total',
  ShotsOnGoal = 'statistics__shots_on_target__total',
  ShotsOffGoal = 'statistics__shots_off_target__total',
  ShotsBlocked = 'statistics__shots_blocked__total',
  ShotsOnPost = 'statistics__shots_on_post__total',
  ShotsOnBar = 'statistics__shots_on_bar__total',
  ShootingEfficiency = 'statistics__shooting_efficiency__total',
  BallPossession = 'statistics__average_ball_possession__total',
  CornerKicks = 'statistics__corner_kicks__total',
  FreeKicks = 'statistics__free_kicks__total',
  Offside = 'statistics__offsides__total',
  YellowCards = 'statistics__yellow_cards__total',
  YellowRedCards = 'statistics__yellow_red_cards__total',
  RedCards = 'statistics__red_cards__total',
  GoalConceded = 'statistics__goals_conceded__total',
  GoalsScored = 'statistics__goals_scored__total',
  GoalsByHead = 'statistics__goals_by_head__total',
  GoalsByFoot = 'statistics__goals_by_foot__total',

  AverageGoalAttempts = 'statistics__shots_total__average',
  AverageShotsOnGoal = 'statistics__shots_on_target__average',
  AverageShotsOffGoal = 'statistics__shots_off_target__average',
  AverageShotsBlocked = 'statistics__shots_blocked__average',
  AverageShotsOnPost = 'statistics__shots_on_post__average',
  AverageShotsOnBar = 'statistics__shots_on_bar__average',
  AverageShootingEfficiency = 'statistics__shooting_efficiency__total',
  AverageBallPossession = 'statistics__average_ball_possession__total',
  AverageCornerKicks = 'statistics__corner_kicks__average',
  AverageFreeKicks = 'statistics__free_kicks__average',
  AverageOffside = 'statistics__offsides__average',
  AverageYellowCards = 'statistics__yellow_cards__average',
  AverageYellowRedCards = 'statistics__yellow_red_cards__average',
  AverageRedCards = 'statistics__red_cards__average',
  AverageGoalConceded = 'statistics__goals_conceded__average',
  AverageGoalsScored = 'statistics__goals_scored__average',
  AverageGoalsByHead = 'statistics__goals_by_head__average',
  AverageGoalsByFoot = 'statistics__goals_by_foot__average',
}

export enum RangeParameter {
  MinGamesPlayed = 'min_games_played',
  MaxGamesPlayed = 'max_games_played',

  MinGoalAttempts = 'min_shots_total',
  MaxGoalAttempts = 'max_shots_total',
  MinShotsOnGoal = 'min_shots_on_target',
  MaxShotsOnGoal = 'max_shots_on_target',
  MinShotsOffGoal = 'min_shots_off_target',
  MaxShotsOffGoal = 'max_shots_off_target',
  MinShotsBlocked = 'min_shots_blocked',
  MaxShotsBlocked = 'max_shots_blocked',
  MinShotsOnPost = 'min_shots_on_post',
  MaxShotsOnPost = 'max_shots_on_post',
  MinShotsOnBar = 'min_shots_on_bar',
  MaxShotsOnBar = 'max_shots_on_bar',
  MinCornerKicks = 'min_corner_kicks',
  MaxCornerKicks = 'max_corner_kicks',
  MinFreeKicks = 'min_free_kicks',
  MaxFreeKicks = 'max_free_kicks',
  MinOffside = 'min_offsides',
  MaxOffside = 'max_offsides',
  MinYellowCards = 'min_yellow_cards',
  MaxYellowCards = 'max_yellow_cards',
  MinYellowRedCards = 'min_yellow_red_cards',
  MaxYellowRedCards = 'max_yellow_red_cards',
  MinRedCards = 'min_red_cards',
  MaxRedCards = 'max_red_cards',
  MinGoalsConceded = 'min_goals_conceded',
  MaxGoalsConceded = 'max_goals_conceded',
  MinGoalsScored = 'min_goals_scored',
  MaxGoalsScored = 'max_goals_scored',
  MinGoalsByHead = 'min_goals_by_head',
  MaxGoalsByHead = 'max_goals_by_head',
  MinGoalsByFoot = 'min_goals_by_foot',
  MaxGoalsByFoot = 'max_goals_by_foot',

  MinAverageGoalAttempts = 'min_average_shots_total',
  MaxAverageGoalAttempts = 'max_average_shots_total',
  MinAverageShotsOnGoal = 'min_average_shots_on_target',
  MaxAverageShotsOnGoal = 'max_average_shots_on_target',
  MinAverageShotsOffGoal = 'min_average_shots_off_target',
  MaxAverageShotsOffGoal = 'max_average_shots_off_target',
  MinAverageShotsBlocked = 'min_average_shots_blocked',
  MaxAverageShotsBlocked = 'max_average_shots_blocked',
  MinAverageShotsOnPost = 'min_average_shots_on_post',
  MaxAverageShotsOnPost = 'max_average_shots_on_post',
  MinAverageShotsOnBar = 'min_average_shots_on_bar',
  MaxAverageShotsOnBar = 'max_average_shots_on_bar',
  MinAverageShootingEfficiency = 'min_average_shooting_efficiency',
  MaxAverageShootingEfficiency = 'max_average_shooting_efficiency',
  MinAverageBallPossession = 'min_ball_possession',
  MaxAverageBallPossession = 'max_ball_possession',
  MinAverageCornerKicks = 'min_average_corner_kicks',
  MaxAverageCornerKicks = 'max_average_corner_kicks',
  MinAverageFreeKicks = 'min_average_free_kicks',
  MaxAverageFreeKicks = 'max_average_free_kicks',
  MinAverageOffside = 'min_average_offsides',
  MaxAverageOffside = 'max_average_offsides',
  MinAverageYellowCards = 'min_average_yellow_cards',
  MaxAverageYellowCards = 'max_average_yellow_cards',
  MinAverageYellowRedCards = 'min_average_yellow_red_cards',
  MaxAverageYellowRedCards = 'max_average_yellow_red_cards',
  MinAverageRedCards = 'min_average_red_cards',
  MaxAverageRedCards = 'max_average_red_cards',
  MinAverageGoalsConceded = 'min_average_goals_conceded',
  MaxAverageGoalsConceded = 'max_average_goals_conceded',
  MinAverageGoalsScored = 'min_average_goals_scored',
  MaxAverageGoalsScored = 'max_average_goals_scored',
  MinAverageGoalsByHead = 'min_average_goals_by_head',
  MaxAverageGoalsByHead = 'max_average_goals_by_head',
  MinAverageGoalsByFoot = 'min_average_goals_by_foot',
  MaxAverageGoalsByFoot = 'max_average_goals_by_foot',
}
