import instance from 'packages/rest/axios';

import { ActualGamesParams } from '../types';

export const loadActualGames = ({ teamId, finishedCount, scheduledCount }: ActualGamesParams) => {
  return instance({
    method: 'get',
    url: `/teams/${teamId}/actual_sport_events/`,
    params: {
      scheduled_count: scheduledCount,
      is_finished_count: finishedCount,
    },
  });
};
