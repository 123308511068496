import instance from 'packages/rest/axios';

import { TournamentListParams } from '../types';

export const loadTournamentList = ({ teamId }: TournamentListParams) => {
  return instance({
    method: 'get',
    url: `/teams/${teamId}/unique_tournaments/`,
  });
};
