import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { headToHeadActions } from '../../actions';
import { Status } from '../../types';

const defaultState: Status = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${headToHeadActions.loadHeadToHeadStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(
    headToHeadActions.loadHeadToHeadFailure,
    headToHeadActions.loadHeadToHeadSuccess,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${headToHeadActions.cleanHeadToHeadSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<Status, void>(reducerMap, defaultState);
