import { historyActions } from '../../actions';
import loadHistory from './loadHistory';

export const loadHistoryPage = (tournamentId: number) => async (dispatch) => {
  try {
    dispatch(historyActions.loadHistoryStart());

    const history = await dispatch(loadHistory(tournamentId));

    dispatch(historyActions.loadHistorySuccess(history));
  } catch (e) {
    dispatch(historyActions.loadHistoryFailure(e));
  }
};
