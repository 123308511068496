import { PlayerEvents } from 'packages/rest';

import { teamsFiltersActions } from '../../actions';
import { mapPlayerTeams } from '../../services';

const loadTeamsFilter = (playerId: number) => async (dispatch) => {
  try {
    dispatch(teamsFiltersActions.loadTeamsFiltersStart());

    const { data } = await PlayerEvents.loadPlayerTeams({ playerId });

    const teams = mapPlayerTeams(data.results);

    dispatch(teamsFiltersActions.loadTeamsFiltersSuccess({ teams }));
  } catch (error) {
    dispatch(teamsFiltersActions.loadTeamsFiltersFailure(error));
  }
};

export default loadTeamsFilter;
