import { Tournaments } from 'packages/rest';
import { settingsSelectors } from 'packages/settings';

import { mapSearchResults } from '../services';
import * as selectors from '../selectors';
import * as actions from '../actions';

const loadMoreResults = ({ scrollTop }) => async (dispatch, getState) => {
  dispatch(actions.loadMoreResultsStart());

  try {
    const state = getState();
    const activeSportId = settingsSelectors.getActiveSportId(state);
    const offset = selectors.getNextOffset(state);
    const previousResults = selectors.getResults(state);
    const searchKey = selectors.getSearchKey(state);

    const { data } = await Tournaments.loadTournaments({
      activeSportId,
      search: searchKey,
      offset,
    });

    const { results, next } = data;
    const mappedResults = mapSearchResults(results);
    const updatedResults = [...previousResults, ...mappedResults];

    const hasNextResults = !!next;
    const nextOffset = updatedResults.length;

    dispatch(
      actions.loadMoreResultsSuccess({
        results: updatedResults,
        hasNextResults,
        nextOffset,
        scrollTop,
      }),
    );
  } catch (e) {
    dispatch(actions.loadMoreResultsSuccess(e));
  }
};

export default loadMoreResults;
