import * as actions from '../../actions';
import { seasonFilterSelectors } from '../../selectors';
import { Filter, FilterValue } from '../../types';

export const updateActiveFilter = (value: FilterValue, type: Filter) => async (
  dispatch,
  getState,
) => {
  const activeFilter = seasonFilterSelectors.getActiveFilters(getState());
  const updatedFilter = { ...activeFilter, [type]: value };

  dispatch(actions.updateActiveFilter(updatedFilter));
};
