import React from 'react';
import { SortableElement } from 'react-sortable-hoc';

import { Button } from 'packages/shared/components';
import { ButtonType } from 'packages/shared/components/Button/types';
import { ColumnType } from 'packages/season-team-statistics/types';

type Props = {
  type: ColumnType;
  className?: string;
};

const DraggableButton = SortableElement(({ type, className }: Props) => (
  <Button className={className} type={ButtonType.Default} key={type}>
    {`season.teamStatistics.customFilter.columnConfiguration.columnName.${type}`}
  </Button>
));

export default DraggableButton;
