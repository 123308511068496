import getPath from '../getPath';
import { TopVenuesStatus } from '../../types';

const getStatus = (state): TopVenuesStatus => {
  return getPath(state).venues.status;
};

export const getIsTopVenuesLoading = (state): boolean => {
  return getStatus(state).isTopVenuesLoading;
};
