import React, { memo, RefObject, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { Event } from 'packages/events-ui';
import { Interfaces as PlayerSummaryInterfaces, Interfaces } from 'packages/player-summary/types';
import { MobilePage } from 'router/types';
import { useNavigation } from 'packages/hooks';
import { MediaScreen, Team } from 'types';

import { ActualGames, TransferHistory, Statistics } from '../../components';
import { getColumnSchema, getSkeletonSchema } from './services';

const HEADER_TOP_OFFSET = 132;

type Props = {
  teams: Team[];
  tournaments: Interfaces.ActualGames.Data;
  parentContainerRef?: RefObject<HTMLDivElement>;
  history: PlayerSummaryInterfaces.Teams.TransferHistory[];
  playerSummaryStatistics: PlayerSummaryInterfaces.Statistics.Statistics.Data;
  isActualGamesLoading: boolean;
  isWholeStatisticsLoading: boolean;
  isTeamsLoading: boolean;
};

const Summary = ({
  parentContainerRef,
  tournaments,
  history,
  playerSummaryStatistics,
  isWholeStatisticsLoading,
  isActualGamesLoading,
  teams,
  isTeamsLoading,
}: Props) => {
  const { playerId } = useParams();
  const { appNavigate } = useNavigation(MediaScreen.Mobile);
  const isAllDataLoaded = !isWholeStatisticsLoading && !isActualGamesLoading && !isTeamsLoading;

  const handleLinkClick = useCallback(() => {
    if (playerId) {
      appNavigate(MobilePage.FootballPlayerGames, { playerId });
    }
  }, [playerId]);

  const handleTeamClick = useCallback((team: Team) => {
    appNavigate(MobilePage.FootballTeam, { teamId: team.id });
  }, []);

  return (
    <>
      <ActualGames
        isCompact
        useModalOnCategoryClick
        onLinkClick={handleLinkClick}
        tournaments={tournaments}
        eventComponent={Event.Mobile}
        isLoading={isActualGamesLoading}
      />

      <Statistics
        isTeamsLoading={isTeamsLoading}
        isColumnDirectionActive
        teams={teams}
        playerSummaryStatistics={playerSummaryStatistics}
        isWholeStatisticsLoading={isWholeStatisticsLoading}
        parentContainerRef={parentContainerRef}
      />

      <TransferHistory
        headerTopOffset={HEADER_TOP_OFFSET}
        isAllDataLoaded={isAllDataLoaded}
        isLoading={isTeamsLoading}
        handleTeamClick={handleTeamClick}
        history={history}
        isScrollable
        getSkeletonColumnSchema={getSkeletonSchema}
        getColumnSchema={getColumnSchema}
      />
    </>
  );
};

export default memo(Summary);
