import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { eventsActions, commonActions } from '../../actions';
import { Data } from '../../types';

const defaultState: Data = [];

const reducerMap: ReducerMap<Data, Data> = {
  [`${combineActions(
    eventsActions.loadInitialEventsSuccess,
    eventsActions.loadMoreEventsSuccess,
  )}`]: (state, { payload }) => {
    return payload;
  },

  [`${combineActions(
    eventsActions.loadInitialEventsFailure,
    eventsActions.loadMoreEventsFailure,
    commonActions.clearSuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
