import { TabletPage } from 'router/types';
import { ROOT } from 'router/constants/tablet';
import { IconType } from 'packages/shared/components/Icon/types';

export const LINK_LIST = [
  {
    id: 1,
    end: true,
    to: ROOT[TabletPage.Football],
    iconId: IconType.NavigationEvents,
    label: 'footer.navigation.link.events',
  },
  {
    id: 2,
    to: ROOT[TabletPage.FootballCategories],
    iconId: IconType.NavigationLeagues,
    label: 'footer.navigation.link.leagues',
  },
  {
    id: 3,
    to: ROOT[TabletPage.FootballFavorites],
    iconId: IconType.NavigationFavorites,
    label: 'footer.navigation.link.favorites',
  },
];
