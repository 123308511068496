import React, { memo } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed/lib';
import i18n from 'i18n-smart';

import { Icon, ExpandableBlock } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import { LoadingSkeleton } from './components';
import { FunFact } from './types';
import styles from './styles.scss';

type Props = {
  facts: FunFact[];
  isLoading?: boolean;
  theme?: 'light' | 'dark';
  className?: string;
};

const FunFacts = ({ facts, isLoading, theme = 'dark', className }: Props) => {
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  if (isEmpty(facts)) {
    return <></>;
  }

  return (
    <div className={classnames(styles.factsContainer, styles[theme], className)}>
      <ExpandableBlock theme="secondary" className={styles.expandableBlock}>
        <div className={styles.factsWrapper}>
          <Icon className={styles.icon} id={IconType.Facts} />

          <div className={styles.facts}>
            <div className={styles.header}>{i18n.value('eventSummary.funFacts.header')}</div>
            <ul className={styles.list}>
              {facts.map(({ sentence }, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <li className={styles.item} key={index}>
                  {sentence}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </ExpandableBlock>
    </div>
  );
};

export default memo(FunFacts);
