import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { EventDetails } from 'packages/event-details-ui';
import { Advertisement } from 'packages/shared/components';
import { TeamDescription, TeamSummary as TeamSummaryBlock } from 'packages/team-summary-ui';
import { SearchQuery } from 'router/types';

import styles from './styles.scss';

const TeamSummary = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  return (
    <Grid.DoubleColumn
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
      isSecondaryOpen={false}
    >
      <div key={GridColumnId.LeftBar} className={styles.leftBar}>
        <TeamSummaryBlock.Desktop />
      </div>
      <div key={GridColumnId.Primary} className={styles.rightBar}>
        {eventId ? (
          <EventDetails.Desktop eventId={eventId} className={styles.eventCard} />
        ) : (
          <>
            <Advertisement className={styles.advertisement} />
            <div className={styles.teamDescription}>
              <TeamDescription.Desktop />
            </div>
          </>
        )}
      </div>
    </Grid.DoubleColumn>
  );
};

export default memo(TeamSummary);
