import * as actions from '../actions';

const clearResults = () => async (dispatch) => {
  dispatch(actions.clearResultsStart());

  try {
    dispatch(actions.clearResultsSuccess());
  } catch (e) {
    dispatch(actions.clearResultsFailure(e));
  }
};

export default clearResults;
