import React, { memo } from 'react';
import classnames from 'classnames';

import { Chip, Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from '../styles.scss';
import EventTime from '../EventTime';

export type Props = {
  minute: string;
  injuryTime?: string;
};

const Shot = ({ minute, injuryTime }: Props) => (
  <Chip className={classnames(styles.chip, styles.extended)}>
    <EventTime minute={minute} injuryTime={injuryTime} />
    <Icon id={IconType.Shot} />
  </Chip>
);

export default memo(Shot);
