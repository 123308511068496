import { Score } from 'types/interfaces';
import { DisplayingScorePeriod } from 'packages/events/types';
import { ScoreNumber } from 'types';

export const getPeriodScoreExtractor = (scores: Score[], isFirstTeamAtHome: boolean) => (
  period: DisplayingScorePeriod,
): string | null => {
  let foundPeriod: Score | null = null;

  if (period === DisplayingScorePeriod.FIRST_PERIOD) {
    foundPeriod = getFirstPeriodScore(scores) || null;
  }
  if (period === DisplayingScorePeriod.PENALTY) {
    foundPeriod = getPenaltiesPeriodScore(scores) || null;
  }

  if (!foundPeriod) {
    return null;
  }

  const { homeScore, awayScore } = foundPeriod;

  return (isFirstTeamAtHome ? [homeScore, awayScore] : [awayScore, homeScore]).join('-');
};

const getFirstPeriodScore = (scores: Score[]) => {
  return scores.find(
    ({ type, number }) =>
      type === DisplayingScorePeriod.FIRST_PERIOD && number === ScoreNumber.FirstPeriod,
  );
};

const getPenaltiesPeriodScore = (scores: Score[]) => {
  return scores.find(({ type }) => type === DisplayingScorePeriod.PENALTY);
};
