import React from 'react';
import { useSearchParams } from 'react-router-dom';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { PlayerEvents } from 'packages/player-events-ui';
import { EventDetails } from 'packages/event-details-ui';
import { SearchQuery } from 'router/types';

import styles from './styles.scss';

const PlayerGames = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  return (
    <Grid.SingleColumn isSecondaryOpen={!!eventId}>
      <div key={GridColumnId.Primary} className={styles.primary}>
        <PlayerEvents.Mobile />
      </div>

      <div className={styles.popup} key={GridColumnId.Secondary}>
        <EventDetails.Mobile eventId={eventId} />
      </div>
    </Grid.SingleColumn>
  );
};

export default PlayerGames;
