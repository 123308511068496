import { seasonEventsSelectors, seasonFilterSelectors } from '../../selectors';
import {
  loadInitialEventsStart,
  loadInitialEventsSuccess,
  loadInitialEventsFailure,
} from '../../actions';
import { loadEvents } from './loadEvents';

interface Params {
  seasonId: number;
  limit?: number;
}

export const loadActualEvents = ({ seasonId, limit }: Params) => async (dispatch, getState) => {
  try {
    dispatch(loadInitialEventsStart());

    const activeFilters = seasonFilterSelectors.getActiveFilters(getState());
    const actualLimit = limit || seasonEventsSelectors.getLimit(getState());
    const offset = seasonEventsSelectors.getOffset(getState());

    const eventsData = await dispatch(
      loadEvents({
        seasonId,
        limit: actualLimit,
        activeFilters,
        offset,
      }),
    );

    dispatch(loadInitialEventsSuccess(eventsData));
  } catch (error) {
    dispatch(loadInitialEventsFailure(error));
  }
};
