import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  isCompact?: boolean;
};

const DetailsSelectorSkeleton = ({ isCompact = false }: Props) => {
  if (isCompact) {
    return (
      <div className={styles.compactWrapper}>
        <Skeleton marginBottom={8} height={24} width={157} variant="rectangular" />

        <div className={styles.content}>
          <Skeleton maxWidth={205} width="100%" marginRight={16} variant="rectangular" />
          <Skeleton width={90} minWidth={90} variant="rectangular" />
        </div>
      </div>
    );
  }

  return (
    <div className={styles.wrapper}>
      <Skeleton height={40} marginRight={12} maxWidth={268} width="100%" variant="rectangular" />
      <Skeleton height={32} marginRight={24} width="100%" maxWidth={304} variant="rectangular" />
      <Skeleton height={40} marginRight={12} minWidth={120} width={120} variant="rectangular" />
      <Skeleton height={32} minWidth={79} width={79} variant="rectangular" />
    </div>
  );
};

export default memo(DetailsSelectorSkeleton);
