import { createElement } from 'react';
import { Column } from 'react-table';

import { Player } from 'packages/team-squad/types';
import { Ordering, SquadOrdering } from 'packages/rest/api/TeamSquad/types';
import { StickyColumnDirection } from 'packages/shared/components/FlexibleTable/types';

import {
  SortableHeader,
  PlayerLogo,
  PlayerName,
  CountryLogoCell,
  AgeCell,
  InTeamCell,
  TextCell,
} from '../components';

type Parameters = {
  teamId: string;
  onHeaderCellClick: (orderingType: Ordering) => void;
  ordering: SquadOrdering;
};

const getTableSchema = ({ teamId, onHeaderCellClick, ordering }: Parameters): Column<Player>[] => {
  return [
    {
      id: 'number',
      Header: () =>
        createElement(SortableHeader, {
          label: 'team.teamSquad.column.number',
          orderingValue: Ordering.Number,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px 8px 8px 16px', textAlign: 'center' },
      cellStyle: {
        padding: '8px 8px 8px 16px',
        textAlign: 'center',
      },
      accessor: 'number',
      sticky: StickyColumnDirection.Left,
      Cell: ({ value }) => createElement(TextCell, { isColorGray: true, value }),
      width: 64,
    },
    {
      id: 'playerLogo',
      Header: () =>
        createElement(SortableHeader, {
          label: 'team.teamSquad.column.player',
          orderingValue: Ordering.Name,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'left' },
      cellStyle: {
        padding: '8px',
        textAlign: 'left',
      },
      accessor: 'logo',
      sticky: StickyColumnDirection.Left,
      Cell: ({ value: logo }) => createElement(PlayerLogo, { logo }),
      width: 48,
    },
    {
      id: 'playerName',
      Header: '',
      cellStyle: {
        textAlign: 'left',
      },
      accessor: 'name',
      Cell: ({ value: name }) => createElement(PlayerName, { name }),
      minWidth: 200,
    },
    {
      id: 'position',
      Header: () =>
        createElement(SortableHeader, {
          label: 'team.teamSquad.column.position',
          orderingValue: Ordering.PositionName,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'left' },
      cellStyle: {
        padding: '8px',
        textAlign: 'left',
      },
      accessor: 'positionName',
      Cell: ({ value }) => createElement(TextCell, { value }),
      width: 80,
    },
    {
      id: 'nation',
      Header: () =>
        createElement(SortableHeader, {
          label: 'team.teamSquad.column.nation',
          orderingValue: Ordering.Country,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'center' },
      cellStyle: {
        padding: '8px',
        textAlign: 'center',
      },
      accessor: 'country',
      Cell: ({ value: country }) => createElement(CountryLogoCell, { country }),
      width: 50,
    },
    {
      id: 'age',
      Header: () =>
        createElement(SortableHeader, {
          label: 'team.teamSquad.column.age',
          orderingValue: Ordering.Age,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'right' },
      cellStyle: {
        padding: '8px',
        textAlign: 'right',
      },
      accessor: 'dateOfBirth',
      Cell: ({ value: dateOfBirth }) => createElement(AgeCell, { dateOfBirth }),
      width: 38,
    },
    {
      id: 'height',
      Header: () =>
        createElement(SortableHeader, {
          label: 'team.teamSquad.column.height',
          orderingValue: Ordering.Height,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'right' },
      cellStyle: {
        padding: '8px',
        textAlign: 'right',
      },
      accessor: 'height',
      Cell: ({ value }) => createElement(TextCell, { value }),
      width: 75,
    },
    {
      id: 'weight',
      Header: () =>
        createElement(SortableHeader, {
          label: 'team.teamSquad.column.weight',
          orderingValue: Ordering.Weight,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'right' },
      cellStyle: {
        padding: '8px',
        textAlign: 'right',
      },
      accessor: 'weight',
      Cell: ({ value }) => createElement(TextCell, { value }),
      width: 70,
    },
    {
      id: 'foot',
      Header: () =>
        createElement(SortableHeader, {
          label: 'team.teamSquad.column.foot',
          orderingValue: Ordering.PreferredFoot,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px', textAlign: 'left' },
      cellStyle: {
        padding: '8px',
        textAlign: 'left',
      },
      accessor: 'preferredFoot',
      Cell: ({ value }) => createElement(TextCell, { value }),
      width: 60,
    },
    {
      id: 'startDate',
      Header: () =>
        createElement(SortableHeader, {
          label: 'team.teamSquad.column.inTeamSince',
          orderingValue: Ordering.StartDate,
          onHeaderCellClick,
          ordering,
        }),
      headerStyle: { padding: '8px 16px 8px 8px', textAlign: 'left' },
      cellStyle: {
        padding: '8px 16px 8px 8px',
        textAlign: 'left',
      },
      accessor: ({ id, startDate }) => ({ id, startDate }),
      Cell: ({ value }) =>
        createElement(InTeamCell, {
          startDate: value.startDate,
          playerId: value.id,
          teamId,
        }),
      width: 115,
    },
  ];
};

export default getTableSchema;
