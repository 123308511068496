import React, { createElement } from 'react';
import { Column } from 'react-table';

import {
  Column as TeamStatisticsColumn,
  ColumnType,
  PlayerStatistics,
} from 'packages/season-player-statistics/types';
import { StickyColumnDirection } from 'packages/shared/components/FlexibleTable/types';

import { ColumnTitle, TeamCell, PlayerCell, PlayerRank, PlayerIcon } from '../../../components';
import { TableSchemaProps } from '../../../types';
import styles from '../styles.scss';

const getTableSchema = (
  columns: TeamStatisticsColumn[],
  additionalProps: TableSchemaProps,
): Column<PlayerStatistics>[] => {
  return columns.map((column) => {
    const { headerProps, columnProps } = additionalProps;
    const { ordering, onOrderingChange } = headerProps;
    const { onTeamClick } = columnProps;
    const { type, columnKey } = column;
    const defaultColumnConfiguration = {
      id: columnKey,
      accessor: ({ statistics }) => statistics[columnKey],
      Header: () =>
        createElement(ColumnTitle, {
          type,
          sortingType: ordering[type],
          onOrderingChange,
        }),
      minWidth: 60,
      width: 60,
    };

    switch (type) {
      case ColumnType.PlayerRank:
        return {
          id: ColumnType.PlayerRank,
          accessor: ({ player }) => player,
          Cell: ({ value: player }) => <PlayerRank player={player} />,
          sticky: StickyColumnDirection.Left,
          width: 40,
        };
      case ColumnType.PlayerIcon:
        return {
          id: ColumnType.PlayerIcon,
          accessor: ({ player }) => player,
          Cell: ({ value: player }) => <PlayerIcon player={player} />,
          sticky: StickyColumnDirection.Left,
          width: 40,
        };
      case ColumnType.Player:
        return {
          id: columnKey,
          accessor: ({ player }) => player,
          Cell: ({ value: player }) => (
            <PlayerCell logoClassName={styles.countryLogo} player={player} />
          ),
          maxWidth: 140,
        };
      case ColumnType.Club:
        return {
          ...defaultColumnConfiguration,
          accessor: ({ team }) => team,
          stickyFor: ColumnType.PlayerIcon,
          Cell: ({ value: team }) => createElement(TeamCell, { team, onTeamClick }),
        };
      default:
        return defaultColumnConfiguration;
    }
  });
};

export default getTableSchema;
