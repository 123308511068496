import React, { useEffect, useMemo } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams, useSearchParams } from 'react-router-dom';

import { LoaderContainer, Table } from 'packages/shared/components';
import { HeadToHead } from 'packages/team-standings/types';
import { SearchQuery } from 'router/types';

import { getTableConfiguration } from './services';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  headToHead: HeadToHead.Data;
  seasonHeadToHeadActions: ActionCreatorsMapObject;
  activeTeamIds?: number[];
  activeUniqueTournamentId?: string | number;
  activeSeasonId?: string | number;
  isLoading: boolean;
};

const HeadToHeadTable = ({
  headToHead,
  isLoading,
  seasonHeadToHeadActions,
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
}: Props) => {
  const { seasonId } = useParams();
  const [, setSearchParams] = useSearchParams();
  const tableConfiguration = useMemo(
    () =>
      getTableConfiguration(
        headToHead,
        activeTeamIds,
        {
          onCellClick: (eventId) => {
            setSearchParams({ [SearchQuery.EventCardId]: eventId.toString() });
          },
        },
        activeUniqueTournamentId,
        activeSeasonId,
      ),
    [headToHead],
  );

  useEffect(() => {
    seasonHeadToHeadActions.loadHeadToHead(seasonId);

    return () => {
      seasonHeadToHeadActions.cleanHeadToHead();
    };
  }, []);

  return (
    <LoaderContainer isLoading={isLoading} className={styles.table}>
      {!!tableConfiguration?.columns && !!tableConfiguration?.data && (
        <Table
          rowClassName={styles.row}
          className={styles.table}
          rowKey={(events, rowIndex) => events[rowIndex].key || events[rowIndex].id}
          scroll={{ x: '100%' }}
          columns={tableConfiguration?.columns}
          data={tableConfiguration?.data}
          tableLayout="fixed"
          sticky
        />
      )}
    </LoaderContainer>
  );
};

export default connect(HeadToHeadTable);
