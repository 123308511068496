import * as settingsActions from './actions';
import * as settingsSelectors from './selectors';
import * as settingsServices from './services';
import * as settingsThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export { settingsThunks, settingsSelectors, settingsActions, createReducer, settingsServices };
