import { FavoritesTableIndex } from 'configure/storage/types';

import { FAVORITES_UPDATE_HOOK_IGNORING_PROPERTIES } from '../constants';

const flushFavoritesModifications = (modifications: object, record: object) => {
  return Object.entries(modifications).reduce((acc, [key, value]) => {
    if (FAVORITES_UPDATE_HOOK_IGNORING_PROPERTIES.includes(key as FavoritesTableIndex)) {
      acc[key] = record[key];
    } else {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export default flushFavoritesModifications;
