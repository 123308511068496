import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed';

import DateService from 'packages/date-az';
import { CustomPeriod } from 'packages/shared/components/Calendar/types';
import { PlayerTeam, Tournament, Season } from 'packages/player-events/types';

type Params = {
  team?: PlayerTeam;
  tournament?: Tournament;
};

const getCustomPeriod = ({ team, tournament }: Params): CustomPeriod | undefined => {
  if (tournament) {
    return getTournamentPeriod(tournament);
  }

  if (team) {
    return getTeamPeriod(team);
  }

  return undefined;
};

const getTournamentPeriod = (tournament: Tournament): CustomPeriod => {
  const { seasons, name } = tournament;

  const [startDate, endDate] = getSeasonsDates(seasons);

  return {
    label: `${i18n.value(
      'player.events.filters.calendar.customPeriod.label',
    )} ${getTournamentShortName(name)}`,
    startDate,
    endDate,
  };
};

const getTeamPeriod = (team: PlayerTeam) => {
  const { logo, startDate, endDate } = team;

  return {
    label: i18n.value('player.events.filters.calendar.customPeriod.label'),
    logo,
    startDate,
    endDate,
  };
};

const getSeasonsDates = (seasons: Season[]): [string, string] => {
  if (!isEmpty(seasons)) {
    const firstSeason = seasons[seasons.length - 1];
    const [lastSeason] = seasons;

    return [firstSeason.startDate, lastSeason.endDate];
  } else {
    const today = DateService.getDate().toISODate();

    return [today, today];
  }
};

const getTournamentShortName = (name: string) => {
  const separatedBySpace = name.split(' ');

  if (separatedBySpace.length > 2) {
    return separatedBySpace.map((word) => word[0]).join('');
  }

  return name.substring(0, 3);
};

export default getCustomPeriod;
