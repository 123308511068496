import DateService from 'packages/date-az';
import { Season } from 'packages/player-events/types';

import sortSeasonsByDates from './sortSeasonsByDates';

const getSeasonsRange = (seasons: Season[]) => {
  const sortedSeasons = sortSeasonsByDates(seasons);

  return formatRanges(sortedSeasons);
};

const formatRanges = (seasons: Season[]): [string, string] => {
  const firstItem = seasons[0];
  const lastItem = seasons[seasons.length - 1];
  const today = DateService.getDate();

  if (DateService.parse(lastItem.endDate) < today) {
    return [firstItem.startDate, lastItem.endDate];
  }

  return [firstItem.startDate, today.toISODate()];
};

export default getSeasonsRange;
