import i18n from 'i18n-smart';

import { EventStats } from 'packages/event-stats-ui/';
import { EventSummary } from 'packages/event-summary-ui/';
import { EventStandings } from 'packages/event-standings-ui';
import { EventLineups } from 'packages/event-lineups-ui';
import { EventCommentary } from 'packages/event-commentary-ui';
import { EventHeadToHead } from 'packages/event-head-to-head-ui';
import { Tabs } from 'packages/event-details/types';

const getEventDetailsTabs = () => {
  return [
    {
      id: `eventDetails.navigation.${Tabs.Summary}`,
      contentComponent: EventSummary.Desktop,
      name: i18n.value(`eventDetails.navigation.${Tabs.Summary}`),
      type: Tabs.Summary,
    },
    {
      id: `eventDetails.navigation.${Tabs.Statistics}`,
      contentComponent: EventStats.Desktop,
      name: i18n.value(`eventDetails.navigation.${Tabs.Statistics}`),
      type: Tabs.Statistics,
    },
    {
      id: `eventDetails.navigation.${Tabs.LineUp}`,
      contentComponent: EventLineups.Desktop,
      name: i18n.value(`eventDetails.navigation.${Tabs.LineUp}`),
      type: Tabs.LineUp,
    },
    {
      id: `eventDetails.navigation.${Tabs.HeadToHead}`,
      contentComponent: EventHeadToHead.Desktop,
      name: i18n.value(`eventDetails.navigation.${Tabs.HeadToHead}`),
      type: Tabs.HeadToHead,
    },
    {
      id: `eventDetails.navigation.${Tabs.Standings}`,
      contentComponent: EventStandings.Desktop,
      name: i18n.value(`eventDetails.navigation.${Tabs.Standings}`),
      type: Tabs.Standings,
    },
    {
      id: `eventDetails.navigation.${Tabs.Comments}`,
      contentComponent: EventCommentary.Desktop,
      name: i18n.value(`eventDetails.navigation.${Tabs.Comments}`),
      type: Tabs.Comments,
    },
  ];
};

export default getEventDetailsTabs;
