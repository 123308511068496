exports = module.exports = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3oi2a31v{width:100%}._2PJD5HkH{width:90px}._1n5HumDZ{width:160px}._23Qbxtx_{width:205px}._1XmXUC2X{position:relative;width:auto;min-width:100%;max-height:480px;white-space:nowrap}._1kEqywoy{top:4px}._2nl1JWYz{bottom:4px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/Select/containers/styles.scss"],"names":[],"mappings":"AAGA,WAMI,UAAW,CANf,WAUI,UATY,CADhB,WAcI,WAZa,CAFjB,WAkBI,WAfa,CAHjB,WAsBI,iBAAkB,CAClB,UAAW,CACX,cAAe,CACf,gBAAiB,CACjB,kBAAmB,CA1BvB,WA8BI,OACF,CA/BF,WAkCI,UAAW","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n\n:local {\n  $sm-size: 90px;\n  $md-size: 160px;\n  $lg-size: 205px;\n\n  .full-width {\n    width: 100%;\n  }\n\n  .sm {\n    width: $sm-size;\n  }\n\n  .md {\n    width: $md-size;\n  }\n\n  .lg {\n    width: $lg-size;\n  }\n\n  .select-items {\n    position: relative;\n    width: auto;\n    min-width: 100%;\n    max-height: 480px;\n    white-space: nowrap;\n  }\n\n  .top-offset {\n    top: 4px\n  }\n\n  .bottom-offset {\n    bottom: 4px;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"full-width": "_3oi2a31v",
	"fullWidth": "_3oi2a31v",
	"sm": "_2PJD5HkH",
	"md": "_1n5HumDZ",
	"lg": "_23Qbxtx_",
	"select-items": "_1XmXUC2X",
	"selectItems": "_1XmXUC2X",
	"top-offset": "_1kEqywoy",
	"topOffset": "_1kEqywoy",
	"bottom-offset": "_2nl1JWYz",
	"bottomOffset": "_2nl1JWYz"
};