import React from 'react';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import { ButtonSize, ButtonViewProps } from '../../types';
import styles from './styles.scss';

const ExpandButton = ({
  isActive,
  isDisabled,
  className,
  size = ButtonSize.Sm,
  children,
}: ButtonViewProps) => (
  <div
    className={classnames(
      styles.expandButton,
      {
        [styles.disabled]: isDisabled,
        [styles.active]: isActive,
      },
      styles[size],
      className,
    )}
  >
    {children}
    <Icon id={isActive ? IconType.Cross : IconType.Arrow} className={styles.icon} />
  </div>
);

export default React.memo(ExpandButton);
