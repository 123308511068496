const errorsLogger = () => (next) => (action) => {
  const failure = getActionFailure(action);

  if (failure) {
    console.error(`${action.type}: `, failure);
  }
  next(action);
};

const getActionFailure = (action = {}) => {
  const { type, meta } = action;

  if (type.includes('_FAILURE') || type.includes('FAILURE')) {
    return action.payload || 'unknown';
  }

  return meta && meta.failure ? meta.failure : null;
};

export default errorsLogger;
