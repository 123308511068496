import i18n from 'i18n-smart';

import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';
import { Enums } from 'packages/team-player-statistics/types';

const getStatsTabs = (): NavigationMenuItem[] => [
  {
    id: Enums.StatType.All,
    name: i18n.value('team.playerStatistics.customFilter.StatType.all'),
  },
  {
    id: Enums.StatType.PerGame,
    name: i18n.value('team.playerStatistics.customFilter.StatType.perGame'),
  },
  {
    id: Enums.StatType.PerNinetyMinutes,
    name: i18n.value('team.playerStatistics.customFilter.StatType.perNinetyMinutes'),
  },
];

export default getStatsTabs;
