import * as teamActiveTournamentSeasonActions from './actions';
import * as teamActiveTournamentSeasonConstants from './constants';
import * as teamActiveTournamentSeasonThunks from './thunks';
import * as teamActiveTournamentSeasonSelectors from './selectors';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  teamActiveTournamentSeasonActions,
  teamActiveTournamentSeasonConstants,
  teamActiveTournamentSeasonThunks,
  teamActiveTournamentSeasonSelectors,
  createReducer,
};
