import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { detailsActions, commonActions } from '../../../../actions';
import { Interfaces } from '../../../../types';

const { standings } = detailsActions;
const defaultState = {
  stages: [],
};

const reducerMap: ReducerMap<
  Interfaces.Details.Standings.Data,
  Interfaces.Details.Standings.Data
> = {
  [`${standings.loadStandingsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(commonActions.cleanSummarySuccess, standings.loadStandingsStart)}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
