import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadInitialEventsStart = createAction(`${NAMESPACE}LOAD_INITIAL_EVENTS_START`);
export const loadInitialEventsSuccess = createAction(`${NAMESPACE}LOAD_INITIAL_EVENTS_SUCCESS`);
export const loadInitialEventsFailure = createAction(`${NAMESPACE}LOAD_INITIAL_EVENTS_FAILURE`);

export const loadMoreEventsStart = createAction(`${NAMESPACE}LOAD_MORE_EVENTS_START`);
export const loadMoreEventsSuccess = createAction(`${NAMESPACE}LOAD_MORE_EVENTS_SUCCESS`);
export const loadMoreEventsFailure = createAction(`${NAMESPACE}LOAD_MORE_EVENTS_FAILURE`);
