import mapGoalHappening from 'packages/event-time-line/services/mappers/mapGoalHappening';
import { toCamelCase } from 'packages/utils/Object.utils';
import { formatTimeLineItem } from 'packages/event-time-line/services';
import { PlayerHappeningType } from 'packages/events/types';

import { SHOT_EVENT_TYPES } from '../constants';
import { Data, CompareStatistics, ShotEvent } from '../types';

const mapShots = (periodTimeLine): Data => {
  const shotEvents: ShotEvent[] = [];
  const eventCountByType = initializeShotCounts();
  const { timeLine } = toCamelCase(periodTimeLine, true);

  for (const event of timeLine) {
    if (SHOT_EVENT_TYPES.includes(event.eventType)) {
      let mappedEvent = formatTimeLineItem(event);

      if (mappedEvent.eventType === PlayerHappeningType.SCORE_CHANGE) {
        mappedEvent = mapGoalHappening(mappedEvent);
      }

      eventCountByType[event.eventType][event.team]++;
      shotEvents.push(mappedEvent as ShotEvent);
    }
  }

  const statistics = mapShotStatistics(eventCountByType);

  return { shotEvents, statistics };
};

const initializeShotCounts = () => {
  return SHOT_EVENT_TYPES.reduce(
    (acc, shotEventType) => ({ ...acc, [shotEventType]: { home: 0, away: 0 } }),
    {},
  );
};

const mapShotStatistics = (stats: ReturnType<typeof initializeShotCounts>): CompareStatistics[] => {
  return SHOT_EVENT_TYPES.map((shotEventType) => {
    const { home, away } = stats[shotEventType];
    const isHomeLeader = home > away;

    return {
      type: shotEventType,
      key: `match.shots.parameter.${shotEventType}`,
      home: {
        isLeader: isHomeLeader,
        percentages: parseFloat(((home / (home + away)) * 100).toFixed(2)),
        formattedValue: home,
      },
      away: {
        isLeader: !isHomeLeader,
        percentages: parseFloat(((away / (home + away)) * 100).toFixed(2)),
        formattedValue: away,
      },
    };
  });
};

export default mapShots;
