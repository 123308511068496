import { handleActions, ReducerMap } from 'redux-actions';

import { detailsActions, commonActions } from '../../../../actions';
import { Interfaces } from '../../../../types';

const { topPlayers } = detailsActions;
const defaultState = [];

const reducerMap: ReducerMap<
  Interfaces.Details.TopPlayers.Data,
  Interfaces.Details.TopPlayers.Data
> = {
  [`${topPlayers.loadTopPlayersSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${commonActions.cleanSummarySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
