import React, { createElement } from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { CommonTableParameter } from 'types';
import { Tooltip, TournamentTableGoalsCell } from 'packages/shared/components';
import {
  CommonTableStatistics,
  SeasonTableRow,
  StatisticsGroup,
} from 'packages/event-standings/types';

import { TeamCell } from '../../../components';

type Options = {
  groupName: string;
  statisticsGroup: StatisticsGroup;
};

const getColumnSchema = ({
  groupName,
  statisticsGroup,
}: Options): Column<SeasonTableRow<CommonTableStatistics>>[] => {
  return [
    {
      id: 'position',
      Header: groupName,
      headerStyle: { textAlign: 'left', paddingLeft: 16 },
      accessor: ({ statistics, team }) => ({
        position: statistics[statisticsGroup].position,
        team,
      }),
      Cell: ({ value: props }) => createElement(TeamCell, { ...props }),
      width: 170,
    },
    {
      id: 'gamesPlayed',
      Header: (
        <Tooltip
          value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.GamesPlayed}`)}
        >
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.GamesPlayed}`)}</>
        </Tooltip>
      ),
      accessor: ({ statistics }) => statistics[statisticsGroup].gamesPlayed,
      width: 20,
    },
    {
      id: 'win',
      Header: (
        <Tooltip value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.Win}`)}>
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.Win}`)}</>
        </Tooltip>
      ),
      accessor: ({ statistics }) => statistics[statisticsGroup].win,
      width: 20,
    },
    {
      id: 'draw',
      Header: (
        <Tooltip value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.Draw}`)}>
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.Draw}`)}</>
        </Tooltip>
      ),
      accessor: ({ statistics }) => statistics[statisticsGroup].draw,
      width: 20,
    },
    {
      id: 'loss',
      Header: (
        <Tooltip value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.Loss}`)}>
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.Loss}`)}</>
        </Tooltip>
      ),
      accessor: ({ statistics }) => statistics[statisticsGroup].loss,
      width: 20,
    },
    {
      id: 'statistics',
      Header: (
        <Tooltip value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.Goals}`)}>
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.Goals}`)}</>
        </Tooltip>
      ),
      accessor: ({ statistics }) => statistics[statisticsGroup],
      Cell: ({ value: props }) => createElement(TournamentTableGoalsCell, props),
      width: 35,
    },
    {
      id: 'goalDifference',
      Header: (
        <Tooltip
          value={i18n.value(
            `common.tableParameter.extended.${CommonTableParameter.GoalDifference}`,
          )}
        >
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.GoalDifference}`)}</>
        </Tooltip>
      ),
      accessor: ({ statistics }) => statistics[statisticsGroup].goalDifference,
      width: 25,
    },
    {
      id: 'points',
      Header: (
        <Tooltip
          value={i18n.value(`common.tableParameter.extended.${CommonTableParameter.Points}`)}
        >
          <>{i18n.value(`common.tableParameter.short.${CommonTableParameter.Points}`)}</>
        </Tooltip>
      ),
      accessor: ({ statistics }) => statistics[statisticsGroup].points,
      width: 20,
    },
  ];
};

export default getColumnSchema;
