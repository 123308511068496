import * as eventsConstants from '../../constants';

const { PERIOD_STATUS_PRIORITY } = eventsConstants;

export const getPeriodStatus = (periodScores) => {
  if (periodScores) {
    const [{ status }] = Object.keys(periodScores)
      .map(map)
      .sort(sort);

    return status || '';
  }

  return '';
};

const sort = (a, b) => b.priority - a.priority;

const map = (status) => ({
  status: eventsConstants.STATUS_VIEW_SET[status] || '',
  priority: PERIOD_STATUS_PRIORITY[status] || PERIOD_STATUS_PRIORITY['unknown'],
});
