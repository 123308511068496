import React, { memo, RefObject, useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';

import { Interfaces as PlayerSummaryInterfaces } from 'packages/player-summary/types';
import { Team } from 'types';

import { getTabs, mapTeamsToSelectItems } from '../../services';
import { Header } from './components';
import styles from './styles.scss';
import { FilterState } from './types';
import StatisticsBlock from '../StatisticsBlock';
import StatisticsHeaderSkeleton from '../StatisticsHeaderSkeleton';

type Props = {
  teams: Team[];
  playerSummaryStatistics: PlayerSummaryInterfaces.Statistics.Statistics.Data;
  isWholeStatisticsLoading: boolean;
  isColumnDirectionActive?: boolean;
  isPopoverWidthFixed?: boolean;
  className?: string;
  parentContainerRef?: RefObject<HTMLDivElement>;
  isTeamsLoading: boolean;
};

const Statistics = ({
  isColumnDirectionActive = false,
  isPopoverWidthFixed = false,
  playerSummaryStatistics,
  isWholeStatisticsLoading,
  className,
  parentContainerRef,
  teams,
  isTeamsLoading,
}: Props) => {
  const tabs = useMemo(getTabs, []);
  const teamItems = useMemo(() => mapTeamsToSelectItems(teams), [teams]);

  const [mainFilter, updateMainFilter] = useState<FilterState>({
    activeViewId: tabs[0].id,
    selectedTeam: teamItems[0],
  });

  useEffect(() => {
    updateMainFilter({ activeViewId: tabs[0].id, selectedTeam: teamItems[0] });
  }, [teamItems]);

  const { activeViewId } = mainFilter;

  const isLoading = isWholeStatisticsLoading || isTeamsLoading;

  return (
    <div className={classnames(styles.statisticsBlock, className)}>
      {isLoading ? (
        <StatisticsHeaderSkeleton isCompact />
      ) : (
        <Header
          isPopoverWidthFixed={isPopoverWidthFixed}
          updateMainFilter={updateMainFilter}
          teamItems={teamItems}
          tabs={tabs}
          mainFilter={mainFilter}
          parentContainerRef={parentContainerRef}
        />
      )}

      <StatisticsBlock
        isInitialLoading={isLoading}
        isColumnDirectionActive={isColumnDirectionActive}
        statistics={playerSummaryStatistics?.[activeViewId]}
      />
    </div>
  );
};

export default memo(Statistics);
