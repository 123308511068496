import { SeasonHistory } from 'packages/rest';
import { mapSeasons, getFirstSeason } from 'packages/season-history/services';

const loadHistory = (tournamentId: number) => async () => {
  const { data } = await SeasonHistory.loadSeasonHistory({ tournamentId });

  const mappedSeason = mapSeasons(data.results);

  return {
    seasons: mappedSeason,
    firstSeason: getFirstSeason(mappedSeason),
  };
};

export default loadHistory;
