import i18n from 'i18n-smart';

import { Stage, StatisticsGroup } from 'packages/event-standings/types';
import { HeadToHeadTeamStatisticsTable, TeamFormTable } from 'packages/shared/modules';

import { StageTab } from '../../../../../types';
import {
  shouldTableGroupTabBeDisplayed,
  shouldHeadToHeadBeDisplayed,
} from '../../../../../services';

const getFilterTabs = (stages: Stage[]): StageTab[] => {
  const shouldH2HDBeDisplayed = shouldHeadToHeadBeDisplayed(stages);
  const shouldAwayGroupBeDisplayed = shouldTableGroupTabBeDisplayed({
    stages,
    group: StatisticsGroup.Away,
  });
  const shouldHomeGroupBeDisplayed = shouldTableGroupTabBeDisplayed({
    stages,
    group: StatisticsGroup.Home,
  });

  const filterTabs: any = [
    {
      id: 'standings-overall-filter',
      name: i18n.value('season.seasonStandings.overallTitle'),
      overriddenProps: {
        getProps: (props) => {
          return {
            ...props,
            statisticsGroup: StatisticsGroup.Overall,
          };
        },
      },
    },
  ];

  if (shouldHomeGroupBeDisplayed) {
    filterTabs.push({
      id: 'standings-home-filter',
      name: i18n.value('season.seasonStandings.homeTitle'),
      overriddenProps: {
        getProps: (props) => {
          return {
            ...props,
            statisticsGroup: StatisticsGroup.Home,
          };
        },
      },
    });
  }

  if (shouldAwayGroupBeDisplayed) {
    filterTabs.push({
      id: 'standings-away-filter',
      name: i18n.value('season.seasonStandings.awayTitle'),
      overriddenProps: {
        getProps: (props) => {
          return {
            ...props,
            statisticsGroup: StatisticsGroup.Away,
          };
        },
      },
    });
  }

  filterTabs.push({
    id: 'season.seasonStandings.form',
    name: i18n.value('season.seasonStandings.form'),
    overriddenProps: {
      contentComponent: TeamFormTable,
      getProps: (props) => {
        return {
          ...props,
          statisticsGroup: StatisticsGroup.Overall,
        };
      },
    },
  });

  if (shouldH2HDBeDisplayed) {
    filterTabs.push({
      id: 'standings-h2h-filter',
      name: i18n.value('season.seasonStandings.headToHeadTitle'),
      overriddenProps: {
        contentComponent: HeadToHeadTeamStatisticsTable,
      },
    });
  }

  return filterTabs;
};

export default getFilterTabs;
