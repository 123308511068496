import React from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { PlayersParameter, TopPlayer } from 'packages/season-top-board/types';
import { Tooltip } from 'packages/shared/components';

import { ParameterHeader, PlayerCell, TeamCell } from '../../components';

const getGoals = (): Column<TopPlayer>[] => [
  {
    id: 'player',
    Header: i18n.value('season.seasonTopBoard.headers.player'),
    Cell: ({ value }) => <PlayerCell player={value} />,
    accessor: ({ player }) => player,
    width: 58,
    cellStyle: { paddingLeft: 16 },
    headerStyle: { textAlign: 'left', padding: '8px 8px 8px 16px' },
  },
  {
    id: 'team',
    Cell: ({ value: team }) => <TeamCell isNameHidden team={team} />,
    accessor: ({ player }) => player.team,
    width: 10,
  },
  {
    id: 'gamesPlayed',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.GamesPlayed}`)}>
        <>{i18n.value(`common.tableParameter.short.${PlayersParameter.GamesPlayed}`)}</>
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.gamesPlayed,
    width: 10,
  },
  {
    id: 'goals',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.Goals}`)}>
        <ParameterHeader parameter={PlayersParameter.Goals} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.goals,
    width: 10,
  },
  {
    id: 'assists',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.Assists}`)}>
        <ParameterHeader parameter={PlayersParameter.Assists} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.assists,
    width: 10,
  },
  {
    id: 'goalPoints',
    Header: (
      <Tooltip value={i18n.value(`common.tableParameter.extended.${PlayersParameter.GoalPoints}`)}>
        <ParameterHeader parameter={PlayersParameter.GoalPoints} />
      </Tooltip>
    ),
    accessor: ({ statistics }) => statistics.goalPoints,
    width: 12,
  },
];

export default getGoals;
