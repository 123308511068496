import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';

import {
  TeamStatistics,
  Ordering,
  Column,
  ColumnType,
  PresetType,
  StatType,
} from 'packages/season-team-statistics/types';
import { Pagination } from 'packages/shared/components';
import { Team } from 'types';
import { DEFAULT_TEAMS_LIMIT } from 'packages/season-team-statistics/constants';

import { getPresetItems } from '../../../../services';
import { getTableSchema, getSkeletonTableSchema } from './services';
import { StatisticsTable, TableSkeleton } from '../../../../components';
import { ControlHeader } from './components';
import styles from './styles.scss';

type Props = {
  onOrderingChange: (columnType: ColumnType) => void;
  onPresetChange: (preset: PresetType) => void;
  onPaginationChange: ({ offset, limit }) => void;
  onTeamClick: (team: Team) => void;
  onCustomizeButtonClick: () => void;
  teamStatistics: TeamStatistics[];
  ordering: Ordering;
  columns: Column[];
  activeStatsTab: StatType;
  activePreset: PresetType;
  currentPageSize: number;
  amountOfTeams: number;
  isNewTeamsPageLoading: boolean;
  isInitialTeamsPageLoading: boolean;
  className?: string;
};

const MainScreen = ({
  onCustomizeButtonClick,
  onOrderingChange,
  onPresetChange,
  onPaginationChange,
  teamStatistics,
  isNewTeamsPageLoading,
  activeStatsTab,
  activePreset,
  currentPageSize,
  amountOfTeams,
  ordering,
  columns,
  className,
  onTeamClick,
  isInitialTeamsPageLoading,
}: Props) => {
  const isLimitSelectable = amountOfTeams > DEFAULT_TEAMS_LIMIT;
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const presetItems = useMemo(getPresetItems, []);
  const activePresetItem = useMemo(() => {
    return presetItems.find(({ id }) => id === activePreset) || presetItems[0];
  }, [activePreset, presetItems]);

  useEffect(() => {
    scrollContainerToTop();
  }, [teamStatistics]);

  const scrollContainerToTop = () => {
    tableContainerRef.current?.scrollTo({ top: 0 });
  };

  const handlePresetItemSelect = useCallback((presetType: PresetType) => {
    onPresetChange(presetType);
  }, []);

  return (
    <div className={classnames(styles.mainScreenContainer, className)}>
      {isInitialTeamsPageLoading ? (
        <TableSkeleton
          isHeaderButtonLabelHidden
          getTableSchema={getSkeletonTableSchema}
          elementsCount={currentPageSize}
        />
      ) : (
        <>
          <ControlHeader
            onPresetItemSelect={handlePresetItemSelect}
            onCustomizeButtonClick={onCustomizeButtonClick}
            activePresetItem={activePresetItem}
            presetItems={presetItems}
            className={styles.controlHeader}
          />
          <StatisticsTable
            getTableSchema={getTableSchema}
            onTeamClick={onTeamClick}
            onOrderingChange={onOrderingChange}
            activeStatType={activeStatsTab}
            teamStatistics={teamStatistics}
            ordering={ordering}
            columns={columns}
            forwardedRef={tableContainerRef}
            isLoading={isNewTeamsPageLoading}
            className={styles.statisticsTable}
          />
        </>
      )}
      <Pagination
        isLoading={isInitialTeamsPageLoading}
        isLimitSelectable={isLimitSelectable}
        amountOfItems={amountOfTeams}
        pageSize={currentPageSize}
        onPaginationChange={onPaginationChange}
        className={styles.paginationContainer}
        useRightAlignment
      />
    </div>
  );
};

export default memo(MainScreen);
