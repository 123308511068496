import { stagesActions } from '../../actions';

const cleanStages = () => async (dispatch) => {
  try {
    dispatch(stagesActions.cleanStagesSuccess());
  } catch (e) {
    dispatch(stagesActions.cleanStagesFailure(e));
  }
};

export default cleanStages;
