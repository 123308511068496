interface Params {
  bracketContainerElement: HTMLDivElement;
  bracketGridElement: HTMLDivElement;
  columnElements: NodeListOf<HTMLDivElement>;
  columnsHeights: number[];
}

const resetBracketState = ({
  bracketContainerElement,
  bracketGridElement,
  columnElements,
  columnsHeights,
}: Params) => {
  resetColumnsHeights(columnElements, columnsHeights);
  bracketContainerElement.scrollTop = 0;
  bracketGridElement.style.transform = `unset`;
};

const resetColumnsHeights = (columnElements: NodeListOf<HTMLDivElement>, heights: number[]) => {
  for (let i = 0; i < columnElements.length; i++) {
    (columnElements[i] as HTMLDivElement).style.height = `${heights[i]}px`;
  }
};

export default resetBracketState;
