import React from 'react';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';

import styles from './styles.scss';
import { MIN_DISPLAYABLE_COUNT, MIN_SCALABLE_COUNT } from './constants';

type Props = {
  iconId: IconType.Assist | IconType.Goal;
  amount: number;
  className?: string;
};

const CountableHappening = ({ iconId, amount, className }: Props) => {
  if (!amount) {
    return null;
  }

  return (
    <div className={classnames(styles.wrapper, className)}>
      <Icon id={iconId} />

      {amount > MIN_DISPLAYABLE_COUNT && (
        <div className={classnames(styles.count, { [styles.lg]: amount > MIN_SCALABLE_COUNT })}>
          {amount}
        </div>
      )}
    </div>
  );
};

export default CountableHappening;
