import { useEffect, useMemo, useState } from 'react';
import { getPages } from '../services';

const usePages = (amountOfPages: number) => {
  const pages = useMemo(() => getPages(amountOfPages), [amountOfPages]);
  const [controllablePageIndexes, setControllablePageIndexes] = useState<number[]>([]);
  const [activePageIndex, setActivePageIndex] = useState(0);

  useEffect(() => {
    updatePages(0);
  }, [pages]);

  const updatePages = (activePageIndex) => {
    if (pages.length < 3) {
      const pageIndexes = pages.map((page, index) => index);
      setControllablePageIndexes(pageIndexes);

      if (activePageIndex <= 0) {
        setActivePageIndex(0);
      } else if (activePageIndex >= pages.length - 1) {
        setActivePageIndex(pages.length - 1);
      }
    } else {
      if (activePageIndex <= 0) {
        setControllablePageIndexes([0, 1, pages.length - 1]);
        setActivePageIndex(0);
      } else if (activePageIndex >= pages.length - 1) {
        setControllablePageIndexes([0, pages.length - 2, pages.length - 1]);
        setActivePageIndex(pages.length - 1);
      } else {
        setControllablePageIndexes([0, activePageIndex, pages.length - 1]);
        setActivePageIndex(activePageIndex);
      }
    }
  };

  return {
    pages,
    controllablePageIndexes,
    activePageIndex,
    updatePages,
  };
};

export default usePages;
