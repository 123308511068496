import * as matchLastGamesActions from './actions';
import * as matchLastGamesSelectors from './selectors';
import * as matchLastGamesConstants from './constants';
import * as matchLastGamesThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  matchLastGamesActions,
  matchLastGamesSelectors,
  matchLastGamesConstants,
  matchLastGamesThunks,
  createReducer,
};
