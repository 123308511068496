import React from 'react';
import i18n from 'i18n-smart';

import { Chip } from 'packages/shared/components';

import styles from './styles.scss';

const Loading = () => {
  return (
    <div className={styles.commentContainer}>
      <div className={styles.commentChip}>
        <Chip className={styles.chip}>
          <div className={styles.loaderContainer}>
            <div className={styles.loaderDot} />
            <div className={styles.loaderDot} />
            <div className={styles.loaderDot} />
          </div>
        </Chip>
      </div>

      <div className={styles.commentText}>
        <span>{i18n.value('eventCommentary.matchGoesOn')}</span>
      </div>
    </div>
  );
};

export default React.memo(Loading);
