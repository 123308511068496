import { createElement } from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { History } from 'packages/season-history/types';
import { Team } from 'types';

import { SeasonCell, TeamCell, RelegationCell } from '../components';
import { Props as TeamCellProps } from '../components/TeamCell';

type Options = {
  teamComponent: TeamCellProps['teamComponent'];
  teamClickGetter: (seasonId: string) => (team: Team) => void;
};

const getTableSchema = ({ teamClickGetter, teamComponent }: Options): Column<History>[] => {
  return [
    {
      id: 'year',
      Header: i18n.value('season.history.column.season'),
      headerStyle: { textAlign: 'left', paddingLeft: 16 },
      accessor: 'year',
      Cell: ({ value: year }) => createElement(SeasonCell, { year }),
      width: 50,
    },
    {
      id: 'winner',
      Header: i18n.value('season.history.column.winner'),
      headerStyle: { textAlign: 'left' },
      accessor: ({ winner, id }) => ({ winner, id }),
      Cell: ({ value: props }) =>
        createElement(TeamCell, {
          team: props.winner,
          onTeamClick: teamClickGetter(props.id),
          teamComponent,
        }),
      width: 50,
    },
    {
      id: 'runnerUp',
      Header: i18n.value('season.history.column.runnerUp'),
      headerStyle: { textAlign: 'left' },
      accessor: ({ runnerUp, id }) => ({ runnerUp, id }),
      Cell: ({ value: props }) =>
        createElement(TeamCell, {
          team: props.runnerUp,
          onTeamClick: teamClickGetter(props.id),
          teamComponent,
        }),
      width: 50,
    },
    {
      id: 'thirdPlace',
      Header: i18n.value('season.history.column.thirdPlace'),
      headerStyle: { textAlign: 'left' },
      accessor: ({ thirdPlace, id }) => ({ thirdPlace, id }),
      Cell: ({ value: props }) =>
        createElement(TeamCell, {
          team: props.thirdPlace,
          onTeamClick: teamClickGetter(props.id),
          teamComponent,
        }),
      width: 50,
    },
    {
      id: 'relegations',
      Header: i18n.value('season.history.column.relegation'),
      headerStyle: { textAlign: 'left' },
      accessor: ({ relegations, id }) => ({ relegations, id }),
      Cell: ({ value: props }) =>
        createElement(RelegationCell, {
          teams: props.relegations,
          onTeamClick: teamClickGetter(props.id),
        }),
      width: 50,
    },
  ];
};

export default getTableSchema;
