import React, { RefObject } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { SelectItem } from 'packages/shared/components/Select/types';
import { ComponentLike } from 'types';

import { SelectItems, ItemListControls } from '../../../../components';
import styles from './styles.scss';

type Props<T extends SelectItem> = {
  items: T[];
  selectedItems: T[];
  onItemClick: (item: T) => void;
  itemsContainerClass?: string;
  itemComponent?: ComponentLike;
  onClear: () => void;
  onSelectAll: () => void;
  containerRef?: RefObject<HTMLDivElement>;
};

const PopoverItemList = <T extends SelectItem>({
  items,
  itemsContainerClass,
  onItemClick,
  selectedItems,
  itemComponent,
  onClear,
  onSelectAll,
  containerRef,
}: Props<T>) => {
  return (
    <SelectItems
      className={classnames(styles.selectItemsContainer, itemsContainerClass)}
      items={items}
      onItemClick={onItemClick}
      selectedItems={selectedItems}
      itemComponent={itemComponent}
      isCheckShown
      containerRef={containerRef}
    >
      {items.length ? (
        <ItemListControls
          isClearButtonDisabled={!selectedItems.length}
          onClear={onClear}
          onSelectAll={onSelectAll}
        />
      ) : (
        <div className={styles.emptyOptions}>{i18n.value('common.emptyText')}</div>
      )}
    </SelectItems>
  );
};

export default PopoverItemList;
