import DateService from 'packages/date-az';

const getLiveMatchesCount = (
  matchesCount: number,
  liveMatchesCount: number,
  activeDate: string,
) => {
  const isActiveDateToday = DateService.getDate().toISODate() === activeDate;

  if (isActiveDateToday) {
    return liveMatchesCount > matchesCount ? matchesCount : liveMatchesCount;
  }

  return 0;
};

export default getLiveMatchesCount;
