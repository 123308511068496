import React, { memo, useMemo } from 'react';
import i18n from 'i18n-smart';

import { ArrayUtils } from 'packages/utils';
import { Season } from 'packages/team-events/types';
import { ExtendedMultipleSelect } from 'packages/shared/components/Select/containers';

import { getTeamSelectItems } from './services';
import { SeasonSelectOption } from './types';

type Props = {
  seasons: Season[];
  selectedSeasons: Season[];
  onChange: (seasons: Season[]) => void;
  isDisabled?: boolean;
  className?: string;
};

const SeasonSelect = ({
  seasons,
  isDisabled = false,
  selectedSeasons,
  onChange,
  className,
}: Props) => {
  const selectItems = useMemo(() => {
    return getTeamSelectItems(seasons);
  }, [seasons]);
  const selectedItems = useMemo(() => {
    return getTeamSelectItems(selectedSeasons);
  }, [selectedSeasons]);

  const handleChange = (items: SeasonSelectOption[]) => {
    const selectedSeasons = items.map((item) => ArrayUtils.findById(seasons, item.id));

    onChange(selectedSeasons);
  };

  return (
    <ExtendedMultipleSelect<SeasonSelectOption>
      onChange={handleChange}
      isDisabled={isDisabled}
      items={selectItems}
      selectedItems={selectedItems}
      inputLabel={i18n.value('team.teamEvents.filters.seasonStaticSelect.label')}
      className={className}
    />
  );
};

export default memo(SeasonSelect);
