import { SelectItem } from 'packages/shared/components/Select/types';

import { NavigationMenuItem } from '../../../types';

export const getSelectItems = (
  items: NavigationMenuItem[],
  activeItemId: string | number,
): { activeItem?: SelectItem; selectItems: SelectItem[] } => {
  const selectItems = items.map(({ id, name }) => ({ id, label: name || '' }));
  const activeItem = selectItems.find(({ id }) => id === activeItemId);

  return { activeItem, selectItems };
};
