import React from 'react';

import { Player } from 'packages/season-top-board/types';
import { Figure } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import styles from './styles.scss';

type Props = {
  player: Player;
};

const PlayerCell = ({ player }: Props) => {
  const { appNavigate } = useNavigation();

  const { countryLogo, name, firstName, lastName, logo, id: playerId, position, age } = player;
  const nameView = name ? name : `${firstName} ${lastName}`;

  const handlePlayerClick = () => {
    appNavigate(DesktopPage.FootballPlayer, { playerId });
  };

  return (
    <div className={styles.playerCell}>
      <div onClick={handlePlayerClick} className={styles.playerLogoContainer}>
        <Figure src={logo} className={styles.playerLogo} />
      </div>

      <div className={styles.description}>
        <div onClick={handlePlayerClick} className={styles.nameBlock}>
          {nameView}
        </div>

        <div className={styles.position}>
          <Figure src={countryLogo} className={styles.logo} />
          {position}, {age}
        </div>
      </div>
    </div>
  );
};

export default PlayerCell;
