exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._KfbLe8pC{flex:1 1;margin-right:16px}._24EK41UC{width:443px}._3PJWx-t3{flex:1 1;display:flex;align-items:flex-start;justify-content:center}._35NLFbha{position:relative;display:flex;flex-direction:column;height:100%;width:100%}._2DAlEaap{margin-bottom:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktop/football/TeamStandings/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,WAAY,CAPhB,WAWI,QAAO,CACP,YAAa,CACb,sBAAuB,CACvB,sBAAuB,CAd3B,WAkBI,iBAAkB,CAClB,YAAa,CACb,qBAAsB,CACtB,WAAY,CACZ,UAAW,CAtBf,WA0BI,kBC3BS","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n  }\n\n  .side-bar {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n  }\n\n  .advertisement {\n    margin-bottom: $margin;\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_KfbLe8pC",
	"leftColumn": "_KfbLe8pC",
	"right-column": "_24EK41UC",
	"rightColumn": "_24EK41UC",
	"left-bar": "_3PJWx-t3",
	"leftBar": "_3PJWx-t3",
	"side-bar": "_35NLFbha",
	"sideBar": "_35NLFbha",
	"advertisement": "_2DAlEaap"
};