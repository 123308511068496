import React from 'react';
import classnames from 'classnames';

import { GridColumnId } from 'types';
import { ComingSoon } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  className?: string;
};

const SideBar = ({ className }: Props) => {
  return (
    <div key={GridColumnId.LeftBar} className={classnames(styles.leftBar, className)}>
      <ComingSoon />
    </div>
  );
};

export default SideBar;
