import React from 'react';
import classnames from 'classnames';

import { Separator } from 'packages/shared/components';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';
import {
  RangeFilterColumnsView,
  ActiveColumnsState,
  RangeFilterState,
  PresetView,
  Range,
  Column,
  ColumnType,
  PresetType,
  StatType,
  CountrySelectOption,
} from 'packages/season-team-statistics/types';
import { ComponentLike } from 'types';

import styles from './styles.scss';
import { ColumnsArrangement, ColumnsConfiguration, CustomizationPresets } from './components';

type Props = {
  configurableColumns: Column[];
  activePresetType: PresetType;
  onPresetChange: (activePreset: PresetView) => void;
  onColumnsOrderChange: (columns: Column[]) => void;
  onRangeGroupUpdate: (type: ColumnType, updates?: Range) => void;
  onColumnToggle: (type: ColumnType) => void;
  onActiveStatTypeChange: (statType: StatType) => void;
  onSelectedCountriesChange: (countries: CountrySelectOption[]) => void;
  rangeFilters: RangeFilterState;
  activeColumns: ActiveColumnsState;
  activeStatType: StatType;
  statTabs: NavigationMenuItem[];
  selectedCountryItems: CountrySelectOption[];
  countrySelectItems: CountrySelectOption[];
  columnConfigurationSchema: RangeFilterColumnsView;
  className?: string;
  GeneralFilterComponent: ComponentLike;
};

const FilterArea = ({
  configurableColumns,
  activePresetType,
  onPresetChange,
  onColumnsOrderChange,
  onRangeGroupUpdate,
  onSelectedCountriesChange,
  selectedCountryItems,
  countrySelectItems,
  onColumnToggle,
  rangeFilters,
  activeColumns,
  activeStatType,
  onActiveStatTypeChange,
  statTabs,
  columnConfigurationSchema,
  className,
  GeneralFilterComponent,
}: Props) => {
  return (
    <div className={classnames(styles.filterAreaContainer, className)}>
      <GeneralFilterComponent
        onActiveStatTypeChange={onActiveStatTypeChange}
        onSelectedCountriesChange={onSelectedCountriesChange}
        activeStatType={activeStatType}
        countrySelectItems={countrySelectItems}
        selectedCountryItems={selectedCountryItems}
        statTabs={statTabs}
      />
      <Separator className={styles.filterGroupSeparator} />
      <CustomizationPresets
        onPresetChange={onPresetChange}
        activePresetType={activePresetType}
        className={styles.customizationPresetsContainer}
      />
      <ColumnsArrangement
        columns={configurableColumns}
        onColumnPositionChange={onColumnsOrderChange}
        className={styles.columnsArrangementContainer}
      />
      <ColumnsConfiguration
        activeStatType={activeStatType}
        columnConfigurationSchema={columnConfigurationSchema}
        rangeFilterState={rangeFilters}
        activeColumnsState={activeColumns}
        onRangeGroupUpdate={onRangeGroupUpdate}
        onColumnToggle={onColumnToggle}
        columns={configurableColumns}
      />
    </div>
  );
};

export default FilterArea;
