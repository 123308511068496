import * as notifierActions from './actions';
import * as notifierSelectors from './selectors';
import * as notifierThunks from './thunks';
import * as notifierConstants from './constants';
import reducers from './reducers/notifier';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export { notifierActions, notifierSelectors, notifierThunks, createReducer, notifierConstants };
