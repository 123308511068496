import { Team } from 'types';
import { toCamelCase } from 'packages/utils/Object.utils';

const mapPlayerTeams = (playerTeams: Record<string, any>[]): Team[] => {
  return [
    ...playerTeams
      .reduce((acc, { team }) => {
        const { id, name, logo, suffix } = toCamelCase(team);

        return acc.set(id, { id, name: suffix ? `${name} ${suffix}` : name, logo });
      }, new Map())
      .values(),
  ];
};

export default mapPlayerTeams;
