import React from 'react';
import classnames from 'classnames';

import { Tabs } from 'packages/shared/components';
import { TabView } from 'packages/shared/components/Tabs/types';

import { EVENT_DETAILS_CONTENT_ID } from './constants';
import styles from './styles.scss';

type Props = {
  stickyHeaderTopOffset?: number;
  className?: string;
  contentComponentProps?: object;
  tabs: TabView[];
};

const EventDetailsContent = ({
  className,
  stickyHeaderTopOffset = 0,
  tabs,
  contentComponentProps,
}: Props) => {
  return (
    <div
      id={EVENT_DETAILS_CONTENT_ID}
      className={classnames(styles.eventDetailsContent, className)}
    >
      <Tabs
        contentComponentProps={contentComponentProps}
        tabs={tabs}
        isHeaderSticky
        stickyHeaderTopOffset={stickyHeaderTopOffset}
      />
    </div>
  );
};

export default React.memo(EventDetailsContent);
