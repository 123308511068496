import React, { memo, useMemo } from 'react';

import { History } from 'packages/season-history/types';
import { FlexibleTable } from 'packages/shared/components';
import { Team } from 'types';

import { Props as TeamCellProps } from './components/TeamCell';
import { getRowProps, getTableSchema } from './services';

type Props = {
  data: History[];
  onSeasonClick: (year: string) => void;
  teamClickGetter: (seasonId: string) => (team: Team) => void;
  teamComponent: TeamCellProps['teamComponent'];
  isHeaderSticky?: boolean;
};

const HistoryTable = ({
  data,
  teamComponent,
  onSeasonClick,
  teamClickGetter,
  isHeaderSticky = false,
}: Props) => {
  const tableColumns = useMemo(() => getTableSchema({ teamComponent, teamClickGetter }), []);
  const rowProps = useMemo(() => getRowProps({ onClick: onSeasonClick }), []);

  return (
    <FlexibleTable
      isHeaderSticky={isHeaderSticky}
      columns={tableColumns}
      setRowProps={rowProps}
      data={data}
      isRowClickable
    />
  );
};

export default memo(HistoryTable);
