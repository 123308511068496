import getPath from './getPath';
import { TeamsStatus } from '../../types';

const getStatus = (state): TeamsStatus => {
  return getPath(state).status;
};

export const getIsInitialPageLoading = (state): boolean => {
  return getStatus(state).isInitialPageLoading;
};

export const getIsNewPageLoading = (state): boolean => {
  return getStatus(state).isNewPageLoading;
};

export const getHasNext = (state): boolean => {
  return getStatus(state).hasNext;
};

export const getOffset = (state): number => {
  return getStatus(state).offset;
};

export const getLimit = (state): number => {
  return getStatus(state).limit;
};

export const getCount = (state): number => {
  return getStatus(state).count;
};
