import React from 'react';

import styles from './styles.scss';

type Props = {
  teamName: string;
  formation: string;
};

const TeamDetails = ({ teamName, formation }: Props) => {
  return (
    <div className={styles.teamDetailsContainer}>
      <span className={styles.teamTitle}>{teamName}</span>
      <span className={styles.formation}>{formation}</span>
    </div>
  );
};

export default React.memo(TeamDetails);
