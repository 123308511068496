import React from 'react';
import classnames from 'classnames';

import { Cards } from 'types';
import { RefereeCard } from 'packages/shared/components';
import { RefereeCardType } from 'packages/shared/components/RefereeCard/types';

import styles from './styles.scss';

type Props = {
  fines: Cards;
  className?: string;
};

const EventFines = ({ fines, className }: Props) => {
  const { yellow, red, yellowRed } = fines;

  return (
    <div className={classnames(styles.container, className)}>
      {Boolean(yellow) && (
        <RefereeCard className={styles.card} type={RefereeCardType.Yellow} count={yellow} />
      )}
      {Boolean(yellowRed) && (
        <RefereeCard className={styles.card} type={RefereeCardType.YellowRed} count={yellowRed} />
      )}
      {Boolean(red) && (
        <RefereeCard className={styles.card} type={RefereeCardType.Red} count={red} />
      )}
    </div>
  );
};

export default React.memo(EventFines);
