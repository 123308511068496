import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';
import classnames from 'classnames';

import { Team } from 'types';
import { Interfaces, Enums } from 'packages/team-player-statistics/types';
import { Pagination } from 'packages/shared/components';
import { PlayerStatisticsTable } from 'packages/shared/modules';
import { Player } from 'packages/season-player-statistics/types';
import { DEFAULT_PLAYERS_LIMIT } from 'packages/team-player-statistics/constants';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';

import { getPresetItems, getTournamentSeasonSelectItems } from '../../../../services';
import { SkeletonHeader } from '../../../../components';
import { ControlHeader } from './components';
import styles from './styles.scss';

type Props = {
  onOrderingChange: (columnType: Enums.ColumnType) => void;
  onPresetChange: (preset: Enums.PresetType) => void;
  onTeamClick: (team: Team) => void;
  onPlayerClick: (player: Player) => void;
  onPaginationChange: ({ offset, limit }) => void;
  onCustomizeButtonClick: () => void;
  onTournamentChange: (tournamentId: number | string) => void;
  playersStatistics: Interfaces.PlayerStatistics.PlayerStatistics[];
  selectedTournamentId?: number;
  selectedSeasonId?: number;
  tournaments?: SeasonsExtendedTournament[];
  ordering: Interfaces.CustomFilter.Ordering;
  columns: Interfaces.CustomFilter.Column[];
  activeStatsTab: Enums.StatType;
  activePreset: Enums.PresetType;
  amountOfPlayers: number;
  currentPageSize: number;
  isInitialPlayersPageLoading: boolean;
  isNewPlayersPageLoading: boolean;
  className?: string;
};

const MainScreen = ({
  onCustomizeButtonClick,
  onOrderingChange,
  onPresetChange,
  onPaginationChange,
  onTournamentChange,
  playersStatistics,
  selectedTournamentId,
  selectedSeasonId,
  tournaments = [],
  isInitialPlayersPageLoading,
  isNewPlayersPageLoading,
  activeStatsTab,
  activePreset,
  amountOfPlayers,
  currentPageSize,
  ordering,
  columns,
  className,
  onTeamClick,
  onPlayerClick,
}: Props) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { tournamentItems, activeTournamentItem } = useMemo(
    () => getTournamentSeasonSelectItems(tournaments, selectedTournamentId, selectedSeasonId),
    [tournaments, selectedTournamentId, selectedSeasonId],
  );
  const presetItems = useMemo(getPresetItems, []);
  const activePresetItem = useMemo(() => {
    return presetItems.find(({ id }) => id === activePreset) || presetItems[0];
  }, [activePreset]);
  const isLimitSelectable = amountOfPlayers > DEFAULT_PLAYERS_LIMIT;

  useEffect(() => {
    scrollContainerToTop();
  }, [playersStatistics]);

  const scrollContainerToTop = () => {
    tableContainerRef.current?.scrollTo({ top: 0 });
  };

  const handlePresetItemSelect = useCallback(
    (presetType: Enums.PresetType) => {
      onPresetChange(presetType);
      scrollContainerToTop();
    },
    [onPresetChange],
  );

  return (
    <>
      <div className={classnames(styles.mainScreenContainer, className)}>
        {isInitialPlayersPageLoading ? (
          <SkeletonHeader isCompact />
        ) : (
          <ControlHeader
            activePresetItem={activePresetItem}
            presetItems={presetItems}
            className={styles.controlHeader}
            tournamentItems={tournamentItems}
            activeTournamentItem={activeTournamentItem}
            onTournamentChange={onTournamentChange}
            onCustomizeButtonClick={onCustomizeButtonClick}
            onPresetItemSelect={handlePresetItemSelect}
          />
        )}

        <PlayerStatisticsTable.DesktopNarrow
          onPlayerClick={onPlayerClick}
          onTeamClick={onTeamClick}
          onOrderingChange={onOrderingChange}
          activeStatType={activeStatsTab}
          isInitialPlayersLoading={isInitialPlayersPageLoading}
          isPlayersLoading={isNewPlayersPageLoading}
          playersStatistics={playersStatistics}
          pageSize={currentPageSize}
          ordering={ordering}
          columns={columns}
          forwardedRef={tableContainerRef}
          className={styles.statisticsTable}
        />

        <Pagination
          useRightAlignment
          isLoading={isInitialPlayersPageLoading}
          resultsLabel="common.pagination.playersLabel"
          isLimitSelectable={isLimitSelectable}
          amountOfItems={amountOfPlayers}
          pageSize={currentPageSize}
          onPaginationChange={onPaginationChange}
          className={styles.paginationContainer}
          isLabelVisible
        />
      </div>
    </>
  );
};

export default memo(MainScreen);
