import React, { memo } from 'react';

import { Player } from 'packages/season-top-board/types';
import { Figure } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  player: Player;
};

const PlayerCell = ({ player }: Props) => {
  const { countryLogo, name, firstName, lastName, logo, position, age } = player;
  const nameView = name ? name : `${firstName} ${lastName}`;

  return (
    <div className={styles.playerCell}>
      <div className={styles.playerLogoContainer}>
        <Figure src={logo} className={styles.playerLogo} />
      </div>

      <div className={styles.description}>
        <div className={styles.nameBlock}>{nameView}</div>

        <div className={styles.position}>
          <Figure src={countryLogo} className={styles.logo} />
          {position}, {age}
        </div>
      </div>
    </div>
  );
};

export default memo(PlayerCell);
