import React, { memo, useMemo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { EventLoadingSkeleton } from 'packages/events-ui/components';

type Props = {
  eventCount: number;
};

const LoadingSkeleton = ({ eventCount }: Props) => {
  const events = useMemo(() => ArrayUtils.getArrayFromNumber(eventCount), []);

  return (
    <>
      {events.map((event) => (
        <EventLoadingSkeleton key={event} isCompact />
      ))}
    </>
  );
};

export default memo(LoadingSkeleton);
