import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Sport } from '../types';

const defaultState: Sport = {
  id: -1,
  name: '',
};

const reducerMap: ReducerMap<Sport, Sport> = {
  [`${actions.setActiveSport}`]: (state, { payload }) => {
    const { id, name } = payload;

    return { ...defaultState, id, name };
  },
};

export default handleActions(reducerMap, defaultState);
