import React, { memo, useMemo } from 'react';

import { FlexibleTable, Skeleton } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';

import { getColumnSchema } from './services';
import styles from './styles.scss';

interface Props {
  elementsCount: number;
}

const LoadingSkeleton = ({ elementsCount }: Props) => {
  const elements = useMemo(() => ArrayUtils.getArrayFromNumber(elementsCount), []);
  const columns = useMemo(getColumnSchema, []);

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Skeleton height={16} width={90} variant="rectangular" />
        <Skeleton height={16} width={125} variant="rectangular" />
      </div>
      <FlexibleTable
        data={elements}
        columns={columns}
        className={styles.table}
        headerClassName={styles.tableHeader}
        rowClassName={styles.tableRow}
      />
    </div>
  );
};

export default memo(LoadingSkeleton);
