import { EventStandings } from 'packages/rest';
import { Event } from 'types';

import { loadStandingsStart, loadStandingsSuccess, loadStandingsFailure } from '../actions';
import { mapStandings, getActiveStageIdByEvent } from '../services';

interface Params {
  seasonId: number;
  event?: Event;
}

export const loadStandings = ({ seasonId, event }: Params) => async (dispatch) => {
  try {
    dispatch(loadStandingsStart());

    const [
      { data: seasonTablesStagesResponse },
      { data: tournamentBracketsResponse },
    ] = await Promise.all([
      EventStandings.loadSeasonTables({ seasonId }),
      EventStandings.loadEventBrackets({ seasonId }),
    ]);

    const stages = mapStandings({
      seasonTablesStages: seasonTablesStagesResponse.results,
      tournamentBrackets: tournamentBracketsResponse.results,
    });
    const activeStageId = event ? getActiveStageIdByEvent(stages, event) : undefined;

    dispatch(loadStandingsSuccess({ stages, activeStageId }));
  } catch (error) {
    dispatch(loadStandingsFailure());
  }
};

export default loadStandings;
