import React, { memo, useMemo } from 'react';
import classnames from 'classnames';

import DateService from 'packages/date-az';
import { DATE_FORMATS } from 'packages/date-az/consts';

import styles from './styles.scss';

type Props = {
  date: string;
  className?: string;
};

const EventDate = ({ date, className }: Props) => {
  const formattedDate = useMemo(
    () => DateService.formatString(date, DATE_FORMATS.SHORT_FULL_DATE),
    [],
  );

  return <div className={classnames(styles.eventDate, className)}>{formattedDate}</div>;
};

export default memo(EventDate);
