import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import isEmpty from 'is-empty-typed/lib';
import i18n from 'i18n-smart';

import { TeamsParameter, TopTeam } from 'packages/season-top-board/types';
import { TEAM_DEFAULT_ORDERING } from 'packages/season-top-board/constants';
import { BlockLink, Tabs } from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import { SortingValueStateContext } from '../../../../../../constants';
import { getInnerTabs, getTeamGoals as getGoals } from '../../../../../../services';
import { getTeamRowProps, getAggression, getAttack } from './services';
import connect from './connect';

type Props = {
  seasonTopBoardActions: ActionCreatorsMapObject;
  seasonId: string;
  uniqueTournamentId: string;
  teams: TopTeam[];
  isTopTeamsLoading: boolean;
};

const TopTeams = ({
  teams,
  seasonTopBoardActions,
  seasonId,
  uniqueTournamentId,
  isTopTeamsLoading,
}: Props) => {
  const sortingValueState = useState<TeamsParameter>(TeamsParameter.GoalsScored);
  const [sortingValue, setSortingValue] = sortingValueState;
  const activeTabs = useMemo(() => getInnerTabs({ getAggression, getGoals, getAttack }), [
    seasonId,
  ]);

  const { appNavigate } = useNavigation();

  useEffect(() => {
    seasonTopBoardActions.loadSeasonTopTeams(seasonId, sortingValue);
  }, [sortingValue, seasonId]);

  const handleTabChange = useCallback((tabId: string | number) => {
    setSortingValue(TEAM_DEFAULT_ORDERING[tabId]);
  }, []);

  const handleRowClick = useCallback(
    (teamId: number) => {
      appNavigate(DesktopPage.FootballTeamSummaryDescription, {
        teamId,
        seasonId,
        uniqueTournamentId,
      });
    },
    [seasonId, uniqueTournamentId],
  );

  const handleLinkClick = useCallback(() => {
    appNavigate(DesktopPage.FootballSeasonTeamStatistics, { seasonId, uniqueTournamentId });
  }, [seasonId, uniqueTournamentId]);

  const contentComponentProps = useMemo(
    () => ({
      rows: teams,
      setRowProps: getTeamRowProps({ onClick: handleRowClick }),
      isLoading: isTopTeamsLoading,
    }),
    [handleRowClick, teams, isTopTeamsLoading],
  );

  return (
    !isEmpty(activeTabs) && (
      <SortingValueStateContext.Provider value={sortingValueState}>
        <Tabs
          tabs={activeTabs}
          theme={NavigationTheme.Button}
          onTabChange={handleTabChange}
          contentComponentProps={contentComponentProps}
          isNavigationWidthLimited
        />
        <BlockLink
          label={i18n.value('season.seasonTopBoard.tabs.showAll.teams')}
          isDisabled={isEmpty(teams)}
          onClick={handleLinkClick}
          useHorizontalPadding
        />
      </SortingValueStateContext.Provider>
    )
  );
};

export default connect(TopTeams);
