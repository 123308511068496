exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3fbTuvVQ{max-width:443px;width:100%;margin:0 auto}._3fbTuvVQ ._mn-srBkN{position:relative}._3fbTuvVQ ._mn-srBkN ._32Lfgw6-{-webkit-filter:grayscale(100%);filter:grayscale(100%)}._3fbTuvVQ ._mn-srBkN ._2lAyEsNB{position:absolute;top:0;left:0;width:100%;height:100%}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/team-summary-ui/containers/TeamSummary/components/GameField/styles.scss"],"names":[],"mappings":"AAEA,WAEI,eAAgB,CAChB,UAAW,CAEX,aAAc,CALlB,sBAQM,iBAAkB,CARxB,iCAWQ,8BAAQ,CAAR,sBAAuB,CAX/B,iCAeQ,iBAAkB,CAClB,KAAM,CACN,MAAO,CAEP,UAAW,CACX,WAAY","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .game-field-container {\n    max-width: 443px;\n    width: 100%;\n\n    margin: 0 auto;\n\n    .wrapper {\n      position: relative;\n\n      .no-data-filter {\n        filter: grayscale(100%);\n      }\n\n      .lineup {\n        position: absolute;\n        top: 0;\n        left: 0;\n\n        width: 100%;\n        height: 100%;\n      }\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"game-field-container": "_3fbTuvVQ",
	"gameFieldContainer": "_3fbTuvVQ",
	"wrapper": "_mn-srBkN",
	"no-data-filter": "_32Lfgw6-",
	"noDataFilter": "_32Lfgw6-",
	"lineup": "_2lAyEsNB"
};