import { useEffect, useState } from 'react';

import { BaseTab } from '../types';

const useActiveTab = <T extends BaseTab>(
  tabs: T[],
  defaultActiveTabIndex: number = 0,
): [T | undefined, (tabId: T['id']) => void] => {
  const [activeTab, setActiveTab] = useState<T>();

  useEffect(() => {
    if (tabs) {
      setActiveTab(tabs[defaultActiveTabIndex]);
    }
  }, [tabs, defaultActiveTabIndex]);

  const setActiveTabById = (id) => {
    setActiveTab(tabs.find(({ id: tabId }) => id === tabId));
  };

  return [activeTab, setActiveTabById];
};

export default useActiveTab;
