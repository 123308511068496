import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  isCompact?: boolean;
  isDateShrink?: boolean;
};

const HeaderSkeleton = ({ isCompact = false, isDateShrink = false }: Props) => {
  return (
    <div className={styles.wrapper}>
      <Skeleton marginRight={12} height={14} minWidth={52} width={52} variant="rectangular" />
      <Skeleton
        marginRight={12}
        height={32}
        maxWidth={isDateShrink ? 200 : 230}
        width="100%"
        variant="rectangular"
      />

      {!isCompact ? (
        <>
          <Skeleton
            marginRight={12}
            height={32}
            variant="rectangular"
            width="100%"
            maxWidth={205}
          />
          <Skeleton height={32} variant="rectangular" width="100%" maxWidth={160} />
        </>
      ) : (
        <Skeleton
          marginLeft="auto"
          variant="rectangular"
          minWidth={32}
          minHeight={32}
          height={32}
          widows={32}
        />
      )}
    </div>
  );
};

export default memo(HeaderSkeleton);
