import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import {
  filtersThunks,
  filtersSelectors,
  customFilterThunks,
  customFilterSelectors,
  playersThunks,
  playersSelectors,
  commonThunks,
} from 'packages/season-player-statistics';

const mapStateToProps = (state) => {
  return {
    customFilters: customFilterSelectors.getData(state),
    filters: filtersSelectors.getData(state),
    isFiltersLoading: filtersSelectors.getIsLoading(state),
    playersStatistics: playersSelectors.getData(state),
    isInitialPlayersPageLoading: playersSelectors.getIsInitialPageLoading(state),
    isNewPlayersPageLoading: playersSelectors.getIsNewPageLoading(state),
    limit: playersSelectors.getLimit(state),
    count: playersSelectors.getCount(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    playersActions: bindActionCreators(playersThunks, dispatch),
    commonActions: bindActionCreators(commonThunks, dispatch),
    filtersActions: bindActionCreators(filtersThunks, dispatch),
    customFilterActions: bindActionCreators(customFilterThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
