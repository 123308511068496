import React from 'react';

import { Team } from 'types';
import { Size } from 'packages/shared/components/TeamLabel/types';
import { TeamLabel } from 'packages/shared/components';

type Props = {
  team: Team;
  onTeamClick: (team: Team) => void;
};

const TeamCell = ({ team, onTeamClick }: Props) => (
  <TeamLabel
    onNameClick={onTeamClick}
    onLogoClick={onTeamClick}
    team={team}
    size={Size.Md}
    isLogoFirst
  />
);

export default TeamCell;
