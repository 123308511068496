import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { topPlayersSelectors, seasonTopBoardThunks } from 'packages/season-top-board';

const mapStateToProps = (state) => ({
  players: topPlayersSelectors.getData(state),
  isTopPlayersLoading: topPlayersSelectors.getIsTopPlayersLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  seasonTopBoardActions: bindActionCreators(seasonTopBoardThunks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
