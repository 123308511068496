const calculateJustifyByParent = (
  offsetLeft: number,
  popoverWidth: number,
  parentRect: ClientRect,
) => {
  if (offsetLeft + popoverWidth > parentRect.right) {
    offsetLeft = parentRect.right - popoverWidth;
  } else if (offsetLeft < parentRect.left) {
    offsetLeft = parentRect.left;
  }

  return offsetLeft;
};

export default calculateJustifyByParent;
