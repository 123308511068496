import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';

const defaultState = {
  all: [],
  important: [],
};

const reducerMap: ReducerMap<any, any> = {
  [`${actions.loadEventCommentarySuccess}`]: (state, { payload }) => {
    const { commentaries } = payload;

    return commentaries;
  },
  [`${actions.cleanEventCommentary}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
