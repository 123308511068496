import React, { memo, useCallback, useMemo, RefObject } from 'react';

import { FlexibleTable, TournamentTableRulesBlock } from 'packages/shared/components';
import {
  SeasonTablePromotion,
  SeasonTableRow,
  SeasonTableStatistics,
  TeamComment,
} from 'packages/event-standings/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Team } from 'types';

import { getColumnSchema, getTeamRowProps } from './services';
import styles from './styles.scss';

type Props = {
  rows: SeasonTableRow<SeasonTableStatistics>[];
  groupName: string;
  activeTeamIds: number[];
  activeUniqueTournamentId?: number | string;
  activeSeasonId?: number | string;
  isRulesBlockDisplayed: boolean;
  rule: string;
  promotions: SeasonTablePromotion[];
  teamComments: TeamComment[];
  tableRef?: RefObject<HTMLDivElement>;
};

const SeasonTable = ({
  rows,
  groupName,
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
  isRulesBlockDisplayed,
  rule,
  promotions,
  teamComments,
  tableRef,
}: Props) => {
  const { appNavigate } = useNavigation();

  const handleTeamClick = useCallback(
    (team: Team) => {
      if (activeUniqueTournamentId && activeSeasonId) {
        appNavigate(DesktopPage.FootballTeamSummaryDescription, {
          teamId: team.id,
          uniqueTournamentId: activeUniqueTournamentId,
          seasonId: activeSeasonId,
        });
      } else {
        appNavigate(DesktopPage.FootballTeam, { teamId: team.id });
      }
    },
    [activeUniqueTournamentId, activeSeasonId],
  );

  const rowProps = useMemo(() => getTeamRowProps({ activeTeamIds, onClick: handleTeamClick }), [
    handleTeamClick,
    activeTeamIds,
  ]);

  const columns = useMemo(() => getColumnSchema({ groupName }), [groupName]);

  return (
    <div className={styles.seasonTableWrapper} ref={tableRef}>
      <FlexibleTable<SeasonTableRow<SeasonTableStatistics>>
        className={styles.seasonTable}
        setRowProps={rowProps}
        columnClassName={styles.tableColumn}
        rowClassName={styles.tableRow}
        columns={columns}
        data={rows}
        isRowClickable
      />

      {isRulesBlockDisplayed && (
        <TournamentTableRulesBlock
          rule={rule}
          promotions={promotions}
          teamComments={teamComments}
        />
      )}
    </div>
  );
};

export default memo(SeasonTable);
