import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Team = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton
        marginRight={6}
        width={20}
        height={20}
        minHeight={20}
        minWidth={20}
        variant="circular"
      />
      <Skeleton width="100%" maxWidth={80} height={14} variant="rectangular" />
    </div>
  );
};
export default memo(Team);
