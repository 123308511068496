import React from 'react';

import { Language, LocaleCode } from 'types';
import { NavigationMenu } from 'packages/shared/components';
import {
  NavigationMenuItem,
  NavigationTheme,
} from 'packages/shared/components/NavigationMenu/types';
import { ArrayUtils } from 'packages/utils';

import LanguageLabel from './LanguageLabel';
import styles from './styles.scss';

type Props = {
  languages: Language[];
  localeCode: LocaleCode;
  handleLanguageChange: Function;
};

const LanguageSelect = ({ languages, localeCode, handleLanguageChange }: Props) => {
  const activeLanguage: Language = ArrayUtils.findBy(languages, 'localeCode', localeCode);

  const languageNavigationItems: NavigationMenuItem[] = languages.map((language) => {
    const { name, localeCode } = language;

    return {
      id: localeCode,
      name,
      itemComponent: LanguageLabel,
      itemComponentProps: { language },
    };
  });

  return (
    <div className={styles.languageSelect}>
      <NavigationMenu
        activeId={activeLanguage.localeCode}
        theme={NavigationTheme.Button}
        items={languageNavigationItems}
        onClick={(id) => handleLanguageChange(id)}
      />
    </div>
  );
};

export default LanguageSelect;
