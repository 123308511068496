import getState from './getState';

export const getData = (state) => {
  return getState(state)?.data;
};

export const getEventDetails = (state) => {
  return getData(state)?.details;
};

export const getEventTimeLines = (state) => {
  return getData(state)?.timeLines;
};
