import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadTopPlayersStart = createAction(`${NAMESPACE}LOAD_TOP_PLAYERS_START`);
export const loadTopPlayersSuccess = createAction(`${NAMESPACE}LOAD_TOP_PLAYERS_SUCCESS`);
export const loadTopPlayersFailure = createAction(`${NAMESPACE}LOAD_TOP_PLAYERS_FAILURE`);

export const loadCompactTopPlayersStart = createAction(
  `${NAMESPACE}LOAD_COMPACT_TOP_PLAYERS_START`,
);
export const loadCompactTopPlayersSuccess = createAction(
  `${NAMESPACE}LOAD_COMPACT_TOP_PLAYERS_SUCCESS`,
);
export const loadCompactTopPlayersFailure = createAction(
  `${NAMESPACE}LOAD_COMPACT_TOP_PLAYERS_FAILURE`,
);

export const loadInitialTopPlayersStart = createAction(
  `${NAMESPACE}LOAD_INITIAL_TOP_PLAYERS_START`,
);
export const loadInitialTopPlayersSuccess = createAction(
  `${NAMESPACE}LOAD_INITIAL_TOP_PLAYERS_SUCCESS`,
);
export const loadInitialTopPlayersFailure = createAction(
  `${NAMESPACE}LOAD_INITIAL_TOP_PLAYERS_FAILURE`,
);
