import i18n from 'i18n-smart';

import {
  Interfaces as PlayerDetailsInterfaces,
  Enums as PlayerDetailsEnums,
} from 'packages/player-summary/types';
import { TabView } from 'packages/shared/components/Tabs/types';

const mapStatisticsGroupsToTab = (
  statistics: PlayerDetailsInterfaces.Statistics.Statistics.Data,
  isLoading?: boolean,
): TabView[] => {
  return [
    {
      id: PlayerDetailsEnums.Statistics.Statistics.StatType.All,
      name: i18n.value(
        `player.summary.statistics.statType.${PlayerDetailsEnums.Statistics.Statistics.StatType.All}`,
      ),
      contentComponentProps: {
        statistics: statistics?.[PlayerDetailsEnums.Statistics.Statistics.StatType.All],
      },
      isLoading,
      isDisabled: !statistics?.[PlayerDetailsEnums.Statistics.Statistics.StatType.All],
      isDefault: true,
    },
    {
      id: PlayerDetailsEnums.Statistics.Statistics.StatType.PerGame,
      name: i18n.value(
        `player.summary.statistics.statType.${PlayerDetailsEnums.Statistics.Statistics.StatType.PerGame}`,
      ),
      isLoading,
      contentComponentProps: {
        statistics: statistics?.[PlayerDetailsEnums.Statistics.Statistics.StatType.PerGame],
      },
      isDisabled: !statistics?.[PlayerDetailsEnums.Statistics.Statistics.StatType.PerGame],
    },
    {
      id: PlayerDetailsEnums.Statistics.Statistics.StatType.PerNinetyMinutes,
      name: i18n.value(
        `player.summary.statistics.statType.${PlayerDetailsEnums.Statistics.Statistics.StatType.PerNinetyMinutes}`,
      ),
      isLoading,
      contentComponentProps: {
        statistics:
          statistics?.[PlayerDetailsEnums.Statistics.Statistics.StatType.PerNinetyMinutes],
      },
      isDisabled: !statistics?.[PlayerDetailsEnums.Statistics.Statistics.StatType.PerNinetyMinutes],
    },
  ];
};

export default mapStatisticsGroupsToTab;
