import { createElement } from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import {
  CommonTableStatistics,
  SeasonTableRow,
  StatisticsGroup,
} from 'packages/event-standings/types';

import { FormCell, TeamCell } from '../components';

type Options = {
  groupName: string;
  statisticsGroup: StatisticsGroup;
};

const getColumnSchema = ({
  groupName,
  statisticsGroup,
}: Options): Column<SeasonTableRow<CommonTableStatistics>>[] => {
  return [
    {
      id: 'position',
      Header: groupName,
      headerStyle: { textAlign: 'left', paddingLeft: 16 },
      accessor: ({ statistics, team }) => ({
        position: statistics[statisticsGroup].position,
        team,
      }),
      Cell: ({ value: props }) => createElement(TeamCell, { ...props }),
      width: 170,
    },
    {
      id: 'form',
      Header: i18n.value('eventSummary.form.formTitle'),
      accessor: ({ form }) => ({
        form: form[statisticsGroup],
      }),
      Cell: ({ value: props }) => createElement(FormCell, props),
      headerStyle: { maxWidth: 116 },
      cellStyle: { maxWidth: 116 },
      minWidth: 150,
    },
  ];
};

export default getColumnSchema;
