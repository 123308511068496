import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Tournament = () => (
  <div className={styles.wrapper}>
    <Skeleton
      minWidth={18}
      minHeight={18}
      width={18}
      height={18}
      marginRight={8}
      variant="circular"
    />
    <Skeleton height={14} width="100%" variant="rectangular" />
  </div>
);

export default memo(Tournament);
