import React, { useEffect, useMemo, memo } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import { Stage } from 'packages/event-standings/types';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { Data } from 'packages/team-active-tournament-season/types';
import { TimeZone } from 'types';

import { getTournamentSeasonSelectItems } from './services';
import StandingsView from './views';
import connect from './connect';

type Props = {
  seasonStandingsActions: ActionCreatorsMapObject;
  seasonStagesActions: ActionCreatorsMapObject;
  tournaments?: SeasonsExtendedTournament[];
  activeTournamentSeasons: Data;
  isStagesLoading: boolean;
  stages: Stage[] | null;
  activeStageId?: number;
  timeZone: TimeZone;
};

const extendStandings = (Standings: typeof StandingsView[keyof typeof StandingsView]) =>
  memo(
    ({
      stages,
      activeStageId,
      isStagesLoading,
      seasonStagesActions,
      seasonStandingsActions,
      timeZone,
      activeTournamentSeasons,
      tournaments = [],
    }: Props) => {
      const { teamId = '', tournamentId, seasonId } = useParams();
      const { tournamentId: activeTournamentId, seasonId: activeSeasonId } =
        activeTournamentSeasons[teamId] || {};

      useEffect(() => {
        if (seasonId) {
          seasonStagesActions.loadStages({ seasonId, teamId: parseInt(teamId || '') });
        }
      }, [seasonId, teamId, timeZone.value]);

      useEffect(() => {
        return () => {
          seasonStandingsActions.cleanSeasonStandings();
        };
      }, []);

      const tournamentSeasonSelectItems = useMemo(
        () =>
          getTournamentSeasonSelectItems(
            tournaments,
            parseInt(tournamentId || ''),
            parseInt(seasonId || ''),
          ),
        [tournaments, tournamentId, seasonId],
      );

      return (
        <Standings
          activeStageId={activeStageId}
          tournamentSeasonSelectItems={tournamentSeasonSelectItems}
          isLoading={isStagesLoading}
          activeTournamentId={activeTournamentId}
          activeSeasonId={activeSeasonId}
          tournamentId={tournamentId}
          tournaments={tournaments}
          teamId={teamId}
          stages={stages}
          seasonId={seasonId}
        />
      );
    },
  );

export default {
  Desktop: connect(extendStandings(StandingsView.Desktop)),
  DesktopNarrow: connect(extendStandings(StandingsView.DesktopNarrow)),
  Tablet: connect(extendStandings(StandingsView.Tablet)),
  TabletNarrow: connect(extendStandings(StandingsView.TabletNarrow)),
  Mobile: connect(extendStandings(StandingsView.Mobile)),
};
