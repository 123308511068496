import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Ordering, SquadOrdering } from 'packages/rest/api/TeamSquad/types';
import { OrderType } from 'types';

import styles from './styles.scss';

type Props = {
  orderingValue: Ordering;
  ordering: SquadOrdering;
  label: string;
  onHeaderCellClick: (ordering: Ordering) => void;
};

const SortableHeader = ({ orderingValue, ordering, onHeaderCellClick, label }: Props) => {
  const { name, type } = ordering;

  const handleHeaderCellClick = () => {
    onHeaderCellClick(orderingValue);
  };

  const isOrderingActive = name === orderingValue;

  return (
    <div
      className={classnames(styles.parameterHeader, {
        [styles.ascending]: isOrderingActive && type === OrderType.Ascending,
        [styles.descending]: isOrderingActive && type === OrderType.Descending,
      })}
      onClick={handleHeaderCellClick}
    >
      {i18n.value(label)}
    </div>
  );
};
export default memo(SortableHeader);
