import { SocketConfig, SocketStatus } from '../../types';

abstract class BaseSocket<ItemType = any> {
  protected status: SocketStatus;
  protected readonly config: SocketConfig<ItemType>;

  constructor (config: SocketConfig<ItemType>) {
    this.config = config;
    this.status = SocketStatus.Closed;
  }

  public connect() {
    const { onConnect, onError } = this.config;

    try {
      if (this.status === SocketStatus.Open) {
        throw new Error('connection already exists');
      } else {
        this.subscribe();
        this.status = SocketStatus.Open;

        onConnect?.(this.status);
      }
    } catch (error) {
      onError?.(`EventsSocket connection error: ${error.message}`);
    }
  }

  public close() {
    const { onClose, onError } = this.config;

    try {
      if (this.status === SocketStatus.Open) {
        this.unsubscribe();
        this.status = SocketStatus.Closed;

        onClose?.(this.status);
      } else {
        throw new Error('EventsSocket: connection not exists');
      }
    } catch (error) {
      onError?.(`EventsSocket close error: ${error.message}`);
    }
  }

  abstract subscribe();
  abstract unsubscribe();
}

export default BaseSocket;
