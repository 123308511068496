import React, { useMemo } from 'react';
import classnames from 'classnames';

import { StatisticsGroup } from './components';
import styles from './styles.scss';
import { Statistics } from './types';

type Props = {
  statistics: Statistics;
  single?: boolean;
  isColumnDirectionActive?: boolean;
};

const StatisticsBlock = ({
  statistics,
  single = false,
  isColumnDirectionActive = false,
}: Props) => {
  const [firstStatisticsGroup, secondStatisticsGroup, thirdStatisticsGroup] = statistics;
  const wholeStatistics = useMemo(() => statistics.flat(), [statistics]);

  return (
    <div
      className={classnames(styles.statisticsContainer, {
        [styles.columnDirection]: isColumnDirectionActive,
      })}
    >
      {single ? (
        <StatisticsGroup
          statisticsGroup={wholeStatistics}
          className={styles.statisticsGroupContainer}
        />
      ) : (
        <>
          <StatisticsGroup
            statisticsGroup={firstStatisticsGroup}
            className={styles.statisticsGroupContainer}
          />
          <StatisticsGroup
            statisticsGroup={secondStatisticsGroup}
            className={styles.statisticsGroupContainer}
          />
          <StatisticsGroup
            statisticsGroup={thirdStatisticsGroup}
            className={styles.statisticsGroupContainer}
          />
        </>
      )}
    </div>
  );
};

export default StatisticsBlock;
