import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadStadiumsPageStart = createAction(`${NAMESPACE}LOAD_STADIUMS_PAGE_START`);
export const loadStadiumsPageSuccess = createAction(`${NAMESPACE}LOAD_STADIUMS_PAGE_SUCCESS`);
export const loadStadiumsPageFailure = createAction(`${NAMESPACE}LOAD_STADIUMS_PAGE_FAILURE`);

export const loadInitialStadiumsStart = createAction(`${NAMESPACE}LOAD_INITIAL_STADIUMS_START`);
export const loadInitialStadiumsSuccess = createAction(`${NAMESPACE}LOAD_INITIAL_STADIUMS_SUCCESS`);
export const loadInitialStadiumsFailure = createAction(`${NAMESPACE}LOAD_INITIAL_STADIUMS_FAILURE`);

export const updatePaginationSuccess = createAction(`${NAMESPACE}UPDATE_PAGINATION_SUCCESS`);

export const cleanStadiums = createAction(`${NAMESPACE}CLEAN_STADIUMS`);
