import React, { HTMLAttributes, memo } from 'react';
import isEmpty from 'is-empty-typed/lib';
import { Column, Row } from 'react-table';

import { BlockLink } from 'packages/shared/components';

import { TopTable } from '../../../../../../components';
import styles from './styles.scss';

type Props<T extends object> = {
  title: string;
  linkLabel: string;
  handleLinkClick: () => void;
  rows: T[];
  columns: Column<T>[];
  setRowProps: (row: Row<T>) => HTMLAttributes<HTMLTableRowElement>;
};

const MiniTopTable = <T extends object>({
  rows,
  columns,
  title,
  linkLabel,
  handleLinkClick,
  setRowProps,
}: Props<T>) => {
  return (
    <div className={styles.miniTopTable}>
      <div className={styles.titleBlock}>
        <div className={styles.title}>{title}</div>
        <BlockLink label={linkLabel} onClick={handleLinkClick} isDisabled={isEmpty(rows)} />
      </div>

      <TopTable<T> setRowProps={setRowProps} columns={columns} rows={rows} />
    </div>
  );
};

export default memo(MiniTopTable) as typeof MiniTopTable;
