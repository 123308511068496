import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton width={75} height={16} variant="rectangular" />
      <div className={styles.descriptionLine}>
        <Skeleton width={50} height={15} variant="rectangular" marginRight={8} />
        <Skeleton width={16} height={15} variant="rectangular" marginRight={8} />
        <Skeleton width={150} height={15} variant="rectangular" />
      </div>
      <div className={styles.descriptionLine}>
        <Skeleton width={55} height={15} variant="rectangular" marginRight={8} />
        <Skeleton width={36} height={15} variant="rectangular" />
      </div>
      <div className={styles.descriptionLine}>
        <Skeleton width={120} height={15} variant="rectangular" marginRight={8} />
        <Skeleton width={150} height={15} variant="rectangular" />
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
