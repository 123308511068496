import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Relegation = () => {
  return (
    <div className={styles.relegation}>
      <Skeleton variant="circular" />
      <Skeleton variant="circular" />
      <Skeleton variant="circular" />
    </div>
  );
};

export default memo(Relegation);
