exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3xK8CEr9{display:grid;grid-row-gap:16px;grid-column-gap:11px;grid-template-columns:repeat(auto-fill, minmax(274px, 1fr))}._1Ok2A9Fb{max-height:200px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/season-player-statistics-ui/containers/PlayerStatistics/views/Mobile/components/GeneralFilter/styles.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,iBAAkB,CAClB,oBAAqB,CACrB,2DAA4D,CALhE,WASI,gBAAiB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .general-filter-group {\n    display: grid;\n    grid-row-gap: 16px;\n    grid-column-gap: 11px;\n    grid-template-columns: repeat(auto-fill, minmax(274px, 1fr));\n  }\n\n  .search-list-items {\n    max-height: 200px;\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"general-filter-group": "_3xK8CEr9",
	"generalFilterGroup": "_3xK8CEr9",
	"search-list-items": "_1Ok2A9Fb",
	"searchListItems": "_1Ok2A9Fb"
};