exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._3L2Mwjtt{flex:1 1;margin-right:16px}._2ATFfecf{width:443px}._UE5UzayV{flex:1 1;display:flex;align-items:flex-start;justify-content:center}._3obJMP1N{flex:1 1}._3obJMP1N ._2_1cxTgO{height:100%}._3obJMP1N ._2MO4bfvi{margin-bottom:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktop/football/TeamSummary/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,WAAY,CAPhB,WAWI,QAAO,CACP,YAAa,CACb,sBAAuB,CACvB,sBAAuB,CAd3B,WAkBI,QAAO,CAlBX,sBAqBM,WAAY,CArBlB,sBAyBM,kBC1BO","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n  }\n\n  .right-bar {\n    flex: 1;\n\n    .event-card {\n      height: 100%;\n    }\n\n    .advertisement {\n      margin-bottom: $margin;\n    }\n  }\n}","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_3L2Mwjtt",
	"leftColumn": "_3L2Mwjtt",
	"right-column": "_2ATFfecf",
	"rightColumn": "_2ATFfecf",
	"left-bar": "_UE5UzayV",
	"leftBar": "_UE5UzayV",
	"right-bar": "_3obJMP1N",
	"rightBar": "_3obJMP1N",
	"event-card": "_2_1cxTgO",
	"eventCard": "_2_1cxTgO",
	"advertisement": "_2MO4bfvi"
};