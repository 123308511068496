import { connect } from 'react-redux';
import { appSelectors } from 'modules/app';

const mapStateToProps = (state) => {
  return {
    mediaScreen: appSelectors.getMediaScreen(state),
  };
};

export default connect(mapStateToProps);
