import { DATE_FORMATS } from 'packages/date-az/consts';
import DateService from 'packages/date-az';

import { ColumnType, Range, RangeFilterState, RangeType, StatType } from '../types';
import { RANGE_FILTER_PARAMETER_BY_STAT_TYPE } from '../constants';

const formatRangeFilters = (rangeFilters: RangeFilterState, ages: Range, statType: StatType) => {
  const minBirthDate = ages.from
    ? DateService.getDateByYearOffset(parseInt(ages.from)).toFormat(DATE_FORMATS.DEFAULT)
    : undefined;

  const maxBirthDate = ages.to
    ? DateService.getDateByYearOffset(parseInt(ages.to)).toFormat(DATE_FORMATS.DEFAULT)
    : undefined;

  return {
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinGoals]]:
      rangeFilters[ColumnType.Goals]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxGoals]]:
      rangeFilters[ColumnType.Goals]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinAssists]]:
      rangeFilters[ColumnType.Assists]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxAssists]]:
      rangeFilters[ColumnType.Assists]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinGoalsAndAssists]]:
      rangeFilters[ColumnType.GoalsAndAssists]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxGoalsAndAssists]]:
      rangeFilters[ColumnType.GoalsAndAssists]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinHeadedGoals]]:
      rangeFilters[ColumnType.HeadedGoals]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxHeadedGoals]]:
      rangeFilters[ColumnType.HeadedGoals]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinPenaltyGoals]]:
      rangeFilters[ColumnType.PenaltyGoals]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxPenaltyGoals]]:
      rangeFilters[ColumnType.PenaltyGoals]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShots]]:
      rangeFilters[ColumnType.Shots]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShots]]:
      rangeFilters[ColumnType.Shots]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShotsOffGoal]]:
      rangeFilters[ColumnType.ShotsOffGoal]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShotsOffGoal]]:
      rangeFilters[ColumnType.ShotsOffGoal]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinShotsOnGoal]]:
      rangeFilters[ColumnType.ShotsOnGoal]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxShotsOnGoal]]:
      rangeFilters[ColumnType.ShotsOnGoal]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinGoalConversation]]:
      rangeFilters[ColumnType.GoalConversion]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxGoalConversation]]:
      rangeFilters[ColumnType.GoalConversion]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinOffside]]:
      rangeFilters[ColumnType.Offside]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxOffside]]:
      rangeFilters[ColumnType.Offside]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinYellowCards]]:
      rangeFilters[ColumnType.YellowCards]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxYellowCards]]:
      rangeFilters[ColumnType.YellowCards]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinYellowRedCards]]:
      rangeFilters[ColumnType.YellowRedCards]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxYellowRedCards]]:
      rangeFilters[ColumnType.YellowRedCards]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinRedCards]]:
      rangeFilters[ColumnType.RedCards]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxRedCards]]:
      rangeFilters[ColumnType.RedCards]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinCleanSheets]]:
      rangeFilters[ColumnType.CleanSheets]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxCleanSheets]]:
      rangeFilters[ColumnType.CleanSheets]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinGamesPlayed]]:
      rangeFilters[ColumnType.GamesPlayed]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxGamesPlayed]]:
      rangeFilters[ColumnType.GamesPlayed]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinMinutesPlayed]]:
      rangeFilters[ColumnType.MinutesPlayed]?.from,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxMinutesPlayed]]:
      rangeFilters[ColumnType.MinutesPlayed]?.to,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MinDateOfBirth]]: maxBirthDate,
    [RANGE_FILTER_PARAMETER_BY_STAT_TYPE[statType][RangeType.MaxDateOfBirth]]: minBirthDate,
  };
};

export default formatRangeFilters;
