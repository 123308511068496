import React from 'react';

import { Division } from 'packages/season-newcomers/types';
import { Team } from 'types';
import { isEmpty } from 'packages/utils/Object.utils';
import { Separator } from 'packages/shared/components';
import { Type } from 'packages/shared/components/Separator/types';

import { Divisions, Newcomers } from './components';
import { NewcomersTitle } from './types';
import styles from './styles.scss';

type Props = {
  newcomers: Team[];
  lowerDivisions: Division[];
  higherDivisions: Division[];
  onTeamClick: (team: Team) => void;
};

const NewcomersDetails = ({ newcomers, higherDivisions, lowerDivisions, onTeamClick }: Props) => {
  return (
    <div className={styles.newcomersDetailsContainer}>
      {!isEmpty(newcomers) && (
        <>
          <Newcomers onTeamClick={onTeamClick} newcomers={newcomers} />
          <Separator type={Type.Border} />
        </>
      )}
      {!isEmpty(higherDivisions) && (
        <>
          <Divisions type={NewcomersTitle.HigherDivision} newcomers={higherDivisions} />
          <Separator type={Type.Border} />
        </>
      )}
      {!isEmpty(lowerDivisions) && (
        <>
          <Divisions type={NewcomersTitle.LowerDivision} newcomers={lowerDivisions} />
          <Separator type={Type.Border} />
        </>
      )}
    </div>
  );
};

export default React.memo(NewcomersDetails);
