import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { initialEventsActions, moreEventsActions, commonActions } from '../../actions';
import { EventsStatus } from '../../types';
import { DEFAULT_EVENTS_LIMIT } from '../../constants';

const defaultState: EventsStatus = {
  isEventsLoading: true,
  isMoreEventsLoading: false,
  count: 0,
  offset: 0,
  limit: DEFAULT_EVENTS_LIMIT,
};

const reducerMap: ReducerMap<EventsStatus, Partial<EventsStatus>> = {
  [`${initialEventsActions.loadInitialEventsStart}`]: (state) => {
    return { ...state, isEventsLoading: true };
  },

  [`${combineActions(
    initialEventsActions.loadInitialEventsFailure,
    initialEventsActions.loadInitialEventsSuccess,
  )}`]: (state) => {
    return { ...state, isEventsLoading: false };
  },

  [`${moreEventsActions.loadFilteredEventsStart}`]: (state) => {
    return {
      ...state,
      isMoreEventsLoading: true,
    };
  },

  [`${combineActions(
    moreEventsActions.loadFilteredEventsFailure,
    moreEventsActions.loadFilteredEventsSuccess,
  )}`]: (state) => {
    return { ...state, isMoreEventsLoading: false };
  },

  [`${commonActions.updateCountSuccess}`]: (state, { payload }) => {
    const { count = 0 } = payload;

    return {
      ...state,
      count,
    };
  },

  [`${commonActions.updatePaginationSuccess}`]: (state, { payload }) => {
    const { offset = 0, limit = 0 } = payload;

    return {
      ...state,
      offset,
      limit,
    };
  },

  [`${commonActions.clearSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
