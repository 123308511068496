import { toCamelCase } from 'packages/utils/Object.utils';

import { Player } from '../types';

const mapSquad = (squad: object[]): Player[] => {
  return squad.map(mapPlayer);
};

const mapPlayer = (squadPlayer: unknown): Player => {
  const { player, startDate, jerseyNumber } = toCamelCase(squadPlayer as object, true);
  const {
    id,
    name,
    positionName,
    height,
    weight,
    preferredFoot,
    marketValue,
    logo,
    country,
    dateOfBirth,
  } = player;

  return {
    id,
    name,
    logo,
    dateOfBirth: dateOfBirth || undefined,
    number: jerseyNumber || undefined,
    positionName: positionName || undefined,
    height: height || undefined,
    weight: weight || undefined,
    preferredFoot: preferredFoot || undefined,
    marketValue: marketValue || undefined,
    startDate: startDate || undefined,
    country: country || undefined,
  };
};

export default mapSquad;
