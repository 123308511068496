import { CSSProperties } from 'react';
import { ColumnInstance, HeaderGroup } from 'react-table';

import { FixedColumn } from '../types';

type StickyStyle<D extends object> = {
  column: HeaderGroup<D> | ColumnInstance<D>;
  columnInstance: ColumnInstance<D>[];
  accumulator: Map<string, FixedColumn>;
};

type StaticPositionParams<D extends object> = {
  anchorElement: ColumnInstance<D>;
  accumulator: Map<string, FixedColumn>;
  column: HeaderGroup<D> | ColumnInstance<D>;
  accumulatorKey: string;
};

type StickyPositionParams<D extends object> = {
  anchorElement: ColumnInstance<D>;
  stickyColumn?: FixedColumn;
  accumulator: Map<string, FixedColumn>;
  accumulatorKey: string;
};

const getStickyStyle = <D extends object>({
  column,
  columnInstance,
  accumulator,
}: StickyStyle<D>): Partial<CSSProperties> => {
  const { stickyFor } = column;

  if (stickyFor) {
    const anchorElement = columnInstance.find(({ id }) => {
      return id === stickyFor;
    });

    if (!anchorElement) {
      return {};
    }

    if (anchorElement.stickyFor) {
      const stickyColumn = accumulator.get(anchorElement.stickyFor);

      return getStickyPosition({
        anchorElement,
        stickyColumn,
        accumulator,
        accumulatorKey: stickyFor,
      });
    } else {
      return getStaticPosition({
        anchorElement,
        column,
        accumulator,
        accumulatorKey: stickyFor,
      });
    }
  }

  return {};
};

const getStaticPosition = <D extends object>({
  anchorElement,
  column,
  accumulator,
  accumulatorKey,
}: StaticPositionParams<D>) => {
  const offset = anchorElement.totalLeft + anchorElement.totalWidth;

  if (!accumulator.get(accumulatorKey)) {
    accumulator.set(accumulatorKey, {
      leftOffset: offset,
      totalWidth: column.totalWidth,
    });
  }

  return getStyle(offset);
};

const getStickyPosition = <D extends object>({
  anchorElement,
  stickyColumn,
  accumulator,
  accumulatorKey,
}: StickyPositionParams<D>) => {
  if (!stickyColumn) {
    return {};
  }

  const { totalWidth, leftOffset } = stickyColumn;
  const offset = totalWidth + leftOffset;

  if (!accumulator.get(accumulatorKey)) {
    accumulator.set(accumulatorKey, {
      leftOffset: offset,
      totalWidth: anchorElement.totalWidth,
    });
  }

  return getStyle(offset);
};

const getStyle = (left: number): CSSProperties => ({
  left,
  position: 'sticky',
  zIndex: 2,
});

export default getStickyStyle;
