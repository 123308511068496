import { eventTimeLineThunks } from 'packages/event-time-line';

import { cleanEventSummarySuccess } from '../actions';

const cleanEventSummary = () => (dispatch) => {
  dispatch(eventTimeLineThunks.cleanEventTimeline());
  dispatch(cleanEventSummarySuccess());
};

export default cleanEventSummary;
