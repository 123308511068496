import React, { useMemo, memo } from 'react';

import { FlexibleTable } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';

import styles from './styles.scss';
import { TournamentRulesBlockSkeleton } from '../../../../components';
import { getSkeletonTableSchema } from './services';

const ELEMENTS_COUNT = 20;

const Skeleton = () => {
  const elements = ArrayUtils.getArrayFromNumber(ELEMENTS_COUNT);
  const tableSchema = useMemo(getSkeletonTableSchema, []);

  return (
    <>
      <FlexibleTable
        data={elements}
        columns={tableSchema}
        columnClassName={styles.tableColumn}
        className={styles.narrowStatisticsTable}
        isHeaderSticky
        isRowClickable
      />
      <TournamentRulesBlockSkeleton />
    </>
  );
};

export default memo(Skeleton);
