const flushFilterClause = (filterClause: object) => {
  return Object.entries(filterClause).reduce((acc, [key, value]) => {
    if (value !== undefined) {
      acc[key] = value;
    }

    return acc;
  }, {});
};

export default flushFilterClause;
