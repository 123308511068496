import { statisticsActions } from '../../../actions';

interface Params {
  selectedTournamentId?: string;
  selectedSeasonId?: string;
}

const updateFilters = (newFilters: Params) => (dispatch) => {
  dispatch(statisticsActions.mobileFilter.updateMobileFiltersSuccess(newFilters));
};

export default updateFilters;
