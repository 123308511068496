import React from 'react';
import isEmpty from 'is-empty-typed/lib';
import classnames from 'classnames';

import { BlockLink, Select } from 'packages/shared/components';
import { SelectItem } from 'packages/shared/components/Select/types';

import { ThemedNavigationMenuProps } from '../../types';
import { getSelectItems } from './services';
import styles from './styles.scss';

const DropdownNavigationMenu = ({
  items,
  activeId,
  onClick,
  linkLabel,
  onLinkClick = () => {},
  className,
  isDisabled,
  label,
}: ThemedNavigationMenuProps) => {
  const { selectItems, activeItem } = getSelectItems(items, activeId);

  const onSelectItem = (item: SelectItem) => {
    onClick(item.id);
  };

  return (
    <div className={classnames(styles.navigationContainer, className)}>
      <div className={styles.dropdownContainer}>
        {!!label && <div className={styles.label}>{label}</div>}
        {selectItems.length > 1 && (
          <div className={styles.dropdown}>
            <Select
              items={selectItems}
              selectedItem={activeItem || selectItems[0]}
              isDisabled={isDisabled}
              onChange={onSelectItem}
            />
          </div>
        )}
      </div>
      {!isEmpty(linkLabel) && <BlockLink label={linkLabel} onClick={onLinkClick} />}
    </div>
  );
};

export default React.memo(DropdownNavigationMenu);
