export enum DesktopNarrowPage {
  Home = 'home',
  Football = 'football',
  FootballCategory = 'footballCategory',
  NotFound = 'notFound',

  FootballSeason = 'footballSeason',
  FootballSeasonHome = 'footballSeasonHome',
  FootballSeasonSummary = 'footballSeasonSummary',
  FootballSeasonGames = 'footballSeasonGames',
  FootballSeasonStandings = 'footballSeasonStandings',
  FootballSeasonTeamStatistics = 'footballSeasonTeamStatistics',
  FootballSeasonPlayerStatistics = 'footballSeasonPlayerStatistics',
  FootballSeasonStadiums = 'footballSeasonStadiums',
  FootballSeasonHistory = 'footballSeasonHistory',
  FootballSeasonComingSoon = 'footballSeasonComingSoon',

  FootballTeam = 'footballTeam',
  FootballTeamHome = 'footballTeamHome',
  FootballTeamSummary = 'footballTeamSummary',
  FootballTeamSummaryDescription = 'footballTeamSummaryDescription',
  FootballTeamStandings = 'footballTeamStandings',
  FootballTeamPlayerStatistics = 'footballTeamPlayerStatistics',
  FootballTeamGames = 'footballTeamGames',
  FootballTeamSquad = 'footballTeamSquad',

  FootballPlayer = 'footballPlayer',
  FootballPlayerHome = 'footballPlayerHome',
  FootballPlayerSummary = 'footballPlayerSummary',
  FootballPlayerGames = 'footballPlayerGames',

  FootballStadium = 'footballStadium',
  FootballStadiumHome = 'footballStadiumHome',
  FootballStadiumSummary = 'footballStadiumSummary',

  FootballMatch = 'footballMatch',
}
