import React, { FunctionComponent, MemoExoticComponent, memo } from 'react';

import { Team } from 'types';

import EmptyCell from '../EmptyCell';

export type Props = {
  team?: Team;
  onTeamClick: (team: Team) => void;
  teamComponent: MemoExoticComponent<
    FunctionComponent<Required<Pick<Props, 'team' | 'onTeamClick'>>>
  >;
};

const TeamCell = ({ team, onTeamClick, teamComponent: TeamComponent }: Props) => {
  return team ? <TeamComponent team={team} onTeamClick={onTeamClick} /> : <EmptyCell />;
};

export default memo(TeamCell);
