const calculatePositionFromHorizontalEdges = (popoverWidth: number, offsetLeft: number) => {
  if (offsetLeft > 0) {
    const isPopoverIntersectsVerticalScreenEdges = offsetLeft + popoverWidth > window.innerWidth;

    if (isPopoverIntersectsVerticalScreenEdges) {
      return window.innerWidth - popoverWidth;
    } else {
      return offsetLeft;
    }
  } else {
    return 0;
  }
};

export default calculatePositionFromHorizontalEdges;
