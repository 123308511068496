import { lazy } from 'react';

import Player from './Player';
import PlayerSummary from './PlayerSummary';
import PlayerGames from './PlayerGames';
import Category from './Category';
import Season from './Season';
import SeasonGames from './SeasonGames';
import SeasonStadiums from './SeasonStadiums';
import SeasonHistory from './SeasonHistory';
import SeasonPlayerStatistics from './SeasonPlayerStatistics';
import SeasonTeamStatistics from './SeasonTeamStatistics';
import SeasonStandings from './SeasonStandings';
import SeasonSummary from './SeasonSummary';
import Team from './Team';
import TeamGames from './TeamGames';
import TeamPlayers from './TeamPlayers';
import TeamSummary from './TeamSummary';
import TeamStandings from './TeamStandings';
import TeamSquad from './TeamSquad';
import Stadium from './Stadium';
import StadiumSummary from './StadiumSummary';
import StadiumHome from './StadiumHome';
import Categories from './Categories';
import Favorites from './Favorites';
import Settings from './Settings';
import Match from './Match';

const Home = lazy(() => import('./Home'));

export {
  Category,
  Player,
  PlayerSummary,
  PlayerGames,
  Home,
  SeasonGames,
  Season,
  SeasonStadiums,
  SeasonHistory,
  SeasonPlayerStatistics,
  SeasonTeamStatistics,
  SeasonStandings,
  SeasonSummary,
  TeamPlayers,
  TeamGames,
  TeamSummary,
  TeamStandings,
  TeamSquad,
  Team,
  Stadium,
  StadiumSummary,
  StadiumHome,
  Categories,
  Favorites,
  Settings,
  Match,
};
