import React, { memo, useEffect, useRef } from 'react';
import i18n from 'i18n-smart';

import styles from './styles.scss';
import { getYearsByOffset } from '../../services';
import { YearItem } from '../../types';

type Props = {
  yearOffset: number;
  onYearClick?: (year: YearItem) => void;
  closePopover?: () => void;
};

const YearsRange = ({ yearOffset, onYearClick, closePopover }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const yearItems = getYearsByOffset(yearOffset);

  useEffect(() => {
    containerRef?.current?.addEventListener('wheel', handleHorizontalScroll);

    return () => {
      containerRef?.current?.removeEventListener('wheel', handleHorizontalScroll);
    };
  }, []);

  const handleHorizontalScroll = (event: WheelEvent) => {
    event.preventDefault();

    if (containerRef?.current) {
      containerRef.current.scrollLeft += event.deltaY;
    }
  };

  const getYearClickHandler = (year: YearItem) => {
    return () => {
      onYearClick?.(year);
      closePopover?.();
    };
  };

  return (
    <div className={styles.rangeWrapper}>
      <div className={styles.label}>{i18n.value('calendar.yearSelector.title')}</div>
      <div ref={containerRef} className={styles.yearsBlock}>
        {yearItems.map((yearItem) => (
          <span onClick={getYearClickHandler(yearItem)} key={yearItem.year} className={styles.year}>
            {yearItem.year}
          </span>
        ))}
      </div>
    </div>
  );
};

export default memo(YearsRange);
