import React, { memo } from 'react';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { TeamStatistics } from 'packages/season-team-statistics-ui';
import { Advertisement } from 'packages/shared/components';
import { FeaturedOdds } from 'packages/odds-ui';

import styles from './styles.scss';

const SeasonTeamStatistics = () => {
  return (
    <Grid.DoubleColumn
      isSecondaryOpen={false}
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
    >
      <div key={GridColumnId.LeftBar} className={styles.leftBar}>
        <TeamStatistics.Desktop />
      </div>

      <div key={GridColumnId.Primary} className={styles.sideBar}>
        <Advertisement />
        <FeaturedOdds.Desktop />
      </div>
    </Grid.DoubleColumn>
  );
};

export default memo(SeasonTeamStatistics);
