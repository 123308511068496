import React from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const Team = () => {
  return (
    <div className={styles.container}>
      <Skeleton marginBottom={8} width={40} height={40} variant="circular" />
      <Skeleton width={71} height={8} variant="rectangular" />
    </div>
  );
};

export default Team;
