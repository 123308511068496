import React, { memo, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { TournamentChipList } from 'packages/shared/components';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { getSearchQuery } from 'router/services';
import { MobilePage, SearchQuery } from 'router/types';
import { useNavigation } from 'packages/hooks';
import { MediaScreen } from 'types';

import connect from './connect';

type Props = {
  tournaments?: SeasonsExtendedTournament[];
  className?: string;
};

const TeamTournamentChipList = ({ tournaments, className }: Props) => {
  const { appNavigate } = useNavigation(MediaScreen.Mobile);
  const { teamId } = useParams();
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  const handleTournamentClick = useCallback(
    (tournament: SeasonsExtendedTournament) => {
      const [firstTournamentSeason] = tournament.seasons;
      const query = getSearchQuery({ [SearchQuery.EventCardId]: eventId });

      if (teamId) {
        appNavigate(
          MobilePage.FootballTeamSummaryDescription,
          {
            uniqueTournamentId: tournament.id,
            seasonId: firstTournamentSeason.id,
            teamId,
          },
          query,
        );
      }
    },
    [teamId, eventId],
  );

  return (
    <TournamentChipList.Mobile
      tournaments={tournaments}
      onTournamentClick={handleTournamentClick}
      className={className}
    />
  );
};

export default connect(memo(TeamTournamentChipList));
