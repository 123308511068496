import { connect } from 'react-redux';

import { actualGamesSelectors } from 'packages/stadium-summary';

const mapStateToProps = (state) => {
  return {
    tournaments: actualGamesSelectors.getData(state),
    isLoading: actualGamesSelectors.getIsLoading(state),
  };
};

export default connect(mapStateToProps);
