import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../../actions';
import { CompactTopPlayersStatus } from '../../types';

const defaultState: CompactTopPlayersStatus = {
  isLoading: true,
};

const reducerMap: ReducerMap<CompactTopPlayersStatus, any> = {
  [`${actions.loadCompactTopPlayersStart}`]: (state) => {
    return { ...state, isLoading: true };
  },
  [`${combineActions(
    actions.loadCompactTopPlayersSuccess,
    actions.loadCompactTopPlayersFailure,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${actions.cleanTopBoardSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<CompactTopPlayersStatus>(reducerMap, defaultState);
