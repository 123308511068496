import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState = {
  isEventSummaryLoading: true,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${actions.loadEventSummaryStart}`]: () => {
    return { isEventSummaryLoading: true };
  },
  [`${combineActions(actions.loadEventSummarySuccess, actions.loadEventSummaryFailure)}`]: () => {
    return { isEventSummaryLoading: false };
  },
  [`${actions.cleanEventSummarySuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
