import React, { memo } from 'react';
import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed';

import { LoaderContainer } from 'packages/shared/components';
import { Tournament } from 'packages/events-ui/containers/EventsTabs/components';
import { Event } from 'packages/events-ui';
import { TournamentView } from 'packages/events/types';

import styles from './styles.scss';

const eventComponentProps = {
  isDateVisible: true,
  isRoundVisible: true,
  isPlayerStatsShown: true,
};

type Props = {
  events: TournamentView[];
  isLoading: boolean;
  onEventClick: (eventId: number) => void;
  useModalOnCategoryClick?: boolean;
};

const EventList = ({ events, onEventClick, isLoading, useModalOnCategoryClick = false }: Props) => {
  return (
    <div className={styles.listContainer}>
      <LoaderContainer className={styles.listLoaderContainer} isLoading={isLoading}>
        {isEmpty(events) ? (
          <div className={styles.notFound}>{i18n.value('team.teamEvents.notFound')}</div>
        ) : (
          events.map((tournament, index) => {
            const key = `${tournament.id}${index}`;

            return (
              <Tournament
                useModalOnCategoryClick={useModalOnCategoryClick}
                key={key}
                tournament={tournament}
                eventComponent={Event.Mobile}
                handleEventClick={onEventClick}
                eventComponentProps={eventComponentProps}
                isUniqueTournamentNameDisplayed
              />
            );
          })
        )}
      </LoaderContainer>
    </div>
  );
};
export default memo(EventList);
