import { EventStandings, SeasonStandings } from 'packages/rest';
import { mapStandings } from 'packages/event-standings/services';

import { stagesActions } from '../../actions';

const loadStages = (seasonId: number) => async (dispatch) => {
  try {
    dispatch(stagesActions.loadStagesStart());

    const [
      {
        data: { results: seasonTablesStagesResponse },
      },
      {
        data: { results: seasonBracketsResponse },
      },
    ] = await Promise.all([
      SeasonStandings.loadSeasonTables({ seasonId }),
      EventStandings.loadEventBrackets({ seasonId }),
    ]);

    const mappedStages = mapStandings({
      seasonTablesStages: seasonTablesStagesResponse,
      tournamentBrackets: seasonBracketsResponse,
    });

    dispatch(stagesActions.loadStagesSuccess(mappedStages));
  } catch (e) {
    dispatch(stagesActions.loadStagesFailure(e));
  }
};

export default loadStages;
