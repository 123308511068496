import * as teamDetailsActions from './actions';
import * as teamDetailsSelectors from './selectors';
import * as teamDetailsConstants from './constants';
import * as teamDetailsThunks from './thunks';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  teamDetailsActions,
  teamDetailsConstants,
  teamDetailsThunks,
  teamDetailsSelectors,
  createReducer,
};
