import React from 'react';

import { Icon } from 'packages/shared/components';
import { Language } from 'types';
import { COUNTRY_ICON_BY_LOCALE_CODE } from 'appConstants';

import styles from './styles.scss';

type Props = {
  language: Language;
};

const LanguageLabel = ({ language }: Props) => {
  const { name, localeCode } = language;

  return (
    <div className={styles.languageLabel}>
      <Icon className={styles.flag} id={COUNTRY_ICON_BY_LOCALE_CODE[localeCode]} />
      <div className={styles.name}>{name}</div>
    </div>
  );
};

export default LanguageLabel;
