import React from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { ReactComponent as Logo } from './images/logo.svg';
import { ReactComponent as Lines } from './images/lines.svg';
import styles from './styles.scss';

type Props = {
  className?: string;
  logoClassName?: string;
  isLogoHidden?: boolean;
};

const AppLogo = ({ className, logoClassName, isLogoHidden = false }: Props) => {
  return (
    <div className={classnames(styles.appLogo, className)}>
      {!isLogoHidden && (
        <Link to="/" className={classnames(styles.logo, logoClassName)}>
          <Logo className={styles.image} />
        </Link>
      )}
      <Lines className={styles.lines} />
    </div>
  );
};

export default React.memo(AppLogo);
