import React, { memo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { TeamSummary, TeamDescription } from 'packages/team-summary-ui';
import { EventDetails } from 'packages/event-details-ui';
import { SearchQuery } from 'router/types';
import { Advertisement } from 'packages/shared/components';

import styles from './styles.scss';

const TeamSummaryPage = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);

  return (
    <Grid.SingleColumn isSecondaryOpen={!!eventId}>
      <div key={GridColumnId.Primary} className={styles.primary}>
        <TeamSummary.Mobile />
        <TeamDescription.Mobile className={styles.description} />
        <Advertisement />
      </div>

      <div className={styles.popup} key={GridColumnId.Secondary}>
        <EventDetails.Mobile eventId={eventId} />
      </div>
    </Grid.SingleColumn>
  );
};

export default memo(TeamSummaryPage);
