import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './styles.scss';

const EventsCounter = ({ liveCount, eventsCount, className }) => {
  const containerClassName = classnames(styles.container, className);

  return (
    <div className={containerClassName}>
      {!!liveCount && <span className={styles.liveCount}>{liveCount}</span>}
      {!!liveCount && !!eventsCount && <span className={styles.eventsCount}> / </span>}
      {!!eventsCount && <span className={styles.eventsCount}>{eventsCount}</span>}
    </div>
  );
};

EventsCounter.defaultProps = {
  liveCount: 0,
  eventsCount: 0,
  className: '',
};

EventsCounter.propTypes = {
  liveCount: PropTypes.number,
  eventsCount: PropTypes.number,
  className: PropTypes.string,
};

export default EventsCounter;
