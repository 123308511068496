import { toCamelCase } from 'packages/utils/Object.utils';

import { TEAM_DEFAULT_LOGO } from 'appConstants';
import { TopTeam } from '../types';

export const mapTopOfTheSeasonTeams = (seasonTopTeams: any[]): TopTeam[] => {
  return seasonTopTeams.map((player) => mapTopOfTheSeasonTeam(player));
};

const mapTopOfTheSeasonTeam = (seasonTopTeam: { team; statistics }): TopTeam => {
  const { team, statistics } = seasonTopTeam;
  const { id, logo, name } = toCamelCase(team);

  const {
    ballPossession,
    goalAttempts,
    goalsScored,
    shotsOnTarget,
    yellowCards,
    yellowredCards,
    redCards,
    goalsByHead,
    matchesPlayed,
  } = toCamelCase(statistics);

  return {
    id,
    team: {
      id,
      logo: logo || TEAM_DEFAULT_LOGO,
      name,
    },
    statistics: {
      goalsScored: goalsScored?.total || 0,
      goalsByHead: goalsByHead?.total || 0,
      goalAttempts: goalAttempts?.average || 0,
      shotsOnGoal: shotsOnTarget?.total || 0,
      ballPossession: ballPossession?.average || 0,
      redCards: redCards?.total || 0,
      yellowCards: yellowCards?.total || 0,
      yellowRedCards: yellowredCards?.total || 0,
      gamesPlayed: matchesPlayed?.total || 0,
    },
  };
};
