import { ReactElement } from 'react';

export enum SelectTheme {
  White = 'white',
  Transparent = 'transparent',
  Gray = 'gray',
}

export interface SelectItem {
  id: number | string;
  label: string | ReactElement;
  componentProps?: any;
}

export interface SearchSelectItem extends SelectItem {
  value: string;
}

export enum SelectSize {
  Sm = 'sm',
  Md = 'md',
  Lg = 'lg',
  ExtraLg = 'extra-lg',
  FullWidth = 'full-width',
}
