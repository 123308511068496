import {
  AdditionalSportEventData,
  SportEvent,
  SportEventStatus,
} from 'packages/event-standings/types';
import { HOME_QUALIFIER } from 'packages/events/constants';

const mapSportEvents = (
  sportEvents: any[],
  additionalData: AdditionalSportEventData,
): SportEvent[] => {
  return sportEvents.map(({ event }) => {
    const { id, scheduled, eventStatus } = event;
    const { homeTeam, awayTeam } = getSportEventCompetitors(event, additionalData);

    return {
      id,
      scheduled,
      homeTeam,
      awayTeam,
      status: mapSportEventStatus(eventStatus),
    };
  });
};

const getSportEventCompetitors = (sportEvent: any, additionalData: AdditionalSportEventData) => {
  const { firstMatchUpTeam, secondMatchUpTeam } = additionalData;

  const { eventStatus, competitors } = sportEvent;
  const { homeScore, awayScore } = mapSportEventStatus(eventStatus);
  const firstEventTeam = competitors[0];
  let homeTeam;
  let awayTeam;

  if (firstEventTeam?.qualifier === HOME_QUALIFIER) {
    if (firstEventTeam?.id === firstMatchUpTeam?.id) {
      homeTeam = { ...firstMatchUpTeam, score: homeScore };
      awayTeam = { ...secondMatchUpTeam, score: awayScore };
    } else {
      homeTeam = { ...secondMatchUpTeam, score: homeScore };
      awayTeam = { ...firstMatchUpTeam, score: awayScore };
    }
  } else {
    if (firstEventTeam?.id === firstMatchUpTeam?.id) {
      homeTeam = { ...secondMatchUpTeam, score: homeScore };
      awayTeam = { ...firstMatchUpTeam, score: awayScore };
    } else {
      homeTeam = { ...firstMatchUpTeam, score: homeScore };
      awayTeam = { ...secondMatchUpTeam, score: awayScore };
    }
  }

  return {
    homeTeam,
    awayTeam,
  };
};

const mapSportEventStatus = (eventStatus: any): SportEventStatus => {
  const { winner: winnerId, homeScore, awayScore, periodScores, status } = eventStatus;

  return {
    homeScore,
    awayScore,
    winnerId,
    periodScores,
    status,
  };
};

export default mapSportEvents;
