import React, { useRef } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { reorder } from 'packages/utils/Array.utils';
import { Column } from 'packages/season-team-statistics/types';

import { DraggableButtonListContainer } from './components';
import styles from './styles.scss';

type Props = {
  onColumnPositionChange: (columns: Column[]) => void;
  columns: Column[];
  className?: string;
};

const ColumnsArrangement = ({ onColumnPositionChange, columns, className }: Props) => {
  const containerRef = useRef(null);

  const getHelperDimensions = ({ node }) => {
    return node.getBoundingClientRect();
  };

  const handleSortEnd = ({ oldIndex, newIndex }) => {
    const reorderedColumns = reorder(columns, oldIndex, newIndex);

    onColumnPositionChange(reorderedColumns);
  };

  return (
    <div ref={containerRef} className={classnames(styles.columnsArrangementContainer, className)}>
      <div className={styles.title}>
        {i18n.value('season.teamStatistics.customFilter.columnConfiguration.columnsArrangement')}
      </div>
      <DraggableButtonListContainer
        getHelperDimensions={getHelperDimensions}
        helperClass={styles.dndHelper}
        helperContainer={() => containerRef.current || document.body}
        onSortEnd={handleSortEnd}
        columns={columns}
        axis="xy"
      />
    </div>
  );
};

export default ColumnsArrangement;
