import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import * as Common from 'pages/common';
import { Football } from 'pages/tabletNarrow';

import { TABLET_NARROW_PATH } from '../../constants';
import { TabletNarrowPage } from '../../types';

const {
  ROOT,
  SEASON_CHILDREN,
  PLAYER_CHILDREN,
  STADIUM_CHILDREN,
  TEAM_CHILDREN,
} = TABLET_NARROW_PATH;

const FOOTBALL_SEASON_ROUTES: RouteObject[] = [
  {
    path: SEASON_CHILDREN[TabletNarrowPage.FootballSeasonHome],
    element: <Navigate to={SEASON_CHILDREN[TabletNarrowPage.FootballSeasonSummary]} replace />,
  },
  {
    path: SEASON_CHILDREN[TabletNarrowPage.FootballSeasonSummary],
    element: <Football.SeasonSummary />,
  },
  {
    path: SEASON_CHILDREN[TabletNarrowPage.FootballSeasonGames],
    element: <Football.SeasonGames />,
  },
  {
    path: SEASON_CHILDREN[TabletNarrowPage.FootballSeasonHistory],
    element: <Football.SeasonHistory />,
  },
  {
    path: SEASON_CHILDREN[TabletNarrowPage.FootballSeasonStadiums],
    element: <Football.SeasonStadiums />,
  },
  {
    path: SEASON_CHILDREN[TabletNarrowPage.FootballSeasonTeamStatistics],
    element: <Football.SeasonTeamStatistics />,
  },
  {
    path: SEASON_CHILDREN[TabletNarrowPage.FootballSeasonPlayerStatistics],
    element: <Football.SeasonPlayerStatistics />,
  },
  {
    path: SEASON_CHILDREN[TabletNarrowPage.FootballSeasonStandings],
    element: <Football.SeasonStandings />,
  },
  {
    path: SEASON_CHILDREN[TabletNarrowPage.FootballSeasonComingSoon],
    element: <Common.ComingSoon />,
  },
];

const FOOTBALL_TEAM_ROUTES: RouteObject[] = [
  {
    path: TEAM_CHILDREN[TabletNarrowPage.FootballTeamHome],
    element: <Navigate to={TEAM_CHILDREN[TabletNarrowPage.FootballTeamSummary]} replace />,
  },
  { path: TEAM_CHILDREN[TabletNarrowPage.FootballTeamSummary], element: <Football.TeamSummary /> },
  {
    path: TEAM_CHILDREN[TabletNarrowPage.FootballTeamSummaryDescription],
    element: <Football.TeamSummary />,
  },
  {
    path: TEAM_CHILDREN[TabletNarrowPage.FootballTeamStandings],
    element: <Football.TeamStandings />,
  },
  { path: TEAM_CHILDREN[TabletNarrowPage.FootballTeamGames], element: <Football.TeamGames /> },
  { path: TEAM_CHILDREN[TabletNarrowPage.FootballTeamSquad], element: <Football.TeamSquad /> },
  {
    path: TEAM_CHILDREN[TabletNarrowPage.FootballTeamPlayerStatistics],
    element: <Football.TeamPlayers />,
  },
];

const FOOTBALL_STADIUM_ROUTES: RouteObject[] = [
  {
    path: STADIUM_CHILDREN[TabletNarrowPage.FootballStadiumHome],
    element: <Football.StadiumHome />,
  },
  {
    path: STADIUM_CHILDREN[TabletNarrowPage.FootballStadiumSummary],
    element: <Football.StadiumSummary />,
  },
];

const FOOTBALL_PLAYER_ROUTES: RouteObject[] = [
  {
    path: PLAYER_CHILDREN[TabletNarrowPage.FootballPlayerHome],
    element: <Navigate to={PLAYER_CHILDREN[TabletNarrowPage.FootballPlayerSummary]} replace />,
  },
  {
    path: PLAYER_CHILDREN[TabletNarrowPage.FootballPlayerSummary],
    element: <Football.PlayerSummary />,
  },
  {
    path: PLAYER_CHILDREN[TabletNarrowPage.FootballPlayerGames],
    element: <Football.PlayerGames />,
  },
];

const ROOT_ROUTES: RouteObject[] = [
  {
    path: ROOT[TabletNarrowPage.Home],
    children: [
      {
        path: ROOT[TabletNarrowPage.Home],
        element: <Navigate to={ROOT[TabletNarrowPage.Football]} replace />,
      },
      { path: ROOT[TabletNarrowPage.Football], element: <Football.Home /> },
      { path: ROOT[TabletNarrowPage.FootballFavorites], element: <Football.Favorites /> },
      { path: ROOT[TabletNarrowPage.FootballCategories], element: <Football.Categories /> },
      { path: ROOT[TabletNarrowPage.FootballCategory], element: <Football.Category /> },
      {
        path: ROOT[TabletNarrowPage.FootballTeam],
        element: <Football.Team />,
        children: FOOTBALL_TEAM_ROUTES,
      },
      {
        path: ROOT[TabletNarrowPage.FootballPlayer],
        element: <Football.Player />,
        children: FOOTBALL_PLAYER_ROUTES,
      },
      {
        path: ROOT[TabletNarrowPage.FootballSeason],
        element: <Football.Season />,
        children: FOOTBALL_SEASON_ROUTES,
      },
      {
        path: ROOT[TabletNarrowPage.FootballStadium],
        element: <Football.Stadium />,
        children: FOOTBALL_STADIUM_ROUTES,
      },
      {
        path: ROOT[TabletNarrowPage.FootballMatch],
        element: <Football.Match />,
      },
    ],
  },
  { path: ROOT[TabletNarrowPage.Settings], element: <Football.Settings /> },
  { path: ROOT[TabletNarrowPage.NotFound], element: <Common.NotFound /> },
];

const Router = () => {
  return useRoutes(ROOT_ROUTES);
};

export default Router;
