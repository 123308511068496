import React, { memo, useMemo } from 'react';

import { Skeleton } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';

import styles from './styles.scss';

type Props = {
  leaguesCount?: number;
};

const DEFAULT_LEAGUES_COUNT = 5;

const LoadingSkeleton = ({ leaguesCount = DEFAULT_LEAGUES_COUNT }: Props) => {
  const leagues = useMemo(() => ArrayUtils.getArrayFromNumber(leaguesCount), [leaguesCount]);

  return (
    <div className={styles.container}>
      {leagues.map((league) => (
        <div className={styles.league} key={league}>
          <Skeleton variant="rectangular" width={130} height={12} />
          <Skeleton variant="rectangular" width={14} height={13} />
        </div>
      ))}
    </div>
  );
};

export default memo(LoadingSkeleton);
