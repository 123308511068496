import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { eventStatsThunks, eventStatsSelectors, eventStatsActions } from 'packages/event-stats';
import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => {
  return {
    activeEvent: eventDetailsSelectors.getActiveEvent(state),
    details: eventStatsSelectors.getEventDetails(state),
    timeLines: eventStatsSelectors.getEventTimeLines(state),
    isLoading: eventStatsSelectors.getIsEventStatsLoading(state),
    teams: eventDetailsSelectors.getActiveEventTeams(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    eventStatsActions: bindActionCreators(eventStatsThunks, dispatch),
    cleanEventStats: () => dispatch(eventStatsActions.cleanEventStats()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
