import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Event } from 'types';

import * as actions from '../../actions';

const defaultState: Event[] = [];

const reducerMap: ReducerMap<Event[], { events: Event[] }> = {
  [`${combineActions(actions.loadInitialEventsSuccess, actions.loadMoreEventsSuccess)}`]: (
    state,
    { payload: { events } },
  ) => {
    return events;
  },

  [`${actions.updateLiveEvents}`]: (state, { payload: { events } }) => {
    return state.map((event) => events[event.id] || event);
  },
  [`${actions.clearSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
