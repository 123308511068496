import { Event, EventPlayerStatistics } from 'types';
import { toCamelCase } from 'packages/utils/Object.utils';
import { mapEvent, groupEventsByDateAndTournament } from 'packages/events/services';
import { mapForm } from 'packages/event-summary/services/mappers/mapEventSummary';
import { TournamentView } from 'packages/events/types';

const mapPlayerEvents = (events: Record<string, unknown>[]): TournamentView[] => {
  const validEvents = events.filter((event) => Boolean(event?.event_status));

  return groupEventsByDateAndTournament(validEvents.map(mapGames));
};

const mapGames = (event: Record<string, unknown>): Event => {
  const { id, value, competitors, scheduled, eventStatus, playerStats } = toCamelCase(event);
  const mappedEvent = mapEvent(event);

  return {
    ...mappedEvent,
    playerStats: playerStats ? mapPlayerStatistics(playerStats) : undefined,
    form: value ? mapForm([{ id, value, competitors, scheduled, eventStatus }]) : undefined,
  };
};

const mapPlayerStatistics = (
  statistic: Record<string, number | undefined>,
): EventPlayerStatistics => {
  const { redCards, yellowCards, yellowRedCards, ...params } = toCamelCase(statistic, true);

  return {
    ...params,
    cards: {
      red: redCards || 0,
      yellow: yellowCards || 0,
      yellowRed: yellowRedCards || 0,
    },
  };
};

export default mapPlayerEvents;
