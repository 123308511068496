import React, { memo } from 'react';

import { Competitor } from 'types';
import { YearsGroupedEvents } from 'packages/event-head-to-head/types';
import { Separator } from 'packages/shared/components';

import { TeamMatch } from './components';
import styles from './styles.scss';

type Props = {
  yearsGroupedEvents: YearsGroupedEvents;
  onEventClick: (eventId: number) => void;
  currentYear: string;
  team?: Competitor;
};

const TeamMatches = ({ yearsGroupedEvents, onEventClick, currentYear, team }: Props) => {
  return (
    <>
      {yearsGroupedEvents.map(([year, events]) => (
        <div key={year}>
          {year !== currentYear && <div className={styles.yearSeparator}>{year}</div>}
          {events.map((event, index) => (
            <React.Fragment key={event.id}>
              {index === 0 && <Separator />}
              <TeamMatch
                onEventClick={() => onEventClick(event.id)}
                event={event}
                selectedTeam={team}
              />
              <Separator />
            </React.Fragment>
          ))}
        </div>
      ))}
    </>
  );
};

export default memo(TeamMatches);
