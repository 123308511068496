import React, { memo, ReactElement, RefObject } from 'react';

import { PopoverInvokeTrigger } from '../../types';
import { POPOVER_COMPONENT_TYPE } from './constants';

type Props = {
  openPopover: () => void;
  closePopover: () => void;
  popoverElement: JSX.Element;
  invokeTrigger: PopoverInvokeTrigger;
  preserveOnTargetInteraction?: boolean;
  isOpen: boolean;
  generatePopoverComponent: Function;
  children?: ReactElement;
  isModal?: boolean;
  useBackdrop?: boolean;
  popoverRef: RefObject<HTMLElement> | null;
};

const Popover = ({
  openPopover,
  closePopover,
  popoverElement,
  invokeTrigger,
  isOpen,
  isModal,
  useBackdrop,
  generatePopoverComponent,
  children,
  popoverRef,
  preserveOnTargetInteraction,
}: Props) => {
  const PopoverComponent = POPOVER_COMPONENT_TYPE[invokeTrigger];

  return (
    <PopoverComponent
      generatePopoverComponent={generatePopoverComponent}
      openPopover={openPopover}
      closePopover={closePopover}
      popoverElement={popoverElement}
      isOpen={isOpen}
      isModal={isModal}
      useBackdrop={useBackdrop}
      popoverRef={popoverRef}
      preserveOnTargetInteraction={preserveOnTargetInteraction}
    >
      {children}
    </PopoverComponent>
  );
};

export default memo(Popover);
