import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton width={30} height={16} variant="rectangular" />
      <div className={styles.jerseyGrid}>
        <div className={styles.cell}>
          <Skeleton width={88} height={93} variant="rectangular" />
          <Skeleton width={40} height={16} variant="rectangular" marginTop={2} />
        </div>
        <div className={styles.cell}>
          <Skeleton width={88} height={93} variant="rectangular" />
          <Skeleton width={30} height={16} variant="rectangular" marginTop={2} />
        </div>
        <div className={styles.cell}>
          <Skeleton width={88} height={93} variant="rectangular" />
          <Skeleton width={110} height={16} variant="rectangular" marginTop={2} />
        </div>
        <div className={styles.cell}>
          <Skeleton width={88} height={93} variant="rectangular" />
          <Skeleton width={75} height={16} variant="rectangular" marginTop={2} />
        </div>
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
