import React, { ReactElement } from 'react';

import { GridColumnId } from 'types';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';
import { FlexiblePopover } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';

import styles from './styles.scss';

type Props = {
  children: ReactElement[];
  isSecondaryOpen: boolean;
};

const SingleColumn = ({ children, isSecondaryOpen }: Props) => {
  const PrimaryComponent: ReactElement = ArrayUtils.findBy(children, 'key', GridColumnId.Primary);
  const SecondaryComponent: ReactElement = ArrayUtils.findBy(
    children,
    'key',
    GridColumnId.Secondary,
  );

  return (
    <div className={styles.container}>
      <div className={styles.column}>{PrimaryComponent}</div>

      <FlexiblePopover
        popoverComponent={() => <>{SecondaryComponent}</>}
        justify={PopoverJustify.CenterStart}
        align={PopoverAlign.Top}
        isOpen={isSecondaryOpen}
        disableBodyOverflow
        isModal
      />
    </div>
  );
};

export default SingleColumn;
