import * as eventsConstants from './constants';
import * as eventsServices from './services';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export { createReducer, eventsConstants, eventsServices };

export * from './thunks';
export * from './selectors';
export * from './actions';
