import { createElement } from 'react';
import i18n from 'i18n-smart';
import { Column } from 'react-table';

import { Team } from 'types';
import { EventSummaryRow } from 'packages/event-summary/types';

import TeamCell from '../components/TeamCell';
import FormCell from '../components/FormCell';

type Options = {
  onTeamClick: (team: Team) => void;
};

export const getColumnSchema = ({ onTeamClick }: Options): Column<EventSummaryRow>[] => [
  {
    id: 'team',
    Header: i18n.value('eventSummary.form.teamTitle'),
    headerStyle: { textAlign: 'left' },
    accessor: 'team',
    Cell: ({ value: team }) => createElement(TeamCell, { team, onTeamClick }),
    minWidth: 110,
  },
  {
    id: 'from',
    Header: i18n.value('eventSummary.form.formTitle'),
    headerStyle: { textAlign: 'left' },
    accessor: 'form',
    Cell: ({ value: form }) => createElement(FormCell, { form }),
    minWidth: 130,
    width: 0,
    maxWidth: 0,
  },
];
