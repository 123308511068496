exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._G8QSpDWB{flex:1 1;margin-right:16px}._1eK25RJ1{flex:1 1;display:flex;align-items:flex-start}._3WMQXVsA{position:relative;display:flex;flex-direction:column;height:100%;width:100%}._5B_6EB0Q{width:443px}._5B_6EB0Q ._BSqJQBxJ{margin-top:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktopNarrow/football/PlayerSummary/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,QAAO,CACP,YAAa,CACb,sBAAuB,CAT3B,WAaI,iBAAkB,CAClB,YAAa,CACb,qBAAsB,CACtB,WAAY,CACZ,UAAW,CAjBf,WAqBI,WAAY,CArBhB,sBAwBM,eCzBO","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    align-items: flex-start;\n  }\n\n  .side-bar {\n    position: relative;\n    display: flex;\n    flex-direction: column;\n    height: 100%;\n    width: 100%;\n  }\n\n  .right-column {\n    width: 443px;\n\n    .advertisement {\n      margin-top: $margin;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_G8QSpDWB",
	"leftColumn": "_G8QSpDWB",
	"left-bar": "_1eK25RJ1",
	"leftBar": "_1eK25RJ1",
	"side-bar": "_3WMQXVsA",
	"sideBar": "_3WMQXVsA",
	"right-column": "_5B_6EB0Q",
	"rightColumn": "_5B_6EB0Q",
	"advertisement": "_BSqJQBxJ"
};