import Dexie from 'dexie';

import hooks from '../hooks';

const initializeHooks = (dexieInstance: Dexie) => {
  for (const hook of hooks) {
    hook.call(null, dexieInstance);
  }
};

export default initializeHooks;
