import i18n from 'i18n-smart';

import { TabView } from 'packages/shared/components/Tabs/types';
import { CommentaryGroups } from 'packages/event-commentary/types';
import { Event } from 'types';

import { Commentaries } from '../components';

export interface AdditionalTabProps {
  activeEvent: Event;
}

const getTabsData = (
  commentaryGroups: CommentaryGroups,
  additionalProps: AdditionalTabProps,
): TabView[] => {
  const { important, all } = commentaryGroups;
  const { isLive, isFinished } = additionalProps.activeEvent;
  const isMatchNotStarted = !isLive && !isFinished;

  return [
    {
      id: 'eventCommentary.allCommentsTab',
      name: i18n.value('eventCommentary.allCommentsTab'),
      contentComponentProps: {
        commentaries: all,
        ...additionalProps,
      },
      contentComponent: Commentaries,
    },
    {
      id: 'eventCommentary.importantCommentsTab',
      name: i18n.value('eventCommentary.importantCommentsTab'),
      isDisabled: isMatchNotStarted,
      contentComponentProps: {
        commentaries: important,
        ...additionalProps,
      },
      contentComponent: Commentaries,
    },
  ];
};

export default getTabsData;
