import { toCamelCase } from 'packages/utils/Object.utils';

import { TopVenue } from '../types';

export const mapSeasonTopVenues = (topVenues: Record<string, any>[]): TopVenue[] => {
  return topVenues.map((venue) => topVenue(venue));
};

const topVenue = (topVenue: Record<string, any>): TopVenue => {
  const { id, venueId, name, average = 0, capacity = 0, occupancy = 0 } = toCamelCase(topVenue);

  return {
    id,
    venue: {
      id: venueId,
      name,
    },
    average,
    capacity,
    occupancy,
  };
};
