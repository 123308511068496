import React, { memo, RefObject, useEffect } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { LoaderContainer } from 'packages/shared/components';
import { ComponentLike } from 'types';

import { SearchSelectItem } from '../../../../types';
import { ItemListControls, SelectItems } from '../../../../components';
import styles from './styles.scss';

type Props<T extends SearchSelectItem> = {
  itemsContainerClass?: string;
  searchValue: string;
  isSearching: boolean;
  selectedItems: T[];
  items: T[];
  itemComponent?: ComponentLike;
  containerRef?: RefObject<HTMLDivElement>;
  onItemClick: (item: T) => void;
  onSelectAll: () => void;
  onClear: () => void;
  resetSelectData: () => void;
};

const PopoverItemList = <T extends SearchSelectItem>({
  itemsContainerClass,
  onItemClick,
  selectedItems,
  searchValue,
  items,
  itemComponent,
  isSearching,
  onSelectAll,
  containerRef,
  resetSelectData,
  onClear,
}: Props<T>) => {
  useEffect(() => {
    return resetSelectData;
  }, []);

  return (
    <SelectItems
      containerRef={containerRef}
      className={classnames(styles.selectItemsContainer, itemsContainerClass)}
      isCheckShown
      onItemClick={onItemClick}
      selectedItems={selectedItems}
      items={items}
      itemComponent={itemComponent}
    >
      <LoaderContainer
        spinnerClassName={styles.spinner}
        className={styles.preloader}
        isLoading={isSearching}
      />
      {items.length ? (
        <ItemListControls
          isClearButtonDisabled={!selectedItems.length}
          isSelectAllButtonDisabled={!!searchValue}
          onClear={onClear}
          onSelectAll={onSelectAll}
        />
      ) : (
        <div className={styles.emptyOptions}>{i18n.value('common.emptyText')}</div>
      )}
    </SelectItems>
  );
};

export default memo(PopoverItemList);
