import React from 'react';
import classnames from 'classnames';

import { PROMOTION_COLOR_GROUPS } from 'appConstants';

import styles from './styles.scss';

type Props = {
  value: string | number;
  cssClass?: string;
};

const TournamentTablePositionCell = ({ value, cssClass }: Props) => (
  <div
    className={classnames(
      styles.position,
      cssClass && styles[`promotion${PROMOTION_COLOR_GROUPS[cssClass]}`],
    )}
  >
    {value}
  </div>
);

export default TournamentTablePositionCell;
