import * as eventTimeLineActions from './actions';
import * as eventTimeLineThunks from './thunks';
import * as eventTimeLineConstants from './constants';
import * as eventTimeLineSelectors from './selectors';
import * as eventTimeLineServices from './services';

import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  eventTimeLineActions,
  eventTimeLineThunks,
  eventTimeLineConstants,
  eventTimeLineSelectors,
  eventTimeLineServices,
  createReducer,
};
