import { isEmpty } from 'packages/utils/Object.utils';
import { DataReducerConfig } from '../../types';

abstract class BaseUpdatesSelector<ItemType> {
  protected abstract data: any;

  protected version: number;
  protected readonly config: DataReducerConfig<ItemType> | undefined;

  constructor(config?: DataReducerConfig<ItemType>) {
    this.config = config;
    this.version = 0;
  }

  protected incrementVersion(): BaseUpdatesSelector<ItemType> {
    this.version++;

    return this;
  }

  protected handleNewData(data: any) {
    !isEmpty(data) && this.config?.handleNewData?.(this.buildMessageBody(data));
  }

  protected handleUpdatedData(data: any) {
    !isEmpty(data) && this.config?.handleUpdatedData?.(this.buildMessageBody(data));
  }

  protected handleRemovedData(data: any) {
    !isEmpty(data) && this.config?.handleRemovedData?.(this.buildMessageBody(data));
  }

  protected buildMessageBody(data: any) {
    return {
      data,
      version: this.version,
    };
  }

  protected abstract handleDataUpdate(data: any);
}

export default BaseUpdatesSelector;
