import React from 'react';
import { Column } from 'react-table';

import { Skeleton } from 'packages/shared/components';

import { TeamCellSkeleton } from '../../../../../components';

const centerAlignStyle = {
  display: 'flex',
  justifyContent: 'center',
};

const getSkeletonTableSchema = (): Column[] => {
  return [
    {
      id: 'position',
      Header: <Skeleton width={80} height={12} variant="rectangular" />,
      headerStyle: { textAlign: 'left', padding: `10px 8px 10px 16px` },
      Cell: TeamCellSkeleton,
      width: 170,
    },
    {
      id: 'gamesPlayed',
      Header: <Skeleton width={20} height={12} variant="rectangular" />,
      headerStyle: { ...centerAlignStyle, padding: `10px 0` },
      cellStyle: centerAlignStyle,
      Cell: <Skeleton width={20} height={14} variant="rectangular" />,
      width: 20,
    },
    {
      id: 'win',
      Header: <Skeleton width={20} height={12} variant="rectangular" />,
      headerStyle: { ...centerAlignStyle, padding: `10px 0` },
      cellStyle: centerAlignStyle,
      Cell: <Skeleton width={20} height={14} variant="rectangular" />,
      width: 20,
    },
    {
      id: 'draw',
      Header: <Skeleton width={20} height={12} variant="rectangular" />,
      headerStyle: { ...centerAlignStyle, padding: `10px 0` },
      cellStyle: centerAlignStyle,
      Cell: <Skeleton width={20} height={14} variant="rectangular" />,
      width: 20,
    },
    {
      id: 'loss',
      Header: <Skeleton width={20} height={12} variant="rectangular" />,
      headerStyle: { ...centerAlignStyle, padding: `10px 0` },
      cellStyle: centerAlignStyle,
      Cell: <Skeleton width={20} height={14} variant="rectangular" />,
      width: 20,
    },
    {
      id: 'statistics',
      Header: <Skeleton width={20} height={12} variant="rectangular" />,
      headerStyle: { ...centerAlignStyle, padding: `10px 0` },
      cellStyle: centerAlignStyle,
      Cell: <Skeleton width={40} height={14} variant="rectangular" />,
      width: 35,
    },
    {
      id: 'goalDifference',
      Header: <Skeleton width={20} height={12} variant="rectangular" />,
      headerStyle: { ...centerAlignStyle, padding: `10px 0` },
      cellStyle: centerAlignStyle,
      Cell: <Skeleton width={20} height={14} variant="rectangular" />,
      width: 25,
    },
    {
      id: 'points',
      Header: <Skeleton width={25} height={12} variant="rectangular" />,
      headerStyle: { ...centerAlignStyle, padding: `10px 16px 10px 0` },
      cellStyle: centerAlignStyle,
      Cell: <Skeleton width={20} height={14} variant="rectangular" />,
      width: 20,
    },
  ];
};

export default getSkeletonTableSchema;
