import React, { useMemo, memo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { FlexibleTable, Skeleton } from 'packages/shared/components';

import { getTableSchema } from './services';
import styles from './styles.scss';

type Props = {
  elementsCount: number;
};

const TableSkeleton = ({ elementsCount }: Props) => {
  const tableColumns = useMemo(() => getTableSchema(), []);
  const rows = ArrayUtils.getArrayFromNumber(elementsCount);

  return (
    <>
      <div className={styles.header}>
        <Skeleton height={16} width={180} variant="rectangular" />
      </div>

      <FlexibleTable columns={tableColumns} data={rows} isRowClickable />
    </>
  );
};

export default memo(TableSkeleton);
