import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadTopPlayersStart,
  loadTopPlayersSuccess,
  loadTopPlayersFailure,
  loadInitialTopPlayersStart,
  loadInitialTopPlayersSuccess,
  loadInitialTopPlayersFailure,
  cleanTopPlayersSuccess,
} from '../actions';
import { Status } from '../types';

const defaultState = {
  isLoading: false,
  isInitialLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${loadTopPlayersStart}`]: (state) => {
    return { ...state, isLoading: true };
  },
  [`${combineActions(loadTopPlayersSuccess, loadTopPlayersFailure)}`]: (state) => {
    return { ...state, isLoading: false };
  },
  [`${loadInitialTopPlayersStart}`]: (state) => {
    return { ...state, isInitialLoading: true };
  },
  [`${combineActions(loadInitialTopPlayersSuccess, loadInitialTopPlayersFailure)}`]: (state) => {
    return { ...state, isInitialLoading: false };
  },
  [`${cleanTopPlayersSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
