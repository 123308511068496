import React, { useRef } from 'react';

import { isEmpty } from 'packages/utils/Object.utils';
import { PlayerHappening } from 'packages/event-time-line/types';
import { FlexiblePopover, HappeningsTimelinePopover } from 'packages/shared/components';
import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';

import { HappeningsPreview, PlayerBadge, PlayerName } from './components';
import styles from './styles.scss';
import { Jersey } from '../../types';

type Props = {
  happenings?: PlayerHappening[];
  jerseyColorScheme?: Jersey;
  jerseyNumber: number;
  name: string;
};

const PlayerCell = ({ jerseyNumber, name, happenings, jerseyColorScheme }: Props) => {
  const containerRef = useRef(null);

  return (
    <div ref={containerRef} className={styles.playerCell}>
      {!isEmpty(happenings) && (
        <FlexiblePopover
          popoverComponent={HappeningsTimelinePopover}
          popoverProps={{ happenings }}
          justify={PopoverJustify.Center}
          align={PopoverAlign.Bottom}
          invokeTrigger={PopoverInvokeTrigger.Hover}
        >
          <div className={styles.hoverArea} />
        </FlexiblePopover>
      )}

      <PlayerBadge
        className={styles.playerBadge}
        playerNumber={jerseyNumber}
        jerseyColorScheme={jerseyColorScheme}
      />

      <PlayerName className={styles.playerNameContainer} playerName={name} />

      {!isEmpty(happenings) && (
        <HappeningsPreview className={styles.playerHappeningsContainer} happenings={happenings} />
      )}
    </div>
  );
};

export default React.memo(PlayerCell);
