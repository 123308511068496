import React, { ReactElement } from 'react';
import classnames from 'classnames';

import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { LoaderContainer, NavigationMenu, NoDataLabel } from 'packages/shared/components';
import { ComponentLike } from 'types';

import { mapTabsIntoNavigationItems } from './services';
import { useWiredTabs } from './hooks';
import { TabView } from './types';
import styles from './styles.scss';

const THEMES_WITHOUT_PADDING = [
  NavigationTheme.Link,
  NavigationTheme.LinkStretched,
  NavigationTheme.LinkWhite,
];

type Props = {
  onNavigationLinkClick?: () => void;
  navigationTabComponent?: ComponentLike;
  navigationLabel?: string | ReactElement;
  isNavigationWidthLimited?: boolean;
  isNavigationMenuDisabled?: boolean;
  isNavigationLinkLabelDisabled?: boolean;
  navigationLinkLabel?: string;
  shouldNavigationMenuBeDisplayed?: boolean;
  shouldComponentBeRenderedInAnyCase?: boolean;
  contentComponentProps?: Record<any, any>;
  stickyHeaderTopOffset?: number;
  contentComponent?: ComponentLike;
  isHeaderSticky?: boolean;
  theme?: NavigationTheme;
  onTabChange?: (tabId: string | number) => void;
  className?: string;
  navigationClassName?: string;
  tabs: TabView[];
  isPaddingDisabled?: boolean;
};

const Tabs = ({
  onNavigationLinkClick,
  navigationTabComponent,
  navigationLabel,
  isNavigationWidthLimited,
  navigationLinkLabel,
  isNavigationMenuDisabled = false,
  isNavigationLinkLabelDisabled = false,
  shouldNavigationMenuBeDisplayed = true,
  shouldComponentBeRenderedInAnyCase = false,
  isPaddingDisabled = false,
  theme = NavigationTheme.Link,
  contentComponentProps = {},
  stickyHeaderTopOffset = 0,
  isHeaderSticky = false,
  contentComponent,
  className,
  onTabChange,
  tabs,
  navigationClassName,
}: Props) => {
  const { activeTab, setActiveTab } = useWiredTabs(tabs, onTabChange);
  const navigationItems = mapTabsIntoNavigationItems(tabs);
  const ContentComponent: ComponentLike | undefined =
    activeTab?.contentComponent || contentComponent;
  const stickyHeaderStyle = isHeaderSticky ? { top: stickyHeaderTopOffset } : {};
  const useNavigationMenuPadding = !isPaddingDisabled && !THEMES_WITHOUT_PADDING.includes(theme);

  const navigationMenuProps = {
    linkLabel: navigationLinkLabel,
    label: navigationLabel,
    isDisabled: isNavigationMenuDisabled,
    isWidthLimited: isNavigationWidthLimited,
    isLinkLabelDisabled: isNavigationLinkLabelDisabled,
    tabComponent: navigationTabComponent,
    onLinkClick: onNavigationLinkClick,
    className: classnames({ [styles.withPadding]: useNavigationMenuPadding }, navigationClassName),
    theme,
  };

  const shouldComponentBeRendered =
    shouldComponentBeRenderedInAnyCase || (!isNavigationMenuDisabled && !activeTab?.isDisabled);

  return (
    <div className={classnames(styles.tabs, styles[theme.toLowerCase()], className)}>
      {shouldNavigationMenuBeDisplayed && (
        <div
          style={stickyHeaderStyle}
          className={classnames(styles.navigation, {
            [styles.stickyNavigation]: isHeaderSticky,
          })}
        >
          <NavigationMenu
            items={navigationItems}
            activeId={activeTab?.id}
            onClick={setActiveTab}
            {...navigationMenuProps}
          />
        </div>
      )}

      <LoaderContainer className={styles.loaderContainer} isLoading={Boolean(activeTab?.isLoading)}>
        {ContentComponent && shouldComponentBeRendered ? (
          <ContentComponent
            {...contentComponentProps}
            {...(activeTab?.contentComponentProps || {})}
          />
        ) : (
          <NoDataLabel className={styles.noDataLabel} />
        )}
      </LoaderContainer>
    </div>
  );
};

export default React.memo(Tabs);
