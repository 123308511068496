import React, { memo, useCallback, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { EventActiveFilters, PlayerTeam, Season, Tournament } from 'packages/player-events/types';
import { Button, DatePicker, Icon, Separator } from 'packages/shared/components';
import { YearItem } from 'packages/shared/components/Calendar/types';
import { ButtonType } from 'packages/shared/components/Button/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { Weight } from 'packages/shared/components/Separator/types';
import DateService from 'packages/date-az';
import { LocaleCode } from 'types';

import { SeasonSelect, TournamentSelect, TeamSelect } from '..';
import { YEARS_SELECT_RANGE } from '../../constants';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  eventsCount: number;
  closePopover: () => void;
  teamFilters: PlayerTeam[];
  tournaments: Tournament[];
  activeFilters: EventActiveFilters;
  filterActions: ActionCreatorsMapObject;
};

interface FilterState {
  tournament?: Tournament;
  team?: PlayerTeam;
  seasons: Season[];
  period: [string, string];
}

const FiltrationPopover = ({
  tournaments,
  teamFilters,
  activeFilters,
  eventsCount,
  closePopover,
  filterActions,
}: Props) => {
  const { period, tournament, selectedSeasons, team } = activeFilters;
  const [filter, setFilter] = useState<FilterState>({
    tournament,
    seasons: selectedSeasons,
    period,
    team,
  });

  const handleClearFilterClick = useCallback(() => {
    const now = DateService.getDate().toISODate();

    setFilter({ tournament: undefined, seasons: [], period: [now, now] });
  }, []);

  const handleApplyClick = useCallback(() => {
    const { period, tournament, seasons, team } = filter;

    filterActions.updateActiveFilter({
      period,
      tournament,
      selectedSeasons: seasons,
      tournamentSeasons: tournament?.seasons || [],
      team,
    });
    closePopover();
  }, [filter]);

  const handleTeamChange = (team?: PlayerTeam) => {
    if (team) {
      filterActions.loadTournamentFilter(team.id);
      setFilter({
        period: [team.startDate, team.endDate],
        team,
        tournament: undefined,
        seasons: [],
      });
    } else {
      setFilter({ ...filter, team, tournament: undefined, seasons: [] });
    }
  };

  const handleTournamentChange = useCallback(
    (tournament?: Tournament) => {
      setFilter({ ...filter, tournament, seasons: [] });
    },
    [filter],
  );

  const handleSeasonsChange = useCallback(
    (seasons: Season[]) => {
      setFilter({ ...filter, seasons });
    },
    [filter],
  );

  const handlePeriodChange = useCallback(
    (period: [string, string]) => {
      setFilter({ ...filter, period });
    },
    [filter],
  );

  const handleYearChange = useCallback(
    (year: YearItem) => {
      const { startDate, endDate } = year;

      handlePeriodChange([startDate.toISODate(), endDate.toISODate()]);
    },
    [handlePeriodChange],
  );

  return (
    <div className={styles.filtrationPopover}>
      <div className={styles.header}>
        <div className={styles.title}>
          {i18n.value('team.teamEvents.mobileGamesFilterPopover.title')}
        </div>
        <Button
          onClick={closePopover}
          type={ButtonType.Default}
          contentClassName={styles.closeButton}
        >
          <Icon id={IconType.Cross} className={styles.icon} />
        </Button>
      </div>
      <Separator weight={Weight.Bold} />
      <div className={styles.filters}>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('player.events.mobileFilterPopover.periodFilter.label')}
          </span>
          <DatePicker
            onApplyClick={handlePeriodChange}
            onYearClick={handleYearChange}
            eventsCount={eventsCount}
            yearOffset={YEARS_SELECT_RANGE}
            localeCode={LocaleCode.En}
            value={filter.period}
            isRangeMode
          />
        </div>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('player.events.mobileFilterPopover.teamFilter.label')}
          </span>
          <TeamSelect
            onChange={handleTeamChange}
            selectedTeam={filter.team}
            teams={teamFilters}
            className={styles.select}
          />
        </div>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('player.events.mobileFilterPopover.tournamentFilter.label')}
          </span>
          <TournamentSelect
            onChange={handleTournamentChange}
            isDisabled={!filter.team?.isTournamentSelectable}
            selectedTournament={filter.tournament}
            tournaments={tournaments}
            className={styles.select}
          />
        </div>
        <div className={styles.filterContainer}>
          <span className={styles.label}>
            {i18n.value('player.events.mobileFilterPopover.seasonFilter.label')}
          </span>
          <SeasonSelect
            onChange={handleSeasonsChange}
            seasons={filter.tournament?.seasons || []}
            isDisabled={!filter.tournament}
            selectedSeasons={filter.seasons}
            className={styles.select}
          />
        </div>
      </div>
      <Separator />
      <div className={styles.footer}>
        <div onClick={handleClearFilterClick} className={classnames(styles.button, styles.clear)}>
          {i18n.value('player.events.mobileFilterPopover.clearFilter')}
        </div>

        <div className={styles.controlButtons}>
          <Button onClick={closePopover} type={ButtonType.Secondary} className={styles.button}>
            {i18n.value('player.events.mobileFilterPopover.cancel')}
          </Button>
          <Button type={ButtonType.Primary} onClick={handleApplyClick} className={styles.button}>
            {i18n.value('player.events.mobileFilterPopover.apply')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default connect(memo(FiltrationPopover));
