import React, { memo, useCallback, useMemo } from 'react';

import { Language, LocaleCode } from 'types';
import { NavigationMenu } from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { ArrayUtils } from 'packages/utils';

import { getNavigationItems } from './services';

type Props = {
  languages: Language[];
  localeCode: LocaleCode;
  onLanguageChange: (id: number | string) => void;
};

const LanguageSelect = ({ languages, localeCode, onLanguageChange }: Props) => {
  const activeLanguage: Language = useMemo(
    () => ArrayUtils.findBy(languages, 'localeCode', localeCode),
    [languages, localeCode],
  );

  const languageNavigationItems = useMemo(() => getNavigationItems(languages), [languages]);

  const handleNavigationItemClick = useCallback(onLanguageChange, []);

  return (
    <div>
      <NavigationMenu
        activeId={activeLanguage.localeCode}
        theme={NavigationTheme.Button}
        items={languageNavigationItems}
        onClick={handleNavigationItemClick}
      />
    </div>
  );
};

export default memo(LanguageSelect);
