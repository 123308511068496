import { handleActions, ReducerMap } from 'redux-actions';

import { Data } from '../types';
import * as actions from '../actions';

const defaultState: Data = {
  stages: [],
  activeStageId: undefined,
};

const reducerMap: ReducerMap<Data, Data> = {
  [`${actions.loadEventStandingsSuccess}`]: (state, { payload }) => {
    const { stages, activeStageId } = payload;

    return { stages, activeStageId };
  },
  [`${actions.cleanEventStandings}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
