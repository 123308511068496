import { PlayerEvents } from 'packages/rest';
import { PlayerEventsParams } from 'packages/rest/api/PlayerEvents/types';

import { mapPlayerEvents, getEventsDatePeriod } from '../../services';

const loadEvents = (params: PlayerEventsParams) => async () => {
  const { data } = await PlayerEvents.loadPlayerEvents(params);

  return {
    events: mapPlayerEvents(data.results),
    period: getEventsDatePeriod(data.results),
    count: data.count,
  };
};

export default loadEvents;
