import { Event } from 'packages/events/types';

export const mapFavoriteEvents = (
  favoriteEvents: Event[],
  favoriteTeamsEvents: Event[],
  favoriteTournamentsEvents: Event[],
) => {
  const flushedFromTeamDuplicatesEvents = flushEventListFromIdDuplicates(
    favoriteEvents,
    favoriteTeamsEvents,
  );
  const flushedEvents = flushEventListFromIdDuplicates(
    flushedFromTeamDuplicatesEvents,
    favoriteTournamentsEvents,
  );
  const flushedTeamsEvents = flushEventListFromIdDuplicates(
    favoriteTeamsEvents,
    favoriteTournamentsEvents,
  );

  return [...flushedEvents, ...flushedTeamsEvents, ...favoriteTournamentsEvents];
};

const flushEventListFromIdDuplicates = (eventList: Event[], duplicatesList: Event[]): Event[] => {
  const flushedEventList: Event[] = [];

  for (const event of eventList) {
    const duplicate = duplicatesList.find(({ id }) => id === event.id);

    if (!duplicate) {
      flushedEventList.push(event);
    }
  }

  return flushedEventList;
};
