import React, { memo, useRef } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { LoaderContainer, Spinner, TournamentPreview } from 'packages/shared/components';
import { SpinnerSize } from 'packages/shared/components/Spinner/types';
import { isEmpty } from 'packages/utils/Object.utils';
import { Tournament } from 'types';

import { PIXELS_FROM_END_TO_LOAD_MORE } from '../../constants';
import styles from './styles.scss';
import connect from './connect';

const SEARCH_RESULTS_SCROLL_CONTAINER_ID = 'search_results_scroll_container';

type Props = {
  results: Tournament[];
  favoriteTournaments: Tournament[];
  favoritesActions: ActionCreatorsMapObject;
  searchActions: ActionCreatorsMapObject;
  onTournamentClick: () => void;
  hasNextResults: boolean;
  activeDate: string;
  scrollTop?: number;
  isLoading: boolean;
  className?: string;
};

const SearchResults = ({
  results,
  hasNextResults,
  activeDate,
  favoriteTournaments,
  favoritesActions,
  onTournamentClick,
  scrollTop = 0,
  isLoading,
  className,
  searchActions,
}: Props) => {
  const scrollContainer = useRef<HTMLDivElement>(null);

  const handleLoadMore = () => {
    const scrollTop = scrollContainer.current?.scrollTop || 0;

    searchActions.loadMoreResults({ scrollTop });
  };

  const getFavoriteTournamentHandler = (tournament) => () => {
    favoritesActions.toggleLeague(tournament);
  };

  return (
    <div
      id={SEARCH_RESULTS_SCROLL_CONTAINER_ID}
      ref={scrollContainer}
      className={classnames(styles.resultsContainer, className)}
    >
      <LoaderContainer className={styles.loaderContainer} isLoading={isLoading}>
        {!isEmpty(results) ? (
          <InfiniteScroll
            next={handleLoadMore}
            hasMore={hasNextResults}
            dataLength={results.length}
            scrollThreshold={PIXELS_FROM_END_TO_LOAD_MORE}
            initialScrollY={scrollTop}
            loader={
              <div key={0} className={styles.spinnerContainer}>
                <Spinner size={SpinnerSize.Small} />
              </div>
            }
            scrollableTarget={SEARCH_RESULTS_SCROLL_CONTAINER_ID}
          >
            {results.map((tournament) => {
              const isTournamentFavorite = Boolean(
                favoriteTournaments.find(
                  ({ uniqueTournamentId }) => uniqueTournamentId === tournament.uniqueTournamentId,
                ),
              );

              return (
                <TournamentPreview
                  onFavoriteToggle={getFavoriteTournamentHandler(tournament)}
                  isFavorite={isTournamentFavorite}
                  onClick={onTournamentClick}
                  key={tournament.id}
                  tournament={tournament}
                  activeDate={activeDate}
                  isTournamentUnique
                />
              );
            })}
          </InfiniteScroll>
        ) : (
          <div className={styles.noResultsContainer}>{i18n.value('header.search.noResults')}</div>
        )}
      </LoaderContainer>
    </div>
  );
};

export default connect(memo(SearchResults));
