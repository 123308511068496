import { handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { State } from '../types';

const defaultState: State = {
  mediaScreen: '',
  viewWidth: null,
};

const reducerMap: ReducerMap<State, any> = {
  [`${actions.setMediaInfo}`]: (state, { payload }) => {
    const { mediaScreen, viewWidth } = payload;

    return { ...state, mediaScreen, viewWidth };
  },
};

export default handleActions(reducerMap, defaultState);
