exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1LCde1uw{display:flex;align-items:center}._JbFGQ9KE{padding:8px 16px 0 16px}._JbFGQ9KE ._1C5FFl2S{display:flex}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/stadium-summary-ui/containers/Summary/components/DetailsSelectorSkeleton/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,kBAAmB,CAHvB,WAOI,uBCTU,CDEd,sBAUM,YAAa","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .wrapper {\n    display: flex;\n    align-items: center;\n  }\n\n  .compact-wrapper {\n    padding: 8px $padding 0 $padding;\n\n    .content {\n      display: flex;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"wrapper": "_1LCde1uw",
	"compact-wrapper": "_JbFGQ9KE",
	"compactWrapper": "_JbFGQ9KE",
	"content": "_1C5FFl2S"
};