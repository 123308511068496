import { getMediaScreen } from './getMediaScreen';

export const getDeviceInfo = () => {
  const { innerHeight: viewHeight, innerWidth: viewWidth } = window;
  const { language, languages } = navigator;
  const mediaScreen = getMediaScreen(viewWidth);

  return {
    viewHeight,
    viewWidth,
    language,
    languages,
    mediaScreen,
  };
};
