import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const HeaderSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <Skeleton variant="rectangular" height={16} width={83} minWidth={83} marginRight={12} />
      <Skeleton variant="rectangular" height={32} maxWidth={100} width="100%" />
    </div>
  );
};

export default memo(HeaderSkeleton);
