import React, { useCallback, useState, memo } from 'react';
import classnames from 'classnames';

import { Category as CategoryView, Tournament } from 'types';
import { IconType } from 'packages/shared/components/Icon/types';
import { Figure, Icon, ToggleArrowButton } from 'packages/shared/components';

import { TournamentList } from '../../../../../../components';
import styles from './styles.scss';

type Props = {
  tournaments?: Tournament[];
  onCategoryClick: (category: CategoryView) => void;
  onTournamentClick: (tournament: Tournament) => void;
  onCategoryToggle: (category: CategoryView) => void;
  category: CategoryView;
  className?: string;
  isLoading: boolean;
  isOpen: boolean;
  useControlsHoverEffect?: boolean;
};

const Category = ({
  category,
  isOpen,
  tournaments = [],
  isLoading,
  onCategoryClick,
  onTournamentClick,
  onCategoryToggle,
  className,
  useControlsHoverEffect = false,
}: Props) => {
  const { logo, name } = category;

  const [isCategoryLabelHovered, setIsCategoryLabelHovered] = useState<boolean>();

  const handleLabelMouseEnter = useCallback(() => {
    if (useControlsHoverEffect) {
      setIsCategoryLabelHovered(true);
    }
  }, [useControlsHoverEffect]);

  const handleLabelMouseLeave = useCallback(() => {
    if (useControlsHoverEffect) {
      setIsCategoryLabelHovered(false);
    }
  }, [useControlsHoverEffect]);

  const handleLabelClick = useCallback(() => {
    onCategoryClick(category);
  }, [onCategoryClick, category]);

  const handleCategoryToggle = useCallback(() => {
    onCategoryToggle(category);
  }, [onCategoryToggle, category]);

  const containerClasses = classnames(styles.container, className, {
    [styles.open]: isOpen,
    [styles.hoverable]: useControlsHoverEffect,
  });

  return (
    <div className={containerClasses}>
      <div className={styles.header}>
        <div
          className={styles.categoryLabel}
          onMouseEnter={handleLabelMouseEnter}
          onMouseLeave={handleLabelMouseLeave}
          onClick={handleLabelClick}
        >
          <Figure src={logo} className={styles.categoryIcon} />
          <div className={styles.categoryName}>{name}</div>
        </div>

        <div className={classnames(styles.controlContainer)} onClick={handleCategoryToggle}>
          {useControlsHoverEffect && (
            <Icon
              id={IconType.ArrowExtended}
              className={classnames(styles.arrowIcon, {
                [styles.active]: isCategoryLabelHovered,
              })}
            />
          )}

          <div
            className={classnames(styles.dropdownButton, {
              [styles.disabled]: isCategoryLabelHovered,
            })}
          >
            <ToggleArrowButton isOpen={isOpen} />
          </div>
        </div>
      </div>
      <div className={styles.tournaments}>
        <TournamentList
          useControlsHoverEffect={useControlsHoverEffect}
          onTournamentClick={onTournamentClick}
          tournaments={tournaments}
          isLoading={isLoading}
        />
      </div>
    </div>
  );
};

export default memo(Category);
