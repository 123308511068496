import { combineReducers } from 'redux';

import players from './players';
import teams from './teams';
import venues from './venues';
import topOfTheSeason from './topOfTheSeason';
import compactPlayers from './compactPlayers';

export default combineReducers({
  players,
  teams,
  venues,
  topOfTheSeason,
  compactPlayers,
});
