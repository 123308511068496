import { KeyValueStorage } from './types';

class SessionStorage implements KeyValueStorage {
  storage: Storage;

  constructor() {
    this.storage = window.sessionStorage;
  }

  get<DataType extends object>(key: string): DataType {
    const item = this.storage.getItem(key);

    return item ? JSON.parse(item) : item;
  }

  remove(key: string): void {
    return this.storage.removeItem(key);
  }

  set<DataType extends object>(key: string, value: DataType): void {
    return this.storage.setItem(key, JSON.stringify(value));
  }
}

const sessionStorage = new SessionStorage();

export default sessionStorage;
