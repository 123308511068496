import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadResultsStart = createAction(`${NAMESPACE}LOAD_RESULTS_START`);
export const loadResultsSuccess = createAction(`${NAMESPACE}LOAD_RESULTS_SUCCESS`);
export const loadResultsFailure = createAction(`${NAMESPACE}LOAD_RESULTS_FAILURE`);

export const loadMoreResultsStart = createAction(`${NAMESPACE}LOAD_MORE_RESULTS_START`);
export const loadMoreResultsSuccess = createAction(`${NAMESPACE}LOAD_MORE_RESULTS_SUCCESS`);
export const loadMoreResultsFailure = createAction(`${NAMESPACE}LOAD_MORE_RESULTS_FAILURE`);

export const clearResultsStart = createAction(`${NAMESPACE}CLEAR_RESULTS_START`);
export const clearResultsSuccess = createAction(`${NAMESPACE}CLEAR_RESULTS_SUCCESS`);
export const clearResultsFailure = createAction(`${NAMESPACE}CLEAR_RESULTS_FAILURE`);

export const setSearchKeySuccess = createAction(`${NAMESPACE}SET_SEARCH_KEY_SUCCESS`);
