import React, { memo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import { Team } from 'types/interfaces/teams';
import { TeamGrid } from 'packages/shared/components';

import { NewcomersTitle } from '../../types';
import styles from './styles.scss';

type Props = {
  newcomers: Team[];
  className?: string;
  onTeamClick: (team: Team) => void;
};

const Newcomers = ({ newcomers, onTeamClick, className }: Props) => {
  return (
    <div className={classnames(styles.mainNewcomersContainer, className)}>
      <div className={styles.title}>
        {i18n.value(`season.seasonTopBoard.newcomers.title.${NewcomersTitle.Main}`)}
      </div>
      <TeamGrid className={styles.content} teams={newcomers} onTeamClick={onTeamClick} />
    </div>
  );
};

export default memo(Newcomers);
