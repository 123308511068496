import React from 'react';

import { Figure } from 'packages/shared/components';
import { Team } from 'types';

import styles from './styles.scss';

type Props = {
  team: Team;
  onTeamClick: (team: Team) => void;
};

const TeamCell = ({ team, onTeamClick }: Props) => {
  const { logo } = team;

  const handleTeamClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    onTeamClick(team);
  };

  return (
    <div className={styles.teamCell}>
      <Figure onClick={handleTeamClick} src={logo} className={styles.image} />
    </div>
  );
};

export default TeamCell;
