import { commonActions } from '../../actions';
import { cleanHeadToHead } from '../headTohead';
import { cleanStages } from '../stages';

const cleanSeasonStandings = () => async (dispatch) => {
  dispatch(cleanStages());
  dispatch(cleanHeadToHead());
  dispatch(commonActions.cleanSeasonStandingsSuccess());
};

export default cleanSeasonStandings;
