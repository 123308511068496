import React, { useEffect, memo, useMemo } from 'react';
import { ActionCreatorsMapObject } from 'redux';

import { useActiveTabContentElement, useStageTabs } from 'packages/season-standings-ui/hooks';
import { LoaderContainer, Separator } from 'packages/shared/components';
import { isEmpty } from 'packages/utils/Object.utils';
import { Type } from 'packages/shared/components/Separator/types';
import { Stage } from 'packages/event-standings/types';
import { Event } from 'types';

import { getBracketProps, getTableProps, getEventDetailsSubpageHeight } from './services';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  stages: Stage[];
  activeStageId: number | undefined;
  eventStandingsActions: ActionCreatorsMapObject;
  activeEvent: Event;
  isLoading: boolean;
  cleanEventStandings: Function;
};

const EventStandings = ({
  stages,
  activeStageId,
  eventStandingsActions,
  activeEvent,
  isLoading,
  cleanEventStandings,
}: Props) => {
  const { id: activeEventId, seasonId, teams, tournament } = activeEvent;
  const activeTeamIds = teams.map(({ id }) => id);
  const [homeTeamId] = activeTeamIds;

  const additionalProps = useMemo(
    () => ({
      style: { height: getEventDetailsSubpageHeight() },
      activeEventId,
      activeTeamIds,
      teamIdToScroll: homeTeamId,
      activeUniqueTournamentId: tournament.uniqueTournamentId,
      activeSeasonId: seasonId,
    }),
    [stages, activeEvent, homeTeamId],
  );
  const StageTabs = useStageTabs(stages, {
    getTableProps,
    getBracketProps,
    additionalProps,
    defaultActiveTabId: activeStageId,
  });
  const activeContentElement = useActiveTabContentElement(StageTabs.activeTab);

  useEffect(() => {
    eventStandingsActions.loadEventStandings({ seasonId, event: activeEvent });
  }, [activeEvent]);

  useEffect(
    () => () => {
      cleanEventStandings();
    },
    [],
  );

  return (
    <LoaderContainer isLoading={isLoading} className={styles.loader}>
      {!isEmpty(stages) && activeContentElement}
      <Separator type={Type.Block} />
    </LoaderContainer>
  );
};

export default connect(memo(EventStandings));
