import { CommonTableParameter } from 'types';
import { SeasonTopBoard } from 'packages/rest';

import { PLAYER_API_ORDERING_BY_PARAMETERS } from '../constants';
import * as services from '../services';
import * as actions from '../actions';

export const loadInitialSeasonTopPlayers = (seasonId: number) => async (dispatch) => {
  dispatch(actions.loadInitialTopPlayersStart());

  try {
    const { data } = await SeasonTopBoard.loadSeasonTopPlayers({
      seasonId,
      ordering: services.parseOrderingParams(
        PLAYER_API_ORDERING_BY_PARAMETERS[CommonTableParameter.Goals],
      ),
    });
    const topPlayers = services.mapSeasonTopPlayers(data.results);

    dispatch(actions.loadInitialTopPlayersSuccess(topPlayers));
  } catch (e) {
    dispatch(actions.loadInitialTopPlayersFailure(e));
  }
};
