import React, { ReactNode } from 'react';
import classnames from 'classnames';

import { Theme } from './types';

import styles from './styles.scss';

type Props = {
  value?: string | number;
  isBold?: boolean;
  className?: string;
  children?: ReactNode;
  theme?: Theme;
  injuryTime?: number;
  isInjuryTimeDisplayed?: boolean;
};

const Chip = ({
  value,
  isBold,
  children,
  theme = Theme.Light,
  className,
  injuryTime,
  isInjuryTimeDisplayed = false,
}: Props) => {
  const containerClasses = classnames(
    styles.chipContainer,
    styles[theme],
    {
      [styles.bold]: isBold,
    },
    className,
  );

  return (
    <div className={containerClasses}>
      {children || value}
      {isInjuryTimeDisplayed && !!injuryTime && <span>{`+${injuryTime}'`}</span>}
    </div>
  );
};

export default React.memo(Chip);
