import React, { memo } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import i18n from 'i18n-smart';

import { isEmpty } from 'packages/utils/Object.utils';

import { InfiniteScrollSpinnerContainer } from './components';
import styles from './styles.scss';

type Props = {
  scrollContainerIdentifier: string | null;
  onLoadMore?: () => void;
  eventsLength: number;
  hasNext: boolean;
  children: any[];
};

const InfiniteScrollContainer = ({
  scrollContainerIdentifier,
  onLoadMore,
  eventsLength,
  hasNext,
  children,
}: Props) => {
  if (!isEmpty(children)) {
    return (
      <>
        {onLoadMore ? (
          <InfiniteScroll
            scrollableTarget={scrollContainerIdentifier}
            loader={<InfiniteScrollSpinnerContainer />}
            dataLength={eventsLength}
            next={onLoadMore}
            hasMore={hasNext}
            style={{ overflow: 'unset' }}
          >
            {children}
          </InfiniteScroll>
        ) : (
          children
        )}
      </>
    );
  }

  return <div className={styles.empty}>{i18n.value('common.notFound')}</div>;
};

export default memo(InfiniteScrollContainer);
