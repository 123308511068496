import getState from './getState';

export const getDefaultLanguages = (state) => {
  return getState(state).defaultLanguages;
};

export const getDefaultLocaleCode = (state) => {
  return getState(state).defaultLocaleCode;
};

export const getFootballTopLeagueIds = (state) => {
  return getState(state).footballTopLeagueIds;
};

export const getAvailableSports = (state) => {
  return getState(state).availableSports;
};

export const getDefaultSport = (state) => {
  return getState(state).defaultSport;
};

export const getDefaultMobileTab = (state) => {
  return getState(state).defaultMobileTab;
};

export const getDefaultSportName = (state) => {
  return getDefaultSport(state).name;
};

export const getDefaultSportId = (state) => {
  return getDefaultSport(state).id;
};

export const getDefaultFootballTopCountriesIds = (state) => {
  return getState(state).footballTopCountriesIds;
};

export const getDefaultTimeZone = (state) => {
  return getState(state).timeZoneDefault;
};

export const getTimeZones = (state) => {
  return getState(state).timeZones;
};
