import React from 'react';
import classnames from 'classnames';

import DateService from 'packages/date-az';
import { Competitor } from 'types';

import { TeamLabel } from 'packages/shared/components';
import { Size, Theme } from 'packages/shared/components/TeamLabel/types';
import { DATE_FORMATS } from 'packages/date-az/consts';
import { DetailedMatchStatus } from './types';

import styles from './styles.scss';

type Props = {
  homeTeam: Competitor;
  awayTeam: Competitor;
  status: DetailedMatchStatus;
  scheduled: string;
  onMouseUp?: () => void;
  className?: string;
};

const DetailedMatchUp = ({
  homeTeam,
  awayTeam,
  className,
  scheduled,
  status,
  onMouseUp,
}: Props) => {
  const { winnerId, homeScore, awayScore } = status;
  const formattedScheduledDate = DateService.getFormattedDateViewFromString(
    scheduled,
    DATE_FORMATS.FULL_DATE_TIME,
  );

  return (
    <div
      className={classnames(className, styles.menuElementContainer, {
        [styles.menuElementContainerClickable]: onMouseUp,
      })}
      onMouseUp={onMouseUp}
    >
      <div className={styles.scheduledDateContainer}>{formattedScheduledDate}</div>
      <div className={styles.matchUpContainer}>
        <div
          className={classnames(styles.teamContainer, {
            [styles.winnerMatchResultElement]: winnerId === homeTeam.id,
          })}
        >
          <div className={styles.teamLabelWrapper}>
            <TeamLabel size={Size.Sm} theme={Theme.Light} team={homeTeam} isLogoFirst />
          </div>
          <div>{homeScore}</div>
        </div>
        <div
          className={classnames(styles.teamContainer, {
            [styles.winnerMatchResultElement]: winnerId === awayTeam.id,
          })}
        >
          <div className={styles.teamLabelWrapper}>
            <TeamLabel size={Size.Sm} theme={Theme.Light} team={awayTeam} isLogoFirst />
          </div>
          <div>{awayScore}</div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(DetailedMatchUp);
