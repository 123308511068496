import axios, { AxiosRequestConfig, AxiosInstance } from 'axios';

/*eslint-disable*/
let instance: AxiosInstance;

const configure = (requestConfig: AxiosRequestConfig): void => {
  instance = axios.create({
    headers: requestConfig.headers || {},
    baseURL: requestConfig.baseURL || '',
    timeout: requestConfig.timeout || 0,
  });
};

export { configure, instance as default };
