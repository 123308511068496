import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.container}>
      <Skeleton width={150} height={16} marginBottom={16} variant="rectangular" />
      <div className={styles.managerInfo}>
        <Skeleton width={32} height={32} marginRight={8} variant="circular" />
        <div className={styles.details}>
          <div className={styles.name}>
            <Skeleton width={100} height={16} variant="rectangular" marginRight={8} />
            <Skeleton width={16} height={16} variant="rectangular" />
          </div>
          <Skeleton width={110} height={14} variant="rectangular" marginTop={4} />
        </div>
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
