import getPath from './getPath';
import { MobileFilterStages } from '../../types';

const getStatus = (state): MobileFilterStages.Status => {
  return getPath(state).status;
};

export const getIsLoading = (state): boolean => {
  return getStatus(state).isLoading;
};
