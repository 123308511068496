import React, { memo, useCallback } from 'react';

import { EventSeries } from 'packages/event-details/types';
import { Event, MediaScreen, Tournament } from 'types';
import { TabView } from 'packages/shared/components/Tabs/types';
import { useNavigation } from 'packages/hooks';

import { EventDetailsCard } from '../../components';

type Props = {
  event: Event;
  className?: string;
  eventSeries: EventSeries;
  onClose: () => void;
  eventDetailsTabs: TabView[];
  onTournamentClick: (tournament: Tournament) => void;
  onFirstLegClick: (event?: Event) => void;
};

const EventDetails = ({
  event,
  eventSeries,
  className,
  onClose,
  eventDetailsTabs,
  onTournamentClick,
  onFirstLegClick,
}: Props) => {
  const { deltaNavigate } = useNavigation(MediaScreen.Desktop);

  const handleBack = useCallback(() => {
    deltaNavigate(-1);
  }, []);

  return (
    <EventDetailsCard
      onBack={handleBack}
      event={event}
      className={className}
      onClose={onClose}
      eventDetailsTabs={eventDetailsTabs}
      onTournamentClick={onTournamentClick}
      onFirstLegClick={onFirstLegClick}
      eventSeries={eventSeries}
    />
  );
};

export default memo(EventDetails);
