import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState = {
  isEventLineupLoading: true,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${actions.loadEventLineupsStart}`]: () => {
    return { isEventLineupLoading: true };
  },
  [`${combineActions(actions.loadEventLineupsSuccess, actions.loadEventLineupsFailure)}`]: () => {
    return { isEventLineupLoading: false };
  },
  [`${actions.cleanEventLineups}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
