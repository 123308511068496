import React, { memo } from 'react';
import classnames from 'classnames';

import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import styles from './styles.scss';

type Props = {
  playerName?: string;
  playerId?: number;
};

const GoalLabel = ({ playerName = '', playerId }: Props) => {
  const { appNavigate } = useNavigation();

  const handlePlayerClick = (playerId?: number) => () => {
    if (playerId) {
      appNavigate(DesktopPage.FootballPlayer, { playerId });
    }
  };

  return (
    <div className={styles.label}>
      {playerName && (
        <span
          onClick={handlePlayerClick(playerId)}
          className={classnames(styles.playerName, {
            [styles.clickable]: playerId !== undefined,
          })}
        >
          {playerName}
        </span>
      )}
    </div>
  );
};

export default memo(GoalLabel);
