import { createElement } from 'react';

import { Chip } from 'packages/shared/components';

const getChipElement = (
  chipValue: string | number | undefined,
  customProps: Record<any, any> = {},
) => {
  return createElement(Chip, {
    value: chipValue,
    ...customProps,
  });
};

export default getChipElement;
