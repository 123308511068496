import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const StatisticsCell = () => {
  return (
    <div className={styles.container}>
      <Skeleton marginBottom={10} width={213} height={50} variant="rectangular" />
      <Skeleton marginBottom={10} width={120} height={10} variant="rectangular" />
    </div>
  );
};
export default memo(StatisticsCell);
