import instance from 'packages/rest/axios';

import { StatisticsParams } from '../types';

export const loadStatistics = ({ playerId, seasonId }: StatisticsParams) => {
  return instance({
    method: 'get',
    url: `/players/${playerId}/${seasonId}/statistics/`,
  });
};
