import { combineReducers } from 'redux';

import actualGames from './actualGames';
import tournaments from './tournaments';
import statistics from './statistics';

export default combineReducers({
  actualGames,
  tournaments,
  statistics,
});
