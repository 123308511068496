import React, { useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';

import { EventsTabs } from 'packages/events-ui';
import { DatePicker } from 'packages/date-picker-ui';
import { SearchQuery } from 'router/types';
import { FlexiblePopover, Separator } from 'packages/shared/components';
import { CategoryModal } from 'packages/categories-ui';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';

import styles from './styles.scss';

const Main = () => {
  const { categoryId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const openedCategoryId = searchParams.get(SearchQuery.CategoryId);

  const handleClose = useCallback(() => {
    searchParams.delete(SearchQuery.CategoryId);
    setSearchParams(searchParams);
  }, [searchParams]);

  return (
    <div className={styles.container}>
      <FlexiblePopover
        popoverComponent={CategoryModal.Mobile}
        justify={PopoverJustify.CenterStart}
        align={PopoverAlign.Top}
        onClose={handleClose}
        isOpen={!!openedCategoryId}
        className={styles.modalWindow}
        disableBodyOverflow
        closeOnClickOutside
        isModal
      />

      <div className={styles.stickyBlock}>
        <DatePicker.Tablet />
        <Separator />
      </div>

      <EventsTabs.Mobile categoryId={categoryId} />
    </div>
  );
};

export default React.memo(Main);
