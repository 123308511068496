import { Categories } from 'packages/rest';

import { mapModalCategory } from '../../services';
import { categoryModalActions } from '../../actions';

type Params = {
  categoryId: string;
};

export const loadCategory = ({ categoryId }: Params) => async (dispatch) => {
  try {
    dispatch(categoryModalActions.loadCategoryStart());

    const { data } = await Categories.loadCategory({ id: categoryId });
    const { category, tournaments } = mapModalCategory(data.results);

    dispatch(categoryModalActions.loadCategorySuccess({ category, tournaments }));
  } catch (error) {
    dispatch(categoryModalActions.loadCategoryFailure());
  }
};
