import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Skeleton height={14} marginBottom={12} width={90} variant="rectangular" />
      </div>

      <div className={styles.content}>
        <Skeleton height={64} width={74} variant="rectangular" />
        <Skeleton height={64} width={42} variant="rectangular" />
        <Skeleton height={64} width={74} variant="rectangular" />
      </div>

      <Skeleton height={8} marginTop={14} width="100%" variant="rectangular" />

      <Skeleton height={2} marginTop={14} width="100%" variant="rectangular" />

      <div className={styles.content}>
        <Skeleton marginTop={14} height={14} width={90} variant="rectangular" />
        <Skeleton marginTop={14} height={14} width={90} variant="rectangular" />
      </div>

      <Skeleton height={32} marginTop={24} width="100%" variant="rectangular" />

      <Skeleton height={32} marginTop={24} width="100%" variant="rectangular" />
    </div>
  );
};

export default memo(LoadingSkeleton);
