import React, { RefObject, useCallback, useEffect, useRef } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useSearchParams } from 'react-router-dom';

import { Event, Season, TimeZone } from 'types';
import { PaginationParams } from 'packages/shared/components/Pagination/types';
import { DEFAULT_EVENTS_LIMIT } from 'packages/season-events/constants';
import { SearchQuery } from 'router/types';
import {
  Filter,
  FilterValue,
  SeasonEventsFilter as SeasonEventsFiltersType,
} from 'packages/season-events/types';

import SeasonEventsView from './views';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  events: Event[];
  selectedSeason: Season;
  seasonEventsFilter: SeasonEventsFiltersType;
  isInitialEventsLoading: boolean;
  isMoreEventsLoading: boolean;
  isFiltersLoading: boolean;
  seasonEventsActions: ActionCreatorsMapObject;
  timeZone: TimeZone;
  limit: number;
  eventsCount: number;
};

const extendSeasonEvents = (
  SeasonEvents: typeof SeasonEventsView[keyof typeof SeasonEventsView],
) => ({
  selectedSeason,
  seasonEventsFilter,
  events,
  isInitialEventsLoading,
  seasonEventsActions,
  timeZone,
  limit,
  eventsCount,
  isMoreEventsLoading,
  isFiltersLoading,
}: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const containerRef: RefObject<HTMLDivElement> = useRef(null);
  const isLimitSelectable = eventsCount > DEFAULT_EVENTS_LIMIT;

  useEffect(() => {
    if (selectedSeason) {
      seasonEventsActions.loadEventsPage(selectedSeason);

      return () => {
        seasonEventsActions.unsubscribe();
        seasonEventsActions.clear();
      };
    }
  }, [selectedSeason, timeZone.value]);

  const handleFilterChange = (filterValue: FilterValue, filterType: Filter): void => {
    seasonEventsActions.updateActiveFilter(filterValue, filterType);
    seasonEventsActions.loadMoreEvents(selectedSeason.id);
  };

  const handlePaginationChange = (params: PaginationParams) => {
    seasonEventsActions.updatePagination(params);
    seasonEventsActions.loadMoreEvents(selectedSeason.id);
  };

  const handleCustomRangeClick = () => {
    const { startDate, endDate } = selectedSeason;

    seasonEventsActions.updateActiveFilter([startDate, endDate], Filter.Dates);
    seasonEventsActions.loadMoreEvents(selectedSeason.id);
  };

  const handleEventClick = useCallback(
    (eventId: number) => {
      setSearchParams({ [SearchQuery.EventCardId]: eventId.toString() });
    },
    [searchParams],
  );

  return (
    <div ref={containerRef} className={styles.seasonEvents}>
      <SeasonEvents
        isFiltersLoading={isFiltersLoading}
        isMoreEventsLoading={isMoreEventsLoading}
        isInitialEventsLoading={isInitialEventsLoading}
        seasonEventsFilter={seasonEventsFilter}
        eventsCount={eventsCount}
        events={events}
        limit={limit}
        onPaginationChange={handlePaginationChange}
        onFilterChange={handleFilterChange}
        onCustomRangeClick={handleCustomRangeClick}
        containerRef={containerRef}
        isLimitSelectable={isLimitSelectable}
        onEventClick={handleEventClick}
      />
    </div>
  );
};

export default {
  Desktop: connect(extendSeasonEvents(SeasonEventsView.Desktop)),
  DesktopNarrow: connect(extendSeasonEvents(SeasonEventsView.DesktopNarrow)),
  Tablet: connect(extendSeasonEvents(SeasonEventsView.Tablet)),
  TabletNarrow: connect(extendSeasonEvents(SeasonEventsView.TabletNarrow)),
  Mobile: connect(extendSeasonEvents(SeasonEventsView.Mobile)),
};
