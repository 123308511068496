import React, { memo, useCallback, useEffect } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import isEmpty from 'is-empty-typed/lib';

import { LoaderContainer, FunFacts } from 'packages/shared/components';
import { EventSummary as EventSummaryView } from 'packages/event-summary/types';
import { useNavigation } from 'packages/hooks';
import { Event, Team } from 'types';
import { DesktopPage } from 'router/types';

import { SummaryForm, SummaryDetails, Odds, TimeLine } from './components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  isLoading: boolean;
  activeEvent: Event;
  eventSummary: EventSummaryView;
  eventSummaryActions: ActionCreatorsMapObject;
};

const EventSummary = ({ isLoading, activeEvent, eventSummary, eventSummaryActions }: Props) => {
  const { appNavigate } = useNavigation();

  useEffect(() => {
    return eventSummaryActions.cleanEventSummary;
  }, []);

  useEffect(() => {
    eventSummaryActions.loadEventSummary({ eventId: activeEvent.id });
  }, [activeEvent.id]);

  const handleTeamClick = useCallback(
    (team: Team) => {
      const { tournament, seasonId } = activeEvent;

      appNavigate(DesktopPage.FootballTeamSummaryDescription, {
        teamId: team.id,
        uniqueTournamentId: tournament.uniqueTournamentId,
        seasonId,
      });
    },
    [activeEvent.tournament.uniqueTournamentId, activeEvent.seasonId],
  );

  return (
    <LoaderContainer isLoading={isLoading} className={styles.loader}>
      {!isEmpty(eventSummary.periods) && <TimeLine periods={eventSummary.periods} />}

      {!isEmpty(eventSummary.facts) && (
        <div className={styles.factsContainer}>
          <FunFacts facts={eventSummary.facts} />
        </div>
      )}

      {!isEmpty(eventSummary.teams) && (
        <SummaryForm onTeamClick={handleTeamClick} teams={eventSummary.teams} />
      )}

      <Odds />

      <SummaryDetails event={activeEvent} matchDetails={eventSummary} />
    </LoaderContainer>
  );
};

export default connect(memo(EventSummary));
