import { EventTimeLine } from 'packages/rest';
import {
  formatEventTimeLine,
  formatTimeLinePeriodScores,
  mapTimeLineHappenings,
} from 'packages/event-time-line/services';

import { matchShotsThunks, matchShotsActions } from 'packages/match-shots';
import { loadTimeLineStart, loadTimeLineSuccess, loadTimeLineFailure } from '../actions';

const loadTimeLine = ({ eventId }) => async (dispatch) => {
  try {
    dispatch(matchShotsActions.loadShotEventsStart());
    dispatch(loadTimeLineStart());

    const { data } = await EventTimeLine.loadEventTimeLine({ eventId });
    const { period_scores: periodScores, time_line: timeLine } = data.results;

    const formattedTimeLineHappenings = formatEventTimeLine(timeLine);
    const formattedPeriodScores = formatTimeLinePeriodScores(periodScores);
    const mappedTimeLineHappenings = mapTimeLineHappenings(
      formattedTimeLineHappenings,
      formattedPeriodScores,
    );

    dispatch(matchShotsThunks.setShotEvents(data.results));
    dispatch(loadTimeLineSuccess(mappedTimeLineHappenings));
  } catch (error) {
    dispatch(loadTimeLineFailure());
    dispatch(matchShotsActions.loadShotEventsFailure());
  }
};

export default loadTimeLine;
