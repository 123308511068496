import React from 'react';

import { Select } from 'packages/shared/components';
import { ArrayUtils } from 'packages/utils';
import { TimeZone } from 'types';

import { getTimeZoneSelectItems, getTimeZoneSelectItem } from './services';

type Props = {
  timeZones: TimeZone[];
  selectedTimeZone: TimeZone;
  handleTimeZoneChange: Function;
};

const TimeZoneSelect = ({ selectedTimeZone, timeZones, handleTimeZoneChange }: Props) => {
  const timeZoneSelectItems = getTimeZoneSelectItems(timeZones);
  const selectedTimeZoneSelectItem = getTimeZoneSelectItem(selectedTimeZone);

  const handleTimeZoneSelectChange = (item) => {
    const selectedTimeZone = ArrayUtils.findBy(timeZones, 'value', item.id);

    handleTimeZoneChange(selectedTimeZone);
  };

  return (
    <Select
      items={timeZoneSelectItems}
      selectedItem={selectedTimeZoneSelectItem}
      onChange={handleTimeZoneSelectChange}
    />
  );
};

export default TimeZoneSelect;
