import React from 'react';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { Advertisement } from 'packages/shared/components';
import { FeaturedOdds } from 'packages/odds-ui';
import { TeamSquad as TeamSquadBlock } from 'packages/team-squad-ui';

import styles from './styles.scss';

const TeamSquad = () => {
  return (
    <Grid.DoubleColumn
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
      isSecondaryOpen={false}
    >
      <div key={GridColumnId.LeftBar} className={styles.leftBar}>
        <TeamSquadBlock.Desktop />
      </div>

      <div key={GridColumnId.Primary}>
        <Advertisement className={styles.advertisement} />
        <FeaturedOdds.Desktop />
      </div>
    </Grid.DoubleColumn>
  );
};

export default TeamSquad;
