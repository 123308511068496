import { Favorites } from 'packages/rest';
import { mapEvents } from 'packages/events/services';
import { settingsSelectors } from 'packages/settings';

import { loadFavoritesStart, loadFavoritesSuccess, loadFavoritesFailure } from '../actions';

interface Parameters {
  leagueIds?: number[];
  teamIds?: number[];
  eventIds?: number[];
}

const loadFavoritesByDate = ({ leagueIds, eventIds, teamIds }: Parameters) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(loadFavoritesStart());

    const activeDate = settingsSelectors.getActiveDate(getState());

    const { data } = await Favorites.loadFavoriteEvents({
      leagueIds,
      eventIds,
      teamIds,
      activeDate,
    });

    const mappedFavoriteEvents = mapEvents(data.results);

    dispatch(loadFavoritesSuccess());

    return mappedFavoriteEvents;
  } catch (e) {
    dispatch(loadFavoritesFailure(e));
  }
};

export default loadFavoritesByDate;
