import React from 'react';

import { Commentary as CommentaryType } from 'packages/event-commentary/types';

import { getHappeningComponentByEventType } from './services';
import styles from './styles.scss';

type Props = {
  comment: CommentaryType;
};

const Commentary = ({ comment }: Props) => {
  const { text, minute, eventType, injuryTime, additionalInfo } = comment;
  const HappeningComponent = getHappeningComponentByEventType(eventType);

  return (
    <div className={styles.commentContainer}>
      <div className={styles.commentChip}>
        <HappeningComponent
          additionalInfo={additionalInfo}
          eventType={eventType}
          minute={minute}
          injuryTime={injuryTime}
        />
      </div>

      <div className={styles.commentText}>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default React.memo(Commentary);
