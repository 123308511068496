import React from 'react';
import classnames from 'classnames';

import { DetailedMatchUp, FlexiblePopover } from 'packages/shared/components';
import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';

import styles from './styles.scss';
import { MatchResultValue } from './types';

const getResultViews = (isClickable) => ({
  L: (
    <div
      className={classnames(styles.matchResult, styles.loss, { [styles.clickable]: isClickable })}
    >
      L
    </div>
  ),
  D: (
    <div
      className={classnames(styles.matchResult, styles.draw, { [styles.clickable]: isClickable })}
    >
      D
    </div>
  ),
  W: (
    <div
      className={classnames(styles.matchResult, styles.win, { [styles.clickable]: isClickable })}
    >
      W
    </div>
  ),
});

type Props = {
  value: MatchResultValue;
  onMatchMouseUp?: Function;
  isClickable?: boolean;
  isPopoverDisabled?: boolean;
};

const MatchResult = ({
  value,
  onMatchMouseUp,
  isClickable = false,
  isPopoverDisabled = false,
  ...popoverProps
}: Props) => {
  const resultViews = getResultViews(isClickable);

  return isClickable ? (
    <FlexiblePopover
      isDisabled={isPopoverDisabled}
      closeOnClickOutside
      popoverProps={{
        ...popoverProps,
        onMouseUp: onMatchMouseUp,
        className: styles.matchResultTooltip,
      }}
      popoverComponent={DetailedMatchUp}
      justify={PopoverJustify.Center}
      align={PopoverAlign.Top}
      invokeTrigger={PopoverInvokeTrigger.Click}
      customPosition={{ top: -4 }}
    >
      <div className={classnames({ [styles.clickableArea]: isClickable })}>
        {resultViews[value]}
      </div>
    </FlexiblePopover>
  ) : (
    resultViews[value]
  );
};

export default React.memo(MatchResult);
