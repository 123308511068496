import Dexie from 'dexie';

import { Tables } from '../../schema';

const onCreate = (dexieInstance: Dexie) => {
  const favoriteLeaguesModel = dexieInstance.table(Tables.FAVORITE_LEAGUES);

  favoriteLeaguesModel.hook('creating', async (primaryKey, record) => {
    const lastLeagueRecord = await favoriteLeaguesModel.orderBy('orderNumber').last();

    record.orderNumber = lastLeagueRecord?.orderNumber || 10;

    return record;
  });
};

export default [onCreate];
