import { SeasonTopBoard } from 'packages/rest';

import { PlayersParameter } from '../types';
import { PLAYER_API_ORDERING_BY_PARAMETERS } from '../constants';
import * as services from '../services';
import * as actions from '../actions';

export const loadSeasonTopPlayers = (seasonId: number, parameter: PlayersParameter) => async (
  dispatch,
) => {
  dispatch(actions.loadTopPlayersStart());

  try {
    const { data } = await SeasonTopBoard.loadSeasonTopPlayers({
      seasonId,
      ordering: services.parseOrderingParams(PLAYER_API_ORDERING_BY_PARAMETERS[parameter]),
    });
    const topPlayers = services.mapSeasonTopPlayers(data.results);

    dispatch(actions.loadTopPlayersSuccess(topPlayers));
  } catch (e) {
    dispatch(actions.loadTopPlayersFailure(e));
  }
};
