import i18n from 'i18n-smart';

import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';
import { StatType } from 'packages/season-team-statistics/types';

const getStatTabs = (): NavigationMenuItem[] => [
  {
    id: StatType.All,
    name: i18n.value('season.teamStatistics.customFilter.StatType.all'),
  },
  {
    id: StatType.PerGame,
    name: i18n.value('season.teamStatistics.customFilter.StatType.perGame'),
  },
];

export default getStatTabs;
