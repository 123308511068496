import * as seasonTopBoardActions from './actions';
import * as seasonTopBoardConstants from './constants';
import * as seasonTopBoardThunks from './thunks';
import {
  topTeamsSelectors,
  topPlayersSelectors,
  topVenuesSelectors,
  topOfTheSeasonSelectors,
  compactPlayersSelectors,
} from './selectors';
import reducers from './reducers';
import { configure } from './config';

const createReducer = (options?: object) => {
  configure(options);

  return reducers;
};

export {
  seasonTopBoardActions,
  seasonTopBoardConstants,
  seasonTopBoardThunks,
  topTeamsSelectors,
  topPlayersSelectors,
  topVenuesSelectors,
  topOfTheSeasonSelectors,
  compactPlayersSelectors,
  createReducer,
};
