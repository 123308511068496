import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import i18n from 'i18n-smart';
import isEmpty from 'is-empty-typed/lib';

import { MobilePage } from 'router/types';
import {
  Button,
  FlexiblePopover,
  Icon,
  NoDataLabel,
  Select,
  SelectItemComponent,
  SelectLabelComponent,
} from 'packages/shared/components';
import { Stage } from 'packages/event-standings/types';
import { SelectItem, SelectSize } from 'packages/shared/components/Select/types';
import { useNavigation } from 'packages/hooks';
import {
  SeasonsExtendedTournament,
  TournamentSeasonSelectItems,
} from 'packages/shared/components/TournamentSeasonSelect/types';
import {
  useActiveTabContentElement,
  useFilterTabs,
  useStageTabs,
} from 'packages/season-standings-ui/hooks';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';
import { ButtonType } from 'packages/shared/components/Button/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { NarrowTeamStatisticsTable } from 'packages/shared/modules';
import { MediaScreen } from 'types';

import { getBracketProps, getFilterTabs, getTableProps } from './services';
import { FiltrationPopover } from '../../components';
import { HeaderSkeleton } from './components';
import connect from './connect';
import styles from './styles.scss';
import { getScrollToStageTable } from '../../services';

type Props = {
  tournaments?: SeasonsExtendedTournament[];
  activeTournamentId?: number;
  activeSeasonId?: number;
  stages: Stage[] | null;
  tournamentSeasonSelectItems: TournamentSeasonSelectItems;
  mobileFilterStagesActions: ActionCreatorsMapObject;
  defaultStageTabId?: number | string;
  defaultFilterTabId?: number | string;
  teamId?: string;
  tournamentId?: string;
  seasonId?: string;
  isLoading?: boolean;
};

const Standings = ({
  stages,
  activeTournamentId,
  activeSeasonId,
  tournaments = [],
  tournamentSeasonSelectItems,
  mobileFilterStagesActions,
  defaultStageTabId,
  defaultFilterTabId,
  teamId,
  tournamentId,
  seasonId,
  isLoading = false,
}: Props) => {
  if (isLoading) {
    return (
      <div className={styles.tabsContainer}>
        <HeaderSkeleton />
        <div className={styles.content}>
          <NarrowTeamStatisticsTable.Mobile isLoading />
        </div>
      </div>
    );
  }

  const { tournamentItems, activeTournamentItem } = tournamentSeasonSelectItems;
  const headerBlockRef = useRef<HTMLDivElement>(null);
  const { appNavigate } = useNavigation(MediaScreen.Mobile);
  const containerRef = useRef<HTMLDivElement>(null);
  const [popoverTopOffset, setPopoverTopOffset] = useState(0);

  useEffect(() => {
    mobileFilterStagesActions.resetStages();
  }, [stages]);

  useEffect(() => {
    const { top = 0 } = containerRef?.current?.getBoundingClientRect() || {};

    setPopoverTopOffset(top);

    return () => {
      mobileFilterStagesActions.cleanMobileFilter();
    };
  }, []);

  const StageTabs = useStageTabs(stages, {
    getTableProps,
    getBracketProps,
    additionalProps: {
      activeTeamIds: teamId ? [parseInt(teamId)] : undefined,
      activeUniqueTournamentId: activeTournamentId,
      teamIdToScroll: teamId ? parseInt(teamId) : undefined,
      activeSeasonId,
      scrollToTable: getScrollToStageTable(headerBlockRef),
    },
    defaultActiveTabId: defaultStageTabId,
  });
  const FilterTabs = useFilterTabs(stages, {
    getFilterTabs,
    activeStageTab: StageTabs.activeTab,
    defaultActiveTabId: defaultFilterTabId,
  });
  const activeContentElement = useActiveTabContentElement(
    StageTabs.activeTab,
    FilterTabs.activeTab,
  );

  const handleTournamentChange = useCallback(
    (tournamentItem: SelectItem) => {
      if (teamId) {
        const [firstSelectedTournamentSeason] =
          tournaments.find(({ id }) => id === tournamentItem.id)?.seasons || [];

        appNavigate(MobilePage.FootballTeamStandings, {
          uniqueTournamentId: tournamentItem.id,
          seasonId: firstSelectedTournamentSeason.id,
          teamId,
        });
      }
    },
    [tournaments, teamId],
  );

  const handleFilterUpdate = useCallback(
    (tournamentId, seasonId) => {
      if (teamId) {
        appNavigate(MobilePage.FootballTeamStandings, {
          uniqueTournamentId: tournamentId,
          seasonId: seasonId,
          teamId,
        });
      }
    },
    [teamId],
  );

  const selectedTournamentItem = activeTournamentItem || tournamentItems[0];

  return (
    <div className={styles.tabsContainer} ref={containerRef}>
      <div ref={headerBlockRef} className={styles.header}>
        <div className={styles.leftFilterGroup}>
          <div className={styles.title}>{i18n.value('team.standings.title')}</div>
          <Select
            items={tournamentItems}
            size={SelectSize.Lg}
            selectedItem={selectedTournamentItem}
            onChange={handleTournamentChange}
            labelComponentProps={selectedTournamentItem?.componentProps}
            labelComponent={SelectLabelComponent.Tournament}
            itemComponent={SelectItemComponent.Tournament}
          />
        </div>
        <div className={styles.rightFilterGroup}>
          <FlexiblePopover
            popoverProps={{
              tournaments,
              tournamentId,
              seasonId,
              teamId,
              tournamentSeasonSelectItems,
              activeStageTabId: StageTabs.activeTab?.id,
              activeFilterTabId: FilterTabs.activeTab?.id,
              onFilterUpdate: handleFilterUpdate,
            }}
            popoverComponent={FiltrationPopover}
            justify={PopoverJustify.Center}
            align={PopoverAlign.CenterStart}
            customPosition={{ top: popoverTopOffset }}
            disableBodyOverflow
            useChildrenWrapper
            useBackdrop
            isModal
          >
            <Button type={ButtonType.Default} contentClassName={styles.settingsButtonContent}>
              <Icon id={IconType.Configuration} className={styles.icon} />
            </Button>
          </FlexiblePopover>
        </div>
      </div>
      <div className={styles.content}>
        {!isEmpty(activeContentElement) ? activeContentElement : <NoDataLabel />}
      </div>
    </div>
  );
};

export default connect(memo(Standings));
