import { Event } from 'packages/events/types';

export const getEventsAfterLiveInsert = (
  oldEvents: Event[],
  newEvents: { [key: number]: Event },
) => {
  const insertingEvents = Object.values(newEvents);

  return [...oldEvents, ...insertingEvents];
};
