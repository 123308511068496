import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import {
  loadTeamDetailsStart,
  loadTeamDetailsSuccess,
  loadTeamDetailsFailure,
  cleanTeamDetailsSuccess,
} from '../actions';
import { Status } from '../types';

const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${loadTeamDetailsStart}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(loadTeamDetailsFailure, loadTeamDetailsSuccess)}`]: () => {
    return { isLoading: false };
  },
  [`${cleanTeamDetailsSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
