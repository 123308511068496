import React, { memo, useCallback, useMemo, useRef, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import i18n from 'i18n-smart';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

import { Team } from 'types';
import { Select, SelectItemComponent, SelectLabelComponent } from 'packages/shared/components';
import { SelectItem, SelectSize } from 'packages/shared/components/Select/types';
import { Interfaces } from 'packages/player-summary/types';

import { getTournamentSeasonSelectItems } from './services';
import { mapTeamsToSelectItems } from '../../../../../../services';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  teams: Team[];
  tournamentSeasons?: Interfaces.Statistics.TournamentSeasons.Data;
  tournamentSeasonsActions: ActionCreatorsMapObject;
  className?: string;
  isSeasonTournamentsLoading: boolean;
  isStatisticsLoading: boolean;
};

const TabsNavigationLabel = ({
  teams,
  tournamentSeasonsActions,
  tournamentSeasons,
  className,
  isSeasonTournamentsLoading,
  isStatisticsLoading,
}: Props) => {
  const { playerId } = useParams();
  const containerRef = useRef<HTMLDivElement>(null);
  const teamItems = useMemo(() => mapTeamsToSelectItems(teams), [teams]);
  const [selectedTeam, setSelectedTeam] = useState<SelectItem>(teamItems[0]);

  const { tournaments = [], selectedTournamentId, selectedSeasonId } = tournamentSeasons || {};
  const { tournamentItems, seasonItems, activeSeasonItem, activeTournamentItem } = useMemo(
    () => getTournamentSeasonSelectItems(tournaments, selectedTournamentId, selectedSeasonId),
    [tournaments, selectedTournamentId, selectedSeasonId],
  );

  const handleTeamChange = useCallback(
    (team: SelectItem) => {
      setSelectedTeam(team);

      tournamentSeasonsActions.loadTournamentSeasons({ playerId, teamId: team.id });
    },
    [playerId],
  );

  const handleTournamentChange = useCallback(
    (tournamentItem: SelectItem) => {
      const { seasons } = tournaments.find(({ id }) => id === tournamentItem.id) || {};
      const [firstSeason] = seasons || [];

      tournamentSeasonsActions.selectTournamentSeason({
        tournamentId: tournamentItem.id,
        seasonId: firstSeason.id,
      });
    },
    [tournaments],
  );

  const handleSeasonChange = useCallback(
    (seasonItem: SelectItem) => {
      tournamentSeasonsActions.selectTournamentSeason({
        tournamentId: activeTournamentItem?.id,
        seasonId: seasonItem.id,
      });
    },
    [activeTournamentItem?.id],
  );

  const tournamentLabelComponentProps = activeTournamentItem?.componentProps || { name: 'League' };

  return (
    <div ref={containerRef} className={classnames(styles.navigationLabelContainer, className)}>
      <div className={styles.title}>{i18n.value('player.playerSummary.statistics.title')}</div>
      <Select
        itemsContainerClass={styles.selectItemsContainer}
        className={styles.teamSelect}
        size={SelectSize.Md}
        selectedItem={selectedTeam}
        onChange={handleTeamChange}
        items={teamItems}
        isDisabled={isStatisticsLoading || isSeasonTournamentsLoading}
      />
      <Select
        itemsContainerClass={styles.selectItemsContainer}
        isLoading={isSeasonTournamentsLoading}
        isDisabled={!activeTournamentItem || isStatisticsLoading}
        selectedItem={activeTournamentItem}
        items={tournamentItems}
        size={SelectSize.Md}
        onChange={handleTournamentChange}
        className={styles.leagueSelect}
        labelComponentProps={tournamentLabelComponentProps}
        labelComponent={SelectLabelComponent.Tournament}
        itemComponent={SelectItemComponent.Tournament}
      />
      <Select
        itemsContainerClass={styles.selectItemsContainer}
        isLoading={isSeasonTournamentsLoading}
        placeholder="Season"
        isDisabled={!activeSeasonItem || isStatisticsLoading}
        selectedItem={activeSeasonItem}
        size={SelectSize.Sm}
        items={seasonItems}
        onChange={handleSeasonChange}
        className={styles.seasonSelect}
      />
    </div>
  );
};

export default connect(memo(TabsNavigationLabel));
