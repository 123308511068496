import { RefObject, useEffect, useState } from 'react';

const useElementOverflow = <T extends HTMLElement>(elementRef: RefObject<T>): boolean => {
  const [isElementOverflow, setIsElementOverflow] = useState<boolean>(false);

  useEffect(() => {
    const { scrollWidth = 0, offsetWidth = 0 } = elementRef.current || {};

    setIsElementOverflow(scrollWidth > offsetWidth);
  }, []);

  return isElementOverflow;
};

export default useElementOverflow;
