import { squadActions } from '../actions';

interface Params {
  offset: number;
  limit: number;
}

const updatePagination = (params: Params) => (dispatch) => {
  dispatch(squadActions.updatePaginationSuccess(params));
};

export default updatePagination;
