exports = module.exports = require("../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1fbss5x6{flex:1 1;margin-right:16px}._3zqU9Ld0{width:443px}._2lBbzXjj{flex:1 1;display:flex;align-items:flex-start;justify-content:center}._V51ZuVb2{margin-bottom:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/pages/desktop/football/TeamSquad/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,QAAO,CACP,iBCJS,CDCb,WAOI,WAAY,CAPhB,WAWI,QAAO,CACP,YAAa,CACb,sBAAuB,CACvB,sBAAuB,CAd3B,WAkBI,kBCnBS","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .left-column {\n    flex: 1;\n    margin-right: $margin;\n  }\n\n  .right-column {\n    width: 443px;\n  }\n\n  .left-bar {\n    flex: 1;\n    display: flex;\n    align-items: flex-start;\n    justify-content: center;\n  }\n\n  .advertisement {\n    margin-bottom: $margin;\n  }\n}","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"left-column": "_1fbss5x6",
	"leftColumn": "_1fbss5x6",
	"right-column": "_3zqU9Ld0",
	"rightColumn": "_3zqU9Ld0",
	"left-bar": "_2lBbzXjj",
	"leftBar": "_2lBbzXjj",
	"advertisement": "_V51ZuVb2"
};