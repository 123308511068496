import getPath from './getPath';

export const getData = (state) => {
  return getPath(state).data;
};

export const getResults = (state) => {
  return getData(state).results;
};

export const getSearchKey = (state) => {
  return getData(state).searchKey;
};
