const calculateAlignByParent = (
  offsetTop: number,
  popoverHeight: number,
  parentRect: ClientRect,
) => {
  if (offsetTop < parentRect.top) {
    offsetTop = parentRect.top;
  } else if (offsetTop + popoverHeight > parentRect.bottom) {
    offsetTop = parentRect.bottom - popoverHeight;
  }

  return offsetTop;
};

export default calculateAlignByParent;
