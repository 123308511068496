exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1ZH9KOgU{display:flex;flex-direction:column;width:100%;height:100%}._1ZH9KOgU ._2EWwZoso{padding:8px 16px 0 16px}._1ZH9KOgU ._1bA5HBxm,._1ZH9KOgU ._J9zvYw0v{margin-top:12px}._1ZH9KOgU ._1vbDzL0U>*{margin-top:8px}._1ZH9KOgU ._3Q3dxNM8{margin-top:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/stadium-summary-ui/containers/Summary/views/Mobile/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/vars/offsets.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,qBAAsB,CAEtB,UAAW,CACX,WAAY,CANhB,sBASM,uBCXQ,CDEd,4CAaM,eAAgB,CAbtB,wBAkBQ,cAAe,CAlBvB,sBAuBM,eCxBO","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .team-summary {\n    display: flex;\n    flex-direction: column;\n\n    width: 100%;\n    height: 100%;\n\n    .details-selector {\n      padding: 8px $padding 0 $padding;\n    }\n\n    .games, .loader-container {\n      margin-top: 12px;\n    }\n\n    .details {\n      > * {\n        margin-top: 8px;\n      }\n    }\n\n    .home-teams {\n      margin-top: $margin;\n    }\n  }\n}\n","$padding: 16px;\n$margin: 16px;\n$container-border-radius: 8px;\n"]}]);

// Exports
exports.locals = {
	"team-summary": "_1ZH9KOgU",
	"teamSummary": "_1ZH9KOgU",
	"details-selector": "_2EWwZoso",
	"detailsSelector": "_2EWwZoso",
	"games": "_1bA5HBxm",
	"loader-container": "_J9zvYw0v",
	"loaderContainer": "_J9zvYw0v",
	"details": "_1vbDzL0U",
	"home-teams": "_3Q3dxNM8",
	"homeTeams": "_3Q3dxNM8"
};