import { keyValueDatabase } from 'packages/storage';
import { SettingsStorageKey } from 'packages/settings/types';
import { settingsThunks } from 'packages/settings';

import * as actions from '../actions';
import * as thunks from '../thunks';
import * as selectors from '../selectors';

export const configureLanguage = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const lastUsedLocaleCode = await keyValueDatabase.get(
      SettingsStorageKey.LocaleCode,
      keyValueDatabase.tables.userSettings,
    );
    const localeCode = lastUsedLocaleCode || selectors.getDefaultLocaleCode(state);

    dispatch(thunks.configureDateService(localeCode));
    dispatch(settingsThunks.setLocale(localeCode));
  } catch (e) {
    dispatch(actions.failure(e));
  }
};
