import React from 'react';
import { Column } from 'react-table';

import { Skeleton } from 'packages/shared/components';

import { Team } from '../components';

const centerAlignStyle = {
  display: 'flex',
  justifyContent: 'center',
};

const getTableSchema = (): Column[] => {
  return [
    {
      id: 'team',
      Header: <Skeleton width={30} variant="rectangular" height={8} />,
      headerStyle: { padding: '12px 0 12px 16px' },
      Cell: Team,
      width: 300,
    },
    {
      id: 'trophies',
      Header: <Skeleton width={50} variant="rectangular" height={8} />,
      headerStyle: { ...centerAlignStyle, padding: '12px 0' },
      cellStyle: centerAlignStyle,
      Cell: <Skeleton variant="rectangular" height={8} />,
      width: 80,
    },
  ];
};

export default getTableSchema;
