import React, { memo, useCallback, useEffect, useMemo, useRef } from 'react';

import {
  PlayerStatistics,
  Ordering,
  Column,
  ColumnType,
  PresetType,
  StatType,
  Player,
} from 'packages/season-player-statistics/types';
import { Pagination } from 'packages/shared/components';
import { PlayerStatisticsTable } from 'packages/shared/modules';
import { Team } from 'types';
import { DEFAULT_PLAYERS_LIMIT } from 'packages/season-player-statistics/constants';

import { getActivePresetItem, getPresetItems } from '../../../../services';
import { ControlHeader, SkeletonHeader } from './components';
import styles from './styles.scss';

type Props = {
  onOrderingChange: (columnType: ColumnType) => void;
  onPresetChange: (preset: PresetType) => void;
  onTeamClick: (team: Team) => void;
  onPlayerClick: (player: Player) => void;
  onPaginationChange: ({ offset, limit }) => void;
  onCustomizeButtonClick: () => void;
  playersStatistics: PlayerStatistics[];
  ordering: Ordering;
  columns: Column[];
  activeStatsTab: StatType;
  activePreset: PresetType;
  amountOfPlayers: number;
  currentPageSize: number;
  isInitialPlayersPageLoading: boolean;
  isNewPlayersPageLoading: boolean;
};

const MainScreen = ({
  onCustomizeButtonClick,
  onOrderingChange,
  onPresetChange,
  onPaginationChange,
  playersStatistics,
  isInitialPlayersPageLoading,
  isNewPlayersPageLoading,
  activeStatsTab,
  activePreset,
  amountOfPlayers,
  currentPageSize,
  ordering,
  columns,
  onPlayerClick,
  onTeamClick,
}: Props) => {
  const isLimitSelectable = amountOfPlayers > DEFAULT_PLAYERS_LIMIT;
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const presetItems = useMemo(getPresetItems, []);
  const activePresetItem = useMemo(() => getActivePresetItem({ presetItems, activePreset }), [
    activePreset,
    presetItems,
  ]);

  useEffect(() => {
    scrollContainerToTop();
  }, [playersStatistics]);

  const scrollContainerToTop = () => {
    tableContainerRef.current?.scrollTo({ top: 0 });
  };

  const handlePresetItemSelect = useCallback((presetType: PresetType) => {
    onPresetChange(presetType);
    scrollContainerToTop();
  }, []);

  return (
    <div className={styles.mainScreenContainer}>
      {isInitialPlayersPageLoading ? (
        <SkeletonHeader />
      ) : (
        <ControlHeader
          onPresetItemSelect={handlePresetItemSelect}
          onCustomizeButtonClick={onCustomizeButtonClick}
          activePresetItem={activePresetItem}
          presetItems={presetItems}
          className={styles.controlHeader}
        />
      )}

      <PlayerStatisticsTable.Desktop
        onPlayerClick={onPlayerClick}
        onTeamClick={onTeamClick}
        onOrderingChange={onOrderingChange}
        activeStatType={activeStatsTab}
        isInitialPlayersLoading={isInitialPlayersPageLoading}
        isPlayersLoading={isNewPlayersPageLoading}
        playersStatistics={playersStatistics}
        pageSize={currentPageSize}
        ordering={ordering}
        columns={columns}
        forwardedRef={tableContainerRef}
        className={styles.statisticsTable}
      />

      <Pagination
        isLoading={isInitialPlayersPageLoading}
        resultsLabel="common.pagination.playersLabel"
        isLimitSelectable={isLimitSelectable}
        amountOfItems={amountOfPlayers}
        pageSize={currentPageSize}
        onPaginationChange={onPaginationChange}
        className={styles.paginationContainer}
        isLabelVisible
      />
    </div>
  );
};

export default memo(MainScreen);
