import React, { useCallback, useMemo, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import isEmpty from 'is-empty-typed/lib';
import i18n from 'i18n-smart';

import { PlayersParameter, TopPlayer } from 'packages/season-top-board/types';
import { PLAYER_DEFAULT_ORDERING } from 'packages/season-top-board/constants';
import { BlockLink, Tabs } from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { useNavigation, useNonInitialEffect } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import { getInnerTabs, getPlayerGoals as getGoals } from '../../../../../../services';
import { getPlayerRowProps, getAggression, getAttack } from './services';
import { SortingValueStateContext } from '../../../../../../constants';
import connect from './connect';

type Props = {
  seasonTopBoardActions: ActionCreatorsMapObject;
  seasonId: string;
  uniqueTournamentId: string;
  players: TopPlayer[];
  isTopPlayersLoading: boolean;
};

const TopPlayers = ({
  players,
  seasonTopBoardActions,
  seasonId,
  uniqueTournamentId,
  isTopPlayersLoading,
}: Props) => {
  const { appNavigate } = useNavigation();

  const sortingValueState = useState<PlayersParameter>(PlayersParameter.Goals);
  const activeTabs = useMemo(() => getInnerTabs({ getAggression, getAttack, getGoals }), [
    seasonId,
  ]);

  const [sortingValue, setSortingValue] = sortingValueState;

  useNonInitialEffect(() => {
    seasonTopBoardActions.loadSeasonTopPlayers(seasonId, sortingValue);
  }, [sortingValue, seasonId]);

  const handleTabChange = useCallback((tabId: string | number) => {
    setSortingValue(PLAYER_DEFAULT_ORDERING[tabId]);
  }, []);

  const handleRowClick = useCallback((playerId: number) => {
    appNavigate(DesktopPage.FootballPlayer, { playerId });
  }, []);

  const handleLinkClick = useCallback(() => {
    appNavigate(DesktopPage.FootballSeasonPlayerStatistics, { seasonId, uniqueTournamentId });
  }, [seasonId, uniqueTournamentId]);

  const contentComponentProps = useMemo(
    () => ({
      setRowProps: getPlayerRowProps({ onClick: handleRowClick }),
      rows: players,
      isLoading: isTopPlayersLoading,
    }),
    [handleRowClick, players, isTopPlayersLoading],
  );

  return (
    <SortingValueStateContext.Provider value={sortingValueState}>
      <Tabs
        tabs={activeTabs}
        theme={NavigationTheme.Button}
        onTabChange={handleTabChange}
        isNavigationMenuDisabled={isEmpty(players)}
        contentComponentProps={contentComponentProps}
        isNavigationWidthLimited
      />
      <BlockLink
        label={i18n.value('season.seasonTopBoard.tabs.showAll.players')}
        isDisabled={isEmpty(players)}
        onClick={handleLinkClick}
        useHorizontalPadding
      />
    </SortingValueStateContext.Provider>
  );
};

export default connect(TopPlayers);
