import {
  SHADOWS_BASE_INVERSION_COLOR,
  SHADOWS_INVERSION_REPLACEMENT_COLOR,
  STROKE_BASE_INVERSION_COLOR,
} from '../constants';
import { Jersey, JerseyElementId } from '../types';

export interface Options {
  useJerseyOutline?: boolean;
}

const customizeJersey = (
  jerseyContainer: HTMLDivElement,
  jersey: Jersey,
  options: Options = {},
) => {
  const { base, sleeve, squares, stripes } = jersey;
  const { useJerseyOutline = false } = options;

  const baseElement = jerseyContainer.querySelector(`#${JerseyElementId.Base}`);
  const sleveElement = jerseyContainer.querySelector(`#${JerseyElementId.Sleve}`);
  const stripesElement = jerseyContainer.querySelector(`#${JerseyElementId.Stripes}`);
  const squaresElement = jerseyContainer.querySelector(`#${JerseyElementId.Squares}`);

  baseElement?.setAttribute('fill', base);
  sleveElement?.setAttribute('fill', sleeve);

  if (stripes) {
    stripesElement?.setAttribute('fill', stripes);
  } else if (squares) {
    squaresElement?.setAttribute('fill', squares);
  }

  if (useJerseyOutline) {
    if (base === STROKE_BASE_INVERSION_COLOR || sleeve === STROKE_BASE_INVERSION_COLOR) {
      invertStroke(jerseyContainer);
    }

    if (base === SHADOWS_BASE_INVERSION_COLOR) {
      invertShadows(jerseyContainer);
    }
  }
};

const invertShadows = (jerseyContainer: HTMLDivElement) => {
  const shadowsElement = jerseyContainer.querySelector(`#${JerseyElementId.Shadows}`);
  const collarElement = jerseyContainer.querySelector(`#${JerseyElementId.Collar}`);

  shadowsElement?.setAttribute('fill', SHADOWS_INVERSION_REPLACEMENT_COLOR);
  collarElement?.setAttribute('fill', SHADOWS_INVERSION_REPLACEMENT_COLOR);
};

const invertStroke = (jerseyContainer: HTMLDivElement) => {
  const strokeElement = jerseyContainer.querySelector(`#${JerseyElementId.Stroke}`);

  strokeElement?.setAttribute('fill-opacity', '1');
};

export default customizeJersey;
