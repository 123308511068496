import React from 'react';
import classnames from 'classnames';

import { Icon } from 'packages/shared/components';

import { PLAYER_HAPPENING_ICON_MAP } from '../../../../../../constants';
import { PlayerHappeningType } from '../../../../../../types';
import styles from './styles.scss';

type Props = {
  count: number;
  type: PlayerHappeningType;
  className?: string;
};

const HappeningBadge = ({ count, type, className }: Props) => {
  const iconType = PLAYER_HAPPENING_ICON_MAP[type];

  return (
    <div className={classnames(styles.badge, className)}>
      {count > 1 && <div className={classnames(styles.badge, styles.counter)}>{count}</div>}
      {iconType && <Icon className={styles.icon} id={iconType} />}
    </div>
  );
};

export default React.memo(HappeningBadge);
