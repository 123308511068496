import React from 'react';
import classnames from 'classnames';

import { LineupRowsExtendedCompetitor } from 'packages/event-lineups/types';
import { Lineup } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  homeCompetitor: LineupRowsExtendedCompetitor;
  awayCompetitor: LineupRowsExtendedCompetitor;
  className?: string;
};

const TeamLineup = ({ homeCompetitor, awayCompetitor, className }: Props) => {
  return (
    <div className={classnames(styles.lineupsContainer, className)}>
      <Lineup
        className={styles.lineup}
        lineupInfoForm={homeCompetitor.lineupRows}
        playerTypeJersey={homeCompetitor.playerTypeJersey}
      />
      <Lineup
        className={styles.lineup}
        lineupInfoForm={awayCompetitor.lineupRows.reverse()}
        playerTypeJersey={awayCompetitor.playerTypeJersey}
        isReversed
      />
    </div>
  );
};

export default React.memo(TeamLineup);
