import React, { memo, useRef } from 'react';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { Description, Summary } from 'packages/player-summary-ui';
import { useSearchParams } from 'react-router-dom';
import { EventDetails } from 'packages/event-details-ui';
import { Advertisement } from 'packages/shared/components';
import { SearchQuery } from 'router/types';

import { ACTUAL_GAMES_LIMIT } from './constants';
import styles from './styles.scss';

const PlayerSummary = () => {
  const [searchParams] = useSearchParams();
  const eventId = searchParams.get(SearchQuery.EventCardId);
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Grid.DoubleColumn
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
      isSecondaryOpen={false}
    >
      <div ref={containerRef} key={GridColumnId.LeftBar} className={styles.leftBar}>
        <Summary.DesktopNarrow
          parentContainerRef={containerRef}
          actualGamesLimit={ACTUAL_GAMES_LIMIT}
        />
      </div>
      <div className={styles.sideBar} key={GridColumnId.Primary}>
        {eventId ? (
          <EventDetails.DesktopNarrow eventId={eventId} />
        ) : (
          <>
            <Description.DesktopNarrow />
            <Advertisement className={styles.advertisement} />
          </>
        )}
      </div>
    </Grid.DoubleColumn>
  );
};

export default memo(PlayerSummary);
