import { TabletNarrowPage } from '../enums';

export interface TabletNarrowPathParams {
  [TabletNarrowPage.Football]: {};

  [TabletNarrowPage.FootballSeason]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [TabletNarrowPage.FootballSeasonPlayerStatistics]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [TabletNarrowPage.FootballSeasonTeamStatistics]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [TabletNarrowPage.FootballSeasonStadiums]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [TabletNarrowPage.FootballSeasonGames]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [TabletNarrowPage.FootballTeam]: {
    teamId: string | number;
  };
  [TabletNarrowPage.FootballTeamGames]: {
    teamId: string | number;
  };
  [TabletNarrowPage.FootballTeamSquad]: {
    teamId: string | number;
  };
  [TabletNarrowPage.FootballTeamSummaryDescription]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
    teamId: string | number;
  };
  [TabletNarrowPage.FootballTeamStandings]: {
    teamId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [TabletNarrowPage.FootballTeamPlayerStatistics]: {
    teamId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [TabletNarrowPage.FootballCategory]: {
    categoryId: string | number;
  };

  [TabletNarrowPage.FootballPlayer]: {
    playerId: string | number;
  };
  [TabletNarrowPage.FootballPlayerGames]: {
    playerId: string | number;
  };

  [TabletNarrowPage.FootballStadium]: {
    stadiumId: string | number;
  };
  [TabletNarrowPage.FootballStadiumSummary]: {
    stadiumId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
}
