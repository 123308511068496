exports = module.exports = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._2nxtYwBz{display:flex;align-items:center;flex-grow:1}._2nxtYwBz ._t9OcLlVH{display:flex;align-items:center;flex-grow:1;margin:0 6px;justify-content:flex-end}._2nxtYwBz ._t9OcLlVH ._CjTySz_o{flex-grow:1}._2nxtYwBz._1Rfz_IO0{margin-right:12px;justify-content:flex-start}._2nxtYwBz._1Rfz_IO0 ._t9OcLlVH{flex-direction:row-reverse}._2nxtYwBz:not(._1Rfz_IO0){margin-left:12px;justify-content:flex-end}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/events-ui/components/EventLoadingSkeleton/components/Team/styles.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,kBAAmB,CACnB,WAAY,CAJhB,sBAOM,YAAa,CACb,kBAAmB,CACnB,WAAY,CACZ,YAAa,CACb,wBAAyB,CAX/B,iCAcQ,WAAY,CAdpB,qBAmBM,iBAAkB,CAClB,0BAA2B,CApBjC,gCAuBQ,0BAA2B,CAvBnC,2BA4BM,gBAAiB,CACjB,wBAAyB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\n\n:local {\n  .container {\n    display: flex;\n    align-items: center;\n    flex-grow: 1;\n\n    .wrapper {\n      display: flex;\n      align-items: center;\n      flex-grow: 1;\n      margin: 0 6px;\n      justify-content: flex-end;\n\n      .team-label {\n        flex-grow: 1;\n      }\n    }\n\n    &.reversed {\n      margin-right: 12px;\n      justify-content: flex-start;\n\n      .wrapper {\n        flex-direction: row-reverse;\n      }\n    }\n\n    &:not(.reversed) {\n      margin-left: 12px;\n      justify-content: flex-end;\n    }\n  }\n}"]}]);

// Exports
exports.locals = {
	"container": "_2nxtYwBz",
	"wrapper": "_t9OcLlVH",
	"team-label": "_CjTySz_o",
	"teamLabel": "_CjTySz_o",
	"reversed": "_1Rfz_IO0"
};