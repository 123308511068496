import React, { memo, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { Button, Icon } from 'packages/shared/components';
import { ButtonSize } from 'packages/shared/components/Button/types';
import { IconType } from 'packages/shared/components/Icon/types';

import { buildLabelParameters } from './services';
import { usePages } from './hooks';
import { LimitSelect } from './components';
import { DEFAULT_LIMIT_OPTIONS } from './constants';
import { ControlProps, PaginationParams } from '../../types';
import styles from './styles.scss';

const Controls = ({
  amountOfItems,
  pageSize,
  onPaginationChange,
  className,
  limitLabel,
  limitOptions = DEFAULT_LIMIT_OPTIONS,
  resultsLabel = 'common.pagination.resultsLabel',
  isLabelVisible,
  isLimitSelectable,
  useRightAlignment,
}: ControlProps) => {
  const amountOfPages = useMemo(() => Math.ceil(amountOfItems / pageSize), [
    amountOfItems,
    pageSize,
  ]);
  const { controllablePageIndexes, activePageIndex, pages, updatePages } = usePages(amountOfPages);
  const labelParameters = useMemo(
    () => buildLabelParameters(activePageIndex, pageSize, amountOfItems),
    [activePageIndex, pageSize, amountOfItems],
  );
  const handlePaginationChange = (params: PaginationParams) => {
    onPaginationChange(params);
  };

  const handleLimitSelect = (limit: number) => {
    onPaginationChange({ offset: 0, limit });
  };

  const handleNextPageClick = () => {
    const updatedActivePageIndex = activePageIndex + 1;

    updatePages(updatedActivePageIndex);
    handlePaginationChange({
      offset: updatedActivePageIndex * pageSize,
      limit: pageSize,
    });
  };

  const handlePreviousPageClick = () => {
    const updatedActivePageIndex = activePageIndex - 1;

    updatePages(updatedActivePageIndex);
    handlePaginationChange({
      offset: updatedActivePageIndex * pageSize,
      limit: pageSize,
    });
  };

  const getPageClickHandler = useCallback(
    (pageIndex: number) => () => {
      if (activePageIndex === pageIndex) {
        return;
      }

      updatePages(pageIndex);
      handlePaginationChange({
        offset: pageIndex * pageSize,
        limit: pageSize,
      });
    },
    [activePageIndex, updatePages, handlePaginationChange, pageSize],
  );

  return (
    <div className={classnames(styles.paginationContainer, className)}>
      {isLimitSelectable && !!pageSize && (
        <div className={styles.limitSelector}>
          <span className={styles.selectorLabel}>
            {i18n.value(limitLabel || 'common.pagination.limit_label')}:
          </span>
          <LimitSelect
            onChange={handleLimitSelect}
            selectedLimit={pageSize}
            limits={limitOptions}
          />
        </div>
      )}

      {isLabelVisible && (
        <div className={styles.label}>
          {i18n.value('common.pagination.resultsAmountLabel', labelParameters)}{' '}
          {i18n.pluralize(resultsLabel, { value: amountOfItems })}
        </div>
      )}
      <div
        className={classnames(styles.pagesContainer, {
          [styles.rightAlignment]: useRightAlignment,
        })}
      >
        <Button
          onClick={handlePreviousPageClick}
          size={ButtonSize.Md}
          isDisabled={activePageIndex === 0}
          className={classnames(styles.button, styles.controlButton)}
        >
          <Icon id={IconType.Arrow} className={styles.arrowIcon} />
        </Button>
        {controllablePageIndexes.map((pageIndex) => (
          <Button
            isActive={pageIndex === activePageIndex}
            onClick={getPageClickHandler(pageIndex)}
            className={styles.button}
            key={pageIndex}
          >
            {pages[pageIndex]}
          </Button>
        ))}
        <Button
          onClick={handleNextPageClick}
          size={ButtonSize.Md}
          isDisabled={activePageIndex === pages.length - 1}
          className={classnames(styles.button, styles.controlButton)}
        >
          <Icon id={IconType.Arrow} className={classnames(styles.arrowIcon, styles.reversed)} />
        </Button>
      </div>
    </div>
  );
};

export default memo(Controls);
