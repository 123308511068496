import React, { memo } from 'react';

import styles from './styles.scss';

type Props = {
  year: string;
};

const SeasonCell = ({ year }: Props) => {
  return (
    <div className={styles.seasonDate}>
      <span className={styles.label}>{year}</span>
    </div>
  );
};

export default memo(SeasonCell);
