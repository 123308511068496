import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import * as Common from 'pages/common';
import { Football } from 'pages/desktopNarrow';

import { DESKTOP_NARROW_PATH } from '../../constants';
import { DesktopNarrowPage } from '../../types';

const {
  ROOT,
  SEASON_CHILDREN,
  PLAYER_CHILDREN,
  STADIUM_CHILDREN,
  TEAM_CHILDREN,
} = DESKTOP_NARROW_PATH;

const FOOTBALL_SEASON_ROUTES: RouteObject[] = [
  {
    path: SEASON_CHILDREN[DesktopNarrowPage.FootballSeasonHome],
    element: <Navigate to={SEASON_CHILDREN[DesktopNarrowPage.FootballSeasonSummary]} replace />,
  },
  {
    path: SEASON_CHILDREN[DesktopNarrowPage.FootballSeasonSummary],
    element: <Football.SeasonSummary />,
  },
  {
    path: SEASON_CHILDREN[DesktopNarrowPage.FootballSeasonGames],
    element: <Football.SeasonGames />,
  },
  {
    path: SEASON_CHILDREN[DesktopNarrowPage.FootballSeasonHistory],
    element: <Football.SeasonHistory />,
  },
  {
    path: SEASON_CHILDREN[DesktopNarrowPage.FootballSeasonStadiums],
    element: <Football.SeasonStadiums />,
  },
  {
    path: SEASON_CHILDREN[DesktopNarrowPage.FootballSeasonTeamStatistics],
    element: <Football.SeasonTeamStatistics />,
  },
  {
    path: SEASON_CHILDREN[DesktopNarrowPage.FootballSeasonPlayerStatistics],
    element: <Football.SeasonPlayerStatistics />,
  },
  {
    path: SEASON_CHILDREN[DesktopNarrowPage.FootballSeasonStandings],
    element: <Football.SeasonStandings />,
  },
  {
    path: SEASON_CHILDREN[DesktopNarrowPage.FootballSeasonComingSoon],
    element: <Common.ComingSoon />,
  },
];

const FOOTBALL_TEAM_ROUTES: RouteObject[] = [
  {
    path: TEAM_CHILDREN[DesktopNarrowPage.FootballTeamHome],
    element: <Navigate to={TEAM_CHILDREN[DesktopNarrowPage.FootballTeamSummary]} replace />,
  },
  { path: TEAM_CHILDREN[DesktopNarrowPage.FootballTeamSummary], element: <Football.TeamSummary /> },
  {
    path: TEAM_CHILDREN[DesktopNarrowPage.FootballTeamSummaryDescription],
    element: <Football.TeamSummary />,
  },
  {
    path: TEAM_CHILDREN[DesktopNarrowPage.FootballTeamStandings],
    element: <Football.TeamStandings />,
  },
  { path: TEAM_CHILDREN[DesktopNarrowPage.FootballTeamGames], element: <Football.TeamGames /> },
  { path: TEAM_CHILDREN[DesktopNarrowPage.FootballTeamSquad], element: <Football.TeamSquad /> },
  {
    path: TEAM_CHILDREN[DesktopNarrowPage.FootballTeamPlayerStatistics],
    element: <Football.TeamPlayers />,
  },
];

const FOOTBALL_STADIUM_ROUTES: RouteObject[] = [
  {
    path: STADIUM_CHILDREN[DesktopNarrowPage.FootballStadiumHome],
    element: <Football.StadiumHome />,
  },
  {
    path: STADIUM_CHILDREN[DesktopNarrowPage.FootballStadiumSummary],
    element: <Football.StadiumSummary />,
  },
];

const FOOTBALL_PLAYER_ROUTES: RouteObject[] = [
  {
    path: PLAYER_CHILDREN[DesktopNarrowPage.FootballPlayerHome],
    element: <Navigate to={PLAYER_CHILDREN[DesktopNarrowPage.FootballPlayerSummary]} replace />,
  },
  {
    path: PLAYER_CHILDREN[DesktopNarrowPage.FootballPlayerSummary],
    element: <Football.PlayerSummary />,
  },
  {
    path: PLAYER_CHILDREN[DesktopNarrowPage.FootballPlayerGames],
    element: <Football.PlayerGames />,
  },
];

const ROOT_ROUTES: RouteObject[] = [
  {
    path: ROOT[DesktopNarrowPage.Home],
    children: [
      {
        path: ROOT[DesktopNarrowPage.Home],
        element: <Navigate to={ROOT[DesktopNarrowPage.Football]} replace />,
      },
      { path: ROOT[DesktopNarrowPage.Football], element: <Football.Home /> },
      { path: ROOT[DesktopNarrowPage.FootballCategory], element: <Football.Category /> },
      {
        path: ROOT[DesktopNarrowPage.FootballTeam],
        element: <Football.Team />,
        children: FOOTBALL_TEAM_ROUTES,
      },
      {
        path: ROOT[DesktopNarrowPage.FootballPlayer],
        element: <Football.Player />,
        children: FOOTBALL_PLAYER_ROUTES,
      },
      {
        path: ROOT[DesktopNarrowPage.FootballSeason],
        element: <Football.Season />,
        children: FOOTBALL_SEASON_ROUTES,
      },
      {
        path: ROOT[DesktopNarrowPage.FootballStadium],
        element: <Football.Stadium />,
        children: FOOTBALL_STADIUM_ROUTES,
      },
      {
        path: ROOT[DesktopNarrowPage.FootballMatch],
        element: <Football.Match />,
      },
    ],
  },
  { path: ROOT[DesktopNarrowPage.NotFound], element: <Common.NotFound /> },
];

const Router = () => {
  return useRoutes(ROOT_ROUTES);
};

export default Router;
