import React, { memo, useCallback, useEffect, useState } from 'react';
import { ActionCreatorsMapObject } from 'redux';
import { useParams } from 'react-router-dom';

import {
  ColumnType,
  CustomFilterState,
  Filters,
  Player,
  PlayerStatistics as PlayersStatisticsType,
  PresetType,
} from 'packages/season-player-statistics/types';
import { PaginationParams } from 'packages/shared/components/Pagination/types';
import { useNavigation } from 'packages/hooks';
import { Team } from 'types';
import { DesktopPage } from 'router/types';

import PlayerStatisticsView from './views';
import connect from './connect';

type Props = {
  filters: Filters;
  customFilters: CustomFilterState;
  playersActions: ActionCreatorsMapObject;
  filtersActions: ActionCreatorsMapObject;
  customFilterActions: ActionCreatorsMapObject;
  commonActions: ActionCreatorsMapObject;
  playersStatistics: PlayersStatisticsType[];
  isFiltersLoading: boolean;
  isInitialPlayersPageLoading: boolean;
  isNewPlayersPageLoading: boolean;
  limit: number;
  count: number;
};

const extendedPlayerStatistics = (
  PlayerStatistics: typeof PlayerStatisticsView[keyof typeof PlayerStatisticsView],
) =>
  memo(
    ({
      filters,
      customFilters,
      playersActions,
      filtersActions,
      customFilterActions,
      commonActions,
      playersStatistics,
      isFiltersLoading,
      isInitialPlayersPageLoading,
      isNewPlayersPageLoading,
      limit,
      count,
    }: Props) => {
      const [isExtendedFilterOpen, setIsExtendedFilterOpen] = useState(false);
      const { tournamentId = '', seasonId = '' } = useParams();
      const { appNavigate } = useNavigation();

      useEffect(() => {
        playersActions.loadInitialPlayers(seasonId);

        return commonActions.cleanPlayerStatistics;
      }, [seasonId]);

      const toggleExtendedFilter = useCallback(() => {
        setIsExtendedFilterOpen((isExtendedFilterOpen) => !isExtendedFilterOpen);
      }, []);

      const handleApplyClick = useCallback(() => {
        toggleExtendedFilter();
      }, []);

      const handleCancelClick = useCallback(() => {
        toggleExtendedFilter();
      }, []);

      const handleCustomizeButtonClick = useCallback(() => {
        toggleExtendedFilter();
      }, []);

      const loadPlayersPage = useCallback(() => {
        playersActions.loadPlayersPage(seasonId);
      }, [seasonId]);

      const loadFilters = useCallback(() => {
        filtersActions.loadFilters(seasonId);
      }, [seasonId]);

      const handleCustomFilterUpdate = useCallback((updates: CustomFilterState) => {
        customFilterActions.updateCustomFilter(updates);
        loadPlayersPage();
      }, []);

      const handlePresetChange = useCallback((presetType: PresetType) => {
        customFilterActions.updateFilterPreset(presetType);
        loadPlayersPage();
      }, []);

      const handleOrderingChange = useCallback((type: ColumnType) => {
        customFilterActions.updateConfigurableOrdering(type);
        loadPlayersPage();
      }, []);

      const handlePaginationChange = useCallback((params: PaginationParams) => {
        playersActions.updatePagination(params);
        loadPlayersPage();
      }, []);

      const handleTeamClick = useCallback(
        (team: Team) => {
          appNavigate(DesktopPage.FootballTeamSummaryDescription, {
            teamId: team.id,
            uniqueTournamentId: tournamentId,
            seasonId,
          });
        },
        [tournamentId, seasonId],
      );

      const handlePlayerClick = useCallback((player: Player) => {
        appNavigate(DesktopPage.FootballPlayer, { playerId: player.id });
      }, []);

      return (
        <PlayerStatistics
          filters={filters}
          customFilters={customFilters}
          playerStatistics={playersStatistics}
          isFiltersLoading={isFiltersLoading}
          isInitialPlayersPageLoading={isInitialPlayersPageLoading}
          isNewPlayersPageLoading={isNewPlayersPageLoading}
          limit={limit}
          count={count}
          onCustomFilterUpdate={handleCustomFilterUpdate}
          onCancelClick={handleCancelClick}
          onApplyClick={handleApplyClick}
          loadFilters={loadFilters}
          onTeamClick={handleTeamClick}
          onPlayerClick={handlePlayerClick}
          onCustomizeButtonClick={handleCustomizeButtonClick}
          onOrderingChange={handleOrderingChange}
          onPresetChange={handlePresetChange}
          onPaginationChange={handlePaginationChange}
          isExtendedFilterOpen={isExtendedFilterOpen}
        />
      );
    },
  );

export default {
  Desktop: connect(extendedPlayerStatistics(PlayerStatisticsView.Desktop)),
  DesktopNarrow: connect(extendedPlayerStatistics(PlayerStatisticsView.DesktopNarrow)),
  Tablet: connect(extendedPlayerStatistics(PlayerStatisticsView.Tablet)),
  TabletNarrow: connect(extendedPlayerStatistics(PlayerStatisticsView.TabletNarrow)),
  Mobile: connect(extendedPlayerStatistics(PlayerStatisticsView.Mobile)),
};
