import { commonSelectors } from '../../selectors';
import { EVENT_STORAGE_GROUP_KEYS } from '../../constants';
import { EventsStatus, StorageEventGroup } from '../../types';

export const getAllEventsStatus = (state): EventsStatus => {
  return commonSelectors.getEventsStatusByGroup(
    state,
    EVENT_STORAGE_GROUP_KEYS[StorageEventGroup.All],
  );
};

export const getIsAllEventsLoading = (state): boolean => {
  return getAllEventsStatus(state).isLoading;
};

export const getHasNextAllEvents = (state): boolean => {
  return getAllEventsStatus(state).hasNext;
};

export const getAllEventsOffset = (state): number => {
  return getAllEventsStatus(state).offset;
};
