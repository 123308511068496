import React, { memo } from 'react';
import classnames from 'classnames';

import { isEmpty } from 'packages/utils/Object.utils';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';

import ChipListView from './views';
import styles from './styles.scss';

type Props = {
  tournaments?: SeasonsExtendedTournament[];
  onTournamentClick?: (tournament: SeasonsExtendedTournament) => void;
  className?: string;
};

const extendTournamentChipList = (ChipList: typeof ChipListView[keyof typeof ChipListView]) =>
  memo(({ tournaments, onTournamentClick, className }: Props) => {
    if (!isEmpty(tournaments)) {
      return (
        <div className={classnames(styles.tournamentChipList, className)}>
          <ChipList tournaments={tournaments} onTournamentClick={onTournamentClick} />
        </div>
      );
    }

    return null;
  });

export default {
  Desktop: extendTournamentChipList(ChipListView.Desktop),
  DesktopNarrow: extendTournamentChipList(ChipListView.DesktopNarrow),
  Tablet: extendTournamentChipList(ChipListView.Tablet),
  TabletNarrow: extendTournamentChipList(ChipListView.TabletNarrow),
  Mobile: extendTournamentChipList(ChipListView.Mobile),
};
