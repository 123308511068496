import mapTournamentExtendedCategory from './mapTournamentExtendedCategory';
import { CategoryModalData } from '../types';

const mapModalCategory = (category: object[]): CategoryModalData => {
  const { tournaments, id, name, logo } = mapTournamentExtendedCategory(category);

  return {
    category: {
      id,
      name,
      logo,
    },
    tournaments,
  };
};

export default mapModalCategory;
