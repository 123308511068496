import { allEventsSelectors } from 'packages/events/selectors';

import { allEventsActions } from '../../actions';
import { loadEvents } from '../common';

interface Params {
  categoryId?: number;
}

export const loadMoreAllEvents = ({ categoryId }: Params = {}) => async (dispatch, getState) => {
  try {
    const offset = allEventsSelectors.getAllEventsOffset(getState());
    dispatch(allEventsActions.loadMoreAllEventsStart());

    const eventsData = await dispatch(loadEvents({ categoryId, offset }));

    dispatch(allEventsActions.loadMoreAllEventsSuccess(eventsData));
  } catch (error) {
    dispatch(allEventsActions.loadMoreAllEventsFailure(error));
  }
};
