import { EventPeriod } from '../types';

const shouldInjuryTimeBeDisplayed = (minutes?): boolean => {
  if (!minutes) {
    return false;
  }

  return (
    minutes === EventPeriod.First ||
    minutes === EventPeriod.Second ||
    minutes === EventPeriod.Third ||
    minutes === EventPeriod.Fourth
  );
};

export default shouldInjuryTimeBeDisplayed;
