import { getData } from '../common';
import { Interfaces } from '../../../types';

export default (
  state,
): {
  data: Interfaces.Statistics.MobileFilter.Data;
  status: Interfaces.Statistics.MobileFilter.Status;
} => {
  return getData(state).mobileFilter;
};
