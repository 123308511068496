import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { detailsActions, commonActions } from '../../../../actions';
import { Interfaces } from '../../../../types';

const { statistics } = detailsActions;
const defaultState = null;

const reducerMap: ReducerMap<
  Interfaces.Details.Statistics.Data,
  Interfaces.Details.Statistics.Data
> = {
  [`${statistics.loadStatisticsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(commonActions.cleanSummarySuccess, statistics.loadStatisticsStart)}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
