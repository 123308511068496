import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Newcomers } from '../types';

const defaultState: Newcomers = {
  newcomers: [],
  lowerDivisions: [],
  higherDivisions: [],
};

const reducerMap: ReducerMap<Newcomers, Newcomers> = {
  [`${actions.loadNewcomersSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(
    actions.loadNewcomersStart,
    actions.loadNewcomersFailure,
    actions.cleanNewcomers,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions<Newcomers, Newcomers>(reducerMap, defaultState);
