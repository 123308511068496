import React, { memo } from 'react';

import { Team } from 'types';
import { Data as StadiumDetailsData } from 'packages/stadium-details/types';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { ActualLeagues } from 'packages/shared/components';

import { HomeTeams, StadiumLocation } from '../../../../components';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  details: StadiumDetailsData;
  isDetailsLoading: boolean;
  isHomeTeamsLoading: boolean;
  homeTeams: Team[];
  tournamentSeasons: SeasonsExtendedTournament[];
  isTournamentSeasonsLoading: boolean;
  isHomeTeamsShown?: boolean;
};

const StadiumDetails = ({
  details,
  homeTeams,
  isDetailsLoading,
  isHomeTeamsLoading,
  tournamentSeasons,
  isTournamentSeasonsLoading,
  isHomeTeamsShown = false,
}: Props) => {
  return (
    <div className={styles.detailsContainer}>
      <StadiumLocation isLoading={isDetailsLoading} mapCoordinates={details?.mapCoordinates} />
      {isHomeTeamsShown && <HomeTeams isLoading={isHomeTeamsLoading} homeTeams={homeTeams} />}
      <ActualLeagues tournamentList={tournamentSeasons} isLoading={isTournamentSeasonsLoading} />
    </div>
  );
};

export default connect(memo(StadiumDetails));
