import { Qualifier } from 'types';

export const NAMESPACE = 'EVENT-SUMMARY::';

export const INITIAL_PENALTY_HAPPENING_STATE = {
  id: 0,
  score: '0-0',
  [Qualifier.Home]: {
    isGoalScored: null,
    isPenaltyKicked: null,
  },
  [Qualifier.Away]: {
    isGoalScored: null,
    isPenaltyKicked: null,
  },
};
