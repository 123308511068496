import { EventStats } from 'packages/rest';

import {
  loadMatchStatisticsStart,
  loadMatchStatisticsSuccess,
  loadMatchStatisticsFailure,
} from '../actions';
import { mapStatistics } from '../services';

const loadStatistics = ({ eventId }) => async (dispatch) => {
  try {
    dispatch(loadMatchStatisticsStart());

    const { data } = await EventStats.loadEventStats({ eventId });
    const { details, timeLines } = mapStatistics(data.results);

    return dispatch(loadMatchStatisticsSuccess({ details, timeLines }));
  } catch (error) {
    dispatch(loadMatchStatisticsFailure());
  }
};

export default loadStatistics;
