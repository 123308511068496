import { loadTeamEvents as loadEvents } from 'packages/rest/api/TeamEvents';

import { mapTeamEvents } from '../../services';
import { EventsResponse } from '../../types';
import { eventsSelectors, filterSelectors } from '../../selectors';

const loadTeamEvents = (teamId: string) => async (dispatch, getState): Promise<EventsResponse> => {
  const offset = eventsSelectors.getOffset(getState());
  const limit = eventsSelectors.getLimit(getState());
  const activeFilters = filterSelectors.getActiveFilter(getState());
  const { selectedSeasons, tournament, period } = activeFilters;

  const { data } = await loadEvents({
    period,
    seasons: selectedSeasons,
    tournament,
    teamId,
    limit,
    offset,
  });

  return {
    events: mapTeamEvents(data.results),
    count: data.count,
  };
};

export default loadTeamEvents;
