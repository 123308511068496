import React from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { StatisticsGroup as StatisticsGroupType } from '../../types';

import styles from './styles.scss';

type Props = {
  statisticsGroup: StatisticsGroupType;
  className?: string;
};

const StatisticsGroup = ({ statisticsGroup, className }: Props) => {
  return (
    <div className={classnames(styles.statisticsGroupContainer, className)}>
      {statisticsGroup.map(({ key, value }) => (
        <div key={key} className={styles.statisticsRow}>
          <span>{i18n.value(`common.statisticsBlock.parameter.${key}`)}</span>
          <span>{value}</span>
        </div>
      ))}
    </div>
  );
};

export default StatisticsGroup;
