import React from 'react';
import classnames from 'classnames';

import { ButtonSize, ButtonViewProps } from '../../types';
import styles from './styles.scss';

const DefaultButton = ({
  isActive,
  isDisabled,
  size = ButtonSize.Lg,
  className,
  children,
}: ButtonViewProps) => (
  <div
    className={classnames(
      styles.defaultButton,
      {
        [styles.disabled]: isDisabled,
        [styles.active]: isActive,
      },
      styles[size],
      className,
    )}
  >
    {children}
  </div>
);

export default React.memo(DefaultButton);
