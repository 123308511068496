import { SearchParams } from '../types';
import validateParams from './validateParams';

const getSearchQuery = (params: SearchParams): string | undefined => {
  const validParams = validateParams(params);

  const searchParams = new URLSearchParams(validParams).toString();

  if (!searchParams) {
    return undefined;
  } else {
    return `?${searchParams}`;
  }
};

export default getSearchQuery;
