import React, { memo } from 'react';

import { GridColumnId } from 'types';
import { Grid } from 'modules/shared/layouts';
import { Details, TournamentSeasonDetails } from 'packages/stadium-summary-ui';

import styles from './styles.scss';

const StadiumHome = () => {
  return (
    <Grid.DoubleColumn
      leftColumnClassName={styles.leftColumn}
      rightColumnClassName={styles.rightColumn}
      isSecondaryOpen={false}
    >
      <div key={GridColumnId.LeftBar} className={styles.leftBar}>
        <TournamentSeasonDetails.Desktop />
      </div>

      <div key={GridColumnId.Primary}>
        <Details.Desktop />
      </div>
    </Grid.DoubleColumn>
  );
};

export default memo(StadiumHome);
