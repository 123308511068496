import { BaseHappening } from 'packages/event-time-line/types';

const sortHappeningsByTime = (happenings: BaseHappening[]): BaseHappening[] => {
  return happenings.sort(({ minute: leftTime = 0 }, { minute: rightTime = 0 }) => {
    if (leftTime < rightTime) {
      return -1;
    }

    if (leftTime > rightTime) {
      return 1;
    }

    return 0;
  });
};

export default sortHappeningsByTime;
