import React, { memo, useCallback } from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { EventSummaryReferee, EventSummaryStadium } from 'packages/event-summary/types';
import { Weight } from 'packages/shared/components/Separator/types';
import { RefereeCard, Separator } from 'packages/shared/components';
import { RefereeCardType } from 'packages/shared/components/RefereeCard/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Event } from 'types';

import StadiumPreview from './views/StadiumPreview';
import RefereePreview from './views/RefereePreview';

import styles from './styles.scss';

type Props = {
  event: Event;
  matchDetails: {
    attendance: number;
    stadium?: EventSummaryStadium;
    referee?: EventSummaryReferee;
  };
};

const SummaryDetails = ({ event, matchDetails }: Props) => {
  const { attendance, stadium, referee } = matchDetails;
  const { redCardsAverage, yellowCardsAverage, penaltiesAverage } = referee || {};
  const { capacity } = stadium || {};

  const { appNavigate } = useNavigation();

  const handleStadiumClick = useCallback(() => {
    if (stadium?.id) {
      const { tournament, seasonId } = event;

      appNavigate(DesktopPage.FootballStadiumSummary, {
        stadiumId: stadium.id,
        uniqueTournamentId: tournament.uniqueTournamentId,
        seasonId,
      });
    }
  }, [stadium?.id, event.tournament.uniqueTournamentId, event.seasonId]);

  return (
    <div className={styles.summaryDetails}>
      <div className={styles.title}>{i18n.value('eventSummary.matchDetails.title')}</div>
      {attendance || stadium || referee ? (
        <>
          {!!stadium && (
            <div className={styles.descriptionContainer}>
              <span className={styles.subtitle}>
                {i18n.value('eventSummary.matchDetails.stadium')}
              </span>
              <StadiumPreview
                onClick={handleStadiumClick}
                className={styles.description}
                stadium={stadium}
              />
            </div>
          )}
          {(!!attendance || !!capacity) && (
            <div className={styles.descriptionContainer}>
              <span className={styles.subtitle}>
                {i18n.value('eventSummary.matchDetails.attendance')}
              </span>

              <div className={classnames(styles.attendance, styles.description)}>
                <span>
                  {attendance || '-'} <span className={styles.capacity}> / {capacity || '-'}</span>
                </span>
              </div>
            </div>
          )}
          {!!referee && (
            <>
              {(!!stadium || !!attendance) && <Separator weight={Weight.Thin} />}
              <div className={styles.descriptionContainer}>
                <span className={styles.subtitle}>
                  {i18n.value('eventSummary.matchDetails.referee')}
                </span>
                <RefereePreview className={styles.description} referee={referee} />
              </div>
              {!!redCardsAverage && !!yellowCardsAverage && (
                <div className={styles.descriptionContainer}>
                  <span className={styles.subtitle}>
                    {i18n.value('eventSummary.matchDetails.averageCards')}
                  </span>

                  <div className={styles.description}>
                    <RefereeCard className={styles.card} type={RefereeCardType.Red} />
                    <span className={styles.cardAverage}>{redCardsAverage}</span>
                    <RefereeCard className={styles.card} type={RefereeCardType.Yellow} />
                    <span className={styles.cardAverage}>{yellowCardsAverage}</span>
                  </div>
                </div>
              )}
              {!!penaltiesAverage && (
                <div className={styles.descriptionContainer}>
                  <span className={styles.subtitle}>
                    {i18n.value('eventSummary.matchDetails.averagePenalties')}
                  </span>
                  <span className={styles.description}>{penaltiesAverage}</span>
                </div>
              )}
            </>
          )}
        </>
      ) : (
        <div>{i18n.value('common.notFound')}</div>
      )}
    </div>
  );
};

export default memo(SummaryDetails);
