import instance from '../../../axios';
import { PlayerEventsParams } from '../types';

export const loadPlayerEvents = ({
  playerId,
  limit,
  offset,
  teamId,
  tournamentId,
  period,
  seasons,
  needDescendingSort = false,
}: PlayerEventsParams) => {
  const seasonParam = seasons?.map((season) => season.id).join(',');
  const [scheduledAfter, scheduledBefore] = period || [];

  return instance({
    method: 'get',
    url: `/players/${playerId}/sport_events/`,
    params: {
      limit,
      offset,
      team: teamId,
      uq: tournamentId,
      scheduled_before: scheduledBefore,
      scheduled_after: scheduledAfter,
      season: seasonParam,
      need_descending_sort: needDescendingSort,
    },
  });
};
