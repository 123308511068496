import React, { memo } from 'react';

import { TournamentView } from 'packages/events/types';
import {
  InfiniteScrollContainer,
  Tournament,
} from 'packages/events-ui/containers/EventsTabs/components';

import Event from '../../../Event/views';
import EventListLoadingSkeleton from '../EventListLoadingSkeleton';
import { NAVIGATION_HEADER_HEIGHT } from '../../constants';

type Props = {
  isCompact?: boolean;
  scrollContainerIdentifier: string;
  tournaments: TournamentView[];
  handleEventClick: (eventId: number) => void;
  handleTournamentClick: Function;
  onLoadMore?: () => void;
  activeSportId: number;
  activeEventId: number;
  eventsLength?: number;
  className?: string;
  hasNext?: boolean;
  isLoading?: boolean;
  useModalOnCategoryClick?: boolean;
};

const EventList = ({
  scrollContainerIdentifier,
  onLoadMore,
  eventsLength = 0,
  tournaments,
  isCompact = false,
  hasNext = false,
  isLoading = false,
  useModalOnCategoryClick = false,
  ...props
}: Props) => {
  if (isLoading) {
    return <EventListLoadingSkeleton isCompact={isCompact} />;
  }

  return (
    <InfiniteScrollContainer
      scrollContainerIdentifier={scrollContainerIdentifier}
      onLoadMore={onLoadMore}
      eventsLength={eventsLength}
      hasNext={hasNext}
      {...props}
    >
      {tournaments.map((tournament, index) => {
        const key = `${tournament.id}${index}`;

        return (
          <Tournament
            headerOffset={NAVIGATION_HEADER_HEIGHT}
            key={key}
            tournament={tournament}
            useModalOnCategoryClick={useModalOnCategoryClick}
            eventComponent={isCompact ? Event.Mobile : Event.Desktop}
            eventComponentProps={{ isTeamClickable: true }}
            {...props}
          />
        );
      })}
    </InfiniteScrollContainer>
  );
};

export default memo(EventList);
