import React, {
  memo,
  useCallback,
  useEffect,
  useState,
  RefObject,
  useMemo,
  useLayoutEffect,
} from 'react';
import { ActionCreatorsMapObject } from 'redux';
import i18n from 'i18n-smart';
import classnames from 'classnames';
import { useParams } from 'react-router-dom';

import { Button, Icon, Select, Separator, FlexiblePopover } from 'packages/shared/components';
import { SelectItem } from 'packages/shared/components/Select/types';
import { Interfaces as PlayerSummaryInterfaces } from 'packages/player-summary/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { ButtonType } from 'packages/shared/components/Button/types';
import { PopoverAlign, PopoverJustify } from 'packages/shared/components/FlexiblePopover/types';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';

import { getPopoverOffsets } from './services';
import { FiltrationPopover } from './components';
import { FilterState } from '../../types';
import styles from './styles.scss';
import connect from './connect';

type Props = {
  tabs: NavigationMenuItem[];
  teamItems: SelectItem[];
  mainFilter: FilterState;
  updateMainFilter: React.Dispatch<React.SetStateAction<FilterState>>;
  playerDetailsStatistics?: PlayerSummaryInterfaces.Statistics.Statistics.Statistics;
  tournamentSeasonsActions: ActionCreatorsMapObject;
  parentContainerRef?: RefObject<HTMLDivElement>;
  className?: string;
  isStatisticsLoading: boolean;
  isSeasonTournamentsLoading: boolean;
  isPopoverWidthFixed?: boolean;
};

const Header = ({
  tabs,
  teamItems,
  updateMainFilter,
  mainFilter,
  playerDetailsStatistics,
  tournamentSeasonsActions,
  className,
  parentContainerRef,
  isStatisticsLoading,
  isSeasonTournamentsLoading,
  isPopoverWidthFixed = false,
}: Props) => {
  const { playerId } = useParams();
  const [popoverOffset, setPopoverOffset] = useState({ top: 0, left: 0, right: 0 });
  const popoverProps = useMemo(() => {
    return { tabs, teamItems, mainFilter, updateMainFilter, isWidthFixed: isPopoverWidthFixed };
  }, [tabs, teamItems, mainFilter, updateMainFilter, isPopoverWidthFixed]);

  useLayoutEffect(() => {
    const offsets = getPopoverOffsets({ element: parentContainerRef?.current });

    setPopoverOffset(offsets);
  }, []);

  useEffect(() => {
    if (mainFilter.selectedTeam) {
      tournamentSeasonsActions.loadTournamentSeasons({
        playerId,
        teamId: mainFilter.selectedTeam.id,
      });
    }
  }, [playerId, mainFilter.selectedTeam, playerDetailsStatistics]);

  const handleTeamChange = useCallback(
    (selectedTeam: SelectItem) => {
      updateMainFilter((prevState) => ({ ...prevState, selectedTeam }));
    },
    [playerId, playerDetailsStatistics],
  );

  const { selectedTeam } = mainFilter;

  const isCustomizationLocked = isStatisticsLoading || isSeasonTournamentsLoading;

  return (
    <div>
      <div className={classnames(styles.header, className)}>
        <div className={styles.title}>{i18n.value('player.playerSummary.statistics.title')}</div>

        <div>
          <Select
            itemsContainerClass={styles.selectItemsContainer}
            selectedItem={selectedTeam}
            onChange={handleTeamChange}
            items={teamItems}
            isDisabled={isCustomizationLocked}
          />
        </div>

        <div className={styles.btnContainer}>
          <FlexiblePopover
            popoverProps={popoverProps}
            popoverComponent={FiltrationPopover}
            justify={PopoverJustify.Right}
            align={PopoverAlign.Top}
            customPosition={popoverOffset}
            isDisabled={isCustomizationLocked}
            disableTouchMoveEvent
            useChildrenWrapper
            isModal
          >
            <Button contentClassName={styles.filterBtn} type={ButtonType.Default}>
              <Icon className={styles.icon} id={IconType.Configuration} />
            </Button>
          </FlexiblePopover>
        </div>
      </div>

      <Separator />
    </div>
  );
};

export default connect(memo(Header));
