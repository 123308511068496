import { DataComparisonPredicate, DifferenceExtractorConfig } from 'packages/socket/types';
import { isEqual } from 'packages/utils/Object.utils';

abstract class BaseDifferenceExtractor<ItemType, ItemConfigType> {
  protected dataComparisonPredicate: DataComparisonPredicate<ItemConfigType>

  constructor(options?: DifferenceExtractorConfig<ItemConfigType>) {
    this.dataComparisonPredicate = options?.dataComparisonPredicate || isEqual;
  }

  abstract getDataUpdate(item: ItemType, updatedItem: ItemType);
}

export default BaseDifferenceExtractor;
