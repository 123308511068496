import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const LoadingSkeleton = () => {
  return (
    <div className={styles.loadingSkeleton}>
      <Skeleton variant="rectangular" width={270} height={16} />
      <div className={styles.skeletonTournamentList}>
        <div className={styles.skeletonTournament}>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton marginLeft={8} variant="rectangular" width={220} height={16} />
        </div>
        <div className={styles.skeletonTournament}>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton marginLeft={8} variant="rectangular" width={220} height={16} />
        </div>
        <div className={styles.skeletonTournament}>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton marginLeft={8} variant="rectangular" width={220} height={16} />
        </div>
        <div className={styles.skeletonTournament}>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton marginLeft={8} variant="rectangular" width={220} height={16} />
        </div>
        <div className={styles.skeletonTournament}>
          <Skeleton variant="circular" width={20} height={20} />
          <Skeleton marginLeft={8} variant="rectangular" width={220} height={16} />
        </div>
      </div>
    </div>
  );
};

export default memo(LoadingSkeleton);
