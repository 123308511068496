import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import DateService from 'packages/date-az';

import styles from './styles.scss';

const DateItem = ({ date, id, isActive, isCurrent, handleClick, className }) => {
  const containerClasses = classnames(styles.dateItem, className, {
    [styles.active]: isActive,
    [styles.current]: isCurrent,
  });

  const { day } = date;
  const weekDayShort = DateService.getWeekDayShortName(date);

  return (
    <div id={id} className={containerClasses} onMouseUp={() => handleClick(date)}>
      <div className={styles.day}>{day}</div>
      <div className={styles.weekday}>{weekDayShort}</div>
    </div>
  );
};

DateItem.defaultProps = {
  isActive: false,
  isCurrent: false,
  className: '',
};

DateItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.number.isRequired,
  date: PropTypes.shape({
    day: PropTypes.number,
  }).isRequired,
  isActive: PropTypes.bool,
  isCurrent: PropTypes.bool,
  handleClick: PropTypes.func.isRequired,
};

export default DateItem;
