import { connect } from 'react-redux';

import { settingsSelectors } from 'packages/settings';

const mapStateToProps = (state) => {
  return {
    activeDate: settingsSelectors.getActiveDate(state),
  };
};

export default connect(mapStateToProps);
