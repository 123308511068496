import { getData } from '../common';
import { Interfaces } from '../../../types';

export default (
  state,
): {
  data: Interfaces.Statistics.TournamentSeasons.Data;
  status: Interfaces.Statistics.TournamentSeasons.Status;
} => {
  return getData(state).tournamentSeasons;
};
