import React, { useCallback, memo } from 'react';
import i18n from 'i18n-smart';

import { TournamentSeasonChampion } from 'packages/season-history/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Team } from 'types';

import { TrophiesTable, TableSkeleton } from './components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  champions: TournamentSeasonChampion[];
  isLoading: boolean;
  season: string;
};

const TotalTrophies = ({ champions, isLoading, season }: Props) => {
  const { appNavigate } = useNavigation();

  const handleTeamClick = useCallback((team: Team) => {
    appNavigate(DesktopPage.FootballTeam, { teamId: team.id });
  }, []);

  return (
    <div className={styles.totalTrophiesBlock}>
      {isLoading ? (
        <TableSkeleton elementsCount={8} />
      ) : (
        <>
          <div className={styles.header}>
            {i18n.value('season.totalTrophies.title')} {season}
          </div>
          <TrophiesTable data={champions} onTeamClick={handleTeamClick} />
        </>
      )}
    </div>
  );
};

export default connect(memo(TotalTrophies));
