export enum MediaScreen {
  Mobile = 'mobile',
  TabletNarrow = 'tablet_narrow',
  Tablet = 'tablet',
  DesktopNarrow = 'desktop_narrow',
  Desktop = 'desktop',
}

export enum LayoutKey {
  Top = 'top',
  Main = 'main',
  Bottom = 'bottom',
}

export enum GridColumnId {
  Primary = 'primary',
  Secondary = 'secondary',
  LeftBar = 'left_bar',
}
