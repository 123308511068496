import {
  SeasonTablePromotion,
  StatisticsGroup,
  TableStatistics,
  TeamComment,
  TournamentTable,
} from 'packages/event-standings/types';
import { mapForm } from 'packages/event-summary/services/mappers/mapEventSummary';
import { toCamelCase } from 'packages/utils/Object.utils';
import { TEAM_DEFAULT_LOGO } from 'appConstants';
import { Qualifier } from 'types';

import { FORM_MATCH_AMOUNT } from '../../../constants';
import {
  selectCommonAwayStatistics,
  selectCommonHomeStatistics,
  selectCommonOverallStatistics,
} from './selectStatistics';

export const mapSeasonTables = (
  seasonTables,
  positionSortKey: StatisticsGroup,
): TournamentTable[] => {
  return seasonTables.map((seasonTable) => {
    const {
      tournamentTableRows,
      rule,
      promotions,
      teamComments,
      id,
      groupName,
      name,
      start,
      end,
    } = toCamelCase(seasonTable);

    const mappedTableRows = mapTableRows(sortTableRows(formatTableRows(tournamentTableRows)));
    const formattedTableRows = sortTournamentRowsByStatisticsGroupPosition(
      mappedTableRows,
      positionSortKey,
    );

    return {
      id,
      end,
      rule,
      start,
      groupName: groupName || name,
      rows: formattedTableRows,
      promotions: promotions.map(toCamelCase) as SeasonTablePromotion[],
      teamComments: teamComments.map(toCamelCase) as TeamComment[],
    };
  });
};

const formatTableRows = (seasonTableRows: any[]) => {
  return seasonTableRows.map((seasonTableRow) => {
    const { team, statistics, promotion, sportEvents, id } = toCamelCase(seasonTableRow, true);

    return {
      id,
      team: mapTeam(team),
      statistics: toCamelCase(statistics),
      promotion: promotion ? toCamelCase(promotion) : promotion,
      sportEvents,
    };
  });
};

const mapTeam = ({ logo, ...rest }) => {
  return {
    logo: logo || TEAM_DEFAULT_LOGO,
    ...rest,
  };
};

const sortTableRows = (tableRows: any[]) => {
  return tableRows.sort((firstTableRow, secondTableRow) => {
    const { sortPositionTotal: firstSortPositionTotal = 0 } = firstTableRow?.statistics;
    const { sortPositionTotal: secondSortPositionTotal = 0 } = secondTableRow?.statistics;

    return firstSortPositionTotal - secondSortPositionTotal;
  });
};

const mapTableRows = (seasonTableRows: any[]) => {
  return seasonTableRows.map(({ id, team, statistics, promotion }) => {
    const { totalForm, id: teamId } = team;

    return {
      id,
      team,
      statistics: mapRowStatistics(statistics, promotion),
      form: mapRowForm(totalForm, teamId),
    };
  });
};

const mapRowStatistics = (statistics: TableStatistics, promotion: SeasonTablePromotion) => {
  return {
    [StatisticsGroup.Home]: selectCommonHomeStatistics(statistics, promotion),
    [StatisticsGroup.Away]: selectCommonAwayStatistics(statistics, promotion),
    [StatisticsGroup.Overall]: selectCommonOverallStatistics(statistics, promotion),
  };
};

const mapRowForm = (form: any | null, teamId: number) => {
  if (form) {
    const slicedHomeForm = sliceForm(getTeamFormGroupByQualifier(form, teamId, Qualifier.Home));
    const slicedAwayForm = sliceForm(getTeamFormGroupByQualifier(form, teamId, Qualifier.Away));
    const slicedOverallForm = sliceForm(form);

    return {
      [StatisticsGroup.Home]: mapForm(slicedHomeForm),
      [StatisticsGroup.Away]: mapForm(slicedAwayForm),
      [StatisticsGroup.Overall]: mapForm(slicedOverallForm),
    };
  }

  return null;
};

const getTeamFormGroupByQualifier = (form: any, teamId: number, qualifier: Qualifier) => {
  return form.filter(({ competitors }) =>
    competitors.find(({ qualifier: formQualifier, id }) => {
      return teamId === id && qualifier === formQualifier;
    }),
  );
};

const sliceForm = (form) => {
  return form.slice(0, FORM_MATCH_AMOUNT);
};

export const sortTournamentRowsByStatisticsGroupPosition = (rows, key: StatisticsGroup) => {
  return rows.sort((a, b) => a.statistics[key].position.value - b.statistics[key].position.value);
};
