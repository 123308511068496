import React, { ReactElement, useRef, memo } from 'react';

import FlexiblePopover from 'packages/shared/components/FlexiblePopover';
import {
  PopoverAlign,
  PopoverInvokeTrigger,
  PopoverJustify,
} from 'packages/shared/components/FlexiblePopover/types';

import { TooltipPopover } from './components';

type Props = {
  value: string | number;
  children?: ReactElement;
  isPopoverDisabled?: boolean;
  invokeTrigger?: PopoverInvokeTrigger;
};

const Tooltip = ({
  value,
  children,
  isPopoverDisabled = false,
  invokeTrigger = PopoverInvokeTrigger.Hover,
}: Props) => {
  const childrenContainerRef = useRef(null);

  return (
    <FlexiblePopover
      isDisabled={isPopoverDisabled}
      popoverComponent={TooltipPopover}
      popoverProps={{ value }}
      align={PopoverAlign.Top}
      justify={PopoverJustify.Center}
      invokeTrigger={invokeTrigger}
    >
      <div ref={childrenContainerRef}>{children}</div>
    </FlexiblePopover>
  );
};

export default memo(Tooltip);
