import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { mobileFilterStagesActions, stagesActions } from '../../actions';
import { MobileFilterStages } from '../../types';

const defaultState = {
  isLoading: false,
};

const reducerMap: ReducerMap<MobileFilterStages.Status, void> = {
  [`${combineActions(
    stagesActions.loadStagesStart,
    mobileFilterStagesActions.loadStagesStart,
  )}`]: () => {
    return { isLoading: true };
  },
  [`${combineActions(
    stagesActions.loadStagesFailure,
    stagesActions.loadStagesSuccess,
    mobileFilterStagesActions.loadStagesFailure,
    mobileFilterStagesActions.loadStagesSuccess,
  )}`]: () => {
    return { isLoading: false };
  },
  [`${combineActions(
    mobileFilterStagesActions.resetStagesSuccess,
    mobileFilterStagesActions.cleanMobileFilterSuccess,
  )}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
