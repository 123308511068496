import { toCamelCase, toSnakeCase, isNumber } from 'packages/utils/Object.utils';

import { BaseHappening, TimeLinePeriodScores } from '../../types';

export const formatEventTimeLine = (timeLine: any[]): BaseHappening[] => {
  return timeLine.map((item) => formatTimeLineItem(item));
};

export const formatTimeLineItem = (item: any): BaseHappening => {
  const firstLevelFormattedItem = toCamelCase(item);
  const { eventType, team, players, additionalInfo, minute, ...rest } = firstLevelFormattedItem;

  return {
    ...rest,
    team: team,
    minute: isNumber(minute) ? Math.abs(minute) : undefined,
    eventType: eventType.toUpperCase(),
    eventName: toSnakeCase(firstLevelFormattedItem.eventName || '').toUpperCase(),
    players: players.map((player) => (player ? toCamelCase(player) : null)),
    additionalInfo: additionalInfo ? toCamelCase(additionalInfo) : null,
  };
};

export const formatTimeLinePeriodScores = (timeLinePeriodScores: any): TimeLinePeriodScores => {
  const mappedPeriodScores: TimeLinePeriodScores = {};

  for (const [periodName, periodScores] of Object.entries(timeLinePeriodScores)) {
    mappedPeriodScores[toSnakeCase(periodName).toUpperCase()] = periodScores;
  }

  return mappedPeriodScores;
};
