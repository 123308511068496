import { handleActions, ReducerMap } from 'redux-actions';

import { TopVenue } from '../../types';
import * as actions from '../../actions';

const defaultState: TopVenue[] = [];

const reducerMap: ReducerMap<TopVenue[], TopVenue[]> = {
  [`${actions.loadTopVenuesSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${actions.cleanTopBoardSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<TopVenue[], TopVenue[]>(reducerMap, defaultState);
