import React, { memo } from 'react';

import { BaseHappening } from 'packages/event-time-line/types';
import { PenaltyHappening } from 'packages/event-summary/types';

import { getBothPositionsHappeningElementsByPeriodName } from './services';
import { Theme } from '../../../../types';
import { HappeningLine } from '../../components';

type Props = {
  periodHappening?: BaseHappening;
  happening: BaseHappening | PenaltyHappening;
  theme?: Theme;
};

const BothDirectionsRow = ({ happening, periodHappening, theme }: Props) => {
  const {
    homeHappeningElement,
    awayHappeningElement,
    chipElement,
  } = getBothPositionsHappeningElementsByPeriodName(happening, periodHappening?.periodType, theme);

  return (
    <HappeningLine
      homeHappeningElement={homeHappeningElement}
      awayHappeningElement={awayHappeningElement}
      chipElement={chipElement}
      theme={theme}
    />
  );
};

export default memo(BothDirectionsRow);
