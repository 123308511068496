exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, "._1j6CQB9C{display:flex;align-items:center}._1j6CQB9C ._rk3I87s0{margin-right:8px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/shared/components/SeasonTables/components/SeasonTable/components/TeamCell/styles.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,kBAAmB,CAHvB,sBAMM,gBAAiB","file":"styles.scss","sourcesContent":["@import '~packages/theme/common.scss';\n\n:local {\n  .team-cell {\n    display: flex;\n    align-items: center;\n\n    .position-wrapper {\n      margin-right: 8px;\n    }\n  }\n}\n"]}]);

// Exports
exports.locals = {
	"team-cell": "_1j6CQB9C",
	"teamCell": "_1j6CQB9C",
	"position-wrapper": "_rk3I87s0",
	"positionWrapper": "_rk3I87s0"
};