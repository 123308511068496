import { createAction } from 'redux-actions';

import { NAMESPACE } from '../constants';

export const loadAllEventsStart = createAction(`${NAMESPACE}LOAD_ALL_EVENTS_START`);
export const loadAllEventsSuccess = createAction(`${NAMESPACE}LOAD_ALL_EVENTS_SUCCESS`);
export const loadAllEventsFailure = createAction(`${NAMESPACE}LOAD_ALL_EVENTS_FAILURE`);

export const loadMoreAllEventsStart = createAction(`${NAMESPACE}LOAD_MORE_ALL_EVENTS_START`);
export const loadMoreAllEventsSuccess = createAction(`${NAMESPACE}LOAD_MORE_ALL_EVENTS_SUCCESS`);
export const loadMoreAllEventsFailure = createAction(`${NAMESPACE}LOAD_MORE_ALL_EVENTS_FAILURE`);

export const cleanAllEventsSuccess = createAction(`${NAMESPACE}CLEAN_ALL_EVENTS_SUCCESS`);
export const cleanAllEventsFailure = createAction(`${NAMESPACE}CLEAN_ALL_EVENTS_FAILURE`);

export const updateAllEventsStart = createAction(`${NAMESPACE}UPDATE_ALL_EVENTS_START`);
export const updateAllEventsFailure = createAction(`${NAMESPACE}UPDATE_ALL_EVENTS_FAILURE`);
export const updateAllEventsSuccess = createAction(`${NAMESPACE}UPDATE_ALL_EVENTS_SUCCESS`);

export const subscribeForAllEventsUpdatesStart = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_ALL_EVENTS_UPDATES_START`,
);
export const subscribeForAllEventsUpdatesFailure = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_ALL_EVENTS_UPDATES_FAILURE`,
);
export const subscribeForAllEventsUpdatesSuccess = createAction(
  `${NAMESPACE}SUBSCRIBE_FOR_ALL_EVENTS_UPDATES_SUCCESS`,
);
