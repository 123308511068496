import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  eventsSelectors,
  filterSelectors,
  eventThunks,
  commonThunks,
  filterThunks,
} from 'packages/player-events';
import { settingsSelectors } from 'packages/settings';

const mapStateToProps = (state) => ({
  eventsCount: eventsSelectors.getCount(state),
  limit: eventsSelectors.getLimit(state),
  offset: eventsSelectors.getOffset(state),
  events: eventsSelectors.getData(state),
  isInitialFiltersLoading: filterSelectors.getIsInitialLoading(state),
  isInitialEventsLoading: eventsSelectors.getIsInitialLoading(state),
  isMoreEventsLoading: eventsSelectors.getIsMoreLoading(state),
  isMoreFiltersLoading: filterSelectors.getIsMoreLoading(state),
  tournamentFilters: filterSelectors.getTournamentFilters(state),
  teamFilters: filterSelectors.getTeamFilters(state),
  activeFilters: filterSelectors.getActiveFilter(state),
  localeCode: settingsSelectors.getLocaleCode(state),
});

const mapDispatchToProps = (dispatch) => ({
  eventActions: bindActionCreators(eventThunks, dispatch),
  commonActions: bindActionCreators(commonThunks, dispatch),
  filterActions: bindActionCreators(filterThunks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
