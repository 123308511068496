import Dexie from 'dexie';

import { Tables } from 'configure/storage/types';
import { keyValueDatabase } from 'packages/storage';

const loadLatestEventsFromStorage = () => () => {
  const dexieInstance = keyValueDatabase.getStorageInstance() as Dexie;
  const favoriteEventsModel = dexieInstance.table(Tables.FAVORITE_EVENTS);

  return favoriteEventsModel.toArray();
};

export default loadLatestEventsFromStorage;
