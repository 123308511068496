import { Players } from 'packages/rest';

import { statisticsActions } from '../../../actions';
import { mapTournamentSeasons } from '../../../services';

interface Params {
  playerId: number;
  teamId: number;
}

const loadTournamentSeasons = ({ playerId, teamId }: Params) => async (dispatch) => {
  try {
    dispatch(statisticsActions.tournamentSeasons.loadTournamentSeasonsStart());

    const { data } = await Players.loadTournamentSeasons({ playerId, teamId });
    const mappedTournaments = mapTournamentSeasons(data.results);
    const [firstTournament] = mappedTournaments;
    const [firstSeason] = firstTournament?.seasons;

    dispatch(
      statisticsActions.tournamentSeasons.loadTournamentSeasonsSuccess({
        tournaments: mappedTournaments,
        selectedTournamentId: firstTournament.id,
        selectedSeasonId: firstSeason.id,
      }),
    );
  } catch (e) {
    dispatch(statisticsActions.tournamentSeasons.loadTournamentSeasonsFailure(e));
  }
};

export default loadTournamentSeasons;
