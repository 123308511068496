import React from 'react';

import { getEventSchema } from './services';
import { FootballComponentProps } from './types';

interface Props extends FootballComponentProps {
  activeSportId?: number;
}

const EventBase = ({ activeSportId = 1, ...props }: Props) => {
  const Component = getEventSchema(activeSportId);

  return <Component {...props} />;
};

export default EventBase;
