import { IconType } from 'packages/shared/components/Icon/types';
import {
  BaseHappening,
  GoalHappening,
  PlayerHappening,
  PlayerType,
} from 'packages/event-time-line/types';
import { SummaryHappeningType } from 'packages/events/types';

export const getPlayerHappeningIcon = (happening: BaseHappening): IconType | undefined => {
  const { eventType } = happening;

  switch (eventType) {
    case SummaryHappeningType.SCORE_CHANGE: {
      const { isOwnGoal, isPenaltyGoal } = happening as GoalHappening;

      if (isOwnGoal) {
        return IconType.OwnGoal;
      } else if (isPenaltyGoal) {
        return IconType.PenaltyGoal;
      } else {
        return IconType.Goal;
      }
    }
    case SummaryHappeningType.ASSISTS: {
      return IconType.Assist;
    }
    case SummaryHappeningType.YELLOW_CARD: {
      return IconType.YellowCard;
    }
    case SummaryHappeningType.RED_CARD: {
      return IconType.RedCard;
    }
    case SummaryHappeningType.YELLOW_RED_CARD: {
      return IconType.YellowRedCard;
    }
    case SummaryHappeningType.SUBSTITUTION: {
      const { playerId: currentPlayerId, players } = happening as PlayerHappening;
      const currentPlayerHappening = players?.find(({ playerId }) => playerId === currentPlayerId);

      if (currentPlayerHappening) {
        if (currentPlayerHappening.playerType === PlayerType.SubstitutedIn) {
          return IconType.SubstitutionIn;
        } else {
          return IconType.SubstitutionOut;
        }
      }

      return IconType.SubstitutionLight;
    }
  }
};
