import React from 'react';

type Props = {
  minute: string;
  injuryTime?: string;
};

const EventTime = ({ minute, injuryTime }: Props) => (
  <span>
    {minute}
    {injuryTime && `+${injuryTime}'`}
  </span>
);

export default EventTime;
