import React, { memo, useMemo } from 'react';

import { ArrayUtils } from 'packages/utils';
import { FlexibleTable, Skeleton } from 'packages/shared/components';

import { getColumnSchema } from './services';
import styles from './styles.scss';

type Props = {
  elementsCount?: number;
};

const DEFAULT_ELEMENTS_COUNT = 10;

const LoadingSkeleton = ({ elementsCount = DEFAULT_ELEMENTS_COUNT }: Props) => {
  const elements = useMemo(() => ArrayUtils.getArrayFromNumber(elementsCount), []);
  const columns = useMemo(getColumnSchema, []);

  return (
    <div className={styles.container}>
      <div className={styles.compactHeader}>
        <div className={styles.control}>
          <Skeleton width={101} height={16} variant="rectangular" marginRight={8} />
          <Skeleton width={100} height={32} variant="rectangular" />
        </div>

        <Skeleton width={80} height={16} variant="rectangular" />
      </div>
      <FlexibleTable
        data={elements}
        columns={columns}
        className={styles.table}
        headerClassName={styles.tableHeader}
        rowClassName={styles.tableRow}
      />
    </div>
  );
};

export default memo(LoadingSkeleton);
