import React, { memo } from 'react';

import { Round } from 'types';

import styles from './styles.scss';

type Props = {
  round?: Round;
};

const RoundName = ({ round }: Props) => <div className={styles.roundName}>{round?.name}</div>;

export default memo(RoundName);
