import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { stagesActions } from '../../actions';
import { Stages } from '../../types';

const defaultState: Stages.Data = {
  stages: [],
  activeStageId: undefined,
};

const reducerMap: ReducerMap<Stages.Data, Stages.Data> = {
  [`${stagesActions.loadStagesSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(stagesActions.cleanStagesSuccess, stagesActions.loadStagesFailure)}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
