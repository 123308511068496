import React from 'react';
import classnames from 'classnames';

import { Team } from 'types/interfaces/teams';
import { Figure } from 'packages/shared/components';

import styles from './styles.scss';

type Props = {
  teams: Team[];
  onTeamClick?: (team: Team) => void;
  className?: string;
};

const TeamGrid = ({ teams, onTeamClick, className }: Props) => {
  const handleTeamClick = (team: Team) => {
    return () => {
      onTeamClick?.(team);
    };
  };

  return (
    <div className={classnames(styles.teamGrid, className)}>
      {teams.map((team) => {
        const { id, name, logo } = team;

        return (
          <div onClick={handleTeamClick(team)} key={id} className={styles.team}>
            <Figure src={logo} className={styles.logo} />
            <div className={styles.name}>{name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default React.memo(TeamGrid);
