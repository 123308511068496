import React from 'react';
import { Navigate, RouteObject, useRoutes } from 'react-router-dom';

import * as Common from 'pages/common';
import { Football } from 'pages/mobile';

import { MOBILE_PATH } from '../../constants';
import { MobilePage } from '../../types';

const { ROOT, SEASON_CHILDREN, PLAYER_CHILDREN, STADIUM_CHILDREN, TEAM_CHILDREN } = MOBILE_PATH;

const FOOTBALL_SEASON_ROUTES: RouteObject[] = [
  {
    path: SEASON_CHILDREN[MobilePage.FootballSeasonHome],
    element: <Navigate to={SEASON_CHILDREN[MobilePage.FootballSeasonSummary]} replace />,
  },
  { path: SEASON_CHILDREN[MobilePage.FootballSeasonSummary], element: <Football.SeasonSummary /> },
  { path: SEASON_CHILDREN[MobilePage.FootballSeasonGames], element: <Football.SeasonGames /> },
  { path: SEASON_CHILDREN[MobilePage.FootballSeasonHistory], element: <Football.SeasonHistory /> },
  {
    path: SEASON_CHILDREN[MobilePage.FootballSeasonStadiums],
    element: <Football.SeasonStadiums />,
  },
  {
    path: SEASON_CHILDREN[MobilePage.FootballSeasonTeamStatistics],
    element: <Football.SeasonTeamStatistics />,
  },
  {
    path: SEASON_CHILDREN[MobilePage.FootballSeasonPlayerStatistics],
    element: <Football.SeasonPlayerStatistics />,
  },
  {
    path: SEASON_CHILDREN[MobilePage.FootballSeasonStandings],
    element: <Football.SeasonStandings />,
  },
  { path: SEASON_CHILDREN[MobilePage.FootballSeasonComingSoon], element: <Common.ComingSoon /> },
];

const FOOTBALL_TEAM_ROUTES: RouteObject[] = [
  {
    path: TEAM_CHILDREN[MobilePage.FootballTeamHome],
    element: <Navigate to={TEAM_CHILDREN[MobilePage.FootballTeamSummary]} replace />,
  },
  { path: TEAM_CHILDREN[MobilePage.FootballTeamSummary], element: <Football.TeamSummary /> },
  {
    path: TEAM_CHILDREN[MobilePage.FootballTeamSummaryDescription],
    element: <Football.TeamSummary />,
  },
  { path: TEAM_CHILDREN[MobilePage.FootballTeamStandings], element: <Football.TeamStandings /> },
  { path: TEAM_CHILDREN[MobilePage.FootballTeamGames], element: <Football.TeamGames /> },
  { path: TEAM_CHILDREN[MobilePage.FootballTeamSquad], element: <Football.TeamSquad /> },
  {
    path: TEAM_CHILDREN[MobilePage.FootballTeamPlayerStatistics],
    element: <Football.TeamPlayers />,
  },
];

const FOOTBALL_STADIUM_ROUTES: RouteObject[] = [
  {
    path: STADIUM_CHILDREN[MobilePage.FootballStadiumHome],
    element: <Football.StadiumHome />,
  },
  {
    path: STADIUM_CHILDREN[MobilePage.FootballStadiumSummary],
    element: <Football.StadiumSummary />,
  },
];

const FOOTBALL_PLAYER_ROUTES: RouteObject[] = [
  {
    path: PLAYER_CHILDREN[MobilePage.FootballPlayerHome],
    element: <Navigate to={PLAYER_CHILDREN[MobilePage.FootballPlayerSummary]} replace />,
  },
  { path: PLAYER_CHILDREN[MobilePage.FootballPlayerSummary], element: <Football.PlayerSummary /> },
  { path: PLAYER_CHILDREN[MobilePage.FootballPlayerGames], element: <Football.PlayerGames /> },
];

const ROOT_ROUTES: RouteObject[] = [
  {
    path: ROOT[MobilePage.Home],
    children: [
      {
        path: ROOT[MobilePage.Home],
        element: <Navigate to={ROOT[MobilePage.Football]} replace />,
      },
      { path: ROOT[MobilePage.Football], element: <Football.Home /> },
      { path: ROOT[MobilePage.FootballFavorites], element: <Football.Favorites /> },
      { path: ROOT[MobilePage.FootballCategories], element: <Football.Categories /> },
      { path: ROOT[MobilePage.FootballCategory], element: <Football.Category /> },
      {
        path: ROOT[MobilePage.FootballTeam],
        element: <Football.Team />,
        children: FOOTBALL_TEAM_ROUTES,
      },
      {
        path: ROOT[MobilePage.FootballPlayer],
        element: <Football.Player />,
        children: FOOTBALL_PLAYER_ROUTES,
      },
      {
        path: ROOT[MobilePage.FootballSeason],
        element: <Football.Season />,
        children: FOOTBALL_SEASON_ROUTES,
      },
      {
        path: ROOT[MobilePage.FootballStadium],
        element: <Football.Stadium />,
        children: FOOTBALL_STADIUM_ROUTES,
      },
      {
        path: ROOT[MobilePage.FootballMatch],
        element: <Football.Match />,
      },
    ],
  },
  { path: ROOT[MobilePage.Settings], element: <Football.Settings /> },
  { path: ROOT[MobilePage.Search], element: <Football.Search /> },
  { path: ROOT[MobilePage.NotFound], element: <Common.NotFound /> },
];

const Router = () => {
  return useRoutes(ROOT_ROUTES);
};

export default Router;
