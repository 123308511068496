import React from 'react';
import classnames from 'classnames';

import { Event } from 'types';
import { Figure } from 'packages/shared/components';
import { DynamicEventScore } from 'packages/shared/modules';

import LinkToMatchPage from '../LinkToMatchPage';
import styles from './styles.scss';

type Props = {
  event: Event;
  style: any;
};

const EventDetailsMiniHeader = ({ event, style }: Props) => {
  const {
    isLive,
    teams: [home, away],
  } = event;

  return (
    <div
      style={style}
      className={classnames(styles.eventDetailsMiniHeader, { [styles.live]: isLive })}
    >
      <div className={styles.scoreBlock}>
        <Figure src={home.logo} className={styles.teamLogo} />
        <DynamicEventScore className={styles.score} event={event} />
        <Figure src={away.logo} className={styles.teamLogo} />
      </div>

      <LinkToMatchPage className={styles.link} isCompact />
    </div>
  );
};

export default EventDetailsMiniHeader;
