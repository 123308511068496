import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { favoritesSelectors, favoritesThunks } from 'packages/favorites';

const mapStateToProps = (state) => {
  return {
    favoriteTeams: favoritesSelectors.getFavoriteTeams(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    favoritesThunks: bindActionCreators(favoritesThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
