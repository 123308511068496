import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState = {
  isEventStatsLoading: true,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${actions.loadEventStatsStart}`]: () => {
    return { isEventStatsLoading: true };
  },
  [`${combineActions(actions.loadEventStatsSuccess, actions.loadEventStatsFailure)}`]: () => {
    return { isEventStatsLoading: false };
  },
  [`${actions.cleanEventStats}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
