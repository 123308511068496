import React, { RefObject, useEffect, useRef } from 'react';
import classnames from 'classnames';

import { getLastPortal } from 'packages/shared/components/SimplePortal/services';

import { HandlingEventType } from './types';
import styles from './styles.scss';

type Props = {
  children: any;
  onOutsideClick: Function;
  handlingEventType?: HandlingEventType;
  disabled?: boolean;
  useCapture?: boolean;
  useOverlay?: boolean;
  className?: string;
};

const OutsideClickHandler = ({
  children,
  onOutsideClick,
  handlingEventType = HandlingEventType.mouseUp,
  useOverlay = false,
  disabled = false,
  useCapture = true,
  className,
}: Props) => {
  const containerRef: RefObject<HTMLDivElement> = useRef(null);

  useEffect(() => {
    if (!disabled) {
      const clickOutsideContainer = useOverlay ? getLastPortal() : document;

      if (clickOutsideContainer) {
        clickOutsideContainer?.addEventListener(handlingEventType, onMouseUp, {
          capture: useCapture,
        });

        return () => {
          clickOutsideContainer?.removeEventListener(handlingEventType, onMouseUp, {
            capture: useCapture,
          });
        };
      }
    }
  }, [disabled]);

  const onMouseUp = (e) => {
    const isDescendantOfRoot = containerRef.current?.contains(e.target);

    if (!isDescendantOfRoot) {
      onOutsideClick(e);
    }
  };

  return (
    <div className={classnames(styles.outsideClickHandler, className)} ref={containerRef}>
      {children}
    </div>
  );
};

export default OutsideClickHandler;
