import { Event } from 'packages/events/types';

export const getEventsAfterLiveRemove = (oldEvents: Event[], newEvents: Event[]) => {
  const eventsAfterRemove: Event[] = [];

  for (const event of oldEvents) {
    if (!newEvents.find(({ id }) => id === event.id)) {
      eventsAfterRemove.push(event);
    }
  }

  return eventsAfterRemove;
};
