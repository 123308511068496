import React, { memo, ReactElement, useRef } from 'react';
import classnames from 'classnames';
import isEmpty from 'is-empty-typed';

import { Select as PlainSelect } from 'packages/shared/components';
import { SelectItem, SelectTheme } from 'packages/shared/components/Select/types';
import { ComponentLike } from 'types';

import { Label } from '../../components';
import styles from './styles.scss';

type Props = {
  name: string;
  onChange: (item: SelectItem) => void;
  items: SelectItem[];
  selectedItem?: SelectItem;
  label?: string | ReactElement;
  theme?: SelectTheme;
  className?: string;
  useScrollIntoPopoverView?: boolean;
  itemsContainerClass?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  itemComponent?: ComponentLike;
  labelComponent?: ComponentLike;
  labelComponentProps?: any;
};

const Select = ({
  useScrollIntoPopoverView = false,
  isDisabled = false,
  isLoading = false,
  selectedItem,
  items,
  onChange,
  label,
  name,
  theme,
  className,
  itemsContainerClass,
  itemComponent,
  labelComponent,
  labelComponentProps,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <div ref={containerRef} className={classnames(styles.selectInputContainer, className)}>
      {!isEmpty(label) && <Label name={name} label={label} />}
      <PlainSelect
        useScrollIntoPopoverView={useScrollIntoPopoverView}
        isLoading={isLoading}
        isDisabled={isDisabled}
        scrollElementRef={containerRef}
        onChange={onChange}
        items={items}
        selectedItem={selectedItem || items[0]}
        theme={theme}
        className={styles.select}
        itemsContainerClass={itemsContainerClass}
        itemComponent={itemComponent}
        labelComponent={labelComponent}
        labelComponentProps={labelComponentProps}
      />
    </div>
  );
};

export default memo(Select);
