import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../actions';
import { Status } from '../types';

const defaultState = {
  isEventStandingsLoading: true,
};

const reducerMap: ReducerMap<Status, any> = {
  [`${actions.loadEventStandingsStart}`]: () => {
    return { isEventStandingsLoading: true };
  },
  [`${combineActions(
    actions.loadEventStandingsSuccess,
    actions.loadEventStandingsFailure,
  )}`]: () => {
    return { isEventStandingsLoading: false };
  },
  [`${actions.cleanEventStandings}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
