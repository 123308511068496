import React, { memo, useCallback, useMemo } from 'react';
import classnames from 'classnames';

import { SelectItem } from 'packages/shared/components/Select/types';

import { SeasonsExtendedTournament } from './types';
import { getTournamentSeasonSelectItems } from './services';
import TournamentSeasonSelectView from './views';
import styles from './styles.scss';

type Props = {
  tournaments?: SeasonsExtendedTournament[];
  onTournamentSelect?: (tournamentItem: SelectItem) => void;
  onSeasonSelect?: (seasonItem: SelectItem) => void;
  activeTournamentId?: string;
  activeSeasonId?: string;
  className?: string;
  useSelectorsInOneRow?: boolean;
  isLoading?: boolean;
};

const extendTournamentSeasonSelect = (
  TournamentSelect: typeof TournamentSeasonSelectView[keyof typeof TournamentSeasonSelectView],
) =>
  memo(
    ({
      tournaments = [],
      activeTournamentId = '',
      activeSeasonId = '',
      onTournamentSelect,
      onSeasonSelect,
      className,
      useSelectorsInOneRow,
      isLoading = false,
    }: Props) => {
      const tournamentSeasonSelectItems = useMemo(
        () =>
          getTournamentSeasonSelectItems(
            tournaments,
            parseInt(activeTournamentId),
            parseInt(activeSeasonId),
          ),
        [tournaments, activeTournamentId, activeSeasonId],
      );

      const handleTournamentChange = useCallback(
        (tournamentItem: SelectItem) => {
          onTournamentSelect?.(tournamentItem);
        },
        [onTournamentSelect],
      );

      const handleSeasonChange = useCallback(
        (seasonItem: SelectItem) => {
          onSeasonSelect?.(seasonItem);
        },
        [onSeasonSelect],
      );

      return (
        <div className={classnames(styles.detailsSelectorContainer, className)}>
          <TournamentSelect
            tournamentSeasonSelectItems={tournamentSeasonSelectItems}
            onTournamentSelect={handleTournamentChange}
            onSeasonSelect={handleSeasonChange}
            useSelectorsInOneRow={useSelectorsInOneRow}
            isLoading={isLoading}
          />
        </div>
      );
    },
  );

export default {
  Desktop: extendTournamentSeasonSelect(TournamentSeasonSelectView.Desktop),
  DesktopNarrow: extendTournamentSeasonSelect(TournamentSeasonSelectView.DesktopNarrow),
  Tablet: extendTournamentSeasonSelect(TournamentSeasonSelectView.Tablet),
  TabletNarrow: extendTournamentSeasonSelect(TournamentSeasonSelectView.TabletNarrow),
  Mobile: extendTournamentSeasonSelect(TournamentSeasonSelectView.Mobile),
};
