import React, { useCallback, useEffect } from 'react';
import i18n from 'i18n-smart';
import { useNavigate } from 'react-router-dom';
import { ActionCreatorsMapObject } from 'redux';

import { Icon, SearchBar } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';
import { debounce } from 'packages/utils/Function.utils';

import { SEARCH_DELAY, MIN_SYMBOL_COUNT } from './constants';
import styles from './styles.scss';

type Props = {
  searchActions: ActionCreatorsMapObject;
  searchKey: string;
};

const SearchBlock = ({ searchActions, searchKey }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    return searchActions.clearResults;
  }, []);

  const goBack = useCallback(() => navigate(-1), []);

  const clearSearch = useCallback(() => {
    searchActions.setSearchKey('');
  }, []);

  const handleOnChange = (searchKey: string) => {
    if (searchKey.length >= MIN_SYMBOL_COUNT) {
      searchActions.loadResults({ searchKey });
    }

    searchActions.setSearchKey(searchKey);
  };

  const debouncedHandleOnChange = useCallback(debounce(handleOnChange, SEARCH_DELAY), []);

  return (
    <div className={styles.searchContainer}>
      <SearchBar
        placeholder={i18n.value('header.search.placeholder')}
        value={searchKey}
        onClear={clearSearch}
        onChange={debouncedHandleOnChange}
        className={styles.input}
      />
      <div onClick={goBack}>
        <Icon className={styles.closeIcon} id={IconType.Cross} />
      </div>
    </div>
  );
};

export default SearchBlock;
