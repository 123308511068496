import DifferentiatedUpdatesSelector from './DifferentiatedUpdatesSelector';
import { ObjectDifferenceExtractor } from '../differenceExtractors';
import { DataReducerConfig } from '../../types';

class DifferentiatedObjectUpdatesSelector<ItemType extends {}>
  extends DifferentiatedUpdatesSelector<ItemType> {
  protected differenceExtractor: ObjectDifferenceExtractor<ItemType | null>
  protected data: ItemType | null;

  constructor(config?: DataReducerConfig<ItemType | null>) {
    super(config);
    this.differenceExtractor = new ObjectDifferenceExtractor(config?.differenceExtractorConfig);
    this.data = null;
  }
}

export default DifferentiatedObjectUpdatesSelector;
