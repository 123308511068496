import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  eventLineupsThunks,
  eventLineupsSelectors,
  eventLineupsActions,
} from 'packages/event-lineups';
import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => {
  return {
    activeEvent: eventDetailsSelectors.getActiveEvent(state),
    competitors: eventLineupsSelectors.getData(state),
    isLoading: eventLineupsSelectors.getIsEventLineupLoading(state),
    teams: eventDetailsSelectors.getActiveEventTeams(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    eventLineupsActions: bindActionCreators(eventLineupsThunks, dispatch),
    cleanEventLineups: () => dispatch(eventLineupsActions.cleanEventLineups()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
