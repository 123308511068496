import { Language } from 'types';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';

import { LanguageLabel } from '../components';

const getNavigationItems = (languages: Language[]): NavigationMenuItem[] =>
  languages.map((language) => {
    const { name, localeCode } = language;

    return {
      id: localeCode,
      name,
      itemComponent: LanguageLabel,
      itemComponentProps: { language },
    };
  });

export default getNavigationItems;
