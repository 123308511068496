import { RefObject, useState } from 'react';

import { PopoverAlign, PopoverCustomPosition, PopoverJustify, PopoverStyle } from '../types';
import { calculatePopoverPosition } from '../services';
import { DEFAULT_POPOVER_STYLE, DEFAULT_MODAL_STYLE, INITIAL_POPOVER_STYLE } from '../constants';

interface Params {
  popoverRef: RefObject<HTMLDivElement>;
  getParent: () => HTMLElement | null;
  targetRef: RefObject<HTMLDivElement>;
  boundedByParent: boolean;
  customPosition?: PopoverCustomPosition;
  isModal?: boolean;
  insertIntoParent?: boolean;
}

const usePopoverPosition = ({
  popoverRef,
  getParent,
  targetRef,
  customPosition,
  boundedByParent,
  isModal,
  insertIntoParent,
}: Params): [PopoverStyle, (justify: PopoverJustify, align: PopoverAlign) => void, () => void] => {
  const [popoverPositionStyle, setPopoverPositionStyle] = useState<PopoverStyle>(
    INITIAL_POPOVER_STYLE,
  );

  const calculatePosition = (justify, align) => {
    if (isModal) {
      setPopoverPositionStyle({ ...DEFAULT_MODAL_STYLE, ...customPosition });
    } else if (popoverRef?.current) {
      const recalculatedPosition = calculatePopoverPosition({
        justify,
        align,
        popoverEl: popoverRef.current,
        targetEl: targetRef.current,
        getParent,
        customPosition,
        insertIntoParent,
        boundedByParent,
      });

      setPopoverPositionStyle({
        ...DEFAULT_POPOVER_STYLE,
        ...recalculatedPosition,
      });
    } else {
      setPopoverPositionStyle(DEFAULT_POPOVER_STYLE);
    }
  };

  const dropPopoverPosition = () => {
    setPopoverPositionStyle(INITIAL_POPOVER_STYLE);
  };

  return [popoverPositionStyle, calculatePosition, dropPopoverPosition];
};

export default usePopoverPosition;
