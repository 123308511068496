export enum PlayerHappeningType {
  Substitution = 'substitution',
  SubstitutionIn = 'substitution-in',
  SubstitutionOut = 'substitution-out',
  Assist = 'assist',
  Goal = 'goal',
  OwnGoal = 'own-goal',
  PenaltyGoal = 'penalty-goal',
  YellowCard = 'yellow-card',
  RedCard = 'red-card',
  YellowRedCard = 'yellow-red-card',
}
