import React, { memo } from 'react';

import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';
import { TournamentChipList } from 'packages/shared/components';

import DetailsSelector from '../../../DetailsSelector';
import { LoadingSkeleton } from './components';

type Props = {
  tournaments?: SeasonsExtendedTournament[];
  onTournamentClick: (tournament: SeasonsExtendedTournament) => void;
  isLoading?: boolean;
};

const TournamentSeasonDetails = ({ tournaments, onTournamentClick, isLoading = false }: Props) => {
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <>
      <DetailsSelector.Desktop />
      <TournamentChipList.Desktop tournaments={tournaments} onTournamentClick={onTournamentClick} />
    </>
  );
};

export default memo(TournamentSeasonDetails);
