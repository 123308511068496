import React, { MutableRefObject, useRef } from 'react';
import classnames from 'classnames';

import { MatchUp, MatchUpActiveType } from 'packages/event-standings/types';
import { isCommonMatchUp } from 'packages/event-standings/services';

import { MatchUpsGroup } from './components';
import { useHiddenContent } from './services';
import styles from './styles.scss';

type Props = {
  isFirstRound: boolean;
  scrollContainerRef: MutableRefObject<HTMLDivElement | null>;
  matchUp: MatchUp;
  activeTeamIds?: number[];
  activeUniqueTournamentId?: number | string;
  activeSeasonId?: number | string;
  className?: string;
};

const LinkedMatchUp = ({
  isFirstRound,
  matchUp,
  scrollContainerRef,
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
  className,
}: Props) => {
  const linkedMatchUpRef = useRef<HTMLDivElement>(null);
  const matchUpDetailsRef = useRef<HTMLDivElement>(null);
  const { activeType = undefined, isActive = undefined } = isCommonMatchUp(matchUp) ? matchUp : {};

  useHiddenContent(linkedMatchUpRef, matchUpDetailsRef, scrollContainerRef);

  return (
    <div ref={linkedMatchUpRef} className={styles.matchUpContainer}>
      <div
        ref={matchUpDetailsRef}
        className={classnames(styles.matchUpDetails, className, {
          [styles.firstRound]: isFirstRound,
        })}
      >
        <div
          className={classnames(styles.previousRoundConnection, {
            [styles.firstRound]: isFirstRound,
          })}
        >
          <div className={styles.splitConnection}>
            <div
              className={classnames(styles.connection, styles.topConnection, {
                [styles.active]:
                  activeType === MatchUpActiveType.HalfTop || activeType === MatchUpActiveType.Full,
              })}
            />
            <div
              className={classnames(styles.connection, styles.bottomConnection, {
                [styles.active]:
                  activeType === MatchUpActiveType.HalfBottom ||
                  activeType === MatchUpActiveType.Full,
              })}
            />
          </div>
          <div
            className={classnames(styles.connection, styles.centerConnection, {
              [styles.active]: activeType,
            })}
          />
        </div>
        <MatchUpsGroup
          matchUp={matchUp}
          activeTeamIds={activeTeamIds}
          activeUniqueTournamentId={activeUniqueTournamentId}
          activeSeasonId={activeSeasonId}
          isActive={isActive}
        />
      </div>
    </div>
  );
};

export default LinkedMatchUp;
