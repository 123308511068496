import React from 'react';

import styles from './styles.scss';

type Props = {
  name: string;
};

const PlayerPopoverContent = ({ name }: Props) => {
  return <div className={styles.wrapper}>{name}</div>;
};
export default PlayerPopoverContent;
