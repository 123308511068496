import React, { memo, useCallback, useMemo } from 'react';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import {
  Input,
  ItemLabel,
  Separator,
  SelectItemComponent,
  SelectLabelComponent,
} from 'packages/shared/components';
import { InputValidationType } from 'packages/shared/components/Input/types';
import { Interfaces, Enums } from 'packages/team-player-statistics/types';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';
import { SelectItem } from 'packages/shared/components/Select/types';
import { SeasonsExtendedTournament } from 'packages/shared/components/TournamentSeasonSelect/types';

import styles from './styles.scss';
import { getTournamentSeasonSelectItems } from '../../../../services';

type Props = {
  onActiveStatTypeChange: (statType: Enums.StatType) => void;
  onSelectedPositionsChange: (positions: SelectItem[]) => void;
  onSelectedFootChange: (foot: SelectItem) => void;
  onSelectedNationalitiesChange: (
    nationalities: Interfaces.CustomFilter.NationalitySelectOption[],
  ) => void;
  onAgeRangeChange: (from: string, to: string) => void;
  onSelectedTournamentChange: (item: SelectItem) => void;
  onSelectedSeasonChange: (item: SelectItem) => void;
  activeStatType: Enums.StatType;
  statTabs: NavigationMenuItem[];
  positionSelectItems: SelectItem[];
  selectedPositionItems: SelectItem[];
  footSelectItems: SelectItem[];
  selectedFootItem?: SelectItem;
  selectedTournamentId?: number;
  selectedSeasonId?: number;
  nationalitySelectItems: Interfaces.CustomFilter.NationalitySelectOption[];
  selectedNationalityItems: Interfaces.CustomFilter.NationalitySelectOption[];
  ageRange: Interfaces.CustomFilter.Range;
  className?: string;
  tournaments: SeasonsExtendedTournament[];
};

const GeneralFilter = ({
  activeStatType,
  onActiveStatTypeChange,
  onSelectedPositionsChange,
  onSelectedFootChange,
  onSelectedNationalitiesChange,
  onAgeRangeChange,
  statTabs,
  positionSelectItems,
  selectedPositionItems,
  footSelectItems,
  selectedFootItem,
  nationalitySelectItems,
  selectedNationalityItems,
  ageRange,
  className,
  selectedTournamentId,
  selectedSeasonId,
  onSelectedTournamentChange,
  onSelectedSeasonChange,
  tournaments = [],
}: Props) => {
  const { tournamentItems, seasonItems, activeSeasonItem, activeTournamentItem } = useMemo(
    () => getTournamentSeasonSelectItems(tournaments, selectedTournamentId, selectedSeasonId),
    [tournaments, selectedTournamentId, selectedSeasonId],
  );

  const handleActiveStatTypeChange = useCallback(
    (activeStatsTab: string | number) => onActiveStatTypeChange(activeStatsTab as Enums.StatType),
    [],
  );

  return (
    <div>
      <div className={classnames(styles.generalFilterGroup, className)}>
        <Input.Select
          onChange={onSelectedTournamentChange}
          selectedItem={activeTournamentItem}
          items={tournamentItems}
          labelComponentProps={activeTournamentItem?.componentProps}
          labelComponent={SelectLabelComponent.Tournament}
          itemComponent={SelectItemComponent.Tournament}
          label={i18n.value('team.playerStatistics.customFilter.general.tournamentLabel')}
          name="Tournament"
        />

        <Input.Select
          onChange={onSelectedSeasonChange}
          selectedItem={activeSeasonItem}
          items={seasonItems}
          label={i18n.value('team.playerStatistics.customFilter.general.seasonLabel')}
          name="Season"
        />
      </div>

      <Separator className={styles.separator} />

      <div className={classnames(styles.generalFilterGroup, className)}>
        <Input.Tabs
          onChange={handleActiveStatTypeChange}
          activeId={activeStatType}
          items={statTabs}
          label={i18n.value('team.playerStatistics.customFilter.general.showStatsLabel')}
          name="Show stats"
          isWidthLimited
        />

        <Input.Multiselect
          onChange={onSelectedPositionsChange}
          selectedItems={selectedPositionItems}
          items={positionSelectItems}
          pluralItemLabel="team.playerStatistics.customFilter.general.positionsSelectPlural"
          label={i18n.value('team.playerStatistics.customFilter.general.positionLabel')}
          name="Position"
        />

        <Input.Select
          onChange={onSelectedFootChange}
          selectedItem={selectedFootItem}
          items={footSelectItems}
          label={i18n.value('team.playerStatistics.customFilter.general.preferredFootLabel')}
          name="Preferred foot"
        />

        <Input.SearchSelect<Interfaces.CustomFilter.NationalitySelectOption>
          items={nationalitySelectItems}
          selectedItems={selectedNationalityItems}
          itemsContainerClass={styles.searchListItems}
          onSelectionChange={onSelectedNationalitiesChange}
          itemComponent={ItemLabel}
          labelPrefix={i18n.value('team.playerStatistics.customFilter.general.searchLabel')}
          label={i18n.value('team.playerStatistics.customFilter.general.nationalityLabel')}
          name="Nationality"
        />

        <Input.TextRange
          startValue={ageRange.from}
          endValue={ageRange.to}
          onRangeValuesChange={onAgeRangeChange}
          inputValidationType={InputValidationType.Integer}
          startPlaceholder="0"
          endPlaceholder="∞"
          label={i18n.value('team.playerStatistics.customFilter.general.ageLabel')}
          name="Age"
        />
      </div>
    </div>
  );
};

export default memo(GeneralFilter);
