import {
  BracketStageData,
  CommonTableStatistics,
  MatchUp,
  SeasonTableRow,
  SeasonTableStageData,
  Stage,
  StatisticsGroup,
} from 'packages/event-standings/types';
import {
  isBracketStage,
  isCommonMatchUp,
  isTableStage,
} from 'packages/event-standings/services/utils';

const getActiveStageIdByTeamId = (stages: Stage[], teamId: number) => {
  const reversedStagesCopy = [...stages].reverse();
  const stage = getStageByTeamId(reversedStagesCopy, teamId);

  return stage?.id;
};

const getStageByTeamId = (stages: Stage[], teamId: number) => {
  for (const stage of stages) {
    let foundTeam;

    if (isTableStage(stage)) {
      foundTeam = findSeasonTableRowByTeamId(stage, teamId);
    } else if (isBracketStage(stage)) {
      foundTeam = findBracketMatchUpByTeamId(stage, teamId);
    }

    if (foundTeam) {
      return stage;
    }
  }
};

const findSeasonTableRowByTeamId = (
  stage: Stage<SeasonTableStageData>,
  teamId: number,
): SeasonTableRow<Record<StatisticsGroup, CommonTableStatistics>> | undefined => {
  for (const tournamentTable of stage.data.tournamentTables) {
    const foundRow = tournamentTable.rows.find((row) => {
      return row.team.id === teamId;
    });

    if (foundRow) {
      return foundRow;
    }
  }
};

const findBracketMatchUpByTeamId = (
  stage: Stage<BracketStageData>,
  teamId: number,
): MatchUp | undefined => {
  for (const round of stage.data.rounds) {
    const foundMatchUp = round.matchups.find((matchUp) => {
      if (isCommonMatchUp(matchUp)) {
        return [matchUp.homeTeam.id, matchUp.awayTeam.id].includes(teamId);
      }
    });

    if (foundMatchUp) {
      return foundMatchUp;
    }
  }
};

export default getActiveStageIdByTeamId;
