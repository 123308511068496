exports = module.exports = require("../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js")(true);
// Module
exports.push([module.id, ".__VEAmb_o{display:flex;justify-content:flex-start}.__VEAmb_o ._3jd7Vwof{display:flex;align-items:center;text-align:left;overflow:hidden}.__VEAmb_o ._3jd7Vwof ._cEMkD3bW{overflow:hidden}.__VEAmb_o ._3jd7Vwof ._cEMkD3bW>div{overflow:hidden;white-space:nowrap;text-overflow:ellipsis}.__VEAmb_o ._3jd7Vwof ._1Ue9zifz{margin-right:8px;min-width:16px;max-width:16px;min-height:16px;max-height:16px}\n", "",{"version":3,"sources":["/opt/frontend_dev_new/src/scripts/packages/season-stadiums-ui/containers/Stadiums/components/StadiumsTable/components/CountryLogoCell/styles.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/ellipsis.mixin.scss","/opt/frontend_dev_new/src/scripts/packages/theme/mixins/size.mixin.scss"],"names":[],"mappings":"AAEA,WAEI,YAAa,CACb,0BAA2B,CAH/B,sBAMM,YAAa,CACb,kBAAmB,CACnB,eAAgB,CAChB,eAAgB,CATtB,iCAYQ,eAAgB,CAZxB,qCCDE,eAAgB,CAChB,kBAFuC,CAGvC,sBAAuB,CDDzB,iCAqBQ,gBAAiB,CEtBvB,cFwB8B,CEvB9B,cFuB8B,CEpB5B,eFoB4B,CEnB5B,eFmB4B","file":"styles.scss","sourcesContent":["@import '~packages/theme/common';\r\n\r\n:local {\r\n  .country-cell {\r\n    display: flex;\r\n    justify-content: flex-start;\r\n\r\n    .country-block {\r\n      display: flex;\r\n      align-items: center;\r\n      text-align: left;\r\n      overflow: hidden;\r\n\r\n      .name {\r\n        overflow: hidden;\r\n\r\n        > div {\r\n          @include text-ellipsis();\r\n\r\n        }\r\n      }\r\n\r\n      .logo {\r\n        margin-right: 8px;\r\n\r\n        @include fixed-size(16px);\r\n      }\r\n    }\r\n  }\r\n}","@mixin text-ellipsis($white-space: nowrap) {\n  overflow: hidden;\n  white-space: $white-space;\n  text-overflow: ellipsis;\n}\n","@mixin fixed-size($width, $height: null) {\n  min-width: $width;\n  max-width: $width;\n\n  @if ($height == null) {\n    min-height: $width;\n    max-height: $width;\n  } @else {\n    min-height: $height;\n    max-height: $height;\n  }\n}"]}]);

// Exports
exports.locals = {
	"country-cell": "__VEAmb_o",
	"countryCell": "__VEAmb_o",
	"country-block": "_3jd7Vwof",
	"countryBlock": "_3jd7Vwof",
	"name": "_cEMkD3bW",
	"logo": "_1Ue9zifz"
};