import { connect } from 'react-redux';

import { detailsSelectors } from 'packages/team-summary';

const mapStateToProps = (state) => {
  return {
    stages: detailsSelectors.standings.getStages(state),
    activeStageId: detailsSelectors.standings.getActiveStageId(state),
  };
};

export default connect(mapStateToProps);
