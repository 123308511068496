import React from 'react';
import classnames from 'classnames';

import { BarTheme } from './types';
import styles from './styles.scss';

type Props = {
  percentages?: number;
  isReversed?: boolean;
  isHighlighted?: boolean;
  className?: string;
  theme?: BarTheme;
};

const PercentageBar = ({
  percentages = 0,
  isReversed = false,
  isHighlighted = false,
  theme = BarTheme.GreenGray,
  className,
}: Props) => {
  const containerClasses = classnames(
    styles.percentageBar,
    { [styles.reversed]: isReversed },
    { [styles.highlighted]: isHighlighted },
    styles[theme],
    className,
  );

  return (
    <div className={containerClasses}>
      <div className={styles.background} />
      <div className={styles.percentages} style={{ width: `${percentages}%` }} />
    </div>
  );
};

export default React.memo(PercentageBar);
