import { MobilePage } from '../types';

export const ROOT = {
  [MobilePage.Home]: '/',
  [MobilePage.Settings]: '/settings',
  [MobilePage.Search]: '/search',
  [MobilePage.Football]: '/football',
  [MobilePage.FootballSeason]: '/football/unique-tournament/:tournamentId/season/:seasonId',
  [MobilePage.FootballTeam]: '/football/team/:teamId',
  [MobilePage.FootballFavorites]: '/football/favorites',
  [MobilePage.FootballCategories]: '/football/categories',
  [MobilePage.FootballCategory]: '/football/category/:categoryId',
  [MobilePage.FootballPlayer]: '/football/player/:playerId',
  [MobilePage.FootballStadium]: '/football/stadium/:stadiumId',
  [MobilePage.FootballMatch]: '/football/match/:eventId',
  [MobilePage.NotFound]: '*',
};

export const SEASON_CHILDREN = {
  [MobilePage.FootballSeasonHome]: '',
  [MobilePage.FootballSeasonSummary]: 'summary',
  [MobilePage.FootballSeasonGames]: 'games',
  [MobilePage.FootballSeasonStandings]: 'standings',
  [MobilePage.FootballSeasonTeamStatistics]: 'team-statistics',
  [MobilePage.FootballSeasonPlayerStatistics]: 'player-statistics',
  [MobilePage.FootballSeasonStadiums]: 'stadiums',
  [MobilePage.FootballSeasonHistory]: 'history',
  [MobilePage.FootballSeasonComingSoon]: '*',
};

export const STADIUM_CHILDREN = {
  [MobilePage.FootballStadiumHome]: '',
  [MobilePage.FootballStadiumSummary]: 'unique-tournament/:tournamentId/season/:seasonId',
};

export const TEAM_CHILDREN = {
  [MobilePage.FootballTeamHome]: '',
  [MobilePage.FootballTeamSummary]: 'summary',
  [MobilePage.FootballTeamSummaryDescription]:
    'summary/unique-tournament/:tournamentId/season/:seasonId',
  [MobilePage.FootballTeamStandings]: 'standings/unique-tournament/:tournamentId/season/:seasonId',
  [MobilePage.FootballTeamPlayerStatistics]:
    'player-statistics/unique-tournament/:tournamentId/season/:seasonId',
  [MobilePage.FootballTeamGames]: 'games',
  [MobilePage.FootballTeamSquad]: 'squad',
};

export const PLAYER_CHILDREN = {
  [MobilePage.FootballPlayerHome]: '',
  [MobilePage.FootballPlayerSummary]: 'summary',
  [MobilePage.FootballPlayerGames]: 'games',
};
