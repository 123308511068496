import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Category } from 'types';

import { categoryListActions } from '../../../actions';

const defaultState: Category[] = [];

const reducerMap: ReducerMap<Category[], Category[]> = {
  [`${combineActions(
    categoryListActions.loadCategoriesStart,
    categoryListActions.loadSelectedCategoryFailure,
  )}`]: () => {
    return defaultState;
  },
  [`${categoryListActions.loadCategoriesSuccess}`]: (state, { payload }) => {
    return payload;
  },
};

export default handleActions(reducerMap, defaultState);
