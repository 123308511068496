import { Event, YearsGroupedEvents, YearsGroupedEventSet } from '../types';

const groupEventsByYears = (events: Event[]): YearsGroupedEvents => {
  const yearsGroupedEvents: YearsGroupedEventSet = {};

  for (const event of events) {
    const { year } = event.date;

    if (yearsGroupedEvents[year]) {
      yearsGroupedEvents[year].push(event);
    } else {
      yearsGroupedEvents[year] = [event];
    }
  }

  return Object.entries(yearsGroupedEvents).reverse();
};

export default groupEventsByYears;
