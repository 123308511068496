import { useEffect, useState } from 'react';

const DEFAULT_SHRINK_HEADER_START = 20;
const DEFAULT_SHRINK_HEADER_END = 5;

interface Params {
  start?: number;
  end?: number;
}

const useDocumentHeaderShrink = ({
  start = DEFAULT_SHRINK_HEADER_START,
  end = DEFAULT_SHRINK_HEADER_END,
}: Params = {}) => {
  const [isShrunk, setShrunk] = useState(false);

  useEffect(() => {
    const handler = () => {
      setShrunk((isShrunk) => {
        if (
          !isShrunk &&
          (document.body.scrollTop > start || document.documentElement.scrollTop > start)
        ) {
          return true;
        }

        if (isShrunk && document.body.scrollTop < end && document.documentElement.scrollTop < end) {
          return false;
        }

        return isShrunk;
      });
    };

    window.addEventListener('scroll', handler);

    return () => {
      window.removeEventListener('scroll', handler);
    };
  }, []);

  return isShrunk;
};

export default useDocumentHeaderShrink;
