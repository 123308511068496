import React, { memo } from 'react';

import { Player } from 'packages/season-player-statistics/types';

import styles from './styles.scss';

type Props = {
  player: Player;
};

const PlayerCell = ({ player }: Props) => {
  const { rank } = player;

  return (
    <div className={styles.playerCell}>
      <div className={styles.playerRank}>{rank}</div>
    </div>
  );
};

export default memo(PlayerCell);
