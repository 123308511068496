import { EventLineups } from 'packages/rest';
import { matchTimeLineSelectors } from 'packages/match-time-line';
import { BaseHappening } from 'packages/event-time-line/types';

import {
  mapCompetitors,
  groupHappeningsByPlayerId,
  mapAssistHappenings,
  sortHappeningsByTime,
} from 'packages/event-lineups/services';

import { loadLineupsStart, loadLineupsSuccess, loadLineupsFailure } from '../actions';

const loadLineups = ({ eventId }) => async (dispatch, getState) => {
  try {
    dispatch(loadLineupsStart());

    const timeLineHappenings: BaseHappening[] = matchTimeLineSelectors.getData(getState());
    const assistsHappenings = mapAssistHappenings(timeLineHappenings);
    const happenings = timeLineHappenings.concat(assistsHappenings);
    const sortedHappenings = sortHappeningsByTime(happenings);
    const happeningsByPlayerId = groupHappeningsByPlayerId(sortedHappenings);

    const { data } = await EventLineups.loadEventLineups({ eventId });
    const eventLineups = mapCompetitors(data.results, happeningsByPlayerId);

    dispatch(loadLineupsSuccess(eventLineups));
  } catch (error) {
    dispatch(loadLineupsFailure());
  }
};

export default loadLineups;
