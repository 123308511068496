import React from 'react';

import { SeasonCompactStandings } from 'packages/season-standings-ui';
import { ActualEvents } from 'packages/season-events-ui';

import styles from './styles.scss';

const SeasonSummary = () => {
  return (
    <div className={styles.seasonSummary}>
      <SeasonCompactStandings.Desktop />

      <ActualEvents.Desktop />
    </div>
  );
};

export default React.memo(SeasonSummary);
