import React, { useEffect, useMemo, useRef } from 'react';

import { Table, TournamentTableRulesBlock } from 'packages/shared/components';
import {
  CommonTableStatistics,
  StatisticsGroup,
  TournamentTable,
} from 'packages/event-standings/types';
import { sortTournamentRowsByStatisticsGroupPosition } from 'packages/event-standings/services';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';
import { Team } from 'types';

import styles from './styles.scss';
import { getColumnSchema, getRowSchema, scrollToTable } from './services';

type Props = {
  tournamentTables: TournamentTable<CommonTableStatistics>[];
  activeTeamIds?: number[];
  activeUniqueTournamentId?: string | number;
  activeSeasonId?: string | number;
  teamIdToScroll?: number;
};

const WideStatistics = ({
  tournamentTables,
  activeTeamIds = [],
  activeUniqueTournamentId,
  activeSeasonId,
  teamIdToScroll,
}: Props) => {
  const tableRef = useRef<HTMLDivElement>(null);
  const { appNavigate } = useNavigation();

  const activeTableIndex = useMemo(() => {
    return tournamentTables.find(({ rows }) => rows.some(({ team }) => team.id === teamIdToScroll))
      ?.id;
  }, [teamIdToScroll, tournamentTables]);

  useEffect(() => {
    if (tableRef.current) {
      scrollToTable({ tableRef });
    }
  }, []);

  const handleTeamClick = (team: Team) => {
    if (activeUniqueTournamentId && activeSeasonId) {
      appNavigate(DesktopPage.FootballTeamSummaryDescription, {
        teamId: team.id,
        uniqueTournamentId: activeUniqueTournamentId,
        seasonId: activeSeasonId,
      });
    } else {
      appNavigate(DesktopPage.FootballTeam, { teamId: team.id });
    }
  };

  return (
    <>
      {tournamentTables.map(({ rows, id, groupName, rule, promotions, teamComments }, index) => (
        <div
          ref={activeTableIndex === id ? tableRef : undefined}
          key={id}
          className={styles.tableContainer}
        >
          <Table
            data={sortTournamentRowsByStatisticsGroupPosition(rows, StatisticsGroup.Overall)}
            onRow={getRowSchema({ onClick: handleTeamClick, activeTeamIds })}
            columns={getColumnSchema({ groupName })}
            columnClassName={styles.tableColumn}
            rowClassName={styles.tableRow}
          />
          {index === tournamentTables.length - 1 && (
            <TournamentTableRulesBlock
              rule={rule}
              promotions={promotions}
              teamComments={teamComments}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default WideStatistics;
