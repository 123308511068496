import React from 'react';
import classnames from 'classnames';

import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import { IconType } from '../Icon/types';
import Icon from '../Icon';
import styles from './styles.scss';

type Props = {
  playerInName?: string;
  playerOutName?: string;
  playerInId?: number;
  playerOutId?: number;
};

const SubstitutionByPlayer = ({ playerOutId, playerInId, playerInName, playerOutName }: Props) => {
  const { appNavigate } = useNavigation();

  const getPlayerClickHandler = (playerId?: number) => {
    return () => {
      if (playerId) {
        appNavigate(DesktopPage.FootballPlayer, { playerId });
      }
    };
  };

  return (
    <div className={styles.wrapper}>
      <div className={classnames(styles.player, styles.out)}>
        <Icon className={styles.icon} id={IconType.SubstitutionOut} />
        <span onClick={getPlayerClickHandler(playerOutId)}>{playerOutName}</span>
      </div>
      <div className={styles.player}>
        <Icon className={styles.icon} id={IconType.SubstitutionIn} />
        <span onClick={getPlayerClickHandler(playerInId)}>{playerInName}</span>
      </div>
    </div>
  );
};

export default SubstitutionByPlayer;
