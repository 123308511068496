import { isEmpty } from 'packages/utils/Object.utils';
import { SummaryHappeningType } from 'packages/events/types';
import { BaseHappening, PeriodHappening } from 'packages/event-time-line/types';

import { HappeningPeriod } from '../types';

const groupHappeningsByPeriod = (timeLineHappenings: BaseHappening[]) => {
  if (!timeLineHappenings.length) {
    return [];
  }

  const mappedHappenings = [...timeLineHappenings];
  const happeningPeriods: HappeningPeriod[] = [];
  const unknownTimeHappenings: BaseHappening[] = [];
  let currentHappeningIndex = 0;

  for (const happening of mappedHappenings) {
    if (happening.eventType === SummaryHappeningType.PERIOD_START) {
      happeningPeriods.push({
        id: currentHappeningIndex++,
        periodHappening: happening as PeriodHappening,
        happeningsOfThePeriod: [],
      });
    } else if (!isEmpty(happeningPeriods)) {
      const lastPeriodHappeningIndex = happeningPeriods.length - 1;

      if (happening.minute !== undefined) {
        happeningPeriods[lastPeriodHappeningIndex].happeningsOfThePeriod.push(happening);
      } else {
        unknownTimeHappenings.push(happening);
      }
    }
  }

  const lastPeriodHappeningIndex = happeningPeriods.length - 1;
  happeningPeriods[lastPeriodHappeningIndex].happeningsOfThePeriod.push(...unknownTimeHappenings);

  return happeningPeriods;
};

export default groupHappeningsByPeriod;
