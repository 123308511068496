import { PopoverAlign } from '../types';

const calculateAlignWithoutTarget = (
  align: PopoverAlign,
  popoverEl: HTMLElement,
  getParent: () => HTMLElement | null,
  insertIntoParent: boolean = false,
  customTop: number = 0,
): number => {
  const popoverHeight = popoverEl.clientHeight;
  const parentEl = getParent();

  switch (align) {
    case PopoverAlign.Top:
      return getTopAlign(popoverHeight, customTop, parentEl, insertIntoParent);
    case PopoverAlign.CenterStart:
    case PopoverAlign.Center:
    case PopoverAlign.CenterEnd:
      return getCenterAlign(popoverHeight, customTop, parentEl, insertIntoParent);
    case PopoverAlign.Bottom:
      return getBottomAlign(popoverHeight, customTop, parentEl, insertIntoParent);
    default:
      throw Error('Invalid align specified');
  }
};

const getTopAlign = (
  popoverHeight: number,
  customTop: number,
  parentEl: HTMLElement | null,
  insertIntoParent: boolean,
) => {
  if (parentEl && !insertIntoParent) {
    return parentEl.getBoundingClientRect().top + customTop;
  }

  return customTop;
};

const getCenterAlign = (
  popoverHeight: number,
  customTop: number,
  parentEl: HTMLElement | null,
  insertIntoParent: boolean,
) => {
  const centerEdgeTop = getCenterEdgeTop(parentEl, insertIntoParent);

  return centerEdgeTop - popoverHeight / 2 + customTop;
};

const getBottomAlign = (
  popoverHeight: number,
  customTop: number,
  parentEl: HTMLElement | null,
  insertIntoParent: boolean,
) => {
  const rightEdgeTop = getRightEdgeTop(parentEl, insertIntoParent);

  return rightEdgeTop - popoverHeight + customTop;
};

const getCenterEdgeTop = (parentEl: HTMLElement | null, insertIntoParent: boolean) => {
  if (!parentEl) {
    return window.innerHeight / 2;
  }

  if (insertIntoParent) {
    return parentEl.clientHeight / 2;
  } else {
    const { top, height } = parentEl.getBoundingClientRect();

    return top + height / 2;
  }
};

const getRightEdgeTop = (parentEl: HTMLElement | null, insertIntoParent: boolean) => {
  if (!parentEl) {
    return window.innerHeight;
  }

  if (insertIntoParent) {
    return parentEl.clientHeight;
  } else {
    const { top, height } = parentEl.getBoundingClientRect();

    return top + height;
  }
};

export default calculateAlignWithoutTarget;
