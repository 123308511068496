import { useEffect, useMemo, useRef, useState } from 'react';
import { Duration } from 'luxon';

import DateService from 'packages/date-az';

interface Params {
  modifyTimeOnCount?: (timeDiff: Duration) => Duration;
  startDate?: string;
  countStep?: number;
}

const DEFAULT_COUNT_STEP = 1000;

const useLiveTime = ({
  startDate = '',
  countStep = DEFAULT_COUNT_STEP,
  modifyTimeOnCount = (timeDiff) => timeDiff,
}: Params) => {
  const startDateTime = DateService.getDateTimeFromIsoString(startDate);

  const initialTime = useMemo(() => {
    return modifyTimeOnCount(
      DateService.getDate().diff(startDateTime, ['minutes', 'seconds', 'milliseconds']),
    );
  }, [startDate]);

  const ref = useRef(initialTime);
  const [time, setTime] = useState(ref.current);

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = DateService.getDate().diff(startDateTime, [
        'minutes',
        'seconds',
        'milliseconds',
      ]);
      const modifiedTime = modifyTimeOnCount(diff);

      ref.current = modifiedTime;
      setTime(modifiedTime);
    }, countStep);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return time;
};

export default useLiveTime;
