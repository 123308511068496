import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { categoryListActions } from '../../../actions';
import { Status } from '../../../types';

const defaultState = {
  isLoading: true,
};

const reducerMap: ReducerMap<Status, void> = {
  [`${categoryListActions.loadSelectedCategoryStart}`]: (state) => {
    return { ...state, isLoading: true };
  },
  [`${combineActions(
    categoryListActions.loadSelectedCategoryFailure,
    categoryListActions.loadSelectedCategorySuccess,
  )}`]: (state) => {
    return { ...state, isLoading: false };
  },
};

export default handleActions(reducerMap, defaultState);
