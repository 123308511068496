import { toCamelCase } from 'packages/utils/Object.utils';

import { Interfaces, Enums } from '../types';

const DEFAULT_PARAMETER_VALUE = 0;
const EXTENDED_PARAMETER_VALUE = {
  total: 0,
  average: 0,
  matches: 0,
};

const mapTeamStatistics = (
  teamStatistics: Record<string, any>,
): Interfaces.Details.Statistics.Data => {
  const { statistics } = toCamelCase(teamStatistics, true);

  const {
    gamesPlayed = DEFAULT_PARAMETER_VALUE,

    shootingEfficiency = EXTENDED_PARAMETER_VALUE,
    shotsTotal = EXTENDED_PARAMETER_VALUE,
    shotsOnTarget = EXTENDED_PARAMETER_VALUE,
    shotsOffTarget = EXTENDED_PARAMETER_VALUE,
    shotsOnPost = EXTENDED_PARAMETER_VALUE,
    averageBallPossession = EXTENDED_PARAMETER_VALUE,
    cornerKicks = EXTENDED_PARAMETER_VALUE,
    freeKicks = EXTENDED_PARAMETER_VALUE,
    offsides = EXTENDED_PARAMETER_VALUE,
    yellowCards = EXTENDED_PARAMETER_VALUE,
    yellowRedCards = EXTENDED_PARAMETER_VALUE,
    redCards = EXTENDED_PARAMETER_VALUE,
    goalsConceded = EXTENDED_PARAMETER_VALUE,
    goalsScored = EXTENDED_PARAMETER_VALUE,
    goalsByHead = EXTENDED_PARAMETER_VALUE,
    goalsByFoot = EXTENDED_PARAMETER_VALUE,
  } = statistics;

  return {
    [Enums.Details.Statistics.StatisticsType.PerGame]: [
      [
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.GamesPlayed,
          value: gamesPlayed,
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.GoalsScored,
          value: parseFloat(goalsScored.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.GoalsConceded,
          value: parseFloat(goalsConceded.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.GoalsByHead,
          value: parseFloat(goalsByHead.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.FootGoals,
          value: parseFloat(goalsByFoot.average.toFixed(2)),
        },
      ],
      [
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.TotalShots,
          value: parseFloat(shotsTotal.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.ShotsOnGoal,
          value: parseFloat(shotsOnTarget.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.ShotsOffGoal,
          value: parseFloat(shotsOffTarget.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.ShotsOnPost,
          value: parseFloat(shotsOnPost.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.ShootingEfficiency,
          value: parseFloat(shootingEfficiency.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.Possession,
          value: parseFloat(averageBallPossession.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.CornerKicks,
          value: parseFloat(cornerKicks.average.toFixed(2)),
        },
      ],
      [
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.FreeKicks,
          value: parseFloat(freeKicks.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.Offsides,
          value: parseFloat(offsides.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.YellowCards,
          value: parseFloat(yellowCards.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.YellowRedCards,
          value: parseFloat(yellowRedCards.average.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.DirectRedCards,
          value: parseFloat(redCards.average.toFixed(2)),
        },
      ],
    ],
    [Enums.Details.Statistics.StatisticsType.WholeSeason]: [
      [
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.GamesPlayed,
          value: gamesPlayed,
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.GoalsScored,
          value: parseFloat(goalsScored.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.GoalsConceded,
          value: parseFloat(goalsConceded.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.GoalsByHead,
          value: parseFloat(goalsByHead.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.FootGoals,
          value: parseFloat(goalsByFoot.total.toFixed(2)),
        },
      ],
      [
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.TotalShots,
          value: parseFloat(shotsTotal.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.ShotsOnGoal,
          value: parseFloat(shotsOnTarget.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.ShotsOffGoal,
          value: parseFloat(shotsOffTarget.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.ShotsOnPost,
          value: parseFloat(shotsOnPost.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.ShootingEfficiency,
          value: parseFloat(shootingEfficiency.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.Possession,
          value: parseFloat(averageBallPossession.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.CornerKicks,
          value: parseFloat(cornerKicks.total.toFixed(2)),
        },
      ],
      [
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.FreeKicks,
          value: parseFloat(freeKicks.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.Offsides,
          value: parseFloat(offsides.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.YellowCards,
          value: parseFloat(yellowCards.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.YellowRedCards,
          value: parseFloat(yellowRedCards.total.toFixed(2)),
        },
        {
          key: Enums.Details.Statistics.StatisticsFieldKey.DirectRedCards,
          value: parseFloat(redCards.total.toFixed(2)),
        },
      ],
    ],
  };
};

export default mapTeamStatistics;
