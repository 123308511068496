import React, { useCallback, memo } from 'react';
import { useParams } from 'react-router-dom';

import {
  ColumnType,
  CustomFilterState,
  Filters,
  PresetType,
  TeamStatistics as TeamStatisticsType,
} from 'packages/season-team-statistics/types';
import { useNavigation } from 'packages/hooks';
import { MobilePage } from 'router/types';
import { MediaScreen, Team } from 'types';

import { GeneralFilter, MainScreen } from './components';
import { ExtendedFilter } from '../../components';
import styles from './styles.scss';

type Props = {
  filters: Filters;
  customFilters: CustomFilterState;
  teamStatistics: TeamStatisticsType[];
  isFiltersLoading: boolean;
  isInitialTeamsPageLoading: boolean;
  isNewTeamsPageLoading: boolean;
  isExtendedFilterOpen: boolean;
  limit: number;
  count: number;
  onCustomFilterUpdate: (updates: CustomFilterState) => void;
  onPaginationChange: ({ offset, limit }) => void;
  onOrderingChange: (columnType: ColumnType) => void;
  onPresetChange: (preset: PresetType) => void;
  onCustomizeButtonClick: () => void;
  loadFilters: () => void;
  onApply: () => void;
  onCancel: () => void;
};

const TeamStatistics = ({
  filters,
  customFilters,
  teamStatistics,
  isFiltersLoading,
  isInitialTeamsPageLoading,
  isNewTeamsPageLoading,
  limit,
  count,
  onCustomFilterUpdate,
  loadFilters,
  onApply,
  onCancel,
  isExtendedFilterOpen,
  onPaginationChange,
  onOrderingChange,
  onPresetChange,
  onCustomizeButtonClick,
}: Props) => {
  const { tournamentId = '', seasonId = '' } = useParams();
  const { appNavigate } = useNavigation(MediaScreen.Mobile);

  const handleTeamClick = useCallback(
    (team: Team) => {
      appNavigate(MobilePage.FootballTeamSummaryDescription, {
        teamId: team.id,
        uniqueTournamentId: tournamentId,
        seasonId,
      });
    },
    [tournamentId, seasonId],
  );

  return (
    <div className={styles.teamStatisticsContainer}>
      <ExtendedFilter
        GeneralFilterComponent={GeneralFilter}
        onCustomFilterUpdate={onCustomFilterUpdate}
        onCancel={onCancel}
        onApply={onApply}
        loadFilters={loadFilters}
        customFilters={customFilters}
        isLoading={isFiltersLoading}
        filters={filters}
        isOpen={isExtendedFilterOpen}
        className={styles.filter}
      />
      <div className={styles.tableContainer}>
        <MainScreen
          isInitialTeamsPageLoading={isInitialTeamsPageLoading}
          onTeamClick={handleTeamClick}
          onPaginationChange={onPaginationChange}
          onCustomizeButtonClick={onCustomizeButtonClick}
          onOrderingChange={onOrderingChange}
          onPresetChange={onPresetChange}
          teamStatistics={teamStatistics}
          isNewTeamsPageLoading={isNewTeamsPageLoading}
          currentPageSize={limit}
          amountOfTeams={count}
          activeStatsTab={customFilters.generalFilter.activeStatsTab}
          activePreset={customFilters.columnConfigurationFilter.activePreset}
          columns={customFilters.columnConfigurationFilter.columns}
          ordering={customFilters.columnConfigurationFilter.configurableOrdering}
          className={styles.mainScreenContainer}
        />
      </div>
    </div>
  );
};

export default memo(TeamStatistics);
