import { toCamelCase } from 'packages/utils/Object.utils';

import { Enums, Interfaces } from '../types';

const { PlayerRoles, MappedPlayerRole } = Enums.TransferHistory.TransferHistory;

const mapTransferHistory = (history: object[]): Interfaces.Teams.TransferHistory[] => {
  return history.map(mapHistoryObject);
};

const mapHistoryObject = (object: object): Interfaces.Teams.TransferHistory => {
  const { id, startDate, endDate, playerRole, team } = toCamelCase(object, true);

  return { id, startDate, endDate, playerRole: mapPlayerRole(playerRole), team: mapTeam(team) };
};

const mapTeam = (team: Record<string, any>) => {
  const { id, name, logo, suffix, isCountry } = team;

  return { id, name: suffix ? `${name} ${suffix}` : name, logo, isCountry };
};

const mapPlayerRole = (role: string): string => {
  return role === PlayerRoles.Player ? MappedPlayerRole.Contract : MappedPlayerRole.Loan;
};

export default mapTransferHistory;
