import i18n from 'i18n-smart';

import { TabMode } from 'packages/season-top-board/types';
import { TabView } from 'packages/shared/components/Tabs/types';
import { Column } from 'packages/shared/components/Table/types';

import { TopTable } from '../components';

interface TableSchema {
  getGoals: () => Column[];
  getAttack: () => Column[];
  getAggression: () => Column[];
  useFixedPreloader?: boolean;
}

const getInnerTabs = (schema: TableSchema): TabView[] => {
  const { getGoals, getAttack, getAggression, useFixedPreloader } = schema;

  return [
    {
      id: TabMode.Goals,
      name: i18n.value(`season.seasonTopBoard.tabs.mode.${TabMode.Goals}`),
      contentComponent: TopTable,
      contentComponentProps: {
        columns: getGoals(),
        useFixedPreloader,
      },
    },
    {
      id: TabMode.Attack,
      name: i18n.value(`season.seasonTopBoard.tabs.mode.${TabMode.Attack}`),
      contentComponent: TopTable,
      contentComponentProps: {
        columns: getAttack(),
        useFixedPreloader,
      },
    },
    {
      id: TabMode.Aggression,
      name: i18n.value(`season.seasonTopBoard.tabs.mode.${TabMode.Aggression}`),
      contentComponent: TopTable,
      contentComponentProps: {
        columns: getAggression(),
        useFixedPreloader,
      },
    },
  ];
};

export default getInnerTabs;
