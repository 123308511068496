import React, { memo } from 'react';
import isEmpty from 'is-empty-typed';

import EmptyCell from '../EmptyCell';
import { Age } from './components';

type Props = {
  dateOfBirth?: string;
};

const AgeCell = ({ dateOfBirth }: Props) => {
  return isEmpty(dateOfBirth) ? <EmptyCell /> : <Age dateOfBirth={dateOfBirth} />;
};

export default memo(AgeCell);
