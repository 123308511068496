import React from 'react';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import styles from './styles.scss';

type Props = {
  className?: string;
  label?: string;
};

const NoDataLabel = ({ className, label = i18n.value('common.emptyText') }: Props) => {
  return <div className={classnames(styles.noDataLabel, className)}>{label}</div>;
};

export default React.memo(NoDataLabel);
