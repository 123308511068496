import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { topTeamsSelectors, seasonTopBoardThunks } from 'packages/season-top-board';

const mapStateToProps = (state) => ({
  teams: topTeamsSelectors.getData(state),
  isTopTeamsLoading: topTeamsSelectors.getIsTopTeamsLoading(state),
});

const mapDispatchToProps = (dispatch) => ({
  seasonTopBoardActions: bindActionCreators(seasonTopBoardThunks, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps);
