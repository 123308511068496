import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { Data } from '../types';

import {
  loadStandingsStart,
  loadStandingsSuccess,
  loadStandingsFailure,
  cleanStandingsSuccess,
} from '../actions';

const defaultState = {
  stages: [],
  activeStageId: undefined,
};

const reducerMap: ReducerMap<Data, Data> = {
  [`${loadStandingsSuccess}`]: (state, { payload }) => {
    return payload;
  },
  [`${combineActions(loadStandingsStart, loadStandingsFailure, cleanStandingsSuccess)}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
