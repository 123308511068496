import { connect } from 'react-redux';

import { favoritesSelectors } from 'packages/favorites';
import { settingsSelectors } from 'packages/settings';
import { liveEventsSelectors } from 'packages/events';
import { eventDetailsSelectors } from 'packages/event-details';

const mapStateToProps = (state) => ({
  favoriteTournaments: favoritesSelectors.getFavoriteLeagues(state),
  isLoading: !liveEventsSelectors.getIsLiveEventsLoaded(state),
  activeSportId: settingsSelectors.getActiveSportId(state),
  activeEventId: eventDetailsSelectors.getActiveEventId(state),
  events: liveEventsSelectors.getLiveEventsList(state),
});

export default connect(mapStateToProps);
