import React, { cloneElement, memo, ReactElement, RefObject } from 'react';

import Backdrop from './views/Backdrop';

type Props = {
  popoverElement: JSX.Element;
  closePopover: Function;
  openPopover: () => void;
  generatePopoverComponent: Function;
  popoverRef: RefObject<HTMLElement> | null;
  isOpen: boolean;
  isModal?: boolean;
  useBackdrop?: boolean;
  children?: ReactElement;
  preserveOnTargetInteraction?: boolean;
};

const ClickablePopover = ({
  generatePopoverComponent,
  isModal = false,
  useBackdrop = false,
  openPopover,
  closePopover,
  popoverElement,
  popoverRef,
  isOpen,
  children,
  preserveOnTargetInteraction = false,
}: Props) => {
  const handleTriggerClick = (event: MouseEvent) => {
    event.stopPropagation();

    if (isOpen) {
      if (!preserveOnTargetInteraction) {
        closePopover();
      }
    } else {
      openPopover();
    }
  };

  return (
    <>
      {children && cloneElement(children, { onMouseUp: handleTriggerClick })}
      {generatePopoverComponent(
        <>
          {(isModal || useBackdrop) && <Backdrop targetRef={popoverRef} />}
          {popoverElement}
        </>,
      )}
    </>
  );
};

export default memo(ClickablePopover);
