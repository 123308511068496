import { InputValidationType } from '../../../types';
import { validateFloatRange, validateIntegerRange } from './validators';

const getValidatorByType = (type: InputValidationType) => {
  switch (type) {
    case InputValidationType.Integer:
      return validateIntegerRange;
    case InputValidationType.Float:
      return validateFloatRange;
    default:
      return (firstValue: string, secondValue: string) => [firstValue, secondValue];
  }
};

export default getValidatorByType;
