import { Event } from 'types';

import { EventSeries, Meta } from 'packages/event-details/types';

import { Data } from '../types';
import getPath from './getPath';

export const getData = (state): Data => {
  return getPath(state).data;
};

export const getEvent = (state): Event | undefined => {
  return getData(state)?.event;
};

export const getEventSeries = (state): EventSeries | undefined => {
  return getData(state)?.eventSeries;
};

export const getMeta = (state): Meta | undefined => {
  return getData(state).meta;
};
