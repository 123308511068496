import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { searchSelectors, searchThunks } from 'packages/search';

const mapStateToProps = (state) => {
  return {
    scrollTop: searchSelectors.getScrollTop(state),
    searchKey: searchSelectors.getSearchKey(state),
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    searchActions: bindActionCreators(searchThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
