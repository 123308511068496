import { finishedEventsActions } from '../../actions';
import { LiveEventsUpdateEventType } from '../../types/enums';
import { updateFinishedEvents } from './updateFinishedEvents';

export const subscribeForFinishedEventsUpdates = () => (dispatch) => {
  try {
    dispatch(finishedEventsActions.subscribeForFinishedEventsUpdatesStart());

    const handleUpdatedData = (event) => {
      const { detail } = event as CustomEvent;

      dispatch(updateFinishedEvents(detail));
    };

    document.addEventListener(LiveEventsUpdateEventType.Removed, handleUpdatedData);

    dispatch(finishedEventsActions.subscribeForFinishedEventsUpdatesSuccess());

    return () => {
      document.removeEventListener(LiveEventsUpdateEventType.Removed, handleUpdatedData);
    };
  } catch (error) {
    dispatch(finishedEventsActions.subscribeForFinishedEventsUpdatesFailure(error));
  }
};
