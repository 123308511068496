import React from 'react';
import { ComponentLike } from 'types';

import { NavigationTheme, ThemedNavigationMenuProps } from './types';
import { ButtonNavigationMenu, LinkNavigationMenu, DropdownNavigationMenu } from './views';

const WhiteLinkNavigationMenu = (props: ThemedNavigationMenuProps) => (
  <LinkNavigationMenu {...props} isWhite />
);

const StretchedLinkNavigationMenu = (props: ThemedNavigationMenuProps) => (
  <LinkNavigationMenu {...props} isStretched />
);

export default (theme: NavigationTheme): ComponentLike => {
  switch (theme) {
    case NavigationTheme.Button:
      return ButtonNavigationMenu;
    case NavigationTheme.Link:
      return LinkNavigationMenu;
    case NavigationTheme.LinkWhite:
      return WhiteLinkNavigationMenu;
    case NavigationTheme.LinkStretched:
      return StretchedLinkNavigationMenu;
    case NavigationTheme.Dropdown:
      return DropdownNavigationMenu;
    default:
      return LinkNavigationMenu;
  }
};
