export enum PopoverJustify {
  Left = 'left',
  Center = 'center',
  CenterStart = 'center-start',
  CenterEnd = 'center-end',
  Right = 'right',
}

export enum PopoverAlign {
  Top = 'top',
  Center = 'center',
  CenterStart = 'center-start',
  CenterEnd = 'center-end',
  Bottom = 'bottom',
}

export enum PopoverInvokeTrigger {
  Click = 'click',
  Hover = 'hover',
}

export enum PopoverState {
  Closed = 'closed',
  Opened = 'opened',
}
