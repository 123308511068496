import { DateTime } from 'luxon';

import { HALF_VISIBLE_RATIO } from '../const';

const drawCurrentDateButton = (
  scrollContainerRef,
  currentButtonRef,
  currentDateTime: DateTime,
  activeDateTime: DateTime,
) => {
  const currentButtonEl = currentButtonRef.current;
  const scrollContainerEl = scrollContainerRef.current;
  const currentDateMillis = currentDateTime.toMillis();
  const activeDateMillis = activeDateTime.toMillis();
  const currentDateItem = scrollContainerEl.querySelector(
    `#${CSS.escape(String(currentDateMillis))}`,
  );

  const dateItemIntersectionObserver = getIntersectionObserver(
    currentButtonEl,
    scrollContainerEl,
    getObserverOptions(scrollContainerEl),
  );

  if (!currentDateItem) {
    showButton(currentButtonEl, currentDateMillis < activeDateMillis);
  } else {
    dateItemIntersectionObserver.observe(currentDateItem);
  }

  return dateItemIntersectionObserver;
};

const getIntersectionObserver = (currentDateButtonEl, scrollContainerEl, observerOptions) => {
  return new IntersectionObserver(([entry]) => {
    const { intersectionRatio, boundingClientRect } = entry;

    const isCurrentDateItemOutOfScreen = intersectionRatio <= HALF_VISIBLE_RATIO;
    const isCurrentDateItemOnTheScreen = intersectionRatio > HALF_VISIBLE_RATIO;
    const currentDateItemLeft = boundingClientRect.left;
    const scrollContainerLeft = scrollContainerEl.getBoundingClientRect().left;

    const isCurrentDateItemHiddenOnTheLeft = currentDateItemLeft < scrollContainerLeft;

    if (isCurrentDateItemOutOfScreen) {
      showButton(currentDateButtonEl, isCurrentDateItemHiddenOnTheLeft);
    } else if (isCurrentDateItemOnTheScreen) {
      hideButton(currentDateButtonEl);
    }
  }, observerOptions);
};

const getObserverOptions = (scrollContainerEl: HTMLElement) => ({
  root: scrollContainerEl,
  rootMargin: '0px -60px 0px 0px',
  threshold: [0.5, 0.6],
});

const showButton = (buttonEl, showOnTheLeft: boolean) => {
  buttonEl.style.visibility = 'visible';

  if (showOnTheLeft) {
    buttonEl.style.left = '12px';
    buttonEl.style.right = 'unset';
  } else {
    buttonEl.style.left = 'unset';
    buttonEl.style.right = '12px';
  }
};

const hideButton = (buttonEl) => {
  buttonEl.style.visibility = 'hidden';
};

export default drawCurrentDateButton;
