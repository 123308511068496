import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { categoryModalThunks, categoryModalSelectors } from 'packages/categories';

const mapStateToProps = (state) => {
  return {
    category: categoryModalSelectors.getCategory(state),
    tournaments: categoryModalSelectors.getTournaments(state),
    isLoading: categoryModalSelectors.getIsLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    categoryActions: bindActionCreators(categoryModalThunks, dispatch),
  };
};

export default connect(mapStateToProps, mapDispatchToProps);
