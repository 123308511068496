import React, { memo } from 'react';

import {
  ColumnType,
  CustomFilterState,
  Filters,
  Player,
  PlayerStatistics as PlayersStatisticsType,
  PresetType,
} from 'packages/season-player-statistics/types';
import { Team } from 'types';
import { PaginationParams } from 'packages/shared/components/Pagination/types';

import styles from './styles.scss';
import { MainScreen, GeneralFilter } from './components';
import { ExtendedFilter } from '../../components';

type Props = {
  filters: Filters;
  customFilters: CustomFilterState;
  playerStatistics: PlayersStatisticsType[];
  isFiltersLoading: boolean;
  isInitialPlayersPageLoading: boolean;
  isNewPlayersPageLoading: boolean;
  isExtendedFilterOpen: boolean;
  limit: number;
  count: number;
  onCustomFilterUpdate: (updates: CustomFilterState) => void;
  onCancelClick: () => void;
  onApplyClick: () => void;
  loadFilters: () => void;
  onTeamClick: (team: Team) => void;
  onPlayerClick: (player: Player) => void;
  onCustomizeButtonClick: () => void;
  onOrderingChange: (type: ColumnType) => void;
  onPresetChange: (presetType: PresetType) => void;
  onPaginationChange: (params: PaginationParams) => void;
};

const PlayerStatistics = ({
  filters,
  customFilters,
  playerStatistics,
  isFiltersLoading,
  isInitialPlayersPageLoading,
  isNewPlayersPageLoading,
  limit,
  count,
  isExtendedFilterOpen,
  onCustomFilterUpdate,
  onCancelClick,
  onApplyClick,
  loadFilters,
  onTeamClick,
  onPlayerClick,
  onCustomizeButtonClick,
  onOrderingChange,
  onPresetChange,
  onPaginationChange,
}: Props) => {
  return (
    <div className={styles.playerStatisticsContainer}>
      <ExtendedFilter
        GeneralFilterComponent={GeneralFilter}
        onCustomFilterUpdate={onCustomFilterUpdate}
        onCancel={onCancelClick}
        onApply={onApplyClick}
        loadFilters={loadFilters}
        customFilters={customFilters}
        isLoading={isFiltersLoading}
        filters={filters}
        isOpen={isExtendedFilterOpen}
        className={styles.filter}
      />

      <div className={styles.tableContainer}>
        <MainScreen
          onTeamClick={onTeamClick}
          onPlayerClick={onPlayerClick}
          onCustomizeButtonClick={onCustomizeButtonClick}
          onOrderingChange={onOrderingChange}
          onPresetChange={onPresetChange}
          onPaginationChange={onPaginationChange}
          amountOfPlayers={count}
          currentPageSize={limit}
          playersStatistics={playerStatistics}
          isInitialPlayersPageLoading={isInitialPlayersPageLoading}
          isNewPlayersPageLoading={isNewPlayersPageLoading}
          activeStatsTab={customFilters.generalFilter.activeStatsTab}
          activePreset={customFilters.columnConfigurationFilter.activePreset}
          columns={customFilters.columnConfigurationFilter.columns}
          ordering={customFilters.columnConfigurationFilter.configurableOrdering}
        />
      </div>
    </div>
  );
};

export default memo(PlayerStatistics);
