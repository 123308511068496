import React, { memo, RefObject, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { DesktopPage } from 'router/types';
import { useNavigation } from 'packages/hooks';
import { Event } from 'packages/events-ui';
import { Interfaces as PlayerSummaryInterfaces, Interfaces } from 'packages/player-summary/types';
import { Team } from 'types';

import { ActualGames, TransferHistory, Statistics } from '../../components';
import { getTransferColumnSchema, getTransferColumnSkeletonSchema } from '../../services';
import styles from './styles.scss';

type Props = {
  tournaments: Interfaces.ActualGames.Data;
  history: PlayerSummaryInterfaces.Teams.TransferHistory[];
  playerSummaryStatistics: PlayerSummaryInterfaces.Statistics.Statistics.Data;
  teams: Team[];
  parentContainerRef?: RefObject<HTMLDivElement>;
  isWholeStatisticsLoading: boolean;
  isActualGamesLoading: boolean;
  isTeamsLoading: boolean;
};

const Summary = ({
  teams,
  tournaments,
  history,
  playerSummaryStatistics,
  isWholeStatisticsLoading,
  isActualGamesLoading,
  parentContainerRef,
  isTeamsLoading,
}: Props) => {
  const { appNavigate } = useNavigation();
  const { playerId } = useParams();
  const isAllDataLoaded = !isWholeStatisticsLoading && !isActualGamesLoading && !isTeamsLoading;

  const handleLinkClick = useCallback(() => {
    if (playerId) {
      appNavigate(DesktopPage.FootballPlayerGames, { playerId });
    }
  }, [playerId]);

  const handleTeamClick = useCallback((team: Team) => {
    appNavigate(DesktopPage.FootballTeam, { teamId: team.id });
  }, []);

  return (
    <div className={styles.playerSummary}>
      <ActualGames
        onLinkClick={handleLinkClick}
        tournaments={tournaments}
        eventComponent={Event.Desktop}
        isLoading={isActualGamesLoading}
      />

      <Statistics
        teams={teams}
        isTeamsLoading={isTeamsLoading}
        parentContainerRef={parentContainerRef}
        playerSummaryStatistics={playerSummaryStatistics}
        isWholeStatisticsLoading={isWholeStatisticsLoading}
        className={styles.statisticsContainer}
        isPopoverWidthFixed
      />

      <TransferHistory
        isAllDataLoaded={isAllDataLoaded}
        getSkeletonColumnSchema={getTransferColumnSkeletonSchema}
        isLoading={isTeamsLoading}
        handleTeamClick={handleTeamClick}
        history={history}
        getColumnSchema={getTransferColumnSchema}
        className={styles.transferHistoryContainer}
      />
    </div>
  );
};

export default memo(Summary);
