export enum StatisticsParameterKey {
  MatchesWon = 'MatchesWon',
  MatchesLost = 'MatchesLost',
  MatchesDrawn = 'MatchesDrawn',
  FirstGoals = 'FirstGoals',
  LastGoals = 'LastGoals',
  NumberOfCards1stHalf = 'NumberOfCards1stHalf',
  NumberOfCards2ndHalf = 'NumberOfCards2ndHalf',
  TeamMatches = 'TeamMatches',
  Penalties = 'Penalties',
  PenaltyGoals = 'PenaltyGoals',
  GamesPlayed = 'GamesPlayed',
  Goals = 'Goals',
  GoalsScored = 'GoalsScored',
  GoalPoints = 'GoalPoints',
  GoalsByHead = 'GoalsByHead',
  Assists = 'Assists',
  TotalShots = 'TotalShots',
  Offside = 'Offside',
  YellowCards = 'YellowCards',
  YellowRedCards = 'YellowRedCards',
  DirectRedCards = 'DirectRedCards',
  Started = 'Started',
  Corners = 'Corners',
  ShotsOnGoal = 'ShotsOnGoal',
  ShotsOffGoal = 'ShotsOffGoal',
  ShotsBlocked = 'ShotsBlocked',
  MinutesPlayed = 'MinutesPlayed',
  SubstitutedIn = 'SubstitutedIn',
  SubstitutedOut = 'SubstitutedOut',
  GoalsConversion = 'GoalsConversion',
  CleanSheets = 'CleanSheets',
}
