export enum PromotionGroups {
  FIRST_GROUP = '-first-group',
  SECOND_GROUP = '-second-group',
  THIRD_GROUP = '-third-group',
  FOURTH_GROUP = '-fourth-group',
  FIFTH_GROUP = '-fifth-group',
  SIXTH_GROUP = '-sixth-group',
  SEVENTH_GROUP = '-seventh-group',
  EIGHTH_GROUP = '-eighth-group',
  NINTH_GROUP = '-ninth-group',
}

export enum PromotionColorTypes {
  PROMOTIONCOLOR_UEFA = 'promotioncolor_uefa',
  PROMOTIONCOLOR_UEFAQ = 'promotioncolor_uefaq',
  PROMOTIONCOLOR_CL = 'promotioncolor_cl',
  PROMOTIONCOLOR_CLQ = 'promotioncolor_clq',
  PROMOTIONCOLOR_PROMOTIONPLAYOFF = 'promotioncolor_promotionplayoff',
  PROMOTIONCOLOR_PLAYOFF = 'promotioncolor_playoff',
  PROMOTIONCOLOR_RELEGATIONPLAYOFF = 'promotioncolor_relegationplayoff',
  PROMOTIONCOLOR_RELEGATION = 'promotioncolor_relegation',
  PROMOTIONCOLOR_PROMOTION = 'promotioncolor_promotion',
  PROMOTIONCOLOR_FINALFOUR = 'promotioncolor_finalfour',
}
