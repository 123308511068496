import { Stadiums } from 'packages/rest';

import {
  loadStadiumDetailsStart,
  loadStadiumDetailsSuccess,
  loadStadiumDetailsFailure,
} from '../actions';
import { mapDetails } from '../services';

interface Params {
  stadiumId: number;
}

const loadDetails = ({ stadiumId }: Params) => async (dispatch) => {
  try {
    dispatch(loadStadiumDetailsStart());

    const { data } = await Stadiums.loadDescription({ stadiumId });
    const description = mapDetails(data.results);

    dispatch(loadStadiumDetailsSuccess(description));
  } catch (e) {
    dispatch(loadStadiumDetailsFailure());
  }
};

export default loadDetails;
