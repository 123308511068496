import React from 'react';

import styles from './styles.scss';

type Props = {
  title: string;
};

const DispellingTitle = ({ title }: Props) => {
  return (
    <div className={styles.dispellingTitle}>
      <span className={styles.title}>{title}</span>
      <div className={styles.dispellingLine} />
    </div>
  );
};

export default React.memo(DispellingTitle);
