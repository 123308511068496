import { Language, LocaleCode } from 'types';

export const defaultLocaleCode = LocaleCode.En;

export const defaultLanguages: Language[] = [
  {
    id: 640,
    localeCode: LocaleCode.En,
    name: 'English',
    isRtl: false,
    pluralForms: ['one', 'other'],
  },
  {
    id: 597,
    localeCode: LocaleCode.Ru,
    name: 'Русский',
    isRtl: false,
    pluralForms: ['one', 'few', 'many', 'other'],
  },
];
