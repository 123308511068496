import React, { memo } from 'react';
import classnames from 'classnames';

import { Chip, Icon } from 'packages/shared/components';
import { IconType } from 'packages/shared/components/Icon/types';
import { BaseHappeningAdditionalInfo } from 'packages/event-stats/types';

import { HappeningMethod } from 'packages/event-time-line/types';
import styles from '../styles.scss';
import localStyles from './styles.scss';
import EventTime from '../EventTime';

export type Props = {
  additionalInfo?: BaseHappeningAdditionalInfo;
  minute: string;
  injuryTime?: string;
};

const Goal = ({ additionalInfo, minute, injuryTime }: Props) => {
  const goalImageType =
    additionalInfo?.method === HappeningMethod.Penalty ? IconType.PenaltyGoal : IconType.Goal;

  return (
    <Chip>
      <div
        className={classnames(styles.chip, styles.extended, localStyles.goalContainer, {
          [localStyles.ownGoal]: additionalInfo?.method === HappeningMethod.OwnGoal,
        })}
      >
        <EventTime minute={minute} injuryTime={injuryTime} />
        <Icon className={localStyles.goalImage} id={goalImageType} />
      </div>
    </Chip>
  );
};

export default memo(Goal);
