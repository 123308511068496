import { TabletPage } from '../enums';

export interface TabletPathParams {
  [TabletPage.Football]: {};

  [TabletPage.FootballSeason]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [TabletPage.FootballSeasonPlayerStatistics]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [TabletPage.FootballSeasonTeamStatistics]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [TabletPage.FootballSeasonStadiums]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [TabletPage.FootballSeasonGames]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [TabletPage.FootballTeam]: {
    teamId: string | number;
  };
  [TabletPage.FootballTeamGames]: {
    teamId: string | number;
  };
  [TabletPage.FootballTeamSquad]: {
    teamId: string | number;
  };
  [TabletPage.FootballTeamSummaryDescription]: {
    seasonId: string | number;
    uniqueTournamentId: string | number;
    teamId: string | number;
  };
  [TabletPage.FootballTeamStandings]: {
    teamId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
  [TabletPage.FootballTeamPlayerStatistics]: {
    teamId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };

  [TabletPage.FootballCategory]: {
    categoryId: string | number;
  };

  [TabletPage.FootballPlayer]: {
    playerId: string | number;
  };
  [TabletPage.FootballPlayerGames]: {
    playerId: string | number;
  };

  [TabletPage.FootballStadium]: {
    stadiumId: string | number;
  };
  [TabletPage.FootballStadiumSummary]: {
    stadiumId: string | number;
    seasonId: string | number;
    uniqueTournamentId: string | number;
  };
}
