import { commonSelectors } from '../../selectors';
import { EventsStatus, StorageEventGroup } from '../../types';
import { EVENT_STORAGE_GROUP_KEYS } from '../../constants';

export const getScheduledEventsStatus = (state): EventsStatus => {
  return commonSelectors.getEventsStatusByGroup(
    state,
    EVENT_STORAGE_GROUP_KEYS[StorageEventGroup.Scheduled],
  );
};

export const getIsScheduledEventsLoading = (state): boolean => {
  return getScheduledEventsStatus(state).isLoading;
};

export const getHasNextScheduledEvents = (state): boolean => {
  return getScheduledEventsStatus(state).hasNext;
};

export const getScheduledEventsOffset = (state): number => {
  return getScheduledEventsStatus(state).offset;
};
