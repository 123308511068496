import i18n from 'i18n-smart';

import { TabView } from 'packages/shared/components/Tabs/types';
import { Interfaces } from 'packages/team-summary/types';

const mapTeamStatisticsIntoTabView = (
  teamStatistics: Interfaces.Details.Statistics.Data,
  single = false,
): TabView[] => {
  return [
    {
      id: 'team.teamSummary.statistics.tab.wholeSeason',
      name: i18n.value('team.teamSummary.statistics.tab.wholeSeason'),
      isDisabled: !teamStatistics?.wholeSeason,
      contentComponentProps: {
        statistics: teamStatistics?.wholeSeason,
        single,
      },
    },
    {
      id: 'team.teamSummary.statistics.tab.perGame',
      name: i18n.value('team.teamSummary.statistics.tab.perGame'),
      isDisabled: !teamStatistics?.perGame,
      contentComponentProps: {
        statistics: teamStatistics?.perGame,
        single,
      },
    },
  ];
};

export default mapTeamStatisticsIntoTabView;
