import { useEffect } from 'react';

const disableTouchMove = (e: TouchEvent) => {
  e.preventDefault();
  e.stopPropagation();
};

const useTouchMoveDisabling = () => {
  useEffect(() => {
    window.addEventListener('touchmove', disableTouchMove, { passive: false });

    return () => {
      window.removeEventListener('touchmove', disableTouchMove);
    };
  }, []);
};

export default useTouchMoveDisabling;
