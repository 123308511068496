import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import { SeasonFilterStatus } from '../../types';
import * as actions from '../../actions';

const defaultState: SeasonFilterStatus = {
  isLoading: true,
};

const reducerMap: ReducerMap<SeasonFilterStatus, SeasonFilterStatus> = {
  [`${actions.loadFiltersStart}`]: () => ({
    isLoading: true,
  }),
  [`${combineActions(actions.loadFiltersSuccess, actions.updateActiveFilter)}`]: () => ({
    isLoading: false,
  }),
  [`${actions.clearSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
