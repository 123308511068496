import React, { memo, useMemo } from 'react';
import isEmpty from 'is-empty-typed';
import classnames from 'classnames';
import i18n from 'i18n-smart';

import { Data } from 'packages/player-details/types';
import { Figure } from 'packages/shared/components';

import { getBirthdayViews } from './services';
import styles from './styles.scss';

type Props = {
  playerDetails: NonNullable<Data>;
  className?: string;
};

const Passport = ({ playerDetails, className }: Props) => {
  const { dateOfBirth, country, secondCountry, height, weight, preferredFoot } = playerDetails;

  const parsedDateOfBirth = useMemo(() => getBirthdayViews(dateOfBirth), [dateOfBirth]);

  return (
    <div className={classnames(styles.stadiumsContainer, className)}>
      <div className={styles.header}>
        {i18n.value('player.playerSummary.description.passport.title')}
      </div>

      <div className={styles.descriptionContainer}>
        {!isEmpty(parsedDateOfBirth) && (
          <div className={styles.descriptionLine}>
            <div className={styles.property}>
              {i18n.value('player.playerSummary.description.passport.birthdayPropName')}
            </div>
            <div className={styles.description}>{parsedDateOfBirth.birthdayView}</div>
            <div className={styles.additionalInfo}>
              ({i18n.pluralize('common.yearLabel', { value: parsedDateOfBirth.yearsOldView })})
            </div>
          </div>
        )}

        {!isEmpty(country) && (
          <div className={styles.descriptionLine}>
            <div className={styles.property}>
              {i18n.value('player.playerSummary.description.passport.nationalityPropName')}
            </div>
            {!isEmpty(country.logo) && <Figure src={country.logo} className={styles.countryLogo} />}
            <div className={styles.description}>{country.name}</div>
          </div>
        )}
        {!isEmpty(secondCountry) && (
          <div className={styles.descriptionLine}>
            <div className={styles.property}>
              {i18n.value('player.playerSummary.description.passport.secondNationalityPropName')}
            </div>
            {!isEmpty(secondCountry.logo) && (
              <Figure src={secondCountry.logo} className={styles.countryLogo} />
            )}
            <div className={styles.description}>{secondCountry.name}</div>
          </div>
        )}
        {!isEmpty(height) && (
          <div className={styles.descriptionLine}>
            <div className={styles.property}>
              {i18n.value('player.playerSummary.description.passport.heightPropName')}
            </div>
            <div className={styles.description}>
              {height} {i18n.value('common.unit.centimeters.short')}
            </div>
          </div>
        )}
        {!isEmpty(weight) && (
          <div className={styles.descriptionLine}>
            <div className={styles.property}>
              {i18n.value('player.playerSummary.description.passport.weightPropName')}
            </div>
            <div className={styles.description}>
              {weight} {i18n.value('common.unit.kilograms.short')}
            </div>
          </div>
        )}
        {!isEmpty(preferredFoot) && (
          <div className={styles.descriptionLine}>
            <div className={styles.property}>
              {i18n.value('player.playerSummary.description.passport.legPropName')}
            </div>
            <div className={styles.description}>{preferredFoot}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(Passport);
