import React, { createContext, memo, useCallback, useEffect, useMemo, useState } from 'react';
import isEmpty from 'is-empty-typed/lib';
import i18n from 'i18n-smart';

import { PlayersParameter, TopPlayer } from 'packages/season-top-board/types';
import { PLAYER_DEFAULT_ORDERING } from 'packages/season-top-board/constants';
import { Tabs } from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';
import { getInnerTabs } from 'packages/season-top-board-ui/services';

import { LoadingSkeleton } from './components';
import { columnSchema, getPlayerRowProps } from './services';

export const SortingValueStateContext = createContext<any[]>([]);

interface Props {
  onRowClick: (playerId: number) => void;
  onNavigationLinkClick: () => void;
  onUpdate: (sortingValue: PlayersParameter) => void;
  isLoading: boolean;
  dropSortingValueOn: any;
  isCompact?: boolean;
  isInitialLoading?: boolean;
  players?: TopPlayer[];
  useFixedPreloader?: boolean;
  className?: string;
}

const TopPlayers = ({
  onRowClick,
  onNavigationLinkClick,
  onUpdate,
  isLoading,
  isCompact,
  isInitialLoading,
  dropSortingValueOn,
  players,
  useFixedPreloader,
  className,
}: Props) => {
  const sortingValueState = useState<PlayersParameter>(PlayersParameter.Goals);
  const tabs = useMemo(() => getInnerTabs({ ...columnSchema, useFixedPreloader }), [
    dropSortingValueOn,
  ]);
  const [sortingValue, setSortingValue] = sortingValueState;

  useEffect(() => {
    return () => {
      setSortingValue(PlayersParameter.Goals);
    };
  }, [dropSortingValueOn]);

  useEffect(() => {
    onUpdate(sortingValue);
  }, [sortingValue]);

  const handleTabChange = useCallback((tabId: string | number) => {
    setSortingValue(PLAYER_DEFAULT_ORDERING[tabId]);
  }, []);

  const contentComponentProps = useMemo(
    () => ({
      isLoading,
      rows: players,
      setRowProps: getPlayerRowProps({ onClick: onRowClick }),
    }),
    [players, onRowClick, isLoading],
  );

  return (
    <SortingValueStateContext.Provider value={sortingValueState}>
      {isInitialLoading ? (
        <LoadingSkeleton elementsCount={isCompact ? 3 : undefined} />
      ) : (
        <Tabs
          tabs={tabs}
          theme={NavigationTheme.Dropdown}
          className={className}
          contentComponentProps={contentComponentProps}
          onTabChange={handleTabChange}
          isNavigationMenuDisabled={isEmpty(players)}
          onNavigationLinkClick={onNavigationLinkClick}
          navigationLabel={i18n.value('team.teamSummary.topPlayers.title')}
          navigationLinkLabel={i18n.value('team.teamSummary.topPlayers.showAllPlayersLink')}
        />
      )}
    </SortingValueStateContext.Provider>
  );
};

export default memo(TopPlayers);
