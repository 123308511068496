import { toCamelCase } from 'packages/utils/Object.utils';

import { mapSeasonTopVenues, mapTopOfTheSeasonTeams } from '.';
import { TopOfTheSeason } from '../types';

export const mapTopOfTheSeason = (topOfTheSeason: any): TopOfTheSeason => {
  const { teamSeasonStatistics, venueSeasonStatistics } = toCamelCase(topOfTheSeason);

  return {
    teams: mapTopOfTheSeasonTeams(teamSeasonStatistics),
    venues: mapSeasonTopVenues(venueSeasonStatistics),
  };
};
