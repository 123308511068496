import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18n-smart';
import classnames from 'classnames';

import PercentageBar from '../PercentageBar';

import styles from './styles.scss';

const StatsCompareRow = ({ parameter, className }) => {
  const { key, home, away, marker } = parameter;

  return (
    <div className={classnames(styles.statsCompareRow, className)}>
      <div className={styles.description}>
        <div>{home.formattedValue}</div>
        <div className={styles.parameter}>
          {marker ? (
            <>
              {marker} <span className={styles.name}>{i18n.value(key)}</span> {marker}
            </>
          ) : (
            <span className={styles.name}>{i18n.value(key)}</span>
          )}
        </div>
        <div>{away.formattedValue}</div>
      </div>
      <div className={styles.bars}>
        <PercentageBar percentages={home.percentages} isHighlighted={home.isLeader} isReversed />
        <PercentageBar percentages={away.percentages} isHighlighted={away.isLeader} />
      </div>
    </div>
  );
};

const parameterDetailsType = PropTypes.shape({
  percentages: PropTypes.number,
  formattedValue: PropTypes.oneOfType(PropTypes.string, PropTypes.number),
  isLeader: PropTypes.bool,
}).isRequired;

StatsCompareRow.propTypes = {
  className: PropTypes.string,
  parameter: PropTypes.shape({
    key: PropTypes.string.isRequired,
    away: parameterDetailsType,
    home: parameterDetailsType,
  }).isRequired,
};

export default React.memo(StatsCompareRow);
