import { Row } from 'react-table';

import { SeasonTableRow, SeasonTableStatistics } from 'packages/event-standings/types';
import { Team } from 'types';

import styles from '../styles.scss';

type Params = {
  activeTeamIds: number[];
  onClick: (teamId: Team) => void;
};

const getTeamRowProps = ({ activeTeamIds, onClick }: Params) => ({
  original,
}: Row<SeasonTableRow<SeasonTableStatistics>>) => {
  const { team } = original;
  const { id } = team;
  const isTeamActive = activeTeamIds.includes(id);

  return {
    onClick: () => onClick(team),
    className: isTeamActive ? styles.activeTeam : undefined,
  };
};

export default getTeamRowProps;
