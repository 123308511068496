import { handleActions, ReducerMap } from 'redux-actions';

import DateService from 'packages/date-az';

import * as actions from '../../actions';
import { Filters, SeasonEventsFilter } from '../../types';

const now = DateService.getDate().toISODate();

const defaultState: SeasonEventsFilter = {
  filters: {
    rounds: [],
    teams: [],
  },
  activeFilters: { dates: [now, now], teams: [], rounds: [] },
};

const reducerMap: ReducerMap<SeasonEventsFilter, Filters> = {
  [`${actions.loadFiltersSuccess}`]: (state, { payload }) => {
    return { ...state, filters: payload };
  },
  [`${actions.updateActiveFilter}`]: (state, { payload }) => {
    return { ...state, activeFilters: payload };
  },
  [`${actions.clearSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
