import React, { memo } from 'react';

import { Skeleton } from 'packages/shared/components';

import styles from './styles.scss';

const FormCell = () => {
  return (
    <div className={styles.container}>
      <Skeleton width={22} height={22} marginRight={4} variant="circular" />
      <Skeleton width={22} height={22} marginRight={4} variant="circular" />
      <Skeleton width={22} height={22} marginRight={4} variant="circular" />
      <Skeleton width={22} height={22} marginRight={4} variant="circular" />
      <Skeleton width={22} height={22} variant="circular" />
    </div>
  );
};

export default memo(FormCell);
