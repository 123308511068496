import { handleActions, ReducerMap } from 'redux-actions';
import DateService from 'packages/date-az';

import { filterActions, commonActions } from '../../actions';
import { EventsFilters } from '../../types';
import { findEventsPeriod } from '../../services';

const now = DateService.getDate().toISODate();

const defaultState: EventsFilters = {
  defaultPeriod: findEventsPeriod([]),
  tournaments: [],
  activeFilters: {
    period: [now, now],
    tournament: undefined,
    selectedSeasons: [],
    tournamentSeasons: [],
  },
};

const reducerMap: ReducerMap<EventsFilters, Partial<EventsFilters>> = {
  [`${filterActions.loadFiltersSuccess}`]: (state, { payload }) => {
    const { tournaments = [] } = payload;

    return { ...state, tournaments };
  },
  [`${filterActions.updateActiveFilterSuccess}`]: (state, { payload }) => {
    const { activeFilters = defaultState.activeFilters } = payload;

    return { ...state, activeFilters: { ...activeFilters } };
  },
  [`${filterActions.initActiveFilterSuccess}`]: (state, { payload }) => {
    return { ...state, ...payload };
  },
  [`${commonActions.clearSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions(reducerMap, defaultState);
