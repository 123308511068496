import { SeasonTopBoard } from 'packages/rest';
import { PlayersParameter } from 'packages/season-top-board/types';

import { loadTopPlayersStart, loadTopPlayersSuccess, loadTopPlayersFailure } from '../actions';
import { mapSeasonTopPlayers, parseOrderingParams } from '../services';
import { PLAYER_API_ORDERING_BY_PARAMETERS } from '../constants';

interface Params {
  teamIds: number[];
  seasonId: number;
  sortingParameter?: PlayersParameter;
  limit?: number;
}

const loadTopPlayers = ({
  teamIds,
  seasonId,
  sortingParameter = PlayersParameter.Goals,
  limit,
}: Params) => async (dispatch) => {
  try {
    dispatch(loadTopPlayersStart());

    const { data } = await SeasonTopBoard.loadSeasonTopPlayers({
      teamIds,
      seasonId,
      ordering: parseOrderingParams(PLAYER_API_ORDERING_BY_PARAMETERS[sortingParameter]),
      limit,
    });
    const topPlayers = mapSeasonTopPlayers(data.results);

    dispatch(loadTopPlayersSuccess(topPlayers));
  } catch (e) {
    dispatch(loadTopPlayersFailure(e));
  }
};

export default loadTopPlayers;
