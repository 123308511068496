import { SummaryHappeningType } from 'packages/events/types';

import { BaseHappening } from '../../types';
import { MATCH_SECOND_HALF_START_TIME } from '../../constants';

const sortTimeLineHappenings = (timeLineHappenings: BaseHappening[]): BaseHappening[] => {
  return [...timeLineHappenings].sort((a, b) => {
    if (
      b.minute === MATCH_SECOND_HALF_START_TIME &&
      a.eventType === SummaryHappeningType.PERIOD_START
    ) {
      return -1;
    }

    return 0;
  });
};
export default sortTimeLineHappenings;
