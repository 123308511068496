import { createAction } from 'redux-actions';

import { LOCAL_NAMESPACE } from '../constants';

export const getNamespacedActionCreators = (namespace) => ({
  update: createAction(`${namespace}${LOCAL_NAMESPACE}UPDATE`),
  open: createAction(`${namespace}${LOCAL_NAMESPACE}OPEN`),
  close: createAction(`${namespace}${LOCAL_NAMESPACE}CLOSE`),
  failure: createAction(`${namespace}${LOCAL_NAMESPACE}FAILURE`),
});
