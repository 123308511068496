import React, { memo, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { ActionCreatorsMapObject } from 'redux';

import { Tabs } from 'packages/shared/components';
import { NavigationTheme } from 'packages/shared/components/NavigationMenu/types';

import { LoadingSkeleton } from '../../../../components';
import { getMainTabs } from './services';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  isInitialTopPlayersLoading: boolean;
  seasonTopBoardActions: ActionCreatorsMapObject;
};

const TopBoard = ({ isInitialTopPlayersLoading, seasonTopBoardActions }: Props) => {
  const { seasonId, tournamentId } = useParams();

  useEffect(() => {
    seasonTopBoardActions.loadInitialSeasonTopPlayers(seasonId);

    return () => {
      seasonTopBoardActions.cleanTopBoard();
    };
  }, [seasonId]);

  const tabs = useMemo(getMainTabs, []);
  const contentComponentProps = useMemo(
    () => ({
      seasonId,
      uniqueTournamentId: tournamentId,
    }),
    [seasonId, tournamentId],
  );

  if (isInitialTopPlayersLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <Tabs
      contentComponentProps={contentComponentProps}
      className={styles.topBoard}
      tabs={tabs}
      theme={NavigationTheme.LinkStretched}
    />
  );
};

export default connect(memo(TopBoard));
