import { Row } from 'react-table';

import { CommonTableStatistics, SeasonTableRow } from 'packages/event-standings/types';
import { Team } from 'types';

type Params = {
  onRowMouseUp: (team: Team) => void;
  activeRowClassName?: string;
  activeTeamIds: number[];
};

const getRowProps = ({ onRowMouseUp, activeRowClassName, activeTeamIds }: Params) => ({
  original,
}: Row<SeasonTableRow<CommonTableStatistics>>) => {
  const { team } = original;
  const { id: currentTeamId } = team;

  return {
    onMouseUp: () => {
      onRowMouseUp(team);
    },
    className: activeTeamIds.includes(currentTeamId) ? activeRowClassName : undefined,
  };
};

export default getRowProps;
