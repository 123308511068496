import React, { memo } from 'react';
import isEmpty from 'is-empty-typed/lib';

import EmptyCell from '../EmptyCell';
import styles from './styles.scss';

type Props = {
  occupancy?: number;
};

const OccupancyCell = ({ occupancy }: Props) => {
  return (
    <div className={styles.occupancyCell}>
      {isEmpty(occupancy) ? <EmptyCell /> : <span className={styles.percent}>{occupancy}%</span>}
    </div>
  );
};

export default memo(OccupancyCell);
