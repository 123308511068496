import React, { memo, useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { StandingsBlock } from 'packages/shared/modules';
import { Stage } from 'packages/event-standings/types';
import { useNavigation } from 'packages/hooks';
import { DesktopPage } from 'router/types';

import connect from './connect';

type Props = {
  stages: Stage[] | null;
  activeStageId?: number;
  activeTeamIds?: number[];
};

const Standings = ({ stages, activeStageId, activeTeamIds = [] }: Props) => {
  const { tournamentId = '', seasonId = '', teamId = '' } = useParams();
  const { appNavigate } = useNavigation();

  const handleNavigationLinkClick = useCallback(() => {
    appNavigate(DesktopPage.FootballTeamStandings, {
      uniqueTournamentId: tournamentId,
      seasonId,
      teamId,
    });
  }, [tournamentId, seasonId, teamId]);

  return (
    <StandingsBlock.Mobile
      activeTeamIds={activeTeamIds}
      activeUniqueTournamentId={tournamentId}
      activeSeasonId={seasonId}
      stages={stages}
      activeStageId={activeStageId}
      onLinkClick={handleNavigationLinkClick}
      useStagesControl
    />
  );
};

export default connect(memo(Standings));
