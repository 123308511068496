import React from 'react';
import classnames from 'classnames';

import { Jersey } from '../../../../types';
import { DEFAULT_JERSEY_COLOR } from './constants';
import styles from './styles.scss';

type Props = {
  jerseyColorScheme?: Jersey;
  playerNumber: number;
  className?: string;
};

const PlayerBadge = ({
  jerseyColorScheme = DEFAULT_JERSEY_COLOR,
  playerNumber,
  className,
}: Props) => {
  const { base, number, stripes } = jerseyColorScheme;
  const badgeStyle = { backgroundColor: base };
  const stripeStyle = { backgroundColor: stripes };
  const numberStyle = { color: number };
  const numberOutlineStyle = { color: base, WebkitStrokeColor: base };

  return (
    <div style={badgeStyle} className={classnames(styles.playerBadge, className)}>
      {!!stripes && (
        <>
          <div style={stripeStyle} className={classnames(styles.jerseyStripe, styles.first)} />
          <div style={stripeStyle} className={classnames(styles.jerseyStripe, styles.second)} />
        </>
      )}

      <div className={styles.playerNumberContainer}>
        <div style={numberOutlineStyle} className={classnames(styles.playerNumber, styles.outline)}>
          {playerNumber}
        </div>
        <div style={numberStyle} className={styles.playerNumber}>
          {playerNumber}
        </div>
      </div>
    </div>
  );
};

export default React.memo(PlayerBadge);
