import camelcase from 'camelcase';
import camelcaseKeys from 'camelcase-keys';
import snakeCase from 'lodash.snakecase';
import mapKeys from 'lodash.mapkeys';
import _omit from 'lodash.omit';
import _get from 'lodash.get';
import _IsNumber from 'lodash.isnumber';
import _IsNil from 'lodash.isnil';
import _IsEqual from 'lodash.isequal';
import isEmptyTyped from 'is-empty-typed';

export const isNumber = _IsNumber;
export const isNil = _IsNil;
export const get = _get;
export const omit = _omit;
export const isEqual = _IsEqual;
export const isEmpty = isEmptyTyped;
export const isArray = Array.isArray;

export const isString = (value: any): value is string => {
  return typeof value === 'string' || value instanceof String;
};

export const toSnakeCase = (value: object | string) => {
  return isString(value) ? snakeCase(value) : mapKeys(value, (v, k) => snakeCase(k));
};

export const toCamelCase = (value: object | string, deep: boolean = false): any => {
  return isString(value) ? camelcase(value as string) : camelcaseKeys(value as object, { deep });
};
