import React, { memo, useCallback } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import i18n from 'i18n-smart';

import { Data as ActiveTournamentSeasonData } from 'packages/team-active-tournament-season/types';
import { Tournament } from 'packages/events-ui/containers/EventsTabs/components';
import { DesktopPage, SearchQuery } from 'router/types';
import { TournamentView } from 'packages/events/types';
import { BlockLink } from 'packages/shared/components';
import { useNavigation } from 'packages/hooks';
import { Event } from 'packages/events-ui';

import { ActualGamesLoadingSkeleton } from '../../../../components';
import connect from './connect';
import styles from './styles.scss';

type Props = {
  tournaments: TournamentView[];
  activeTournamentSeason: ActiveTournamentSeasonData;
  isLoading: boolean;
};

const DEFAULT_SKELETON_EVENTS_COUNT = 3;
const eventComponentProps = {
  isDateVisible: true,
  isRoundVisible: true,
  isFormVisible: true,
  isFormPopoverDisabled: true,
};

const ActualGames = ({ tournaments, activeTournamentSeason, isLoading }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { teamId } = useParams();
  const { appNavigate } = useNavigation();
  const lastTournamentIndex = tournaments.length - 1;

  const handleEventClick = useCallback(
    (eventId: number) => {
      setSearchParams({ [SearchQuery.EventCardId]: eventId.toString() });
    },
    [searchParams, activeTournamentSeason],
  );

  const handleLinkClick = useCallback(() => {
    if (teamId) {
      appNavigate(DesktopPage.FootballTeamGames, { teamId });
    }
  }, [teamId]);

  if (isLoading) {
    return <ActualGamesLoadingSkeleton eventCount={DEFAULT_SKELETON_EVENTS_COUNT} isCompact />;
  }

  return (
    <div className={styles.actualGamesBlock}>
      <div className={styles.header}>
        <div className={styles.title}> {i18n.value(`team.teamSummary.actualGames.title`)}</div>
        <BlockLink
          label={i18n.value('team.teamSummary.actualGames.link')}
          onClick={handleLinkClick}
        />
      </div>
      <div className={styles.gamesList}>
        {tournaments.map((tournament, index) => {
          const key = `${tournament.id}${index}${teamId}`;

          return (
            <Tournament
              useModalOnCategoryClick
              key={key}
              tournament={tournament}
              eventComponent={Event.Mobile}
              handleEventClick={handleEventClick}
              eventComponentProps={eventComponentProps}
              isUniqueTournamentNameDisplayed
              isTournamentLast={lastTournamentIndex === index}
            />
          );
        })}
      </div>
    </div>
  );
};

export default connect(memo(ActualGames));
