export enum StatisticsType {
  WholeSeason = 'wholeSeason',
  PerGame = 'perGame',
}

export enum StatisticsFieldKey {
  GamesPlayed = 'GamesPlayed',
  GoalsScored = 'GoalsScored',
  GoalsConceded = 'GoalsConceded',
  CleanSheets = 'CleanSheets',
  PenaltyGoals = 'PenaltyGoals',
  GoalsByHead = 'GoalsByHead',
  FootGoals = 'FootGoals',
  TotalShots = 'TotalShots',
  ShotsOnGoal = 'ShotsOnGoal',
  ShotsOffGoal = 'ShotsOffGoal',
  ShotsOnPost = 'ShotsOnPost',
  ShootingEfficiency = 'ShootingEfficiency',
  Possession = 'Possession',
  CornerKicks = 'CornerKicks',
  FreeKicks = 'FreeKicks',
  Offsides = 'Offsides',
  YellowCards = 'YellowCards',
  YellowRedCards = 'YellowRedCards',
  DirectRedCards = 'DirectRedCards',
}
