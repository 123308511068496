import { TeamPlayerStatistics } from 'packages/rest';

import { mapFilters } from '../../services';
import { filtersActions } from '../../actions';

interface Params {
  teamId: number;
  seasonId: number;
}

const loadFilters = ({ seasonId }: Params) => async (dispatch) => {
  try {
    dispatch(filtersActions.loadFiltersStart());

    const { data } = await TeamPlayerStatistics.loadFilters({ seasonId });
    const mappedFilters = mapFilters(data.results);

    dispatch(filtersActions.loadFiltersSuccess(mappedFilters));
  } catch (e) {
    dispatch(filtersActions.loadFiltersFailure(e));
  }
};

export default loadFilters;
