import { combineActions, handleActions, ReducerMap } from 'redux-actions';

import * as actions from '../../actions';
import { TopPlayer } from '../../types';

const defaultState: TopPlayer[] = [];

const reducerMap: ReducerMap<TopPlayer[], TopPlayer[]> = {
  [`${combineActions(actions.loadTopPlayersSuccess, actions.loadInitialTopPlayersSuccess)}`]: (
    state,
    { payload },
  ) => {
    return payload;
  },
  [`${actions.cleanTopBoardSuccess}`]: () => {
    return defaultState;
  },
};

export default handleActions<TopPlayer[], TopPlayer[]>(reducerMap, defaultState);
