import i18n from 'i18n-smart';

import { Enums as PlayerDetailsEnums } from 'packages/player-summary/types';
import { NavigationMenuItem } from 'packages/shared/components/NavigationMenu/types';

const getTabs = () => {
  const tabs: NavigationMenuItem[] = [];

  for (const statType of Object.values(PlayerDetailsEnums.Statistics.Statistics.StatType)) {
    tabs.push({
      id: statType,
      name: i18n.value(`player.summary.statistics.statType.${statType}`),
    });
  }

  return tabs;
};

export default getTabs;
