import { PopoverAlign, PopoverCustomPosition, PopoverJustify } from '../types';
import calculatePopoverPositionWithTarget from './calculatePopoverPositionWithTarget';
import calculatePopoverPositionWithoutTarget from './calculatePopoverPositionWithoutTarget';

interface Params {
  justify: PopoverJustify;
  align: PopoverAlign;
  popoverEl: HTMLDivElement;
  targetEl: HTMLDivElement | null;
  getParent: () => HTMLElement | null;
  boundedByParent: boolean;
  customPosition?: PopoverCustomPosition;
  insertIntoParent?: boolean;
}

const calculatePopoverPosition = ({
  justify,
  align,
  popoverEl,
  targetEl,
  getParent,
  customPosition,
  insertIntoParent,
  boundedByParent,
}: Params) => {
  if (targetEl) {
    return calculatePopoverPositionWithTarget({
      justify,
      align,
      popoverEl,
      targetEl,
      getParent,
      insertIntoParent,
      customPosition,
      boundedByParent,
    });
  }

  return calculatePopoverPositionWithoutTarget({
    justify,
    align,
    popoverEl,
    getParent,
    insertIntoParent,
    customPosition,
  });
};

export default calculatePopoverPosition;
