import React, { useCallback, useMemo, memo } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import classnames from 'classnames';
import { ActionCreatorsMapObject } from 'redux';

import { Icon, Select } from 'packages/shared/components';
import { SelectItem, SelectTheme } from 'packages/shared/components/Select/types';
import { IconType } from 'packages/shared/components/Icon/types';
import { Sport } from 'types';
import { ROOT } from 'router/constants/mobile';
import { MobilePage } from 'router/types';

import styles from './styles.scss';
import { Logo, SettingsBtn } from '../../components';
import { getSportTypeOptions } from './services';
import connect from './connect';
import { SearchBlock } from './components';

type Props = {
  sports: Sport[];
  searchKey: string;
  searchActions: ActionCreatorsMapObject;
};

const Header = ({ sports, searchActions, searchKey }: Props) => {
  const { pathname } = useLocation();

  const sportTypes = useMemo(() => getSportTypeOptions(sports), [sports]);

  const handleSportTypeSelect = useCallback((type: SelectItem) => {}, []);

  const isSearchOpen = pathname === ROOT[MobilePage.Search];

  return (
    <header className={classnames(styles.headerContainer, { [styles.search]: isSearchOpen })}>
      <Logo isLogoHidden={isSearchOpen} className={styles.logoContainer} />

      {isSearchOpen ? (
        <SearchBlock searchKey={searchKey} searchActions={searchActions} />
      ) : (
        <div className={styles.container}>
          <Select
            className={styles.select}
            inputClassName={styles.selectInput}
            itemsContainerClass={styles.selectItemsContainer}
            onChange={handleSportTypeSelect}
            theme={SelectTheme.Transparent}
            items={sportTypes}
            selectedItem={sportTypes[0]}
          />

          <Link className={styles.searchLink} to={ROOT[MobilePage.Search]}>
            <Icon id={IconType.SearchLogo} />
          </Link>

          <SettingsBtn />
        </div>
      )}
    </header>
  );
};

export default connect(memo(Header));
